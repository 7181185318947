import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button, Card, Message, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import createOrders from "astrid-firestore/src/api/orders/createOrders";

import { getProfile } from "../../../authentication/state/profile";

export default function CreateOrdersModal({ title, orders, redirectTo, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [{ loading, error }, onSubmit] = useAsyncFn(async () => {
		await createOrders(firebase, { orders, user: getProfile() });

		onClose();

		if (redirectTo) {
			navigate(redirectTo);
		}
	}, [redirectTo, onClose]);

	if (error) {
		console.error(error);
	}

	return (
		<Modal open size="tiny" closeOnDimmerClick onClose={onClose}>
			<Modal.Header>{title}</Modal.Header>
			<Modal.Content>
				<Card.Group>
					{orders.map((costs, index) => (
						<Card
							fluid
							key={index}
							header={costs[0].firstParty.vendor?.name || costs[0].firstParty.name}
							meta={costs[0].secondParty.vendor?.name || costs[0].secondParty.name}
							description={`${costs.length} ${t("costs")}`}
						/>
					))}
				</Card.Group>
				{error && <Message error content={t("errorMessageHeader")} />}
			</Modal.Content>
			<Modal.Actions>
				<Button content={t("cancel")} onClick={onClose} />
				<Button color="green" loading={loading} content={t("create", "Create")} onClick={onSubmit} />
			</Modal.Actions>
		</Modal>
	);
}
