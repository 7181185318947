import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import Rebase from "re-base";

import permissions from "astrid-permissions/permissions";

import app, { config } from "./app";
import db from "./db";
import storage from "./storage";

// connect to emulated functions
if (typeof window !== "undefined" && window.location.hostname === "localhost" && window.location.search === "?emulate")
	app.functions().useFunctionsEmulator("http://localhost:5001");

const base = Rebase.createClass(db);

const mapNewUserError = (t, error, email) => {
	const errorCodes = {
		"auth/email-already-in-use": t("emailAlreadyInUse", { email }),
		"auth/invalid-email": t("invalidEmail", { email }),
	};
	const mappedError = errorCodes[error.code];
	return mappedError ? mappedError : "Ett okänt fel har inträffat";
};

const newUser = (context, firstName, lastName, email, isReader, permissions = {}, t) => {
	context.setState({
		message: {
			text: t ? t("savingUser") : "Sparar användaren",
			positive: false,
			negative: false,
		},
		loading: !isReader,
		addingReader: !!isReader,
	});

	// create app (if neccesary) and store it for reuse
	let userAdderApp = window.earselectUserAdderApp;
	if (!userAdderApp && context.props.profile) {
		window.earselectUserAdderApp = firebase.initializeApp(config, "UserAdderApp");
		userAdderApp = window.earselectUserAdderApp;
	} else if (isReader && !context.props.profile) {
		console.log("use standard app for reader self registration");
		userAdderApp = app;
	}

	// add user
	userAdderApp
		.auth()
		.createUserWithEmailAndPassword(email, "pw" + Math.round(+new Date() * Math.random()))
		.then(function (obj) {
			const firebaseUser = obj.user;

			const data = {
				email,
				firstName,
				lastName,
				permissions,
				created: firebase.firestore.FieldValue.serverTimestamp(),
			};

			if (isReader) {
				// reader added while creating/adding production or self signup
				data.permissions = { reader: ["any", "allPublishers", "allProducers"] };

				// store referer id and campaign
				if (context.props.match.params.referrer) {
					data.referrer = context.props.match.params.referrer;
				}
				if (context.props.match.params.campaign) {
					data.referrerCampaign = context.props.match.params.campaign;
				}

				// self signup readers are NOT searchable by default
				if (!context.props.profile) {
					data.readerData = {
						searchable: false,
					};
				}
				if (context.props.production?.language) {
					data.languages = {
						[context.props.production?.language]: {
							skill: 5,
						},
					};
				}
			}

			base.addToCollection("users", data, firebaseUser.uid).then((ref) => {
				context.setState({
					addedUserId: firebaseUser.uid,
					message: {
						text: t ? t("userCreated", { email }) : "Användaren " + email + " skapades!",
						positive: true,
						negative: false,
					},
					loading: false,
					addingReader: false,
				});

				// send email if not created from production page
				if (!isReader || (isReader && !context.props.profile)) {
					app.auth()
						.sendPasswordResetEmail(email, {
							url: "https://astrid.fm/login/email?email=" + email,
						})
						.then(function () {
							console.log("Welcome email sent to " + email);
							if (typeof isReader === "function") isReader(firebaseUser);
						})
						.catch(function (error) {
							console.log("Mail error", error);
						});
				} else {
					if (typeof isReader === "function") isReader(firebaseUser);
				}
			});
		})
		.catch((args) => {
			console.log("User create error", args);
			context.setState({
				message: {
					text: mapNewUserError(t, args, email),
					positive: false,
					negative: true,
				},
				loading: false,
				addingReader: false,
			});
		});
};

const notifyUsers = (users, userMap, subject, message, attachments) => {
	// only unique
	let notifyUsers = [...new Set(users)];

	// loop and send
	notifyUsers.forEach((user) => {
		// not to user who opted out
		if (user && userMap[user] && userMap[user].notifications !== "none") {
			const channel = userMap[user].notifications === "slack" ? "slack" : "email";

			// not to dummy emails
			if (userMap[user].email.substr(0, 6) !== "dummy-") {
				// add to database (triggers cloud function)
				base.addToCollection("notifications", {
					to: {
						uid: user,
						email: userMap[user].email,
						producer: userMap[user].producer || false,
					},
					message,
					subject,
					time: firebase.firestore.FieldValue.serverTimestamp(),
					attachments: attachments || null,
					// sendOnStage: true,
				})
					.then((ref) => {
						console.log("Added notification", channel, user, userMap[user], subject, message, ref.id);
					})
					.catch((err) => console.log("Notify error", err, channel, userMap[user]));
			}
		}
	});
};

const getProductionPermissions = (store, production) => {
	const { user, profile } = store.state;
	const perms = profile.permissions;

	// figure out relationship with production
	const isSuperAdmin = ["owner", "admin"].includes(perms?.role);
	const isProducer = isSuperAdmin
		? ["any", "producerAdmin"]
		: production?.producer && perms?.producer?.[production.producer];
	const isPublisher = production?.publisher && perms?.publisher?.[production.publisher];
	const isReader = perms?.reader && production?.reader?.includes(user.uid);
	const isManager = production?.manager?.includes(user.uid);
	const isSubcontractor = production?.subcontractor === user.uid;
	const isEditor = production?.editor?.includes(user.uid);
	const isRecorder = production?.bookedRecorders?.includes(user.uid);
	const isProofer = production?.proofer?.includes(user.uid);

	// merge all rights
	const allRights = [
		...(isProducer || []),
		...(isPublisher || []),
		...(isReader ? ["reader"] : []),
		...(isManager ? ["manager"] : []),
	].reduce((prev, curr) => {
		if (permissions[curr]) {
			prev.push(...permissions[curr]);
		}
		return prev;
	}, []);
	const rights = [...new Set(allRights)];

	return { isReader, isManager, isProducer, isPublisher, isSubcontractor, rights, isEditor, isRecorder, isProofer };
};

const getOrganizationPermissions = (store, org) => {
	const { profile } = store.state;
	const perms = profile.permissions;

	// figure out if org is producer or publisher
	const isProducer = perms?.producer && perms?.producer[org];

	// if not producer, check if publisher (id can't be both)
	let isPublisher = false;
	if (!isProducer) isPublisher = perms.publisher && perms.publisher[org];

	// merge all rights
	const allRights = [...(isProducer || isPublisher || [])].reduce((prev, curr) => {
		if (permissions[curr]) {
			prev.push(...permissions[curr]);
		}
		return prev;
	}, []);
	const rights = [...new Set(allRights)];

	return { isProducer, isPublisher, rights };
};

const hasRight = (store, right, { org, prod } = {}) => {
	if (org) {
		return getOrganizationPermissions(store, org).rights.includes(right);
	} else if (prod) {
		return getProductionPermissions(store, prod).rights.includes(right);
	} else {
		// check single right globally
		const { profile } = store.state;
		if (!profile?.permissions) return false;

		const perms = profile.permissions;

		// get all users roles
		let allRoles = [];
		if (perms.reader) allRoles.push("reader");
		if (perms.finance) allRoles.push("finance");
		if (perms.super) allRoles.push("super");
		if (perms.producer)
			allRoles = Object.values(perms.producer).reduce((prev, curr) => curr.concat(prev), allRoles);
		if (perms.publisher)
			allRoles = Object.values(perms.publisher).reduce((prev, curr) => curr.concat(prev), allRoles);

		// check if right exists in any of the users roles
		return !!allRoles.find((role) => role !== "any" && permissions[role] && permissions[role].includes(right));
	}
};

export {
	firebase,
	base,
	db,
	storage,
	newUser,
	notifyUsers,
	getProductionPermissions,
	getOrganizationPermissions,
	hasRight,
};
