import { useTranslation } from "react-i18next";
import { useInfiniteHits } from "react-instantsearch";

export default function ResultText() {
	const { t } = useTranslation();
	const { results } = useInfiniteHits();

	return (
		<div style={{ whiteSpace: "nowrap", opacity: 0.5, marginLeft: 5 }}>
			{t("foundAmountNarrators", "Found {{count}} narrators", { count: results.nbHits })}
		</div>
	);
}
