import { useCallback } from "react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import { useProfile } from "../../../authentication/state/profile";
import useOrganizationsQuery from "../../../organizations/hooks/useOrganizationsQuery";
import useSelectOrganization from "../../../organizations/hooks/useSelectOrganization";
import useUserOrganizations from "../../../organizations/hooks/useUserOrganizations";

import CreateProductionForm from "../../components/CreateProductionForm/CreateProductionForm";

export default function CreateProductionPublisherView({ organization }) {
	const profile = useProfile();
	const selectOrganization = useSelectOrganization();

	// change global organization when publisher is changed in form
	const setPublisherId = useCallback(
		(id) => {
			if (id !== organization.id) {
				selectOrganization({ id, type: organizationTypes.PUBLISHER });
			}
		},
		[organization.id, selectOrganization],
	);

	// get producers for publisher
	const [producers] = useCollectionDataOnce(
		useOrganizationsQuery({
			publisher: organization.id,
			type: organizationTypes.PRODUCER,
		}),
	);

	const publisherOptions = useUserOrganizations(profile)
		.filter(({ type }) => type === organizationTypes.PUBLISHER)
		.map(({ id, name }) => ({ key: id, value: id, text: name }));

	const producerOptions = producers?.map(({ id, name }) => ({ key: id, value: id, text: name }));

	if (!producerOptions) {
		return null;
	}

	return (
		<CreateProductionForm
			isDraft
			profile={profile}
			setPublisherId={setPublisherId}
			defaultProducerId={producerOptions?.length === 1 ? producerOptions[0].value : null}
			defaultPublisherId={organization.id}
			producerOptions={producerOptions}
			publisherOptions={publisherOptions}
			enableProductionCategory={organization.enableProductionCategory}
		/>
	);
}
