import { useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";

import { dotPick } from "astrid-firestore/src/utils/dotObject";

export default function useFormField({
	name,
	rules = { required: true },
	className = "",
	valueField = "value",
	defaultValue,
	shouldUnregister = true,
	validateErrorMessage = "",
	...props
}) {
	const form = useFormContext();

	const { control, locked, alteredValues } = form;

	const { field, fieldState, formState } = useController({
		name,
		rules,
		control,
		defaultValue,
		shouldUnregister,
	});

	const { onChange: onChangeField, ...restField } = field;

	const onChange = useCallback(
		(e, data) => {
			if (!locked) {
				const value = data
					? ["range", "number"].includes(data?.type)
						? data[valueField]
							? +data[valueField]
							: null
						: data[valueField]
					: e?.target?.[valueField] || e?.target?.value || e;

				onChangeField(value);
			}
		},
		[locked, onChangeField, valueField],
	);

	const altered = !!dotPick(name, alteredValues);

	return {
		locked,
		field: {
			...restField,
			onChange,
			error:
				fieldState?.error && (fieldState?.error?.message || validateErrorMessage)
					? {
							content: fieldState.error.message || validateErrorMessage,
					  }
					: !!fieldState?.error,
			className: altered ? `${className} altered` : undefined,
			...props,
		},
		fieldState,
		formState,
	};
}
