import createFirestoreCollection from "../../firestore/createFirestoreCollection";
import { MetaData } from "../../types/MetaData";
import arrayUnion from "../../utils/arrayUnion";
import createDocumentData from "../../utils/createDocumentData";

import { Article } from "./types/Article";
import { ArticleVersion, ArticleVersionRef } from "./types/ArticleVersion";

const articles = createFirestoreCollection("articles", Article, {
	preProcess: async ({ api, firebase }, { transaction, data, before }) => {
		// Check if an article with the same ISBN already exists
		if (data.isbn && data.isbn !== before.isbn) {
			const articles = await api.articles.getAll((ref) =>
				ref
					.where(firebase.firestore.FieldPath.documentId(), "!=", data.ref.id)
					.where("isbn", "==", data.isbn)
					.where("publisher.id", "==", before.publisher.id)
					.where("deleted", "==", false)
					.limit(1),
			);

			if (articles.length > 0) {
				throw new Error("An article with this ISBN already exists.");
			}
		}

		// If we have a series and it doesn't exist, create it
		if (data.serie && !data.serie.exists) {
			data.serie = await api.series.create(data.serie, { transaction });
		}

		// If we have an imprint and it doesn't exist, create it
		if (data.imprint && !data.imprint.exists) {
			data.imprint = await api.imprints.create(data.imprint, { transaction });
		}

		// If we add an author and it doesn't exist, create it
		if (data.authors) {
			data.authors = await Promise.all(
				data.authors.map((author) => {
					if (!author.exists) {
						return api.authors.create(author, { transaction });
					}

					if (before.authors?.every((a) => a.id !== author.id)) {
						return api.authors.update(
							{
								ref: author.ref,
								publisherIds: arrayUnion(firebase, before.publisher.id),
							},
							{ before: author, transaction, validate: false },
						);
					}

					return author;
				}),
			);

			data.author = data.authors.map((author) => author.name).join(", ");
			data.authorIds = data.authors.map((author) => author.id);
		}

		// If we add a translator and it doesn't exist, create it
		if (data.translators) {
			data.translators = await Promise.all(
				data.translators.map((translator) => {
					if (!translator.exists) {
						return api.translators.create(translator, { transaction });
					}

					if (before.translators?.every((a) => a.id !== translator.id)) {
						return api.translators.update(
							{
								ref: translator.ref,
								publisherIds: arrayUnion(firebase, before.publisher.id),
							},
							{ before: translator, transaction, validate: false },
						);
					}

					return translator;
				}),
			);

			data.translator = data.translators.map((translator) => translator.name).join(", ");
			data.translatorIds = data.translators.map((translator) => translator.id);
		}

		// If title is provided, fetch the title data
		if (data.title && data.sync) {
			data = { ...data, ...MetaData.parse(data.title) };
		}

		return data;
	},

	sideEffects: async ({ api, firebase, getUser }, { transaction, after, before, version }) => {
		if (version) {
			const version = ArticleVersion.parse(
				createDocumentData(
					{ after, before },
					{
						collection: after.ref.collection("versions"),
						firebase,
						getUser,
					},
				),
			);

			transaction.set(version.ref, version);
			transaction.update(after.ref, { version: ArticleVersionRef.parse(version) });
		}

		if (after.exists !== before?.exists || after?.isbn !== before?.isbn) {
			const data = {
				isbns: arrayUnion(firebase, after.isbn, after.exists),
				articleIds: arrayUnion(firebase, after.id, after.exists),
			};

			if (after.serie?.ref) {
				await api.series.update({ ref: after.serie.ref, ...data }, { transaction, validate: false });
			}

			if (after.imprint?.ref) {
				await api.imprints.update({ ref: after.imprint.ref, ...data }, { transaction, validate: false });
			}

			if (after.bundle?.ref) {
				await api.articleBundles.update({ ref: after.bundle.ref, ...data }, { transaction, validate: false });
			}
		}
	},

	updateTitleData: async ({ api }, { ref, sync = false, ...data }) => {
		const title = await api.titles.getByRef(data.title.ref);

		if (title) {
			return api.articles.update({ ref, sync, title });
		}
	},

	distribute: ({ api }, { articles, channels }) => {
		const documents = articles.flatMap(({ id: articleId }) => {
			return Object.entries(channels).flatMap(([channelId, { pipelines }]) => {
				return Object.entries(pipelines)
					.filter(([, checked]) => checked)
					.map(([pipelineId]) => {
						return {
							articleId,
							channelId,
							pipelineId,
						};
					});
			});
		});

		return api.distributions.createAll(documents);
	},
});

export default articles;
