import { Card } from "semantic-ui-react";

import VendorSettings from "../../../vendors/components/VendorSettings/VendorSettings";

export default function OrganizationSettingsVendorsView({ organization }) {
	return (
		<Card.Group>
			<VendorSettings
				type="organization"
				uid={organization.id}
				isProducer={organization.type === "producer"}
				currentVendors={organization.vendors}
			/>
		</Card.Group>
	);
}
