import React from "react";

import useCurrencyOptions from "astrid-hooks/src/useCurrencyOptions";

import Select from "../../../ui/components/Select/Select";
import useInitialFormData from "../../../ui/hooks/useInitialFormData";

export default function CurrencySelect(props) {
	const currencyOptions = useCurrencyOptions();

	useInitialFormData("currency", props);

	return <Select fluid search deburr options={currencyOptions} {...props} />;
}
