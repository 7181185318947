import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import useOrganizationsQuery from "../../../organizations/hooks/useOrganizationsQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminOrganizationsColumns from "./hooks/useAdminOrganizationsColumns";

export default function AdminOrganizationsView({ header, type, producer }) {
	const navigate = useNavigate();

	const query = useOrganizationsQuery({ type, producer });
	const columns = useAdminOrganizationsColumns();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={header}>{searchInput}</AdminHeader>

			<CollectionDataTable
				persist
				filterable={false}
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(`../${id}`)}
				searchQuery={searchQuery}
				searchableKeys={["name"]}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</>
	);
}
