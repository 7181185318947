export function getPartyRef(party, vendorId) {
	return getRef(party, {
		img: party?.img,
		email: party?.email,
		type: party?.type || "user",
		name: party?.type ? party?.name : `${party?.firstName} ${party?.lastName}`,
		vendor: party?.vendors?.[vendorId]
			? {
					id: vendorId,
					...party.vendors[vendorId],
			  }
			: party?.vendor,
	});
}

export function getProductionRef(production, ...additionalData) {
	return getRef(
		production,
		{
			title: production?.title,
			producer: production?.producer,
			publisher: production?.publisher,
		},
		...additionalData,
	);
}

export function getOfferProductionRef(production) {
	return getProductionRef(production, {
		title: production.title,
		isbn: production.isbn,
		scriptDate: production.scriptDate,
		scriptUploadDate: production.scriptUploadDate,
		productionDate: production.productionDate,
		deliveryDate: production.deliveryDate,
		authorOptions: production.authorOptions,
		synopsis: production.synopsis,
		language: production.language,
		languageExtra: production.languageExtra,
		pages: production.pages,
		series: production.series,
		part: production.part,
		team: Object.values(production?.team || {}).reduce(
			(map, member) => ({
				...map,
				[member.user?.id]: {
					name: member.user?.name,
					img: member.user?.img,
					roles: [...(map[member.user?.id]?.roles || []), member.role],
				},
			}),
			{},
		),
	});
}

export function getOrganizationRef(organization) {
	return getRef(organization, {
		type: organization?.type,
		name: organization?.name,
	});
}

export function getStudioRef(studio) {
	return getRef(studio, {
		gen: studio?.gen || 1,
		name: studio?.name,
		address: studio?.address,
		version: studio?.version,
		color: studio?.color,
	});
}

export function getProductionProducerRef(db, production) {
	return getOrganizationRef({
		id: production.producer,
		ref: db.collection("organizations").doc(production.producer),
		name: production.producerName,
		type: "producer",
	});
}

export function getProductionPublisherRef(db, production) {
	return getOrganizationRef({
		id: production.publisher,
		ref: db.collection("organizations").doc(production.publisher),
		name: production.publisherName,
		type: "publisher",
	});
}

export function getUserRef(user, ...additionalData) {
	return getRef(
		user,
		{
			img: user?.img,
			email: user?.email,
			phone: user?.phone,
			firstName: user?.firstName?.trim(),
			lastName: user?.lastName?.trim(),
			name: user?.firstName
				? [user?.firstName?.trim(), user?.lastName?.trim()].filter((s) => s).join(" ")
				: user?.name,
		},
		...additionalData,
	);
}

export function getAgreementRef(agreement) {
	return getRef(agreement, {
		name: agreement?.name,
		type: agreement?.type,
		part: agreement?.part,
		state: agreement?.state,
		status: agreement?.status,
		firstParty: getPartyRef(agreement?.firstParty),
		secondParty: getPartyRef(agreement?.secondParty),
	});
}

export function getPriceRef(price) {
	return getRef(price, {
		vat: price?.vat,
		name: price?.name,
		type: price?.type,
		unit: price?.unit,
		price: price?.price,
		maxUses: price?.maxUses || false,
		currency: price?.currency,
		employee: price?.employee || false,
	});
}

export function getOfferRef(offer) {
	return getRef(offer, {
		type: offer?.type,
	});
}

export function getSessionRef(session) {
	return getRef(session, {
		start: session?.start,
		end: session?.end,
		arrival: session?.arrival,
		finish: session?.finish,
		stats: session?.stats,
	});
}

export function getRef(refData = null, ...additionalData) {
	if (!refData?.id) {
		return refData;
	}

	const data = Object.assign(
		{
			id: refData.id,
			ref: refData.ref,
		},
		...additionalData,
	);

	return Object.keys(data).reduce(
		(map, key) => ({
			...map,
			[key]: data[key] !== undefined ? data[key] : null,
		}),
		{},
	);
}
