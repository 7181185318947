import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Header, Segment } from "semantic-ui-react";

import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

import ProductionOffers from "../../components/ProductionOffers/ProductionOffers";
import ProductionTeam from "../../components/ProductionTeam/ProductionTeam";

import ProductionViewContext from "../ProductionView/context/ProductionViewContext";

export default function ProductionTeamView({ production }) {
	const { t } = useTranslation();
	const { offers } = useContext(ProductionViewContext);

	return (
		<Segment basic padded>
			<Header as="h4" content={t("team", "Team")} />
			<ProductionTeam production={production} />

			<Header as="h4" content={t("offers", "Offers")} />
			<ProductionOffers
				production={production}
				offers={offers.filter((offer) => [offerTypes.WORK_ORDER, offerTypes.OPEN].includes(offer.type))}
			/>
		</Segment>
	);
}
