import React from "react";
import { Draggable } from "react-beautiful-dnd";

export default function DraggableSlot({ children, ...props }) {
	return (
		<Draggable {...props}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={{
						userSelect: "none",
						...provided.draggableProps.style,
					}}
				>
					{children}
				</div>
			)}
		</Draggable>
	);
}
