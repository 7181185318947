import { useMemo } from "react";

import db from "astrid-firebase/src/db";

const rolePermissionMap = {
	editor: "producerEditor",
	proofer: "producerProofer",
	manager: "producerStaff",
	recorder: "producerRecorder",
	subcontractor: "producerSubcontractor",
};

export default function useTeamUsersQuery({ role, production }) {
	return useMemo(() => {
		if (role && production.producer) {
			return db
				.collection("users")
				.where(`permissions.producer.${production.producer}`, "array-contains", rolePermissionMap[role]);
		}
	}, [role, production.producer]);
}
