import styled from "styled-components";

export const ResponsiveContainer = styled.div`
	display: grid;
	grid-template-columns: max-content max-content;
	gap: 10px;
	align-items: center;

	@media (max-width: 768px) {
		display: inline-block;
	}
`;
