import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";
import CreateDistributionChannelButton from "../../../distribution/components/CreateDistributionChannelForm/CreateDistributionChannelButton";
import DistributionChannelsCollectionDataTable from "../../../distribution/components/DistributionChannelsCollectionDataTable/DistributionChannelsCollectionDataTable";

export default function SuperAdminDistributionChannelsView() {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("distributionChannels", "Distribution channels")}>
				{searchInput}
				<CreateDistributionChannelButton />
			</AdminHeader>

			<DistributionChannelsCollectionDataTable searchQuery={searchQuery} />
		</>
	);
}
