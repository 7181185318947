import { useTranslation } from "react-i18next";
import { useStats } from "react-instantsearch-core";

export default function Stats() {
	const { t } = useTranslation();
	const { nbHits } = useStats();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				margin: "0.5rem",
			}}
		>
			{`${nbHits} ${t("productions", "Productions")?.toLowerCase()}`}
		</div>
	);
}
