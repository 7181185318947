import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

export default function AlertModal({ onClose, text }) {
	const { t } = useTranslation();

	return (
		<Modal open onClose={onClose} closeOnDimmerClick size="tiny">
			<Modal.Content>
				{text.split("\n").map((row, index) => (
					<p key={index}>{row}</p>
				))}
			</Modal.Content>

			<Modal.Actions>
				<Button primary content={t("ok", "Ok")} onClick={onClose} />
			</Modal.Actions>
		</Modal>
	);
}
