import React from "react";
import { useTranslation } from "react-i18next";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useUserLanguagesTableColumns from "./hooks/useUserLanguagesTableColumns";

export default function UserLanguagesTable({ user }) {
	const { t } = useTranslation();
	const userLanguagesTableColumns = useUserLanguagesTableColumns(user);

	const languages = Object.entries(user?.languages || {}).map(([code, { skill, dialect }]) => ({
		language: code,
		skill,
		dialect,
	}));

	return (
		<DataTable
			noDataAvailableMessage={t("noLanguagesAdded", "No languages added")}
			columns={userLanguagesTableColumns}
			data={languages}
			hoverRow={false}
		/>
	);
}
