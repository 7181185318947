import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import BiblioImprintsForm from "./components/BiblioImprintsForm";
import BiblioProducerForm from "./components/BiblioProducerForm";

export default function SuperAdminBiblioSettingsView() {
	const [settings, loading, error] = useDocumentDataOnce(db.collection("integrations").doc("biblio"));

	return (
		<>
			<AdminHeader>Biblio</AdminHeader>

			<LoadingContext loading={loading} error={error} data={settings}>
				{settings && (
					<Card.Group>
						<BiblioProducerForm settings={settings} />
						<BiblioImprintsForm settings={settings} />
					</Card.Group>
				)}
			</LoadingContext>
		</>
	);
}
