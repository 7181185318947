import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

export default function OfferCreatedLabel({ offer }) {
	const { t } = useTranslation();

	const created = offer.createdAt?.toDate().toLocaleDateString(undefined, {
		year: "numeric",
		month: "long",
		day: "numeric",
	});

	return (
		<Label basic>
			{t("created", "Created")}

			<Label.Detail style={{ marginLeft: 5 }}>{created}</Label.Detail>
		</Label>
	);
}
