import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import TeamUserSelect from "../../TeamUserSelect/TeamUserSelect";

export default function TeamMemberUserField() {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();

	const { role, production } = watch();

	if (!role) {
		return null;
	}

	return (
		<Form.Field name="userId">
			<TeamUserSelect label={t("user", "User")} role={role} production={production} />
		</Form.Field>
	);
}
