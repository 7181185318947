import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import { useProfile } from "../../authentication/state/profile";
import useHasRight from "../../authorization/hooks/useHasRight";
import useHasRole from "../../authorization/hooks/useHasRole";

import SuperAdminOrganizationsView from "../views/SuperAdminOrganizationsView/SuperAdminOrganizationsView";

export default function SuperAdminProducers() {
	const profile = useProfile();

	const hasRole = useHasRole();
	const hasRight = useHasRight();

	if (!hasRight("organizations.admin")) {
		return <Unauthorized />;
	}

	const admin = hasRole("admin") && profile.adminProducerIds && profile.id;

	return <SuperAdminOrganizationsView type="producer" admin={admin} />;
}
