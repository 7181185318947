import { Navigate, Outlet } from "react-router-dom";
import { Grid, Header } from "semantic-ui-react";

import { useProfile } from "../state/profile";

export default function Login() {
	const profile = useProfile();

	if (profile) {
		return <Navigate to="/" />;
	}

	return (
		<div className="login-form">
			<Grid textAlign="center">
				<Grid.Column textAlign="left" style={{ marginTop: "3%", maxWidth: 550 }}>
					<Header as="h1" style={{ fontSize: "4vw", letterSpacing: "-.05em" }} textAlign="center">
						<a href="https://astrid.fm" className="login-logo">
							<img src="/img/astrid-logo.svg" alt="Astrid" />
						</a>
					</Header>
					<Outlet />
				</Grid.Column>
			</Grid>
		</div>
	);
}
