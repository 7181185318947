import debounce from "lodash/debounce";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form, Header } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { base } from "astrid-firebase";

import useProductionAds from "../../features/productions/hooks/useProductionAds";

const update = debounce(({ document, blurbs }) => {
	base.updateDoc(document, {
		blurbs,
	});
}, 500);

const PublisherBlurbs = ({ template, pid, production, publisher, disabled }) => {
	const [language, setLanguage] = useState();
	const [intro, setIntro] = useState("");
	const [outro, setOutro] = useState("");
	const [current, setCurrent] = useState();
	const { t } = useTranslation(["common", "language"]);

	const ads = useProductionAds(pid);
	const adsById = useMemo(() => ads.reduce((map, ad) => ({ ...map, [ad.id]: ad }), {}), [ads]);

	// if (!intro && production?.intro) setIntro(production.intro);
	// if (!outro && production?.outro) setOutro(production.outro);
	// if (!language && production?.language) setLanguage(production.language);

	/* eslint-disable no-eval */
	useEffect(() => {
		if (!production && !publisher?.languageOptions?.length && publisher?.languageDefault) {
			//setLanguage(publisher.languageDefault);
			t("language:" + publisher.languageDefault);
			setIntro(publisher?.blurbs?.[language]?.intro || "");
			setOutro(publisher?.blurbs?.[language]?.outro || "");
		} else if (production && !production.blurbs?.intro && !production.blurbs?.outro && production.language) {
			//setLanguage(production.language);
			t("language:" + production.language);
			setIntro(publisher?.blurbs?.[production.language]?.intro || "");
			setOutro(publisher?.blurbs?.[production.language]?.outro || "");
		} else if (
			production &&
			production.blurbs?.intro &&
			production.blurbs?.outro &&
			production.language &&
			((!intro && !outro) ||
				(intro === publisher?.blurbs?.[language]?.intro && outro === publisher?.blurbs?.[language]?.outro))
		) {
			//setLanguage(production.language);
			t("language:" + production.language);
			setIntro(production.blurbs?.intro || "");
			setOutro(production.blurbs?.outro || "");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const availableLanguages = Object.keys(languages).filter(
		(code) =>
			!publisher.languageOptions ||
			!publisher.languageOptions.length ||
			publisher.languageOptions.includes(code) ||
			publisher.languageDefault === code,
	);

	const injectTag = (tag) => {
		const text = "[" + tag.toUpperCase() + "] ";

		if (current.selectionStart || current.selectionStart === 0) {
			const startPos = current.selectionStart;
			const endPos = current.selectionEnd;
			if (current.name === "intro") {
				setIntro(intro.substring(0, startPos) + text + intro.substring(endPos, intro.length));
			} else {
				setOutro(outro.substring(0, startPos) + text + outro.substring(endPos, outro.length));
			}
			current.selectionStart = startPos + text.length;
			current.selectionEnd = startPos + text.length;
		} else {
			current.value += text;
		}

		current.focus();
	};

	useEffect(() => {
		const blurbs = (production ? production?.blurbs : publisher?.blurbs) || {};

		if (
			production &&
			(intro !== publisher?.blurbs?.[language]?.intro || outro !== publisher?.blurbs?.[language]?.outro)
		) {
			if (intro) blurbs.intro = intro;
			if (outro) blurbs.outro = outro;
		} else if (!production && (intro || outro)) {
			if (!language) return;
			blurbs[language] = {
				intro,
				outro,
			};
			delete blurbs.intro;
			delete blurbs.outro;
		}

		const document = (production ? "productions/" : "organizations/") + pid;

		if (Object.keys(blurbs).length) update({ document, blurbs });
	}, [language, intro, outro, pid, production, publisher]);

	return (
		<div>
			<Header as="h4" icon="file audio" content={(template ? t("templateFor") + " " : "") + t("announce")} />

			{!production && !!publisher?.languageOptions?.length && (
				<Dropdown
					button
					className="icon"
					floating
					pointing
					labeled
					inline
					icon="flag"
					style={{ marginBottom: "1em" }}
					search
					deburr
					placeholder={t("prodLanguage")?.toLowerCase()}
					name={"language"}
					value={language || ""}
					options={availableLanguages.map((code) => ({
						key: code,
						value: code,
						//text: (languages[code].sv || languages[code].name) + " (" + languages[code].nativeName + ")",
						text: t("language:" + code) || t("language:" + code).name,
					}))}
					onChange={(e, data) => {
						setLanguage(data.value);
						setIntro(publisher?.blurbs?.[data.value]?.intro || "");
						setOutro(publisher?.blurbs?.[data.value]?.outro || "");
					}}
					onOpen={(e) => {
						// stupid hack to maintain width while selecting
						if (e && e.currentTarget) e.currentTarget.style.minWidth = e.currentTarget.offsetWidth + "px";
					}}
					onClose={(e) => {
						if (e && e.currentTarget && e.currentTarget.closest)
							e.currentTarget.closest(".dropdown").style.minWidth = "";
					}}
				/>
			)}

			{(language || production) && (
				<Form>
					{!disabled &&
						[
							{
								name: t("title"),
								tag: "title",
							},
							{
								name: t("author"),
								tag: "author",
							},
							{
								name: t("translator"),
								tag: "translator",
							},
							{
								name: t("narrator"),
								tag: "reader",
							},
							{
								name: t("publisher"),
								tag: "publisher",
							},
							{
								name: t("productionCompany"),
								tag: "producer",
							},
							{
								name: t("releaseYear"),
								tag: "year",
							},
						].map((tag) => (
							<Button
								key={tag.tag}
								disabled={!current}
								onClick={() => {
									injectTag(tag.tag);
								}}
								color="orange"
								size="mini"
								style={{ marginBottom: 4 }}
							>
								{tag.name}
							</Button>
						))}
					<Form.TextArea
						label={t("intro")}
						name="intro"
						disabled={disabled}
						value={!disabled ? intro : adsById?.pre?.text}
						onFocus={(e) => {
							setCurrent(e.target);
						}}
						onChange={(e, data) => {
							setIntro(data.value);
						}}
					/>
					<Form.TextArea
						label={t("outro")}
						name="outro"
						disabled={disabled}
						value={!disabled ? outro : adsById?.post?.text}
						onFocus={(e) => {
							setCurrent(e.target);
						}}
						onChange={(e, data) => {
							setOutro(data.value);
						}}
					/>
				</Form>
			)}
		</div>
	);
};

export default PublisherBlurbs;
