import { Button, Segment } from "semantic-ui-react";

import LoadingContext from "../../features/ui/components/LoadingContext/LoadingContext";

export default function LoadMore({ onClick, children, ...props }) {
	return (
		<div style={{ position: "relative" }}>
			<LoadingContext {...props}>
				{onClick && (
					<Segment basic textAlign="center">
						<Button onClick={onClick}>{children}</Button>
					</Segment>
				)}
			</LoadingContext>
		</div>
	);
}
