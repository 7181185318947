import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

export default function AddPricesAction() {
	const { t } = useTranslation();

	return (
		<Button as={Link} to="create">
			{t("addPrices", "Add prices")}
		</Button>
	);
}
