import { useTranslation } from "react-i18next";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

import useTeamRoleTranslations from "../../../../team/hooks/useTeamRoleTranslations";

export default function useOfferMessage(offer) {
	const { t } = useTranslation();
	const roles = useTeamRoleTranslations();

	switch (offer.status) {
		case offerStatuses.ACCEPTED:
			return {
				text: t("acceptedOffer", "You have accepted the offer"),
				color: "green",
			};
		case offerStatuses.CANCELLED:
			return {
				text: t("offerWasCancelled", "This offer was cancelled, thank you for your shown interest!"),
				color: "black",
			};

		case offerStatuses.DECLINED:
			return {
				text: t("declinedOffer"),
				color: "red",
			};
		case offerStatuses.EXPIRED:
			return {
				text: t("expiredOfferModalHeader", "The offer expired {{expiresAt}}", {
					expiresAt: offer.expiresAt?.toDate().toLocaleDateString(),
				}),
				color: "black",
			};
		case offerStatuses.INTERESTED:
			return {
				text: t(
					"acceptInquiryMessage",
					"Your expression of interest has been received for this production. A production coordinator will process your expression of interest shortly.",
				),
				color: "yellow",
			};
		case offerStatuses.OFFERED:
			if (offer.type === offerTypes.INQUIRY) {
				return null;
			} else if (offer.type === offerTypes.OPEN) {
				return {
					text: t("upForGrabsMessage", "There is an open position as {{role}} for this production", {
						role: roles[offer.role],
					}),
					color: "blue",
				};
			} else {
				return {
					text: t("productionInvitationMessage", "You have been invited to this production as {{role}}", {
						role: roles[offer.role],
					}),
					color: "blue",
				};
			}
		case offerStatuses.SIGNING:
			return {
				header: t("productionInvitationMessage", "You have been invited to this production as {{role}}", {
					role: roles[offer.role],
				}),
				text: (
					<>
						{t("referToAgreementInInbox", "Sign the agreement in your email inbox to accept this offer.")}
						<br />
						{t("afterAgreementSigned")} "{t("myProductions")} - {t("current")}".
					</>
				),
				color: "blue",
			};
		default:
			return false;
	}
}
