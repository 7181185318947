import { Outlet } from "react-router-dom";
import { Card } from "semantic-ui-react";

import renderChildren from "../../../../utils/renderChildren";
import Breadcrumbs from "../../../ui/components/Breadcrumbs/Breadcrumbs";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";

export default function AdminPageView({ children, ...props }) {
	const breadcrumbs = useBreadcrumbs(props);

	const isNested = breadcrumbs.some(({ text }) => !text);

	if (isNested) {
		return <Outlet context={props} />;
	}

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			<Card.Group style={{ marginTop: 2 }}>
				{renderChildren(children, props)}

				<Outlet context={props} />
			</Card.Group>
		</>
	);
}
