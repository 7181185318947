import { useEffect, useState } from "react";

export default function useThemaCodes() {
	const [codes, setCodes] = useState(null);

	useEffect(() => {
		import("astrid-config/src/genres/thema_sv.json").then((data) => {
			setCodes(data.default.CodeList.ThemaCodes.Code);
		});
	}, []);

	return codes;
}
