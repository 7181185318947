import React from "react";
import { Link } from "react-router-dom";
import { Card, Icon } from "semantic-ui-react";

import { languageName } from "astrid-helpers/src/languages";

import useArticleTypeTranslations from "../../hooks/useArticleTypeTranslations";
import articleTypeIcons from "../../utils/articleTypeIcons";

import ArticleCoverImage from "../ArticleCoverImage/ArticleCoverImage";
import ArticleName from "../ArticleName/ArticleName";

export default function ArticleCard({ article }) {
	const articleTypeTranslations = useArticleTypeTranslations();

	return (
		<Card raised key={article.id} as={Link} to={`${article.id}`} style={{ color: "inherit" }}>
			<ArticleCoverImage thumb article={article} />

			<Card.Content style={{ display: "flex", flexDirection: "column" }}>
				<Card.Header>
					<ArticleName article={article} truncate />
				</Card.Header>

				<Card.Meta style={{ marginTop: 4 }}>{article.isbn}</Card.Meta>

				<Card.Meta>{languageName(article.language)}</Card.Meta>
			</Card.Content>

			<Card.Content extra>
				<Icon name={articleTypeIcons[article.type]} />
				{articleTypeTranslations[article.type]}
			</Card.Content>
		</Card>
	);
}
