import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import createAgreementTemplate from "astrid-firestore/src/api/agreements/createAgreementTemplate";
import { setData } from "astrid-firestore/src/helpers";

import PreviewAgreementPdfButton from "../../../../components/PreviewAgreementPdf/PreviewAgreementPdfButton";
import ContentCard from "../../../ui/components/ContentCard/ContentCard";

import Form from "../../../forms/components/Form/Form";

export default function AgreementTemplateFormActions() {
	const { t } = useTranslation();

	const { formState, getValues, handleSubmit, afterSubmit } = Form.useFormContext();
	const { isValid, isSubmitting } = formState;

	const onSubmit = handleSubmit(async (formData) => {
		const agreementTemplate = createAgreementTemplate(firebase, formData);

		await setData(agreementTemplate.ref, agreementTemplate);

		afterSubmit?.(agreementTemplate.ref);
	});

	const previewRequest = () => {
		return createAgreementTemplate(firebase, getValues());
	};

	return (
		<ContentCard disabled={!isValid}>
			<Button primary loading={isSubmitting} disabled={!isValid || isSubmitting} onClick={handleSubmit(onSubmit)}>
				{t("create", "Create")}
			</Button>
			<PreviewAgreementPdfButton request={previewRequest} />
		</ContentCard>
	);
}
