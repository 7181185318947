import React from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import Flex from "../../../components/Flex/Flex";
import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import ArticleCoverImage from "../../articles/components/ArticleCoverImage/ArticleCoverImage";

import useTitleDropdownMenuOptions from "./useTitleDropdownMenuOptions";

export default function useTitlesTableColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			filter: "text",
			disableSortBy: true,
			Header: t("name", "Name"),
			Cell: ({ value, row }) => (
				<Flex justifyContent="flex-start">
					<ArticleCoverImage
						thumb
						rounded
						article={row.original}
						style={{ marginRight: "1em", height: 45, width: 45 }}
					/>
					<span>{value}</span>
				</Flex>
			),
		},
		{
			id: "author.name",
			filter: "text",
			disableSortBy: true,
			Header: t("author", "Author"),
		},
		{
			id: "language",
			filter: "select",
			Header: t("language", "Language"),
			accessor: ({ language }) => languageName(language),
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
		dropdownMenuColumn(useTitleDropdownMenuOptions),
	];
}
