export default {
	name: "Axiell",
	format: "wav",
	connection: "ftp",
	cover: {
		folder: "ISBN", // place inside audio folder
	},
	audio: true,
	disableArticles: ["cd", "mp3cd"],
};
