import bisacToThema from "astrid-config/src/genres/BisacToThema.json";

import { currencies } from "./lists";

// check if timestamp exists, has toDate method and if so run method and return js date,
// if it's already a date, just return it,
// if it's a converted timestamp (has _seconds), reconvert to date
// if not return undefined. (avoids repetitive code...)
export const toDate = (timestamp) => {
	return timestamp && timestamp.toDate
		? timestamp.toDate()
		: timestamp && typeof timestamp.getMonth === "function"
		? timestamp
		: timestamp && timestamp._seconds
		? new Date(timestamp._seconds * 1000)
		: undefined;
};

// simple random (non-unique) string (for keying up objects in less predictable way than timestamps)
export const randomString = () => {
	return Math.random().toString(36).substring(2);
};

// convert seconds to H:MM:SS.MS
export const secToDuration = (sec, ms = true) => {
	var roundSec = Math.abs(sec % 60) + "";
	if (Math.floor(roundSec) < 10) roundSec = "0" + roundSec;
	roundSec = roundSec.substr(0, ms ? 5 : 2);

	return Math.floor(sec / 60 / 60) + ":" + ("0" + Math.floor((sec / 60) % 60)).substr(-2) + ":" + roundSec;
};

// convert duration string to seconds
export const durationToSec = (dur) => {
	if (!dur) return 0;
	const [h, m, s] = dur.split(":");
	return +h * 60 * 60 + +m * 60 + +s;
};

// store stuff in local storage
export const localStorage = (obj, key, val) => {
	let ls = window.localStorage.getItem(obj);
	ls = ls ? JSON.parse(ls) : {};

	if (typeof val !== "undefined") {
		// set key: value pair
		ls[key] = val;
		window.localStorage.setItem(obj, JSON.stringify(ls));
	} else if (key) {
		// get value
		return ls[key];
	} else {
		// get entire object
		return ls;
	}
};

export const getBisac = (thema) => {
	// reduce all Thema codes to mapped Bisac codes
	const bisacEntries = Object.entries(bisacToThema);

	const themaToBisac = {};

	for (const themaCode of thema || []) {
		let currentWeight = 0;

		// get all Bisac codes that are mapped to this Thema code
		const bisacs = bisacEntries.filter(([, map]) => map.includes(themaCode));

		// loop through all Bisac codes and check if all Thema codes are present
		for (const [bisacCode, map] of bisacs) {
			const weight = map.length;
			const hasAll = map.every((code) => thema.includes(code));

			// if all thema codes are present and weight is higher than current, set as current
			if (hasAll && weight > currentWeight) {
				currentWeight = weight;
				themaToBisac[themaCode] = bisacCode;
			}
		}
	}

	return [...new Set(Object.values(themaToBisac))];
};

export const getDefaultCurrency = (lang, availableCurrencies) => {
	// filter currencies?
	const filteredCurrencies = Object.entries(currencies).filter(
		([code]) => !availableCurrencies || availableCurrencies.includes(code),
	);

	// look for language preset
	const preset = filteredCurrencies.find(([, info]) => info.preset && info.preset.includes(lang));

	// default to EUR or first of limited list
	const defaultFallback = availableCurrencies ? availableCurrencies[0] : "EUR";

	return preset ? preset[0] : defaultFallback;
};

export const slugify = (string, replaceSpaceWith = "-", lowerCase = true) => {
	let a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;&";
	let b = "aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz-------";

	string = string.toString();

	if (lowerCase) {
		string = string.toLowerCase();
	} else {
		// also convert uppercase characters
		a += "ÀÁÄÂÃÅĂÆÇÈÉËÊǴḦÌÍÏÎḾŃǸÑÒÓÖÔŒØṔŔẞŚȘȚÙÚÜÛǗẂẌŸŹ";
		b += "AAAAAAAACEEEEGHIIIIMNNNOOOOOOPRSSSTUUUUUWXYZ";
	}

	const p = new RegExp(a.split("").join("|"), "g");

	return (
		string
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, "-and-") // Replace & with ‘and’
			// eslint-disable-next-line
			.replace(/[^\w\-]+/g, replaceSpaceWith) // Replace non-word characters
			// eslint-disable-next-line
			.replace(/\-\-+/g, replaceSpaceWith) // Replace multiple -
			.replace(/^replaceSpaceWith+/, "") // Trim - from start of text
			.replace(/replaceSpaceWith+$/, "") // Trim - from end of text
			.trim()
	);
};
