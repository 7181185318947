import { useTranslation } from "react-i18next";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";
import useDocTitle from "../../ui/hooks/useDocTitle";

import useHasRight from "../../authorization/hooks/useHasRight";

import ListProductionsView from "../views/ListProductionsView/ListProductionsView";

export default function ListProductions(props) {
	const { t } = useTranslation();
	useDocTitle(t("productions"));
	const hasRight = useHasRight();

	if (!hasRight("list-productions.beta")) {
		return <Unauthorized />;
	}

	return <ListProductionsView {...props} />;
}
