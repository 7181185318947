import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import useApprovalStatusTranslations from "../../../../approval/hooks/useApprovalStatusTranslations";
import useCostTypes from "../../../../costs/hooks/useCostTypes";
import { priceAccessor } from "../../../../prices/utils/priceAccessors";

export default function usePricesColumns() {
	const { t } = useTranslation();

	const costTypes = useCostTypes();
	const approvalStatus = useApprovalStatusTranslations();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: ({ created }) => created?.toDate().getTime() || "",
				Cell: ({ value }) => new Date(value).toLocaleString(),
				collapsing: true,
			},
			{
				id: "employee",
				filter: "select",
				Header: t("employee", "Employee"),
				accessor: ({ employee }) => (employee ? t("employee", "Employee") : t("nonEmployee", "Non-Employee")),
				collapsing: true,
			},
			{
				id: "type",
				filter: "select",
				Header: t("type", "Type"),
				accessor: ({ type }) => costTypes[type],
				collapsing: true,
			},
			{
				id: "price",
				filter: "text",
				Header: t("price", "Price"),
				accessor: priceAccessor,
				collapsing: true,
			},
			{
				id: "languages",
				filter: "select",
				Header: t("language", "Language"),
				accessor: ({ languages }) => languages.map(languageName).join(", "),
				collapsing: false,
			},
			{
				id: "approved",
				Header: t("status"),
				accessor: ({ approval }) => approvalStatus[approval?.status],
				collapsing: true,
			},
		],
		[approvalStatus, costTypes, t],
	);
}
