import get from "lodash/get";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Checkbox, Dropdown, Header, Icon, Label, Popup, Table, Transition } from "semantic-ui-react";

import { base, firebase } from "astrid-firebase";

import { withStore } from "../../helpers/context";
import { localStorage, toDate } from "../../helpers/fnc";
import { prodStatus } from "../../helpers/lists";

import ProgressEdit from "../production/ProgressEdit";
import ProgressRecording from "../production/ProgressRecording";

class HomeAdmin extends Component {
	//Martin: Id till "Ej tilldelad"-Hur göra??????
	state = {
		transition: true,
		hideOthers: localStorage("homeAdmin", "hideOthers"),
		productions: [],
		adminOptions: [{ text: this.props.t("notAssigned"), value: "", key: "NONE" }],
	};

	componentDidMount() {
		this.props.store.getUsers().then(() => {
			this.updateProductions();
			this.updateAdmins();
		});
		this.props.store.getOrganizations();

		setTimeout(() => {
			this.setState({ transition: false });
		}, 400);
	}

	componentDidUpdate(prevProps) {
		const { productions, store } = this.props;

		// update productions
		if (productions && prevProps && !isEqual(productions, prevProps.productions)) {
			this.updateProductions();
		}

		// update admin users
		if (
			store.state.users &&
			prevProps &&
			prevProps.store.state.users &&
			!isEqual(store.state.users, prevProps.store.state.users)
		) {
			this.updateAdmins();
		}
	}

	updateProductions = () => {
		const { t, productions, store } = this.props;
		const { user } = store.state;

		const newProductions = productions
			.map((prod) => {
				let reasons = [];

				// sorting ID for comments at top
				prod.adminComment = 2;

				// offers
				if (prod.status === "offer") {
					reasons.push({
						id: "isOffer",
						text: t("productionOffer"),
					});
				}

				// comment flagged with adminAttention
				if (prod.adminAttention) {
					reasons.push({
						id: "adminAttention",
						text: t("adminAttention"),
					});
					prod.adminComment = 1;
				}

				// there is a session without recorder within 30 h
				if (
					typeof prod.bookedSessionMissingRecorder === "object" &&
					moment(prod.bookedSessionMissingRecorder.toDate()).diff(moment(), "hours") < 30 &&
					prod.productionType !== "subcontract"
				) {
					reasons.push({
						removable: true,
						id: "bookedSessionMissingRecorder",
						text: t("sessionMissingRecorder"),
					});
				} else if (prod.bookedSessionMissingRecorder === true && prod.productionType !== "subcontract") {
					reasons.push({
						removable: true,
						id: "bookedSessionMissingRecorder",
						text: t("sessionMissingRecorder"),
					});
				}

				if (prod.adminDownloadExport) {
					reasons.push({
						removable: true,
						id: "adminDownloadExport",
						text: t("adminDownloadExport") + "(" + prod.adminDownloadExport.join(", ") + ")",
					});
				}

				// last comment was by the publisher
				if (prod.lastCommentPublisher) {
					reasons.push({
						removable: true,
						id: "lastCommentPublisher",
						text: t("lastCommentPublisher"),
					});
					prod.adminComment = 0;
				}

				// missing proofer or editor < 7 days
				if (
					prod.status !== "done" &&
					prod.productionType !== "external" &&
					toDate(prod.productionDate) &&
					moment(prod.productionDate.toDate()).diff(moment(), "days") < 7
				) {
					if (!prod.proofer) reasons.push({ id: "missingProofer", text: t("missingProofer") });
					if (!prod.editor && prod.productionType !== "subcontract")
						reasons.push({ id: "missingEditor", text: t("missingEditor") });
				}

				// subcontractor declined offer
				if (prod.subcontractor && prod.subcontractorStatus === "declined") {
					reasons.push({ id: "subcontractorDeclined", text: t("subcontractorDeclined") });
				}

				// no sessions booked < 14 days
				if (
					prod.status !== "done" &&
					prod.productionType !== "external" &&
					toDate(prod.productionDate) &&
					moment(prod.productionDate.toDate()).diff(moment(), "days") < 14 &&
					prod.status === "accepted" &&
					prod.productionType !== "subcontract"
				) {
					reasons.push({ id: "missingSession", text: t("missingSession") });
				}

				// no polish session < 10 days
				if (
					prod.status !== "done" &&
					prod.productionType !== "external" &&
					toDate(prod.productionDate) &&
					moment(prod.productionDate.toDate()).diff(moment(), "days") < 10 &&
					(!prod.bookedPolish || prod.bookedPolish === false) &&
					prod.productionType !== "subcontract"
				) {
					reasons.push({ removable: true, id: "bookedPolish", text: t("missingPolish") });
				}

				// no edited parts < 5 days
				if (
					prod.status !== "done" &&
					prod.productionType !== "external" &&
					toDate(prod.productionDate) &&
					moment(prod.productionDate.toDate()).diff(moment(), "days") < 5 &&
					!(
						// edited in Astrid Studio or…
						(
							prod.recordedDurationEdited ||
							prod.progress?.edited ||
							// edited externally
							(prod.proofParts &&
								Object.values(prod.proofParts).find((part) => part.status !== "deleted"))
						)
					)
				) {
					reasons.push({ id: "missingParts", text: t("missingParts") });
				}

				// proofing left < 1 day
				if (
					prod.status !== "done" &&
					toDate(prod.productionDate) &&
					moment(prod.productionDate.toDate()).diff(moment(), "days") < 1 &&
					prod.proofer &&
					prod.proofer.length &&
					!prod.proofer.includes("NONE") &&
					// proofed in Astrid Studio
					((prod.prooferData && Object.values(prod.prooferData).find((proof) => !proof.completed)) ||
						// proofed externally
						(prod.proofParts &&
							Object.values(prod.proofParts).find(
								(part) =>
									(part.status !== "done" && part.status !== "deleted") ||
									(part.delivered && Object.keys(part.delivered).length < prod.proofer.length),
							)))
				) {
					reasons.push({ id: "missingProof", text: t("missingProof") });
				}

				// is mastering
				if (prod.status === "production" && prod.polishDone && prod.productionType !== "subcontract") {
					reasons.push({ id: "isMastering", text: t("isMastering") });
				}

				// master files uploaded
				const uploadedMasters =
					prod.master &&
					Object.entries(prod.master || {}).filter(
						([article, info]) =>
							article !== "parking" &&
							info.files &&
							info.files.length &&
							info.settings &&
							info.settings.approved === false &&
							!info.settings.pendingUploads,
					);

				if (uploadedMasters && uploadedMasters.length) {
					uploadedMasters.forEach(([article, info]) => {
						reasons.push({
							id: "masterUpload" + article,
							text:
								t("masterUploaded") +
								"(" +
								(article === "total"
									? t("audiobookUpload")
									: article === "cd"
									? t("cdUpload")
									: article === "mp3cd"
									? t("mp3Upload")
									: //Martin: Interpolation????
									  t("partedAudiobook") + " " + (parseInt(article.split("_")[1]) + 1)) +
								")",
						});
					});
				}

				// not done by 22:00 on deliveryDate
				if (
					prod.status !== "done" &&
					prod.deliveryDate &&
					typeof prod.deliveryDate === "object" &&
					moment(prod.deliveryDate.toDate()).diff(moment(), "hours") < 2
				) {
					reasons.push({ id: "passedDelivery", text: t("deliveryDatePassed") });
				}

				// Missing productionDate
				if (
					!["planning", "draft", "offer", "done"].includes(prod.status) &&
					!prod.productionDate &&
					prod.productionType !== "external"
				) {
					reasons.push({ id: "missingProductionDate", text: t("missingProductionDate") });
				}

				// no Script uploaded by 22:00 on scriptDate
				if (
					prod.status !== "done" &&
					toDate(prod.scriptDate) &&
					moment(prod.scriptDate.toDate()).diff(moment(), "hours") < 2 &&
					!prod.script
				) {
					reasons.push({ id: "passedScriptUpload", text: t("scriptUploadDatePassed") });
				}

				// no Script uploaded 3 days before scriptDate - DO NOT REMOVE CODE
				// if (
				// 	prod.status !== "done" &&
				// 	toDate(prod.scriptDate) &&
				// 	moment(prod.scriptDate.toDate()).diff(moment(), "days") < 3 &&
				// 	!prod.script
				// ) {
				// 	reasons.push({
				// 		id: "threeDaysUntilScript",
				// 		text: t("threeDaysUntilScript", "Three days until script due date"),
				// 	});
				// }

				// subcontractor uploaded new files
				if (prod.master?.proof?.fire) {
					reasons.push({
						id: "subcontractorUploadedNewFiles",
						text: t("subcontractorUploadedNewFiles"),
					});
				}

				// return the reasons
				if (reasons.length) {
					prod.adminReasons = reasons;

					// see if prod has reasons that are unassigned or
					const canHide = !reasons.find((rsn) => {
						const assignee =
							(prod.adminAssignments && prod.adminAssignments[rsn.id]) ||
							(prod.manager && prod.manager[0]);

						return !assignee || assignee === user.uid;
					});
					if (canHide) prod.canHide = true;
				}
				return prod;
			})
			.filter((prod) => prod.adminReasons);

		this.setState({ productions: newProductions });
	};

	updateAdmins = () => {
		const { t, store } = this.props;
		const { producerId } = store.state;

		const adminOptions = [{ text: t("notAssigned"), value: "", key: "NONE" }];

		if (store.state.users)
			Object.values(store.state.users)
				.filter((user) => {
					const roles = get(user, "permissions.producer." + producerId);
					return roles && (roles.includes("producerAdmin") || roles.includes("producerStaff"));
				})
				.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
				.forEach((admin) => {
					adminOptions.push({
						text: admin.firstName + " " + admin.lastName,
						value: admin.id,
						key: admin.id,
					});
				});

		this.setState({ adminOptions });
	};

	getProductions = () =>
		this.props.store.getProductions({
			filterStatus: ["offer", "planning", "accepted", "production"],
			adminAlerts: true,
		});

	render() {
		const { t, store } = this.props;
		const { user, organizations } = store.state;
		const { hideOthers, productions, adminOptions } = this.state;

		return productions ? (
			<>
				<div className="flex-stack home-admin">
					<Header as="h4">
						<Transition animation="tada" duration={1400} visible={this.state.transition}>
							<Icon name="fire extinguisher" color="red" />
						</Transition>
						<Header.Content>{t("adminCalledFor")}</Header.Content>
					</Header>
					<div>
						<Checkbox
							checked={hideOthers}
							onChange={(e, data) => {
								localStorage("homeAdmin", "hideOthers", data.checked);
								this.setState({ hideOthers: data.checked });
							}}
							label={t("hideOthersTasks")}
						/>{" "}
					</div>
				</div>

				<Table compact basic="very" sortable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("titleHomeAdm")}</Table.HeaderCell>
							<Table.HeaderCell>{t("causeHomeAdm")}</Table.HeaderCell>
							<Table.HeaderCell>{t("assignedHomeAdm")}</Table.HeaderCell>
							<Table.HeaderCell>{t("statusHomeAdm")}</Table.HeaderCell>
							<Table.HeaderCell></Table.HeaderCell>
							<Table.HeaderCell textAlign="right">{t("prodDateHomeAdm")}</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">{t("deliveryDateHomeAdm")}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{sortBy(productions, ["adminComment", "productionDate"])
							// .sort((a, b) => (a.productionDate >= b.productionDate ? 1 : -1))
							.filter((prod) => !hideOthers || !prod.canHide)
							.map((prod) => {
								// call the status "booked" until first session is logged
								const status =
									prod.status === "production" &&
									!prod.stats &&
									!prod.proofParts &&
									!prod.progress &&
									prod.productionType !== "subcontract"
										? "booked"
										: prod.status;

								return (
									<Table.Row key={prod.id}>
										<Table.Cell selectable>
											<Link to={"/production/" + prod.id + "/audiobook"}>
												{prod.title}
												<br />
												<small>{organizations?.[prod.publisher]?.name}&nbsp;</small>
											</Link>
										</Table.Cell>
										<Table.Cell collapsing>
											{prod.adminReasons &&
												prod.adminReasons
													.filter((rsn) => {
														const assignee =
															(prod.adminAssignments && prod.adminAssignments[rsn.id]) ||
															(prod.manager && prod.manager[0]);

														return !hideOthers || !assignee || assignee === user.uid;
													})
													.map((rsn, i) => (
														<div key={i}>
															<Popup
																inverted
																size="small"
																content={
																	rsn.removable
																		? t("clickToMarkOff")
																		: t("cantMarkOff")
																}
																trigger={
																	<Icon
																		name="fire"
																		color="orange"
																		style={{ cursor: "pointer" }}
																		onClick={(e) => {
																			if (rsn.removable && rsn.id) {
																				base.updateDoc(
																					"productions/" + prod.id,
																					{
																						[rsn.id]:
																							firebase.firestore.FieldValue.delete(),
																					},
																				).then(this.getProductions);
																			}
																		}}
																	/>
																}
															/>{" "}
															{rsn.text}
														</div>
													))}
										</Table.Cell>
										<Table.Cell singleLine>
											{prod.adminReasons &&
												prod.adminReasons
													.filter((rsn) => {
														const assignee =
															(prod.adminAssignments && prod.adminAssignments[rsn.id]) ||
															(prod.manager && prod.manager[0]);

														return !hideOthers || !assignee || assignee === user.uid;
													})
													.map((rsn, i) => {
														const assignee =
															(prod.adminAssignments && prod.adminAssignments[rsn.id]) ||
															(prod.manager && prod.manager[0]);

														const isOk =
															prod.adminAssignments &&
															prod.adminAssignments.ok &&
															prod.adminAssignments.ok[rsn.id] &&
															prod.adminAssignments.ok[rsn.id] === assignee;

														return (
															<div key={i}>
																<Label
																	size="mini"
																	className="only-icon"
																	color={isOk ? "green" : "red"}
																	style={{
																		margin: "0 5px 1px 0",
																		opacity: assignee === user.uid ? 1 : 0.5,
																		cursor:
																			assignee === user.uid && !isOk
																				? "pointer"
																				: "default",
																	}}
																	icon="check"
																	disabled={assignee !== user.uid}
																	onClick={
																		assignee === user.uid && !isOk
																			? (e) => {
																					base.updateDoc(
																						"productions/" + prod.id,
																						{
																							["adminAssignments.ok." +
																							rsn.id]: user.uid,
																						},
																					).then(this.getProductions);
																			  }
																			: null
																	}
																/>

																<Dropdown
																	options={adminOptions}
																	placeholder={t("notAssigned")}
																	scrolling
																	value={
																		prod.adminAssignments &&
																		prod.adminAssignments[rsn.id]
																			? prod.adminAssignments[rsn.id]
																			: prod.manager
																			? prod.manager[0]
																			: ""
																	}
																	onChange={(e, data) => {
																		base.updateDoc("productions/" + prod.id, {
																			["adminAssignments." + rsn.id]: data.value,
																		}).then(this.getProductions);
																	}}
																/>
															</div>
														);
													})}
										</Table.Cell>
										<Table.Cell collapsing>
											<Icon name={prodStatus[status].icon} color={prodStatus[status].color} />
											{/* {prodStatus[status].text} */}
											{t("productions:" + status)}
										</Table.Cell>
										<Table.Cell style={{ width: "35%" }}>
											{prod.stats && <ProgressRecording size="small" production={prod} />}
											<ProgressEdit size="small" production={prod} />
										</Table.Cell>
										<Table.Cell collapsing textAlign="right">
											{prod.productionDate
												? moment(prod.productionDate.toDate()).format("YYYY-MM-DD")
												: t("noProductionDate")}
										</Table.Cell>
										<Table.Cell collapsing textAlign="right">
											{prod.deliveryDate
												? moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")
												: t("noDeliveryDate")}
										</Table.Cell>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</>
		) : (
			""
		);
	}
}

export default withTranslation()(withStore(HomeAdmin));
