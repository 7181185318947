import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function VoiceReaderImage({ reader, added, selected }) {
	const { t } = useTranslation();

	const hasSelection = added || selected;
	const color = added ? "#21ba45" : "#2185d0";

	return (
		<div style={{ position: "relative" }}>
			<ImageAvatar
				src={reader.avatarImg}
				style={{
					width: 50,
					height: 50,
					border: hasSelection && "2px solid transparent",
					outline: hasSelection && `3px solid ${color}`,
				}}
			/>

			{added && (
				<Label
					size="mini"
					style={{
						position: "absolute",
						zIndex: 1,
						bottom: 0,
						left: "50%",
						border: "2px solid white",
						backgroundColor: color,
						borderRadius: 50,
						color: "white",
						transform: "translate(-50%, 50%)",
					}}
				>
					{added ? t("added", "Added") : t("add", "Add")}
				</Label>
			)}
		</div>
	);
}
