import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminUserView from "../views/SuperAdminUserView/SuperAdminUserView";

export default function SuperAdminUser() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("users.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminUserView id={id} />;
}
