import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";
import { codes } from "astrid-helpers/src/currencies";
import ErrorMessage from "astrid-web/src/features/ui/components/Messages/ErrorMessage";

import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";

import useVoiceOptions from "../hooks/useVoiceOptions";

export default function AccountNarration({ user }) {
	const { t } = useTranslation();

	const voiceOptions = useVoiceOptions();

	const [error, setError] = useState(false);

	const { readerData } = user;

	const form = useForm({
		defaultValues: {
			presentation: readerData?.presentation || "",
			voice: readerData?.voice || null,
			currency: readerData?.currency || null,
			minimumFee: readerData?.cost || null,
		},
	});

	const { handleSubmit, formState, reset } = form;

	const { isSubmitting, isDirty } = formState;

	const onSubmit = async ({ presentation, voice, currency, minimumFee }) => {
		try {
			await updateDocument(user.ref, {
				"readerData.presentation": presentation,
				"readerData.voice": voice,
				"readerData.currency": currency,
				"readerData.cost": minimumFee,
			});

			reset({ presentation, voice, currency, minimumFee });
		} catch (error) {
			console.error(error);
			setError(error);
		}
	};

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("narration", "Narration")}</Card.Header>
			</Card.Content>
			<Card.Content extra>
				<Form form={form}>
					<Form.TextArea
						name="presentation"
						label={t("presentation", "Presentation")}
						rules={{ required: false }}
					/>

					<Form.Group widths="equal">
						<Form.Select name="voice" label={t("voice", "Voice")} options={voiceOptions} />

						<Form.Select
							name="currency"
							label={t("currency", "Currency")}
							search
							options={codes.map((code) => ({ text: code, value: code }))}
						/>

						<Form.NumberInput name="minimumFee" label={t("minimumFee", "Minimum fee per hour")} />
					</Form.Group>
				</Form>

				{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
			</Card.Content>

			<Card.Content extra>
				<Button
					primary
					disabled={isSubmitting || !isDirty}
					loading={isSubmitting}
					onClick={handleSubmit(onSubmit)}
				>
					{t("save", "Save")}
				</Button>
			</Card.Content>
		</Card>
	);
}
