import React from "react";
import get from "lodash/get";

const StudioAppIcon = ({ studioStatus, studio }) => {
	const studioVersion = get(studioStatus, studio + ".version");
	const studioApp = studioVersion && (studioVersion.substr(0, 1) === "0" ? "legacy" : "studio");

	return studioApp ? (
		<img
			src={"/img/" + (studioApp === "studio" ? "astrid-icon.svg" : "teamviewer.png")}
			alt={studioApp === "studio" ? "Astrid Studio" : "Astrid Legacy + Teamviewer"}
			style={{ width: 16, verticalAlign: "middle" }}
		/>
	) : null;
};
export default StudioAppIcon;
