export default function Flex({
	width = "100%",
	justifyContent = "space-between",
	alignItems = "center",
	gap = "normal",
	style,
	children,
	...props
}) {
	return (
		<div style={{ width, display: "flex", justifyContent, alignItems, gap, ...style }} {...props}>
			{children}
		</div>
	);
}
