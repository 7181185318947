import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import useOrganizationsQuery from "./useOrganizationsQuery";

const empty = [];

export default function useOrganizationsOnce(options) {
	const [data = empty] = useCollectionDataOnce(useOrganizationsQuery(options));

	return data;
}
