import React from "react";
import { useTranslation } from "react-i18next";
import { List, Segment } from "semantic-ui-react";

export default function MetaDataSegment({ hit }) {
	const { t } = useTranslation();
	const notSpecified = t("notSpecified", "Not specified");
	return (
		<Segment style={{ width: "33%" }}>
			<List>
				<List.Item>
					<p>
						<b>{t("originalTitle", "Original title")}</b>:&nbsp;
						<i>{hit.originalTitle || notSpecified}</i>
					</p>
				</List.Item>
				<List.Item>
					<p>
						<b>{t("series", "Series")}</b>:&nbsp;
						<i>{hit.series || notSpecified}</i>
					</p>
				</List.Item>
				<List.Item>
					<p>
						<b>{t("team", "Team")}</b>:&nbsp;
						<i>{hit.team?.join(",") || notSpecified}</i>
					</p>
				</List.Item>
				<List.Item>
					<p>
						<b>{t("partIndex", "Part index")}</b>:&nbsp;
						<i>{hit.partIndex || notSpecified}</i>
					</p>
				</List.Item>
			</List>
			{hit.productions?.map((prod, index) => {
				return (
					<List key={`${index}-${prod.productionId}`}>
						<List.Item>
							<p>
								<b>{t("productionId", "Production id")}</b>:&nbsp;
								<i>{prod.productionId || notSpecified}</i>
							</p>
						</List.Item>
						<List.Item>
							<p>
								<b>{t("status", "status")}</b>:&nbsp;
								<i>{prod.status || notSpecified}</i>
							</p>
						</List.Item>
					</List>
				);
			})}
		</Segment>
	);
}
