export default function getExportOptions(article, format, duration) {
	return {
		format: {
			label: "Ljudformat",
			placeholder: "MP3, wav, aac",
			options: [
				{ key: "mp3", value: "mp3", text: "MP3" },
				{ key: "aac", value: "aac", text: "MP4/AAC" },
				{ key: "wav", value: "wav", text: "WAV" },
			],
			disabled: ["mp3cd", "cd"].includes(article),
			default: article === "mp3cd" ? "mp3" : "wav",
		},
		bitrate: {
			label: "Ljudkvalitet",
			placeholder: "Önskad bitrate",
			options: [
				{
					key: "auto650",
					value: "auto650",
					text: "Auto (650mb-cd)",
					hidden: article !== "mp3cd",
				},
				{
					key: "auto700",
					value: "auto700",
					text: "Auto (700mb-cd)",
					hidden: article !== "mp3cd",
				},
				{ key: 48, value: 48, text: "48 kbps" },
				{ key: 64, value: 64, text: "64 kbps" },
				{ key: 96, value: 96, text: "96 kbps" },
				{ key: 128, value: 128, text: "128 kbps" },
				{ key: 192, value: 192, text: "192 kbps" },
				{ key: 256, value: 256, text: "256 kbps" },
				{ key: 320, value: 320, text: "320 kbps" },
			],
			disabled: format === "wav",
			default: article === "mp3cd" ? "auto700" : 192,
		},
		part: {
			label: "Uppdelning",
			placeholder: "Separata ljudfiler?",
			options: [
				{
					key: "total",
					value: "total",
					text: "Total längd i en fil",
					hidden: ["mp3cd", "cd"].includes(article),
				},
				{
					key: "parts",
					value: "parts",
					text: "Enligt uppladdade filer",
					hidden: ["mp3cd", "cd"].includes(article),
				},
				{
					key: "split",
					value: "split",
					text: "Ca. " + (duration ? Math.round(duration / 60) : "5-10") + " min delar",
				},
				{ key: "chapter", value: "chapter", text: "Kapitelpunkter" },
			],
			default: "chapter",
		},
	};
}
