import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Header, Icon, Table } from "semantic-ui-react";

import { db, firebase, getProductionPermissions, hasRight } from "astrid-firebase";
import arrayChunk from "astrid-helpers/src/arrayChunk";

import { withStore } from "../../helpers/context";
import { prodStatus } from "../../helpers/lists";
import withUserProfile from "../../hocs/withUserProfile";

import ProgressEdit from "../production/ProgressEdit";
import ProgressRecording from "../production/ProgressRecording";
import StarToggle from "../production/StarToggle";

class HomeStarred extends Component {
	state = { prods: [] };

	componentDidMount() {
		const { starredProductions } = this.props.profile;

		// MEMO: move to generic function? maybe make realtime? custom hook?
		Promise.all(
			arrayChunk(starredProductions, 10).map((chunk) =>
				db.collection("productions").where(firebase.firestore.FieldPath.documentId(), "in", chunk).get(),
			),
		).then((snaps) => {
			const prods = [];
			snaps.forEach((snap) => {
				snap.docs.forEach((doc) => {
					prods.push({ id: doc.id, ...doc.data() });
				});
			});

			this.setState({ prods });
		});
	}

	render() {
		const { t, store, profile, user } = this.props;
		const { prods } = this.state;
		const starredProductions = profile.starredProductions || [];

		let productions = Object.values(cloneDeep(prods));

		productions.forEach((prod) => {
			// call the status "booked" until first session is logged
			if (
				prod.status === "production" &&
				!prod.stats &&
				!prod.proofParts &&
				prod.productionType !== "subcontract"
			)
				prod.status = "booked";

			// call the status "mastring" when polish is done
			if (prod.status === "production" && prod.polishDone && prod.productionType !== "subcontract")
				prod.status = "mastering";
		});

		return (
			<>
				<Header as="h4" icon="star" content={t("favoriteProds")} />
				<Table compact basic="very" sortable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("titleFavorite")}</Table.HeaderCell>
							<Table.HeaderCell colSpan={2}>{t("statusFavorite")}</Table.HeaderCell>
							{hasRight(store, "listProducerProductions") && (
								<Table.HeaderCell>{t("prodDateFavorite")}</Table.HeaderCell>
							)}
							{hasRight(store, "createProduction") && (
								<Table.HeaderCell>
									{hasRight(store, "listProducerProductions")
										? t("deliveryShort")
										: t("deliveryFull")}
								</Table.HeaderCell>
							)}
							{/* <Table.HeaderCell>
									Lev{hasRight(store, "listProducerProductions") ? "." : "erans"}datum
								</Table.HeaderCell>
							)} */}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{productions
							.filter((prod) => starredProductions.includes(prod.id) && prod.status)
							.map((prod) => {
								const date = prod.productionDate || prod.deliveryDate;
								const productionPermissions = getProductionPermissions(store, prod);
								const { isPublisher, isReader } = productionPermissions;

								return (
									<Table.Row key={prod.id}>
										<Table.Cell selectable>
											<Link to={"/production/" + prod.id}>
												<StarToggle production={prod.id} profile={profile} uid={user.uid} />
												{prod.title}
											</Link>
										</Table.Cell>
										<Table.Cell collapsing>
											<Icon
												name={prodStatus[prod.status].icon}
												color={prodStatus[prod.status].color}
											/>
											{/* {prodStatus[prod.status].text} */}
											{t("productions:" + prod.status)}
										</Table.Cell>
										<Table.Cell style={{ width: "35%" }}>
											{prod.stats && <ProgressRecording size="small" production={prod} />}
											{!isReader && !isPublisher && (
												<ProgressEdit size="small" production={prod} />
											)}
										</Table.Cell>
										{hasRight(store, "listProducerProductions") && (
											<Table.Cell collapsing textAlign="right">
												{date &&
													(prod.productionDate
														? moment(date.toDate()).format("YYYY-MM-DD")
														: "")}
											</Table.Cell>
										)}
										{hasRight(store, "createProduction") && (
											<Table.Cell collapsing textAlign="right">
												{prod.deliveryDate &&
													moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
											</Table.Cell>
										)}
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</>
		);
	}
}

export default withTranslation()(withStore(withUserProfile(HomeStarred)));
