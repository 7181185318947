import React from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../ui/components/Select/Select";

import usePriceUnitOptions from "../../hooks/usePriceUnitOptions";

export default function PriceUnitSelect(props) {
	const { t } = useTranslation();

	const priceUnitOptions = usePriceUnitOptions("user");

	return <Select search fluid label={t("unit")} options={priceUnitOptions} {...props} />;
}
