import { useParams } from "react-router-dom";

import { useProfile } from "../../../../authentication/state/profile";
import { useOrganizationType } from "../../../../organizations/state/organizationType";

export default function useUserLanguages() {
	const profile = useProfile();
	const organizationType = useOrganizationType();
	const { orgId } = useParams();
	return profile?.[`${organizationType}s`]?.[orgId]?.languageOptions;
}
