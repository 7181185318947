import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import AsyncButton from "../../../ui/components/AsyncButton/AsyncButton";
import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import useFileInput from "../../../ui/hooks/useFileInput";

export default function FileUploadTable({ onChange }) {
	const onChangeRef = useRef(onChange);

	const { t } = useTranslation();

	const { files, input, onClick } = useFileInput({
		multiple: true,
	});

	const columns = useMemo(
		() => [
			{
				id: "name",
			},
		],
		[],
	);

	useEffect(() => {
		if (files.length > 0) {
			onChangeRef.current?.(files);

			return () => onChangeRef.current?.([]);
		}
	}, [files]);

	return (
		<>
			<FlexTable
				data={files}
				columns={columns}
				getRowKey={(row) => row.name}
				footer={<AsyncButton onClick={onClick}>{t("chooseFiles")}</AsyncButton>}
			/>

			{input}
		</>
	);
}
