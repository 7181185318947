import { useTranslation } from "react-i18next";
import { Message, Comment as SemanticComment } from "semantic-ui-react";

import { db, firebase } from "astrid-firebase";
import createComment from "astrid-firestore/src/api/discussion/createComment";
import { useCollectionData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import useSortBy from "../../../ui/hooks/useSortBy";

import { useProfile } from "../../../authentication/state/profile";

import Comment from "../Comment/Comment";

import DiscussionForm from "./components/DiscussionForm";

export default function Discussion({
	channel,
	initialParticipants = [],
	notificationRef,
	onLoaded,
	onCommentCreated,
	disabled,
	visibleTo = [],
}) {
	const { t } = useTranslation();
	const user = useProfile();

	const [comments, loading, error] = useCollectionData(
		db.collection("comments").where("channels", "array-contains", channel).where("deleted", "==", false),
	);

	const sortedComments = useSortBy(comments, "created.seconds", "asc");

	const filteredComments = sortedComments?.filter((comment) => !comment.replyTo);

	const onComment = async ({ text, replyTo }) => {
		const comment = await createComment(firebase, { user, text, channels: [channel], notificationRef, replyTo });

		const participants = new Set(
			[...initialParticipants, ...comments.map(({ user }) => user.id)].filter((id) => id !== user.id),
		);

		onCommentCreated?.(comment, participants);
	};

	return (
		<LoadingContext onLoaded={onLoaded} loading={loading} error={error}>
			{visibleTo.length > 0 && (
				<i style={{ color: "#bbb" }}>
					{t("discussionVisibleTo", "This discussion is visible to", {
						organization: visibleTo.join(" & "),
					})}
				</i>
			)}

			<SemanticComment.Group>
				{filteredComments?.map((comment) => (
					<Comment
						disabled={disabled}
						sortedComments={sortedComments}
						onComment={onComment}
						key={comment?.id}
						comment={comment}
					/>
				))}
				{!disabled && (
					<DiscussionForm onComment={onComment} channel={channel} notificationRef={notificationRef} />
				)}
				{disabled && filteredComments.length === 0 && (
					<Message>{t("noDiscussionAvailable", "No discussion available")}</Message>
				)}
			</SemanticComment.Group>
		</LoadingContext>
	);
}
