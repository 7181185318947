import { useTranslation } from "react-i18next";

import { useProfile } from "../../authentication/state/profile";

import AccountCostsView from "../views/AccountCostsView/AccountCostsView";

export default function ListAccountRevenues() {
	const { t } = useTranslation();
	const profile = useProfile();

	return <AccountCostsView header={t("revenues", "Revenues")} secondPartyId={profile?.id} />;
}
