import { useState, useEffect } from "react";

export default function useAsync(fn, initialState) {
	const [value, setValue] = useState(initialState);

	useEffect(() => {
		if (fn) {
			let mounted = true;

			(async () => {
				const value = await fn();

				if (mounted) {
					setValue(value);
				}
			})();

			return () => {
				mounted = false;
			};
		}
	}, [fn]);

	return value;
}
