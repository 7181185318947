import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import useUpcomingDeliveries from "./hooks/useUpcomingDeliveries";
import useUpcomingDeliveriesColumns from "./hooks/useUpcomingDeliveriesColumns";

export default function UpcomingDeliveriesWidget({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useUpcomingDeliveriesColumns();

	const [data, loading, error] = useUpcomingDeliveries({ organization });

	return (
		<HomeLayout.Widget icon="sync" color="red" header={t("commingDeliveries", "Upcoming deliveries")}>
			<DataTable
				// sortable={false}
				data={data}
				error={error}
				columns={columns}
				loading={loading}
				onClick={({ production }) => navigate(`/production/${production.id}`)}
				noDataAvailableMessage={t("noUpcomingDeliveries", "You have no upcoming deliveries")}
			/>
		</HomeLayout.Widget>
	);
}
