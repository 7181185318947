export default function msToTime(ms, showMs = true, showSecs = true) {
	let milliseconds = parseInt((ms % 1000) / 100),
		seconds = parseInt((ms / 1000) % 60),
		minutes = parseInt((ms / (1000 * 60)) % 60),
		hours = parseInt(ms / (1000 * 60 * 60));

	hours = hours < 10 ? "0" + hours : hours;
	minutes = minutes < 10 ? "0" + minutes : minutes;
	seconds = seconds < 10 ? "0" + seconds : seconds;

	return `${hours}:${minutes}${showSecs ? `:${seconds}` : ""}${showMs ? `.${milliseconds}` : ""}`;
}
