import { Card } from "semantic-ui-react";

const disabledStyles = {
	opacity: 0.2,
	pointerEvents: "none",
};

export default function ContentCard({ header, description, disabled, children }) {
	return (
		<Card fluid style={disabled ? disabledStyles : undefined}>
			{(header || description) && (
				<Card.Content>
					{header && <Card.Header>{header}</Card.Header>}
					{description && <Card.Description>{description}</Card.Description>}
				</Card.Content>
			)}

			<Card.Content>{children}</Card.Content>
		</Card>
	);
}
