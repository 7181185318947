import { db } from "astrid-firebase";
import runBatch from "astrid-firestore/src/helpers/runBatch";

import getExpense from "./getExpense";
import getRevenue from "./getRevenue";

export default async function submitProductionPrices({ firstParty, production, expenseData, revenueData }) {
	await runBatch(db, (batch) => {
		if (expenseData) {
			const expense = getExpense({ firstParty, production, ...expenseData });
			batch.set(expense.ref, expense);
		}

		if (revenueData) {
			const revenue = getRevenue({ production, ...revenueData });
			batch.set(revenue.ref, revenue);
		}
	});

	return true;
}
