import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

import { deleteDocument } from "astrid-firestore/src/helpers";

export default function OnDeleteModal({ document, setOpenDeleteModal }) {
	const { t } = useTranslation();
	const onSave = async () => {
		await deleteDocument(document.ref);
	};

	return (
		<Modal open size="mini">
			<Modal.Header>{t("areYouSure", "Are you sure?")}</Modal.Header>
			<Modal.Actions>
				<Button default onClick={() => setOpenDeleteModal(false)}>
					{t("cancel")}
				</Button>
				<Button primary onClick={onSave}>
					{t("save")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
