import { db } from "astrid-firebase";

export default function useNotificationsQuery({ userId, organizationId }) {
	const query = db.collection("todos").where("notification", "==", true).orderBy("createdAt", "desc");

	if (organizationId) {
		return query.where("organizationIds", "array-contains", organizationId);
	}

	return query.where("userIds", "array-contains", userId);
}
