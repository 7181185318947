import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useProductionPricesQuery({ deleted = false } = {}) {
	return useMemo(
		() =>
			createQuery(db.collectionGroup("productionPrices"), {
				deleted,
			}),
		[deleted],
	);
}
