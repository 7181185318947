import React, { useContext } from "react";
import { Button, Card } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import editSession from "astrid-firestore/src/api/sessions/editSession";

import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";

import notifyEditSession from "../../../helpers/notifyEditSession";
import tsToLocaleTimeString from "../../../helpers/tsToLocalTimeString";

import SessionBaseForm from "../../SessionBaseForm";
import SessionContext from "../../SessionContext";

import CancelSession from "../SessionActions/CancelSession";

function EditForm() {
	const {
		t,
		session,
		production,
		sessionRoles,
		onClose,
		showOnClose,
		showCancel,
		addAffectedStaff,
		addNotification,
	} = useContext(SessionContext);
	const disable = sessionRoles.isRecorder && !sessionRoles.isAdminOrProducerStaff;

	const form = useForm({
		mode: "onSubmit",
		defaultValues: {
			date: session.start?.toDate(),
			start: tsToLocaleTimeString(session.start),
			end: tsToLocaleTimeString(session.end),
			reader: session.reader,
			recorder: session.recorder,
			recorderName: session.recorderData?.name,
			priceId: session.priceId,
			priceRef: session.priceRef,
			agreementRef: session.agreementRef,
			studio: session.studio,
			polish: session.polish || false,
			production: production?.id || null,
		},
		values: {
			date: session.start?.toDate(),
			start: tsToLocaleTimeString(session.start),
			end: tsToLocaleTimeString(session.end),
			reader: session.reader,
			recorder: session.recorder,
			recorderName: session.recorderData?.name,
			priceId: session.priceId,
			priceRef: session.priceRef,
			agreementRef: session.agreementRef,
			studio: session.studio,
			polish: session.polish || false,
			production: production?.id || null,
		},
	});

	const {
		handleSubmit,
		formState: { dirtyFields, isSubmitting },
	} = form;

	const submit = handleSubmit((formData) => {
		const [startHours, startMinutes] = formData.start.split(":").map(Number);
		const [endHours, endMinutes] = formData.end.split(":").map(Number);

		const start = new Date(formData.date);
		start.setHours(startHours);
		start.setMinutes(startMinutes);
		const end = new Date(formData.date);
		end.setHours(endHours);
		end.setMinutes(endMinutes);

		const sessionUpdate = {
			date: formData.date,
			start,
			end,
			reader: formData.reader,
			studio: formData.studio,
			polish: formData.polish,
			recorder: formData.recorder,
			recorderData: {
				name: formData.recorderName || null,
			},
		};

		const priceUpdate = (dirtyFields.priceId || dirtyFields.recorder) && {
			priceId: formData.priceId,
			priceRef: formData.priceRef,
			agreementRef: formData.agreementRef,
		};

		return editSession(firebase, { session, production, sessionUpdate, priceUpdate }).then(() => {
			notifyEditSession({ session, sessionUpdate, addAffectedStaff, addNotification });
		});
	});

	return (
		<Card fluid style={{ padding: 5 }}>
			<Card.Content>
				<Card.Header>{t("editRecSession")}</Card.Header>
				<Form form={form}>
					<Form.Group widths="equal">
						<Form.DateInput name="date" inputProps={{ disabled: disable }} label={t("date")} />
						<Form.Input name="start" disabled={disable} label={t("start")} type="time" />
						<Form.Input name="end" label={t("end")} type="time" />
					</Form.Group>
					<SessionBaseForm disable={disable} />
				</Form>
			</Card.Content>
			<Card.Content extra>
				<div style={showCancel && showOnClose && { display: "flex", justifyContent: "space-between" }}>
					{showOnClose && (
						<Button color="black" onClick={onClose}>
							{t("close", "Close")}
						</Button>
					)}
					<div>
						{showCancel && <CancelSession />}
						<Button
							style={{ marginLeft: 20 }}
							disabled={!Object.keys(dirtyFields)?.length}
							loading={isSubmitting}
							floated="right"
							color="green"
							onClick={submit}
						>
							{t("saveChanges", "Save changes")}
						</Button>
					</div>
				</div>
			</Card.Content>
		</Card>
	);
}

export default function EditSession() {
	const { sessionRoles, session, profile } = useContext(SessionContext);

	if (!sessionRoles.isAdminOrProducerStaff && !sessionRoles.isRecorder) {
		return null;
	}

	if (!sessionRoles.isAdminOrProducerStaff && sessionRoles.isRecorder && session.recorder !== profile.id) {
		return null;
	}

	return <EditForm />;
}
