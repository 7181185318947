import { useTranslation } from "react-i18next";

export default function useArticleVersionsDropdownMenuOptions({ article }) {
	const { t } = useTranslation();

	return (version) => {
		return [
			{
				text: t("revertToThisVersion", "Revert to this version"),
				disabled: article?.version?.id === version.id,
			},
		];
	};
}
