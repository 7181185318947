import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";
import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateTitleButton from "../../../titles/components/CreateTitleForm/CreateTitleButton";
import useTitlesTableColumns from "../../../titles/hooks/useTitlesTableColumns";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminTitlesView({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useTitlesTableColumns();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("titles", "Titles")}>
				{searchInput}
				<CreateTitleButton organization={organization} />
			</AdminHeader>

			<InstantSearchDataTable
				indexName="titles"
				searchQuery={searchQuery}
				columns={columns}
				configure={{
					filters: `publisher.id:${organization.id}`,
				}}
				onClick={({ id }) => navigate(id)}
			/>
		</>
	);
}
