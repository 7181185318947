import firebase from "firebase/app";
import { useMemo } from "react";

import db from "astrid-firebase/src/db";

export default function useOrganizationsQuery({ ids, type, admin, producer, publisher } = {}) {
	return useMemo(() => {
		let query = db.collection("organizations");

		if (type) {
			query = query.where("type", "==", type);
		}

		if (admin) {
			query = query.where("adminUserIds", "array-contains", admin);
		}

		if (producer) {
			query = query.where("producerIds", "array-contains", producer);
		}

		if (publisher) {
			query = query.where("publishers", "array-contains", publisher);
		}

		if (ids) {
			if (ids.length) {
				query = query.where(firebase.firestore.FieldPath.documentId(), "in", ids);
			} else {
				return false;
			}
		}

		return query;
	}, [admin, ids, producer, publisher, type]);
}
