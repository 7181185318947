import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import Flex from "../../../../components/Flex/Flex";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import AdminPageDropdownMenu from "./AdminPageDropdownMenu";
import AdminPageMenu from "./AdminPageMenu";

export default function AdminPageHeader({ error, loading, menuOptions, dropdownMenuOptions, children }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Flex gap={15}>
					<Flex justifyContent="flex-start" gap={10}>
						{children}
					</Flex>

					{dropdownMenuOptions && <AdminPageDropdownMenu loading={loading} options={dropdownMenuOptions} />}
				</Flex>

				{error && <ErrorMessage error={error} />}
			</Card.Content>

			{menuOptions && (
				<Card.Content>
					<AdminPageMenu options={menuOptions} />
				</Card.Content>
			)}
		</Card>
	);
}
