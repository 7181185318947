import { parseToISODate } from "../../../helpers/date";

export default function mapScriptDate(production) {
	const isUploaded = production.script && production.scriptUploadDate;

	return {
		isUploaded,
		date: isUploaded ? parseToISODate(production.scriptUploadDate) : parseToISODate(production.scriptDate),
	};
}
