import createStore from "astrid-helpers/src/createStore";

import { removeItem } from "../../../utils/localStorage";

export const [getOrganizationId, setOrganizationId, useOrganizationId, onOrganizationId, resetOrganizationId] =
	createStore();

onOrganizationId((orgId, previousOrgId) => {
	if (previousOrgId) {
		removeItem("calendarFilters");
	}
});
