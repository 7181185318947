import React from "react";
import { Segment } from "semantic-ui-react";

import { useMetaGuide } from "../../../../components/production/parts/MetaGuide";
import ProductionInformation from "../../../../components/production/parts/ProductionInformation";
import ProductionMeta from "../../../../components/production/parts/ProductionMeta";
import PublisherManager from "../../../../components/production/parts/PublisherManager";
import useHistory from "../../../ui/hooks/useHistory";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";

export default function ProductionOverviewView({ publisher, production, permissions, handleChange }) {
	const profile = useProfile();
	const history = useHistory();
	const hasRight = useHasRight();

	const checkMissing = useMetaGuide((state) => state.checkMissing);

	return (
		<Segment basic padded>
			<ProductionInformation
				production={production}
				permissions={permissions}
				checkMissing={() => checkMissing({ history })}
			/>

			<PublisherManager production={production} profile={profile} />

			{hasRight("productions.manage") && (
				<ProductionMeta
					publisher={publisher}
					production={production}
					permissions={permissions}
					handleChange={handleChange}
					productionFields={["publisher", "meta"]}
				/>
			)}
		</Segment>
	);
}
