import firebase from "firebase/app";
import "firebase/auth";

import { getDocumentData } from "astrid-firestore/src/helpers";
import nameCase from "astrid-helpers/src/nameCase";
import getDummyEmail from "astrid-web/src/features/users/components/AddUser/utils/getDummyEmail";

import { config } from "./app";
import db from "./db";

export async function addUser({ email: emailProp, firstName, lastName, language, reader, organization }) {
	const email = emailProp || getDummyEmail({ firstName, lastName });

	if (!window.earselectUserAdderApp) {
		window.earselectUserAdderApp = firebase.initializeApp(config, "UserAdderApp");
	}

	const { user } = await window.earselectUserAdderApp
		.auth()
		.createUserWithEmailAndPassword(email, "pw" + Math.round(+new Date() * Math.random()));

	const userRef = db.collection("users").doc(user.uid);

	await userRef.set({
		email,
		firstName: nameCase(firstName),
		lastName: nameCase(lastName),
		readerData: { searchable: true },
		languages: language ? { [language]: { skill: 5, dialect: "" } } : {},
		organizationIds: organization ? [organization.id] : [],
		permissions: {
			role: "user",
			reader: false,
			producerIndex: false,
			publisherIndex: false,
			...(reader
				? {
						reader: ["any", "allPublishers", "allProducers"],
				  }
				: organization
				? { [`${organization.type}Index`]: true, [organization.type]: { [organization.id]: ["any"] } }
				: {}),
		},
	});

	// send password reset email if email was provided
	if (email === emailProp) {
		await window.earselectUserAdderApp.auth().sendPasswordResetEmail(email, {
			url: "https://astrid.fm/login/email?email=" + email,
		});
	}

	return await getDocumentData(userRef);
}
