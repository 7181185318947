import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import canTerminate from "astrid-firestore/src/api/agreements/canTerminate";
import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";
import isTerminated from "astrid-firestore/src/api/agreements/isTerminated";
import notifyAgreementTermination from "astrid-firestore/src/api/agreements/notifyAgreementTermination";
import terminateAgreement from "astrid-firestore/src/api/agreements/terminateAgreement";

import Flex from "../../../../components/Flex/Flex";
import ConfirmationButton from "../../../ui/components/ConfirmationButton/ConfirmationButton";

import { getProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";

import useAgreementStatusTranslations from "../../hooks/useAgreementStatusTranslations";

export default function TerminationAction({ agreement }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const agreementStatusTranslation = useAgreementStatusTranslations();

	return (
		<Card.Content extra>
			{hasRight("agreements.terminate") && !isTerminated(agreement) && (
				<>
					<ConfirmationButton
						text={t(
							"terminateAgreement",
							"Are you sure you want to terminate this agreement? Upon termination an email confirmation will be sent to both parties.",
						)}
						onConfirm={async () => {
							await terminateAgreement({
								agreement,
								user: getProfile(),
							});
							notifyAgreementTermination(agreement.ref, t);
						}}
						disabled={!canTerminate(agreement)}
					>
						{t("terminate", "Terminate")}
					</ConfirmationButton>
				</>
			)}

			{isTerminated(agreement) && (
				<Card.Description>
					<Flex style={{ minHeight: 36 }}>
						{agreementStatusTranslation[agreementStatuses.TERMINATED]}

						{agreement.termination?.user &&
							` - ${agreement.termination?.user.name}, ${agreement.termination.timestamp
								.toDate()
								.toLocaleString()}`}
					</Flex>
				</Card.Description>
			)}
		</Card.Content>
	);
}
