import useForm from "../../../../forms/hooks/useForm";

export default function useProductionOfferForm() {
	return useForm({
		defaultValues: {
			productionType: "default",
			productionPriceId: null,
			otherPriceIds: [],
		},
	});
}
