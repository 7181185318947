import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

import ViewAgreementDropdownItem from "../../../../agreements/components/AgreementDropdownMenu/items/ViewAgreementDropdownItem";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

export default function AdminPublisherPriceDropdownMenu({ price, publisher, organization }) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			{({ setLoading }) => (
				<>
					<ViewAgreementDropdownItem agreement={price.agreement} />

					<Dropdown.Item
						text={t("remove", "Remove")}
						onClick={async () => {
							setLoading(true);
							await updateDocument(publisher.ref, {
								[`prices.${organization.id}.${price.type}`]: firebase.firestore.FieldValue.delete(),
							});
							setLoading(false);
						}}
					/>
				</>
			)}
		</DropdownMenu>
	);
}
