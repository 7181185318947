import intersection from "lodash/intersection";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { base } from "astrid-firebase";

import StickyAudio from "../components/StickyAudio";
import ProductionWordContribute from "../components/production/ProductionWordContribute";

class Words extends Component {
	state = {
		loading: true,
		email: this.props.match.params.email,
		words: [],
		includeSamples: false,
		addNew: false,
		newWord: "",
		newLanguage: "sv",
		savedWords: [],
		ids: [],
	};

	// store algoritmically chosen contribution
	unchosenContribution = {};

	UNSAFE_componentWillMount() {
		// show existing samples?
		const params = new URLSearchParams(window.location.search);
		if (params.get("s") !== null) {
			this.setState({ includeSamples: true });
		}
		if (params.get("a") !== null) {
			this.setState({ addNew: true, productionLanguage: params.get("a") });
		}

		base.bindCollection("words", {
			context: this,
			state: "words",
			withIds: true,
			query: (ref) => ref.where("productions." + this.props.match.params.productionId + ".index", "==", true),
			then() {
				this.setState({ loading: false });
			},
			onFailure(err) {
				console.log("Words get err", err);
			},
		});
	}

	addWord = () => {
		const { t } = this.props;
		if (this.state.words.find((word) => word.word === this.state.newWord)) {
			window.alert(t("theWord") + " " + this.state.newWord + " " + t("isAlreadyAdded"));
			return;
		}

		base.addToCollection("words", {
			word: this.state.newWord,
			productions: {
				[this.props.match.params.productionId]: {
					index: true, // for firestore indexability
					productionLanguage: this.state.productionLanguage,
					wordLanguage: this.state.newLanguage,
					source: "external",
				},
			},
		}).then((word) => {
			this.setState({ newWord: "", ids: [...this.state.ids, word.id] });
		});
	};

	render() {
		const {
			loading,
			email,
			validEmail,
			words,
			includeSamples,
			addNew,
			newWord,
			newLanguage,
			playingFile,
			savedWords,
			ids,
		} = this.state;

		const { t } = this.props;
		const productionId = this.props.match.params.productionId;
		const emailNotDone = validEmail === false || (validEmail === true && !email);

		return loading ? (
			<h1 style={{ textAlign: "center" }}>{t("isLoading")}</h1>
		) : (
			<>
				<Grid textAlign="center">
					<Grid.Column style={{ marginTop: "3%", maxWidth: 420 }}>
						{!this.props.user && (
							<div style={{ marginBottom: "2em", paddingBottom: "2em", borderBottom: "2px solid #eee" }}>
								<Header as="h2" textAlign="center">
									{this.props.match.params.email ? t("yourEmail") : t("enterEmail")}
								</Header>
								<Form>
									<Form.Input
										type="email"
										fluid
										autoFocus={!this.props.match.params.email}
										onBlur={(e) => {
											this.setState({ validEmail: e.target.checkValidity() });
										}}
										defaultValue={this.props.match.params.email}
										onChange={(e) => {
											this.setState({ email: e.target.value });
											if (validEmail === false)
												this.setState({ validEmail: e.target.checkValidity() });
										}}
										error={emailNotDone}
									/>
									{emailNotDone && <em>{t("enterValidEmail")}</em>}
								</Form>
							</div>
						)}

						<Header as="h2" textAlign="center">
							{t("addPronounciation")}
						</Header>
						<Segment.Group
							padded={window.innerWidth > 600 ? "very" : null}
							style={{
								textAlign: "left",
								overflow: "hidden",
								opacity: !this.props.user && emailNotDone ? "0.5" : 1,
							}}
						>
							{words
								.sort((a, b) => (a.word > b.word ? 1 : -1))
								.map((word) => (
									<Segment key={word.id}>
										<h2 style={{ float: "left" }}>{word.word}</h2>
										<div style={{ float: "right" }}>
											<strong>{t("language") + ":"} </strong>
											{(() => {
												const lang = word.productions[productionId].wordLanguage;

												return languages[lang].sv || languages[lang].name;
											})()}
										</div>

										{includeSamples &&
											!savedWords.includes(word.word) &&
											word.contributions &&
											Object.keys(word.contributions).find((id) => word.contributions[id].file) &&
											(() => {
												let file;
												if (
													word.choice &&
													word.choice[productionId] &&
													word.contributions[word.choice[productionId]]
												) {
													file = word.contributions[word.choice[productionId]].file;
												} else {
													// calculate unchosen word choice
													const keys = Object.keys(word.contributions);

													const winners = keys
														.map((key) => {
															const cont = word.contributions[key];
															const prod = word.productions[cont.production];
															const thisProd = word.productions[productionId];
															let points = 0;

															if (thisProd && prod) {
																if (
																	thisProd.productionLanguage ===
																	prod.productionLanguage
																)
																	points += 2;
																if (thisProd.wordLanguage === prod.wordLanguage)
																	points += 2;
																if (intersection(thisProd.author, prod.author).length)
																	points += 3;
															}

															return {
																key,
																...cont,
																points,
															};
														})
														.sort((a, b) => (a.points < b.points ? 1 : -1));

													const winner = winners.find((pointedCont) => pointedCont.file);

													file = winner.file;
													this.unchosenContribution[word.id] = winner.key;
												}

												return (
													<div style={{ clear: "both", marginBottom: "1em" }}>
														{playingFile !== file ? (
															<Button
																basic
																color="teal"
																icon="play"
																content={t("existingSample")}
																disabled={!!this.state.recordingWord}
																onClick={(e) => {
																	e.preventDefault();
																	e.stopPropagation();

																	this.setState({ playingFile: file });
																}}
															/>
														) : (
															<Button
																basic
																color="teal"
																icon="square"
																content={t("existingSample")}
																onClick={(e) => {
																	e.preventDefault();
																	e.stopPropagation();
																	this.setState({ playingFile: null });
																}}
															/>
														)}
													</div>
												);
											})()}

										<ProductionWordContribute
											t={t}
											ids={ids}
											word={word}
											productionId={productionId}
											validUser={this.props.user || !emailNotDone}
											uid={this.props.user && this.props.user.uid}
											email={email || (this.props.user && this.props.user.email)}
											onSave={() => {
												this.setState({ savedWords: [...savedWords, word.word] });
											}}
										/>
									</Segment>
								))}
						</Segment.Group>

						{addNew && (
							<>
								<Header as="h2" textAlign="center">
									{t("addNewWord")}
								</Header>
								<Segment style={{ textAlign: "left" }}>
									<Form size="small" as="div">
										<Form.Input
											label={t("word")}
											value={newWord}
											onChange={(e, data) => {
												this.setState({ newWord: data.value });
											}}
										/>
										<Form.Group widths="equal">
											<Form.Select
												search
												deburr
												label={t("languageForm")}
												value={newLanguage}
												options={Object.keys(languages).map((code) => ({
													key: code,
													value: code,
													text:
														(languages[code].sv || languages[code].name) +
														" (" +
														languages[code].nativeName +
														")",
												}))}
												onChange={(e, data) => {
													this.setState({ newLanguage: data.value });
												}}
											/>
											<Form.Button
												icon="plus"
												color="teal"
												content={t("add")}
												style={{ marginTop: "1.6em" }}
												onClick={this.addWord}
												disabled={!newWord || !newLanguage}
											/>
										</Form.Group>
									</Form>
								</Segment>
							</>
						)}
						<h1 style={{ textAlign: "center" }}>{t("thanksForHelp")}</h1>
					</Grid.Column>
				</Grid>

				{playingFile && (
					<StickyAudio
						file={playingFile}
						onClose={() => {
							this.setState({ playingFile: null });
						}}
					/>
				)}
			</>
		);
	}
}

export default withTranslation()(Words);
