import { useMemo } from "react";

import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import useUsersQuery from "./useUsersQuery";

export default function useUserOptions(props) {
	const [users, loading, error] = useCollectionDataOnce(useUsersQuery(props));

	const options = useMemo(
		() =>
			users?.map((user) => ({
				key: user.id,
				value: user.id,
				data: user,
				text: `${user.firstName} ${user.lastName} | ${user.email}`,
			})) || [],
		[users],
	);

	return [options, loading, error];
}
