import { Button } from "semantic-ui-react";

import CreateOrganizationAction from "./components/CreateOrganizationAction";

export default function OrganizationsActions(props) {
	return (
		<Button.Group basic secondary>
			<CreateOrganizationAction {...props} />
		</Button.Group>
	);
}
