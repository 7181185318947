import runTransaction from "astrid-firestore/src/helpers/runTransaction";

import { teamRoles } from "../../../constants/teamRoles";
import { getPriceRef } from "../../../utils/referenceMappers";

import offerStatuses from "../../offers/constants/offerStatuses";
import getOffersToCancel from "../../offers/utils/getOffersToCancel";

export default async function addTeamMember(firebase, { member, offer, production, productionPrice }) {
	const db = firebase.firestore();

	// Get all offers to cancel
	const offersToCancel = await getOffersToCancel(db, offer);

	await runTransaction(db, [production.ref], (transaction, [production]) => {
		// Update the offer status to accepted
		if (offer?.ref) {
			transaction.update(offer.ref, {
				voiceId: offer.voiceId || null,
				status: offerStatuses.ACCEPTED,
			});
		}

		// If the offer has a price, create a new price document
		if (productionPrice?.ref) {
			transaction.set(productionPrice.ref, productionPrice);
		}

		// If the price has a reference price, increment the uses counter
		if (productionPrice?.referencePrice?.maxUses) {
			transaction.update(productionPrice.agreement.ref, {
				[`prices.${productionPrice.referencePrice.id}.uses`]: firebase.firestore.FieldValue.increment(1),
			});
		}

		// Remove exisiting production price
		if (member?.price?.ref?.path.includes("productions") && member?.role !== teamRoles.RECORDER) {
			transaction.update(member.price.ref, {
				deleted: true,
			});
		}

		// If the offer is an inquiry, we need to cancel all other inquiries
		if (offersToCancel.length > 0) {
			for (const offer of offersToCancel) {
				transaction.update(offer.ref, {
					status: offerStatuses.CANCELLED,
				});
			}
		}

		// Add the member to the production team
		transaction.update(production.ref, {
			[`team.${member.id}`]: { ...member, price: getPriceRef(productionPrice) },
		});
	});

	return member;
}
