import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import DataTable from "../../../ui/components/DataTable/DataTable";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import useMyProductionsColumns from "./hooks/useMyProductionsColumns";
import useMyProductionsQuery from "./hooks/useMyProductionsQuery";

export default function MyProductionsWidget({ organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const columns = useMyProductionsColumns();

	const [productions, loading, error] = useCollectionData(useMyProductionsQuery({ organization }));

	return (
		<HomeLayout.Widget icon="clipboard list" color="green" header={t("myProductions", "My productions")}>
			<DataTable
				// sortable={false}
				columns={columns}
				data={productions}
				loading={loading}
				error={error}
				onClick={({ id }) => navigate(`/production/${id}`)}
				noDataAvailableMessage={t("noOngoingProductions", "You are not a part of any ongoing productions")}
				sortBy={{
					id: "productionDate",
					desc: false,
				}}
			/>
		</HomeLayout.Widget>
	);
}
