import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminOrganizationsView from "../views/SuperAdminOrganizationsView/SuperAdminOrganizationsView";

export default function SuperAdminPublishers() {
	const hasRight = useHasRight();

	if (!hasRight("publishers.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminOrganizationsView type="publisher" />;
}
