import { parsePhoneNumber } from "awesome-phonenumber";
import { useTranslation } from "react-i18next";

import roles from "astrid-permissions/roles";

import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function useAdminUsersColumns({ organization }) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			Header: t("name"),
			accessor: ({ name }) => name,
			Cell: ({ value, row }) => (
				<>
					<ImageAvatar src={row.original.avatarImg} style={{ marginRight: "1em" }} />
					<span>{value}</span>
				</>
			),
		},
		{
			id: "email",
			Header: t("email"),
		},
		{
			id: "phone",
			Header: t("telephone"),
			disableSortBy: true,
			collapsing: true,
			accessor: ({ phone }) => phone && parsePhoneNumber(phone).getNumber("international"),
		},
		{
			id: "roles",
			Header: t("roles", "Roles"),
			disableSortBy: true,
			accessor: ({ permissions }) =>
				(permissions?.[organization.type]?.[organization.id] || [])
					.filter((role) => role !== "any")
					.map((role) => roles[role])
					.join(", "),
			collapsing: false,
		},
	];
}
