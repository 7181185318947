import { useTranslation } from "react-i18next";

import todoPriorities from "astrid-firestore/src/api/todos/constants/todoPriorities";

export default function useTodoPriorityTranslations() {
	const { t } = useTranslation();

	return {
		[todoPriorities.HIGH]: t("highPriority", "High priority"),
		[todoPriorities.NORMAL]: t("normalPriority", "Normal priority"),
		[todoPriorities.LOW]: t("lowPriority", "Low priority"),
	};
}
