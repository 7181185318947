import moment from "moment";

export const quarters = [
	{
		name: "Q1",
		from: (selectedYear) => moment(new Date(selectedYear, 0, 1)).startOf("day").unix(),
		to: (selectedYear) => moment(new Date(selectedYear, 2, 31)).endOf("day").unix(),
	},
	{
		name: "Q2",
		from: (selectedYear) => moment(new Date(selectedYear, 3, 1)).startOf("day").unix(),
		to: (selectedYear) => moment(new Date(selectedYear, 5, 30)).endOf("day").unix(),
	},
	{
		name: "Q3",
		from: (selectedYear) => moment(new Date(selectedYear, 6, 1)).startOf("day").unix(),
		to: (selectedYear) => moment(new Date(selectedYear, 8, 30)).endOf("day").unix(),
	},
	{
		name: "Q4",
		from: (selectedYear) => moment(new Date(selectedYear, 9, 1)).startOf("day").unix(),
		to: (selectedYear) => moment(new Date(selectedYear, 11, 31)).endOf("day").unix(),
	},
];
