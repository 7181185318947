export default {
	name: "Elisa",
	format: "mp3",
	connection: "ftp",
	meta: {
		format: "onix",
		fields: ["availability", "takedown"],
		sharedFields: ["tags", "thema"],
		requiredFields: ["priceUnitSale"],
		currency: ["EUR"],
	},
	audio: {
		rename: "ISBN_SEQ_TOTAL.SUFFIX",
	},
	cover: true,
	disableArticles: ["cd", "mp3cd"],
};
