import React from "react";
import { useTranslation } from "react-i18next";

import useDownloadURL from "astrid-firestore/src/hooks/useDownloadURL";

export default function FetchScript({ productionId, production, ...props }) {
	const { t } = useTranslation();
	const bucket = window.ES.stage ? "stage-earselect-secret" : "earselect-secret";
	const fullPath = `scripts/${productionId}/${production.title}.pdf`;
	const [url] = useDownloadURL({ bucket, fullPath });
	return (
		<a className="ui teal button" href={url} target="_blank" rel="noopener noreferrer" {...props}>
			<i aria-hidden="true" className="book icon" />
			{t("fetchScript")}
		</a>
	);
}
