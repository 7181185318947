import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { invoiceStatuses } from "astrid-firestore/src/api/invoices/invoiceStatuses";

export default function useInvoiceStatusTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[invoiceStatuses.UNPAID]: t("unpaid", "Unpaid"),
			[invoiceStatuses.PAID]: t("paid", "Paid"),
		}),
		[t],
	);
}
