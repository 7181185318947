import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import { formatPrice } from "../../../../prices/utils/priceFormatters";

import AdminPublisherPriceDropdownMenu from "../components/AdminPublisherPriceDropdownMenu";

export default function useAdminPublisherPricesColumns({ publisher, organization }) {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "price.type",
				Header: t("type", "Type"),
				accessor: ({ price }) => costTypes[price.type],
			},
			{
				id: "price",
				Header: t("price", "Price"),
				accessor: ({ price }) => price && `${price.name} (${formatPrice(price)})`,
			},
			{
				id: "agreement.name",
				Header: t("agreement", "Agreement"),
				Cell: ({ value }) => value,
			},
			{
				id: "remove",
				collapsing: true,
				Cell: ({ row }) => (
					<AdminPublisherPriceDropdownMenu
						publisher={publisher}
						organization={organization}
						price={row.original}
					/>
				),
			},
		],
		[t, costTypes, publisher, organization],
	);
}
