const taskStatuses = {
	CREATED: "created",
	IGNORED: "ignored",
	SCHEDULED: "scheduled",
	INITIALIZED: "initialized",
	CANCELLED: "cancelled",
	COMPLETED: "completed",
	FAILED: "failed",
	RETRY: "retry",
};

export default taskStatuses;
