import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

import { isAppendixesValid } from "../../../utils/agreementValidators";

import AgreementPricesFormTable from "../../AgreementPricesFormTable/AgreementPricesFormTable";

export default function AgreementPricesForm() {
	const { t } = useTranslation();
	const { watch, setValue } = Form.useFormContext();
	const isValid = Form.usePartialFormValidation(["appendixes"], isAppendixesValid);

	const [part, prices, template, signed] = watch(["part", "prices", "template", "signed"]);

	const pricesApproved = prices.every((price) => price.approved);

	useEffect(() => {
		setValue("pricesApproved", pricesApproved);
	}, [pricesApproved, setValue]);

	return (
		<ContentCard
			header={t("prices", "Prices")}
			description={t("agreementPricesDescription", "These prices will be listed in the final agreement.")}
			disabled={!isValid && !signed}
		>
			<Form.Form>
				<AgreementPricesFormTable part={part} template={template} />
			</Form.Form>
		</ContentCard>
	);
}
