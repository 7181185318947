import debounce from "lodash/debounce";
import trimStart from "lodash/trimStart";
import moment from "moment";

import { db, firebase } from "astrid-firebase";

const updateFirestore = (production, data) => {
	db.collection("productions")
		.doc(production.id)
		.update({
			...data,
			updated: firebase.firestore.FieldValue.serverTimestamp(),
		})
		.catch((err) => {
			console.log("Production data update error", err);
		});
};

const debouncedUpdate = debounce((...args) => {
	// debounced method for keystrokes
	updateFirestore(...args);
}, 1500);

export default function updateProduction(e, production, data, { log, metadata } = {}) {
	// put single value in array
	if (!Array.isArray(data)) data = [data];

	const newData = {};

	// loop all data fields
	data.forEach((dataPiece) => {
		const field = dataPiece.name;

		// don't store empty arrays
		if (Array.isArray(dataPiece.value) && !dataPiece.value.length) dataPiece.value = null;

		// convert to number if necessary
		if (dataPiece.type === "number" && dataPiece.value !== "") {
			dataPiece.value = +dataPiece.value;
		} else if (dataPiece.type === "date") {
			// convert to date if necessary
			const dateString = dataPiece.value;
			dataPiece.value = dateString ? new Date(dateString) : null;

			// invalid, probably 30 feb or 31 april or similar, set to first of next month
			if (!Number.isInteger(+dataPiece.value)) {
				dataPiece.value = moment(production[field].toDate()).add(1, "M").startOf("month").toDate();
			}
		} else if (typeof dataPiece.value === "string") {
			// trim space from start for strings (and end on blur)
			dataPiece.value = e && e.type === "blur" ? dataPiece.value.trim() : trimStart(dataPiece.value);
		}

		// if reader, add assigned production manager
		if (field === "reader") {
			let readers = dataPiece.value;

			// add single reader to array
			if (typeof readers === "string") {
				readers = [...(production.reader || [])];
				readers.push(dataPiece.value);
				dataPiece.value = readers;
			}
		}

		// unsnooze?
		if (production.statusPlanningSnooze && ["deliveryDate", "reader"].includes(field))
			newData.statusPlanningSnooze = null;

		// if value changed, update it
		if (
			dataPiece.value !== production[field] &&
			(dataPiece.value !== "" || production[field] || field === "isbn")
		) {
			newData[field] = dataPiece.value;

			// set total article metadata update date
			if (field === "isbn") newData.metaUpdatedTotalArticle = firebase.firestore.FieldValue.serverTimestamp();
		}
	});

	// if nothing changed, bail
	if (!Object.keys(newData).length) return;

	// metadata timestamp, used to keep track of distributor metadata expiration
	if (metadata) newData.metaUpdated = firebase.firestore.FieldValue.serverTimestamp();

	if (log) {
		// log instead?
		console.log(newData);
	} else if (e) {
		// debounce browser event updates (keystrokes)
		debouncedUpdate(production, { ...newData });
	} else {
		// update immediately
		updateFirestore(production, { ...newData });
	}
}
