import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Message, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import db from "astrid-firebase/src/db";
import getNextInvoiceNumber from "astrid-firestore/src/api/invoices/getNextInvoiceNumber";
import { invoiceStatuses } from "astrid-firestore/src/api/invoices/invoiceStatuses";
import { invoiceTypes } from "astrid-firestore/src/api/invoices/invoiceTypes";
import { getCollectionData, getDocumentData, updateDocument } from "astrid-firestore/src/helpers";

import DropdownMenu from "../../ui/components/DropdownMenu/DropdownMenu";

const createSalesOrderInvoice = firebase.functions().httpsCallable("invoices-createSalesOrderInvoice");

function showCreditInvoice({ invoice }) {
	return invoice.type !== invoiceTypes.CREDIT && !invoice.creditedBy;
}

export default function InvoiceActionMenu({ invoice }) {
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = useState(false);

	const markPaid = async (setLoading) => {
		try {
			setLoading(true);
			await updateDocument(db.collection("invoices").doc(invoice.id), {
				status: invoice.status === invoiceStatuses.UNPAID ? invoiceStatuses.PAID : invoiceStatuses.UNPAID,
			});
		} catch (error) {
			setErrorMessage(t("errorMessageHeader"));
		}
		setLoading(false);
	};

	const creditInvoice = async (setLoading) => {
		try {
			setLoading(true);
			const salesOrderInvoices = await getCollectionData(
				db.collection("invoices").where("orderId", "==", invoice.orderId).orderBy("created", "desc"),
			);
			const latestInvoice = salesOrderInvoices?.[0];

			const previousInvoiceNumber = await getDocumentData(
				db.collection("organizations").doc(invoice.organization.id).collection("settings").doc("invoiceNumber"),
			);

			await createSalesOrderInvoice({
				organizationId: invoice.organization.id,
				orderId: invoice.orderId,
				dueDate: invoice.dueDate?.toDate().getTime(),
				invoiceNumber: getNextInvoiceNumber({
					prevYear: previousInvoiceNumber?.year,
					prevNumber: previousInvoiceNumber?.number,
					currentYear: new Date().getFullYear(),
				}),
				credit: {
					invoiceId: latestInvoice.id,
					invoiceNumber: latestInvoice.invoiceNumber,
				},
				vatReverseCharge: latestInvoice?.vatReverseCharge,
			});
		} catch (error) {
			console.error(error);
			setErrorMessage(t("errorMessageHeader"));
		}
		setLoading(false);
	};

	return (
		<>
			<DropdownMenu>
				{({ setLoading }) => (
					<>
						<Dropdown.Item onClick={() => markPaid(setLoading)}>
							{invoice.status === invoiceStatuses.UNPAID
								? t("markAsPaid", "Mark as paid")
								: t("markAsUnpaid", "Mark as unpaid")}
						</Dropdown.Item>
						{showCreditInvoice({ invoice }) && (
							<Dropdown.Item onClick={() => creditInvoice(setLoading)}>
								{t("creditInvoiceAction", "Credit invoice")}
							</Dropdown.Item>
						)}
					</>
				)}
			</DropdownMenu>
			<Modal open={!!errorMessage} onClose={() => setErrorMessage(false)}>
				<Modal.Header>{t("error")}</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<Message error content={errorMessage} />
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button color="black" onClick={() => setErrorMessage(false)}>
						{t("close")}
					</Button>
				</Modal.Actions>
			</Modal>
		</>
	);
}
