import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import { deleteableOfferStatuses } from "astrid-firestore/src/api/offers/constants/offerStatuses";
import deleteOffer from "astrid-firestore/src/api/offers/deleteOffer";

import { getProfile } from "../../../../authentication/state/profile";

export default function DeleteOfferDropdownItem({ offer, disabled, setLoading, ...props }) {
	const { t } = useTranslation();

	const deleteable = deleteableOfferStatuses.includes(offer.status);

	return (
		<Dropdown.Item
			text={t("remove", "Remove")}
			disabled={disabled || !deleteable}
			onClick={async () => {
				setLoading?.(true);

				try {
					await deleteOffer(firebase, { offer, user: getProfile() });
				} catch (error) {
					window.alert(t("failedToDeleteOffer", "Failed to delete offer"));
					console.error(error);
				}

				setLoading?.(false);
			}}
			{...props}
		/>
	);
}
