import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import { parseToISODate } from "../../../helpers/date";

import { formatPrice } from "../../prices/utils/priceFormatters";
import getAuthorNames from "../../productions/utils/getAuthorNames";
import getReaderNames from "../../productions/utils/getReaderNames";
import mapScriptDate from "../../productions/utils/mapScriptDate";

function mapPriceAndAgreement({ offer, user }) {
	const { price: defaultPrice, agreement: defaultAgreement } =
		user?.prices?.[offer.producer?.id]?.[offer.languageRole] || {};

	const price = offer.price === "default" ? defaultPrice : offer.price;
	const agreement = !offer.agreement ? defaultAgreement : offer.agreement;

	return {
		price,
		agreement,
	};
}

export default function mapOffer({ offer, user }) {
	const { production = {} } = offer;
	const { price, agreement } = mapPriceAndAgreement({ offer, user });

	return {
		offer: {
			...offer,
			price,
			agreement,
		},
		production: {
			...offer.production,
			scriptDate: mapScriptDate(production),
			productionDate: parseToISODate(production.productionDate),
			deliveryDate: parseToISODate(production.deliveryDate),
			author: getAuthorNames(production),
			reader: getReaderNames(production),
			series:
				production.series || production.part
					? `${production.series || "-"} ${production.part || ""}`
					: undefined,
		},
		publisher: offer.publisher,
		formattedPrice: formatPrice(price || {}),
		status: offer.declinedUserIds?.includes?.(user.id) ? offerStatuses.DECLINED : offer.status,
		expiresAtISODate: parseToISODate(offer?.expiresAt),
	};
}
