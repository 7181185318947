import { updateDocument } from "../../helpers";
import { getStudioRef } from "../../utils/referenceMappers";

export default async function updateSession(firebase, { session, studio, studioGroup = null, start, end }) {
	const data = {
		start: firebase.firestore.Timestamp.fromDate(start),
		end: firebase.firestore.Timestamp.fromDate(end),
		studioGroup,
	};

	if (studio !== undefined) {
		data.studio = studio?.id || null;
		data.studioData = getStudioRef(studio);
	}

	await updateDocument(session.ref, data);

	return {
		...session,
		...data,
	};
}
