import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import TeamPriceSelect from "../../TeamPriceSelect/TeamPriceSelect";

export default function TeamMemberPriceField() {
	const { t } = useTranslation();
	const { watch, setValue } = Form.useFormContext();

	const { role, user, employee, production, agreementId } = watch();

	useEffect(() => {
		setValue("priceId", null);
	}, [agreementId, setValue]);

	if ((!employee && !agreementId) || !production) {
		return null;
	}

	return (
		<TeamPriceSelect
			name="priceId"
			role={role}
			user={user}
			production={production}
			agreementId={agreementId}
			onChangeOption={(option) => setValue("price", option?.data)}
			additionalOptions={
				agreementId === "external" && [
					{
						key: "hidden",
						value: "hidden",
						text: `[${t("hiddenPrice", "Hidden price")}]`,
					},
					{
						key: "custom",
						value: "custom",
						text: `[${t("customPrice", "Custom price")}]`,
					},
				]
			}
		/>
	);
}
