import { teamRoles } from "../../constants/teamRoles";
import runTransaction from "../../helpers/runTransaction";
import { getPriceRef, getUserRef } from "../../utils/referenceMappers";

import createProductionPriceData from "../productions/prices/createProductionPriceData";
import createTeamMemberData from "../productions/team/createTeamMemberData";
import createOfferStatusChangedTodoData from "../todos/data/offers/createOfferStatusChangedTodoData";

import offerStatuses from "./constants/offerStatuses";
import getOfferAgreement from "./utils/getOfferAgreement";
import getOfferPrice from "./utils/getOfferPrice";

export const ALREADY_ACCEPTED_ERROR = "Already accepted!";

export default function acceptOffer(firebase, { offer, user }) {
	const db = firebase.firestore();

	const price = getOfferPrice({ offer, user });
	const agreement = getOfferAgreement({ offer, user });

	return runTransaction(db, [offer.ref], (transaction, [transactionOffer]) => {
		if (transactionOffer.status === offerStatuses.ACCEPTED) {
			throw ALREADY_ACCEPTED_ERROR;
		}

		transaction.update(offer.ref, {
			status: offerStatuses.ACCEPTED,
			secondParty: offer.secondParty || getUserRef(user),
		});

		const firstParty = agreement?.firstParty || offer.firstParty;
		const secondParty = agreement?.secondParty || offer.secondParty;

		const productionPrice = price
			? createProductionPriceData(firebase, {
					...price,
					expense: true,
					approved: true,
					producer: offer.producer,
					production: offer.production,
					agreement,
					firstParty,
					secondParty,
					referencePrice: price,
			  })
			: null;

		const member = createTeamMemberData(firebase, {
			user,
			offer,
			agreement,
			firstParty,
		});

		if (productionPrice?.ref && offer.role !== teamRoles.RECORDER) {
			transaction.set(productionPrice.ref, productionPrice);
		}

		transaction.update(offer.production.ref, {
			[`team.${member.id}`]: { ...member, price: getPriceRef(productionPrice) },
		});

		const todo = createOfferStatusChangedTodoData(firebase, {
			status: offerStatuses.ACCEPTED,
			offer: { ...offer, secondParty: offer.secondParty || getUserRef(user) },
		});

		transaction.set(todo.ref, todo);
	});
}
