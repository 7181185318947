import { crispWebsiteId } from "astrid-config/src/crisp";
import { isLive } from "astrid-helpers/src/env";

export function pushSessionData(key, data) {
	window.$crisp?.push(["set", "session:data", [key, data ? JSON.stringify(data) : "missing"]]);
}

export function setUpCrisp(profile) {
	window.$crisp = [];
	window.$crisp.push(["set", "user:nickname", [`${profile.firstName} ${profile.lastName}`]]);
	window.$crisp.push(["set", "user:email", profile.email]);
	window.CRISP_WEBSITE_ID = isLive ? crispWebsiteId.live : crispWebsiteId.stage;

	const scriptUrl = "https://client.crisp.chat/l.js";
	const scripts = document.querySelector(`script[src='${scriptUrl}']`);

	if (!scripts) {
		const script = document.createElement("script");
		script.src = "https://client.crisp.chat/l.js";
		script.async = 1;
		document.getElementsByTagName("head")[0].appendChild(script);
	}
}
