import { Card } from "semantic-ui-react";

import OrganizationLanguageSettings from "./components/OrganizationLanguageSettings";

export default function OrganizationSettingsLanguageView({ organization }) {
	return (
		<Card.Group>
			<OrganizationLanguageSettings organization={organization} />
		</Card.Group>
	);
}
