import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

import AgreementPartSelect from "../components/AgreementPartSelect";
import FirstPartyVendorSelect from "../components/FirstPartyVendorSelect";
import FirstSigneeSelect from "../components/FirstSigneeSelect";

export default function AgreementSettingsForm() {
	const { t } = useTranslation();
	const { watch, setValue } = useFormContext();

	const isValid = Form.usePartialFormValidation(["name"]);

	const [type, part, life, template, firstParty, signed] = watch([
		"type",
		"part",
		"life",
		"template",
		"firstParty",
		"signed",
	]);

	const lifeApproved = life <= template?.life;

	useEffect(() => {
		setValue("lifeApproved", lifeApproved);
	}, [lifeApproved, setValue]);

	return (
		<ContentCard header={t("settings")} disabled={!isValid}>
			<Form.Form>
				<Form.Group widths="equal">
					<AgreementPartSelect disabled={!!template} />
					<FirstPartyVendorSelect firstParty={firstParty} />
					<FirstSigneeSelect firstParty={firstParty} />
				</Form.Group>

				<Form.Group widths="equal">
					<Form.DateInput name="activeFrom" label={t("activeFrom", "Active from")} />
					<Form.Input name="life" type="number" label={t("agreementTime")} />
					<Form.Input
						name="daysToSign"
						type="number"
						max={30}
						label={t("daysToSign")}
						rules={{
							min: { value: 1, message: t("daysToSignValidation") },
							max: { value: 30, message: t("daysToSignValidation") },
						}}
					/>
				</Form.Group>

				<Form.Checkbox
					name="signed"
					label={t("agreementAlreadySigned", "This agreement is already signed")}
					rules={{ required: false }}
				/>

				{signed && (
					<Form.UploadButton
						name="signedAgreementFile"
						bucketName="earselect-secret"
						path={`agreements/${firstParty?.id}`}
					/>
				)}

				{type === agreementTypes.FRAMEWORK && part === "publisher" && (
					<Form.Checkbox
						name="canManageAgreements"
						label={t("canManageAgreements", "Can manage user agreements for this publisher")}
						rules={{ required: false }}
					/>
				)}
			</Form.Form>

			{!signed && !lifeApproved && (
				<Message info>{t("priceAboveThresholdMessage", "Needs to be approved before signing")}</Message>
			)}
		</ContentCard>
	);
}
