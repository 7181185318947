import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { Distribution } from "./types/Distribution";

const distributions = createFirestoreCollection("distributions", Distribution, {
	preProcess: async ({ api }, { data }) => {
		// If articleId is provided, fetch the article data & metadata
		if (data.articleId) {
			data.article = await api.articles.getById(data.articleId);
		}

		// If artifactId is provided, fetch the artifact data
		if (data.artifactId) {
			data.artifact = await api.artifacts.getById(data.artifactId);
		}

		// If we have an article and no artifact, fetch the artifact from the default artifact
		if (data.article && !data.artifact) {
			data.artifact = await api.artifacts.getByRef(data.article.artifact.ref);
		}

		// If artifactId is provided, fetch the artifact data
		if (data.channelId) {
			data.channel = await api.distributionChannels.getById(data.channelId);
		}

		// If pipelineId is provided, fetch the pipeline data
		if (data.pipelineId) {
			data.pipeline = data.channel.pipelines[data.pipelineId];
		}

		if (data.article?.publisher) {
			data.publisher = data.article.publisher;
		}

		return data;
	},
});

export default distributions;
