import { useTranslation } from "react-i18next";

import getTotalSessionMs from "../../../utils/getTotalSessionMs";

export default function useStudioTime(sessions) {
	const { t } = useTranslation();

	const totalStudioTime = getTotalSessionMs(sessions);
	const completedStudioTime = getTotalSessionMs(sessions.filter((session) => session.status === "done"));
	const cancelledStudioTime = getTotalSessionMs(sessions.filter((session) => session.status === "cancelled"));
	const studioTimeMissingEngineer = getTotalSessionMs(sessions.filter((session) => !session.recorder));

	return [
		{ title: t("totalStudioTime"), time: totalStudioTime },
		{ title: t("accomplishedStudioTime"), time: completedStudioTime },
		{ title: t("cancelledStudioTime"), time: cancelledStudioTime },
		{ title: t("studioTimeWithoutTechn"), time: studioTimeMissingEngineer },
	];
}
