import { useInstantSearch, useRefinementList } from "react-instantsearch";

export default function useRefinement({
	attribute,
	sortBy,
	operator = "or",
	limit = Infinity,
	showMore = false,
	showMoreLimit = Infinity,
}) {
	const { indexUiState, setIndexUiState } = useInstantSearch();
	const { refinementList = {} } = indexUiState;

	const value = refinementList[attribute];

	const {
		items,
		refine: simpleRefine,
		searchForItems,
		canToggleShowMore,
		toggleShowMore,
		isShowingMore,
	} = useRefinementList({
		attribute,
		limit,
		showMore,
		showMoreLimit,
		sortBy,
		operator,
	});

	const refine = (value) =>
		setIndexUiState((prevIndexUiState) => ({
			...prevIndexUiState,
			refinementList: {
				...prevIndexUiState.refinementList,
				[attribute]: value,
			},
		}));

	return { items, value, refine, simpleRefine, searchForItems, canToggleShowMore, toggleShowMore, isShowingMore };
}
