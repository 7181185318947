import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useTitlesTableColumns from "../../hooks/useTitlesTableColumns";

export default function TitlesCollectionDataTable({ getQuery = (query) => query }) {
	const navigate = useNavigate();

	const columns = useTitlesTableColumns();

	return (
		<CollectionDataTable
			query={getQuery(db.collection("titles").where("deleted", "==", false))}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/titles/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
		/>
	);
}
