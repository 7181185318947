import { db } from "astrid-firebase";
import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import { useProfile } from "../../../../authentication/state/profile";

export default function useUpcomingDeliveriesQuery({ organization }) {
	const profile = useProfile();

	if (profile) {
		const query = db
			.collection("productions")
			.where("deleted", "==", false)
			.where(organization.type, "==", organization.id)
			.where("status", "in", [
				productionStatuses.PLANNING,
				productionStatuses.ACCEPTED,
				productionStatuses.PRODUCTION,
			]);

		switch (organization.type) {
			case "publisher":
				return query.where("publisherManagerIds", "array-contains", profile.id);
			default:
				return query;
		}
	}

	return null;
}
