import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader, Message, Segment } from "semantic-ui-react";

import Table from "../../../ui/components/Table/Table";

import useProductionRequestsColumns from "./hooks/useProductionRequestsColumns";

export default function ProductionRequestsView({ mappedProductionRequests, loading }) {
	const { t } = useTranslation();
	const columns = useProductionRequestsColumns();
	const navigate = useNavigate();

	if (loading) {
		return (
			<Segment padded="very">
				<Loader active />
			</Segment>
		);
	}

	if (!loading && !mappedProductionRequests?.length) {
		return (
			<Segment>
				<Message info content={t("noProductionRequests")} />
			</Segment>
		);
	}

	return (
		<Table
			filterable
			pagination
			persist
			hoverRow
			onClickRow={(e, { original }) => navigate(`/production/${original.id}`)}
			columns={columns}
			data={mappedProductionRequests}
		/>
	);
}
