import hasNotification from "../notifications/hasNotification";

import offerTypes from "./constants/offerTypes";

export default function offerHasNotification(offer, userId) {
	if (offer.type === offerTypes.OPEN_POSITION && offer.declinedUserIds?.includes?.(userId)) {
		return false;
	}
	return hasNotification(offer, userId);
}
