import useForm from "../../../../forms/hooks/useForm";

export default function usePricesForm(organization) {
	return useForm({
		defaultValues: {
			currency: null,
			languages: organization?.languageDefault ? [organization?.languageDefault] : [],
			prices: [
				{
					type: null,
					name: "",
					price: null,
					unit: "time",
					employee: false,
					vat: 25,
				},
			],
		},
	});
}
