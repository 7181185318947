import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import ViewAgreementDropdownItem from "../../../agreements/components/AgreementDropdownMenu/items/ViewAgreementDropdownItem";

import EditMemberDropdownItem from "./items/EditMemberDropdownItem";
import RemoveMemberDropdownItem from "./items/RemoveMemberDropdownItem";

export default function TeamMemberDropdownMenu({ member, production }) {
	return (
		<DropdownMenu>
			{({ setLoading, setError }) => (
				<>
					<ViewAgreementDropdownItem agreement={member.agreement} />
					<EditMemberDropdownItem
						member={member}
						production={production}
						setError={setError}
						setLoading={setLoading}
					/>
					<RemoveMemberDropdownItem
						member={member}
						production={production}
						setLoading={setLoading}
						setError={setError}
					/>
				</>
			)}
		</DropdownMenu>
	);
}
