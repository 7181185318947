import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

import AgreementAppendixForm from "../../AgreementAppendixForm/AgreementAppendixForm";

export default function AgreementAppendixesForm() {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();
	const isValid = Form.usePartialFormValidation(["part", "firstSignee"]);

	const [type, part, producer] = watch(["type", "part", "producer"]);

	return (
		<ContentCard header={t("appendixes", "Appendixes")} disabled={!isValid}>
			<Form.Form>
				<AgreementAppendixForm type={type} part={part} producerId={producer?.id} />
			</Form.Form>
		</ContentCard>
	);
}
