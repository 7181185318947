import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminUsersView from "../views/SuperAdminUsersView/SuperAdminUsersView";

export default function SuperAdminUsers(props) {
	const hasRight = useHasRight();

	if (!hasRight("users.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminUsersView {...props} />;
}
