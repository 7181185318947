import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import useAgreementTemplatesQuery from "./useAgreementTemplatesQuery";

export default function useAgreementTemplateOptions({ type, part, priceType, firstPartyId }) {
	const { t } = useTranslation();

	const [agreementTemplates = []] = useCollectionDataOnce(useAgreementTemplatesQuery({ type, part, firstPartyId }));

	return useMemo(
		() => [
			{ key: "custom", value: "custom", text: `[${t("customAgreement", "Custom agreement")}]` },
			...agreementTemplates
				.filter(
					({ part, prices }) =>
						part !== "user" || Object.values(prices).some(({ type }) => type === priceType),
				)
				.map((template) => ({
					key: template.id,
					value: template.id,
					text: template.name,
					data: template,
				})),
		],
		[agreementTemplates, priceType, t],
	);
}
