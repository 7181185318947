import get from "lodash/get";
import moment from "moment";

import { Button, Dropdown, Form, Icon, Input, Loader, Popup } from "semantic-ui-react";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import countries from "astrid-config/src/countries";
import distConfig from "astrid-config/src/distributors";
import { firebase } from "astrid-firebase";
import { codes } from "astrid-helpers/src/currencies";
import { Store } from "astrid-web/src/helpers/context";
import { toDate } from "astrid-web/src/helpers/fnc";

import DateTime from "../../../features/ui/components/DateInput/DateTime";
import { getDefaultCurrency } from "../../../helpers/fnc";

import { sendToDistributor } from "../fnc";

const scrapeDistributorStatus = firebase.functions().httpsCallable("scrapeDistributorStatus");

const DistributorMetaBox = ({
	production,
	productionId,
	isProducer,
	label,
	article,
	distributor,
	openMetaBox,
	setOpenMetaBox,
	handleChange,
	fluid,
}) => {
	// context
	const context = useContext(Store);
	const { profile, user } = context.state;

	// state
	const [isScraping, setIsScraping] = useState(false);
	const [downloadUrl, setDownloadUrl] = useState(null);

	// distributor info
	const dist = distConfig[distributor];
	const distFields = get(dist, "meta.fields") || [];
	const distRequiredFields = get(dist, "meta.requiredFields") || [];

	// article info
	const path =
		article === "total"
			? "" // root
			: article.substr(0, 4) === "part"
			? "deliveryParts[" + article.split("_")[1] + "]." // part
			: "deliveryEbook."; // ebook

	const releaseDate =
		get(production, path + "release." + distributor + ".date") ||
		get(production, path + "releaseDate." + distributor);
	const takedownDate = get(production, path + "release." + distributor + ".takedown");
	const releaseMarkets = get(production, path + "release." + distributor + ".markets") || [];
	const releaseAvailability = get(production, path + "release." + distributor + ".availability");
	const releaseWorld = get(production, path + "release." + distributor + ".world");
	const override = get(production, path + "release." + distributor + ".override");
	const overrideIsbn = override ? override.isbn : undefined;

	const isbn = get(production, path + "isbn");
	const img =
		article.substr(0, 4) === "part"
			? get(production, "deliveryPartsCover." + article.split("_")[1] + ".img")
			: get(production, path + "img");
	const missingEpub = article === "ebook" && !get(production, "deliveryEbook.path");
	const draftStatus = production.status === "draft";

	// meta status (updated distributor meta, article och production)
	const metaUpdated = toDate(get(production, path + "release." + distributor + ".metaUpdated"));
	const metaUpdatedArticle = toDate(get(production, path ? path + "metaUpdated" : "metaUpdatedTotalArticle"));
	const metaUpdatedProduction = toDate(get(production, "metaUpdated"));

	// which was updated last?
	const metaLatest = Math.max(metaUpdated || 0, metaUpdatedArticle || 0, metaUpdatedProduction || 0);
	const metaSent = get(production, path + "release." + distributor + ".metaSent");
	const scraped = get(production, path + "release." + distributor + ".scraped");

	// is the metadata outdated?
	const metaOutdated = toDate(metaSent) < metaLatest;

	const isSendingMetadata = metaSent && typeof metaSent === "string";

	const open = openMetaBox === article + distributor;

	const availableCurrencies = codes
		// supported by dist
		.filter((code) => !dist.meta?.currency || dist.meta?.currency.includes(code));

	const isActive = !((distFields.includes("availability") && !releaseAvailability) || takedownDate);
	const { t } = useTranslation();

	useEffect(() => {
		if (metaSent) {
			firebase
				.storage()
				.refFromURL(window.ES.stage ? "gs://stage-earselect-static" : "gs://earselect-static")
				.child(`masterDelivery/${productionId}/export/${article}/metadata/${distributor}/${isbn}.xml`)
				.getDownloadURL()
				.then(setDownloadUrl)
				.catch((error) => {
					// Not an error, prevent Sentry logging
				});
		}
	}, [article, distributor, isbn, metaSent, productionId]);

	return (
		<Popup
			open={openMetaBox === article + distributor}
			trigger={
				<Button
					fluid={!!fluid}
					icon={isSendingMetadata ? null : true}
					size="small"
					labelPosition={isSendingMetadata ? null : "left"}
					onClick={() => {
						setOpenMetaBox(open ? null : article + distributor);
					}}
					// style={{ minWidth: "18em" }}
				>
					{isSendingMetadata ? (
						<Loader inline active size="tiny" style={{ margin: "-2px 5px -2px 0" }} />
					) : (
						<Icon name="calendar" color={metaSent ? (metaOutdated ? "orange" : "green") : "grey"} />
					)}

					{label + (releaseDate ? ": " + moment(toDate(releaseDate)).format("YYYY-MM-DD") : "")}
				</Button>
			}
		>
			{/* as div to avoid form submit on enter */}
			<Form as="div">
				{distFields.includes("availability") && (
					<Form.Checkbox
						label={t("metaBoxActive")}
						checked={releaseAvailability}
						onClick={(e, data) => {
							handleChange("availability", data.checked);
						}}
					/>
				)}
				<div className="field">
					<label>{t("metaBoxReleaseDate")}</label>
					<DateTime
						value={releaseDate ? moment(toDate(releaseDate)).format("YYYY-MM-DD") : ""}
						timeFormat={false}
						dateFormat={"YYYY-MM-DD"}
						closeOnSelect={true}
						onChange={(dt) => {
							if (!dt || typeof dt === "object") {
								const day = dt ? new Date(dt.format("YYYY-MM-DD")) : null;
								handleChange("date", day);
							}
						}}
					/>
				</div>

				{distFields.includes("takedown") && (
					<div className="field">
						<Form.Checkbox
							label={t("metaBoxDePublish")}
							style={{ fontWeight: "bold", fontSize: ".92857143em" }}
							checked={typeof takedownDate !== "undefined" && takedownDate !== null}
							onClick={(e, data) => {
								handleChange("takedown", data.checked ? "" : null);
							}}
						/>

						{typeof takedownDate !== "undefined" && takedownDate !== null && (
							<DateTime
								className={takedownDate ? null : "field error"}
								value={takedownDate ? moment(toDate(takedownDate)).format("YYYY-MM-DD") : ""}
								timeFormat={false}
								closeOnSelect={true}
								dateFormat={"YYYY-MM-DD"}
								onChange={(dt) => {
									if (!dt || typeof dt === "object") {
										let day = dt ? new Date(dt.format("YYYY-MM-DD")) : null;

										if (day && (day <= toDate(releaseDate) || !releaseDate)) {
											window.alert(t("metaBoxWarningDePublish"));
											day = null;
										}
										handleChange("takedown", day);
									}
								}}
							/>
						)}
					</div>
				)}

				{distFields.includes("markets") && (
					<div className="field">
						<label>{t("metaBoxMarkets")}</label>
						<Form.Checkbox
							label={t("metaBoxGlobalRights")}
							checked={releaseWorld}
							onClick={(e, data) => {
								handleChange("world", data.checked);
							}}
						/>

						{dist.meta.markets &&
							Object.entries(dist.meta.markets).map(([code, name]) => (
								<Form.Checkbox
									key={code}
									label={name}
									disabled={releaseWorld}
									checked={releaseMarkets.includes(code)}
									onClick={(e, data) => {
										const markets = [...releaseMarkets];
										if (data.checked) {
											markets.push(code);
										} else {
											markets.splice(markets.indexOf(code), 1);
										}
										handleChange("markets", markets);
									}}
								/>
							))}
					</div>
				)}

				{distFields.includes("countriesIncluded") && (
					<Form.Select
						search
						deburr
						multiple
						label={t("metaBoxRights")}
						placeholder={t("metaBoxGlobalRights")}
						value={get(production, path + "release." + distributor + ".countriesIncluded") || []}
						options={Object.entries(countries).map(([code, text]) => ({ key: code, value: code, text }))}
						onChange={(e, data) => {
							handleChange("countriesIncluded", data.value);
						}}
					/>
				)}

				{Object.entries({
					pricePayPerLoan: "Pay-per-loan",
					priceUnitSale: "styckförsäljning",
					priceOCOU: "One Copy One User",
					priceCPC: "Cost Per Circulation",
				}).map(
					([price, label]) =>
						distRequiredFields.includes(price) && (
							<div className="field" key={price}>
								<label>
									{t("metaBoxPrice")} {label} {t("metaBoxExVat")}
								</label>
								<Input
									type="number"
									labelPosition="right"
									value={get(production, path + "release." + distributor + "." + price) || ""}
									onChange={(e, data) => {
										handleChange(price, data.value);
									}}
								>
									<input style={{ width: 60 }} />
									<Dropdown
										basic
										search
										className="label"
										value={
											get(
												production,
												path + "release." + distributor + "." + price + "Currency",
											) || getDefaultCurrency(production.language, availableCurrencies)
										}
										options={availableCurrencies.map((code) => ({
											key: code,
											value: code,
											text: code,
										}))}
										disabled={availableCurrencies.length < 2}
										onChange={(e, data) => {
											handleChange(price + "Currency", data.value);
										}}
									/>
								</Input>
							</div>
						),
				)}

				{false && (
					<Form.Checkbox //Disabled due to not fully working
						label={t("metaBoxReplaceISBN")}
						style={{ fontWeight: "bold", fontSize: ".92857143em" }}
						checked={overrideIsbn !== undefined}
						onClick={(e, data) => {
							handleChange("override", data.checked ? { isbn: "" } : null);
						}}
					/>
				)}

				{overrideIsbn !== undefined && (
					<Form.Input
						value={overrideIsbn || ""}
						onChange={(e, data) => {
							handleChange("override", { isbn: data.value });
						}}
					/>
				)}

				<hr />

				{isSendingMetadata ? (
					<>
						<small>{t("metaBoxMetaDataSend")}</small>
						{isProducer && (
							<Button
								size="tiny"
								content={t("metaBoxResend")}
								disabled={!isbn}
								onClick={(e) => {
									const articles = { [productionId]: { [article]: "metadata" } };
									sendToDistributor(distributor, articles, profile, user.uid);
								}}
							/>
						)}
					</>
				) : (
					<>
						{downloadUrl && (
							<div>
								<small>
									<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
										{t("metaBoxMetaDataDownload", "Download metadata")} (XML)
									</a>
								</small>
							</div>
						)}
						{metaSent && (
							<div>
								<small>
									{t("metaBoxMetaDataSent")} {moment(toDate(metaSent)).format("YYYY-MM-DD HH:mm")}
								</small>
							</div>
						)}
						{(!metaSent || metaOutdated) && (
							<>
								{metaUpdated && (
									<div>
										<small>
											{t("metaBoxMetaDataChanged")}{" "}
											{moment(metaLatest).format("YYYY-MM-DD HH:mm")}
										</small>
									</div>
								)}
								{missingEpub && (
									<p>
										<Icon name="warning circle" color="red" />
										{t("metaBoxEpubMissing")}
									</p>
								)}
								{draftStatus && (
									<p>
										<Icon name="warning circle" color="red" />
										{t("productionDraftStatus")}
									</p>
								)}
								{!isbn && (
									<p>
										<Icon name="warning circle" color="red" />
										{t("metaBoxISBNMissing")}
									</p>
								)}
								{!releaseDate && (
									<p>
										<Icon name="warning circle" color="red" />
										{t("metaBoxReleaseDataMissing")}
									</p>
								)}
								{takedownDate === "" && (
									<p>
										<Icon name="warning circle" color="red" />
										{t("metaBoxDePublishDateMissing")}
									</p>
								)}
								{isActive && !img && (
									<p>
										<Icon name="warning circle" color="red" />
										{t("metaBoxCoverMissing")}
									</p>
								)}
								{distRequiredFields?.find(
									(field) =>
										field.substr(0, 5) === "price" &&
										!get(production, path + "release." + distributor + "." + field),
								) && (
									<p>
										<Icon name="warning circle" color="red" />
										{t("metaBoxPriceMissing")}
									</p>
								)}
								{!dist.audio?.disableSendWithoutMeta ? (
									<Button
										fluid
										color="teal"
										size="tiny"
										content={t("metaBoxSendMetaData")}
										disabled={
											draftStatus ||
											!isbn ||
											(isActive && !img) ||
											!releaseDate ||
											dist.disabled ||
											takedownDate === "" ||
											!!distRequiredFields.find(
												(field) =>
													!get(production, path + "release." + distributor + "." + field),
											)
										}
										onClick={(e) => {
											const articles = { [productionId]: { [article]: "metadata" } };
											sendToDistributor(distributor, articles, profile, user.uid);
											handleChange("metaSent", "queued");
										}}
									/>
								) : (
									<i>
										{t(
											"disableSendWithoutMetaMessage",
											"This channel requires both audio and metadata together, please distribute from the export menu",
										)}
									</i>
								)}
							</>
						)}
					</>
				)}
				{scraped && isProducer && (
					<div>
						<small>
							{t("metaBoxStatus")}{" "}
							{scraped.url ? (
								<a
									href={scraped.url}
									target="_blank"
									rel="noopener noreferrer"
									style={{ fontWeight: "bold" }}
								>
									{t("metaBoxPublished")} {scraped.upcoming && " (kommande)"} <Icon name="external" />
								</a>
							) : (
								t("metaBoxNotPublished")
							)}
							<br />
							{t("metaBoxControlled")} {moment(toDate(scraped.time)).format("YYYY-MM-DD HH:mm")}
						</small>
					</div>
				)}
				{dist.scrape && user.uid === "bM9vdT58yWZk7QkTqVNZsHSVuID3" && (
					<Button
						fluid
						content={t("metaBoxScrape")}
						loading={isScraping}
						onClick={(e) => {
							setIsScraping(true);

							scrapeDistributorStatus({
								isbn: overrideIsbn || isbn,
								language: production.language,
								distributor: [distributor],
								returnFilter: ["url", "upcoming"],
							}).then(({ data }) => {
								const { url, upcoming } = data;

								const newData = { time: new Date() };
								if (url) newData.url = url;
								if (upcoming) newData.upcoming = true;

								handleChange("scraped", newData);

								setIsScraping(false);
							});
						}}
					/>
				)}
			</Form>
		</Popup>
	);
};

export default DistributorMetaBox;
