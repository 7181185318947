export default {
	name: "Bokbasen",
	connection: "api",
	audio: false,
	ebook: false,
	cover: true,
	meta: {
		format: "onix",
		fields: ["countriesIncluded"],
		requiredFields: ["pricePayPerLoan", "priceUnitSale"],
		sharedFields: ["bokgruppe", "thema"],
	},
	disableArticles: ["cd", "mp3cd"],
};
