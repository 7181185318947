export const storytelProducers = [
	{
		id: "5zgAsg2XgCxU7PjY9U5p",
		name: "Storytel_Iceland",
		filter: "production.producer:5zgAsg2XgCxU7PjY9U5p",
	},
	{
		id: "4hCmQig254WuPAjUFUc4",
		name: "Storytel_Production_Brazil",
		filter: "production.producer:4hCmQig254WuPAjUFUc4",
	},
	{
		id: "C8MDTj6SwopSXO68zYYR",
		name: "Storytel_Production_Singapore",
		filter: "production.producer:C8MDTj6SwopSXO68zYYR",
	},
	{
		id: "D0WkzZILUdb3GWb3G5dk",
		name: "Storytel_Production_Netherlands",
		filter: "production.producer:D0WkzZILUdb3GWb3G5dk",
	},
	{
		id: "EeCCAKsJviDNGUTzDxYz",
		name: "Storytel_Production_Poland",
		filter: "production.producer:EeCCAKsJviDNGUTzDxYz",
	},
	{
		id: "FBDJMObjMHp27GcOv3P7",
		name: "Storytel_Production_MENA",
		filter: "production.producer:FBDJMObjMHp27GcOv3P7",
	},
	{
		id: "Fiv6UfxyarwZeimtxbDf",
		name: "Storytel_Production_Denmark",
		filter: "production.producer:Fiv6UfxyarwZeimtxbDf",
	},
	{
		id: "KQX8jV6wC0rz4FqMJiT7",
		name: "Global_Planning",
		filter: "production.producer:KQX8jV6wC0rz4FqMJiT7",
	},
	{
		id: "LD72xWXZxKVu1xHv3WTA",
		name: "Storytel_Production_Russia",
		filter: "production.producer:LD72xWXZxKVu1xHv3WTA",
	},
	{
		id: "Q7YCP0mTVgkynHuvGYjS",
		name: "Storytel_Production_India",
		filter: "production.producer:Q7YCP0mTVgkynHuvGYjS",
	},
	{
		id: "SZhD0iBOysMzmLSYVS6C",
		name: "Storytel_Production_Germany",
		filter: "production.producer:SZhD0iBOysMzmLSYVS6C",
	},
	{
		id: "W9loRIOY12t2loiCaqxz",
		name: "Storytel_Production_Latam",
		filter: "production.producer:W9loRIOY12t2loiCaqxz",
	},
	{
		id: "WJUOr4qtN71oX6znTJUM",
		name: "Storytel_Production_Global_English",
		filter: "production.producer:WJUOr4qtN71oX6znTJUM",
	},
	{
		id: "YWk3cFiqB4WUHhh0E0jo",
		name: "Storytel_Production_Italy",
		filter: "production.producer:YWk3cFiqB4WUHhh0E0jo",
	},
	{
		id: "YWsvoePWomNevVwpwqNY",
		name: "Rufoof_Online_FZ_LLC",
		filter: "production.producer:YWsvoePWomNevVwpwqNY",
	},
	{
		id: "b8d9c9FqtsU4t7h2igkY",
		name: "Storytel_Production_Turkey",
		filter: "production.producer:b8d9c9FqtsU4t7h2igkY",
	},
	{
		id: "bG2Y9I5klk4CyuLY00ig",
		name: "Storytel_Production_South_Korea",
		filter: "production.producer:bG2Y9I5klk4CyuLY00ig",
	},
	{
		id: "jweFKIAFzw6m68rbx3xj",
		name: "Storytel_Production_Thailand",
		filter: "production.producer:jweFKIAFzw6m68rbx3xj",
	},
	{
		id: "r1BVBTLTm3R56HDRceMK",
		name: "Storytel_Production_Sweden",
		filter: "production.producer:r1BVBTLTm3R56HDRceMK",
	},
	{
		id: "rKdzBJLqYA6Cqi73fy3Q",
		name: "Storytel_Production_Romania",
		filter: "production.producer:rKdzBJLqYA6Cqi73fy3Q",
	},
	{
		id: "sCtluSREHf4mrB7xkLy8",
		name: "Storytel_Production_Israel",
		filter: "production.producer:sCtluSREHf4mrB7xkLy8",
	},
	{
		id: "x8BAXYnZp4vl18MBBOWI",
		name: "Storytel_Production_Indonesia",
		filter: "production.producer:x8BAXYnZp4vl18MBBOWI",
	},
	{
		id: "xo0LTm8LNO5Z3alVJW5T",
		name: "Storytel_Production_Spain",
		filter: "production.producer:xo0LTm8LNO5Z3alVJW5T",
	},
	{
		id: "yP0C7dyzhKbP5lfMeb9z",
		name: "Storytel_Production_Bulgaria",
		filter: "production.producer:yP0C7dyzhKbP5lfMeb9z",
	},
];
