import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

export default function DropdownMenuItems({ options }) {
	return options
		.filter((options) => options.some(({ hidden }) => !hidden))
		.map((options, index) => {
			return (
				<Fragment key={index}>
					{index > 0 && <Dropdown.Divider style={{ margin: 2 }} />}
					{options
						.filter(({ hidden }) => !hidden)
						.map((option) => (
							<Dropdown.Item as={!option.onClick ? Link : ""} key={option.text} {...option} />
						))}
				</Fragment>
			);
		});
}
