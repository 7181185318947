import AddExpenseAction from "./AddExpenseAction";
import AddRevenueAction from "./AddRevenueAction";
import FinalizeCostsAction from "./FinalizeCostsAction";

export default function OpenCostsActions({ partyId, production, openCosts }) {
	return (
		<>
			<AddExpenseAction partyId={partyId} production={production} />{" "}
			{partyId === production.producer && <AddRevenueAction production={production} />}{" "}
			<FinalizeCostsAction openCosts={openCosts} />
		</>
	);
}
