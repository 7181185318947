import { useProfile } from "../features/authentication/state/profile";
import { useUser } from "../features/authentication/state/user";

export default function withUserProfile(Component) {
	const Wrapper = (props) => {
		const user = useUser();
		const profile = useProfile();

		return <Component {...props} user={user} profile={profile} />;
	};

	Wrapper.displayName = "withUserProfile";

	return Wrapper;
}
