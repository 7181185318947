import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import TeamMemberForm from "../TeamMemberForm/TeamMemberForm";

export default function AddMemberAction(props) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)}>{t("add", "Add")}</Button>

			{open && <TeamMemberForm {...props} onClose={() => setOpen(false)} />}
		</>
	);
}
