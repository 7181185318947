import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminAgreementView from "../views/AdminAgreementView/AdminAgreementView";

export default function AdminAgreement() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("agreements.view")) {
		return <Unauthorized />;
	}

	return <AdminAgreementView id={id} />;
}
