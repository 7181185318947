import auth from "astrid-firebase/src/auth";

export function sendPasswordResetEmail(email) {
	return auth.sendPasswordResetEmail(email, { url: "https://astrid.fm/login/email?email=" + email });
}

export async function confirmPasswordReset(code, newPassword) {
	await auth.confirmPasswordReset(code, newPassword);
	await auth.signOut();
}

export function verifyPasswordResetCode(code) {
	return auth.verifyPasswordResetCode(code);
}
