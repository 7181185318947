import { createSelector } from "reselect";

import selectPermissions from "./selectPermissions";

const selectRoles = createSelector(
	selectPermissions,
	(_, id) => id,
	(permissions, id) =>
		[
			...(permissions.producer?.[id] || []),
			...(permissions.publisher?.[id] || []),
			...(permissions.super ? ["super"] : []),
			...(permissions.reader ? ["reader"] : []),
			...(permissions.finance ? ["finance"] : []),
		].filter((role) => role !== "any"),
);

export default selectRoles;
