import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import ArtifactFilesDataTable from "../../../artifacts/components/ArtifactFilesDataTable/ArtifactFilesDataTable";

export default function AdminArtifactFilesView({ artifact }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("parts", "Files")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<ArtifactFilesDataTable artifact={artifact} />
			</Card.Content>
		</Card>
	);
}
