import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { Imprint } from "./types/Imprint";

const imprints = createFirestoreCollection("imprints", Imprint, {
	preProcess: async ({ api, firebase }, { data }) => {
		// Check if an imprint with the same name already exists
		if (data.name) {
			const imprints = await api.imprints.getAll((ref) =>
				ref
					.where(firebase.firestore.FieldPath.documentId(), "!=", data.ref.id)
					.where("name", "==", data.name)
					.where("deleted", "==", false)
					.limit(1),
			);

			if (imprints.length > 0) {
				throw new Error("An imprint with this name already exists.");
			}
		}

		return data;
	},
});

export default imprints;
