import useForm from "../../../../forms/hooks/useForm";

import offerDefaultValues from "../../../constants/offerDefaultValues";

const today = new Date();

export default function useWorkOrderForm({ offer, production }) {
	return useForm({
		defaultValues: {
			role: offer?.role,
			userId: offer?.secondParty?.id,
			agreementId: null,
			priceId: null,
			firstPartyId: production.producer,
			expiresAt: new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() + offerDefaultValues.EXPIRES_IN_DAYS,
			),
			customAgreement: {
				templateId: null,
				name: "",
				approved: false,
				firstSigneeId: null,
			},
			customPrice: {},
		},
		resetFields: {
			userId: ["role"],
			agreementId: ["role", "userId"],
			priceId: ["role", "userId", "agreementId"],
		},
	});
}
