import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useArticleTranslations() {
	const { t } = useTranslation();

	return useMemo(() => {
		return {
			audioBook: t("audioBook", "Audio book"),
			ebook: t("ebook", "E-book"),
			splitAudioBook: t("sharedAudioBook", "Split audio book"),
		};
	}, [t]);
}
