import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";

class NotificationButton extends Component {
	render() {
		const { t, affectedStaff, onClick, style } = this.props;

		return (
			<Label
				color={affectedStaff.length ? "grey" : null}
				as="a"
				size="large"
				onClick={onClick}
				style={{ ...style, position: "relative" }}
			>
				<Icon name="mail" />
				{t("notify")}
				{!!affectedStaff.length && (
					<Label color="red" size="mini" circular floating>
						{affectedStaff.length}
					</Label>
				)}
			</Label>
		);
	}
}

export default withTranslation()(NotificationButton);
