import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AlertProductonDropdownMenu from "astrid-web/src/features/home/components/AlertsWidget/components/AlertProductonDropdownMenu";

export default function useAlertsColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "title",
				Header: t("title", "Title"),
				disableSortBy: true,
			},

			{
				id: "comment",
				Header: t("commentHomeAlert", "Comment"),
				disableSortBy: true,
			},
			{
				id: "time",
				Header: t("reminderHomeAlert", "Reminder"),
				accessor: ({ time }) => time?.toDate?.().toLocaleString(),
			},
			{
				id: "deliveryDate",
				Header: t("deliveryDate", "Delivery date"),
				collapsing: true,
				accessor: ({ deliveryDate }) => deliveryDate?.toDate?.().toLocaleDateString(),
			},
			{
				id: "actions",
				collapsing: true,
				disableSortBy: true,
				Cell: ({ row }) => {
					return <AlertProductonDropdownMenu production={row.original} />;
				},
			},
		],
		[t],
	);
}
