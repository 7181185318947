import { Divider, Header } from "semantic-ui-react";

import Flex from "../../../../components/Flex/Flex";

const headerStyle = {
	display: "flex",
	gap: 25,
	alignItems: "center",
	margin: "0.2em 0 0 0",
	fontSize: "1.6em",
};

export default function AdminHeader({ text, children, ...props }) {
	return (
		<>
			<Flex>
				<div>
					<Header style={headerStyle} {...props}>
						{text || children}
					</Header>
				</div>
				<div>{text && children && <Flex gap={10}>{children}</Flex>}</div>
			</Flex>
			<Divider />
		</>
	);
}
