import { useEffect, useState } from "react";
import { Image as SemanticImage } from "semantic-ui-react";

export default function ArticleCoverImage({ article, thumb, style, ...props }) {
	const [internalSrc, setInternalSrc] = useState("/img/thumb.jpg");

	useEffect(() => {
		const src = thumb ? article.cover?.thumbUrl : article.cover?.url;

		if (src) {
			const img = new Image();
			img.onload = () => setInternalSrc(src);
			img.src = src;
		}
	}, [article, thumb]);

	return <SemanticImage src={internalSrc} style={{ width: "100%", ...style }} {...props} />;
}
