import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ProductionDropdownMenu from "astrid-web/src/features/productions/components/ProductionDropdownMenu/ProductionDropdownMenu";
import useProductionTypeTranslations from "astrid-web/src/features/productions/hooks/useProductionTypeTranslations";

export default function useAdminProductionsColumns() {
	const { t } = useTranslation();
	const productionTypeTranslations = useProductionTypeTranslations();

	return useMemo(
		() => [
			{
				id: "title",
				filter: "text",
				Header: t("production"),
			},
			{
				id: "publisherName",
				filter: "text",
				Header: t("publisher", "Publisher"),
			},
			{
				id: "productionType",
				filter: "select",
				Header: t("type", "Type"),
				disableSortBy: true,
				accessor: ({ productionType }) => productionTypeTranslations[productionType],
				collapsing: true,
			},
			{
				id: "productionDate",
				Header: t("productionDate", "Production date"),
				accessor: ({ productionDate }) => productionDate?.toDate()?.toLocaleDateString(),
				collapsing: true,
			},
			{
				id: "doneDate",
				Header: t("doneDate"),
				accessor: ({ doneDate }) => doneDate?.toDate()?.toLocaleDateString(),
				collapsing: true,
			},
			{
				id: "menu",
				textAlign: "right",
				disableSortBy: true,
				Cell: ({ row }) => <ProductionDropdownMenu production={row.original} />,
				collapsing: true,
			},
		],
		[productionTypeTranslations, t],
	);
}
