import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Ref } from "semantic-ui-react";

import Discussion from "../Discussion/Discussion";

export default function DiscussionModal({ disabled, onClose, title, ...props }) {
	const { t } = useTranslation();
	const ref = useRef();

	const onLoaded = () => {
		if (ref.current) {
			ref.current.scrollTop = ref.current.scrollHeight;
		}
	};

	return (
		<Modal size="small" open onClose={onClose} style={{ overflow: "hidden" }}>
			<Modal.Header>{t("discussion", "Discussion") + " - " + title}</Modal.Header>
			<Ref innerRef={ref}>
				<Modal.Content scrolling>
					<div style={{ maxWidth: 650, margin: "0 auto" }}>
						<Discussion disabled={disabled} {...props} onLoaded={onLoaded} />
					</div>
				</Modal.Content>
			</Ref>
			<Modal.Actions>
				<Button onClick={onClose}>Close</Button>
			</Modal.Actions>
		</Modal>
	);
}
