import { useTranslation } from "react-i18next";

export default function useArtifactFileDropdownMenuOptions(artifact) {
	const { t } = useTranslation();

	return [
		{
			as: "a",
			text: t("download", "Download"),
			href: artifact?.url,
			download: true,
		},
	];
}
