import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

const typeRoleMap = {
	edit: "producerEditor",
	proof: "producerProofer",
	record: "producerRecorder",
	manage: "producerStaff",
	production: "producerSubcontractor",
};

export default function usePriceTargets({ type, producer } = {}) {
	const [data] = useCollectionDataOnce(
		useMemo(
			() =>
				producer &&
				type &&
				db.collection("users").where(`permissions.producer.${producer}`, "array-contains", typeRoleMap[type]),
			[producer, type],
		),
	);

	return useMemo(() => data?.sort((a, b) => (a.firstName < b.firstName ? -1 : 1)) || [], [data]);
}
