import React from "react";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import OfferCreatedLabel from "./OfferCreatedLabel";
import OfferExpiresLabel from "./OfferExpiresLabel";

const displayExpiredOfferStatues = [offerStatuses.EXPIRED, offerStatuses.SIGNING, offerStatuses.OFFERED];

export default function OfferLabel({ offer }) {
	if (!offer.expiresAt || offer.status === offerStatuses.CANCELLED) {
		return null;
	}

	const showExpires = displayExpiredOfferStatues.includes(offer.status);

	if (showExpires) {
		return <OfferExpiresLabel offer={offer} />;
	}

	return <OfferCreatedLabel offer={offer} />;
}
