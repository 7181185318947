import React from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import db from "astrid-firebase/src/db";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import createOffer from "astrid-firestore/src/api/offers/createOffer";
import notifyOffer from "astrid-firestore/src/api/offers/notifyOffer";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import { getProfile } from "../../../../authentication/state/profile";
import Form from "../../../../forms/components/Form/Form";
import TeamAgreementSelect from "../../../../team/components/TeamAgreementSelect/TeamAgreementSelect";
import TeamPriceSelect from "../../../../team/components/TeamPriceSelect/TeamPriceSelect";
import Message from "../../../../ui/components/Messages/Message";
import ModalPrompt from "../../../../ui/components/ModalPrompt/ModalPrompt";

import offerTypeIcons from "../../../constants/offerTypeIcons";
import useOfferTypeTranslations from "../../../hooks/useOfferTypeTranslations";

import OfferRoleSelect from "../../OfferRoleSelect/OfferRoleSelect";
import OfferUserSelect from "../../OfferUserSelect/OfferUserSelect";

import useWorkOrderForm from "../hooks/useWorkOrderForm";
import createProductionAgreement from "../utils/createProductionAgreement";
import getPrice from "../utils/getPrice";

import CustomAgreementForm from "./CustomAgreementForm";
import CustomPriceForm from "./CustomPriceForm";
import ExpiresAtInput from "./ExpiresAtInput";

export default function WorkOrderForm({ type, offer, production, ...props }) {
	const { t } = useTranslation();

	const types = useOfferTypeTranslations();
	const form = useWorkOrderForm({ offer, production });

	const { watch, formState, setValue, handleSubmit } = form;

	const [role, userId, firstPartyId, agreementId, priceId] = watch([
		"role",
		"userId",
		"firstPartyId",
		"agreementId",
		"priceId",
	]);

	const [user] = useDocumentDataOnce(userId && db.collection("users").doc(userId));
	const [agreement] = useDocumentDataOnce(agreementId && db.collection("agreements").doc(agreementId));
	const [firstParty] = useDocumentDataOnce(firstPartyId && db.collection("organizations").doc(firstPartyId));

	const showContractedBy =
		user && role === teamRoles.READER && production.productionPrice?.agreement?.canManageAgreements;

	const onSubmit = async ({ role, expiresAt, agreementId, customAgreement, priceId, price, customPrice }) => {
		const createdOffer = await createOffer(firebase, {
			id: offer?.id,
			role,
			type: offerTypes.WORK_ORDER,
			price: price || getPrice({ role, priceId, agreementId, customPrice }),
			voiceId: offer?.voiceId,
			approved: priceId !== "custom" || !!customPrice?.approved,
			expiresAt,
			createdBy: getProfile(),
			agreement:
				agreement ||
				createProductionAgreement({
					role,
					production,
					firstParty,
					secondParty: user,
					customAgreement,
					customPrice,
				}),
			production,
		});

		notifyOffer(createdOffer, t).then(
			(notoficationIds) => notoficationIds && console.log("Offer notifications sent:", notoficationIds),
		);
	};

	const isDummyUser = user?.email.includes("dummy");

	return (
		<ModalPrompt
			icon={offerTypeIcons[type]}
			form={form}
			header={types[type]}
			disabled={!formState.isValid || (isDummyUser && agreementId === "custom")}
			confirmText={t("send", "Send")}
			onSave={handleSubmit(onSubmit)}
			{...props}
		>
			<Form form={form}>
				{!offer && (
					<>
						<Form.Group widths="2">
							<ExpiresAtInput name="expiresAt" label={t("expiresAt", "Expires at")} />

							<Form.Field name="role">
								<OfferRoleSelect label={t("role", "Role")} />
							</Form.Field>
						</Form.Group>

						{role && (
							<Form.Field name="userId">
								<OfferUserSelect role={role} production={production} />
							</Form.Field>
						)}
					</>
				)}

				{showContractedBy && (
					<Form.Select
						name="firstPartyId"
						label={t("contractedBy", "Contracted by")}
						options={[
							{
								key: production.producer,
								value: production.producer,
								text: production.producerName,
							},
							{
								key: production.publisher,
								value: production.publisher,
								text: production.publisherName,
							},
						]}
					/>
				)}

				{user && (
					<TeamAgreementSelect
						name="agreementId"
						production={production}
						role={role}
						user={user}
						firstPartyId={firstPartyId}
						additionalOptions={[
							{
								key: "custom",
								value: "custom",
								text: `[${t("customAgreement", "Custom agreement")}]`,
							},
						]}
					/>
				)}

				{agreement && (
					<TeamPriceSelect
						name="priceId"
						role={role}
						user={user}
						agreement={agreement}
						production={production}
						onChangeOption={(option) => setValue("price", option?.data)}
						additionalOptions={[
							{
								key: "custom",
								value: "custom",
								text: `[${t("customPrice", "Custom price")}]`,
							},
						]}
					/>
				)}

				{priceId === "custom" && (
					<CustomPriceForm
						firstParty={firstParty}
						production={production}
						defaultPrice={offer?.price}
						autoApprove
					/>
				)}

				{agreementId === "custom" && !isDummyUser && (
					<CustomAgreementForm user={user} production={production} firstParty={firstParty} />
				)}

				{agreementId === "custom" && isDummyUser && (
					<Message negative>
						{t(
							"canNotSendAgreementToDummyUser",
							"You can't send an agreement to a user with a dummy email",
						)}
					</Message>
				)}
			</Form>
		</ModalPrompt>
	);
}
