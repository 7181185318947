import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

const noActionOfferStatuses = [
	offerStatuses.INTERESTED,
	offerStatuses.ACCEPTED,
	offerStatuses.DECLINED,
	offerStatuses.CANCELLED,
	offerStatuses.EXPIRED,
];

const statusesForWorkOrderThatShouldHideOpenPositions = [
	offerStatuses.OFFERED,
	offerStatuses.SIGNING,
	offerStatuses.ACCEPTED,
];

function offerHasNoAction({ offer, user }) {
	return noActionOfferStatuses.includes(offer.status) || offer.declinedUserIds?.includes(user.id);
}

function userIsAlreadyAssignedToRole({ offer, user }) {
	return offer.production.team?.[user?.id]?.roles?.includes(offer.role);
}

function userHasUnansweredWorkOrderForSameRoleAsOpenPosition({ offer, offers }) {
	if (offer.type === offerTypes.OPEN) {
		const userWorkOrders = offers
			.filter((offer) => offer.type === offerTypes.WORK_ORDER)
			.reduce((acc, offer) => {
				return {
					...acc,
					[offer.production.id]: acc[offer.production.id]
						? acc[offer.production.id].concat({ role: offer.role, status: offer.status })
						: [{ role: offer.role, status: offer.status }],
				};
			}, {});

		const userWorkOrderForSameRole = userWorkOrders?.[offer.production.id]?.find(
			(userWorkOrder) => userWorkOrder.role === offer.role,
		);
		return statusesForWorkOrderThatShouldHideOpenPositions.includes(userWorkOrderForSameRole?.status);
	}
	return false;
}

function offerAcceptedByOtherUser(offer, user) {
	return (
		offer.type === offerTypes.OPEN &&
		offer.secondParty &&
		offer.secondParty.id !== user.id &&
		!offer.declinedUserIds?.includes(user.id)
	);
}

export default function filterOffers({ offers, user }) {
	return offers.filter((offer) => {
		if (offer.type === offerTypes.OPEN && offer.role === teamRoles.RECORDER) {
			return false;
		}

		if (offerAcceptedByOtherUser(offer, user)) {
			return false;
		}

		if (offerHasNoAction({ offer, user })) {
			return true;
		}

		if (userIsAlreadyAssignedToRole({ offer, user })) {
			return false;
		}

		if (userHasUnansweredWorkOrderForSameRoleAsOpenPosition({ offer, offers })) {
			return false;
		}

		return true;
	});
}
