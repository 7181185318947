import React from "react";
import { Grid } from "semantic-ui-react";

export default function HomeLayoutRightColumn({ children }) {
	return (
		<Grid.Column width="10">
			<Grid>{children}</Grid>
		</Grid.Column>
	);
}
