import { createSelector } from "reselect";

import permissions from "../permissions";

import selectRoles from "./selectRoles";

const selectRights = createSelector(selectRoles, (roles) => [
	...new Set(
		roles.reduce((prev, curr) => {
			if (permissions[curr]) {
				prev.push(...permissions[curr]);
			}
			return prev;
		}, []),
	),
]);

export default selectRights;
