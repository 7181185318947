import { Checkbox } from "semantic-ui-react";

import useListProductionsColumnTranslations from "../../../listproductions/views/ListProductionsView/hooks/translations/useListProductionsColumnTranslations";

export default function SelectColumns({ selectedColumns, selectColumn }) {
	const translations = useListProductionsColumnTranslations();
	return (
		<>
			{Object.entries(selectedColumns).map(([columnKey, enabled]) => {
				return (
					<div key={columnKey} style={{ margin: "0.5rem 0 0.5rem 0" }}>
						<Checkbox
							toggle
							checked={enabled}
							value={columnKey}
							label={translations[columnKey]}
							onClick={(_, data) => {
								selectColumn(data.value);
							}}
						/>
					</div>
				);
			})}
		</>
	);
}
