import React from "react";
import { Icon, Label } from "semantic-ui-react";

export default function ArticleLabel({ icon, value }) {
	if (!value) {
		return null;
	}

	return (
		<>
			<Label style={{ marginLeft: 0 }}>
				<Icon name={icon} />
				{value}
			</Label>
		</>
	);
}
