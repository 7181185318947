import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

export default function getNarratorNames(production) {
	return Object.values(production.team || {})
		?.filter(
			(teamMember) => teamMember?.role === teamRoles.READER || teamMember?.roles?.includes?.(teamRoles.READER),
		)
		?.map((reader) => reader?.user?.name)
		?.filter((v) => v)
		.join(",");
}
