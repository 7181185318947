import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import isApproved from "astrid-firestore/src/api/approval/isApproved";

import useApprovalActions from "../../../approval/hooks/useApprovalActions";
import useHasRight from "../../../authorization/hooks/useHasRight";

export default function DropdownItemApprove({ data, right, setLoading }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const { approve } = useApprovalActions(data.ref);

	if (right && !hasRight(right)) {
		return null;
	}

	return (
		<Dropdown.Item
			text={t("approve", "Approve")}
			disabled={isApproved(data)}
			loading
			onClick={async () => {
				setLoading?.(true);

				try {
					await approve();
				} catch (error) {
					console.error(error);
				}

				setLoading?.(false);
			}}
		/>
	);
}
