function addOneAndPad(number) {
	const nextNumber = number + 1;
	return nextNumber.toString().padStart(4, "0");
}

export default function getNextInvoiceNumber({ prevYear, prevNumber, currentYear }) {
	if (!currentYear) {
		throw "Missing argument currentYear";
	}

	const year = currentYear % 100;

	if ((!prevYear && !prevNumber) || prevYear !== year) {
		return `${year}-${addOneAndPad(0)}`;
	}
	return `${year}-${addOneAndPad(prevNumber)}`;
}
