import { Divider, Header, Select } from "semantic-ui-react";

import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";

import usePublisherManagers from "../../../features/users/hooks/usePublisherManagers";

export default function PublisherManager({ production, profile }) {
	const { t } = useTranslation();
	const [publisherManagers, setPublisherManagers] = useState([]);
	const [selectablePublisherManagers, loadingPublisherManagers] = usePublisherManagers(production.publisher);
	const isPubAdminForProd = profile?.permissions?.publisher?.[production.publisher]?.includes("publisherAdmin");
	const isProducerAdmin = profile?.permissions?.producer?.[production.producer]?.includes("producerAdmin");

	useEffect(() => {
		setPublisherManagers(production.publisherManagers?.map((pM) => pM.id) || []);
	}, [production.publisherManagers]);

	const options = useMemo(() => {
		if (selectablePublisherManagers) {
			const publisherManagerOptions = selectablePublisherManagers?.map((pM) => ({
				id: pM.id,
				name: `${pM.firstName} ${pM.lastName}`,
			}));
			const notSelectableButAssigned = production.publisherManagers?.filter(
				(pM) => !publisherManagerOptions.map((option) => option.id)?.includes?.(pM.id),
			);
			if (notSelectableButAssigned?.length) {
				return notSelectableButAssigned.concat(publisherManagerOptions);
			}
			return publisherManagerOptions;
		}
	}, [production.publisherManagers, selectablePublisherManagers]);

	if (isPubAdminForProd || isProducerAdmin) {
		return (
			<div>
				<Divider />
				<Header as="h5" style={{ marginTop: 0 }} content={t("publisherManager")} />
				<Select
					search
					deburr
					multiple
					label={t("publisherManager")}
					value={publisherManagers}
					loading={loadingPublisherManagers}
					options={
						options?.map((pM) => ({
							key: pM.id,
							value: pM.id,
							text: pM.name,
						})) || []
					}
					onChange={(e, { value }) => {
						setPublisherManagers(value);
						updateDocument(production.ref, {
							publisherManagers: value?.map((pMId) => ({
								id: pMId,
								name: options?.find((pM) => pM.id === pMId)?.name,
							})),
							publisherManagerIds: value,
						});
					}}
				/>
			</div>
		);
	}

	const publisherManagerNames = publisherManagers
		?.map((pMId) => {
			const pM = options?.find((option) => option.id === pMId);
			return pM?.name;
		})
		.join(", ");

	return (
		<div>
			<Divider />
			<Header as="h5" style={{ marginTop: 0 }} content={t("publisherManager")} />
			<p>{publisherManagerNames || "-"}</p>
		</div>
	);
}
