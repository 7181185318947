import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Card, Header, Icon } from "semantic-ui-react";

import getSessionPriceAndAgreement from "astrid-firestore/src/api/sessions/getSessionPriceAndAgreement";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import { useProfile } from "../../../../authentication/state/profile";
import mapOffer from "../../../../myproductions/utils/mapOffer";
import OfferInformation from "../../../../offers/views/OfferView/components/OfferInformation";
import useTeamMemberByRole from "../../../../team/hooks/useTeamMemberByRole";

export default function ProductionInformation({ session, production }) {
	const { t } = useTranslation();
	const user = useProfile();

	const [active, setActive] = useState(false);

	const teamMember = useTeamMemberByRole({ team: production.team, role: teamRoles.RECORDER, user });

	const showPrice = session.priceRef && !!teamMember;

	const priceAndAgreement = showPrice && production && getSessionPriceAndAgreement({ session, production, user });
	const { price } = priceAndAgreement || {};

	const mappedOffer = mapOffer({
		offer: { price, production },
		user,
	});

	if (!mappedOffer) {
		return null;
	}

	return (
		<>
			<Accordion.Title active={active} onClick={() => setActive(!active)}>
				<Header size="small">
					<Icon name="dropdown" />
					{t("productionInformation", "Production information")}
				</Header>
			</Accordion.Title>
			<Accordion.Content active={active}>
				<Card fluid>
					<Card.Content>
						<OfferInformation offer={mappedOffer.offer} hidePrice={!showPrice} production={production} />
					</Card.Content>
				</Card>
			</Accordion.Content>
		</>
	);
}
