import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { prodStatus } from "../../../../../helpers/lists";

export default function useUpcomingDeliveriesColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "production.title",
				Header: t("title", "Title"),
				disableSortBy: true,
			},
			{
				id: "obstacles",
				Header: t("missingInfoUpCom"),
				disableSortBy: true,
				Cell({ value }) {
					return value.map((problem, i) => (
						<div key={i}>
							<Icon name="warning circle" color="red" />

							{t(problem)}
						</div>
					));
				},
			},
			{
				id: "status",
				Header: t("status", "Status"),
				disableSortBy: true,
				collapsing: true,
				accessor: ({ production }) => production,
				Cell({ value }) {
					return value.scheduled ? (
						<>
							<Icon name={prodStatus[value.status].icon} color={prodStatus[value.status].color} />
							{t("finished")}
						</>
					) : value.stats && value.stats.pages ? (
						<>
							{value.stats.pages.total < value.pages ? (
								<>
									<Icon name={prodStatus[value.status].icon} color={prodStatus[value.status].color} />
									{t("recInProgress")}
								</>
							) : (
								<>
									<Icon name="sliders" color="green" />
									{t("editProofAndMastering")}
								</>
							)}
						</>
					) : (
						<>
							<Icon name={prodStatus[value.status].icon} color={prodStatus[value.status].color} />
							{/* {prodStatus[value.status].text} */}
							{t("productions:" + value.status)}
						</>
					);
				},
			},
			{
				id: "prodStart",
				Header: t("prodStart", "Start date"),
				disableSortBy: true,
				collapsing: true,
				accessor: ({ production }) => production,
				Cell({ value }) {
					return value.startDate
						? moment(value.startDate.toDate()).format("YYYY-DD-MM")
						: t("notBooked", "Not booked");
				},
			},
			{
				id: "deliveryDate",
				Header: t("deliveryDate", "Start date"),
				disableSortBy: true,
				collapsing: true,
				accessor: ({ production }) => production,
				Cell({ value }) {
					return value.deliveryDate && moment(value.deliveryDate.toDate()).format("YYYY-MM-DD");
				},
			},
		],
		[t],
	);
}
