import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";
import Vendor from "../../../../vendors/components/Vendor";

export default function AdminUserVendors({ user }) {
	const { t } = useTranslation();

	const vendors = Object.entries(user.vendors || {});

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("vendors", "Vendors")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<LoadingContext data={vendors}>
					<Card.Group>
						{vendors.map(([id, vendor]) => {
							return (
								<Card key={id}>
									<Card.Content>
										<Vendor vendor={vendor} />
									</Card.Content>
								</Card>
							);
						})}
					</Card.Group>
				</LoadingContext>
			</Card.Content>
		</Card>
	);
}
