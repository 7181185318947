import equal from "fast-deep-equal";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button, Card, Checkbox } from "semantic-ui-react";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import useOrganizationsOnce from "../../../../organizations/hooks/useOrganizationsOnce";
import Table from "../../../../ui/components/Table/Table";

import useProducersColumns from "../hooks/useProducersColumns";

export default function UserProducersAdmin({ user }) {
	const { t } = useTranslation();

	const producers = useOrganizationsOnce({ type: "producer" });
	const producersColumns = useProducersColumns();

	const [selectedRowIds, setSelectedRowIds] = useState(
		user.adminProducerIds?.reduce((map, id) => ({ ...map, [id]: true }), {}) || {},
	);
	const [someProducers, setSomeProducers] = useState(!!user.adminProducerIds);

	const adminProducerIds = useMemo(
		() => (someProducers ? Object.keys(selectedRowIds) : null),
		[selectedRowIds, someProducers],
	);

	const [{ loading }, onClickSave] = useAsyncFn(async () => {
		await updateDocument(user.ref, { adminProducerIds });
	}, [adminProducerIds, user.ref]);

	const disabled = useMemo(
		() => equal(user.adminProducerIds, adminProducerIds),
		[adminProducerIds, user.adminProducerIds],
	);

	if (producers.length === 0) {
		return false;
	}

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("producerAdmin", "Producer admin")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Checkbox
					slider
					label={someProducers ? t("someProducers", "Some producers") : t("allProducers", "All producers")}
					checked={someProducers}
					onChange={(e, { checked }) => setSomeProducers(checked)}
				/>
			</Card.Content>
			{someProducers && (
				<Card.Content>
					<Table
						sortable
						selectable
						filterable
						pagination
						data={producers}
						columns={producersColumns}
						onSelectedRowIds={setSelectedRowIds}
						initialState={{ pageSize: 5, selectedRowIds }}
						// footer={<Footer user={user} />}
						sortBy={{
							id: "name",
							desc: false,
						}}
					/>
				</Card.Content>
			)}
			<Card.Content>
				<Button.Group>
					<Button primary loading={loading} disabled={disabled} onClick={onClickSave}>
						{t("save")}
					</Button>
				</Button.Group>
			</Card.Content>
		</Card>
	);
}
