import Handlebars from "handlebars";
import { Button } from "semantic-ui-react";

Handlebars.registerHelper("isEbook", (article) => {
	return article.type === "ebook";
});

export default function PreviewMetadata({ article, template }) {
	return (
		<Button
			size="tiny"
			primary
			content="Preview metadata"
			onClick={() => {
				const metadata = Handlebars.compile(template)({ article });
				const blob = new Blob([metadata], { type: "application/xml" });
				const link = document.createElement("a");
				link.download = "preview.xml";
				link.href = window.URL.createObjectURL(blob);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}}
		/>
	);
}
