import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

import { useUser } from "../../../../authentication/state/user";

export default function DeleteCostAction({ cost }) {
	const { t } = useTranslation();

	const user = useUser();

	const onClickDelete = useCallback(
		() =>
			updateDocument(cost.ref, {
				deleted: true,
				deletedBy: {
					id: user.uid,
				},
			}),
		[cost.ref, user.uid],
	);

	return (
		<Dropdown.Item text={t("delete", "Delete")} disabled={!cost.ref || !!cost.orderId} onClick={onClickDelete} />
	);
}
