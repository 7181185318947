import { useState } from "react";
import { Dropdown } from "semantic-ui-react";

import TeamMemberForm from "../../../../team/components/TeamMemberForm/TeamMemberForm";

export default function AddTeamMemberDropdownItem({ offer, production, ...props }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Dropdown.Item onClick={() => setOpen(true)} {...props} />

			{open && (
				<TeamMemberForm
					offer={{ ...offer, agreement: { id: "external" } }}
					production={production}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
}
