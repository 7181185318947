import React from "react";
import { Button, Icon } from "semantic-ui-react";

import useDownloadURL from "astrid-firestore/src/hooks/useDownloadURL";

export default function DownloadURLButton({ bucket, fullPath, children, loading, error }) {
	const [url, urlLoading, urlError] = useDownloadURL({ bucket, fullPath });

	return (
		<Button
			loading={urlLoading || loading}
			disabled={urlLoading || urlError || loading || error}
			negative={urlError || error}
			icon
			as="a"
			target="_blank"
			href={url}
			labelPosition="left"
		>
			<Icon name="file" />
			{children}
		</Button>
	);
}
