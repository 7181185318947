import { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";

export default function BlurInput({ name, value, onChange, ...props }) {
	const [tempValue, setTempValue] = useState(value);

	useEffect(() => {
		setTempValue(value);
	}, [value]);

	return (
		<Form.Input
			{...props}
			name={name}
			value={tempValue}
			onChange={(e) => setTempValue(e.target.value)}
			onBlur={(e) => onChange(e, { name, value: tempValue })}
		/>
	);
}
