import { useMemo } from "react";

import useProductionTypes from "./useProductionTypes";

export default function useProductionTypeOptions() {
	const productionTypes = useProductionTypes();

	return useMemo(
		() =>
			Object.entries(productionTypes).map(([value, text]) => ({
				key: value,
				value,
				text,
			})),
		[productionTypes],
	);
}
