import { boolean, email, mobilePhone, number, object, record, string, vat, zip } from "../../../types/_types";

export const Vendor = object({
	billingInstructions: string(),
	city: string(),
	country: string(),
	email: email(),
	name: string(),
	organizationNumber: string(),
	phone: mobilePhone(),
	primary: boolean(),
	secondaryStreet: string(),
	sortOrder: number(),
	state: string(),
	street: string(),
	vat: vat(),
	zip: zip(),
});

export const Vendors = record(string().min(8), Vendor);
