import { useMemo } from "react";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import useGroupBy from "../../ui/hooks/useGroupBy";

export const initialVoices = { 1: {} };

export default function useProductionVoices({ production, offers }) {
	// sort offers by createdAt
	const sortedOffers = useMemo(() => offers?.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()), [offers]);

	// group offers by reader id
	const offersByReaderId = useGroupBy(sortedOffers, "secondParty.id");

	return useMemo(
		() =>
			Object.entries(production.voices || initialVoices).reduce((map, [id, { name, readerIds = [] }]) => {
				// get the first offer for each reader
				const offers = readerIds
					.map((id) => offersByReaderId[id]?.[0])
					.filter((offer) => offer)
					.map((offer, index, offers) => ({
						...offer,
						voiceId: id,

						// disable all offers if there is an accepted offer
						disabled: offers.some((offer) => offer.status === offerStatuses.ACCEPTED),
					}));

				return {
					...map,
					[id]: {
						id,
						name,
						offers,

						// mark the voice as assigned if there is an accepted offer
						assigned: offers.some((offer) => offer.status === offerStatuses.ACCEPTED),

						// add all reader ids to the voice
						readerIds: offers.map((offer) => offer.secondParty.id),
					},
				};
			}, {}) || [],
		[production.voices, offersByReaderId],
	);
}
