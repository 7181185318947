import { useMemo } from "react";

import { db } from "astrid-firebase";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

export default function useOpenRecorderPositionsQuery({ productionId }) {
	return useMemo(() => {
		if (productionId) {
			return db
				.collection("offers")
				.where("deleted", "==", false)
				.where("production.id", "==", productionId)
				.where("role", "==", teamRoles.RECORDER)
				.where("status", "==", offerStatuses.OFFERED)
				.where("type", "==", offerTypes.OPEN);
		}
		return false;
	}, [productionId]);
}
