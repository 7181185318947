import React from "react";
import { Grid } from "semantic-ui-react";

export default function HomeLayoutLeftColumn({ children }) {
	return (
		<Grid.Column width="6">
			<Grid>{children}</Grid>
		</Grid.Column>
	);
}
