import { useOutletContext, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import ArticleView from "../views/ArticleView/ArticleView";

export default function Article() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const [article, loading, error] = useDocumentDataOnce(db.collection("articles").doc(id));

	return (
		<LoadingContext data={article} loading={loading} error={error}>
			<ArticleView article={article} organization={organization} />
		</LoadingContext>
	);
}
