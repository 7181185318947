import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import useUserOptions from "../../../../users/hooks/useUserOptions";

export default function FirstSigneeSelect({ firstParty }) {
	const { t } = useTranslation();
	const { setValue } = useFormContext();

	const [userOptions, loading, error] = useUserOptions({ producer: firstParty?.id || null, role: "producerAdmin" });

	return (
		<Form.Select
			label={t("adminSignee", "Admin signee")}
			name="firstSigneeId"
			options={userOptions}
			loading={loading}
			error={error}
			search
			onChangeOption={(option) => setValue("firstSignee", option?.data)}
		/>
	);
}
