export function getTeamMembersByRole({ production, role }) {
	return Object.values(production?.team || {})
		.filter((member) => member.role === role)
		.map((member) => ({
			id: member?.user?.id,
			img: member?.user?.img,
			name: member?.user?.name,
			email: member?.user?.email,
		}));
}
