import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import storage from "astrid-firebase/src/storage";
import { addDocument, updateDocument } from "astrid-firestore/src/helpers";

import UploadButton from "../../../../../components/UploadButton";
import { useProfile } from "../../../../authentication/state/profile";
import { useOrganizationId } from "../../../../organizations/state/organizationId";

const bucketRef = storage.refFromURL(window.ES.stage ? "gs://stage-earselect-static" : "gs://earselect-static");

export default function UploadDocumentModal({ setOpenModal, document }) {
	const profile = useProfile();
	const [file, setFile] = useState(document);
	const [title, setTitle] = useState(document ? document.title : "");
	const [roleAccess, setRoleAccess] = useState(document ? document.roleAccess : ["producerAdmin"]);
	const [loading, setLoading] = useState(false);

	const { t } = useTranslation();
	const orgId = useOrganizationId();

	const onUpload = (e) => {
		setFile(e.target.files?.[0]);
	};

	const onSave = async () => {
		setLoading(true);
		if (!document) {
			const fileRef = bucketRef.child(`documents/${orgId}/${file.name}`);

			await fileRef.put(file);

			const { name, bucket, fullPath } = fileRef;

			const url = await fileRef.getDownloadURL();

			await addDocument(db.collection("organizations").doc(orgId).collection("documents"), {
				title,
				file: { url, name, bucket, fullPath },
				created: new Date(),
				createdBy: { id: profile.id, name: profile.firstName + " " + profile.lastName },
				roleAccess,
			});
		} else {
			await updateDocument(document.ref, { title, roleAccess });
		}

		setOpenModal(false);
		setLoading(false);
	};

	const onChangeRoles = (role) => {
		setRoleAccess((roleAccess) => {
			return roleAccess.includes(role) ? roleAccess.filter((r) => !r.includes(role)) : [...roleAccess, role];
		});
	};

	const onCancel = () => {
		setOpenModal(false);
	};

	return (
		<Modal open>
			<Modal.Content size="large">
				<Form>
					{!document && (
						<UploadButton
							text={file ? file.name : t("chooseFile", "Choose file")}
							onUpload={onUpload}
							size="small"
						/>
					)}
					<Form.Input
						width={8}
						placeholder={t("addTitle", "Add title here...")}
						onChange={(e) => setTitle(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
						value={title}
					/>
					<p style={{ marginBottom: 10 }}>{t("visibleFor")}:</p>
					<div style={{ display: "flex", gap: "20px" }}>
						<Form.Field>
							<Form.Checkbox label={t("producerAdmin")} checked disabled />
						</Form.Field>
						<Form.Field>
							<Form.Checkbox
								label={t("producerStaff")}
								checked={roleAccess.includes("producerStaff")}
								onChange={() => onChangeRoles("producerStaff")}
							/>
						</Form.Field>
						<Form.Field>
							<Form.Checkbox
								label={t("producerRecorder")}
								checked={roleAccess.includes("producerRecorder")}
								onChange={() => onChangeRoles("producerRecorder")}
							/>
						</Form.Field>
						<Form.Field>
							<Form.Checkbox
								label={t("producerEditor")}
								checked={roleAccess.includes("producerEditor")}
								onChange={() => onChangeRoles("producerEditor")}
							/>
						</Form.Field>
						<Form.Field>
							<Form.Checkbox
								label={t("producerProofer")}
								checked={roleAccess.includes("producerProofer")}
								onChange={() => onChangeRoles("producerProofer")}
							/>
						</Form.Field>
						<Form.Field>
							<Form.Checkbox
								label={t("producerSubcontractor")}
								checked={roleAccess.includes("producerSubcontractor")}
								onChange={() => onChangeRoles("producerSubcontractor")}
							/>
						</Form.Field>
					</div>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onCancel} default>
					{t("cancel")}
				</Button>
				<Button loading={loading} onClick={onSave} primary disabled={!title || !file}>
					{t("save")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
