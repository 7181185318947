import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useVendorOptions(vendors) {
	const { t } = useTranslation();

	return useMemo(
		() =>
			Object.entries(vendors || {})
				.sort(([, a], [, b]) => Number(b.primary) - Number(a.primary))
				.map(([id, vendor]) => ({
					key: id,
					value: id,
					text: `${vendor.name} - ${vendor.email || t("missingEmail", "Missing email")}`,
					description: vendor.description ? `[${vendor.description}]` : "",
				})),
		[t, vendors],
	);
}
