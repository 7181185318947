import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { useProfile } from "../../../../authentication/state/profile";

import SessionContext from "../../SessionContext";

export default function GoToProduction() {
	const profile = useProfile();
	const { t, session, production, sessionRoles } = useContext(SessionContext);

	return session.recorder === profile.id || sessionRoles.isAdminOrProducerStaff ? (
		<Button as={Link} to={"/production/" + production?.id} content={t("toProduction")} color="blue" />
	) : null;
}
