import createDocumentRef from "./createDocumentRef";

export default function createDocumentData({ id = null, ...data }, { firebase, collection, getUser }) {
	if (!firebase) {
		throw new Error("Firebase is required");
	}

	if (!collection) {
		throw new Error("Collection is required");
	}

	const ref = createDocumentRef(collection, id);

	return {
		id: ref.id,
		ref,
		...data,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		createdBy: getUser(),
	};
}
