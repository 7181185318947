import { teamRoles } from "../../../../constants/teamRoles";
import todoTypes from "../../../../constants/todoTypes";

import organizationTypes from "../../../organizations/constants/organizationTypes";

import getOfferProductionManagers from "../../helpers/team/managers/getOfferProductionManagers";

import createTodoData from "../todos/createTodoData";

export default function createCommentOnOfferTodoData(firebase, { offer, comment, organizationType }) {
	const organizationIds = [
		organizationType !== organizationTypes.PRODUCER && offer.producer.id,
		offer.discussionVisibleForPublisher && organizationType !== organizationTypes.PUBLISHER && offer.publisher.id,
	].filter(Boolean);

	const productionManagers = getOfferProductionManagers({ offer });

	const commentedBySecondParty = comment.user.id === offer.secondParty.id;

	const offerRoleIsReader = offer.role === teamRoles.READER;

	const route = offerRoleIsReader ? `/narrator/${offer.secondParty.id}` : `/producer/${offer.producer.id}`;

	return createTodoData(firebase, {
		type: todoTypes.NEW_COMMENT_ON_OFFER,
		organizationIds,
		userIds: commentedBySecondParty ? productionManagers.map((pM) => pM.id) : [offer.secondParty.id],
		users: productionManagers,
		subjectRef: offer.ref,
		url: commentedBySecondParty
			? `/production/${offer.production?.id}/${offerRoleIsReader ? "readers" : "team"}`
			: `${route}/my-productions/offers/${offer.id}`,
		notification: true,
		languages: [offer.production.language],
		content: {
			offer: {
				type: offer.type,
				id: offer.id,
			},
			comment: {
				id: comment.id,
				text: comment.text,
			},
			user: {
				id: comment.user.id,
				name: comment.user.name,
			},
			production: {
				title: offer?.production?.title,
				id: offer?.production.id,
			},
			publisher: {
				id: offer?.publisher?.id,
				name: offer?.publisher?.name,
			},
		},
	});
}
