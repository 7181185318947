export default {
	name: "Bokbasen",
	format: "wav",
	connection: "ftp",
	metaFirst: "bokbasenApi", // meta must be sent via API forst
	audio: {
		// should the audio files be zipped?
		zip: true,
	},
	disableArticles: ["cd", "mp3cd"],
};
