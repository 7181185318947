import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

export default function OrganizationDropdownMenu({ organization }) {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	return (
		<DropdownMenu>
			{() => (
				<>
					<Dropdown.Item as={Link} to={`../${organization.id}`}>
						{t("showMore", "Show more")}
					</Dropdown.Item>

					<Dropdown.Item as={Link} to={`/${organization.type}/${organization.id}/admin`} state={{ pathname }}>
						{t("manage", "Manage")}
					</Dropdown.Item>
				</>
			)}
		</DropdownMenu>
	);
}
