import { Segment } from "semantic-ui-react";

import FlexTableCell from "./FlexTableCell";

const style = {
	display: "flex",
	fontWeight: "600",
};

export default function FlexTableHeader({ gap, columns }) {
	return (
		<Segment secondary style={{ gap, ...style }}>
			{columns.map((column) => (
				<FlexTableCell key={column.id} {...column} Cell={() => column.Header || null} />
			))}
		</Segment>
	);
}
