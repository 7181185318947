import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import Table from "../../../../ui/components/Table/Table";

import useSecondaryAgreementParties from "../../../hooks/useSecondaryAgreementParties";

import useSecondaryPartiesColumns from "../hooks/useSecondaryPartiesColumns";

function SecondaryPartiesTable({ part, firstParty, onChange }) {
	const { t } = useTranslation();

	const secondaryPartiesColumns = useSecondaryPartiesColumns({ part });
	const secondaryParties = useSecondaryAgreementParties({ part, firstPartyId: firstParty.id });

	if (secondaryParties.length === 0) {
		return t("noPartiesFound", "No parties found");
	}

	return (
		<Table
			data={secondaryParties}
			footer={false}
			columns={secondaryPartiesColumns}
			sortable
			selectable
			filterable
			pagination
			sortBy={{ id: "name", desc: false }}
			initialState={{ pageSize: 10 }}
			onSelectRows={(parties) =>
				onChange(null, {
					value: parties.reduce(
						(map, party) => ({
							...map,
							[party.rowId]: party,
						}),
						{},
					),
				})
			}
		/>
	);
}

export default function SecondaryParties({ part, firstParty, multiple = true }) {
	return (
		<Form.Field
			name="parties"
			rules={{
				validate: (value) =>
					Object.keys(value).length > 0 &&
					(part !== "publisher" || Object.values(value).every(({ signeeId }) => signeeId)),
			}}
		>
			<SecondaryPartiesTable part={part} firstParty={firstParty} />
		</Form.Field>
	);
}
