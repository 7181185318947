import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import agreementParts from "astrid-firestore/src/api/agreements/constants/agreementParts";

export default function useCostTypeOptions(part = agreementParts.USER) {
	const { t } = useTranslation();

	const byType = useMemo(
		() => ({
			user: {
				record: t("priceRecord"),
				edit: t("priceEdit"),
				proof: t("priceProof"),
				manage: t("priceManage"),
			},
			narrator: {
				reader: t("priceNarration"),
			},
			subcontractor: {
				production: t("priceSubcontractor"),
			},
			publisher: {
				production: t("priceProduction", "Studio"),
				cloudAudiobook: `${t("priceCloud")}: ${t("cloudAudiobook")}`,
				cloudEbook: `${t("priceCloud")}: ${t("cloudEbook")}`,
				extra: t("priceExtra"),
			},
		}),
		[t],
	);

	return useMemo(
		() =>
			(Array.isArray(part) ? part : [part])
				.map((part) =>
					Object.entries(byType[part] || {}).map(([key, text]) => ({
						key,
						text,
						value: key,
					})),
				)
				.flat(),
		[byType, part],
	);
}
