import React from "react";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminTranslatorMenuOptions from "../hooks/useAdminTranslatorMenuOptions";

export default function AdminTranslatorHeader({ translator }) {
	const menuOptions = useAdminTranslatorMenuOptions({ translator });

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<Header style={{ margin: 0 }}>{translator.name}</Header>
		</AdminPageHeader>
	);
}
