import { Form, Loader, Segment } from "semantic-ui-react";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { db, firebase } from "astrid-firebase";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import LoadingContext from "../features/ui/components/LoadingContext/LoadingContext";

import Upload from "./Upload";

const publisherExternalUpload = firebase.functions().httpsCallable("publisherExternalUpload");

const ExternalUpload = ({ match }) => {
	const { publisherId, language } = match.params;

	const [uid, setUid] = useState(null);
	const [loginChecked, setLoginChecked] = useState(false);

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user?.isAnonymous) {
				setUid(user.uid);
			}

			setLoginChecked(true);
		});
	}, []);

	if (!loginChecked) {
		return (
			<div style={{ margin: "5% auto", textAlign: "center" }}>
				<Loader inline active />
			</div>
		);
	}

	if (uid) {
		return <LoggedIn uid={uid} publisherId={publisherId} language={language} />;
	}

	return <LoginForm publisherId={publisherId} language={language} />;
};

const LoggedIn = ({ uid, publisherId, language }) => {
	const [productions, loading, error] = useCollectionDataOnce(
		db
			.collection("productions")
			.where("publisher", "==", publisherId)
			.where("language", "==", language)
			.where("deleted", "==", false),
	);

	return (
		<LoadingContext loading={loading} error={error} data={productions}>
			{productions && (
				<Upload external productions={productions} language={language} uid={uid} publisherId={publisherId} />
			)}
		</LoadingContext>
	);
};

const LoginForm = ({ publisherId, language }) => {
	const { t } = useTranslation();

	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");

	const onSubmit = async () => {
		setError(false);
		setLoading(true);

		try {
			const { data } = await publisherExternalUpload({
				publisher: publisherId,
				password,
				language,
			});

			if (data) {
				await firebase.auth().signInAnonymously();
			} else {
				setError(true);
			}
		} catch (error) {
			setError(error);
		}

		setLoading(false);
	};

	return (
		<Segment compact style={{ margin: "5% auto" }}>
			<Form loading={loading} onSubmit={onSubmit}>
				<Form.Input
					label={t("password", "Password")}
					type="password"
					error={error}
					value={password}
					onChange={(e, { value }) => setPassword(value)}
				/>

				<Form.Button primary fluid content={t("loginBtn", "Sign in")} disabled={!publisherId || !password} />
			</Form>
		</Segment>
	);
};

export default ExternalUpload;
