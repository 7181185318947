import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";
import create from "zustand";

export const useMetaGuide = create((set, get) => ({
	// temporary state context for options enabling/disabling different checks
	options: {},
	setOption: (key, value) => {
		const { options } = get();
		set({ options: { ...options, [key]: value } });
	},

	alerts: {},
	initiated: false,
	setAlerts: (alerts) => {
		set({ alerts });

		if (!Object.keys(alerts).length) set({ initiated: false, current: "" });
	},

	current: "",
	goTo: (stepId, history) => {
		const { alerts } = get();
		const step = alerts[stepId];
		console.log("Metaguide step", stepId, step);
		set({ current: stepId, initiated: !!Object.keys(alerts).length });

		if (history && step?.url && history?.location?.pathname !== step.url) {
			history.push(step.url);
		}

		// scroll to element
		setTimeout(() => {
			const el = document.querySelector("#meta-popup");
			if (el) el.scrollIntoView({ behavior: "smooth", block: "center" });
		}, 100);
	},
	close: () => {
		set({ current: "" });
	},

	checkMissing: ({ history }) => {
		const { current, goTo, alerts } = get();
		console.log("checkMissing alerts", alerts);
		const qs = new URLSearchParams(window.location.search);
		const queryStep = qs.get("guide");

		if (!current && queryStep && alerts[queryStep]) {
			// go to url query step
			// console.log("alerts[queryStep]", alerts[queryStep]);
			goTo(queryStep, history);
		} else if (!current || !alerts[current]) {
			const urlMatch = Object.entries(alerts).find(([id, alert]) => alert.url === history?.location?.pathname);

			if (urlMatch) {
				// go to next step for current url
				// console.log("urlmatch", urlMatch);
				goTo(urlMatch[0], history);
			} else {
				// go to first step for any url
				const firstAlert = Object.keys(alerts)[0];
				// console.log("firstAlert", firstAlert);
				if (firstAlert) goTo(firstAlert, history);
			}
		} else if (current && alerts[current] && alerts[current].url !== history?.location?.pathname) {
			// current is no longer at correct url, go back
			goTo(current, history);
		}

		// console.log(alerts, current);

		return { length: Object.keys(alerts).length, current };
	},
}));

export const withMetaGuide = (Component) => (props) => {
	const checkMissing = useMetaGuide((state) => state.checkMissing);
	const current = useMetaGuide((state) => state.current);
	const options = useMetaGuide((state) => state.options);
	const setOption = useMetaGuide((state) => state.setOption);
	const close = useMetaGuide((state) => state.close);

	return (
		<Component
			{...props}
			checkMissing={checkMissing}
			currentMetaGuide={current}
			closeMetaGuide={close}
			options={options}
			setOption={setOption}
		/>
	);
};

export const MetaPopup = ({ id, text, children }) => {
	const current = useMetaGuide((state) => state.current);
	const close = useMetaGuide((state) => state.close);
	const alerts = useMetaGuide((state) => state.alerts);
	const isOpen = current === id && alerts[id];
	const { t } = useTranslation();

	return (
		<Popup
			content={
				<>
					<h5 style={{ paddingRight: "2.5em" }}>
						<Icon name="clipboard check" />
						{t("metaMissing")}
					</h5>
					<p style={{ lineHeight: "1" }}>
						<small>{text || t("metaMustFillOut")}</small>
					</p>
					<Icon name="close" onClick={close} className="pointer" />
				</>
			}
			open={!!isOpen}
			id="meta-popup"
			trigger={children || <span />}
		/>
	);
};
