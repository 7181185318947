import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useCostTypes(expense = false) {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			custom: t("priceCustom", "Custom"),
			edit: t("priceEdit"),
			proof: t("priceProof"),
			extra: t("priceExtra"),
			record: t("priceRecord"),
			manage: t("priceManage"),
			reader: t("priceNarration"),
			production: expense ? t("priceSubcontractor") : t("priceProduction", "Studio"),
			cloudEbook: `${t("priceCloud")}: ${t("cloudEbook")}`,
			cloudAudiobook: `${t("priceCloud")}: ${t("cloudAudiobook")}`,
		}),
		[expense, t],
	);
}
