import { Link } from "react-router-dom";
import { Card, Divider } from "semantic-ui-react";

import TodoAssignedTo from "./TodoAssignedTo";
import TodoContent from "./TodoContent";
import TodoHeader from "./TodoHeader";
import TodoProduction from "./TodoProduction";

export default function Todo({ todo }) {
	const { content, users, userNames, url } = todo;

	const showContent = !!content;
	const showAssigned = users?.length > 0 || userNames?.length > 0;
	const isResolved = todo.resolved.status;
	const showDivider = showContent || showAssigned || isResolved;

	return (
		<Card
			as={!isResolved && Link}
			fluid
			style={{
				borderRadius: 5,
				cursor: "pointer",
				color: "black",
				border: isResolved ? "1px solid #21ba45" : null,
				boxShadow: isResolved && "none",
			}}
			to={url}
		>
			<Card.Content>
				<TodoHeader todo={todo} />

				{showDivider && <Divider style={{ margin: "10px 0" }} />}

				{showContent && (
					<>
						<TodoProduction todo={todo} />
						<TodoContent todo={todo} />
					</>
				)}

				<TodoAssignedTo todo={todo} />
			</Card.Content>
		</Card>
	);
}
