import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import useRefinement from "../hooks/useRefinement";

export default function GenderButtons() {
	const { t } = useTranslation();

	const { value, refine } = useRefinement({
		attribute: "sex",
	});

	const buttons = [
		{
			icon: "woman",
			content: t("female"),
			value: "female",
		},
		{
			icon: "man",
			content: t("male"),
			value: "male",
		},
		{
			icon: "transgender",
			content: t("other"),
			value: "other",
		},
	];

	return (
		<>
			{buttons
				.map((button) => ({ ...button, active: value?.includes(button.value) }))
				.map((button) => (
					<Button
						basic
						animated="vertical"
						key={button.value}
						primary={button.active}
						onClick={() => refine(button.active ? [] : [button.value])}
					>
						<Button.Content hidden>{button.content}</Button.Content>
						<Button.Content visible>
							<Icon name={button.icon} />
						</Button.Content>
					</Button>
				))}
		</>
	);
}
