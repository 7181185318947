import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAgreementOptions from "../../agreements/hooks/useAgreementOptions";
import rolePartMap from "../../agreements/utils/rolePartMap";

export default function useTeamAgreementOptions({ role, user, language, firstPartyId, firstPartyVendorId }) {
	const { t } = useTranslation();

	const [agreementOptions, loading, error] = useAgreementOptions({
		role,
		part: rolePartMap[role],
		firstPartyId,
		firstPartyVendorId,
		secondPartyId: user?.id || null,
	});

	const defaultAgreementId = user?.prices?.[firstPartyId]?.[`${language}-${role}`]?.agreement?.id;

	const options = useMemo(
		() =>
			agreementOptions.map((option) => ({
				...option,
				text: option.text + (option.data.id === defaultAgreementId ? ` [${t("default")}]` : ""),
				defaultOption: option.data.id === defaultAgreementId,
			})),

		[agreementOptions, defaultAgreementId, t],
	);

	return [options, loading, error];
}
