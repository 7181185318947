import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card } from "semantic-ui-react";

import useAgreementColumns from "../../../../agreements/hooks/useAgreementColumns";
import useAgreementsQuery from "../../../../agreements/hooks/useAgreementsQuery";
import CollectionDataTable from "../../../../ui/components/CollectionDataTable/CollectionDataTable";

export default function AdminPublisherAgreements({ publisher, organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = useAgreementsQuery({ firstPartyId: organization.id, secondPartyId: publisher.id });
	const columns = useAgreementColumns();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("agreements", "Agreements")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<CollectionDataTable
					query={query}
					columns={columns}
					hiddenColumns={["secondParty.name"]}
					onClick={({ id }) => navigate(`../../agreements/${id}`)}
					sortBy={{
						id: "created",
						desc: true,
					}}
				/>
			</Card.Content>
		</Card>
	);
}
