import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import useOrganizationOptions from "../../../../organizations/hooks/useOrganizationOptions";
import Select from "../../../../ui/components/Select/Select";

export default function useBiblioImprintsColumns(form) {
	const { t } = useTranslation();
	const { setValue } = form;

	const publishers = useOrganizationOptions({ type: "publisher" });

	return useMemo(
		() => [
			{
				id: "description",
				flex: 1,
				Header: "Imprint",
				accessor: ({ id, description, comment }) => `${description}${comment ? ` (${comment})` : ""} [${id}]`,
			},
			{
				id: "publisher",
				flex: 1,
				Header: t("publisher"),
				Cell: ({ row }) => {
					setValue(
						`imprints.${row.original.id}.name`,
						`${row.original.description}${row.original.comment ? ` (${row.original.comment})` : ""}`,
					);

					return (
						<Form.Field name={`imprints.${row.original.id}.publisherId`} rules={{ required: false }}>
							<Select
								search
								options={[
									{
										key: null,
										value: null,
										text: t("none", "None"),
									},
									...publishers,
								]}
							/>
						</Form.Field>
					);
				},
			},
		],
		[publishers, setValue, t],
	);
}
