import { AuthorRef } from "../api/authors/types/Author";
import { ImprintRef } from "../api/imprints/types/Imprint";
import { SerieRef } from "../api/series/types/Serie";
import { TranslatorRef } from "../api/translators/types/Translator";

import { StorageFile } from "./StorageFile";
import { array, int, nullable, object, string } from "./_types";

export const MetaData = object({
	additionalLanguages: array(string()),
	author: nullable(string()),
	authors: array(AuthorRef),
	authorIds: array(string()),
	translators: array(TranslatorRef),
	translator: nullable(string()),
	translatorIds: array(string()),
	cover: nullable(
		StorageFile.extend({
			thumbUrl: nullable(string()),
		}),
	),
	imprint: nullable(ImprintRef),
	language: nullable(string()),
	name: string(3),
	originalLanguage: nullable(string()),
	originalName: nullable(string()),
	serie: nullable(SerieRef),
	serieNumber: nullable(int()),
	synopsis: nullable(string()),
	themaCodes: array(string()),
});
