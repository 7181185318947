import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useApproveOffersColumns from "./hooks/useApproveOffersColumns";

export default function SuperAdminApproveOffersView({ offers, loading, error }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useApproveOffersColumns();

	return (
		<>
			<AdminHeader>
				{t("needsApproval", "Needs approval")} - {t("offers", "Offers")}
			</AdminHeader>

			<DataTable
				data={offers}
				error={error}
				loading={loading}
				columns={columns}
				onClick={({ production }) => navigate(`/production/${production.id}/team`)}
				sortBy={{ id: "expiresAt", desc: false }}
			/>
		</>
	);
}
