import React from "react";
import { useTranslation } from "react-i18next";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import Todo from "./components/Todo";

export default function Todos({ todos, loading, error }) {
	const { t } = useTranslation();

	return (
		<LoadingContext data={todos} loading={loading} error={error} noDataAvailableMessage={t("noTodos", "No todos")}>
			{todos?.map((todo) => (
				<Todo key={todo.id} todo={todo} />
			))}
		</LoadingContext>
	);
}
