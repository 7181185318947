import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

export default function PricesFormTableActions({ onAdd }) {
	const { t } = useTranslation();
	const { watch } = useFormContext();

	const prices = watch("prices");

	return (
		<Button.Group>
			<Button onClick={() => onAdd(prices[prices.length - 1])}>{t("add", "Add")}</Button>
		</Button.Group>
	);
}
