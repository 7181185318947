import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Button, Card, List, Loader, Message, Radio } from "semantic-ui-react";

import countries from "astrid-config/src/countries";

import { isOkToDelete } from "./validators/vendorValidator";

export default function DisplayVendor({
	isProducer,
	vendorKey,
	vendor,
	setIsEditing,
	saveVendor,
	deleteVendor,
	disablePrimaryChoice,
}) {
	const { t } = useTranslation();

	const [confirmDelete, setConfirmDelete] = useState(false);
	const [isValidatingDelete, setIsValidatingDelete] = useState(false);
	const [notOkToDeleteMessage, setNotOkToDeleteMessage] = useState(false);

	const onConfirmDelete = useCallback(async () => {
		setConfirmDelete(false);
		setIsValidatingDelete(true);

		const okToDelete = await isOkToDelete(vendorKey, isProducer);

		setIsValidatingDelete(false);

		if (okToDelete) {
			deleteVendor(vendorKey);
		} else {
			setNotOkToDeleteMessage(true);
		}
	}, [deleteVendor, isProducer, vendorKey]);

	return (
		<Card key={vendorKey}>
			<Card.Content>
				<List divided>
					{vendor.name && (
						<List.Item>
							<List.Header as="h5">{t("vendorName")}</List.Header>
							{vendor.name}
						</List.Item>
					)}
					{vendor.organizationNumber && (
						<List.Item>
							<List.Header as="h5">{t("vendorOrgNo")}</List.Header>
							{vendor.organizationNumber}
						</List.Item>
					)}
					{vendor.description && (
						<List.Item>
							<List.Header as="h5">{t("description")}</List.Header>
							{vendor.description}
						</List.Item>
					)}
					{vendor.street && (
						<List.Item>
							<List.Header as="h5">{t("vendorAddr1")}</List.Header>
							{vendor.street}
						</List.Item>
					)}
					{vendor.secondaryStreet && (
						<List.Item>
							<List.Header as="h5">{t("vendorAddr2")}</List.Header>
							{vendor.secondaryStreet}
						</List.Item>
					)}
					{vendor.zip && (
						<List.Item>
							<List.Header as="h5">{t("vendorZip")}</List.Header>
							{vendor.zip}
						</List.Item>
					)}
					{vendor.city && (
						<List.Item>
							<List.Header as="h5">{t("vendorCity")}</List.Header>
							{vendor.city}
						</List.Item>
					)}
					{vendor.state && (
						<List.Item>
							<List.Header as="h5">{t("vendorState")}</List.Header>
							{vendor.state}
						</List.Item>
					)}
					{vendor.country && (
						<List.Item>
							<List.Header as="h5">{t("country")}</List.Header>
							{countries[vendor.country]}
						</List.Item>
					)}
					{vendor.email && (
						<List.Item>
							<List.Header as="h5">{t("email")}</List.Header>
							{vendor.email}
						</List.Item>
					)}
					{vendor.phone && (
						<List.Item>
							<List.Header as="h5">{t("vendorPhone")}</List.Header>
							{formatPhoneNumberIntl(vendor.phone)}
						</List.Item>
					)}
					{vendor.vat && (
						<List.Item>
							<List.Header as="h5">{t("vendorVAT", "VAT number")}</List.Header>
							{vendor.vat}
						</List.Item>
					)}
					{vendor.billingInstructions && (
						<List.Item style={{ whiteSpace: "pre-wrap" }}>
							<List.Header as="h5">{t("billingInstructions", "Billing instructions")}</List.Header>
							{vendor.billingInstructions}
						</List.Item>
					)}
					<List.Item>
						<List.Header as="h5">{t("defaultVendor")}</List.Header>
						<Radio
							toggle
							style={{ marginTop: "5px" }}
							checked={!!vendor.primary}
							disabled={disablePrimaryChoice && !vendor.primary}
							onChange={(e, data) => {
								saveVendor(vendorKey, { ...vendor, primary: data.checked });
							}}
						/>
					</List.Item>
				</List>
				{notOkToDeleteMessage && (
					<Message
						error
						header={t("actionForbidden")}
						content={t("notOkToDeleteVendor")}
						onDismiss={() => setNotOkToDeleteMessage(false)}
					/>
				)}
			</Card.Content>
			{!confirmDelete && !isValidatingDelete && (
				<div style={{ display: "flex", margin: "10px", justifyContent: "flex-end" }}>
					<Button icon="pencil" primary onClick={() => setIsEditing(true)} />
					<Button color="red" icon="trash" floated="right" onClick={() => setConfirmDelete(true)} />
				</div>
			)}
			{confirmDelete && (
				<Card.Content>
					<p>
						<strong>{`${t("confirmDeleteInvoicingDetails")} ${vendor.name}?`}</strong>
					</p>
					<div className="ui two buttons">
						<Button color="red" icon="checkmark" onClick={onConfirmDelete} />
						<Button
							icon="close"
							onClick={() => {
								setConfirmDelete(false);
							}}
						/>
					</div>
				</Card.Content>
			)}
			{isValidatingDelete && (
				<Card.Content>
					<Loader active centered inline="centered" />
				</Card.Content>
			)}
		</Card>
	);
}
