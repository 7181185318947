import { useMemo } from "react";

import useProducerTypes from "./useProducerTypes";

export default function useProducerTypeOptions() {
	const producerTypes = useProducerTypes();

	return useMemo(
		() =>
			Object.entries(producerTypes).map(([value, text]) => ({
				key: value,
				value,
				text,
			})),
		[producerTypes],
	);
}
