import get from "lodash/get";
import moment from "moment";

import { Button, Icon, Loader, Popup } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import distConfig from "astrid-config/src/distributors";
import { db, firebase } from "astrid-firebase";
// import { toDate } from "astrid-web/src/helpers/fnc";
import { toDate } from "astrid-web/src/helpers/fnc";

import { getDistOptions, sendToDistributor } from "../fnc";

const MasterExportDistribution = ({
	exp,
	id,
	production,
	productionId,
	publisher,
	profile,
	uid,
	article,
	productionRights,
	distributors,
}) => {
	const styles = {
		distRow: { display: "table-row", marginBottom: 8 },
		distCol: { display: "table-cell" },
		buttonGroup: { margin: "2px 0 0 5px" },
		buttonRight: { borderLeft: "1px solid" },
	};

	const distOptions = getDistOptions({ production, publisher });
	const articleType = article.includes("part_") ? "part" : article;
	const { t } = useTranslation();

	const metaPath =
		articleType === "total"
			? "" // root
			: articleType === "part"
			? "deliveryParts[" + article.split("_")[1] + "]." // part
			: "deliveryEbook."; // ebook

	return distributors ? (
		Object.entries(distConfig)
			.filter(([dist, info]) => info.audio)
			.map(([dist, info]) => {
				// convert old batched statuses
				if (exp.distributor && exp.distributor[dist] === "sent") {
					exp.distributor[dist] = {
						status: "sent",
						sent: firebase.firestore.Timestamp.fromDate(new Date("2019-06-30")),
					};
				}

				const queueSend = (audioOnly) => {
					const articles = { [productionId]: { [article]: id } };
					sendToDistributor(dist, articles, profile, uid, audioOnly);

					// set export status to queued
					// prettier-ignore
					const distData = {
						["master." + article + ".exports." + id + ".distributor." + dist + ".status"]: "queued",
						["master." + article + ".exports." + id + ".distributor." + dist + ".sent"]: firebase.firestore.FieldValue.serverTimestamp(),
					};

					db.collection("productions")
						.doc(productionId)
						.update(distData)
						.catch((err) => {
							console.log(err);
						});
				};

				const icon = info.connection ? "server" : "file archive";
				const metaFirst =
					info.metaFirst && !get(production, metaPath + "release." + info.metaFirst + ".metaSent");

				const isbnBlock = !get(production, metaPath + "isbn") && !info.allowMissingISBN;

				return (
					Object.keys(distributors).includes(dist) &&
					!(info.format && info.format !== exp.format) &&
					!(info.disableArticles && info.disableArticles.includes(articleType)) &&
					distOptions &&
					distOptions[articleType] &&
					distOptions[articleType].includes(dist) &&
					(exp.distributor && exp.distributor[dist] && exp.distributor[dist].status ? (
						<Popup
							inverted
							size="tiny"
							key={dist}
							content={
								(exp.distributor[dist].status === "sent"
									? info.connection
										? t("masterExpSent") + " "
										: t("masterExpCreated") + " "
									: t("masterExpStarted") + " ") +
								moment(toDate(exp.distributor[dist].sent)).format("YYYY-MM-DD HH:mm")
							}
							trigger={
								<div style={styles.distRow}>
									<div style={styles.distCol}>
										<Icon
											name={icon}
											color={
												exp.distributor[dist].status === "sent"
													? "green"
													: exp.distributor[dist].status === "queued"
													? "yellow"
													: "orange"
											}
										/>{" "}
										{info.name}
									</div>
									<div style={styles.distCol}>
										{info.connection ? (
											<Button.Group
												color="teal"
												size="mini"
												compact
												disabled={exp.status !== "done"}
												style={styles.buttonGroup}
											>
												<Button
													disabled
													content={t("exportStatus" + exp.distributor[dist].status)}
												/>
												{exp.distributor[dist].status === "sent" &&
													productionRights.includes("production.masterExportEdit") && (
														<Button
															content={t("masterExpSendAgain")}
															style={styles.buttonRight}
															onClick={() => {
																if (
																	info?.audio?.disableSendWithoutMeta ||
																	dist === "nextory_audio"
																) {
																	queueSend(false);
																} else {
																	queueSend(true);
																}
															}}
														/>
													)}
											</Button.Group>
										) : (
											<Button
												as="a"
												href={get(exp, "distributor." + dist + ".zip.url") || null}
												color="teal"
												size="mini"
												compact
												disabled={exp.distributor[dist].status !== "sent"}
												style={styles.buttonGroup}
												content={
													exp.distributor[dist].status === "sent"
														? t("masterExpDownload")
														: t("exportStatus" + exp.distributor[dist].status)
												}
											/>
										)}
									</div>
								</div>
							}
						/>
					) : (
						<div key={dist} style={styles.distRow}>
							<div style={styles.distCol}>
								<Icon name={icon} color={"grey"} />
								{info.name}
							</div>
							<div style={styles.distCol}>
								<Button.Group
									color="teal"
									size="mini"
									compact
									disabled={exp.status !== "done"}
									style={styles.buttonGroup}
								>
									{!info.audio.disableSendWithoutMeta && dist !== "nextory_audio" && (
										<Button
											content={
												isbnBlock
													? t("masterExpISBNIsMissing")
													: info.connection
													? metaFirst
														? t("masterExpSendMetadata")
														: t("masterExpSendSound")
													: t("masterExpCreateZip")
											}
											disabled={metaFirst || isbnBlock}
											onClick={() => {
												queueSend(true);
											}}
										/>
									)}
									{Object.entries(distributors).find(
										([id, info]) => id === dist && !info.excludeMeta,
									) &&
										info.meta &&
										!get(distOptions, "settings." + articleType + "." + dist + ".excludeMeta") && (
											<Button
												style={styles.buttonRight}
												content={t("masterExpSoundAndMetadata")}
												disabled={isbnBlock}
												onClick={() => {
													queueSend(false);
												}}
											/>
										)}
								</Button.Group>
							</div>
						</div>
					))
				);
			})
	) : (
		<Loader inline size="tiny" />
	);
};

export default MasterExportDistribution;
