import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

export default function ReaderVoiceLevel({ voiceLevel }) {
	const { t } = useTranslation(["common", "language"]);

	const voiceLevels = [t("veryDark"), t("dark"), t("neutral"), t("light"), t("veryLight")];

	return (
		<Label basic>
			{t("voiceLevel")}
			<Label.Detail style={{ marginLeft: 5 }}>{voiceLevels[voiceLevel - 1]}</Label.Detail>
		</Label>
	);
}
