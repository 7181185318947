import sessionOfferTypes from "../../constants/sessionOfferTypes";
import { teamRoles } from "../../constants/teamRoles";

export default function getSessionPriceAndAgreement({ session, production, user, isGrab = false }) {
	const teamRecorder = Object.values(production?.team || {}).find(
		(teamMember) => teamMember.role === teamRoles.RECORDER && teamMember.user?.id === user?.id,
	);
	if (session.recorder === sessionOfferTypes.ANY_RECORDER_IN_TEAM || session.recorder === null) {
		return {
			priceId: teamRecorder?.id,
			price: teamRecorder?.price,
			agreement: teamRecorder?.agreement,
		};
	} else if (session.recorder === sessionOfferTypes.OPEN_POSITION_RECORDER) {
		const defaultUser = user?.prices?.[production.producer]?.[`${production.language}-${teamRoles.RECORDER}`];
		if (session.priceRef === "default") {
			return {
				priceId: isGrab && teamRecorder ? teamRecorder.id : session.priceId,
				price: defaultUser?.price,
				defaultUserPrice: defaultUser?.price,
				agreement: defaultUser?.agreement,
			};
		} else {
			return {
				priceId: session.priceId,
				price: session.priceRef,
				defaultUserPrice: defaultUser?.price,
				agreement: defaultUser?.agreement,
			};
		}
	} else {
		return { price: session.priceRef, agreement: session.agreementRef };
	}
}
