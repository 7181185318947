import moment from "moment";
import { useTranslation } from "react-i18next";

export default function DateHeaderContent({ endDate, startDate, viewType }) {
	const { i18n } = useTranslation();

	const endDateMoment = moment(endDate).subtract(1, "days");
	const dayFormat = i18n.language === "sv" ? "D MMMM" : "MMMM Do";
	const weekFormat = i18n.language === "sv" ? "V. WW" : "[Week] WW";

	switch (viewType) {
		case "resourceTimelineDay":
		case "timeGridDay":
			return moment(startDate).format(`${dayFormat}, YYYY`);
		case "resourceTimelineWeek":
		case "timeGridWeek":
			return `${moment(startDate).format(`${dayFormat}`)} -  ${endDateMoment.format(
				`${dayFormat} (${weekFormat})`,
			)}`;
		case "resourceTimelineMonth":
			return moment(startDate).format("MMMM YYYY");

		default:
			return null;
	}
}
