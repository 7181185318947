import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

import { resolvedOfferStatuses } from "astrid-firestore/src/api/offers/constants/offerStatuses";

import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import FlexTableExpander from "../../../ui/components/FlexTable/components/FlexTableExpander";
import useSortBy from "../../../ui/hooks/useSortBy";

import useHasRight from "../../../authorization/hooks/useHasRight";

import ProductionOffersActions from "./components/ProductionOffersActions";
import useProductionOffersTableColumns from "./hooks/useProductionOffersTableColumns";

export default function ProductionOffers({ production, offers }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const [showInactiveOffers, setShowInactiveOffers] = useState(false);

	const sortedOffers = useSortBy(offers, "createdAt.seconds");

	const activeOffers = useMemo(
		() => sortedOffers?.filter((offer) => !resolvedOfferStatuses.includes(offer.status)),
		[sortedOffers],
	);

	const inactiveOffers = useMemo(
		() => sortedOffers?.filter((offer) => resolvedOfferStatuses.includes(offer.status)),
		[sortedOffers],
	);

	const columns = useProductionOffersTableColumns(production);
	const disabled = !hasRight("production.accept") && !production?.productionDate;

	return (
		<>
			{disabled && (
				<Message info size="tiny">
					<p>
						{t(
							"productionDueDateForOffers",
							'The production needs a "Project due date" in order to send Offers',
						)}
					</p>
				</Message>
			)}

			<FlexTable
				selectable
				data={showInactiveOffers ? sortedOffers : activeOffers}
				columns={columns}
				footer={<ProductionOffersActions production={production} disabled={disabled} />}
			>
				{inactiveOffers?.length > 0 && (
					<FlexTableExpander
						onClick={() => setShowInactiveOffers((showInactiveOffers) => !showInactiveOffers)}
						expanded={showInactiveOffers}
						collapsedText={t("showInactiveOffers", "Show inactive offers ({{amount}})", {
							amount: inactiveOffers.length,
						})}
						expandedText={t("hideInactiveOffers", "Hide inactive offers")}
					/>
				)}
			</FlexTable>
		</>
	);
}
