import { formatPrice } from "../../utils/priceFormatters";

export default function NamedPrice({ price }) {
	return (
		<>
			{price.name && `${price.name} (`}
			{formatPrice(price)}
			{price.name && ")"}
		</>
	);
}
