import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import ApprovalAction from "./ApprovalAction";
import DeletionAction from "./DeletionAction";
import AdminActionsContext from "./context/AdminActionsContext";

export default function AdminActions({ data, children }) {
	const { t } = useTranslation();

	return (
		<AdminActionsContext.Provider value={{ data }}>
			<Card fluid>
				<Card.Content>
					<Card.Header>{t("actions", "Actions")}</Card.Header>
				</Card.Content>
				{children}
			</Card>
		</AdminActionsContext.Provider>
	);
}

AdminActions.ApprovalAction = ApprovalAction;
AdminActions.DeletionAction = DeletionAction;
