import { useCallback, useState } from "react";
import { Range as ReactRange, getTrackBackground } from "react-range";
import { Header, Label } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import updateDocument from "astrid-firestore/src/helpers/updateDocument";

const MIN = 0;
const MAX = 100;
const STEP = 1;

export default function SubcontractorProgress({ productionId, currentProgress }) {
	const [progress, setProgress] = useState([currentProgress || 0]);
	const saveProgress = useCallback(
		async (values) => {
			const progress = values[0];
			if (progress !== currentProgress) {
				await updateDocument(db.collection("productions").doc(productionId), {
					subcontractorProgress: values[0],
				});
			}
		},
		[productionId, currentProgress],
	);
	return (
		<div style={{ marginBottom: "3rem" }}>
			<Header as="h3" content="Progress" />
			<ReactRange
				step={STEP}
				min={MIN}
				max={MAX}
				values={progress}
				onChange={(values) => {
					setProgress(values);
				}}
				onFinalChange={saveProgress}
				renderTrack={({ props, children }) => {
					return (
						<div
							{...props}
							style={{
								...props.style,
								height: "6px",
								width: "100%",
								background: getTrackBackground({
									values: progress,
									colors: ["#548BF4", "#ccc"],
									min: MIN,
									max: MAX,
								}),
							}}
						>
							{children}
						</div>
					);
				}}
				renderThumb={({ props }) => {
					return (
						<div
							{...props}
							style={{
								...props.style,
								outline: "none",
							}}
						>
							<Label circular color="blue" size="large">
								{progress[0]}
							</Label>
						</div>
					);
				}}
			/>
		</div>
	);
}
