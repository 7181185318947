import { Outlet, useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

export default function OrganizationContext() {
	const { orgId } = useParams();

	const [organization, loading, error] = useDocumentData(orgId && db.collection("organizations").doc(orgId));

	return (
		<LoadingContext data={organization} loading={loading} error={error}>
			{organization && <Outlet context={{ organization }} />}
		</LoadingContext>
	);
}
