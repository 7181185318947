import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Message, Modal } from "semantic-ui-react";

import { useProfile } from "../../authentication/state/profile";
import Form from "../../forms/components/Form/Form";
import useForm from "../../forms/hooks/useForm";

import Sentry from "../sentry";

export default function SentryErrorFallback({ eventId }) {
	const { t } = useTranslation();
	const user = useProfile();

	const [openModal, setOpenModal] = useState(true);

	const form = useForm({
		defaultValues: {
			name: user.fullName || "",
			email: user.email || "",
			message: "",
		},
	});

	const { handleSubmit, formState } = form;

	const { isSubmitting, isValid } = formState;

	const onSubmit = async ({ name, email, message }) => {
		try {
			await Sentry.captureUserFeedback({
				event_id: eventId,
				name,
				email,
				comments: message,
			});
		} catch (error) {
			console.log(error);
		}

		setOpenModal(false);
	};

	return (
		<>
			<Message negative>{t("errorMessageHeader", "Something went wrong")}</Message>
			<Modal open={openModal} size="tiny">
				<Modal.Header style={{ textAlign: "center" }}>
					{t("sentryModalHeader", "Oops! Looks like you ran into a problem")}
				</Modal.Header>
				<Modal.Content>
					<div style={{ textAlign: "center" }}>
						{t(
							"sentryModalText",
							"By telling us what happened below and sending a report you'll help our team to solve this problem as fast as possible",
						)}
					</div>
					<Divider />
					<Form form={form}>
						<Form.Group widths="equal">
							<Form.Input name="name" label={t("name", "Name")} />
							<Form.Input name="email" label={t("email", "Email")} />
						</Form.Group>
						<Form.TextArea name="message" label={t("whatHappened", "What happened?")} />
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => setOpenModal(false)}>{t("skip", "Skip")}</Button>
					<Button onClick={handleSubmit(onSubmit)} primary loading={isSubmitting} disabled={!isValid}>
						{t("reportIssue", "Report issue")}
					</Button>
				</Modal.Actions>
			</Modal>
		</>
	);
}
