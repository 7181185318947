import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateArticleBundleForm from "./CreateArticleBundleForm";

export default function CreateArticleBundleFromArticlesButton({ articles, ...props }) {
	const { t } = useTranslation();

	const text = t("createArticleBundleFromAmount", "Create article bundle ({{amount}})", {
		amount: articles?.length || 0,
	});

	return (
		<OpenButton text={text} disabled={articles?.length === 0} {...props}>
			{({ setOpen }) => (
				<CreateArticleBundleForm title={text} articles={articles} onClose={() => setOpen(false)} />
			)}
		</OpenButton>
	);
}
