import { useOutletContext } from "react-router-dom";

import SidebarLayout from "../../../ui/layouts/SidebarLayout/SidebarLayout";

import ProducerAdminSidebar from "./ProducerAdminSidebar";

export default function ProducerAdminLayout() {
	const context = useOutletContext();

	const { organization } = context;

	return <SidebarLayout context={context} sidebar={<ProducerAdminSidebar organization={organization} />} />;
}
