import updateDocument from "../../helpers/updateDocument";

import offerStatuses from "./constants/offerStatuses";

export default async function cancelOffer(firebase, { offer }) {
	if (offer.agreement?.id) {
		await firebase.functions().httpsCallable("scrive-cancelSigning")({
			agreementId: offer.agreement.id,
		});
	}

	await updateDocument(offer.ref, {
		status: offerStatuses.CANCELLED,
	});
}
