import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import OfferAccordion from "../../../offers/components/OfferAccordion/OfferAccordion";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import useOffersQuery from "./hooks/useOffersQuery";

export default function OffersWidget({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [offers, loading, error] = useCollectionData(useOffersQuery({ organization }));

	return (
		<HomeLayout.Widget icon="send" color="orange" header={t("myOffers", "My offers")}>
			<LoadingContext
				data={offers}
				loading={loading}
				error={error}
				noDataAvailableMessage={t("noNewOffers", "You have no new offers")}
			>
				<OfferAccordion
					offers={offers}
					onClickOffer={({ id }) =>
						navigate(`/${organization.type}/${organization.id}/my-productions/offers/${id}`)
					}
				/>
			</LoadingContext>
		</HomeLayout.Widget>
	);
}
