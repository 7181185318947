import React from "react";

import money from "astrid-helpers/src/money";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import Table from "../../../ui/components/Table/Table";

import useTotalCost from "../../hooks/useTotalCost";

import useFinalizedCostsTableColumns from "./hooks/useFinalizedCostsTableColumns";

export default function FinalizedCostsTable({ costs, reversed }) {
	const finalizedCostsColumns = useFinalizedCostsTableColumns();

	const [totalCost, totalCurrency] = useTotalCost(costs, reversed);

	return (
		<LoadingContext data={costs}>
			<Table data={costs} columns={finalizedCostsColumns} footer={false}>
				<Table.Row textAlign={"right"} style={{ fontWeight: "bold" }}>
					<Table.Cell></Table.Cell>
					<Table.Cell></Table.Cell>
					<Table.Cell></Table.Cell>
					<Table.Cell></Table.Cell>
					<Table.Cell></Table.Cell>
					<Table.Cell>{money(totalCost, totalCurrency)}</Table.Cell>
					<Table.Cell></Table.Cell>
				</Table.Row>
			</Table>
		</LoadingContext>
	);
}
