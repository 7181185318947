import { zodResolver } from "@hookform/resolvers/zod";
import { useForm as useReactHookForm } from "react-hook-form";

import useResetFields from "./useResetFields";

export default function useForm({
	schema,
	resetFields,
	resolverOptions = { raw: true },
	locked = false,
	alteredValues = {},
	...props
} = {}) {
	const form = useReactHookForm({
		resolver: schema ? zodResolver(schema.partial(), {}, resolverOptions) : undefined,
		...props,
	});

	form.locked = locked;
	form.alteredValues = alteredValues;

	useResetFields(form, resetFields);

	return form;
}
