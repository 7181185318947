import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";

import useLoadMore from "astrid-firestore/src/hooks/useLoadMore";

import LoadMore from "../../../components/LoadMore/LoadMore";

import { useProfile } from "../../authentication/state/profile";
import OrdersAccordion from "../../orders/components/OrdersAccordion/OrdersAccordion";
import useOrdersQuery from "../../orders/hooks/useOrdersQuery";

export default function ListAccountPurchaseOrders() {
	const { t } = useTranslation();
	const profile = useProfile();

	const [orders = [], loading, error, loadMore] = useLoadMore(
		useOrdersQuery({ expense: true, secondPartyId: profile?.id || null }).orderBy("created", "desc"),
		{
			limit: 30,
		},
	);

	return (
		<>
			<Header inline>{t("POs")}</Header>

			<OrdersAccordion orders={orders} />

			<LoadMore loading={loading} error={error} onClick={loadMore}>
				{t("showMore")}
			</LoadMore>
		</>
	);
}
