import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useTranslatorsTableColumns from "../../hooks/useTranslatorsTableColumns";

export default function TranslatorsCollectionDataTable({ organization, getQuery = (query) => query, ...props }) {
	const navigate = useNavigate();

	const columns = useTranslatorsTableColumns({ organization });

	return (
		<CollectionDataTable
			filterable={false}
			query={getQuery(db.collection("translators").where("deleted", "==", false))}
			columns={columns}
			onClick={({ id }) => navigate(`/publisher/${organization.id}/admin/translators/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			searchableKeys={["name"]}
			{...props}
		/>
	);
}
