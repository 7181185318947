import { firebase } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";

import CommentForm from "./CommentForm";

export default function EditCommentForm({ comment, onClose }) {
	const onSaveEdit = async (text) => {
		await updateDocument(comment.ref, { text, edited: firebase.firestore.FieldValue.serverTimestamp() });
		onClose(false);
	};

	return <CommentForm onClickCancel={onClose} onSave={onSaveEdit} comment={comment} />;
}
