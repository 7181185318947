import nameCase from "astrid-helpers/src/nameCase";

import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { Author } from "./types/Author";

const authors = createFirestoreCollection("authors", Author, {
	preProcess: async ({ api, firebase }, { data }) => {
		if (data.name) {
			data.name = nameCase(data.name);
			data.nameLowerCase = data.name.toLowerCase();

			// Check if an author with the same name already exists
			const [existingAuthor] = await api.authors.getAll((ref) =>
				ref
					.where(firebase.firestore.FieldPath.documentId(), "!=", data.ref.id)
					.where("nameLowerCase", "==", data.nameLowerCase)
					.limit(1),
			);

			if (existingAuthor) {
				data.id = existingAuthor.id;
				data.ref = existingAuthor.ref;

				if (data.publisherIds) {
					data.publisherIds = [
						...existingAuthor.publisherIds,
						...data.publisherIds.filter((id) => !existingAuthor.publisherIds.includes(id)),
					];
				}
			}
		}

		return data;
	},
});

export default authors;
