import { useEffect, useMemo } from "react";
import ContentEditable from "react-contenteditable";
import { Loader } from "semantic-ui-react";

import countries from "astrid-config/src/countries";
import db from "astrid-firebase/src/db";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

const formatAddress = (vendor) => {
	const { addr1, addr2, zip, city, country } = vendor.address || {};
	if (addr1 || addr2 || zip || city || country) {
		const address = [
			vendor.name,
			vendor.orgnum,
			addr1,
			addr2,
			`${zip || ""} ${city || ""}`,
			country ? countries[country] : "",
		]
			.filter((v) => v)
			.map((v) => v.trim())
			.filter((v) => v.length > 0);

		return address.join("\n");
	}
	return `${vendor?.name || ""} \n\n Förlaget har ingen adress angiven \n Redigerbart fält`;
};

export default function BillReceiver({ publisher, billReceiver, setBillReceiver, onChange }) {
	const [data, loading, error] = useCollectionDataOnce(
		useMemo(() => {
			return db.collection("finance/setup/vendors").where("entityId", "==", publisher);
		}, [publisher]),
		{ idField: "id" },
	);

	const vendor = data?.[0];

	useEffect(() => {
		if (vendor) {
			setBillReceiver(formatAddress(vendor));
		}
	}, [vendor, setBillReceiver]);

	if (loading) return <Loader active inline />;
	if (error) console.error(error);

	return (
		<ContentEditable
			className="bill-receiver"
			data-name="billReceiver"
			onChange={onChange}
			html={billReceiver || ""}
		/>
	);
}
