import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Divider, Header, Icon, Table } from "semantic-ui-react";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import ImageAvatar from "../../../ui/components/ImageAvatar/ImageAvatar";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";
import { formatPrice } from "../../../prices/utils/priceFormatters";

import useProductionWorkGroup from "../../hooks/useProductionWorkGroup";

import { ResponsiveContainer } from "./components/ResponsiveContainer";

export default function ProductionWorkGroup({ production }) {
	const workGroup = useProductionWorkGroup(production);
	const { t } = useTranslation();
	const hasRight = useHasRight();
	const profile = useProfile();

	return (
		<>
			<Divider />
			<Header as="h4">
				<Icon.Group size="big">
					<Icon name="group" />
				</Icon.Group>
				<Header.Content>{t("team", "Team")}</Header.Content>
			</Header>
			<Table basic="very" celled>
				<Table.Body>
					{workGroup
						.filter(({ hidden }) => !hidden)
						.map(({ title, members }) => (
							<Table.Row key={title}>
								<Table.HeaderCell collapsing style={{ paddingRight: 15 }}>
									{title}
								</Table.HeaderCell>
								<Table.Cell>
									<ResponsiveContainer>
										{members.length > 0 ? (
											members.map(({ user, role, id, price }) => (
												<Header key={id} as="h5" image style={{ margin: 0 }}>
													<ImageAvatar src={user.img} style={{ width: 35, height: 35 }} />
													<Header.Content>
														{user.name}

														{profile.id === user.id &&
															price &&
															` - ${formatPrice(price, t)}`}

														{(user.contactDetails ||
															hasRight("users.viewContactDetails") ||
															role === teamRoles.MANAGER) && (
															<>
																<Header.Subheader
																	as={Link}
																	to="#"
																	onClick={(e) => {
																		window.location.href = `tel:${user.phone}`;
																		e.preventDefault();
																	}}
																	style={{ fontSize: "0.9em" }}
																>
																	{user.phone}
																</Header.Subheader>

																<Header.Subheader
																	as={Link}
																	to="#"
																	onClick={(e) => {
																		window.location.href = `mailto:${user.email}`;
																		e.preventDefault();
																	}}
																>
																	{user.email}
																</Header.Subheader>
															</>
														)}
													</Header.Content>
												</Header>
											))
										) : (
											<p style={{ lineHeight: 3 }}>{t("notAssigned", "Not assigned")}</p>
										)}
									</ResponsiveContainer>
								</Table.Cell>
							</Table.Row>
						))}
				</Table.Body>
			</Table>
		</>
	);
}
