import useForm from "../../../../forms/hooks/useForm";

export default function useProductionPriceForm({ type, production, firstPartyId, price }) {
	return useForm({
		defaultValues: {
			production,
			firstPartyId,
			addExpense: type === "expense",
			addRevenue: type === "revenue",
			expense: price || {},
			revenue: price || {},
		},
	});
}
