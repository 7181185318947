import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { StorageFile } from "../../../types/StorageFile";
import { array, boolean, number, string } from "../../../types/_types";

import { ArticleRef } from "../../articles/types/ArticleRef";
import { OrganizationRef } from "../../organizations/types/Organization";

export const Artifact = FirestoreDocument.extend({
	article: ArticleRef,
	files: array(StorageFile),
	isDefault: boolean(),
	name: string(3),
	size: number().default(0),
	publisher: OrganizationRef,
}).merge(FirestoreLifecycle);
