import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { useCollectionData } from "astrid-firestore/src/hooks";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";
import useProductionsWithOpenCostsQuery from "../../productions/hooks/useProductionsWithOpenCostsQuery";

import AdminProductionsView from "../views/AdminProductionsView/AdminProductionsView";

export default function AdminProductionsUnfinalized() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	const [productions, loading, error] = useCollectionData(useProductionsWithOpenCostsQuery({ organization }));

	if (!hasRight("productions.admin")) {
		return <Unauthorized />;
	}

	return (
		<AdminProductionsView
			header={t("unfinalized", "Unfinalized")}
			organization={organization}
			productions={productions}
			loading={loading}
			error={error}
		/>
	);
}
