import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useStarred from "../../hooks/useStarred";
import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import useStarredColumns from "./hooks/useStarredColumns";

export default function FavoriteProductionsWidget() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [starred, loading, error] = useStarred();

	const columns = useStarredColumns();

	return (
		<HomeLayout.Widget icon="star" color="yellow" header={t("favoriteProds", "Favourite productions")}>
			<DataTable
				columns={columns}
				data={starred}
				loading={loading}
				error={error}
				onClick={({ id }) => navigate(`/production/${id}`)}
				noDataAvailableMessage={t("noFavouriteProductions", "You have no favourite productions")}
			/>
		</HomeLayout.Widget>
	);
}
