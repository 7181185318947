import React from "react";

import AddMemberAction from "./AddMemberAction";
import SetDefaultPricesAndApplySessionCosts from "./SetDefaultPricesAndApplySessionCosts";

export default function TeamMembersActions({ production }) {
	return (
		<>
			<AddMemberAction production={production} />
			<SetDefaultPricesAndApplySessionCosts production={production} />
		</>
	);
}
