import { useTranslation } from "react-i18next";
import { Button, Segment } from "semantic-ui-react";

import Form from "../../../../forms/components/Form/Form";

import { sentOffersFormDefaultValues } from "../hooks/useSentOffersForm";

import OfferProductionSelect from "./OfferProductionSelect";
import OfferSecondPartySelect from "./OfferSecondPartySelect";
import OfferStatusSelect from "./OfferStatusSelect";
import OfferTypeSelect from "./OfferTypeSelect";

export default function SentOffersFilterForm({ offers, form }) {
	const { t } = useTranslation();

	const { reset } = form;

	return (
		<Segment basic style={{ width: 300 }}>
			<Form form={form}>
				<OfferProductionSelect offers={offers} />
				<OfferSecondPartySelect offers={offers} />
				<OfferStatusSelect offers={offers} />
				<OfferTypeSelect offers={offers} />
			</Form>

			<Button style={{ marginTop: 20 }} onClick={() => reset(sentOffersFormDefaultValues)}>
				{t("resetFilters", "Reset filters")}
			</Button>
		</Segment>
	);
}
