import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import taskStatuses from "astrid-firestore/src/api/tasks/constants/taskStatuses";

export default function useTaskStatusTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[taskStatuses.CREATED]: t("pending", "Pending"),
			[taskStatuses.INITIALIZED]: t("initialized", "Initialized"),
			[taskStatuses.COMPLETED]: t("completed", "Completed"),
			[taskStatuses.CANCELLED]: t("cancelled", "Cancelled"),
			[taskStatuses.IGNORED]: t("ignored", "Ignored"),
			[taskStatuses.FAILED]: t("failed", "Failed"),
			[taskStatuses.RETRY]: t("retry", "Retry"),
		}),
		[t],
	);
}
