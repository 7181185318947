import getCollectionData from "../../helpers/getCollectionData";
import getDocumentData from "../../helpers/getDocumentData";

import { reduceTotalExchangedCost } from "./utils/costsHelper";
import { getOpenCosts } from "./utils/openCosts";

export default async function summarizeOpenCosts(firebase, productionRef) {
	const db = firebase.firestore();

	const [production, exchangeRates, prices, users, costs] = await Promise.all([
		getDocumentData(productionRef),
		getCollectionData(db.collection("exchangeRates").orderBy("timestamp", "desc").limit(1)),
		getCollectionData(productionRef.collection("productionPrices").where("deleted", "==", false)),
		getCollectionData(productionRef.collection("users")),
		getCollectionData(
			db.collection("costs").where("production.id", "==", productionRef.id).where("deleted", "==", false),
		),
	]);

	const openCosts = getOpenCosts({
		exchangeRate: exchangeRates[0],
		production,
		prices,
		users,
		costs,
	});

	const expenses = openCosts.filter((cost) => cost.expense);
	const revenues = openCosts.filter((cost) => !cost.expense);

	const totalExpenses = reduceTotalExchangedCost(expenses);
	const totalRevenues = reduceTotalExchangedCost(revenues);

	return {
		total: totalExpenses + totalRevenues,
		expenses: {
			total: totalExpenses,
			currency: expenses[0]?.exchange?.currency || null,
		},
		revenues: {
			total: totalRevenues,
			currency: revenues[0]?.exchange?.currency || null,
		},
	};
}
