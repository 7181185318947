export const AGREEMENT_STATE = {
	CREATED: "CREATED",
	APPROVAL_DENIED: "APPROVAL_DENIED",
	MISSING_AGREEMENT_DETAILS: "MISSING_AGREEMENT_DETAILS",
	CREATED_PDF: "CREATED_PDF",
	FAILED_TO_CREATE_PDF: "FAILED_TO_CREATE_PDF",
	CREATED_DOCUMENT: "CREATED_DOCUMENT",
	FAILED_TO_CREATE_DOCUMENT: "FAILED_TO_CREATE_DOCUMENT",
	READY_TO_START_SIGNING: "READY_TO_START_SIGNING",
	FAILED_TO_BE_READY_FOR_SIGNING: "FAILED_TO_BE_READY_FOR_SIGNING",
	AWAITING_SIGNING: "AWAITING_SIGNING",
	FAILED_TO_START_SIGNING: "FAILED_TO_START_SIGNING",
	FAILED_TO_PROLONG: "FAILED_TO_PROLONG",
	FAILED_TO_RESTART: "FAILED_TO_RESTART",
	SIGNED: "SIGNED",
	REJECTED: "REJECTED",
	CANCELLED: "CANCELLED",
	SIGNING_EXPIRED: "SIGNING_EXPIRED",
	UNKNOWN_ERROR: "UNKNOWN_ERROR",
};

export const AGREEMENT_VALIDATED = "AGREEMENT_VALIDATED";

export const SIGNEE_STATE = {
	PENDING: "PENDING",
	SIGNED: "SIGNED",
	REJECTED: "REJECTED",
};

export const SCRIVE_STATE = {
	PENDING: "pending",
	CLOSED: "closed",
	REJECTED: "rejected",
	CANCELED: "canceled",
	PREPARATION: "preparation",
	TIMED_OUT: "timedout",
	DOCUMENT_ERROR: "document_error",
};
