import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminOfferView from "../views/AdminOfferView/AdminOfferView";

export default function AdminOffer() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("offers.view")) {
		return <Unauthorized />;
	}

	return <AdminOfferView id={id} />;
}
