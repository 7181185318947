import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import isApproved from "astrid-firestore/src/api/approval/isApproved";
import approveOffer from "astrid-firestore/src/api/offers/approveOffer";

import { getProfile } from "../../../../authentication/state/profile";

export default function ApproveOfferDropdownItem({ offer, setLoading, ...props }) {
	const { t } = useTranslation();

	return (
		<Dropdown.Item
			text={t("approve", "Approve")}
			disabled={isApproved(offer)}
			onClick={async () => {
				const user = getProfile();

				setLoading?.(true);

				await approveOffer({ offer, user });

				setLoading?.(false);
			}}
			{...props}
		/>
	);
}
