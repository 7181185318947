import { useOutletContext } from "react-router-dom";

import CreateProductionLayout from "../layouts/CreateProductionLayout";
import CreateProductionProducerView from "../views/CreateProductionProducerView/CreateProductionProducerView";

export default function CreateProductionProducer() {
	const { organization } = useOutletContext();

	return (
		<CreateProductionLayout>
			<CreateProductionProducerView organization={organization} />
		</CreateProductionLayout>
	);
}
