import firebase from "firebase/app";

import createAPI from "astrid-firestore/src/api/api";
import firestoreHooksPlugin from "astrid-firestore/src/firestore/plugins/firestoreHooksPlugin";
import { isDevelopment } from "astrid-helpers/src/env";

import { getProfile } from "./features/authentication/state/profile";

const api = createAPI({
	firebase,
	plugins: [firestoreHooksPlugin()],
	getUser: getProfile,
	debug: isDevelopment,
});

export default api;
