import { useTranslation } from "react-i18next";

export default function useSkillLevelOptions() {
	const { t } = useTranslation();

	return [
		{ key: 1, value: 1, text: t("skillLevel1", "Some knowledge about pronunciation") },
		{ key: 2, value: 2, text: t("skillLevel2", "Basic knowledge") },
		{ key: 3, value: 3, text: t("skillLevel3", "Good knowledge, conversation skills") },
		{ key: 4, value: 4, text: t("skillLevel4", "Fluent") },
		{ key: 5, value: 5, text: t("skillLevel5", "Main language") },
	];
}
