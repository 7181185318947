import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminProductionsColumns from "./hooks/useAdminProductionsColumns";

export default function AdminProductionsView({
	header,
	producerId,
	productions,
	organization,
	loading,
	error,
	...props
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const columns = useAdminProductionsColumns();

	return (
		<>
			<AdminHeader>
				{t("productions", "Productions")} - {header}
			</AdminHeader>

			<DataTable
				data={productions}
				columns={columns}
				loading={loading}
				error={error}
				onClick={({ id }) => navigate(`/production/${id}`)}
				sortBy={{
					id: "doneDate",
					desc: false,
				}}
				{...props}
			/>
		</>
	);
}
