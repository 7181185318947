import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

export default function TitleOccupiedModal({ onSubmit, open, setOpenModal }) {
	const { t } = useTranslation();

	return (
		<Modal open={open}>
			<Modal.Header>{t("titleAlreadyExists", "This title already exists")}</Modal.Header>
			<Modal.Content>
				<p>{t("stillCreateProduction", "Do you still want to create this production?")}</p>
			</Modal.Content>
			<Modal.Actions>
				<Button content={t("cancel")} onClick={() => setOpenModal(false)} />
				<Button primary content={t("create")} onClick={onSubmit} />
			</Modal.Actions>
		</Modal>
	);
}
