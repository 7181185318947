import React from "react";
import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import Flex from "../../../../../components/Flex/Flex";
import ArticleToggleSyncTitleDataIcon from "../../../../articles/components/ArticleIcons/ArticleToggleSyncTitleDataIcon";
import ArticleUpdateTitleDataIcon from "../../../../articles/components/ArticleIcons/ArticleUpdateTitleDataIcon";

import AdminMetaDataForm from "../../../components/AdminMetaDataForm/AdminMetaDataForm";

export default function AdminArticleMetaDataForm({ article, locked, ...props }) {
	const { t } = useTranslation();

	return (
		<AdminMetaDataForm
			header={
				<Flex>
					{t("metaData", "Meta data")}

					<div>
						{!locked && (
							<Flex gap={10}>
								<ArticleUpdateTitleDataIcon data={article} />
								<ArticleToggleSyncTitleDataIcon data={article} />
							</Flex>
						)}
					</div>
				</Flex>
			}
			data={article}
			locked={!!locked}
			api={api.articles}
			disabled={article.sync}
			onSubmit={(data) => {
				return api.articles.update(data, { version: true });
			}}
			{...props}
		/>
	);
}
