import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import ReaderSearchForm from "../../ReaderSearchForm/ReaderSearchForm";

export default function AddReaderAction({ voice, production, allReaderIds }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Accordion.Title onClick={() => setOpen(true)}>
				<Flex style={{ gap: 12, justifyContent: "flex-start" }}>
					<Icon circular inverted name="plus" color="blue" style={{ margin: 0, fontSize: "1.25em" }} />

					{t("addReader", "Add reader")}
				</Flex>
			</Accordion.Title>

			{open && (
				<ReaderSearchForm
					voice={voice}
					production={production}
					allReaderIds={allReaderIds}
					onClose={() => setOpen(false)}
					submitText={t("addToVoice", "Add to voice")}
				/>
			)}
		</>
	);
}
