import Color from "color";

import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";

export default function useEvents({
	sessions,
	getResourceId,
	resourceColors,
	isEditing,
	resourceType,
	getSessionOrder,
}) {
	const profile = useProfile();
	const hasRight = useHasRight();

	return sessions.map((session) => {
		const shouldHaveBlackTextColor = Color(resourceColors[getResourceId(session)] || "#d9d9d9").isLight();

		const end = session.end.toDate();

		const sessionColor = resourceColors[getResourceId(session)] || session.studioData?.color || "#d9d9d9";

		return {
			id: session.id,
			resourceId: getResourceId(session),
			title: session.production,
			start: session.start.toDate(),
			end,
			backgroundColor: sessionColor,
			borderColor: sessionColor,
			textColor: shouldHaveBlackTextColor ? "black" : "white",
			order: getSessionOrder?.(session),
			editable:
				isEditing &&
				(session.managerIds?.includes(profile.id) || hasRight("calendar.editAllSessions")) &&
				session.status === "booked",
			resourceEditable:
				isEditing &&
				(session.managerIds?.includes(profile.id) || hasRight("calendar.editAllSessions")) &&
				session.status === "booked" &&
				resourceType === "studios",
			session,
		};
	});
}
