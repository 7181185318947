import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";

import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";
import { formatCostToTime } from "astrid-helpers/src/formatCostToTime";
import money from "astrid-helpers/src/money";
import msToTime from "astrid-helpers/src/msToTime";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import useCostsQuery from "../../../../costs/hooks/useCostsQuery";
import { formatPrice } from "../../../../prices/utils/priceFormatters";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

export default function OrderCosts({ order }) {
	const { t } = useTranslation();

	const costTypes = useCostTypes();
	const costsQuery = useCostsQuery({ orderId: order.id });

	const [costs = [], loading, error] = useCollectionDataOnce(order && costsQuery);

	return (
		<LoadingContext data={costs} loading={loading} error={error}>
			<Table basic="very">
				<Table.Row>
					<Table.Cell>{t("production", "Production")}</Table.Cell>
					<Table.Cell>{t("description", "Description")}</Table.Cell>
					<Table.Cell>{t("time", "Time")}</Table.Cell>
					<Table.Cell>{t("price", "Price")}</Table.Cell>
					<Table.Cell>{t("created", "Created")}</Table.Cell>
					<Table.Cell textAlign="right">{t("sum")}</Table.Cell>
				</Table.Row>

				{costs.map((cost) => {
					return (
						<Table.Row key={cost.id}>
							<Table.Cell>
								<Link to={`/production/${cost.production?.id}`}>{cost.production?.title}</Link>
							</Table.Cell>
							<Table.Cell>
								{cost.price?.type === "custom" ? cost.price?.name : costTypes[cost.price?.type] || "-"}
							</Table.Cell>
							<Table.Cell>{formatCostToTime({ cost })}</Table.Cell>
							<Table.Cell>{formatPrice(cost.price)}</Table.Cell>
							<Table.Cell>{cost.created?.toDate().toLocaleString() || "-"}</Table.Cell>
							<Table.Cell textAlign="right">
								{cost.total ? money(cost.total, cost.price?.currency) : "-"}
							</Table.Cell>
						</Table.Row>
					);
				})}

				<Table.Row>
					<Table.Cell>
						<strong>
							{order.total ? t("totalExclVAT", "Total excl. VAT") : t("totalDuration", "Total duration")}
						</strong>
					</Table.Cell>

					<Table.Cell colSpan={5} textAlign="right">
						<strong>
							{order.total
								? money(order.total, order.currency)
								: msToTime(order.totalAmountProduced * 60 * 1000, false)}
						</strong>
					</Table.Cell>
				</Table.Row>
			</Table>
		</LoadingContext>
	);
}
