import { useState } from "react";
import { Icon, Message as SemanticMessage } from "semantic-ui-react";

export default function Message({ header, children, dismissible, ...props }) {
	const [visible, setVisible] = useState(true);

	if (!visible) {
		return null;
	}

	return (
		<SemanticMessage {...props}>
			{header && <SemanticMessage.Header>{header}</SemanticMessage.Header>}

			{children}

			{dismissible && (
				<Icon
					name="remove"
					color="grey"
					style={{ position: "absolute", right: 5, top: 15, fontSize: 12 }}
					onClick={() => setVisible(false)}
				/>
			)}
		</SemanticMessage>
	);
}
