import React from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import db from "astrid-firebase/src/db";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import createOffer from "astrid-firestore/src/api/offers/createOffer";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import { getProfile } from "../../../../authentication/state/profile";
import Form from "../../../../forms/components/Form/Form";
import TeamPriceSelect from "../../../../team/components/TeamPriceSelect/TeamPriceSelect";
import ModalPrompt from "../../../../ui/components/ModalPrompt/ModalPrompt";

import offerTypeIcons from "../../../constants/offerTypeIcons";
import useOfferTypeTranslations from "../../../hooks/useOfferTypeTranslations";

import OfferRoleSelect from "../../OfferRoleSelect/OfferRoleSelect";

import useOpenOfferForm from "../hooks/useOpenOfferForm";
import getPrice from "../utils/getPrice";

import CustomPriceForm from "./CustomPriceForm";
import ExpiresAtInput from "./ExpiresAtInput";

export default function OpenOfferForm({ type, production, ...props }) {
	const { t } = useTranslation();

	const types = useOfferTypeTranslations();
	const form = useOpenOfferForm();

	const { watch, formState, setValue, handleSubmit } = form;

	const [role, priceId] = watch(["role", "priceId"]);

	const [firstParty] = useDocumentDataOnce(db.collection("organizations").doc(production.producer));

	return (
		<ModalPrompt
			icon={offerTypeIcons[type]}
			form={form}
			header={types[type]}
			disabled={!formState.isValid}
			confirmText={t("publish", "Publish")}
			onSave={handleSubmit(({ role, priceId, price, expiresAt, customPrice }) =>
				createOffer(firebase, {
					role,
					type: offerTypes.OPEN,
					price: price || getPrice({ role, priceId, customPrice }),
					approved: priceId !== "custom" || !!customPrice?.approved,
					expiresAt,
					production,
					firstParty,
					createdBy: getProfile(),
				}),
			)}
			{...props}
		>
			<Form form={form}>
				<Form.Group widths="2">
					<ExpiresAtInput name="expiresAt" label={t("expiresAt", "Expires at")} />

					<Form.Field name="role">
						<OfferRoleSelect label={t("role", "Role")} />
					</Form.Field>
				</Form.Group>

				{role && (
					<TeamPriceSelect
						name="priceId"
						role={role}
						production={production}
						onChangeOption={(option) => setValue("price", option?.data)}
						additionalOptions={[
							{
								key: "default",
								value: "default",
								text: `[${t("defaultPrice", "Default price")}]`,
							},
							{
								key: "custom",
								value: "custom",
								text: `[${t("customPrice", "Custom price")}]`,
							},
						]}
					/>
				)}

				{priceId === "custom" && (
					<CustomPriceForm firstParty={firstParty} production={production} autoApprove />
				)}
			</Form>
		</ModalPrompt>
	);
}
