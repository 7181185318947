import React from "react";

import AlertsWidget from "../components/AlertsWidget/AlertsWidget";
import FavoriteProductionsWidget from "../components/FavoriteProductionsWidget/FavoriteProductionsWidget";
import MyProductionsWidget from "../components/MyProductionsWidget/MyProductionsWidget";
import NotificationsWidget from "../components/NotificationsWidget/NotificationsWidget";
import TodosWidget from "../components/TodosWidget/TodosWidget";
import UpcomingDeliveriesWidget from "../components/UpcomingDeliveriesWidget/UpcomingDeliveriesWidget";
import HomeLayout from "../layouts/HomeLayout/HomeLayout";

export default function HomePublisherView({ organization }) {
	return (
		<HomeLayout>
			<HomeLayout.LeftColumn>
				<NotificationsWidget organization={organization} />
				<TodosWidget organization={organization} />
			</HomeLayout.LeftColumn>

			<HomeLayout.RightColumn>
				<AlertsWidget organization={organization} />
				<FavoriteProductionsWidget organization={organization} />
				<MyProductionsWidget organization={organization} />
				<UpcomingDeliveriesWidget organization={organization} />
			</HomeLayout.RightColumn>
		</HomeLayout>
	);
}
