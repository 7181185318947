import { useTranslation } from "react-i18next";

import { dropdownMenuColumn } from "../../ui/utils/columns";

import useDistributionPipelineDropdownMenuOptions from "./useDistributionPipelineDropdownMenuOptions";

export default function useDistributionChannelPipelinesTableColumns({ channel }) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			filter: "text",
			disableSortBy: true,
			Header: t("Name", "Name"),
		},
		dropdownMenuColumn(useDistributionPipelineDropdownMenuOptions({ channel })),
	];
}
