import { db } from "astrid-firebase";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import { useProfile } from "../../../../authentication/state/profile";

export default function useOffersQuery({ organization }) {
	const profile = useProfile();

	if (profile) {
		const query = db
			.collection("offers")
			.where("deleted", "==", false)
			.where("secondParty.id", "==", profile.id)
			.where("status", "in", [offerStatuses.OFFERED, offerStatuses.SIGNING])
			.orderBy("createdAt", "desc")
			.limit(50);

		if (organization.type === organizationTypes.NARRATOR) {
			return query.where("role", "==", teamRoles.READER);
		}

		return query.where("producer.id", "==", organization.id);
	}

	return null;
}
