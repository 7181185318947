import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Label, Menu } from "semantic-ui-react";

function MenuItem({ label, labelColor = "blue", children, active, ...props }) {
	return (
		<Menu.Item as={Link} active={!!active} {...props}>
			{label !== undefined && <Label color={labelColor}>{label}</Label>}
			{children}
		</Menu.Item>
	);
}

function getActive({ path, active, pathname, options }) {
	return active !== undefined
		? active
		: pathname.includes(path) || options?.find(({ path }) => pathname.includes(path));
}

export default function VerticalMenu({ options }) {
	const { pathname } = useLocation();

	return (
		<Menu fluid vertical pointing secondary>
			{options
				.filter(({ hidden }) => !hidden)
				.map(({ name, path, label, labelColor, options, active }) => {
					active = getActive({ path, active, pathname, options });

					return (
						<React.Fragment key={path}>
							<MenuItem to={path} label={label} labelColor={labelColor} active={active}>
								{name}
							</MenuItem>

							{options && active && (
								<Menu.Menu style={{ marginLeft: 20 }}>
									{options.map(
										({ name, path, label, labelColor, active, hidden }) =>
											!hidden && (
												<MenuItem
													end="true"
													to={path}
													key={path}
													label={label}
													labelColor={labelColor}
													active={getActive({ path, active, pathname })}
												>
													{name}
												</MenuItem>
											),
									)}
								</Menu.Menu>
							)}
						</React.Fragment>
					);
				})}
		</Menu>
	);
}
