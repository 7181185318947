import React from "react";
import { Link } from "react-router-dom";

import OfferDiscussionIcon from "../../../../offers/components/OfferDiscussion/OfferDiscussionIcon";
import OfferDropdownMenu from "../../../../offers/components/OfferDropdownMenu/OfferDropdownMenu";
import OfferHeader from "../../../../offers/components/OfferHeader/OfferHeader";
import OfferLabel from "../../../../offers/components/OfferLabel/OfferLabel";
import OfferStatusLabel from "../../../../offers/components/OfferStatus/OfferStatusLabel";
import OfferStatusPopup from "../../../../offers/components/OfferStatus/OfferStatusPopup";
import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function useSentOffersTableColumns() {
	return [
		{
			id: "secondParty.img",
			Cell: ({ value }) => <ImageAvatar src={value} />,
		},
		{
			id: "secondParty.name",
			flex: 1,
			Cell: ({ row }) => <OfferHeader offer={row.original} />,
		},
		{
			id: "production.title",
			Cell: ({ row, value }) => (
				<Link
					to={`/production/${row.original.production.id}`}
					style={{
						display: "block",
						marginLeft: "auto",
						maxWidth: "200px",
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
					}}
				>
					{value}
				</Link>
			),
		},
		{
			id: "status",
			Cell: ({ row }) => (
				<OfferStatusPopup offer={row.original}>
					<OfferStatusLabel offer={row.original} />
				</OfferStatusPopup>
			),
		},
		{
			id: "expires",
			Cell: ({ row }) => <OfferLabel offer={row.original} />,
		},
		{
			id: "actions",
			Cell: ({ row }) => (
				<>
					<OfferDiscussionIcon offer={row.original} />{" "}
					<OfferDropdownMenu offer={row.original} production={row.original.production} />
				</>
			),
		},
	];
}
