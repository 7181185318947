import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

import { isAppendixesValid } from "../../../utils/agreementValidators";

import AgreementPricesFormTable from "../../AgreementPricesFormTable/AgreementPricesFormTable";

export default function AgreementTemplatePricesForm() {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();

	const isValid = Form.usePartialFormValidation(["appendixes"], isAppendixesValid);

	const [part] = watch(["part"]);

	return (
		<ContentCard
			header={t("prices", "Prices")}
			description={t(
				"templatePricesDescription",
				"Agreements created from this template will be auto-approved if they confine to the max. prices below.",
			)}
			disabled={!isValid}
		>
			<Form.Form>
				<AgreementPricesFormTable part={part} maxPrices />
			</Form.Form>
		</ContentCard>
	);
}
