import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

export default function ClearQueryButton({ active, value, disabled, ...props }) {
	const { t } = useTranslation();

	return (
		<Button animated="vertical" basic={!disabled} disabled={disabled} {...props}>
			<Button.Content hidden>{t("clear", "Clear")}</Button.Content>
			<Button.Content visible>
				<Icon name="trash alternate outline" />
			</Button.Content>
		</Button>
	);
}
