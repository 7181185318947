import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Button, Card, Label, Message } from "semantic-ui-react";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import useHasRight from "../../../../authorization/hooks/useHasRight";
import shouldEnableInvoices from "../../../../invoices/utils/shouldEnableInvoices";

import DeleteOrderAction from "./DeleteOrderAction";

export default function OrderActions({ order, invoices }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();

	const { organization } = useOutletContext();

	const onClickDone = useCallback(() => {
		if (!order.processed || window.confirm(t("sureSetUnprocessed"))) {
			updateDocument(order.ref, { processed: !order.processed });
		}
	}, [order?.processed, order?.ref, t]);

	if (order.deleted) {
		return false;
	}

	if (order.inactivated) {
		return (
			<Card fluid>
				<Card.Content>
					<Message
						info
						content={`${t(
							"salesOrderInactivated",
							"This sales order was inactivated:",
						)} ${order?.inactivatedBy?.time?.toDate?.()?.toLocaleString()}`}
					/>
				</Card.Content>
			</Card>
		);
	}

	return (
		<Card fluid>
			{hasRight("orders.delete") && (
				<Card.Content>
					{order.id.startsWith("PO") || !shouldEnableInvoices(organization.id) ? (
						<Button color={order.processed ? "grey" : "teal"} onClick={onClickDone}>
							{t("toggleProcessed", { status: order.processed ? t("unprocessed") : t("processed") })}
						</Button>
					) : (
						<Label style={{ marginRight: 20 }} color={order.processed ? "teal" : "grey"}>
							{order.processed ? t("processed") : t("unprocessed")}
						</Label>
					)}
					<DeleteOrderAction order={order} invoices={invoices} />
				</Card.Content>
			)}
		</Card>
	);
}
