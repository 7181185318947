import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";

import { db, firebase } from "astrid-firebase";
import arrayChunk from "astrid-helpers/src/arrayChunk";

import ScheduledRow from "./ScheduledRow";

const ScheduledList = ({ publisher }) => {
	// get scheduled productions
	const [productions, setProductions] = useState([]);
	useEffect(() => {
		let ref = db.collection("productions").where("status", "==", "done").where("scheduled", "==", true);

		if (publisher) ref = ref.where("publisher", "==", publisher);

		return ref.onSnapshot((snapshot) => {
			const list = [];

			snapshot.forEach((doc) => {
				list.push({
					id: doc.id,
					...doc.data(),
				});
			});

			setProductions(list);
		});
	}, [publisher]);

	// get distributor settings
	const [distributorSettings, setDistributorSettings] = useState({});
	const producerId = "HfvG4FQfXWpWv6dzqM5E"; // MEMO: hard coded earselect

	// listen to producer distribution setup document
	useEffect(
		() =>
			db
				.collection("organizations")
				.doc(producerId)
				.collection("secrets")
				.doc("distribution")
				.onSnapshot((doc) => {
					setDistributorSettings(doc.data());
				}),
		[producerId],
	);

	// get publisher settings
	const [publisherSettings, setPublisherSettings] = useState();
	useEffect(() => {
		const publishers = productions.reduce((prev, curr) => {
			if (!prev.includes(curr.publusher)) prev.push(curr.publisher);

			return prev;
		}, []);

		if (publishers.length)
			Promise.all(
				arrayChunk(publishers, 10).map((chunk) =>
					db.collection("organizations").where(firebase.firestore.FieldPath.documentId(), "in", chunk).get(),
				),
			).then((snaps) => {
				const settings = {};
				snaps.forEach((snap) => {
					snap.docs.forEach((doc) => {
						const { name, distributionOptions } = doc.data();
						settings[doc.id] = { name, distributionOptions };
					});
				});

				setPublisherSettings(settings);
			});
	}, [productions]);

	return (
		<Table compact sortable basic="very">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>Titel</Table.HeaderCell>
					<Table.HeaderCell>Saknad info från förlag</Table.HeaderCell>
					<Table.HeaderCell>Distribution</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{sortBy(productions, ["title"]).map((production) => (
					<ScheduledRow
						key={production.id}
						production={production}
						distributorSettings={distributorSettings}
						publisher={publisherSettings?.[production.publisher]}
					/>
				))}
			</Table.Body>
		</Table>
	);
};

export default ScheduledList;
