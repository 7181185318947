import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import TitlesCollectionDataTable from "../../../titles/components/TitlesCollectionDataTable/TitlesCollectionDataTable";

export default function AdminTranslatorTitlesView({ translator, organization }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("titles", "Titles")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<TitlesCollectionDataTable
					getQuery={(query) =>
						query
							.where("translatorIds", "array-contains", translator.id)
							.where("publisher.id", "==", organization.id)
					}
				/>
			</Card.Content>
		</Card>
	);
}
