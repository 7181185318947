import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import { useOrganizationType } from "../../../../organizations/state/organizationType";

import TeamAgreementSelect from "../../TeamAgreementSelect/TeamAgreementSelect";

export default function TeamMemberAgreementField() {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();
	const organizationType = useOrganizationType();

	const { role, user, employee, production, firstPartyId, firstPartyVendorId } = watch();

	if (!user || employee || organizationType !== "producer") {
		return null;
	}

	return (
		<TeamAgreementSelect
			name="agreementId"
			role={role}
			user={user}
			production={production}
			firstPartyId={firstPartyId}
			firstPartyVendorId={firstPartyVendorId}
			additionalOptions={
				role === "reader" && [
					{
						key: "external",
						value: "external",
						text: `[${t("externalAgreements", "External agreement")}]`,
					},
				]
			}
		/>
	);
}
