import { useOutletContext, useParams } from "react-router-dom";

import MyProductionOffersView from "../views/MyProductionOffersView/MyProductionOffersView";

export default function MyProductionOffers() {
	const { filteredOffers, loading } = useOutletContext();
	const { id } = useParams();

	return <MyProductionOffersView selectedOfferId={id} offers={filteredOffers} loading={loading} />;
}
