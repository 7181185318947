const agreementStatuses = {
	PENDING_APPROVAL: "pending_approval",
	APPROVAL_DENIED: "approval_denied",
	APPROVED: "approved",
	SIGNING: "signing",
	SIGNED: "signed",
	SIGNING_FAILED: "signing_failed",
	SIGNING_EXPIRED: "signing_expired",
	SIGNING_CANCELLED: "signing_cancelled",
	ACTIVE: "active",
	EXPIRED: "expired",
	TERMINATED: "terminated",
};

export default agreementStatuses;
