import React from "react";
import { Header, Icon, Segment } from "semantic-ui-react";

import DocTitle from "astrid-web/src/components/DocTitle";

import ScheduledList from "../components/scheduled/ScheduledList";

const Scheduled = () => {
	return (
		<div>
			<DocTitle title={"Schemalagda leveranser"} />
			<Segment>
				<Header as="h4">
					<Icon name="truck" />
					<Header.Content>Schemalagda leveranser</Header.Content>
				</Header>
				<ScheduledList />
			</Segment>
		</div>
	);
};

export default Scheduled;
