import { Card } from "semantic-ui-react";

import AccountLanguages from "astrid-web/src/features/account/views/AccountSettingsView/components/AccountLanguages";

import AccountAudioSamples from "./components/AccountAudioSamples";
import AccountGeneral from "./components/AccountGeneral";
import AccountInfo from "./components/AccountInfo";
import AccountNarration from "./components/AccountNarration";

export default function AccountSettingsView({ user }) {
	const isReader = user.permissions?.reader;

	return (
		<>
			{user && (
				<Card.Group>
					<AccountInfo user={user} />
					<AccountGeneral user={user} />
					<AccountLanguages user={user} />
					{isReader && <AccountNarration user={user} />}
					{isReader && <AccountAudioSamples user={user} />}
				</Card.Group>
			)}
		</>
	);
}
