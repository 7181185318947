import React from "react";

import ProductionComments from "../../../../../components/production/parts/ProductionComments";

export default function ProductionDiscussion({ production, permissions, internalPublisher = false }) {
	return (
		<div className="scroll-fade">
			<ProductionComments
				key={internalPublisher}
				production={production}
				subcontractor={production.subcontractor}
				internalPublisher={internalPublisher}
				productionId={production.id}
				isPublisher={permissions?.isPublisher}
				productionFields={[
					"title",
					"producer",
					"publisher",
					"publisherManagerIds",
					"language",
					"reader",
					"readerStatus",
					"bookedRecorders",
					"manager",
					"proofer",
					"editor",
					"series",
					"lastCommentPublisher",
					"adminAttention",
				]}
			/>
		</div>
	);
}
