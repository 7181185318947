import { useContext } from "react";
import { useInstantSearch } from "react-instantsearch";
import { Button } from "semantic-ui-react";

import { ClearStateContext } from "../contexts/ClearStateProvider";
import useUserLanguages from "../hooks/useUserLanguages";

function hasActiveRefinements(indexUiState, userLanguages) {
	const { query, refinementList, range, sortBy, page } = indexUiState;
	const { language, ...rest } = refinementList || {};
	const onlyDefaultLang =
		(!userLanguages && !language?.length) || language?.every?.((lang) => userLanguages?.includes?.(lang));
	const hasRefined = !onlyDefaultLang || !!Object.keys(rest)?.length;
	return [!!query, hasRefined, !!range, !!sortBy, !!page].some((v) => v);
}

export default function Clear() {
	const { indexUiState, setIndexUiState } = useInstantSearch();
	const { triggerClear } = useContext(ClearStateContext);

	const userLanguages = useUserLanguages();

	const red = hasActiveRefinements(indexUiState, userLanguages);

	return (
		<Button
			toggle={false}
			color={red ? "red" : undefined}
			icon="trash"
			onClick={() => {
				setIndexUiState({ refinementList: { language: userLanguages } });
				triggerClear();
			}}
		/>
	);
}
