const roles = {
	publisherAdmin: "Administratör",
	publisherStaff: "Medarbetare",
	producerAdmin: "Administratör",
	producerStaff: "Produktionsansvarig",
	producerRecorder: "Inspelningstekniker",
	producerEditor: "Redigerare",
	producerProofer: "Korrekturlyssnare",
	producerSubcontractor: "Underleverantör",
	producerReaderManager: "Uppläsarhanterare",
	producerLimited: "Begränsad (ser endast produktioner hen bokats på)",
	reader: "Uppläsare",
	finance: "Ekonomi",
};

export default roles;
