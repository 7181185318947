import React from "react";
import { Header, Image } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";

export default function resultRenderer({ value }) {
	const { title, description, image, language } = value;

	return (
		<Flex alingItems="center" style={{ gap: 5 }}>
			<Image
				style={{ height: 40, width: 40, objectFit: "cover", flexShrink: 0 }}
				avatar
				src={image || "/img/image.png"}
			/>
			<div style={{ flex: 1 }}>
				<Header style={{ margin: 0 }} as="h5">
					{title}
				</Header>
				<p>{description}</p>
			</div>
			<Image
				avatar
				src={`https://unpkg.com/language-icons/icons/${language}.svg`}
				style={{ width: 20, height: 20 }}
			/>
		</Flex>
	);
}
