import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import useTitleVersionsDropdownMenuOptions from "./useTitleVersionsDropdownMenuOptions";

export default function useTitleVersionsTableColumns() {
	const { t } = useTranslation();

	return [
		timestampColumn({ id: "updated", Header: t("date", "Date"), collapsing: false }),
		{
			id: "updatedBy.name",
			Header: t("updatedBy", "Updated by"),
			disableSortBy: true,
		},
		dropdownMenuColumn(useTitleVersionsDropdownMenuOptions),
	];
}
