import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteHits } from "react-instantsearch";
import { Image, Popup, Segment } from "semantic-ui-react";

import { languageName } from "astrid-helpers/src/languages";

import FlexRow from "../../../../../components/Flex/FlexRow";
import ShowMore from "../../../../readers/components/ReaderSearchResults/components/ShowMore";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import DeliverySegment from "./DeliverySegment";
import DownloadFilesSegment from "./DownloadFilesSegment";
import MetaDataSegment from "./MetaDataSegment";

function Cell({ children }) {
	return <div style={{ flex: "1 1 0" }}>{children}</div>;
}

export default function ArchiveSearchResult() {
	const { t } = useTranslation();
	const { hits, results, isLastPage, showMore } = useInfiniteHits();
	const hitsLeft = results.nbHits - hits.length;

	const [activeIndexes, setActiveIndexes] = useState([]);

	function handleClick(e, props) {
		const { index } = props;
		if (activeIndexes.includes(index)) {
			setActiveIndexes(activeIndexes.filter((activeIndex) => activeIndex !== index));
		} else {
			setActiveIndexes(activeIndexes.concat(index));
		}
	}

	return (
		<Accordion styled>
			<FlexRow style={{ padding: "15px", flex: "1 1 0", gap: 10 }}>
				<Cell>
					<i>{t("isbn", "ISBN")}</i>
				</Cell>
				<Cell>
					<i>Imprint</i>
				</Cell>
				<Cell>
					<i>{t("title", "Title")}</i>
				</Cell>
				<Cell>
					<i>{t("author", "Author")}</i>
				</Cell>
				<Cell>
					<i>{t("narrator", "Narrator")}</i>
				</Cell>
				<Image avatar size="mini" style={{ marginLeft: 5, width: 25, height: 25 }} />
			</FlexRow>
			{hits.map((hit, index) => {
				const isCollapsed = activeIndexes.includes(index);
				return (
					<div key={`${hit.title}-${index}`}>
						<Accordion.Title index={index} onClick={handleClick}>
							<FlexRow style={{ flex: "1 1 0", gap: 10 }}>
								<Cell>{hit.isbn}</Cell>
								<Cell>{hit.imprintName}</Cell>
								<Cell>{hit.title}</Cell>
								{hit.authors?.length > 0 ? <Cell>{hit.authors.join(", ")}</Cell> : <Cell />}
								{hit.narrators?.length > 0 ? <Cell>{hit.narrators.join(", ")}</Cell> : <Cell />}
								{hit.languageCode && (
									<Popup
										key={hit.languageCode}
										size="small"
										position="bottom right"
										content={
											<>
												<strong>{languageName(hit.languageCode.toLowerCase())}</strong>
											</>
										}
										trigger={
											<Image
												avatar
												size="mini"
												src={`https://unpkg.com/language-icons/icons/${hit.languageCode.toLowerCase()}.svg`}
												style={{ marginLeft: 5, width: 25, height: 25 }}
											/>
										}
									/>
								)}
							</FlexRow>
						</Accordion.Title>
						<Accordion.Content active={isCollapsed}>
							{isCollapsed && (
								<Segment.Group horizontal>
									<DownloadFilesSegment hit={hit} />
									<MetaDataSegment hit={hit} />
									<DeliverySegment hit={hit} />
								</Segment.Group>
							)}
						</Accordion.Content>
					</div>
				);
			})}
			{hitsLeft > 0 && <ShowMore hitsLeft={hitsLeft} showMore={showMore} isLastPage={isLastPage} />}
		</Accordion>
	);
}
