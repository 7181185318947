import { Button, Icon } from "semantic-ui-react";

export default function ViewTypeButton({ children, viewPeriod, navigation, viewType, disableTools }) {
	const resourceView = `resourceTimeline${viewPeriod}`;
	const gridView = `timeGrid${viewPeriod}`;

	return (
		<Button.Group size="tiny" style={{ marginRight: 2 }}>
			<Button
				onClick={() => navigation.changeView(disableTools ? gridView : resourceView)}
				primary={viewType.includes(viewPeriod)}
			>
				{children}
			</Button>
			{!disableTools && (
				<>
					<Button
						icon
						onClick={() => navigation.changeView(resourceView)}
						primary={viewType === resourceView}
					>
						<Icon name="bars" />
					</Button>

					<Button icon onClick={() => navigation.changeView(gridView)} primary={viewType === gridView}>
						<Icon name="th" />
					</Button>
				</>
			)}
		</Button.Group>
	);
}
