const offerStatuses = {
	INITIAL: "initial",
	DRAFT: "draft",
	PENDING_SIGNING: "pending_signing",
	PENDING_APPROVAL: "pending_approval",
	APPROVAL_DENIED: "approval_denied",
	SIGNING: "signing",
	OFFERED: "offered",
	EXPIRED: "expired",
	ACCEPTED: "accepted",
	DECLINED: "declined",
	CANCELLED: "cancelled",
	INTERESTED: "interested",
	EXPIRE_FAILED: "expire_failed",
};

export const deleteableOfferStatuses = [
	offerStatuses.INITIAL,
	offerStatuses.DRAFT,
	offerStatuses.EXPIRED,
	offerStatuses.ACCEPTED,
	offerStatuses.DECLINED,
	offerStatuses.CANCELLED,
	offerStatuses.INTERESTED,
	offerStatuses.EXPIRE_FAILED,
];

export const cancellableOfferStatuses = [
	offerStatuses.PENDING_APPROVAL,
	offerStatuses.PENDING_SIGNING,
	offerStatuses.OFFERED,
	offerStatuses.SIGNING,
];

export const resolvedOfferStatuses = [
	offerStatuses.EXPIRED,
	offerStatuses.ACCEPTED,
	offerStatuses.DECLINED,
	offerStatuses.CANCELLED,
];

export const expireableOfferStatuses = [offerStatuses.PENDING_SIGNING, offerStatuses.SIGNING, offerStatuses.OFFERED];

export default offerStatuses;
