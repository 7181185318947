import { base, firebase } from "./";

export default function addEvent({ productionId, user, email, data }) {
	return base.addToCollection("productions/" + productionId + "/events", {
		user: user,
		email: email,
		data: data,
		time: firebase.firestore.FieldValue.serverTimestamp(),
	});
}
