import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Header, Icon } from "semantic-ui-react";

import { productionCategories } from "astrid-config/src/productionCategories/productionCategories";
import { db } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";

import useOrganization from "../../../features/organizations/hooks/useOrganization";
import { useOrganizationId } from "../../../features/organizations/state/organizationId";

export default function ProductionCategory({ productionId, production }) {
	const { t } = useTranslation();
	const orgId = useOrganizationId();
	const org = useOrganization(orgId);
	return org?.enableProductionCategory ? (
		<Form.Select
			label={
				<Header as="h4">
					<Icon.Group size="big">
						<Icon name="list layout" />
					</Icon.Group>
					<Header.Content>{t("productionCategory")}</Header.Content>
				</Header>
			}
			placeholder={t("selectProductionCategory")}
			value={production.category}
			options={productionCategories.map((productionCategory) => ({
				key: productionCategory,
				value: productionCategory,
				text: productionCategory,
			}))}
			onChange={(e, { value }) => {
				updateDocument(db.collection("productions").doc(productionId), { category: value });
			}}
		/>
	) : (
		<Form.Field />
	);
}
