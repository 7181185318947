import { useCallback } from "react";

import db from "astrid-firebase/src/db";
import createAgreementAppendix from "astrid-firestore/src/api/agreements/createAgreementAppendix";
import { updateDocument } from "astrid-firestore/src/helpers";
import createDocument from "astrid-firestore/src/helpers/createDocument";
import useBucket from "astrid-hooks/src/useBucket";

export default function useSubmitAppendix(appendix) {
	const bucket = useBucket("earselect-secret");

	return useCallback(
		async (formData) => {
			if (appendix) {
				await updateDocument(appendix.ref, {
					name: formData.name,
					producerIds: formData.producerIds,
					agreementTypes: formData.agreementTypes,
					agreementParts: formData.agreementParts,
				});
			} else {
				const fileExtension = formData.file.name.split(".").pop();
				const filePath = `appendixes/${Date.now()}.${fileExtension}`;

				const fileData = await bucket.putFile(filePath, formData.file);

				const appendix = createAgreementAppendix(db, {
					...formData,
					file: fileData,
				});

				await createDocument(appendix);
			}
		},
		[appendix, bucket],
	);
}
