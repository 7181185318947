import React, { useState } from "react";
import { Icon } from "semantic-ui-react";

import { useProfile } from "../../../features/authentication/state/profile";

import AlertToggle from "../AlertToggle";
import StarToggle from "../StarToggle";

import DeleteProduction from "./DeleteProduction/DeleteProduction";
import ProductionBilling from "./ProductionBilling";
import HistoryModal from "./ProductionHistory";

export default function ProductionHeader({ production, permissions }) {
	const profile = useProfile();

	const { isProducer, isPublisher } = permissions;

	// history modal
	const [historyOpen, setHistoryOpen] = useState(false);

	return (
		<div>
			<h2 style={{ float: "left" }}>
				{production.title} <StarToggle production={production.id} profile={profile} uid={profile?.id} />
				<AlertToggle production={production} profile={profile} uid={profile?.id} />
			</h2>

			{/* always show publisher and producers if they exist*/}
			{production.publisherName && (
				<span
					style={{
						verticalAlign: "-webkit-baseline-middle",
					}}
				>
					{"Publisher: " + production.publisherName + ". "}
				</span>
			)}
			{production.producerName && (
				<span
					style={{
						verticalAlign: "-webkit-baseline-middle",
					}}
				>
					{"Producer: " + production.producerName}
				</span>
			)}
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				{isProducer && isProducer.includes("producerAdmin") && (
					<>
						<Icon
							size="large"
							color="grey"
							name="history"
							style={{ cursor: "pointer" }}
							onClick={() => {
								setHistoryOpen(true);
							}}
						/>
						<ProductionBilling production={production} size="large" />
					</>
				)}
				<DeleteProduction
					productionId={production.id}
					productionStatus={production.status}
					isProducer={isProducer}
					isPublisher={isPublisher}
				/>
			</div>

			{/* history modal */}
			{historyOpen && (
				<HistoryModal
					productionId={production.id}
					onClose={() => {
						setHistoryOpen(false);
					}}
				/>
			)}
		</div>
	);
}
