import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useUserRoles() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			owner: t("owner", "Owner"),
			admin: t("admin", "Admin"),
			user: t("user", "User"),
		}),
		[t],
	);
}
