import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import mapScriptDate from "../../productions/utils/mapScriptDate";

function hasManager(prod) {
	return !!Object.values(prod?.team || {}).find((member) => member.role === teamRoles.MANAGER);
}

export default function mapProductionRequests({ productionRequests, orgId, profile }) {
	return productionRequests
		?.filter((prod) => !hasManager(prod) && profile?.producers?.[orgId]?.languageOptions?.includes?.(prod.language))
		.map((prod) => {
			return {
				...prod,
				scriptDate: mapScriptDate(prod),
			};
		});
}
