import useForm from "../../../../ui/hooks/useForm";

const validators = {
	name: (name) => name,
	file: (file) => file,
};

export default function useAppendixForm(appendix) {
	return useForm({
		validators,
		initialData: {
			name: appendix?.name || "",
			file: appendix?.file || null,
			producerIds: appendix?.producerIds || [],
			agreementTypes: appendix?.agreementTypes || [],
			agreementParts: appendix?.agreementParts || [],
		},
	});
}
