import React, { Component } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { base } from "astrid-firebase";

class StarToggle extends Component {
	toggle = (e) => {
		e.preventDefault();

		let starredProductions = this.props.profile.starredProductions;
		if (!starredProductions) starredProductions = [];

		if (starredProductions.includes(this.props.production)) {
			// unstar
			const index = starredProductions.indexOf(this.props.production);
			if (index !== -1) starredProductions.splice(index, 1);
		} else {
			// star
			starredProductions.push(this.props.production);
		}

		base.updateDoc("users/" + this.props.uid, { starredProductions });
	};

	render() {
		const { profile, production } = this.props;
		const active = profile.starredProductions && profile.starredProductions.includes(production);

		return (
			<Popup
				trigger={
					<Icon
						name={active ? "star" : "star outline"}
						color={active ? "yellow" : "grey"}
						onClick={this.toggle}
						style={{ cursor: "pointer" }}
					/>
				}
				content={active ? "Ta bort från favoritlistan" : "Favorit (listas på startsidan)"}
				size="mini"
				inverted
			/>
		);
	}
}

export default StarToggle;
