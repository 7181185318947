import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import ApproveOfferDropdownItem from "../../../../offers/components/OfferDropdownMenu/items/ApproveOfferDropdownItem";
import DenyOfferDropdownItem from "../../../../offers/components/OfferDropdownMenu/items/DenyOfferDropdownItem";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

export default function OfferDropdownMenu({ offer }) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			{(context) => (
				<>
					<ApproveOfferDropdownItem offer={offer} {...context} />
					<DenyOfferDropdownItem offer={offer} {...context} />

					<Dropdown.Item as={Link} to={`/production/${offer.production.id}/team`}>
						{t("showMore", "Show more")}
					</Dropdown.Item>
				</>
			)}
		</DropdownMenu>
	);
}
