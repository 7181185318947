import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

export default function sortOffers(offers) {
	return offers.sort((a, b) => {
		if (a.status === offerStatuses.OFFERED) {
			return -1;
		}

		if (b.status === offerStatuses.OFFERED) {
			return 1;
		}

		return (a.notification?.timestamp?.seconds || a.createdAt.seconds) <
			(b.notification?.timestamp?.seconds || b.createdAt.seconds)
			? 1
			: -1;
	});
}
