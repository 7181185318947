import React from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import FormSelect from "../../../forms/components/FormSelect/FormSelect";

import useTeamPriceOptions from "../../hooks/useTeamPriceOptions";

export default function TeamPriceSelect({ role, user, production, agreement, agreementId, ...props }) {
	const { t } = useTranslation();

	const [fetchedAgreement, agreementLoading, agreementError] = useDocumentDataOnce(
		!agreement && agreementId && db.collection("agreements").doc(agreementId),
	);

	const [options, optionsLoading, optionsError] = useTeamPriceOptions({
		role,
		user,
		agreement: agreement || fetchedAgreement,
		language: production.language,
		producerId: production.producer,
	});

	const loading = agreementLoading || optionsLoading;
	const error = agreementError || optionsError;

	return (
		<FormSelect
			label={t("price", "Price")}
			options={options}
			loading={loading}
			error={error?.message || error}
			search
			deburr
			{...props}
		/>
	);
}
