import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useTitleVersionsTableColumns from "../../hooks/useTitleVersionsTableColumns";

export default function TitleVersionsCollectionDataTable({ title }) {
	const columns = useTitleVersionsTableColumns();

	return (
		<CollectionDataTable
			query={title.ref.collection("versions")}
			columns={columns}
			sortBy={{
				id: "updated",
				desc: true,
			}}
		/>
	);
}
