import { useMemo } from "react";

import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import useProductionTeam from "../../productions/hooks/useProductionTeam";
import { filterUserHasLanguageOption, filterUserIsNotMember } from "../../users/utils/userFilters";
import { mapUserOption } from "../../users/utils/userMappers";
import { sortUserOption } from "../../users/utils/userSorters";

import useTeamUsersQuery from "./useTeamUsersQuery";

export default function useTeamUserOptions({ role, production }) {
	const team = useProductionTeam(production);

	const [users = [], loading, error] = useCollectionDataOnce(useTeamUsersQuery({ role, production }));

	const options = useMemo(
		() =>
			users
				.filter(filterUserIsNotMember({ role, team }))
				.filter(filterUserHasLanguageOption({ language: production.language, producerId: production.producer }))
				.map(mapUserOption(production.producer))
				.sort(sortUserOption),
		[users, role, team, production.language, production.producer],
	);

	return [options, loading, error];
}
