import { useMemo } from "react";

import useArticleTypeTranslations from "./useArticleTypeTranslations";

export default function useArticleTypeOptions() {
	const articleTypeTranslations = useArticleTypeTranslations();

	return useMemo(
		() =>
			Object.entries(articleTypeTranslations).map(([value, text]) => ({
				key: value,
				value,
				text,
			})),
		[articleTypeTranslations],
	);
}
