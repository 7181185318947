import { priceTypeFromRole } from "astrid-firestore/src/api/prices/utils/priceMappers";

export function filterActiveAgreements() {
	const now = new Date();

	return (agreement) =>
		(!agreement.activeFrom || agreement.activeFrom.toDate() <= now) && now < agreement.expires.toDate();
}

export function filterAgreementsByRole(role) {
	return (agreement) =>
		!role ||
		Object.keys(agreement.prices || {}).length === 0 ||
		agreement.part !== "user" ||
		Object.values(agreement.prices).some(({ type }) => type === priceTypeFromRole[role]);
}
