import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import Popup from "../../../ui/components/Popup/Popup";

export default function ArticleVersionActiveIcon({ article, version }) {
	const { t } = useTranslation();

	const isActive = article?.version?.id === version.id;

	if (!isActive) {
		return null;
	}

	return (
		<Popup
			content={t("activeVersion", "Active version")}
			trigger={<Icon color={"green"} name={"check circle outline"} />}
		/>
	);
}
