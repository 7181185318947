import React from "react";
import { useTranslation } from "react-i18next";
import { InstantSearch } from "react-instantsearch";
import { Button, Dimmer, Loader, Modal } from "semantic-ui-react";

import { db, firebase } from "astrid-firebase";
import createVoiceOffer from "astrid-firestore/src/api/offers/createVoiceOffer";
import { getDocumentData } from "astrid-firestore/src/helpers";

import Flex from "../../../../components/Flex/Flex";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";
import { getProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";

import useReaderSearchForm from "../ReaderSearchForm/hooks/useReaderSearchForm";
import ReaderSearchResults from "../ReaderSearchResults/ReaderSearchResults";

import CustomConfigure from "./components/CustomConfigure";
import ReaderSearchBar from "./components/ReaderSearchBar";
import ResultText from "./components/ResultText";
import ReaderFilterForm from "./forms/ReaderFilterForm";

export default function ReaderSearchForm({ voice, production, allReaderIds, submitText, onClose, ...props }) {
	const { t } = useTranslation();
	const form = useReaderSearchForm();

	const { watch, formState, handleSubmit } = form;
	const searchClient = useAlgoliaSearchClient();

	const indexName = "readers";

	const [filtersOpen, reader] = watch(["filtersOpen", "reader"]);

	const onSubmit = async ({ reader }) => {
		const user = await getDocumentData(db.collection("users").doc(reader.id));

		await createVoiceOffer(firebase, { production, voice, user, price: reader?.price, createdBy: getProfile() });

		form.setValue("reader", null);
	};

	return (
		<Modal open onClose={onClose} style={{ overflow: "hidden" }} {...props}>
			<LoadingContext loading={!searchClient}>
				<InstantSearch indexName={indexName} searchClient={searchClient}>
					<CustomConfigure form={form} production={production} />

					<Modal.Header>
						<Form form={form}>
							<ReaderSearchBar />

							{filtersOpen && <ReaderFilterForm />}
						</Form>
					</Modal.Header>

					<Modal.Content scrolling style={{ padding: 0, maxHeight: "40vh" }}>
						<ReaderSearchResults
							production={production}
							allReaderIds={allReaderIds}
							selectedId={reader?.id}
							onClick={(clickedReader) =>
								form.setValue("reader", clickedReader.id !== reader?.id ? clickedReader : null)
							}
						/>
					</Modal.Content>

					<Modal.Actions>
						<Flex>
							<ResultText />

							<Flex style={{ justifyContent: "flex-end", gap: 10 }}>
								<Button content={t("close")} onClick={onClose} />
								<Button
									primary
									content={submitText}
									disabled={!reader}
									onClick={handleSubmit(onSubmit)}
								/>
							</Flex>
						</Flex>
					</Modal.Actions>

					{formState.isSubmitting && (
						<Dimmer active inverted>
							<Loader />
						</Dimmer>
					)}
				</InstantSearch>
			</LoadingContext>
		</Modal>
	);
}
