import React from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import useDocTitle from "../../ui/hooks/useDocTitle";
import TabMenuLayout from "../../ui/layouts/TabMenuLayout/TabMenuLayout";

export default function PublisherMyProductions() {
	const { t } = useTranslation();
	useDocTitle(t("myProductions"));

	const { organization } = useOutletContext();

	const panes = [
		{
			text: t("current"),
			path: "current",
		},
		{
			text: t("completed"),
			path: "completed",
		},
	];

	return <TabMenuLayout context={{ organization }} panes={panes} />;
}
