import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import CreateDistributionPipelineButton from "../../../distribution/components/CreateDistributionPipelineForm/CreateDistributionPipelineButton";
import DistributionChannelPipelinesDataTable from "../../../distribution/components/DistributionChannelPipelinesDataTable/DistributionChannelPipelinesDataTable";

export default function SuperAdminDistributionChannelWorkflowsView({ channel }) {
	const { t } = useTranslation();

	return (
		<>
			<Card fluid>
				<Card.Content>
					<Card.Header>{t("workflows", "Workflows")}</Card.Header>
				</Card.Content>

				<Card.Content>
					<DistributionChannelPipelinesDataTable channel={channel} />
				</Card.Content>

				<Card.Content extra>
					<CreateDistributionPipelineButton channel={channel} />
				</Card.Content>
			</Card>
		</>
	);
}
