import { getUserRef } from "../../utils/referenceMappers";

import approvalStatuses from "./constants/approvalStatuses";

export default function createApprovalData(approved = null, user = null) {
	return {
		status:
			approved === true
				? approvalStatuses.APPROVED
				: approved === false
				? approvalStatuses.DENIED
				: approvalStatuses.PENDING,
		user: approved !== null ? getUserRef(user) : null,
		timestamp: approved !== null && user ? new Date() : null,
	};
}
