import { useEffect } from "react";
import { useConfigure, useInstantSearch } from "react-instantsearch";

export default function CustomConfigure({ form, production, excludeIds = [] }) {
	const { indexUiState } = useInstantSearch();
	const { query } = indexUiState;
	const { setValue } = form;

	const norwegianLanguages = ["nb", "nn", "no"];

	const languageFiltering = norwegianLanguages.includes(production.language)
		? norwegianLanguages.map((languageCode) => `languageSkills.${languageCode}.skill:5`).join(" OR ")
		: `languageSkills.${production.language}.skill:5`;

	const filters = [
		"filterable:true",
		query?.length ? "" : `(${languageFiltering})`,
		...excludeIds.map((id) => `NOT objectID:${id}`),
	]
		.filter((filter) => filter)
		.join(" AND ");

	useConfigure({
		filters,
	});

	useEffect(() => {
		setValue("reader", null);
	}, [setValue, indexUiState]);

	return null;
}
