import { db, firebase } from "astrid-firebase";
import createAgreementData from "astrid-firestore/src/api/agreements/createAgreementData";
import runBatch from "astrid-firestore/src/helpers/runBatch";

import { getProfile } from "../../../../authentication/state/profile";

export default function submitAgreements(formData) {
	return runBatch(db, (batch) => {
		Object.values(formData.parties)
			.map((secondParty) =>
				createAgreementData(firebase, {
					...formData,
					createdBy: getProfile(),
					secondParty,
				}),
			)
			.forEach((agreement) => batch.set(agreement.ref, agreement));
	});
}
