import { Dropdown } from "semantic-ui-react";

import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import ResourceHeaderButtons from "./ResourceHeaderButtons";

export default function ResourceHeaderDropdown(props) {
	return (
		<DropdownMenu direction="right">
			{() => (
				<>
					<Dropdown.Item>
						<ResourceHeaderButtons {...props} />
					</Dropdown.Item>
				</>
			)}
		</DropdownMenu>
	);
}
