import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message } from "semantic-ui-react";

import useUpdateAvailable from "../../features/ui/hooks/useUpdateAvailable";

function UpdateMessage() {
	const { t } = useTranslation();
	const updateAvailable = useUpdateAvailable();

	if (!updateAvailable) return false;

	return (
		<Message warning className="astrid-update">
			{t("newVersionMessage", "There's a new version of the app")}{" "}
			<Button
				size="small"
				color="green"
				onClick={(e) => {
					e.preventDefault();

					// service workers are disabled for now...
					// serviceWorker.unregister();

					window.location.reload(true);
				}}
			>
				{t("clickHere")}
			</Button>{" "}
			{t("toUpdateApp")}
		</Message>
	);
}

export default memo(UpdateMessage);
