import selectHasRight from "astrid-permissions/selectors/selectHasRight";

import { useProfile } from "../../authentication/state/profile";
import { useOrganizationId } from "../../organizations/state/organizationId";

import useHasRole from "./useHasRole";

export default function useHasRight() {
	const profile = useProfile();
	const orgId = useOrganizationId();
	const hasRole = useHasRole();

	const hasRight = selectHasRight(profile, orgId);

	return (right, checkRole = true) => {
		// Special case for publisher Bookea (hide reader registry)
		if (right === "readerRegistry.view" && orgId === "KUbqZ3OYu3nS9FkaLoYo") {
			return false;
		}

		return (checkRole && hasRole("owner", "admin")) || hasRight(right);
	};
}
