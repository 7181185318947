import _ from "lodash";
import get from "lodash/get";

import { getArticleType } from "./articles";

export function getArticleDistributionOptions({ production, article }) {
	const articleType = getArticleType(article);

	return production?.distributionOptions?.[articleType];
}

export function getArticleDistributionSettings({ article, distributor, distributionOptions }) {
	const articleType = getArticleType(article);

	return distributionOptions?.settings?.[articleType]?.[distributor];
}

export function getDistributionOptions({ production, publisher }) {
	const publisherOptions =
		get(publisher, "distributionOptions") &&
		Object.values(publisher.distributionOptions).find(
			(opt) => opt.languages && opt.languages.includes(production.language),
		);

	let mergedOptions = _.cloneDeep(publisherOptions);

	if (mergedOptions && production?.distributionOptions) {
		const productionOptions = production.distributionOptions;
		if (productionOptions.settings) {
			mergedOptions.settings = _.merge({}, publisherOptions.settings, productionOptions.settings);
		}

		const isDisabled = (key, type) => {
			return (
				productionOptions.settings &&
				productionOptions.settings[type] &&
				productionOptions.settings[type][key] &&
				productionOptions.settings[type][key].disabled
			);
		};

		const filterDisabled = (arr, type) => {
			return arr.filter((item) => !isDisabled(item, type));
		};

		mergedOptions.languages = _.union(publisherOptions.languages, productionOptions.languages || []);
		mergedOptions.ebook = filterDisabled(_.union(publisherOptions.ebook, productionOptions.ebook || []), "ebook");
		mergedOptions.total = filterDisabled(_.union(publisherOptions.total, productionOptions.total || []), "total");
		mergedOptions.part = filterDisabled(_.union(publisherOptions.part, productionOptions.part || []), "part");
	}

	return mergedOptions;
}
