import { useMemo } from "react";

import isApproved from "astrid-firestore/src/api/approval/isApproved";

export default function useFinalizableCosts(openCosts = []) {
	return useMemo(
		() =>
			openCosts.filter(
				({ price, openAmount, secondParty }) => price && isApproved(price) && openAmount > 0 && secondParty,
			),
		[openCosts],
	);
}
