import { Link } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";

export default function Breadcrumbs({ breadcrumbs }) {
	if (!breadcrumbs || breadcrumbs.length === 0) {
		return null;
	}

	return (
		<Breadcrumb
			icon="right angle"
			sections={breadcrumbs?.map(({ text, linkTo }, index) => ({
				key: index,
				as: linkTo ? Link : undefined,
				content: text,
				to: linkTo,
			}))}
		/>
	);
}
