import Form from "../../../../forms/components/Form/Form";
import ReaderInstantSearchSelect from "../../../../readers/components/ReaderInstantSearchSelect/ReaderInstantSearchSelect";

export default function CalendarFiltersReaderSelect() {
	return (
		<Form.Field name="readers" shouldUnregister={false}>
			<ReaderInstantSearchSelect multiple clearable />
		</Form.Field>
	);
}
