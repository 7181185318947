import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import useTeamMembersByRole from "../../team/hooks/useTeamMembersByRole";

export default function useRecorderOptions({ production, openPositionExists }) {
	const { t } = useTranslation();
	const productionRecorders = useTeamMembersByRole({
		team: production?.team,
		role: teamRoles.RECORDER,
	});

	return useMemo(
		() =>
			[
				...(productionRecorders?.map((recorder) => {
					return {
						key: recorder.id,
						value: recorder.user?.id,
						data: recorder.user?.name,
						text: recorder.user?.name,
					};
				}) || []),
				{
					key: sessionOfferTypes.ANY_RECORDER_IN_TEAM,
					value: sessionOfferTypes.ANY_RECORDER_IN_TEAM,
					text: `[${t("anyRecorderFromTeam")}]`,
				},
				openPositionExists && {
					key: sessionOfferTypes.OPEN_POSITION_RECORDER,
					value: sessionOfferTypes.OPEN_POSITION_RECORDER,
					text: `[${t("upForGrabs")}]`,
				},
			].filter((v) => v),
		[openPositionExists, productionRecorders, t],
	);
}
