import FirestoreDocument from "../../../types/FirestoreDocument";
import { array, currency, distributor, enumValues, language, nullable, ref, string } from "../../../types/_types";

import { Vendors } from "../../vendors/types/Vendor";

import organizationTypes from "../constants/organizationTypes";

export const organizationType = enumValues(organizationTypes);

export const Organization = FirestoreDocument.extend({
	adminUserIds: array(string()),
	currencyDefault: nullable(currency()),
	currencyOptions: array(currency()),
	distributors: array(distributor()),
	languageDefault: language(),
	languageOptions: array(language()),
	name: string(2),
	type: organizationType,
	vendors: Vendors,
});

export const OrganizationRef = ref(Organization, {
	name: true,
	type: true,
});
