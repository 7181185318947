import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import useCostTypeOptions from "../../../../costs/hooks/useCostTypeOptions";
import Form from "../../../../forms/components/Form/Form";
import CurrencySelect from "../../../../prices/components/CurrencySelect/CurrencySelect";
import VatSelect from "../../../../prices/components/VatSelect/VatSelect";
import usePriceAutoApproval from "../../../../prices/hooks/usePriceAutoApproval";
import usePriceUnitOptions from "../../../../prices/hooks/usePriceUnitOptions";

export default function PriceForm({ part, prefix, showVAT }) {
	const { t } = useTranslation();
	const { watch, setValue } = Form.useFormContext();

	const [price, production] = watch([prefix, "production"]);

	const priceUnitOptions = usePriceUnitOptions(part);
	const costTypeOptions = useCostTypeOptions(part);

	const [approved = false] = usePriceAutoApproval({ price, production });

	useEffect(() => {
		setValue(`${prefix}.approved`, approved);
	}, [approved, prefix, setValue]);

	const typeOptions = useMemo(
		() => [
			{
				key: "custom",
				value: "custom",
				text: t("priceCustom", "Custom"),
			},
			...costTypeOptions,
		],
		[costTypeOptions, t],
	);

	return (
		<>
			<Form.Group widths="equal">
				<Form.Input name={`${prefix}.name`} label={t("name")} defaultValue="" />

				{part && (
					<Form.Select
						name={`${prefix}.type`}
						label={t("type", "Type")}
						options={typeOptions}
						defaultValue="custom"
						fluid
					/>
				)}
			</Form.Group>

			<Form.Group widths="equal">
				<Form.Select
					name={`${prefix}.unit`}
					label={t("unit", "Unit")}
					options={priceUnitOptions}
					defaultValue="fixed"
					fluid
				/>

				<Form.Field
					name={`${prefix}.currency`}
					defaultValue={
						production.team?.[price?.memberId]?.price?.currency ||
						production?.productionPrice?.currency ||
						null
					}
				>
					<CurrencySelect label={t("currency", "Currency")} />
				</Form.Field>
				{showVAT && (
					<Form.Field name={`${prefix}.vat`} defaultValue={25}>
						<VatSelect label={t("VAT", "VAT")} />
					</Form.Field>
				)}
			</Form.Group>

			<Form.Group widths="equal">
				{["time", "hour"].includes(price?.unit) && (
					<Form.NumberInput
						name={`${prefix}.amount`}
						label={
							price?.unit === "hour"
								? t("amountOfHours", "Amount of hours")
								: t("amountOfMinutes", "Amount of minutes")
						}
						placeholder={t("leaveEmptyForDeliveryDuration", "Leave empty for delivery duration")}
						rules={{ required: false }}
					/>
				)}

				<Form.NumberInput
					name={`${prefix}.price`}
					label={t("price")}
					error={
						price.price &&
						prefix === "expense" &&
						!price.approved && {
							content: t("priceNeedsApproval", "Needs to be approved"),
							pointing: "above",
						}
					}
				/>
			</Form.Group>
		</>
	);
}
