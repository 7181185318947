// make sure name has leading capitals and all lower-case inside name... not correct for some names, but better than not correcting user input
export default (name) => {
	const lowerCaseAcceptedPart = ["van", "von", "voor", "af", "in", "der", "den", "de", "het", "ten", "the"];
	const lowerCaseAcceptedIntro = ["d'", "'t"];

	return name
		.split(" ")
		.map((part) => {
			const acceptedPart = lowerCaseAcceptedPart.includes(part);
			const acceptedIntro = lowerCaseAcceptedIntro.includes(part.substr(0, 2));

			// lower case everything if entire string is upper case, but not if it seems to be initials
			if (part === part.toUpperCase() && !part.includes(".") && part.length > 3) part = part.toLowerCase();

			// upper case after all dashes
			if (part.includes("-")) {
				part = part
					.split("-")
					.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
					.join("-");
			}

			// upper case after apostrophe unless accepted variation
			if (!acceptedIntro && part.includes("'")) {
				part = part
					.split("'")
					.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
					.join("'");
			}

			// make first letter upper case unless accepted variation
			if (!acceptedPart && !acceptedIntro) part = part.charAt(0).toUpperCase() + part.slice(1);

			return part;
		})
		.join(" ")
		.trim();
};
