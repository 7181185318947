import get from "lodash/get";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";

const getBillingStatus = (production) => {
	let hasCloud = get(production, "master.total.files.length") || get(production, "master.part_0.files.length");
	let hasProduction =
		!["external", "backlist"].includes(production.productionType) &&
		(get(production, "deliveryDuration") || get(production, "billingStatus.producer.billed"));

	let articles = {
		production:
			hasProduction &&
			// ignore if cloud is billed, but production is not… because it makes no sense
			!(
				get(production, "billingStatus.producer.billedCloud") &&
				!get(production, "billingStatus.producer.billed")
			)
				? {
						title: "Produktion ljudbok",
						billed: get(production, "billingStatus.producer.billed"),
						payed: get(production, "billingStatus.producer.payed"),
				  }
				: null,
		cloud: hasCloud
			? {
					title: "Molnlagring ljudbok",
					billed: get(production, "billingStatus.producer.billedCloud"),
					payed: get(production, "billingStatus.producer.payedCloud"),
			  }
			: null,
		ebook: get(production, "deliveryEbook.path")
			? {
					title: "Molnlagring e-bok",
					billed: get(production, "billingStatus.producer.billedEbook"),
					payed: get(production, "billingStatus.producer.payedEbook"),
			  }
			: null,
	};

	// some handy values
	const total = Object.values(articles).filter((b) => b).length;
	const billed = Object.values(articles).filter((b) => b && b.billed).length;
	const payed = Object.values(articles).filter((b) => b && b.payed).length;

	// remove all undefined values
	articles = omitBy(articles, isNil);
	Object.entries(articles).forEach(([key, obj]) => {
		articles[key] = omitBy(obj, isNil);
	});

	return {
		articles,
		total,
		billed,
		payed,
		status: !total ? "none" : total > billed ? "unbilled" : billed > payed ? "unpayed" : "payed",
	};
};

export default getBillingStatus;
