import { Button } from "semantic-ui-react";

import Flex from "../../../components/Flex/Flex";

import Form from "../../forms/components/Form/Form";

import PreviewMetadata from "../components/PreviewMetadata/PreviewMetadata";

export default function useArticleDistributionChannelsTableColumns(article) {
	return [
		{
			id: "name",
			flex: 1,
		},
		{
			id: "pipelines",
			Cell: ({ row, value }) => {
				return (
					<Flex gap={20}>
						{Object.values(value)
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((pipeline) => {
								return (
									<div key={pipeline.id}>
										<Form.Checkbox
											name={`channels.${row.id}.pipelines.${pipeline.id}`}
											label={pipeline.name}
											shouldUnregister={false}
											rules={{ required: false }}
										/>
									</div>
								);
							})}
					</Flex>
				);
			},
		},
		{
			id: "onixTemplate",
			Cell: ({ value }) => {
				return value ? <PreviewMetadata article={article} template={value} /> : null;
			},
		},
	];
}
