import { useMemo } from "react";

import useAgreementTypes from "./useAgreementTypes";

export default function useAgreementTypeOptions() {
	const agreementTypes = useAgreementTypes();

	return useMemo(
		() =>
			Object.entries(agreementTypes).map(([value, text]) => ({
				key: value,
				value,
				text,
			})),
		[agreementTypes],
	);
}
