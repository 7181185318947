import { useMemo } from "react";

import { db } from "astrid-firebase";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";
import useDebounce from "astrid-hooks/src/useDebounce";

export default function useTitleOptions({ organization, searchQuery = "" }) {
	const debouncedSearchQuery = useDebounce(searchQuery, 500);

	const [titles, loading, error] = useCollectionDataOnce(
		db
			.collection("titles")
			.where(`${organization.type}.id`, "==", organization.id)
			.where("name", ">=", debouncedSearchQuery),
	);

	const options = useMemo(() => {
		return (
			titles
				?.map((title) => ({
					key: title.id,
					value: title.id,
					text: title.name,
				}))
				.sort((a, b) => a.text.localeCompare(b.text)) || []
		);
	}, [titles]);

	return [options, loading, error];
}
