import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import cancelOffer from "astrid-firestore/src/api/offers/cancelOffer";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

import useHasRight from "../../../../authorization/hooks/useHasRight";
import useProductionTeam from "../../../../productions/hooks/useProductionTeam";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import AddTeamMemberDropdownItem from "../items/AddTeamMemberDropdownItem";
import DeleteOfferDropdownItem from "../items/DeleteOfferDropdownItem";
import PrepareOfferDropdownItem from "../items/PrepareOfferDropdownItem";

export default function InquiryOfferDropdownMenu({ offer, production }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	const team = useProductionTeam(production);

	const isTeamMember = team.some((member) => member.user?.id === offer.secondParty?.id);

	const canPrepareOffer =
		!offer.disabled &&
		!isTeamMember &&
		[
			offerStatuses.INITIAL,
			offerStatuses.DRAFT,
			offerStatuses.INTERESTED,
			offerStatuses.CANCELLED,
			offerStatuses.OFFERED,
			offerStatuses.DECLINED,
		].includes(offer.status);

	const canCancelOffer = !isTeamMember && [offerStatuses.OFFERED, offerStatuses.ACCEPTED].includes(offer.status);

	return (
		<DropdownMenu>
			{({ setLoading }) => (
				<>
					{hasRight("offers.prepare") && (
						<PrepareOfferDropdownItem
							text={t("prepareInquiry", "Prepare inquiry")}
							type={offerTypes.INQUIRY}
							offer={offer}
							disabled={!canPrepareOffer}
							production={production}
						/>
					)}

					{hasRight("offers.send") && (
						<PrepareOfferDropdownItem
							text={t("sendWorkOrder", "Send work order")}
							type={offerTypes.WORK_ORDER}
							offer={offer}
							disabled={!canPrepareOffer}
							production={production}
						/>
					)}

					{hasRight("offers.send") && (
						<Dropdown.Item
							text={t("cancel", "Cancel")}
							disabled={!canCancelOffer}
							onClick={async () => {
								setLoading(true);
								await cancelOffer(firebase, { offer });
								setLoading(false);
							}}
						/>
					)}

					{hasRight("offers.prepare") && (
						<AddTeamMemberDropdownItem
							text={t("alreadyContracted")}
							offer={offer}
							disabled={!canPrepareOffer}
							production={production}
						/>
					)}

					{hasRight("offers.prepare") && (
						<DeleteOfferDropdownItem
							offer={offer}
							disabled={isTeamMember}
							production={production}
							setLoading={setLoading}
						/>
					)}
				</>
			)}
		</DropdownMenu>
	);
}
