import React from "react";
import { useTranslation } from "react-i18next";

import { useDocumentDataOnce } from "astrid-firestore/src/hooks";

import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import ProductionOfferFormButton from "../ProductionOfferForm/ProductionOfferFormButton";

import useProductionAgreementsColumns from "./hooks/useProductionAgreementsColumns";

export default function ProductionAgreements({ production }) {
	const { t } = useTranslation();

	const [agreement, loading, error] = useDocumentDataOnce(production.productionPrice?.agreement?.ref);
	const columns = useProductionAgreementsColumns();

	return (
		<LoadingContext data={agreement} loading={loading} error={error}>
			{agreement ? (
				<FlexTable selectable data={[agreement]} columns={columns} />
			) : (
				<ProductionOfferFormButton production={production}>
					{t("addAgreement", "Add agreement")}
				</ProductionOfferFormButton>
			)}
		</LoadingContext>
	);
}
