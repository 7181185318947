import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useAdminArtifactMenuOptions({ artifact }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	return [
		{
			to: "",
			active: pathname.endsWith(artifact.id),
			text: t("parts", "Files"),
		},
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles"),
		},
	];
}
