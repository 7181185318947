import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import todoPriorities from "astrid-firestore/src/api/todos/constants/todoPriorities";

import todoPriorityColors from "../../../constants/todoPriorityColors";
import useTodoPriorityTranslations from "../../../hooks/useTodoPriorityTranslations";

export default function TodoIcon({ todo }) {
	const { t } = useTranslation();
	const priorityTranslations = useTodoPriorityTranslations();

	const { priority = todoPriorities.LOW, resolved } = todo;

	if (resolved.status) {
		let content = `${t("resolved", "Resolved")} ${moment(resolved.timestamp?.toDate()).fromNow()}`;

		if (resolved.user) {
			content += ` ${t("by", "by").toLowerCase()} ${resolved.user.name}`;
		}

		return (
			<Popup
				position="right center"
				size="tiny"
				content={content}
				trigger={
					<Icon
						fitted
						name="check circle"
						color="green"
						style={{
							fontSize: 18,
						}}
					/>
				}
			/>
		);
	}

	return (
		<Popup
			position="right center"
			size="tiny"
			content={priorityTranslations[priority]}
			trigger={
				<Icon
					fitted
					name="warning circle"
					color={todoPriorityColors[priority]}
					style={{
						fontSize: 18,
					}}
				/>
			}
		/>
	);
}
