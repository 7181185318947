import api from "../../../../api";
import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import FirestoreDocumentContext from "../../../firebase/components/FirestoreDocumentContext";

import AdminTranslatorView from "../../views/AdminTranslatorView/AdminTranslatorView";

export default function AdminTranslator() {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<FirestoreDocumentContext param="translator" api={api.translators}>
			<AdminTranslatorView />
		</FirestoreDocumentContext>
	);
}
