import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useAgreementTemplatesQuery({ type, part, deleted = false, firstPartyId } = {}) {
	return useMemo(
		() =>
			createQuery(db.collection("agreementTemplates"), {
				type,
				part,
				deleted,
				"firstParty.id": firstPartyId,
			}),
		[type, part, deleted, firstPartyId],
	);
}
