import { useEffect } from "react";

// simple doc title setter with support for nested doc titles
// TODO: should use ref or context but couldn't get it to work so... storing docTitles in window.ES for now.

const setTitle = (updatedTitles) => {
	window.ES.docTitles = updatedTitles;
	const title = updatedTitles[updatedTitles.length - 1];
	if (title) window.document.title = updatedTitles[updatedTitles.length - 1];
};

export default function DocTitle({ title }) {
	useEffect(() => {
		const titles = window.ES.docTitles || [window.ES.stage ? "STAGE Astrid" : "Astrid"];
		titles.push(title);
		setTitle(titles);

		return () => {
			const i = titles.indexOf(title);
			titles.splice(i, 1);
			setTitle(titles);
		};
	}, [title]);

	return null;
}
