import get from "lodash/get";
import { useMemo } from "react";

export default function useValidation(data = {}, validators = {}) {
	const validation = useMemo(
		() =>
			Object.entries(validators).reduce(
				(map, [name, validator]) => ({
					...map,
					[name]: typeof validator === "function" ? !!validator(get(data, name), data) : !!validator,
				}),
				{},
			),
		[data, validators],
	);

	const isValid = useMemo(() => Object.values(validation).every((valid) => valid), [validation]);

	const errors = useMemo(
		() =>
			Object.entries(validation)
				.filter(([, valid]) => !valid)
				.reduce((map, [key]) => ({ ...map, [key]: true }), {}),
		[validation],
	);

	return [isValid, errors, validation];
}
