import { Dropdown } from "semantic-ui-react";

import ViewAgreementDropdownItem from "../../../../agreements/components/AgreementDropdownMenu/items/ViewAgreementDropdownItem";

import DeleteCostAction from "./DeleteCostAction";

export default function FinalizedCostActions({ row }) {
	return (
		<Dropdown icon="ellipsis vertical">
			<Dropdown.Menu>
				<ViewAgreementDropdownItem agreement={row.original.agreement} />
				<DeleteCostAction cost={row.original} />
			</Dropdown.Menu>
		</Dropdown>
	);
}
