import { getOrganizationRef } from "../../utils/referenceMappers";

import createApprovalData from "../approval/createApprovalData";

export default function createPrice(db, data = {}) {
	const {
		vat = 0,
		uses = 0,
		type = null,
		name = "",
		unit = "time",
		price = 0,
		users = null,
		deleted = false,
		currency = null,
		approved = null,
		employee = false,
		producer = null,
		languages = [],
	} = data;

	const ref = data.ref || db.collection("prices").doc();

	return {
		...data,
		id: ref.id,
		ref,
		vat: +vat,
		uses,
		type,
		name,
		unit,
		price: +price || 0,
		users,
		deleted,
		created: new Date(Date.now()),
		currency,
		approval: createApprovalData(approved || null),
		employee,
		producer: getOrganizationRef(producer),
		languages,
	};
}
