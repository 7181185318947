import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Header, Table } from "semantic-ui-react";

import { base, firebase } from "astrid-firebase";

import { withStore } from "../../helpers/context";

class ReaderCampaign extends Component {
	componentDidMount() {
		this.props.store.getUsers();
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header as="h4" icon="linkify" content={t("yourInvitingLinks")} />
				<Table compact basic="very" sortable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("campaignInvite")}</Table.HeaderCell>
							<Table.HeaderCell>{t("linkInvite")}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>{t("generalInvite")}</Table.Cell>
							<Table.Cell style={{ userSelect: "all" }}>
								{window.location.href + "readerSignup/" + this.props.store.state.user.uid}
							</Table.Cell>
						</Table.Row>
						{(this.props.store.state.profile.readerCampaign || []).map((id) => (
							<Table.Row key={id}>
								<Table.Cell>{id}</Table.Cell>
								<Table.Cell style={{ userSelect: "all" }}>
									{window.location.href +
										"readerSignup/" +
										this.props.store.state.user.uid +
										"/" +
										id}
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
				<Form
					onSubmit={(e) => {
						const campaignId = document.querySelector("#campaignId").value;
						base.updateDoc("users/" + this.props.store.state.user.uid, {
							readerCampaign: firebase.firestore.FieldValue.arrayUnion(campaignId),
						}).then((e) => {
							document.querySelector("#campaignId").value = "";
						});
					}}
				>
					<Form.Group>
						<Form.Input
							id="campaignId"
							required
							pattern="^[-a-zA-Z0-9@\.+_]+$"
							placeholder={t("campaignId")}
						/>
						<Form.Button primary content={t("createNewCampaign")} />
					</Form.Group>
				</Form>

				<Header as="h4" icon="microphone" content={t("yourReaders")} />
				<Table compact basic="very" sortable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("nameYourReaders")}</Table.HeaderCell>
							<Table.HeaderCell>{t("campaignYourReaders")}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{this.props.store.state.users &&
							Object.values(this.props.store.state.users)
								.filter(
									(user) =>
										user.permissions.reader && user.referrer === this.props.store.state.user.uid,
								)
								.map((reader) => (
									<Table.Row key={reader.id}>
										<Table.Cell>
											<Link to={"/profile/" + reader.id}>
												{reader.firstName} {reader.lastName}
											</Link>
										</Table.Cell>
										<Table.Cell>{reader.referrerCampaign || <em>{t("noOne")}</em>}</Table.Cell>
									</Table.Row>
								))}
					</Table.Body>
				</Table>
			</>
		);
	}
}

export default withTranslation()(withStore(ReaderCampaign));
