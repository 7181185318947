import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import useHasRight from "../../authorization/hooks/useHasRight";

import useProductionRequestsQuery from "../views/ProductionRequests/hooks/useProductionRequestsQuery";

export default function useProductionRequests({ orgId }) {
	const hasRight = useHasRight();
	return useCollectionData(
		useProductionRequestsQuery({ orgId: hasRight("myProductions.productionRequests") && orgId }),
	);
}
