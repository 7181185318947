const currentDate = new Date();
const currentYear = currentDate.getFullYear();
export const years = [
	{
		key: currentYear,
		text: currentYear,
		value: currentYear,
	},
	{
		key: currentYear - 1,
		text: currentYear - 1,
		value: currentYear - 1,
	},
	{
		key: currentYear - 2,
		text: currentYear - 2,
		value: currentYear - 2,
	},
];
