import { Outlet, useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";
import BiblioTasksLayout from "../../biblio/layouts/BiblioTasksLayout/BiblioTasksLayout";

export default function SuperAdminBiblioTasks() {
	const hasRight = useHasRight();

	const context = useOutletContext();

	const { uncompletedBiblioTaskCount } = context;

	if (!hasRight("biblio.admin")) {
		return <Unauthorized />;
	}

	return (
		<BiblioTasksLayout uncompletedTaskCount={uncompletedBiblioTaskCount}>
			<Outlet context={context} />
		</BiblioTasksLayout>
	);
}
