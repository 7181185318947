import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import useHasRight from "../../../authorization/hooks/useHasRight";

import useSoftDelete from "../../hooks/useSoftDelete";

export default function DropdownItemSoftDelete({ data, right, beforeDelete, afterDelete, setLoading }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const softDelete = useSoftDelete();

	if (data.deleted || (right && !hasRight(right))) {
		return null;
	}

	return (
		<Dropdown.Item
			text={t("remove", "Remove")}
			onClick={async () => {
				setLoading?.(true);

				try {
					if (beforeDelete) {
						await beforeDelete(data);
					}

					await softDelete(data.ref);
				} catch (error) {
					window.alert(t("softDeleteFailed", "Failed to delete resource!"));
					console.error(error);
				}

				if (afterDelete) {
					await afterDelete(data);
				}

				setLoading?.(false);
			}}
		/>
	);
}
