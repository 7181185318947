import { enumValues, lazy, nullable, number, object, record, string } from "../../../types/_types";

import pipelineStatuses from "../../pipelines/constants/pipelineStatuses";
import { Pipeline } from "../../pipelines/types/Pipeline";

const pipelineStatus = enumValues(pipelineStatuses).default(pipelineStatuses.PENDING);

const progress = nullable(
	record(
		string(),
		object({
			status: pipelineStatus,
			executionTime: nullable(number()),
			progress: lazy(() => progress),
		}).passthrough(),
	),
);

export const DistributionPipeline = Pipeline.extend({
	progress,
}).partial();
