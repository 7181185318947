import { useTranslation } from "react-i18next";
import { Card, Message } from "semantic-ui-react";

import isPendingApproval from "astrid-firestore/src/api/approval/isPendingApproval";

import useAgreementParts from "../../hooks/useAgreementParts";
import useAgreementTypes from "../../hooks/useAgreementTypes";

export default function AgreementTemplateCard({ template }) {
	const { t } = useTranslation();

	const agreementTypes = useAgreementTypes();
	const agreementParts = useAgreementParts();

	return (
		<Card fluid>
			<Card.Content>
				{isPendingApproval(template) && (
					<Message info>{t("waitingForApproval", "Waiting for approval")}</Message>
				)}

				<Card.Header>{template.name}</Card.Header>

				<Card.Description>
					{agreementParts[template.part]} ({agreementTypes[template.type]})
				</Card.Description>
			</Card.Content>

			<Card.Content extra>
				<Card.Description>
					<strong>{t("agreementTime")}:</strong> {template.life}
				</Card.Description>
			</Card.Content>

			{template.type === agreementTypes.FRAMEWORK && template.part === "publisher" && (
				<Card.Content extra>
					<Card.Description>
						<strong>{t("canManageAgreements", "Can manage user agreements for this publisher")}:</strong>{" "}
						{template.canManageAgreements ? t("yes") : t("no")}
					</Card.Description>
				</Card.Content>
			)}
		</Card>
	);
}
