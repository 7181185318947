import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import producerRoles from "astrid-firestore/src/api/organizations/constants/producerRoles";
import publisherRoles from "astrid-firestore/src/api/organizations/constants/publisherRoles";

export default function useOrganizationRoleTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[publisherRoles.ADMIN]: t("admin", "Admin"),
			[publisherRoles.RESOURCE]: t("publisherStaff", "Resource"),

			[producerRoles.ADMIN]: t("admin", "Admin"),
			[producerRoles.MANAGER]: t("producerStaff", "Production coordinator"),
			[producerRoles.RECORDER]: t("producerRecorder", "Recording engineer"),
			[producerRoles.EDITOR]: t("producerEditor", "Editor"),
			[producerRoles.PROOFER]: t("producerProofer", "Proof listener"),
			[producerRoles.SUBCONTRACTOR]: t("producerSubcontractor", "Subcontractor"),
			[producerRoles.READER_MANAGER]: t("producerReaderManager", "Narrator handler"),
			[producerRoles.LIMITED]: t("producerLimited", "Limited (can only view assigned productions)r"),
		}),
		[t],
	);
}
