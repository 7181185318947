import { useMemo } from "react";

import useHasRight from "../../../authorization/hooks/useHasRight";
import CreateOrdersButton from "../../../orders/components/CreateOrdersButton/CreateOrdersButton";

import CostsBalance from "./CostsBalance";

export default function CostsActions({ data, selectedFlatRows, ...props }) {
	const hasRight = useHasRight();

	const selectedCosts = useMemo(() => selectedFlatRows.map(({ original }) => original), [selectedFlatRows]);

	return (
		<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
			<div>{hasRight("orders.create") && <CreateOrdersButton costs={selectedCosts} {...props} />}</div>

			<CostsBalance costs={data} />
		</div>
	);
}
