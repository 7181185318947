import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminArticleBundleHeader from "./components/AdminArticleBundleHeader";

export default function AdminArticleBundleView(props) {
	return (
		<AdminPageView {...props}>
			<AdminArticleBundleHeader />
		</AdminPageView>
	);
}
