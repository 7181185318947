import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import BlurInput from "../../../../../components/BlurInput";
import useCostTypeOptions from "../../../../costs/hooks/useCostTypeOptions";
import Form from "../../../../forms/components/Form/Form";
import useEmployeeOptions from "../../../../users/hooks/useEmployeeOptions";

import usePriceUnitOptions from "../../../hooks/usePriceUnitOptions";

import VatSelect from "../../VatSelect/VatSelect";

const parts = ["user", "subcontractor"];

export default function usePricesColumns({ onRemove }) {
	const { t } = useTranslation();

	const unitOptions = usePriceUnitOptions();
	const employeeOptions = useEmployeeOptions();
	const costTypeOptions = useCostTypeOptions(parts);

	return useMemo(
		() => [
			{
				id: "type",
				Header: t("type", "Type"),
				Cell: ({ row }) => (
					<Form.Select
						name={`prices.${row.index}.type`}
						fluid
						search
						upward
						options={costTypeOptions}
						placeholder={t("chooseType", "Select type")}
					/>
				),
			},
			{
				id: "name",
				Header: t("name", "Name"),
				Cell: ({ row }) => (
					<Form.Field name={`prices.${row.index}.name`}>
						<BlurInput placeholder={t("namePrice", "Name price variant")} />
					</Form.Field>
				),
			},
			{
				id: "price",
				Header: t("price", "Price"),
				Cell: ({ row }) => (
					<Form.NumberInput
						name={`prices.${row.index}.price`}
						rules={{
							min: 0,
							required: true,
							valueAsNumber: true,
						}}
					/>
				),
			},
			{
				id: "unit",
				Header: t("unit", "Unit"),
				Cell: ({ row }) => <Form.Select name={`prices.${row.index}.unit`} fluid upward options={unitOptions} />,
			},
			{
				id: "employee",
				Header: t("employee", "Employee"),
				Cell: ({ row }) => (
					<Form.Select
						name={`prices.${row.index}.employee`}
						options={employeeOptions}
						rules={{ required: false }}
						fluid
						upward
					/>
				),
			},
			{
				id: "vat",
				Header: t("VAT", "VAT"),
				Cell: ({ row }) => (
					<Form.Field name={`prices.${row.index}.vat`}>
						<VatSelect upward />
					</Form.Field>
				),
			},
			{
				id: "remove",
				Cell: ({ row }) => (
					<Icon name="trash alternate outline" style={{ cursor: "pointer" }} onClick={() => onRemove(row)} />
				),
			},
		],
		[costTypeOptions, employeeOptions, onRemove, t, unitOptions],
	);
}
