import notify, { markdownMessage } from "astrid-firebase/src/notifications";

import getSessionInformation from "../utils/sessionInformation";

export default function notifyChangedSession({ before, after, userIds, t }) {
	return notify(userIds, (user) => {
		const lang = user.languageCode || "en";

		const subject = t("changedSessionSubject", "Your session has been changed", { lng: lang });

		const message = t("sessionNotificationInformation", {
			lng: lang,
			...getSessionInformation({ before, after, lang }),
		});
		return {
			subject,
			message: markdownMessage({ message, lang }),
			sendOnStage: true,
			html: true,
		};
	});
}
