import equal from "fast-deep-equal";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button, Card } from "semantic-ui-react";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import useOrganizationsOnce from "../../../../organizations/hooks/useOrganizationsOnce";
import Table from "../../../../ui/components/Table/Table";

import usePublishersColumns from "../hooks/usePublishersColumns";

function Footer({ producer, state }) {
	const { t } = useTranslation();

	const publishers = useMemo(() => Object.keys(state.selectedRowIds), [state.selectedRowIds]);

	const disabled = useMemo(() => equal(publishers, producer.publishers), [producer.publishers, publishers]);

	const [{ loading }, onClickSave] = useAsyncFn(() => updateDocument(producer.ref, { publishers }), [publishers]);

	return (
		<Button.Group>
			<Button primary loading={loading} disabled={disabled} onClick={onClickSave}>
				{t("save")}
			</Button>
		</Button.Group>
	);
}

export default function ProducerPublishers({ producer }) {
	const { t } = useTranslation();

	const publishers = useOrganizationsOnce({ type: "publisher" });
	const publishersColumns = usePublishersColumns();

	const selectedRowIds = useMemo(
		() => producer.publishers?.reduce((map, id) => ({ ...map, [id]: true }), {}) || {},
		[producer.publishers],
	);

	if (publishers.length === 0) {
		return false;
	}

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("publisherAccess", "Publisher access")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Table
					sortable
					selectable
					filterable
					pagination
					data={publishers}
					columns={publishersColumns}
					initialState={{ pageSize: 5, selectedRowIds }}
					footer={<Footer producer={producer} />}
					sortBy={{
						id: "name",
						desc: false,
					}}
				/>
			</Card.Content>
		</Card>
	);
}
