export default function getLocationOrganization(location = window.location) {
	const isOrganizationPath =
		location.pathname.startsWith("/producer") ||
		location.pathname.startsWith("/publisher") ||
		location.pathname.startsWith("/narrator");

	if (isOrganizationPath) {
		const [, type, id] = location.pathname.split("/");

		return { id, type };
	}
}
