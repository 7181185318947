import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import approvalStatuses from "astrid-firestore/src/api/approval/constants/approvalStatuses";

export default function useApprovalStatusTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[approvalStatuses.PENDING]: t("pending", "Pending"),
			[approvalStatuses.APPROVED]: t("approved", "Approved"),
			[approvalStatuses.DENIED]: t("denied", "Denied"),
		}),
		[t],
	);
}
