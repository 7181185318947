import { useMemo } from "react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

export default function useUserOrganizations(user) {
	return useMemo(
		() =>
			Object.entries(user?.organizations || {})
				.map(([id, organization]) => ({ id, ...organization }))
				.concat(
					user?.permissions?.reader?.length
						? [
								{
									id: user.id,
									name: `${user.firstName} ${user.lastName}`,
									type: organizationTypes.NARRATOR,
								},
						  ]
						: [],
				),
		[user],
	);
}
