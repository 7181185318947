import todoTypes from "../../../../constants/todoTypes";

import getOfferProductionManagers from "../../helpers/team/managers/getOfferProductionManagers";

import createTodoData from "../todos/createTodoData";

export default function createReaderInquiryDraftTodoData(firebase, { offer, status }) {
	const productionManagers = getOfferProductionManagers({ offer });

	return createTodoData(firebase, {
		type: todoTypes.READER_INQUIRY_DRAFT,
		organizationIds: [offer.producer.id],
		userIds: productionManagers.map((pM) => pM.id),
		users: productionManagers,
		subjectRef: offer.ref,
		url: `/production/${offer.production?.id}/readers`,
		languages: [offer.production.language],
		content: {
			offer: {
				status,
				id: offer.id,
			},
			reader: {
				id: offer.secondParty.id,
				name: offer.secondParty.name,
			},
			production: {
				title: offer?.production?.title,
				id: offer?.production.id,
			},
			publisher: {
				id: offer?.publisher?.id,
				name: offer?.publisher?.name,
			},
		},
	});
}
