import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { languageOptions } from "astrid-helpers/src/languages";

import Select from "../../../ui/components/Select/Select";

export default function UserLanguageSelect({ user, producerId, ...props }) {
	const { t } = useTranslation();

	const userLanguageOptions = useMemo(
		() => languageOptions.filter(({ value }) => user.producers?.[producerId]?.languageOptions?.includes(value)),
		[producerId, user.producers],
	);

	return <Select search fluid label={t("language")} options={userLanguageOptions} {...props} />;
}
