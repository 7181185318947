import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

import prodGenres from "astrid-config/src/genres/storytel";
import { updateDocument } from "astrid-firestore/src/helpers";
import Flex from "astrid-web/src/components/Flex/Flex";
import useFileUpload from "astrid-web/src/features/ui/hooks/useFileUpload";

import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";

export default function AccountAudioSamplesModal({ user, onClose }) {
	const { t } = useTranslation();
	const ref = useRef();

	const form = useForm({
		defaultValues: {
			id: +new Date(),
			file: null,
			genre: null,
		},
	});

	const { handleSubmit, formState, setValue, getValues, watch } = form;

	const { file } = watch();

	const { isSubmitting, isValid } = formState;

	const [{ loading }, onUploadFile] = useFileUpload({
		bucketName: "earselect-static",
		getDestination: () => `profileAudioSamples/${user.id}/sample-${getValues("id")}.mp3`,
		onSuccess: ({ url }) => {
			setValue("file", url);
		},
	});

	const onSubmit = async ({ id, file, genre }) => {
		try {
			await updateDocument(user.ref, {
				[`readerData.samples.${id}`]: {
					file,
					genre,
				},
			});

			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	const genreOptions = Object.entries(prodGenres.sv).map(([key]) => ({
		key: key,
		value: key,
		text: t(key),
	}));

	return (
		<Modal open closeOnDimmerClick onClose={onClose} size="small">
			<Modal.Header>{t("addAudioFile", "Add audio file")}</Modal.Header>
			<Modal.Content>
				<Form form={form}>
					<Form.Group widths="equal">
						<div className="field">
							<label style={file && { visibility: "hidden" }}>{t("file", "File")}</label>
							<Flex>
								{file ? (
									<audio
										style={{
											width: "100%",
											height: 39,
										}}
										src={file}
										controls
										onPlay={(e) => {
											const allAudio = document.querySelectorAll("audio");

											for (const audio of allAudio) {
												if (audio !== e.target) audio.pause();
											}
										}}
									/>
								) : (
									<>
										<Button
											loading={loading}
											fluid
											basic
											content={t("upload", "Upload")}
											icon="download"
											color="black"
											labelPosition="left"
											style={{ height: 38 }}
											onClick={() => {
												ref.current.click();
											}}
										/>
									</>
								)}
							</Flex>
						</div>
						<Form.Select name="genre" label={t("genre", "Genre")} options={genreOptions} />
					</Form.Group>
				</Form>
				<input style={{ display: "none" }} ref={ref} type="file" onChange={onUploadFile} />
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>{t("cancel", "Cancel")}</Button>
				<Button primary loading={isSubmitting} disabled={!isValid || !file} onClick={handleSubmit(onSubmit)}>
					{t("add", "Add")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
