import db from "astrid-firebase/src/db";
import getCollectionData from "astrid-firestore/src/helpers/getCollectionData";

function isNotExpired(agreement) {
	const now = new Date();

	return (!agreement.activeFrom || agreement.activeFrom.toDate() <= now) && now < agreement.expires.toDate();
}

export async function isOkToDelete(vendorKey, isProducer = false) {
	const costs = await getCollectionData(
		db
			.collection("costs")
			.where("orderId", "==", null)
			.where("deleted", "==", false)
			.where(isProducer ? "producer.vendor.id" : "vendor.id", "==", vendorKey),
	);

	if (costs.length > 0) {
		return false;
	}

	const agreements = await getCollectionData(
		db
			.collection("agreements")
			.where("state", "==", "SIGNED")
			.where("deleted", "==", false)
			.where(isProducer ? "firstParty.vendor.id" : "secondParty.vendor.id", "==", vendorKey),
	);

	if (agreements.filter(isNotExpired).length > 0) {
		return false;
	}

	return true;
}
