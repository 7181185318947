import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Icon, Label, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import db from "astrid-firebase/src/db";
import createProductionPriceData from "astrid-firestore/src/api/productions/prices/createProductionPriceData";
import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";
import { useDocumentData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import useHasRight from "../../authorization/hooks/useHasRight";
import Form from "../../forms/components/Form/Form";
import useForm from "../../forms/hooks/useForm";

import SessionBaseForm from "./SessionBaseForm";
import SessionContext from "./SessionContext";

export default function BookSession({ bookingActive, setState, state }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	const { productionId } = useParams();
	const [showModal, setShowModal] = useState(!!productionId);

	const [production, loadingProduction, errorProduction] = useDocumentData(
		productionId && db.collection("productions").doc(productionId),
	);

	const form = useForm({
		defaultValues: {
			reader: state.bookReader || production?.reader || [],
			recorder: state.bookRecorder || null,
			recorderName: null,
			studio: state.bookStudio || null,
			priceId: state.priceId || null,
			priceRef: state.priceRef || null,
			agreementRef: state.agreementRef || null,
			polish: state.bookPolishSession || null,
			production: production?.id || null,
		},
		values: {
			reader: state.bookReader || production?.reader || [],
			recorder: state.bookRecorder || null,
			recorderName: null,
			studio: state.bookStudio || null,
			priceId: state.priceId || null,
			priceRef: state.priceRef || null,
			agreementRef: state.agreementRef || null,
			polish: state.bookPolishSession || null,
			production: production?.id || null,
		},
	});

	const { handleSubmit } = form;

	const submit = handleSubmit((formData) => {
		const createProductionPriceCallback = async (session) => {
			if (
				![sessionOfferTypes.OPEN_POSITION_RECORDER, sessionOfferTypes.ANY_RECORDER_IN_TEAM, null].includes(
					formData.recorder,
				) &&
				formData.priceRef &&
				formData.agreementRef
			) {
				const price = formData?.priceRef;
				const agreement = formData?.agreementRef;

				const productionPrice = createProductionPriceData(firebase, {
					...price,
					expense: true,
					approved: true,
					producer: production.producer,
					production,
					agreement,
					firstParty: agreement?.firstParty,
					secondParty: agreement?.secondParty,
					referencePrice: price,
					session,
				});
				await productionPrice.ref.set({ ...productionPrice });
				return productionPrice.ref;
			}
		};
		setState({
			bookProduction: productionId,
			bookReader: formData.reader,
			bookStudio: formData.studio,
			bookRecorder: formData.recorder,
			bookRecorderName: formData.recorderName,
			priceId: formData.priceId || null,
			priceRef: formData.priceRef || null,
			agreementRef: formData.agreementRef || null,
			createProductionPriceCallback,
			bookPolishSession: formData.polish,
		});
		setShowModal(false);
	});

	const reset = () => {
		setState({
			bookReader: [],
			bookRecorder: null,
			bookRecorderName: null,
			bookStudio: null,
			priceId: null,
			priceRef: null,
			agreementRef: null,
			createProductionPriceCallback: null,
			bookPolishSession: null,
		});
		setShowModal(false);
	};

	if (!hasRight("bookSession")) return null;

	return productionId ? (
		<>
			<Label color={bookingActive ? "pink" : "blue"} as="a" size="large" onClick={() => setShowModal(true)}>
				<Icon name="calendar plus" />
				{t("book")}
				{bookingActive && (
					<Icon
						name="delete"
						onClick={(e) => {
							e.stopPropagation();
							reset();
						}}
					/>
				)}
			</Label>
			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<Modal.Header>{t("bookRecSession")}</Modal.Header>
				<Modal.Content>
					<LoadingContext data={production} loading={loadingProduction} error={errorProduction}>
						<Form form={form}>
							<SessionContext.Provider value={{ t, production }}>
								<SessionBaseForm />
							</SessionContext.Provider>
						</Form>
					</LoadingContext>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={reset}>{t("resetCalendarBtn")}</Button>
					<Button
						positive
						icon="checkmark"
						labelPosition="right"
						content={t("startBooking")}
						onClick={submit}
					/>
				</Modal.Actions>
			</Modal>
		</>
	) : null;
}
