import { useTranslation } from "react-i18next";

import api from "../../../api";

export default function useDistributionPipelineDropdownMenuOptions({ channel }) {
	const { t } = useTranslation();

	return (pipeline) => {
		return [
			{
				text: t("view", "View"),
				linkTo: `/admin/distributions/channels/${channel.id}/pipelines/${pipeline.id}`,
			},
			{
				text: t("delete", "Delete"),
				action: () => {
					return api.distributionChannels.deletePipeline({ channel, pipeline });
				},
			},
		];
	};
}
