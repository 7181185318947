import moment from "moment";

export default function formatByLocale(formats = {}) {
	return (data) => {
		const locale = data.localeCodes[0];
		const format = formats[locale];

		if (typeof format === "string") {
			return format;
		}

		return format({
			start: moment(data.start.marker),
			end: moment(data.end.marker),
		});
	};
}
