import { useTranslation } from "react-i18next";

export default function useArticleDropdownMenuOptions(data) {
	const { t } = useTranslation();

	return [
		{
			text: t("view", "View"),
			linkTo: `/publisher/${data.publisher.id}/admin/articles/${data.id}`,
		},
		{
			text: t("viewTitle", "View title"),
			disabled: !data.title?.id,
			linkTo: `/publisher/${data.publisher.id}/admin/titles/${data.title?.id}`,
		},
	];
}
