import productionStatuses from "../../productions/constants/productionStatuses";

export default function createSessionProductionData(firebase, { production, sessionData }) {
	const data = {};

	if ([productionStatuses.PLANNING, productionStatuses.ACCEPTED].includes(production.status)) {
		data.status = productionStatuses.PRODUCTION;
	}

	if (sessionData.polish) {
		data.bookedPolish = true;
	}

	if (sessionData.recorder && !production?.bookedRecorders?.includes(sessionData.recorder)) {
		data.bookedRecorders = firebase.firestore.FieldValue.arrayUnion(sessionData.recorder);
	}

	return data;
}
