import { useMemo } from "react";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import { useOrganizationType } from "../../organizations/state/organizationType";
import useTeamRoleTranslations from "../../team/hooks/useTeamRoleTranslations";

import useProductionTeam from "./useProductionTeam";

export default function useProductionWorkGroup(production) {
	const team = useProductionTeam(production);
	const roleTranslations = useTeamRoleTranslations();
	const organizationType = useOrganizationType();

	const { productionType } = production;

	return useMemo(
		() => [
			{
				title: roleTranslations[teamRoles.MANAGER],
				members: team.filter((member) => member.role === teamRoles.MANAGER),
			},
			{
				title: roleTranslations[teamRoles.READER],
				members: team.filter((member) => member.role === teamRoles.READER),
			},
			{
				title: roleTranslations[teamRoles.EDITOR],
				members: team.filter((member) => member.role === teamRoles.EDITOR),
				hidden: organizationType === "publisher" || productionType === "subcontract",
			},
			{
				title: roleTranslations[teamRoles.SUBCONTRACTOR],
				members: team.filter((member) => member.role === teamRoles.SUBCONTRACTOR),
				hidden: productionType !== "subcontract",
			},
			{
				title: roleTranslations[teamRoles.PROOFER],
				members: team.filter((member) => member.role === teamRoles.PROOFER),
				hidden: organizationType === "publisher",
			},
		],
		[organizationType, productionType, roleTranslations, team],
	);
}
