import React from "react";
import { InstantSearch } from "react-instantsearch";
import { Grid } from "semantic-ui-react";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";

import ArchiveFilter from "./components/ArchiveFilter";
import ArchiveSearchBar from "./components/ArchiveSearchBar";
import ArchiveSearchResult from "./components/ArchiveSearchResult";

const indexName = "storytool-archive";

export default function StorytoolArchiveView({ user, isSuperAdmin }) {
	const searchClient = useAlgoliaSearchClient();

	return (
		<LoadingContext loading={!searchClient}>
			<InstantSearch indexName={indexName} searchClient={searchClient}>
				<Grid stackable columns={2}>
					<Grid.Column mobile={16} tablet={16} computer={3}>
						<ArchiveFilter user={user} isSuperAdmin={isSuperAdmin} />
					</Grid.Column>
					<Grid.Column mobile={16} tablet={16} computer={13}>
						<ArchiveSearchBar />
						<ArchiveSearchResult />
					</Grid.Column>
				</Grid>
			</InstantSearch>
		</LoadingContext>
	);
}
