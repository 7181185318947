import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { invoiceTypes } from "astrid-firestore/src/api/invoices/invoiceTypes";

export default function useInvoiceTypeTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[invoiceTypes.INVOICE]: t("invoice", "Invoice"),
			[invoiceTypes.CREDIT]: t("creditInvoice", "Credit invoice"),
		}),
		[t],
	);
}
