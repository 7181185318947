import { parsePhoneNumber } from "awesome-phonenumber";

import { getCountryName } from "astrid-helpers/src/countries";

export function formatVendorMarkdown(vendor) {
	const details = [
		`${vendor.name} (${vendor.organizationNumber})`,
		`${vendor.street}${vendor.secondaryStreet ? `, ${vendor.secondaryStreet}` : ""}`,
		`${vendor.zip} ${vendor.city}${vendor.state ? `, ${vendor.state}` : ""}`,
		vendor.country && getCountryName(vendor.country),
		vendor.email,
		vendor.phone && parsePhoneNumber(vendor.phone).getNumber("international"),
		vendor.billingInstructions && `*${vendor.billingInstructions}*`,
	];

	return details.filter((detail) => detail).join("\\\n");
}
