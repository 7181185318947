import React from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../../ui/components/Select/Select";
import useUserOptions from "../../../../users/hooks/useUserOptions";

export default function SigneeSelect({ firstParty, ...props }) {
	const { t } = useTranslation();

	const [options, loading, error] = useUserOptions({ organization: firstParty, role: `${firstParty?.type}Admin` });

	return <Select search label={t("signee", "Signee")} options={options} loading={loading} error={error} {...props} />;
}
