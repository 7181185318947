import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../../components/Flex/Flex";
import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import FlexTableExpander from "../../../ui/components/FlexTable/components/FlexTableExpander";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import SentOffersFilterForm from "./components/SentOffersFilterForm";
import useFilteredSentOffers from "./hooks/useFilteredSentOffers";
import useSentOffersForm from "./hooks/useSentOffersForm";
import useSentOffersTableColumns from "./hooks/useSentOffersTableColumns";

export default function SentOffersView() {
	const { t } = useTranslation();

	const [showInactiveOffers, setShowInactiveOffers] = useState(false);

	const form = useSentOffersForm();
	const columns = useSentOffersTableColumns();

	const { isFiltering, sortedOffers, filteredOffers, activeOffers, inactiveOffers, loading, error } =
		useFilteredSentOffers({
			form,
		});

	return (
		<LoadingContext data={sortedOffers} loading={loading} error={error}>
			<Flex alignItems="start" style={{ gap: 13 }}>
				<SentOffersFilterForm offers={sortedOffers} form={form} />

				<div style={{ flex: 1 }}>
					<LoadingContext
						noDataAvailableMessage={t("offerFilterNoMatch", "No offers match the active filters")}
						data={filteredOffers}
					>
						<FlexTable
							style={{ flex: 1, margin: 0 }}
							selectable
							data={showInactiveOffers || isFiltering ? filteredOffers : activeOffers}
							columns={columns}
						>
							{inactiveOffers?.length > 0 && !isFiltering && (
								<FlexTableExpander
									onClick={() => setShowInactiveOffers((showInactiveOffers) => !showInactiveOffers)}
									expanded={showInactiveOffers}
									collapsedText={t("showInactiveOffers", "Show inactive offers ({{amount}})", {
										amount: inactiveOffers.length,
									})}
									expandedText={t("hideInactiveOffers", "Hide inactive offers")}
								/>
							)}
						</FlexTable>
					</LoadingContext>
				</div>
			</Flex>
		</LoadingContext>
	);
}
