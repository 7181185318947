import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";
import { Button, Grid, Icon } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import updateNotificationData from "astrid-firestore/src/api/notifications/updateNotificationData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import OfferAccordion from "../../../offers/components/OfferAccordion/OfferAccordion";
import OfferView from "../../../offers/views/OfferView/OfferView";

export default function MyProductionOffersView({ selectedOfferId, offers, loading }) {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const navigate = useNavigate();

	const selectedOffer = offers.find((offer) => offer.id === selectedOfferId);

	const onClickOffer = (offer) => {
		navigate(`../offers/${offer.id}`);
		updateNotificationData(firebase, { document: offer, user: offer.secondParty });
	};

	return (
		<LoadingContext loading={loading} data={offers} noDataAvalibleMessage={t("noProductionOffers")}>
			<Grid stackable columns={2} style={{ maxWidth: "1600px" }}>
				<Grid.Column width={5}>
					{(width >= 800 || !selectedOffer) && (
						<OfferAccordion selectedOffer={selectedOffer} offers={offers} onClickOffer={onClickOffer} />
					)}
				</Grid.Column>
				{selectedOffer && (
					<Grid.Column width={11}>
						{width <= 800 && (
							<Button icon onClick={() => navigate("../offers")}>
								<Icon name="chevron left" />
							</Button>
						)}
						<OfferView offer={selectedOffer} />
					</Grid.Column>
				)}
			</Grid>
		</LoadingContext>
	);
}
