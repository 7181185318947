import React from "react";
import { useTranslation } from "react-i18next";

import FormSelect from "../../../forms/components/FormSelect/FormSelect";

import useTeamAgreementOptions from "../../hooks/useTeamAgreementOptions";

export default function TeamAgreementSelect({ role, user, production, firstPartyId, firstPartyVendorId, ...props }) {
	const { t } = useTranslation();

	const [options, loading, error] = useTeamAgreementOptions({
		role,
		user,
		firstPartyId,
		firstPartyVendorId,
		language: production?.language,
	});

	return (
		<FormSelect
			label={t("agreement", "Agreement")}
			options={options}
			loading={loading}
			error={error?.message || error}
			search
			deburr
			{...props}
		/>
	);
}
