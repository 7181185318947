import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import roles from "astrid-permissions/roles";

export default function useOrganizationRoles(type) {
	const { t } = useTranslation();

	return useMemo(
		() =>
			Object.keys(roles)
				.filter((role) => !type || role.includes(type))
				.reduce((map, role) => ({ ...map, [role]: t(role) }), {}),
		[t, type],
	);
}
