import runBatch from "../../helpers/runBatch";

import createOfferStatusChangedTodoData from "../todos/data/offers/createOfferStatusChangedTodoData";

import offerStatuses from "./constants/offerStatuses";
import offerTypes from "./constants/offerTypes";

export default function declineOffer(firebase, { offer, user }) {
	return runBatch(firebase.firestore(), (batch) => {
		if (offerTypes.OPEN === offer.type) {
			batch.update(offer.ref, { declinedUserIds: firebase.firestore.FieldValue.arrayUnion(user.id) });
		} else {
			const status = offerStatuses.DECLINED;
			const todo = createOfferStatusChangedTodoData(firebase, { offer, status });

			batch.update(offer.ref, { status });
			batch.set(todo.ref, todo);
		}
	});
}
