import { Header, Icon } from "semantic-ui-react";

import money from "astrid-helpers/src/money";

import useTotalCost from "../../hooks/useTotalCost";

export default function CostsBalance({ costs }) {
	const [totalCost, totalCurrency] = useTotalCost(costs);

	if (!totalCost) {
		return null;
	}

	return (
		<Header as="h4" floated="right">
			<Icon name="balance" />
			{money(totalCost, totalCurrency)}
		</Header>
	);
}
