const liveDomains = [
	// "localhost", // ENABLES LIVE DATA ON LOCALHOST
	"astrid.fm",
	"beta.astrid.fm",
	"earselect.firebaseapp.com",
	"next-earselect.firebaseapp.com",
	"studio.astrid.fm",
	"beta.studio.astrid.fm",
];

export const isBrowser = typeof window !== "undefined";
export const isElectron = isBrowser && window.api;

export const isLocal = isBrowser && window.location.hostname === "localhost";
export const isProduction = process.env.NODE_ENV === "production";
export const isDevelopment = !isProduction;
export const isLive =
	(isElectron && window.api.isLive) || (isBrowser && liveDomains.includes(window.location.hostname));
export const isStage = !isLive;

if (isBrowser) {
	window.ES = window.ES || {};
	window.ES.stage = isStage;

	if (window.document.title && isStage) {
		window.document.title = "STAGE Astrid";
	}
}
