import { useMemo } from "react";

import db from "astrid-firebase/src/db";

function getTypeQuery(query, type) {
	switch (type) {
		case "narrator":
			return query.where("permissions.reader", "array-contains", "any");
		case "producer":
		case "publisher":
			return query.where(`permissions.${type}Index`, "==", true);
		case "unassigned":
			return query
				.where("permissions.reader", "==", false)
				.where("permissions.producerIndex", "==", false)
				.where("permissions.publisherIndex", "==", false);
		default:
			return query;
	}
}

export default function useUsersQuery({ role = "any", type, producer, publisher, organization } = {}) {
	return useMemo(() => {
		let query = db.collection("users");

		query = getTypeQuery(query, type);

		if (role === "reader") {
			query = query.where("permissions.reader", "array-contains-any", ["allProducers", producer]);
		} else if (role) {
			if (producer) {
				query = query.where(`permissions.producer.${producer}`, "array-contains", role);
			}

			if (publisher) {
				query = query.where(`permissions.publisher.${publisher}`, "array-contains", role);
			}

			if (organization) {
				query = query.where(`permissions.${organization.type}.${organization.id}`, "array-contains", role);
			}
		}

		return query;
	}, [organization, producer, publisher, role, type]);
}
