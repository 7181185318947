import { useInfiniteHits } from "react-instantsearch";

import Accordion from "../../../ui/components/Accordion/Accordion";

import AddReader from "./components/AddReader";
import Reader from "./components/Reader";
import ShowMore from "./components/ShowMore";

export default function ReaderSearchResults({ production, allReaderIds, adding, selectedId, onClick }) {
	const { hits, results, isLastPage, showMore } = useInfiniteHits();

	const hitsLeft = results.nbHits - hits.length;

	return (
		<Accordion>
			{hits
				.map(({ objectID, ...reader }) => ({ id: objectID, ...reader }))
				.map((reader) => (
					<Reader
						key={reader.id}
						reader={reader}
						added={allReaderIds.includes(reader.id)}
						adding={adding && reader.id === selectedId}
						selected={reader.id === selectedId}
						onClick={() => !allReaderIds.includes(reader.id) && onClick(reader)}
					/>
				))}

			{hitsLeft > 0 && <ShowMore hitsLeft={hitsLeft} showMore={showMore} isLastPage={isLastPage} />}
			{hitsLeft === 0 && <AddReader selectedId={selectedId} onClick={onClick} production={production} />}
		</Accordion>
	);
}
