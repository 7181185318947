import React from "react";
import { Header } from "semantic-ui-react";

const contentStyle = {
	margin: 0,
	fontSize: "0.8em",
	lineHeight: "2em",
	fontStyle: "italic",
	fontWeight: "normal",
	opacity: 0.5,
	backgroundColor: "#f8f8f8",
};

const presentationLimit = 50;

export default function ReaderPresentation({ reader, selected }) {
	return (
		<Header style={{ ...contentStyle, backgroundColor: selected && "#f8f8f8" }}>
			{reader.presentation.length > presentationLimit && !selected
				? reader.presentation.substr(0, presentationLimit) + " […]"
				: reader.presentation}
		</Header>
	);
}
