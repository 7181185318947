import debounce from "lodash/debounce";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Form, Icon, Modal, Popup } from "semantic-ui-react";

import { base, firebase, hasRight } from "astrid-firebase";

import DateTime from "../../features/ui/components/DateInput/DateTime";
import { toDate } from "../../helpers/fnc";
import { colors, defaultHours } from "../../helpers/lists";

import Right from "../Right";

class AvailabilityModal extends Component {
	state = {
		uid: this.props.isEditing || this.props.store.state.user.uid,
		availability: {},
		sessions: [],
		spanDate: moment().add(1, "days").toDate(),
		spanIn: moment().add(1, "days").hour(8).minute(0).toDate(),
		spanOut: moment().add(1, "days").hour(22).minute(0).toDate(),
	};

	componentDidMount() {
		this.props.store.getUsers();

		// in calendar producer is passed as prop, should probably do the same on home page? fow now, get from context
		if (!this.props.producer) this.props.store.getOrganizations();

		// get users sessions
		if (this.state.uid) this.getSessions(this.state.uid);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// get new sessions?
		if (this.state.uid !== nextState.uid) this.getSessions(nextState.uid);
	}

	getSessions = (uid) => {
		// ditch old binding
		if (this.sessionBind) {
			base.removeBinding(this.sessionBind);
		}

		const currentUser = uid && get(this.props.store, "state.users[" + uid + "]");
		const isReader = currentUser && currentUser.permissions && currentUser.permissions.reader;

		if (uid && isReader)
			this.sessionBind = base.bindCollection("sessions", {
				context: this,
				state: "sessions",
				withIds: true,
				query: (ref) => ref.where("reader", "array-contains", uid).where("end", ">", new Date()),
				then() {},
				onFailure(err) {
					console.log("Session get err", err);
				},
			});
	};

	updateStandardTime = (day, out, time) => {
		const statePath = "standardTime.day" + day + "." + (out ? "out" : "in");
		const firestorePath = "availability." + statePath;
		const availability = this.state.availability;

		// set in state immediately
		set(availability, statePath, time);
		this.setState({ availability });

		// debounce firestore
		this.debouncedTimeChange(firestorePath, time);
	};

	debouncedTimeChange = debounce((path, time) => {
		base.updateDoc("/users/" + this.state.uid, {
			[path]: time,
		});
	}, 400);

	timeSpans = (currentUser, spanType, producer, sessions) => {
		const { t } = this.props;

		return (
			<>
				<Form
					className="availability-timespan-form"
					style={
						spanType === "bookableTime" && !get(currentUser, "availability.homeStudio")
							? { opacity: 0.5, pointerEvents: "none" }
							: {}
					}
				>
					<div className="flex-stack availability-booked-header">
						<b>{t("dateInCal")}</b>
						<b>{t("timeInCal")}</b>
						{spanType === "bookableTime" && <b>{t("studio")}</b>}
						<div>&nbsp;</div>
					</div>
					{currentUser.availability &&
						currentUser.availability[spanType] &&
						currentUser.availability[spanType]
							.map((date, i) => ({ ...date, index: i }))
							// hide passed dates (MEMO: perhaps delete passed dates after a while? when adding new span?)
							.filter((date) => toDate(date.out) > new Date())
							.sort((a, b) => (a.in > b.in ? 1 : -1))
							.map((date) => {
								// look for booked sessions
								const relevantSessions = sessions
									? sessions.filter(
											(session) =>
												session.status !== "cancelled" &&
												toDate(session.start) < toDate(date.out) &&
												toDate(session.end) > toDate(date.in),
									  )
									: [];

								const duration = date.out.seconds - date.in.seconds;
								const booked = relevantSessions
									? relevantSessions.reduce(
											(prev, curr) => prev + (curr.end.seconds - curr.start.seconds),
											0,
									  )
									: 0;

								return { ...date, sessions: relevantSessions, duration, booked };
							})
							.map((date) => (
								<div className="flex-stack" key={date.index} style={{ marginBottom: 10 }}>
									<div>{moment(toDate(date.in)).format("YYYY-MM-DD")}</div>
									<div>
										{spanType === "bookableTime" && (
											<Popup
												inverted
												size="tiny"
												content={
													!date.booked
														? t("timeNotBooked")
														: date.sessions.map((session) => (
																<div key={session.id}>
																	{moment(toDate(session.start)).format("HH:mm") +
																		" - " +
																		moment(toDate(session.end)).format("HH:mm")}
																</div>
														  ))
												}
												trigger={
													<Icon
														name={
															!date.booked
																? "hourglass start"
																: date.booked / date.duration < 0.75
																? "hourglass half"
																: "hourglass end"
														}
														color={
															!date.booked
																? "green"
																: date.booked / date.duration < 0.75
																? "orange"
																: "red"
														}
													/>
												}
											/>
										)}
										{moment(toDate(date.in)).format("HH:mm")} –{" "}
										{moment(toDate(date.out)).format("HH:mm")}
									</div>
									{spanType === "bookableTime" && (
										<TranslatedStudioDropdown
											producer={producer}
											value={date.studio || ""}
											disabled={!!date.booked}
											onChange={(e, data) => {
												this.setState({ savingSpan: true });
												e.preventDefault();
												const newData = currentUser.availability[spanType];
												newData[date.index].studio = data.value;

												base.updateDoc("/users/" + this.state.uid, {
													["availability." + spanType]: newData,
												}).then(() => {
													this.setState({ savingSpan: false });
												});
											}}
										/>
									)}

									<div>
										<Button
											icon="minus"
											size="tiny"
											color="red"
											disabled={!!date.booked}
											content={t("delete")}
											style={{ marginTop: -5 }}
											onClick={(e) => {
												this.setState({ savingSpan: true });
												e.preventDefault();
												const newData = currentUser.availability[spanType];
												newData.splice(date.index, 1);

												base.updateDoc("/users/" + this.state.uid, {
													["availability." + spanType]: newData,
												}).then(() => {
													this.setState({ savingSpan: false });
												});
											}}
										/>
										{!!date.booked && (
											<small style={{ display: "block" }}>
												<em>{t("recordingBooked")}</em>
											</small>
										)}
									</div>
								</div>
							))}

					<div className="new-span flex-stack" style={{ alignItems: "center" }}>
						<div className="field">
							<label>{t("dateInCal")}</label>
							<DateTime
								value={moment(this.state.spanIn).format("YYYY-MM-DD")}
								timeFormat={false}
								onChange={(dt) => {
									if (typeof dt === "object") {
										// valid date, save it

										const spanIn = moment(
											dt.format("YYYY-MM-DD") + " " + moment(this.state.spanIn).format("HH:mm"),
										).toDate();
										const spanOut = moment(
											dt.format("YYYY-MM-DD") + " " + moment(this.state.spanOut).format("HH:mm"),
										).toDate();

										this.setState({
											spanIn,
											spanOut,
										});
									}
								}}
								onBlur={(dt) => {
									const writtenDate = moment(dt).format("YYYY-MM-DD");

									// see if typed date is valid
									if (writtenDate !== "Invalid date") {
										const newDate = moment(
											dt.format("YYYY-MM-DD") + " " + moment(this.state.spanIn).format("HH:mm"),
										).toDate();

										this.setState({
											spanIn: newDate,
										});
									}
								}}
							/>
						</div>
						<div className={"span-time"}>
							{[
								{ label: t("from"), key: "spanIn" },
								{ label: t("until"), key: "spanOut" },
							].map((picker, pi) => (
								<Form.Input
									type="time"
									key={picker.key}
									name={picker.key}
									value={moment(this.state[picker.key]).format("LT")}
									required
									label={picker.label}
									error={moment(this.state.spanIn).toDate() >= moment(this.state.spanOut).toDate()}
									onChange={(e, data) => {
										const newDate = moment(
											moment(this.state.spanIn).format("YYYY-MM-DD") + " " + data.value,
										).toDate();

										this.setState({
											[data.name]: newDate,
										});
									}}
								/>
							))}
						</div>
						<div>
							<Button
								primary
								content={t("addTime")}
								size="tiny"
								icon="plus"
								disabled={this.state.spanInvalid}
								loading={this.state.savingSpan}
								style={{ marginTop: 3 }}
								onClick={(e) => {
									if (moment(this.state.spanIn).toDate() >= moment(this.state.spanOut).toDate()) {
										window.alert(t("timeStartFalse"));
										return;
									} else if (moment(this.state.spanIn).toDate() < new Date()) {
										window.alert(t("timeAlreadyPassed"));
										return;
									}
									this.setState({ savingSpan: true });
									const availability = { ...(currentUser.availability || {}) };

									// create array if needed
									if (!availability[spanType]) availability[spanType] = [];

									const data = {
										in: this.state.spanIn,
										out: this.state.spanOut,
									};

									// set studio for readers time
									if (spanType === "bookableTime")
										data.studio = get(currentUser, "availability.homeStudio") || null;

									// add span
									availability[spanType].push(data);

									// update db
									base.updateDoc("/users/" + this.state.uid, {
										"availability.updated": firebase.firestore.FieldValue.serverTimestamp(),
										["availability." + spanType]: availability[spanType],
									}).then(() => {
										this.setState({ savingSpan: false });
									});
								}}
							/>
						</div>
					</div>
				</Form>
			</>
		);
	};

	render() {
		const { uid, availability, sessions } = this.state;
		const { t, store, modalClose, editAvailability } = this.props;
		// gets store from actual prop because the modal is rendered outside context provider

		const producer = this.props.producer || get(store, "state.organizations." + get(store, "state.producerId"));

		// get info about selected user (or self)
		const currentUser = uid && get(store, "state.users[" + uid + "]");
		const isRecorder =
			get(currentUser, "permissions.producer") &&
			Object.values(currentUser.permissions.producer)[0].includes("producerRecorder");

		const isReader = currentUser && currentUser.permissions && currentUser.permissions.reader;

		// combine data from firestore and state
		const comboAvailability = merge({}, get(currentUser, "availability"), availability);

		const you = currentUser && (currentUser.id === store.state.user.uid ? t("you") : currentUser.firstName);

		return (
			<>
				<Modal.Header>
					{t("availabilityAndVacancy")}
					<Right backupstore={store} can={["availability.editOthers", "availability.editReaders"]}>
						<div
							style={{
								fontSize: 14,
								display: "inline-block",
								marginLeft: 20,
							}}
						>
							<Form.Select
								placeholder={t("chooseCoworker")}
								search
								value={isReader || isRecorder ? uid : ""}
								options={
									store.state.users
										? Object.values(store.state.users)
												.filter(
													(user) =>
														user.permissions &&
														(user.permissions.reader ||
															(hasRight(store, "availability.editOthers")
																? user.permissions.producer &&
																  Object.values(user.permissions.producer)[0]?.includes(
																		"producerRecorder",
																  )
																: store.state.user.uid === user.id)), // recorders only see themselves and readers
												)
												.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
												.map((user) => ({
													key: user.id,
													text: user.firstName + " " + user.lastName,
													value: user.id,
												}))
										: []
								}
								onChange={(e, data) => {
									this.setState({ uid: data.value, availability: {} });
								}}
							/>
						</div>
					</Right>
				</Modal.Header>
				<Modal.Content className="availability-modal">
					{!isReader && !isRecorder && <em>{t("nobodyChoosen")}</em>}
					{isRecorder && (
						<Right backupstore={store} can={["availability.standardTime", "availability.editOthers"]}>
							<h5>
								{t("ordinaryWorkWeek")} {isRecorder && isReader && t("recTechnicianAvailability")}
							</h5>
							<p>
								<em>{t("enterBookingAvailability", { you })}</em>
							</p>
							<Form size="small">
								{Object.values(defaultHours).map((hours, dayIndex) => (
									<Form.Group key={dayIndex} unstackable widths={2}>
										{Object.values(hours).map((time, hourIndex) => {
											// iso day number:
											const isoDay = dayIndex + 1;

											const setTime = get(
												comboAvailability,
												"standardTime[day" + isoDay + "][" + (hourIndex ? "out" : "in") + "]",
											);

											return (
												<Form.Input
													key={hourIndex}
													fluid
													type="time"
													label={
														!hourIndex
															? moment().isoWeekday(isoDay).format("dddd") +
															  " " +
															  t("fromTime")
															: t("toTime")
													}
													value={typeof setTime !== "undefined" ? setTime : time}
													onChange={(e, data) => {
														this.updateStandardTime(isoDay, hourIndex, data.value);
													}}
												/>
											);
										})}
									</Form.Group>
								))}
							</Form>
							<h5>
								{t("vacancy")} {isRecorder && isReader && t("recTechnicianAvailability")}
							</h5>
							<p>
								<em>{t("specifyAndPaintCal")}</em>
							</p>
							{this.timeSpans(currentUser, "timeOff")}
						</Right>
					)}

					{isReader && (
						<Right backupstore={store} can={["availability.bookableTime", "availability.editReaders"]}>
							<h5>
								{t("studioRequest")} {isRecorder && isReader && "(uppläsare)"}
							</h5>
							<p>
								<em>{t("chooseHomeStudio")}</em>
							</p>
							<TranslatedStudioDropdown
								producer={producer}
								value={get(currentUser, "availability.homeStudio") || ""}
								onChange={(e, data) => {
									base.updateDoc("/users/" + this.state.uid, {
										"availability.homeStudio": data.value,
									});
								}}
							/>
							<h5>
								{t("availableToBook")} {isRecorder && isReader && t("availableReader")}
							</h5>
							<p>
								<em>{t("enterBookingAvailability", { you })}</em>
							</p>
							{this.timeSpans(currentUser, "bookableTime", producer, sessions)}
						</Right>
					)}
				</Modal.Content>

				<Modal.Actions>
					{isRecorder && editAvailability && (
						<Right backupstore={store} can={["availability.standardTime", "availability.editOthers"]}>
							<Button
								primary
								onClick={() => {
									editAvailability(uid, "timeOff");
									modalClose();
								}}
							>
								{t("paintVacancy")}
							</Button>
						</Right>
					)}

					{isReader && editAvailability && (
						<Right backupstore={store} can={["availability.bookableTime", "availability.editReaders"]}>
							<Button
								primary
								onClick={() => {
									editAvailability(uid, "bookableTime");
									modalClose();
								}}
							>
								{t("paintAvailability")}
							</Button>
						</Right>
					)}

					<Button
						positive
						icon="checkmark"
						labelPosition="right"
						content={t("ready")}
						onClick={() => {
							if (editAvailability) editAvailability();
							modalClose();
						}}
					/>
				</Modal.Actions>
			</>
		);
	}
}

class StudioDropdown extends Component {
	getStudioGroupTags = (producer) => {
		// gather all groups
		let tags = Object.values((producer && producer.studios) || []).reduce(
			(prev, curr) => [...prev, ...(curr.groupTags || [])],
			[],
		);

		// make'm unique
		tags = [...new Set(tags)];

		return tags;
	};

	render() {
		const { t, producer, value, onChange, disabled } = this.props;

		return (
			<Dropdown
				placeholder={t("chooseStudio") + "..."}
				selection
				multiple
				disabled={disabled}
				onChange={onChange}
				value={value}
				renderLabel={(label) => ({ content: label.content })}
				options={[
					...this.getStudioGroupTags(producer).map((group) => ({
						key: group,
						value: group,
						text: group,
						content: (
							<span>
								<Icon name="cubes" /> {group}
							</span>
						),
					})),
					...(producer && producer.studios
						? Object.keys(producer.studios)
								.filter((key) => producer.studios[key].active)
								.sort((a, b) =>
									producer.studios[a].name.toLowerCase() > producer.studios[b].name.toLowerCase()
										? 1
										: -1,
								)
								.map((key, i) => {
									const studio = producer.studios[key];
									const color = studio.color || colors[Object.keys(producer.studios).indexOf(key)];

									return {
										key: i,
										value: key,
										text: studio.name,
										content: (
											<span>
												<Icon name="cube" style={{ color }} /> {studio.name}
											</span>
										),
									};
								})
						: []),
				]}
			/>
		);
	}
}

const TranslatedStudioDropdown = withTranslation()(StudioDropdown);

export default withTranslation()(AvailabilityModal);
