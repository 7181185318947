import { db } from "astrid-firebase";

import { useProfile } from "../../../../authentication/state/profile";

export default function useMyProductionsQuery({ organization }) {
	const profile = useProfile();

	if (profile) {
		const query = db.collection("productions").where("deleted", "==", false).where("status", "!=", "done");

		switch (organization.type) {
			case "publisher":
				return query
					.where(organization.type, "==", organization.id)
					.where("publisherManagerIds", "array-contains", profile.id);
			case "producer":
				return query
					.where(organization.type, "==", organization.id)
					.where("teamUserIds", "array-contains", profile.id);
			case "narrator":
				return query.where("teamUserIds", "array-contains", profile.id);
			default:
				return null;
		}
	}

	return null;
}
