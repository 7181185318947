import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";

export default function ArticlesInstantSearchSelect({ publisher, ...props }) {
	return (
		<InstantSearchSelect
			indexName="articles"
			configure={{
				filters: `publisher.id:${publisher?.id}`,
			}}
			getOption={(data) => ({
				key: data.id,
				value: data.id,
				text: data.name,
			})}
			{...props}
		/>
	);
}
