import { useTranslation } from "react-i18next";

export default function useCalendarStatusFilterOptions() {
	const { t } = useTranslation();

	return [
		{
			name: "statusFilters.booked",
			label: t("booked", "Booked"),
		},
		{
			name: "statusFilters.completed",
			label: t("completed", "Completed"),
		},
		{
			name: "statusFilters.cancelled",
			label: t("cancelled", "Cancelled"),
		},
	];
}
