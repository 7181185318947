import uniqid from "uniqid";

import { getOrganizationRef, getPartyRef } from "../../utils/referenceMappers";

import createApprovalData from "../approval/createApprovalData";

import agreementTypes from "./constants/agreementTypes";

export default function createAgreementTemplate(firebase, data) {
	const {
		name = "",
		life = 365,
		type = agreementTypes.FRAMEWORK,
		part = "user",
		prices = [],
		deleted = false,
		producer = null,
		approved = null,
		appendixes = null,
		firstParty = null,
		daysToSign = 14,
		canManageAgreements = false,
	} = data;

	const db = firebase.firestore();
	const ref = db.collection("agreementTemplates").doc();

	return {
		id: ref.id,
		ref,
		life: +life,
		type,
		part,
		name,
		appendixes:
			appendixes?.map((appendix) => ({
				name: appendix.name || "",
				text: appendix.text || "",
				file: appendix.file || null,
			})) || [],
		prices: prices
			.filter((price) => !!price.name)
			.reduce(
				(
					map,
					{
						vat = 0,
						uses = 0,
						type = null,
						name = "",
						unit = "time",
						price = 0,
						maxUses = false,
						currency = null,
						approved = false,
					},
				) => ({
					...map,
					[uniqid()]: {
						vat: +vat,
						uses,
						type,
						name,
						unit,
						price: +price,
						maxUses: maxUses === false ? false : +maxUses,
						currency,
						approved,
					},
				}),
				{},
			),
		deleted,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		approval: createApprovalData(approved || null),
		daysToSign: +daysToSign,
		canManageAgreements,
		producer: getOrganizationRef(producer),
		firstParty: getPartyRef(firstParty),
	};
}
