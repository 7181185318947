import { useTranslation } from "react-i18next";

export default function useTitleVersionsDropdownMenuOptions() {
	const { t } = useTranslation();

	return [
		{
			text: t("revertToThisVersion", "Revert to this version"),
		},
	];
}
