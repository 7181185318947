import { getDocumentData } from "../../helpers";
import runBatch from "../../helpers/runBatch";

export default async function copyWordsToOtherProduction(firebase, { fromProductionId, copyToProductionId, words }) {
	const db = firebase.firestore();
	const toProduction = await getDocumentData(db.collection("productions").doc(copyToProductionId));

	await runBatch(db, (batch) => {
		for (const word of words) {
			batch.update(db.collection("words").doc(word.id), {
				[`choice.${copyToProductionId}`]: word?.choice?.[copyToProductionId]
					? word.choice[copyToProductionId]
					: word?.choice?.[fromProductionId] || null,
				[`productions.${copyToProductionId}`]: {
					...word.productions[fromProductionId],
					author: toProduction.author || null,
					productionLanguage: toProduction.language || null,
				},
			});
		}
	});
}
