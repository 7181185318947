
import React, { Component } from "react";
//id's in reader.json
import { withTranslation } from "react-i18next";
import { Card, Loader } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import CopyInput from "astrid-web/src/components/CopyInput";
import StickyAudio from "astrid-web/src/components/StickyAudio";

import ReaderCard from "../components/reader/ReaderCard";
import { withStore } from "../helpers/context";

class ReaderPreview extends Component {
	state = {
		loading: true,
		readers: [],
	};
	componentDidMount() {
		const ids = this.props.match.params.ids.split(",");
		const readerPreview = firebase.functions().httpsCallable("readerPreview");

		readerPreview({ ids }).then((result) => {
			this.setState({ loading: false, readers: result.data });
		});
	}

	render() {
		const { t } = this.props;
		const { loading, readers } = this.state;

		return (
			<>
				{this.props.profile && (
					<div
						style={{
							margin: "0 auto 2em",
							textAlign: "center",
							paddingBottom: "2em",
							borderBottom: "2px solid #eee",
							maxWidth: 600,
						}}
					>
						<p>{t("shareChoosenReaders")}</p>
						<CopyInput content={window.location.href} />
					</div>
				)}

				{loading ? (
					<div style={{ textAlign: "center" }}>
						<Loader active inline /> {t("loadsReader")}
					</div>
				) : (
					<Card.Group
						className="reader-list"
						style={{ clear: "both", marginTop: "2em", justifyContent: "center" }}
					>
						{readers.map((reader) => (
							<ReaderCard preview key={reader.id} parent={this} reader={reader} />
						))}
					</Card.Group>
				)}

				{this.state.playingFile && (
					<StickyAudio
						file={this.state.playingFile}
						onClose={() => {
							this.setState({ playingFile: null });
						}}
					/>
				)}
			</>
		);
	}
}

export default withTranslation()(withStore(ReaderPreview));
