import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import InstantSearchSelect from "../../../../ui/components/InstantSearch/InstantSearchSelect";

export default function CalendarFiltersRecorderSelect({ organizationId }) {
	const { t } = useTranslation();

	return (
		<Form.Field name="recorders" shouldUnregister={false}>
			<InstantSearchSelect
				multiple
				clearable
				indexName="users"
				label={t("technician", "Engineer")}
				getOption={({ objectID, name }) => {
					return {
						key: objectID,
						value: objectID,
						text: name,
					};
				}}
				additionalOptions={[
					{
						key: "noTechnicianBooked",
						value: "noTechnicianBooked",
						text: t("noTechnicianBooked", "No engineer booked"),
					},
				]}
				configure={{
					hitsPerPage: 1000,
				}}
				filters={`permissions.producer.${organizationId}:producerRecorder`}
			/>
		</Form.Field>
	);
}
