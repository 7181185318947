import React from "react";
import { useTranslation } from "react-i18next";

import PipelineForm from "../../../pipelines/components/PipelineForm/PipelineForm";

export default function SuperAdminDistributionPipelineSettingsView({ channel, pipeline }) {
	const { t } = useTranslation();

	return (
		<>
			<PipelineForm channel={channel} pipeline={pipeline} header={t("settings", "Settings")} />
		</>
	);
}
