import { xml } from "@codemirror/lang-xml";
import { useCodeMirror } from "@uiw/react-codemirror";
import React, { useState } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Accordion, Button, Icon } from "semantic-ui-react";
import formatter from "xml-formatter";

import api from "../../../../../api";

import useOnixTemplate from "../hooks/useOnixTemplate";

function format(xml) {
	return formatter(xml, { collapseContent: true });
}

export default function MetadataEditor({ channel }) {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const [onixTemplate, setOnixTemplate] = useOnixTemplate(channel);

	const { setContainer } = useCodeMirror({
		container: document.getElementById("editor"),
		value: onixTemplate,
		extensions: [xml()],
		onChange: (value) => {
			console.log("hej");
			setOnixTemplate(value);
		},
	});

	const [{ loading }, saveOnixTemplate] = useAsyncFn(() => {
		return api.distributionChannels.update({
			ref: channel.ref,
			onixTemplate: format(onixTemplate),
		});
	}, [onixTemplate]);

	return (
		<Accordion fluid styled>
			<Accordion.Title active={isCollapsed} onClick={() => setIsCollapsed(!isCollapsed)}>
				<Icon name="dropdown" />
				Edit Metadata Template
			</Accordion.Title>
			<Accordion.Content active={isCollapsed}>
				<div id="editor" ref={setContainer} style={{ maxHeight: "800px", overflow: "auto" }} />
				<Button secondary onClick={() => setOnixTemplate(format(onixTemplate))} content="Format template" />
				<Button primary loading={loading} onClick={saveOnixTemplate} content="Save template" />
			</Accordion.Content>
		</Accordion>
	);
}
