import React from "react";
import { Link } from "react-router-dom";
import { Icon, Label, Menu } from "semantic-ui-react";

export default function MenuItems({ options }) {
	return options
		.filter(({ hidden, disabled }) => !hidden && !disabled)
		.map(({ to, text, icon, label, content, children, labelColor, loading, ...option }) => (
			<Menu.Item as={Link} key={to} to={to} {...option}>
				{icon && <Icon name={icon} />}

				{text || content || children}

				{loading && <Icon loading name="spinner" style={{ marginLeft: "1em" }} />}

				{label !== undefined && (
					<Label size="small" color={labelColor || "blue"} style={{ margin: "-1em 0 -1em 1em" }}>
						{label}
					</Label>
				)}
			</Menu.Item>
		));
}
