import { Menu as SemanticMenu } from "semantic-ui-react";

import MenuItems from "./components/MenuItems";

export default function Menu({ options, children, ...props }) {
	return (
		<SemanticMenu {...props} icon={!!options?.[0]?.icon}>
			{children || <MenuItems options={options} />}
		</SemanticMenu>
	);
}
