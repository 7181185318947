import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";
import { mapAgreementPrices } from "astrid-firestore/src/api/agreements/utils/agreementMappers";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import Flex from "../../../components/Flex/Flex";

import { formatPrice } from "../../prices/utils/priceFormatters";

import useAgreementsQuery from "./useAgreementsQuery";

function mapOption(t) {
	return (price) => {
		const text = `${price.name} (${formatPrice(price)})`;

		return {
			key: price.agreement.id + price.id,
			text: text + (price.agreement ? ` [${price.agreement.name}]` : ""),
			value: price.agreement.id + price.id,
			data: price,
			content: (
				<Flex style={{ gap: 12 }}>
					<div style={{ marginRight: "auto" }}>{text}</div>

					<Label basic>
						{price.agreement.name}

						{price.agreement.canManageAgreements && (
							<Label.Detail style={{ marginLeft: 5 }}>
								{t("managePublisherAgreements", "Manage publisher agreements")}
							</Label.Detail>
						)}
					</Label>
				</Flex>
			),
		};
	};
}

export default function useAgreementsPriceOptions({ priceTypes, ...options }) {
	const { t } = useTranslation();

	const [agreements] = useCollectionDataOnce(
		useAgreementsQuery({
			...options,
			statuses: [agreementStatuses.ACTIVE],
		}),
	);

	return useMemo(
		() =>
			agreements
				?.map((agreement) => mapAgreementPrices(agreement).map(mapOption(t)))
				.flat()
				.filter(({ data }) => !priceTypes || priceTypes.includes(data.type)) || [],
		[agreements, priceTypes, t],
	);
}
