import sessionOfferTypes from "../../constants/sessionOfferTypes";
import runTransaction from "../../helpers/runTransaction";
import { getSessionRef } from "../../utils/referenceMappers";

import createProductionPriceData from "../productions/prices/createProductionPriceData";

export default function editSession(firebase, { session, production, sessionUpdate, priceUpdate }) {
	const db = firebase.firestore();
	const sessionRef = db.collection("sessions").doc(session.id);

	return runTransaction(db, [sessionRef], (transaction) => {
		if (priceUpdate) {
			if (session.productionPriceRef) {
				transaction.delete(session.productionPriceRef);
			}
			if (
				![sessionOfferTypes.ANY_RECORDER_IN_TEAM, sessionOfferTypes.OPEN_POSITION_RECORDER].includes(
					sessionUpdate.recorder,
				)
			) {
				const price = priceUpdate?.priceRef;
				const agreement = priceUpdate?.agreementRef;

				const productionPrice =
					price &&
					createProductionPriceData(firebase, {
						...price,
						expense: true,
						approved: true,
						producer: production.producer,
						production,
						agreement,
						firstParty: agreement?.firstParty,
						secondParty: agreement?.secondParty,
						referencePrice: price,
						session: getSessionRef({ ...session, ref: db.collection("sessions").doc(session.id) }),
					});
				if (productionPrice) {
					transaction.set(productionPrice.ref, productionPrice);
				}
				transaction.update(sessionRef, {
					...sessionUpdate,
					...priceUpdate,
					productionPriceRef: productionPrice?.ref || null,
				});
			} else {
				transaction.update(sessionRef, {
					...sessionUpdate,
					...priceUpdate,
					productionPriceRef: null,
				});
			}
		} else {
			transaction.update(sessionRef, sessionUpdate);
		}
	});
}
