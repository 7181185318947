import { useTranslation } from "react-i18next";

import formatBytes from "astrid-helpers/src/formatBytes";

import { dropdownMenuColumn } from "../../ui/utils/columns";

import useArtifactFileDropdownMenuOptions from "./useArtifactFileDropdownMenuOptions";

export default function useArtifactFilesTableColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			filter: "text",
			disableSortBy: true,
			Header: t("Name", "Name"),
		},
		{
			id: "contentType",
			Header: t("contentType", "Content-Type"),
			collapsing: true,
			disableSortBy: true,
		},
		{
			id: "size",
			Header: t("size", "Size"),
			collapsing: true,
			disableSortBy: true,
			accessor: ({ size }) => formatBytes(size),
		},
		dropdownMenuColumn(useArtifactFileDropdownMenuOptions),
	];
}
