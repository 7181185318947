function getComparisonOperator(value) {
	if (Array.isArray(value)) {
		return "in";
	}

	return "==";
}

export default function createQuery(query, options = {}) {
	for (const [key, value] of Object.entries(options)) {
		if (typeof value !== "undefined") {
			query = query.where(key, getComparisonOperator(value), value);
		}
	}

	return query;
}
