import ISBN from "is-isbn";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import memoize from "lodash/memoize";
import merge from "lodash/merge";
import moment from "moment";
import React, { Component, Suspense } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, Divider, Form, Icon, Image, Loader, Popup } from "semantic-ui-react";

import distConfig from "astrid-config/src/distributors";
import bokgruppe from "astrid-config/src/genres/bokgruppe";
import { base, firebase } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import UploadButton from "astrid-web/src/components/UploadButton";
import { getDistOptions } from "astrid-web/src/components/production/fnc";
import { MetaPopup } from "astrid-web/src/components/production/parts/MetaGuide";
import ProductionDistribution from "astrid-web/src/components/production/parts/ProductionDistribution";
import { withStore } from "astrid-web/src/helpers/context";
import { slugify, toDate } from "astrid-web/src/helpers/fnc";

import DateTime from "../../../features/ui/components/DateInput/DateTime";

import ScrapeArticle from "../ScrapeArticle";
import DistributorMetaBox from "../master/DistributorMetaBox";
import MasterButton from "../master/MasterButton";
import MasterUploads from "../master/MasterUploads";
import SendEbook from "../master/SendEbook";

import SubcontractorProgress from "./SubcontractorProgress";

const activeDistributors = Object.entries(distConfig).filter(
	([distributor, { meta, ebook, disabled }]) => (meta || ebook?.enableSendWithoutMetaData) && !disabled,
);

//const getAvailableProducers = firebase.functions().httpsCallable("getAvailableProducers");

class ProductionArticles extends Component {
	state = {
		openMetaBox: null,
	};

	handlePartChange = (i, data) => {
		const newData = [];
		const partsData = this.props.production.deliveryParts ? cloneDeep(this.props.production.deliveryParts) : [];
		if (!partsData[i]) partsData[i] = {};
		let updateInstantly = false;

		if (data.type === "date") {
			updateInstantly = true;
			const dateString = data.value;
			data.value = data.value ? new Date(dateString) : null;

			// publishers can't change delivery dates after production is accepted
			if (data.name === "delivery" && this.props.deliveryDateCheck(toDate(partsData[i]["delivery"]), data.value))
				return;

			if (data.value && !Number.isInteger(+data.value)) {
				// invalid, probably 30 feb or 31 april or similar, set to first of next month
				data.value = moment(partsData[i][data.name]).add(1, "M").startOf("month").toDate();
			}

			// no total production article, set delivery date to soonest part date?
			if (
				typeof this.props.production.isbn !== "string" &&
				(!this.props.production.deliveryDate || toDate(this.props.production.deliveryDate) > data.value)
			) {
				newData.push({ name: "deliveryDate", value: data.value });
			}
		}

		// updated meta?
		if (["isbn", "title", "synopsis"].includes(data.name)) {
			partsData[i].metaUpdated = new Date();
		}

		if (partsData[i][data.name] !== data.value) {
			partsData[i][data.name] = data.value;
			newData.push({ name: "deliveryParts", value: partsData });
		}

		// if nothing changed, bail
		if (Object.keys(newData).length) this.handleChange(!updateInstantly, newData);
	};

	handleDiscChange = (disc, data) => {
		const discData = this.props.production[disc] ? cloneDeep(this.props.production[disc]) : {};
		let updateInstantly = false;

		if (data.type === "date") {
			updateInstantly = true;
			const dateString = data.value;
			data.value = data.value ? new Date(dateString) : null;

			// publishers can't change delivery dates after production is accepted
			if (data.name === "delivery" && this.props.deliveryDateCheck(toDate(discData["delivery"]), data.value))
				return;

			if (data.value && !Number.isInteger(+data.value)) {
				// invalid, probably 30 feb or 31 april or similar, set to first of next month
				data.value = moment(discData[data.name]).add(1, "M").startOf("month").toDate();
			}
		}

		// updated meta?
		if (disc === "deliveryEbook" && ["isbn", "synopsis"].includes(data.name)) {
			discData.metaUpdated = firebase.firestore.FieldValue.serverTimestamp();
		}

		if (discData[data.name] !== data.value) {
			discData[data.name] = data.value;
			this.handleChange(!updateInstantly, { name: disc, value: discData });
		}
	};

	metaFields = ["synopsis"];

	handleChange = (e, data, { metadata } = {}) => {
		if (e) {
			// cache keystroke events, firestore update is debounced
			const cachedUpdate = {};
			if (!Array.isArray(data)) data = [data];
			data.forEach((data) => {
				cachedUpdate[data.name] = data.value;
			});
			this.setState({ cachedUpdate });
		}

		this.props.handleChange(e, data, { metadata });
	};

	confirmRemoveArticle = (changeData) => {
		const { t } = this.props;
		if (window.confirm(`${t("wantToDelete")}`)) {
			this.handleChange(null, changeData);
		}
	};

	eventLog = (data) => {
		// store event in subcollection for blaming
		addEvent({
			productionId: this.props.productionId,
			user: this.props.store.state.user.uid,
			email: this.props.store.state.profile.email,
			data,
		});
	};

	uploadImage = (e, article, i) => {
		const { t } = this.props;
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-uploads" : "gs://earselect-uploads");

		// file from input
		const file = e.target.files[0];

		if (!file) return;

		if (file.type && file.type.substr(0, 5) !== "image") {
			this.setState({ upload: t("notAnImg") }); //Translation id's in productions.json
			return;
		}

		// start upload, send article and
		const uploadTask = storageRef
			.child(
				"coverImages/" +
					this.props.productionId +
					(article ? "_" + article : "") + // part or disc
					(typeof i === "undefined" ? "" : "_" + i), // part index
			)
			.put(file);

		// upload status events

		// hacky way of separating image upload statuses... should be componentized
		const status = "upload" + (article || "") + (typeof i === "undefined" ? "" : i);

		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ [status]: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					let field = "img";
					let metaUpdateField;

					if (!article) {
						metaUpdateField = "metaUpdatedTotalArticle";
					} else if (article === "part") {
						field = "deliveryPartsCover." + i + ".img";
						this.handlePartChange(i, {
							name: "metaUpdated",
							value: new Date(),
						});
					} else if (article === "cd") {
						field = "deliveryCD.img";
						metaUpdateField = "deliveryCD.metaUpdated";
					} else if (article === "mp3cd") {
						field = "deliveryMP3CD.img";
						metaUpdateField = "deliveryMP3CD.metaUpdated";
					} else if (article === "EbookCover") {
						field = "deliveryEbook.img";
						metaUpdateField = "deliveryEbook.metaUpdated";
					}

					const data = [
						{ name: field, value: downloadURL },
						{ name: field + "Deleted", value: null },
					];
					if (metaUpdateField) {
						data.push({ name: metaUpdateField, value: firebase.firestore.FieldValue.serverTimestamp() });
					}

					this.handleChange(null, data);
					this.setState({ [status]: null });
				});
			},
		);
	};

	toggleCover = ({ path, img, deleted }) => {
		const newData = img
			? [
					{ name: path, value: null },
					{ name: path + "Deleted", value: img },
			  ]
			: [
					{ name: path, value: deleted },
					{ name: path + "Deleted", value: null },
			  ];

		this.handleChange(null, newData);
	};

	downloadCover = async (img, article, i) => {
		const prod = this.props.production;
		const title = slugify(prod.title);
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-uploads" : "gs://earselect-uploads");

		await storageRef
			.child(
				"coverImages/" +
					this.props.productionId +
					(article ? "_" + article : "") +
					(typeof i === "undefined" ? "" : "_" + i),
			)
			.updateMetadata({ contentDisposition: `attachment; filename=${title}.jpg` });

		window.location = img;
	};

	uploadEbook = (e) => {
		const { t } = this.props;
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-static" : "gs://earselect-static");

		// file from input
		const file = e.target.files[0];

		if (!file) return;

		const suffix = file.name.split(".").pop();

		if (!["epub"].includes(suffix)) {
			this.setState({ uploadEbook: t("fileFormatAsEpub") });
			return;
		}

		const path = "masterDelivery/" + this.props.productionId + "/ebook/" + file.name;
		const uploadTask = storageRef.child(path).put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ uploadEbook: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.handleChange(null, [
						{ name: "deliveryEbook.path", value: path },
						{ name: "deliveryEbook.url", value: downloadURL },
						{ name: "deliveryEbook.metaUpdated", value: new Date() },
					]);

					this.setState({ uploadEbook: null });
				});
			},
		);
	};

	setOpenMetaBox = (box) => {
		this.setState({ openMetaBox: box });
	};

	setOpenExportModal = (article, title) => {
		this.setOpenMetaBox();
		this.props.openExportModal(article, title);
	};

	isbnIsValid = memoize((isbn) => !isbn || ISBN.validate(isbn));

	isbnIsAvailable = memoize((isbn) => {
		const { store, productionId } = this.props;

		return (
			!isbn ||
			!(
				store.state.productions &&
				Object.values(store.state.productions).find(
					(prod) =>
						prod.id !== productionId &&
						(prod.isbn === isbn ||
							get(prod, "deliveryEbook.isbn") === isbn ||
							prod.deliveryParts?.find((part) => part.isbn === isbn)),
				)
			)
		);
	});

	isbnIsAvalibleInternal = (production, isbn) => {
		return (
			this.isbnIsAvailable(isbn) &&
			!(production.deliveryEbook?.isbn === isbn || production.deliveryParts?.find((prod) => prod.isbn === isbn))
		);
	};

	eBookisbnIsAvailable = (production, isbn) => {
		return (
			!isbn ||
			(this.isbnIsAvailable(isbn) &&
				!(production.isbn === isbn || production.deliveryParts?.find((prod) => prod.isbn === isbn)))
		);
	};

	splitisbnIsAvailable = (production, part) => {
		return (
			!part.isbn ||
			(this.isbnIsAvailable(part.isbn) &&
				!(
					production.deliveryEbook?.isbn === part.isbn ||
					production.isbn === part.isbn ||
					production.deliveryParts?.find((p) => p !== part && p.isbn === part.isbn)
				))
		);
	};

	isValidDate = (date) => {
		const { production } = this.props;
		const isInternal = !["external", "backlist"].includes(production.productionType);

		if (isInternal && production.productionDate) {
			return date.isAfter(toDate(production.productionDate));
		}

		if (isInternal && production.status === "draft") {
			return date.isAfter(new Date(+Date.now() + 7 * 24 * 60 * 60 * 1000));
		}

		return date.isAfter(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000));
	};

	render() {
		const { cachedUpdate, openMetaBox, uploadPane } = this.state;
		const {
			t,
			store,
			productionRights,
			isSubcontractor,
			isPublisher,
			isProducer,
			publisher,
			productionId,
			distributors,
			addReader,
			addingReader,
			userMap,
			alerts,
			history,
			// checkMissing,
		} = this.props;

		// merge production data with cache
		const production = cachedUpdate ? merge(this.props.production, cachedUpdate) : this.props.production;

		const hasExternalUpload =
			publisher &&
			publisher.distributionOptions &&
			Object.values(publisher.distributionOptions).find(
				(opt) =>
					opt.languages &&
					opt.languages.includes(production.language || "sv") &&
					get(opt, "settings.external.upload.password"),
			);

		const hasAudioBook = !!(typeof production.isbn === "string" || production.deliveryParts);

		const lockDate = !!(
			production &&
			["accepted", "production", "done"].includes(production.status) &&
			!productionRights.includes("createProduction")
		);

		// only show distributors where publisher has been added
		const distOptions = getDistOptions({ production, publisher });
		const dists = distributors ? activeDistributors.filter((d) => Object.keys(distributors).includes(d[0])) : [];

		// check if any metadata has been sent for each article
		const metaSentTotal = production.release && Object.values(production.release).find((d) => d.metaSent);
		const metaSentEbook =
			production.deliveryEbook &&
			production.deliveryEbook.release &&
			Object.values(production.deliveryEbook.release).find((d) => d.metaSent);
		const metaSentPart =
			production.deliveryParts &&
			production.deliveryParts[production.deliveryParts.length - 1] &&
			production.deliveryParts[production.deliveryParts.length - 1].release &&
			Object.values(production.deliveryParts[production.deliveryParts.length - 1].release).find(
				(d) => d.metaSent,
			);

		return (
			<>
				{/* deliver in parts */}
				{typeof production.isbn === "string" && !isSubcontractor && (
					<div className="production-delivery-article clear">
						<Divider horizontal>
							{t("audioBook")} <Icon name="box" color="grey" />
							{production.productionType && isProducer?.includes?.("producerAdmin") && (
								<Popup
									inverted
									size="mini"
									content="Ändra till intern produktion och aktivera ljudboksflik"
									trigger={
										<Icon
											name={"random"}
											className="pointer"
											onClick={() => {
												this.handleChange(null, [
													{ name: "status", value: "planning" },
													{ name: "productionType", value: null },
												]);
											}}
										/>
									}
								/>
							)}
						</Divider>

						<div className="flex-stack">
							<Form as="div">
								<MetaPopup id="isbn" text={t("specifyIsbn")}>
									<Form.Input
										fluid
										label={
											t("isbnNr") +
											(!this.isbnIsAvalibleInternal(production, production.isbn)
												? t("taken")
												: "")
										}
										name="isbn"
										type="text"
										disabled={!productionRights.includes("createProduction")}
										className={
											!productionRights.includes("createProduction") ? "locked-field" : null
										}
										value={production.isbn || ""}
										error={
											!!alerts.isbn || !this.isbnIsAvalibleInternal(production, production.isbn)
										}
										onChange={this.handleChange}
										onBlur={(e, data) => {
											const stripped = e.target.value.replace(/-|\s/g, "");
											this.handleChange(e, { name: "isbn", value: stripped });

											// check for existing iSBN
											store.getProductions({ filterPublisher: [production.publisher] });
										}}
									/>
								</MetaPopup>
								{productionRights.includes("createProduction") && (
									<div className="production-cover">
										<Cover
											production={production}
											uploadImage={this.uploadImage}
											toggleCover={this.toggleCover}
											downloadCover={this.downloadCover}
											uploadState={this.state.upload}
											path={"img"}
										/>
									</div>
								)}
								{production.language === "no" && (
									<SelectBokgruppe
										name={"bokgruppe"}
										productionRights={productionRights}
										value={production.bokgruppe}
										handleChange={this.handleChange}
									/>
								)}
							</Form>
							<Form as="div">
								{productionRights.includes("productionDeliveryDate.view") && (
									<div
										className={
											"field" +
											(lockDate ? " locked-field" : "") +
											(alerts.deliveryDate ? " error" : "")
										}
									>
										<MetaPopup id="deliveryDate" text={t("specifyBookDelDate")}>
											<label>{t("delDate")}</label>
										</MetaPopup>
										<DateTime
											locale={moment.locale()}
											dateFormat={"YYYY-MM-DD"}
											timeFormat={false}
											isValidDate={this.isValidDate}
											inputProps={{
												disabled: lockDate || !productionRights.includes("createProduction"),
											}}
											value={
												production.deliveryDate?.toDate
													? moment(production.deliveryDate.toDate()).format("YYYY-MM-DD")
													: ""
											}
											onChange={(dt) => {
												if (!dt || typeof dt === "object") {
													// valid date, save it
													this.handleChange(null, {
														name: "deliveryDate",
														type: "date",
														value: dt ? dt.format("YYYY-MM-DD") : null,
													});
												}
											}}
										/>
									</div>
								)}

								{production.productionType ? (
									<MetaPopup id="reader" text={t("specifyBookReader")}>
										<Form.Select
											label={t("narrator")}
											search
											deburr
											fluid
											multiple
											placeholder={t("searchCreateNew")}
											// disabled={lockDetails && !production.productionType}
											// className={lockDetails && !production.productionType ? "locked-field" : null}
											error={!!alerts.reader}
											name="reader"
											allowAdditions={true}
											additionLabel={t("add") + " "}
											loading={addingReader}
											value={production.reader || []}
											options={
												userMap
													? Object.values(userMap)
															.filter(
																(user) =>
																	// readers who are available for all publishers, or this specific one
																	user?.permissions?.reader?.includes?.(
																		"allPublishers",
																	) &&
																	// if language is specified, only list readers who know the language
																	(!production.language ||
																		Object.keys(user.languages || {}).length ===
																			0 ||
																		user.languages?.[production.language]),
															)
															.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))

															.map((user, i) => {
																return {
																	key: i,
																	text: user.firstName + " " + user.lastName,
																	value: user.id,
																};
															})
													: []
											}
											noResultsMessage={t("noMatches")}
											onChange={(e, data) => {
												if (!production.language) {
													window.alert(t("addProdLang"));
												} else {
													// update metadata date if accepted user was removed
													const last = data.value[data.value.length - 1];
													if (!!userMap[last] || !data.value?.length) {
														this.handleChange(null, data);
													} else {
														addReader(e, { ...data, value: last });
													}
												}
											}}
										/>
									</MetaPopup>
								) : (
									!production.reader &&
									!production.readerSuggestion &&
									!production.readerStatus && (
										<Button
											primary
											content={t("chooseReader")}
											onClick={() => {
												history.push(`/production/${productionId}/readers`);
											}}
										/>
									)
								)}
							</Form>

							{productionRights.includes("createProduction") && (
								<div className="article-distribution">
									{distributors === false && <Loader inline active size="tiny" />}
									<div className="field">
										{!!dists?.length && <label>{t("distribution")}</label>}
										{dists
											.filter(
												([distributor, { ebook }]) =>
													!ebook?.enableSendWithoutMetaData &&
													distOptions &&
													// !get(distOptions, "settings.total." + distributor + ".excludeMeta") &&
													distOptions.total &&
													distOptions.total.includes(distributor) &&
													Object.entries(distributors).find(
														([id, info]) => id === distributor && !info.excludeMeta,
													),
											)
											.map(([distributor, { name }]) => (
												<DistributorMetaBox
													fluid
													key={distributor}
													production={production}
													productionId={this.props.productionId}
													isProducer={isProducer}
													label={name}
													distributor={distributor}
													article="total"
													openMetaBox={openMetaBox}
													setOpenMetaBox={this.setOpenMetaBox}
													handleChange={(key, val) => {
														const newData = {
															["release." + distributor + "." + key]: val,
														};

														if (key !== "scraped")
															newData["release." + distributor + ".metaUpdated"] =
																firebase.firestore.FieldValue.serverTimestamp();

														if (key === "date") {
															// set release date and availability for all undefined dists
															dists.forEach(([dist, info]) => {
																if (
																	dist !== distributor &&
																	!get(production, "release." + dist + ".date")
																)
																	newData["release." + dist + ".date"] = val;

																if (
																	val &&
																	info?.meta?.fields?.includes("availability") &&
																	!get(production, "release." + dist + ".date")
																)
																	newData["release." + dist + ".availability"] = true;

																if (
																	val &&
																	info?.meta?.fields?.includes("markets") &&
																	!get(
																		production,
																		"release." + dist + ".markets.length",
																	)
																)
																	newData["release." + dist + ".world"] = true;
															});
														}

														base.updateDoc("productions/" + productionId, newData).then(
															() => {
																this.eventLog(newData);
															},
														);
													}}
												/>
											))}
										<MasterButton
											article="total"
											title={t("audioBook")}
											production={production}
											productionRights={productionRights}
											openExportModal={this.setOpenExportModal}
										/>
									</div>
									{!metaSentTotal && ["draft", "offer"].includes(production.status) && (
										<Icon
											name="remove"
											className="article-remove"
											onClick={(e) => {
												this.confirmRemoveArticle({
													name: "isbn",
													value: null,
												});
											}}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				)}
				{production.deliveryParts &&
					production.deliveryParts.map((part, i) => (
						<div key={i} className="production-delivery-article clear part-article">
							{!i && (
								<Form.Group widths="equal" style={{ marginBottom: -10 }}>
									<Form.Input
										label={t("nameOnSerie")}
										name="deliveryPartsTitle"
										value={production.deliveryPartsTitle || ""}
										error={!production.deliveryPartsTitle && !production.series}
										onChange={(e, data) => {
											this.handleChange(e, data, { metadata: true });
										}}
									/>
									<div className="field" />
									<div className="field" />
									<div className="field" />
									{production.language === "no" && (
										<SelectBokgruppe
											name={"bokgruppe"}
											productionRights={productionRights}
											value={production.bokgruppe}
											handleChange={this.handleChange}
										/>
									)}
								</Form.Group>
							)}
							{!i && production.series && production.deliveryPartsTitle && (
								<em style={{ position: "relative", top: 10, fontSize: "0.8em" }}>
									<b>{t("important")}</b> {t("replaceProdsSerieMetadata")}{" "}
									<strike>
										{production.series}
										{production.part && ", del " + production.part}
									</strike>
								</em>
							)}
							<Divider horizontal>
								{t("partOfBook")} {i + 1}
								<Icon circular inverted color="grey" name="percent" />
								{isProducer?.includes?.("producerAdmin") && part.isbn && (
									<ScrapeArticle article={"part"} isbn={part.isbn} production={production} />
								)}
							</Divider>
							<div className="flex-stack">
								<Form as="div">
									<Form.Input
										label={t("title")}
										name="title"
										value={part.title || ""}
										onChange={(e, data) => this.handlePartChange(i, data)}
										error={!part.title}
									/>

									<Form.Input
										fluid
										label={
											t("isbnNr") +
											(!this.splitisbnIsAvailable(production, part) ? t("taken") : "")
										}
										name="isbn"
										type="text"
										value={part.isbn || ""}
										error={
											!part.isbn ||
											!this.isbnIsValid(part.isbn) ||
											!this.splitisbnIsAvailable(production, part)
										}
										onChange={(e, data) => {
											this.handlePartChange(i, data);
										}}
										onBlur={(e, data) => {
											const stripped = e.target.value.replace(/-|\s/g, "");
											this.handlePartChange(i, { name: "isbn", value: stripped });

											// check for existing iSBN
											store.getProductions({ filterPublisher: [production.publisher] });
										}}
									/>
									<Form.TextArea
										className="textarea-expander"
										label={t("synopsis")}
										name="synopsis"
										value={part.synopsis || ""}
										onChange={(e, data) => this.handlePartChange(i, data)}
									/>

									<div className="production-cover">
										<Cover
											production={production}
											uploadImage={this.uploadImage}
											toggleCover={this.toggleCover}
											downloadCover={this.downloadCover}
											uploadState={this.state["uploadpart" + i]}
											path={"deliveryPartsCover." + i + ".img"}
											article="part"
											articleIndex={i}
										/>
									</div>
								</Form>
								<Form as="div">
									<Form.Input
										label={t("pageStart")}
										name="pageStart"
										type="number"
										value={part.pageStart || ""}
										onChange={(e, data) => this.handlePartChange(i, data)}
									/>

									<Form.Input
										label={t("pageEnd")}
										name="pageEnd"
										type="number"
										value={part.pageEnd || ""}
										onChange={(e, data) => this.handlePartChange(i, data)}
									/>
									<div className={"field" + (lockDate ? " locked-field" : "")}>
										<label>{t("deliveryDate")}</label>
										<DateTime
											locale={moment.locale()}
											dateFormat={"YYYY-MM-DD"}
											timeFormat={false}
											isValidDate={
												production.productionDate &&
												!["external", "backlist"].includes(production.productionType)
													? (current) => current.isAfter(toDate(production.productionDate))
													: production.status === "draft" &&
													  !["external", "backlist"].includes(production.productionType)
													? (current) =>
															current.isAfter(
																new Date(+Date.now() + 7 * 24 * 60 * 60 * 1000),
															)
													: () => true
											}
											inputProps={{
												disabled: lockDate || !productionRights.includes("createProduction"),
											}}
											value={
												part.delivery && part.delivery.toDate
													? moment(part.delivery.toDate()).format("YYYY-MM-DD")
													: ""
											}
											onChange={(dt) => {
												if (!dt || typeof dt === "object") {
													// valid date, save it
													this.handlePartChange(i, {
														name: "delivery",
														type: "date",
														value: dt ? dt.format("YYYY-MM-DD") : null,
													});
												}
											}}
											onBlur={(dt) => {
												const writtenDate = moment(dt).format("YYYY-MM-DD");
												// see if typed date is valid and not the same as currently is in the db
												if (
													writtenDate !== "Invalid date" &&
													writtenDate !== part.delivery &&
													part.delivery &&
													part.delivery.toDate &&
													moment(part.delivery.toDate()).format("YYYY-MM-DD")
												) {
													// valid date, save it
													this.handlePartChange(i, {
														name: "delivery",
														type: "date",
														value: writtenDate,
													});
												} else {
													console.log("Invalid date", dt);
												}
											}}
										/>
									</div>
									<Form.Input
										label={t("finalDuration")}
										name="duration"
										value={part.duration || ""}
										pattern="([0-9]{0,3})(:[0-5][0-9]){2}"
										placeholder="0:00:00"
										disabled={!isProducer}
										className={!isProducer ? "locked-field" : null}
										onChange={(e, data) => this.handlePartChange(i, data)}
										onBlur={(e) => {
											if (!e.target.checkValidity()) window.alert(t("wrongTimeFormat"));
										}}
									/>
								</Form>
								{productionRights.includes("createProduction") && (
									<div className="article-distribution">
										<div className="field">
											{!!dists?.length && <label>{t("distribution")}</label>}
											{dists
												.filter(
													([distributor]) =>
														distOptions &&
														// !get(
														// 	distOptions,
														// 	"settings.part." + distributor + ".excludeMeta",
														// ) &&
														distOptions.part &&
														distOptions.part.includes(distributor) &&
														Object.entries(distributors).find(
															([id, info]) => id === distributor && !info.excludeMeta,
														),
												)
												.map(([distributor, { name }]) => (
													<DistributorMetaBox
														fluid
														key={distributor}
														production={production}
														productionId={this.props.productionId}
														isProducer={isProducer}
														label={name}
														distributor={distributor}
														article={"part_" + i}
														openMetaBox={openMetaBox}
														setOpenMetaBox={this.setOpenMetaBox}
														handleChange={(key, val) => {
															// reset cache
															this.setState({ cachedUpdate: null });

															const data = cloneDeep(production.deliveryParts);
															if (!data[i].release) data[i].release = {};
															if (!data[i].release[distributor])
																data[i].release[distributor] = {};

															data[i].release[distributor][key] = val;

															if (key === "date") {
																// set release date and availability for all undefined dists
																dists.forEach(([dist, info]) => {
																	const firstTimeDate =
																		val &&
																		!get(
																			production.deliveryParts,
																			"[" + i + "].release." + dist + ".date",
																		);

																	if (firstTimeDate && dist !== distributor) {
																		if (!data[i].release[dist])
																			data[i].release[dist] = {};
																		data[i].release[dist][key] = val;
																	}

																	if (
																		firstTimeDate &&
																		info?.meta?.fields?.includes("availability")
																	) {
																		if (!data[i].release[dist])
																			data[i].release[dist] = {};
																		data[i].release[dist].availability = true;
																	}

																	if (
																		firstTimeDate &&
																		info?.meta?.fields?.includes("markets") &&
																		!get(
																			data,
																			"[" +
																				i +
																				"].release." +
																				dist +
																				".markets.length",
																		)
																	) {
																		if (!data[i].release[dist])
																			data[i].release[dist] = {};
																		data[i].release[dist].world = true;
																	}
																});
															}

															// (can't use firebase.firestore.FieldValue.serverTimestamp() in arrays...)
															if (key !== "metaSent" && key !== "scraped")
																data[i].release[distributor].metaUpdated = new Date();

															base.updateDoc("productions/" + productionId, {
																deliveryParts: data,
															}).then(() => {
																this.eventLog({
																	alteredPartData: { [key]: val },
																	deliveryParts: data,
																});
															});
														}}
													/>
												))}
											<MasterButton
												article={"part_" + i}
												title={t("part") + " " + (i + 1)}
												production={production}
												productionRights={productionRights}
												openExportModal={this.setOpenExportModal}
											/>
										</div>

										{production.deliveryParts.length === i + 1 && (
											<div style={{ marginTop: 15 }}>
												<Button
													icon="plus"
													color="blue"
													size="mini"
													floated="right"
													content={t("addPart")}
													onClick={() => {
														const partsData = cloneDeep(production.deliveryParts);
														partsData.push({});

														// store changes
														this.handleChange(null, {
															name: "deliveryParts",
															value: partsData,
														});
													}}
												/>
												{!metaSentPart && (
													<Icon
														name="remove"
														className="article-remove"
														onClick={() => {
															const partsData = cloneDeep(production.deliveryParts);
															partsData.pop();

															// store changes
															this.confirmRemoveArticle({
																name: "deliveryParts",
																value: partsData,
															});
														}}
													/>
												)}
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					))}

				{/* deliver on discs */}
				{production.deliveryCD && (
					<div className="production-delivery-article clear">
						<Divider horizontal>
							CD <Icon name="dot circle" color="grey" />
						</Divider>
						<Form.Group widths="equal">
							{/* deprecated field */}
							{production.deliveryCD.info !== undefined && (
								<Form.Input
									fluid
									label={t("delCdInfo")}
									name="info"
									type="text"
									value={production.deliveryCD.info || ""}
									onChange={(e, data) => this.handleDiscChange("deliveryCD", data)}
								/>
							)}
							<Form.Input
								fluid
								label={t("ifcdNr")}
								name="ifcd"
								type="number"
								value={production.deliveryCD.ifcd || ""}
								onChange={(e, data) => this.handleDiscChange("deliveryCD", data)}
							/>

							<Form.Select
								fluid
								label={t("cdDuration")}
								name="duration"
								value={production.deliveryCD.duration || ""}
								options={[
									{ text: "74 min", key: 74, value: 74 },
									{ text: "80 min", key: 80, value: 80 },
								]}
								onChange={(e, data) => this.handleDiscChange("deliveryCD", data)}
							/>

							<div className={"field" + (lockDate ? " locked-field" : "")}>
								<label>{t("deliveryDate")}</label>
								<DateTime
									value={
										production.deliveryCD.delivery && production.deliveryCD.delivery.toDate
											? moment(production.deliveryCD.delivery.toDate()).format("YYYY-MM-DD")
											: ""
									}
									timeFormat={false}
									inputProps={{
										disabled: lockDate || !productionRights.includes("createProduction"),
									}}
									isValidDate={
										production.productionDate &&
										!["external", "backlist"].includes(production.productionType)
											? (current) => current.isAfter(toDate(production.productionDate))
											: production.status === "draft" &&
											  !["external", "backlist"].includes(production.productionType)
											? (current) =>
													current.isAfter(new Date(+Date.now() + 7 * 24 * 60 * 60 * 1000))
											: () => true
									}
									onChange={(dt) => {
										if (typeof dt === "object") {
											// valid date, save it
											this.handleDiscChange("deliveryCD", {
												name: "delivery",
												type: "date",
												value: dt.format("YYYY-MM-DD"),
											});
										}
									}}
									onBlur={(dt) => {
										const writtenDate = moment(dt).format("YYYY-MM-DD");
										// see if typed date is valid and not the same as currently is in the db
										if (
											writtenDate !== "Invalid date" &&
											writtenDate !== production.deliveryCD &&
											production.deliveryCD.delivery &&
											production.deliveryCD.delivery.toDate &&
											moment(production.deliveryCD.delivery.toDate()).format("YYYY-MM-DD")
										) {
											// valid date, save it
											this.handleDiscChange("deliveryCD", {
												name: "delivery",
												type: "date",
												value: writtenDate,
											});
										} else {
											console.log("Invalid date", dt);
										}
									}}
								/>
							</div>
							{production.language === "no" && (
								<SelectBokgruppe
									name={"deliveryCD.bokgruppe"}
									productionRights={productionRights}
									value={production.deliveryCD.bokgruppe}
									handleChange={this.handleChange}
								/>
							)}
						</Form.Group>
						{productionRights.includes("createProduction") && (
							<div className="flex-stack">
								<div className="production-cover">
									<Cover
										production={production}
										uploadImage={this.uploadImage}
										toggleCover={this.toggleCover}
										downloadCover={this.downloadCover}
										uploadState={this.state.uploadcd}
										path={"deliveryCD.img"}
										article="cd"
									/>

									<MasterButton
										article={"cd"}
										title={t("cd")}
										production={production}
										productionRights={productionRights}
										openExportModal={this.setOpenExportModal}
									/>
								</div>
								<Icon
									name="remove"
									className="article-remove"
									onClick={(e) => {
										this.confirmRemoveArticle({
											name: "deliveryCD",
											value: null,
										});
									}}
								/>
							</div>
						)}
					</div>
				)}
				{production.deliveryMP3CD && (
					<div className="production-delivery-article clear">
						<Divider horizontal>
							{t("mp3cd")} <Icon name="file audio" color="grey" />
						</Divider>
						<Form.Group widths="equal">
							{/* deprecated field */}
							{production.deliveryMP3CD.info !== undefined && (
								<Form.Input
									fluid
									label={t("delMp3Info")}
									name="info"
									type="text"
									value={production.deliveryMP3CD.info || ""}
									onChange={(e, data) => this.handleDiscChange("deliveryMP3CD", data)}
								/>
							)}
							<Form.Input
								fluid
								label={t("ifcdNr")}
								name="ifcd"
								type="number"
								value={production.deliveryMP3CD.ifcd || ""}
								onChange={(e, data) => this.handleDiscChange("deliveryMP3CD", data)}
							/>
							<div className={"field" + (lockDate ? " locked-field" : "")}>
								<label>{t("deliveryDate")}</label>
								<DateTime
									value={
										production.deliveryMP3CD.delivery && production.deliveryMP3CD.delivery.toDate
											? moment(production.deliveryMP3CD.delivery.toDate()).format("YYYY-MM-DD")
											: ""
									}
									timeFormat={false}
									inputProps={{
										disabled: lockDate || !productionRights.includes("createProduction"),
									}}
									isValidDate={
										production.productionDate &&
										!["external", "backlist"].includes(production.productionType)
											? (current) => current.isAfter(toDate(production.productionDate))
											: production.status === "draft" &&
											  !["external", "backlist"].includes(production.productionType)
											? (current) =>
													current.isAfter(new Date(+Date.now() + 7 * 24 * 60 * 60 * 1000))
											: () => true
									}
									onChange={(dt) => {
										if (typeof dt === "object") {
											// valid date, save it
											this.handleDiscChange("deliveryMP3CD", {
												name: "delivery",
												type: "date",
												value: dt.format("YYYY-MM-DD"),
											});
										}
									}}
									onBlur={(dt) => {
										const writtenDate = moment(dt).format("YYYY-MM-DD");
										// see if typed date is valid and not the same as currently is in the db
										if (
											writtenDate !== "Invalid date" &&
											writtenDate !== production.deliveryMP3CD &&
											production.deliveryMP3CD.delivery &&
											production.deliveryMP3CD.delivery.toDate &&
											moment(production.deliveryMP3CD.delivery.toDate()).format("YYYY-MM-DD")
										) {
											// valid date, save it
											this.handleDiscChange("deliveryMP3CD", {
												name: "delivery",
												type: "date",
												value: writtenDate,
											});
										} else {
											console.log("Invalid date", dt);
										}
									}}
								/>
							</div>
							{production.language === "no" && (
								<SelectBokgruppe
									name={"deliveryMP3CD.bokgruppe"}
									productionRights={productionRights}
									value={production.deliveryMP3CD.bokgruppe}
									handleChange={this.handleChange}
								/>
							)}
						</Form.Group>
						{productionRights.includes("createProduction") && (
							<div className="flex-stack">
								<div className="production-cover">
									<Cover
										production={production}
										uploadImage={this.uploadImage}
										toggleCover={this.toggleCover}
										downloadCover={this.downloadCover}
										uploadState={this.state.uploadmp3cd}
										path={"deliveryMP3CD.img"}
										article="mp3cd"
									/>

									<MasterButton
										article={"mp3cd"}
										title={t("mp3cd")}
										production={production}
										productionRights={productionRights}
										openExportModal={this.setOpenExportModal}
									/>
								</div>
								<Icon
									name="remove"
									className="article-remove"
									onClick={(e) => {
										this.confirmRemoveArticle({
											name: "deliveryMP3CD",
											value: null,
										});
									}}
								/>
							</div>
						)}
					</div>
				)}
				{production.deliveryEbook && (
					<div className="production-delivery-article clear">
						<Divider horizontal>
							{t("eBook")} <Icon name="book" color="grey" />{" "}
							{isProducer?.includes?.("producerAdmin") && production.deliveryEbook.isbn && (
								<ScrapeArticle
									article={"ebook"}
									isbn={production.deliveryEbook.isbn}
									production={production}
								/>
							)}
						</Divider>
						<div className="flex-stack">
							<Form as="div">
								<Form.Input
									fluid
									label={
										t("isbnNr") +
										" " +
										(!this.eBookisbnIsAvailable(production, production.deliveryEbook.isbn)
											? t("taken")
											: "")
									}
									name="isbn"
									type="text"
									value={production.deliveryEbook.isbn || ""}
									error={
										!production.deliveryEbook.isbn ||
										!this.isbnIsValid(production.deliveryEbook.isbn) ||
										!this.eBookisbnIsAvailable(production, production.deliveryEbook.isbn)
									}
									onChange={(e, data) => {
										this.handleDiscChange("deliveryEbook", data);
									}}
									onBlur={(e, data) => {
										const stripped = e.target.value.replace(/-|\s/g, "");
										this.handleDiscChange("deliveryEbook", { name: "isbn", value: stripped });

										// check for existing ISBN
										store.getProductions({ filterPublisher: [production.publisher] });
									}}
								/>

								<Form.TextArea
									className="textarea-expander"
									label={t("synopsis")}
									name="synopsis"
									value={production.deliveryEbook.synopsis || ""}
									onChange={(e, data) => this.handleDiscChange("deliveryEbook", data)}
								/>
								<div className="production-cover">
									<Cover
										production={production}
										uploadImage={this.uploadImage}
										toggleCover={this.toggleCover}
										downloadCover={this.downloadCover}
										uploadState={this.state.uploadEbookCover}
										path={"deliveryEbook.img"}
										article="EbookCover"
									/>
								</div>
							</Form>
							<Form as="div">
								<div className={"field" + (lockDate ? " locked-field" : "")}>
									<label>{t("deliveryDate")}</label>
									<DateTime
										value={
											production.deliveryEbook.delivery &&
											production.deliveryEbook.delivery.toDate
												? moment(production.deliveryEbook.delivery.toDate()).format(
														"YYYY-MM-DD",
												  )
												: ""
										}
										timeFormat={false}
										inputProps={{
											disabled: lockDate || !productionRights.includes("createProduction"),
										}}
										isValidDate={
											production.productionDate &&
											!["external", "backlist"].includes(production.productionType)
												? (current) => current.isAfter(toDate(production.productionDate))
												: production.status === "draft" &&
												  !["external", "backlist"].includes(production.productionType)
												? (current) =>
														current.isAfter(new Date(+Date.now() + 7 * 24 * 60 * 60 * 1000))
												: () => true
										}
										onChange={(dt) => {
											if (typeof dt === "object") {
												// valid date, save it
												this.handleDiscChange("deliveryEbook", {
													name: "delivery",
													type: "date",
													value: dt.format("YYYY-MM-DD"),
												});
											}
										}}
										onBlur={(dt) => {
											const writtenDate = moment(dt).format("YYYY-MM-DD");
											// see if typed date is valid and not the same as currently is in the db
											if (
												writtenDate !== "Invalid date" &&
												writtenDate !== production.deliveryEbook &&
												production.deliveryEbook.delivery &&
												production.deliveryEbook.delivery.toDate &&
												moment(production.deliveryEbook.delivery.toDate()).format("YYYY-MM-DD")
											) {
												// valid date, save it
												this.handleDiscChange("deliveryEbook", {
													name: "delivery",
													type: "date",
													value: writtenDate,
												});
											} else {
												console.log("Invalid date", dt);
											}
										}}
									/>
								</div>

								<div>
									<UploadButton
										label={t("eBookFile")}
										text={t("uploadEpub")}
										onUpload={(e) => {
											this.uploadEbook(e);
										}}
										size="small"
									/>
									<div>
										{production.deliveryEbook && production.deliveryEbook.url && (
											<a
												href={production.deliveryEbook.url}
												target="_blank"
												rel="noopener noreferrer"
												className="ui blue button"
											>
												<i aria-hidden="true" className="book icon" />
												{t("getEpub")}
											</a>
										)}
										{this.state.uploadEbook && <em>{this.state.uploadEbook}</em>}
									</div>
								</div>
							</Form>

							<div className="article-distribution">
								{productionRights.includes("createProduction") && (
									<div className="field">
										{!!dists?.length && <label>{t("distribution")}</label>}

										{dists
											.filter(
												([distributor, { disableArticles }]) =>
													distOptions &&
													// !get(distOptions, "settings.ebook." + distributor + ".excludeMeta") &&
													distOptions.ebook &&
													distOptions.ebook.includes(distributor) &&
													(!disableArticles || !disableArticles.includes("ebook")) &&
													Object.entries(distributors).find(
														([id, info]) => id === distributor && !info.excludeMeta,
													),
											)
											.map(([distributor, { name, ebook }]) => {
												if (ebook?.enableSendWithoutMetaData) {
													return (
														<SendEbook
															key={`sendEbook-${distributor}`}
															distName={name}
															profile={store.state.profile}
															uid={store.state.user.uid}
															productionId={this.props.productionId}
															production={production}
															distributor={distributor}
															logEvent={this.eventLog}
														/>
													);
												} else {
													return (
														<DistributorMetaBox
															fluid
															key={distributor}
															production={production}
															productionId={this.props.productionId}
															isProducer={isProducer}
															label={name}
															distributor={distributor}
															article="ebook"
															openMetaBox={openMetaBox}
															setOpenMetaBox={this.setOpenMetaBox}
															handleChange={(key, val) => {
																// reset cache
																this.setState({ cachedUpdate: null });

																const newData = {
																	["deliveryEbook.release." +
																	distributor +
																	"." +
																	key]: val,
																};

																if (key !== "scraped")
																	newData[
																		"deliveryEbook.release." +
																			distributor +
																			".metaUpdated"
																	] = firebase.firestore.FieldValue.serverTimestamp();

																if (key === "date") {
																	// set release date and availability for all undefined dists
																	dists.forEach(([dist, info]) => {
																		if (
																			dist !== distributor &&
																			!get(
																				production,
																				"deliveryEbook.release." +
																					dist +
																					".date",
																			)
																		)
																			newData[
																				"deliveryEbook.release." +
																					dist +
																					".date"
																			] = val;

																		if (
																			val &&
																			info?.meta?.fields?.includes(
																				"availability",
																			) &&
																			!get(
																				production,
																				"deliveryEbook.release." +
																					dist +
																					".date",
																			)
																		)
																			newData[
																				"deliveryEbook.release." +
																					dist +
																					".availability"
																			] = true;

																		if (
																			val &&
																			info?.meta?.fields?.includes("markets") &&
																			!get(
																				production,
																				"deliveryEbook.release." +
																					dist +
																					".markets.length",
																			)
																		)
																			newData[
																				"deliveryEbook.release." +
																					dist +
																					".world"
																			] = true;
																	});
																}

																base.updateDoc(
																	"productions/" + productionId,
																	newData,
																).then(() => {
																	this.eventLog(newData);
																});
															}}
														/>
													);
												}
											})}
										{!metaSentEbook && (
											<Icon
												name="remove"
												className="article-remove"
												onClick={(e) => {
													this.confirmRemoveArticle({
														name: "deliveryEbook",
														value: null,
													});
												}}
											/>
										)}
									</div>
								)}
								{production.language === "no" && (
									<SelectBokgruppe
										name={"deliveryEbook.bokgruppe"}
										productionRights={productionRights}
										value={production.deliveryEbook.bokgruppe}
										handleChange={this.handleChange}
									/>
								)}
							</div>
						</div>
					</div>
				)}
				{(productionRights.includes("createProduction") || isSubcontractor) && (
					<div>
						<MetaPopup id="articles" text={t("addArticlesToDel")} />

						{productionRights.includes("createProduction") && (
							<Popup
								open={!!this.state.addArticleOpen}
								size="small"
								content={
									<Button.Group vertical basic icon labeled size="small">
										{typeof production.isbn !== "string" && (
											<Button
												content={t("audioBook")}
												icon="box"
												onClick={(e, data) => {
													this.setState({ addArticleOpen: false });

													const newData = [
														{
															name: "isbn",
															value: "",
														},
													];

													// set to internal?
													if (!hasExternalUpload)
														newData.push({ name: "productionType", value: null });

													this.handleChange(null, newData);
												}}
											/>
										)}
										{!production.deliveryParts && (
											<Button
												content={t("sharedAudioBook")}
												icon="percent"
												disabled={!!production.deliveryParts}
												onClick={(e, data) => {
													this.setState({ addArticleOpen: false });

													this.handleChange(null, {
														name: "deliveryParts",
														value: [{}, {}],
													});
												}}
											/>
										)}
										{!production.deliveryCD && (
											<Button
												content={t("cd") + (hasAudioBook ? "" : " " + t("audioBookDemanded"))}
												icon="dot circle"
												disabled={!hasAudioBook}
												onClick={(e, data) => {
													this.setState({ addArticleOpen: false });

													this.handleChange(null, {
														name: "deliveryCD",
														value: {},
													});
												}}
											/>
										)}
										{!production.deliveryMP3CD && (
											<Button
												content={
													t("mp3cd") + (hasAudioBook ? "" : " " + t("audioBookDemanded"))
												}
												icon="file audio"
												disabled={!hasAudioBook}
												onClick={(e, data) => {
													this.setState({ addArticleOpen: false });

													this.handleChange(null, {
														name: "deliveryMP3CD",
														value: {},
													});
												}}
											/>
										)}
										{!production.deliveryEbook && (
											<Button
												content={t("eBook")}
												icon="book"
												onClick={(e, data) => {
													this.setState({ addArticleOpen: false });

													this.handleChange(null, {
														name: "deliveryEbook",
														value: {},
													});
												}}
											/>
										)}
									</Button.Group>
								}
								trigger={
									<Button
										primary
										icon="plus"
										labelPosition="left"
										content={t("addArticle")}
										onClick={(e) => {
											e.preventDefault();
											this.setState({ addArticleOpen: !this.state.addArticleOpen });
										}}
									/>
								}
							/>
						)}

						{production.status !== "done" &&
							productionRights.includes("production.masterExportCreate") &&
							(isProducer || (isPublisher && production.productionType)) &&
							!isSubcontractor && (
								<>
									<Button
										color={uploadPane ? "grey" : "blue"}
										icon={uploadPane ? "close" : "cloud upload"}
										labelPosition="left"
										floated="right"
										content={uploadPane ? t("close") : t("handleAudioFiles")}
										style={{ marginBottom: 20 }}
										onClick={() => {
											this.setState({ uploadPane: !uploadPane });
										}}
									/>
									<span style={{ clear: "both", display: "table" }} />
								</>
							)}

						{isProducer && !isSubcontractor && (
							<ProductionDistribution
								publisher={publisher}
								production={production}
								handleChange={this.handleChange}
								distributors={distributors}
								distOptions={distOptions}
							/>
						)}

						{uploadPane && (
							<Suspense fallback={<div />}>
								<MasterUploads
									user={this.props.user}
									profile={store.state.profile}
									production={production}
									productionId={productionId}
									isPublisher={isPublisher}
									isProducer={isProducer}
									onClose={() => {
										this.setState({ uploadPane: false });
									}}
								/>
							</Suspense>
						)}
						{isSubcontractor && !["offer", "planning"].includes(production.status) && (
							<Suspense fallback={<div />}>
								<MasterUploads
									user={this.props.user}
									userMap={store.state.users}
									profile={store.state.profile}
									production={production}
									productionId={productionId}
									isPublisher={isPublisher}
									isProducer={isProducer}
									onClose={() => {
										this.setState({ uploadPane: false });
									}}
								/>
							</Suspense>
						)}
						{production.productionType === "subcontract" &&
							(isSubcontractor || isProducer?.includes?.("producerAdmin")) &&
							!["offer", "planning"].includes(production.status) && (
								<>
									<Divider />
									<SubcontractorProgress
										productionId={productionId}
										currentProgress={production.subcontractorProgress}
									/>
								</>
							)}
					</div>
				)}
			</>
		);
	}
}

const SelectBokgruppe = ({ value, name, productionRights, handleChange }) => {
	const { t } = useTranslation();

	return (
		<div>
			<Form.Select
				label={t("bookGroupNorway")}
				search
				error={!value}
				name={name}
				value={value || ""}
				disabled={!productionRights.includes("createProduction")}
				className={!productionRights.includes("createProduction") ? "locked-field" : null}
				options={bokgruppe.map((gruppe) => {
					// extract code from string. if no code, disable (used as category heading)
					const code = Math.abs(gruppe.split(" ")[0].replace(/\./g, ""));

					return {
						key: !isNaN(code) ? code : gruppe,
						value: code,
						text: gruppe,
						// bokbasen only accepts three number codes
						disabled: isNaN(code) || code < 100,
					};
				})}
				onChange={(e, data) => {
					handleChange(null, data);
				}}
			/>
		</div>
	);
};

const Cover = ({ production, uploadState, uploadImage, toggleCover, downloadCover, path, article, articleIndex }) => {
	const img = get(production, path);
	const thumb = get(production, path + "Thumb");
	const deleted = get(production, path + "Deleted");
	const { t } = useTranslation();

	return (
		<>
			<UploadButton
				text={t("uploadCover")}
				onUpload={(e) => {
					uploadImage(e, article, articleIndex);
				}}
				size="small"
			/>
			{(img || deleted) && (
				<Popup
					hoverable
					trigger={
						<div>
							<Image
								href={img}
								src={thumb || img}
								spaced
								style={{ height: "50px", opacity: deleted ? 0.5 : 1 }}
								onClick={(e) => {
									e.preventDefault();
									downloadCover(img, article, articleIndex);
								}}
							/>
						</div>
					}
					content={
						img ? (
							<Button
								color="red"
								icon="delete"
								size="tiny"
								content={t("deleteCover")}
								onClick={() => {
									toggleCover({ path, img, deleted });
								}}
							/>
						) : (
							<Button
								color="green"
								icon="recycle"
								size="tiny"
								content={t("restoreCover")}
								onClick={() => {
									toggleCover({ path, img, deleted });
								}}
							/>
						)
					}
					size="small"
					inverted
				/>
			)}
			{uploadState && <em>{uploadState}</em>}
		</>
	);
};

export default withTranslation()(withStore(ProductionArticles));
