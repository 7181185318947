import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import MenuItems from "../../../ui/components/Menu/components/MenuItems";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";
import QuickSearch from "../../../productions/components/QuickSearch/QuickSearch";

import OrganizationMenu from "./components/OrganizationsMenu";
import SecondaryMenu from "./components/SecondaryMenu";
import useMainMenuOptions from "./hooks/useMainMenuOptions";
import useSecondaryMenuOptions from "./hooks/useSecondaryMenuOptions";

export default function MainMenu() {
	const { t } = useTranslation();

	const profile = useProfile();
	const location = useLocation();
	const hasRight = useHasRight();

	const isDesktop = useMediaQuery({ minWidth: 1200 });

	const mainMenuOptions = useMainMenuOptions();
	const secondaryMenuOptions = useSecondaryMenuOptions();

	return (
		<Menu secondary>
			<Menu.Item as={Link} to="/" icon="home" name={t("start")} active={location.pathname === "/"} />

			{isDesktop && <MenuItems options={mainMenuOptions} />}

			<Menu.Menu position="right">
				{hasRight("productions.quickSearch") && <QuickSearch />}

				{profile && <OrganizationMenu />}

				<Menu.Item>
					<SecondaryMenu
						mainMenuOptions={!isDesktop && mainMenuOptions}
						secondaryMenuOptions={secondaryMenuOptions}
					/>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	);
}
