import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Button, Segment } from "semantic-ui-react";

class HomeFinance extends Component {
	render() {
		return (
			<Segment padded>
				<Link to="/finance">
					<Button content="Hantera ekonomin" primary />
				</Link>
			</Segment>
		);
	}
}

export default HomeFinance;
