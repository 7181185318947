import { useTranslation } from "react-i18next";
import { Divider, Header } from "semantic-ui-react";

import DurationForQuarter from "./DurationForQuarter";
import IndexQuestions from "./IndexQuestions";

export default function PredefinedQuestions() {
	const { t } = useTranslation();
	return (
		<>
			<Header as="h3">{t("predefinedQuestions", "Predefined questions")}</Header>
			<DurationForQuarter />
			<Divider />
			<IndexQuestions />
		</>
	);
}
