import { useTranslation } from "react-i18next";

import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function useSuperAdminUsersColumns({ type }) {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			Header: t("name"),
			Cell: ({ value, row }) => (
				<>
					<ImageAvatar src={row.original.avatarImg} style={{ marginRight: "1em" }} />
					<span>{value}</span>
				</>
			),
		},
		{
			id: "email",
			Header: t("email"),
		},
		["producer", "publisher"].includes(type) && {
			id: "organizations",
			Header: type === "producer" ? t("producers", "Producers") : t("publishers", "Publishers"),
			accessor: ({ organizations }) =>
				Object.values(organizations || {})
					.filter((organization) => organization.type === type)
					.map(({ name }) => name)
					.join(", "),
		},
	].filter(Boolean);
}
