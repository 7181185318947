import { useMemo } from "react";

import mapDocument from "../utils/mapDocument";

import useCollectionOnce from "./useCollectionOnce";

export default function useCollectionDataOnce(query) {
	const [snapshot, loading, error] = useCollectionOnce(query);

	const value = useMemo(() => snapshot?.docs?.map(mapDocument), [snapshot]);

	return [value, loading, error];
}
