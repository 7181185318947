import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import { useOrganizationType } from "../../organizations/state/organizationType";

export default function useOfferStatusTranslations() {
	const { t } = useTranslation();
	const organizationType = useOrganizationType();

	return useMemo(
		() => ({
			[offerStatuses.INITIAL]: t("draft", "Draft"),
			[offerStatuses.DRAFT]:
				organizationType === "publisher" ? t("offerPending", "Pending") : t("draft", "Draft"),
			[offerStatuses.OFFERED]: t("offerOffered", "Awaiting answer"),
			[offerStatuses.APPROVAL_DENIED]: t("approvalDenied", "Approval denied"),
			[offerStatuses.DECLINED]: t("offerDeclined", "Declined"),
			[offerStatuses.ACCEPTED]: t("offerAccepted", "Accepted"),
			[offerStatuses.PENDING_APPROVAL]: t("pendingApproval", "Approving"),
			[offerStatuses.PENDING_SIGNING]: t("pendingSigning", "Signing"),
			[offerStatuses.SIGNING]: t("signing", "Signing"),
			[offerStatuses.CANCELLED]: t("offerCancelled", "Cancelled"),
			[offerStatuses.EXPIRED]: t("offerExpired", "Expired"),
			[offerStatuses.EXPIRE_FAILED]: t("error", "Error"),
			[offerStatuses.INTERESTED]: t("offerInterested", "Interested"),
		}),
		[t, organizationType],
	);
}
