import { useTranslation } from "react-i18next";

import api from "../../../../api";

import ArticleDistributionChannelsCollectionFlexTable from "../../../distribution/components/ArticleDistributionChannelsCollectionFlexTable/ArticleDistributionChannelsCollectionFlexTable";
import DistributionsCollectionDataTable from "../../../distribution/components/DistributionsCollectionDataTable/DistributionsCollectionDataTable";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import AdminCard from "../../components/AdminCard/AdminCard";
import AdminForm from "../../components/AdminForm/AdminForm";

export default function AdminArticleBundleDistributionView({ bundle, organization }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		clearOnSuccess: true,
		onSubmit: ({ channels }) => {
			return api.articles.distribute({ articles: bundle.articles, channels });
		},
	});

	return (
		<>
			<AdminForm header={t("channels", "Channels")} submitText={t("distribute", "Distribute")} {...form}>
				<ArticleDistributionChannelsCollectionFlexTable article={bundle} organization={organization} />
			</AdminForm>

			<AdminCard header={t("history", "History")}>
				<DistributionsCollectionDataTable
					getQuery={(query) => query.where("article.id", "==", bundle.id).limit(50)}
				/>
			</AdminCard>
		</>
	);
}
