import lamejs from "lamejs";
import BitStream from "lamejs/src/js/BitStream";
import Lame from "lamejs/src/js/Lame";
import MPEGMode from "lamejs/src/js/MPEGMode";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

window.MPEGMode = MPEGMode;
window.Lame = Lame;
window.BitStream = BitStream;

class RecordButton extends Component {
	state = {
		remoteFile: this.props.file || null,
		recordLoading: false,
		recording: false,
		volume: 0,
	};

	componentDidMount() {
		window.AudioContext = window.AudioContext || window.webkitAudioContext;

		// prevent browser behaviour
		"drag dragstart dragend dragover dragenter dragleave drop"
			.split(" ")
			.forEach((ev) => this.el.addEventListener(ev, this.prevent));

		// set body class
		"dragover dragenter".split(" ").forEach((ev) => this.el.addEventListener(ev, this.bodyStart));
		"dragleave dragend drop click".split(" ").forEach((ev) => this.el.addEventListener(ev, this.bodyEnd));
	}

	componentWillUnmount() {
		// reset listeners
		"drag dragstart dragend dragover dragenter dragleave drop"
			.split(" ")
			.forEach((ev) => this.el.removeEventListener(ev, this.prevent));

		"dragover dragenter".split(" ").forEach((ev) => this.el.removeEventListener(ev, this.bodyStart));
		"dragleave dragend drop click".split(" ").forEach((ev) => this.el.removeEventListener(ev, this.bodyEnd));
	}

	prevent = (e) => {
		// stop propagation unless the file was dropped in the drop zone
		if (
			(e.type !== "drop" || !e.target.classList.contains("es-upload--drop")) &&
			!e.target.parentElement.closest(".earselect-blurb")
		) {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	bodyStart = (e) => {
		document.body.classList.add("is-dragging");
	};

	bodyEnd = (e) => {
		// remove body class (if drag leaves body, or is dropped)
		if (e.target === document.body || e.type === "drop") document.body.classList.remove("is-dragging");
	};

	onUpload = (e) => {
		e.preventDefault();
		const files = e.target.files || e.dataTransfer.files;
		const suffix = files[0].name.split(".").pop();

		if (files.length > 1) {
			window.alert("Endast en fil tack!");
		} else if (!files[0].type) {
			window.alert("Måste vara en fil! Mappar eller text går inte att ladda upp.");
		} else if (suffix !== "mp3" && suffix !== "m4a" && suffix !== "wav" && files[0].type !== "audio/mp3") {
			window.alert("Ljudfilen måste vara i något av följande format: mp3, wav eller m4a.");
		} else {
			console.log("drag upl", files);

			let url = window.URL.createObjectURL(files[0]);

			this.setState({ recording: false, file: url });
			this.props.onChange(files[0]);
		}
	};

	record = (e) => {
		if (this.props.onRec) this.props.onRec(e);

		this.audioContext = new AudioContext();
		this.processor = this.audioContext.createScriptProcessor(4096, 1, 1);
		this.processor.connect(this.audioContext.destination);

		navigator.mediaDevices
			.getUserMedia({ audio: true, video: false })
			.then(this.gotStreamMethod)
			.catch((e) => {
				window.alert("Du måste ge tillstånd att använda mikrofonen.");
				console.error(e);
			});
	};

	gotStreamMethod = (stream) => {
		this.tracks = stream.getTracks();
		this.microphone = this.audioContext.createMediaStreamSource(stream);
		this.microphone.connect(this.processor);

		this.mp3encoder = new lamejs.Mp3Encoder(1, this.audioContext.sampleRate || 48000, 192);
		this.mp3Data = [];

		this.processor.onaudioprocess = (event) => {
			this.setState({ recording: true });
			this.encode(event.inputBuffer.getChannelData(0));
		};
	};

	encode = (arrayBuffer) => {
		const maxSamples = 1152;
		let samplesMono = this.convertBuffer(arrayBuffer);
		let remaining = samplesMono.length;
		for (let i = 0; remaining >= 0; i += maxSamples) {
			var left = samplesMono.subarray(i, i + maxSamples);
			var data = this.mp3encoder.encodeBuffer(left);
			this.mp3Data.push(data);
			remaining -= maxSamples;
		}
	};

	convertBuffer = (arrayBuffer) => {
		var data = new Float32Array(arrayBuffer);
		var out = new Int16Array(arrayBuffer.length);
		this.floatTo16BitPCM(data, out);
		return out;
	};

	floatTo16BitPCM = (input, output) => {
		for (var i = 0; i < input.length; i++) {
			var s = Math.max(-1, Math.min(1, input[i]));
			output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
		}
	};

	stop = (e) => {
		if (this.props.onStop) this.props.onStop(e);

		this.audioContext.close();
		this.processor.disconnect();
		this.tracks.forEach((track) => track.stop());
		let mp3buf = this.mp3encoder.flush();

		if (mp3buf.length > 0) {
			this.mp3Data.push(mp3buf);
		}

		let blob = new Blob(this.mp3Data, { type: "audio/mp3" });

		// edge workaround
		blob.lastModifiedDate = new Date();
		blob.lastModified = new Date();
		blob.name = "temp";

		// let file = new File(this.mp3Data, "name", { type: "audio/mp3" });
		let url = window.URL.createObjectURL(blob);

		this.setState({ recording: false, file: url });
		this.props.onChange(blob);
	};

	renderRecAndUpload = () => {
		const { t } = this.props;

		return !this.state.file ? (
			<div style={{ ...this.props.style }}>
				<Button.Group>
					<Button as="div" className="es-upload icon" labelPosition="left" basic color="grey">
						<Icon name="upload" />
						{t("uploadSingle")}
						<input className="es-upload--input" type="file" onChange={this.onUpload} />
						<div className="es-upload--drop" onDrop={this.onUpload}>
							{t("dropFile")}
						</div>
					</Button>

					{!this.state.recording ? (
						<Button
							labelPosition="right"
							icon="circle"
							content={t("record")}
							basic
							color="red"
							onClick={this.record}
						/>
					) : (
						<Button
							labelPosition="right"
							icon="square"
							content={t("stop")}
							basic
							color="blue"
							onClick={this.stop}
						/>
					)}
				</Button.Group>
			</div>
		) : (
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<a
					href="/"
					onClick={(e) => {
						e.preventDefault();
						this.setState({ recording: false, file: null });
						this.props.onChange(null);
					}}
				>
					<Icon name="remove" color="black" />
				</a>

				<audio src={this.state.file} controls style={{ verticalAlign: "middle" }} />
			</div>
		);
	};

	renderMiniRec = () =>
		!this.state.recording ? (
			<Button
				basic
				color="red"
				icon="circle"
				size="mini"
				disabled={!!this.props.disabled}
				onClick={this.record}
			/>
		) : (
			<Button basic color="blue" icon="square" size="mini" onClick={this.stop} />
		);

	render() {
		return (
			<div ref={(el) => (this.el = el)} style={{ display: "inline-block" }}>
				{this.props.miniRec ? this.renderMiniRec() : this.renderRecAndUpload()}
			</div>
		);
	}
}

export default withTranslation()(RecordButton);
