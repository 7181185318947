import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";
import { Header } from "semantic-ui-react";

import Accordion from "../../../../ui/components/Accordion/Accordion";
import AddReaderForm from "../../../../users/components/AddUser/AddReaderForm";

import Reader from "./Reader";

export default function AddReader({ production, selectedId, onClick }) {
	const { t } = useTranslation();
	const { indexUiState } = useInstantSearch();
	const { query } = indexUiState;

	const [reader, setReader] = useState(null);
	const [open, setOpen] = useState(false);

	if (!query || query?.length < 5) {
		return null;
	}

	if (reader) {
		return <Reader reader={reader} onClick={() => onClick(reader)} selected={reader.id === selectedId} />;
	}

	const afterSubmit = (user) => {
		const reader = {
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
		};

		setReader(reader);
		onClick(reader);
	};

	return (
		<Accordion.Title style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
			<Header as="h4" textAlign="center" style={{ width: "100%" }}>
				{t("didNotFindNarrator", "Couldn't find a suitable narrator?")}
				<Header.Subheader style={{ color: "#2185d0" }}>
					{t("addNewNarrator", "Add new narrator")}
				</Header.Subheader>
			</Header>

			{open && (
				<AddReaderForm
					language={production.language}
					firstName={query.split(" ")[0]}
					lastName={query.split(" ")[1]}
					afterSubmit={afterSubmit}
					onClose={() => setOpen(false)}
				/>
			)}
		</Accordion.Title>
	);
}
