import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminStudiosView from "../views/AdminStudiosView/AdminStudiosView";

export default function AdminStudios({ gen }) {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("studios.view")) {
		return <Unauthorized />;
	}

	return <AdminStudiosView gen={gen} organization={organization} />;
}
