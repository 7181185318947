import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

export default function ConfirmationModal({ text, onConfirm, onClose }) {
	const { t } = useTranslation();

	return (
		<Modal open size="tiny" closeOnDimmerClick onClose={onClose}>
			<Modal.Content>
				{text.split("\n").map((row, index) => (
					<p key={index}>{row}</p>
				))}
			</Modal.Content>

			<Modal.Actions>
				<Button content={t("negative", "No")} onClick={onClose} />

				<Button
					primary
					content={t("yes", "Yes")}
					onClick={async (e) => {
						await onConfirm(e);
						onClose();
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
}
