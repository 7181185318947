import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

export default function RecorderPriceSelect({ options }) {
	const { t } = useTranslation();

	const { setValue } = Form.useFormContext();

	return (
		<Form.Select
			name="recorderPriceId"
			label={t("price", "Price")}
			options={options}
			rules={{ required: false }}
			onChangeOption={(option) => {
				if (!option) {
					// means no match and the price should be removed.
					setValue("recorderPriceId", null);
					setValue("price", null);
					setValue("agreement", null);
				} else {
					setValue("price", option.data.price);
					setValue("agreement", option.data.agreement);
				}
			}}
		/>
	);
}
