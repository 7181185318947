import { useOutletContext } from "react-router-dom";

import SidebarLayout from "../../../ui/layouts/SidebarLayout/SidebarLayout";

import PublisherAdminSidebar from "./PublisherAdminSidebar";

export default function PublisherAdminLayout() {
	const context = useOutletContext();

	return <SidebarLayout context={context} sidebar={<PublisherAdminSidebar />} />;
}
