import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";

export default function useArticleTypeTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[articleTypes.EBOOK]: t("eBook", "E-book"),
			[articleTypes.AUDIOBOOK]: t("audioBook", "Audiobook"),
			[articleTypes.PODCAST]: t("podcast", "Podcast"),
		}),
		[t],
	);
}
