import taskStatuses from "../constants/taskStatuses";

export default function getUncompletedTasksQuery(firebase) {
	return firebase
		.firestore()
		.collection("tasks/queue/tasks")
		.where("status", "not-in", [
			taskStatuses.COMPLETED,
			taskStatuses.CANCELLED,
			taskStatuses.SCHEDULED,
			taskStatuses.IGNORED,
		]);
}
