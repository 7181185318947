export function getItem(key) {
	const value = window.localStorage.getItem(key);

	return value ? JSON.parse(value) : null;
}

export function setItem(key, value) {
	return window.localStorage.setItem(key, JSON.stringify(value));
}

export function removeItem(key) {
	return window.localStorage.removeItem(key);
}
