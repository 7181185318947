import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useAdminDistributionMenuOptions({ distribution }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	return [
		{
			to: "",
			active: pathname.endsWith(distribution.id),
			text: t("pipeline", "Pipeline"),
		},
		// {
		// 	to: "artifacts",
		// 	active: pathname.endsWith("/artifacts"),
		// 	text: t("artifacts", "Artifacts"),
		// 	label: data.artifactIds?.length,
		// },
	];
}
