export default function getSessionTimestamps({ date, startTime, endTime }) {
	const start = new Date(date);
	start.setHours(startTime?.getHours());
	start.setMinutes(startTime?.getMinutes());
	const end = new Date(date);
	end.setHours(endTime?.getHours());
	end.setMinutes(endTime?.getMinutes());

	return {
		start,
		end,
	};
}
