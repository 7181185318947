import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

const offerStatusColors = {
	[offerStatuses.DRAFT]: "orange",
	[offerStatuses.OFFERED]: "blue",
	[offerStatuses.DECLINED]: "red",
	[offerStatuses.APPROVAL_DENIED]: "red",
	[offerStatuses.ACCEPTED]: "green",
	[offerStatuses.PENDING_APPROVAL]: "grey",
	[offerStatuses.PENDING_SIGNING]: "grey",
	[offerStatuses.SIGNING]: "blue",
	[offerStatuses.CANCELLED]: "black",
	[offerStatuses.EXPIRED]: "black",
	[offerStatuses.INTERESTED]: "yellow",
	[offerStatuses.EXPIRE_FAILED]: "black",
};

export default offerStatusColors;
