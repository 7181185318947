import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import ProductionPriceForm from "../../../../productions/components/ProductionPriceForm/ProductionPriceForm";

export default function EditCostDropdownItem({ openCost, production, ...props }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const price = useMemo(() => {
		const member = Object.values(production?.team || {}).find(({ user }) => user.id === openCost.secondParty?.id);

		return {
			identifier: openCost.price?.id,
			memberId: member ? member.id : null,
			name: openCost.price?.name || "",
			type: openCost.price?.type || null,
			unit: openCost.price?.unit || null,
			currency: openCost.price?.currency || null,
			vat: openCost.price?.vat || null,
			price: openCost.price?.price || null,
			amount: openCost.price?.amount || 0,
		};
	}, [openCost, production?.team]);

	return (
		<>
			<Dropdown.Item text={t("edit", "Edit")} onClick={() => setOpen(true)} {...props} />

			{open && (
				<ProductionPriceForm
					type={openCost.expense ? "expense" : "revenue"}
					price={price}
					production={production}
					firstPartyId={openCost.firstParty.id}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
}
