import { firebase } from "astrid-firebase";
import { isLive } from "astrid-helpers/src/env";

function getRoute() {
	// return "http://localhost:5001/stage-earselect/us-central1";
	return isLive
		? "https://us-central1-earselect.cloudfunctions.net"
		: "https://us-central1-stage-earselect.cloudfunctions.net";
}

export default function previewPdf(request) {
	return firebase
		.auth()
		.currentUser.getIdToken()
		.then((token) => {
			return fetch(`${getRoute()}/scrive-previewAgreementPdf`, {
				method: "POST",
				headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
				body: JSON.stringify(request),
			})
				.then((response) => {
					if (response.ok) {
						return response.blob();
					} else {
						throw new Error("Failed to fetch preview of agreement");
					}
				})
				.then((response) => {
					const newBlob = new Blob([response], { type: "application/pdf" });
					return URL.createObjectURL(newBlob);
				});
		});
}
