import timeToMs from "astrid-helpers/src/timeToMs";

function msToMinutes(ms) {
	if (!ms) {
		return 0;
	}

	return ms / 1000 / 60;
}

export function getDeliveryMinutes(production) {
	return msToMinutes(production?.deliveryDuration ? timeToMs(production.deliveryDuration) : production.render?.time);
}

export function getEstimatedDurationInMinutes(production) {
	return msToMinutes(production?.estimatedDurationInMilliseconds);
}

export function getProgressTime(production, type) {
	return msToMinutes(production.progress?.[type]?.time) || 0;
}

export function getProgressAmount(production, type, userId) {
	return msToMinutes(production.progress?.[type]?.amount?.[userId]) || 0;
}
