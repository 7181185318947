import db from "astrid-firebase/src/db";
import { mapCostFromPrice } from "astrid-firestore/src/api/costs/utils/costMappers";
import { getOpenExpenses } from "astrid-firestore/src/api/costs/utils/openCosts";
import { getCollectionData, getDocumentData } from "astrid-firestore/src/helpers";

export async function getTeamMemberOpenCost({ member, production }) {
	const price = await getDocumentData(member.price?.ref);

	if (price && !price?.deleted) {
		const openCosts = [mapCostFromPrice(price)];

		const [costs, users, exchangeRates] = await Promise.all([
			getCollectionData(
				db.collection("costs").where("deleted", "==", false).where("production.id", "==", production.id),
			),
			getCollectionData(production.ref.collection("users")),
			getCollectionData(db.collection("exchangeRates").orderBy("timestamp", "desc").limit(1)),
		]);

		const [expense] = getOpenExpenses({ exchangeRate: exchangeRates?.[0], openCosts, production, users, costs });

		return expense;
	}
}
