import studioGens from "astrid-firestore/src/api/studios/constants/studioGens";

import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import RestartStudioDropdownItem from "./items/RestartStudioDropdownItem";

export default function StudioDropdownMenu({ studio }) {
	return (
		<DropdownMenu style={{ marginLeft: "auto" }}>
			{({ setLoading }) => (
				<>
					{studio.get === studioGens.TWO && (
						<RestartStudioDropdownItem studio={studio} setLoading={setLoading} />
					)}
					{/*<UpdateStudioIdDropdownItem studio={studio} setLoading={setLoading} />*/}
				</>
			)}
		</DropdownMenu>
	);
}
