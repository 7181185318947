import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import useHasRight from "../../../../authorization/hooks/useHasRight";

import { getAgreementAdminURL } from "../../../utils/agreementsHelper";

export default function ViewAgreementDropdownItem({ agreement }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	if (!hasRight("agreements.view")) {
		return null;
	}

	return (
		<Dropdown.Item as={agreement ? Link : null} to={getAgreementAdminURL(agreement)} disabled={!agreement}>
			{t("viewAgreement", "View agreement")}
		</Dropdown.Item>
	);
}
