import debounce from "lodash/debounce";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Form, Header, Icon, Segment } from "semantic-ui-react";

import { base, firebase } from "astrid-firebase";
import { codes } from "astrid-helpers/src/currencies";

import UploadButton from "../UploadButton";

class PublisherCurrencies extends Component {
	state = {
		currencyReaderSettings: this.props.publisher.currencyReaderSettings || {},
	};

	addCurrency = (e, data) => {
		base.updateDoc("organizations/" + this.props.pid, {
			currencies: firebase.firestore.FieldValue.arrayUnion(data.value),
		}).then(() => {});
	};

	removeCurrency = (value) => {
		base.updateDoc("organizations/" + this.props.pid, {
			currencies: firebase.firestore.FieldValue.arrayRemove(value),
		}).then(() => {});
	};

	updateFirestore = debounce((ref, data) => {
		base.updateDoc(ref, data).then(() => {
			// console.log("updated");
		});
	}, 400);

	handleCurrencySetting = (code, name, value) => {
		const newData = {
			...this.state.currencyReaderSettings,
			[code]: {
				...(this.state.currencyReaderSettings[code] || {}),
				[name]: value,
			},
		};
		// store in state
		this.setState({
			currencyReaderSettings: newData,
		});

		// store in database
		this.updateFirestore("organizations/" + this.props.pid, {
			currencyReaderSettings: newData,
		});
	};

	uploadContract = (e, data, code) => {
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-secret" : "gs://earselect-secret");

		// file from input
		const file = e.target.files[0];

		if (!file) return;

		const suffix = file.name.split(".").pop();
		const { t } = this.props;

		if (suffix.toLowerCase() !== "pdf") {
			this.setState({ uploadContract: t("notAPdf") });
			return;
		}

		// start upload
		const uploadTask = storageRef
			.child(`contracts/publisher/${this.props.pid}/narrator-contract-${code}.${suffix}`)
			.put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ uploadContract: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.handleCurrencySetting(code, "readerContract", downloadURL);
					this.setState({ uploadContract: null });
				});
			},
		);
	};

	render() {
		const { currencyReaderSettings, uploadContract } = this.state;
		const { t, publisher } = this.props;

		return (
			<Segment padded>
				<Header as="h4" icon="money" content={t("fee")} />
				<p>
					<em>{t("choosableCurrencies")}</em>
				</p>
				<Form>
					<Form.Group>
						<Form.Select
							search
							placeholder="EUR"
							options={codes
								.filter((code) => !publisher.currencies?.includes(code))
								.map((code) => ({
									key: code,
									text: code,
									value: code,
								}))}
							onChange={this.addCurrency}
						/>
					</Form.Group>
				</Form>
				<p>
					<b>{t("billingAddress")}</b>
				</p>
				{publisher.currencies && publisher.currencies.length ? (
					publisher.currencies.map((code) => (
						<div
							key={code}
							style={{
								display: "inline-block",
								background: "#eee",
								padding: 10,
								margin: "0 10px 10px 0",
								width: "100%",
								maxWidth: 250,
								verticalAlign: "top",
							}}
						>
							<Form>
								<Icon
									name="delete"
									className="readerfee-remove pointer"
									onClick={() => {
										this.removeCurrency(code);
									}}
								/>
								<b>{code}</b>
								<Form.TextArea
									label={t("billAddress")}
									placeholder={t("addressAndEmail")}
									name="address"
									value={currencyReaderSettings[code] ? currencyReaderSettings[code].address : ""}
									onChange={(e, data) => {
										this.handleCurrencySetting(code, "address", data.value);
									}}
								/>
								<Form.TextArea
									label={t("instructions")}
									placeholder={t("paymentRequests")}
									name="info"
									value={currencyReaderSettings[code] ? currencyReaderSettings[code].info : ""}
									onChange={(e, data) => {
										this.handleCurrencySetting(code, "info", data.value);
									}}
								/>

								<UploadButton
									text={t("uploadReaderContract")}
									onUpload={(e, data) => {
										this.uploadContract(e, data, code);
									}}
								/>

								{currencyReaderSettings[code]?.readerContract && (
									<a href={currencyReaderSettings[code]?.readerContract}>{t("readerContract")}</a>
								)}
							</Form>
						</div>
					))
				) : (
					<em>{t("chooseCurrAbove")}</em>
				)}

				{uploadContract && (
					<p>
						{t("uploadReaderContract")} <em>{uploadContract}</em>
					</p>
				)}
			</Segment>
		);
	}
}

export default withTranslation()(PublisherCurrencies);
