import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Header, Icon, Modal, Segment, Table } from "semantic-ui-react";

import { hasRight } from "astrid-firebase";

import { withStore } from "../../helpers/context";
import { toDate } from "../../helpers/fnc";
import { prodStatus } from "../../helpers/lists";
import withMatch from "../../hocs/withMatch";
import withUserProfile from "../../hocs/withUserProfile";

import Right from "../Right";
import AvailabilityModal from "../calendar/AvailabilityModal";
import ReaderCampaign from "../home/ReaderCampaign";
import ProgressEdit from "../production/ProgressEdit";
import ProgressRecording from "../production/ProgressRecording";

import HomeAdmin from "./HomeAdmin";
import HomeAlerts from "./HomeAlerts";
import HomeStarred from "./HomeStarred";
import HomeSubcontractorOffers from "./HomeSubcontractorOffers";

class HomeProducer extends Component {
	state = {
		loading: true,
		search: "",
		searchResult: [],
		adding: false,
		modal: null,
	};

	componentDidMount() {
		/*
		if (!this.props.store.state.productions) {
			this.setState({ loading: true });
		}
*/
		const startPageStatuses = ["offer", "planning", "accepted", "production"];

		// Disabled because of performance issues (unclear consequenses)
		/*
		// also get done prods for readers (so that they get active status)
		if (get(this.props.profile, "permissions.reader")) {
			startPageStatuses.push("done");
		}*/

		this.props.store.getProductions({ filterStatus: startPageStatuses, adminAlerts: true }).then((org) => {
			this.setState({ loading: false });
		});
	}

	modalClose = () => {
		this.setState({ modal: null });
	};

	render() {
		const { modal } = this.state;
		const { t, user, profile, store } = this.props;
		const limitedUser = profile.permissions?.producer?.[store.state.producerId]?.includes("producerLimited");
		let productions = Object.values(cloneDeep(store.state.productions || {}));

		productions.forEach((prod) => {
			// call the status "booked" until first session is logged
			if (
				prod.status === "production" &&
				!prod.stats &&
				!prod.proofParts &&
				prod.productionType !== "subcontract"
			)
				prod.status = "booked";

			// call the status "mastring" when polish is done
			if (prod.status === "production" && prod.polishDone && prod.productionType !== "subcontract")
				prod.status = "mastering";
		});

		productions = sortBy(productions, "productionDate");

		return (
			<Segment loading={this.state.loading} padded>
				{/*hasRight(store, "availability.standardTime") && (
					<Button
						color="orange"
						content={t("enterYourAvailability")}
						icon="calendar alternate outline"
						onClick={() => {
							this.setState({ modal: "availability" });
						}}
					/>
				)*/}
				{(!get(profile, "userData.genre") || !get(profile, "languages")) &&
					!get(profile, "permissions.reader") && (
						<Link to={"/profile/"}>
							<Button
								primary
								content={t("completeYourProfile")}
								icon="right arrow"
								labelPosition="right"
							/>
						</Link>
					)}
				{hasRight(store, "startPage.listAdminAlerts") && <HomeAdmin productions={productions} />}
				<HomeAlerts
					productions={productions}
					prodStatus={prodStatus}
					profile={profile}
					uid={this.props.user.uid}
					alertProductions={profile.alertProductions}
				/>
				{profile.starredProductions && profile.starredProductions.length > 0 && <HomeStarred />}

				{profile.permissions?.producer?.[store.state?.producerId]?.includes("producerSubcontractor") && (
					<HomeSubcontractorOffers productions={productions} uid={user.uid} />
				)}

				{/*profile.permissions && profile.permissions.reader && (
					<Button
						color="orange"
						content={t("enterYourAvailability")}
						icon="calendar alternate outline"
						onClick={() => {
							this.setState({ modal: "availability" });
						}}
					/>
				)*/}

				{modal && (
					<Modal size="small" open={true} onClose={this.modalClose} closeOnDocumentClick>
						{modal === "availability" && <AvailabilityModal store={store} modalClose={this.modalClose} />}
					</Modal>
				)}

				{profile.permissions &&
					profile.permissions.reader &&
					(!profile.readerData || !profile.readerData.sex || !profile.readerData.language) && (
						<Link to={"/profile/"}>
							<Button
								primary
								content={t("enterMoreInfoInProfile")}
								icon="right arrow"
								labelPosition="right"
							/>
						</Link>
					)}

				<Right can="listProducerProductions">
					<Header as="h4" icon="user" content={t("yourProductions")} />
					<Table compact basic="very" sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{t("titleProdProd")}</Table.HeaderCell>
								<Table.HeaderCell colSpan={2}>{t("statusProdProd")}</Table.HeaderCell>
								<Table.HeaderCell>{t("prodDateProdProd")}</Table.HeaderCell>
								{hasRight(store, "startPage.listAdminAlerts") && (
									<Table.HeaderCell>{t("deliveryDateProdProd")}</Table.HeaderCell>
								)}
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{this.props.user &&
								productions
									.filter(
										(prod) =>
											prod.status !== "done" &&
											prod.status !== "draft" &&
											((prod.manager && prod.manager.includes(this.props.user.uid)) ||
												(prod.proofer && prod.proofer.includes(this.props.user.uid)) ||
												(prod.editor && prod.editor.includes(this.props.user.uid)) ||
												(prod.productionType === "subcontract" &&
													prod.subcontractor === this.props.user.uid &&
													prod.subcontractorStatus === "accepted") ||
												(prod.reader &&
													prod.reader.includes(this.props.user.uid) &&
													(!prod.readerStatus ||
														!prod.readerStatus[this.props.user.uid] ||
														prod.readerStatus[this.props.user.uid].status === "accepted"))),
									)
									.map((prod) => {
										const date = prod.productionDate;
										const isReader = prod.reader && prod.reader.includes(this.props.user.uid);

										return (
											<Table.Row key={prod.id}>
												<Table.Cell selectable>
													<Link to={"/production/" + prod.id}>{prod.title}</Link>
												</Table.Cell>
												<Table.Cell collapsing>
													<Icon
														name={prodStatus[prod.status].icon}
														color={prodStatus[prod.status].color}
													/>
													{/* {prodStatus[prod.status].text} */}
													{t("productions:" + prod.status)}
												</Table.Cell>
												<Table.Cell style={{ width: "35%" }}>
													{prod.stats && (
														<ProgressRecording size={"small"} production={prod} />
													)}
													{!isReader && <ProgressEdit size={"small"} production={prod} />}
												</Table.Cell>

												<Table.Cell collapsing textAlign="right">
													{date && moment(date.toDate()).format("YYYY-MM-DD")}
												</Table.Cell>
												{hasRight(store, "startPage.listAdminAlerts") && (
													<Table.Cell collapsing textAlign="right">
														{prod.deliveryDate &&
															moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
													</Table.Cell>
												)}
											</Table.Row>
										);
									})}
						</Table.Body>
					</Table>
				</Right>

				<Right can="readerRegistry.campaign">
					<ReaderCampaign />
				</Right>

				{hasRight(store, "startPage.listProductionsMissingProofer") &&
					!hasRight(store, "startPage.listAdminAlerts") &&
					!limitedUser && (
						<>
							<Header as="h4" icon="headphones" content={t("prodNoProofer")} />
							<Table compact basic="very" sortable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{t("titleProofer")}</Table.HeaderCell>
										<Table.HeaderCell>{t("statusProofer")}</Table.HeaderCell>
										<Table.HeaderCell>{t("prodDateProofer")}</Table.HeaderCell>
										{hasRight(store, "startPage.listAdminAlerts") && (
											<Table.HeaderCell>{t("deliveryDateProofer")}</Table.HeaderCell>
										)}
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{productions
										.filter(
											(prod) =>
												prod.status !== "done" &&
												prod.status !== "draft" &&
												prod.status !== "planning" &&
												prod.status !== "offer" &&
												!["backlist", "external"].includes(prod.productionType) &&
												!prod.proofer &&
												(!prod.prooferPrio ||
													get(profile, "permissions.producer." + prod.producer).includes(
														"producerProoferPrio",
													)) &&
												(!prod.language ||
													get(profile, "languages." + prod.language + ".skill") > 3), // lang skills 4-5
										)
										.map((prod) => {
											const date = prod.productionDate;

											return (
												<Table.Row key={prod.id}>
													<Table.Cell selectable>
														<Link to={"/production/" + prod.id}>{prod.title}</Link>
													</Table.Cell>
													<Table.Cell>
														<Icon
															name={prodStatus[prod.status].icon}
															color={prodStatus[prod.status].color}
														/>
														{/* {prodStatus[prod.status].text} */}
														{t("productions:" + prod.status)}
													</Table.Cell>
													<Table.Cell collapsing textAlign="right">
														{date &&
															(prod.productionDate
																? moment(date.toDate()).format("YYYY-MM-DD")
																: "")}
													</Table.Cell>
													{hasRight(store, "startPage.listAdminAlerts") && (
														<Table.Cell collapsing textAlign="right">
															{prod.deliveryDate &&
																moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
														</Table.Cell>
													)}
												</Table.Row>
											);
										})}
								</Table.Body>
							</Table>
						</>
					)}

				{hasRight(store, "startPage.listProductionsMissingRecorder") &&
					!hasRight(store, "startPage.listAdminAlerts") &&
					!limitedUser && (
						<>
							<Header as="h4" icon="microphone" content={t("missingRecorderNextWeek")} />
							<Table compact basic="very" sortable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{t("titleRecorder")}</Table.HeaderCell>
										<Table.HeaderCell>{t("prodDateRecorder")}</Table.HeaderCell>
										{hasRight(store, "startPage.listAdminAlerts") && (
											<Table.HeaderCell>{t("deliveryDateRecorder")}</Table.HeaderCell>
										)}
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{productions
										.filter((prod) => {
											const inProduction =
												prod.status !== "done" &&
												prod.status !== "draft" &&
												prod.status !== "offer";

											const missingRecorder =
												prod.bookedSessionMissingRecorder &&
												toDate(prod.bookedSessionMissingRecorder) > new Date() &&
												moment(toDate(prod.bookedSessionMissingRecorder)).diff(
													moment(),
													"days",
												) < 7;

											const missingRecorderPrio =
												(get(profile, "permissions.producer." + prod.producer, []).includes(
													"producerRecorderPrio",
												) ||
													!get(profile, "permissions.producer." + prod.producer, []).includes(
														"producerRecorder",
													)) &&
												prod.bookedSessionMissingRecorderPrio &&
												toDate(prod.bookedSessionMissingRecorderPrio) > new Date() &&
												moment(toDate(prod.bookedSessionMissingRecorderPrio)).diff(
													moment(),
													"days",
												) < 7;

											return inProduction && (missingRecorder || missingRecorderPrio);
										})
										.map((prod) => {
											const date = prod.productionDate || prod.deliveryDate;

											return (
												<Table.Row key={prod.id}>
													<Table.Cell selectable>
														<Link to={"/production/" + prod.id}>{prod.title}</Link>
													</Table.Cell>

													<Table.Cell collapsing textAlign="right">
														{date &&
															(prod.productionDate
																? moment(date.toDate()).format("YYYY-MM-DD")
																: "")}
													</Table.Cell>
													{hasRight(store, "startPage.listAdminAlerts") && (
														<Table.Cell collapsing textAlign="right">
															{prod.deliveryDate &&
																moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
														</Table.Cell>
													)}
												</Table.Row>
											);
										})}
								</Table.Body>
							</Table>
						</>
					)}

				{hasRight(store, "startPage.listProductionsMissingEditor") &&
					!hasRight(store, "startPage.listAdminAlerts") &&
					!limitedUser && (
						<>
							<Header as="h4" icon="hand scissors" content={t("prodsNoEditor")} />
							<Table compact basic="very" sortable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{t("titleEditor")}</Table.HeaderCell>
										<Table.HeaderCell>{t("prodDateEditor")}</Table.HeaderCell>
										{hasRight(store, "startPage.listAdminAlerts") && (
											<Table.HeaderCell>{t("deliveryDateEditor")}</Table.HeaderCell>
										)}
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{productions
										.filter(
											(prod) =>
												prod.status !== "done" &&
												prod.status !== "draft" &&
												prod.status !== "offer" &&
												prod.status !== "planning" &&
												!["backlist", "external", "subcontract"].includes(
													prod.productionType,
												) &&
												(!prod.editorPrio ||
													get(profile, "permissions.producer." + prod.producer).includes(
														"producerEditorPrio",
													)) &&
												!prod.editor,
										)
										.map((prod) => {
											const date = prod.productionDate || prod.deliveryDate;

											return (
												<Table.Row key={prod.id}>
													<Table.Cell selectable>
														<Link to={"/production/" + prod.id}>{prod.title}</Link>
													</Table.Cell>

													<Table.Cell collapsing textAlign="right">
														{date &&
															(prod.productionDate
																? moment(date.toDate()).format("YYYY-MM-DD")
																: "")}
													</Table.Cell>
													{hasRight(store, "startPage.listAdminAlerts") && (
														<Table.Cell collapsing textAlign="right">
															{prod.deliveryDate &&
																moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
														</Table.Cell>
													)}
												</Table.Row>
											);
										})}
								</Table.Body>
							</Table>
						</>
					)}

				{hasRight(store, "startPage.listUpcomingDeliveries") && !limitedUser && (
					<>
						<Header as="h4" icon="truck" content={t("commingDeliveries")} />
						<Table compact basic="very" sortable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t("titleDel")}</Table.HeaderCell>
									<Table.HeaderCell colSpan={2}>{t("statusDel")}</Table.HeaderCell>
									<Table.HeaderCell>{t("prodDateDel")}</Table.HeaderCell>
									{hasRight(store, "startPage.listAdminAlerts") && (
										<Table.HeaderCell>{t("delDateDel")}</Table.HeaderCell>
									)}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{productions
									.filter((prod) => {
										return (
											(prod.status === "production" ||
												prod.status === "planning" ||
												prod.status === "mastering") &&
											prod.status !== "draft" &&
											prod.stats &&
											prod.deliveryDate
										);
									})
									.slice(0, 10)
									.map((prod) => {
										// use production date if available
										const date = prod.productionDate || prod.deliveryDate;

										return (
											<Table.Row key={prod.id}>
												<Table.Cell selectable>
													<Link to={"/production/" + prod.id}>{prod.title}</Link>
												</Table.Cell>
												<Table.Cell collapsing>
													<Icon
														name={prodStatus[prod.status].icon}
														color={prodStatus[prod.status].color}
													/>
													{/* {prodStatus[prod.status].text} */}
													{t("productions:" + prod.status)}
												</Table.Cell>
												<Table.Cell style={{ width: "35%" }}>
													{prod.stats && <ProgressRecording size="small" production={prod} />}
													{prod.proofParts && <ProgressEdit size="small" production={prod} />}
												</Table.Cell>
												<Table.Cell collapsing textAlign="right">
													{date &&
														(prod.productionDate
															? moment(date.toDate()).format("YYYY-MM-DD")
															: "")}
												</Table.Cell>
												{hasRight(store, "startPage.listAdminAlerts") && (
													<Table.Cell collapsing textAlign="right">
														{prod.deliveryDate &&
															moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
													</Table.Cell>
												)}
											</Table.Row>
										);
									})}
							</Table.Body>
						</Table>
					</>
				)}
			</Segment>
		);
	}
}

export default withTranslation()(withStore(withMatch(withUserProfile(HomeProducer))));
