import React from "react";
import { useTranslation } from "react-i18next";

import TasksAccordion from "../../../tasks/components/TasksAccordion/TasksAccordion";

export default function BiblioTasksView({ tasks }) {
	const { t } = useTranslation();

	return (
		<TasksAccordion
			tasks={tasks}
			getData={({ data, deliveries, production }) => ({
				data,
				deliveries,
				production: {
					id: production?.id,
					title: production?.title,
					producer: production?.producer,
					publisher: production?.publisher,
				},
			})}
			getLink={(task) =>
				task.production?.id && {
					to: `/production/${task.production.id}`,
					text: t("toProduction", "To production"),
				}
			}
		/>
	);
}
