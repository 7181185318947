import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminUserView from "../views/AdminUserView/AdminUserView";

export default function AdminPublisherUser() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("users.view")) {
		return <Unauthorized />;
	}

	return <AdminUserView id={id} organization={organization} />;
}
