import { useMemo } from "react";

import todoPriorities from "astrid-firestore/src/api/todos/constants/todoPriorities";
import { useCollectionData, useLoadingValues } from "astrid-firestore/src/hooks";
import { useProfile } from "astrid-web/src/features/authentication/state/profile";
import useHasRight from "astrid-web/src/features/authorization/hooks/useHasRight";

import useLanguageOptions from "../../users/hooks/useLanguageOptions";

import useTodoHeaderTranslations from "./useTodoHeaderTranslations";
import useTodosQuery from "./useTodosQuery";

function filterType(type) {
	return (todo) => !type || todo.type === type;
}

function filterPriority(priority) {
	return (todo) => !priority || todo.priority === priority;
}

function filterAssigned(userId) {
	return (todo) => !userId || (userId === "notAssigned" ? todo.userIds.length === 0 : todo.userIds.includes(userId));
}

export default function useTodos(
	{ type = null, order = "desc", showResolved = false, userId = null, priority = null, organization = null },
	initialTodos = [],
) {
	const user = useProfile();
	const hasRight = useHasRight();

	const languageOptions = useLanguageOptions({ organization });
	const enabledTodos = useTodoHeaderTranslations();

	const [[unresolvedTodos = initialTodos, resolvedTodos = initialTodos], loading, error] = useLoadingValues(
		useCollectionData(
			useTodosQuery({
				userId: user.id,
				organizationId: hasRight("organization.todos.view") && organization?.id,
			}),
		),
		useCollectionData(
			useTodosQuery({
				userId: user.id,
				organizationId: hasRight("organization.todos.view") && organization?.id,
				resolved: true,
			}),
		),
	);

	const todos = useMemo(() => {
		return [...unresolvedTodos, ...resolvedTodos].sort((a, b) =>
			a.createdAt.toDate() < b.createdAt.toDate() ? 1 : -1,
		);
	}, [unresolvedTodos, resolvedTodos]);

	const sortedTodos = useMemo(() => {
		return (order === "desc" ? todos : todos.slice().reverse())
			.filter(
				(todo) =>
					!!enabledTodos[todo.type] &&
					(!languageOptions?.length ||
						!todo.languages ||
						todo.languages?.some((language) => languageOptions.includes(language))),
			)
			.sort((a, b) => ((a.priority || todoPriorities.LOW) < (b.priority || todoPriorities.LOW) ? -1 : 1))
			.filter((todo) => showResolved || !todo.resolved?.status);
	}, [enabledTodos, languageOptions, order, showResolved, todos]);

	const filteredTodos = useMemo(() => {
		return sortedTodos.filter(filterType(type)).filter(filterPriority(priority)).filter(filterAssigned(userId));
	}, [priority, sortedTodos, type, userId]);

	return { sortedTodos, filteredTodos, loading, error };
}
