import React from "react";
import { useTranslation } from "react-i18next";
import { Segment, Statistic } from "semantic-ui-react";

import money from "astrid-helpers/src/money";
import useTotalCost from "astrid-web/src/features/costs/hooks/useTotalCost";
import { getCostColor } from "astrid-web/src/features/costs/utils/costColors";

export default function CostBalance({ costs, openCosts }) {
	const { t } = useTranslation();

	const allCosts = [...costs, ...openCosts];

	const allExpenses = allCosts.filter(({ expense }) => expense);
	const allRevenues = allCosts.filter(({ expense }) => !expense);

	const [totalExpenses, totalExpensesCurrency] = useTotalCost(allExpenses);
	const [totalRevenues, totalRevenuesCurrency] = useTotalCost(allRevenues);

	const totalProfit = totalRevenues + totalExpenses;
	const grossProfit = (totalProfit / -totalExpenses) * 100;
	const grossMargin = (100 * totalProfit) / totalRevenues;

	return (
		<Segment padded>
			<Statistic.Group size="mini" widths="5" style={{ fontSize: 12 }}>
				<Statistic>
					<Statistic.Label>{t("expenses", "Expenses")}</Statistic.Label>
					<Statistic.Value>
						<div style={{ fontSize: 18 }}>{money(totalExpenses, totalExpensesCurrency)}</div>
					</Statistic.Value>
				</Statistic>

				<Statistic>
					<Statistic.Label>{t("revenues", "Revenues")}</Statistic.Label>
					<Statistic.Value>
						<div style={{ fontSize: 18 }}>{money(totalRevenues, totalRevenuesCurrency)}</div>
					</Statistic.Value>
				</Statistic>

				<Statistic color={getCostColor(totalProfit)}>
					<Statistic.Label>{t("balance", "Balance")}</Statistic.Label>
					<Statistic.Value>
						<div style={{ fontSize: 18 }}>{money(totalProfit, totalRevenuesCurrency)}</div>
					</Statistic.Value>
				</Statistic>

				<Statistic color={getCostColor(grossProfit)}>
					<Statistic.Label>{t("grossProfit", "Gross profit")}</Statistic.Label>
					<Statistic.Value>
						<div style={{ fontSize: 18 }}>{money(grossProfit)} %</div>
					</Statistic.Value>
				</Statistic>

				<Statistic color={getCostColor(grossMargin)}>
					<Statistic.Label>{t("grossMargin", "Gross margin")}</Statistic.Label>
					<Statistic.Value>
						<div style={{ fontSize: 18 }}>{money(grossMargin)} %</div>
					</Statistic.Value>
				</Statistic>
			</Statistic.Group>
		</Segment>
	);
}
