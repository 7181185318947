export const storytelPublishers = [
	{ id: "lIHTmCbJGCNjHfvDXgzo", name: "Barnbolaget", filter: "production.publisher:lIHTmCbJGCNjHfvDXgzo" },
	{ id: "UZ5tZfqwNIzXB5aeNZap", name: "Kitab Sawti", filter: "production.publisher:UZ5tZfqwNIzXB5aeNZap" },
	{ id: "iP0GjiQjrA3cTJE4g1Hi", name: "Rubinstein Audio", filter: "production.publisher:iP0GjiQjrA3cTJE4g1Hi" },
	{ id: "FJFODA31CaYsTaVwDoe6", name: "Rugram", filter: "production.publisher:FJFODA31CaYsTaVwDoe6" },
	{ id: "bhx1EXClLYIXerZOBiAo", name: "Storyside", filter: "production.publisher:bhx1EXClLYIXerZOBiAo" },
	{ id: "xLyLfyl0FlpmA4aperBO", name: "Storyside para Planeta", filter: "production.publisher:xLyLfyl0FlpmA4aperBO" },
	{ id: "ruABAUe7e3vwR3C36hmF", name: "Storytel Dox", filter: "production.publisher:ruABAUe7e3vwR3C36hmF" },
	{ id: "T4mo7AkxOVNqlpj3GbdP", name: "Storytel Original", filter: "production.publisher:T4mo7AkxOVNqlpj3GbdP" },
	{ id: "ozg1MvOnQhVF23unAApF", name: "Storytel Original NL", filter: "production.publisher:ozg1MvOnQhVF23unAApF" },
	{ id: "4HMSow3MiUKSAouR93rD", name: "Storytel Publishing NL", filter: "production.publisher:4HMSow3MiUKSAouR93rD" },
];
