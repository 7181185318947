const todoTypes = {
	READER_INQUIRY_STATUS_CHANGED: "reader_inquiry_status_changed",
	READER_INQUIRY_DRAFT: "reader_inquiry_draft",
	OFFER_STATUS_CHANGED: "offer_status_changed",
	SCRIPT_MISSING: "script_missing",
	LAST_COMMENT_PUBLISHER: "last_comment_publisher",
	PRODUCTION_STATUS_IS_OFFER: "production_status_is_offer",
	MISSING_SESSIONS: "missing_sessions",
	MISSING_POLISH_SESSION: "missing_polish_session",
	SESSION_MISSING_RECORDER: "session_missing_recorder",
	DELIVERY_DATE_TODAY: "delivery_date_today",
	MISSING_PROOFER: "missing_proofer",
	MISSING_EDITOR: "missing_editor",
	FILES_UPLOADED: "files_uploaded",
	SUBCONTRACTOR_UPLOADED_FILES: "subcontractor_uploaded_files",
	ADMIN_ATTENTION: "admin_attention",
	NEW_COMMENT_ON_OFFER: "new_comment_on_offer",
	PRODUCTION_DATE_MISSING: "production_date_missing",
	DELIVERY_DATE_MISSING: "delivery_date_missing",
};

export default todoTypes;
