import React from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import { Button, Message } from "semantic-ui-react";

import { useProfile } from "../../../features/authentication/state/profile";
import { useOrganizationId } from "../../../features/organizations/state/organizationId";
import { useOrganizationType } from "../../../features/organizations/state/organizationType";

export default function VendorMissing() {
	const { t } = useTranslation();

	const match = useMatch("/profile");
	const navigate = useNavigate();
	const profile = useProfile();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();

	if (orgType === "publisher" || match || profile?.vendors || profile?.producers?.[orgId]?.employee) {
		return false;
	}

	return (
		<Message
			color="yellow"
			icon="file outline alternate"
			header={t(
				"vendorMissingMessage",
				"Please fill in your invoicing details in order to get new offers from Astrid",
			)}
			content={
				<Button
					color="green"
					content={t("goToYourAccount", "Go to your account")}
					size="small"
					style={{ marginTop: "1em" }}
					onClick={() => navigate(`/account/${profile.id}/vendor-settings`)}
				/>
			}
		/>
	);
}
