import React, { useMemo } from "react";

import AgreementActiveStatusLabel from "../../../../agreements/components/AgreementActiveStatus/AgreementActiveStatusLabel";
import AgreementDropdownMenu from "../../../../agreements/components/AgreementDropdownMenu/AgreementDropdownMenu";
import AgreementHeader from "../../../../agreements/components/AgreementHeader/AgreementHeader";
import AgreementStatusLabel from "../../../../agreements/components/AgreementStatus/AgreementStatusLabel";

export default function useAccountAgreementsColumns() {
	return useMemo(
		() => [
			{
				id: "secondParty.name",
				flex: 1,
				Cell: ({ row }) => <AgreementHeader agreement={row.original} />,
			},
			{
				id: "expires",
				Cell: ({ row }) => <AgreementActiveStatusLabel agreement={row.original} />,
			},
			{
				id: "status",
				Cell: ({ row }) => <AgreementStatusLabel agreement={row.original} />,
			},
			{
				id: "menu",
				Cell: ({ row }) => <AgreementDropdownMenu agreement={row.original} />,
			},
		],
		[],
	);
}
