import React from "react";

import useDownloadURL from "astrid-firestore/src/hooks/useDownloadURL";

export default function InvoiceLink({ invoice }) {
	const { name, bucket, fullPath } = invoice?.file;
	const [url] = useDownloadURL({ bucket, fullPath });
	return (
		<a href={url} rel="noopener noreferrer">
			{name}
		</a>
	);
}
