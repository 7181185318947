import { useMemo } from "react";

import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import { formatNamedPrice } from "../../prices/utils/priceFormatters";
import useTeamMembersByRole from "../../team/hooks/useTeamMembersByRole";

export default function useRecorderPriceOptions({ production, openRecorderPositions, recorderId }) {
	const productionRecorders = useTeamMembersByRole({
		team: production?.team,
		role: teamRoles.RECORDER,
	});

	const selectedRecorderOption = productionRecorders?.find((recorder) => recorder.user?.id === recorderId);

	return useMemo(
		() =>
			[
				selectedRecorderOption && {
					key: selectedRecorderOption.id,
					value: selectedRecorderOption.id, // teamId
					data: {
						price: selectedRecorderOption.price,
						agreement: selectedRecorderOption.agreement,
					},
					defaultOption: true,
					text: selectedRecorderOption.price
						? formatNamedPrice(selectedRecorderOption.price)
						: "[Recorder has no price setup]",
				},
				...(openRecorderPositions
					?.filter((openPosition) => {
						if (selectedRecorderOption) {
							return openPosition.price !== "default";
						}
						return true;
					})
					.map((openPosition) => {
						return {
							key: `${sessionOfferTypes.OPEN_POSITION_RECORDER}-${openPosition.id}`,
							value: `${sessionOfferTypes.OPEN_POSITION_RECORDER}-${openPosition.id}`,
							data: {
								price: openPosition.price,
								agreement: selectedRecorderOption?.agreement,
							},
							text: `[${
								openPosition.price === "default"
									? openPosition.price
									: formatNamedPrice(openPosition.price)
							}]`,
						};
					}) || []),
			].filter((v) => v),
		[openRecorderPositions, selectedRecorderOption],
	);
}
