import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Header, Icon } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

export default function ProductionTags({ name = "productionTags", value, onChange, width, labelAsHeader, disable }) {
	const { t } = useTranslation();

	const [data, loading, error] = useDocumentDataOnce(
		useMemo(() => {
			return db.collection("settings").doc("tags");
		}, []),
		{ idField: "id" },
	);

	if (error) console.error(error);

	return (
		<>
			<Form.Select
				name={name}
				disabled={disable}
				loading={loading}
				label={
					labelAsHeader ? (
						<Header as="h4">
							<Icon.Group size="big">
								<Icon name="tags" />
							</Icon.Group>
							<Header.Content>{t("productionTags", "Production tags")}</Header.Content>
						</Header>
					) : (
						t("productionTags", "Production tags")
					)
				}
				multiple
				placeholder={t("selectProductionTags", "select production tags")}
				value={value}
				options={
					data ? data?.production.map((tag) => ({ key: tag, value: tag, text: t(`tag-${tag}`, tag) })) : []
				}
				onChange={(e, data) => onChange(data)}
			/>
		</>
	);
}
