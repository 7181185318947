import firebase from "firebase/app";

import createOrUpdateSession from "astrid-firestore/src/api/sessions/createOrUpdateSession";

import { useProfile } from "../../../../authentication/state/profile";

export default function useOnCreateSession({ setIsLoading, createSessionData, sessions, setModifiedSessions }) {
	const user = useProfile();

	return async ({ start, end, resource }) => {
		setIsLoading(true);

		const { studio, group } = resource.toPlainObject({ collapseExtendedProps: true });

		const { productionId, reader, recorder, price, agreement, polish } = createSessionData;

		try {
			const session = await createOrUpdateSession(firebase, {
				price,
				agreement,
				productionId,
				user,
				studio,
				group,
				reader,
				recorder,
				start,
				end,
				polish,
				sessions,
			});

			if (session.studio) {
				setModifiedSessions((sessions) => ({
					...sessions,
					[session.id]: {
						before: null,
						after: session,
					},
				}));
			}
		} catch (error) {
			window.alert(error.message);
		}

		setIsLoading(false);
	};
}
