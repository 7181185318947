import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import useUserOptions from "../../../hooks/useUserOptions";

const searchQueryMinLength = 3;

const emptyOptions = [];

export default function AddUserSelect(props) {
	const { t } = useTranslation();

	const [searchQuery, setSearchQuery] = useState("");

	const [userOptions, loading, error] = useUserOptions();

	const userOptionsSlice = useMemo(() => userOptions.slice(0, 10), [userOptions]);

	return (
		<Form.Select
			search
			deburr
			label={t("user", "User")}
			options={
				searchQuery
					? searchQuery.length >= searchQueryMinLength
						? userOptions
						: emptyOptions
					: userOptionsSlice
			}
			loading={loading}
			error={error}
			onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
			additionalOptions={!searchQuery && [{ key: "new", value: "new", text: `[${t("createNew")}]` }]}
			noResultsMessage={
				searchQuery.length >= searchQueryMinLength
					? t("noResults", "No results")
					: t("typeMoreCharacters", "Type more characters")
			}
			disabled={false}
			{...props}
		/>
	);
}
