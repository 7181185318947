import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";

import { getDeliveryObstacles, getDistOptions } from "astrid-web/src/components/production/fnc";

import ScheduledObstacles from "./ScheduledObstacles";
import ScheduledPlan from "./ScheduledPlan";

const ScheduledRow = ({ production, distributorSettings, publisher }) => {
	const distOptions = getDistOptions({ production, publisher });
	const obstacles = getDeliveryObstacles({ production, distOptions });

	return (
		<Table.Row verticalAlign="top">
			<Table.Cell selectable collapsing>
				<Link to={"/production/" + production.id}>
					{production.title}
					<br />
					<small>{publisher?.name}</small>
				</Link>
			</Table.Cell>
			<Table.Cell collapsing>
				<ScheduledObstacles obstacles={obstacles} />
			</Table.Cell>
			<Table.Cell>
				{!publisher ? (
					<em>Laddar…</em>
				) : (
					<ScheduledPlan
						production={production}
						distOptions={distOptions}
						distributorSettings={distributorSettings}
					/>
				)}
			</Table.Cell>
		</Table.Row>
	);
};

export default ScheduledRow;
