import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import { useDocumentData, useLoadingValues } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import StudioDataForm from "./components/StudioDataForm";
import StudioHeader from "./components/StudioHeader";
import StudioSettingsForm from "./components/StudioSettingsForm";

export default function AdminStudioView({ id, organizationId }) {
	const [[studioGen2, studio], loading, error] = useLoadingValues(
		useDocumentData(id && db.collection("studios").doc(id)),
		useDocumentData(
			id && organizationId && db.collection("organizations").doc(organizationId).collection("studios").doc(id),
		),
	);

	return (
		<LoadingContext data={studioGen2 || studio} loading={loading} error={error}>
			<Card.Group>
				<StudioHeader studio={studioGen2 || studio} />
				<StudioDataForm studio={studioGen2 || studio} />

				{studioGen2 && <StudioSettingsForm studio={studioGen2} />}
			</Card.Group>
		</LoadingContext>
	);
}
