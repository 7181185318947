import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";
import { Button, Card, Icon, List, Loader } from "semantic-ui-react";

import { firebase } from "astrid-firebase";

const calculateDuration = firebase.functions().httpsCallable("algolia-calculateDuration");

export default function CalculateDuration() {
	const { t } = useTranslation();
	const { indexUiState } = useInstantSearch();
	const [isCalculatingDuration, setIsCalculatingDuration] = useState(false);
	const [duration, setDuration] = useState();

	return (
		<Card fluid>
			<Card.Content header={t("calculateDuration", "Calculate duration")} />
			<Card.Content style={{ minHeight: "110px" }}>
				{isCalculatingDuration && <Loader indeterminate inline="centered" active />}
				{!isCalculatingDuration && (
					<List size="medium">
						<List.Item>
							<p>
								<b>{`${t("totalDuration", "Total duration")}: `}</b>
								{duration?.actual || "-"}
							</p>
						</List.Item>
						<List.Item>
							<p>
								<b>{`${t("totalEstimatedDuration", "Total estimated duration")}: `}</b>
								{duration?.estimated || "-"}
							</p>
						</List.Item>
						<List.Item>
							<p>
								<b>{`${t("numberOfProductions", "Number of productions")}: `}</b>
								{duration?.numberOfProductions || "-"}
							</p>
						</List.Item>
					</List>
				)}
			</Card.Content>
			<Card.Content extra>
				<Button
					primary
					disabled={isCalculatingDuration}
					onClick={() => {
						setDuration(undefined);
						setIsCalculatingDuration(true);
						const query = indexUiState.query || "";
						const filters = indexUiState.configure?.filters;
						const refinements = Object.entries(indexUiState.refinementList || {}).map(([key, value]) => {
							if (value?.length > 1) {
								return value
									.map((value) => {
										return `${key}:"${value}"`;
									})
									?.join(" OR ");
							}
							return `${key}:"${value}"`;
						});
						const ranges = Object.entries(indexUiState.range || {}).map(([key, value]) => {
							const [from, to] = value.split(":");

							if (from && to) {
								return `${key}:${from} TO ${to}`;
							}

							if (from) {
								return `${key}>=${from}`;
							}

							if (to) {
								return `${key}<=${to}`;
							}

							return null;
						});

						const combinedFilters = [filters, ...refinements, ...ranges].join(" AND ");

						calculateDuration({ query, filters: combinedFilters })
							.then((data) => {
								setIsCalculatingDuration(false);
								setDuration(data.data);
							})
							.catch((error) => {
								console.error(error);
							});
					}}
				>
					<Icon name="time" />
					{t("calculate", "Calculate")}
				</Button>
			</Card.Content>
		</Card>
	);
}
