import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, FormField, Message } from "semantic-ui-react";

import {
	defaultProductionCategory,
	productionCategories,
} from "astrid-config/src/productionCategories/productionCategories";
import { db } from "astrid-firebase";
import createProduction from "astrid-firestore/src/api/productions/createProduction";
import { getCollection } from "astrid-firestore/src/helpers";
import { useDocumentDataOnce } from "astrid-firestore/src/hooks";

import Flex from "../../../../components/Flex/Flex";
import Select from "../../../ui/components/Select/Select";
import useLoadingFunction from "../../../ui/hooks/useLoadingFunction";

import Form from "../../../forms/components/Form/Form";
import useForm from "../../../forms/hooks/useForm";
import usePublisherManagers from "../../../users/hooks/usePublisherManagers";

import TitleOccupiedModal from "./components/TitleOccupiedModal";

const NOT_ASSIGNED = "notAssigned";

export default function CreateProductionForm({
	isDraft,
	profile,
	setProducerId,
	setPublisherId,
	defaultProducerId = null,
	defaultPublisherId,
	producerOptions,
	publisherOptions,
	enableProductionCategory,
}) {
	const { t } = useTranslation(["common", "language"]);
	const navigate = useNavigate();

	const [openModal, setOpenModal] = useState(false);

	const form = useForm({
		defaultValues: {
			title: "",
			language: null,
			producerId: defaultProducerId,
			publisherId: defaultPublisherId,
			productionCategory: defaultProductionCategory,
			publisherManagers: [],
		},
	});

	const { watch, formState, setValue, getValues, handleSubmit } = form;

	const [publisherId, producerId, selectedPublisherManagers] = watch([
		"publisherId",
		"producerId",
		"publisherManagers",
	]);

	useEffect(() => {
		setProducerId?.(producerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [producerId]);

	useEffect(() => {
		setPublisherId?.(publisherId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [publisherId]);

	const [publisherManagers, loadingPublisherManagers] = usePublisherManagers(publisherId);

	const publisherManagerOptions = publisherManagers?.map((user) => ({
		key: user.id,
		value: user.id,
		text: `${user.firstName} ${user.lastName}`,
	}));

	useEffect(() => {
		setValue(
			"publisherManagers",
			publisherManagers?.filter((user) => user.id === profile?.id).map(({ id }) => id),
		);
	}, [setValue, publisherManagers, profile]);

	useEffect(() => {
		if (selectedPublisherManagers?.length > 0) {
			const lastElement = selectedPublisherManagers[selectedPublisherManagers.length - 1];

			setValue(
				"publisherManagers",
				lastElement === NOT_ASSIGNED
					? [NOT_ASSIGNED]
					: selectedPublisherManagers.filter((id) => id !== NOT_ASSIGNED),
			);
		}
		// eslint-disable-next-line
	}, [selectedPublisherManagers?.length]);

	const [publisher] = useDocumentDataOnce(publisherId && db.collection("organizations").doc(publisherId));

	const languageOptions =
		publisher?.languageOptions?.map((lang) => ({
			key: lang,
			value: lang,
			text: t(`language:${lang}`),
		})) || [];

	useEffect(() => {
		setValue("language", null);
	}, [setValue, publisherId]);

	const [onSubmit, submitting, submitError] = useLoadingFunction(
		handleSubmit(async ({ title, language, producerId, publisherId, publisherManagers, productionCategory }) => {
			const ref = await createProduction(firebase, {
				title,
				language,
				producerId,
				publisherId,
				status: isDraft ? "draft" : "offer",
				createdBy: profile.email,
				category: enableProductionCategory ? productionCategory : null,
				publisherManagers: publisherManagerOptions
					?.filter(({ value }) => publisherManagers.includes(value))
					.map(({ value, text }) => ({
						id: value,
						name: text,
					})),
			});

			navigate(`/production/${ref.id}`);
		}),
	);

	const checkTitle = async () => {
		const { publisherId, title } = getValues();

		const productions = await getCollection(
			db.collection("productions").where("publisher", "==", publisherId).where("title", "==", title),
		);

		if (productions.length > 0) {
			setOpenModal(true);
		} else {
			onSubmit();
		}
	};

	return (
		<Form form={form}>
			<Form.Field name="producerId" disabled={producerOptions?.length <= 1}>
				<Select label={t("productionCompany")} search deburr options={producerOptions} />
			</Form.Field>

			<Form.Field name="publisherId" disabled={publisherOptions?.length <= 1}>
				<Select label={t("publisher")} search deburr options={publisherOptions} />
			</Form.Field>

			<Form.Field name="publisherManagers" disabled={!publisherId}>
				<Select
					label={t("publisherManager")}
					multiple
					search
					deburr
					options={publisherManagerOptions}
					loading={loadingPublisherManagers}
					additionalOptions={[
						{
							key: NOT_ASSIGNED,
							value: NOT_ASSIGNED,
							text: t(NOT_ASSIGNED),
						},
					]}
				/>
			</Form.Field>

			<Form.Field name="title">
				<Form.Input label={t("bookTitle")} />
			</Form.Field>

			<Form.Field name="language" disabled={!publisherId}>
				<Select label={t("language")} search deburr options={languageOptions} />
			</Form.Field>

			{enableProductionCategory && (
				<FormField>
					<label>{t("productionCategory")}</label>

					<Flex style={{ paddingTop: 5 }}>
						{productionCategories.map((productionCategory) => (
							<Form.Radio
								key={productionCategory}
								name="productionCategory"
								label={productionCategory}
								disabled={!producerId}
								value={productionCategory}
							/>
						))}
					</Flex>
				</FormField>
			)}

			{openModal && <TitleOccupiedModal onSubmit={onSubmit} open={openModal} setOpenModal={setOpenModal} />}

			{submitError && <Message negative>{t("errorMessageHeader")}</Message>}

			<Flex justifyContent="center">
				<Button
					loading={submitting}
					disabled={submitting || !formState.isValid}
					style={{ marginTop: 15, width: 200 }}
					primary
					content={t("create")}
					onClick={checkTitle}
				/>
			</Flex>
		</Form>
	);
}
