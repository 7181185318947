import { useTable as useReactTable } from "react-table";

import filterTypes from "../helpers/filterTypes";
import getUrlState from "../utils/getUrlState";

const noData = [];

function getRowId(data, index, parent) {
	return data.rowId || data.id || (parent ? [parent.id, index].join(".") : index);
}

export default function useTable({
	data,
	sortBy = [],
	columns = [],
	filters = [],
	plugins = [],
	pageSize = 30,
	getSubRows,
	initialState,
	hiddenColumns = [],
}) {
	return useReactTable(
		{
			getRowId,
			filterTypes,
			data: data || noData,
			columns,
			getSubRows,
			disableMultiSort: true,
			autoResetPage: false,
			autoResetSortBy: false,
			autoResetFilters: false,
			autoResetExpanded: false,
			autoResetSelectedRows: false,
			initialState: initialState ||
				getUrlState() || {
					sortBy: [sortBy],
					filters,
					pageSize,
					pageIndex: 0,
					hiddenColumns,
				},
		},
		...plugins,
	);
}
