import { Card } from "semantic-ui-react";

import OrganizationFinanceSettings from "./components/OrganizationFinanceSettings";

export default function OrganizationSettingsFinanceView({ organization }) {
	return (
		<Card.Group>
			<OrganizationFinanceSettings organization={organization} />
		</Card.Group>
	);
}
