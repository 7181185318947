import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminApproveAgreementTemplatesView from "../views/SuperAdminApproveAgreementTemplatesView/SuperAdminApproveAgreementTemplatesView";

export default function SuperAdminApproveAgreementTemplates() {
	const hasRight = useHasRight();
	const { needsApproval } = useOutletContext();

	if (!hasRight("agreements.admin")) {
		return <Unauthorized />;
	}

	const { agreementTemplates, loading, error } = needsApproval;

	return (
		<SuperAdminApproveAgreementTemplatesView
			agreementTemplates={agreementTemplates}
			loading={loading}
			error={error}
		/>
	);
}
