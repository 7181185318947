import FirestoreDocument from "../../../types/FirestoreDocument";
import { nullable, ref, string } from "../../../types/_types";

export const User = FirestoreDocument.extend({
	email: string(),
	firstName: string(),
	img: nullable(string()),
	lastName: string(),
	phone: nullable(string()),
});

export const UserRef = ref(User, {
	email: true,
	firstName: true,
	img: true,
	lastName: true,
	phone: true,
}).transform((data) => ({
	...data,
	name: data.firstName ? [data.firstName.trim(), data.lastName.trim()].filter((s) => s).join(" ") : data.name,
}));
