import moment from "moment";

export function isValidDate(momentDate) {
	return moment(momentDate, "YYYY-MM-DD", true).isValid() && momentDate.isBefore(moment());
}

export function fieldHasError(field, formError) {
	return !!formError?.find((error) => error.key === field);
}

export function validateForm({ fromDate, toDate, t }) {
	const fromDateError = {
		key: "FROM_DATE",
		text: t(
			"invalidFromDate",
			"From date has to be in the format YYYY-MM-DD. It should not be in the future and should be before to date",
		),
	};

	const toDateError = {
		key: "TO_DATE",
		text: t(
			"invalidToDate",
			"To date has to be in the format YYYY-MM-DD. It should not be in the future and should be after from date",
		),
	};
	const fromDateFormatted = isValidDate(fromDate);
	const toDateFormatted = isValidDate(toDate);
	if (!fromDateFormatted || !toDateFormatted) {
		return [!fromDateFormatted && fromDateError, !toDateFormatted && toDateError].filter((v) => v);
	}
	const validFromDate = fromDate.isSameOrBefore(toDate);
	const validToDate = toDate.isSameOrAfter(fromDate);
	if (!validFromDate || !validToDate) {
		return [!validFromDate && fromDateError, !validToDate && toDateError].filter((v) => v);
	}
}
