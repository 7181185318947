import React from "react";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminAuthorMenuOptions from "../hooks/useAdminAuthorMenuOptions";

export default function AdminAuthorHeader({ author }) {
	const menuOptions = useAdminAuthorMenuOptions({ author });

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<Header style={{ margin: 0 }}>{author.name}</Header>
		</AdminPageHeader>
	);
}
