export default function getDummyEmail({ firstName, lastName }) {
	const name = `${firstName}-${lastName}`
		.toLowerCase()
		.replace(/\s+/g, "-")
		.replace(/[^\w-]+/g, "");

	const seed = (Math.random() + "").substr(4, 3);

	return `dummy-${name}-${seed}@earselect.se`;
}
