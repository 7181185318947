import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";

export default function useAgreementStatusTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[agreementStatuses.PENDING_APPROVAL]: t("awaitingApproval", "Awaiting approval"),
			[agreementStatuses.APPROVAL_DENIED]: t("approvalDenied", "Approval denied"),
			[agreementStatuses.APPROVED]: t("approved", "Approved"),
			[agreementStatuses.SIGNING]: t("signing", "Signing"),
			[agreementStatuses.SIGNED]: t("signed", "Signed"),
			[agreementStatuses.SIGNING_FAILED]: t("signingFailed", "Signing failed"),
			[agreementStatuses.SIGNING_EXPIRED]: t("signingExpired", "Signing expired"),
			[agreementStatuses.SIGNING_CANCELLED]: t("signingCancelled", "Signing cancelled"),
			[agreementStatuses.ACTIVE]: t("active", "Active"),
			[agreementStatuses.EXPIRED]: t("expired", "Expired"),
			[agreementStatuses.TERMINATED]: t("terminated", "Terminated"),
		}),
		[t],
	);
}
