import {
	hasAddedReader,
	hasRemovedReader,
	isChangedRecorder,
	isChangedTimeOrStudio,
	isNewSession,
} from "../utils/checkSessionChanges";

import notifyCancelledSession from "./notifyCancelledSession";
import notifyChangedSession from "./notifyChangedSession";
import notifyNewSession from "./notifyNewSession";

export default async function notifySessions({ modifiedSessions, userIdsToNotify, t }) {
	const sessions = Object.values(modifiedSessions);

	const filterUserIdsToNotify = (userIds) => {
		return userIds.filter((userId) => userIdsToNotify.includes(userId));
	};

	await Promise.all(
		sessions.map(async ({ before, after }) => {
			if (isNewSession({ before, after })) {
				await notifyNewSession({
					before,
					after,
					userIds: filterUserIdsToNotify([after.recorder, ...after.reader]),
					t,
				});
				return;
			}

			if (isChangedRecorder({ before, after })) {
				await notifyNewSession({ before, after, userIds: filterUserIdsToNotify([after.recorder]), t });
				await notifyCancelledSession({ before, after, userIds: filterUserIdsToNotify([before.recorder]), t });
			}

			if (hasRemovedReader({ before, after })) {
				const removedReaderIds = before.reader.filter((reader) => !after.reader.includes(reader));

				await notifyCancelledSession({ before, after, userIds: filterUserIdsToNotify(removedReaderIds), t });
			}

			if (hasAddedReader({ before, after })) {
				const addedReaderIds = after.reader.filter((reader) => !before.reader.includes(reader));

				await notifyNewSession({ before, after, userIds: filterUserIdsToNotify(addedReaderIds), t });
			}

			if (isChangedTimeOrStudio({ before, after })) {
				// If there was a reader added and time or studio also was changed at the same time the original readers should be notified
				const originalReaderIds = before.reader.filter((reader) => after.reader.includes(reader));

				await notifyChangedSession({
					before,
					after,
					userIds: filterUserIdsToNotify([after.recorder, ...originalReaderIds]),
					t,
				});
			} else if (hasRemovedReader({ before, after }) || hasAddedReader({ before, after })) {
				await notifyChangedSession({
					before,
					after,
					userIds: filterUserIdsToNotify([after.recorder]),
					t,
				});
			}
		}),
	);
}
