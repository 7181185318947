import { useEffect, useMemo, useRef } from "react";

import isEqualRef from "../utils/isEqualRef";

import useLoadingValue from "./useLoadingValue";

export default function useSnapshot(query, listen = true) {
	const ref = useRef();

	const { value, loading, error, reset, setValue, setError } = useLoadingValue(!!query);

	const isEqual = isEqualRef(ref.current, query);

	const queryRef = isEqual ? ref.current : query;

	useEffect(() => {
		if (isEqual) {
			return;
		}

		ref.current = queryRef;
		reset(!!queryRef);

		if (queryRef) {
			if (listen) {
				return queryRef.onSnapshot(setValue, setError);
			} else {
				queryRef.get().then(setValue).catch(setError);
			}
		}
	}, [queryRef]);

	const response = [value, loading, error];

	return useMemo(() => response, response);
}
