import { useTranslation } from "react-i18next";

export default function useNotificationOptions() {
	const { t } = useTranslation();

	return [
		{ key: "yes", value: "email", text: t("yes", "Yes") },
		{ key: "no", value: "none", text: t("no", "No") },
	];
}
