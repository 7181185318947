export function userIsEmployee(producerId) {
	return (user) => !!user?.producers?.[producerId]?.employee;
}

export function defaultUserAgreement(producerId, language, role) {
	return (user) => user?.prices?.[producerId]?.[`${language}-${role}`]?.agreement;
}

export function defaultUserPrice(producerId, language, role) {
	return (user) => user?.prices?.[producerId]?.[`${language}-${role}`]?.price;
}
