import {
	getAgreementRef,
	getOfferProductionRef,
	getPartyRef,
	getProductionProducerRef,
	getProductionPublisherRef,
	getUserRef,
} from "../../utils/referenceMappers";

import createApprovalData from "../approval/createApprovalData";
import createNotificationData from "../notifications/createNotificationData";

import createOfferStatus from "./createOfferStatus";

export default function createOfferData(
	firebase,
	{
		id = undefined,
		role = null,
		type = null,
		price = null,
		status = null,
		voiceId = null,
		deleted = false,
		approved = null,
		createdBy = null,
		expiresAt = null,
		agreement = null,
		production = null,
		firstParty = null,
		secondParty = null,
		optionalMessage = "",
		optionalMessageSender = null,
		discussionVisibleForPublisher = false,
	},
) {
	if (!role || !type || !production) {
		throw new Error("Missing required data to create offer");
	}

	const db = firebase.firestore();
	const ref = db.collection("offers").doc(id);

	return {
		id: ref.id,
		ref,
		type,
		role,
		price,
		optionalMessage,
		optionalMessageSender,
		status: status || createOfferStatus({ approved, agreement }),
		voiceId,
		deleted,
		approval: createApprovalData(approved || null, createdBy),
		expiresAt,
		createdAt: new Date(),
		createdBy: getUserRef(createdBy),
		agreement: getAgreementRef(agreement),
		production: getOfferProductionRef(production),
		producer: getProductionProducerRef(db, production),
		publisher: getProductionPublisherRef(db, production),
		firstParty: getPartyRef(agreement?.firstParty || firstParty),
		secondParty: getPartyRef(agreement?.secondParty || secondParty),
		languageRole: `${production.language}-${role}`,
		notification: createNotificationData(firebase, createdBy),
		discussionVisibleForPublisher,
	};
}
