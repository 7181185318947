import { firebase } from "astrid-firebase";
import db from "astrid-firebase/src/db";
import createProductionPriceData from "astrid-firestore/src/api/productions/prices/createProductionPriceData";
import runBatch from "astrid-firestore/src/helpers/runBatch";

export default function submitOffer({ production, productionType, productionPrice, otherPrices = [] }) {
	return runBatch(db, (batch) => {
		batch.update(production.ref, {
			productionType: productionType === "default" ? null : productionType,
			productionPrice,
		});

		for (const price of [productionPrice, ...otherPrices]) {
			const productionPrice = createProductionPriceData(firebase, {
				...price,
				approved: true,
				identifier: `${production.id}_${price.type}`,
				production,
			});

			batch.set(productionPrice.ref, productionPrice);
		}
	});
}
