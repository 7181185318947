import DropdownItemSoftDelete from "../../../../ui/components/DropdownMenu/DropdownItemSoftDelete";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import EditAppendixAction from "./EditAppendixAction";

export default function AppendixDropdownMenu({ appendix }) {
	return (
		<DropdownMenu>
			{(context) => (
				<>
					<EditAppendixAction appendix={appendix} />
					<DropdownItemSoftDelete data={appendix} {...context} />
				</>
			)}
		</DropdownMenu>
	);
}
