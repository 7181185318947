import moment from "moment";
import React from "react";
import { Icon, Popup, Table } from "semantic-ui-react";

import distConfig from "astrid-config/src/distributors";
import toDate from "astrid-helpers/src/toDate";

const ScheduledPlan = ({ production, distOptions, distributorSettings }) => {
	return (
		<Table compact size="small">
			<Table.Body>
				<Table.Row verticalAlign="top">
					{
						// merge all active distributors
						[
							...((typeof production.isbn === "string" && distOptions.total) || []),
							...((production.deliveryEbook && distOptions.ebook) || []),
							...((production.deliveryParts && distOptions.part) || []),
						]
							.reduce((prev, curr) => {
								if (!prev.includes(curr)) prev.push(curr);
								return prev;
							}, [])
							.sort()
							.map((distId) => (
								<DistributorCell
									key={distId}
									production={production}
									distId={distId}
									distributorSettings={distributorSettings}
									activeArticles={{
										total: distOptions.total?.includes(distId),
										part: distOptions.part?.includes(distId),
										ebook: distOptions.ebook?.includes(distId),
									}}
								/>
							))
					}
				</Table.Row>
			</Table.Body>
		</Table>
	);
};

export default ScheduledPlan;

const DistributorArticle = ({ title, release, info }) => {
	return (
		<Popup
			inverted
			size="tiny"
			content={
				release?.date ? (
					<>Släppdatum: {moment(toDate(release?.date)).format("YYYY-MM-DD")}</>
				) : (
					"Släppdatum saknas"
				)
			}
			trigger={
				<div>
					<Icon name="calendar" color={!info.meta || release?.metaSent ? "green" : "yellow"} /> {title}
				</div>
			}
		/>
	);
};

const DistributorCell = ({ production, distId, distributorSettings, activeArticles }) => {
	const info = distConfig[distId];
	const total = production?.release?.[distId];
	const ebook = production?.deliveryEbook?.release?.[distId];
	const settings = distributorSettings?.[distId] || {};

	return (
		<Table.Cell>
			<b>{info.name}</b>

			<div>
				<Icon
					name={
						settings.automation === "group"
							? "archive"
							: settings.automation === "schedule"
							? "arrows alternate horizontal"
							: "fast forward"
					}
				/>{" "}
				{settings.automation === "group"
					? "Grupperad levarans"
					: settings.automation === "schedule"
					? settings.scheduleDays + " dagar före släppdatum"
					: "Leveraras direkt"}
			</div>

			{activeArticles.total && typeof production.isbn === "string" && (
				<DistributorArticle title="Ljudbok" release={total} info={info} />
			)}

			{activeArticles.part &&
				production.deliveryParts &&
				Object.values(production.deliveryParts).map((part, pi) => (
					<DistributorArticle
						key={pi}
						title={"Del " + (pi + 1)}
						release={part.release?.[distId]}
						info={info}
					/>
				))}

			{activeArticles.ebook && production.deliveryEbook && (
				<DistributorArticle title="E-bok" release={ebook} info={info} />
			)}
		</Table.Cell>
	);
};
