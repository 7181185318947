import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import StudioDropdownMenu from "../../../../studios/components/StudioDropdownMenu/StudioDropdownMenu";
import { onlineAccessor } from "../../../../studios/utils/studioAccessors";

export default function useAdminStudiosColumns({ gen }) {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "name",
				Header: t("name"),
				Cell: ({ row, value }) => (
					<Flex style={{ gap: 10, justifyContent: "flex-start" }}>
						<Label circular empty style={{ background: row.original.color }} />
						<div>{value}</div>
					</Flex>
				),
			},
			{
				id: "groups",
				Header: t("studioGroupTags"),
				accessor: ({ groupTags }) => groupTags?.join(", "),
			},
			{
				id: "status",
				Header: t("status"),
				accessor: ({ active }) => (active ? t("active") : t("inactive")),
			},
			{
				id: "online",
				Header: t("activity", "Activity"),
				accessor: (studio) => (onlineAccessor(studio) ? t("online") : t("offline")),
				Cell: ({ row, value }) => {
					return (
						<Flex style={{ gap: 10, justifyContent: "flex-start" }}>
							<Label circular empty color={onlineAccessor(row.original) ? "green" : "red"} />
							<div>
								{value} {row.original.online && `(${moment(row.original.online?.toDate()).fromNow()})`}
							</div>
						</Flex>
					);
				},
			},
			{
				id: "version",
				Header: t("version", "Version"),
			},
			gen === 2 && {
				id: "menu",
				textAlign: "right",
				collapsing: true,
				disableSortBy: true,
				Cell: ({ row }) => <StudioDropdownMenu studio={row.original} />,
			},
		],
		[gen, t],
	);
}
