import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, List, ListItem } from "semantic-ui-react";

import { db } from "astrid-firebase";
import { useCollectionData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import DeliverFilesButton from "./DeliverFilesButton";

const statusColors = {
	queued: "orange",
	created: "orange",
	initiated: "yellow",
	fail: "red",
	done: "green",
};

function DeliveryButtons({ latestJob, isbn, files }) {
	return files?.length
		? files.map((file) => {
				return (
					<ListItem key={file.name}>
						<DeliverFilesButton
							latestJob={latestJob}
							isbn={isbn}
							text={`Deliver ${file.name}`}
							file={file}
							color="teal"
						/>
					</ListItem>
				);
		  })
		: null;
}

export default function DeliveryInformation({ hit }) {
	const { t } = useTranslation();
	const { isbn, batchDelivered_timestamp } = hit;
	const [jobs, loading, error] = useCollectionData(db.collection("tasks/compute/jobs").where("isbn", "==", isbn));

	const latestJob = jobs?.sort(
		(a, b) => b.created?.toDate?.()?.getTime?.() - a.created?.toDate?.()?.getTime?.(),
	)?.[0];

	return (
		<LoadingContext loading={loading} error={error} loaderMinHeight="">
			<>
				<List>
					{batchDelivered_timestamp && (
						<List.Item>
							<p>
								<b>{t("batchDelivered", "Batch delivered")}</b>:&nbsp;
								<i>{new Date(batchDelivered_timestamp * 1000)?.toLocaleString()}</i>
								<Icon style={{ marginLeft: 5 }} name="circle" color="green" />
							</p>
						</List.Item>
					)}
					<List.Item>
						<p>
							<b>{t("deliveryOrdered", "Delivery ordered")}</b>:&nbsp;
							<i>{latestJob?.created?.toDate?.()?.toLocaleString() || t("notOrdered", "Not ordered")}</i>
						</p>
					</List.Item>
					<List.Item>
						<p>
							<b>{t("deliveryStatus", "Delivery status")}</b>:&nbsp;
							<i>{latestJob?.status || "n/a"}</i>
							<Icon
								style={{ marginLeft: 5 }}
								name="circle"
								color={statusColors[latestJob?.status] || "grey"}
							/>
						</p>
					</List.Item>
				</List>
				<List>
					<DeliveryButtons latestJob={latestJob} isbn={isbn} files={hit.zipFiles} />
					<DeliveryButtons latestJob={latestJob} isbn={isbn} files={hit.wavFiles} />
					<DeliveryButtons latestJob={latestJob} isbn={isbn} files={hit.mp3Files} />
					<DeliveryButtons latestJob={latestJob} isbn={isbn} files={hit.epubFiles} />
					<DeliveryButtons latestJob={latestJob} isbn={isbn} files={hit.pdfFiles} />
				</List>
			</>
		</LoadingContext>
	);
}
