import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Label } from "semantic-ui-react";

import { formatVendorMarkdown } from "../../../../vendors/utils/vendorsHelper";

import SigneeSelect from "../components/SigneeSelect";

export default function useSecondaryPartiesColumns({ part }) {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "name",
				filter: "text",
				Header: t("name"),
			},
			...(part === "publisher"
				? [
						{
							id: "signee",
							Header: t("signee", "Signee"),
							Cell: ({ row }) => row.isSelected && <SigneeSelect party={row.original} />,
						},
				  ]
				: [
						{
							id: "email",
							filter: "text",
							Header: t("email"),
						},
				  ]),
			{
				id: "vendor",
				filter: "text",
				Header: t("vendor"),
				accessor: ({ vendor }) => formatVendorMarkdown(vendor),
				Cell: ({ value }) => <ReactMarkdown>{value}</ReactMarkdown>,
			},
			{
				id: "vendor.primary",
				Header: "",
				accessor: ({ vendor }) => vendor.primary && <Label>{t("common:primary", "Primary")}</Label>,
			},
		],
		[t, part],
	);
}
