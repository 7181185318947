import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { useProfile } from "../../../../authentication/state/profile";

export default function useProductionSecondaryMenuOptions({ production, isDesktop }) {
	const { t } = useTranslation();

	const user = useProfile();
	const location = useLocation();

	const { pathname } = location;

	return useMemo(
		() => [
			{
				to: "discussion",
				className: "tab-pinned-right",
				active: pathname.endsWith("discussion") || (isDesktop && !pathname.includes("discussionInternal")),
				hidden: !production.producer,
				content: (
					<>
						<Icon name="comments" />

						{isDesktop ? t("discussion") : ""}
					</>
				),
			},
			{
				to: "discussionInternal",
				active: pathname.includes("discussionInternal"),
				hidden: !user?.permissions?.publisher?.[production.publisher],
				content: (
					<>
						<Icon name="comment" />

						{isDesktop ? t("internal") : ""}
					</>
				),
			},
		],
		[pathname, production.producer, production.publisher, isDesktop, t, user?.permissions?.publisher],
	);
}
