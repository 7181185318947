import { useMemo } from "react";

import { db } from "astrid-firebase";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

export default function usePublisherManagers(selectedPublisher) {
	const getSelectablePublisherManagers = useMemo(() => {
		if (selectedPublisher) {
			return db
				.collection("users")
				.where(`permissions.publisher.${selectedPublisher}`, "array-contains-any", [
					"publisherAdmin",
					"publisherStaff",
				]);
		}
	}, [selectedPublisher]);

	const [selectablePublisherManagers, loading, error] = useCollectionData(getSelectablePublisherManagers);

	if (error) {
		console.error(error);
	}

	return [selectablePublisherManagers, loading, error];
}
