import { useMemo } from "react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

import useCostsQuery from "../../../../costs/hooks/useCostsQuery";
import useOpenCosts from "../../../../costs/hooks/useOpenCosts";
import useCurrentExchangeRate from "../../../../exchangeRates/hooks/useCurrentExchangeRate";

const partyTypes = [organizationTypes.PRODUCER, organizationTypes.PUBLISHER];

function addCostToGroup(groupedCosts, name, cost, party) {
	if (partyTypes.includes(party?.type)) {
		groupedCosts[party.id] = groupedCosts[party.id] || { party };
		groupedCosts[party.id][name] = groupedCosts[party.id][name] || [];
		groupedCosts[party.id][name].push(cost);
	}
}

function groupCostsByPartyId(costs, openCosts) {
	const groupedCosts = {};

	costs.forEach((cost) => {
		addCostToGroup(groupedCosts, "costs", cost, cost.firstParty);
		addCostToGroup(groupedCosts, "costs", cost, cost.secondParty);
	});

	openCosts.forEach((cost) => {
		addCostToGroup(groupedCosts, "openCosts", cost, cost.firstParty);
		addCostToGroup(groupedCosts, "openCosts", cost, cost.secondParty);
	});

	return groupedCosts;
}

export default function useProductionFinance({ production, partyId }) {
	const costsQuery = useCostsQuery({ productionId: production?.id });

	const [[exchangeRate, prices = [], users = [], costs = []], loading, error] = useLoadingValues(
		useCurrentExchangeRate(),
		useCollectionData(production?.ref?.collection("productionPrices").where("deleted", "==", false)),
		useCollectionData(production?.ref?.collection("users")),
		useCollectionData(production?.id && costsQuery),
	);

	const openCosts = useOpenCosts({
		users,
		production,
		exchangeRate,
		costs,
		prices,
	});

	const groupedCosts = useMemo(() => groupCostsByPartyId(costs, openCosts), [costs, openCosts]);

	const selectedPartyId = partyId || production.producer;
	const isPublisher = selectedPartyId === production.publisher;

	const finalizableOpenCosts = isPublisher ? groupedCosts[selectedPartyId]?.openCosts : openCosts;

	const menuOptions = Object.values(groupedCosts).map(({ party, openCosts }) => ({
		key: party.id,
		name: party.name,
		path: `/production/${production.id}/finance/${party.id}`,
		label: openCosts?.filter(({ openAmount }) => openAmount > 0).length || 0,
		active: party.id === selectedPartyId,
	}));

	if (!menuOptions.find(({ key }) => key === production.producer)) {
		menuOptions.unshift({
			key: production.producer,
			name: production.producerName,
			path: `/production/${production.id}/finance/${production.producer}`,
			active: production.producer === selectedPartyId,
		});
	}

	return [
		{
			menuOptions,
			isPublisher,
			exchangeRate,
			selectedPartyId,
			finalizableOpenCosts,
			costs: groupedCosts[selectedPartyId]?.costs || [],
			openCosts: groupedCosts[selectedPartyId]?.openCosts || [],
		},
		loading,
		error,
	];
}
