import { useMemo } from "react";

import CreateOrdersButton from "../../../../orders/components/CreateOrdersButton/CreateOrdersButton";

import AccountCostsBalance from "./AccountCostsBalance";

export default function AccountCostsTableActions({ data, selectedFlatRows, ...props }) {
	const selectedCosts = useMemo(() => selectedFlatRows.map(({ original }) => original), [selectedFlatRows]);

	return (
		<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
			<div>
				<CreateOrdersButton costs={selectedCosts} redirectTo={"../orders"} {...props} />
			</div>

			<AccountCostsBalance costs={data} />
		</div>
	);
}
