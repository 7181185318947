import React from "react";
import { useTranslation } from "react-i18next";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import Notification from "./components/Notification";
import useNotifications from "./hooks/useNotifications";

export default function Notifications({ organization }) {
	const { t } = useTranslation();

	const [notifications, loading, error] = useNotifications({ organization });

	return (
		<LoadingContext
			data={notifications}
			loading={loading}
			error={error}
			noDataAvailableMessage={t("noNotifications", "You have no notifications")}
		>
			{notifications?.map((todo) => (
				<Notification key={todo.id} todo={todo} />
			))}
		</LoadingContext>
	);
}
