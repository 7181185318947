import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import TeamRoleSelect from "../../TeamRoleSelect/TeamRoleSelect";

export default function TeamMemberRoleField() {
	const { t } = useTranslation();

	return (
		<Form.Field name="role">
			<TeamRoleSelect label={t("role", "Role")} />
		</Form.Field>
	);
}
