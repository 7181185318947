import { getDocumentData, updateDocument } from "../../../helpers";

import createTodoResolvedData from "../data/todos/createTodoResolvedData";

export default async function resolveTodo(firebase, { todo, user }) {
	const existingTodo = await getDocumentData(todo.ref);

	if (existingTodo && !existingTodo.resolved?.status) {
		await updateDocument(todo.ref, {
			resolved: createTodoResolvedData(firebase, { user }),
		});
	}
}
