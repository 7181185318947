import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useCostTypes from "../../costs/hooks/useCostTypes";
import usePriceUnits from "../../prices/hooks/usePriceUnits";
import { formatPrice } from "../../prices/utils/priceFormatters";

export default function useAgreementPricesColumns() {
	const { t } = useTranslation();

	const units = usePriceUnits();
	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "name",
				Header: t("name", "Name"),
			},
			{
				id: "type",
				Header: t("type", "Type"),
				accessor: ({ type }) => costTypes[type],
			},
			{
				id: "price",
				Header: t("price", "Price"),
				accessor: (price) => formatPrice(price),
			},
			{
				id: "unit",
				Header: t("unit", "Unit"),
				accessor: ({ unit }) => units[unit],
			},
			{
				id: "currency",
				Header: t("currency", "Currency"),
			},
			{
				id: "vat",
				Header: t("VAT", "VAT"),
				accessor: ({ vat }) => `${vat}%`,
			},
		],
		[costTypes, t, units],
	);
}
