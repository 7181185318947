import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useConfigure } from "react-instantsearch";
import { Checkbox, Header } from "semantic-ui-react";

import { languageName } from "astrid-helpers/src/languages";

import FlexColumn from "../../../../../components/Flex/FlexColumn";

import RefinementCheckboxes from "./RefinementCheckboxes";

const checkboxes = [
	{
		label: "epub",
		fileTag: "has_epub",
	},
	{
		label: "pdf",
		fileTag: "has_pdf",
	},
	{
		label: "zip",
		fileTag: "has_zip",
	},
	{
		label: "wav",
		fileTag: "has_wav",
	},
	{
		label: "mp3",
		fileTag: "has_mp3",
	},
];

function getMandatoryFacetFilter({ user, isSuperAdmin }) {
	const storytoolArchivePerms = user.permissions?.storytoolArchive;
	if (isSuperAdmin || storytoolArchivePerms.accessToAllImprintNames) {
		return "";
	}
	return storytoolArchivePerms.imprintNames.map((imprintName) => `imprintName:"${imprintName}"`).join(" OR ");
}

export default function ArchiveFilter({ user, isSuperAdmin }) {
	const { t } = useTranslation();
	const [fileFilterTags, setFileFilterTags] = useState([]);

	const filters = [
		fileFilterTags?.length && fileFilterTags.map((tag) => `_tags:${tag}`).join(" OR "),
		getMandatoryFacetFilter({ user, isSuperAdmin }),
	];

	useConfigure({
		filters: filters.filter((v) => v).join(" AND "),
	});

	function onChange({ checked, fileType }) {
		if (checked) {
			setFileFilterTags([...fileFilterTags, fileType]);
		} else {
			setFileFilterTags(fileFilterTags.filter((ft) => ft !== fileType));
		}
	}

	return (
		<FlexColumn
			style={{
				gap: 15,
				//flexWrap: "wrap",
				margin: "10px 0px 10px 10px",
			}}
		>
			<FlexColumn style={{ gap: 10 }}>
				<Header size="tiny">{t("parts", "Files")}</Header>
				{checkboxes.map((checkbox) => {
					return (
						<Checkbox
							key={checkbox.label}
							label={`.${checkbox.label}`}
							checked={fileFilterTags.includes(checkbox.fileTag)}
							onChange={(_, { checked }) => {
								onChange({ checked, fileType: checkbox.fileTag });
							}}
						/>
					);
				})}
			</FlexColumn>
			<RefinementCheckboxes
				attribute="languageCode"
				header={t("language", "language")}
				customLabel={(value) => languageName(value)}
			/>
			<RefinementCheckboxes searchable={false} attribute="productions.status" header={t("status", "Status")} />
			<RefinementCheckboxes attribute="team" header={t("team", "Team")} />
			{(isSuperAdmin ||
				user.permissions.storytoolArchive?.accessToAllImprintNames ||
				user?.permissions?.storytoolArchive?.imprintNames?.length > 1) && (
				<RefinementCheckboxes attribute="imprintName" header={t("imprint", "Imprint")} />
			)}
			<RefinementCheckboxes attribute="narrators" header={t("narrator", "Narrator")} />
			<RefinementCheckboxes attribute="authors" header={t("author", "Author")} />
		</FlexColumn>
	);
}
