import { useMemo } from "react";

export default function useVatOptions() {
	return useMemo(
		() =>
			[...Array(101)].map((n, i) => ({
				key: i,
				value: i,
				text: `${i}%`,
			})),
		[],
	);
}
