import React /*, { useState }*/ from "react";
import { Icon, Popup } from "semantic-ui-react";
import moment from "moment";

import { toDate } from "../../helpers/fnc";
import getBillingStatus from "../../helpers/getBillingStatus";

const ProductionBilling = ({ production, size }) => {
	const { articles, status } = getBillingStatus(production);

	return (
		<Popup
			trigger={
				<Icon
					size={size || null}
					color={
						status === "none"
							? "grey"
							: status === "unbilled"
							? "red"
							: status === "unpayed"
							? "orange"
							: "green"
					}
					name="clipboard"
					style={{ cursor: "pointer" }}
				/>
			}
			on="click"
			className="production-billing"
			content={
				<>
					{status !== "none" ? (
						Object.entries(articles)
							.filter((b) => b[1])
							.map(([id, { title, billed, payed }]) => (
								<div key={id}>
									<h5>{title}</h5>
									<Icon name="clipboard" color={billed ? "green" : "red"} />{" "}
									{billed
										? "Fakturerad " + moment(toDate(billed)).format("YYYY-MM-DD HH:mm")
										: "Ej fakturerad"}
									<br />
									<Icon name="money" color={payed ? "green" : "red"} />{" "}
									{payed ? "Betald " + moment(toDate(payed)).format("YYYY-MM-DD HH:mm") : "Ej betald"}
								</div>
							))
					) : (
						<em>Inga artiklar att fakturera ännu</em>
					)}
				</>
			}
		/>
	);
};

export default ProductionBilling;
export { getBillingStatus };
