import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

import PreviewAgreementPdfButton from "../../../../../components/PreviewAgreementPdf/PreviewAgreementPdfButton";
import AgreementAppendixForm from "../../../../agreements/components/AgreementAppendixForm/AgreementAppendixForm";
import AgreementTemplateSelect from "../../../../agreements/components/AgreementTemplateSelect/AgreementTemplateSelect";
import useIsAgreementPriceApproved from "../../../../agreements/hooks/useIsAgreementPriceApproved";
import rolePartMap from "../../../../agreements/utils/rolePartMap";
import Form from "../../../../forms/components/Form/Form";
import VendorSelect from "../../../../vendors/components/VendorSelect";

import createProductionAgreement from "../utils/createProductionAgreement";

import CustomPriceForm from "./CustomPriceForm";
import SigneeSelect from "./SigneeSelect";

export default function CustomAgreementForm({ user, production, firstParty }) {
	const { t } = useTranslation();

	const { watch, register, formState, setValue, getValues } = useFormContext();

	const [role, templateId, template, customPrice] = watch([
		"role",
		"customAgreement.templateId",
		"customAgreement.template",
		"customPrice",
	]);

	const isAgreementPriceApproved = useIsAgreementPriceApproved(template);
	const approved = template && isAgreementPriceApproved(customPrice);

	useEffect(() => {
		setValue("customAgreement.approved", approved);
	}, [approved, setValue]);

	const previewRequest = () => {
		return createProductionAgreement({
			...getValues(),
			production,
			firstParty,
			secondParty: user,
		});
	};

	if (!user?.vendors) {
		return (
			<>
				<Message negative>{t("userLacksInvoicingDetails", "The user lacks invoicing details")}</Message>
				<input type="hidden" {...register("noVendors", { required: true })} />
			</>
		);
	}

	const vendorArray = Object.keys(user?.vendors || {});
	const defaultVendor = vendorArray.length === 1 ? vendorArray[0] : null;

	const legalEntityArray = Object.keys(firstParty?.vendors || {});
	const defaultLegalEntity = legalEntityArray.length === 1 ? legalEntityArray[0] : null;

	return (
		<>
			<Form.Field name="customAgreement.secondPartyVendorId" defaultValue={defaultVendor}>
				<VendorSelect label={t("vendor", "Vendor")} vendors={user?.vendors} />
			</Form.Field>

			<Form.Field name="customAgreement.templateId">
				<AgreementTemplateSelect
					type="production"
					role={role}
					firstPartyId={production.producer}
					onChangeOption={(option) => setValue("customAgreement.template", option?.data)}
				/>
			</Form.Field>

			{templateId && (
				<>
					<Form.Input name="customAgreement.name" label={t("agreementName", "Agreement name")} />

					<Form.Group widths="2">
						<Form.Field name="customAgreement.firstPartyVendorId" defaultValue={defaultLegalEntity}>
							<VendorSelect label={t("legalEntity", "Legal entity")} vendors={firstParty?.vendors} />
						</Form.Field>

						<Form.Field name="customAgreement.firstSigneeId">
							<SigneeSelect
								firstParty={firstParty}
								onChangeOption={(option) => setValue("customAgreement.firstSignee", option?.data)}
							/>
						</Form.Field>
					</Form.Group>
				</>
			)}

			{templateId === "custom" && (
				<div className="field">
					<label>{t("appendixes", "Appendixes")}</label>
					<div>
						<AgreementAppendixForm
							name="customAgreement.appendixes"
							type="production"
							part={rolePartMap[role]}
							producerId={production.producer}
						/>
					</div>
				</div>
			)}

			<CustomPriceForm firstParty={firstParty} production={production} />

			<PreviewAgreementPdfButton request={formState.isValid && previewRequest} />

			{!approved && (
				<Message info>{t("priceAboveThresholdMessage", "Needs to be approved before signing")}</Message>
			)}
		</>
	);
}
