import hasNotification from "astrid-firestore/src/api/notifications/hasNotification";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

import { getProfile } from "../../../../authentication/state/profile";

export default function tabHasNotification(tab, { offers }) {
	const { id } = getProfile();

	switch (tab) {
		case "readers":
			return offers.filter((offer) => offer.type === offerTypes.INQUIRY && hasNotification(offer, id)).length;
		case "team":
			return offers.filter((offer) => offer.type !== offerTypes.INQUIRY && hasNotification(offer, id)).length;
		default:
			return false;
	}
}
