import { useTranslation } from "react-i18next";
import { Button, Card } from "semantic-ui-react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import Form from "../../../../forms/components/Form/Form";
import useFirestoreForm from "../../../../forms/hooks/useFirestoreForm";
import useOrganizationOptions from "../../../../organizations/hooks/useOrganizationOptions";
import Select from "../../../../ui/components/Select/Select";

export default function BiblioProducerForm({ settings }) {
	const { t } = useTranslation();
	const { ref, defaultProducerId } = settings;

	const { form, onSubmit } = useFirestoreForm({
		ref,
		defaultValues: {
			defaultProducerId,
		},
	});

	const { isDirty, isValid, isSubmitting } = form.formState;

	const producerOptions = useOrganizationOptions({ type: organizationTypes.PRODUCER });

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("settings")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Form form={form}>
					<Form.Group>
						<Form.Field name="defaultProducerId">
							<Select label={t("defaultProducer", "Default producer")} options={producerOptions} />
						</Form.Field>
					</Form.Group>
				</Form>
			</Card.Content>
			<Card.Content>
				<Button
					primary
					loading={isSubmitting}
					disabled={!isDirty || !isValid || isSubmitting}
					onClick={onSubmit}
				>
					{t("save", "Save")}
				</Button>
			</Card.Content>
		</Card>
	);
}
