import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPricesView from "../views/AdminPricesView/AdminPricesView";

export default function AdminPrices() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("prices.view")) {
		return <Unauthorized />;
	}

	return <AdminPricesView producerId={organization.id} />;
}
