import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import api from "../../../../api";
import Popup from "../../../ui/components/Popup/Popup";
import useLoadingFunction from "../../../ui/hooks/useLoadingFunction";

export default function ArticleUpdateTitleDataIcon({ data }) {
	const { t } = useTranslation();

	const [onUpdate, loading, error] = useLoadingFunction(() => {
		return api.articles.updateTitleData(data);
	});

	return (
		<Popup
			content={
				error ? t("errorMessageHeader", "Something went wrong") : t("updateTitleData", "Update with title data")
			}
			trigger={
				<Icon
					color={error && "red"}
					name={error ? "warning sign" : "sync"}
					loading={loading}
					link
					onClick={onUpdate}
				/>
			}
		/>
	);
}
