import { useEffect } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";

import { useAlgoliaSearchClient } from "../../../../algolia/state/algoliaSearchClient";

export default function useImprintNames() {
	const searchClient = useAlgoliaSearchClient();

	const [{ value, loading, error }, fetchImprintNames] = useAsyncFn(async () => {
		const index = searchClient.initIndex("storytool-archive");
		const { facets } = await index.search("", {
			facets: ["imprintName"],
		});
		return Object.keys(facets?.imprintName || {});
	}, [searchClient]);

	useEffect(() => {
		fetchImprintNames();
	}, [fetchImprintNames]);

	return [value, loading, error];
}
