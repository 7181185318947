import React from "react";
import { useTranslation } from "react-i18next";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button, Modal } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import getCollectionData from "astrid-firestore/src/helpers/getCollectionData";
import useBatch from "astrid-firestore/src/hooks/useBatch";

import { useUser } from "../../../../authentication/state/user";

export default function DeleteOrderModal({ order, shouldInactive, onClose }) {
	const { t } = useTranslation();
	const user = useUser();
	const batch = useBatch(db);

	const [{ loading }, onDelete] = useAsyncFn(
		async (deleteCosts) => {
			const costs = await getCollectionData(db.collection("costs").where("orderId", "==", order.id));

			await batch([
				[
					"update",
					order.ref,
					shouldInactive
						? { processed: true, inactivated: true, inactivatedBy: { id: user.uid, time: new Date() } }
						: {
								deleted: true,
								deletedBy: { id: user.uid },
						  },
				],
				...costs.map((cost) => [
					"update",
					cost.ref,
					deleteCosts ? { deleted: true, deletedBy: { id: user.uid } } : { orderId: null },
				]),
			]);

			onClose();
		},
		[order, batch, onClose],
	);

	return (
		<Modal open size="tiny" closeOnDimmerClick onClose={onClose}>
			<Modal.Header>
				{shouldInactive
					? t("inactivateOrderHeader", "Inactivate order")
					: t("deleteOrderHeader", "Delete order")}
			</Modal.Header>
			<Modal.Content>
				{shouldInactive
					? t(
							"confirmInactivateOrder",
							"Are you sure you want to inactivate this order? All associated costs will be marked as unprocessed.",
					  )
					: t(
							"confirmDeleteOrder",
							"Are you sure you want to delete this order? All associated costs will be marked as unprocessed.",
					  )}
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>{t("cancel")}</Button>
				<Button negative loading={loading} onClick={() => onDelete(false)}>
					{shouldInactive
						? t("inactivateOrder", "Yes, inactivate order")
						: t("deleteOrder", "Yes, delete order")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
