import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useApproveAgreementTemplatesColumns from "./hooks/useApproveAgreementTemplatesColumns";

export default function SuperAdminApproveAgreementTemplatesView({ agreementTemplates, loading, error }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useApproveAgreementTemplatesColumns();

	return (
		<>
			<AdminHeader>
				{t("needsApproval", "Needs approval")} - {t("agreementTemplates", "Agreement templates")}
			</AdminHeader>

			<DataTable
				data={agreementTemplates}
				error={error}
				loading={loading}
				columns={columns}
				onClick={({ id, firstParty }) =>
					navigate(`/${firstParty.type}/${firstParty.id}/admin/agreements/templates/${id}`)
				}
			/>
		</>
	);
}
