import { useMemo } from "react";

import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";
import { mapAgreementOption } from "astrid-firestore/src/api/agreements/utils/agreementMappers";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import { filterActiveAgreements, filterAgreementsByRole } from "../utils/agreementFilters";

import useAgreementsQuery from "./useAgreementsQuery";

export default function useAgreementOptions({ role, statuses = [agreementStatuses.ACTIVE], ...props }) {
	const [agreements, loading, error] = useCollectionDataOnce(
		useAgreementsQuery({
			statuses,
			...props,
		}),
	);

	const options = useMemo(
		() =>
			agreements?.filter(filterActiveAgreements()).filter(filterAgreementsByRole(role)).map(mapAgreementOption) ||
			[],
		[agreements, role],
	);

	return [options, loading, error];
}
