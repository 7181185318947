import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";

import distConfig from "astrid-config/src/distributors";
import { base, db, firebase } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import arrayChunk from "astrid-helpers/src/arrayChunk";

const convertMaster = firebase.functions().httpsCallable("convertMaster");

export const estimateTotal = (production) =>
	production &&
	production.stats &&
	production.stats.pages &&
	production.stats.cubase &&
	production.pages &&
	Math.round(production.stats.cubase.total * (production.pages / production.stats.pages.total));

export const estimateTotalStudioHours = (production) => {
	return (
		(production?.pages &&
			production?.stats?.tempo &&
			production?.stats?.loss &&
			Math.ceil(production.pages / production.stats.tempo.average)) ||
		0
	);
};

export const estimateStudioHours = (production) =>
	production &&
	production.stats &&
	production.stats.pages &&
	production.stats.tempo &&
	production.stats.loss &&
	production.pages &&
	Math.ceil(
		((production.pages - production.stats.pages.total) / production.stats.tempo.average) *
			(1 + production.stats.loss.average / 100),
	);

export const bookedStudioHours = (production) =>
	production && production.bookedMinutes && Math.round((production.bookedMinutes / 60) * 10) / 10;

export const sendToDistributor = (distributor, articles, profile, uid, audioOnly, automatedDelivery) => {
	// start job
	base.addToCollection("tasks/compute/jobs", {
		job: "sendToDistributor",
		status: "queued",
		created: firebase.firestore.FieldValue.serverTimestamp(),
		diskType: "pd-ssd",
		diskSize: 60,
		machineType: "n1-highmem-4",
		args: {
			// keepAlive: true,
			distributor,
			articles,
			audioOnly: !!audioOnly,
			automatedDelivery: !!automatedDelivery,
		},
	});

	// add production events
	const batch = [];
	Object.entries(articles).forEach(([prod, arts]) => {
		batch.push([
			prod,
			{
				user: uid,
				email: profile.email,
				data: { sendToDistributor: distributor, articles: arts },
				time: firebase.firestore.FieldValue.serverTimestamp(),
			},
		]);
	});

	// max 250 because serverTimestamp counts as an operation
	arrayChunk(batch, 250).map((chunk) => {
		const batch = db.batch();

		for (const [id, data] of chunk) {
			let eventRef = db.collection("productions").doc(id).collection("events").doc();
			batch.set(eventRef, data);
		}

		return batch.commit();
	});
};

export const addMasterFileToArticle = (productionId, article, fileid, external = false) => {
	db.collection("productions")
		.doc(productionId)
		.set(
			{
				master: {
					[article]: {
						files: firebase.firestore.FieldValue.arrayUnion(fileid),
						settings: { approved: false },
						external,
					},
				},
			},
			{ merge: true },
		);
};

export const queueMasterFileForProcessing = ({
	productionId,
	fileid,
	name,
	path,
	downloadURL,
	uid,
	email,
	unmastered,
	userName,
}) => {
	const data = {
		[fileid]: {
			name,
			original: { path, url: downloadURL },
			status: "queued",
			created: firebase.firestore.FieldValue.serverTimestamp(),
			user: userName,
		},
	};

	if (unmastered) {
		data[fileid].unmastered = true;
	}

	db.collection("productions")
		.doc(productionId)
		.collection("meta")
		.doc("master")
		.set(data, { merge: true })
		.then(() => {
			// start job
			convertMaster({ productionId, masterId: fileid });
		})
		.then(() => {
			// store event
			addEvent({
				productionId,
				user: uid,
				email,
				data,
			});
		})
		.catch((err) => {
			//handle error
			console.log("nextUpload -> err", err);
		});
};

export const getDistOptions = ({ production, publisher }) => {
	const publisherOptions =
		get(publisher, "distributionOptions") &&
		Object.values(publisher.distributionOptions).find(
			(opt) => opt.languages && opt.languages.includes(production.language),
		);

	let mergedOptions = _.cloneDeep(publisherOptions);

	if (mergedOptions && production?.distributionOptions) {
		const productionOptions = production.distributionOptions;
		if (productionOptions.settings) {
			mergedOptions.settings = _.merge({}, publisherOptions.settings, productionOptions.settings);
		}

		const isDisabled = (key, type) => {
			return (
				productionOptions.settings &&
				productionOptions.settings[type] &&
				productionOptions.settings[type][key] &&
				productionOptions.settings[type][key].disabled
			);
		};

		const filterDisabled = (arr, type) => {
			return arr.filter((item) => !isDisabled(item, type));
		};

		mergedOptions.languages = _.union(publisherOptions.languages, productionOptions.languages || []);
		mergedOptions.ebook = filterDisabled(_.union(publisherOptions.ebook, productionOptions.ebook || []), "ebook");
		mergedOptions.total = filterDisabled(_.union(publisherOptions.total, productionOptions.total || []), "total");
		mergedOptions.part = filterDisabled(_.union(publisherOptions.part, productionOptions.part || []), "part");
	}

	return mergedOptions;
};

export const getActiveArticles = (production) => {
	const activeArticles = [];

	if (typeof production.isbn === "string") activeArticles.push("total");
	if (production.deliveryParts) production.deliveryParts.forEach((s, pi) => activeArticles.push(`part_${pi}`));
	if (production.deliveryEbook) activeArticles.push("ebook");

	return activeArticles;
};

export const getDeliveryObstacles = ({
	production, // required production doc
	distOptions, // either pass in distribution options...
	publisher, // ...OR publisher doc
	singleDistributor, // optionally only check obstacles for a single distributor ID
	singleArticle, // optionally only check obstacles for a single article
}) => {
	// get distOptions from publisher if not included
	if (!distOptions && publisher) distOptions = getDistOptions({ production, publisher });
	if (!distOptions) return [];

	const deliveryProblems = [];

	// should this distributor/article be checked?
	const checkDist = (dist) => !singleDistributor || singleDistributor === dist;

	// gather active articles
	const checkArticles = singleArticle ? [singleArticle] : getActiveArticles(production);

	// loop articles
	checkArticles.forEach((article) => {
		// data path for article
		const path =
			article === "total"
				? "" // root
				: article.substr(0, 4) === "part"
				? `deliveryParts[${article.split("_")[1]}].` /* part*/
				: "deliveryEbook."; // ebook

		// name for article
		const name =
			article === "total"
				? "totala produktionen"
				: article.substr(0, 4) === "part"
				? `del nr ${+article.split("_")[1] + 1 /* part*/}`
				: "e-boken"; // ebook

		// ISBN
		const requireISBN = !!(
			!distOptions[article] ||
			distOptions[article].find((dist) => checkDist(dist) && !distConfig[dist]?.allowMissingISBN)
		);
		if (requireISBN && !get(production, `${path}isbn`)) deliveryProblems.push(`Ange ISBN för ${name}`);

		// cover
		const requireImg =
			distOptions[article] &&
			!!distOptions[article].find(
				(dist) =>
					checkDist(dist) &&
					distConfig[dist]?.cover &&
					!distConfig[dist].excludeMeta &&
					distOptions.settings.total[dist] &&
					distOptions.settings.total[dist].automatic &&
					!distOptions.settings.total[dist].excludeCover &&
					!distOptions.settings.total[dist].excludeMeta,
			);
		if (requireImg && !get(production, `${path}img`)) deliveryProblems.push(`Ladda upp omslag för ${name}`);

		// release date
		const missingReleaseDate =
			distOptions &&
			distOptions[article] &&
			!!distOptions[article].find(
				(dist) =>
					checkDist(dist) &&
					distConfig[dist]?.meta &&
					!distConfig[dist].excludeMeta &&
					get(distOptions, `settings.${article}.${dist}.automatic`) &&
					!distOptions.settings[article][dist].excludeMeta &&
					!get(production, `${path}release.${dist}.date`),
			);
		if (missingReleaseDate) deliveryProblems.push("Ange släppdatum hos samtliga distributörer för " + name);

		// pricing
		const missingPricing =
			distOptions &&
			distOptions[article] &&
			!!distOptions[article].find(
				(dist) =>
					checkDist(dist) &&
					distConfig[dist]?.meta &&
					!distConfig[dist].excludeMeta &&
					get(distOptions, `settings.${article}.${dist}.automatic`) &&
					!distOptions.settings[article][dist].excludeMeta &&
					distConfig[dist].meta.requiredFields &&
					distConfig[dist].meta.requiredFields.find(
						(field) =>
							field.substr(0, 5) === "price" && !get(production, `${path}release.${dist}.${field}`),
					),
			);
		if (missingPricing) deliveryProblems.push(`Ange prissättning för samtliga distributörer för ${name}`);

		// epub
		if (article === "ebook" && !production.deliveryEbook.path) deliveryProblems.push("Ladda upp epub för e-boken");
	});

	return deliveryProblems;
};
