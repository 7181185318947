import algoliasearch from "algoliasearch/lite";

import { firebase } from "astrid-firebase";
import createStore from "astrid-helpers/src/createStore";

import { onUser } from "../../authentication/state/user";

const getSecuredSearchApiKey = firebase.functions().httpsCallable("algolia-getSearchApiKey");

export const [
	getSearchClient,
	setAlgoliaSearchClient,
	useAlgoliaSearchClient,
	onSearchClient,
	resetAlgoliaSearchClient,
] = createStore();

onUser((user) => {
	if (!user) {
		resetAlgoliaSearchClient();
	} else {
		getSecuredSearchApiKey()
			.then(({ data }) => {
				const { id, key } = data;
				const client = algoliasearch(id, key);
				setAlgoliaSearchClient(client);
			})
			.catch((error) => console.error(error));
	}
});
