export default function flattenIntervals(array, offset = 1) {
	const result = [];
	let last;

	for (const values of array.sort((a, b) => a[0] - b[0] || a[1] - b[1])) {
		if (!last || values[0] > last[1] + offset) result.push((last = [...values]));
		else if (values[1] > last[1]) last[1] = values[1];
	}

	return result;
}
