import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import api from "../../../../../api";

export default function RestartStudioDropdownItem({ studio, setLoading }) {
	const { t } = useTranslation();

	return (
		<Dropdown.Item
			text={t("restart", "Restart")}
			disabled={studio.occupied}
			onClick={async () => {
				setLoading?.(true);
				await api.studios.update({ ref: studio.ref, restart: true });
				setLoading?.(false);
			}}
		/>
	);
}
