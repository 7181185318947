import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Grid, Header, Segment } from "semantic-ui-react";

const Policy = () => {
	const { t } = useTranslation();
	return (
		<Segment padded>
			<Header as="h2" icon="handshake outline" content="Integritetspolicy &amp; GDPR" />

			<Grid stackable columns={2}>
				<Grid.Column>
					<Header as="h3">{t("policy1")}</Header>
					<p>{t("policy1,1")}</p>
					<p>
						1.2. Earselects behandling av personuppgifter utförs i enlighet med tillämplig
						dataskyddslagstiftning, inklusive den allmänna dataskyddsförordningen (EU) (”GDPR”).
					</p>
					2016/679
					<Header as="h3">{t("policy2")}</Header>
					<p>
						2.1. Vi behandlar dina personuppgifter när detta är nödvändigt för utförandet av ett avtal med
						dig eller för att utföra sådana åtgärder som är nödvändiga för att ingå ett avtal med dig.
					</p>
					<p>
						2.2. Dina personuppgifter används även av Earselect med stöd av ett berättigat intresse för att
						kontakta dig för utskick av notiser via e-post, exempelvis när en bok levererats eller att en
						studiobokning ändrats.
					</p>
					<p>
						2.3. För vissa användare av Earselects produktionsplattform Astrid.fm, vilka nedan benämns
						Uppläsare, samlar vi in personuppgifter som visas i Earselects röstbank med stöd av ett
						berättigat intresse. På detta sätt tillgodoses Earselects intresse av att ge kunder information
						om befintliga Uppläsare.
					</p>
					<p>
						2.4. Personuppgifter kommer även att behandlas om det är nödvändigt för att efterleva rättsliga
						förpliktelser till följd av EU-rätt och svensk lag, exempelvis enligt bokföringslagen
						(1999:1078).
					</p>
					<p>
						2.5. Om du är kontaktperson för en leverantör, samarbetspartner eller potentiell kund till
						Earselects behandlar vi dina personuppgifter med stöd av ett berättigat intresse. Earselects
						kommer då att kommunicera med dig så länge som det specifika intresset föranleder.
					</p>
					<p>
						2.6. För det fall Earselect utför direktmarknadsföring görs detta med stöd av ett berättigat
						intresse.
					</p>
					<Header as="h3">{t("policy3")}</Header>
					<p>
						3.1. Earselect behandlar dina personuppgifter när detta är nödvändigt för att uppfylla
						avtalsenliga förpliktelser gentemot dig.
					</p>
					<p>
						3.2. PersonuppgifteranvändsiEarselectsröstbank för att kunder ska kunna göra sökningar på
						röstprover.
					</p>
					<p>
						3.3. Utöver vad som anges ovan kommer dina personuppgifter att behandlas i samband med
						fakturering, informationsgivande och kommunikation med dig som kund.
					</p>
					<p>
						3.4. Vi behandlar personuppgifter vid utskick av notiser i syfte att kommunicera hur våra
						tjänster och vår verksamhet utvecklas.
					</p>
					<Header as="h3">{t("policy4")}</Header>
					<p>
						4.1. En personuppgift är all information som direkt eller indirekt kan knytas till en levande
						person. Inom ramen för Earselects verksamhet behandlas olika typer av personuppgifter.
						Informationen vi behandlar lämnas direkt från dig som användare av Earselects
						produktionsplattform Astrid.fm.
					</p>
					<p>
						4.2. Earselect kommer att behandla följande kategorier av personuppgifter för de ändamål och
						under de omständigheter som beskrivs ovan:
					</p>
					<p>
						<b>a) Identifierare</b> – förnamn och efternamn,
					</p>
					<p>
						<b>b) Kontaktuppgifter</b> – telefonnummer, adress och e-postadress,
					</p>
					<p>
						<b>c) Faktureringsuppgifter</b> – faktureringsuppgifter för betalning.
					</p>
					<p>
						4.3. Från Uppläsare som använder Earselects produktionsplattform Astrid.fm samlar vi in, utöver
						information om e-post och telefonnummer, även ljudfiler med Uppläsarens röst, foton och andra
						uppgifter som Uppläsaren valt själv att fylla i, exempelvis bakgrundinformation och
						språkkunskaper.
					</p>
					<Header as="h3">{t("policy5")}</Header>
					<p>
						5.1. Earselect delar dina personuppgifter med andra när det är nödvändigt för att (i) utföra
						våra avtalsenliga förpliktelser gentemot dig, eller (ii) efterleva lag, författning eller beslut
						som vi måste efterleva.
					</p>
					<p>
						5.2. Personuppgifter som du lämnar kan komma att lämnas ut till tredjepartsleverantörer av
						tjänster eller system, såsom lagringsleverantörer eller betaltjänstleverantörer.
					</p>
					<p>
						5.3. Earselect eftersträvar att alltid behandla personuppgifter inom EU/EES. I de fall ett
						personuppgiftsbiträde till Earselect har verksamhet utanför EU/EES kommer Earselect att
						säkerställa en adekvat nivå av dataskydd, i förenlighet med GDPR.
					</p>
				</Grid.Column>
				<Grid.Column>
					<Header as="h3">{t("policy6")}</Header>
					<p>
						6.1. Personuppgifter lagras så länge som är nödvändigt för att uppfylla de ändamål som
						beskrivits ovan. De flesta personuppgifter som Earselect behandlar om dig kommer därför att
						raderas när de inte längre är nödvändiga för att uppfylla ett avtal med dig.
					</p>
					<p>
						6.2. Earselect är skyldigt att bevara vissa personuppgifter i sju år för att efterleva
						Bokföringslagen (1999:1078).
					</p>
					<Header as="h3">{t("policy7")}</Header>
					<p>
						7.1. Personuppgifter raderas eller anonymiseras när uppgifterna inte längre behöver bevaras, som
						beskrivet ovan. Med anonymisering menas att uppgifterna inte längre kan användas för att
						identifiera en person.
					</p>
					<p>
						7.2. När Earselect raderar eller anonymiserar personuppgifter kan dessa inte återkallas eller
						återskapas av Earselect.
					</p>
					<Header as="h3">{t("policy8")}</Header>
					<p>
						8.1. Som personuppgiftsansvarig vidtar Earselect lämpliga tekniska och organisatoriska åtgärder
						för att skydda de personuppgifter som behandlas, i enlighet med GDPR.
					</p>
					<p>
						8.2. Om dina personuppgifter är inblandade i en säkerhetsincident (en så kallad
						”personuppgiftsincident”) kommer vi att informera dig i enlighet med GDPR.
					</p>
					<Header as="h3">{t("policy9")}</Header>
					<p></p>
					<p>
						9.1. Du har rätt att återkalla samtycke till en viss behandling utan att detta påverkar
						lagligheten av behandlingen före återkallandet.
					</p>
					<p>
						9.2. Du har rätt att begära att vår behandling av dina personuppgifter begränsas till lagring
						och invända mot personuppgiftsbehandling.
					</p>
					<p>
						9.3. Du har rätt att begära ett registerutdrag, i elektroniskt format eller på papper. Earselect
						kommer att sammanställa information om hur dina personuppgifter behandlas och skicka denna till
						dig.
					</p>
					<p>
						9.4. Du har rätten att begära att Earselect rättar personuppgifter som du anser är felaktiga och
						att komma in med kompletterande personuppgifter (i särskilda fall) om du anser att de
						personuppgifter Earselect har behandlat ger en felaktig bild.
					</p>
					<p>
						9.5. Du har rätten att begära att Earselect raderar dina personuppgifter. Earselect kommer då
						att radera sådana personuppgifter som inte måste bevaras för att uppfylla rättsliga
						förpliktelser. Earselect kommer även att fortsätta behandla personuppgifter i vissa andra fall,
						bland annat när personuppgifterna fortfarande är nödvändiga för att uppfylla ett avtal med dig.
						Earselect kommer alltid att svara dig och förklara vilka personuppgifter som Earselect har rätt
						att fortsätta behandla.
					</p>
					<p>
						9.6. Du har alltid rätten att inge klagomål till den relevanta tillsynsmyndighet där du bor,
						arbetar eller där en överträdelse av GDPR enligt din mening har skett. I Sverige är den
						relevanta tillsynsmyndigheten Datainspektionen.
					</p>
					<p>
						9.7. Du har rätten att begära att Earselect raderar dina personuppgifter. Earselect kommer då
						att radera sådana personuppgifter som inte måste bevaras för att uppfylla rättsliga
						förpliktelser. Earselect kommer även att fortsätta behandla personuppgifter i vissa andra fall,
						bland annat när personuppgifterna fortfarande är nödvändiga för att uppfylla ett avtal med dig.
						Earselect kommer alltid att svara dig och förklara vilka personuppgifter som Earselect har rätt
						att fortsätta behandla.
					</p>
					<p>Om du vill utöva dina rättigheter ovan ber vi dig att kontakta oss på info@earselect.se</p>
					<p>Denna policy är upprättad i november 2019.</p>
					<a href="/policy.pdf">
						<Button primary icon="file pdf" content={t("downloadPdfBtn")} />
					</a>
					<Link to="/">
						<Button color="green" icon="mail forward" content={t("toAstrid")} />
					</Link>
				</Grid.Column>
			</Grid>
		</Segment>
	);
};
export default Policy;
