import React from "react";
import { Header } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import TeamPrice from "../../../../team/components/TeamPrice/TeamPrice";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import ReaderImage from "./ReaderImage";
import ReaderLanguages from "./ReaderLanguages";
import ReaderPresentation from "./ReaderPresentation";
import ReaderSamples from "./ReaderSamples";
import ReaderSexAndBirthday from "./ReaderSexAndBirthday";
import ReaderVoiceLevel from "./ReaderVoiceLevel";

export default function Reader({ reader, added, selected, ...props }) {
	return (
		<Accordion.Title disabled={added} style={{ backgroundColor: selected && "#f8f8f8" }} {...props}>
			<div style={{ width: "100%" }}>
				<Flex>
					<Flex style={{ gap: 15, width: "auto", justifyContent: "flex-start", whiteSpace: "nowrap" }}>
						<ReaderImage reader={reader} selected={selected} added={added} />

						<Header as="h4" style={{ margin: 0, color: (added && "#21ba45") || (selected && "#2185d0") }}>
							{reader?.name}{" "}
							<Header.Subheader>
								<TeamPrice price={reader.price?.price && reader.price} showCa />
							</Header.Subheader>
						</Header>
					</Flex>

					<Flex style={{ gap: 10, width: "auto", justifyContent: "flex-end", whiteSpace: "nowrap" }}>
						{reader.sex && <ReaderSexAndBirthday reader={reader} />}
						{reader.voice && <ReaderVoiceLevel voiceLevel={reader.voice} />}
						{reader.languageSkills && <ReaderLanguages languageSkills={reader.languageSkills} />}
					</Flex>
				</Flex>

				<div style={{ paddingLeft: 65, maxWidth: 600 }}>
					{reader.presentation && <ReaderPresentation reader={reader} selected={selected} />}
					{selected && reader.samples?.length > 0 && <ReaderSamples samples={reader.samples} />}
				</div>
			</div>
		</Accordion.Title>
	);
}
