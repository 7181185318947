import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";

function timestampToTime(ts) {
	if (ts?.toDate) {
		return ts?.toDate()?.getTime();
	}
	return ts?.getTime();
}
export default function notifyEditSession({ session, sessionUpdate, addAffectedStaff, addNotification }) {
	let readers = [];
	if (
		timestampToTime(session.date) !== timestampToTime(sessionUpdate.date) ||
		timestampToTime(session.start) !== timestampToTime(sessionUpdate.start) ||
		timestampToTime(session.end) !== timestampToTime(sessionUpdate.end) ||
		session.studio !== sessionUpdate?.studio ||
		session.reader.length !== sessionUpdate.reader.length ||
		!session?.reader?.every((reader) => sessionUpdate?.reader?.includes(reader))
	) {
		readers = [...new Set([...(session.reader || []), ...(sessionUpdate?.reader || [])])];
	}
	const recorders = [...new Set([session.recorder, sessionUpdate.recorder])].filter(
		(recorder) =>
			![sessionOfferTypes.OPEN_POSITION_RECORDER, sessionOfferTypes.ANY_RECORDER_IN_TEAM].includes(recorder),
	);
	addAffectedStaff(true, recorders, readers);
	addNotification(session.id, "edit", [...recorders, ...readers]);
}
