import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../../api";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import AdminForm from "../../../components/AdminForm/AdminForm";

import AdminArticleDatesFormInputs from "./AdminArticleDatesFormInputs";

export default function AdminArticleDistributionDatesForm({ article, organization, ...props }) {
	const { t } = useTranslation();

	const [channels, loading, error] = api.distributionChannels.useAllByIds(
		organization.distributionChannelIds || ["JUGGP9qBpHy9JuoSBdmL", "6a0xFok7w2Ti3N7oED2X"],
	);

	const form = useSimpleForm({
		schema: Article,
		defaultValues: {
			ref: article.ref,
			distribution: article.distribution || {
				releaseDate: null,
				takedownDate: null,
				announcementDate: null,
			},
			channels: article.channels || {},
		},
		onSubmit: (data) => {
			return api.articles.update(data, { version: true });
		},
		...props,
	});

	return (
		<AdminForm header={t("ditributionDates", "Distribution dates")} {...form}>
			<LoadingContext data={channels} loading={loading} error={error}>
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("channel", "Channel")}</Table.HeaderCell>
							<Table.HeaderCell>{t("announcementDate", "Announcement date")}</Table.HeaderCell>
							<Table.HeaderCell>{t("releaseDate", "Release date")}</Table.HeaderCell>
							<Table.HeaderCell>{t("takedownDate", "Takedown date")}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row verticalAlign="top">
							<Table.Cell verticalAlign="middle">All channels</Table.Cell>
							<AdminArticleDatesFormInputs name="distribution" />
						</Table.Row>
						{channels?.map((channel) => {
							return (
								<Table.Row key={channel.id} verticalAlign="top">
									<Table.Cell verticalAlign="middle">{channel.name}</Table.Cell>
									<AdminArticleDatesFormInputs name={`channels.${channel.id}.distribution`} />
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</LoadingContext>
		</AdminForm>
	);
}
