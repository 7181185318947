import React from "react";
import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import useYearOptions from "../../../../ui/hooks/useYearOptions";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminTitleDataForm({ title }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: api.titles.Schema,
		defaultValues: {
			ref: title.ref,
			releaseYear: title.releaseYear,
		},
		onSubmit: (data) => {
			return api.titles.update(data);
		},
	});

	const releaseYearOptions = useYearOptions();

	return (
		<AdminForm header={t("titleData", "Title data")} {...form}>
			<Form.Group widths="equal">
				<Form.Select
					name="releaseYear"
					label={t("releaseYear", "Release year")}
					options={releaseYearOptions}
					search
				/>
			</Form.Group>
		</AdminForm>
	);
}
