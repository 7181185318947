import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Label } from "semantic-ui-react";

import stopPropagation from "astrid-helpers/src/stopPropagation";

import Flex from "../../../../../components/Flex/Flex";
import StickyAudio from "../../../../../components/StickyAudio";

const flexStyle = {
	marginTop: 10,
	gap: 10,
	width: "auto",
	justifyContent: "flex-start",
	whiteSpace: "nowrap",
	flexWrap: "wrap",
};

export default function ReaderSamples({ samples }) {
	const { t } = useTranslation();
	const [activeFile, setActiveFile] = useState(null);

	return (
		<Flex onClick={stopPropagation} style={flexStyle}>
			{samples.map(({ title, genre, file }, i) => (
				<Label
					key={i}
					basic
					size="tiny"
					icon
					onClick={() => setActiveFile((activeFile) => (file !== activeFile ? file : null))}
				>
					<Icon name={file !== activeFile ? "play" : "stop"} />
					<Label.Detail style={{ marginLeft: 0 }}>{genre ? t(genre) : title}</Label.Detail>
				</Label>
			))}

			{activeFile && <StickyAudio file={activeFile} onClose={() => setActiveFile(null)} />}
		</Flex>
	);
}
