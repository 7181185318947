import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import AdminAuthorsView from "../views/AdminAuthorsView/AdminAuthorsView";

export default function AdminAuthors() {
	const context = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("authors.view")) {
		return <Unauthorized />;
	}

	return <AdminAuthorsView {...context} />;
}
