import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminOrganizationView from "../views/SuperAdminOrganizationView/SuperAdminOrganizationView";

export default function SuperAdminPublisher() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("publishers.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminOrganizationView id={id} />;
}
