import runBatch from "../../helpers/runBatch";

import createNotificationData from "../notifications/createNotificationData";

import createCommentData from "./createCommentData";

export default async function createComment(firebase, { notificationRef, ...data }) {
	const comment = createCommentData(firebase, data);

	await runBatch(firebase.firestore(), (batch) => {
		batch.set(comment.ref, comment);

		if (notificationRef) {
			batch.update(notificationRef, {
				notification: createNotificationData(firebase, data.user),
			});
		}
	});

	return comment;
}
