import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Studio } from "astrid-firestore/src/api/studios/types/Studio";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateStudioForm({ gen, organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, error, onSubmit } = useSimpleForm({
		schema: Studio,
		defaultValues: {
			name: "",
		},
		onSubmit: ({ id, name }) => {
			return api.studios.add({ id, name, gen, organization });
		},
		onSuccess: ({ id }) => {
			navigate(`../${id}`);
		},
	});

	return (
		<ModalPrompt
			size="tiny"
			error={error}
			header={t("addStudio", "Add studio")}
			onSave={onSubmit}
			onClose={onClose}
			closeOnConfirm={false}
		>
			<Form form={form}>
				{gen === 2 && (
					<Form.Input
						name="id"
						defaultValue=""
						label={t("studioId", "Studio ID")}
						placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
					/>
				)}

				<Form.Input name="name" label={t("name")} />
			</Form>
		</ModalPrompt>
	);
}
