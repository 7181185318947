import { db } from "astrid-firebase";
import { useDocumentDataOnce } from "astrid-firestore/src/hooks";

function isPriceApproved(autoApprovePrices, { language, type, unit, currency, price }) {
	if (!autoApprovePrices) {
		return false;
	}

	const key = `${language}_${type}_${unit}_${currency}`;
	const setting = autoApprovePrices[key];

	return !!setting && price <= parseFloat(setting.threshold);
}

export default function usePriceAutoApproval({ production, price, autoApprove = false }) {
	const [autoApprovePrices, loading, error] = useDocumentDataOnce(
		price &&
			autoApprove &&
			db.collection("organizations").doc(production.producer).collection("settings").doc("autoApprovePrices"),
	);

	const approved =
		price && autoApprove ? isPriceApproved(autoApprovePrices, { ...price, language: production.language }) : false;

	return [approved, loading, error];
}
