import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Card, Dropdown, Header } from "semantic-ui-react";

import DropdownMenu from "astrid-web/src/features/ui/components/DropdownMenu/DropdownMenu";
import ErrorMessage from "astrid-web/src/features/ui/components/Messages/ErrorMessage";
import Message from "astrid-web/src/features/ui/components/Messages/Message";
import useFileUpload from "astrid-web/src/features/ui/hooks/useFileUpload";

import Flex from "../../../../../components/Flex/Flex";
import useResetPassword from "../../../../authentication/hooks/useResetPassword";
import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function AccountInfo({ user }) {
	const { t } = useTranslation();
	const ref = useRef();

	const [{ loading }, onUploadFile] = useFileUpload({
		bucketName: "earselect-uploads",
		getDestination: () => `profileImages/${user.id}`,
	});

	const { resetPassword, loading: loadingPasswordReset, error, message } = useResetPassword(user);

	return (
		<Card fluid>
			<Card.Content>
				<Flex>
					<Flex justifyContent="flex-start" gap={10}>
						<ImageAvatar src={user.img} style={{ width: 40, height: 40 }} />
						<Header style={{ margin: 0 }}>
							{user.firstName} {user.lastName}
							<Header.Subheader>{user.email}</Header.Subheader>
						</Header>
					</Flex>
					<DropdownMenu loading={loading}>
						{() => (
							<>
								<Dropdown.Item
									text={t("uploadNewProfilePicture", "Upload new profile picture")}
									onClick={() => {
										ref.current.click();
									}}
								/>
								<Dropdown.Item
									loading={loadingPasswordReset}
									text={t("setNewPassword", "Set new password")}
									onClick={resetPassword}
								/>
							</>
						)}
					</DropdownMenu>
				</Flex>

				{message && <Message dismissible>{message}</Message>}

				{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
			</Card.Content>
			<input style={{ display: "none" }} ref={ref} type="file" onChange={onUploadFile} />
		</Card>
	);
}
