import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import useOrdersFromCosts from "../../hooks/useOrdersFromCosts";

import CreateOrdersModal from "../CreateOrdersModal/CreateOrdersModal";

export default function CreateOrdersButton({ expense, costs, redirectTo, ...props }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const orders = useOrdersFromCosts(costs);

	const amount = orders.length;

	const title = expense
		? t("createAmountPurchaseOrders", "Create {{amount}} purchase orders", { amount })
		: t("createAmountSalesOrders", "Create {{amount}} sales orders", { amount });

	return (
		<>
			<Button disabled={costs.length === 0} onClick={() => setOpen(true)} {...props}>
				{title}
			</Button>

			{open && (
				<CreateOrdersModal
					title={title}
					orders={orders}
					redirectTo={redirectTo}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
}
