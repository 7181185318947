import Form from "../../../../forms/components/Form/Form";
import ProductionInstantSearchSelect from "../../../../productions/components/ProductionInstantSearchSelect/ProductionInstantSearchSelect";

export default function CalendarFiltersProductionSelect({ organizationId }) {
	return (
		<Form.Field name="productions" shouldUnregister={false}>
			<ProductionInstantSearchSelect multiple clearable organizationId={organizationId} />
		</Form.Field>
	);
}
