import CreateArticleBundleFromArticlesButton from "../CreateArticleBundleForm/CreateArticleBundleFromArticlesButton";

export default function ArticlesTableFooter({ organization, selectedFlatRows }) {
	const selectedArticles = selectedFlatRows?.map(({ original }) => original);

	return (
		<>
			<CreateArticleBundleFromArticlesButton organization={organization} articles={selectedArticles} />
		</>
	);
}
