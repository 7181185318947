import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import CreateTranslatorButton from "../../../translators/components/CreateTranslatorForm/CreateTranslatorButton";
import TranslatorsCollectionDataTable from "../../../translators/components/TranslatorsCollectionDataTable/TranslatorsCollectionDataTable";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminTranslatorsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("translators", "Translators")}>
				{searchInput}

				<CreateTranslatorButton organization={organization} />
			</AdminHeader>

			<TranslatorsCollectionDataTable
				organization={organization}
				searchQuery={searchQuery}
				getQuery={(query) => query.where("publisherIds", "array-contains", organization.id)}
			/>
		</>
	);
}
