import notify, { markdownMessage } from "astrid-firebase/src/notifications";

import getSessionInformation from "../utils/sessionInformation";

export default function notifyNewSession({ before, after, userIds, t }) {
	return notify(userIds, (user) => {
		const lang = user.languageCode || "en";

		const subject = t("newSessionSubject", "You have been booked to a new session", { lng: lang });

		const message = t("sessionNotificationInformation", {
			lng: lang,
			...getSessionInformation({ before, after, lang }),
		});

		return {
			subject,
			message: markdownMessage({ message, lang }),
			sendOnStage: true,
			html: true,
		};
	});
}
