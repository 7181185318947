import {
	useCollectionCount,
	useCollectionData,
	useCollectionDataOnce,
	useDocumentData,
	useDocumentDataOnce,
} from "../../hooks";
import useCollectionDataByIdsOnce from "../../hooks/useCollectionDataByIdsOnce";
import useCollectionDataOptions from "../../hooks/useCollectionDataOptions";

export default function firestoreHooksPlugin() {
	return (api, context) => {
		const { firebase } = context;
		const { collection, getRef, getByRef, getAll } = api;

		// Hooks

		const useByRef = (ref, getter = useDocumentData) => {
			return getByRef(ref, getter);
		};

		const useByRefOnce = (ref, getter = useDocumentDataOnce) => {
			return useByRef(ref, getter);
		};

		const useById = (id, getter) => {
			return useByRef(getRef(id), getter);
		};

		const useByIdOnce = (id, getter) => {
			return useByRefOnce(getRef(id), getter);
		};

		const useAll = (callback, getter = useCollectionData) => {
			return getAll(callback, getter);
		};

		const useAllByIds = (ids) => {
			return useCollectionDataByIdsOnce(firebase, collection, ids);
		};

		const useAllOnce = (callback, getter = useCollectionDataOnce) => {
			return getAll(callback, getter);
		};

		const useOptions = ({ getQuery, mapOption, filterOptions } = {}, getter = useCollectionDataOptions) => {
			return getAll(getQuery, getter, { mapOption, filterOptions });
		};

		const useCount = (callback) => {
			return getAll(callback, useCollectionCount);
		};

		// API

		return {
			...api,

			// Hooks
			useAll,
			useAllByIds,
			useAllOnce,
			useById,
			useByIdOnce,
			useByRef,
			useByRefOnce,
			useOptions,
			useCount,
		};
	};
}
