import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Form, Loader, Message, Radio } from "semantic-ui-react";

import { useOrganizationId } from "../../features/organizations/state/organizationId";
import DateTime from "../../features/ui/components/DateInput/DateTime";

import PredefinedQuestions from "./PredefinedQuestions/PredefinedQuestions";
import fetchStatistics from "./helpers/fetchStatistics";
import generateXLXS from "./helpers/generateXLXS";
import * as viewHelper from "./helpers/viewHelper";

const dateTypes = {
	STATISTICS_DATE: "statisticsDate",
	DELIVERY_DATE: "production.deliveryDate",
};

export default function Statistic() {
	const { t } = useTranslation();
	const producerId = useOrganizationId();
	const [dateType, setDateType] = useState(dateTypes.STATISTICS_DATE);
	const [fromDate, setFromDate] = useState(moment(new Date()));
	const [toDate, setToDate] = useState(moment(new Date()));
	const [formError, setFormError] = useState(null);
	const [formSuccess, setFormSuccess] = useState(null);
	const [loading, setLoading] = useState(false);

	const resetMessages = () => {
		setFormError(null);
		setFormSuccess(null);
	};

	const submit = async () => {
		resetMessages();
		const error = viewHelper.validateForm({ fromDate, toDate, t });

		if (error) {
			setFormError(error);
			return;
		}

		try {
			setLoading(true);
			const statistics = await fetchStatistics({
				producerId,
				dateType,
				fromDate,
				toDate,
			});
			await generateXLXS(statistics);
			setFormSuccess(true);
		} catch (error) {
			setFormError([
				{
					key: "UNKNOWN",
					text: t("unknownError", "An unknown error occurred when trying to fetch statistics"),
				},
			]);
			console.error(error);
		}
		setLoading(false);
	};

	return (
		<div>
			<Form onSubmit={submit} error={!!formError} success={!!formSuccess}>
				<Form.Field>
					<Radio
						label={t("doneDate", "Completion date")}
						name="radioGroup"
						value={dateTypes.STATISTICS_DATE}
						checked={dateType === dateTypes.STATISTICS_DATE}
						onChange={(_, { value }) => setDateType(value)}
					/>
				</Form.Field>
				<Form.Field>
					<Radio
						label={t("deliveryDate", "Delivery date")}
						name="radioGroup"
						value={dateTypes.DELIVERY_DATE}
						checked={dateType === dateTypes.DELIVERY_DATE}
						onChange={(_, { value }) => setDateType(value)}
					/>
				</Form.Field>
				<Form.Group>
					<Form.Field error={viewHelper.fieldHasError("FROM_DATE", formError)}>
						<label>{t("fromDate", "From date")}</label>
						<DateTime
							value={fromDate}
							isValidDate={viewHelper.isValidDate}
							dateFormat="YYYY-MM-DD"
							timeFormat={false}
							closeOnSelect={true}
							onChange={(date) => {
								if (!!formError || !!formSuccess) resetMessages();
								setFromDate(date);
							}}
						/>
					</Form.Field>
					<Form.Field error={viewHelper.fieldHasError("TO_DATE", formError)}>
						<label>{t("toDate", "To date")}</label>
						<DateTime
							value={toDate}
							isValidDate={viewHelper.isValidDate}
							dateFormat="YYYY-MM-DD"
							timeFormat={false}
							closeOnSelect={true}
							onChange={(date) => {
								if (!!formError || !!formSuccess) resetMessages();
								setToDate(date);
							}}
						/>
					</Form.Field>
				</Form.Group>
				<Message
					error
					header={t("errorMessageHeader", "Something went wrong")}
					content={
						<ul>
							{formError?.map((error) => {
								return <li key={error.key}>{error.text}</li>;
							})}
						</ul>
					}
				/>
				<Message
					success
					header={t("successMessageHeader", "Successfully fetched production statistics")}
					content={t("successMessageContent", "See downloaded files.")}
				/>
				{loading ? (
					<Loader active inline />
				) : (
					<Form.Button primary content={t("submitButton", "Download statistics")} />
				)}
			</Form>
			<Divider />
			<PredefinedQuestions />
		</div>
	);
}
