import { Label } from "semantic-ui-react";

import distributionStatusColors from "../../utils/distributionStatusColors";

import DistributionStatus from "./DistributionStatus";

export default function DistributionStatusLabel({ distribution, ...props }) {
	return (
		<Label color={distributionStatusColors[distribution.status]} {...props}>
			<DistributionStatus distribution={distribution} />
		</Label>
	);
}
