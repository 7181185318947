export const columns = {
	title: "title",
	series: "series",
	author: "author",
	status: "status",
	progressBar: "progressBar",
	noOfCorrections: "noOfCorrections",
	publisher: "publisher",
	publisherManagerNames: "publisherManagerNames",
	producer: "producer",
	productionDate: "productionDate",
	productionType: "productionType",
	productionTags: "productionTags",
	language: "language",
	readerNames: "readerNames",
	editorNames: "editorNames",
	prooferNames: "prooferNames",
	managerNames: "managerNames",
	subcontractorName: "subcontractorName",
	deliveryDate: "deliveryDate",
	duration: "duration",
	doneDate: "doneDate",
	scriptDate: "scriptDate",
	created: "created",
	updated: "updated",
	isbn: "isbn",
};

export const defaultColumnsPublisher = {
	series: false,
	author: false,
	producer: false,
	progressBar: false,
	publisherManagerNames: false,
	productionType: false,
	productionTags: false,
	isbn: false,
	language: false,
	readerNames: false,
	managerNames: false,
	subcontractorName: false,
	duration: false,
	scriptDate: false,
	doneDate: false,
	created: false,
	updated: false,
};

export const defaultColumnsProducer = {
	series: false,
	author: false,
	publisher: true,
	publisherManagerNames: false,
	progressBar: false,
	noOfCorrections: false,
	productionType: false,
	productionTags: false,
	isbn: false,
	language: false,
	readerNames: false,
	editorNames: false,
	prooferNames: false,
	managerNames: false,
	subcontractorName: false,
	duration: false,
	scriptDate: false,
	doneDate: false,
	created: false,
	updated: false,
};

export const defaultColumnsNarrator = {
	series: false,
	author: true,
	producer: false,
	publisher: false,
	progressBar: false,
	managerNames: false,
	scriptDate: true,
	duration: false,
};
