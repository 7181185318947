import * as Sentry from "@sentry/react";

import { useProfile } from "../../authentication/state/profile";

import SentryErrorFallback from "./SentryErrorFallback";

export default function SentryErrorBoundary(props) {
	const profile = useProfile();

	return (
		<Sentry.ErrorBoundary
			dialogOptions={{
				user: profile
					? {
							id: profile?.id,
							email: profile?.email,
							name: `${profile?.firstName} ${profile?.lastName}`,
					  }
					: null,
			}}
			fallback={(props) => {
				return <SentryErrorFallback {...props} />;
			}}
			{...props}
		/>
	);
}
