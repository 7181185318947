function getSessionTime(session, lang) {
	if (!session) {
		return null;
	}

	const { start, end } = session;

	const date = start.toDate().toLocaleDateString(lang);
	const startTime = start.toDate().toLocaleTimeString("sv").substring(0, 5);
	const endTime = end.toDate().toLocaleTimeString("sv").substring(0, 5);

	return `${date} (${startTime} - ${endTime})`;
}

function getSessionStudio(session) {
	return session?.studioData.name;
}

function getSessionReader(session) {
	return Object.values(session?.readerData || {})
		.map((reader) => reader.name)
		.join(", ");
}

function getSessionRecorder(session) {
	return session?.recorderData.name;
}

function getSessionChange(before, after) {
	if (!before || before === after) {
		return after;
	}

	return `<span style="color: #21ba45;">${after || "N/A"}</span> <i style="color: grey;">- from ${before}</i>`;
}

export default function getSessionInformation({ before, after, lang }) {
	return {
		lng: lang,
		productionTitle: after.productionData.title,
		time: getSessionChange(getSessionTime(before, lang), getSessionTime(after, lang)),
		studio: getSessionChange(getSessionStudio(before), getSessionStudio(after)),
		reader: getSessionChange(getSessionReader(before), getSessionReader(after)),
		recorder: getSessionChange(getSessionRecorder(before), getSessionRecorder(after)),
		productionLink: "https://astrid.fm/production/" + after.productionData.id,
	};
}
