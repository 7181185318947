import moment from "moment";
import { useTranslation } from "react-i18next";

import getAgreementStatus from "../../utils/getAgreementStatus";

export default function AgreementActiveStatus({ agreement }) {
	const { t } = useTranslation();
	const status = getAgreementStatus(agreement);

	const prefix =
		status === "inactive"
			? t("active", "Active")
			: status === "expired"
			? t("expired", "Expired")
			: t("expires", "Expires");

	const date = status === "inactive" ? agreement.activeFrom : agreement.expires;
	const fromNow = moment(date?.toDate()).fromNow();

	return (
		<>
			{prefix} {fromNow}
		</>
	);
}
