function fallbackCell(value) {
	if (value === 0) {
		return value;
	}
	return value || "";
}

export default function sheetMaker(statistics) {
	const columns = [
		"Statistics date (first done date/completion date)",
		"Production Id",
		"Publisher",
		"Publisher id",
		"Production company",
		"Production company id",
		"Narrator",
		"Author",
		"Completion date",
		"Delivery date",
		"Received date",
		"Script date",
		"ISBN",
		"Title",
		"Langugage",
		"Duration",
		"Duration in hours",
		"Turnaround (days)",
		"Production type",
		"Studio hours",
		"Ratio",
		"Loss",
		"Production tags",
		"Production Category",
		"Errors",
		"Production FTE hours",
		"Production non-FTE hours",
		"Narrator paid by",
		"Narrator cost",
		"Narrator FTE cost",
		"Subcontractor cost",
		"Rec eng cost",
		"Rec FTE cost",
		"Editor cost",
		"Editor FTE cost",
		"Proof cost",
		"Proof FTE cost",
		"Coordination cost",
		"Coordination FTE cost",
		"Cloud cost",
		"Other costs",
		"Other FTE costs",
		"Errors/h",
		"Narrator/h",
		"Narrator FTE/h",
		"Studio/h",
		"Rec/h",
		"Rec FTE/h",
		"Editor/h",
		"Editor FTE/h",
		"Proof/h",
		"Proof FTE/h",
		"Coordinator/h",
		"Coordinator FTE/h",
		"Cloud cost/h",
		"Other/h",
		"Other FTE/h",
		"Total cost",
		"Total cost/h",
		"Total cost FTE",
		"Total cost FTE/h",
		"Grand total cost",
		"Grand total cost/h",
		"Currency",
		"Statistics errors",
	];

	const rows = statistics
		.map((stat) => {
			const { production = {}, markers = {}, costs = {}, hoursWorked = {}, errors = {} } = stat;
			return [
				{ value: stat.statisticsDate, type: "string" },
				{ value: production.id, type: "string" },
				{ value: production.publisherName, type: "string" },
				{ value: production.publisher, type: "string" },
				{ value: production.producerName, type: "string" },
				{ value: production.producer, type: "string" },
				{ value: production.readerNames?.join(","), type: "string" },
				{ value: production.authorNames?.join(","), type: "string" },
				{ value: production.doneDate, type: "string" },
				{ value: production.deliveryDate, type: "string" },
				{ value: production.createdDate, type: "string" },
				{ value: production.scriptDate, type: "string" },
				{ value: production.isbn, type: "string" },
				{ value: production.title, type: "string" },
				{ value: production.language, type: "string" },
				{ value: production.deliveryDuration, type: "string" },
				{ value: production.deliveryDurationInHours, type: "string" },
				{ value: production.turnaround?.days, type: "number" },
				{ value: production.type, type: "string" },
				{ value: production.studioHours, type: production.studioHours === "n/a" ? "string" : "number" },
				{ value: production.ratio, type: "number" },
				{ value: production.loss, type: "number" },
				{ value: production.tags?.join(", "), type: "string" },
				{ value: production.category, type: "string" },
				{ value: markers.numberOfErrors, type: "number" },
				{ value: hoursWorked.employees, type: "number" },
				{ value: hoursWorked.nonEmployees, type: "number" },
				{ value: costs.narratorPaidBy, type: "string" },
				{ value: costs.reader?.value, type: "number" },
				{ value: costs.readerFTE?.value, type: "number" },
				{ value: costs.subcontractor?.value, type: "number" },
				{ value: costs.recorder?.value, type: "number" },
				{ value: costs.recorderFTE?.value, type: "number" },
				{ value: costs.editor?.value, type: "number" },
				{ value: costs.editorFTE?.value, type: "number" },
				{ value: costs.proofer?.value, type: "number" },
				{ value: costs.prooferFTE?.value, type: "number" },
				{ value: costs.coordinator?.value, type: "number" },
				{ value: costs.coordinatorFTE?.value, type: "number" },
				{ value: costs.cloud?.value, type: "number" },
				{ value: costs.other?.value, type: "number" },
				{ value: costs.otherFTE?.value, type: "number" },
				{ value: markers.numberOfErrorsPerHour, type: "number" },
				{ value: costs.reader?.byHour, type: "number" },
				{ value: costs.readerFTE?.byHour, type: "number" },
				{ value: costs.subcontractor?.byHour, type: "number" },
				{ value: costs.recorder?.byHour, type: "number" },
				{ value: costs.recorderFTE?.byHour, type: "number" },
				{ value: costs.editor?.byHour, type: "number" },
				{ value: costs.editorFTE?.byHour, type: "number" },
				{ value: costs.proofer?.byHour, type: "number" },
				{ value: costs.prooferFTE?.byHour, type: "number" },
				{ value: costs.coordinator?.byHour, type: "number" },
				{ value: costs.coordinatorFTE?.byHour, type: "number" },
				{ value: costs.cloud?.byHour, type: "number" },
				{ value: costs.other?.byHour, type: "number" },
				{ value: costs.otherFTE?.byHour, type: "number" },
				{ value: costs.totalCost?.value, type: "number" },
				{ value: costs.totalCost?.byHour, type: "number" },
				{ value: costs.totalCostFTE?.value, type: "number" },
				{ value: costs.totalCostFTE?.byHour, type: "number" },
				{ value: costs.grandTotalCost?.value, type: "number" },
				{ value: costs.grandTotalCost?.byHour, type: "number" },
				{ value: costs.baseCurrency, type: "string" },
				{ value: JSON.stringify(errors), type: "string" },
			];
		})
		.map((row) => {
			return row.map((cell) => ({
				value: fallbackCell(cell.value),
				type: cell.type,
			}));
		});

	return {
		columns,
		rows,
	};
}
