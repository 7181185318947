import Select from "../../../ui/components/Select/Select";

import useProductionTeam from "../../hooks/useProductionTeam";

export default function ProductionMemberUserSelect({ production, role, onChange, ...props }) {
	const team = useProductionTeam(production);

	const options = team
		.filter((member) => !role || member.role === role)
		.map((member) => ({
			key: member.user.id,
			text: member.user.name,
			value: member.user.id,
		}));

	return <Select closeOnChange options={options} onChange={onChange} {...props} />;
}
