import React from "react";
import { useTranslation } from "react-i18next";

import db from "astrid-firebase/src/db";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";

import ProducerExpenseForm from "./components/ProducerExpenseForm";
import ProducerRevenueForm from "./components/ProducerRevenueForm";
import PublisherExpenseForm from "./components/PublisherExpenseForm";
import useProductionPriceForm from "./hooks/useProductionPriceForm";
import submitProductionPrices from "./utils/submitProductionPrices";

function ExpenseFormSwitch({ firstParty }) {
	switch (firstParty?.type) {
		case "producer":
			return <ProducerExpenseForm firstParty={firstParty} />;
		case "publisher":
			return <PublisherExpenseForm firstParty={firstParty} />;
		default:
			return null;
	}
}

export default function ProductionPriceForm({ type, firstPartyId, production, price, ...props }) {
	const { t } = useTranslation();

	const form = useProductionPriceForm({ type, firstPartyId, production, price });

	const { watch, formState, handleSubmit } = form;

	const [addExpense, addRevenue] = watch(["addExpense", "addRevenue"]);

	const [firstParty, loading, error] = useDocumentDataOnce(
		firstPartyId && db.collection("organizations").doc(firstPartyId),
	);

	const onSubmit = ({ production, addExpense, addRevenue, expense, revenue }) => {
		return submitProductionPrices({
			production,
			firstParty,
			expenseData: addExpense && expense,
			revenueData: addRevenue && revenue,
		});
	};

	return (
		<ModalPrompt
			header={type === "revenue" ? t("addEarnings") : t("addCost")}
			disabled={!formState.isValid}
			onSave={handleSubmit(onSubmit)}
			{...props}
		>
			<LoadingContext data={firstParty} loading={loading} error={error}>
				<Form form={form}>
					{addExpense && <ExpenseFormSwitch firstParty={firstParty} />}
					{addRevenue && <ProducerRevenueForm disableAgreementPrices={addExpense} />}
				</Form>
			</LoadingContext>
		</ModalPrompt>
	);
}
