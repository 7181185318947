import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch";
import { Icon, Input } from "semantic-ui-react";

import useDebounce from "astrid-hooks/src/useDebounce";

import Flex from "../../../../../components/Flex/Flex";
import ClearQueryButton from "../../../../readers/components/ReaderSearchForm/components/ClearQueryButton";

import Stats from "./Stats";

export default function ArchiveSearchBar() {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState("");
	const { refine, clear } = useSearchBox();

	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	useEffect(() => {
		refine(debouncedSearchTerm);
	}, [debouncedSearchTerm, refine]);

	const customClear = useCallback(() => {
		setSearchTerm("");
		clear();
	}, [setSearchTerm, clear]);

	return (
		<div className="ui field">
			<Flex style={{ justifyContent: "stretch", alignItems: "center", gap: 15, marginBottom: 20 }}>
				<Input
					fluid
					value={searchTerm}
					iconPosition="left"
					labelPosition="right"
					placeholder={t("search")}
					onChange={(e, { value }) => setSearchTerm(value)}
					style={{ flex: 1 }}
					action
				>
					<Icon name="search" />
					<input />
					<Stats />
					<ClearQueryButton disabled={!searchTerm} onClick={customClear} />
				</Input>
			</Flex>
		</div>
	);
}
