import equal from "fast-deep-equal";
import { useEffect } from "react";

import { getItem, setItem } from "../../../../../utils/localStorage";
import useHasOrganizationRole from "../../../../authorization/hooks/useHasOrganizationRole";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import Form from "../../../../forms/components/Form/Form";

export default function useFilterForm({ user, organization }) {
	const hasOrganizationRole = useHasOrganizationRole({ organization, user });
	const hasRight = useHasRight();

	const isOnlyRecoder =
		!hasRight("calendar.defaultAllSessions") &&
		hasOrganizationRole("producerRecorder") &&
		!hasOrganizationRole("producerStaff");

	const defaultStatusFilters = {
		booked: true,
		completed: true,
		cancelled: false,
	};

	const defaultValues = {
		productions: [],
		readers: [],
		recorders: isOnlyRecoder ? [user.id, "noTechnicianBooked"] : [],
		managers: !hasRight("calendar.defaultAllSessions") && hasOrganizationRole("producerStaff") ? [user.id] : [],
		studios: [],
		statusFilters: defaultStatusFilters,
		hideUnavailable: isOnlyRecoder,
	};

	const form = Form.useForm({
		defaultValues: getItem("calendarFilters") || defaultValues,
	});

	const filters = form.watch();

	useEffect(() => {
		setItem("calendarFilters", filters);
	}, [filters]);

	const onClearStatusFilters = () => {
		form.setValue("statusFilters", {
			booked: false,
			completed: false,
			cancelled: false,
		});
	};

	const onResetStatusFilters = () => {
		form.setValue("statusFilters", defaultStatusFilters);
	};

	const clearFilters = () => {
		form.reset({
			productions: [],
			readers: [],
			recorders: [],
			managers: [],
			studios: [],
			statusFilters: filters.statusFilters,
			hideUnavailable: false,
		});
	};

	const resetFilters = () => {
		form.reset(defaultValues);
	};

	const isStatusFiltersActive = !equal(filters.statusFilters, defaultStatusFilters);

	const isFiltersActive = !equal(filters, defaultValues);

	const isNoEngineerBookedActive = filters.recorders.includes("noTechnicianBooked");

	const activeFilters =
		Object.values(filters).filter((filter) => filter.length > 0).length +
		(isStatusFiltersActive ? 1 : 0) +
		(filters.hideUnavailable ? 1 : 0);

	return {
		form,
		filters,
		activeFilters,
		isFiltersActive,
		isStatusFiltersActive,
		isNoEngineerBookedActive,
		onClearStatusFilters,
		onResetStatusFilters,
		clearFilters,
		resetFilters,
	};
}
