import api from "../../../../api";
import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import FirestoreDocumentContext from "../../../firebase/components/FirestoreDocumentContext";

import AdminArtifactView from "../../views/AdminArtifactView/AdminArtifactView";

export default function AdminArtifact() {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<FirestoreDocumentContext param="artifact" api={api.artifacts}>
			<AdminArtifactView />
		</FirestoreDocumentContext>
	);
}
