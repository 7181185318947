import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Header, Icon, Popup } from "semantic-ui-react";

import distConfig from "astrid-config/src/distributors";
import bookwire from "astrid-config/src/distributors/bookwire";
import exportOptions from "astrid-config/src/exportOptions";
import languages from "astrid-config/src/languages";
import { firebase } from "astrid-firebase";
import { withStore } from "astrid-web/src/helpers/context";

import CopyInput from "../CopyInput";

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

class PublisherDistribution extends Component {
	state = { distributionOptions: this.props.distributionOptions || {}, modified: false, language: null };

	componentDidMount() {
		if (this.props.distributors) {
			this.setState({ distributors: this.props.distributors.sort((a, b) => (a.id > b.id ? 1 : -1)) });
		} else if (this.props.singleLanguage) {
			this.loadDistributors(this.props.singleLanguage);
		}
	}

	getDistLangOptions = (distributionOptions) => {
		const lang = this.props.singleLanguage || this.state.language;
		let distLangOptions;

		// exists?
		const code = Object.keys(distributionOptions).find(
			(code) => distributionOptions[code].languages && distributionOptions[code].languages.includes(lang),
		);
		if (code) distLangOptions = distributionOptions[code];

		// new language setup
		if (!distLangOptions) {
			const newID = Date.now();
			distributionOptions[newID] = { languages: [lang] };
			distLangOptions = distributionOptions[newID];
		}

		return distLangOptions;
	};

	onDragEnd = (result) => {
		const { source, destination } = result;
		const distributionOptions = cloneDeep(this.state.distributionOptions);
		let distLangOptions = this.getDistLangOptions(distributionOptions);

		// get source from all distributors or from source list
		const sourceList =
			source.droppableId === "available" ? this.state.distributors : distLangOptions[source.droppableId];
		const id = source.droppableId === "available" ? sourceList[source.index].id : sourceList[source.index];

		// maybe not do anything?
		const isReordering = destination && destination.droppableId === source.droppableId;
		if (
			// dropped outside the list
			!destination ||
			// or reordered available list
			(isReordering && source.droppableId === "available") ||
			// or already in the list
			(!isReordering &&
				destination.droppableId !== "available" &&
				distLangOptions[destination.droppableId] &&
				distLangOptions[destination.droppableId].includes(id)) ||
			// or dist does not support article
			(distConfig[id].disableArticles && distConfig[id].disableArticles.includes(destination.droppableId))
		) {
			return;
		}

		// ok let's go
		if (source.droppableId !== destination.droppableId && destination.droppableId !== "available") {
			// add
			if (!distLangOptions[destination.droppableId]) distLangOptions[destination.droppableId] = [];
			distLangOptions[destination.droppableId].splice(destination.index, 0, id);

			// set default format?
			if (distConfig[id].format && distConfig[id].format !== "wav")
				set(
					distLangOptions,
					"settings." + destination.droppableId + "." + id + ".format",
					distConfig[id].format,
				);

			this.updateSetting(destination.droppableId, id, "format", distConfig[id].format);

			// unset disabled flag
			if (get(distLangOptions, "settings." + destination.droppableId + "." + id + ".disabled"))
				set(distLangOptions, "settings." + destination.droppableId + "." + id, {});
		} else if (destination.droppableId !== "available") {
			// reorder
			const newOrder = reorder(distLangOptions[destination.droppableId], source.index, destination.index);
			distLangOptions[destination.droppableId] = newOrder;
		}

		if (source.droppableId !== "available" && source.droppableId !== destination.droppableId) {
			// remove if dropped on available or other article
			distLangOptions[source.droppableId].splice(source.index, 1);

			// store disabled status in production override options
			if (this.props.productionPage)
				set(distLangOptions, "settings." + source.droppableId + "." + id, { disabled: true });
		}

		this.setState({ distributionOptions, modified: true });
		if (this.props.onChange) this.props.onChange(distLangOptions);
	};

	updateSetting = (article, dist, key, value) => {
		const distributionOptions = cloneDeep(this.state.distributionOptions);
		let distLangOptions = this.getDistLangOptions(distributionOptions);

		// new language setup
		if (!distLangOptions) {
			const newID = Date.now();
			distributionOptions[newID] = { languages: [this.props.singleLanguage || this.state.language] };
			distLangOptions = distributionOptions[newID];
		}

		// stupid way to set languages
		if (article === "languages") {
			set(distLangOptions, "languages", dist); // dist is actually data.value... sorry
		} else {
			// existing or new settings
			distLangOptions.settings = distLangOptions.settings || {};
			set(distLangOptions, "settings." + article + "." + dist + "." + key, value);
		}
		this.setState({ distributionOptions, modified: true });

		if (this.props.onChange) this.props.onChange(distLangOptions);
	};

	deleteSetting = () => {
		const distributionOptions = cloneDeep(this.state.distributionOptions);
		const code = Object.keys(distributionOptions).find(
			(code) =>
				distributionOptions[code].languages &&
				distributionOptions[code].languages.includes(this.state.language),
		);

		delete distributionOptions[code];

		this.setState({ distributionOptions, modified: true, language: null });
	};

	loadDistributors = (language) => {
		const publisherHasDistributors = firebase.functions().httpsCallable("publisherHasDistributors");

		publisherHasDistributors({
			producer: this.props.store?.state?.producerId || "HfvG4FQfXWpWv6dzqM5E",
			publisher: this.props.pid,
			language: language,
		}).then(({ data }) => {
			this.setState({
				distributors: Object.entries(data)
					.reduce((prev, curr) => {
						const [id, info] = curr;
						prev.push({ id, info });
						return prev;
					}, [])
					.sort((a, b) => (a.id > b.id ? 1 : -1)),
			});
		});
	};

	render() {
		const { t, singleLanguage, publisher, pid, productionPage, saveOptions, activeArticles } = this.props;
		const { distributionOptions, distributors, modified, language } = this.state;

		const selectedLanguage = singleLanguage || language;
		const distLangOptions = (selectedLanguage && this.getDistLangOptions(distributionOptions)) || {};

		const externalUpload = get(distLangOptions, "settings.external.upload.password");

		const availableLanguages = Object.keys(languages).filter(
			(code) =>
				!publisher.languageOptions ||
				!publisher.languageOptions.length ||
				publisher.languageOptions.includes(code) ||
				publisher.languageDefault === code,
		);

		return (
			<>
				{!productionPage && (
					<>
						<Header as="h4" icon="sitemap" content={t("distrPreChoice")} />
						<p>
							<em>{t("notifyPreChoice")}</em>
						</p>

						{!singleLanguage && (
							<Dropdown
								button
								className="icon"
								floating
								pointing
								labeled
								inline
								icon="flag"
								style={{ marginBottom: "1em" }}
								search
								deburr
								placeholder={t("prodLanguage")}
								name={"language"}
								value={null}
								options={availableLanguages.map((code) => ({
									key: code,
									value: code,
									text: t("language:" + code) || t("language:" + code).name,
								}))}
								onChange={(e, data) => {
									this.loadDistributors(data.value);

									// clean up unused language setups
									const distributionOptions = cloneDeep(this.state.distributionOptions);

									Object.keys(distributionOptions).forEach((opt) => {
										const optKeys = Object.keys(distributionOptions[opt]);
										if (
											optKeys.length === 1 &&
											distributionOptions[opt].languages &&
											distributionOptions[opt].languages.length === 1
										)
											delete distributionOptions[opt];
									});

									this.setState({ language: data.value, distributionOptions });
								}}
								onOpen={(e) => {
									// stupid hack to maintain width while selecting
									if (e && e.currentTarget)
										e.currentTarget.style.minWidth = e.currentTarget.offsetWidth + "px";
								}}
								onClose={(e) => {
									if (e && e.currentTarget && e.currentTarget.closest)
										e.currentTarget.closest(".dropdown").style.minWidth = "";
								}}
							/>
						)}

						{!productionPage && language && (
							<Form style={{ margin: "1em 0" }} autoComplete="new-password">
								<Form.Select
									search
									deburr
									multiple
									label={t("settingsForProdLang")}
									value={get(distLangOptions, "languages", [])}
									style={{ maxWidth: 670 }}
									autoComplete="new-password"
									options={availableLanguages
										.filter(
											(code) =>
												!Object.values(distributionOptions).find(
													(dop) =>
														dop.languages &&
														!dop.languages.includes(language) &&
														dop.languages.includes(code),
												),
										)
										.map((code) => ({
											key: code,
											value: code,
											text: t("language:" + code) || t("language:" + code).name,
											//(languages[code].sv || languages[code].name) +
											//" (" +
											//languages[code].nativeName +
											//")",
										}))}
									onChange={(e, data) => {
										console.log(data.value);
										if (!data.value.length) {
											if (window.confirm(t("prechoiceLangDelete"))) {
												this.deleteSetting();
											}
										} else {
											this.updateSetting("languages", data.value);

											if (!data.value.includes(language))
												this.setState({ language: data.value[0] });
										}
									}}
								/>
								<Checkbox
									label={t("allowExtUpload")}
									checked={typeof externalUpload === "string"}
									onChange={(e, data) => {
										this.updateSetting("external", "upload", "password", data.checked ? "" : false);
									}}
									style={{ marginBottom: "1em", fontWeight: "bold" }}
								/>
								{typeof externalUpload === "string" && (
									<>
										<Form.Input
											inline
											type="password"
											placeholder={t("password")}
											name="disableautocomplete"
											value={externalUpload}
											error={!externalUpload}
											onChange={(e, data) => {
												this.updateSetting("external", "upload", "password", data.value);
											}}
											autoComplete="new-password"
										/>
										<p>
											<em>{t("extProdCompUpload")}</em>
										</p>
										<CopyInput
											inline
											content={`https://astrid.fm/external/upload/${pid}/${language}`}
										/>
									</>
								)}
							</Form>
						)}

						{modified && (
							<Button
								primary
								content={t("saveSettings")}
								onClick={() => {
									saveOptions(distributionOptions);
									this.setState({ modified: false });
								}}
							/>
						)}
					</>
				)}

				{selectedLanguage && (
					<DragDropContext onDragEnd={this.onDragEnd}>
						<div className="flex-stack pubdist-articles">
							{Object.entries({
								available: t("available"),
								total: t("audioBook"),
								part: t("audiobookPart"),
								ebook: t("ebook"),
								cd: t("cd"),
								mp3cd: t("mp3cd"),
							})
								.filter(
									([article, articleName]) =>
										article === "available" || !activeArticles || activeArticles.includes(article),
								)
								.map(([article, articleName]) => (
									<div className={"pubdist-area"} key={article}>
										<b>{articleName}</b>
										<Droppable droppableId={article}>
											{(provided, snapshot) => {
												const draggingSource =
													snapshot.draggingOverWith &&
													snapshot.draggingOverWith.split("_")[0];
												const draggingDistributor =
													snapshot.draggingOverWith &&
													snapshot.draggingOverWith.split("_")[1];
												return (
													<div
														ref={provided.innerRef}
														className={
															"pubdist-area-drop" +
															(snapshot.isDraggingOver ? " over" : "") +
															// article is not available for distributor
															((distConfig[draggingDistributor] &&
																distConfig[draggingDistributor].disableArticles &&
																distConfig[
																	draggingDistributor
																].disableArticles.includes(article)) ||
															// article is already in list
															(article !== "available" &&
																article !== draggingSource &&
																distLangOptions[article] &&
																distLangOptions[article].includes(draggingDistributor))
																? " disabled"
																: "")
														}
													>
														{distributors &&
															(article === "available"
																? distributors
																		// .filter(({ id, info }) => {
																		// 	console.log(
																		// 		info,
																		// 		distConfig[id],
																		// 		get(distLangOptions, "languages", []),
																		// 	);

																		// 	return true;
																		// })
																		.sort((a, b) => (a.id > b.id ? 1 : -1))
																		.map(({ id, info }, di) => (
																			<Draggable
																				key={id}
																				draggableId={article + "_" + id}
																				index={di}
																			>
																				{(provided, snapshot2) => (
																					<div
																						ref={provided.innerRef}
																						{...provided.draggableProps}
																						{...provided.dragHandleProps}
																						style={
																							provided.draggableProps
																								.style
																						}
																						className={
																							"pubdist-distributor " +
																							info.credentials
																						}
																					>
																						{distConfig[id].name}{" "}
																						{distConfig[id].connection ? (
																							`(${distConfig[
																								id
																							].connection.toUpperCase()})`
																						) : (
																							<Popup
																								inverted
																								size="tiny"
																								content={t(
																									"manualTransaction",
																								)}
																								trigger={
																									<Icon name="download" />
																								}
																							/>
																						)}{" "}
																						{distConfig[id].audio !==
																							false && (
																							<Popup
																								inverted
																								size="tiny"
																								content={t(
																									"supportForAudioBooks",
																								)}
																								trigger={
																									<Icon
																										name={
																											"headphones"
																										}
																									/>
																								}
																							/>
																						)}{" "}
																						{distConfig[id].ebook !==
																							false &&
																							(!distConfig[id]
																								.disableArticles ||
																								!distConfig[
																									id
																								].disableArticles.includes(
																									"ebook",
																								)) && (
																								<Popup
																									inverted
																									size="tiny"
																									content={t(
																										"supportForEbooks",
																									)}
																									trigger={
																										<Icon
																											name={
																												"book"
																											}
																										/>
																									}
																								/>
																							)}{" "}
																						{distConfig[id].meta &&
																							!info.excludeMeta && (
																								<Popup
																									inverted
																									size="tiny"
																									content={t(
																										"supportForMetaData",
																									)}
																									trigger={
																										<Icon
																											name={
																												"clipboard"
																											}
																										/>
																									}
																								/>
																							)}
																						<small className="cred">
																							(
																							{info.credentials === "own"
																								? t("publishers")
																								: t("prodCompanys") +
																								  " "}
																							{t("details")})
																						</small>
																					</div>
																				)}
																			</Draggable>
																		))
																: (distLangOptions[article] || [])
																		.filter((option) => distConfig[option])
																		.map((option, i) => (
																			<Draggable
																				key={option}
																				draggableId={article + "_" + option}
																				index={i}
																			>
																				{(provided, snapshot) => (
																					<div
																						ref={provided.innerRef}
																						{...provided.draggableProps}
																						{...provided.dragHandleProps}
																						style={
																							provided.draggableProps
																								.style
																						}
																						className={
																							"pubdist-distributor " +
																							(
																								distributors.find(
																									(d) =>
																										option === d.id,
																								) || {
																									info: {
																										credentials:
																											"disabled",
																									},
																								}
																							).info.credentials
																						}
																					>
																						{distConfig[option].name}
																						<div className="details">
																							<ActiveDistributorOptions
																								article={article}
																								distId={option}
																								distributor={distributors.find(
																									(d) =>
																										option === d.id,
																								)}
																								settings={
																									get(
																										distLangOptions,
																										"settings." +
																											article +
																											"." +
																											option,
																									) || []
																								}
																								updateSetting={
																									this.updateSetting
																								}
																							/>
																						</div>
																					</div>
																				)}
																			</Draggable>
																		)))}
														{provided.placeholder}
													</div>
												);
											}}
										</Droppable>
									</div>
								))}
						</div>
					</DragDropContext>
				)}
			</>
		);
	}
}

const ActiveDistributorOptions = ({ article, distId, distributor, settings, updateSetting }) => {
	const { t } = useTranslation();

	return distributor ? (
		<div>
			<Checkbox
				label={t("automaticDelivery")}
				checked={!!settings.automatic}
				onChange={(e, data) => {
					updateSetting(article, distId, "automatic", data.checked);
				}}
			/>
			{article !== "ebook" ? (
				<>
					{distConfig[distId].meta && distConfig[distId].audio && (
						<Checkbox
							label={t("excludeMetadata")}
							disabled={!!distributor.info.excludeMeta}
							checked={!!settings.excludeMeta || !!distributor.info.excludeMeta}
							onChange={(e, data) => {
								updateSetting(article, distId, "excludeMeta", data.checked);
							}}
						/>
					)}
					{!distConfig[distId].meta && distConfig[distId].cover && (
						<Checkbox
							label={t("excludeCover")}
							disabled={!!distributor.info.excludeCover}
							checked={!!settings.excludeCover || !!distributor.info.excludeCover}
							onChange={(e, data) => {
								updateSetting(article, distId, "excludeCover", data.checked);
							}}
						/>
					)}
				</>
			) : (
				<div>{t("EpubAndCover")}</div>
			)}
			<br />
			{(article !== "ebook" && distConfig[distId].audio
				? Object.entries(exportOptions(article, article === "mp3cd" ? "mp3" : settings.format || "wav"))
				: []
			)
				.filter(([name, select]) => {
					return !select.disabled && !(name === "format" && distConfig[distId].format);
				})
				.concat(
					distId === "bookwire"
						? [
								[
									"shops",
									{
										label: "Kanaler",
										multiple: true,
										placeholder: "Välj kanaler",
										options: Object.entries(bookwire.shops).map(([key, { name }]) => ({
											key,
											value: key,
											text: name,
										})),
									},
								],
						  ]
						: [],
				)
				.map(([name, select]) => (
					<Dropdown
						inline
						key={name}
						multiple={select.multiple}
						placeholder={select.placeholder}
						value={settings[name] || select.default}
						style={{ maxWidth: 300 }}
						options={select.options
							.filter((option) => !option.hidden)
							.map((option) => {
								if (name === "part")
									option.text = t("exportPart" + option.key, {
										mins: "5-10",
									});

								return option;
							})}
						onChange={(e, data) => {
							updateSetting(article, distId, name, data.value);
						}}
					/>
				))}{" "}
			<br />
		</div>
	) : null;
};

const TranslatedPublisherDistribution = withTranslation(["common", "language"])(withStore(PublisherDistribution));

export default TranslatedPublisherDistribution;
