import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

export function sortTeam(a, b) {
	if (a.role === teamRoles.MANAGER && b.role !== teamRoles.MANAGER) {
		return -1;
	}

	if (a.role !== teamRoles.MANAGER && b.role === teamRoles.MANAGER) {
		return 1;
	}

	return a.user.name?.localeCompare(b.user.name);
}
