import { useCallback } from "react";
import { useFieldArray } from "react-hook-form";

import Table from "../../../ui/components/Table/Table";

import AgreementPricesFormActions from "./components/AgreementPricesFormActions";
import useAgreementPricesFormColumns from "./hooks/useAgreementPricesFormColumns";

export default function AgreementPricesFormTable({ part, template, maxPrices }) {
	const { fields, append, remove } = useFieldArray({
		name: "prices",
	});

	const onAdd = useCallback((lastPrice) => append({ ...lastPrice, name: "", maxUses: false }), [append]);
	const onRemove = useCallback(({ index }) => remove(index), [remove]);

	const pricesColumns = useAgreementPricesFormColumns({
		part,
		template,
		onRemove,
		maxPrices,
	});

	return <Table data={fields} columns={pricesColumns} footer={<AgreementPricesFormActions onAdd={onAdd} />} />;
}
