import { useRef, useState } from "react";
import useMountedState from "react-use/lib/useMountedState";

export default function useLoadingValue(initialLoading = false) {
	const initalState = useRef({
		loading: initialLoading,
		value: undefined,
		error: undefined,
	});

	const isMounted = useMountedState();
	const [state, setState] = useState(initalState.current);

	const updateState = (fn) => {
		if (isMounted()) {
			setState(fn);
		}
	};

	const reset = (loading = false) => {
		updateState({ ...initalState.current, loading });
	};

	const setError = (error) => {
		updateState((state) => ({ ...state, error, loading: false, value: undefined }));
	};

	const setValue = (value) => {
		updateState((state) => ({ ...state, value, loading: false, error: undefined }));
	};

	const setLoading = (loading) => {
		updateState((state) => ({ ...state, loading, error: undefined, value: undefined }));
	};

	return {
		...state,

		reset,
		setError,
		setValue,
		setLoading,
	};
}
