import { useSyncExternalStore } from "react";

import eventTarget from "./eventTarget";

export default function createStore(init = undefined, updater = undefined) {
	const events = eventTarget();

	const update = (v) => (updater ? updater(v) : v);

	const store = {
		state: init,
	};

	const getStore = (fb = undefined) => (store.state !== undefined ? store.state : fb);

	const setStore = (value) => {
		const prev = store.state;

		if (typeof value === "function") {
			value = value(prev);
		}

		store.state = update(value);

		events.emit("update", store.state, prev);
	};

	const useStore = (initialState) => {
		return useSyncExternalStore(
			(listener) => events.on("update", listener),
			() => (store.state !== undefined ? store.state : initialState),
		);
	};

	const onStore = (fn, once = false, first = false) => {
		let cleanup;

		const listener = (state, prev) => {
			if (once) {
				events.off("update", listener);
				fn(state, prev);
			} else {
				cleanup && cleanup();
				cleanup = fn(state, prev);
			}
		};

		const off = () => events.off("update", listener);

		events.on("update", listener, first);

		return off;
	};

	const resetStore = () => setStore(init);

	return [getStore, setStore, useStore, onStore, resetStore];
}
