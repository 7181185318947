import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Form, Header, Icon, Popup, Segment, Table } from "semantic-ui-react";

import { base } from "astrid-firebase";

class PublisherProductionMeta extends Component {
	addMeta = () => {
		const metaOptions = this.props.publisher.productionMeta || {};

		// use timestamp as unique key
		const key = +new Date();

		metaOptions[key] = { type: "checkbox" };
		base.updateDoc("organizations/" + this.props.pid, {
			productionMeta: metaOptions,
		});
	};

	updateMeta = (key, meta, val) => {
		const metaOptions = this.props.publisher.productionMeta;

		metaOptions[key][meta] = val;

		base.updateDoc("organizations/" + this.props.pid, {
			productionMeta: metaOptions,
		});
	};

	deleteMeta = (key) => {
		const { t } = this.props;
		if (window.confirm(t("warningInfoWillBeDeleted"))) {
			console.log("bort", key);
			const metaOptions = this.props.publisher.productionMeta;

			delete metaOptions[key];

			base.updateDoc("organizations/" + this.props.pid, {
				productionMeta: metaOptions,
			});
		}
	};

	render() {
		const metaOptions =
			this.props.publisher && this.props.publisher.productionMeta ? this.props.publisher.productionMeta : {};

		// sort based on sorting number
		const sortedArray = Object.keys(metaOptions).sort((a, b) =>
			(metaOptions[a].sort + 1 || a) > (metaOptions[b].sort + 1 || b) ? 1 : -1,
		);

		const { t } = this.props;

		return (
			<Segment padded>
				<Header as="h4" icon="checkmark box" content={t("extraProdField")} />
				<Table basic="very" compact sortable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("name")}</Table.HeaderCell>
							<Table.HeaderCell>{t("type")}</Table.HeaderCell>
							<Table.HeaderCell>{t("alternatives")}</Table.HeaderCell>
							<Table.HeaderCell>{t("visibleForProdComp")}</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{sortedArray.map((key, i) => (
							<Table.Row key={key}>
								<Table.Cell>
									<Form.Input
										placeholder={t("forExampleDownload")}
										value={metaOptions[key].name || ""}
										onChange={(e, data) => this.updateMeta(key, "name", data.value)}
										style={{ float: "left" }}
									/>
									{!!i && (
										<div style={{ float: "left" }}>
											<Icon
												name="circle arrow up"
												size="large"
												onClick={() => {
													const above = sortedArray[i - 1];
													console.log(key, i, metaOptions[key].name, metaOptions[above].name);
													this.updateMeta(key, "sort", i - 1);
													this.updateMeta(above, "sort", i);
												}}
												style={{ cursor: "pointer", marginTop: 9 }}
											/>
										</div>
									)}
								</Table.Cell>
								<Table.Cell>
									<Form.Select
										// compact
										value={metaOptions[key].type}
										options={[
											{ text: t("checkbox"), value: "checkbox" },
											{ text: t("alternatives"), value: "select" },
											{ text: t("freeText"), value: "text" },
											{ text: t("file"), value: "file" },
											{ text: t("date"), value: "date" },
										]}
										onChange={(e, data) => this.updateMeta(key, "type", data.value)}
									/>
								</Table.Cell>
								<Table.Cell>
									{metaOptions[key].type === "text" && (
										<>
											<Form.Radio
												label={t("oneRow")}
												value="single"
												checked={!metaOptions[key].textRows}
												name={"textRows" + key}
												onChange={(e, data) => this.updateMeta(key, "textRows", false)}
											/>
											<Form.Radio
												label={t("multiRows")}
												value="multi"
												checked={metaOptions[key].textRows}
												name={"textRows" + key}
												onChange={(e, data) => this.updateMeta(key, "textRows", true)}
											/>
										</>
									)}

									{["file", "date"].includes(metaOptions[key].type) && (
										<div>{t("noAlternatives")}</div>
									)}

									{metaOptions[key].type === "select" && (
										<Form.Select
											multiple
											allowAdditions
											additionLabel={t("add") + " "}
											noResultsMessage={null}
											search
											value={metaOptions[key].selectOptions || []}
											options={
												metaOptions[key].selectOptions
													? metaOptions[key].selectOptions.map((option) => ({
															key: option,
															text: option,
															value: option,
													  }))
													: []
											}
											onChange={(e, data) => this.updateMeta(key, "selectOptions", data.value)}
										/>
									)}

									{metaOptions[key].type === "checkbox" && (
										<Popup
											trigger={
												<span style={{ cursor: "pointer" }}>
													<Button basic compact>
														<Icon
															fitted
															color={metaOptions[key].color || "green"}
															size="large"
															name={metaOptions[key].icon || "checkmark"}
															style={{ verticalAlign: "text-bottom" }}
														/>
														{t("iconAndColor")}
													</Button>
												</span>
											}
											content={
												<div>
													<h4>{t("icon")}</h4>
													{[
														"checkmark",
														"heart",
														"pin",
														"privacy",
														"announcement",
														"help circle",
														"travel",
														"rocket",
														"lightning",
														"inbox",
														"cloud upload",
														"file audio outline",
														"alarm",
														"clock",
														"photo",
														"sticky note",
														"asterisk",
														"calendar",
														"attach",
														"dollar",
														"line chart",
													].map((option) => (
														<Icon
															key={option}
															color={metaOptions[key].color || "green"}
															name={option}
															size="large"
															onClick={() => this.updateMeta(key, "icon", option)}
														/>
													))}
													<h4>{t("color")}</h4>

													{[
														"red",
														"orange",
														"yellow",
														"olive",
														"green",
														"teal",
														"blue",
														"violet",
														"purple",
														"pink",
														"brown",
														"black",
													].map((color) => (
														<Icon
															key={color}
															color={color}
															name={"square"}
															size="large"
															onClick={() => this.updateMeta(key, "color", color)}
														/>
													))}
												</div>
											}
											on="click"
											position="bottom left"
										/>
									)}
								</Table.Cell>
								<Table.Cell>
									{t("negative")}
									<span>
										<Checkbox
											toggle
											label={t("yes")}
											style={{ marginLeft: "1em" }}
											checked={metaOptions[key].prodVisible}
											onChange={(e, data) =>
												this.updateMeta(key, "prodVisible", !metaOptions[key].prodVisible)
											}
										/>
									</span>
								</Table.Cell>
								<Table.Cell>
									<a
										href="/"
										onClick={(e) => {
											e.preventDefault();
											this.deleteMeta(key);
										}}
									>
										<Icon name="trash alternate" color="black" />
									</a>
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
				<Button content={t("addInfoField")} primary onClick={this.addMeta} />
			</Segment>
		);
	}
}

export default withTranslation()(PublisherProductionMeta);
