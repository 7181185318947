import { useTranslation } from "react-i18next";

import { addUser } from "astrid-firebase/src/users";

import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";

import useUserForm from "./hooks/useUserForm";

export default function AddReaderForm({ language, firstName, lastName, afterSubmit, onClose }) {
	const { t } = useTranslation();

	const form = useUserForm({ firstName, lastName });

	const { formState, handleSubmit } = form;

	const onSubmit = async ({ firstName, lastName, email }) => {
		try {
			const user = await addUser({ firstName, lastName, email, language, reader: true });

			afterSubmit?.(user);
		} catch (error) {
			if (error?.code === "auth/email-already-in-use") {
				throw new Error(t("userExistsMessage", "This user already exists. Contact an admin for help."));
			}

			throw error;
		}
	};

	return (
		<ModalPrompt
			size="tiny"
			header={t("addReader", "Add reader")}
			disabled={!formState.isValid}
			onClose={onClose}
			onSave={handleSubmit(onSubmit)}
			confirmText={t("addToVoice", "Add to voice")}
		>
			<Form form={form}>
				<Form.Group widths="equal">
					<Form.Input name="firstName" label={t("firstName")} />
					<Form.Input name="lastName" label={t("lastName")} />
				</Form.Group>

				<Form.Input
					name="email"
					label={t("email")}
					placeholder={t("leaveEmptyMessage", "Leave empty if you don't want to send an invitation")}
					rules={{ validate: (value) => !value || value?.includes("@") }}
				/>
			</Form>
		</ModalPrompt>
	);
}
