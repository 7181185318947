import React from "react";
import { FormProvider as ReactHookFormProvider, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form as SemanticForm } from "semantic-ui-react";

import FormPhoneInput from "astrid-web/src/features/forms/components/FormPhoneInput/FormPhoneInput";

import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import useForm from "../../hooks/useForm";
import useFormField from "../../hooks/useFormField";
import usePartialFormValidation from "../../hooks/usePartialFormValidation";

import FormAutoSave from "../FormAutoSave/FormAutoSave";
import FormCheckbox from "../FormCheckbox/FormCheckbox";
import FormDateInput from "../FormDateInput/FormDateInput";
import FormDurationInput from "../FormDurationInput/FormDurationInput";
import FormField from "../FormField/FormField";
import FormInput from "../FormInput/FormInput";
import FormNumberInput from "../FormNumberInput/FormNumberInput";
import FormRadio from "../FormRadio/FormRadio";
import FormDocumentSelect from "../FormSelect/FormDocumentSelect";
import FormSelect from "../FormSelect/FormSelect";
import FormTextArea from "../FormTextArea/FormTextArea";
import FormUploadButton from "../FormUploadButton/FormUploadButton";

export function FormProvider({ form, ...props }) {
	return <ReactHookFormProvider {...form} {...props} />;
}

export function FormForm(props) {
	return <SemanticForm as="div" {...props} />;
}

export default function Form({ form, error, ...props }) {
	const { t } = useTranslation();

	return (
		<FormProvider {...form}>
			<FormForm {...props} />

			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</FormProvider>
	);
}

// Form
Form.Form = FormForm;
Form.FormProvider = FormProvider;
Form.AutoSave = FormAutoSave;

// Semantic UI
Form.Group = SemanticForm.Group;
Form.SemanticField = SemanticForm.Field;

// Fields
Form.Checkbox = FormCheckbox;
Form.DateInput = FormDateInput;
Form.DocumentSelect = FormDocumentSelect;
Form.Field = FormField;
Form.Input = FormInput;
Form.NumberInput = FormNumberInput;
Form.DurationInput = FormDurationInput;
Form.PhoneInput = FormPhoneInput;
Form.Radio = FormRadio;
Form.Select = FormSelect;
Form.TextArea = FormTextArea;
Form.UploadButton = FormUploadButton;

// Hooks
Form.useFieldArray = useFieldArray;
Form.useForm = useForm;
Form.useFormContext = useFormContext;
Form.useFormField = useFormField;
Form.usePartialFormValidation = usePartialFormValidation;
