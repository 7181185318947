import { useMemo } from "react";

export default function useTotalCost(costs, reversed) {
	return useMemo(() => {
		const currency = costs?.[0]?.price?.currency;
		const rate = costs?.[0]?.exchange?.rate;

		if (currency && rate) {
			const sameCurrencyCosts = costs.filter((cost) => cost.price?.currency === currency);
			const diffCurrencyCosts = costs.filter((cost) => cost.price?.currency !== currency);

			const sameCurrencyTotal = sameCurrencyCosts.reduce(
				(total, cost) => total + (cost.total || 0) * (cost.expense ? -1 : 1),
				0,
			);

			const diffCurrencyTotal =
				diffCurrencyCosts.reduce(
					(total, cost) => total + (cost.exchange?.value || 0) * (cost.expense ? -1 : 1),
					0,
				) * rate;

			const total = (sameCurrencyTotal + diffCurrencyTotal) * (reversed ? -1 : 1);

			return [total, currency];
		}

		return [];
	}, [costs, reversed]);
}
