import { defaultColumnsNarrator, defaultColumnsProducer, defaultColumnsPublisher } from "../constants";

export default function getInitialSelectedColumns(type) {
	switch (type) {
		default:
			return {};
		case "producer":
			return {
				storageKey: "myProductionsProducer",
				defaults: defaultColumnsProducer,
			};
		case "publisher":
			return {
				storageKey: "myProductionsPublisher",
				defaults: defaultColumnsPublisher,
			};
		case "narrator":
			return {
				storageKey: "myProductionsNarrator",
				defaults: defaultColumnsNarrator,
			};
	}
}
