import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminActions from "../../../admin/components/AdminActions/AdminActions";

import PriceInfo from "./components/PriceInfo";

export default function SuperAdminProductionPriceView({ id, productionId }) {
	const [price, loading, error] = useDocumentData(
		id && productionId && db.collection("productions").doc(productionId).collection("productionPrices").doc(id),
	);

	return (
		<LoadingContext data={price} loading={loading} error={error}>
			{price && (
				<Card.Group>
					<PriceInfo price={price} />
					<AdminActions data={price}>
						<AdminActions.ApprovalAction right="prices.approve" />
						<AdminActions.DeletionAction right="costs.delete" />
					</AdminActions>
				</Card.Group>
			)}
		</LoadingContext>
	);
}
