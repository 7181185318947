import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { Icon, Message } from "semantic-ui-react";

import VerticalMenu from "../../ui/components/VerticalMenu/VerticalMenu";

import useHasRight from "../../authorization/hooks/useHasRight";

export default function OrganizationAdminSidebar({ options }) {
	const { t } = useTranslation();
	const { state } = useLocation();
	const { organization } = useOutletContext();

	const [returnPath, setReturnPath] = useState();

	const hasRight = useHasRight();

	useEffect(() => {
		if (state?.pathname) {
			setReturnPath(state.pathname);
		}
	}, [state]);

	return (
		<>
			{hasRight("superadmin") && (
				<Link to={returnPath || "/admin"}>
					<Icon name="left chevron" />
					{t("superAdmin", "Super admin")}
				</Link>
			)}
			<Message>
				<Message.Header>{organization?.name}</Message.Header>
			</Message>
			<VerticalMenu options={options} />
		</>
	);
}
