import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import Popup from "../../../../ui/components/Popup/Popup";

export default function TodoResolvedIcon({ onClick, showResolved }) {
	const { t } = useTranslation();

	return (
		<div>
			<Popup
				content={showResolved ? t("hideResolved", "Hide resolved") : t("showResolved", "Show resolved")}
				trigger={<Icon link color={showResolved ? "green" : null} name={"check circle"} onClick={onClick} />}
			/>
		</div>
	);
}
