import { db } from "astrid-firebase";
import { getDocumentData } from "astrid-firestore/src/helpers";
import createStore from "astrid-helpers/src/createStore";

import { localStorage } from "../../../helpers/fnc";

import { getUser, onUser, setUser } from "./user";

export const [getActAsUser, setActAsUser, useActAsUser, onActAsUser, resetActAsUser] = createStore();

onUser(() => {
	const uid = localStorage("actAsUser", "uid");

	if (!getActAsUser() && uid) {
		setTimeout(() => setActAsUser(uid), 50);
	}
});

onActAsUser((uid) => {
	if (uid) {
		let abort = false;
		const user = getUser();

		(async () => {
			const actAsUser = await getDocumentData(db.collection("users").doc(uid));

			if (actAsUser && !abort) {
				setUser({ uid });
				localStorage("actAsUser", "uid", uid);
			} else {
				localStorage("actAsUser", "uid", null);
			}
		})();

		return () => {
			abort = true;
			localStorage("actAsUser", "uid", null);
			setUser(user);
		};
	}
});
