import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import useHasRight from "../../authorization/hooks/useHasRight";
import PricesForm from "../../prices/components/PricesForm/PricesForm";

import AdminHeader from "../components/AdminHeader/AdminHeader";

export default function CreatePrices() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const navigate = useNavigate();
	const hasRight = useHasRight();

	if (!hasRight("prices.create")) {
		return false;
	}

	return (
		<>
			<AdminHeader>{t("addPrices", "Add prices")}</AdminHeader>

			<PricesForm organization={organization} afterSubmit={() => navigate("../")} />
		</>
	);
}
