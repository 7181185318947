import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import ArticleBundlesCollectionDataTable from "../../../articles/components/ArticleBundlesCollectionDataTable/ArticleBundlesCollectionDataTable";

export default function AdminArticleArticleBundlesView({ article }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("bundles", "Bundles")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<ArticleBundlesCollectionDataTable
					getQuery={(query) => query.where("articleIds", "array-contains", article.id)}
				/>
			</Card.Content>
		</Card>
	);
}
