import { Button } from "semantic-ui-react";

import useHasRight from "../../../authorization/hooks/useHasRight";

import CreateAgreementTemplateAction from "./components/CreateAgreementTemplateAction";

export default function AgreementTemplatesActions() {
	const hasRight = useHasRight();

	return (
		<Button.Group basic secondary>
			{hasRight("agreements.create") && <CreateAgreementTemplateAction />}
		</Button.Group>
	);
}
