import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

import InquiryOfferDropdownMenu from "./components/InquiryOfferDropdownMenu";
import OpenOfferDropdownMenu from "./components/OpenOfferDropdownMenu";
import WorkOrderDropdownMenu from "./components/WorkOrderDropdownMenu";

export default function OfferDropdownMenu({ offer, production }) {
	switch (offer.type) {
		case offerTypes.OPEN:
			return <OpenOfferDropdownMenu offer={offer} production={production} />;
		case offerTypes.WORK_ORDER:
			return <WorkOrderDropdownMenu offer={offer} production={production} />;
		case offerTypes.INQUIRY:
			return <InquiryOfferDropdownMenu offer={offer} production={production} />;
		default:
			return null;
	}
}
