import { priceTypeFromRole } from "astrid-firestore/src/api/prices/utils/priceMappers";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import useForm from "../../../../forms/hooks/useForm";

import offerDefaultValues from "../../../constants/offerDefaultValues";

const today = new Date();

export default function useInquiryOfferForm({ offer, firstParty }) {
	return useForm({
		defaultValues: {
			expiresAt: new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() + offerDefaultValues.EXPIRES_IN_DAYS,
			),
			price: {
				type: priceTypeFromRole[teamRoles.READER] || null,
				unit: offer?.price?.unit || null,
				price: offer?.price?.price || 0,
				currency: offer?.price?.currency || firstParty?.currencyDefault || null,
			},
			optionalMessage: offer?.optionalMessage || "",
			optionalMessageSender: null,
			discussionVisibleForPublisher: false,
		},
	});
}
