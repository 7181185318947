import { useInstantSearch } from "react-instantsearch";

import CustomSearchBox from "./CustomSearchBox";

export default function MainSearchBox() {
	const {
		indexUiState: { query },
	} = useInstantSearch();

	return <CustomSearchBox value={query} />;
}
