import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

export default function AgreementTemplateNameForm() {
	const { t } = useTranslation();

	return (
		<ContentCard
			header={t("templateName", "Template name")}
			description={t(
				"templateNameDescription",
				"This name is only for internal use and will not be visible in the final agreement.",
			)}
		>
			<Form.Form>
				<Form.Input name="name" size="large" rules={{ required: true, min: 5 }} />
			</Form.Form>
		</ContentCard>
	);
}
