import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function DistributionsCollectionDataTable({ getQuery = (query) => query }) {
	const navigate = useNavigate();

	const columns = useDistributionsTableColumns();

	return (
		<CollectionDataTable
			query={getQuery(db.collection("distributions").where("deleted", "==", false).orderBy("created", "desc"))}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/distributions/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
		/>
	);
}
