import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminReadersView from "../views/AdminReadersView/AdminReadersView";

export default function AdminReaders() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("users.view")) {
		return <Unauthorized />;
	}

	return <AdminReadersView organization={organization} />;
}
