import { db } from "astrid-firebase";

const date = new Date();
date.setDate(date.getDate() - 2);

export default function useTodosQuery({ userId, organizationId, resolved }) {
	let query = db.collection("todos").where("notification", "==", false);

	if (resolved) {
		query = query.where("resolved.status", "==", true).where("resolved.timestamp", ">=", date);
	} else {
		query = query.where("resolved.status", "==", false);
	}

	if (organizationId) {
		return query.where("organizationIds", "array-contains", organizationId);
	}

	return query.where("userIds", "array-contains", userId);
}
