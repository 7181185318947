import sessionOfferTypes from "../../constants/sessionOfferTypes";
import { teamRoles } from "../../constants/teamRoles";
import getCollectionData from "../../helpers/getCollectionData";
import getDocumentData from "../../helpers/getDocumentData";
import runBatch from "../../helpers/runBatch";
import { getSessionRef } from "../../utils/referenceMappers";

import { priceTypeFromRole } from "../prices/utils/priceMappers";
import createProductionPriceData from "../productions/prices/createProductionPriceData";

const batchEnabled = true;

function setBatch({ batch, ref, data }) {
	if (batchEnabled) {
		batch.set(ref, data);
	} else {
		console.log("Setting batch", data);
	}
}

function updateBatch({ batch, ref, data }) {
	if (batchEnabled) {
		batch.update(ref, data);
	} else {
		console.log("Updating batch", data);
	}
}

function getTeamMembersMissingPrice({ team }) {
	return Object.values(team || {}).filter((teamMember) => teamMember.user?.ref && !teamMember?.price);
}

async function getDefaultUserPrice({ production, teamMember }) {
	if (!teamMember.user.ref || !teamMember.user.id) {
		return { error: true, message: `User ${teamMember.user?.name} is missing a user reference` };
	}
	const user = await getDocumentData(teamMember.user.ref);
	const defaultUserPrice = user?.prices?.[production.producer]?.[`${production.language}-${teamMember.role}`];
	if (!defaultUserPrice) {
		return {
			error: true,
			message: `User ${teamMember.user?.name} is missing a default price for role ${teamMember.role}`,
		};
	}
	if (!defaultUserPrice.price || !defaultUserPrice.agreement) {
		return { error: true, message: `User ${teamMember.user?.name} does not have a valid default price` };
	}

	return defaultUserPrice;
}

async function setPriceForOtherTeamMembers(firebase, { production }) {
	const result = [];
	const teamMembersMissingPrice = getTeamMembersMissingPrice({ team: production?.team }).filter(
		(teamMember) => ![teamRoles.READER, teamRoles.RECORDER].includes(teamMember?.role),
	);

	if (teamMembersMissingPrice.length) {
		await runBatch(firebase.firestore(), async (batch) => {
			for (const teamMember of teamMembersMissingPrice) {
				const defaultUserPrice = await getDefaultUserPrice({ production, teamMember });

				if (!defaultUserPrice.error) {
					const productionPrice = createProductionPriceData(firebase, {
						...defaultUserPrice.price,
						expense: true,
						approved: true,
						producer: production.producer,
						production,
						agreement: defaultUserPrice.agreement,
						firstParty: defaultUserPrice.agreement?.firstParty,
						secondParty: defaultUserPrice.agreement?.secondParty,
						referencePrice: defaultUserPrice.price,
					});
					setBatch({ batch, ref: productionPrice.ref, data: productionPrice });
					updateBatch({
						batch,
						ref: production.ref,
						data: {
							[`team.${teamMember.id}`]: {
								...teamMember,
								price: productionPrice,
								agreement: defaultUserPrice.agreement,
							},
						},
					});
					result.push({
						error: false,
						message: `Added price for ${teamMember.role} ${teamMember?.user?.name}`,
					});
				} else {
					result.push(defaultUserPrice);
				}
			}
		});
	}
	return result.length ? result : [{ error: false, message: "No action was necessary" }];
}

async function setPriceForRecorders(firebase, { production }) {
	const db = firebase.firestore();
	const result = [];
	const recordersMissingPrice = getTeamMembersMissingPrice({ team: production?.team }).filter(
		(teamMember) => teamMember?.role === teamRoles.RECORDER,
	);

	const recordersWithPrice = Object.values(production?.team || {}).filter(
		(teamMember) => teamMember.role === teamRoles.RECORDER && teamMember?.price,
	);

	const recorderPricesToAdd = await Promise.all(
		recordersMissingPrice.map(async (recorder) => {
			const defaultPrice = await getDefaultUserPrice({ production, teamMember: recorder });
			if (!defaultPrice.error) {
				return {
					...recorder,
					price: defaultPrice.price,
					agreement: defaultPrice.agreement,
				};
			} else {
				result.push(defaultPrice);
				return {
					...recorder,
				};
			}
		}),
	);

	const completeRecorderTeam = [...recorderPricesToAdd, ...recordersWithPrice];
	const sessions = await getCollectionData(db.collection("sessions").where("production", "==", production.id));
	const sessionsMissingPrice = sessions.filter(
		(session) =>
			![sessionOfferTypes.ANY_RECORDER_IN_TEAM, sessionOfferTypes.OPEN_POSITION_RECORDER, null].includes(
				session.recorder,
			) && !session.priceRef,
	);

	await runBatch(db, async (batch) => {
		if (sessionsMissingPrice.length) {
			for (const session of sessionsMissingPrice) {
				const sessionRecorder = completeRecorderTeam.find(
					(recorderInTeam) => recorderInTeam?.user?.id === session.recorder,
				);
				if (!sessionRecorder) {
					result.push({
						error: true,
						message: `There is a session (${
							session.id
						}) missing price for this production with a recorder that is not part of the team (name: ${
							session.recorderData?.name
						}, userid: ${session.recorder}, date: ${
							session.start?.toDate()?.toLocaleDateString("sv") || ""
						})`,
					});
					continue;
				}

				if (!sessionRecorder.price) {
					result.push({
						error: true,
						message: `Could not add price to session (${session.id}) since ${sessionRecorder.user?.name} is missing default price`,
					});
				}
				const productionPrice = createProductionPriceData(firebase, {
					...sessionRecorder.price,
					expense: true,
					approved: true,
					producer: session.producer,
					production,
					agreement: sessionRecorder.agreement,
					firstParty: sessionRecorder.agreement?.firstParty,
					secondParty: sessionRecorder.agreement?.secondParty,
					referencePrice: sessionRecorder.price,
					session: getSessionRef(session),
				});

				setBatch({ batch, ref: productionPrice.ref, data: productionPrice });
				updateBatch({
					batch,
					ref: session.ref,
					data: {
						priceId: sessionRecorder.id,
						priceRef: sessionRecorder?.price,
						agreementRef: sessionRecorder?.agreement,
						productionPriceRef: productionPrice?.ref,
					},
				});
				result.push({
					error: false,
					message: `Updated session ${session.id} with price for recorder ${sessionRecorder.user?.name}`,
				});
			}
		}

		if (recorderPricesToAdd.length) {
			for (const recorder of recorderPricesToAdd) {
				if (recorder.price) {
					updateBatch({
						batch,
						ref: production.ref,
						data: {
							[`team.${recorder.id}`]: {
								...recorder,
								price: recorder.price,
								agreement: recorder.agreement,
							},
						},
					});
					result.push({ error: false, message: `Added default price for recorder ${recorder?.user?.name}` });
				}
			}
		}
	});

	return result.length ? result : [{ error: false, message: "No action was necessary" }];
}

async function setPriceForReaders(firebase, { production }) {
	const result = [];
	const db = firebase.firestore();
	const readersMissingPrice = getTeamMembersMissingPrice({ team: production?.team }).filter(
		(teamMember) => teamMember?.role === teamRoles.READER,
	);

	if (!readersMissingPrice.length) {
		return [{ error: false, message: "No action was necessary" }];
	}

	const firstPartyOrgId = production?.readerContractedByProducer ? production.producer : production.publisher;
	const firstParty = await getDocumentData(db.collection("organizations").doc(firstPartyOrgId));

	await runBatch(db, async (batch) => {
		for (const reader of readersMissingPrice) {
			const events = await getCollectionData(
				db
					.collection("productions")
					.doc(production.id)
					.collection("events")
					.where(`data.readerStatus.${reader.user.id}`, ">", {}),
			);

			if (!events.length) {
				result.push({
					error: true,
					message: `Could not find historic prices for reader ${reader?.user?.name}`,
				});
				continue;
			}

			const readerStatus = events
				.sort((a, b) => b?.time?.toDate() - a?.time?.toDate())
				.map((e) => {
					return e.data.readerStatus;
				})?.[0]?.[reader.user.id];

			if (!readerStatus.fee || !readerStatus.unit || !readerStatus.currency) {
				result.push({
					error: true,
					message: `Found historic price but with insufficient data for reader ${reader?.user?.name}`,
				});
				continue;
			}

			const price = {
				price: readerStatus.fee,
				unit: readerStatus.unit,
				currency: readerStatus.currency,
				type: priceTypeFromRole.reader,
			};

			const productionPrice = createProductionPriceData(firebase, {
				...price,
				expense: true,
				approved: true,
				producer: production.producer,
				production,
				firstParty,
				secondParty: reader?.user,
				referencePrice: price,
			});

			setBatch({ batch, ref: productionPrice.ref, data: productionPrice });
			updateBatch({
				batch,
				ref: production.ref,
				data: {
					[`team.${reader.id}`]: {
						...reader,
						price: productionPrice,
					},
				},
			});
			result.push({
				error: false,
				message: `Added price (${readerStatus.fee} ${readerStatus.currency}) for reader ${reader?.user?.name}`,
			});
		}
	});
	return result;
}

export default async function applyDefaultPricesForProduction(firebase, { productionId }) {
	const db = firebase.firestore();
	const production = await getDocumentData(db.collection("productions").doc(productionId));

	const recorderPrices = await setPriceForRecorders(firebase, { production });
	const readerPrices = await setPriceForReaders(firebase, { production });
	const otherPrices = await setPriceForOtherTeamMembers(firebase, { production });

	return {
		recorderPrices,
		readerPrices,
		otherPrices,
	};
}
