import { db } from "astrid-firebase";
import { addUser } from "astrid-firebase/src/users";
import { getDocumentData, updateDocument } from "astrid-firestore/src/helpers";

export default async function submitUser({ organization, userId, firstName, lastName, email, reader }) {
	if (userId === "new") {
		return await addUser({ organization, firstName, lastName, email, reader });
	} else {
		const user = await getDocumentData(db.collection("users").doc(userId));

		const { employee = false, languageOptions = Object.keys(user.languages || {}) } =
			user[`${organization.type}s`]?.[organization.id] || {};

		await updateDocument(user.ref, {
			[`permissions.${organization.type}Index`]: true,
			[`permissions.${organization.type}.${organization.id}`]: ["any"],
			[`${organization.type}s.${organization.id}.employee`]: employee,
			[`${organization.type}s.${organization.id}.languageOptions`]: languageOptions.filter((lang) =>
				organization.languageOptions.includes(lang),
			),
		});

		return user;
	}
}
