import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import todoPriorities from "astrid-firestore/src/api/todos/constants/todoPriorities";
import resolveTodo from "astrid-firestore/src/api/todos/utils/resolveTodo";
import { updateDocument } from "astrid-firestore/src/helpers";
import useTodoPriorityTranslations from "astrid-web/src/features/todos/hooks/useTodoPriorityTranslations";

import { useProfile } from "../../../../authentication/state/profile";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

export default function TodoDropdownMenu({ todo }) {
	const { t } = useTranslation();
	const user = useProfile();

	const priorityTranslations = useTodoPriorityTranslations();

	return (
		<DropdownMenu style={{ width: 15 }}>
			{({ setLoading }) => (
				<>
					<Dropdown.Header content={t("priority", "Priority")} />

					{Object.values(todoPriorities).map((priority) => (
						<Dropdown.Item
							key={priority}
							active={todo.priority === priority}
							text={priorityTranslations[priority]}
							onClick={async (e) => {
								e.preventDefault();
								setLoading(true);
								await updateDocument(todo.ref, { priority });
								setLoading(false);
							}}
						/>
					))}

					<Dropdown.Divider style={{ margin: 0 }} />

					<Dropdown.Item
						text={t("markAsResolved", "Mark as resolved")}
						onClick={async (e) => {
							e.preventDefault();
							setLoading(true);
							await resolveTodo(firebase, { todo, user });
							setLoading(false);
						}}
					/>
				</>
			)}
		</DropdownMenu>
	);
}
