import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { DistributionChannel } from "./types/DistributionChannel";

const distributionChannels = createFirestoreCollection("distributionChannels", DistributionChannel, {
	preProcess: async ({ firebase, api }, { data }) => {
		// Check if a distribution channel with the same name already exists
		if (data.name) {
			const channels = await api.distributionChannels.getAll((ref) =>
				ref
					.where(firebase.firestore.FieldPath.documentId(), "!=", data.ref.id)
					.where("name", "==", data.name)
					.where("deleted", "==", false)
					.limit(1),
			);

			if (channels.length > 0) {
				throw new Error("A distribution channel with this name already exists.");
			}
		}
		return data;
	},

	createPipeline: ({ api }, { channel, id, data }) => {
		return api.distributionChannels.update({
			ref: channel.ref,
			[`pipelines.${id}`]: { id, ...data },
		});
	},

	updatePipeline: ({ api }, { channel, pipeline, data }) => {
		return api.distributionChannels.update({
			ref: channel.ref,
			...Object.entries(data).reduce(
				(acc, [key, value]) => ({
					...acc,
					[`pipelines.${pipeline.id}.${key}`]: value,
				}),
				{},
			),
		});
	},

	deletePipeline: ({ api, firebase }, { channel, pipeline }) => {
		return api.distributionChannels.update(
			{
				ref: channel.ref,
				[`pipelines.${pipeline.id}`]: firebase.firestore.FieldValue.delete(),
			},
			{
				validate: false,
			},
		);
	},
});

export default distributionChannels;
