import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Modal } from "semantic-ui-react";

import { db, notifyUsers } from "astrid-firebase";

class NoticationModal extends Component {
	state = {
		selected: this.props.affectedStaff,
	};

	sendNotifications = () => {
		const userMap = this.props.userMap;
		const notifications = this.props.notifications;

		this.state.selected.forEach(async (person) => {
			let message = "";
			let subject;

			for (const id of Object.keys(notifications).filter((id) => notifications[id].staff.includes(person))) {
				// compile data
				let session = this.props.sessions.find((session) => session.id === id);

				if (!session) {
					// if user left the time period, get single session
					const doc = await db.collection("sessions").doc(id).get();
					session = doc.data();
				}

				const prod = this.props.production || this.props.productionMap[session.production];
				const studio = this.props.studios[session.studio];
				const readerArr = session.reader || (prod && prod.reader);
				const { t } = this.props;
				const reader = readerArr.length
					? readerArr
							.reduce(
								(arr, id) => [
									...arr,
									userMap[id]
										? userMap[id].firstName + " " + userMap[id].lastName
										: t("deletedNarratorBrackets"),
								],
								[],
							)
							.join(" & ")
					: t("notSpecified");

				// compose message
				subject = t("updatedRecSessions");

				const sessionType = notifications[id]?.type === "new" ? t("newSession") : t("editedSession");
				const sessionTitle = prod ? t("titleSession") + prod.title : "";
				const sessionTime =
					moment(session.start.toDate()).format("YYYY-MM-DD LT") +
					" - " +
					moment(session.end.toDate()).format("LT");
				const sessionStudio = studio.name + ", " + studio.address;
				const sessionReader = reader;
				const sessionRecorder = userMap[session.recorder]
					? userMap[session.recorder].firstName + " " + userMap[session.recorder].lastName
					: t("recorderMissing");
				const sessionWeb = prod ? "https://astrid.fm/production/" + session.production : "";

				message += t("sessionMessage", {
					sessType: sessionType,
					sessTitle: sessionTitle,
					sessTime: sessionTime,
					sessStudio: sessionStudio,
					sessReader: sessionReader,
					sessRecorder: sessionRecorder,
					sessWeb: sessionWeb,
				});
			}

			// figure out recipient language
			// const personLangs = Object.entries(userMap[person].readerData.language);
			// const motherTongue = personLangs.filter(([code, skill]) => skill === 5);
			// //Choose first of possible mothertongue languages:
			// const motherToungeCode = motherTongue[0][0];
			// console.log("Språk: " + personLangs);
			// console.log("MotherTongueCode: " + motherToungeCode);

			// add to notification queue
			notifyUsers([person], userMap, subject, message);

			// reset stuff
			this.props.resetNotifications();
			this.props.modalClose();
		});
	};

	render() {
		const { t, affectedStaff, userMap, modalClose } = this.props;

		return (
			<>
				<Modal.Header>{t("notifyCoworker")}</Modal.Header>
				<Modal.Content>
					<p>{t("notifyCoworkerMessage", { staffAffected: affectedStaff.length })}</p>
					<Form>
						{affectedStaff.map((person, i) =>
							userMap[person] ? (
								<Form.Checkbox
									key={i}
									label={userMap[person].firstName + " " + userMap[person].lastName}
									name="notifyStaff[]"
									value={person}
									checked={this.state.selected.includes(person)}
									onChange={(e, data) => {
										const selected = [...this.state.selected];
										console.log("Selected: " + selected);
										if (data.checked) {
											selected.push(data.value);
										} else {
											const i = selected.indexOf(data.value);
											selected.splice(i, 1);
										}
										this.setState({ selected });
									}}
								/>
							) : (
								""
							),
						)}
					</Form>
				</Modal.Content>

				<Modal.Actions>
					<Button onClick={modalClose}>{t("cancelBtn")}</Button>
					<Button
						positive
						icon="send"
						labelPosition="right"
						content={t("send")}
						onClick={() => {
							this.sendNotifications();
						}}
					/>
				</Modal.Actions>
			</>
		);
	}
}

export default withTranslation()(NoticationModal);
