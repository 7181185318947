import React from "react";
import { useTranslation } from "react-i18next";

import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";

import AgreementSelect from "../../../../agreements/components/AgreementSelect/AgreementSelect";
import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";
import PriceSelect from "../../../../prices/components/PriceSelect/PriceSelect";
import TeamRoleSelect from "../../../../team/components/TeamRoleSelect/TeamRoleSelect";
import ModalPrompt from "../../../../ui/components/ModalPrompt/ModalPrompt";
import UserLanguageSelect from "../../../../users/components/UserLanguageSelect/UserLanguageSelect";

import submitAdminUserPrice from "../utils/submitAdminUserPrice";

export default function AdminUserPriceForm({ user, organization, onClose }) {
	const { t } = useTranslation();

	const form = useForm();

	const { watch, setValue, formState, handleSubmit } = form;

	const [language, role, agreement] = watch(["language", "role", "agreement"]);

	const employee = user?.producers?.[organization.id]?.employee;

	const onSubmit = async ({ role, price, language, agreement }) => {
		try {
			await submitAdminUserPrice({ role, price, language, agreement, user, organization });
		} catch (error) {
			throw error;
		}
	};

	return (
		<ModalPrompt
			header={t("addDefaultPrice", "Add default price")}
			disabled={!formState.isValid}
			onClose={onClose}
			onSave={handleSubmit(onSubmit)}
		>
			<Form form={form}>
				<Form.Group widths="equal">
					<Form.Field name="language">
						<UserLanguageSelect label={t("language")} user={user} producerId={organization?.id} />
					</Form.Field>

					<Form.Field name="role">
						<TeamRoleSelect label={t("role", "Role")} />
					</Form.Field>
				</Form.Group>

				{role && !employee && (
					<Form.Field name="agreementId">
						<AgreementSelect
							type={agreementTypes.FRAMEWORK}
							role={role}
							firstPartyId={organization?.id}
							secondPartyId={user?.id}
							onChangeOption={(option) => setValue("agreement", option?.data)}
						/>
					</Form.Field>
				)}

				{(agreement || employee) && (
					<Form.Field name="priceId">
						<PriceSelect
							user={user}
							role={role}
							language={language}
							agreement={agreement}
							producerId={organization.id}
							onChangeOption={(option) => setValue("price", option?.data)}
						/>
					</Form.Field>
				)}
			</Form>
		</ModalPrompt>
	);
}
