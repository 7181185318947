import { runBatch, runTransaction } from "../helpers";
import createDocumentData from "../utils/createDocumentData";
import createDocumentRef from "../utils/createDocumentRef";
import getCollectionData from "../utils/getCollectionData";
import getCollectionDataById from "../utils/getCollectionDataById";
import getDocumentData from "../utils/getDocumentData";
import validateDocumentData from "../utils/validateDocumentData";

export default function createFirestoreCollection(
	collectionName,
	Schema,
	{ preProcess, sideEffects, getOption, softDeletes = true, ...methods } = {},
) {
	return (context) => {
		const { plugins = [], firebase, getUser, debug = false } = context;

		const db = firebase.firestore();
		const collection = db.collection(collectionName);

		// Validation

		const validateData = (data, { partial } = {}) => {
			return validateDocumentData(data, { Schema, partial });
		};

		// Transaction

		const getTransactionMethod = ({ partial = false, method, hardDelete = false }) => {
			return method || (hardDelete ? "delete" : partial ? "update" : "set");
		};

		const getValidData = ({ data, validate = true, partial = false, hardDelete = false }) => {
			return validate && !hardDelete ? validateData(data, { partial }) : { validData: data, partialData: data };
		};

		const getBefore = ({ before, validData, hardDelete = false }) => {
			return before ? before : hardDelete ? validData : null;
		};

		const getAfter = ({ before, validData, hardDelete = false }) => {
			return {
				...before,
				...validData,
				exists: !validData.deleted && !hardDelete,
			};
		};

		const applyTransactionMethod = ({ transaction, method, partialData, merge = true }) => {
			const { ref, ...data } = partialData;

			transaction[method](ref, data, { merge });
		};

		const applyPreProcess = ({ data, ...rest }) => {
			return preProcess?.(context, { data, ...rest }) || data;
		};

		const applySideEffects = async ({ withSideEffects = true, ...rest }) => {
			if (withSideEffects && sideEffects) {
				await sideEffects(context, rest);
			}
		};

		const transact = async ({ ...data }, { transaction, ...options } = {}) => {
			data = await applyPreProcess({ transaction, data, ...options });

			const method = getTransactionMethod(options);

			const { validData, partialData } = getValidData({ data, ...options });

			const before = getBefore({ validData, ...options });
			const after = getAfter({ before, validData, ...options });

			applyTransactionMethod({ transaction, method, partialData, ...options });

			await applySideEffects({ transaction, data, before, after, ...options });

			if (debug) {
				console.log(method, data.ref.path, { data, before, after, validData, partialData });
			}

			return after;
		};

		// Set

		const set = (data, { transaction, ...options } = {}) => {
			if (transaction) {
				return transact(data, { transaction, ...options });
			}

			return runBatch(db, (transaction) => {
				return transact(data, { transaction, ...options });
			});
		};

		const setAll = (documents, { transaction, ...options } = {}) => {
			if (transaction) {
				return Promise.all(
					documents.map((data) => {
						return set(data, { transaction, before: data.exists ? data : null, ...options });
					}),
				);
			}

			return runBatch(db, (transaction) => {
				return Promise.all(
					documents.map((data) => {
						return set(data, { transaction, before: data.exists ? data : null, ...options });
					}),
				);
			});
		};

		// Create

		const createRef = (id) => {
			return createDocumentRef(collection, id);
		};

		const createData = (data) => {
			return createDocumentData(data, { firebase, collection, getUser });
		};

		const create = (data, { transaction, ...options } = {}) => {
			if (transaction) {
				return transact(createData(data), { transaction, ...options });
			}

			return runBatch(db, (transaction) => {
				return transact(createData(data), { transaction, ...options });
			});
		};

		const createAll = (documents, { transaction, ...options } = {}) => {
			if (transaction) {
				return Promise.all(
					documents.map((data) => {
						return create(data, { transaction, ...options });
					}),
				);
			}

			return runBatch(db, (transaction) => {
				return Promise.all(
					documents.map((data) => {
						return create(data, { transaction, ...options });
					}),
				);
			});
		};

		// Update

		const update = (data, { transaction, partial = true, ...options } = {}) => {
			if (transaction) {
				return transact(data, { transaction, partial, ...options });
			}

			return runTransaction(db, [data.ref], (transaction, [before]) => {
				return transact(data, { before, transaction, partial, ...options });
			});
		};

		const updateAll = (documents, { transaction, getData, ...options } = {}) => {
			if (transaction) {
				return Promise.all(
					documents.map((before) => {
						return update({ ref: before.ref, ...getData(before) }, { transaction, before, ...options });
					}),
				);
			}

			return runBatch(db, (transaction) => {
				return Promise.all(
					documents.map((before) => {
						return update({ ref: before.ref, ...getData(before) }, { transaction, before, ...options });
					}),
				);
			});
		};

		const updateArray = ({ ref, key, value, exists }, { transaction }) => {
			if (ref && key && value) {
				return update(
					{
						ref,
						[key]: exists
							? firebase.firestore.FieldValue.arrayUnion(value)
							: firebase.firestore.FieldValue.arrayRemove(value),
					},
					{ transaction, validate: false },
				);
			}
		};

		// Delete

		const softDelete = ({ ref }) => {
			return update({
				ref,
				deleted: true,
				deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
				deletedBy: getUser(),
			});
		};

		const hardDelete = (data, { transaction, hardDelete = true, ...options } = {}) => {
			if (transaction) {
				return transact(data, { transaction, hardDelete, ...options });
			}

			return runTransaction(db, [data.ref], (transaction, [before]) => {
				return transact(data, { transaction, hardDelete, before, ...options });
			});
		};

		// Getters

		const getRef = (id) => {
			return collection.doc(id);
		};

		const getByRef = (ref, getter = getDocumentData) => {
			return getter(ref);
		};

		const getById = (id, getter) => {
			return getByRef(getRef(id), getter);
		};

		const getQuery = (callbackOrObject = (query) => query) => {
			const query = softDeletes ? collection.where("deleted", "==", false) : collection;

			if (typeof callbackOrObject === "function") {
				return callbackOrObject(query);
			}

			return Object.entries(callbackOrObject).reduce((query, [key, value]) => {
				if (value?.id) {
					return query.where(`${key}.id`, "==", value.id);
				}

				return query.where(key, "==", value);
			}, query);
		};

		const getAll = (callbackOrObject, getter = getCollectionData, options) => {
			return getter(getQuery(callbackOrObject), options);
		};

		const getAllByIds = (ids = [], getter = getCollectionDataById) => {
			return getter(firebase, collection, ids);
		};

		// API

		const api = {
			Schema,
			collection,

			// Validation
			validateData,

			// Getters
			getQuery,
			getAll,
			getAllByIds,
			getById,
			getByRef,
			getRef,

			// Set
			set,
			setAll,

			// Create
			create,
			createAll,
			createData,
			createRef,

			// Update
			transact,
			update,
			updateAll,
			updateArray,

			// Delete
			delete: softDelete,
			hardDelete,
			softDelete,

			// Methods
			...Object.entries(methods)
				.filter(([, value]) => typeof value === "function")
				.reduce(
					(acc, [key, value]) => ({
						...acc,
						[key]: (...args) => value(context, ...args),
					}),
					{},
				),
		};

		return plugins.reduce((api, plugin) => plugin(api, context), api);
	};
}
