import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useAgreementAppendixesQuery from "../../hooks/useAgreementAppendixesQuery";
import useAppendixesColumns from "../../hooks/useAppendixesColumns";

export default function AgreementAppendixesCollectionDataTable({ getQuery = (query) => query, ...props }) {
	const query = useAgreementAppendixesQuery();
	const columns = useAppendixesColumns();

	return (
		<CollectionDataTable
			filterable={false}
			query={getQuery(query)}
			columns={columns}
			hoverRow={false}
			searchableKeys={["name"]}
			sortBy={{
				id: "created",
				desc: true,
			}}
			{...props}
		/>
	);
}
