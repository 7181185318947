import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, Form } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { base } from "astrid-firebase";
import nameCase from "astrid-helpers/src/nameCase";

class PersonDropdown extends Component {
	state = {
		isFetching: false,
		people: [],
		value: this.props.value || [],
		lookedForAuthorLanguage: false,
	};

	componentDidMount() {
		this.peopleOptions = this.props.options || [];

		// if no person selected or author is missing language, load all
		const missingLanguage = this.props.name === "author" && this.peopleOptions.find((author) => !author.language);
		const noneSelected = !this.props.value?.length;
		if (missingLanguage || noneSelected) {
			this.openDropdown();
		}
	}

	openDropdown = () => {
		console.log("PersonDropdown -> openDropdown -> openDropdown");
		// are there options?
		if (!this.state.people.length) {
			this.setState({ isFetching: true });

			base.bindCollection("people", {
				context: this,
				state: "people",
				withIds: true,
				query: (ref) => ref.where("type", "==", this.props.name),
				then() {
					this.setState({ isFetching: false });
					this.updatePeopleOptions(this.state.people);
				},
				onFailure(err) {
					console.log("people err", err);
				},
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.people && nextState.people.length) {
			this.updatePeopleOptions(nextState.people);
		}
		return true;
	}

	updatePeopleOptions = (people) => {
		const options = [];
		people
			.filter(
				(person) =>
					!this.props.limitPublisher ||
					!person.visible ||
					!person.visible.length ||
					person.visible.includes(this.props.limitPublisher),
			)
			.forEach((person) => {
				options.push({
					key: person.id,
					value: person.id,
					text: (person.firstName + " " + person.lastName).trim(),
					language: person.language || null,
				});
			});
		options.sort((a, b) => {
			return a.text > b.text ? 1 : -1;
		});
		this.peopleOptions = options;

		return options;
	};

	handleChange = (e, data) => {
		this.setState({ value: data.value });

		// send new value upwards
		this.sendChange(data.value);
	};

	sendChange = (value) => {
		this.props.onChange(
			value,
			this.peopleOptions
				.filter((option) => value.includes(option.value))
				.map((option) => ({
					...option,
					language: option.language || (this.state[option.key] && this.state[option.key].language) || null,
				})),
		);
	};

	addPerson = (e, data) => {
		this.setState({ isFetching: true });

		// separate name, use last part as last name
		const trimmedNameString = data.value.trim();
		const nameParts = trimmedNameString.split(" ");
		const lastName = nameParts.pop().trim();
		const firstName = nameParts.join(" ").trim();

		// check if person exists
		const hit = this.state.people.find(
			(person) =>
				person.firstName.toLowerCase() === firstName.toLowerCase() &&
				person.lastName.toLowerCase() === lastName.toLowerCase(),
		);

		if (hit) {
			// there's a person with same name, add him/her
			const value = [...this.state.value];
			value[value.indexOf(data.value)] = hit.id;
			this.setState({ value, isFetching: false });

			// make person visible for publisher that created
			if (hit.visible && hit.visible.length) {
				hit.visible.push(this.props.limitPublisher);

				// store in preload options
				this.peopleOptions.push({
					key: hit.id,
					value: hit.id,
					text: hit.firstName + " " + hit.lastName,
					language: hit.language,
				});

				base.updateDoc("people/" + hit.id, {
					visible: hit.visible,
				});
			}

			// send new value upwards
			this.sendChange(value);
		} else {
			// no person exists, create new
			base.addToCollection("/people", {
				firstName: nameCase(firstName),
				lastName: nameCase(lastName),
				type: this.props.name,
				visible: [this.props.limitPublisher],
			})
				.then((ref) => {
					// replace name value with id
					const value = [...this.state.value];
					value[value.indexOf(data.value)] = ref.id;
					this.setState({ value, isFetching: false });

					// send new value upwards
					this.sendChange(value);
				})
				.catch((error) => {
					console.log("Person add error", error);
				});
		}
	};

	render() {
		const { t, error, label, disabled, name } = this.props;
		const { isFetching, value, people } = this.state;
		const options = this.peopleOptions;

		return (
			<div className="field">
				<Form.Select
					fluid
					selection
					multiple
					allowAdditions
					deburr
					error={error}
					additionLabel={t("add") + ":"}
					noResultsMessage={t("noPersonHits")}
					search={true}
					options={options || []}
					value={value}
					label={label}
					onOpen={this.openDropdown}
					onChange={this.handleChange}
					onAddItem={this.addPerson}
					disabled={isFetching || disabled}
					className={disabled ? "locked-field" : null}
					loading={isFetching}
				/>
				{name === "author" &&
					!!people.length &&
					value &&
					value
						.filter(
							(author) =>
								this.state[author] || options.find((opt) => author === opt.key && !opt.language),
						)
						.map((author) => (
							<Dropdown
								className="tiny"
								style={{ marginTop: 3 }}
								button
								fluid
								scrolling
								search
								deburr
								floating
								disabled={isFetching || disabled}
								loading={isFetching}
								onOpen={this.openDropdown}
								key={author}
								value={this.state[author] || options.find((opt) => author === opt.key).language || ""}
								placeholder={
									t("enterAuthor") +
									(options.find((opt) => author === opt.key) || {}).text +
									t("authorsOriginalLang")
								}
								options={Object.keys(languages).map((code) => ({
									key: code,
									value: code,
									text:
										(languages[code].sv || languages[code].name) +
										" (" +
										languages[code].nativeName +
										")",
								}))}
								onChange={(e, data) => {
									this.setState({ [author]: data.value });

									base.updateDoc("people/" + author, {
										language: data.value,
									}).then(() => {
										this.sendChange(value);
									});
								}}
							/>
						))}
			</div>
		);
	}
}

export default withTranslation()(PersonDropdown);
