import get from "lodash/get";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import uniqWith from "lodash/uniqWith";

import { Button, Card, Checkbox, Form, Header, Icon, Image, Loader, Segment, Table } from "semantic-ui-react";

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

//import translator from "astrid-web/src/helpers/translator";
import languages from "astrid-config/src/languages";
import { base, db, firebase } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import DocTitle from "astrid-web/src/components/DocTitle";
import UploadButton from "astrid-web/src/components/UploadButton";
//import genres from "astrid-config/src/genres/storytel";
import { addMasterFileToArticle, queueMasterFileForProcessing } from "astrid-web/src/components/production/fnc";
import { Store } from "astrid-web/src/helpers/context";
import { secToDuration } from "astrid-web/src/helpers/fnc";

// cloud functions
const scrapeDistributorStatus = firebase.functions().httpsCallable("scrapeDistributorStatus");
const createProductions = firebase.functions().httpsCallable("createProductions");

// strings (just for external uploaders for now)
/* const translations = {
	sv: {
		upload: "Uppladdning",
		instr1:
			"Filerna måste vara döpta efter ISBN och ha filändelse .jpg, .epub, .zip eller .wav, t.ex 9789165454559.jpg. OBS! Ej vi tar inte emot .mp3 eller .pdf-filer.",
		instr2:
			"Wav-filer kan även döpas med löpande nummer efter understreck, t.ex. 9789165454559_001.wav, 9789165454559_002.wav…",
		chooseFiles: "Välj filer att ladda upp",
		filename: "Filnamn",
		filetype: "Filtyp",
		production: "Produktion",
		preview: "Förhandsvisning",
		conflict: "Konflikt",
		cover: "Omslag",
		epub: "E-bok",
		audio: "Ljud",
		error: "Fel",
		noHit: "Ingen träff",
		notIsbn: "Ej döpt till ISBN",
		article: "Article",
		part: "Del",
		existingEpub: "Befintlig epub",
		existingMaster: "Master finns redan",
		park: "Parkera",
		replace: "Ersätt",
		startUpload: "Ladda upp matchade filer",
		unknownType: "Okänd filtyp",
	},
}; */

// cache selected files for retriggering file analysis
let selectedFiles = [];

const Upload = (props) => {
	const context = useContext(Store);
	const { getProductions, getOrganizations } = context;
	const { productions: contextProds, user, profile, producerId } = context.state;
	const { external, productions: propProds, uid: propUid, publisherId } = props;

	const [files, setFiles] = useState([]);
	const [processing, setProcessing] = useState();
	const [scrapeLanguage, setScrapeLanguage] = useState("sv");
	const [scrapePublisher, setScrapePublisher] = useState(publisherId);
	const [publishers, setPublishers] = useState();
	const { t } = useTranslation();

	// productions and user data can come from props (used by external uploaders) or context (internal users)
	const productions = propProds || contextProds;
	const uid = propUid || user.uid;
	const email = external ? "externaluploader" : profile.email;

	const getPublisherProductions = useCallback(() => {
		return getProductions({ filterPublisher: [scrapePublisher] });
	}, [scrapePublisher, getProductions]);

	useEffect(() => {
		console.log("UE", scrapePublisher);
		if (!propProds && scrapePublisher) getPublisherProductions();
	}, [getPublisherProductions, propProds, scrapePublisher]);

	const isProcessing = (to) => {
		window.onbeforeunload = () => (to === "started" ? true : undefined);
		setProcessing(to);
	};

	const setFile = (i, key, val) => {
		setFiles((state) => {
			const set = [...state];
			set[i][key] = val;
			return set;
		});
	};

	const addFiles = (e) => {
		// file from input (convert to array)
		const files = Array.from(e.target.files)
			// ignore annoying files
			.filter((file) => file.name !== ".DS_Store");

		files.sort((a, b) => {
			const aName = a.fullPath || a.name;
			const bName = b.fullPath || b.name;

			return aName > bName ? 1 : -1;
		});

		if (!files.length) return;

		console.log("added files");
		setScrapedISBN(null);
		isProcessing(false);

		selectedFiles = files;
		analyzeFiles();
	};

	// check if files are valid and if they match existing productions
	const analyzeFiles = (prods) => {
		setFiles(
			selectedFiles.map((file) => {
				const isbns = (file.fullPath || file.name).match(/\d{13}/g);
				const isbn = isbns && isbns.length ? isbns.pop() : false;
				const suffix = file.name.split(".").pop();
				const image = ["jpeg", "jpg", "png"].includes(suffix);
				const epub = ["epub"].includes(suffix);
				const zip = ["zip"].includes(suffix);
				const wav = ["wav"].includes(suffix);

				// find production match
				let hit, part;
				const prod = Object.values(prods || productions).find((prod) => {
					if (!epub && prod.isbn === isbn) hit = "total";
					if (!hit && prod.deliveryEbook && prod.deliveryEbook.isbn === isbn) hit = "ebook";
					part =
						!epub &&
						!hit &&
						get(prod, "deliveryParts.length") &&
						prod.deliveryParts.findIndex((part) => part.isbn === isbn);

					if (Number.isInteger(part) && part > -1) hit = "part";

					return hit;
				});

				const supported = image || (epub && (!hit || hit === "ebook")) || ((wav || zip) && hit !== "ebook");
				const valid = isbn && hit && supported;

				// check if asset already exists
				const conflict =
					prod &&
					hit &&
					((hit === "total" && image && prod.imgThumb) ||
						(hit === "ebook" && image && prod.deliveryEbook.imgThumb) ||
						(hit === "part" && image && get(prod, "deliveryPartsCover[" + part + "].imgThumb")) ||
						(hit === "ebook" && epub && prod.deliveryEbook.path));

				const masterExists =
					(zip || wav) &&
					((hit === "total" && get(prod, "master.total.files.length") && get(prod, "master.total.files")) ||
						(hit === "part" &&
							get(prod, "master.part_" + part + ".files.length") &&
							get(prod, "master.part_" + part + ".files")));

				return {
					file,
					isbn,
					epub,
					image,
					zip,
					wav,
					hit,
					part,
					prod,
					prodId: prod && prod.id,
					supported,
					valid,
					conflict,
					masterExists,
					checked: valid && !conflict && !masterExists,
				};
			}),
		);
	};

	// start next upload
	let movedToParking = false;
	const uploadQueue = () => {
		const i = files.findIndex((file) => file.checked && !file.upload);
		if (!movedToParking) movedToParking = [];

		if (i >= 0) {
			isProcessing("started");
			setFile(i, "upload", "started");

			const { file, part, hit, prodId, image, epub, wav, zip, masterExists, replaceMaster } = files[i];
			const fileid = Date.now() + "_" + file.name;
			const article = hit === "part" ? "part_" + part : hit;

			const bucket =
				(window.ES.stage ? "gs://stage-earselect-" : "gs://earselect-") + (image ? "uploads" : "static");
			const storageRef = firebase.storage().refFromURL(bucket);

			const path = image
				? "coverImages/" +
				  prodId +
				  (hit !== "total" ? "_" + (hit === "ebook" ? "EbookCover" : "part") : "") + // part or ebook
				  (!Number.isInteger(part) ? "" : "_" + part) // part index
				: epub
				? "masterDelivery/" + prodId + "/ebook/" + file.name
				: wav
				? "masterDelivery/" + prodId + "/source/" + fileid
				: zip
				? "masterZip/" + prodId + "/" + fileid
				: "";

			// start upload
			const uploadTask = storageRef.child(path).put(file);
			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED,
				(snapshot) => {
					// progress
					const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
					setFile(i, "progress", progress);
				},
				(error) => {
					// error
					console.log(error);
				},
				() => {
					// success, store in db
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						let newData = {};
						if (image) {
							let field = "img";
							if (hit === "part") {
								field = "deliveryPartsCover." + part + ".img";
							} else if (hit === "ebook") {
								field = "deliveryEbook.img";
							}
							newData[field] = downloadURL;
						} else if (epub) {
							newData["deliveryEbook.path"] = path;
							newData["deliveryEbook.url"] = downloadURL;
						}

						if (Object.keys(newData).length) {
							// image and epub, update production
							db.collection("productions")
								.doc(prodId)
								.update({ ...newData, status: "production" })
								.then(() => {
									setFile(i, "upload", "done");
									uploadQueue();
								});
						} else {
							// master audio files
							const destinationArticle = masterExists && !replaceMaster ? "parking" : article;

							if (replaceMaster && !movedToParking.includes(prodId + article)) {
								movedToParking.push(prodId + article);
								const prod = productions.find((prod) => prod.id === prodId);

								db.collection("productions")
									.doc(prodId)
									.update({
										"master.parking.files": firebase.firestore.FieldValue.arrayUnion(
											...prod.master[article].files,
										),
										["master." + article + ".files"]: [],
										status: "production",
									});

								console.log("move to parking", prodId, article, productions[prodId]);
							}

							const userName =
								profile?.firstName && profile?.lastName
									? `${profile.firstName} ${profile.lastName}`
									: "";

							if (wav) {
								addMasterFileToArticle(prodId, destinationArticle, fileid, true);
								queueMasterFileForProcessing({
									productionId: prodId,
									fileid,
									name: file.name,
									path,
									downloadURL,
									uid,
									email,
									userName,
								});
							} else if (zip) {
								base.addToCollection("tasks/compute/jobs", {
									job: "newMasterZip",
									status: "queued",
									created: firebase.firestore.FieldValue.serverTimestamp(),
									diskType: "pd-ssd",
									diskSize: 60,
									machineType: "n1-highmem-2",
									args: {
										production: prodId,
										article: destinationArticle,
										path,
										fileid,
									},
								});

								addEvent({
									productionId: prodId,
									user: uid,
									email,
									data: {
										newMasterZip: path,
										destinationArticle,
										[fileid]: { user: userName },
									},
								});
							}
							setFile(i, "upload", "done");
							uploadQueue();
						}
					});
				},
			);
		} else {
			movedToParking = false;
			isProcessing("done");
			if (!propProds) getPublisherProductions();
		}
	};

	// are there files that are missing productions?
	const noHits = uniq(files.filter(({ isbn, supported, hit }) => isbn && supported && !hit).map(({ isbn }) => isbn));

	// trigger scrape queue
	const [scrapedISBN, setScrapedISBN] = useState();
	const [skippedISBN, setSkippedISBN] = useState([]);
	const startScraping = () => {
		isProcessing("started");
		setScrapedISBN({});
	};

	// process scrape queue
	useEffect(() => {
		const inProgress =
			scrapedISBN && noHits.find((isbn) => scrapedISBN[isbn] && scrapedISBN[isbn].status === "started");
		const next = !inProgress && scrapedISBN && noHits.find((isbn) => !scrapedISBN[isbn]);

		if (next) {
			console.log("scrape", next);
			setScrapedISBN({ ...scrapedISBN, [next]: { status: "started" } });

			scrapeDistributorStatus({
				isbn: next,
				language: scrapeLanguage,
				distributor: ["bookbeat", "storytel"],
			}).then(({ data }) => {
				console.log(next, data);
				let isbn = next;

				const linkedEbookExists =
					get(data, "audiobook.isbn") === isbn &&
					get(data, "ebook.isbn") &&
					Object.values(productions).find((p) => p.isbn === get(data, "ebook.isbn"));

				const linkedAudiobookExists =
					get(data, "ebook.isbn") === isbn &&
					get(data, "audiobook.isbn") &&
					Object.values(productions).find((p) => p.isbn === get(data, "audiobook.isbn"));

				const linkedArticle = linkedEbookExists || linkedAudiobookExists;

				if (linkedArticle) {
					setSkippedISBN((s) => [...s, isbn]);
				}

				setScrapedISBN({
					...scrapedISBN,
					[next]: { ...scrapedISBN[next], status: "done", ...data, linkedArticle },
				});
			});
		} else if (scrapedISBN && !inProgress && !next) {
			isProcessing("done");
		}
	}, [scrapedISBN, noHits, scrapeLanguage, productions]);

	// is scrape complete?
	const scrapeIsComplete = scrapedISBN && !noHits.find((isbn) => get(scrapedISBN, isbn + ".status") !== "done");

	// create productions out of selected scraped titles
	const [isCreatingProductions, setIsCreatingProductions] = useState();

	// skip reset for dev
	const skipReset = false;
	const createScrapedProductions = () => {
		if (!skipReset) setIsCreatingProductions(true);
		const ommitSkipped = omit(scrapedISBN, skippedISBN);
		const uniqueProds = uniqWith(
			Object.values(ommitSkipped).filter((p) => p.title),
			isEqual,
		);
		//const { t } = this.props;

		console.log("Create prods", scrapedISBN, skippedISBN);
		console.log("Unique prods:", uniqueProds);

		if (uniqueProds.length) {
			createProductions({
				productions: uniqueProds,
				publisher: scrapePublisher,
				language: scrapeLanguage,
				producer: producerId,
				email: profile.email,
			})
				.then(({ data }) => {
					console.log("created", data);
					console.log("reset scrape");

					console.log("update production data");
					getPublisherProductions().then(() => {
						console.log("reanalyze selected files");
						if (!skipReset) setScrapedISBN(null);
						setIsCreatingProductions(false);
					});
				})
				.catch((err) => {
					console.log("prod creation error", err);
				});
		} else {
			alert(t("noArticlesAlert"));
			setIsCreatingProductions(false);
		}
	};

	useEffect(() => {
		if (isCreatingProductions === false) analyzeFiles();
		// TODO: add analyzeFiles as dep, wrap analyzeFiles in useCallback? https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
		// eslint-disable-next-line
	}, [isCreatingProductions]);

	return (
		<>
			{/* <DocTitle title={str("upload", "Bulk upload")} /> */}
			<DocTitle title={t("upload")} />
			<Header as="h4">
				<Icon name="cloud upload" />
				{/* <Header.Content>{str("upload", "Bulk upload")}</Header.Content> */}
				<Header.Content>{t("upload")}</Header.Content>
			</Header>
			<Segment>
				<p>
					<em>{t("instr1")}</em>
				</p>
				<p>
					<em>{t("instr2")}</em>
				</p>
				<Form as="div" size="small">
					<Form.Group>
						{!external && (
							<Form.Select
								search
								deburr
								placeholder={t("choosePublisher")}
								value={scrapePublisher}
								loading={publishers === null}
								onOpen={() => {
									if (publishers === undefined) {
										setPublishers(null);
										getOrganizations().then((org) => {
											setPublishers(org);
										});
									}
								}}
								options={Object.values(publishers || [{ id: "null", name: t("loading") }])
									.filter((org) => org.type === "publisher")
									.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
									.map(({ id, name }) => ({ key: id, value: id, text: name }))}
								onChange={(e, data) => {
									setFiles([]);
									setScrapePublisher(data.value);
								}}
							/>
						)}

						<UploadButton
							text={t("chooseFiles")}
							name={t("upload")}
							multiple
							onUpload={addFiles}
							disabled={processing === "started" || !scrapePublisher}
							loading={scrapePublisher && !productions}
						/>
					</Form.Group>
				</Form>
				<br />

				{!!noHits.length && profile && (
					<>
						<Form size="tiny" as="div">
							<p>
								<Icon name="search" color="green" size="large" />
								<b>{t("filesContainsWrongIsbn", { noHitsL: noHits.length })} </b>
								{t("searchInfoOnlineUpload")}
							</p>
							{noHits.length > 100 && (
								<p>
									<b>{t("maxGetInfo")}</b>
								</p>
							)}
							<Form.Group>
								<Form.Select
									search
									deburr
									placeholder={t("chooseLanguage")}
									value={scrapeLanguage}
									options={Object.keys(languages).map((code) => ({
										key: code,
										value: code,
										text:
											(languages[code].sv || languages[code].name) +
											" (" +
											languages[code].nativeName +
											")",
									}))}
									onChange={(e, data) => {
										setScrapedISBN(null);
										isProcessing(false);
										setScrapeLanguage(data.value);
									}}
								/>

								<Form.Button
									disabled={
										noHits.length > 100 || !(scrapeLanguage && scrapePublisher) || !!processing
									}
									loading={processing === "started"}
									content={t("searchInfoOnline")}
									primary
									size="tiny"
									onClick={startScraping}
								/>
							</Form.Group>
						</Form>
					</>
				)}

				{!!files.length && (
					<Table collapsing style={{ minWidth: "60%" }}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{t("filename")}</Table.HeaderCell>
								<Table.HeaderCell>{t("filetype")}</Table.HeaderCell>
								<Table.HeaderCell>{t("production")}</Table.HeaderCell>
								<Table.HeaderCell>{t("preview")}</Table.HeaderCell>
								<Table.HeaderCell>{t("conflict")}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{files.map(
								(
									{
										file,
										image,
										epub,
										zip,
										wav,
										isbn,
										hit,
										part,
										prod,
										prodId,
										supported,
										valid,
										conflict,
										masterExists,
										replaceMaster,
										checked,
										upload,
										progress,
									},
									i,
								) => {
									return (
										<Table.Row key={file.fullPath || file.name} positive={!!checked}>
											<Table.Cell>
												{upload === "started" ? (
													<Loader inline active size="tiny" />
												) : upload === "done" ? (
													<Icon name="check" color="green" />
												) : (
													<Checkbox
														label={file.name}
														disabled={!valid}
														checked={!!checked}
														onClick={(e) => {
															setFile(i, "checked", !checked);

															// check/uncheck other wavs for same article
															if (wav) {
																files.forEach((file, fi) => {
																	if (
																		fi !== i &&
																		prodId === file.prodId &&
																		part === file.part &&
																		file.wav
																	)
																		setFile(fi, "checked", !checked);
																});
															}
														}}
													/>
												)}{" "}
												{progress && (
													<small style={{ display: "inline-block", minWidth: 38 }}>
														{progress}%
													</small>
												)}
												{upload && file.name}
											</Table.Cell>
											<Table.Cell>
												{image ? (
													t("cover")
												) : epub ? (
													t("epub")
												) : zip ? (
													t("audio") + " (zip)"
												) : wav ? (
													t("audio") + " (wav)"
												) : (
													<>
														<Icon name="warning circle" color="red" />
														{t("error")}: {file.type || t("unknownType")}
													</>
												)}
											</Table.Cell>
											<Table.Cell>
												{supported && !(isbn && hit) && (
													<>
														<Icon name="warning circle" color={isbn ? "orange" : "red"} />
														{isbn ? t("No hit") : t("notIsbn")}
													</>
												)}

												{isbn && hit && (
													<>
														<a
															href={"/production/" + prodId}
															target="_blank"
															rel="noopener noreferrer"
														>
															<b>{prod.title}</b>
														</a>
														<div>
															<small>
																{t("article")}:{" "}
																{hit === "ebook"
																	? t("eBook") + ":"
																	: hit === "part"
																	? t("part") +
																	  " " +
																	  (part + 1) +
																	  " " +
																	  (prod.deliveryParts[part].title || "")
																	: t("normal")}
																{isbn && <div>{isbn}</div>}
															</small>
														</div>
													</>
												)}
											</Table.Cell>
											<Table.Cell colSpan={conflict || masterExists ? 1 : 2}>
												{image && !get(scrapedISBN, isbn + ".status") && (
													<PreviewImage file={file} />
												)}

												{isbn &&
													supported &&
													!hit &&
													(get(scrapedISBN, isbn + ".status") === "started" ? (
														<>
															<Loader inline active size="tiny" /> {t("searchInfo")}
														</>
													) : (
														get(scrapedISBN, isbn + ".status") === "done" &&
														(scrapedISBN[isbn].title ? (
															<>
																<ScrapeResult
																	isbn={isbn}
																	shown={
																		!!i &&
																		(get(files[i - 1], "isbn") ===
																			get(scrapedISBN[isbn], "audiobook.isbn") ||
																			get(files[i - 1], "isbn") ===
																				get(scrapedISBN[isbn], "ebook.isbn"))
																	}
																	data={scrapedISBN[isbn]}
																	productions={productions}
																	skipped={skippedISBN.includes(isbn)}
																	setSkipped={(checked) => {
																		const isbns = [...skippedISBN];

																		if (checked) {
																			isbns.splice(isbns.indexOf(isbn), 1);
																		} else {
																			isbns.push(isbn);
																		}

																		setSkippedISBN(isbns);
																	}}
																	updateAnalysis={() => {
																		getPublisherProductions().then((a) => {
																			console.log("aaa", a);
																			analyzeFiles(a);
																		});
																	}}
																/>

																{scrapedISBN[isbn].image && (
																	<img
																		src={scrapedISBN[isbn].image}
																		alt=""
																		style={{ height: 75 }}
																	/>
																)}
															</>
														) : (
															<em>{t("noInfoFound")}</em>
														))
													))}
											</Table.Cell>
											{(conflict || masterExists) && (
												<Table.Cell>
													{!!(image && conflict) && (
														<img src={conflict} alt="" style={{ height: 75 }} />
													)}
													{!!(epub && conflict) && (
														<a
															className="ui blue button"
															href={prod.deliveryEbook.url}
															target="_blank"
															rel="noopener noreferrer"
														>
															<Icon name="book" /> {t("existingEpub")}
														</a>
													)}
													{!!masterExists && (
														<div>
															<Icon name="warning circle" color="red" />
															<b>{t("existingMaster")}</b> <br />
															<span style={{ color: checked ? "#000" : "#808080" }}>
																{t("park")}
															</span>
															<span>
																<Checkbox
																	toggle
																	disabled={!checked}
																	label={t("replace")}
																	style={{ marginLeft: "1em" }}
																	checked={!!replaceMaster}
																	onChange={(e, data) => {
																		setFile(i, "replaceMaster", !replaceMaster);

																		if (wav)
																			files.forEach((file, fi) => {
																				if (
																					fi !== i &&
																					prodId === file.prodId &&
																					part === file.part &&
																					file.wav
																				)
																					setFile(
																						fi,
																						"replaceMaster",
																						!replaceMaster,
																					);
																			});
																	}}
																/>
															</span>
														</div>
													)}
												</Table.Cell>
											)}
										</Table.Row>
									);
								},
							)}
						</Table.Body>
					</Table>
				)}
				{scrapedISBN && (
					<>
						<p>{t("allMarkedTitlesCreates")}</p>
						<Button
							primary
							content={t("createChoosenTitles")}
							loading={isCreatingProductions}
							disabled={isCreatingProductions || !scrapeIsComplete || processing === "started"}
							onClick={createScrapedProductions}
						/>
					</>
				)}

				{!!files.filter((file) => file.checked && !file.upload).length && processing !== "started" && (
					<Button primary content={t("startUpload")} onClick={uploadQueue} />
				)}
			</Segment>
		</>
	);
};

const PreviewImage = ({ file }) => {
	const img = useRef();
	useEffect(() => {
		let reader = new FileReader();

		reader.onload = (e) => {
			if (img && img.current) img.current.src = e.target.result;
		};

		reader.readAsDataURL(file);
	}, [file]);

	return <img ref={img} alt="" style={{ height: 75 }} />;
};

const ScrapeResult = ({ isbn, data, skipped, setSkipped, shown, updateAnalysis }) => {
	const [isOpen, setIsOpen] = useState(!shown);
	const { t } = useTranslation();

	return (
		<Card fluid>
			{!skipped || data.linkedArticle ? (
				<Card.Content>
					{isOpen && data.img && <Image floated="right" size="tiny" src={data.img} />}
					<Card.Meta
						as="p"
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						style={{ cursor: "pointer", fontWeight: "500", color: "#646fc1" }}
					>
						<Icon name={"square outline " + (isOpen ? "minus" : "plus")} /> {t("collectedInfo")}{" "}
						{shown && <small>{t("sameAsAbove")}</small>}
					</Card.Meta>
					<Card.Header>{data.title}</Card.Header>
					<small>
						{data.audiobook && (
							<span>
								<Icon name="headphones" /> <b>{t("audioBook") + ":"}</b> {data.audiobook.isbn} &nbsp;
							</span>
						)}
						{data.ebook && (
							<span>
								<Icon name="book" /> <b>{t("eBook") + ":"}</b> {data.ebook.isbn}
							</span>
						)}
					</small>

					{isOpen && (
						<>
							{data.synopsis && data.synopsis.split(".\n").map((part, pi) => <p key={pi}>{part}</p>)}
							<small>
								{Object.entries({
									publisher: t("publisher"), //Martin: HUR GÖRA HÄR????
									genre: t("genre"),
									tags: t("tags"),
									author: t("author"),
									translator: t("translator"),
								}).map(
									([id, name]) =>
										data[id] && (
											<p key={id}>
												<b>{name}:</b> {id === "genre" ? t(data[id]) : data[id]}
											</p>
										),
								)}
								{data.audiobook && (
									<>
										{data.audiobook.reader && (
											<p>
												<b>{t("narrator") + ":"}</b> {data.audiobook.reader}
											</p>
										)}
										{data.audiobook.duration && (
											<p>
												<b>{t("duration") + ":"}</b>{" "}
												{secToDuration(data.audiobook.duration / 1000)}
											</p>
										)}
										{data.audiobook.release && (
											<p>
												<b>{t("releaseDateAudioBook")}</b> {data.audiobook.release}
											</p>
										)}
									</>
								)}
								{data.ebook && data.ebook.release && (
									<p>
										<b>{t("releaseDateEBook")}</b> {data.ebook.release}
									</p>
								)}
							</small>
						</>
					)}
				</Card.Content>
			) : (
				<Card.Content>
					<em>{t("collectedInfoIgnored")}</em>
				</Card.Content>
			)}
			<Card.Content extra>
				{!data.linkedArticle ? (
					<Form.Checkbox
						checked={!skipped}
						label={t("createTitleInAstrid")}
						onClick={(e, data) => {
							setSkipped(data.checked);
						}}
					/>
				) : (
					<div>
						<p>
							<b>
								{t("linkedArticleExists")}{" "}
								<a
									href={"/production/" + data.linkedArticle.id}
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: "black" }}
								>
									{data.linkedArticle.title}
								</a>
							</b>
						</p>
						<Button
							color="teal"
							content={t("addArticleToProd")}
							onClick={(e) => {
								let path;
								if (!data.linkedArticle.isbn) {
									console.log("skapa ljudbok");
									path = "isbn";
								}
								if (!get(data, "linkedArticle.deliveryEbook.isbn")) {
									console.log("skapa ebok");
									path = "deliveryEbook.isbn";
								}

								if (path)
									db.collection("productions")
										.doc(data.linkedArticle.id)
										.update({ [path]: isbn })
										.then(updateAnalysis);
							}}
						/>
					</div>
				)}
			</Card.Content>
		</Card>
	);
};

export default Upload;
