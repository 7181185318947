import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

import Flex from "../../../../../components/Flex/Flex";
import OfferForm from "../../../../offers/components/OfferForm/OfferForm";
import offerTypeIcons from "../../../../offers/constants/offerTypeIcons";
import useOfferTypeTranslations from "../../../../offers/hooks/useOfferTypeTranslations";

const types = [offerTypes.WORK_ORDER, offerTypes.OPEN];

export default function ProductionOffersActions({ disabled, ...props }) {
	const [type, setType] = useState();
	const translations = useOfferTypeTranslations();

	return (
		<>
			<Flex style={{ gap: 8, justifyContent: "flex-start" }}>
				{types.map((type) => (
					<Button disabled={disabled} icon key={type} onClick={() => setType(type)} labelPosition="left">
						<Icon name={offerTypeIcons[type]} />
						{translations[type]}
					</Button>
				))}
			</Flex>

			{type && <OfferForm {...props} type={type} onClose={() => setType(undefined)} />}
		</>
	);
}
