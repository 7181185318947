import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form, Header, Icon } from "semantic-ui-react";

import distributors from "astrid-config/src/distributors";
import languages from "astrid-config/src/languages";
import { db } from "astrid-firebase";

import DistributorPublisher from "./DistributorPublisher";

const DistributorSetup = ({ distributor, publishers, producerId, producerSetup }) => {
	const { t } = useTranslation();
	const { name, connection, audio, meta, disabled, email } = distributors[distributor];

	const [tempValues, setTempValues] = useState({});
	const [saving, setSaving] = useState(false);
	const [open, setOpen] = useState(false);

	const handleChange = (e, data) => {
		setTempValues({ ...tempValues, [data.name]: data.value });
	};

	// save temporary values to db, then empty temp values
	const saveChanges = () => {
		setSaving(true);
		db.collection("organizations")
			.doc(producerId)
			.collection("secrets")
			.doc("distribution")
			.set({ [distributor]: tempValues }, { merge: true })
			.then(() => {
				setTempValues({});
				setSaving(false);
			});
	};

	// merge stored values with temporary values
	const formValues = {
		...((producerSetup && producerSetup[distributor]) || {}),
		...tempValues,
	};

	return (
		<Form style={disabled ? { opacity: "0.5" } : {}}>
			<Header
				sub
				size="huge"
				onClick={() => {
					setOpen(!open);
				}}
				style={{ cursor: "pointer" }}
			>
				<Icon name={"caret " + (open ? "down" : "right")} />
				{name} ({connection ? connection + ": " : t("downloading") + ": "}
				{audio && t("audio")}
				{audio && meta && " " + t("and") + " "}
				{meta && `metadata: ${meta.format}`})
			</Header>
			{open && (
				<>
					{disabled && t("notReady")}
					{connection === "ftp" && (
						<Form.Group widths="equal">
							<Form.Input
								label={t("hostname")}
								placeholder="files.distributor.com"
								value={formValues.host || ""}
								name="host"
								onChange={handleChange}
							/>
							<Form.Select
								label={t("protocol")}
								value={formValues.protocol || "sftp"}
								name="protocol"
								onChange={handleChange}
								options={["ftp", "sftp", "ftps"].map((pro) => ({
									key: pro,
									value: pro,
									text: pro.toUpperCase(),
								}))}
							/>
							<Form.Input
								label={t("port")}
								type="number"
								value={typeof formValues.port === "undefined" ? 22 : formValues.port}
								name="port"
								onChange={handleChange}
							/>
							<Form.Input
								label={t("path")}
								placeholder="uploads"
								value={formValues.path || ""}
								name="path"
								onChange={handleChange}
							/>
						</Form.Group>
					)}
					{connection && (
						<Form.Group widths="equal">
							<Form.Input
								autoComplete="new-password"
								label={t("sharedUsername")}
								value={formValues.user || ""}
								name="user"
								onChange={handleChange}
							/>
							<Form.Input
								autoComplete="new-password"
								type="password"
								label={t("sharedPassword")}
								value={formValues.pass || ""}
								name="pass"
								onChange={handleChange}
							/>
							<Form.Select
								multiple
								search
								deburr
								name="language"
								label={t("acceptedLanguages")}
								placeholder={t("allLanguages")}
								value={formValues.language || []}
								options={Object.keys(languages).map((code) => ({
									key: code,
									value: code,
									text:
										(languages[code].sv || languages[code].name) +
										" (" +
										languages[code].nativeName +
										")",
								}))}
								onChange={handleChange}
							/>
							{email && (
								<Form.Input
									type="email"
									label={t("notificationEmail")}
									value={formValues.email || ""}
									name="email"
									onChange={handleChange}
								/>
							)}
						</Form.Group>
					)}
					{connection && (
						<Form.Group widths="equal">
							<Form.Select
								label={t("deliveryAndSchedule")}
								value={formValues.automation || "off"}
								name="automation"
								onChange={handleChange}
								options={Object.entries({
									off: t("automationOff"),
									schedule: t("automationSchedule") + " " + (meta ? "" : t("automationMissingMeta")),
								}).map(([id, text]) => ({
									key: id,
									value: id,
									text,
									disabled: id === "schedule" && !meta,
								}))}
							/>
							{formValues.automation === "schedule" && (
								<Form.Input
									label={t("scheduleDaysBefore")}
									value={formValues.scheduleDays || ""}
									name="scheduleDays"
									onChange={handleChange}
									type="number"
									required
								/>
							)}
						</Form.Group>
					)}

					<Dropdown
						button
						pointing
						scrolling
						labeled
						icon="plus"
						className="icon teal"
						size="tiny"
						text={t("addPublisher")}
					>
						<Dropdown.Menu>
							{Object.entries(publishers)
								.filter(
									([id]) =>
										!formValues.publishers ||
										!Object.entries(formValues.publishers).find(
											([bid, info]) =>
												id === bid && info.constructor.name !== "DeleteFieldValueImpl",
										),
								)
								.sort((a, b) => (a[1].toLowerCase() > b[1].toLowerCase() ? 1 : -1))
								.map(([id, name]) => (
									<Dropdown.Item
										key={id}
										text={name}
										id={id}
										onClick={(e, data) => {
											const current = formValues.publishers || {};
											current[data.id] = {};
											handleChange(e, { name: "publishers", value: current });
										}}
									/>
								))}
						</Dropdown.Menu>
					</Dropdown>
					<br />
					<br />
					{formValues.publishers &&
						Object.entries(formValues.publishers)
							.filter(([id, info]) => publishers[id] && info.constructor.name !== "DeleteFieldValueImpl")
							.sort((a, b) => (publishers[a[0]].toLowerCase() > publishers[b[0]].toLowerCase() ? 1 : -1))
							.map(([id]) => (
								<DistributorPublisher
									key={id}
									id={id}
									connection={connection}
									meta={meta}
									audio={audio}
									publishers={publishers}
									formValues={formValues}
									handleChange={handleChange}
								/>
							))}
					{!!Object.keys(tempValues).length && (
						<Button primary content={t("saveSettings")} loading={saving} onClick={saveChanges} />
					)}
				</>
			)}
		</Form>
	);
};

export default DistributorSetup;
