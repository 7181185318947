import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import createVoicesFromTeam from "astrid-firestore/src/api/productions/readers/createVoicesFromTeam";

function ResultModal({ setResult, result }) {
	const { t } = useTranslation();
	const isError = result?.rejected?.length;

	const errorMessages =
		isError &&
		result?.rejected?.map((r, i) => {
			return <Message key={i} error content={r.customError ? r.message : "Unknown error"} />;
		});

	const noActionMessage = result?.noAction && <Message positive content={result.noAction} />;

	if (errorMessages?.length || noActionMessage) {
		const header = isError
			? t("contactProductionCoordinator", "Contact production coordinator")
			: t("result", "Result");

		return (
			<Modal size="tiny" open onClose={() => setResult(undefined)}>
				<Modal.Header>{header}</Modal.Header>
				<Modal.Content>
					<Modal.Description>{errorMessages || noActionMessage}</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button color="black" onClick={() => setResult(undefined)}>
						{t("close")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
	return null;
}

export default function SetVoiceFromTeamReaders({ production }) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(undefined);

	return (
		<>
			<Button
				style={{ marginLeft: 15 }}
				loading={loading}
				disabled={loading}
				onClick={async () => {
					try {
						setResult(undefined);
						setLoading(true);
						const result = await createVoicesFromTeam(firebase, { production });
						setResult(result);
					} catch (error) {
						console.error(error);
					}
					setLoading(false);
				}}
			>
				{t("showReaderAndPrice", "Show narrator and price")}
			</Button>
			{result && <ResultModal result={result} setResult={setResult} />}
		</>
	);
}
