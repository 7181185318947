import { useMemo } from "react";

import agreementParts from "astrid-firestore/src/api/agreements/constants/agreementParts";

import useAgreementsPriceOptions from "../../../../agreements/hooks/useAgreementsPriceOptions";

export default function useProductionPriceOptions(production) {
	const publisherPriceOptions = useAgreementsPriceOptions({
		part: agreementParts.PUBLISHER,
		firstPartyId: production?.producer,
		secondPartyId: production?.publisher,
	});

	const productionPriceOptions = useMemo(
		() => publisherPriceOptions.filter(({ data }) => data.type === "production"),
		[publisherPriceOptions],
	);

	const otherPriceOptions = useMemo(
		() => publisherPriceOptions.filter(({ data }) => data.type !== "production" && !data.type.includes("cloud")),
		[publisherPriceOptions],
	);

	return [productionPriceOptions, otherPriceOptions];
}
