import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import ArticleDistributionChannelsCollectionFlexTable from "../../../../distribution/components/ArticleDistributionChannelsCollectionFlexTable/ArticleDistributionChannelsCollectionFlexTable";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleChannelsForm({ article, organization }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		clearOnSuccess: true,
		onSubmit: ({ channels }) => {
			return api.articles.distribute({ articles: [article], channels });
		},
	});

	return (
		<AdminForm header={t("channels", "Channels")} submitText={t("distribute", "Distribute")} {...form}>
			<ArticleDistributionChannelsCollectionFlexTable article={article} organization={organization} />
		</AdminForm>
	);
}
