export default {
	name: "Publit",
	format: "mp3",
	connection: false,
	audio: {
		zip: true,
		rename: "SPACELESSSLUGSEQPAD.SUFFIX",
	},
	disableArticles: ["cd", "mp3cd", "ebook"],
	allowMissingISBN: true,
};
