import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";
import ArticlesInstantSearchDataTable from "../../../articles/components/ArticlesInstantSearchDataTable/ArticlesInstantSearchDataTable";

export default function SuperAdminArticlesView() {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("articles", "Articles")}>{searchInput}</AdminHeader>

			<ArticlesInstantSearchDataTable searchQuery={searchQuery} selectable={false} footer={false} />
		</>
	);
}
