import { Table } from "semantic-ui-react";

export default function TableFooter({ table, children }) {
	const { footerGroups } = table;

	return (
		<Table.Footer fullWidth>
			{children ? (
				<Table.Row>
					<Table.HeaderCell colSpan={99}>
						<children.type {...children.props} {...table} />
					</Table.HeaderCell>
				</Table.Row>
			) : (
				footerGroups.map((group) => (
					<Table.Row {...group.getFooterGroupProps()}>
						{group.headers.map((column) => (
							<Table.HeaderCell {...column.getFooterProps()} textAlign={column.textAlign || "left"}>
								{column.render("Footer")}
							</Table.HeaderCell>
						))}
					</Table.Row>
				))
			)}
		</Table.Footer>
	);
}
