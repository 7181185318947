import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminUsersView from "../views/AdminUsersView/AdminUsersView";

export default function AdminUsers() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("users.view")) {
		return <Unauthorized />;
	}

	return <AdminUsersView organization={organization} />;
}
