import enLocale from "@fullcalendar/core/locales/en-gb";
import svLocale from "@fullcalendar/core/locales/sv";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";

import { Dimmer, Grid, Label, Loader, Segment } from "semantic-ui-react";

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import msToTime from "astrid-helpers/src/msToTime";

import Flex from "../../../../components/Flex/Flex";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";

import BookingAssistant from "../../components/BookingAssistant/BookingAssistant";
import FilterForm from "../../components/FilterForm/FilterForm";
import useFilterForm from "../../components/FilterForm/hooks/useFilterForm";
import HeaderToolbar from "../../components/HeaderToolbar/HeaderToolbar";
import useTimelineNavigation from "../../components/HeaderToolbar/hooks/useTimelineNavigation";
import LinkToOldCalendar from "../../components/LinkToOldCalendar";
import NotificationModal from "../../components/NotificationModal/NotificationModal";
import ResourceHeader from "../../components/ResourceHeader/ResourceHeader";
import SessionModal from "../../components/SessionModal/SessionModal";

import EventContent from "./components/EventContent";
import MoreLinkContent from "./components/MoreLinkContent";
import useBackgroundEvents from "./hooks/useBackgroundEvents";
import useEvents from "./hooks/useEvents";
import useFullCalendarProps from "./hooks/useFullCalendarProps";
import useOnChangeEvent from "./hooks/useOnChangeEvent";
import useOnCreateSession from "./hooks/useOnCreateSession";
import useResourceTypes from "./hooks/useResourceTypes";
import useSessions from "./hooks/useSessions";
import useStudioTime from "./hooks/useStudioTime";

export default function TimelineView({ organization, productionId }) {
	const { t } = useTranslation();
	const ref = useRef();
	const { i18n } = useTranslation();
	const hasRight = useHasRight();
	const user = useProfile();

	const isReader = organization?.type === organizationTypes.NARRATOR;

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [openFilterMenu, setOpenFilterMenu] = useState(false);
	const [sessionId, setSessionId] = useState(null);
	const [viewType, setViewType] = useState("");
	const [resourceType, setResourceType] = useState(!isReader ? "studios" : "productions");
	const [movingEvent, setMovingEvent] = useState();
	const [isEditing, setIsEditing] = useState(false);
	const [openBookingAssistant, setOpenBookingAssistant] = useState(!!productionId);
	const [openNotificationModal, setOpenNotificationModal] = useState(false);
	const [modifiedSessions, setModifiedSessions] = useState(null);
	const [createSessionData, setCreateSessionData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showAllResources, setShowAllResources] = useState(false);
	const [resourcesOpen, setResourcesOpen] = useState(true);

	// Filtering
	const filterForm = useFilterForm({ user, organization });

	const { views, slotTimeFormat, customButton } = useFullCalendarProps(setOpenFilterMenu);

	const onClickFilter = () => {
		setOpenFilterMenu((openFilterMenu) => !openFilterMenu);
	};

	// Sessions
	const [sessions, filteredSessions] = useSessions({
		startDate,
		organization,
		endDate,
		modifiedSessions,
		viewType,
		filters: filterForm.filters,
	});

	const session = sessions.find((session) => session.id === sessionId);

	// Resources
	const { resourceTypes, loading, error } = useResourceTypes({ organization, sessions });

	const { resources, getOverlappingResourceIds, checkOverlap, getResourceId, resourceColors, getSessionOrder } =
		resourceTypes[resourceType];

	const filteredResources = resources.filter((resource) => {
		if (filterForm.filters.studios.length > 0 && viewType.includes("resourceTimeline")) {
			return filterForm.filters.studios.includes(resource.id);
		} else {
			return showAllResources || filteredSessions.some((session) => getResourceId(session) === resource.id);
		}
	});

	// Events
	const events = useEvents({
		sessions: filteredSessions,
		getResourceId,
		resourceColors,
		isEditing,
		resourceType,
		getSessionOrder,
	});

	const studioTimeArray = useStudioTime(filteredSessions);

	const backgroundEvents = useBackgroundEvents({
		modifiedSession: movingEvent?.extendedProps?.session || createSessionData,
		sessions,
		checkOverlap,
		getOverlappingResourceIds,
	});

	const eventClick = ({ event }) => {
		if (createSessionData || isEditing) {
			return false;
		}

		setSessionId(event.id);
	};

	// Editing
	const onClickEdit = () => {
		setIsEditing((isEditing) => !isEditing);
	};

	const onChangeEvent = useOnChangeEvent({ setIsLoading, resourceType, setModifiedSessions });

	// Booking functions
	const onClickBook = () => {
		setOpenBookingAssistant(true);
		setIsEditing(false);
	};

	const onStartBooking = (data) => {
		setCreateSessionData(data);
		setOpenBookingAssistant(false);
		setShowAllResources(true);
		setIsEditing(true);

		if (viewType.includes("Grid")) {
			navigation.changeView(viewType.replace("timeGrid", "resourceTimeline"));
		}
	};

	const onCreateSession = useOnCreateSession({ setIsLoading, createSessionData, sessions, setModifiedSessions });

	const onStopBooking = () => {
		setCreateSessionData(null);
		setIsEditing(false);
		setModifiedSessions(null);
	};

	// Navigation
	const isTimelineView = viewType.includes("resourceTimeline");

	const navigation = useTimelineNavigation({ ref });

	// Notifications
	const onOpenNotifyModal = () => {
		setOpenNotificationModal(true);
	};

	const businessHours = {
		daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
		startTime: "05:00",
		endTime: "23:00",
	};

	return (
		<>
			<LinkToOldCalendar />
			<Segment>
				<LoadingContext loading={loading} error={error}>
					<Grid>
						{openFilterMenu && (
							<Grid.Column width={3}>
								<FilterForm form={filterForm} organization={organization} />
							</Grid.Column>
						)}
						<Grid.Column width={openFilterMenu ? 13 : 16}>
							<HeaderToolbar
								onOpenNotifyModal={onOpenNotifyModal}
								navigation={navigation}
								startDate={startDate}
								endDate={endDate}
								onClickFilter={onClickFilter}
								viewType={viewType}
								isEditing={isEditing}
								onClickEdit={onClickEdit}
								onClickBook={onClickBook}
								modifiedSessions={modifiedSessions}
								isBooking={!!createSessionData}
								onStopBooking={onStopBooking}
								setShowAllResources={setShowAllResources}
								showAllResources={showAllResources}
								isTimelineView={isTimelineView}
								filterForm={filterForm}
								resourceType={resourceType}
								disableTools={!hasRight("calendar.viewTimeline")}
							/>
							<FullCalendar
								ref={ref}
								initialView={
									hasRight("calendar.defaultTimeline") ? "resourceTimelineDay" : "timeGridWeek"
								}
								views={views}
								allDaySlot={false}
								height="70vh"
								schedulerLicenseKey="0520726615-fcs-1698239718"
								locales={[enLocale, svLocale]}
								locale={i18n.language}
								aspectRatio={false}
								plugins={[momentPlugin, resourceTimelinePlugin, interactionPlugin, timeGridPlugin]}
								headerToolbar={false}
								resourceAreaHeaderContent={
									!isReader ? (
										<ResourceHeader
											t={t}
											resourceType={resourceType}
											setResourceType={setResourceType}
											showAllResources={showAllResources}
											setShowAllResources={setShowAllResources}
											resourcesOpen={resourcesOpen}
											setResourcesOpen={setResourcesOpen}
											disableTools={isReader}
										/>
									) : (
										t("productions", "Productions")
									)
								}
								resourceLabelContent={resourceTypes[resourceType].resourceTitle}
								resourcesInitiallyExpanded={resourcesOpen}
								resourceGroupField={resourceType === "studios" ? "group" : undefined}
								resources={filteredResources}
								resourceAreaWidth="18%"
								resourceOrder={resourceType !== "engineers" ? "groupSorting, title" : "title"}
								firstDay={1}
								scrollTimeReset={false}
								navLinks
								customButtons={customButton}
								selectable={!!createSessionData && isTimelineView}
								select={onCreateSession}
								events={[...events, ...backgroundEvents]}
								eventClick={eventClick}
								eventChange={onChangeEvent}
								eventDragStart={({ event }) => setMovingEvent(event)}
								eventDragStop={() => setMovingEvent(null)}
								eventResizeStart={({ event }) => setMovingEvent(event)}
								eventResizeStop={() => setMovingEvent(null)}
								eventOrder="order,start,-duration,allDay,title"
								eventOrderStrict
								displayEventTime
								eventTimeFormat={slotTimeFormat}
								businessHours={businessHours}
								eventOverlap={false}
								eventAllow={({ start, end, resource }) => {
									const isOverlapping = backgroundEvents
										.filter((backgroundEvent) => backgroundEvent.resourceIds.includes(resource.id))
										.some((event) => event.start < end && start < event.end);

									return !isOverlapping;
								}}
								eventContent={(props) => (
									<EventContent
										isTimelineView={isTimelineView}
										modifiedSessions={modifiedSessions}
										setModifiedSessions={setModifiedSessions}
										{...props}
									/>
								)}
								moreLinkClick={isReader ? "timeGridDay" : "resourceTimelineDay"}
								moreLinkContent={MoreLinkContent}
								nowIndicator
								datesSet={({ view, start, end }) => {
									setViewType(view.type);

									if (start.getTime() !== startDate?.getTime()) {
										setStartDate(start);
									}
									if (end.getTime() !== endDate?.getTime()) {
										setEndDate(end);
									}

									if (view.type === "resourceTimelineWeek" && view.type !== viewType) {
										view.calendar.scrollToTime({
											days: moment().day() - 1,
											hours: moment().hour(),
										});
									}
								}}
							/>
							{!isReader && (
								<Flex style={{ justifyContent: "flex-start", gap: 10, marginTop: 10 }}>
									{studioTimeArray.map(({ title, time }) => (
										<Label key={title} style={{ marginBottom: 0 }}>
											{title}:<Label.Detail>{msToTime(time, false)}</Label.Detail>
										</Label>
									))}
								</Flex>
							)}
						</Grid.Column>
					</Grid>
				</LoadingContext>

				{session && (
					<SessionModal
						session={session}
						onClose={() => {
							setSessionId(null);
						}}
						setModifiedSessions={setModifiedSessions}
						sessions={sessions}
					/>
				)}

				{openBookingAssistant && (
					<BookingAssistant
						organization={organization}
						defaultValues={createSessionData}
						setOpenBookingAssistant={setOpenBookingAssistant}
						onStartBooking={onStartBooking}
						defaultProductionId={productionId}
						onClose={() => setOpenBookingAssistant(false)}
					/>
				)}

				{openNotificationModal && (
					<NotificationModal
						onStopBooking={onStopBooking}
						modifiedSessions={modifiedSessions}
						onClose={() => setOpenNotificationModal(false)}
					/>
				)}

				{isLoading && (
					<Dimmer active inverted>
						<Loader inverted size="large" />
					</Dimmer>
				)}
			</Segment>
		</>
	);
}
