import FlexTableCell from "./FlexTableCell";
import StyledSegment from "./StyledSegment";

export default function FlexTableRow({ row, gap, ...props }) {
	const { columns } = row;

	return (
		<StyledSegment style={{ gap }} {...props}>
			{columns.map((column) => (
				<FlexTableCell key={column.id} {...column} row={row} />
			))}
		</StyledSegment>
	);
}
