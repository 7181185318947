import React from "react";
import { Header } from "semantic-ui-react";

export default function AgreementHeader({ agreement }) {
	return (
		<Header as="h4" style={{ margin: 0 }}>
			{agreement.name}

			<Header.Subheader>
				{agreement.secondParty?.vendor?.name} / {agreement.firstParty?.vendor?.name}
			</Header.Subheader>
		</Header>
	);
}
