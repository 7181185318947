import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Button, Card, Form } from "semantic-ui-react";

import useCountryOptions from "astrid-hooks/src/useCountryOptions";

import "./phoneInput.css";

function validateForm(vendorDetails) {
	const validations = {
		name: !vendorDetails.name,
		organizationNumber: !vendorDetails.organizationNumber,
		street: !vendorDetails.street,
		city: !vendorDetails.city,
		country: !vendorDetails.country,
		zip: !vendorDetails.zip,
		phone: vendorDetails.phone && !isValidPhoneNumber(vendorDetails.phone),
	};
	return Object.entries(validations).reduce((acc, [key, value]) => {
		if (!value) {
			return acc;
		}
		return {
			...acc,
			[key]: value,
		};
	}, {});
}

export default function EditVendor({ vendorKey, vendor, setIsEditing, saveVendor, deleteVendor }) {
	const { t } = useTranslation();

	const [vendorDetails, setVendorDetails] = useState(vendor);
	const [errors, setErrors] = useState({});

	const onChangeVendorDetails = (value, property) => {
		const { [property]: omit, ...restErrors } = errors;

		setErrors({ ...restErrors });

		setVendorDetails({
			...vendorDetails,
			[property]: value,
		});
	};

	const countries = useCountryOptions();

	return (
		<Card key={vendorKey}>
			<Card.Content>
				<Form
					onSubmit={() => {
						const validated = validateForm(vendorDetails);
						if (Object.keys(validated).length === 0) {
							setIsEditing(false);
							saveVendor(vendorKey, vendorDetails);
						} else {
							setErrors(validated);
						}
					}}
				>
					{vendor.notYetStored ? (
						<Form.Input
							label={t("vendorName")}
							value={vendorDetails.name}
							onChange={(e) => {
								onChangeVendorDetails(e.target.value, "name");
							}}
							error={errors["name"]}
						/>
					) : (
						<Form.Input label={t("vendorName")} disabled value={vendorDetails.name} />
					)}

					{vendor.notYetStored ? (
						<Form.Input
							label={t("vendorOrgNo")}
							value={vendorDetails.organizationNumber}
							onChange={(e) => {
								onChangeVendorDetails(e.target.value, "organizationNumber");
							}}
							error={errors["organizationNumber"]}
						/>
					) : (
						<Form.Input label={t("vendorOrgNo")} disabled value={vendorDetails.organizationNumber} />
					)}
					<Form.Input
						label={t("description", "Description")}
						value={vendorDetails.description}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "description");
						}}
					/>
					<Form.Input
						label={t("vendorAddr1")}
						value={vendorDetails.street}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "street");
						}}
						error={errors["street"]}
					/>
					<Form.Input
						label={t("vendorAddr2")}
						value={vendorDetails.secondaryStreet}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "secondaryStreet");
						}}
					/>
					<Form.Input
						label={t("vendorCity")}
						value={vendorDetails.city}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "city");
						}}
						error={errors["city"]}
					/>
					<Form.Input
						label={t("vendorState")}
						value={vendorDetails.state}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "state");
						}}
					/>
					<Form.Select
						search
						deburr
						label={t("chooseCountry")}
						placeholder="Choose country"
						options={countries}
						value={vendorDetails.country}
						onChange={(e, data) => {
							onChangeVendorDetails(data.value, "country");
						}}
						error={errors["country"]}
					/>
					<Form.Input
						label={t("vendorZip")}
						value={vendorDetails.zip}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "zip");
						}}
						error={errors["zip"]}
					/>
					<Form.Input
						label={t("email")}
						value={vendorDetails.email}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "email");
						}}
						error={errors["email"]}
					/>
					<Form.Field className="override-semantic-ui-img-style" error={errors["phone"]}>
						<label>{t("vendorPhone")}</label>
						<PhoneInput
							placeholder={t("vendorPhone")}
							value={vendorDetails.phone}
							defaultCountry="SE"
							onChange={(value) => {
								if (!value) value = "";
								onChangeVendorDetails(value, "phone");
							}}
						/>
					</Form.Field>
					<Form.Input
						label={t("vendorVAT", "VAT number")}
						value={vendorDetails.vat}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "vat");
						}}
					/>
					<Form.TextArea
						label={t("billingInstructions", "Billing instructions")}
						value={vendorDetails.billingInstructions}
						onChange={(e) => {
							onChangeVendorDetails(e.target.value, "billingInstructions");
						}}
						error={errors["billingInstructions"]}
					/>
					<div className="ui two buttons">
						<Button icon="checkmark" type="submit" primary />
						<Button
							icon="close"
							onClick={() => {
								if (vendor.notYetStored) {
									deleteVendor(vendorKey);
								} else {
									setIsEditing(false);
								}
							}}
						/>
					</div>
				</Form>
			</Card.Content>
		</Card>
	);
}
