import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Title } from "astrid-firestore/src/api/titles/types/Title";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateTitleForm({ organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit } = useSimpleForm({
		schema: Title,
		defaultValues: {
			name: "",
		},
		onSubmit: ({ name }) => {
			return api.titles.create({
				name,
				publisher: organization,
			});
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<ModalPrompt size="tiny" header={t("createTitle", "Create title")} onSave={onSubmit} onClose={onClose}>
			<Form form={form}>
				<Form.Input name="name" placeholder={t("titleName", "Title name")} />
			</Form>
		</ModalPrompt>
	);
}
