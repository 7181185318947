import { useTranslation } from "react-i18next";

import useHasRight from "../../authorization/hooks/useHasRight";

export default function useDistributionDropdownMenuOptions(distribution) {
	const { t } = useTranslation();

	const hasRight = useHasRight();

	return [
		{
			text: t("view", "View"),
			linkTo: `/publisher/${distribution.publisher.id}/admin/distributions/${distribution.id}`,
		},
		{
			text: t("viewPipeline", "View pipeline"),
			linkTo: `/admin/distributions/channels/${distribution.channel.id}/pipelines/${distribution.pipeline.id}`,
			disabled: !hasRight("distribution.admin"),
		},
		{
			text: t("viewArticle", "View article"),
			linkTo: `/publisher/${distribution.publisher.id}/admin/articles/${distribution.article.id}`,
		},
		{
			text: t("viewArtifact", "View artifact"),
			linkTo: `/publisher/${distribution.publisher.id}/admin/articles/${distribution.article.id}/artifacts/${distribution.artifact.id}`,
		},
	];
}
