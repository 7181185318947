import agreementStatuses from "../agreements/constants/agreementStatuses";
import isApproved from "../approval/isApproved";

import offerStatuses from "./constants/offerStatuses";

export default function createOfferStatus({ approved, agreement }) {
	if (!agreement) {
		return approved ? offerStatuses.OFFERED : offerStatuses.PENDING_APPROVAL;
	}

	if (agreement.status === agreementStatuses.ACTIVE) {
		return approved ? offerStatuses.OFFERED : offerStatuses.PENDING_APPROVAL;
	}

	if (isApproved(agreement) && agreement.status === agreementStatuses.PENDING_APPROVAL) {
		return offerStatuses.PENDING_SIGNING;
	}

	if (!isApproved(agreement) && agreement.status === agreementStatuses.PENDING_APPROVAL) {
		return offerStatuses.PENDING_APPROVAL;
	}

	return offerStatuses.DRAFT;
}
