import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "semantic-ui-react";

import isPendingApproval from "astrid-firestore/src/api/approval/isPendingApproval";

import Flex from "../../../../components/Flex/Flex";

import useApprovalActions from "../../../approval/hooks/useApprovalActions";
import useApprovalStatusTranslations from "../../../approval/hooks/useApprovalStatusTranslations";
import useHasRight from "../../../authorization/hooks/useHasRight";

import AdminActionsContext from "./context/AdminActionsContext";

export default function ApprovalAction({ right, disabled, getData }) {
	const { t } = useTranslation();
	const { data } = useContext(AdminActionsContext);

	const { approve, deny, loading, reset } = useApprovalActions(data.ref);

	const hasRight = useHasRight();
	const approvalStatusTranlsations = useApprovalStatusTranslations();

	return (
		<Card.Content extra>
			{isPendingApproval(data) && hasRight(right) && (
				<>
					<Button
						color="teal"
						loading={loading}
						disabled={disabled}
						onClick={async () => {
							await approve(getData?.("approve") || {});
						}}
					>
						{t("approve", "Approve")}
					</Button>{" "}
					<Button
						color="red"
						style={{ minWidth: "97px" }}
						loading={loading}
						disabled={disabled}
						onClick={async () => {
							await deny(getData?.("deny") || {});
						}}
					>
						{t("deny", "Deny")}
					</Button>
				</>
			)}

			{!isPendingApproval(data) && (
				<Card.Description>
					<Flex style={{ minHeight: 36 }}>
						<div>
							{approvalStatusTranlsations[data.approval?.status]}

							{data.approval?.user &&
								` - ${data.approval?.user.name}, ${data.approval.timestamp.toDate().toLocaleString()}`}
						</div>

						{hasRight(right) && (
							<Button
								size="mini"
								onClick={async () => {
									await reset(getData?.("reset") || {});
								}}
							>
								{t("undoAction", "Undo")}
							</Button>
						)}
					</Flex>
				</Card.Description>
			)}
		</Card.Content>
	);
}
