import React from "react";
import { Header } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import TaskDropdownMenu from "../../TaskDropdownMenu/TaskDropdownMenu";
import TaskStatusLabel from "../../TaskStatus/TaskStatusLabel";

export default function TaskTitle({ active, task, link, ...props }) {
	return (
		<Accordion.Title {...props} active={active}>
			<Flex style={{ gap: 12, justifyContent: "flex-start", whiteSpace: "nowrap" }}>
				<Header as="h4" style={{ margin: 0 }}>
					{task.title}

					<Header.Subheader>
						{task.created?.toDate().toLocaleDateString(undefined, {
							year: "numeric",
							month: "long",
							day: "numeric",
							hour: "numeric",
						})}
					</Header.Subheader>
				</Header>
			</Flex>

			<Flex style={{ gap: 12, justifyContent: "flex-end", whiteSpace: "nowrap" }}>
				<TaskStatusLabel task={task} />
				<TaskDropdownMenu task={task} link={link} />
			</Flex>
		</Accordion.Title>
	);
}
