import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import AdminTranslatorsView from "../views/AdminTranslatorsView/AdminTranslatorsView";

export default function AdminTranslators() {
	const context = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("translators.view")) {
		return <Unauthorized />;
	}

	return <AdminTranslatorsView {...context} />;
}
