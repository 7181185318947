import Bar from "./Bar";

const colors = {
	main: "#e5e5e5",
	bookedHours: "#fbbd08",
	completeHours: "#21ba45",
};

export default function SessionsProgressBar({ bookedHours, completeHours, ...props }) {
	const containerStyle = {
		display: "grid",
		minWidth: "200px",
	};
	const rowStyle = {
		gridColumn: 1,
		gridRow: 1,
	};

	const rows = [
		{ progress: 100, color: "main" },
		{ progress: bookedHours * 100, color: "bookedHours" },
		{ progress: completeHours * 100, color: "completeHours" },
	];

	return (
		<div style={containerStyle} {...props}>
			{rows.map((row, index) => {
				return <Bar key={index} index={index} rowStyle={rowStyle} row={row} colors={colors} />;
			})}
		</div>
	);
}
