import { useTranslation } from "react-i18next";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import Form from "../../../../forms/components/Form/Form";
import { useOrganizationType } from "../../../../organizations/state/organizationType";

export default function TeamMemberFirstPartyField() {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();
	const organizationType = useOrganizationType();

	const { role, user, production } = watch();

	if (!user || role !== teamRoles.READER || organizationType !== "producer") {
		return null;
	}

	return (
		<Form.Select
			name="firstPartyId"
			label={t("contractedBy", "Contracted by")}
			options={[
				{
					key: production.publisher,
					value: production.publisher,
					text: production.publisherName,
				},
				{
					key: production.producer,
					value: production.producer,
					text: production.producerName,
				},
			]}
		/>
	);
}
