import { useMemo } from "react";

import sortOptions from "../../../utils/sortOptions";

import useOrganizationsOnce from "./useOrganizationsOnce";

export default function useOrganizationOptions(options) {
	const organizations = useOrganizationsOnce(options);

	return useMemo(
		() =>
			sortOptions(
				organizations.map((organization) => ({
					key: organization.id,
					value: organization.id,
					text: organization.name,
				})),
			),
		[organizations],
	);
}
