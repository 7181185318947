import notify from "astrid-firebase/src/notifications";

import getDocumentData from "../../helpers/getDocumentData";

export default async function notifyAgreementTermination(agreementRef, t) {
	const agreement = await getDocumentData(agreementRef);
	const recipients = [agreement.firstSignee.id, agreement.secondSignee.id];

	const getNotificationData = (user) => {
		const userLanguage = user.languageCode || "en";

		return {
			subject: t("agreementTerminationSubject", "Agreement has been terminated", { lng: userLanguage }),
			message: t("agreementTerminationMessage", {
				agreementName: agreement.name,
				userName: agreement.termination.user.name,
				lng: userLanguage,
			}),
			sendOnStage: true,
		};
	};

	return notify(recipients, getNotificationData);
}
