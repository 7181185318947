import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import { filterDuplicateKeys } from "../../../../ui/utils/filters";

export default function OfferSecondPartySelect({ offers }) {
	const { t } = useTranslation();

	const secondPartyOptions = offers
		.filter((offer) => offer.secondParty)
		.map((offer) => ({
			key: offer.secondParty.id,
			value: offer.secondParty.id,
			text: offer.secondParty.name,
		}))
		.filter(filterDuplicateKeys);
	return (
		<Form.Select
			search
			name="secondParties"
			multiple
			options={secondPartyOptions}
			label={t("recipient", "Recipient")}
		/>
	);
}
