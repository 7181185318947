import get from "lodash/get";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

function MasterButton({ production, productionRights, article, title, openExportModal }) {
	const settings = get(production, "master." + article + ".settings");
	const files = get(production, "master." + (article === "mp3cd" ? "total" : article) + ".files");
	const { t } = useTranslation();
	return files &&
		files.length &&
		(productionRights.includes("production.masterExportEdit") ||
			(productionRights.includes("production.masterExportCreate") && settings && settings.duration)) ? (
		<Button
			primary
			size="small"
			icon={"cloud download"}
			className="export-button"
			labelPosition="left"
			content={t("masterBtn")}
			onClick={() => {
				openExportModal(article, title);
			}}
		/>
	) : null;
}
export default MasterButton;

/* export default function MasterButton({ production, productionRights, article, title, openExportModal }) {
	const settings = get(production, "master." + article + ".settings");
	const files = get(production, "master." + (article === "mp3cd" ? "total" : article) + ".files");

	return files &&
		files.length &&
		(productionRights.includes("production.masterExportEdit") ||
			(productionRights.includes("production.masterExportCreate") && settings && settings.duration)) ? (
		<Button
			primary
			size="small"
			icon={"cloud download"}
			className="export-button"
			labelPosition="left"
			content="Exportera master"
			onClick={() => {
				openExportModal(article, title);
			}}
		/>
	) : null;
} */
