export default {
	name: "Nextory audio",
	format: "wav",
	connection: "ftp",
	useSSH: "test-nextory-sftp-private-key",
	meta: {
		folder: "meta",
		format: "onix",
		plaintextSynopsis: true,
		fields: ["availability", "takedown"],
		sharedFields: ["tags", "bisac"],
	},
	audio: {
		zip: true,
		rename: "ISBN_SEQ.SUFFIX",
	},
	ebook: false,
	cover: true,
	disableArticles: ["cd", "mp3cd"],
};
