import { Segment } from "semantic-ui-react";
import styled, { css } from "styled-components";

const StyledSegment = styled(Segment)`
	display: flex;
	align-items: center;
	transition: color 0.2s ease-in-out;
	white-space: nowrap;

	${({ selectable }) =>
		selectable &&
		css`
			color: rgba(0, 0, 0, 0.4);

			&:hover {
				color: rgba(0, 0, 0, 1);
			}
		`}
`;

export default StyledSegment;
