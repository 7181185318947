import { useMemo } from "react";

import permissions from "astrid-permissions/permissions";

import { useProfile } from "../../authentication/state/profile";

export default function useProductionPermissions(production) {
	const user = useProfile();

	return useMemo(() => {
		const isSuperAdmin = ["owner", "admin"].includes(user.permissions?.role);
		const isProducer = isSuperAdmin
			? ["any", "producerAdmin"]
			: production?.producer && user.permissions?.producer?.[production.producer];
		const isPublisher = production?.publisher && user.permissions?.publisher?.[production.publisher];
		const isReader = user.permissions?.reader && production?.reader?.includes(user.id);
		const isManager = production?.manager?.includes(user.id);
		const isSubcontractor = production?.subcontractor === user.id;
		const isEditor = production?.editor?.includes(user.id);
		const isRecorder = production?.bookedRecorders?.includes(user.id);
		const isProofer = production?.proofer?.includes(user.id);

		const allRights = [
			...(isProducer || []),
			...(isPublisher || []),
			...(isReader ? ["reader"] : []),
			...(isManager ? ["manager"] : []),
		].reduce((prev, curr) => {
			if (permissions[curr]) {
				prev.push(...permissions[curr]);
			}
			return prev;
		}, []);
		const rights = [...new Set(allRights)];

		return {
			isReader,
			isManager,
			isProducer,
			isPublisher,
			isSubcontractor,
			rights,
			isEditor,
			isRecorder,
			isProofer,
		};
	}, [production, user]);
}
