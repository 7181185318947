import moment from "moment";

import { db, firebase } from "astrid-firebase";
import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";
import { addDocument, getDocumentData } from "astrid-firestore/src/helpers";

async function getUserInfo(id) {
	const user = await getDocumentData(db.collection("users").doc(id));
	if (user.email.substr(0, 6) !== "dummy-") {
		return {
			id: user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
		};
	}
}

export default async function notifyCancelSession({ session, production, profile, cancelReason, t }) {
	const host = window.ES.stage ? "https://stage.astrid.fm" : "https://astrid.fm";
	const title = production ? production.title : t("productionNotSpecified");

	const url = production && `${host}/production/${session.production}`;
	const messageUrl = url ? t("clickForProduction") + url : "";

	const subject = t("recSessionCancelled") + ` - ${title}`;

	const shouldNotifyRecorder = ![
		sessionOfferTypes.ANY_RECORDER_IN_TEAM,
		sessionOfferTypes.OPEN_POSITION_RECORDER,
		null,
	].includes(session.recorder);

	const affectedUserIds = shouldNotifyRecorder ? [...(session.reader || []), session.recorder] : session.reader;

	const affectedUsers = await Promise.all(
		affectedUserIds?.map(async (id) => {
			return await getUserInfo(id);
		}),
	);

	return Promise.allSettled(
		affectedUsers
			.filter((v) => v)
			.map(async (user) => {
				try {
					const message = t("sessionCancelledMessage", {
						messDetails: t("sessionMessageDetails", {
							messTitel: production ? production.title : t("productionNotSpecified"),
							messTime:
								moment(session.start.toDate()).format("YYYY-MM-DD LT") +
								" - " +
								moment(session.end.toDate()).format("LT"),
							messStudio: session.studioData.name + ", " + session.studioData.address,
						}),
						firstName: profile.firstName,
						lastName: profile.lastName,
						cancelMessage: cancelReason || "",
						webUrl: messageUrl,
					});

					const ref = await addDocument(db.collection("notifications"), {
						to: {
							uid: user?.id,
							email: user.email,
							producer: session.producer || false,
						},
						message,
						subject,
						time: firebase.firestore.FieldValue.serverTimestamp(),
						// attachments: attachments || null,
						// sendOnStage: true,
					});
					return `Added email notification ${JSON.stringify(user)}, ${subject}, ${message}, ${ref.id}`;
				} catch (error) {
					throw new Error(`Email notify error, ${error}, ${JSON.stringify(user)}`);
				}
			}),
	).then((results) => {
		results.forEach((result) => {
			console.log(result.value);
		});
	});
}
