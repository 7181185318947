import { useMemo, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { Button, Grid, Loader, Transition } from "semantic-ui-react";

import productionTypes from "astrid-firestore/src/api/productions/constants/productionTypes";

import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";
import { useProfile } from "../../../authentication/state/profile";
import CustomSearchBox from "../../../listproductions/views/ListProductionsView/components/CustomSearchBox";
import Hits from "../../../listproductions/views/ListProductionsView/components/Hits";
import Stats from "../../../listproductions/views/ListProductionsView/components/Stats";
import useSelectedColumns from "../../../listproductions/views/ListProductionsView/hooks/useSelectedColumns";

import MyProductionsAccordion from "../../components/MyProductionsAccordion";
import useMyProductionColumns from "../../hooks/useMyProductionColumns";
import getInitialSelectedColumns from "../../utils/getInitialSelectedColumns";

function getFilter({ profile, organization }) {
	switch (organization.type) {
		case "producer":
			return `${organization.type}:${organization.id} AND status:done AND teamUserIds:${profile.id}`;
		case "publisher":
			return `${organization.type}:${organization.id} AND status:done AND (teamUserIds:${profile.id} OR publisherManagerIds:${profile.id})`;
		case "narrator":
			return `status:done AND teamUserIds:${profile.id} AND NOT productionType:${productionTypes.EXTERNAL}`;
		default:
			throw Error("CompletedProductions: missing type!");
	}
}

export default function CompletedProductionsView({ organization }) {
	const profile = useProfile();
	const searchClient = useAlgoliaSearchClient();

	const [showRefinements, setShowRefinements] = useState(false);

	const initialColumns = useMemo(() => getInitialSelectedColumns(organization.type), [organization.type]);

	const [selectedColumns, selectColumn] = useSelectedColumns(initialColumns.storageKey, initialColumns.defaults);

	const myProductionColumns = useMyProductionColumns({
		profile,
		organization,
		selectedColumns,
		isAlgolia: true,
	});

	const filter = getFilter({ organization, profile });

	if (!searchClient) {
		return <Loader active />;
	}

	return (
		<InstantSearch indexName="deliveryDate_desc" searchClient={searchClient}>
			<Configure filters={filter} />
			<Grid stackable columns={2}>
				<Grid.Row>
					<Grid.Column width={showRefinements ? 3 : 1}>
						<Button
							icon={showRefinements ? "hide" : "unhide"}
							onClick={() => {
								setShowRefinements(!showRefinements);
							}}
						/>
					</Grid.Column>
					<Grid.Column width={showRefinements ? 13 : 15}>
						<CustomSearchBox />
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Grid stackable columns={2}>
				<Transition visible={showRefinements} duration={500} animation="slide right">
					<Grid.Column
						mobile={16}
						tablet={16}
						computer={3}
						style={showRefinements ? { paddingRight: 0 } : { left: "-100%", position: "absolute" }}
					>
						<MyProductionsAccordion selectedColumns={selectedColumns} selectColumn={selectColumn} />
					</Grid.Column>
				</Transition>
				<Grid.Column mobile={16} tablet={16} computer={showRefinements ? 13 : 16}>
					<div style={{ position: "sticky", top: "5px" }}>
						<Hits selectedColumns={selectedColumns} columns={myProductionColumns} />
						<Stats />
						<InstantSearchPagination />
					</div>
				</Grid.Column>
			</Grid>
		</InstantSearch>
	);
}
