import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Header, Segment } from "semantic-ui-react";

import loginToken from "astrid-helpers/src/loginToken";
import MasterSoundSetting from "astrid-web/src/components/MasterSoundSetting";

export default function ProductionProof({
	production,
	productionId,
	producer,
	user,
	userMap,
	isProducer /*, profile */,
}) {
	const { t } = useTranslation();

	const [loadingStudio, setLoadingStudio] = useState(false);

	const canOpenEdit =
		isProducer.includes("producerAdmin") ||
		isProducer.includes("producerStaff") ||
		production?.editor?.includes(user.uid);

	const canOpenProof =
		!production?.proofer?.includes("NONE") &&
		(isProducer.includes("producerAdmin") ||
			isProducer.includes("producerStaff") ||
			production?.proofer?.includes(user.uid));

	const openStudio = async (args = "") => {
		setLoadingStudio(true);

		const studioDomain = window.ES.stage
			? "stage."
			: production?.studioVersion === "beta" ||
			  (production?.productionType === "subcontract" && window.location.href.includes("beta."))
			? "beta."
			: "";

		const token = await loginToken();

		const url = `https://${studioDomain}studio.astrid.fm?token=${token}&production=${productionId}${args}`;

		const open = window.open(url);

		// ios can't open "popups"
		if (open == null || typeof open == "undefined") window.location = url;

		setLoadingStudio(false);
	};

	return (
		<Segment padded>
			<Header icon="cut" as="h4" content={t("editAndProofing")} />

			{isProducer &&
				(isProducer.includes("producerAdmin") ||
					isProducer.includes("producerStaff") ||
					isProducer.includes("producerEditor") ||
					isProducer.includes("producerProofer")) && (
					<Form as="div" className="flex-stack">
						{production.status === "production" && (
							<div className="field">
								<Button.Group color="blue" size="small">
									{canOpenEdit && (
										<Button
											primary
											loading={loadingStudio}
											labelPosition="left"
											icon="headphones"
											content={t("edit")}
											onClick={() => openStudio()}
										/>
									)}

									{canOpenProof && (
										<Button
											primary
											loading={loadingStudio}
											content={t("proofListening")}
											style={{ borderLeft: "1px solid #7ea7c7" }}
											onClick={() => openStudio("&role=proofer")}
										/>
									)}
								</Button.Group>
							</div>
						)}

						{!production?.proofer?.includes("NONE") &&
							(isProducer.includes("producerAdmin") || isProducer.includes("producerEditor")) && (
								<div className="fields">
									<MasterSoundSetting
										location="productions"
										locationId={productionId}
										locationName={production.title}
										userId={user.uid}
										value={production.reaperTemplates}
										reader={production.reader}
										size="small"
										sourceTemplates={{
											producer: producer?.reaperTemplates,
											producerId: production.producer,
											producerName: producer?.name,
											reader: (production.reader || []).reduce((prev, curr) => {
												const r = userMap[curr]?.reaperTemplates || {};

												if (
													userMap[curr] &&
													production.readerStatus?.[curr]?.status === "accepted"
												)
													prev[curr] = {
														...r,
														name: userMap[curr]?.firstName + " " + userMap[curr]?.lastName,
													};

												return prev;
											}, {}),
										}}
									/>
								</div>
							)}
					</Form>
				)}
		</Segment>
	);
}
