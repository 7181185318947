import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon, Loader, Modal, Table } from "semantic-ui-react";

import { base } from "astrid-firebase";
import jsonPretty from "astrid-web/src/helpers/jsonPretty";

class ProductionHistory extends Component {
	state = {};

	UNSAFE_componentWillMount() {
		base.bindCollection("productions/" + this.props.productionId + "/events", {
			context: this,
			state: "events",
			withIds: true,
			query: (ref) => ref.orderBy("time", "desc").limit(500),
			then() {},
			onFailure(err) {},
		});
	}

	render() {
		const { t } = this.props;
		return (
			<Modal size="fullscreen" open={true} onClose={this.props.onClose} closeOnDocumentClick>
				<Modal.Header>
					{t("nonGenreHistory")}
					<Icon name="close" onClick={this.props.onClose} style={{ cursor: "pointer", float: "right" }} />
				</Modal.Header>
				<Modal.Content scrolling>
					{this.state.events ? (
						<Table compact sortable basic="very">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t("userHistory")}</Table.HeaderCell>
									<Table.HeaderCell>{t("timeHistory")}</Table.HeaderCell>
									<Table.HeaderCell>{t("changesHistory")}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{this.state.events
									.filter((event) => event.data)
									.map((event) => (
										<Table.Row key={event.id}>
											<Table.Cell selectable style={{ fontWeight: "bold" }}>
												<a href={"/profile/" + event.user}>{event.email}</a>
											</Table.Cell>
											<Table.Cell singleLine>
												{event.time &&
													event.time.toDate &&
													moment(event.time.toDate()).format("YYYY-MM-DD HH:mm:ss")}
											</Table.Cell>
											<Table.Cell>{jsonPretty(event.data)}</Table.Cell>
										</Table.Row>
									))}
							</Table.Body>
						</Table>
					) : (
						<Loader active inline />
					)}
				</Modal.Content>
			</Modal>
		);
	}
}

export default withTranslation()(ProductionHistory);
