import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import AdminSeriesView from "../views/AdminSeriesView/AdminSeriesView";

export default function AdminSeries() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("series.view")) {
		return <Unauthorized />;
	}

	return <AdminSeriesView organization={organization} />;
}
