import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Header, Message, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import applyDefaultPricesForProduction from "astrid-firestore/src/api/sessions/applyDefaultPricesForProduction";

function PartMessage({ partResult, header }) {
	if (partResult?.length) {
		return (
			<>
				<Header as="h4" content={header} />
				{partResult.map((r, i) => {
					return <Message key={`${header}-${i}`} error={r.error} positive={!r.error} content={r.message} />;
				})}
			</>
		);
	}
	return null;
}

export default function SetDefaultPricesAndApplySessionCosts({ production }) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState(undefined);

	return (
		<>
			<Button
				style={{ marginLeft: 15 }}
				loading={loading}
				disabled={loading}
				onClick={async () => {
					try {
						setResult(undefined);
						setLoading(true);
						const result = await applyDefaultPricesForProduction(firebase, { productionId: production.id });
						setResult(result);
					} catch (error) {
						setResult({ error: true, unknown: true, message: "Unknown error!" });
						console.error(error);
					}
					setLoading(false);
				}}
			>
				{t("applyDefaultPricesAndFixCosts", "Apply default prices")}
			</Button>
			{result && (
				<Modal size="tiny" open onClose={() => setResult(undefined)}>
					<Modal.Header>{t("result", "Result")}</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<PartMessage partResult={result?.recorderPrices} header={t("recorder")} />
							<PartMessage partResult={result?.readerPrices} header={t("reader")} />
							<PartMessage partResult={result?.otherPrices} header={t("otherTeamMembers")} />
							{result?.unknown && <Message error content={result.message} />}
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button color="black" onClick={() => setResult(undefined)}>
							{t("close")}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
}
