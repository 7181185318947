import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import cancelOffer from "astrid-firestore/src/api/offers/cancelOffer";
import { cancellableOfferStatuses } from "astrid-firestore/src/api/offers/constants/offerStatuses";

export default function CancelOfferDropdownItem({ offer, setLoading, ...props }) {
	const { t } = useTranslation();

	const disabled = !cancellableOfferStatuses.includes(offer.status);

	return (
		<Dropdown.Item
			text={t("cancel", "Cancel")}
			disabled={disabled}
			onClick={async () => {
				setLoading?.(true);
				await cancelOffer(firebase, { offer });
				setLoading?.(false);
			}}
			{...props}
		/>
	);
}
