import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function usePriceUnits() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			time: t("priceTime"),
			fixed: t("priceFixed"),
			hour: t("priceHour", "Per produced hour"),
			activity: t("priceActivity", "Per worked minute"),
			percentage: t("pricePercentage", "Percentage of costs"),
		}),
		[t],
	);
}
