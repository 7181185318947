import { useSortBy } from "react-instantsearch";
import { Icon } from "semantic-ui-react";

const transformItems = (items) => {
	return items.reduce((acc, item) => {
		return {
			...acc,
			[item.value]: {
				iconName: item.iconName,
				refineTo: item.refineTo,
			},
		};
	}, {});
};

export default function ToggleSwitchIndex({ items, fallback }) {
	const { currentRefinement, options, refine } = useSortBy({
		items,
		transformItems,
	});

	const current = options?.[currentRefinement] || fallback;

	return (
		<Icon
			style={{ marginLeft: "5px", cursor: "pointer" }}
			name={current?.iconName}
			onClick={() => refine(current?.refineTo)}
		/>
	);
}
