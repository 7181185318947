import React from "react";
import { useOutletContext } from "react-router-dom";

import ProductionTeamView from "../views/ProductionTeamView/ProductionTeamView";

export default function ProductionTeam() {
	const { production } = useOutletContext();

	return <ProductionTeamView production={production} />;
}
