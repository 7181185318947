import get from "lodash/get";
import React, { Component } from "react";
//id's in common.json
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Form, Header, Icon, Loader, Modal, Table } from "semantic-ui-react";

import exportOptions from "astrid-config/src/exportOptions";
import { base, db, firebase } from "astrid-firebase";
import addEvent from "astrid-firebase/src/utils";
import { toDate } from "astrid-web/src/helpers/fnc";

import { withStore } from "../../../helpers/context";

import CopyInput from "../../CopyInput";

import MasterExportDistribution from "./MasterExportDistribution";

class MasterModal extends Component {
	state = {
		// export settings
		export: {},
	};

	componentDidMount() {
		const state = { export: {} };

		if (this.props.article === "cd") {
			state.export.part = "split";
			state.export.format = "wav";
		} else if (this.props.article === "mp3cd") {
			state.export.part = "split";
			state.export.format = "mp3";
			state.export.bitrate = "auto700";
		}

		if (this.props.production?.productionType === "subcontract") {
			state.export.part = "parts";
		}

		this.setState(state);
	}

	status = {
		queued: "Köad",
		start: "Påbörjad",
		download: "Källfiler",
		merge: "Sammanfogas",
		split: "Delas",
		convert: "Konverteras",
		zip: "Paketeras",
		upload: "Lagras",
		ftp: "Överförs",
		done: "Klar",
		expired: "Utgången",
		sent: "Skickad",
	};

	createExport = (existingId) => {
		const id = typeof existingId === "string" ? existingId : Date.now();
		const settings = typeof existingId === "string" ? {} : this.state.export;
		const masterSettings = get(this.props.production, "master." + this.props.article + ".settings");
		const { t, store } = this.props;

		if (settings.part === "chapter" && masterSettings.hasChapterSplits === false) {
			window.alert(t("createChapterPoint"));
			return;
		}

		this.setState({ creatingExport: true });

		const data = {
			master: {
				[this.props.article]: {
					exports: {
						[id]: {
							...settings,
							created: firebase.firestore.FieldValue.serverTimestamp(),
							user: this.props.uid,
							status: "queued",
							distributor: {},
						},
					},
				},
			},
		};

		db.collection("productions")
			.doc(this.props.productionId)
			.set({ ...data, updated: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true })
			.then(() => {
				// start job
				base.addToCollection("tasks/compute/jobs", {
					job: "createExport",
					status: "queued",
					created: firebase.firestore.FieldValue.serverTimestamp(),
					diskType: "pd-ssd",
					diskSize: 150,
					machineType: "n1-highmem-4",
					args: {
						// keepAlive: true,
						article: this.props.article,
						production: this.props.productionId,
						export: id,
					},
				}).then(() => {
					this.setState({ creatingExport: false });

					data.master[this.props.article].exports[id].user = store.getUserName(
						data.master[this.props.article].exports[id].user,
					);

					// store event
					addEvent({
						productionId: this.props.productionId,
						user: this.props.uid,
						email: this.props.profile.email,
						data,
					});
				});
			})
			.catch((err) => {
				console.log("export create err:", err);
				//handle error
			});
	};

	deleteExport = (id) => {
		const production = this.props.production;
		const storageRef = this.props.getStorage();

		// delete the file
		const zipPath = get(production, "master[this.props.article].exports[id].zip.path");
		const zip = zipPath && storageRef.child(zipPath);
		if (zip)
			zip.delete()
				.then(() => {
					console.log("export deleted");
				})
				.catch((err) => {
					console.log("export delete err", err);
				});

		// remove from db
		db.collection("productions")
			.doc(this.props.productionId)
			.set(
				{
					master: {
						[this.props.article]: {
							exports: {
								[id]: firebase.firestore.FieldValue.delete(),
							},
						},
					},
				},
				{ merge: true },
			);
	};

	closeExportModal = () => {
		this.setState({ modal: null });
	};

	exportUrl = (id) =>
		window.location.protocol +
		"//" +
		window.location.host +
		"/export/" +
		this.props.productionId +
		"/" +
		this.props.article +
		"/" +
		id;

	render() {
		const {
			t,
			article,
			production,
			productionId,
			publisher,
			modifiedSettings,
			productionRights,
			uid,
			profile,
			distributors,
		} = this.props;
		const { creatingExport, modal } = this.state;

		const duration = get(production, "master." + article + ".settings.duration");
		const approved = get(production, "master." + article + ".settings.approved");

		const isStereo = Object.values(get(production, "master." + article + ".parts") || {}).find(
			(part) => part.stereo,
		);

		return (
			<>
				{duration && !modifiedSettings && approved !== false && (
					<div className="flex-stack" style={{ marginTop: "2em", minHeight: 300 }}>
						<Form className="clear">
							<Header as="h3">{t("createExports")}</Header>
							<Form.Group widths="equal">
								{article === "cd" ? (
									<>
										<p className="field" style={{ marginTop: 5 }}>
											<em>
												{t("cdWavExports")} {Math.round(duration / 60)} {t("minutesFiles")}
											</em>
										</p>
										<div className="field">
											<label>{t("asDDP")}</label>
											Nej
											<Checkbox
												style={{ marginLeft: "1em", marginTop: ".5em" }}
												toggle
												label={t("yes")}
												checked={this.state.export.ddp}
												onClick={(e, data) => {
													this.setState({
														export: {
															...this.state.export,
															ddp: data.checked,
														},
													});
												}}
											/>
										</div>
									</>
								) : (
									<>
										{Object.entries(exportOptions(article, this.state.export.format, duration)).map(
											([name, select]) => (
												<Form.Select
													key={name}
													//label={select.label}
													label={t("exportDropdownLabel" + name)}
													//placeholder={select.placeholder}
													placeholder={t("exportDropdownPlaceholder" + name)}
													name={name}
													value={this.state.export[name]}
													options={select.options
														.filter((option) => !option.hidden)
														.map((option) => {
															if (name === "part")
																option.text = t("exportPart" + option.key, {
																	mins: duration ? Math.round(duration / 60) : "5-10",
																});
															return option;
														})}
													disabled={select.disabled}
													onChange={(e, data) => {
														this.setState({
															export: {
																...this.state.export,
																[data.name]: data.value,
															},
														});
													}}
												/>
											),
										)}

										{article === "mp3cd" && (
											<div className="field">
												<label>{t("asISO")}</label>
												Nej
												<Checkbox
													style={{ marginLeft: "1em", marginTop: ".5em" }}
													toggle
													label={t("yes")}
													checked={this.state.export.iso}
													onClick={(e, data) => {
														this.setState({
															export: {
																...this.state.export,
																iso: data.checked,
															},
														});
													}}
												/>
											</div>
										)}

										{isStereo && (
											<div className="field">
												<label>{t("convertToMono")}</label>
												Nej
												<Checkbox
													style={{ marginLeft: "1em", marginTop: ".5em" }}
													toggle
													label={t("yes")}
													checked={this.state.export.mono}
													onClick={(e, data) => {
														this.setState({
															export: {
																...this.state.export,
																mono: data.checked,
															},
														});
													}}
												/>
											</div>
										)}

										{/* <div className="field">
											<label>Inkludera metadata?</label>
											{[{ label: "ONIX-fil", value: "onix" }].map(meta => (
												<Form.Checkbox
													key={meta.value}
													label={meta.label}
													value={meta.value}
													checked={
														this.state.export.meta &&
														this.state.export.meta.includes(meta.value)
													}
													onClick={(e, data) => {
														const metaState = this.state.export.meta || [];
														if (data.checked) {
															metaState.push(meta.value);
														} else {
															const i = metaState.indexOf(meta.value);
															metaState.splice(i, 1);
														}
														this.setState({
															export: {
																...this.state.export,
																meta: metaState,
															},
														});
													}}
												/>
											))}
										</div> */}
									</>
								)}
								<Form.Button
									primary
									content={t("createExport")}
									disabled={
										creatingExport ||
										!(
											this.state.export.format &&
											this.state.export.part &&
											(this.state.export.bitrate || this.state.export.format === "wav")
										)
									}
									loading={creatingExport}
									onClick={this.createExport}
									style={article !== "cd" ? { marginTop: "1.6em" } : null}
								/>
								{/*
								 // preset buttons
								<Form.Button
									color="pink"
									content="Skapa MP3 192 kbps total längd"
									onClick={e => {
										this.setState(
											{
												export: {
													format: "mp3",
													part: "total",
													bitrate: 192,
												},
											},
											this.createExport,
										);
									}}
								/>
								 */}
							</Form.Group>
						</Form>

						{production.master[article].exports &&
							!!Object.keys(production.master[article].exports).length && (
								<Table size="small" collapsing>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t("splitMaster")}</Table.HeaderCell>
											<Table.HeaderCell>{t("formatMaster")}</Table.HeaderCell>
											<Table.HeaderCell>{t("statusMaster")}</Table.HeaderCell>
											<Table.HeaderCell>{t("exportMaster")}</Table.HeaderCell>
											<Table.HeaderCell>
												{productionRights.includes("production.masterExportEdit") &&
													"Distribution"}
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{Object.entries(production.master[article].exports)
											.sort((a, b) => (a[0] > b[0] ? 1 : -1))
											.map(([id, exp]) => {
												const expired =
													!exp.status ||
													toDate(exp.created) <
														toDate(production.master[article].settings.date);

												if (expired) exp.status = "expired";

												return (
													<Table.Row key={id}>
														<Table.Cell>
															{
																{
																	split: t("splits"),
																	chapter: t("chapter"),
																	total: t("total"),
																	parts: t("parts"),
																}[exp.part]
															}
														</Table.Cell>
														<Table.Cell>
															<span style={{ display: "inline-block", minWidth: 30 }}>
																{exp.format && exp.format.toUpperCase()}
															</span>
															{exp.format !== "wav" &&
																(exp.bitrate &&
																(exp.bitrate + "").substr(0, 4) === "auto"
																	? exp.bitrate === "auto700"
																		? "auto 700mb"
																		: "auto 650mb"
																	: exp.bitrate + "kbps")}
															{exp.mono && " mono"}
															{exp.iso && " ISO"}
															{exp.ddp && " DDP"}
														</Table.Cell>
														<Table.Cell>
															<Icon
																name="circle"
																color={
																	exp.status === "done"
																		? "green"
																		: expired
																		? "yellow"
																		: "orange"
																}
															/>
															{t("exportStatus" + exp.status)}
														</Table.Cell>
														<Table.Cell>
															{!expired &&
																(exp.status === "done" ? (
																	<Button
																		primary
																		size="mini"
																		as="a"
																		icon="download"
																		href={exp.zip.url}
																		content={t("downloadBtn")}
																	/>
																) : (
																	<span>
																		<Loader
																			active
																			inline
																			size="tiny"
																			style={{ verticalAlign: "text-bottom" }}
																		/>{" "}
																		<em>{t("processing")}</em>
																	</span>
																))}
															{(expired ||
																productionRights.includes(
																	"production.masterExportEdit",
																)) && (
																<Button
																	primary
																	size="mini"
																	icon="redo"
																	content={t("createNew")}
																	onClick={(e) => {
																		e.preventDefault();
																		this.createExport(id);
																	}}
																/>
															)}
															{!expired && exp.status === "done" && (
																<Button
																	color="teal"
																	icon="share"
																	size="mini"
																	content={t("share")}
																	onClick={(e) => {
																		this.setState({
																			modal: {
																				type: "share",
																				article,
																				export: id,
																			},
																		});
																	}}
																/>
															)}
															{["done", "expired", "error"].includes(exp.status) && (
																<Button
																	color="red"
																	icon="trash alternate"
																	size="mini"
																	content={t("delete")}
																	onClick={(e) => {
																		if (
																			window.confirm(
																				t("sureAboutDeleteExportAndFile"),
																			)
																		) {
																			this.deleteExport(id);
																		}
																	}}
																/>
															)}
														</Table.Cell>
														<Table.Cell>
															{exp.status === "done" &&
																productionRights.includes(
																	"production.masterExportEdit",
																) && (
																	<MasterExportDistribution
																		exp={exp}
																		id={id}
																		production={production}
																		publisher={publisher}
																		productionId={productionId}
																		productionRights={productionRights}
																		article={article}
																		uid={uid}
																		profile={profile}
																		distributors={distributors}
																	/>
																)}
														</Table.Cell>
													</Table.Row>
												);
											})}
									</Table.Body>
								</Table>
							)}
					</div>
				)}

				{modal && (
					<Modal size="small" open={true} onClose={this.closeExportModal} closeOnDocumentClick>
						<Modal.Header>{t("shareExport")}</Modal.Header>
						<Modal.Content>
							<Form>
								<Form.Input
									type="email"
									label={t("emailAddress")}
									value={this.state.exportEmail || ""}
									onChange={(e, data) => {
										this.setState({ exportEmail: data.value });
									}}
								/>
								<p>
									{t(
										"clickBtnToPrepareEmail",
										"Click button below to prepare an email for your e-mail client",
									)}
								</p>
								<p>{t("copyThisLink")}</p>

								<CopyInput content={this.exportUrl(modal.export)} />
							</Form>
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={this.closeExportModal}>{t("close")}</Button>
							<Button
								positive
								icon="send"
								labelPosition="right"
								content={t("send")}
								disabled={!this.state.exportEmail}
								onClick={() => {
									const url = this.exportUrl(modal.export);

									window.location =
										"mailto:" +
										this.state.exportEmail +
										"?subject=" +
										production.title +
										"&body=" +
										encodeURIComponent(t("clickToDownloadProd")) +
										url;
								}}
							/>
						</Modal.Actions>
					</Modal>
				)}
			</>
		);
	}
}

export default withTranslation()(withStore(MasterModal));
