import React, { useState, useRef, useEffect } from "react";
import { Button } from "semantic-ui-react";
import Clipboard from "clipboard";
//id's in common.json
import { useTranslation } from "react-i18next";

const CopyInput = ({ content, inline }) => {
	const [isCopied, setIsCopied] = useState(false);
	const buttonRef = useRef();
	const { t } = useTranslation();

	useEffect(() => {
		new Clipboard(buttonRef.current.ref.current, {
			text: (trigger) => content,
		});
	}, [content]);

	return (
		<div className={"copy-input" + (inline ? " inline" : "")}>
			<div className="copy-input-field">{content}</div>
			<Button
				className="copy-input-button"
				icon="copy"
				//content={"Kopiera" + (isCopied ? "d!" : "")}
				content={isCopied ? t("copied") : t("copy")}
				size="tiny"
				color={isCopied ? "green" : "grey"}
				ref={buttonRef}
				onClick={() => {
					setIsCopied(true);
				}}
			/>
		</div>
	);
};

export default CopyInput;
