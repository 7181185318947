import { teamRoles } from "../../constants/teamRoles";
import runBatch from "../../helpers/runBatch";

import offerStatuses from "./constants/offerStatuses";
import offerTypes from "./constants/offerTypes";
import createOfferData from "./createOfferData";

export default async function createVoiceOffer(firebase, { user, price, production, voice, status, createdBy }) {
	const db = firebase.firestore();

	const offer = createOfferData(firebase, {
		id: `${production.id}-${user.id}-${teamRoles.READER}`,
		role: teamRoles.READER,
		type: offerTypes.INQUIRY,
		price: price?.price
			? {
					unit: "hour",
					type: "reader",
					price: +price?.price || 0,
					currency: price?.currency || "SEK",
			  }
			: null,
		status: status || offerStatuses.INITIAL,
		approved: true,
		production,
		secondParty: user,
		createdBy,
	});

	await runBatch(db, (batch) => {
		batch.set(offer.ref, offer);
		batch.update(production.ref, {
			[`voices.${voice.id}.readerIds`]: firebase.firestore.FieldValue.arrayUnion(offer.secondParty.id),
		});
	});

	return offer;
}
