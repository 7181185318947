import useLoadingFunction from "../../ui/hooks/useLoadingFunction";

export default function useFormSubmit(form, dataHandler, { onSuccess, onError, onSubmitError, clearOnSuccess } = {}) {
	const { reset, handleSubmit, formState } = form;

	const [submitHandler, , error] = useLoadingFunction(
		async (data) => {
			const result = await dataHandler(data);
			reset(clearOnSuccess ? {} : data);
			return result;
		},
		{ onSuccess, onError },
	);

	const onSubmit = handleSubmit(submitHandler, (error) => {
		console.error(error);
		onSubmitError?.(error);
	});

	return [onSubmit, formState.isSubmitting, error];
}
