import React from "react";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminArtifactDropdownMenuOptions from "../hooks/useAdminArtifactDropdownMenuOptions";
import useAdminArtifactMenuOptions from "../hooks/useAdminArtifactMenuOptions";

export default function AdminArtifactHeader({ artifact }) {
	const menuOptions = useAdminArtifactMenuOptions({ artifact });

	const dropdownMenuOptions = useAdminArtifactDropdownMenuOptions({ artifact });

	return (
		<AdminPageHeader menuOptions={menuOptions} dropdownMenuOptions={dropdownMenuOptions}>
			<Header style={{ margin: 0 }}>{artifact.name}</Header>
		</AdminPageHeader>
	);
}
