import { history } from "instantsearch.js/es/lib/routers";
import { useEffect, useState } from "react";
import { InstantSearch } from "react-instantsearch";
import { Button, Grid, Loader, Transition } from "semantic-ui-react";

import InstantSearchPagination from "../../../ui/components/InstantSearch/InstantSearchPagination";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";

import CalculateDuration from "./components/CalculateDuration";
import Clear from "./components/Clear";
import Hits from "./components/Hits";
import MainSearchBox from "./components/MainSearchBox";
import Presets from "./components/Presets";
import Stats from "./components/Stats";
import FiltersAndRefinements from "./components/refinements/FiltersAndRefinements";
import { defaultColumnsProducer, defaultColumnsPublisher } from "./constants";
import ClearStateProvider from "./contexts/ClearStateProvider";
import useProductionsColumns from "./hooks/useProductionsColumns";
import useSelectedColumns from "./hooks/useSelectedColumns";
import useUserLanguages from "./hooks/useUserLanguages";

const initialIndex = "productions";

const routing = {
	router: history(),
	stateMapping: {
		stateToRoute(uiState) {
			const indexUiState = uiState[initialIndex];
			const { configure, ...rest } = indexUiState;
			return {
				...rest,
			};
		},
		routeToState(routeState) {
			if (Object.keys(routeState).length) {
				return {
					[initialIndex]: {
						...routeState,
					},
				};
			}
		},
	},
};

export default function ListProductionsView({ type }) {
	const searchClient = useAlgoliaSearchClient();

	const [showRefinements, setShowRefinements] = useState(true);
	const [selectedColumns, selectColumn] = useSelectedColumns(
		type === "publisher" ? "listProductionsPublisher" : "listProductions",
		type === "publisher" ? defaultColumnsPublisher : defaultColumnsProducer,
	);
	const columns = useProductionsColumns({ type, selectedColumns });

	const userLanguages = useUserLanguages();

	useEffect(() => {
		if (searchClient) {
			searchClient.clearCache();
		}
	}, [searchClient]);

	if (!searchClient) {
		return <Loader active />;
	}

	return (
		<InstantSearch
			initialUiState={{
				[initialIndex]: { refinementList: { language: userLanguages } },
			}}
			indexName={initialIndex}
			searchClient={searchClient}
			routing={routing}
		>
			<ClearStateProvider>
				<Grid stackable columns={2}>
					<Grid.Row>
						<Grid.Column width={showRefinements ? 3 : 1}>
							<Button
								style={{ marginRight: 10 }}
								icon={showRefinements ? "hide" : "unhide"}
								onClick={() => {
									setShowRefinements(!showRefinements);
								}}
							/>
							{showRefinements && <Clear />}
						</Grid.Column>
						<Grid.Column width={showRefinements ? 13 : 15}>
							<MainSearchBox />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable columns={2}>
					<Transition visible={showRefinements} duration={500} animation="slide right">
						<Grid.Column
							mobile={16}
							tablet={16}
							computer={3}
							style={showRefinements ? { paddingRight: 0 } : { left: "-100%", position: "absolute" }}
						>
							<Presets />
							<FiltersAndRefinements
								type={type}
								selectedColumns={selectedColumns}
								selectColumn={selectColumn}
							/>
							<CalculateDuration />
						</Grid.Column>
					</Transition>
					<Grid.Column mobile={16} tablet={16} computer={showRefinements ? 13 : 16}>
						<div style={{ position: "sticky", top: "5px" }}>
							<Hits columns={columns} selectedColumns={selectedColumns} />
							<Stats />
							<InstantSearchPagination pointing={false} secondary={false} />
						</div>
					</Grid.Column>
				</Grid>
			</ClearStateProvider>
		</InstantSearch>
	);
}
