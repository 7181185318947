import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Button, Header, Icon, Loader, Modal } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import { useDocumentData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";

import getCalendarSessionRoles from "../../helpers/getCalendarSessionRoles";

import SessionContext from "../SessionContext";

import CancelSessionModal from "./CancelSessionModal";
import EditSession from "./ModalContent/EditSession";
import ProductionInformation from "./ModalContent/ProductionInformation";
import SessionInformation from "./ModalContent/SessionInformation";
import CancelSession from "./SessionActions/CancelSession";
import GoToProduction from "./SessionActions/GoToProduction";
import GrabSession from "./SessionActions/GrabSession";

export default function OpenSessionModal({ session, onClose, notificationCallbacks }) {
	const { t } = useTranslation();
	const profile = useProfile();
	const [showCancel, setShowCancel] = useState(false);
	const [active, setActive] = useState(false);

	const [production, loadingProduction, errorProduction] = useDocumentData(
		session?.production && db.collection("productions").doc(session.production),
	);

	if (loadingProduction) {
		// prevent form from registering. LoadingContext does not work for this.
		return <Loader active />;
	}

	const sessionRoles = getCalendarSessionRoles({ session, profile });

	const sessionContext = {
		t,
		profile,
		session,
		production,
		sessionRoles,
		setShowCancel,
		onClose,
		addAffectedStaff: notificationCallbacks.addAffectedStaff,
		addNotification: notificationCallbacks.addNotification,
	};

	return showCancel ? (
		<SessionContext.Provider value={sessionContext}>
			<LoadingContext data={production} loading={loadingProduction} error={errorProduction}>
				<CancelSessionModal />
			</LoadingContext>
		</SessionContext.Provider>
	) : (
		<>
			<Modal.Header>{`${t("recSession", "Session")} - ${production?.title}`}</Modal.Header>
			<LoadingContext data={production} loading={loadingProduction} error={errorProduction}>
				<SessionContext.Provider value={sessionContext}>
					<Modal.Content>
						<Accordion fluid>
							<SessionInformation />
							<Accordion.Title active={active} onClick={() => setActive(!active)}>
								<Header size="small">
									<Icon name="dropdown" />
									{t("editSession", "Edit session")}
								</Header>
							</Accordion.Title>
							<Accordion.Content active={active}>
								<EditSession />
							</Accordion.Content>
							<ProductionInformation />
						</Accordion>
					</Modal.Content>
					<Modal.Actions>
						<Button color="black" onClick={onClose}>
							{t("close")}
						</Button>
						<CancelSession />
						<GoToProduction />
						<GrabSession />
					</Modal.Actions>
				</SessionContext.Provider>
			</LoadingContext>
		</>
	);
}
