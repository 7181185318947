import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";

import useOrganizationRoleTranslations from "../../../../organizations/hooks/useOrganizationRoleTranslations";

function Organization({ id, user, type, name }) {
	const { t } = useTranslation();

	const organizationRoleTranslations = useOrganizationRoleTranslations();

	const userRoles = (user.permissions?.[type]?.[id] || [])
		.filter((role) => role !== "any")
		.map((role) => organizationRoleTranslations[role])
		.join(", ");

	return (
		<Card.Content key={id}>
			<Link to={`/${type}/${id}/admin/users/${user.id}`}>{name}</Link>{" "}
			{userRoles ? `(${userRoles})` : <em>{t("noRoles", "No roles")}</em>}
		</Card.Content>
	);
}

export default function UserOrganizations({ user }) {
	const { t } = useTranslation();

	const organizations = Object.entries(user.organizations || {});

	if (organizations.length === 0) {
		return false;
	}

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("organizations", "Organizations")}</Card.Header>
			</Card.Content>
			{organizations.map(([id, organization]) => (
				<Organization key={id} id={id} user={user} {...organization} />
			))}
		</Card>
	);
}
