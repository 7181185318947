import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInstantSearch, useRange } from "react-instantsearch";
import { Form } from "semantic-ui-react";

import DateTime from "../../../../../ui/components/DateInput/DateTime";

function isValidDate(d) {
	return moment(d, "YYYY-MM-DD", true).isValid();
}

function convertToDate(timestamp) {
	return timestamp ? moment(new Date(Number(timestamp) * 1000)) : undefined;
}

export default function RangeDates({ attribute }) {
	const { t } = useTranslation();

	const [fromDate, setFromDate] = useState(undefined);
	const [toDate, setToDate] = useState(undefined);

	const { refine } = useRange({
		attribute,
		min: -Infinity,
		max: Infinity,
	});

	const {
		indexUiState: { range },
	} = useInstantSearch();

	useEffect(() => {
		const stateRange = range?.[attribute]?.split(":");
		const stateFromDate = convertToDate(stateRange?.[0]);
		const stateToDate = convertToDate(stateRange?.[1]);
		setFromDate(stateFromDate || "");
		setToDate(stateToDate || "");
	}, [attribute, range]);

	return (
		<Form>
			<Form.Group>
				<Form.Field error={!isValidDate(fromDate) && !!fromDate}>
					<label>{t("fromDate", "From date")}</label>
					<DateTime
						dateFormat="YYYY-MM-DD"
						timeFormat={false}
						closeOnSelect={true}
						onChange={(date) => {
							setFromDate(date);
							if (!date || isValidDate(date)) {
								refine([
									date?.startOf?.("day")?.unix?.() || undefined,
									toDate?.endOf?.("day")?.unix?.() || undefined,
								]);
							}
						}}
						value={fromDate}
						renderInput={(props) => {
							return <input {...props} value={fromDate ? props.value : ""} />;
						}}
					/>
				</Form.Field>
				<Form.Field error={!isValidDate(toDate) && !!toDate}>
					<label>{t("toDate", "To date")}</label>
					<DateTime
						dateFormat="YYYY-MM-DD"
						timeFormat={false}
						closeOnSelect={true}
						onChange={(date) => {
							setToDate(date);
							if (!date || isValidDate(date)) {
								refine([
									fromDate?.startOf?.("day")?.unix?.() || undefined,
									date?.endOf?.("day")?.unix?.() || undefined,
								]);
							}
						}}
						value={toDate}
						renderInput={(props) => {
							return <input {...props} value={toDate ? props.value : ""} />;
						}}
					/>
				</Form.Field>
			</Form.Group>
		</Form>
	);
}
