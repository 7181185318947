import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import AddAutoApprovalRuleModal from "./AutoApprovalRuleForm";

export default function AddAutoApprovalRule(props) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)}>{t("add", "Add")}</Button>

			{open && <AddAutoApprovalRuleModal {...props} onClose={() => setOpen(false)} />}
		</>
	);
}
