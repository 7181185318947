import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import { db } from "astrid-firebase";
import taskStatuses from "astrid-firestore/src/api/tasks/constants/taskStatuses";
import runBatch from "astrid-firestore/src/helpers/runBatch";

export default function RetryAllButton({ tasks }) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const onClick = async () => {
		setLoading(true);

		await runBatch(db, (batch) => {
			for (const task of tasks) {
				batch.update(task.ref, {
					status: taskStatuses.RETRY,
				});
			}
		});

		setLoading(false);
	};

	return (
		<Button loading={loading} disabled={loading || tasks.length === 0} onClick={onClick}>
			{t("retryAll", "Retry all")}
		</Button>
	);
}
