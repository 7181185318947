import { useMemo } from "react";

export default function useMaxUsesOptions() {
	return useMemo(
		() =>
			[{ key: 0, text: "∞", value: false }].concat(
				[...Array(200)].map((n, i) => ({
					key: i + 1,
					text: i + 1,
					value: i + 1,
				})),
			),
		[],
	);
}
