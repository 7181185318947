import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";
import Table from "../../../../ui/components/Table/Table";

import useAutoApprovalRules from "../../../hooks/useAutoApprovalRules";

import useAutoApprovalRulesColumns from "../hooks/useAutoApprovalRulesColumns";

import AddAutoApprovalRule from "./AddAutoApprovalRule";

export default function AutoApprovalRules({ organization }) {
	const { t } = useTranslation();

	const [autoApprovalRules, loading, error] = useAutoApprovalRules(organization);

	const autoApprovalRulesColumns = useAutoApprovalRulesColumns(organization);

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("autoApprovalRules", "Auto-approval rules")}</Card.Header>
				<Card.Description>
					{t("autoApprovalRulesMessage", "Any prices below the threshold will be auto-approved")}
				</Card.Description>
			</Card.Content>
			<Card.Content extra>
				<LoadingContext data={autoApprovalRules} loading={loading} error={error}>
					<Table data={autoApprovalRules} columns={autoApprovalRulesColumns} footer={false} />
				</LoadingContext>
			</Card.Content>
			<Card.Content extra>
				<AddAutoApprovalRule organization={organization} />
			</Card.Content>
		</Card>
	);
}
