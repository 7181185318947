import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

export default function AdminImprintArticlesView({ imprint }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("articles", "Articles")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<ArticlesCollectionDataTable getQuery={(query) => query.where("imprint.id", "==", imprint.id)} />
			</Card.Content>
		</Card>
	);
}
