import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminPublisherAgreements from "./components/AdminPublisherAgreements";
import AdminPublisherInfo from "./components/AdminPublisherInfo";
import AdminPublisherPrices from "./components/AdminPublisherPrices";
import AdminPublisherUsers from "./components/AdminPublisherUsers";

export default function ProducerPublisherView({ id, organization }) {
	const [publisher, loading, error] = useDocumentData(id && db.collection("organizations").doc(id));

	return (
		<LoadingContext loading={loading} error={error} data={publisher}>
			{publisher && (
				<Card.Group>
					<AdminPublisherInfo publisher={publisher} />
					<AdminPublisherPrices publisher={publisher} organization={organization} />
					<AdminPublisherAgreements publisher={publisher} organization={organization} />
					<AdminPublisherUsers publisher={publisher} />
				</Card.Group>
			)}
		</LoadingContext>
	);
}
