import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useCostsQuery({
	orderId,
	deleted = false,
	expense,
	employee,
	processed,
	producerId,
	productionId,
	firstPartyId,
	secondPartyId,
}) {
	return useMemo(
		() =>
			createQuery(db.collection("costs"), {
				orderId,
				deleted,
				expense,
				employee,
				processed,
				"producer.id": producerId,
				"production.id": productionId,
				"firstParty.id": firstPartyId,
				"secondParty.id": secondPartyId,
			}).orderBy("created", "desc"),
		[deleted, expense, employee, orderId, processed, producerId, productionId, firstPartyId, secondPartyId],
	);
}
