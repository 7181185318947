import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineStatusLabel from "../../PipelineStatus/PipelineStatusLabel";

export default function PipelineStepTitle({ step, active, progress, onClick }) {
	const { t } = useTranslation();

	return (
		<Accordion.Title active={active} onClick={!step.disabled && onClick}>
			<Flex>
				<div>{step.name}</div>

				<div>
					<Flex gap={10}>
						{step.needs && (
							<Label basic>
								{step.forEach ? t("forEach", "For each") : t("needs", "Needs")}
								<Label.Detail>{step.needs}</Label.Detail>
							</Label>
						)}

						<PipelineStatusLabel step={step} progress={progress} />
					</Flex>
				</div>
			</Flex>
		</Accordion.Title>
	);
}
