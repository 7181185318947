import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import Popup from "../../../../ui/components/Popup/Popup";

export default function TodoOrderIcon({ order, onClick }) {
	const { t } = useTranslation();

	return (
		<div>
			<Popup
				content={
					order === "asc" ? t("ascendingOrder", "Ascending order") : t("descendingOrder", "Descending order")
				}
				trigger={
					<Icon
						link
						name={order === "desc" ? "sort amount down" : "sort amount up"}
						color={order === "asc" ? "blue" : null}
						onClick={onClick}
					/>
				}
			/>
		</div>
	);
}
