import get from "lodash/get";
import { useMemo } from "react";

import { useProfile } from "../../authentication/state/profile";

import useHasRole from "./useHasRole";

export default function useFilterDocumentsByAdminProducers(documents, fieldOrCallback = "producer.id") {
	const { adminProducerIds } = useProfile();
	const hasRole = useHasRole();

	const filterFunction = useMemo(
		() =>
			fieldOrCallback &&
			((data) =>
				typeof fieldOrCallback === "function"
					? fieldOrCallback(adminProducerIds, data)
					: adminProducerIds?.includes(get(data, fieldOrCallback))),
		[fieldOrCallback, adminProducerIds],
	);

	return useMemo(
		() =>
			hasRole("owner") || !adminProducerIds || !filterFunction ? documents : documents?.filter(filterFunction),
		[hasRole, adminProducerIds, documents, filterFunction],
	);
}
