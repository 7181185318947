import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminBiblioSettingsView from "../views/SuperAdminBiblioSettingsView/SuperAdminBiblioSettingsView";

export default function SuperAdminBiblioSettings() {
	const hasRight = useHasRight();

	if (!hasRight("biblio.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminBiblioSettingsView />;
}
