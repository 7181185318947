const productionStatuses = {
	DRAFT: "draft",
	OFFER: "offer",
	PLANNING: "planning",
	ACCEPTED: "accepted",
	PRODUCTION: "production",
	DONE: "done",
};

export default productionStatuses;
