import { priceAccessor } from "../../prices/utils/priceAccessors";

export const createdAccessor = ({ created }) => created?.toDate().toLocaleString();

export const costPriceAccessor = ({ expense, price, reversed }) => {
	return price?.price !== null
		? priceAccessor({ ...price, price: price.price * (expense ? -1 : 1) * (reversed ? -1 : 1) })
		: null;
};

export const estimationAccessor = ({ expense, estimation, reversed }) =>
	estimation?.value * (expense ? -1 : 1) * (reversed ? -1 : 1);

export const totalAccessor = ({ total, expense, reversed }) => total * (expense ? -1 : 1) * (reversed ? -1 : 1);
