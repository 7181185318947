import { useTranslation } from "react-i18next";
import { Header, Segment } from "semantic-ui-react";

import Flex from "../../../components/Flex/Flex";
import useDocTitle from "../../ui/hooks/useDocTitle";

export default function CreateProductionLayout({ children }) {
	const { t } = useTranslation();

	useDocTitle(t("createNewProd"));

	return (
		<Flex justifyContent="center">
			<Segment style={{ width: "40%" }} padded="very">
				<Header as="h2" textAlign="center">
					{t("createNewProd")}
				</Header>
				{children}
			</Segment>
		</Flex>
	);
}
