const nfgImprints = [
	"Norstedts",
	"Brombergs",
	"Tiden",
	"B. Wahlströms",
	"Rabén & Sjögren",
	"Massolit",
	"Printz Publishing",
];

export default function isNfgImprint({ hit }) {
	return nfgImprints.includes(hit?.imprintName);
}
