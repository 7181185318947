import { useCallback, useEffect, useState } from "react";

import { localStorage } from "../../../../../helpers/fnc";

function shouldResetToDefault(storageKey, defaultColumns) {
	const storedColumns = Object.keys(localStorage(storageKey, "columns") || {});
	const defaultSettings = Object.keys(defaultColumns);
	return (
		defaultSettings.length !== storedColumns?.length ||
		!storedColumns.every((storedColumn) => defaultSettings.includes(storedColumn))
	);
}

function getColumns(storageKey, defaultColumns) {
	return shouldResetToDefault(storageKey, defaultColumns)
		? { ...defaultColumns }
		: {
				...defaultColumns,
				...localStorage(storageKey, "columns"),
		  };
}

export default function useSelectedColumns(storageKey, defaultColumns) {
	const [selectedColumns, setSelectedColumns] = useState({});

	useEffect(() => {
		setSelectedColumns(getColumns(storageKey, defaultColumns));
	}, [storageKey, defaultColumns]);

	const selectColumn = useCallback(
		(value) => {
			const updatedColumns = {
				...selectedColumns,
				[value]: !selectedColumns[value],
			};
			setSelectedColumns(updatedColumns);
			localStorage(storageKey, "columns", updatedColumns);
		},
		[storageKey, selectedColumns, setSelectedColumns],
	);

	return [selectedColumns, selectColumn];
}
