import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";
import { useProfile } from "astrid-web/src/features/authentication/state/profile";
import DropdownMenu from "astrid-web/src/features/ui/components/DropdownMenu/DropdownMenu";

export default function AlertProductonDropdownMenu({ production }) {
	const { t } = useTranslation();
	const user = useProfile();

	return (
		<DropdownMenu>
			{({ setLoading }) => (
				<>
					<Dropdown.Item as={Link} to={`/production/${production?.id}`}>
						{t("toProduction", "To production")}
					</Dropdown.Item>
					<Dropdown.Item
						onClick={async () => {
							setLoading(true);

							try {
								await updateDocument(user.ref, {
									[`alertProductions.${production.id}`]: firebase.firestore.FieldValue.delete(),
								});
							} catch (error) {
								console.error(error);
							}

							setLoading(false);
						}}
					>
						{t("removeReminder", "Remove reminder")}
					</Dropdown.Item>
				</>
			)}
		</DropdownMenu>
	);
}
