import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useStudiosQuery({ gen, organizationId }) {
	return createQuery(
		gen === 2
			? db.collection("studios").where("producerId", "==", organizationId)
			: db.collection("organizations").doc(organizationId).collection("studios"),
	);
}
