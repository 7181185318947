import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminPriceView from "../views/AdminPriceView/AdminPriceView";

export default function AdminPrice() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("prices.view")) {
		return <Unauthorized />;
	}

	return <AdminPriceView id={id} />;
}
