import React from "react";
import { useTranslation } from "react-i18next";

import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import useOfferAgreement from "../../../hooks/useOfferAgreement";

import DownloadURLButton from "./DownloadURLButton";

export default function DownloadAgreementButton({ offer }) {
	const { t } = useTranslation();

	const { ref } = useOfferAgreement(offer);
	const [agreement, loading, error] = useDocumentDataOnce(ref);

	return (
		<DownloadURLButton loading={loading} error={error} {...agreement?.signedAgreementFile}>
			{t("download", "Download")} {t("agreement", "Agreement")?.toLowerCase()}
		</DownloadURLButton>
	);
}
