export function filterUserHasVendorsOrEmployee(producerId) {
	return (user) => !!user.vendors || user.producers?.[producerId]?.employee;
}

export function filterUserIsNotMember({ role, team }) {
	return (user) => !team.find((member) => member?.user?.id === user.id && member.role === role);
}

export function filterUserHasLanguageOption({ language, producerId }) {
	return (user) => user?.producers?.[producerId]?.languageOptions?.includes(language);
}
