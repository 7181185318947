import React, { useState } from "react";
import { Button } from "semantic-ui-react";

import Accordion from "../../../ui/components/Accordion/Accordion";

import RetryFailedTasksButton from "./components/RetryFailedTasksButton";
import Task from "./components/Task";

const getLinkDefault = () => null;
const getDataDefault = (task) => ({ data: task.data });

export default function TasksAccordion({ tasks, getLink = getLinkDefault, getData = getDataDefault }) {
	const [activeId, setActiveId] = useState();

	if (tasks.length === 0) {
		return null;
	}

	return (
		<Accordion>
			{tasks.map((task) => (
				<Task
					key={task.id}
					task={task}
					data={getData(task)}
					link={getLink(task)}
					active={task.id === activeId}
					onClick={() => setActiveId((activeId) => (activeId !== task.id ? task.id : null))}
				/>
			))}

			<Accordion.Footer>
				<Button.Group>
					<RetryFailedTasksButton tasks={tasks} />
				</Button.Group>
			</Accordion.Footer>
		</Accordion>
	);
}
