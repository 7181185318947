import React from "react";
import { useTranslation } from "react-i18next";

import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";

import api from "../../../../../api";
import useArticleTypeTranslations from "../../../../articles/hooks/useArticleTypeTranslations";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminArticleDataForm({ article, ...props }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: api.articles.Schema,
		values: {
			ref: article.ref,
			isbn: article.isbn,
			narrator: article.narrator,
			pages: article.pages || null,
			duration: article.duration || null,
		},
		onSubmit: (data) => {
			return api.articles.update(data, { version: true });
		},
		...props,
	});

	const articleTypeTranslations = useArticleTypeTranslations();

	return (
		<AdminForm header={articleTypeTranslations[article.type]} {...form}>
			{article.type !== articleTypes.EBOOK ? (
				<>
					<Form.Group widths="equal">
						<Form.Input name="isbn" label={t("isbn", "ISBN")} />
						<Form.Input name="narrator" label={t("reader", "Narrator")} />
					</Form.Group>
					<Form.Group widths="equal">
						<Form.NumberInput name="pages" label={t("pages", "Pages")} />
						<Form.DurationInput name="duration" label={t("duration", "Duration")} />
					</Form.Group>
				</>
			) : (
				<Form.Group widths="equal">
					<Form.Input name="isbn" label={t("isbn", "ISBN")} />
					<Form.Input name="narrator" label={t("reader", "Narrator")} />
					<Form.NumberInput name="pages" label={t("pages", "Pages")} />
				</Form.Group>
			)}
		</AdminForm>
	);
}
