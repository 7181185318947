import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useOffersQuery({ role, type, types, status, deleted = false, production, createdBy } = {}) {
	return useMemo(() => {
		let query = createQuery(db.collection("offers"), {
			role,
			type,
			status,
			deleted,
			"production.id": production?.id,
			"createdBy.id": createdBy?.id,
		});

		if (types) {
			query = query.where("type", "in", types);
		}

		return query;
	}, [createdBy?.id, deleted, production?.id, role, status, type, types]);
}
