import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import PublisherAdminLayout from "../layouts/PublisherAdminLayout/PublisherAdminLayout";

export default function PublisherAdmin(props) {
	const hasRight = useHasRight();

	if (!hasRight("admin")) {
		return <Unauthorized />;
	}

	return <PublisherAdminLayout {...props} />;
}
