import { useTranslation } from "react-i18next";
import { useStats } from "react-instantsearch-core";
import { Message } from "semantic-ui-react";

export default function Stats() {
	const { t } = useTranslation();
	const { nbHits } = useStats();

	return <Message success style={{ margin: 0 }}>{`${t("hits", "Hits")}: ${nbHits}`}</Message>;
}
