import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import GenderButtons from "../components/GenderButtons";
import LanguageSkillsSelect from "../components/LanguageSkillsSelect";

export default function ReaderFilterForm() {
	const { t } = useTranslation();

	return (
		<Form.Group widths="2">
			<LanguageSkillsSelect />

			<div className="ui field">
				<label>{t("sex")}</label>

				<GenderButtons />
			</div>
		</Form.Group>
	);
}
