import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import useOrganizationOnce from "../../organizations/hooks/useOrganizationOnce";

function getQuery(query, part, firstPartyId) {
	switch (part) {
		case "user":
			return query
				.collection("users")
				.where(`permissions.producer.${firstPartyId}`, "array-contains-any", [
					"producerStaff",
					"producerEditor",
					"producerProofer",
					"producerRecorder",
				]);
		case "subcontractor":
			return query
				.collection("users")
				.where(`permissions.producer.${firstPartyId}`, "array-contains-any", ["producerSubcontractor"]);
		case "narrator":
			return query
				.collection("users")
				.where("permissions.reader", "array-contains-any", ["allProducers", firstPartyId]);
		case "publisher":
			return query.collection("organizations").where("type", "==", "publisher");
		default:
			return false;
	}
}

function hasVendors({ vendors }) {
	return !!vendors;
}

function isUserOrProducerPublisher({ part, producer, firstPartyId }) {
	return (target) =>
		part !== "publisher" ||
		(part === "publisher" && target.id !== firstPartyId && producer?.publishers?.includes(target.id));
}

function mapParties() {
	return (party) =>
		Object.entries(party.vendors || {}).map(([vendorId, vendor]) => ({
			...party,
			name: party.name || `${party.firstName} ${party.lastName}`,
			rowId: `${party.id}-${vendorId}`,
			vendor: { id: vendorId, ...vendor },
		}));
}

function sortParties(part) {
	if (part === "publisher") {
		return (a, b) => a.name.localeCompare(b.name);
	}

	return (a, b) => a.firstName.localeCompare(b.firstName);
}

export default function useSecondaryAgreementParties({ part, firstPartyId } = {}) {
	const producer = useOrganizationOnce(firstPartyId);

	const [data] = useCollectionDataOnce(useMemo(() => getQuery(db, part, firstPartyId), [firstPartyId, part]));

	return useMemo(
		() =>
			data
				?.filter(hasVendors)
				.filter(isUserOrProducerPublisher({ part, producer, firstPartyId }))
				.map(mapParties(part))
				.flat()
				.sort(sortParties(part)) || [],
		[data, part, firstPartyId, producer],
	);
}
