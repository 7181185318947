import flattenDeep from "lodash/flattenDeep";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

class UploadButton extends Component {
	componentDidMount() {
		// prevent browser behaviour
		"drag dragstart dragend dragover dragenter dragleave drop"
			.split(" ")
			.forEach((ev) => document.body.addEventListener(ev, this.prevent));

		// set body class
		"dragover dragenter".split(" ").forEach((ev) => document.body.addEventListener(ev, this.bodyStart));
		"dragleave dragend drop click".split(" ").forEach((ev) => document.body.addEventListener(ev, this.bodyEnd));
	}

	componentWillUnmount() {
		// reset listeners
		"drag dragstart dragend dragover dragenter dragleave drop"
			.split(" ")
			.forEach((ev) => document.body.removeEventListener(ev, this.prevent));

		"dragover dragenter".split(" ").forEach((ev) => document.body.removeEventListener(ev, this.bodyStart));
		"dragleave dragend drop click".split(" ").forEach((ev) => document.body.removeEventListener(ev, this.bodyEnd));
	}

	prevent = (e) => {
		// stop propagation unless the file was dropped in the drop zone
		if (e.type !== "drop" || !e.target.classList.contains("es-upload--drop")) {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	bodyStart = (e) => {
		document.body.classList.add("is-dragging");
	};

	bodyEnd = (e) => {
		// remove body class (if drag leaves body, or is dropped)
		if (e.target === document.body || e.type === "drop") document.body.classList.remove("is-dragging");
	};

	traverseDirectory = (entry) => {
		const reader = entry.createReader();

		return new Promise((resolve, reject) => {
			const iterationAttempts = [];
			const readEntries = () => {
				reader.readEntries(
					(entries) => {
						if (!entries.length) {
							resolve(Promise.all(iterationAttempts));
						} else {
							iterationAttempts.push(
								Promise.all(
									entries.map((ientry) => {
										if (ientry.isFile) {
											return ientry;
										}
										return this.traverseDirectory(ientry);
									}),
								),
							);
							readEntries();
						}
					},
					(error) => reject(error),
				);
			};
			readEntries();
		});
	};

	getFile = async (fileEntry) => {
		try {
			return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
		} catch (err) {
			console.log(err);
		}
	};

	drop = async (e) => {
		const { t } = this.props;
		// prevent browser navigation
		e.preventDefault();

		const droppedFiles = e.dataTransfer && e.dataTransfer.files;
		if (droppedFiles && droppedFiles.length > 1 && !this.props.multiple) {
			window.alert(t("oneFileOnlyWarning"));
		} else if (!droppedFiles[0].type && !e.dataTransfer.items.length) {
			window.alert(t("invalidFileTypeWarning"));
		} else if (e.dataTransfer.items.length && this.props.multiple) {
			// dropped files/folder items
			const dirs = [];
			let fileEntries = [];

			// loop first level
			for await (const item of e.dataTransfer.items) {
				const entry = item.webkitGetAsEntry();
				if (entry.isFile) fileEntries.push(entry);
				if (entry.isDirectory) dirs.push(entry);
			}

			// traverse any directories
			if (dirs.length) {
				await Promise.all(dirs.map((dir) => this.traverseDirectory(dir))).then((result) => {
					const flat = flattenDeep(result);
					fileEntries.push(...flat);
				});
			}

			// convert file entries to files
			const files = await Promise.all(
				fileEntries.map(async (entry) => {
					const file = await this.getFile(entry);
					file.fullPath = entry.fullPath;
					return file;
				}),
			);

			// fake some form data
			const fakeEvent = {
				target: {
					name: this.props.name,
					files,
				},
			};
			this.props.onUpload(fakeEvent);
		} else {
			// fake some form data
			e.target.files = droppedFiles;
			if (this.props.name) e.target.name = this.props.name;
			this.props.onUpload(e);
		}
	};

	render() {
		const { label, size, text, name, onUpload, multiple, directory, disabled, loading, style, fluid, t } =
			this.props;
		return (
			<div
				className={`field es-upload ${fluid ? "fluid" : ""}`}
				style={style || null}
				ref={(el) => (this.el = el)}
			>
				{label && <label>{label}</label>}
				<div className="es-upload--inner">
					<Button
						disabled={disabled}
						loading={loading}
						icon
						size={size || "medium"}
						labelPosition="right"
						fluid={fluid || null}
						basic
						className="es-upload--button"
					>
						{text}
						<Icon name="upload" />
					</Button>
					{!disabled && !loading && (
						<input
							className="es-upload--input"
							type="file"
							name={name}
							onChange={onUpload}
							multiple={multiple}
							webkitdirectory={directory ? "true" : null} // yes true needs to be a string
						/>
					)}
				</div>
				{!disabled && !loading && (
					<div className="es-upload--drop" onDrop={this.drop}>
						{t("uploadDropFilesLocation")}
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation()(UploadButton);
