import { useState } from "react";
import { Button } from "semantic-ui-react";

import CreateOrganizationForm from "../../../../organizations/components/CreateOrganizationForm/CreateOrganizationForm";

import useActionTitle from "../hooks/useActionTitle";

export default function CreateOrganizationAction({ type }) {
	const title = useActionTitle(type);

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)}>{title}</Button>

			{open && <CreateOrganizationForm type={type} onClose={() => setOpen(false)} />}
		</>
	);
}
