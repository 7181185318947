import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { version } from "../../helpers/version.json";

import LanguageSelector from "../LanguageSelector";

export default function Footer() {
	const { t } = useTranslation();

	const year = useMemo(() => new Date().getFullYear(), []);

	return (
		<footer className="site-footer">
			<LanguageSelector />
			&bull; Astrid {version} by Earselect © {year}
			<img src="/img/astrid-icon.svg" alt="Astrid" />
			<a href="mailto:info@earselect.se" className="site-footer-contact">
				{t("contact")}
			</a>{" "}
			&bull; <Link to="/policy">{t("integrity")} &amp; GDPR</Link>
		</footer>
	);
}
