import { usePagination } from "react-instantsearch";

import Pagination from "../Pagination/Pagination";

export default function InstantSearchPagination(props) {
	const { currentRefinement, nbPages, refine } = usePagination();

	if (nbPages <= 1) {
		return null;
	}

	return (
		<Pagination
			totalPages={nbPages}
			activePage={currentRefinement + 1}
			onPageChange={(_, { activePage }) => {
				refine(activePage - 1);
			}}
			{...props}
		/>
	);
}
