const actionNames = {
	GENERATE_METADATA: "generateMetadata",
	GET_FILES: "getFiles",
	RENAME_FILE: "renameFile",
	SAVE_TO_BUCKET: "saveToBucket",
	SEND_FILES_TO_FTP: "sendFilesToFTP",
	SEND_FILES_TO_SFTP: "sendFilesToSFTP",
	SPLIT_FILE: "splitFile",
	ZIP_FILES: "zipFiles",
};

export default actionNames;
