import moment from "moment";
import { useMemo } from "react";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";
import { getDeliveryIssues } from "astrid-firestore/src/api/productions/delivery/utils/deliveryIssues";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import mapProductionStatus from "../../../helpers/mapProductionStatus";

import useUpcomingDeliveriesQuery from ".//useUpcomingDeliveriesQuery";

export default function useUpcomingDeliveries({ organization }) {
	const [productions, loading, error] = useCollectionData(useUpcomingDeliveriesQuery({ organization }));

	const upcomingDeliveries = useMemo(
		() =>
			productions
				?.filter((production) =>
					[productionStatuses.PLANNING, productionStatuses.ACCEPTED, productionStatuses.PRODUCTION].includes(
						production.status,
					),
				)
				.map((production) => {
					return {
						production: {
							...production,
							status: mapProductionStatus(production),
						},
						obstacles: getDeliveryIssues(production),
					};
				})
				.filter(({ production, obstacles }) => {
					// scheduled
					const isScheduled = production.scheduled && obstacles.length > 0;

					// planned for delivery within 14 days
					const isUnderProduction = production.deliveryDate?.toDate?.() < moment().add(14, "days");

					return isScheduled || isUnderProduction;
				}),
		[productions],
	);

	return [upcomingDeliveries, loading, error];
}
