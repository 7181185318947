import approvalStatuses from "astrid-firestore/src/api/approval/constants/approvalStatuses";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

import useSortBy from "../../ui/hooks/useSortBy";

import useAgreementTemplatesQuery from "../../agreements/hooks/useAgreementTemplatesQuery";
import useAgreementsQuery from "../../agreements/hooks/useAgreementsQuery";
import useFilterDocumentsByAdminProducers from "../../authorization/hooks/useFilterDocumentsByAdminProducers";
import useOffersQuery from "../../offers/hooks/useOffersQuery";
import usePricesQuery from "../../prices/hooks/usePricesQuery";
import useProductionPricesQuery from "../../productions/hooks/useProductionPricesQuery";

export default function useNeedsApproval() {
	const [
		[prices = [], offers = [], agreements = [], agreementTemplates = [], productionPrices = []],
		loading,
		error,
	] = useLoadingValues(
		useCollectionData(usePricesQuery().where("approval.status", "==", approvalStatuses.PENDING)),
		useCollectionData(useOffersQuery().where("approval.status", "==", approvalStatuses.PENDING)),
		useCollectionData(useAgreementsQuery().where("approval.status", "==", approvalStatuses.PENDING)),
		useCollectionData(useAgreementTemplatesQuery().where("approval.status", "==", approvalStatuses.PENDING)),
		useCollectionData(useProductionPricesQuery().where("approval.status", "==", approvalStatuses.PENDING)),
	);

	return {
		error,
		loading,
		prices: useSortBy(useFilterDocumentsByAdminProducers(prices), "created.seconds"),
		offers: useSortBy(useFilterDocumentsByAdminProducers(offers), "expiresAt.seconds"),
		agreements: useSortBy(useFilterDocumentsByAdminProducers(agreements), "created.seconds"),
		productionPrices: useSortBy(useFilterDocumentsByAdminProducers(productionPrices), "created.seconds"),
		agreementTemplates: useSortBy(useFilterDocumentsByAdminProducers(agreementTemplates), "created.seconds"),
	};
}
