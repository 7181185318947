import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import offerDeclineReasons from "../constants/offerDeclineReasons";

export default function useOfferDeclineReasonTranslations() {
	const { t } = useTranslation();
	return useMemo(
		() => ({
			[offerDeclineReasons.NOT_INTERESTED]: t("notInterested", "Not interested"),
			[offerDeclineReasons.WRONG_FEE]: t("wrongFee", "Fee not agreed upon"),
			[offerDeclineReasons.NOT_AVAILABLE]: t("notAvailable", "Not available within given time frame"),
			[offerDeclineReasons.OTHER]: t("other", "Other"),
		}),
		[t],
	);
}
