import React from "react";

import Select from "../../../ui/components/Select/Select";

import useCostTypeOptions from "../../../costs/hooks/useCostTypeOptions";

export default function PriceTypeSelect({ part, ...props }) {
	const costTypeOptions = useCostTypeOptions(part);

	return <Select search fluid options={costTypeOptions} {...props} />;
}
