import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminArtifactHeader from "./components/AdminArtifactHeader";

export default function AdminArtifactView(props) {
	return (
		<AdminPageView {...props}>
			<AdminArtifactHeader />
		</AdminPageView>
	);
}
