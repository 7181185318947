import { firebase } from "astrid-firebase";

let time;

firebase
	.functions()
	.httpsCallable("serverTime")()
	.then((result) => {
		time = result.data.date - +new Date();
	});

export default function serverTime() {
	return +new Date() + time;
}
