import React, { Component, useState } from "react";
import DocTitle from "astrid-web/src/components/DocTitle";

import { base } from "astrid-firebase";
import { toDate } from "astrid-web/src/helpers/fnc";
import moment from "moment";

import { Icon, Table, Form } from "semantic-ui-react";

class ComputeJobs extends Component {
	state = {
		loading: true,
		jobs: [],
	};

	UNSAFE_componentWillMount() {
		base.bindCollection("tasks/compute/jobs", {
			context: this,
			state: "jobs",
			withIds: true,
			query: (ref) => ref.orderBy("created", "desc").limit(100),
			then() {
				this.setState({ loading: false });
			},
			onFailure(err) {
				console.log("organizations err", err);
			},
		});
	}

	render() {
		const { jobs } = this.state;

		return (
			<>
				<DocTitle title="Serverinstanser" />
				<style>
					{`
					.log {
						font-family: monospace;
						font-size: 12px;
						white-space: pre-wrap;
						line-height: 1.3;
					}
					.log.file {
						display: none;
						padding: 1em;
						background: #111;
						color: white;
					}
					.log.visible {
						display: block;
					}
					.log + .field {
						margin-top: 0.4em;
					}

					`}
				</style>
				<Table compact size="small">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell collapsing>Status/ID</Table.HeaderCell>
							<Table.HeaderCell>Jobb/Instans</Table.HeaderCell>
							<Table.HeaderCell collapsing>Skapad/Klar</Table.HeaderCell>
							<Table.HeaderCell>Argument/Logg</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{jobs.map((job) => {
							return (
								<Table.Row key={job.id} verticalAlign="top">
									<Table.Cell>
										<Icon
											name="circle"
											color={
												job.status === "queued"
													? "orange"
													: job.status === "initiated"
													? "yellow"
													: job.status === "fail"
													? "red"
													: "green"
											}
										/>{" "}
										{job.status}
										<br />
										{job.id}
									</Table.Cell>
									<Table.Cell singleLine>
										{job.job}
										<br />
										{job.machineType} {job.diskSize}GB {job.diskType}
									</Table.Cell>
									<Table.Cell singleLine>
										{job.created && moment(toDate(job.created)).format("YYYY-MM-DD HH:mm:ss")}
										<br />
										{job.done && moment(toDate(job.done)).format("YYYY-MM-DD HH:mm:ss")}
									</Table.Cell>
									<Table.Cell>
										<div className="log">{JSON.stringify(job.args).split(":").join(": ")}</div>
										{typeof job.log === "string" && <Log log={job.log} />}
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</>
		);
	}
}

const Log = ({ log }) => {
	const [show, setShow] = useState();
	return (
		<>
			<Form.Checkbox
				label="Visa logg"
				checked={show}
				onChange={(e, data) => {
					setShow(data.checked);
				}}
			/>
			{show && <div className={"log file" + (show ? " visible" : "")}>{log}</div>}
		</>
	);
};

export default ComputeJobs;
