import React from "react";

import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

import InquiryOfferForm from "./components/InquiryOfferForm";
import OpenOfferForm from "./components/OpenOfferForm";
import WorkOrderForm from "./components/WorkOrderForm";

export default function OfferForm({ type, ...props }) {
	switch (type) {
		case offerTypes.OPEN:
			return <OpenOfferForm type={type} {...props} />;
		case offerTypes.WORK_ORDER:
			return <WorkOrderForm type={type} {...props} />;
		case offerTypes.INQUIRY:
			return <InquiryOfferForm type={type} {...props} />;
		default:
			return null;
	}
}
