import runBatch from "../../helpers/runBatch";

import createReaderInquiryTodoData from "../todos/data/offers/createReaderInquiryTodoData";

import offerStatuses from "./constants/offerStatuses";

export default function acceptInquiry(firebase, { offer }) {
	return runBatch(firebase.firestore(), (batch) => {
		const todo = createReaderInquiryTodoData(firebase, { offer, status: offerStatuses.INTERESTED });

		batch.update(offer.ref, { status: offerStatuses.INTERESTED });
		batch.set(todo.ref, todo);
	});
}
