export default {
	name: "Bookmate",
	format: "mp3",
	connection: "ftp",
	audio: {
		rename: "SEQ.SUFFIX",
	},
	meta: { format: "excel", requiredFields: ["priceUnitSale"], currency: ["SEK", "EUR"] },
	cover: {
		square: true,
		max: 1000, // max 1000px
	},
	email: [
		"delivery",
		// send metadata file as attachment
		"attachMetadata",
	],
	disableArticles: ["cd", "mp3cd"],
	disableFtpVerifyCertificate: true,
};
