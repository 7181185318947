import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DistributionChannel } from "astrid-firestore/src/api/distribution/types/DistributionChannel";

import api from "../../../../api";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateDistributionChannelForm({ onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: DistributionChannel,
		defaultValues: {
			name: "",
		},
		onSubmit: (data) => {
			return api.distributionChannels.create(data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<ModalPrompt
			size="tiny"
			header={t("createDistributionChannel", "Create distribution channel")}
			onSave={onSubmit}
			onClose={onClose}
		>
			<Form form={form}>
				<Form.Input name="name" label={t("name", "Name")} />
			</Form>

			{error && <ErrorMessage error={error} />}
		</ModalPrompt>
	);
}
