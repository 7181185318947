import { array, object, string } from "../../../types/_types";

export const Device = object({
	key: string(),
	text: string(),
	value: string(),
});

export const Devices = object({
	inputs: array(Device),
	outputs: array(Device),
});
