import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import api from "../../../../../api";

export default function useAdminAuthorMenuOptions({ author }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [titlesCount, titlesCountLoading] = api.titles.useCount((query) =>
		query.where("authorIds", "array-contains", author.id),
	);

	const [articlesCount, articlesCountLoading] = api.articles.useCount((query) =>
		query.where("authorIds", "array-contains", author.id),
	);

	return [
		{
			to: "",
			active: pathname.endsWith(author.id),
			text: t("settings", "Settings"),
		},
		{
			to: "titles",
			active: pathname.endsWith("/titles"),
			text: t("titles", "Titles"),
			label: titlesCount,
			loading: titlesCountLoading,
		},
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles"),
			label: articlesCount,
			loading: articlesCountLoading,
		},
	];
}
