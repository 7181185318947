import React, { useState, useEffect } from "react";
import { firebase } from "astrid-firebase";
import { Grid, Segment, Button, Icon, Loader } from "semantic-ui-react";
//id's in common.json
import { useTranslation } from "react-i18next";

export default function SharedExport({ match }) {
	const { productionId, article, id } = match.params;
	const [loading, setLoading] = useState(true);
	const [prod, setProd] = useState({});
	const { t } = useTranslation();

	useEffect(() => {
		// get production and export data from cloud function
		const masterExport = firebase.functions().httpsCallable("masterExport");
		masterExport({ productionId, article, id }).then(({ data }) => {
			setProd(data);
			setLoading(false);
		});
	}, [article, id, productionId]);

	const expired =
		!prod.export ||
		!prod.export.status ||
		(prod.masterPartSettings?.date && prod.export.created._seconds < prod.masterPartSettings.date._seconds);

	if (prod.export && expired) prod.export.status = "expired";

	return (
		<Grid textAlign="center">
			<Grid.Column style={{ marginTop: "3%", maxWidth: 540 }}>
				<Segment loading={loading}>
					<h1>{prod.title} &nbsp;</h1>
					{prod.export && (
						<div>
							<p>
								<b>{t("format")} </b>
								{prod.export.format.toUpperCase()}{" "}
								{prod.export.bitrate && prod.export.bitrate + " kbps"}
							</p>
							<p>
								<b>{t("status")} </b>
								<Icon
									name="circle"
									color={prod.export.status === "done" ? "green" : expired ? "yellow" : "orange"}
								/>
								{/* {status[prod.export.status]} */}
								{t(prod.export.status)}
							</p>
							<p>
								{!expired &&
									(prod.export.status === "done" ? (
										<Button
											primary
											as="a"
											icon="download"
											href={prod.export.zip.url}
											content={t("downloadBtn")}
										/>
									) : (
										<span>
											<Loader
												as="span"
												active
												inline
												size="tiny"
												style={{ verticalAlign: "text-bottom" }}
											/>{" "}
											<em>{t("loadingSoonReady")}</em>
										</span>
									))}
							</p>
						</div>
					)}
					{prod.imgThumb && (
						<p>
							<img src={prod.imgThumb} alt={prod.title} style={{ marginTop: "2em" }} />
						</p>
					)}
				</Segment>
			</Grid.Column>
		</Grid>
	);
}
