import { useMemo } from "react";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

function getTeamMembersForRole(team, role) {
	return Object.values(team || {})
		.filter((member) => member.role === role)
		.map((member) => member?.user?.name);
}

function parseToISOdate(timestamp) {
	return timestamp?.toDate()?.toLocaleDateString("sv") || "";
}

export default function useMapMyProductions(myProductions) {
	return useMemo(() => {
		return myProductions?.map((production) => ({
			id: production.id,
			title: production.title,
			isbn: production.isbn,
			readerNames: getTeamMembersForRole(production?.team, teamRoles.READER)?.join(", "),
			editorNames: getTeamMembersForRole(production?.team, teamRoles.EDITOR)?.join(", "),
			prooferNames: getTeamMembersForRole(production?.team, teamRoles.PROOFER)?.join(", "),
			managerNames: getTeamMembersForRole(production?.team, teamRoles.MANAGER)?.join(", "),
			subcontractorName: getTeamMembersForRole(production?.team, teamRoles.SUBCONTRACTOR)?.join(", "),
			author: production.authorOptions?.map((author) => author.text).join(","),
			status: production.status,
			productionDate: parseToISOdate(production.productionDate),
			productionType: production.productionType,
			deliveryDate: parseToISOdate(production.deliveryDate),
			duration: {
				isEstimated: !production.deliveryDuration,
				time: !production.deliveryDuration ? production.estimatedDuration : production.deliveryDuration,
			},
			doneDate: parseToISOdate(production.doneDate),
			created: parseToISOdate(production.created),
			updated: parseToISOdate(production.updated),
			progressBar: production.progressBar,
			noOfCorrections: production.progressBar?.corrected,
			publisher: production.publisherName,
			publisherManagerNames: production.publisherManagers?.map((pM) => pM.name)?.join(", "),
			productionTags: production.productionTags?.join(", "),
			producer: production.producerName,
			series: production.series,
			language: production.language,
			scriptDate: {
				isUploaded: !!production.script,
				date:
					production.script && production.scriptUploadDate
						? parseToISOdate(production.scriptUploadDate)
						: parseToISOdate(production.scriptDate),
			},
		}));
	}, [myProductions]);
}
