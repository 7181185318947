import { localStorage } from "../../../../../helpers/fnc";
import getLocationOrganization from "../../../../organizations/utils/getLocationOrganization";

export default function getInitialOrganization({ user, organizations }) {
	const { id } = localStorage("organizations", user.id) || {};

	const currentOrganizationId = getLocationOrganization()?.id || id;

	return organizations.find(({ id }) => id === currentOrganizationId) || organizations[0];
}
