import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import usePriceUnits from "../../../../prices/hooks/usePriceUnits";
import { formatPrice } from "../../../../prices/utils/priceFormatters";

export default function PriceInfo({ price }) {
	const { t } = useTranslation();

	const units = usePriceUnits();
	const costTypes = useCostTypes();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{price.name}</Card.Header>
				<Card.Description>{costTypes[price.type]}</Card.Description>
			</Card.Content>
			<Card.Content>
				{t("production", "Production")}
				<Card.Meta>
					<Link to={`/production/${price.production.id}/finance`}>{price.production.title}</Link>
				</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("firstParty", "First party")}
				<Card.Meta>
					<Link to={`../../${price.firstParty?.type}s/${price.firstParty?.id}`}>
						{price.firstParty?.name}
					</Link>
				</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("secondParty", "Second party")}
				<Card.Meta>
					{price.secondParty ? (
						<Link to={`../../${price.secondParty?.type}s/${price.secondParty?.id}`}>
							{price.secondParty?.name}
						</Link>
					) : (
						t("upForGrabs", "Up for grabs")
					)}
				</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("unit", "Unit")}
				<Card.Meta>{units[price.unit]}</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("price", "Price")}
				<Card.Meta>{formatPrice(price)}</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("currency", "Currency")}
				<Card.Meta>{price.currency}</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("VAT", "VAT")}
				<Card.Meta>{price.vat}%</Card.Meta>
			</Card.Content>
		</Card>
	);
}
