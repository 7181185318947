import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Author } from "astrid-firestore/src/api/authors/types/Author";
import { languageOptions } from "astrid-helpers/src/languages";

import api from "../../../../api";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateAuthorForm({ onClose, organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Author,
		defaultValues: {
			name: "",
			languages: [],
			publisherIds: [organization.id],
		},
		onSubmit: async (data) => {
			return api.authors.create(data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<ModalPrompt size="tiny" header={t("createAuthor", "Create author")} onSave={onSubmit} onClose={onClose}>
			<Form form={form}>
				<Form.Input name="name" label={t("name", "Name")} />
				<Form.Select
					name="languages"
					label={t("languages", "Languages")}
					multiple
					search
					options={languageOptions}
				/>
			</Form>

			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</ModalPrompt>
	);
}
