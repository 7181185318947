import { Label } from "semantic-ui-react";

import agreementStatusColors from "../../utils/agreementStatusColors";

import AgreementStatus from "./AgreementStatus";

export default function AgreementStatusLabel({ agreement }) {
	return (
		<Label color={agreementStatusColors[agreement.status]}>
			<AgreementStatus agreement={agreement} />
		</Label>
	);
}
