import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminSerieHeader from "./components/AdminSerieHeader";

export default function AdminSerieView(props) {
	return (
		<AdminPageView {...props}>
			<AdminSerieHeader />
		</AdminPageView>
	);
}
