import firebase from "firebase/app";
import markdownit from "markdown-it/dist/markdown-it";

import { getDocumentData } from "astrid-firestore/src/helpers";

import db from "./db";

const markdown = markdownit({ html: true });

export function htmlMessage({ message, lang }) {
	return `<html lang="${lang}"><head></head><body>${message}</body></html>`;
}

export function markdownMessage({ message, lang }) {
	const html = markdown.render(message);

	return htmlMessage({ message: String(html), lang });
}

export default async function notify(ids, getNotificationData) {
	if (ids.length > 0) {
		const users = await Promise.all(ids.map((id) => getDocumentData(db.collection("users").doc(id))));

		return Promise.all(
			users.filter(Boolean).map(async (user) => {
				const { slack, email, producer, notifications } = user;

				if (notifications !== "none") {
					const channel = notifications === "slack" ? "slack" : "email";

					if (channel === "slack" || email.substr(0, 6) !== "dummy-") {
						const notificationData = getNotificationData(user);

						if (notificationData?.subject && notificationData?.message) {
							const notification = {
								to: {
									uid: user.id,
									producer: producer || false,
									[channel]: channel === "slack" ? slack : email,
								},
								time: firebase.firestore.FieldValue.serverTimestamp(),
								...notificationData,
							};

							const { id } = await db.collection("notifications").add(notification);

							return id;
						}
					}
				}
			}),
		);
	}
}
