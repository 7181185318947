import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

export default function useTeamRoleTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[teamRoles.EDITOR]: t("editor", "Editor"),
			[teamRoles.PROOFER]: t("proofer", "Proofer"),
			[teamRoles.MANAGER]: t("productionManager", "Production coordinator"),
			[teamRoles.READER]: t("reader", "Narrator"),
			[teamRoles.RECORDER]: t("recorder", "Recording engineer"),
			[teamRoles.SUBCONTRACTOR]: t("subcontractor", "Subcontractor"),
		}),
		[t],
	);
}
