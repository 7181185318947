import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useRoleTranslations() {
	const { t } = useTranslation();

	return useMemo(() => {
		return {
			editor: t("editor"),
			proofer: t("proofer"),
			manager: t("manager"),
			reader: t("reader"),
			recorder: t("recorder"),
			subcontractor: t("subcontractor"),
		};
	}, [t]);
}
