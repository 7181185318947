import SelectColumns from "../../../../../ui/components/SelectColumns/SelectColumns";

import RangeDates from "./RangeDates";
import RefinementCheckboxes from "./RefinementCheckboxes";

export default function AccordionContent({ refinement, selectedColumns, selectColumn }) {
	const { attribute, type, sortItems, searchable, scrollable } = refinement;
	switch (type) {
		case "checkbox":
			return (
				<RefinementCheckboxes
					attribute={attribute}
					sortItems={sortItems}
					searchable={searchable}
					scrollable={scrollable}
					showMore
					limit={6}
					showMoreLimit={20}
				/>
			);
		case "dates":
			return <RangeDates attribute={attribute} />;
		case "columns":
			return <SelectColumns selectedColumns={selectedColumns} selectColumn={selectColumn} {...refinement} />;
		default:
			return false;
	}
}
