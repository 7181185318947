import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import AdminArticleBundlesView from "../views/AdminArticleBundlesView/AdminArticleBundlesView";

export default function AdminArticleBundles() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("articles.view")) {
		return <Unauthorized />;
	}

	return <AdminArticleBundlesView organization={organization} />;
}
