import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import usePriceUnits from "../../../../prices/hooks/usePriceUnits";

import RemoveAutoApprovePrice from "../components/RemoveAutoApprovePrice";

export default function useAutoApprovalRulesColumns(organization) {
	const { t } = useTranslation();

	const units = usePriceUnits();
	const costTypes = useCostTypes(true);

	return useMemo(
		() => [
			{
				id: "type",
				Header: t("type", "Type"),
				accessor: ({ type }) => costTypes[type],
			},
			{
				id: "language",
				Header: t("language", "Language"),
				accessor: ({ language }) => languageName(language),
			},
			{
				id: "currency",
				Header: t("currency", "Currency"),
			},
			{
				id: "unit",
				Header: t("unit", "Unit"),
				accessor: ({ unit }) => units[unit],
			},
			{
				id: "threshold",
				Header: t("threshold", "Threshold"),
			},
			{
				id: "remove",
				Cell: ({ row }) => <RemoveAutoApprovePrice id={row.id} organization={organization} />,
			},
		],
		[costTypes, organization, t, units],
	);
}
