import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Segment } from "semantic-ui-react";

import { db } from "astrid-firebase";
import { useCollectionData, useLoadingValues } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import BiblioTasksView from "../../views/BiblioTasksView/BiblioTasksView";

import BiblioTasksMenu from "./components/BiblioTasksMenu";

export default function BiblioTasksLayout({ uncompletedTaskCount, children }) {
	const { t } = useTranslation();

	const [search, setSearch] = useState("");

	const [tasks, loading, error] = useLoadingValues(
		useCollectionData(
			search &&
				db.collection("tasks/queue/tasks").where("isbns", "array-contains", search).orderBy("created", "desc"),
		),
		useCollectionData(
			search &&
				db.collection("tasks/queue/tasks").where("production.id", "==", search).orderBy("created", "desc"),
		),
	);

	const flatTasks = tasks?.flat().filter(Boolean);

	return (
		<>
			<AdminHeader>
				<>Biblio - {t("tasks", "Tasks")}</>

				<Input
					size="mini"
					value={search}
					placeholder={t("search", "Search")}
					onChange={(e, { value }) => setSearch(value)}
				/>
			</AdminHeader>

			<BiblioTasksMenu uncompletedTaskCount={uncompletedTaskCount} />

			<Segment attached="bottom">
				<LoadingContext loading={loading} error={error}>
					{flatTasks?.length > 0 ? <BiblioTasksView tasks={flatTasks} /> : children}
				</LoadingContext>
			</Segment>
		</>
	);
}
