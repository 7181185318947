import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import useForm from "../../../../forms/hooks/useForm";

export default function useMemberForm({ offer, production, member }) {
	const role = offer?.role || member?.role || null;
	const userId = offer?.secondParty?.id || member?.user.id || null;
	const agreementId = offer?.agreement?.id || member?.agreement?.id || null;
	const firstPartyId =
		member?.agreement?.firstParty?.id || (role === teamRoles.READER ? production.publisher : production.producer);
	const priceId = offer ? (offer?.price ? "custom" : "external") : null;

	return useForm({
		defaultValues: {
			member,
			offer,
			production,
			role,
			userId,
			agreementId,
			firstPartyId,
			priceId,
			customPrice: {},
		},
		resetFields: {
			userId: ["role"],
			agreementId: ["role", "userId"],
			priceId: ["role", "userId", "agreementId"],
		},
	});
}
