export default {
	name: "Overdrive",
	format: "mp3",
	connection: "ftp",
	audio: {
		zip: true,
		folder: "Audio/ISBN",
		rename: "ISBN_SEQ_TOTAL.SUFFIX",
	},
	ebook: {
		folder: "Ebook/ISBN",
	},
	meta: {
		folder: "MEDIAFOLDER", // put in same folder as media depending on article
		format: "onix",
		fields: ["availability", "countriesIncluded"],
		sharedFields: ["tags", "bisac", "abridged"],
		requiredFields: ["priceOCOU", "priceCPC"],
	},
	cover: {
		folder: "MEDIAFOLDER", // put in same folder as media depending on article
	},
	disableArticles: ["cd", "mp3cd"],
	email: ["delivery"],
};
