import useHasRight from "../features/authorization/hooks/useHasRight";
import Unauthorized from "../features/ui/components/Unauthorized/Unauthorized";

export default function withRight(Component, right) {
	const Wrapper = (props) => {
		const hasRight = useHasRight();

		if (!hasRight(right)) {
			return <Unauthorized />;
		}

		return <Component {...props} />;
	};

	Wrapper.displayName = "withRight";

	return Wrapper;
}
