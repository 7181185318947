import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminTitleHeader from "./components/AdminTitleHeader";

export default function AdminTitleView(props) {
	return (
		<AdminPageView {...props}>
			<AdminTitleHeader />
		</AdminPageView>
	);
}
