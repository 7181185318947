import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useEmployeeOptions() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				key: "nonEmployee",
				value: false,
				text: t("nonEmployee", "Non-Employee"),
			},
			{
				key: "employee",
				value: true,
				text: t("employee", "Employee"),
			},
		],
		[t],
	);
}
