import { dotObject, dotPick } from "./dotObject";

function parseData(data, { Schema, partial = false }) {
	const objectData = dotObject(data);

	if (partial) {
		return Schema.partial().parse(objectData);
	}

	return Schema.parse(objectData);
}

export default function validateDocumentData(data, { Schema, partial = false }) {
	if (!Schema) {
		throw new Error("Schema is required");
	}

	if (!data) {
		throw new Error("Data is required");
	}

	const validData = parseData(data, { Schema, partial });

	const dotKeys = Object.keys(data).filter((key) => key.includes("."));

	const partialData = Object.keys(validData).reduce((acc, key) => {
		const exists = dotKeys.some((dotKey) => dotKey.startsWith(`${key}.`));

		if (exists) {
			return dotKeys.reduce(
				(acc, dotKey) => ({
					...acc,
					[dotKey]: dotPick(dotKey, validData),
				}),
				acc,
			);
		}

		return {
			...acc,
			[key]: validData[key],
		};
	}, {});

	return { validData, partialData };
}
