import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useApprovePricesColumns from "./hooks/useApprovePricesColumns";

export default function SuperAdminApprovePricesView({ prices, loading, error }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useApprovePricesColumns();

	return (
		<>
			<AdminHeader>
				{t("needsApproval", "Needs approval")} - {t("prices", "Prices")}
			</AdminHeader>

			<DataTable
				data={prices}
				error={error}
				loading={loading}
				columns={columns}
				onClick={({ id, producer }) => navigate(`/producer/${producer.id}/admin/prices/${id}`)}
			/>
		</>
	);
}
