import moment from "moment";

import serverTime from "astrid-helpers/src/serverTime";

export default function secondsSinceOnline(online) {
	const momentServerTime = moment(serverTime());
	const momentOnlineTime = moment(online?.toDate());
	const differenceInSeconds = online ? momentServerTime.diff(momentOnlineTime, "seconds") : 62;

	return differenceInSeconds;
}
