import { getTeamMemberOpenCost } from "astrid-web/src/features/team/utils/teamHelper";

import { teamRoles } from "../../../constants/teamRoles";
import { getCollectionData } from "../../../helpers";
import runBatch from "../../../helpers/runBatch";

import sessionStatuses from "../../sessions/constants/sessionStatuses";

function getSessionsQuery(db, { member, production }) {
	const query = db
		.collection("sessions")
		.where("production", "==", production.id)
		.where("status", "==", sessionStatuses.BOOKED);

	if (member.role === teamRoles.READER) {
		return query.where("reader", "array-contains", member.user.id);
	}

	if (member.role === teamRoles.RECORDER) {
		return query.where("recorder", "==", member.user.id);
	}
}

export default async function removeTeamMember(db, firestore, { member, production }) {
	if (!db || !firestore || !member || !production) {
		throw new Error("Missing params");
	}

	const openCost = await getTeamMemberOpenCost({ member, production });

	if (openCost?.openAmount) {
		throw new Error("Member has open cost");
	}

	const sessionsQuery = getSessionsQuery(db, { member, production });

	if (sessionsQuery) {
		const sessions = await getCollectionData(sessionsQuery);

		if (sessions.length) {
			throw new Error("Member has booked sessions");
		}
	}

	await runBatch(db, (batch) => {
		if (member.price?.ref) {
			batch.delete(member.price.ref);
		}

		batch.update(production.ref, {
			[`team.${member.id}`]: firestore.FieldValue.delete(),
		});

		if (member.offer?.ref) {
			batch.update(member.offer.ref, { removedFromTeam: true });
		}
	});
}
