import { firebase } from "astrid-firebase";
import createAgreementData from "astrid-firestore/src/api/agreements/createAgreementData";

import rolePartMap from "../../../../agreements/utils/rolePartMap";
import { getProfile } from "../../../../authentication/state/profile";

export default function createProductionAgreement({
	role,
	production,
	firstParty,
	secondParty,
	customPrice,
	customAgreement: { name, template, approved, appendixes, firstSignee, firstPartyVendorId, secondPartyVendorId },
}) {
	return createAgreementData(firebase, {
		name,
		template,
		approved,
		production,
		createdBy: getProfile(),
		appendixes: appendixes || template.appendixes,
		firstParty,
		firstSignee,
		firstPartyVendorId,
		secondParty,
		secondPartyVendorId,
		type: "production",
		part: rolePartMap[role],
		prices: [
			{
				...customPrice,
				name: "Custom",
			},
		],
	});
}
