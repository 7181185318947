import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import ArticlesInstantSearchDataTable from "../../../articles/components/ArticleBundlesInstantSearchDataTable/ArticleBundlesInstantSearchDataTable";
import CreateArticleBundleButton from "../../../articles/components/CreateArticleBundleForm/CreateArticleBundleButton";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminArticleBundlesView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("articleBundles", "Article bundles")}>
				{searchInput}

				<CreateArticleBundleButton organization={organization} />
			</AdminHeader>

			<ArticlesInstantSearchDataTable organization={organization} searchQuery={searchQuery} />
		</>
	);
}
