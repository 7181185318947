import { useTranslation } from "react-i18next";

export default function useTranslatorsDropdownMenuOptions({ organization }) {
	const { t } = useTranslation();

	return (data) => [
		{
			text: t("view", "View"),
			linkTo: `/publisher/${organization.id}/admin/translators/${data.id}`,
		},
	];
}
