import React from "react";
import { useTranslation } from "react-i18next";

import Accordion from "../../../../ui/components/Accordion/Accordion";

export default function ShowMore({ hitsLeft, showMore, isLastPage }) {
	const { t } = useTranslation();

	return (
		<Accordion.Footer style={{ cursor: "pointer" }} onClick={showMore} disabled={isLastPage}>
			<div style={{ width: "100%", textAlign: "center" }}>
				{t("showMore", "Show more")} ({hitsLeft})
			</div>
		</Accordion.Footer>
	);
}
