import useSWR from "swr";
import { parse } from "yaml";

const bucketUrl = "https://astrid-apps.s3.eu-north-1.amazonaws.com";

async function fetcher(file) {
	const url = `${bucketUrl}/${file}?cache=${Date.now()}`;
	const response = await fetch(url);

	if (response.status === 200) {
		return parse(await response.text());
	}

	throw new Error(`Failed to fetch ${url}`);
}

function getRelease(data, fileIndex = 0) {
	if (!data) {
		return {};
	}

	return {
		version: data.version,
		url: `${bucketUrl}/astrid-studio/${data.files[fileIndex].url}`,
		releaseDate: new Date(data.releaseDate),
	};
}

export default function useChannelReleases(app, channel) {
	const windows = useSWR(`${app}/${channel}.yml`, fetcher);
	const mac = useSWR(`${app}/${channel}-mac.yml`, fetcher);
	const linux = useSWR(`${app}/${channel}-linux-arm64.yml`, fetcher);

	const releases = [
		{
			os: "Windows",
			icon: "windows",
			...getRelease(windows.data, 0),
		},
		{
			os: "Mac (Intel)",
			icon: "apple",
			...getRelease(mac.data, 0),
		},
		{
			os: "Mac (Silicon)",
			icon: "apple",
			...getRelease(mac.data, 1),
		},
		{
			os: "Linux",
			icon: "linux",
			...getRelease(linux.data, 0),
		},
	];

	const loading = linux.isLoading || mac.isLoading || windows.isLoading;
	const error = false;

	return [releases, loading, error];
}
