import { Button } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import useHasRight from "../../authorization/hooks/useHasRight";
import { useOrganizationType } from "../../organizations/state/organizationType";

export default function LinkToOldCalendar() {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	const orgType = useOrganizationType();

	if (!hasRight("viewCalendar") || orgType === organizationTypes.PUBLISHER) {
		return null;
	}

	return (
		<Button
			size="tiny"
			content={t("calendar", "Calendar") + " (Old)"}
			icon="arrow right"
			labelPosition="right"
			as={Link}
			to={"/calendar"}
		/>
	);
}
