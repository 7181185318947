import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

export default function useOrganization(id) {
	const [data] = useDocumentData(useMemo(() => id && db.collection("organizations").doc(id), [id]));

	return data;
}
