import firebase from "firebase/app";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";
import { languageName } from "astrid-helpers/src/languages";

import ConfirmationModalTrigger from "../../../../ui/components/ConfirmationModal/ConfirmationModalTrigger";

export default function useUserLanguagesTableColumns(user) {
	const { t } = useTranslation();

	const removeLanguage = async ({ language }) => {
		try {
			await updateDocument(user.ref, {
				[`languages.${language}`]: firebase.firestore.FieldValue.delete(),
			});
		} catch (error) {
			console.log(error);
		}
	};

	return useMemo(
		() => [
			{
				id: "language",
				Header: t("language", "Language"),
				disableSortBy: true,
				accessor: ({ language }) => languageName(language),
			},
			{
				id: "skill",
				Header: t("skillLevel", "Skill level"),
			},
			{
				id: "dialect",
				Header: t("dialect", "Dialect"),
				disableSortBy: true,
			},
			{
				id: "actions",
				collapsing: true,
				disableSortBy: true,
				Cell: ({ row }) => {
					return (
						<ConfirmationModalTrigger
							text={t("confirmRemoveLanguage", "Do you want to remove this language?")}
							onConfirm={() => removeLanguage(row.original)}
							trigger={<Icon link name="trash" />}
						/>
					);
				},
			},
		],
		[t],
	);
}
