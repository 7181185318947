import arrayChunk from "astrid-helpers/src/arrayChunk";

import runBatch from "./runBatch";

export default function batch(db, items) {
	return Promise.all(
		arrayChunk(
			items.filter((d) => d),
			500,
		).map((items) => {
			return runBatch(db, (batch) => {
				for (const item of items) {
					const { ref, method, data } = Array.isArray(item)
						? { method: item[0], ref: item[1], data: item[2] }
						: item;

					const doc = typeof ref === "string" ? db.doc(ref) : ref;

					if (method === "delete") {
						batch.delete(doc);
					} else if (method === "set") {
						batch[method](doc, data, { merge: true });
					} else {
						batch[method](doc, data);
					}
				}
			});
		}),
	);
}

batch.set = (ref, data) => ["set", ref, data];
batch.update = (ref, data) => ["update", ref, data];
batch.delete = (ref, data) => ["delete", ref, data];
