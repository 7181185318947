import useOfferDeclineReasonTranslations from "../../hooks/useOfferDeclineReasonTranslations";

export default function OfferDeclineReason({ offer }) {
	const translations = useOfferDeclineReasonTranslations();

	if (offer.declineReasonText) {
		return offer.declineReasonText;
	}

	return translations[offer.declineReason];
}
