import React, { Component } from "react";
import { firebase } from "astrid-firebase";
import { withStore } from "../../helpers/context";

class DropboxApprove extends Component {
	componentDidMount() {
		const { store } = this.props;
		const qs = new URLSearchParams(window.location.search);
		const org = qs.get("state");
		const code = qs.get("code");

		if (org && code && store.state.profile.permissions.producer[org].includes("producerAdmin")) {
			this.completeAuth(org, code);
		}
	}

	completeAuth = (org, code) => {
		var dropbox = firebase.functions().httpsCallable("dropbox");

		dropbox({ task: "authenticate", org, code })
			.then((result) => {
				// go back to settings page
				this.props.history.push("/settings/" + org);
			})
			.catch((err) => {
				console.error(err);
				this.props.history.push("/settings/" + org);
			});
	};

	render() {
		return <div>Ansluter dropbox... Häng kvar.</div>;
	}
}

export default withStore(DropboxApprove);
