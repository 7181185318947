export default {
	name: "Bookbeat",
	format: "wav", // only available for wav exports
	connection: "ftp",
	// should metadata be generated and sent?
	meta: {
		format: "onix",
		fields: ["availability", "markets"], // extra metadata fields
		sharedFields: ["thema"], // extra shared metadata fields
		markets: { SE: "Sverige", FI: "Finland", UK: "Storbritannien", DE: "Tyskland" }, // values for the markets option above
	},
	// should audio exports be sent?
	audio: {
		// rename audio files
		rename: "ISBN_SEQ_TOTAL.SUFFIX",
	},
	cover: true, // defaults to ISBN.jpg alongside audio folder
	scrape: true, // has a scrape function
	disableArticles: ["cd", "mp3cd"],
};
