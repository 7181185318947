import randomHexColor from "random-hex-color";

import FirestoreDocument from "../../../types/FirestoreDocument";
import {
	array,
	boolean,
	email,
	hex,
	intMinMax,
	nullable,
	semVer,
	string,
	timestamp,
	uuid,
} from "../../../types/_types";

import { Devices } from "./Device";
import { Peer } from "./Peer";
import { StudioSettings } from "./StudioSettings";

export const Studio = FirestoreDocument.extend({
	id: nullable(uuid()),
	active: boolean(),
	address: string().default(""),
	color: hex().default(randomHexColor),
	devices: Devices.default({}),
	forceBeta: boolean(),
	gen: intMinMax(1, 2),
	groupTags: array(string()),
	hangup: boolean(),
	name: string(2),
	occupied: boolean(),
	online: nullable(timestamp()),
	peer: nullable(Peer),
	producerId: nullable(string()),
	restart: boolean(),
	settings: StudioSettings,
	uid: nullable(string()),
	user: nullable(email()),
	version: nullable(semVer()),
});

export const StudioRef = Studio.pick({
	id: true,
	ref: true,
	gen: true,
	name: true,
	address: true,
	version: true,
	color: true,
});
