import { useProfile } from "../../authentication/state/profile";

export default function useFeatureFlag(featureFlag) {
	const profile = useProfile();

	if (!featureFlag) {
		return true;
	}

	return profile?.featureFlags?.includes(featureFlag);
}
