import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Item, Label } from "semantic-ui-react";

import prodGenres from "astrid-config/src/genres/storytel";
import languages from "astrid-config/src/languages";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import { useUser } from "../../../features/authentication/state/user";
import useProductionTeam from "../../../features/productions/hooks/useProductionTeam";

export default function ProductionInformation({ production, permissions, checkMissing }) {
	const user = useUser();
	const navigate = useNavigate();
	const team = useProductionTeam(production);
	const readers = team.filter((member) => member.role === teamRoles.READER);

	const { t } = useTranslation();

	const isReader = production?.reader?.includes(user?.uid);

	const cover =
		(typeof production.isbn === "string" && production.img && production.imgThumb) ||
		(production.deliveryEbook?.img && production.deliveryEbook?.imgThumb) ||
		(production.deliveryPartsCover?.[0]?.img && production.deliveryPartsCover?.[0]?.imgThumb) ||
		"/img/image.png";

	return (
		<Item.Group className="production-information">
			<Item>
				{cover && <Item.Image src={cover} size="small" />}

				<Item.Content>
					{production.authorOptions && (
						<Item.Header>
							<div>
								{t("author")}: {production.authorOptions?.map((author) => author.text).join(", ")}
							</div>
						</Item.Header>
					)}

					<Item.Meta>
						{production.titleOriginal && (
							<p>
								{production.titleOriginal && `Originaltitel: ${production.titleOriginal}`}
								{production.translatorOptions && (
									<>
										<br />
										{t("translator")}:{" "}
										{production.translatorOptions?.map((translator) => translator.text).join(", ")}
									</>
								)}
							</p>
						)}
						{!!readers?.length && (
							<p>
								{t("narrator")}: {readers.map(({ user }) => user.name).join(", ")}
							</p>
						)}
						{production.isbn && (
							<>
								<span>{t("audiobookISBN", { ISBN: production.isbn })}</span>
								<br />
							</>
						)}
						{production.deliveryEbook && (
							<>
								<span>{t("eBookISBN", { ISBN: production.deliveryEbook.isbn })}</span>
								<br />
							</>
						)}
						{production.deliveryParts &&
							production.deliveryParts.length > 0 &&
							production.deliveryParts.map((part) => {
								return (
									<>
										<span>{t("audiobookPartISBN", { ISBN: part.isbn, title: part.title })}</span>
										<br />
									</>
								);
							})}
					</Item.Meta>

					<Item.Extra>
						{production.series && (
							<Label
								icon="stack overflow"
								content={production.series + (production.part ? ", del " + production.part : "")}
							/>
						)}
						{production.language && (
							<Label
								icon="globe"
								content={languages[production.language].sv || languages[production.language].name}
							/>
						)}
						{production.genre &&
							production.genre.map((g) => <Label key={g} icon="tag" content={prodGenres.sv[g]} />)}
					</Item.Extra>
					<Item.Description style={{ whiteSpace: "pre-wrap" }}>
						<p>{production.synopsis}</p>

						{(permissions?.isProducer?.includes?.("producerAdmin") ||
							production.manager?.includes(user?.uid) ||
							permissions?.isPublisher) && (
							<Button
								primary
								content={t("editInfo")}
								icon="pencil"
								labelPosition="left"
								floated="right"
								onClick={() => {
									navigate(`/production/${production.id}/info/edit`);
									checkMissing();
								}}
							/>
						)}
					</Item.Description>

					{isReader && (
						<Link to={`/production/${production.id}/audiobook`}>
							<Button
								primary
								content={t("toAudiobookProduction")}
								icon="right arrow"
								labelPosition="right"
							/>
						</Link>
					)}
				</Item.Content>
			</Item>
		</Item.Group>
	);
}
