import { Icon } from "semantic-ui-react";

export default function TableColumnSort({ column }) {
	return (
		<div
			{...column.getSortByToggleProps({
				style: { marginRight: column.canFilter ? 10 : 0 },
			})}
		>
			<Icon
				name={column.isSorted ? (column.isSortedDesc ? "sort up" : "sort down") : "sort"}
				style={{ color: !column.isSorted && "#ddd" }}
			/>
		</div>
	);
}
