import { useProfile } from "../../authentication/state/profile";
import { useOrganizationId } from "../../organizations/state/organizationId";

import DocumentsView from "../views/DocumentsView/DocumentsView";

export default function Documents() {
	const profile = useProfile();
	const orgId = useOrganizationId();

	return <DocumentsView profile={profile} orgId={orgId} />;
}
