import { Message } from "semantic-ui-react";

import useOfferMessage from "../hooks/useOfferMessage";

export default function OfferMessage({ offer }) {
	const message = useOfferMessage(offer);

	if (!message) {
		return null;
	}

	return (
		<Message color={message.color}>
			<p>
				{message.header && (
					<>
						<strong>{message.header}</strong>
						<br />
					</>
				)}

				{message.text}
			</p>
		</Message>
	);
}
