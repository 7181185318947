import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import NamedPrice from "../../../../prices/components/NamedPrice/NamedPrice";

import PriceDropdownMenu from "../components/PriceDropdownMenu";

export default function useApprovePricesColumns() {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				collapsing: true,
				accessor: ({ created }) => created?.toDate?.().toLocaleString(),
			},
			{
				id: "producer.name",
				Header: t("producer", "Producer"),
				collapsing: true,
			},
			{
				id: "type",
				Header: t("type", "Type"),
				collapsing: true,
				accessor: ({ type }) => costTypes[type],
			},
			{
				id: "price",
				Header: t("price", "Price"),
				collapsing: true,
				Cell: ({ row }) => <NamedPrice price={row.original} />,
			},
			{
				id: "languages",
				Header: t("language", "Language"),
				collapsing: false,
				accessor: ({ languages }) => languages.map(languageName).join(", "),
			},
			{
				id: "actions",
				textAlign: "right",
				collapsing: true,
				Cell: ({ row }) => <PriceDropdownMenu price={row.original} />,
			},
		],
		[costTypes, t],
	);
}
