import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useDistributionChannelPipelinesTableColumns from "../../hooks/useDistributionChannelPipelinesTableColumns";

export default function DistributionChannelPipelinesDataTable({ channel }) {
	const navigate = useNavigate();

	const data = Object.values(channel.pipelines || {});

	const columns = useDistributionChannelPipelinesTableColumns({ channel });

	return <DataTable filterable={false} data={data} columns={columns} onClick={({ id }) => navigate(id)} />;
}
