import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Card, Icon } from "semantic-ui-react";

export default function OrganizationInfo({ organization }) {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{organization.name}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Card.Description>
					<Link to={`/${organization.type}/${organization.id}/admin`} state={{ pathname }}>
						{t("manage", "Manage")} <Icon name="chevron right" />
					</Link>
				</Card.Description>
			</Card.Content>
		</Card>
	);
}
