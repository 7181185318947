import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, List } from "semantic-ui-react";

import useHasRight from "../../../../authorization/hooks/useHasRight";

import OnDeleteModal from "./OnDeleteModal";
import UploadDocumentModal from "./UploadDocumentModal";

export default function Document({ document }) {
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const hasRight = useHasRight();

	const onDelete = () => {
		setOpenDeleteModal(true);
	};

	return (
		<>
			<List.Item>
				{hasRight("documents.create") && (
					<Dropdown icon="ellipsis vertical" style={{ float: "right", paddingTop: 8 }} direction="left">
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => setOpenModal(true)}>{t("edit", "Edit")}</Dropdown.Item>
							<Dropdown.Item onClick={onDelete}>{t("delete", "Delete")}</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				)}
				{openDeleteModal && <OnDeleteModal document={document} setOpenDeleteModal={setOpenDeleteModal} />}
				<List.Icon name="file" size="large" verticalAlign="middle" />
				<List.Content>
					<List.Header>
						<a href={document.file.url} target="_blank" rel="noreferrer">
							{document.title}
						</a>
					</List.Header>
					<List.Description style={{ fontSize: 12 }}>
						{t("uploadedAtAndBy", "Uploaded {{date}} by {{name}}", {
							date: document.created.toDate().toLocaleDateString(),
							name: document.createdBy.name,
						})}
					</List.Description>
				</List.Content>
			</List.Item>
			{openModal && <UploadDocumentModal document={document} setOpenModal={setOpenModal} />}
		</>
	);
}
