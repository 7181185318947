import { useState } from "react";
import { Icon } from "semantic-ui-react";

import { deleteDocument } from "astrid-firestore/src/helpers";

export default function NotificationIcon({ todo }) {
	const { ref } = todo;

	const [hoverIcon, setHoverIcon] = useState(false);

	return (
		<div
			style={{
				position: "absolute",
				top: 3,
				right: 0,
				padding: 10,
				cursor: "pointer",
			}}
		>
			<Icon
				name={hoverIcon ? "times circle outline" : "circle outline"}
				onMouseEnter={() => setHoverIcon(true)}
				onMouseLeave={() => setHoverIcon(false)}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					deleteDocument(ref);
				}}
				style={{
					fontSize: 18,
				}}
			/>
		</div>
	);
}
