import { useMemo } from "react";

import mapDocument from "../utils/mapDocument";

import useCollection from "./useCollection";

export default function useCollectionData(query) {
	const [snapshot, loading, error] = useCollection(query);

	const value = useMemo(() => snapshot?.docs?.map(mapDocument), [snapshot]);

	return [value, loading, error];
}
