import { useEffect } from "react";

import { useProfile } from "../../authentication/state/profile";
import useOrganizationOnce from "../../organizations/hooks/useOrganizationOnce";
import { useOrganizationId } from "../../organizations/state/organizationId";

import { pushSessionData } from "../crisp";

export default function useCrisp() {
	const orgId = useOrganizationId();
	const profile = useProfile();
	const organization = useOrganizationOnce(orgId);

	useEffect(() => {
		if (profile) {
			pushSessionData("publisherPermissions", profile.permissions?.publisher);
			pushSessionData("readerPermissions", profile.permissions?.reader);
			pushSessionData("financePermissions", profile.permissions?.finance);
		}
	}, [profile]);

	useEffect(() => {
		if (profile && orgId) {
			pushSessionData("producerPermissions", profile?.permissions?.producer?.[orgId]);
		}
	}, [orgId, profile]);

	useEffect(() => {
		if (organization) {
			pushSessionData("selectedProducer", organization.name);
		}
	}, [organization]);
}
