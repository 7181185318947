import { AGREEMENT_STATE } from "astrid-functions/src/functions/scrive/agreement/constants";

import approveDocument from "../approval/approveDocument";

import offerStatuses from "./constants/offerStatuses";

export default async function approveOffer({ offer, user }) {
	return approveDocument(offer.ref, {
		user,
		status:
			offer?.agreement?.state === AGREEMENT_STATE.SIGNED ? offerStatuses.OFFERED : offerStatuses.PENDING_APPROVAL,
	});
}
