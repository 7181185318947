import { Table as SemanticTable } from "semantic-ui-react";

import Pagination from "../../Pagination/Pagination";

export default function TablePagination({ table }) {
	const {
		gotoPage,
		pageCount,
		state: { pageIndex },
	} = table;

	return (
		<SemanticTable.Footer fullWidth>
			<SemanticTable.Row>
				<SemanticTable.HeaderCell colSpan={99}>
					<Pagination
						activePage={pageIndex + 1}
						totalPages={pageCount}
						onPageChange={(e, { activePage }) => gotoPage(activePage - 1)}
					/>
				</SemanticTable.HeaderCell>
			</SemanticTable.Row>
		</SemanticTable.Footer>
	);
}
