import React, { useContext } from "react";
import { Button } from "semantic-ui-react";

import SessionContext from "../../SessionContext";

export default function CancelSession() {
	const { t, session, sessionRoles, setShowCancel } = useContext(SessionContext);

	return sessionRoles.isAdminOrProducerStaff && !(new Date() > session.end.toDate()) ? (
		<>
			<Button
				color="red"
				onClick={() => {
					setShowCancel(true);
				}}
			>
				{t("cancelBooking")}
			</Button>
		</>
	) : null;
}
