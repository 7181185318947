import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import isDenied from "astrid-firestore/src/api/approval/isDenied";
import isPendingApproval from "astrid-firestore/src/api/approval/isPendingApproval";

export default function ApprovePriceAction({ price }) {
	const { t } = useTranslation();

	if (isPendingApproval(price)) {
		return (
			<Popup
				size="small"
				position="top center"
				content={t("awaitingApproval", "Awaiting approval")}
				trigger={<Icon name="warning circle" />}
			/>
		);
	}

	if (isDenied(price)) {
		return (
			<Popup
				size="small"
				position="top center"
				content={t("costDenied", "Denied {{date}} by {{user}}", {
					date: price.approval.timestamp.toDate().toLocaleDateString(),
					user: price.approval.user.name,
				})}
				trigger={<Icon color="red" name="dont" />}
			/>
		);
	}

	return null;
}
