import { Table } from "semantic-ui-react";

function renderCell(column, cell) {
	if (column?.Cell) {
		return column.Cell(cell);
	} else if (column?.accessor) {
		// Needed since the non-controlled table makes a difference between Cell and accessor.
		// If switching between non-controlled and controlled with same column structure this breaks if it is not included.
		// For example, useMyProdctionColumns is shared between Ongoing and Completed productions.
		return column.accessor(cell);
	} else {
		return cell[column.id];
	}
}

export default function ControlledTable({ data, columns, hoverRow = false, onClickRow = () => {} }) {
	return (
		<div style={{ overflowX: "auto" }}>
			<Table selectable={hoverRow}>
				<Table.Header>
					<Table.Row>
						{columns.map(({ id, Header, CustomHeader }) => (
							<Table.HeaderCell
								style={
									CustomHeader?.onClick
										? { whiteSpace: "nowrap", cursor: "pointer" }
										: { whiteSpace: "nowrap" }
								}
								key={`${id}-header`}
								onClick={CustomHeader && CustomHeader.onClick}
							>
								{Header}
								{CustomHeader && CustomHeader.icon}
							</Table.HeaderCell>
						))}
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{data.map((row) => {
						return (
							<Table.Row key={row.id} onClick={() => onClickRow({ id: row.id })}>
								{columns.map((column) => {
									const cell = {
										productionId: row.id,
										...row,
									};
									return (
										<Table.Cell key={`${row.id}-${column.id}-cell`}>
											{renderCell(column, cell)}
										</Table.Cell>
									);
								})}
							</Table.Row>
						);
					})}
				</Table.Body>
				<Table.Footer fullWidth>
					<Table.Row>
						<Table.HeaderCell colSpan="99" />
					</Table.Row>
				</Table.Footer>
			</Table>
		</div>
	);
}
