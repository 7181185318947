import { useMemo } from "react";

import { currencyCodes, getCountryCurrencyCodes } from "astrid-helpers/src/currencies";

export const currencyOptions = currencyCodes.map((code) => ({
	key: code,
	text: code,
	value: code,
}));

function getOption(value) {
	return {
		value,
		key: value,
		text: value,
	};
}

export default function useCurrencyOptions({ country } = {}) {
	const countryCurrencyOptions = useMemo(() => country && getCountryCurrencyCodes(country).map(getOption), [country]);

	return countryCurrencyOptions || currencyOptions;
}
