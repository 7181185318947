import { useTranslation } from "react-i18next";

import AsyncButton from "../../../../ui/components/AsyncButton/AsyncButton";

export default function ArtifactFilesDataTableFooter({ artifact, selectedFlatRows }) {
	const { t } = useTranslation();

	const selectedArtifacts = selectedFlatRows.map(({ original }) => original);
	const disabled = selectedArtifacts.length === 0;

	const onDownload = async () => {
		// for (const artifact of selectedArtifacts) {
		// 	// Download artifact
		// }
	};

	return (
		<>
			<AsyncButton disabled={disabled} onClick={onDownload}>
				{t("download", "Download")}
			</AsyncButton>
		</>
	);
}
