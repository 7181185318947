import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Header, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import DocTitle from "astrid-web/src/components/DocTitle";

import MasterExport from "./MasterExport";
import MasterSettings from "./MasterSettings";

class MasterModal extends Component {
	state = {
		modifiedSettings: false,
		isUploading: false,
	};

	setModifiedSettings = (to) => {
		this.setState({ modifiedSettings: to });
	};

	getStorage = () =>
		firebase.storage().refFromURL(window.ES.stage ? "gs://stage-earselect-static" : "gs://earselect-static");

	render() {
		const { t, productionRights, production } = this.props;

		return (
			<Modal size="fullscreen" open={true} onClose={this.props.onClose} style={{ top: "2%" }}>
				<DocTitle title={production.title + t("finalMaster") + "–" + this.props.title} />

				<Modal.Header>
					<Header
						as="h4"
						icon="cloud upload"
						content={production.title + " " + t("finalMaster") + "–" + this.props.title}
					/>
				</Modal.Header>
				<Modal.Content scrolling>
					{production.status !== "done" && productionRights.includes("production.masterExportEdit") && (
						<MasterSettings
							{...this.props}
							modifiedSettings={this.state.modifiedSettings}
							setModifiedSettings={this.setModifiedSettings}
						/>
					)}

					<MasterExport
						{...this.props}
						modifiedSettings={this.state.modifiedSettings}
						getStorage={this.getStorage}
					/>
				</Modal.Content>
				<Modal.Actions>
					<Button color="green" onClick={this.props.onClose}>
						{t("ok")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

export default withTranslation()(MasterModal);
