import Flex from "../../../../../components/Flex/Flex";

export default function EngineerResourceTitle({ resource }) {
	const { engineer } = resource.extendedProps;

	if (!engineer) {
		return null;
	}

	return (
		<Flex style={{ justifyContent: "flex-start", display: "inline-flex", gap: 5 }}>
			<div>
				{engineer.firstName} {engineer.lastName}
			</div>
		</Flex>
	);
}
