import { useTranslation } from "react-i18next";
import { FormField } from "semantic-ui-react";

import FileUploadTable from "../../../ui/components/FileUploadTable/FileUploadTable";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";

import useArtifactForm from "../../hooks/useArtifactForm";

export default function CreateArtifactForm({ article, organization, defaultName, onClose }) {
	const { t } = useTranslation();

	const { form, setValue, onSubmit, error } = useArtifactForm({ article, organization, defaultName });

	return (
		<ModalPrompt size="tiny" header={t("uploadArtifact", "Upload artifact")} onSave={onSubmit} onClose={onClose}>
			<Form form={form}>
				<Form.Input name="name" label={t("artifactName", "Artifact name")} />

				<FormField>
					<label>{t("uploadFiles", "Upload files")}</label>

					<FileUploadTable onChange={(files) => setValue("targetFiles", files)} />
				</FormField>

				<Form.Checkbox name="isDefault" label={t("setAsDefault", "Set as default")} />
			</Form>

			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</ModalPrompt>
	);
}
