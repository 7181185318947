import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import DropdownItemApprove from "../../../../ui/components/DropdownMenu/DropdownItemApprove";
import DropdownItemDeny from "../../../../ui/components/DropdownMenu/DropdownItemDeny";
import DropdownItemSoftDelete from "../../../../ui/components/DropdownMenu/DropdownItemSoftDelete";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

export default function ProductionPriceDropdownMenu({ price }) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			{(context) => (
				<>
					<DropdownItemApprove data={price} {...context} />
					<DropdownItemDeny data={price} {...context} />

					<Dropdown.Item
						as={Link}
						to={`/admin/costs/${price.production.id}/${price.id}`}
						content={t("showMore", "Show more")}
					/>

					<DropdownItemSoftDelete data={price} {...context} />
				</>
			)}
		</DropdownMenu>
	);
}
