import useFormField from "../../hooks/useFormField";

export default function FormField({ children, hidden, ...props }) {
	const { field } = useFormField(props);

	if (hidden) {
		return null;
	}

	return <children.type {...children.props} {...field} ref={undefined} />;
}
