import { useTranslation } from "react-i18next";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

export default function AdminArticleBundleArticlesView({ bundle }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("articles", "Articles")}>
			<ArticlesCollectionDataTable
				getQuery={(query) => query.where("bundle.id", "==", bundle.id)}
				selectable={false}
				footer={false}
			/>
		</AdminCard>
	);
}
