import { Segment } from "semantic-ui-react";

import msToTime from "astrid-helpers/src/msToTime";

import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineSteps from "./PipelineSteps";

export default function PipelineStepContent({ step, progress }) {
	return (
		<Accordion.Content active>
			<div style={{ marginBottom: 10 }}>
				<Segment inverted style={{ marginBottom: 7 }}>
					<pre style={{ margin: 0 }}>
						Execution time: {progress?.executionTime ? msToTime(progress.executionTime) : "N/A"}
						<br />
						{JSON.stringify(progress?.files, null, 2)}
					</pre>
				</Segment>

				{step.steps && <PipelineSteps steps={step.steps} progress={progress?.progress} />}
			</div>
		</Accordion.Content>
	);
}
