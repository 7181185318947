import React from "react";

import DateInput from "../../../ui/components/DateInput/DateInput";

import useFormField from "../../hooks/useFormField";

export default function FormDateInput({ rules, ...props }) {
	const { field } = useFormField({
		rules: {
			valueAsDate: true,
			...rules,
		},
		...props,
	});

	return <DateInput {...field} value={field.value || ""} ref={undefined} />;
}
