import { getUserRef } from "../../utils/referenceMappers";

export default function createCommentData(firebase, data) {
	const { user, text, channels, replyTo = null } = data;

	const db = firebase.firestore();
	const ref = db.collection("comments").doc();

	return {
		id: ref.id,
		ref,
		text,
		user: getUserRef(user),
		created: firebase.firestore.FieldValue.serverTimestamp(),
		deleted: false,
		channels,
		replyTo,
	};
}
