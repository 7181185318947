import React from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button } from "semantic-ui-react";

import { db } from "astrid-firebase";
import { addDocument } from "astrid-firestore/src/helpers";

export default function DeliverFilesButton({ latestJob, isbn, text, file, color }) {
	const [{ loading }, onClick] = useAsyncFn(async () => {
		const job = {
			job: "deliverArchiveFilesToNfg",
			isbn: isbn,
			status: "queued",
			created: new Date(),
			diskSize: 60,
			nodeVersion: "18",
			args: {
				file,
			},
		};

		await addDocument(db.collection("tasks").doc("compute").collection("jobs"), job);
	}, [isbn, file, db]);

	const jobStatus = latestJob?.status;
	const disable = jobStatus === "queued" || jobStatus === "created" || jobStatus === "initiated";

	return (
		<Button disabled={disable} color={color} loading={loading} primary={!color} content={text} onClick={onClick} />
	);
}
