import { z } from "zod";

import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";

import { OrganizationRef } from "../../organizations/types/Organization";

export const TitleBundle = FirestoreDocument.extend({
	name: z.string(),

	publisher: OrganizationRef,
})
	.merge(FirestoreLifecycle)
	.strict();

export const TitleBundleRef = TitleBundle.extend({
	name: z.string(),
});
