import { getProfile } from "astrid-web/src/features/authentication/state/profile";

import { teamRoles } from "../../../constants/teamRoles";
import getCollectionData from "../../../helpers/getCollectionData";

import offerStatuses from "../../offers/constants/offerStatuses";
import createVoiceOffer from "../../offers/createVoiceOffer";
import { priceTypeFromRole } from "../../prices/utils/priceMappers";

function alreadyPresentVoice({ production, readerId }) {
	return !!Object.values(production?.voices || {}).find((voice) => voice.readerIds.includes(readerId));
}

async function createVoice({ firebase, reader, production }) {
	const { user, price } = reader;
	await createVoiceOffer(firebase, {
		user,
		price,
		production,
		voice: { id: 1 },
		status: offerStatuses.ACCEPTED,
		createdBy: getProfile(),
	});
	return `Successfully added narrator from team (${user.name})`;
}

export default async function createVoicesFromTeam(firebase, { production }) {
	const db = firebase.firestore();

	const readersToCreateVoicesFor = Object.values(production?.team || {}).filter(
		(teamMember) =>
			teamMember.role === teamRoles.READER &&
			teamMember.user?.ref &&
			teamMember.user?.id &&
			!alreadyPresentVoice({
				production,
				readerId: teamMember.user.id,
			}),
	);

	if (!readersToCreateVoicesFor.length) {
		return { noAction: "No eligible reader was found in the team. No action was necessary" };
	}

	return await Promise.allSettled(
		readersToCreateVoicesFor.map(async (reader) => {
			if (!reader?.price) {
				const events = await getCollectionData(
					db
						.collection("productions")
						.doc(production.id)
						.collection("events")
						.where(`data.readerStatus.${reader.user.id}`, ">", {}),
				);
				if (!events.length) {
					throw {
						customError: true,
						message: `Could not find historic prices for reader ${reader?.user?.name}`,
					};
				}
				const readerStatus = events
					.sort((a, b) => b?.time?.toDate() - a?.time?.toDate())
					.map((e) => {
						return e.data.readerStatus;
					})?.[0]?.[reader.user.id];

				if (!readerStatus.fee || !readerStatus.unit || !readerStatus.currency) {
					throw {
						customError: true,
						message: `Found historic price but with insufficient data for reader ${reader?.user?.name}`,
					};
				}

				return await createVoice({
					firebase,
					reader: {
						...reader,
						price: {
							price: readerStatus.fee,
							unit: readerStatus.unit,
							currency: readerStatus.currency,
							type: priceTypeFromRole.reader,
						},
					},
					production,
				});
			}
			return await createVoice({ firebase, reader, production });
		}),
	).then((result) => {
		return result.reduce(
			(acc, value) => {
				if (value.status === "fulfilled") {
					return {
						...acc,
						fulfilled: acc.fulfilled.concat(value.value),
					};
				}
				return {
					...acc,
					rejected: acc.rejected.concat(value.reason),
				};
			},
			{ fulfilled: [], rejected: [] },
		);
	});
}
