import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

const Pagination = (props) => {
	const { page, items, per, setPage, style, emptyElement } = props;
	const { t } = useTranslation();

	let end = (page + 1) * per;
	if (end > items) end = items;

	if (items > per)
		return (
			<Button.Group size="tiny" style={style}>
				<Button icon="left arrow" disabled={props.page === 0} onClick={() => setPage(page - 1)} />
				<Button
					content={t("pagination", { current: page + 1, total: Math.ceil(items / per) })}
					style={{ opacity: ".7", pointerEvents: "none" }}
				/>
				<Button icon="right arrow" disabled={end === items} onClick={() => setPage(page + 1)} />
			</Button.Group>
		);
	else return emptyElement ? <span /> : null;
};

export default Pagination;
