import { useRef } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";

import useBucket from "astrid-hooks/src/useBucket";

export default function useFileUpload({ bucketName, getDestination, onSuccess }) {
	const ref = useRef();
	const bucket = useBucket(bucketName);

	const [state, fn] = useAsyncFn(
		async (e) => {
			const file = e.target.files?.[0];

			const response = await bucket.putFile(getDestination(file), file);

			await onSuccess?.(response);
		},
		[bucket],
	);

	const input = (
		<input
			style={{ display: "none" }}
			ref={ref}
			type="file"
			onChange={async (e) => {
				await fn(e);
			}}
		/>
	);

	const uploadFile = () => {
		ref.current.click();
	};

	return [{ ...state, ref, input, uploadFile }, fn];
}
