import createFirestoreCollection from "../../firestore/createFirestoreCollection";
import { addDocument } from "../../helpers";

import { Studio } from "./types/Studio";

const studios = createFirestoreCollection("studios", Studio, {
	preProcess: async ({ api }, { data, before }) => {
		console.log({ data, before });
		// Check if a studio with the same ID already exists
		if (!before) {
			const studio = await api.studios.getById(data.ref.id);

			if (studio) {
				throw new Error("A studio with this ID already exists.");
			}
		}

		return data;
	},

	add: ({ api }, { id, gen, organization, name }) => {
		switch (gen) {
			default:
				throw new Error("Invalid generation");

			case 1:
				return addDocument(organization.ref.collection("studios"), { name });

			case 2:
				return api.studios.create({ id, gen, name, producerId: organization.id });
		}
	},
});

export default studios;
