import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import TeamPrice from "../../../team/components/TeamPrice/TeamPrice";

export default function OfferHeader({ offer, member }) {
	const { t } = useTranslation();

	return (
		<Header as="h4" style={{ margin: 0 }}>
			{offer.secondParty?.name || t("openPosition", "Open position")}
			<Header.Subheader>
				<TeamPrice
					role={offer.role}
					price={member ? member.price : offer.price}
					showCa={offer.status.includes(offerStatuses.INITIAL) && offer.price}
				/>
			</Header.Subheader>
		</Header>
	);
}
