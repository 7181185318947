const storytoolWav = {
	name: "Storytool API (WAV)",
	audio: {
		zip: true,
		rename: "ISBN_SEQ.SUFFIX",
	},
	connection: false,
	allowMissingISBN: true,
	disableArticles: ["cd", "mp3cd"],
};

export default storytoolWav;
