import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useHistory() {
	const location = useLocation();
	const navigate = useNavigate();

	return useMemo(
		() => ({
			push: navigate,
			location,
			replace: (to) => navigate(to, { replace: true }),
		}),
		[location, navigate],
	);
}
