import useForm from "../../../../forms/hooks/useForm";

export const sentOffersFormDefaultValues = {
	productions: [],
	secondParties: [],
	offerStatuses: [],
	offerTypes: [],
};

export default function useSentOffersForm() {
	return useForm({
		defaultValues: sentOffersFormDefaultValues,
	});
}
