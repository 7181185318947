import { useState } from "react";

export default function useLoadingFunction(initialFn, { onSuccess, onError } = {}) {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const fn =
		initialFn &&
		(async (...args) => {
			setError(null);
			setLoading(true);

			try {
				const result = await initialFn(...args);
				onSuccess?.(result);
			} catch (error) {
				console.error(error);
				setError(error);
				onError?.(error);
			}

			setLoading(false);
		});

	return [fn, loading, error];
}
