import React from "react";
import { useTranslation } from "react-i18next";
import { Image, Popup } from "semantic-ui-react";

import { languageName } from "astrid-helpers/src/languages";

export default function VoiceReaderLanguages({ languageSkills }) {
	const { t } = useTranslation(["common", "language"]);

	return (
		<div>
			{Object.entries(languageSkills)
				.sort(([, a], [, b]) => b.skill - a.skill)
				.map(([code, { skill }]) => (
					<Popup
						key={code}
						size="small"
						position="bottom right"
						content={
							<>
								<strong>{languageName(code)}</strong> {t("skillLevel" + skill)}
							</>
						}
						trigger={
							<Image
								avatar
								size="mini"
								src={`https://unpkg.com/language-icons/icons/${code}.svg`}
								style={{ marginLeft: 5, width: 25, height: 25 }}
							/>
						}
					/>
				))}
		</div>
	);
}
