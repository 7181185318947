import db from "astrid-firebase/src/db";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import { useProfile } from "../../../authentication/state/profile";
import { useOrganizationId } from "../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../organizations/state/organizationType";

export default function useOrganization() {
	useProfile();

	const organizationId = useOrganizationId();
	const organizationType = useOrganizationType();

	const [organization, loading, error] = useDocumentData(
		organizationId &&
			organizationType !== organizationTypes.NARRATOR &&
			db.collection("organizations").doc(organizationId),
	);

	const useOrganization =
		organizationType === organizationTypes.NARRATOR
			? {
					id: organizationId,
					type: organizationType,
			  }
			: organization;

	return [useOrganization, loading, error];
}
