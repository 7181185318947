import React from "react";

import Select from "../../../ui/components/Select/Select";

import useVatOptions from "../../hooks/useVatOptions";

export default function VatSelect(props) {
	const vatOptions = useVatOptions();

	return <Select search fluid options={vatOptions} {...props} />;
}
