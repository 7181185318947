import React from "react";
import { useTranslation } from "react-i18next";

import api from "../../../../../api";

import AdminMetaDataForm from "../../../components/AdminMetaDataForm/AdminMetaDataForm";

export default function AdminTitleMetaDataForm({ title }) {
	const { t } = useTranslation();

	return <AdminMetaDataForm header={t("metaData", "Meta data")} data={title} api={api.titles} />;
}
