import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";

const agreementStatusColors = {
	[agreementStatuses.PENDING_APPROVAL]: "grey",
	[agreementStatuses.APPROVAL_DENIED]: "red",
	[agreementStatuses.APPROVED]: "blue",
	[agreementStatuses.SIGNING]: "blue",
	[agreementStatuses.SIGNED]: "yellow",
	[agreementStatuses.SIGNING_FAILED]: "red",
	[agreementStatuses.SIGNING_EXPIRED]: "black",
	[agreementStatuses.SIGNING_CANCELLED]: "black",
	[agreementStatuses.ACTIVE]: "green",
	[agreementStatuses.EXPIRED]: "black",
	[agreementStatuses.TERMINATED]: "black",
};

export default agreementStatusColors;
