import moment from "moment";
import React from "react";

const jsonPretty = (data, nested = false) => {
	// build array of key value pairs
	const build = Object.keys(data).map((key, i) => {
		let value = data[key];

		if (key === "ref") {
			value = value.path;
		}

		// print booleans as text
		if (typeof value === "boolean") {
			value = value.toString();
		}

		// recursive prettification for objects and arrays
		else if (value && typeof value === "object" && (value.constructor === Array || value.constructor === Object)) {
			value = jsonPretty(value, true);
		}

		// format timestamps
		else if (value && value.toDate) {
			value = moment(value.toDate()).format("YYYY-MM-DD HH:mm");
		}

		return (
			<div key={i} style={nested ? { marginLeft: 20 } : null}>
				<b>{key}:</b> {value}
			</div>
		);
	});

	return build;
};

export default jsonPretty;
