import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Article } from "astrid-firestore/src/api/articles/types/Article";

import api from "../../../../api";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";
import useTitleOptions from "../../../titles/hooks/useTitleOptions";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

export default function CreateArticleForm({ organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchQuery, setSearchQuery] = useState("");

	const [titleOptions, loading] = useTitleOptions({ organization, searchQuery });
	const articleTypeOptions = useArticleTypeOptions();

	const { form, watch, setValue, onSubmit, error } = useSimpleForm({
		schema: Article.passthrough(),
		defaultValues: {
			titleId: null,
			name: "",
			isbn: null,
			type: null,
		},
		onSubmit: async ({ titleId, name, isbn, type }) => {
			return api.articles.create({
				titleId,
				name,
				isbn,
				type,
				publisher: organization,
			});
		},
		onSuccess: ({ id }) => navigate(id),
	});

	const titleId = watch("titleId");

	useEffect(() => {
		if (titleId) {
			const title = titleOptions.find((option) => option.value === titleId);

			setValue("name", title.text);
		}
	}, [setValue, titleId, titleOptions]);

	return (
		<ModalPrompt size="tiny" header={t("createArticle", "Create article")} onSave={onSubmit} onClose={onClose}>
			<Form form={form}>
				<Form.Select
					search
					name="titleId"
					label={t("titleName", "Title name")}
					options={[{ key: null, value: null, text: t("none", "None") }, ...titleOptions]}
					loading={loading}
					onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
				/>
				<Form.Input name="name" label={t("articleName", "Article name")} />
				<Form.Input name="isbn" label={t("isbn", "ISBN")} />
				<Form.Select name="type" label={t("type", "Type")} options={articleTypeOptions} />
			</Form>

			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</ModalPrompt>
	);
}
