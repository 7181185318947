import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminProductionDeliveriesView from "../views/AdminProductionDeliveriesView/AdminProductionDeliveriesView";

export default function AdminProductionDeliveries() {
	const hasRight = useHasRight();

	const { uncompletedTasks } = useOutletContext();

	const { deliveryTasks, loading, error } = uncompletedTasks;

	if (!hasRight("productions.admin")) {
		return <Unauthorized />;
	}

	return <AdminProductionDeliveriesView tasks={deliveryTasks} loading={loading} error={error} />;
}
