export default {
	name: "Clip source",
	format: "mp3",
	connection: "api",
	metaFirst: true,
	meta: {
		format: "onix",
		fields: ["availability", "takedown", "countriesIncluded"],
	},
	cover: {
		rename: "ISBN",
	},
	audio: {
		folder: false, // no folder
		rename: "ISBN_SEQ.SUFFIX",
	},
	ebook: true,
	disableArticles: ["cd", "mp3cd"],
	email: ["delivery"],
};
