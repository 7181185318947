import taskStatuses from "astrid-firestore/src/api/tasks/constants/taskStatuses";

const taskStatusColors = {
	[taskStatuses.CREATED]: "blue",
	[taskStatuses.INITIALIZED]: "yellow",
	[taskStatuses.COMPLETED]: "green",
	[taskStatuses.CANCELLED]: "black",
	[taskStatuses.IGNORED]: "grey",
	[taskStatuses.FAILED]: "red",
	[taskStatuses.RETRY]: "orange",
};

export default taskStatusColors;
