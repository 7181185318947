import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AgreementTemplatesActions from "../../../agreements/components/AgreementTemplatesActions/AgreementTemplatesActions";
import useAgreementTemplatesQuery from "../../../agreements/hooks/useAgreementTemplatesQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import useAdminAgreementTemplatesColumns from "./hooks/useAdminAgreementTemplatesColumns";

export default function AdminAgreementTemplatesView({ firstPartyId }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = useAgreementTemplatesQuery({ firstPartyId });
	const columns = useAdminAgreementTemplatesColumns();

	return (
		<>
			<AdminHeader>
				{t("agreementTemplates", "Agreement templates")}

				<AgreementTemplatesActions />
			</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</>
	);
}
