import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Message } from "semantic-ui-react";

import HomeFinance from "../../components/home/HomeFinance";
import HomeProducer from "../../components/home/HomeProducer";
import HomePublisher from "../../components/home/HomePublisher";
import { useProfile } from "../../features/authentication/state/profile";

export default function HomeOld() {
	const navigate = useNavigate();
	const profile = useProfile();
	const { t } = useTranslation();

	const isPublisher = profile?.permissions?.publisher && Object.keys(profile?.permissions.publisher).length;

	const isProducerOrReader =
		(profile?.permissions?.producer && Object.keys(profile?.permissions.producer).length) ||
		profile?.permissions?.reader;

	// redirect while finance has no useful home
	const isFinance = profile?.permissions?.finance;

	useEffect(() => {
		if (isFinance) navigate("/finance");
	}, [isFinance, navigate]);

	return (
		<>
			{!isPublisher && !isProducerOrReader && !isFinance && (
				<Message
					info
					icon="lock"
					header={t("youHaveNoPerimissions", "You have no permissions!")}
					content={t("contactAdminForHelp", "Contact an administrator for help.")}
				/>
			)}

			{isPublisher && !isProducerOrReader && <HomePublisher />}

			{isProducerOrReader && <HomeProducer />}

			{isFinance && <HomeFinance />}
		</>
	);
}
