import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstantSearch, useConfigure, useRefinementList } from "react-instantsearch";

import { db, firebase } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";

import Select from "../../../ui/components/Select/Select";

import { useAlgoliaSearchClient } from "../../../algolia/state/algoliaSearchClient";

function initialTags(production) {
	if (typeof production?.tags === "string") {
		const tagString = production.tags;
		const regex = tagString.length > 25 && !/[#,;]/.test(tagString) ? /\s+/ : /[,;#]+/;
		return production.tags
			?.split(regex)
			.map((s) => s.trim())
			.filter((v) => v);
	}
	return production?.tags || [];
}

function TagsDropDown({ production }) {
	const { t } = useTranslation();
	const [selectedTags, setSelectedTags] = useState(initialTags(production));
	const [addedOptions, setAddedOptions] = useState([]);
	const [options, setOptions] = useState([]);
	const [error, setError] = useState();

	useConfigure({ filters: `language:${production.language}` });

	const { items, searchForItems } = useRefinementList({
		attribute: "tags",
		limit: 10,
	});

	useEffect(() => {
		setOptions([
			...selectedTags.map((tag) => ({ key: tag, value: tag, text: tag })),
			...addedOptions,
			...items.map((i, index) => ({
				key: index,
				value: i.value,
				text: i.value,
				label: { circular: true, content: i.count },
			})),
		]);
	}, [items, addedOptions, selectedTags]);

	const isError = (value) => {
		const valuesArray = Array.isArray(value) ? value : [value];
		if (valuesArray?.some?.((v) => v.includes("#"))) {
			setError({ content: t("tagsErrorMessage", "Tags should not include #"), pointing: "below" });
			return true;
		}
		return false;
	};

	const handleAddition = (e, { value }) => {
		if (!isError(value)) {
			const newItem = { key: `addition-${value}`, value: value, text: value };
			setAddedOptions((prevOptions) => [...prevOptions, newItem]);
		}
		searchForItems("");
	};

	const handleChange = (e, { value }) => {
		if (!isError(value)) {
			setSelectedTags(value);
			updateDocument(db.collection("productions").doc(production.id), {
				tags: value,
				metaUpdated: firebase.firestore.FieldValue.serverTimestamp(),
			});
		}
		searchForItems("");
	};

	return (
		<Select
			multiple
			search
			onSearchChange={(e, { searchQuery }) => {
				if (error) {
					setError(undefined);
				}
				searchForItems(searchQuery);
			}}
			allowAdditions={true}
			onAddItem={handleAddition}
			onChange={handleChange}
			disabled={false}
			label={t("tags", "Tags")}
			value={selectedTags}
			options={options}
			error={error || !selectedTags?.length}
		/>
	);
}

export default function Tags({ production }) {
	const searchClient = useAlgoliaSearchClient();

	return (
		<InstantSearch searchClient={searchClient} indexName="productions">
			<TagsDropDown production={production} />
		</InstantSearch>
	);
}
