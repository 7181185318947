import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "semantic-ui-react";

import { deleteDocument } from "astrid-firestore/src/helpers";
import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import useSoftDelete from "../../../ui/hooks/useSoftDelete";

import useHasRight from "../../../authorization/hooks/useHasRight";

import AdminActionsContext from "./context/AdminActionsContext";

export default function DeletionAction({ right, disabled, hardDelete = false }) {
	const { t } = useTranslation();
	const { data } = useContext(AdminActionsContext);

	const hasRight = useHasRight();
	const softDelete = useSoftDelete();

	const onClickApprove = useCallback(() => {
		if (hardDelete) {
			return deleteDocument(data.ref);
		} else {
			return softDelete(data.ref);
		}
	}, [data.ref, hardDelete, softDelete]);

	const onClickUndo = useCallback(() => {
		updateDocument(data.ref, { deleted: false });
	}, [data.ref]);

	return (
		<Card.Content extra>
			{!data.deleted && hasRight(right) && (
				<Button color="black" disabled={disabled} onClick={onClickApprove}>
					{t("delete", "Delete")}
				</Button>
			)}

			{data.deleted && (
				<Card.Description>
					{data.deletedAt ? (
						<>
							{t("deletedByInfo", "Deleted by {{user}}, {{date}}", {
								user: data.deletedBy?.name,
								date: data.deletedAt?.toDate().toLocaleString(),
							})}

							{hasRight(right) && (
								<Button floated="right" size="mini" onClick={onClickUndo}>
									{t("undoAction", "Undo")}
								</Button>
							)}
						</>
					) : (
						<>{t("deleting", "Deleting")}...</>
					)}
				</Card.Description>
			)}
		</Card.Content>
	);
}
