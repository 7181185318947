import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import ArticlesInstantSearchDataTable from "../../../articles/components/ArticlesInstantSearchDataTable/ArticlesInstantSearchDataTable";
import CreateArticleButton from "../../../articles/components/CreateArticleForm/CreateArticleButton";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

export default function AdminArticlesView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("articles", "Articles")}>
				{searchInput}

				<CreateArticleButton organization={organization} />
			</AdminHeader>

			<ArticlesInstantSearchDataTable organization={organization} searchQuery={searchQuery} />
		</>
	);
}
