import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Label, Menu } from "semantic-ui-react";

export default function SecondaryMenu({ options }) {
	const { pathname } = useLocation();

	return (
		<Menu secondary>
			{options.map(({ name, path, label, active }) => (
				<Menu.Item as={Link} key={path} to={path} active={active || pathname.endsWith(path)}>
					{name}
					{label ? <Label color="blue">{label}</Label> : null}
				</Menu.Item>
			))}
		</Menu>
	);
}
