import firebase from "firebase/app";

import { Accordion, Button, Form, Icon, Message, Segment } from "semantic-ui-react";

import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import auth from "astrid-firebase/src/auth";
import db from "astrid-firebase/src/db";

export default function LoginForm() {
	const { provider } = useParams();
	const { t } = useTranslation();
	const [query] = useSearchParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

	const onLogin = useCallback(
		async (provider, email, password) => {
			setLoading(true);
			setError(null);
			setMessage(null);

			try {
				const { user } = await (provider === "email"
					? auth.signInWithEmailAndPassword(email, password)
					: auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()));

				await db.collection("users").doc(user.uid).update({
					loginMethod: provider,
				});

				navigate("/");
			} catch (error) {
				if (provider !== "email") {
					const email = error.email;

					if (error.code === "auth/account-exists-with-different-credential") {
						try {
							const providers = await firebase.auth().fetchSignInMethodsForEmail(email);

							setMessage(
								t(
									"accountExistsWithDifferentCredential",
									"An account with this email already exists. Please login with {{provider}}",
									{ provider: providers[0] },
								),
							);
						} catch (error) {
							setError(error.message);
						}
					} else {
						setError(error.message);
					}
				} else {
					setError(error.message);
				}
			}

			setLoading(false);
		},
		[navigate, t],
	);

	const onResetPassword = async (email) => {
		setError(null);
		setLoading(true);

		try {
			await auth.sendPasswordResetEmail(email, { url: "https://astrid.fm/login/email?email=" + email });
			setMessage(t("resetPasswordEmailSent", "Reset password email has been sent to {{email}}", { email }));
		} catch (error) {
			setError(error.message);
		}

		setLoading(false);
	};

	return (
		<Accordion>
			<Accordion.Title as={Link} to="/login/google" active={provider !== "email"} style={{ display: "block" }}>
				<Icon name="dropdown" />
				{t("loginGoogle")}
			</Accordion.Title>

			<Accordion.Content active={provider !== "email"}>
				<Segment
					padded={window.innerWidth > 600 ? "very" : null}
					loading={loading}
					style={{ textAlign: "left", overflow: "hidden" }}
				>
					<Button color="google plus" size="large" fluid onClick={() => onLogin("google")}>
						<Icon name="google" />
						{t("loginGoogleBtn")}
					</Button>
				</Segment>
			</Accordion.Content>

			<Accordion.Title as={Link} to="/login/email" active={provider !== "google"} style={{ display: "block" }}>
				<Icon name="dropdown" />
				{t("loginMailAndPsw")}
			</Accordion.Title>

			<Accordion.Content active={provider !== "google"}>
				<Segment
					padded={window.innerWidth > 600 ? "very" : null}
					loading={loading}
					style={{ textAlign: "left", overflow: "hidden" }}
				>
					{query.get("email") && (
						<Message color="green">
							{t("createdNewPasswordMessage", "You can now login with your new password")}
						</Message>
					)}

					{message && <Message>{message}</Message>}

					{error && <Message error>{error}</Message>}

					<Form
						size="large"
						onSubmit={(e, data) => {
							const email = e.target.querySelector("input[name=email]").value;
							const password = e.target.querySelector("input[name=password]").value;

							onLogin("email", email, password);
						}}
					>
						<Form.Input
							fluid
							icon="user"
							iconPosition="left"
							placeholder={t("placeHoldEmail")}
							defaultValue={
								// prefill email from welcome/reset mail?
								query.get("email") || ""
							}
							name="email"
							type="email"
							autoFocus={!window.location.search}
							autoComplete="email"
						/>

						<Form.Input
							fluid
							icon="lock"
							iconPosition="left"
							placeholder={t("placeHoldPassword")}
							name="password"
							type="password"
							autoFocus={!!window.location.search}
							autoComplete="current-password"
						/>

						<Button color="blue" floated="right">
							{t("loginBtn")}
						</Button>
					</Form>

					<Button basic onClick={() => onResetPassword(document.querySelector("input[name=email]").value)}>
						{t("forgotPswBtn")}
					</Button>
				</Segment>
			</Accordion.Content>
		</Accordion>
	);
}
