export default {
	name: "Nextory ebook",
	format: "wav",
	connection: "ftp",
	useSSH: "test-nextory-sftp-private-key",
	meta: {
		folder: "meta",
		format: "onix",
		plaintextSynopsis: true,
		fields: ["availability", "takedown"],
		sharedFields: ["tags", "bisac"],
	},
	audio: false,
	ebook: true,
	cover: true,
	disableArticles: ["cd", "mp3cd"],
};
