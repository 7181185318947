import useWindowSize from "react-use/lib/useWindowSize";

import ResourceHeaderButtons from "./ResourceHeaderButtons";
import ResourceHeaderDropdown from "./ResourceHeaderDropdown";

export default function ResourceHeader(props) {
	const { width } = useWindowSize();

	const tablet = width > 900;

	if (!tablet) {
		return <ResourceHeaderDropdown {...props} />;
	}

	return <ResourceHeaderButtons {...props} />;
}
