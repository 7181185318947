import anchorme from "anchorme";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Comment as SemanticComment } from "semantic-ui-react";

import ImageAvatar from "../../../ui/components/ImageAvatar/ImageAvatar";

import { useProfile } from "../../../authentication/state/profile";

import CommentDropdownMenu from "./components/CommentDropdownMenu";
import CommentForm from "./components/CommentForm";
import EditCommentForm from "./components/EditCommentForm";

export default function Comment({ sortedComments, onComment, comment, disabled }) {
	const { t } = useTranslation();
	const currentUser = useProfile();

	const replies = sortedComments.filter((reply) => reply.replyTo === comment.id);

	const { user, created, text } = comment;
	const showDropdown = comment.user.id === currentUser.id;

	const [editComment, setEditComment] = useState(false);
	const [reply, setReply] = useState(false);

	const onReply = async (text) => {
		setReply(false);
		await onComment({ text, replyTo: comment.replyTo || comment.id });
	};

	return (
		<SemanticComment>
			<ImageAvatar src={user.img} style={{ float: "left", borderRadius: "50%", width: 40 }} />
			<SemanticComment.Content>
				<SemanticComment.Author style={{ display: "inline" }}>{user.name}</SemanticComment.Author>
				<SemanticComment.Metadata>
					{moment(created?.toDate()).fromNow()}{" "}
					{comment.edited && <i>({t("edited", "Edited").toLowerCase()})</i>}
				</SemanticComment.Metadata>
				{showDropdown && !disabled && (
					<CommentDropdownMenu setEditComment={setEditComment} editComment={editComment} comment={comment} />
				)}
				<SemanticComment.Text>
					{!editComment ? (
						<div
							dangerouslySetInnerHTML={{
								__html: anchorme(text || "", {
									truncate: [26, 15],
									attributes: [
										function (urlObj) {
											if (urlObj.protocol !== "mailto:")
												return { name: "target", value: "blank" };
										},
									],
								}),
							}}
						/>
					) : (
						<EditCommentForm comment={comment} onClose={() => setEditComment(false)} />
					)}
				</SemanticComment.Text>

				{!editComment && !reply && !disabled && (
					<SemanticComment.Actions>
						<SemanticComment.Action onClick={() => setReply(true)}>
							{t("answer", "Reply")}
						</SemanticComment.Action>
					</SemanticComment.Actions>
				)}

				{reply && (
					<CommentForm
						confirmationButtonText={t("reply", "Reply")}
						onClickCancel={() => setReply(false)}
						onSave={onReply}
					/>
				)}
			</SemanticComment.Content>

			{replies.length > 0 && (
				<SemanticComment.Group>
					{replies.map((reply, index) => (
						<Comment
							key={index}
							disabled={disabled}
							sortedComments={sortedComments}
							onComment={onComment}
							comment={reply}
						/>
					))}
				</SemanticComment.Group>
			)}
		</SemanticComment>
	);
}
