import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";

import ProductionVoices from "../../components/ProductionVoices/ProductionVoices";

import ProductionViewContext from "../ProductionView/context/ProductionViewContext";

export default function ProductionReadersView({ production }) {
	const { t } = useTranslation();
	const { offers } = useContext(ProductionViewContext);

	return (
		<>
			<Header as="h4" content={t("voices", "Voices")} />

			<ProductionVoices production={production} offers={offers} />
		</>
	);
}
