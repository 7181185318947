import { useMemo } from "react";

import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";
import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";

import useAgreementAppendixesQuery from "./useAgreementAppendixesQuery";

export default function useAppendixesOptions({ type = agreementTypes.FRAMEWORK, part = "user", producerId }) {
	const appendixesQuery = useAgreementAppendixesQuery({ producerId });
	const [appendixes, loading, error] = useCollectionDataOnce(producerId && appendixesQuery);

	const options = useMemo(
		() =>
			appendixes
				?.filter((appendix) => appendix.agreementTypes.includes(type) && appendix.agreementParts.includes(part))
				.map((appendix) => ({ key: appendix.id, value: appendix.id, data: appendix, text: appendix.name }))
				.sort((a, b) => a.text.localeCompare(b.text)) || [],
		[appendixes, part, type],
	);

	return [options, loading, error];
}
