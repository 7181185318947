import { Table } from "semantic-ui-react";

export default function TableCell({ cell, ...props }) {
	return (
		<Table.Cell
			{...props}
			textAlign={cell.column.textAlign || "left"}
			verticalAlign={cell.column.verticalAlign || "middle"}
			collapsing={cell.column.collapsing}
		>
			{cell.render("Cell")}
		</Table.Cell>
	);
}
