import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import VendorSelect from "../../../../vendors/components/VendorSelect";

export default function TeamMemberFirstPartyVendorField({ firstParty }) {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();

	const [role, user, employee] = watch(["role", "user", "employee"]);

	const legalEntityArray = Object.keys(firstParty?.vendors || {});
	const defaultLegalEntity = legalEntityArray.length === 1 ? legalEntityArray[0] : null;

	if (!role || !user || !firstParty || employee) {
		return null;
	}

	return (
		<Form.Field name="firstPartyVendorId" defaultValue={defaultLegalEntity}>
			<VendorSelect label={t("legalEntity", "Legal entity")} vendors={firstParty?.vendors} />
		</Form.Field>
	);
}
