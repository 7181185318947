import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminArticleVersionHeader from "./components/AdminArticleVersionHeader";

export default function AdminArticleVersionView(props) {
	return (
		<AdminPageView {...props}>
			<AdminArticleVersionHeader />
		</AdminPageView>
	);
}
