import updateDocument from "../../helpers/updateDocument";

import createApprovalData from "./createApprovalData";

export default async function approveDocument(ref, { user, ...data }) {
	await updateDocument(ref, {
		approved: true,
		approval: createApprovalData(true, user),
		...data,
	});
}
