import equal from "fast-deep-equal";
import { useSearchParams } from "react-router-dom";
import useDeepCompareEffect from "react-use/lib/useDeepCompareEffect";

import getUrlState from "../utils/getUrlState";

export default function usePersist({ table, persist = false }) {
	const [, setSearchParams] = useSearchParams();

	const { state, initialState } = table;

	useDeepCompareEffect(() => {
		if (persist) {
			const isStateEqualToInitialState = equal(state, initialState);
			const isStateEqualToUrlState = equal(state, getUrlState());

			setSearchParams(
				isStateEqualToInitialState && !isStateEqualToUrlState ? {} : { state: JSON.stringify(state) },
				{ replace: true },
			);
		}
	}, [persist, state]);
}
