import { useCallback } from "react";
import { Icon, Input } from "semantic-ui-react";

import stopPropagation from "astrid-helpers/src/stopPropagation";

export default function InputFilter({ column: { filterValue, setFilter, canSort, Header } }) {
	const onChange = useCallback((e) => setFilter(e.target.value || undefined), [setFilter]);

	return (
		<Input fluid icon>
			<input
				placeholder={Header}
				value={filterValue || ""}
				onChange={onChange}
				onClick={stopPropagation}
				style={{ border: "none", minWidth: 100, padding: "1em 0 1em 1em" }}
			/>
			<Icon name="search" style={{ color: "#aaa", right: canSort && "-1em" }} />
		</Input>
	);
}
