import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import CreateAuthorForm from "./CreateAuthorForm";

export default function CreateAuthorButton({ organization }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button basic secondary onClick={() => setOpen(true)}>
				{t("createAuthor", "Create author")}
			</Button>

			{open && <CreateAuthorForm onClose={() => setOpen(false)} organization={organization} />}
		</>
	);
}
