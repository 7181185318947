import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import { useProfile } from "../../authentication/state/profile";
import useHasRole from "../../authorization/hooks/useHasRole";

import StorytoolArchiveView from "../views/StorytoolArchiveView/StorytoolArchiveView";

export default function StorytoolArchive() {
	const user = useProfile();
	const hasRole = useHasRole();
	const isSuperAdmin = hasRole("owner", "admin");

	if (!user?.permissions?.storytoolArchive?.enableArchiveAccess && !isSuperAdmin) {
		return <Unauthorized />;
	}

	return <StorytoolArchiveView user={user} isSuperAdmin={isSuperAdmin} />;
}
