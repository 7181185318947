import { useEffect, useState } from "react";

import Select from "../Select/Select";

import InstantSearchData from "./InstantSearchData";

function InstantSearchSelectHits({ data, getOption, nbHits, nbPages, setPage, setSearchQuery, ...props }) {
	const options = data.map(getOption);

	return <Select search persistOptions options={options} disabled={false} {...props} />;
}

export default function InstantSearchSelect({ configure, value, getOption, ...props }) {
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		setSearchQuery("");
	}, [value]);

	return (
		<InstantSearchData
			value={value}
			searchQuery={searchQuery}
			configure={{
				hitsPerPage: 10,
				...configure,
			}}
			{...props}
		>
			<InstantSearchSelectHits
				getOption={getOption}
				onSearchChange={(e, { searchQuery }) => setSearchQuery(searchQuery)}
			/>
		</InstantSearchData>
	);
}
