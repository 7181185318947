import firebase from "firebase/app";
import { useCallback } from "react";
import { Icon } from "semantic-ui-react";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

export default function RemoveAutoApprovePrice({ id, organization }) {
	const onClick = useCallback(
		() =>
			updateDocument(organization.ref.collection("settings").doc("autoApprovePrices"), {
				[id]: firebase.firestore.FieldValue.delete(),
			}),
		[id, organization.ref],
	);

	return <Icon name="trash alternate outline" style={{ cursor: "pointer" }} onClick={onClick} />;
}
