import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminAuthorHeader from "./components/AdminAuthorHeader";

export default function AdminAuthorView(props) {
	return (
		<AdminPageView {...props}>
			<AdminAuthorHeader />
		</AdminPageView>
	);
}
