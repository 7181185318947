import { useOutletContext } from "react-router-dom";

import CreateProductionLayout from "../layouts/CreateProductionLayout";
import CreateProductionPublisherView from "../views/CreateProductionPublisherView/CreateProductionPublisherView";

export default function CreateProductionPublisher() {
	const { organization } = useOutletContext();

	return (
		<CreateProductionLayout>
			<CreateProductionPublisherView organization={organization} />
		</CreateProductionLayout>
	);
}
