import { formatNamedPrice } from "./priceFormatters";

export function mapPriceOption(price) {
	return {
		key: price.id + (price.agreement?.id || ""),
		text: formatNamedPrice(price),
		data: price,
		value: price.id,
		description: price.agreement && price.agreement.name,
	};
}
