export default function shouldEnableDeleteProduction(productionStatus, isProducer, isPublisher) {
	const isProducerAdmin = isProducer?.includes?.("producerAdmin");
	const isProducerStaff = isProducer?.includes?.("producerStaff");
	const isPublisherAdmin = isPublisher?.includes?.("publisherAdmin");

	return !!(
		isProducerAdmin ||
		(isProducerStaff &&
			(productionStatus === "draft" || productionStatus === "offer" || productionStatus === "planning")) ||
		(isPublisherAdmin && productionStatus === "draft")
	);
}
