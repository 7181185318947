import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

export default function AdminArtifactArticlesView({ artifact }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("articles", "Articles")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<ArticlesCollectionDataTable
					sortable={false}
					filterable={false}
					getQuery={(query) =>
						query.where(firebase.firestore.FieldPath.documentId(), "==", artifact.article?.id)
					}
				/>
			</Card.Content>
		</Card>
	);
}
