import { Link } from "react-router-dom";
import { Card, Divider } from "semantic-ui-react";

import TodoAssignedTo from "astrid-web/src/features/todos/components/Todos/components/TodoAssignedTo";

import NotificationContent from "./NotificationContent";
import NotificationHeader from "./NotificationHeader";
import NotificationIcon from "./NotificationIcon";
import NotificationProduction from "./NotificationProduction";

export default function Notification({ todo }) {
	const { content, url, users, userNames } = todo;

	const showAssigned = users?.length > 0 || userNames?.length > 0;

	return (
		<Card as={Link} fluid style={{ borderRadius: 5, cursor: "pointer", color: "black" }} to={url}>
			<Card.Content>
				<NotificationHeader todo={todo} />

				{content && (
					<>
						<Divider style={{ margin: "10px 0" }} />
						<NotificationProduction todo={todo} />
						<NotificationContent todo={todo} />
					</>
				)}

				<NotificationIcon todo={todo} />
				{showAssigned && <TodoAssignedTo todo={todo} />}
			</Card.Content>
		</Card>
	);
}
