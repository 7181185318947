import { firebase } from "astrid-firebase";
import createProductionPriceData from "astrid-firestore/src/api/productions/prices/createProductionPriceData";

export default function getRevenue({ production, publisherPrice, ...data }) {
	const agreement = publisherPrice?.agreement || production.productionPrice?.agreement;
	const price = publisherPrice || data;

	return createProductionPriceData(firebase, {
		...price,
		expense: false,
		approved: true,
		agreement,
		production,
	});
}
