import { useState } from "react";

import Accordion from "../../../ui/components/Accordion/Accordion";

import Order from "./components/Order";

export default function OrdersAccordion({ orders }) {
	const [activeId, setActiveId] = useState();

	if (orders.length === 0) {
		return null;
	}

	return (
		<Accordion>
			{orders.map((order) => (
				<Order
					key={order.id}
					order={order}
					active={order.id === activeId}
					onClick={() => setActiveId((activeId) => (activeId !== order.id ? order.id : null))}
				/>
			))}
		</Accordion>
	);
}
