import React from "react";
import { Form } from "semantic-ui-react";

import useFormField from "../../hooks/useFormField";

export default function FormInput(props) {
	const { field } = useFormField(props);

	return <Form.Input {...field} value={field.value || ""} ref={undefined} />;
}
