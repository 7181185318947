import { useTranslation } from "react-i18next";

import todoTypes from "astrid-firestore/src/constants/todoTypes";

export default function useTodoHeaderTranslations() {
	const { t } = useTranslation();

	return {
		[todoTypes.SCRIPT_MISSING]: t("scriptMissing", "Script date is about to expire"),
		[todoTypes.LAST_COMMENT_PUBLISHER]: t("lastCommentPublisher", "Comment from publisher"),
		[todoTypes.PRODUCTION_STATUS_IS_OFFER]: t("productionOffer", "Production request"),
		[todoTypes.MISSING_SESSIONS]: t("missingSession", "Missing recording session"),
		[todoTypes.MISSING_POLISH_SESSION]: t("missingPolish", "Missing corrections"),
		[todoTypes.DELIVERY_DATE_TODAY]: t("deliveryDateTodayOrPassed", "Delivery date is today/passed"),
		[todoTypes.SESSION_MISSING_RECORDER]: t("sessionMissingRecorder", "Missing engineer"),
		[todoTypes.MISSING_PROOFER]: t("missingProofer", "Missing prooflistener"),
		[todoTypes.MISSING_EDITOR]: t("missingEditor", "Missing editor"),
		[todoTypes.ADMIN_ATTENTION]: t("adminAttention", "Admin notified"),
		[todoTypes.FILES_UPLOADED]: t("filesUploaded", "Files were uploaded"),
		[todoTypes.READER_INQUIRY_DRAFT]: t("draftInquiryCreated", "Draft inquiry created"),
		[todoTypes.PRODUCTION_DATE_MISSING]: t("productionDateMissing", "Production date is missing"),
		[todoTypes.DELIVERY_DATE_MISSING]: t("deliveryDateMissing", "Delivery date is missing"),
	};
}
