import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminApproveAgreementsView from "../views/SuperAdminApproveAgreementsView/SuperAdminApproveAgreementsView";

export default function SuperAdminApproveAgreements() {
	const hasRight = useHasRight();
	const { needsApproval } = useOutletContext();

	if (!hasRight("agreements.admin")) {
		return <Unauthorized />;
	}

	const { agreements, loading, error } = needsApproval;

	return <SuperAdminApproveAgreementsView agreements={agreements} loading={loading} error={error} />;
}
