import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useArtifactsTableColumns from "../../hooks/useArtifactsTableColumns";

export default function ArtifactsInstantSearchDataTable({ article, organization, ...props }) {
	const navigate = useNavigate();

	const columns = useArtifactsTableColumns({ article });

	return (
		<InstantSearchDataTable
			indexName="artifacts"
			columns={columns}
			configure={{
				filters: organization && `publisher.id:${organization.id}`,
			}}
			onClick={({ id, article, publisher }) =>
				navigate(`/publisher/${publisher.id}/admin/articles/${article.id}/artifacts/${id}`)
			}
			{...props}
		/>
	);
}
