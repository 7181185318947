import cloneDeep from "lodash/cloneDeep";
import lodashGet from "lodash/get";
import isEqual from "lodash/isEqual";
import lodashOmit from "lodash/omit";
import lodashSet from "lodash/set";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import useValidation from "./useValidation";

export default function useForm({ validators, showErrors = false, initialData = {}, submitHandler } = {}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const [internalInitialData, setInitialData] = useState(initialData);
	const [internalShowErrors, setShowErrors] = useState(showErrors);

	const [data, setData] = useState(cloneDeep(initialData));
	const [valid, errors, validation] = useValidation(data, validators);

	const get = useCallback((name) => lodashGet(data, name), [data]);
	const set = useCallback(
		(name, value) =>
			setData((data) =>
				lodashSet({ ...data }, name, typeof value === "function" ? value(lodashGet(data, name)) : value),
			),
		[],
	);
	const reset = useCallback((values = {}) => setData(values), []);
	const omit = useCallback((...names) => setData((data) => lodashOmit({ ...data }, names)), []);

	const field = useCallback(
		(name, valueField = "value") => ({
			name,
			[valueField]: get(name),
			key: name,
			error: (internalShowErrors || showErrors) && errors[name],
			onChange: (e, data) =>
				set(
					data.name,
					data[valueField] === undefined
						? lodashGet(internalInitialData, data.name)
						: ["range", "number"].includes(data.type)
						? data[valueField]
							? +data[valueField]
							: undefined
						: data[valueField],
				),
		}),
		[errors, get, internalInitialData, internalShowErrors, set, showErrors],
	);

	const modified = useMemo(() => !isEqual(data, internalInitialData), [data, internalInitialData]);

	const handleSubmit = (handler = submitHandler, clone = true) => {
		return async () => {
			setError();
			setLoading(true);

			if (valid) {
				try {
					const result = await handler(data);

					if (clone) {
						setInitialData(cloneDeep(data));
					}

					return result;
				} catch (error) {
					setError(error);
					console.error(error);
				}
			} else {
				setShowErrors(true);
			}

			setLoading(false);
		};
	};

	return {
		error,
		loading,
		get,
		set,
		reset,
		omit,
		data,
		field,
		valid,
		errors,
		validation,
		modified,
		initialData,
		setInitialData,
		handleSubmit,
		submitHandler,
	};
}

export function useFormOmitKeys({ form, key, omitKeys = [] }) {
	const omitKeysRef = useRef(omitKeys);

	const { omit } = form;
	const value = form.data[key];

	useEffect(() => {
		if (value) {
			return () => omit(...omitKeysRef.current);
		}
	}, [omit, value]);
}
