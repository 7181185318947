import React from "react";
import { Form } from "semantic-ui-react";

const ProductionAuthorContactEmail = ({ people, production, productionId }) => {
	const peopleWithEmail = Object.entries(people).filter(([id, data]) => data.email);

	return (
		!!peopleWithEmail?.length && (
			<Form as="div">
				<p>
					<b>Skicka epost med länk till uttalslista?</b>
				</p>
				{peopleWithEmail.map(([id, person]) => {
					const lang =
						person.type === "author" && production.languageOriginal
							? production.languageOriginal
							: production.language;

					const url =
						window.location.protocol +
						"//" +
						window.location.host +
						"/words/" +
						productionId +
						"?s&a=" +
						lang;

					return (
						<a
							href={"mailto:" + person.email + "?subject=&body=" + encodeURIComponent(url)}
							key={id}
							style={{ display: "inline-block", marginBottom: "1em" }}
						>
							<Form.Button
								content={`${person.firstName} ${person.lastName} (${
									person.type === "author" ? "författare" : "översättare"
								})`}
								color="teal"
								size="tiny"
							/>
						</a>
					);
				})}
			</Form>
		)
	);
};

export default ProductionAuthorContactEmail;
