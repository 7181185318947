import InputFilter from "../Filters/InputFilter";
import SelectFilter from "../Filters/SelectFilter";

const filterOptions = {
	undefined: {
		disableFilters: true,
	},
	text: {
		Filter: InputFilter,
		filter: "text",
	},
	select: {
		Filter: SelectFilter,
		filter: "exact",
		disableSortBy: true,
	},
};

export default filterOptions;
