import { useTranslation } from "react-i18next";

import { timestampColumn } from "../../ui/utils/columns";

export default function useArticleBundlesTableColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			filter: "text",
			disableSortBy: true,
			Header: t("Name", "Name"),
			Cell: ({ value, row }) => {
				return `${value} (${row.original.articles.length})`;
			},
		},
		{
			id: "isbns",
			filter: "text",
			disableSortBy: true,
			Header: t("isbn", "ISBN"),
			accessor: ({ isbns }) => isbns.join(", "),
		},
		timestampColumn({
			id: "created",
			Header: t("created", "Created"),
			time: false,
		}),
	];
}
