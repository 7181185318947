import firebase from "firebase/app";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import removeTeamMember from "astrid-firestore/src/api/productions/team/removeTeamMember";

export default function useRemoveTeamMemberCallback() {
	const { t } = useTranslation();

	return useCallback(
		async ({ member, production }) => {
			try {
				return await removeTeamMember(db, firebase.firestore, { member, production });
			} catch (error) {
				if (error.message === "Member has open cost") {
					throw new Error(
						t(
							"deleteMemberMessage",
							"The member you are removing has an open cost. You can remove this member after either finalizing or deleting the cost.",
						),
					);
				}

				if (error.message === "Member has booked sessions") {
					throw new Error(
						t(
							"memberHasBookedSessions",
							"The member you are removing has booked sessions. You can remove this member after either completing or cancelling the sessions.",
						),
					);
				}
			}
		},
		[t],
	);
}
