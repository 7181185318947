import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import ArticleVersionsCollectionDataTable from "../../../articles/components/ArticleVersionsCollectionDataTable/ArticleVersionsCollectionDataTable";

export default function AdminArticleVersionsView({ article }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("versions", "Versions")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<ArticleVersionsCollectionDataTable article={article} />
			</Card.Content>
		</Card>
	);
}
