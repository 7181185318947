import { useMemo } from "react";

import useUserRoles from "./useUserRoles";

export default function useUserRoleOptions() {
	const roles = useUserRoles();

	return useMemo(
		() =>
			Object.entries(roles).map(([key, text]) => ({
				key,
				text,
				value: key,
			})),
		[roles],
	);
}
