import { useEffect } from "react";

import Form from "../../../../forms/components/Form/Form";
import useUserOptions from "../../../../users/hooks/useUserOptions";

export default function SigneeSelect({ party }) {
	const { setValue, resetField } = Form.useFormContext();

	const [userOptions, loading, error] = useUserOptions({ publisher: party.id, role: "publisherAdmin" });

	useEffect(() => {
		return () => resetField(`parties.${party.rowId}.signee`);
	}, [party.rowId, resetField]);

	return (
		<Form.Select
			name={`parties.${party.rowId}.signeeId`}
			options={userOptions}
			loading={loading}
			error={error}
			shouldUnregister={false}
			onChangeOption={(option) => setValue(`parties.${party.rowId}.signee`, option?.data)}
		/>
	);
}
