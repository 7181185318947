import { useMemo } from "react";

import useAgreementParts from "./useAgreementParts";

export default function useAgreementPartOptions() {
	const agreementParts = useAgreementParts();

	return useMemo(
		() =>
			Object.entries(agreementParts).map(([value, text]) => ({
				key: value,
				value,
				text,
			})),
		[agreementParts],
	);
}
