import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useArticleBundlesTableColumns from "../../hooks/useArticleBundlesTableColumns";

export default function ArticleBundlesCollectionDataTable({ getQuery = (query) => query, ...props }) {
	const navigate = useNavigate();

	const columns = useArticleBundlesTableColumns();

	return (
		<CollectionDataTable
			query={getQuery(db.collection("articleBundles").where("deleted", "==", false))}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/bundles/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			{...props}
		/>
	);
}
