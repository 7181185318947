import equal from "fast-deep-equal";

import createFirestoreCollection from "../../firestore/createFirestoreCollection";
import { MetaData } from "../../types/MetaData";
import arrayUnion from "../../utils/arrayUnion";

import { Title } from "./types/Title";

const titles = createFirestoreCollection("titles", Title, {
	preProcess: async ({ firebase, api }, { transaction, data, before }) => {
		// Check if a title with the same name already exists
		if (before && data.name) {
			const [existingTitle] = await api.titles.getAll((ref) =>
				ref
					.where(firebase.firestore.FieldPath.documentId(), "!=", data.ref.id)
					.where("name", "==", data.name)
					.where("publisher.id", "==", before.publisher.id)
					.where("deleted", "==", false)
					.limit(1),
			);

			if (existingTitle) {
				throw new Error("A title with this name already exists.");
			}
		}

		// If we have a series and it doesn't exist, create it
		if (data.serie && !data.serie.exists) {
			data.serie = await api.series.create(data.serie, { transaction });
		}

		// If we have an imprint and it doesn't exist, create it
		if (data.imprint && !data.imprint.exists) {
			data.imprint = await api.imprints.create(data.imprint, { transaction });
		}

		// If we add an author and it doesn't exist, create it
		if (data.authors) {
			data.authors = await Promise.all(
				data.authors.map((author) => {
					if (!author.exists) {
						return api.authors.create(author, { transaction });
					}

					if (before.authors?.every((a) => a.id !== author.id)) {
						return api.authors.update(
							{
								ref: author.ref,
								publisherIds: arrayUnion(firebase, before.publisher.id),
							},
							{ before: author, transaction, validate: false },
						);
					}

					return author;
				}),
			);

			data.author = data.authors.map((author) => author.name).join(", ");
			data.authorIds = data.authors.map((author) => author.id);
		}

		// If we add a translator and it doesn't exist, create it
		if (data.translators) {
			data.translators = await Promise.all(
				data.translators.map((translator) => {
					if (!translator.exists) {
						return api.translators.create(translator, { transaction });
					}

					if (before.translators?.every((a) => a.id !== translator.id)) {
						return api.translators.update(
							{
								ref: translator.ref,
								publisherIds: arrayUnion(firebase, before.publisher.id),
							},
							{ before: translator, transaction, validate: false },
						);
					}

					return translator;
				}),
			);

			data.translator = data.translators.map((translator) => translator.name).join(", ");
			data.translatorIds = data.translators.map((translator) => translator.id);
		}

		return data;
	},

	sideEffects: async ({ api, firebase }, { transaction, after, before }) => {
		if (!after.exists) {
			return;
		}

		if (before) {
			const metaDataUpdated = !equal(MetaData.parse(after), MetaData.parse(before));

			if (metaDataUpdated) {
				// Get all articles with sync enabled
				const articles = await api.titles.getArticles(after);

				// Update all articles with the new title data
				if (articles.length > 0) {
					await api.articles.updateAll(articles, {
						transaction,
						getData: (article) => ({
							title: after,
							sync: article.sync,
						}),
					});
				}
			}
		}
	},

	addArticles: async ({ api }, { title, articles = [], sync = false }) => {
		return await api.articles.updateAll(articles, {
			getData: () => ({
				title,
				sync,
			}),
		});
	},

	getArticles: async ({ api }, title) => {
		return await api.articles.getAll((query) => query.where("title.id", "==", title.id));
	},
});

export default titles;
