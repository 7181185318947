import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

export default function ReaderFilterButton({ active, value, onChange }) {
	const { t } = useTranslation();

	return (
		<Button basic animated="vertical" primary={active} onClick={(e) => onChange(e, { value: !value })}>
			<Button.Content hidden>{t("filter")}</Button.Content>
			<Button.Content visible>
				<Icon name="sliders" />
			</Button.Content>
		</Button>
	);
}
