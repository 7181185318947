import uniqid from "uniqid";

import { getAgreementRef, getOfferRef, getPartyRef, getUserRef } from "../../../utils/referenceMappers";

export const MISSING_PARAMS = "CreateTeamMember: Missing required params!";

export default function createTeamMemberData(
	firebase,
	{ role, offer, user, agreement, production, firstParty, member },
) {
	if (!user || (!offer && (!role || !production))) {
		throw MISSING_PARAMS;
	}

	return {
		id: member?.id || uniqid(),
		role: offer?.role || role,
		user: getUserRef(user, {
			contactDetails: user?.readerData?.contactDetails,
		}),
		offer: member?.offer || getOfferRef(offer),
		price: member?.price || null,
		employee: user?.producers?.[(offer?.production || production).producer]?.employee || false,
		agreement: getAgreementRef(offer?.agreement || agreement),
		createdAt: member?.createdAt || firebase.firestore.FieldValue.serverTimestamp(),
		updatedAt: member ? firebase.firestore.FieldValue.serverTimestamp() : null,
		firstParty: getPartyRef(firstParty),
	};
}
