import firebase from "firebase/app";
import "firebase/auth";

import { isLive } from "astrid-helpers/src/env";

import configs from "./config";

let config;

if (isLive) {
	// LIVE
	config = configs.live;
} else {
	// STAGE or LOCALHOST
	config = configs.stage;
}

export { config };

if (typeof window !== "undefined") {
	window.hasFirebase = true;
}

export default firebase.initializeApp(config);
