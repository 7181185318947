import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import ViewTypeButton from "./ViewTypeButton";

export default function HeaderToolbarNavigation({ navigation, viewType, disableTools }) {
	const { t } = useTranslation();

	return (
		<div>
			<Button size="tiny" onClick={navigation.onTodayClick}>
				{t("today", "Today")}
			</Button>

			<Button size="tiny" icon onClick={navigation.onLeftNavigationClick}>
				<Icon name="chevron left" />
			</Button>

			<Button style={{ marginRight: 20 }} size="tiny" icon onClick={navigation.onRightNavigationClick}>
				<Icon name="chevron right" />
			</Button>

			<ViewTypeButton viewPeriod={"Day"} navigation={navigation} viewType={viewType} disableTools={disableTools}>
				{t("day", "Day")}
			</ViewTypeButton>

			<ViewTypeButton viewPeriod={"Week"} navigation={navigation} viewType={viewType} disableTools={disableTools}>
				{t("week", "Week")}
			</ViewTypeButton>

			<Button
				size="tiny"
				onClick={() => navigation.changeView("resourceTimelineMonth")}
				primary={viewType === "resourceTimelineMonth"}
			>
				{t("month", "Month")}
			</Button>
		</div>
	);
}
