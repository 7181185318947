import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import api from "../../../../../api";

export default function useAdminSerieMenuOptions({ serie }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [titlesCount, titlesCountLoading] = api.titles.useCount({ serie });
	const [articlesCount, articlesCountLoading] = api.articles.useCount({ serie });

	return [
		{
			to: "",
			active: pathname.endsWith(serie.id),
			text: t("titles", "Titles"),
			label: titlesCount,
			loading: titlesCountLoading,
		},
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles"),
			label: articlesCount,
			loading: articlesCountLoading,
		},
	];
}
