import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import AdaptiveTextArea from "../../../../ui/components/AdaptiveTextArea/AdaptiveTextArea";

export default function CommentForm({ comment, onClickCancel, onSave, confirmationButtonText }) {
	const { t } = useTranslation();

	const [text, setText] = useState(comment?.text || "");
	const [loading, setLoading] = useState(false);

	return (
		<>
			<Form as="div">
				<AdaptiveTextArea
					fluid
					value={text}
					className="disableClear"
					onChange={(e) => setText(e.target.value)}
					style={{ padding: 7 }}
				/>
				<Flex style={{ justifyContent: "flex-end", marginTop: -10, gap: 2 }}>
					<Button size="mini" onClick={onClickCancel}>
						{t("cancel", "Cancel")}
					</Button>
					<Button
						disabled={!text}
						loading={loading}
						style={{ marginRight: 0 }}
						primary
						size="mini"
						onClick={async () => {
							setLoading(true);
							await onSave(text);
							setLoading(false);
						}}
					>
						{confirmationButtonText || t("save", "Save")}
					</Button>
				</Flex>
			</Form>
		</>
	);
}
