import React from "react";
import { hasRight } from "astrid-firebase";
import { withStore } from "../helpers/context";

const Right = (props) => {
	let { can, not, all, children, store, backupstore } = props;
	let verified = false;

	// use backup store for modals (rendered outside context provider)
	if (!store) store = backupstore;

	// put single value in array
	if (!Array.isArray(can)) can = [can];

	if (all) {
		// check if user has ALL rights (can NOT find a right that user does NOT have)
		verified = !can.find((right) => !hasRight(store, right));
	} else {
		// check if user has ANY right
		verified = can.find((right) => hasRight(store, right));
	}

	// reverse it?
	if (verified && not) verified = !verified;

	return verified ? <>{children}</> : null;
};

export default withStore(Right);
