import useForm from "../../../../forms/hooks/useForm";

export default function useUserForm({ reader = false, firstName = "", lastName = "", email = "" } = {}) {
	return useForm({
		defaultValues: {
			userId: "new",
			firstName,
			lastName,
			email,
			reader,
		},
	});
}
