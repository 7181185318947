import React from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Label, Loader, Message, Segment } from "semantic-ui-react";

import hasNotification from "astrid-firestore/src/api/notifications/hasNotification";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerHasNotification from "astrid-firestore/src/api/offers/offerHasNotification";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import useDocTitle from "../../ui/hooks/useDocTitle";
import TabMenuLayout from "../../ui/layouts/TabMenuLayout/TabMenuLayout";

import { useProfile } from "../../authentication/state/profile";
import useHasRight from "../../authorization/hooks/useHasRight";
import useOffersQuery from "../../offers/hooks/useOffersQuery";

import useMyOffersQuery from "../hooks/useMyOffersQuery";
import useOpenPositionsQuery from "../hooks/useOpenPositionsQuery";
import useProductionRequests from "../hooks/useProductionRequests";
import filterOffers from "../utils/filterOffers";
import mapProductionRequests from "../utils/mapProductionRequest";
import sortOffers from "../utils/sortOffers";

const awaitingAnswerStatuses = [offerStatuses.OFFERED, offerStatuses.SIGNING];

function countOffers({ filteredOffers, userId }) {
	return filteredOffers?.filter(
		(offer) =>
			(awaitingAnswerStatuses.includes(offer.status) && !offer.declinedUserIds?.includes?.(userId)) ||
			offerHasNotification(offer, userId),
	).length;
}

export default function MyProductions({ type }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	useDocTitle(t("myProductions"));

	const { organization } = useOutletContext() || {};
	const profile = useProfile();
	const orgId = organization?.id;

	const [offers = [], loadingOffers, errorOffers] = useCollectionData(
		useMyOffersQuery({ userId: profile?.id, type, orgId }),
	);

	const [sentOffers = []] = useCollectionData(useOffersQuery({ createdBy: profile }));

	const sentOffersWithNotification = sentOffers?.filter((offer) => hasNotification(offer, profile?.id)).length;

	const [openPositions = [], loadingOpenPositions, errorOpenPositions] = useCollectionData(
		useOpenPositionsQuery({
			type,
			orgId,
			user: profile,
		}),
	);
	const [productionRequests, loadingProductionRequests, errorProductionRequests] = useProductionRequests({
		orgId,
	});

	const filteredOffers = sortOffers(filterOffers({ offers: [...offers, ...openPositions], user: profile }));

	const mappedProductionRequests = mapProductionRequests({ productionRequests, orgId, profile });

	const errors = errorOffers || errorOpenPositions || errorProductionRequests;
	const loading = (loadingOffers || loadingOpenPositions || loadingProductionRequests) && !errors;

	if (errors) {
		return (
			<Segment padded="very">
				<Message error>{t("errorMessageHeader")} </Message>
			</Segment>
		);
	}

	const offerCount = countOffers({ filteredOffers, userId: profile.id });

	const panes = [
		{
			text: t("current"),
			path: "current",
		},
		{
			text: t("completed"),
			path: "completed",
		},
		{
			text: t("myProductionsOffer"),
			label: loading ? (
				<Loader style={{ marginLeft: "1em" }} active inline size="tiny" />
			) : offerCount ? (
				<Label
					style={{
						position: "absolute",
						top: "-1em",
						left: "100%",
						margin: "0 0 0 -2.5em",
					}}
					size="tiny"
					circular
					color="blue"
				>
					{offerCount}
				</Label>
			) : undefined,
			path: "offers",
		},
		{
			text: t("sentOffers", "Sent offers"),
			path: "sent",
			hidden: !hasRight("offers.send"),
			label: sentOffersWithNotification > 0 && (
				<Label
					style={{
						position: "absolute",
						top: "-1em",
						left: "100%",
						margin: "0 0 0 -2.5em",
					}}
					size="tiny"
					circular
					color="blue"
				>
					{sentOffersWithNotification}
				</Label>
			),
		},
		{
			text: t("myProductionsRequests"),
			label: loading ? (
				<Loader style={{ marginLeft: "1em" }} active inline size="tiny" />
			) : (
				<Label
					style={{
						position: "absolute",
						top: "-1em",
						left: "100%",
						margin: "0 0 0 -2.5em",
					}}
					size="tiny"
					circular
					color="blue"
				>
					{mappedProductionRequests?.length}
				</Label>
			),
			hidden: type !== organizationTypes.PRODUCER || !hasRight("myProductions.productionRequests"),
			path: "requests",
		},
	];

	return (
		<TabMenuLayout
			context={{
				organization,
				filteredOffers,
				mappedProductionRequests,
				loading,
			}}
			panes={panes}
		/>
	);
}
