const sortOrderProductionStatus = {
	offer: 0,
	planning: 1,
	accepted: 2,
	production: 3,
	done: 4,
	draft: 5,
};

export function sortProductionStatus(items) {
	return items.sort((a, b) => {
		const aSort = sortOrderProductionStatus[a?.label];
		const bSort = sortOrderProductionStatus[b?.label];
		if (aSort !== undefined) {
			return aSort > bSort ? 1 : -1;
		}
		return 1;
	});
}
