import zipcelx from "zipcelx";

import sheetMaker from "./sheetMaker";

export default async function generateXLXS(statistics) {
	const { columns, rows } = sheetMaker(statistics);

	const config = {
		filename: "production-statistics",
		sheet: {
			data: [[...columns.map((col) => ({ value: col, type: "string" }))], ...rows],
		},
	};
	return await zipcelx(config);
}
