import get from "lodash/get";
//import { languages, languageSkill } from "../../helpers/lists";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Image, Label, Popup } from "semantic-ui-react";

import prodGenres from "astrid-config/src/genres/storytel";
import { base, db, firebase, hasRight } from "astrid-firebase";

import { localStorage } from "../../helpers/fnc";

class ReaderCard extends Component {
	state = {
		presLimit: 95,
	};

	toggle = () => {
		const { parent, reader, pins, productionId, onChange } = this.props;

		if (productionId) {
			// store in production data
			base.updateDoc(
				"productions/" + productionId,
				pins.includes(reader.id)
					? {
							reader: firebase.firestore.FieldValue.arrayRemove(reader.id),
							readerSuggestion: firebase.firestore.FieldValue.arrayRemove(reader.id),
					  }
					: {
							readerSuggestion: firebase.firestore.FieldValue.arrayUnion(reader.id),
					  },
			).then(() => {
				onChange({
					type: pins.includes(reader.id) ? "removed" : "added",
					user: {
						ref: db.collection("users").doc(reader.id),
						...reader,
					},
				});
			});
		} else {
			// store in state and local storage
			const newPins = [...pins];

			if (newPins.includes(reader.id)) {
				const pi = newPins.indexOf(reader.id);
				newPins.splice(pi, 1);
			} else {
				newPins.push(reader.id);
			}

			parent.setState({ pins: newPins });
			localStorage("readers", "pins", newPins);
		}
	};

	render() {
		const { t, parent, store, reader, pins, discounts, preview, productionId, alreadyAdded, status } = this.props;

		let motherLang, extraLang;
		if (get(reader, "readerData.language")) {
			motherLang = Object.keys(reader.readerData.language).filter((code) => reader.readerData.language[code] > 4);
			extraLang = Object.keys(reader.readerData.language).filter((code) => reader.readerData.language[code] < 5);
		}

		// format reader fee spans
		let fee = reader?.readerData?.cost;
		let currency = reader?.readerData?.currency || "N/A";

		return (
			<Card
				key={reader.id}
				style={reader.readerData && reader.readerData.searchable ? null : { background: "#f3f3f3" }}
				className={`reader-card ${pins?.includes(reader.id) ? "selected" : ""} ${alreadyAdded ? "added" : ""} ${
					pins?.includes(reader.id) && status
				}`}
			>
				<Card.Content>
					<Image
						floated="right"
						size="tiny"
						src={reader.img || "/img/avatar.png"}
						style={{ cursor: "pointer", borderRadius: 3, marginBottom: 0 }}
						onClick={alreadyAdded ? null : this.toggle}
					/>

					<Card.Header
						style={{ cursor: "pointer", marginBottom: 5 }}
						onClick={alreadyAdded ? null : this.toggle}
					>
						{!preview && (
							<Popup
								inverted
								size="mini"
								trigger={
									<Icon
										name={!pins.includes(reader.id) ? "check circle outline" : "check circle"}
										color={alreadyAdded ? "green" : !pins.includes(reader.id) ? "grey" : "violet"}
									/>
								}
								content={
									t("markAsSuggestion") + (productionId ? t("savesInProd") : t("savesInYourProfile"))
								}
							/>
						)}
						{reader.firstName + " " + reader.lastName}
						{/* TODO: change to better perm */}
						{store && hasRight(store, "createProducerStaff") && (
							<Link
								to={"/profile/" + reader.id}
								style={{ color: "inherit", marginLeft: 5, verticalAlign: "text-bottom" }}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<Icon name="user outline" size="small" />
							</Link>
						)}
					</Card.Header>
					{reader.magicHits && !!reader.magicHits.length && (
						<p className="magic-hits">
							{reader.magicHits.map((hit) => (
								<Label key={hit} size="tiny" as="span" color="orange">
									{hit}
								</Label>
							))}
						</p>
					)}
					<Card.Meta style={{ color: "black" }}>
						{discounts && discounts[reader.id] && (
							<div style={{ marginBottom: 5 }}>
								<Icon name="star" color="green" />
								<b>
									{discounts[reader.id].unit === "minus"
										? discounts[reader.id].amount * 60
										: discounts[reader.id].amount}
									{discounts[reader.id].unit === "minus"
										? t("feeHour", { currency })
										: t("percentage")}{" "}
									{t("discount")?.toLowerCase()}
								</b>{" "}
								{t("onProdCost")}
							</div>
						)}
						{get(reader, "readerData.sex") ? t(reader.readerData.sex) + " - " : t("Other") + " "}
						{get(reader, "readerData.birthday") &&
							moment().diff(
								preview
									? new Date(reader.readerData.birthday._seconds * 1000)
									: reader.readerData.birthday.toDate(),
								"years",
							) +
								" " +
								t("years")}
						{fee && (
							<div>
								{fee} {t("feePerHour", { currency }) + "."}
							</div>
						)}
						{get(reader, "readerData.voice") && (
							<div>
								{t("voiceLevel")}{" "}
								{
									[t("veryDark"), t("dark"), t("neutral"), t("light"), t("veryLight")][
										reader.readerData.voice - 1
									]
								}
							</div>
						)}
					</Card.Meta>
					<Card.Description />
				</Card.Content>
				{(motherLang || extraLang) && (
					<Card.Content>
						<div style={{ color: "black" }}>
							{motherLang && (
								<>
									{motherLang.map((code) => (
										<div key={code} style={{ marginTop: 3 }}>
											<strong>{t("language:" + code)}: </strong>
											{/* <strong>{t(code)}: </strong> */}
											{reader.readerData.languageDialect &&
												reader.readerData.languageDialect[code] &&
												"(" + reader.readerData.languageDialect[code] + ") "}
											{/* {languageSkill[reader.readerData.language[code] - 1]} */}
											{t("skillLevel" + reader.readerData.language[code])}
										</div>
									))}
								</>
							)}

							{!!motherLang.length && !!extraLang.length && (
								<div style={{ borderTop: "1px solid #eee", marginTop: 2 }} />
							)}

							{extraLang &&
								extraLang
									.sort((a, b) =>
										reader.readerData.language[a] < reader.readerData.language[b] ? 1 : -1,
									)
									.slice(0, this.state.showAllExtra ? 100 : 2)
									.map((code, i) => (
										<div key={code} style={{ marginTop: 3 }}>
											<strong>{t("language:" + code)}: </strong>
											{reader.readerData.languageDialect &&
												reader.readerData.languageDialect[code] &&
												"(" + reader.readerData.languageDialect[code] + ") "}
											{/* {languageSkill[reader.readerData.language[code] - 1]} */}
											{t("skillLevel" + reader.readerData.language[code])}
											{extraLang.length > 2 && i === 1 && !this.state.showAllExtra && (
												<Label
													className="show-more"
													size="tiny"
													onClick={(e) => {
														this.setState({ showAllExtra: true });
													}}
												>
													{t("showAllLang")}
												</Label>
											)}
										</div>
									))}
						</div>
					</Card.Content>
				)}

				{reader.readerData && reader.readerData.samples && !!Object.keys(reader.readerData.samples).length && (
					<Card.Content>
						<Button.Group size="mini">
							{Object.keys(reader.readerData.samples)
								.slice(0, 5)
								.sort((a, b) =>
									reader.readerData.samples[a].genre > reader.readerData.samples[b].genre ? 1 : -1,
								)
								.map((sampleId, i) => {
									const sample = reader.readerData.samples[sampleId];

									return (
										<Popup
											key={sampleId}
											inverted
											size="mini"
											content={sample.genre ? t(sample.genre) : sample.title}
											trigger={
												<Button
													size="mini"
													icon={sample.file !== parent.state.playingFile ? "play" : "stop"}
													style={{
														marginBottom: 2,
														opacity: sample.file !== parent.state.playingFile ? 1 : 0.6,
														background: !sample.genre
															? "#ddd"
															: `hsl(${
																	Object.keys(prodGenres.sv).findIndex(
																		(k) => k === sample.genre,
																	) *
																		(360 / 21) +
																	-50
															  } 80% 60% / 1)`,
													}}
													content={i + 1}
													onClick={(e) => {
														e.preventDefault();
														if (sample.file !== parent.state.playingFile) {
															parent.setState({ playingFile: sample.file });
														} else {
															document.querySelector(".sticky-audio .remove").click();
														}
													}}
												/>
											}
										/>
									);
								})}
						</Button.Group>
					</Card.Content>
				)}

				<Card.Content extra>
					{get(reader, "readerData.presentation") && (
						<p
							style={{
								fontStyle: "italic",
								fontSize: ".9em",
								whiteSpace: "pre-wrap",
								marginBottom: 10,
							}}
						>
							{reader.readerData.presentation.length > this.state.presLimit && !this.state.showAllPres
								? reader.readerData.presentation.substr(0, this.state.presLimit) + "[…]"
								: reader.readerData.presentation}

							{reader.readerData.presentation.length > this.state.presLimit &&
								!this.state.showAllPres && (
									<Label
										as="span"
										className="show-more"
										size="tiny"
										onClick={(e) => {
											this.setState({ showAllPres: true });
										}}
									>
										{t("showMore") + "..."}
									</Label>
								)}
						</p>
					)}

					{get(reader, "readerData.contactDetails") && (
						<div style={{ marginTop: 10 }}>
							{reader.phone && (
								<div>
									<Icon name="phone" /> {formatPhoneNumberIntl(reader.phone)}
								</div>
							)}
							{reader.email && (
								<div>
									<Icon name="mail" /> <a href={"mailto:" + reader.email}>{reader.email}</a>
								</div>
							)}
						</div>
					)}
				</Card.Content>
			</Card>
		);
	}
}

export default withTranslation(["common", "language"])(ReaderCard);
