export function mapAgreementOption(agreement) {
	return {
		key: agreement.id,
		text: agreement.name,
		data: agreement,
		value: agreement.id,
		description: `${agreement.secondParty.vendor.name} / ${agreement.firstParty.vendor.name}`,
	};
}

export function mapAgreementPrices(agreement) {
	return Object.entries(agreement?.prices || {}).map(([id, price]) => ({ ...price, id, agreement }));
}
