import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import AgreementTemplateForm from "../../agreements/components/AgreementTemplateForm/AgreementTemplateForm";
import useHasRight from "../../authorization/hooks/useHasRight";

import AdminHeader from "../components/AdminHeader/AdminHeader";

export default function CreateAgreementTemplate() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const navigate = useNavigate();
	const hasRight = useHasRight();

	if (!hasRight("agreements.create")) {
		return false;
	}

	return (
		<>
			<AdminHeader>{t("createAgreementTemplate", "Create agreement template")}</AdminHeader>

			<AgreementTemplateForm
				organization={organization}
				afterSubmit={(ref) => navigate(`../templates/${ref.id}`)}
			/>
		</>
	);
}
