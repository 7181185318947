import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";

export default function useOfferTypeTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[offerTypes.WORK_ORDER]: t("workOrder", "Work order"),
			[offerTypes.OPEN]: t("openPosition", "Open position"),
			[offerTypes.INQUIRY]: t("inquiry", "Inquiry"),
		}),
		[t],
	);
}
