import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Input, Modal } from "semantic-ui-react";

import { CodeList } from "../../../components/production/ThemaField";

import useThemaCodes from "../hooks/useThemaCodes";

export default function ThemaModal({ value = [], onClose }) {
	const { t, i18n } = useTranslation();
	const codes = useThemaCodes();

	const [search, setSearch] = useState("");

	const [themaCodes, setThemaCodes] = useState(value);

	const toggleCode = (code) => {
		setThemaCodes((selectedCodes) => {
			return selectedCodes.includes(code) ? selectedCodes.filter((c) => c !== code) : [...selectedCodes, code];
		});
	};

	const onSave = () => {
		onClose({ themaCodes });
	};

	return (
		<Modal open size="small" onClose={onSave} closeOnDimmerClick>
			<Modal.Header>{t("themaCodes", "Thema codes")}</Modal.Header>

			<Modal.Content scrolling className="thema-modal">
				<Input
					placeholder={t("searchForCodeOrDescription", "Search for code or description")}
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					icon="search"
					fluid
					style={{ marginBottom: 20 }}
				/>

				{codes && <CodeList codes={codes} search={search} selected={themaCodes} toggleCode={toggleCode} />}
			</Modal.Content>

			<Modal.Actions>
				<Button floated="left" as={Link} target="_blank" to={`https://ns.editeur.org/thema/${i18n.language}`}>
					{t("aboutThema", "About Thema")}
				</Button>

				<Button onClick={onSave}>{t("add", "Add")}</Button>
			</Modal.Actions>
		</Modal>
	);
}
