import { useMemo } from "react";

import { useCollectionDataOnce } from "astrid-firestore/src/hooks";

import useStudioOptions from "../../studios/hooks/useStudioOptions";

import useOpenRecorderPositionsQuery from "./useOpenRecorderPositionsQuery";
import useReaderOptions from "./useReaderOptions";
import useRecorderOptions from "./useRecorderOptions";
import useRecorderPriceOptions from "./useRecorderPriceOptions";

export default function useSessionOptions({ production, recorderId }) {
	const readerOptions = useReaderOptions({ production });

	const [openRecorderPositions, loadingOpenRecorderPositions, errorOpenRecorderPositions] = useCollectionDataOnce(
		useOpenRecorderPositionsQuery({ productionId: production?.id }),
	);

	const recorderOptions = useRecorderOptions({
		production,
		openPositionExists: openRecorderPositions?.length,
	});

	const recorderPriceOptions = useRecorderPriceOptions({ production, openRecorderPositions, recorderId });

	const [studioOptions, loadingStudioOptions, errorStudioOptions] = useStudioOptions({
		organizationId: production?.producer,
	});

	return useMemo(
		() => [
			{
				readerOptions,
				recorderOptions,
				recorderPriceOptions,
				studioOptions,
			},
			loadingOpenRecorderPositions || loadingStudioOptions,
			errorOpenRecorderPositions || errorStudioOptions,
		],
		[
			readerOptions,
			recorderOptions,
			recorderPriceOptions,
			studioOptions,
			loadingOpenRecorderPositions,
			loadingStudioOptions,
			errorOpenRecorderPositions,
			errorStudioOptions,
		],
	);
}
