import { useTranslation } from "react-i18next";

export default function useActionTitle(type) {
	const { t } = useTranslation();

	const types = {
		producer: t("addProducer", "Add producer"),
		publisher: t("addPublisher", "Add publisher"),
	};

	return types[type];
}
