import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";

export default function useAgreementTypes() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[agreementTypes.STORAGE]: t("agreementTypeStorage", "Storage agreement"),
			[agreementTypes.FRAMEWORK]: t("agreementTypeFramework", "Framework agreement"),
			[agreementTypes.PRODUCTION]: t("agreementTypeProduction", "Production agreement"),
		}),
		[t],
	);
}
