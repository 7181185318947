import firebase from "firebase/app";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Modal } from "semantic-ui-react";

import { deleteProduction } from "../../../../helpers/production";

import shouldEnableDeleteProduction from "./shouldEnableDeleteProduction";

export default function DeleteProduction({ productionId, productionStatus, isProducer, isPublisher }) {
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();
	const enabled = shouldEnableDeleteProduction(productionStatus, isProducer, isPublisher);
	return (
		<Modal
			trigger={
				<Icon
					name="trash"
					size="large"
					color={enabled ? "red" : "grey"}
					style={{ cursor: enabled ? "pointer" : "not-allowed" }}
				/>
			}
			onClose={() => setIsOpen(false)}
			onOpen={() => {
				if (enabled) {
					setIsOpen(true);
				}
			}}
			open={isOpen}
		>
			<Modal.Header>{t("deleteProductionHeader", "Delete production")}</Modal.Header>
			<Modal.Content>
				<p>{t("deleteProduction", "Are you sure you wish to delete this production?")}</p>
			</Modal.Content>
			<Modal.Actions>
				<Button color="grey" onClick={() => setIsOpen(false)}>
					<Icon name="remove" />
					{t("cancel", "Cancel")}
				</Button>
				<Button
					color="green"
					onClick={() => {
						deleteProduction(productionId, firebase.auth().currentUser.uid);
						setIsOpen(false);
					}}
				>
					<Icon name="checkmark" />
					{t("yes", "Yes")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
