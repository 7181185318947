import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AutoApprovalRules from "./components/AutoApprovalRules";
import OrganizationActions from "./components/OrganizationActions";
import OrganizationInfo from "./components/OrganizationInfo";
import ProducerDistributors from "./components/ProducerDistributors";
import ProducerPublishers from "./components/ProducerPublishers";
import ProducerSettings from "./components/ProducerSettings";

export default function SuperAdminOrganizationView({ id }) {
	const [organization, loading, error] = useDocumentData(id && db.collection("organizations").doc(id));

	return (
		<LoadingContext loading={loading} error={error} data={organization}>
			{organization && (
				<Card.Group>
					<OrganizationInfo organization={organization} />
					{organization.type === "producer" && (
						<>
							<ProducerSettings producer={organization} />
							<ProducerPublishers producer={organization} />
							<ProducerDistributors producer={organization} />
							<AutoApprovalRules organization={organization} />
						</>
					)}
					{organization.type === "publisher" && <OrganizationActions organization={organization} />}
				</Card.Group>
			)}
		</LoadingContext>
	);
}
