import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import Table from "../../../../ui/components/Table/Table";

import useAdminUserPricesColumns from "../hooks/useAdminUserPricesColumns";

import AdminUserPricesActions from "./AdminUserPricesActions";

export default function AdminUserPrices({ user, organization }) {
	const { t } = useTranslation();

	const data = useMemo(() => Object.values(user.prices?.[organization.id] || {}), [organization.id, user.prices]);

	const columns = useAdminUserPricesColumns({ user, organization });

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("defaultPricing", "Default pricing")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Table data={data} columns={columns} footer={false} />
			</Card.Content>
			<Card.Content extra>
				<AdminUserPricesActions user={user} organization={organization} />
			</Card.Content>
		</Card>
	);
}
