import { useEffect } from "react";

import storage from "astrid-firebase/src/storage";

import useLoadingValue from "./useLoadingValue";

export default function useDownloadURL({ bucket, fullPath }) {
	const { value, loading, error, reset, setValue, setError } = useLoadingValue();

	useEffect(() => {
		if (bucket && fullPath) {
			storage.refFromURL(`gs://${bucket}/${fullPath}`).getDownloadURL().then(setValue).catch(setError);

			return reset;
		}
	}, [bucket, fullPath]);

	return [value, loading, error];
}
