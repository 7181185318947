import { useTranslation } from "react-i18next";

import api from "../../../../../api";

export default function useAdminArtifactDropdownMenuOptions({ artifact }) {
	const { t } = useTranslation();

	return [
		{
			text: t("delete", "Delete"),
			onClick: () => {
				return api.artifacts.softDelete(artifact);
			},
		},
	];
}
