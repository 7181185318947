import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import NotFound from "../../../components/NotFound";
import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import OldStartPageLink from "../components/OldStartPageLink";
import HomeNarratorView from "../views/HomeNarratorView";
import HomeProducerView from "../views/HomeProducerView";
import HomePublisherView from "../views/HomePublisherView";

import useOrganization from "./hooks/useOrganization";

function SwitchHomeView({ organization }) {
	switch (organization?.type) {
		case organizationTypes.PRODUCER:
			return <HomeProducerView organization={organization} />;
		case organizationTypes.PUBLISHER:
			return <HomePublisherView organization={organization} />;
		case organizationTypes.NARRATOR:
			return <HomeNarratorView organization={organization} />;
		default:
			return <NotFound />;
	}
}

export default function Home() {
	const [organization, loading, error] = useOrganization();

	return (
		<LoadingContext data={organization} loading={loading || !organization} error={error}>
			<OldStartPageLink />
			<SwitchHomeView organization={organization} />
		</LoadingContext>
	);
}
