import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { Serie } from "./types/Serie";

const series = createFirestoreCollection("series", Serie, {
	preProcess: async ({ api, firebase }, { data }) => {
		// Check if a series with the same name already exists
		if (data.name) {
			const series = await api.series.getAll((ref) =>
				ref
					.where(firebase.firestore.FieldPath.documentId(), "!=", data.id)
					.where("name", "==", data.name)
					.where("deleted", "==", false)
					.limit(1),
			);

			if (series.length > 0) {
				throw new Error("A series with this name already exists.");
			}
		}

		return data;
	},
});

export default series;
