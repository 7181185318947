import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { enumValues, nullable } from "../../../types/_types";

import { Article } from "../../articles/types/Article";
import { Artifact } from "../../artifacts/types/Artifact";
import { OrganizationRef } from "../../organizations/types/Organization";

import distributionStatuses from "../constants/distributionStatuses";

import { DistributionChannelRef } from "./DistributionChannel";
import { DistributionPipeline } from "./DistributionPipeline";

export const distributionStatus = enumValues(distributionStatuses).default(distributionStatuses.PENDING);

export const Distribution = FirestoreDocument.extend({
	status: distributionStatus,
	article: nullable(Article),
	artifact: nullable(Artifact),
	channel: DistributionChannelRef,
	publisher: OrganizationRef,
	pipeline: DistributionPipeline,
}).merge(FirestoreLifecycle);
