function hasRolesForProducer({ producer, permissions, roles }) {
	return roles.some((role) => permissions.producer?.[producer]?.includes?.(role));
}
export default function getCalendarSessionRoles({ session, profile }) {
	return {
		isAdminOrProducerStaff: hasRolesForProducer({
			producer: session.producer,
			permissions: profile.permissions,
			roles: ["producerAdmin", "producerStaff"],
		}),
		isRecorder: hasRolesForProducer({
			producer: session.producer,
			permissions: profile.permissions,
			roles: ["producerRecorder"],
		}),
		isReader: session.reader.includes(profile.id),
	};
}
