import db from "astrid-firebase/src/db";
import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

export default function useProductionsWithOpenCostsQuery({ organization }) {
	return db
		.collection("productions")
		.where("producer", "==", organization?.id)
		.where("openCostsSummary.total", ">", 0)
		.where("status", "==", productionStatuses.DONE);
}
