import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { Artifact } from "./types/Artifact";

const artifacts = createFirestoreCollection("artifacts", Artifact, {
	preProcess: ({ api, firebase }, { data }) => {
		// If we have files, we want to calculate the total size
		if (data.files) {
			data.size = data.files.reduce((sum, file) => sum + (file.size || 0), 0);
		}

		return data;
	},

	sideEffects: async ({ api, firebase }, { transaction, after }) => {
		// Update or delete bundle data on all articles
		if (after.article) {
			await api.articles.update(
				{
					ref: after.article.ref,
					artifactIds: after.exists
						? firebase.firestore.FieldValue.arrayUnion(after.id)
						: firebase.firestore.FieldValue.arrayRemove(after.id),
					...(after.isDefault ? { artifact: after } : {}),
				},
				{ transaction },
			);
		}
	},
});

export default artifacts;
