import UserOption from "../components/UserOption/UserOption";

import { userIsEmployee } from "./userAccessors";

export function mapUserOption(producerId) {
	const isEmployee = userIsEmployee(producerId);

	return (user) => {
		const missingVendor = !user.vendors;
		const employee = isEmployee(user);

		const option = {
			key: user.id,
			data: user,
			value: user.id,
			employee,
			disabled: !employee && missingVendor,
			text: `${user.firstName} ${user.lastName}`,
		};

		return {
			...option,
			content: <UserOption option={option} employee={employee} missingVendor={missingVendor} />,
		};
	};
}
