import { useTranslation } from "react-i18next";

import DistributionsCollectionDataTable from "../../../distribution/components/DistributionsCollectionDataTable/DistributionsCollectionDataTable";

import AdminCard from "../../components/AdminCard/AdminCard";

import AdminArticleChannelsForm from "./components/AdminArticleChannelsForm";

export default function AdminArticleDistributionView({ article, organization }) {
	const { t } = useTranslation();

	return (
		<>
			<AdminArticleChannelsForm article={article} organization={organization} />

			<AdminCard header={t("history", "History")}>
				<DistributionsCollectionDataTable
					getQuery={(query) => query.where("article.id", "==", article.id).limit(50)}
				/>
			</AdminCard>
		</>
	);
}
