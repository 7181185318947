import updateDocument from "../../helpers/updateDocument";

import createApprovalData from "./createApprovalData";

export default async function denyDocument(ref, { user, ...data }) {
	await updateDocument(ref, {
		approval: createApprovalData(false, user),
		...data,
	});
}
