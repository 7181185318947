import firebase from "firebase/app";
import { useHits } from "react-instantsearch-core";

import { db } from "astrid-firebase";

function transformValue(value) {
	// Convert references to Firestore References
	if (value?._path?.segments) {
		return db.doc(value._path.segments.join("/"));
	}

	// Convert timestamps to Firestore Timestamps
	if (value?._seconds) {
		return new firebase.firestore.Timestamp(value._seconds, value._nanoseconds);
	}

	// Recursively transform nested objects
	if (value instanceof Object && !Array.isArray(value)) {
		return transformItem(value);
	}

	return value;
}

function transformItem(item) {
	return Object.entries(item).reduce((acc, [key, value]) => {
		acc[key] = transformValue(value);

		return acc;
	}, {});
}

function transformItems(items) {
	return items.map(transformItem);
}

export default function useInstantSearchDataTableHits() {
	const { hits } = useHits({ transformItems });

	return hits;
}
