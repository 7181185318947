import moment from "moment";
import { Header } from "semantic-ui-react";

import useNotificationHeaderTranslations from "../../../hooks/useNotificationHeaderTranslations";

export default function NotificationHeader({ todo }) {
	const { type, createdAt } = todo;

	const headerTranslations = useNotificationHeaderTranslations(todo);

	return (
		<Header style={{ margin: "0 0 7px 0", fontSize: 16 }}>
			{headerTranslations[type]}

			<span style={{ color: "grey", fontSize: 13, marginLeft: 5, fontWeight: 400 }}>
				{moment(createdAt.toDate()).fromNow()}
			</span>
		</Header>
	);
}
