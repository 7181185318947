import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import useStore from "../../ui/hooks/useStore";

import { getOrganizationId } from "../state/organizationId";
import { getOrganizationType } from "../state/organizationType";
import selectOrganization from "../utils/selectOrganization";

export default function useSelectOrganization() {
	const navigate = useNavigate();

	const { setProducerId: legacySetProducerId } = useStore();

	return useCallback(
		(organization) => {
			if (!organization) {
				return;
			}

			const currentId = getOrganizationId();
			const currentType = getOrganizationType();

			if (currentId === organization.id && currentType === organization.type) {
				return;
			}

			selectOrganization({ organization, legacySetProducerId });

			if (currentId) {
				navigate("/");
			}
		},
		[legacySetProducerId, navigate],
	);
}
