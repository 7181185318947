import React from "react";

import Right from "../components/Right";
import ListReaders from "../components/reader/ListReaders";
import useHasRight from "../features/authorization/hooks/useHasRight";
import Unauthorized from "../features/ui/components/Unauthorized/Unauthorized";

const Reader = (props) => {
	const hasRight = useHasRight();

	if (!hasRight("readerRegistry.view")) {
		return <Unauthorized />;
	}

	return (
		<Right can="readerRegistry.view">
			<ListReaders {...props} />
		</Right>
	);
};

export default Reader;
