import React, { useEffect, useState } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Icon, Popup } from "semantic-ui-react";

import { db, firebase } from "astrid-firebase";
import { getCollectionData, updateDocument } from "astrid-firestore/src/helpers";
import { useCollectionData } from "astrid-firestore/src/hooks";

async function checkTimeAndConfirmRetry({ retryJob, setIsRetrying }) {
	const currentTime = new Date();
	const retryJobTime = retryJob?.retryTimestamp?.toDate?.();
	const timeDifference = retryJobTime && currentTime - retryJobTime;
	const fiveMinutes = 300000;

	if (timeDifference < fiveMinutes) {
		if (
			window.confirm(
				`Less than 5 minutes have passed since the last retry (${retryJobTime.toLocaleDateString()} ${retryJobTime?.toLocaleTimeString?.()}). Do you want to retry again?`,
			)
		) {
			setIsRetrying(true);
			await updateDocument(retryJob.ref, {
				status: "retry",
				retryTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
			});
		}
	} else {
		setIsRetrying(true);
		await updateDocument(retryJob.ref, {
			status: "retry",
			retryTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
		});
	}
}

export default function MasterUploadRetry({ fileId }) {
	const [isRetrying, setIsRetrying] = useState(false);
	const [retryJobs] = useCollectionData(
		isRetrying && db.collection("tasks/compute/jobs").where("args.part", "==", fileId),
	);

	const retryJob = retryJobs?.[0];
	const retryJobTime = retryJob?.retryTimestamp?.toDate?.();

	useEffect(() => {
		if (retryJob?.status === "done") {
			setIsRetrying(false);
		}
	}, [retryJob]);

	const [{ loading }, onClick] = useAsyncFn(async () => {
		try {
			const jobs = await getCollectionData(db.collection("tasks/compute/jobs").where("args.part", "==", fileId));
			const retryJob = jobs[0];
			await checkTimeAndConfirmRetry({ retryJob, setIsRetrying });
		} catch (error) {
			console.log(error);
		}
	}, [fileId]);

	return (
		<Popup
			inverted
			size="mini"
			trigger={
				<Icon
					style={{ cursor: "pointer", marginBottom: 5 }}
					circular
					name="redo"
					disabled={isRetrying}
					loading={loading || isRetrying}
					onClick={() => onClick()}
				/>
			}
			content={
				retryJobTime
					? `Initiated retry ${retryJobTime.toLocaleDateString()} ${retryJobTime?.toLocaleTimeString?.()}`
					: "Retry"
			}
		/>
	);
}
