import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useArtifactsTableColumns from "../../hooks/useArtifactsTableColumns";

export default function ArtifactsCollectionDataTable({ article, getQuery = (query) => query, ...props }) {
	const navigate = useNavigate();

	const columns = useArtifactsTableColumns({ article });

	return (
		<CollectionDataTable
			query={getQuery(
				db.collection("artifacts").where("deleted", "==", false).where("article.id", "==", article.id),
			)}
			columns={columns}
			onClick={({ id, publisher }) =>
				navigate(`/publisher/${publisher.id}/admin/articles/${article?.id}/artifacts/${id}`)
			}
			sortBy={{
				id: "created",
				desc: true,
			}}
			{...props}
		/>
	);
}
