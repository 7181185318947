import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";
import shouldEnableInvoices from "../../invoices/utils/shouldEnableInvoices";

import AdminOrderView from "../views/AdminOrderView/AdminOrderView";

export default function AdminOrder() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("orders.view")) {
		return <Unauthorized />;
	}

	return (
		<AdminOrderView
			id={id}
			enableInvoices={shouldEnableInvoices(organization.id) && hasRight("producerBilling.createPublisherInvoice")}
		/>
	);
}
