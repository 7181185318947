import React from "react";

import FlexTable from "../../../ui/components/FlexTable/FlexTable";

import TeamMembersActions from "../../../team/components/TeamMembersActions/TeamMembersActions";

import useProductionTeam from "../../hooks/useProductionTeam";

import useProductionTeamColumns from "./hooks/useProductionTeamColumns";

export default function ProductionTeam({ production }) {
	const team = useProductionTeam(production);
	const columns = useProductionTeamColumns(production);

	return (
		<FlexTable selectable data={team} columns={columns} footer={<TeamMembersActions production={production} />} />
	);
}
