import { useState } from "react";
import { Button } from "semantic-ui-react";

export default function OpenButton({ text, children, ...props }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)} {...props}>
				{text}
			</Button>

			{open && (typeof children === "function" ? children({ open, setOpen }) : children)}
		</>
	);
}
