import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Form } from "semantic-ui-react";

Dropdown.propTypes.value = PropTypes.any;
Dropdown.Item.propTypes.value = PropTypes.any;

const scrollToTop = (e) => {
	e.target.parentNode.querySelector(".menu").scrollTop = 0;
};

export default function Select({
	value,
	options = [],
	multiple,
	additionalOptions,
	limitedOptions,
	onChange,
	onChangeOption,
	allowEmpty,
	autoSelect,
	persistOptions = false,
	...props
}) {
	const { t } = useTranslation();

	// This state will store all options that has been used before
	const [usedOptions, setUsedOptions] = useState({});

	const values = Array.isArray(value) ? value : [value];

	const allOptions = [
		// Add the empty option if allowed
		...(allowEmpty ? [{ key: null, value: null, text: t("none", "None") }] : []),

		// Add all the currently selected options
		...values.map((value) => usedOptions[value]).filter(Boolean),

		// Add the additional options
		...(additionalOptions || []),

		...(limitedOptions
			? options.filter((option) => limitedOptions.includes(option.value)) // Only show the options that are in the limited options
			: persistOptions
			? options.filter((option) => !usedOptions[option.value] || !values.includes(option.value)) // Re-add the options that are not selected
			: options),
	];

	const onChangeOptionRef = useRef(onChangeOption);

	onChangeOptionRef.current = onChangeOption;

	useEffect(() => {
		const option = multiple
			? allOptions.filter((option) => value?.includes(option.value))
			: allOptions.find((option) => option.value === value);

		onChangeOptionRef.current?.(option);
		// eslint-disable-next-line
	}, [value]);

	useEffect(() => {
		if (allOptions.length === 1 && autoSelect) {
			const option = allOptions[0];

			onChange(null, { value: option.value, option });
		}
		// eslint-disable-next-line
	}, [allOptions.length]);

	return (
		<Form.Select
			value={value}
			multiple={multiple}
			options={allOptions.map(({ key, value, text }) => ({ key, value, text }))}
			disabled={allOptions.length === 0}
			onSearchChange={scrollToTop}
			onChange={(e, { value, ...data }) => {
				const values = Array.isArray(value) ? value : [value];

				// Find the options that are selected
				const foundOptions = values
					.map((value) => options.find((option) => option?.value === value))
					.filter(Boolean);

				const option = foundOptions[foundOptions.length - 1];

				// Save all used options
				if (persistOptions && foundOptions.length > 0) {
					setUsedOptions((usedOptions) =>
						foundOptions.reduce(
							(usedOptions, option) => ({
								...usedOptions,
								[option.value]: option,
							}),
							usedOptions,
						),
					);
				}

				onChange(e, { value, option, foundOptions, ...data });
			}}
			{...props}
		/>
	);
}
