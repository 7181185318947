import React, { useState } from "react";
import { Icon, Modal, Popup, Loader, Button } from "semantic-ui-react";
import { firebase } from "astrid-firebase";
import jsonPretty from "../../helpers/jsonPretty";

const scrapeDistributorStatus = firebase.functions().httpsCallable("scrapeDistributorStatus");

const ScrapeArticle = ({ article, isbn, language }) => {
	const [isScraping, setIsScraping] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [scrapeData, setScrapeData] = useState();

	// const updateProduction = (productionId, data) => {
	// 	base.updateDoc("productions/" + productionId, data).then(() => {
	// 		this.eventLog(data);
	// 	});
	// };

	return (
		<>
			<Popup
				inverted
				size="mini"
				content="Hämta information från distributörer"
				trigger={
					<Icon
						name={"object group outline"}
						className="pointer"
						onClick={() => {
							setIsScraping(true);
							setIsOpen(true);

							scrapeDistributorStatus({
								isbn,
								language,
							}).then(({ data }) => {
								console.log(data);

								Object.entries(data).forEach(([key, val]) => {
									console.log(key, val);
								});

								setScrapeData(data);
								setIsScraping(false);
							});
						}}
					/>
				}
			/>

			{isOpen && (
				<Modal
					open
					closeOnDocumentClick
					onClose={() => {
						setIsOpen(false);
					}}
				>
					<Modal.Header>Hämta information</Modal.Header>
					<Modal.Content>
						{isScraping ? <Loader inline active /> : <div>{jsonPretty(scrapeData)}</div>}
					</Modal.Content>
					<Modal.Actions>
						<Button
							primary
							content="Stäng"
							onClick={() => {
								setIsOpen(false);
							}}
						/>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default ScrapeArticle;
