import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminImprintHeader from "./components/AdminImprintHeader";

export default function AdminImprintView(props) {
	return (
		<AdminPageView {...props}>
			<AdminImprintHeader />
		</AdminPageView>
	);
}
