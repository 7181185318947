import React, { useContext, useState } from "react";
import { Button } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import grabSession from "astrid-firestore/src/api/sessions/grabSession";
import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";

import recorderHasDefaultPrice from "../../../helpers/recorderHasDefaultPrice";
import sessionIsAvailableForRecorder from "../../../helpers/sessionIsAvailableForRecorder";

import SessionContext from "../../SessionContext";

function shouldShowGrabSession({ session, production, user, sessionRoles }) {
	return (
		session.status === "booked" &&
		[sessionOfferTypes.OPEN_POSITION_RECORDER, sessionOfferTypes.ANY_RECORDER_IN_TEAM, null].includes(
			session.recorder,
		) &&
		sessionIsAvailableForRecorder({ production, user, session, sessionRoles }) &&
		recorderHasDefaultPrice({
			production,
			user,
		}) &&
		session.end.toDate() > new Date()
	);
}

export default function GrabSession() {
	const [loading, setLoading] = useState(false);
	const { t, profile, session, production, sessionRoles } = useContext(SessionContext);

	const showGrabSession = shouldShowGrabSession({
		session,
		production,
		user: profile,
		sessionRoles,
	});
	return showGrabSession ? (
		<Button
			primary
			loading={loading}
			onClick={() => {
				setLoading(true);
				grabSession(firebase, {
					session,
					production,
					user: profile,
				})
					.then((result) => {
						console.log("success!");
					})
					.catch((error) => console.log("error!", error))
					.finally(() => setLoading(false));
			}}
		>
			{t("grabSession", "Grab session")}
		</Button>
	) : null;
}
