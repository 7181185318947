import merge from "lodash/merge";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Button, Form, Icon, Loader, Modal, Popup } from "semantic-ui-react";
import uniqid from "uniqid";

import { db, firebase } from "astrid-firebase";
import { addFile, getFile } from "astrid-firebase/src/storage";

import { toDate } from "../helpers/fnc";

import UploadButton from "./UploadButton";

const DragContext = createContext();

const MasterSoundSetting = (props) => {
	const {
		value: actualValue,
		sourceTemplates,
		reader,
		location,
		locationId,
		locationName,
		userId,
		size,
		fluid,
		open: openProp,
		onClose,
	} = props;
	const [open, setOpen] = useState(openProp);
	const [templates, setTemplates] = useState();
	const [tempValue, setTempValue] = useState();
	//id's in common.json
	const { t } = useTranslation();

	// temporarily override actual value while saving
	const value = tempValue || actualValue;

	const isProd = location === "productions";

	const getAllTemplates = async () => {
		// get parking templates
		const ids = [...(value?.list || [])];

		if (reader && sourceTemplates?.reader) {
			reader.forEach((r) => {
				// get reader templates
				if (sourceTemplates.reader[r]?.list) {
					ids.push(...sourceTemplates.reader[r]?.list);
				}

				// get reader track template
				if (value?.readers && value.readers[r]) {
					ids.push(value.readers[r]);
				}
			});
		}

		// get master track template
		if (value?.master) {
			ids.push(value.master);
		}

		// get org templates
		if (sourceTemplates?.producer) {
			ids.push(...sourceTemplates?.producer?.list);
		}

		// now actually get them
		const moreTemplates = await getFile(ids);
		setTemplates({ ...templates, ...moreTemplates });
	};

	const updateValue = async (newValue) => {
		const temp = merge({}, value, newValue);
		setTempValue(temp);

		return db
			.collection(location)
			.doc(locationId)
			.set({ reaperTemplates: newValue }, { merge: true })
			.then(() => {
				setTempValue(null);
			});
	};

	const getTemplate = async (id) => {
		const fileData = await getFile(id);
		setTemplates({ ...templates, [id]: fileData });
	};

	const copyTemplate = async (id) => {
		const copy = await getFile(id);

		copy.originalId = id;
		copy.history.push({
			action: "copied",
			location,
			locationId,
			locationName,
			time: new Date(), // serverTimestamp() doesn't work in arrays, trust the client...
			user: userId,
		});

		const { id: newId } = await db.collection("files").add(copy);

		return newId;
	};

	const openModal = () => {
		getAllTemplates();
		setOpen(true);
	};

	useEffect(() => {
		if (openProp) {
			getAllTemplates();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openProp]);

	return (
		<DragContext.Provider
			value={{ ...props, value, templates, setTemplates, getTemplate, updateValue, isProd, copyTemplate }}
		>
			{!openProp && (
				<Button
					primary
					fluid={fluid}
					size={size || "small"}
					labelPosition="left"
					icon="sliders"
					content={t("soundSetting")}
					onClick={openModal}
				/>
			)}

			{open && (
				<Modal
					open
					closeOnDocumentClick
					onClose={() => {
						setOpen(false);
						onClose?.();
					}}
					// unset to avoid drag offset
					style={{ willChange: "unset" }}
					size={isProd ? null : "tiny"}
				>
					<Modal.Content scrolling>
						{templates ? <DragDropParent {...props} /> : <Loader active />}
					</Modal.Content>
					<Modal.Actions>
						<Button
							color="green"
							content={t("readySound")}
							onClick={() => {
								setOpen(false);
								onClose?.();
							}}
						/>
					</Modal.Actions>
				</Modal>
			)}
		</DragContext.Provider>
	);
};

const DragDropParent = () => {
	const [dragging, setDragging] = useState(false);
	const [combinable, setCombinable] = useState(false);
	const [loading, setLoading] = useState();
	const { sourceTemplates, value, updateValue, isProd, copyTemplate, getTemplate } = useContext(DragContext);
	const { t } = useTranslation();

	const onDragStart = (result) => {
		setDragging(true);
	};

	const onDragUpdate = (result) => {
		if (result?.combine?.draggableId === "Hund") {
			setCombinable(true);
		} else {
			setCombinable(false);
		}
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = async (result) => {
		console.log(result);
		const { destination, source, combine } = result;

		if (combinable) {
			console.log("COMBINE", combine);
		}

		if (destination) {
			const [destType, destId] = destination.droppableId.split("_");
			const sourceId = result.source.droppableId;
			const [sourceType, trackId] = sourceId.split("_");
			let fileId = result.draggableId.replace(sourceId + "_", "");
			console.log({ destType, destId, sourceId, sourceType, trackId, fileId });

			let newValue = {};

			if (
				destType === "track" &&
				((destId === "master" && !value?.master) || !(value?.readers && value?.readers[destId]))
			) {
				setLoading(destId);
				// set track settings

				if (sourceId !== "list") {
					// clone from org and users
					fileId = await copyTemplate(fileId);
					getTemplate(fileId);
				}

				if (destId === "master") {
					// master track
					newValue = { master: fileId };
				} else {
					// reader track
					newValue = { readers: { [destId]: fileId } };

					// set same value to master track if empty
					if (!value?.master) newValue.master = fileId;
				}
			} else if (sourceType === "track" && destType === "list") {
				// move from track to parking
				newValue.list = [...new Set([...(value.list || []), fileId])];

				if (trackId === "master") {
					newValue.master = null;
				} else {
					newValue.readers = { [trackId]: null };

					// clear master track when all reader tracks are empty
					if (
						!Object.entries(value?.readers).find(([userId, templateId]) => userId !== trackId && templateId)
					) {
						newValue.master = null;
					}
				}
			} else if (sourceId === "list" && sourceId === destType) {
				// reorder parking/list
				newValue.list = reorder(value.list, source.index, destination.index);
			}

			if (Object.keys(newValue).length) updateValue(newValue);
			setLoading(null);
		}

		setCombinable(false);
		setDragging(false);
	};

	return (
		<DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
			<div
				className={`soundsetting-grid ${isProd ? "flex-stack" : ""} ${combinable ? "combinable" : ""} ${
					dragging ? "dragging" : ""
				}`}
			>
				<div>
					<h3>
						<Icon name="archive" /> {t("availableSettings")}
					</h3>

					{sourceTemplates?.producer && (
						<DropArea
							title={t("productionCompany")}
							icon="cube"
							id="org"
							items={sourceTemplates?.producer?.list}
						/>
					)}
					{Object.entries(sourceTemplates?.reader || {}).map(([id, user]) => (
						<DropArea title={user.name} icon="user" key={id} id={id} items={user.list} />
					))}
					<DropArea
						title={isProd && t("parking")}
						icon="dolly flatbed"
						id="list"
						items={value?.list?.filter((fileId) => {
							return (
								fileId !== value?.master &&
								!(value.readers && Object.values(value.readers).find((r) => fileId === r))
							);
						})}
						upload
						rename
						remove
						replace={!isProd}
					/>
				</div>

				{isProd && (
					<div className="soundsetting-tracks">
						<h3>
							<Icon name="sliders" /> {t("trackSettings")}
						</h3>
						<DropArea
							title={t("masterbus")}
							icon="bus"
							id="track_master"
							loading={loading === "master"}
							items={value?.master ? [value.master] : undefined}
							placeholder={t("dropSettingHere")}
							single
							rename
							replace
						/>

						{Object.entries(sourceTemplates?.reader || {}).map(([id, user]) => (
							<DropArea
								title={user.name}
								icon="microphone"
								key={id}
								id={"track_" + id}
								loading={loading === id}
								items={value?.readers && value.readers[id] ? [value.readers[id]] : undefined}
								placeholder={t("dropSettingHere")}
								single
								rename
								replace
							/>
						))}
					</div>
				)}
			</div>
		</DragDropContext>
	);
};

const DropArea = ({ title, icon, id, single, rename, remove, replace, items = [], placeholder, upload, loading }) => {
	const { value, updateValue, getTemplate, location, locationId, locationName, userId } = useContext(DragContext);
	const [progress, setProgress] = useState();
	const [uploadInc, setUploadInc] = useState(0);
	const { t } = useTranslation();

	const uploadTemplate = async (e) => {
		const bucket = window.ES.stage ? "stage-earselect-static" : "earselect-static";
		const path = `reaperTemplates/${location}/${locationId}-${uniqid()}.RPP`;

		// file from input or drop
		const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];

		if (!file) return;
		if (file.name.substr(-4, 4).toLowerCase() !== ".rpp") {
			window.alert(t("fileNeedToBeRpp"));
			return;
		}

		const name = window.prompt(t("renameSoundSetting"));
		if (!name) {
			window.alert(t("settingAlert"));
			return;
		}

		const id = await addFile({
			type: "reaperTemplate",
			bucket,
			path,
			file,
			name,
			userId,
			onProgress: setProgress,
			historyEntry: { location, locationId, locationName },
		});

		// store file id
		updateValue({ list: [...(value?.list || []), id] });
		getTemplate(id);

		// reset
		setProgress(null);
		setUploadInc(uploadInc + 1);
	};

	const fullSingle = !!(single && items.length);

	return (
		<div className={"soundsetting-parent " + id}>
			{title && (
				<b>
					{icon && <Icon name={icon} />} {title}
				</b>
			)}
			{loading ? (
				<div>
					<Loader active inline size="small" />
				</div>
			) : (
				<Droppable droppableId={id} isDropDisabled={fullSingle} isCombineEnabled={true}>
					{(provided, snapshot) => (
						<div
							ref={provided.innerRef}
							className={"soundsetting-drop" + (snapshot.isDraggingOver && !fullSingle ? " over" : "")}
						>
							{items.map((fileId, index) => (
								<DragItem
									key={id + "_" + fileId}
									id={id + "_" + fileId}
									parent={id}
									fileId={fileId}
									index={index}
									rename={rename}
									remove={remove}
									replace={replace}
									single={single}
								/>
							))}

							{provided.placeholder}
						</div>
					)}
				</Droppable>
			)}
			{placeholder && !loading && !items.length && (
				<p>
					<em>{placeholder}</em>
				</p>
			)}
			{fullSingle && (
				<p className="show-while-dragging">
					<em>{t("moveExistingSetting")}</em>
				</p>
			)}
			{upload && (
				<UploadButton
					key={uploadInc} // remount after upload to clear file path
					text={!progress ? t("uploadNewSoundSetting") : t("uploads") + ": " + progress}
					onUpload={uploadTemplate}
					fluid
				/>
			)}
		</div>
	);
};

const DragItem = ({ id, fileId, parent, index, rename, remove, replace, single }) => {
	const [editingTitle, setEditingTitle] = useState(false);
	const { templates, setTemplates, getTemplate, location, locationId, locationName, userId, sourceTemplates } =
		useContext(DragContext);
	const info = templates[fileId];

	const { t } = useTranslation();

	let itemType = ["org", "list"].includes(parent) ? parent : "reader";

	// can't look at parent in the track settings and parking to determine item type, look at sourceTemplates
	if (single || parent === "list") {
		itemType =
			info?.originalId && sourceTemplates?.producer?.list?.includes(info.originalId)
				? "org"
				: info?.originalId &&
				  Object.values(sourceTemplates?.reader || {}).find((r) => r?.list?.includes(info.originalId))
				? "reader"
				: "list";
	}

	const updateName = () => {
		db.collection("files").doc(fileId).update({ "file.name": info.file.name });

		setEditingTitle(false);
	};

	const removeFile = (id) => {
		if (window.confirm(t("sureAboutDeleteSetting"))) {
			const newData = { ...templates };
			delete newData[id];
			setTemplates(newData);

			db.collection(location)
				.doc(locationId)
				.update({
					"reaperTemplates.list": firebase.firestore.FieldValue.arrayRemove(id),
				});
		}
	};

	const [progress, setProgress] = useState();
	const [uploadInc, setUploadInc] = useState(0);
	const replaceTemplate = async (e) => {
		const bucket = window.ES.stage ? "stage-earselect-static" : "earselect-static";
		const path = `reaperTemplates/${location}/${locationId}-${uniqid()}.RPP`;
		const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];

		if (!file) return;
		if (file.name.substr(-4, 4).toLowerCase() !== ".rpp") {
			window.alert(t("fileNeedToBeRpp"));
			return;
		}

		await addFile({
			updateId: fileId,
			type: "reaperTemplate",
			bucket,
			originalId: null,
			path,
			file,
			name: info.file.name,
			userId,
			onProgress: setProgress,
			historyEntry: { action: "updated", location, locationId, locationName },
		});

		getTemplate(fileId);
		setProgress(null);
		setUploadInc(uploadInc + 1);
	};

	return (
		<Draggable draggableId={id} index={index}>
			{(provided, snapshot) => {
				return (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={provided.draggableProps.style}
						className={`item ${itemType} ${snapshot.isDragging ? "dragging" : ""} ${
							snapshot.combineWith || snapshot.combineTargetFor ? "combining" : ""
						}`}
					>
						<div className="flex-stack">
							<div>
								{!editingTitle ? (
									<>
										<b>{info?.file?.name}</b>{" "}
										{rename && (
											<Icon
												name="pencil"
												onClick={() => {
													setEditingTitle(true);
												}}
												className="pointer"
											/>
										)}
									</>
								) : (
									<Form.Input
										value={info.file.name}
										onChange={(e, data) => {
											info.file.name = data.value;
											setTemplates({ ...templates });
										}}
										onKeyUp={(e) => {
											if (e.key === "Enter") updateName();
										}}
										action={{
											icon: "save",
											onClick: updateName,
										}}
										autoFocus
									/>
								)}
							</div>
							<div style={{ textAlign: "right" }}>
								{remove && (
									<Icon
										name="trash alternate"
										onClick={(e) => {
											e.stopPropagation();
											removeFile(fileId);
										}}
										className="pointer"
									/>
								)}
								<Popup
									inverted
									size="mini"
									content={
										<>
											<h4>
												<Icon name="clock" /> {t("nonGenreHistory") + ":"}
											</h4>
											{info?.history?.map(
												({ time, action, location, locationId, locationName }) => (
													<p key={+toDate(time)}>
														<b>{moment(toDate(time)).format("YYYY-MM-DD LT")}</b>
														<br />
														{t("fileAction" + action)} {t("fileLocation" + location)}{" "}
														{locationName}
													</p>
												),
											)}
										</>
									}
									trigger={<Icon name="history" />}
								/>
								<a href={info?.file?.url} style={{ color: "white" }}>
									<Icon name="download" />
								</a>
							</div>
						</div>
						{replace && (
							<UploadButton
								key={uploadInc} // remount after upload to clear file path
								text={!progress ? t("replaceSoundSetting") : t("uploads") + ": " + progress}
								onUpload={replaceTemplate}
								size={"tiny"}
								fluid
							/>
						)}
					</div>
				);
			}}
		</Draggable>
	);
};

export default MasterSoundSetting;
