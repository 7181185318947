import React, { useContext, useState } from "react";
import { Accordion, Header, Icon, List, Message } from "semantic-ui-react";

import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";

import recorderHasDefaultPrice from "../../../helpers/recorderHasDefaultPrice";
import sessionIsAvailableForRecorder from "../../../helpers/sessionIsAvailableForRecorder";
import tsToLocaleTimeString from "../../../helpers/tsToLocalTimeString";

import SessionContext from "../../SessionContext";

function translateRecorder(session, t) {
	switch (session.recorder) {
		default:
			return session?.recorderData?.name;
		case sessionOfferTypes.OPEN_POSITION_RECORDER:
			return t("upForGrabs");
		case sessionOfferTypes.ANY_RECORDER_IN_TEAM:
		case null:
			return t("anyRecorderFromTeam");
	}
}

export default function SessionInformation() {
	const { t, profile, session, production, sessionRoles } = useContext(SessionContext);
	const [active, setActive] = useState(true);

	if (
		!sessionIsAvailableForRecorder({ production, session, sessionRoles, user: profile }) &&
		!session.reader?.includes(profile.id)
	) {
		return (
			<Message info>{t("sessionBelongsToOtherRecorder", "This session belongs to another recorder.")}</Message>
		);
	}

	if (sessionRoles.isRecorder && !recorderHasDefaultPrice({ production, user: profile })) {
		return (
			<Message info>
				{t(
					"recorderMissingDefaultPrice",
					"You do not have a default price and can not view or grab this session",
				)}
			</Message>
		);
	}

	return (
		<>
			<Accordion.Title active={active} onClick={() => setActive(!active)}>
				<Header size="small">
					<Icon name="dropdown" />
					{t("sessionInformation", "Session information")}
				</Header>
			</Accordion.Title>
			<Accordion.Content active={active}>
				<List>
					<List.Item>
						<p>
							<b>{t("recorder")}</b>:&nbsp;
							<i>{translateRecorder(session, t)}</i>
						</p>
					</List.Item>
					<List.Item>
						<p>
							<b>{t("date")}</b>:&nbsp;
							<i>{session.start?.toDate()?.toLocaleDateString()}</i>
						</p>
					</List.Item>
					<List.Item>
						<p>
							<b>{t("time")}</b>:&nbsp;
							<i>{`${tsToLocaleTimeString(session.start)} - ${tsToLocaleTimeString(session.end)}`}</i>
						</p>
					</List.Item>
				</List>
			</Accordion.Content>
		</>
	);
}
