import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Serie } from "astrid-firestore/src/api/series/types/Serie";

import api from "../../../../api";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateSerieForm({ organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Serie,
		defaultValues: {
			name: "",
			publisher: organization,
		},
		onSubmit: async (data) => {
			return api.series.create(data);
		},
		onSuccess: ({ id }) => navigate(`../${id}`),
	});

	return (
		<ModalPrompt size="tiny" header={t("createSeries", "Create series")} onSave={onSubmit} onClose={onClose}>
			<Form form={form}>
				<Form.Input name="name" label={t("name", "Name")} />
			</Form>

			{error && <ErrorMessage error={error}>{t("errorMessageHeader", "Something went wrong")}</ErrorMessage>}
		</ModalPrompt>
	);
}
