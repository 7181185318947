export default {
	name: "Bonnier Audio Wav",
	format: "wav",
	connection: false,
	alertAdmin: true,
	audio: {
		zip: true,
		rename: "SEQ_SLUG.SUFFIX",
	},
	disableArticles: ["mp3cd", "ebook"],
	allowMissingISBN: true,
};
