import React from "react";

import useProductionVoices from "../../../productions/hooks/useProductionVoices";
import Voices from "../../../readers/components/Voices/Voices";

export default function ProductionVoices({ production, offers }) {
	const voices = useProductionVoices({ production, offers });

	return <Voices voices={voices} production={production} />;
}
