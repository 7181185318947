export default function getCommentSender(offer) {
	if (offer.optionalMessageSender === offer.publisher.id) {
		return offer.publisher;
	}

	if (offer.optionalMessageSender === offer.producer.id) {
		return offer.producer;
	}

	return offer.createdBy;
}
