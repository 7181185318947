import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "semantic-ui-react";

import { deleteDocument } from "astrid-firestore/src/helpers";

import useConfirm from "../../../../ui/hooks/useConfirm";

export default function OrganizationActions({ organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const onClickDelete = useConfirm(t("deleteConfirmMessage", "Are you sure you want to delete?"), async () => {
		await deleteDocument(organization.ref);
		navigate("../list");
	});

	return (
		<Card fluid>
			<Card.Content>
				<Button onClick={onClickDelete}>{t("delete", "Delete")}</Button>
			</Card.Content>
		</Card>
	);
}
