import React from "react";
import { Form, Icon, Popup } from "semantic-ui-react";
//id's in common.json
import { useTranslation } from "react-i18next";

const ExportChoice = ({ selectedArticles, prod, setArticles }) => {
	const { t } = useTranslation();
	// gather articles
	const availableArticles = {};
	if (prod.isbn)
		availableArticles.total = {
			text: t("normalProduction"),
			checked: selectedArticles[prod.id] && !!selectedArticles[prod.id]["total"],
		};

	if (prod.deliveryParts)
		prod.deliveryParts.forEach((part, i) => {
			if (part.title && part.isbn)
				availableArticles["part_" + i] = {
					text: "Del " + (i + 1) + ": " + part.title,
					checked: selectedArticles[prod.id] && !!selectedArticles[prod.id]["part_" + i],
				};
		});
	if (prod.deliveryEbook && prod.deliveryEbook.isbn)
		availableArticles.ebook = {
			text: t("eBook"),
			checked: selectedArticles[prod.id] && !!selectedArticles[prod.id]["ebook"],
		};

	// get some status
	const nof = Object.keys(availableArticles).length;
	const checked = Object.values(availableArticles).filter((art) => art.checked).length;
	const all = nof && nof === checked;
	const none = nof && !checked;

	const toggleSingle = (e, data) => {
		e.preventDefault();

		if (!selectedArticles[prod.id] || !selectedArticles[prod.id][data.value]) {
			if (!selectedArticles[prod.id]) selectedArticles[prod.id] = {};
			selectedArticles[prod.id][data.value] = "metadata"; // just metadata, otherwise export ID (from export modal on production page)
		} else {
			delete selectedArticles[prod.id][data.value];
			if (!Object.keys(selectedArticles[prod.id]).length) delete selectedArticles[prod.id];
		}

		setArticles(selectedArticles);
	};

	return (
		<Popup
			hoverable
			size="mini"
			position="right center"
			content={
				nof ? (
					<Form className="dist-choice">
						<b>{t("includeInExport")}</b>
						{Object.entries(availableArticles).map(([article, { text, checked }]) => (
							<Form.Checkbox
								key={article}
								label={text}
								value={article}
								checked={checked}
								onClick={toggleSingle}
							/>
						))}
					</Form>
				) : (
					<em>{t("prodMissingArticles")}</em>
				)
			}
			trigger={
				<Icon
					name={"square" + (all ? " check" : none ? " outline" : nof ? " minus" : "")}
					className={nof ? "" : "dist-choice-no-articles"}
					color={nof && !none ? "black" : "grey"}
					onClick={(e) => {
						e.preventDefault();

						if (nof) {
							if (checked) {
								delete selectedArticles[prod.id];
							} else {
								selectedArticles[prod.id] = {};
								Object.keys(availableArticles).forEach((article) => {
									selectedArticles[prod.id][article] = "metadata";
								});
							}

							setArticles(selectedArticles);
						}
					}}
				/>
			}
		/>
	);
};

export default ExportChoice;
