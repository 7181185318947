import anchorme from "anchorme";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Header, Segment, Table } from "semantic-ui-react";

import { withStore } from "../../helpers/context";
import { toDate } from "../../helpers/fnc";
import { prodStatus } from "../../helpers/lists";
import withMatch from "../../hocs/withMatch";
import withUserProfile from "../../hocs/withUserProfile";

import HomeAlerts from "./HomeAlerts";
import HomeReaderOffers from "./HomeReaderOffers";
import HomeStarred from "./HomeStarred";

class HomePublisher extends Component {
	state = {
		loading: true,
		search: "",
		searchResult: [],
		adding: false,
		publishers: null,
	};

	UNSAFE_componentWillMount() {
		this.props.store.getProductions().then((org) => {
			this.setState({ loading: false });
		});
	}

	render() {
		const { loading } = this.state;
		const { t, profile, store } = this.props;

		const productions = Object.values(cloneDeep(store.state.productions || {})).forEach((prod) => {
			// call the status "booked" until first session is logged
			if (
				prod.status === "production" &&
				!prod.stats &&
				!prod.proofParts &&
				prod.productionType !== "subcontract"
			)
				prod.status = "booked";

			// call the status "mastring" when polish is done
			if (prod.status === "production" && prod.polishDone && prod.productionType !== "subcontract")
				prod.status = "mastering";
		});

		const recent = productions
			.filter((prod) => prod.status === "done" && !prod.scheduled)
			.sort((a, b) =>
				toDate(a.deliveryDateActual || a.deliveryDate) < toDate(b.deliveryDateActual || b.deliveryDate)
					? 1
					: -1,
			)
			.slice(0, 10);

		return (
			<Segment loading={loading} padded>
				<HomeAlerts
					productions={productions}
					prodStatus={prodStatus}
					profile={profile}
					uid={this.props.user.uid}
					alertProductions={profile.alertProductions}
				/>
				{profile.starredProductions && profile.starredProductions.length > 0 && <HomeStarred />}

				<HomeReaderOffers productions={productions} />

				{!!recent?.length && (
					<>
						<Header as="h4" icon="truck" content={t("recentlyDelProds")} />
						<Table compact basic="very" sortable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t("titleRecentlyDel")}</Table.HeaderCell>
									<Table.HeaderCell>{t("messageRecentlyDel")}</Table.HeaderCell>
									<Table.HeaderCell>{t("delDateRecentlyDel")}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{recent.map((prod) => {
									return (
										<Table.Row key={prod.id}>
											<Table.Cell selectable>
												<Link to={"/production/" + prod.id}>{prod.title}</Link>
											</Table.Cell>
											<Table.Cell>
												{prod.deliveryMessage && (
													<div
														className="line-broken"
														dangerouslySetInnerHTML={{
															__html: anchorme(prod.deliveryMessage, {
																truncate: [26, 15],
																attributes: [
																	function (urlObj) {
																		if (urlObj.protocol !== "mailto:")
																			return { name: "target", value: "blank" };
																	},
																],
															}),
														}}
													/>
												)}
											</Table.Cell>
											<Table.Cell collapsing textAlign="right">
												{(prod.deliveryDate || prod.deliveryDateActual) &&
													moment(toDate(prod.deliveryDateActual || prod.deliveryDate)).format(
														"YYYY-MM-DD",
													)}
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</>
				)}
			</Segment>
		);
	}
}

export default withTranslation()(withStore(withMatch(withUserProfile(HomePublisher))));
