import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

export default function AgreementNameForm() {
	const { t } = useTranslation();

	return (
		<ContentCard header={t("agreementName", "Agreement name")}>
			<Form.Form>
				<Form.Input name="name" size="large" />
			</Form.Form>
		</ContentCard>
	);
}
