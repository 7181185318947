import round from "astrid-helpers/src/round";

import { getCostEstimation } from "./costEstimation";
import { getActivity, getAmountProduced, getCostAmount, getCostExchange, getCostValue } from "./costsHelper";

export function applyActivity(users) {
	return (cost) => ({
		...cost,
		activity: getActivity(cost, users),
	});
}

export function applyAmountProduced(production) {
	return (cost) => ({
		...cost,
		amountProduced: getAmountProduced({ cost, production }),
	});
}

export function applyAmount() {
	return (cost) => {
		return {
			...cost,
			amount: getCostAmount(cost),
		};
	};
}

export function applyValue(exchangeRate = undefined, expenses = 0) {
	return (cost) => ({
		...cost,
		value: getCostValue({ cost, exchangeRate, expenses }),
	});
}

export function applyOpenAmount(costs) {
	return (openCost) => {
		const filteredCosts = costs.filter((cost) =>
			cost.amounts
				? cost.amounts?.[openCost.price?.id]
				: openCost.price?.type === "custom"
				? cost.price?.id === openCost.price?.id
				: cost.price?.type === openCost.price?.type && cost.secondParty?.id === openCost.secondParty?.id,
		);

		const finalizedAmount = filteredCosts.reduce(
			(amount, cost) => amount + (cost.amounts?.[openCost.price?.id] || cost.amount || 0),
			0,
		);

		const openAmount = Math.max(0, openCost.amount - finalizedAmount);

		return {
			...openCost,
			openAmount,
		};
	};
}

export function applyTotal() {
	return (cost) => ({
		...cost,
		total: round(cost.openAmount * cost.value, 2),
	});
}

export function applyExchange(exchangeRate) {
	return (cost) => ({
		...cost,
		exchange: getCostExchange({ cost, exchangeRate }),
	});
}

export function applyEstimation(production, exchangeRate, expenses = 0) {
	return (cost) => ({
		...cost,
		estimation: getCostEstimation({ cost, production, exchangeRate, expenses }),
	});
}

export function mapCostFromPrice(price) {
	return {
		price: {
			id: price.id,
			ref: price.ref,
			vat: price.vat,
			type: price.type,
			unit: price.unit,
			name: price.name,
			price: price.price,
			amount: price.amount || 0,
			currency: price.currency,
			approval: price.approval,
		},
		session: price.session || null,
		expense: price.expense,
		employee: price.employee,
		producer: price.producer,
		agreement: price.agreement,
		production: price.production,
		firstParty: price.firstParty,
		secondParty: price.secondParty,
		referencePrice: price.referencePrice,
	};
}

export function splitEstimationByType(...types) {
	return (cost, i, costs) => {
		if (!types.includes(cost.price?.type)) {
			return cost;
		}

		const filteredCosts = costs.filter(({ price }) => price.type === cost.price.type);
		const totalAmount = filteredCosts.reduce((total, cost) => total + cost.amount, 0);
		const splitFactor = cost.amount / totalAmount || filteredCosts.length / 1;

		return {
			...cost,
			estimation: {
				...cost.estimation,
				value: cost.estimation.value * splitFactor,
				exchanged: cost.estimation.exchanged * splitFactor,
			},
		};
	};
}
