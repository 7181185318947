import get from "lodash/get";
import moment from "moment";
import React, { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Form, Icon, Segment, Table } from "semantic-ui-react";

import { withStore } from "../helpers/context";
import { toDate } from "../helpers/fnc";

class Publication extends Component {
	state = {
		loadingOrganizations: true,
		loadingProductions: true,
		publisher: "4Ie7jv8pUQ02HvqSxbS6",
		articles: {},
	};

	UNSAFE_componentWillMount() {
		this.props.store.getOrganizations().then((org) => {
			this.setState({ loadingOrganizations: false });
		});
		this.props.store.getProductions().then((prod) => {
			this.updateArticles();
		});
	}

	updateArticles = () => {
		this.setState({ articles: {} });
		const recent = [];
		const planned = [];
		const ready = [];
		const { t } = this.props;

		Object.entries(this.props.store.state.productions || {})
			.filter(
				([id, p]) =>
					p.language === "sv" &&
					p.publisher === this.state.publisher &&
					["production", "done"].includes(p.status),
			)
			.forEach(([id, p]) => {
				const bookbeat = get(p, "release.bookbeat.date") || get(p, "releaseDate.bookbeat");
				const storytel = get(p, "release.storytel.date");
				const nextory = get(p, "release.nextory.date");

				const article = {
					id,
					title: p.title,
					article: t("audioBook"),
					storytel,
					bookbeat,
					nextory,
				};

				if (
					bookbeat &&
					toDate(bookbeat) < new Date() &&
					toDate(bookbeat) > moment().subtract(14, "d").toDate()
				) {
					recent.push(article);
				} else if (bookbeat && toDate(bookbeat) > new Date()) {
					planned.push(article);
				} else if (!bookbeat && p.isbn && p.status === "done") {
					ready.push(article);
				}

				const bookbeatEbook =
					get(p, "deliveryEbook.release.bookbeat.date") || get(p, "deliveryEbook.releaseDate.bookbeat");
				const storytelEbook = get(p, "deliveryEbook.release.storytel.date");
				const nextoryEbook = get(p, "deliveryEbook.release.nextory.date");

				const articleEbook = {
					id,
					title: p.title,
					article: t("eBook"),
					storytel: storytelEbook,
					bookbeat: bookbeatEbook,
					nextory: nextoryEbook,
				};

				if (
					bookbeatEbook &&
					toDate(bookbeatEbook) < new Date() &&
					toDate(bookbeatEbook) > moment().subtract(14, "d").toDate()
				) {
					recent.push(articleEbook);
				} else if (bookbeatEbook && toDate(bookbeatEbook) > new Date()) {
					planned.push(articleEbook);
				} else if (!bookbeatEbook && p.deliveryEbook && p.deliveryEbook.isbn && p.status === "done") {
					ready.push(articleEbook);
				}
			});

		this.setState({
			articles: {
				recent,
				planned,
				ready,
			},
		});
	};

	render() {
		const { loadingOrganizations, publisher, articles } = this.state;
		const { t, store } = this.props;
		const { organizations } = store.state;

		return (
			<Segment padded loading={loadingOrganizations}>
				<Form>
					<Form.Select
						width={4}
						search
						deburr
						fluid
						placeholder={t("publisher")}
						name="publisher"
						onChange={(e, data) => {
							this.setState({ publisher: data.value }, this.updateArticles);
						}}
						value={publisher}
						options={[
							{
								key: "fake",
								text: "",
								value: null,
							},
							...Object.values(organizations || {})
								.filter((org) => org.type === "publisher")
								.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
								.map((pub) => ({
									key: pub.id,
									value: pub.id,
									text: pub.name,
								})),
						]}
					/>
				</Form>
				{publisher && (
					<>
						<h3>
							<Icon name="checked calendar" /> {t("recentlyPublished")}
						</h3>
						<ArticleTable
							articles={articles.recent}
							icon={<Icon name="circle" color="green" size="small" />}
						/>
						<h3>
							<Icon name="clock outline" /> {t("planned")}
						</h3>
						<ArticleTable
							articles={articles.planned}
							icon={<Icon name="circle" color="yellow" size="small" />}
						/>
						<h3>
							<Icon name="box" /> {t("readyToPublish")}
						</h3>
						<ArticleTable articles={articles.ready} />
					</>
				)}
			</Segment>
		);
	}
}

//const ArticleTable = ({ articles, icon }) => (
const ArticleTable = ({ articles, icon }) => {
	const { t } = useTranslation();

	return (
		<Table basic="very" compact sortable className="list-productions">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell style={{ width: "30%" }}>{t("titleArticleTable")}</Table.HeaderCell>
					<Table.HeaderCell>{t("articleArticleTable")}</Table.HeaderCell>
					<Table.HeaderCell>{t("bookbeatArticleTable")}</Table.HeaderCell>
					<Table.HeaderCell>{t("storytelArticleTable")}</Table.HeaderCell>
					<Table.HeaderCell>{t("nextoryArticleTable")}</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{articles &&
					articles.map(({ id, title, article, bookbeat, storytel, nextory }, i) => (
						<Table.Row key={i}>
							<Table.Cell selectable>
								<Link to={"/production/" + id}>{title}</Link>
							</Table.Cell>
							<Table.Cell>
								<Icon
									name={article === "Ljudbok" ? "headphones" : "book"}
									color={article === "Ljudbok" ? "teal" : "purple"}
								/>

								{article}
							</Table.Cell>
							<Table.Cell>
								{bookbeat && (
									<>
										{icon}
										{moment(toDate(bookbeat)).format("YYYY-MM-DD")}
									</>
								)}
							</Table.Cell>
							<Table.Cell>
								{storytel && (
									<>
										{icon}
										{moment(toDate(storytel)).format("YYYY-MM-DD")}
									</>
								)}
							</Table.Cell>
							<Table.Cell>
								{nextory && (
									<>
										{icon}
										{moment(toDate(nextory)).format("YYYY-MM-DD")}
									</>
								)}
							</Table.Cell>
						</Table.Row>
					))}
			</Table.Body>
		</Table>
	);
};
//);

export default withTranslation()(withStore(Publication));
