import { useTranslation } from "react-i18next";

import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";

export default function ReaderInstantSearchSelect(props) {
	const { t } = useTranslation();

	return (
		<InstantSearchSelect
			indexName="readers"
			label={t("reader", "Narrator")}
			getOption={({ objectID, name }) => {
				return {
					key: objectID,
					value: objectID,
					text: name,
				};
			}}
			filters={"filterable:true"}
			{...props}
		/>
	);
}
