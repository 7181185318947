import { useEffect, useMemo } from "react";
import { Dropdown } from "semantic-ui-react";

import { useProfile } from "../../../../authentication/state/profile";
import useSelectOrganization from "../../../../organizations/hooks/useSelectOrganization";
import useUserOrganizations from "../../../../organizations/hooks/useUserOrganizations";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import getInitialOrganization from "../utils/getInitialOrganization";

export default function OrganizationMenu() {
	const user = useProfile();
	const organizationId = useOrganizationId();
	const selectOrganization = useSelectOrganization();
	const organizations = useUserOrganizations(user);

	const organizationName = useMemo(
		() => organizations.find(({ id }) => id === organizationId)?.name,
		[organizationId, organizations],
	);

	useEffect(() => {
		selectOrganization(getInitialOrganization({ user, organizations }));
	}, [organizations, selectOrganization, user]);

	if (organizations.length <= 1) {
		return null;
	}

	return (
		<DropdownMenu item icon="dropdown" text={organizationName}>
			{() =>
				organizations.map((organization) => (
					<Dropdown.Item
						key={organization.id}
						onClick={() => selectOrganization(organization)}
						active={organizationId === organization.id}
					>
						{organization?.name}
					</Dropdown.Item>
				))
			}
		</DropdownMenu>
	);
}
