import getCollection from "../../helpers/getCollection";
import runBatch from "../../helpers/runBatch";

export default async function createOrganization(firebase, { type, name, user }) {
	const db = firebase.firestore();

	const organizations = await getCollection(
		db.collection("organizations").where("type", "==", type).where("name", "==", name),
	);

	if (organizations.length > 0) {
		throw new Error("name unavailable");
	}

	const addProducerId = user?.permissions?.role === "admin" && user?.adminProducerIds;

	const ref = db.collection("organizations").doc();

	await runBatch(db, (batch) => {
		batch.set(ref, { name, type });

		if (addProducerId) {
			batch.update(user.ref, {
				adminProducerIds: firebase.firestore.FieldValue.arrayUnion(ref.id),
			});
		}
	});

	return ref;
}
