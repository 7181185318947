import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Label } from "semantic-ui-react";

import InvoiceActionMenu from "../../../../invoices/components/InvoiceActionMenu";
import InvoiceLink from "../../../../invoices/components/InvoiceLink";
import useInvoiceStatusTranslations from "../../../../invoices/hooks/useInvoiceStatusTranslations";
import useInvoiceTypeTranslations from "../../../../invoices/hooks/useInvoiceTypeTranslations";
import { invoiceStatusColors } from "../../../../invoices/utils/invoiceStatusColors";

export default function useInvoiceColumns() {
	const { t } = useTranslation();

	const invoiceStatusTranslations = useInvoiceStatusTranslations();
	const invoiceTypeTranslations = useInvoiceTypeTranslations();

	return useMemo(
		() => [
			{
				id: "invoiceNumber",
				Header: t("invoice", "Invoice"),
				Cell: ({ row }) => <InvoiceLink invoice={row.original} />,
			},
			{
				id: "orderId",
				Header: t("salesOrder", "Sales order"),
				Cell: ({ value }) => <Link to={`../../orders/${value}`}>{value}</Link>,
			},
			{
				id: "created",
				Header: t("created", "Created"),
				Cell: ({ value }) => value?.toDate?.()?.toLocaleDateString() || "",
			},
			{
				id: "dueDate",
				Header: t("dueDate", "Due date"),
				Cell: ({ value }) => value?.toDate?.()?.toLocaleDateString() || "",
			},
			{
				id: "type",
				Header: t("type", "Type"),
				Cell: ({ value }) => invoiceTypeTranslations[value],
			},
			{
				id: "status",
				Header: t("status", "Status"),
				Cell: ({ value }) => (
					<Label color={invoiceStatusColors[value]}>{invoiceStatusTranslations[value]}</Label>
				),
			},
			{
				id: "menu",
				Cell: ({ row }) => <InvoiceActionMenu invoice={row.original} />,
			},
		],
		[t, invoiceTypeTranslations, invoiceStatusTranslations],
	);
}
