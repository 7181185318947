import todoTypes from "../../../../constants/todoTypes";

import offerStatuses from "../../../offers/constants/offerStatuses";

import getOfferProductionManagers from "../../helpers/team/managers/getOfferProductionManagers";

import createTodoData from "../todos/createTodoData";

export default function createReaderInquiryTodoData(firebase, { offer, status }) {
	const organizationIds =
		status === offerStatuses.INTERESTED ? [offer.producer.id, offer.publisher.id] : [offer.producer.id];

	const productionManagers = getOfferProductionManagers({ offer });

	return createTodoData(firebase, {
		type: todoTypes.READER_INQUIRY_STATUS_CHANGED,
		organizationIds,
		userIds: productionManagers.map((pM) => pM.id),
		users: productionManagers,
		subjectRef: offer.ref,
		url: `/production/${offer.production?.id}/readers`,
		notification: true,
		languages: [offer.production.language],
		content: {
			offer: {
				status,
				id: offer.id,
			},
			reader: {
				id: offer.secondParty.id,
				name: offer.secondParty.name,
			},
			production: {
				title: offer?.production?.title,
				id: offer?.production.id,
			},
			publisher: {
				id: offer?.publisher?.id,
				name: offer?.publisher?.name,
			},
		},
	});
}
