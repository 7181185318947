import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminOrganizationsView from "../views/AdminOrganizationsView/AdminOrganizationsView";

export default function AdminPublishers() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("publishers.view")) {
		return <Unauthorized />;
	}

	return (
		<AdminOrganizationsView header={t("publishers", "Publishers")} type="publisher" producer={organization.id} />
	);
}
