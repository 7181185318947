import { useTranslation } from "react-i18next";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";

export default function ProductionInstantSearchSelect({
	organizationId,
	statuses = [productionStatuses.PLANNING, productionStatuses.ACCEPTED, productionStatuses.PRODUCTION],
	readerId,
	productionId,
	...props
}) {
	const { t } = useTranslation();

	const filters = [
		`producer:${organizationId}`,
		`(${statuses.map((status) => `status:${status}`).join(" OR ")})`,
		readerId && `readers:${readerId}`,
		productionId && `objectID:${productionId}`,
	]
		.filter(Boolean)
		.join(" AND ");

	return (
		<InstantSearchSelect
			indexName="productions"
			label={t("production", "Production")}
			getOption={({ objectID, title }) => {
				return {
					key: objectID,
					value: objectID,
					text: title,
				};
			}}
			filters={filters}
			{...props}
		/>
	);
}
