import React from "react";
import { Segment } from "semantic-ui-react";

import FlexRow from "../../../../../components/Flex/FlexRow";

import isNfgImprint from "../helpers/isNfgImprint";

import DeliveryInformation from "./DeliveryInformation";

export default function DeliverySegment({ hit }) {
	return (
		isNfgImprint({ hit }) && (
			<Segment style={{ width: "33%" }}>
				<FlexRow
					style={{
						justifyContent: "space-between",
						alignItems: "flex-start",
						gap: 15,
					}}
				>
					{(hit.zipFiles?.length ||
						hit.epubFiles?.length ||
						hit.wavFiles?.length ||
						hit.mp3Files?.length ||
						hit.pdfFiles?.length) && <DeliveryInformation hit={hit} />}
				</FlexRow>
			</Segment>
		)
	);
}
