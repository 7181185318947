import Bar from "./Bar";

const colors = {
	main: "#e5e5e5",
	recording: "#21ba45",
	editing: "#fbbd08",
	proofing: "#b5cc18",
	correcting: "#2185d0",
};

export default function ProgressBar({ recorded, edited, proofed, corrected }) {
	const containerStyle = {
		display: "grid",
		minWidth: "200px",
	};
	const rowStyle = {
		gridColumn: 1,
		gridRow: 1,
	};

	const isCompleted =
		recorded >= 1 && edited >= 1 && proofed >= 1 && (corrected === null || corrected.percentage === 1);
	const rows = [
		{ progress: 100, color: "main" },
		{ progress: recorded * 100, color: "recording" },
		{ progress: edited * 100, color: "editing" },
		{ progress: proofed * 100, color: "proofing" },
	];

	return (
		<div style={containerStyle}>
			{rows.map((row, index) => {
				return (
					<Bar
						key={index}
						index={index}
						rowStyle={rowStyle}
						row={row}
						isCompleted={isCompleted}
						colors={colors}
					/>
				);
			})}
		</div>
	);
}
