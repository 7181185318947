import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import PriceForm from "./PriceForm";
import ProductionPriceMemberSelect from "./ProductionPriceMemberSelect";

const part = ["user", "narrator", "subcontractor"];

export default function ProducerExpenseForm({ firstParty }) {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();

	const memberId = watch("expense.memberId");

	return (
		<>
			<Form.Field name="expense.memberId">
				<ProductionPriceMemberSelect firstParty={firstParty} />
			</Form.Field>

			{memberId && (
				<>
					<PriceForm part={part} prefix="expense" />
					<Form.Checkbox name="addRevenue" label={t("alsoAddRevenue")} rules={{ required: false }} />
				</>
			)}
		</>
	);
}
