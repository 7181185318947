import { Table } from "semantic-ui-react";

import TableHeaderCell from "./TableHeaderCell";

export default function TableHeader({ table }) {
	const { headerGroups } = table;

	return (
		<Table.Header>
			{headerGroups.map(({ headers, getHeaderGroupProps }) => (
				<Table.Row {...getHeaderGroupProps()}>
					{headers.map((column) => (
						<TableHeaderCell column={column} {...column.getHeaderProps()} />
					))}
				</Table.Row>
			))}
		</Table.Header>
	);
}
