import firebase from "firebase";

import getUncompletedTasksQuery from "astrid-firestore/src/api/tasks/queries/getUncompletedTasksQuery";
import { useCollectionCount } from "astrid-firestore/src/hooks";

import SidebarLayout from "../../../ui/layouts/SidebarLayout/SidebarLayout";

import useNeedsApproval from "../../hooks/useNeedsApproval";

import SuperAdminSidebar from "./SuperAdminSidebar";

export default function SuperAdminLayout() {
	const needsApproval = useNeedsApproval();

	const [uncompletedBiblioTaskCount] = useCollectionCount(getUncompletedTasksQuery(firebase));

	return (
		<SidebarLayout
			context={{ needsApproval, uncompletedBiblioTaskCount }}
			sidebar={
				<SuperAdminSidebar
					needsApproval={needsApproval}
					uncompletedBiblioTaskCount={uncompletedBiblioTaskCount}
				/>
			}
		/>
	);
}
