import { useEffect } from "react";

import loginToken from "astrid-helpers/src/loginToken";

export default function StudioRedirect() {
	useEffect(() => {
		const url = window.location.hostname.replace("astrid.fm", "studio.astrid.fm");
		loginToken().then((token) => (window.location = `https://${url}?token=${token}`));
	}, []);

	return "Redirecting...";
}
