import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import ConfirmationModalTrigger from "../../../ui/components/ConfirmationModal/ConfirmationModalTrigger";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import useSoftDelete from "../../../ui/hooks/useSoftDelete";

export default function CostDropdownMenu({ cost }) {
	const { t } = useTranslation();

	const softDelete = useSoftDelete();

	return (
		<DropdownMenu>
			{({ setLoading }) => (
				<>
					<Dropdown.Item as={Link} to={`/production/${cost.production?.id}`} disabled={!cost.production}>
						{t("toProduction", "To production")}
					</Dropdown.Item>

					<ConfirmationModalTrigger
						text={t("sureDeleteCost")}
						onConfirm={async () => {
							setLoading(true);
							softDelete(cost.ref);
							setLoading(false);
						}}
						trigger={<Dropdown.Item disabled={!cost.production}>{t("delete", "Delete")}</Dropdown.Item>}
					/>
				</>
			)}
		</DropdownMenu>
	);
}
