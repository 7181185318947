import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import { useOrganizationType } from "../../../../organizations/state/organizationType";

import OfferDeclineReason from "../../OfferDeclineReason/OfferDeclineReason";

export default function useOfferStatusPopupContent({ offer }) {
	const { t } = useTranslation();
	const organizationType = useOrganizationType();

	return useMemo(() => {
		if (offer.status === offerStatuses.DECLINED && offer.declineReason) {
			return (
				<>
					<b>{t("reason", "Reason")}:</b> <OfferDeclineReason offer={offer} />
				</>
			);
		}

		if (offer.status === offerStatuses.DRAFT && organizationType === "publisher") {
			return t("inquiryWillBeForwarded", "This inquiry will be forwarded by {{producerName}}", {
				producerName: offer.producer?.name,
			});
		}

		return null;
	}, [offer, organizationType, t]);
}
