import { useTranslation } from "react-i18next";

import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function useAdminReadersColumns() {
	const { t } = useTranslation();

	return [
		{
			id: "name",
			Header: t("name"),
			accessor: ({ name }) => name,
			Cell: ({ value, row }) => (
				<>
					<ImageAvatar src={row.original.avatarImg} style={{ marginRight: "1em" }} />
					<span>{value}</span>
				</>
			),
		},
		{
			id: "email",
			Header: t("email"),
		},
	];
}
