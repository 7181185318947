export function isNewSession({ before, after }) {
	return !before && after;
}

export function isChangedTimeOrStudio({ before, after }) {
	if (!before) {
		return false;
	}
	return before.studio !== after.studio || before.start !== after.start || before.end !== after.end;
}

export function isChangedRecorder({ before, after }) {
	if (!before) {
		return false;
	}
	return before.recorder !== after.recorder;
}

export function hasRemovedReader({ before, after }) {
	if (!before) {
		return false;
	}
	return before.reader.some((reader, i) => !after.reader.includes(reader));
}

export function hasAddedReader({ before, after }) {
	if (!before) {
		return false;
	}
	return after.reader.some((reader, i) => !before.reader.includes(reader));
}
