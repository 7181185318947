import { useTranslation } from "react-i18next";
import { useMatches } from "react-router-dom";

export default function useBreadcrumbs(props) {
	const { t } = useTranslation();

	const matches = useMatches();

	return matches
		.filter((match) => match.handle?.crumb)
		.map((match, index, crumbs) => ({
			linkTo: index < crumbs.length - 1 ? match.pathname : undefined,
			text: match.handle.crumb({ t, match, ...props }),
		}));
}
