import { useCallback } from "react";

import { firebase } from "astrid-firebase";
import useAsync from "astrid-hooks/src/useAsync";
import useHttpsCallable from "astrid-hooks/src/useHttpsCallable";

export default function useBiblioImprints({ limit = 10 } = {}) {
	const [callable, loading, error] = useHttpsCallable(firebase.functions(), "biblio-getImprints");

	const { data } = useAsync(
		useCallback(() => callable({ limit }), [callable, limit]),
		{},
	);

	return [data?.items, loading, error];
}
