import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, Label } from "semantic-ui-react";

import money from "astrid-helpers/src/money";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import usePriceUnits from "../../../../prices/hooks/usePriceUnits";
import { formatPrice } from "../../../../prices/utils/priceFormatters";

export default function CostInfo({ cost }) {
	const { t } = useTranslation();

	const units = usePriceUnits();
	const costTypes = useCostTypes();

	const defaultName = cost.earnings ? t("revenue", "Revenue") : t("cost", "Cost");

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{cost.price?.name || defaultName}</Card.Header>
				<Card.Description>
					{costTypes[cost.price?.type]}{" "}
					{cost.employee && <Label pointing="left">{t("employee", "Employee")}</Label>}
				</Card.Description>
			</Card.Content>
			{cost.date?.toDate && (
				<Card.Content extra>
					{t("date", "Date")}
					<Card.Meta>{cost.date.toDate().toLocaleString()}</Card.Meta>
				</Card.Content>
			)}
			<Card.Content extra>
				{t("cost", "Cost")}
				<Card.Meta>
					{money(cost.total, cost.price?.currency)}{" "}
					{cost.price.unit !== "fixed" && `(${formatPrice(cost.price)})`}
				</Card.Meta>
			</Card.Content>
			{cost.user && (
				<Card.Content extra>
					{t("user", "User")}
					<br />
					<Link to={`../../users/${cost.user?.id}`}>{cost.user?.name}</Link>
				</Card.Content>
			)}
			{cost.agreement && (
				<Card.Content extra>
					{t("agreement", "Agreement")}
					<br />
					<Link to={`/production/${cost.production?.id}`}>{cost.production?.title}</Link>
				</Card.Content>
			)}
			{cost.production && (
				<Card.Content extra>
					{t("production", "Production")}
					<br />
					<Link to={`/production/${cost.production?.id}`}>{cost.production?.title}</Link>
				</Card.Content>
			)}
			{cost.publisher && (
				<Card.Content extra>
					{t("publisher", "Publisher")}
					<br />
					<Link to={`../../publishers/${cost.publisher?.id}`}>{cost.publisher?.name}</Link>
				</Card.Content>
			)}
			{cost.price?.unit && (
				<Card.Content extra>
					{t("unit", "Unit")}
					<Card.Meta>{units[cost.price.unit]}</Card.Meta>
				</Card.Content>
			)}
			{cost.price?.currency && (
				<Card.Content extra>
					{t("currency", "Currency")}
					<Card.Meta>{cost.price.currency}</Card.Meta>
				</Card.Content>
			)}
			<Card.Content extra>
				{t("VAT", "VAT")}
				<Card.Meta>{cost.price.vat || 0}%</Card.Meta>
			</Card.Content>
		</Card>
	);
}
