import { db } from "astrid-firebase";

export default function useMyProductionsQuery({ organization, userId }) {
	const query = db.collection("productions").where("deleted", "==", false).where("status", "!=", "done");

	switch (organization?.type) {
		case "publisher":
			return query
				.where(organization.type, "==", organization.id)
				.where("publisherManagerIds", "array-contains", userId);
		case "producer":
			return query.where(organization.type, "==", organization.id).where("teamUserIds", "array-contains", userId);
		case "narrator":
			return query.where("teamUserIds", "array-contains", userId);
		default:
			return null;
	}
}
