import { Button } from "semantic-ui-react";

import CreateAppendixAction from "./components/CreateAppendixAction";

export default function AppendixesActions() {
	return (
		<Button.Group basic secondary>
			<CreateAppendixAction />
		</Button.Group>
	);
}
