import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Message } from "semantic-ui-react";

import isPendingApproval from "astrid-firestore/src/api/approval/isPendingApproval";
import { useDownloadURL } from "astrid-firestore/src/hooks";

export default function AgreementInfo({ agreement }) {
	const { t } = useTranslation();

	const [signedAgreementUrl] = useDownloadURL({
		bucket: agreement?.signedAgreementFile?.bucket,
		fullPath: agreement?.signedAgreementFile?.fullPath,
	});

	return (
		<Card fluid>
			<Card.Content>
				{isPendingApproval(agreement) && (
					<Message info>{t("waitingForApproval", "Waiting for approval")}</Message>
				)}

				<Card.Header>{agreement.name}</Card.Header>
				<Card.Description>
					<Link to={`../../users/${agreement.secondParty.id}`}>{agreement.secondParty?.name}</Link>
				</Card.Description>
			</Card.Content>

			{signedAgreementUrl && (
				<Card.Content extra>
					<Button icon as="a" target="_blank" href={signedAgreementUrl} labelPosition="left">
						<Icon name="file" />
						{t("signedDocument", "Signed document")}
					</Button>
				</Card.Content>
			)}

			{agreement.template && (
				<Card.Content>
					<Card.Description>
						<strong>{t("template", "Template")}:</strong>{" "}
						<Link
							to={`/producer/${agreement.producer.id}/admin/agreements/templates/${agreement.template.id}`}
						>
							{agreement.template.name}
						</Link>
					</Card.Description>
				</Card.Content>
			)}

			<Card.Content extra>
				<Card.Description>
					<strong>{t("created", "Created")}:</strong> {agreement.created?.toDate?.().toLocaleString()}
					<br />
					<strong>{t("expires", "Expires")}:</strong> {agreement.expires?.toDate?.().toLocaleString()}
				</Card.Description>
			</Card.Content>
		</Card>
	);
}
