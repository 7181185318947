import { useTranslation } from "react-i18next";

import ConfirmationButton from "../../../../ui/components/ConfirmationButton/ConfirmationButton";

import useFinalizeCosts from "../../../hooks/useFinalizeCosts";

export default function FinalizeCostsAction({ openCosts }) {
	const { t } = useTranslation();

	const { finalizeCosts, finalizableCosts, loading } = useFinalizeCosts(openCosts);

	return (
		<ConfirmationButton
			primary
			floated="right"
			loading={loading}
			disabled={loading || finalizableCosts.length === 0}
			onConfirm={finalizeCosts}
			text={t("confirmFinalizeAllCosts", "Are you sure you want to finalize all {{count}} costs?", {
				count: finalizableCosts.length,
			})}
		>
			{t("finalizeAmountCosts", "Finalize {{count}} costs", { count: finalizableCosts.length })}
		</ConfirmationButton>
	);
}
