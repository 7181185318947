import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

function getLanguageOptions({ user, orgId, orgType }) {
	switch (orgType) {
		case organizationTypes.PRODUCER:
			return user?.producers?.[orgId]?.languageOptions || [];
		case organizationTypes.PUBLISHER:
			return user?.publishers?.[orgId]?.languageOptions || [];
		default:
			return [];
	}
}

export default function hasLanguageOptions({ user, orgId, orgType, languageToMatch }) {
	const languageOptions = getLanguageOptions({ user, orgId, orgType });
	return languageOptions.includes(languageToMatch) || !languageOptions?.length;
}
