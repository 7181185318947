import { useMemo } from "react";

import mapDocument from "../utils/mapDocument";

import useDocumentOnce from "./useDocumentOnce";

export default function useDocumentDataOnce(query) {
	const [snapshot, loading, error] = useDocumentOnce(query);

	const value = useMemo(() => snapshot?.exists && mapDocument(snapshot), [snapshot]);

	return [value, loading, error];
}
