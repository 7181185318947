import { useOutletContext } from "react-router-dom";

import { ArticleVersion } from "astrid-firestore/src/api/articles/types/ArticleVersion";

import AdminArticleSettingsView from "../../views/AdminArticleView/AdminArticleSettingsView";

export default function AdminArticleVersionChange({ change, revert }) {
	const context = useOutletContext();

	const { article, version } = context;

	const alteredValues = ArticleVersion.parse({ after: version?.[change], before: article });

	return (
		<AdminArticleSettingsView
			{...context}
			key={change}
			locked={!!version}
			article={version?.[change] || article}
			alteredValues={revert ? alteredValues?.data : version?.data}
		/>
	);
}
