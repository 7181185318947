import React from "react";
import { Outlet } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";

const segmentStyle = {
	// overflow: "hidden",
	padding: "2em",
};

export default function SidebarLayout({ sidebar, ...props }) {
	return (
		<Segment style={segmentStyle}>
			<Grid>
				<Grid.Column width={3}>{sidebar}</Grid.Column>

				<Grid.Column width={13}>
					<Outlet {...props} />
				</Grid.Column>
			</Grid>
		</Segment>
	);
}
