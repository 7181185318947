const currentYear = new Date().getFullYear();

const years = [];

for (let i = currentYear; i > currentYear - 100; i--) {
	years.push(String(i));
}

export default function useYearOptions() {
	return years.map((year) => ({ key: year, text: year, value: year }));
}
