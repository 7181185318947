import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { ArticleBundle } from "./types/ArticleBundle";

const articleBundles = createFirestoreCollection("articleBundles", ArticleBundle, {
	preProcess: async ({ api }, { data }) => {
		// If we have article IDs, we want to fetch the articles
		if (data.articleIds) {
			data.articles = await api.articles.getAllByIds(data.articleIds);
		}

		// If we have articles, we want to extract the ISBNs and IDs
		if (data.articles) {
			data.isbns = data.articles.map((article) => article.isbn).filter(Boolean);
			data.articleIds = data.articles.map((article) => article.id);
		}

		return data;
	},

	sideEffects: async ({ api }, { transaction, after }) => {
		// Update or delete bundle data on all articles
		if (after.articles?.length > 0) {
			await api.articles.updateAll(after.articles, {
				transaction,
				getData: () => ({
					bundle: after.exists ? after : null,
				}),
			});
		}
	},
});

export default articleBundles;
