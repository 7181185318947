import { useFieldArray } from "react-hook-form";
import { Table } from "semantic-ui-react";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useAppendixesOptions from "../../hooks/useAppendixesOptions";

import AddAppendix from "./components/AddAppendix";
import Appendix from "./components/Appendix";

export default function AgreementAppendixForm({ type, part, name = "appendixes", producerId }) {
	const { fields, append, update, remove } = useFieldArray({
		name,
		rules: { validate: (fields) => fields?.length > 0 },
		shouldUnregister: true,
	});

	const [options, loading, error] = useAppendixesOptions({ type, part, producerId });

	return (
		<LoadingContext data={options} loading={loading} error={error}>
			<Table celled>
				<Table.Body>
					{fields.map((field, index) => (
						<Appendix
							key={field.id}
							name={`${name}.${index}`}
							index={index}
							options={options}
							producerId={producerId}
							onChange={(data) => update(index, data)}
							onRemove={() => remove(index)}
						/>
					))}

					<AddAppendix number={fields.length + 1} options={options} onAdd={append} />
				</Table.Body>
			</Table>
		</LoadingContext>
	);
}
