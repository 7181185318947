import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";

import UploadButton from "../../../../components/UploadButton";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import useFilterDocumentsByAdminProducers from "../../../authorization/hooks/useFilterDocumentsByAdminProducers";
import useOrganizationOptions from "../../../organizations/hooks/useOrganizationOptions";

import useAgreementPartOptions from "../../hooks/useAgreementPartOptions";
import useAgreementTypeOptions from "../../hooks/useAgreementTypeOptions";

import useAppendixForm from "./hooks/useAppendixForm";
import useSubmitAppendix from "./hooks/useSubmitAppendix";

export default function AppendixForm({ appendix, onClose }) {
	const { t } = useTranslation();

	const agreementTypeOptions = useAgreementTypeOptions();
	const agreementPartOptions = useAgreementPartOptions();

	const producerOptions = useFilterDocumentsByAdminProducers(useOrganizationOptions({ type: "producer" }), "value");

	const { field, set, data, valid, error, loading, handleSubmit } = useAppendixForm(appendix);

	const submitAppendix = useSubmitAppendix(appendix);

	return (
		<ModalPrompt
			size="tiny"
			error={error}
			header={appendix ? t("editAppendix", "Edit appendix") : t("createAppendix", "Create appendix")}
			loading={loading}
			disabled={!valid}
			onClose={onClose}
			onSave={handleSubmit(submitAppendix, false)}
		>
			<Form as="div">
				{!appendix && (
					<UploadButton
						onUpload={(e) => {
							const file = e.target.files?.[0];

							if (file) {
								set("file", file);

								if (!data.name) {
									set(
										"name",
										file.name.charAt(0).toUpperCase() +
											file.name.slice(1).split(".").slice(0, -1).join("."),
									);
								}
							}
						}}
						text={data.file ? data.file.name : t("selectFile", "Select file")}
					/>
				)}

				<Form.Input
					{...field("name")}
					value={data.name ? data.name.charAt(0).toUpperCase() + data.name.slice(1) : ""}
					label={t("name", "Name")}
				/>

				<Form.Select
					{...field("agreementTypes")}
					multiple
					options={agreementTypeOptions}
					label={t("selectableForAgreementTypes", "Selectable for agreement types")}
				/>

				<Form.Select
					{...field("agreementParts")}
					multiple
					options={agreementPartOptions}
					label={t("selectableForAgreementParts", "Selectable for agreement parts")}
				/>

				<Form.Select
					{...field("producerIds")}
					multiple
					options={producerOptions}
					label={t("selectableForProducers", "Selectable for producers")}
				/>
			</Form>
		</ModalPrompt>
	);
}
