import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import PrepareAgreementsView from "../views/PrepareAgreementsView/PrepareAgreementsView";

export default function PrepareAgreements() {
	const { templateId } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("agreements.send")) {
		return <Unauthorized />;
	}

	return <PrepareAgreementsView templateId={templateId} organization={organization} />;
}
