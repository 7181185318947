/** Production context */
import ISBN from "is-isbn";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import memoize from "lodash/memoize";
import trimStart from "lodash/trimStart";
import moment from "moment";
import { useEffect } from "react";
import create from "zustand";

import { db, firebase, getProductionPermissions } from "astrid-firebase";
import { toDate } from "astrid-web/src/helpers/fnc";

const isbnIsValid = memoize((isbn) => !isbn || ISBN.validate(isbn));

export const getAlerts = ({ id, production }) => {
	const alerts = {};

	// urls
	const infoModal = `/production/${id}/info/edit`;
	const articleTab = `/production/${id}/articles`;
	const audiobookTab = `/production/${id}/audiobook`;

	// title metadata
	if (!production.title) alerts.title = { tab: "info", url: infoModal };
	if (!production.language) alerts.language = { tab: "info", url: infoModal };
	if (!production.author?.length) alerts.author = { tab: "info", url: infoModal };

	const isTranslated =
		production.language && production.languageOriginal && production.language !== production.languageOriginal;
	if (isTranslated && !production.titleOriginal) alerts.titleOriginal = { tab: "info", url: infoModal };
	if (isTranslated && !production.translator?.length) alerts.translator = { tab: "info", url: infoModal };

	if (!production.genre?.length) alerts.genre = { tab: "info", url: infoModal };
	if (!production.synopsis) alerts.synopsis = { tab: "info", url: infoModal };

	if (!production.author?.length) alerts.author = { tab: "info", url: infoModal };

	const hasAudioArticle =
		typeof production.isbn === "string" ||
		production.deliveryParts?.length ||
		production.deliveryCD ||
		production.deliveryMP3CD;

	// article info
	if (!(typeof production.isbn === "string" || production.deliveryEbook || production.deliveryParts))
		alerts.articles = { tab: "articles", url: articleTab };

	if (
		hasAudioArticle &&
		typeof production.productionType === "undefined" &&
		production.producer &&
		production.status === "draft"
	)
		alerts.productionType = { tab: "articles", url: articleTab };

	if (["external", "backlist"].includes(production.productionType) && !production.reader?.length && hasAudioArticle) {
		alerts.reader = { tab: "articles", url: articleTab };
	}

	if (typeof production.isbn === "string" && !(production.isbn && isbnIsValid(production.isbn)))
		alerts.isbn = { tab: "articles", url: articleTab };

	// audiobook production info
	if (hasAudioArticle) {
		if (
			!production.deliveryDate ||
			(production.status === "draft" &&
				production.productionType !== "external" &&
				toDate(production.deliveryDate) < new Date(+Date.now() + 7 * 24 * 60 * 60 * 1000))
		)
			alerts.deliveryDate = { tab: "articles", url: articleTab };

		if (
			!production.script &&
			!production.scriptDate &&
			!["external", "backlist"].includes(production.productionType)
		)
			alerts.script = { tab: "audiobook", url: audiobookTab };
		if (production.scriptDate && !production.pages) alerts.pages = { tab: "audiobook", url: audiobookTab };
	}

	return alerts;
};

export const useProductionStore = create((set, get) => ({
	production: {},
	currentId: null,
	loading: true,
	loadProduction: ({ id, permissions, uid }) => {
		const { currentId } = get();

		// skip if already loaded
		if (id === currentId || !permissions) return;

		// go ahead
		console.log("Subscribe to production", id, permissions, uid);

		// set loading status and save unsubscribe reference
		set({ currentId: id, loading: true });

		// start listening to firestore document (unsubscribe function is returned)
		const unsubscribe = db
			.collection("productions")
			.doc(id)
			.onSnapshot((doc) => {
				const production = doc.data();
				const alerts = !production || production.status === "done" ? {} : getAlerts({ id, production });

				// merge in ID and update context state
				set({
					production: { id, ref: doc.ref, ...production },
					loading: false,
					permissions: permissions
						? getProductionPermissions({ state: { profile: { permissions }, user: { uid } } }, production)
						: {},
					alerts,
				});
			});

		// return unsubscribe function for useEffect hook
		return () => {
			set({ currentId: null, loading: true, production: {} });
			unsubscribe();
		};
	},

	options: {},
	setOptions: (options) => {
		set({ options });
	},
}));

const compare = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const handleChange = (e, data, { log, metadata } = {}) => {
	const { production } = useProductionStore.getState();

	// put single value in array
	if (!Array.isArray(data)) data = [data];

	// clone current data
	const oldData = cloneDeep(production);
	const newData = {};

	// loop all data fields
	data.forEach((dataPiece) => {
		const field = dataPiece.name;

		// don't store empty arrays
		if (Array.isArray(dataPiece.value) && !dataPiece.value.length) dataPiece.value = null;

		// convert to number if necessary
		if (dataPiece.type === "number" && dataPiece.value !== "") {
			dataPiece.value = +dataPiece.value;
			if (oldData[field] !== undefined) oldData[field] = +oldData[field];
		} else if (dataPiece.type === "date") {
			// convert to date if necessary
			const dateString = dataPiece.value;
			dataPiece.value = dateString ? new Date(dateString) : null;

			// publishers can't change delivery dates after production is accepted
			// if (field === "deliveryDate" && this.deliveryDateCheck(toDate(oldData[field]), dataPiece.value)) return;

			// invalid, probably 30 feb or 31 april or similar, set to first of next month
			if (!Number.isInteger(+dataPiece.value)) {
				dataPiece.value = moment(oldData[field].toDate()).add(1, "M").startOf("month").toDate();
			}
		} else if (typeof dataPiece.value === "string") {
			// trim space from start for strings (and end on blur)
			dataPiece.value = e && e.type === "blur" ? dataPiece.value.trim() : trimStart(dataPiece.value);
		}

		// if reader, add assigned production manager
		if (field === "reader") {
			let readers = dataPiece.value;

			// add single reader to array
			if (typeof readers === "string") {
				readers = [...(oldData.reader || [])];
				readers.push(dataPiece.value);
				dataPiece.value = readers;
			}

			if (Array.isArray(readers)) {
				// if array, look for managers (if it is not array, it is an array remove or union operation)
				// const manager = readers.reduce((prev, curr) => {
				// 	const readerManager = GETGET(
				// 		this.props.store,
				// 		"state.users." + curr + ".readerData.producerSettings." + oldData.producer + ".manager",
				// 	);
				// 	if (readerManager) prev.push(readerManager);
				// 	return prev;
				// }, []);
				// // if managers found, merge to unique array
				// if (manager.length) newData.manager = [...new Set([...(oldData.manager || []), ...manager])];
			}
		}

		// unsnooze?
		if (oldData.statusPlanningSnooze && ["deliveryDate", "reader"].includes(field))
			newData.statusPlanningSnooze = null;

		// if value changed, update it
		if (dataPiece.value !== oldData[field] && (dataPiece.value !== "" || oldData[field] || field === "isbn")) {
			newData[field] = dataPiece.value;

			// set total article metadata update date
			if (field === "isbn") newData.metaUpdatedTotalArticle = firebase.firestore.FieldValue.serverTimestamp();

			// get distributors again?
			// if (["language", "publisher"].includes(field)) this.getDistributors({ [field]: dataPiece.value });
		}
	});

	// if nothing changed, bail
	if (!Object.keys(newData).length) return;

	// metadata timestamp, used to keep track of distributor metadata expiration
	if (metadata) newData.metaUpdated = firebase.firestore.FieldValue.serverTimestamp();

	if (log) {
		// log instead?
		console.log(newData);
	} else if (e) {
		// debounce browser event updates (keystrokes)
		debouncedUpdate({ ...newData });
	} else {
		// update immediately
		updateFirestore({ ...newData });
	}
};

const updateFirestore = (data) => {
	const { currentId } = useProductionStore.getState();

	db.collection("productions")
		.doc(currentId)
		.update({
			...data,
			updated: firebase.firestore.FieldValue.serverTimestamp(),
		})
		.catch((err) => {
			console.log("Production data update error", err);
		});
};

//Soft delete
export const deleteProduction = (productionId, uid) => {
	db.collection("productions")
		.doc(productionId)
		.update({
			deleted: firebase.firestore.FieldValue.serverTimestamp(),
			deletedBy: uid,
			updated: firebase.firestore.FieldValue.serverTimestamp(),
		})
		.catch((err) => {
			console.log("Production data update error", err);
		});
};

const debouncedUpdate = debounce((data) => {
	// debounced method for keystrokes
	updateFirestore(data);
}, 1500);

const selector = (fields) => (state) =>
	fields
		? // only rerender on required fields
		  fields.reduce((prev, curr) => {
				prev[curr] = state.production[curr];
				return prev;
		  }, {})
		: // rerender on all fields
		  state.production;

export const useProduction = (productionId, { fields, store, options }) => {
	const production = useProductionStore(selector(fields), compare);
	const loadProduction = useProductionStore((state) => state.loadProduction);
	const loading = useProductionStore((state) => state.loading);
	const permissions = useProductionStore((state) => state.permissions);
	const alerts = useProductionStore((state) => state.alerts) || {};

	// load on mount, unsub on unmount
	const perms = store?.state?.profile?.permissions;
	const uid = store?.state?.user?.uid;
	useEffect(
		() => loadProduction({ id: productionId, permissions: perms, uid }),
		[loadProduction, productionId, perms, uid],
	);

	// full document, won't rerender
	const fullProduction = useProductionStore.getState().production;

	return { loading, production, handleChange, fullProduction, permissions, alerts };
};
