import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, isbn, string } from "../../../types/_types";

import { OrganizationRef } from "../../organizations/types/Organization";

import { ArticleRef } from "./ArticleRef";

export const ArticleBundle = FirestoreDocument.extend({
	name: string().min(3),
	isbns: array(isbn()),
	articles: array(ArticleRef),
	articleIds: array(string()),

	publisher: OrganizationRef,
}).merge(FirestoreLifecycle);
