import React from "react";
import { useTranslation } from "react-i18next";

import Notifications from "../../../notifications/components/Notifications/Notifications";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

export default function NotificationsWidget({ organization }) {
	const { t } = useTranslation();

	return (
		<HomeLayout.Widget icon="list ul" header={t("notifications", "Notifications")}>
			<Notifications organization={organization} />
		</HomeLayout.Widget>
	);
}
