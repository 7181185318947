import firebase from "firebase/app";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid } from "semantic-ui-react";

import notify from "astrid-firebase/src/notifications";
import { resolvedOfferStatuses } from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import createCommentOnOfferTodoData from "astrid-firestore/src/api/todos/data/offers/createCommentOnOfferTodoData";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import { setData } from "astrid-firestore/src/helpers";

import Discussion from "../../../../discussion/components/Discussion/Discussion";
import { getUrl } from "../../../../ui/utils/url";

import DownloadAgreementButton from "./DownloadAgreementButton";
import InquiryOfferActions from "./InquiryOfferActions";
import OfferActions from "./OfferActions";
import OfferDeclineReasonForm from "./OfferDeclineReasonForm";
import OfferInformation from "./OfferInformation";
import OfferMessage from "./OfferMessage";

export default function OfferContent({ offer, production }) {
	const { t } = useTranslation();
	const [showDeclineReasonForm, setShowDeclineReasonForm] = useState(false);

	if (showDeclineReasonForm) {
		return <OfferDeclineReasonForm offer={offer} onClose={() => setShowDeclineReasonForm(false)} />;
	}

	const onCommentCreated = (comment, participants) => {
		const route = offer.role === teamRoles.READER ? "readers" : "team";

		const todo = createCommentOnOfferTodoData(firebase, { offer, comment });

		setData(todo.ref, todo);

		notify(participants, (user) => {
			const lang = user.languageCode || "en";

			return {
				subject: t("newOfferCommentSubject", { lng: lang, title: production.title }),
				message: t("commentNotificationMessage", {
					lng: lang,
					sender: comment.user.name,
					message: comment.text,
					url: getUrl(`production/${production.id}/${route}`),
				}),
			};
		});
	};

	const visibleTo = [offer.producer.name, offer.discussionVisibleForPublisher && offer.publisher.name].filter(
		(o) => o,
	);

	return (
		<>
			<OfferMessage offer={offer} />
			<OfferInformation offer={offer} production={production}>
				<Grid style={{ marginTop: -10 }}>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						{offer.type !== offerTypes.INQUIRY && <DownloadAgreementButton offer={offer} />}
					</Grid.Column>

					<Grid.Column mobile={16} tablet={8} computer={8}>
						{offer.type === offerTypes.INQUIRY ? (
							<InquiryOfferActions offer={offer} onClickDecline={() => setShowDeclineReasonForm(true)} />
						) : (
							<OfferActions offer={offer} />
						)}
					</Grid.Column>
				</Grid>
			</OfferInformation>
			<Divider horizontal style={{ margin: "30px 0" }}>
				{t("discussion", "Discussion")}
			</Divider>
			<div style={{ maxWidth: 550, margin: "0 auto" }}>
				<Discussion
					disabled={resolvedOfferStatuses.includes(offer.status)}
					channel={`offers/${offer.id}`}
					notificationRef={offer.ref}
					initialParticipants={[offer.createdBy?.id]}
					onCommentCreated={onCommentCreated}
					visibleTo={visibleTo}
				/>
			</div>
		</>
	);
}
