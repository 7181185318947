import { useCallback, useMemo, useState } from "react";

export default function useHttpsCallable(functions, name) {
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);

	const callable = useMemo(() => functions.httpsCallable(name), [name]);

	const callCallable = useCallback(
		async (data) => {
			setLoading(true);
			setError(undefined);

			try {
				return await callable(data);
			} catch (error) {
				setError(error);
				return error;
			} finally {
				setLoading(false);
			}
		},
		[callable],
	);

	return [callCallable, loading, error];
}
