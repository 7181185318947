import ViewAgreementDropdownItem from "../../../../agreements/components/AgreementDropdownMenu/items/ViewAgreementDropdownItem";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import CancelOfferDropdownItem from "../items/CancelOfferDropdownItem";
import DeleteOfferDropdownItem from "../items/DeleteOfferDropdownItem";

export default function WorkOrderDropdownMenu({ offer }) {
	return (
		<DropdownMenu>
			{({ setLoading }) => (
				<>
					<ViewAgreementDropdownItem agreement={offer.agreement} setLoading={setLoading} />
					<CancelOfferDropdownItem offer={offer} setLoading={setLoading} />
					<DeleteOfferDropdownItem offer={offer} setLoading={setLoading} />
				</>
			)}
		</DropdownMenu>
	);
}
