import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import AddReaderAction from "./AddReaderAction";
import DraggableVoice from "./DraggableVoice";
import DroppableVoice from "./DroppableVoice";
import VoiceName from "./VoiceName";
import VoiceOffer from "./VoiceOffer";

export default function Voice({ voice, production, allReaderIds }) {
	return (
		<>
			<VoiceName voice={voice} production={production} />

			<DroppableVoice droppableId={voice.id} isDropDisabled={voice.assigned}>
				{voice.offers.map((offer, index) => (
					<DraggableVoice
						key={offer.id}
						draggableId={offer.secondParty.id}
						index={index}
						isDragDisabled={offer.disabled || offer.status !== offerStatuses.INITIAL}
					>
						<VoiceOffer offer={offer} production={production} />
					</DraggableVoice>
				))}
			</DroppableVoice>

			{!voice.assigned && <AddReaderAction voice={voice} production={production} allReaderIds={allReaderIds} />}
		</>
	);
}
