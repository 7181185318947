import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import notify from "astrid-firebase/src/notifications";
import updateNotificationData from "astrid-firestore/src/api/notifications/updateNotificationData";
import { resolvedOfferStatuses } from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerHasNotification from "astrid-firestore/src/api/offers/offerHasNotification";
import createCommentOnOfferTodoData from "astrid-firestore/src/api/todos/data/offers/createCommentOnOfferTodoData";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import { setData } from "astrid-firestore/src/helpers";

import { getUrl } from "../../../ui/utils/url";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";
import DiscussionModal from "../../../discussion/components/DiscussionModal/DiscussionModal";
import { useOrganizationType } from "../../../organizations/state/organizationType";
import useTeamRoleTranslations from "../../../team/hooks/useTeamRoleTranslations";

export default function OfferDiscussionIcon({ offer }) {
	const { t } = useTranslation();
	const teamRoleTranslations = useTeamRoleTranslations();
	const user = useProfile();
	const hasRight = useHasRight();
	const organizationType = useOrganizationType();

	const [openModal, setOpenModal] = useState(false);

	const modalTitle = `${offer.secondParty?.name} (${teamRoleTranslations[offer.role]})`;

	if (!offer.discussionVisibleForPublisher && !hasRight("offers.send")) {
		return null;
	}

	const onCommentCreated = (comment, participants) => {
		const route =
			offer.role === teamRoles.READER ? `narrator/${offer.secondParty.id}` : `producer/${offer.producer.id}`;

		const todo = createCommentOnOfferTodoData(firebase, { offer, comment, organizationType });

		setData(todo.ref, todo);

		notify(participants, (user) => {
			const lang = user.languageCode || "en";

			return {
				subject: t("newOfferCommentSubject", { lng: lang, title: offer.production.title }),
				message: t("commentNotificationMessage", {
					lng: lang,
					sender: comment.user.name,
					message: comment.text,
					url: getUrl(`${route}/my-productions/offers/${offer.id}`),
				}),
				sendOnStage: true,
			};
		});
	};

	const visibleTo = [offer.producer.name, offer.discussionVisibleForPublisher && offer.publisher.name].filter(
		(o) => o,
	);

	return (
		<>
			<Icon
				color={offerHasNotification(offer, user.id) ? "blue" : "grey"}
				name={!offerHasNotification(offer, user.id) ? "comment outline" : "comment"}
				onClick={() => {
					setOpenModal(true);
					updateNotificationData(firebase, { document: offer, user });
				}}
			/>

			{openModal && (
				<DiscussionModal
					disabled={resolvedOfferStatuses.includes(offer.status)}
					title={modalTitle}
					channel={`offers/${offer.id}`}
					notificationRef={offer.ref}
					initialParticipants={[offer.secondParty?.id]}
					onClose={() => setOpenModal(false)}
					onCommentCreated={onCommentCreated}
					visibleTo={visibleTo}
				/>
			)}
		</>
	);
}
