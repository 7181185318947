import React from "react";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminSerieMenuOptions from "../hooks/useAdminSerieMenuOptions";

export default function AdminSerieHeader({ serie }) {
	const menuOptions = useAdminSerieMenuOptions({ serie });

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<Header style={{ margin: 0 }}>{serie.name}</Header>
		</AdminPageHeader>
	);
}
