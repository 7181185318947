import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import OrganizationDropdownMenu from "../components/OrganizationDropdownMenu";

export default function useOrganizationsColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "name",
				Header: t("name"),
				collapsing: true,
				filter: "text",
			},
			{
				id: "languageDefault",
				Header: t("languageDefault"),
				accessor: ({ languageDefault }) => languageName(languageDefault),
			},
			{
				id: "menu",
				textAlign: "right",
				collapsing: true,
				disableSortBy: true,
				Cell: ({ row }) => <OrganizationDropdownMenu organization={row.original} />,
			},
		],
		[t],
	);
}
