import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

export default function PipelineStepForEachCheckbox({ name }) {
	const { t } = useTranslation();

	return (
		<Form.Checkbox label={t("forEachItem", "For each item")} name={`${name}.forEach`} shouldUnregister={false} />
	);
}
