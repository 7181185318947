import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TodoPriorityDropdown from "astrid-web/src/features/home/components/TodosWidget/components/TodoPriorityDropdown";

import Todos from "../../../todos/components/Todos/Todos";
import useTodos from "../../../todos/hooks/useTodos";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import TodoClearFiltersIcon from "./components/TodoClearFiltersIcon";
import TodoOrderIcon from "./components/TodoOrderIcon";
import TodoResolvedIcon from "./components/TodoResolvedIcon";
import TodoTypeDropdown from "./components/TodoTypeDropdown";
import TodoUserDropdown from "./components/TodoUserDropdown";

export default function TodosWidget({ organization }) {
	const { t } = useTranslation();

	const [type, setType] = useState(null);
	const [userId, setUserId] = useState(null);
	const [priority, setPriority] = useState(null);
	const [order, setOrder] = useState("desc");
	const [showResolved, setShowResolved] = useState(false);

	const { sortedTodos, filteredTodos, loading, error } = useTodos({
		type,
		order,
		showResolved,
		userId,
		priority,
		organization,
	});

	const activeFilters = type || userId || priority || order === "asc" || showResolved;

	const onClear = () => {
		setType(null);
		setUserId(null);
		setPriority(null);
		setOrder("desc");
		setShowResolved(false);
	};

	return (
		<HomeLayout.Widget
			icon="tasks"
			color="blue"
			header={t("todos", "Todos")}
			toolbar={
				<>
					<TodoTypeDropdown type={type} todos={sortedTodos} onClick={setType} />

					<TodoUserDropdown userId={userId} todos={sortedTodos} onClick={setUserId} />

					<TodoPriorityDropdown priority={priority} todos={sortedTodos} onClick={setPriority} />

					<TodoResolvedIcon showResolved={showResolved} onClick={() => setShowResolved(!showResolved)} />

					<TodoOrderIcon
						order={order}
						onClick={() => setOrder((order) => (order === "desc" ? "asc" : "desc"))}
					/>

					<TodoClearFiltersIcon onClick={onClear} active={activeFilters} />
				</>
			}
		>
			<Todos todos={filteredTodos} loading={loading} error={error} />
		</HomeLayout.Widget>
	);
}
