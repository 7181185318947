import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { getTeamMemberOpenCost } from "../../../utils/teamHelper";

import TeamMemberForm from "../../TeamMemberForm/TeamMemberForm";

export default function EditMemberDropdownItem({ member, production, setError, setLoading }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const onClick = async () => {
		setLoading(true);

		const openCost = await getTeamMemberOpenCost({ member, production });

		if (openCost?.openAmount) {
			setError(
				t(
					"memberHasOpenCosts",
					"The member you are trying to edit has open costs. You can edit this member after either finalizing or deleting the cost.",
				),
			);
		} else {
			setOpen(true);
		}

		setLoading(false);
	};

	return (
		<>
			<Dropdown.Item text={t("edit", "Edit")} onClick={onClick} />
			{open && <TeamMemberForm member={member} production={production} onClose={() => setOpen(false)} />}
		</>
	);
}
