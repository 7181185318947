import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useBiblioTasksMenuOptions({ uncompletedTaskCount }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	return [
		{
			to: "uncompleted",
			active: !pathname.endsWith("/completed"),
			text: t("uncompleted", "Uncompleted"),
			label: uncompletedTaskCount || undefined,
			labelColor: "red",
		},
		{
			to: "completed",
			active: pathname.endsWith("/completed"),
			text: t("completed", "Completed"),
		},
	];
}
