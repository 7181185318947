import { useState } from "react";
import { Icon, Accordion as SemanticAccordion } from "semantic-ui-react";

import Flex from "../../../../components/Flex/Flex";

export default function Accordion({ style = {}, borderRadius = 0, ...props }) {
	return <SemanticAccordion styled fluid style={{ borderRadius, ...style }} {...props} />;
}

export function AccordionTitle({ children, active, icon, disabled, onClick, hoverRow, style, ...props }) {
	const [hover, setHover] = useState(false);

	return (
		<SemanticAccordion.Title
			disabled={disabled}
			onClick={!disabled ? onClick : undefined}
			onMouseEnter={!disabled && hoverRow ? () => setHover(true) : undefined}
			onMouseLeave={!disabled ? () => setHover(false) : undefined}
			style={{
				padding: 15,
				cursor: !disabled && onClick ? "pointer" : "default",
				background: hover ? "#DDDDDD" : "white",
				...style,
			}}
			{...props}
		>
			<Flex style={{ gap: 12 }}>
				{(icon || active !== undefined) && (
					<div>
						<Icon name={icon || (active ? "chevron down" : "chevron right")} />
					</div>
				)}
				<Flex style={{ gap: 12 }}>{children}</Flex>
			</Flex>
		</SemanticAccordion.Title>
	);
}

export function AccordionContent({ children, ...props }) {
	return <SemanticAccordion.Content {...props}>{children}</SemanticAccordion.Content>;
}

export function AccordionFooter({ children, style = {}, ...props }) {
	return (
		<AccordionTitle style={{ background: "#f9fafb", ...style }} {...props}>
			{children}
		</AccordionTitle>
	);
}

Accordion.Title = AccordionTitle;
Accordion.Footer = AccordionFooter;
Accordion.Content = AccordionContent;
