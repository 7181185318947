import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAgreementParts from "../../../../agreements/hooks/useAgreementParts";
import useAgreementTypes from "../../../../agreements/hooks/useAgreementTypes";
import useApprovalStatusTranslations from "../../../../approval/hooks/useApprovalStatusTranslations";

export default function useAdminAgreementTemplatesColumns() {
	const { t } = useTranslation();

	const agreementTypes = useAgreementTypes();
	const agreementParts = useAgreementParts();
	const approvalStatuses = useApprovalStatusTranslations();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: ({ created }) => created?.toDate().getTime() || "",
				Cell: ({ value }) => new Date(value).toLocaleString(),
			},
			{
				id: "name",
				filter: "text",
				Header: t("name"),
			},
			{
				id: "type",
				filter: "select",
				Header: t("type", "Type"),
				accessor: ({ type }) => agreementTypes[type],
			},
			{
				id: "part",
				filter: "select",
				Header: t("part", "Part"),
				accessor: ({ part }) => agreementParts[part],
			},
			{
				id: "approval",
				Header: t("approval", "Approval"),
				accessor: ({ approval }) => approvalStatuses[approval?.status],
			},
		],
		[agreementParts, agreementTypes, approvalStatuses, t],
	);
}
