export function getNameWithLastNameInitial(name) {
	if (!name) {
		return "";
	}

	const names = name.split(" ");

	const firstName = names[0];

	if (names.length === 1) {
		return firstName;
	}

	const lastNameInitial = names[1].charAt(0);

	return firstName + " " + lastNameInitial;
}
