import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import money from "astrid-helpers/src/money";
import msToTime from "astrid-helpers/src/msToTime";

import useCostTypes from "../../../../costs/hooks/useCostTypes";

export default function useAccountCostsColumns() {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: ({ created }) => created?.toDate().getTime() || "",
				Cell: ({ value }) => new Date(value).toLocaleString(),
			},
			{
				id: "price.type",
				filter: "select",
				Header: t("type", "Type"),
				accessor: ({ price }) => costTypes[price.type],
			},
			{
				id: "production.title",
				filter: "text",
				Header: t("production"),
				Cell: ({ row, value }) => <Link to={`/production/${row.original.production.id}`}>{value}</Link>,
			},
			{
				id: "producer.name",
				filter: "text",
				Header: t("producer", "Producer"),
			},
			{
				id: "total",
				Header: t("total", "Total"),
				accessor: ({ total, price, amountProduced }) =>
					total ? money(total, price.currency) : msToTime(amountProduced * 60 * 1000, false),
			},
		],
		[costTypes, t],
	);
}
