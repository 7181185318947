import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import featureFlags from "../../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useHasRole from "../../../../authorization/hooks/useHasRole";
import { useOrganizationId } from "../../../../organizations/state/organizationId";
import { useOrganizationType } from "../../../../organizations/state/organizationType";

export default function useMainMenuOptions() {
	const { t } = useTranslation();

	const hasRole = useHasRole();
	const hasRight = useHasRight();
	const location = useLocation();
	const orgId = useOrganizationId();
	const orgType = useOrganizationType();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { pathname } = location;

	return [
		{
			to: "/reader",
			icon: "address card outline",
			text: t("narrator"), //"Uppläsare",
			hidden: !hasRight("readerRegistry.view"),
			active: pathname.startsWith("/reader"),
		},
		{
			to: `/${orgType}/${orgId}/my-productions/current`,
			icon: "clipboard list",
			text: t("myProductions"),
			hidden: !orgId || !(hasRight("listProducerProductions") || hasRight("listPublisherProductions")),
			active: pathname.includes(`/${orgType}/${orgId}/my-productions`),
		},
		{
			to: `/${orgType}/${orgId}/list-productions`,
			icon: "microphone",
			text: t("productions"),
			hidden: !orgId || !hasRight("list-productions.beta") || orgType === organizationTypes.NARRATOR,
			active: pathname.includes(`${orgId}/list-productions`),
		},
		{
			to: `/${orgType}/${orgId}/calendar`,
			icon: "calendar alternate outline",
			text: t("calendar"), //"Kalender",
			hidden: !hasRight("viewCalendar") || orgType === organizationTypes.PUBLISHER,
			active: pathname.includes("/calendar"),
		},
		{
			to: `/${orgType}/${orgId}/articles`,
			icon: "book",
			text: t("articles", "Articles"),
			hidden: !hasRight("articles.view") || !hasFlatteningFeature || orgType !== organizationTypes.PUBLISHER, // TODO: Remove feature flag check
			active: pathname.startsWith("/articles"),
		},
		{
			to: "/publication",
			icon: "world",
			text: t("publication"), //"Utgivning",
			hidden: !hasRole("owner"),
			active: pathname.startsWith("/publication"),
		},
		{
			to: `/${orgType}/${orgId}/create-production`,
			icon: "write",
			text: t("create"),
			hidden: !hasRight("createProduction") || orgType === organizationTypes.NARRATOR,
			active: pathname.includes("create-production"),
		},
	];
}
