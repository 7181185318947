import { nullable, number, object, string } from "./_types";

export const StorageFile = object({
	bucket: string(),
	contentType: nullable(string()),
	fullPath: string(),
	generation: nullable(string()),
	md5Hash: nullable(string()),
	name: string(),
	size: nullable(number()),
	type: nullable(string()),
	url: string(),
});
