import { useMemo } from "react";

export default function useOrdersFromCosts(costs) {
	return useMemo(() => {
		const filteredCosts = costs.filter(({ firstParty, secondParty }) => firstParty && secondParty);

		const costsByUniqueKey = filteredCosts.reduce((object, cost) => {
			const firstPartyKey = cost.firstParty.vendor?.id || cost.firstParty.id;
			const secondPartyKey = cost.secondParty.vendor?.id || cost.secondParty.id;

			const uniqueKey = `${firstPartyKey}-${secondPartyKey}-${cost.price?.currency}`;

			return {
				...object,
				[uniqueKey]: object[uniqueKey] ? [...object[uniqueKey], cost] : [cost],
			};
		}, {});

		return Object.values(costsByUniqueKey);
	}, [costs]);
}
