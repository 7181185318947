import { parsePhoneNumber } from "awesome-phonenumber";
import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import { getCountryName } from "astrid-helpers/src/countries";

export default function AgreementParties({ agreement }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("parties", "Parties")}</Card.Header>
			</Card.Content>

			{[agreement.firstParty.vendor, agreement.secondParty.vendor]
				.filter((vendor) => vendor)
				.map((vendor) => (
					<Card.Content key={vendor.id}>
						<Card.Description>
							{vendor.name} {vendor.organizationNumber && `(${vendor.organizationNumber})`}
						</Card.Description>
						{vendor.street && <Card.Meta>{vendor.street}</Card.Meta>}
						{vendor.secondaryStreet && <Card.Meta>{vendor.secondaryStreet}</Card.Meta>}
						{vendor.zip && (
							<Card.Meta>
								{vendor.zip} {vendor.city}
							</Card.Meta>
						)}
						{vendor.state && <Card.Meta>{vendor.state}</Card.Meta>}
						{vendor.country && <Card.Meta>{getCountryName(vendor.country)}</Card.Meta>}
						{vendor.phone && (
							<Card.Meta>{parsePhoneNumber(vendor.phone).getNumber("international")}</Card.Meta>
						)}
					</Card.Content>
				))}
		</Card>
	);
}
