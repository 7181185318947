import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRole from "../../authorization/hooks/useHasRole";

import SuperAdminLayout from "../layouts/SuperAdminLayout/SuperAdminLayout";

export default function SuperAdmin(props) {
	const hasRole = useHasRole();

	if (!hasRole("owner", "admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminLayout {...props} />;
}
