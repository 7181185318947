import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import RemoveVoiceAction from "./RemoveVoiceAction";

export default function VoiceActions({ voice, production }) {
	return (
		<DropdownMenu>
			{(context) => (
				<>
					<RemoveVoiceAction voice={voice} production={production} {...context} />
				</>
			)}
		</DropdownMenu>
	);
}
