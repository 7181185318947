import { useEffect, useState } from "react";

import useInterval from "astrid-hooks/src/useInterval";

import packageInfo from "../../../../package.json";

console.log("Installed version: ", packageInfo.version);

export default function useUpdateAvailable() {
	const [updateAvailable, setUpdateAvailable] = useState(false);

	const checkUpdate = async () => {
		try {
			const response = await fetch("/version.json?" + +new Date());

			if (response.ok) {
				const json = await response.json();

				//show update meassage
				if (json.version !== packageInfo.version) {
					console.log("New version: ", json.version);
					setUpdateAvailable(true);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		(async () => {
			await checkUpdate();
		})();
	}, []);

	useInterval(checkUpdate, 60 * 1000, true);

	return updateAvailable;
}
