import { priceTypeFromRole } from "astrid-firestore/src/api/prices/utils/priceMappers";

export function filterPriceType(role) {
	return (price) => !role || price.type === priceTypeFromRole[role];
}

export function filterPriceAvailability(uid) {
	return (price) => !price.users || price.users.includes(uid);
}

export function filterPriceUseability() {
	return (price) => !price.maxUses || price.uses < price.maxUses;
}
