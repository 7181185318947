import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Menu, Segment } from "semantic-ui-react";

export default function TabMenuLayout({ panes, ...props }) {
	const { pathname } = useLocation();

	return (
		<>
			<Menu attached="top" tabular>
				{panes
					.filter((pane) => !pane.hidden)
					.map((pane) => {
						const active = pathname.includes(pane.path);
						return (
							<Menu.Item as={Link} to={pane.path} key={pane.path} active={active}>
								{pane.text}
								{pane.label}
							</Menu.Item>
						);
					})}
			</Menu>
			<Segment attached="bottom">
				<Outlet {...props} />
			</Segment>
		</>
	);
}
