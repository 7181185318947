import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../ui/components/Select/Select";

import useTeamUserOptions from "../../../team/hooks/useTeamUserOptions";

export default function OfferUserSelect({ role, production, ...props }) {
	const { t } = useTranslation();

	const [options, loading, error] = useTeamUserOptions({
		role,
		production,
	});

	// filter out employees
	const filteredOptions = useMemo(() => options.filter((option) => !option.employee), [options]);

	return (
		<Select
			label={t("user", "User")}
			options={filteredOptions}
			loading={loading}
			error={error?.message || error}
			search
			deburr
			{...props}
		/>
	);
}
