import { useMemo } from "react";

import { getOpenCosts } from "astrid-firestore/src/api/costs/utils/openCosts";

import { sortOpenCosts } from "../utils/costSorters";

export default function useOpenCosts({ costs, exchangeRate, prices, production, users }) {
	return useMemo(() => {
		if (exchangeRate && prices && users && production && costs) {
			return getOpenCosts({ costs, exchangeRate, prices, production, users }).sort(sortOpenCosts);
		}

		return [];
	}, [costs, exchangeRate, prices, production, users]);
}
