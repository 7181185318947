import { useTranslation } from "react-i18next";

import Flex from "../../../../components/Flex/Flex";

import NamedPrice from "../../../prices/components/NamedPrice/NamedPrice";

import useTeamRoleTranslations from "../../hooks/useTeamRoleTranslations";

function Price({ price }) {
	const { t } = useTranslation();

	if (!price || price.price === null) {
		return t("hiddenPrice", "Hidden price");
	}

	if (price === "default") {
		return t("defaultPrice", "Default price");
	}

	return <NamedPrice price={price} />;
}

export default function TeamPrice({ price, role, showCa }) {
	const { t } = useTranslation();
	const roles = useTeamRoleTranslations();

	return (
		<Flex justifyContent="flex-start" gap={2}>
			{role && `${roles[role]} - `}
			{showCa && price && t("circa", "ca.")}
			<div style={{ color: !price?.price ? "#db2828" : null }}>
				<Price price={price} />
			</div>
		</Flex>
	);
}
