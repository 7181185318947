import React from "react";
import { useOutletContext } from "react-router-dom";
import { Segment } from "semantic-ui-react";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import ProductionReadersView from "../views/ProductionReadersView/ProductionReadersView";

export default function ProductionReaders() {
	const { production } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("productions.readers")) {
		return (
			<Segment basic padded>
				<Unauthorized />
			</Segment>
		);
	}

	return (
		<Segment basic padded>
			<ProductionReadersView production={production} />
		</Segment>
	);
}
