import { Icon } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Popup from "../../../../ui/components/Popup/Popup";

export default function TodoClearFiltersIcon({ active, onClick }) {
	const { t } = useTranslation();

	return (
		<div>
			<Popup
				disabled={!active}
				content={t("clearFilters", "Clear filters")}
				trigger={<Icon link={!!active} name="trash" onClick={onClick} />}
			/>
		</div>
	);
}
