import React from "react";

import Form from "../../../../forms/components/Form/Form";

import PriceForm from "./PriceForm";
import ProductionPriceMemberSelect from "./ProductionPriceMemberSelect";

export default function PublisherExpenseForm({ firstParty }) {
	const { watch } = Form.useFormContext();

	const memberId = watch("expense.memberId");

	return (
		<>
			<Form.Field name="expense.memberId">
				<ProductionPriceMemberSelect firstParty={firstParty} />
			</Form.Field>

			{memberId && <PriceForm part="narrator" prefix="expense" showVAT />}
		</>
	);
}
