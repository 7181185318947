import { Accordion, Message, Segment } from "semantic-ui-react";

import TaskTitle from "./TaskTitle";

export default function Task({ active, task, data, ...props }) {
	return (
		<>
			<TaskTitle active={active} task={task} {...props} />

			{active && (
				<Accordion.Content active>
					{task.error && <Message negative>{task.error}</Message>}

					<Segment inverted style={{ marginBottom: 7 }}>
						<pre>{JSON.stringify(data, null, "  ")}</pre>
					</Segment>
				</Accordion.Content>
			)}
		</>
	);
}
