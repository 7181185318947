import { Checkbox } from "semantic-ui-react";

import stopPropagation from "astrid-helpers/src/stopPropagation";

export default function checkboxes(selectable) {
	return (hooks) => {
		const isRowDisabled = (row) =>
			row.original.disabled || (typeof selectable === "function" && !selectable(row.original));

		hooks.getToggleAllPageRowsSelectedProps = (props, { instance }) => [
			props,
			{
				onChange(e) {
					const { page } = instance;

					page.filter((row) => !isRowDisabled(row)).forEach(({ id }) => {
						instance.toggleRowSelected(id, e.target.checked);
					});
				},
				style: {
					cursor: "pointer",
				},
				checked: instance.isAllPageRowsSelected,
				title: "Toggle All Current Page Rows Selected",
				indeterminate: Boolean(
					!instance.isAllPageRowsSelected &&
						instance.page?.some(({ id }) => instance.state.selectedRowIds[id]),
				),
			},
		];

		hooks.visibleColumns.push((columns) => [
			{
				id: "selection",
				collapsing: true,
				groupByBoundary: true,
				Cell: ({ row }) => (
					<Checkbox
						style={{ display: "block" }}
						disabled={isRowDisabled(row)}
						onClick={stopPropagation}
						{...row.getToggleRowSelectedProps()}
					/>
				),
				Header: ({ getToggleAllPageRowsSelectedProps }) => (
					<Checkbox {...getToggleAllPageRowsSelectedProps()} />
				),
			},
			...columns,
		]);
	};
}
