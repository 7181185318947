import isEqual from "lodash/isEqual";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";
import { useParams } from "react-router-dom";
import { Accordion, Button, Header, Input, Menu, Message } from "semantic-ui-react";

import { db, firebase } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";

import { useProfile } from "../../../../authentication/state/profile";

import { ClearStateContext } from "../contexts/ClearStateProvider";
import useUserLanguages from "../hooks/useUserLanguages";

const invalidCharacters = ["~", "*", "/", "[", "]"];

function isPresetActive({ indexUiState, preset }) {
	const { refinementList = null, range = null, sortBy = null } = indexUiState;
	const mappedState = { refinementList, range, sortBy };
	if (isEqual(mappedState, preset)) {
		return preset;
	}
}

export default function Presets() {
	const { t } = useTranslation();
	const profile = useProfile();
	const { clearKey } = useContext(ClearStateContext);
	const userLanguages = useUserLanguages();
	const { orgId } = useParams();
	const presets = profile?.listProductionsPresets?.[orgId];
	const { indexUiState, setIndexUiState } = useInstantSearch();
	const [isOpen, setOpen] = useState(true);
	const [activePreset, setActivePreset] = useState(undefined);
	const [presetName, setPresetName] = useState("");
	const [showEditPreset, setShowEditPreset] = useState(!Object.keys(presets || {}).length);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (clearKey) {
			setActivePreset(undefined);
			setPresetName("");
			setError(undefined);
		}
	}, [clearKey]);

	useEffect(() => {
		const activePreset = Object.keys(presets || {}).find((presetKey) => {
			return isPresetActive({ indexUiState, preset: presets[presetKey] });
		});

		if (activePreset) {
			setActivePreset(activePreset);
			setPresetName(activePreset);
		} else {
			setActivePreset(undefined);
			setPresetName("");
		}
	}, [indexUiState, presets]);

	const selectPreset = ({ presetKey, clear }) => {
		if (clear) {
			setIndexUiState({ refinementList: { language: userLanguages } });
			setError(undefined);
		} else {
			setIndexUiState(presets?.[presetKey]);
		}
	};

	return (
		<Accordion as={Menu} vertical fluid>
			<Menu.Item color="green">
				<Accordion.Title
					active={isOpen}
					onClick={() => setOpen(!isOpen)}
					content={
						<div>
							<Header as="h4">{t("presets", "Presets")}</Header>
						</div>
					}
				/>
				<Accordion.Content
					active={isOpen}
					content={
						<>
							<Button
								basic
								circular
								style={{ marginRight: 10 }}
								icon={showEditPreset ? "hide" : "pencil"}
								onClick={() => setShowEditPreset(!showEditPreset)}
							/>
							{Object.keys(presets || {}).length ? (
								Object.keys(presets).map((presetKey) => {
									const isActive = activePreset === presetKey;
									return (
										<Button
											key={presetKey}
											basic={!isActive}
											style={{ marginRight: 8, marginTop: 8 }}
											content={presetKey}
											color={isActive ? "teal" : undefined}
											onClick={() => selectPreset({ presetKey, clear: isActive })}
										/>
									);
								})
							) : (
								<i>{t("missingPresets", "You do not have any stored presets")}</i>
							)}
							{showEditPreset && (
								<Input
									style={{ marginTop: 20 }}
									fluid
									error={!!error}
									action
									onChange={(e) => {
										setPresetName(e.target.value);
										setError(false);
									}}
									value={presetName}
									placeholder={t("presetName", "Name")}
								>
									<input />
									{(presetName !== activePreset || !activePreset) && (
										<Button
											color="teal"
											content={t("save", "Save")}
											onClick={() => {
												const { refinementList, range, sortBy } = indexUiState;
												const onlyDefaultLang = refinementList?.language?.every?.((lang) =>
													userLanguages?.includes?.(lang),
												);
												const { language, ...rest } = refinementList || {};
												const hasRefined =
													!onlyDefaultLang ||
													!!Object.keys(rest)?.length ||
													!!range ||
													!!sortBy;
												if (!presetName || !hasRefined) {
													setError(true);
												} else if (
													invalidCharacters.some((char) => presetName.includes(char))
												) {
													setError({
														content: `${t(
															"invalidCharacters",
															"Invalid characters",
														)}: ${invalidCharacters.join(" ")}`,
														pointing: "below",
													});
												} else {
													let data = {
														[`listProductionsPresets.${orgId}.${presetName}`]: {
															refinementList: refinementList || null,
															range: range || null,
															sortBy: sortBy || null,
														},
													};

													if (activePreset) {
														// just a name change delete old key.
														data[`listProductionsPresets.${orgId}.${activePreset}`] =
															firebase.firestore.FieldValue.delete();
													}
													updateDocument(db.collection("users").doc(profile.id), data).catch(
														(error) => console.error(error),
													);
												}
											}}
										/>
									)}
									{activePreset === presetName && (
										<Button
											content={t("remove", "Remove")}
											color="red"
											onClick={() => {
												const { [activePreset]: remove, ...rest } = presets;
												updateDocument(db.collection("users").doc(profile.id), {
													[`listProductionsPresets.${orgId}`]: rest,
												}).catch((error) => {
													console.error(error);
												});
											}}
										/>
									)}
								</Input>
							)}
							{error && typeof error === "object" && <Message error content={error.content} />}
						</>
					}
				/>
			</Menu.Item>
		</Accordion>
	);
}
