export default function createAgreementAppendix(db, data, collection = "agreementAppendixes") {
	const {
		name = "",
		file = null,
		deleted = false,
		producerIds = [],
		agreementTypes = [],
		agreementParts = [],
	} = data;

	const ref = data.ref || db.collection(collection).doc();

	return {
		id: ref.id,
		ref,
		...data,
		name,
		file,
		deleted,
		created: new Date(Date.now()),
		producerIds,
		agreementTypes,
		agreementParts,
	};
}
