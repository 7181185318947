import { useCallback } from "react";

import CustomSearchBox from "./CustomSearchBox";

export default function RefinementSearchBox({ searchForItems }) {
	const customCallbackSearch = useCallback(
		(value) => {
			searchForItems(value);
		},
		[searchForItems],
	);

	return <CustomSearchBox customCallbackSearch={customCallbackSearch} />;
}
