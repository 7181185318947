import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

export default function DoneOrNotifyButton({ onOpenNotifyModal, onStopBooking, modifiedSessions }) {
	const { t } = useTranslation();

	return (
		<>
			{modifiedSessions && (
				<Button color="blue" icon labelPosition="right" size="tiny" onClick={onOpenNotifyModal}>
					{t("notify", "Notify")}
					<Icon
						name="close"
						onClick={(e) => {
							e.stopPropagation();
							onStopBooking();
						}}
					/>
				</Button>
			)}

			{!modifiedSessions && (
				<Button color="blue" size="tiny" onClick={onStopBooking}>
					{t("ready", "Done")}
				</Button>
			)}
		</>
	);
}
