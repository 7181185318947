import React from "react";
import { Header } from "semantic-ui-react";

import TeamPrice from "../../../team/components/TeamPrice/TeamPrice";

export default function TeamMemberHeader({ member }) {
	return (
		<Header as="h4" style={{ margin: 0 }}>
			{member.user?.name}

			<Header.Subheader>
				<TeamPrice role={member.role} price={member.price} />
			</Header.Subheader>
		</Header>
	);
}
