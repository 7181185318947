import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

import ArticlesTableFooter from "../ArticlesTableFooter/ArticlesTableFooter";

export default function ArticlesInstantSearchDataTable({ organization, ...props }) {
	const navigate = useNavigate();

	const columns = useArticlesTableColumns();

	return (
		<InstantSearchDataTable
			selectable={({ bundle }) => !bundle}
			indexName="articles"
			columns={columns}
			configure={{
				filters: organization && `publisher.id:${organization.id}`,
			}}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/${id}`)}
			footer={<ArticlesTableFooter />}
			{...props}
		/>
	);
}
