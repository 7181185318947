import trim from "lodash/trim";

import { addDocument } from "../../helpers";

export default function createProduction(
	firebase,
	{ title, status, publisherId, producerId, language, createdBy, publisherManagers = [], category },
) {
	const db = firebase.firestore();

	const data = {
		title: trim(title),
		status,
		producer: producerId,
		publisher: publisherId,
		created: firebase.firestore.FieldValue.serverTimestamp(),
		createdBy,
		language,
		category,
		publisherManagers,
		publisherManagerIds: publisherManagers.map(({ id }) => id),
	};

	return addDocument(db.collection("productions"), data);
}
