import { useState } from "react";

import PipelineStepContent from "./PipelineStepContent";
import PipelineStepTitle from "./PipelineStepTitle";

export default function PipelineStep({ step, progress }) {
	const [active, setActive] = useState(false);

	const onClick = () => {
		setActive((active) => !active);
	};

	return (
		<>
			<PipelineStepTitle step={step} active={active} progress={progress} onClick={onClick} />

			{active && !step.disabled && <PipelineStepContent step={step} progress={progress} />}
		</>
	);
}
