import Case from "case";
import ISBN from "isbn3";
import isCurrency from "validator/lib/isCurrency";
import isHexColor from "validator/lib/isHexColor";
import isISO6391 from "validator/lib/isISO6391";
import isMobilePhone from "validator/lib/isMobilePhone";
import isPostalCode from "validator/lib/isPostalCode";
import isSemVer from "validator/lib/isSemVer";
import isUUID from "validator/lib/isUUID";
import isVAT from "validator/lib/isVAT";
import { z } from "zod";

import distributors from "astrid-config/src/distributors";
import nameCase from "astrid-helpers/src/nameCase";

// Tools

export const nullable = (type, defaultValue = null) => type.nullable().default(defaultValue);

export const lazy = (callback) => z.lazy(callback);

export const preprocess = (callback, type) => z.preprocess(callback, type);

// Booleans

export const boolean = (defaultValue = false) => z.boolean().default(defaultValue);

// Numbers

export const number = () => z.number();

export const int = () => z.number().int();

export const intMinMax = (min = 0, max = Infinity) => z.number().int().min(min).max(max);

// Strings

export const string = (min = 0) => z.string().min(min);

export const stringMinMax = (min = 0, max = Infinity) => z.string().min(min).max(max);

export const name = (min) => string(min).transform(nameCase);

export const email = () => z.string().email();

export const isbn = () => z.custom((value) => ISBN.parse(value)?.isValid, { params: { i18n: "errors.isbn.invalid" } });

export const mobilePhone = () => z.custom(isMobilePhone, { params: { i18n: "errors.phone.invalid" } });

export const currency = () => z.custom(isCurrency, { params: { i18n: "errors.currency.invalid" } });

export const vat = () => z.custom(isVAT, { params: { i18n: "errors.vat.invalid" } });

export const zip = () => z.custom(isPostalCode, { params: { i18n: "errors.zip.invalid" } });

export const language = () => z.custom(isISO6391, { params: { i18n: "errors.language.invalid" } });

export const hex = () => z.custom(isHexColor, { params: { i18n: "errors.hex.invalid" } });

export const semVer = () => z.custom(isSemVer, { params: { i18n: "errors.semVer.invalid" } });

export const uuid = () => z.custom(isUUID, { params: { i18n: "errors.uuid.invalid" } });

export const distributor = () => enumKeys(distributors);

export const isCase = (theCase) =>
	z.custom((value) => Case.of(value) === theCase, { params: { i18n: "errors.case.invalid" } });

export const date = () => z.string().date();

// Enums

export const enumArray = (array) => z.enum(array);

export const enumKeys = (object) => z.enum(Object.keys(object || {}));

export const enumValues = (object) => z.enum(Object.values(object || {}));

// Arrays

export const array = (type, force = true) => type.array().default(force ? [] : undefined);

// Objects

export const object = (shape = {}) => z.object(shape);

export const record = (key, type) => z.record(key, type);

// Unions

export const union = (types) => z.union(types);

// Files

export const file = () =>
	object({
		url: string(),
		name: string(),
		bucket: string(),
		fullPath: string(),
	});

// Firestore

export const timestamp = () =>
	z.custom(
		(value) =>
			value instanceof Date || value.seconds || value?._delegate?._methodName === "FieldValue.serverTimestamp",
		{
			params: { i18n: "errors.server_timestamp.invalid" },
		},
	);

export const ref = (type, picks = {}) =>
	type
		.pick({
			id: true,
			ref: true,
			...picks,
		})
		.merge(
			object({
				exists: boolean(true),
			}),
		);
