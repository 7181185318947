const k = 1024;
const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export default function formatBytes(bytes, decimals) {
	if (bytes === 0) {
		return "0 Bytes";
	}

	const dm = decimals || 2;
	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
