import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../components/Flex/Flex";
import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import ArticleCoverImage from "../components/ArticleCoverImage/ArticleCoverImage";
import ArticleToggleSyncTitleDataIcon from "../components/ArticleIcons/ArticleToggleSyncTitleDataIcon";

import useArticleDropdownMenuOptions from "./useArticleDropdownMenuOptions";
import useArticleTypeTranslations from "./useArticleTypeTranslations";

export default function useArticlesTableColumns({ enableSync = false } = {}) {
	const { t } = useTranslation();

	const articleTypeTranslations = useArticleTypeTranslations();

	return [
		enableSync && {
			id: "sync",
			collapsing: true,
			Cell: ({ row }) => {
				return <ArticleToggleSyncTitleDataIcon data={row.original} />;
			},
		},
		{
			id: "name",
			Header: t("Name", "Name"),
			Cell: ({ value, row }) => (
				<Flex justifyContent="flex-start">
					<ArticleCoverImage
						thumb
						rounded
						article={row.original}
						style={{ marginRight: "1em", height: 45, width: 45 }}
					/>
					<span>{value}</span>
				</Flex>
			),
		},
		{
			id: "title.name",
			Header: t("title", "Title"),
		},
		{
			id: "isbn",
			Header: t("isbn", "ISBN"),
		},
		{
			id: "type",
			filter: "select",
			Header: t("type", "Type"),
			accessor: ({ type }) => articleTypeTranslations[type],
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
		dropdownMenuColumn(useArticleDropdownMenuOptions),
	];
}
