import { useMemo } from "react";

import { resolvedOfferStatuses } from "astrid-firestore/src/api/offers/constants/offerStatuses";
import { useCollectionData } from "astrid-firestore/src/hooks";

import { useProfile } from "../../../../authentication/state/profile";
import useOffersQuery from "../../../../offers/hooks/useOffersQuery";
import useSortBy from "../../../../ui/hooks/useSortBy";

export default function useFilteredSentOffers({ form }) {
	const user = useProfile();

	const { watch } = form;

	const { productions, secondParties, offerStatuses, offerTypes } = watch();

	const [offers, loading, error] = useCollectionData(useOffersQuery({ createdBy: user }));

	const isFiltering =
		productions.length > 0 || secondParties.length > 0 || offerStatuses.length > 0 || offerTypes.length > 0;

	const sortedOffers = useSortBy(offers, "createdAt.seconds");

	const filteredOffers = sortedOffers
		.filter((offer) => productions.length === 0 || productions.includes(offer.production.id))
		.filter((offer) => secondParties.length === 0 || secondParties.includes(offer.secondParty.id))
		.filter((offer) => offerStatuses.length === 0 || offerStatuses.includes(offer.status))
		.filter((offer) => offerTypes.length === 0 || offerTypes.includes(offer.type));

	const activeOffers = useMemo(
		() => filteredOffers?.filter((offer) => !resolvedOfferStatuses.includes(offer.status)),
		[filteredOffers],
	);

	const inactiveOffers = useMemo(
		() => filteredOffers?.filter((offer) => resolvedOfferStatuses.includes(offer.status)),
		[filteredOffers],
	);

	return {
		isFiltering,
		sortedOffers,
		filteredOffers,
		activeOffers,
		inactiveOffers,
		loading,
		error,
	};
}
