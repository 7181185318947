import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import useFormField from "astrid-web/src/features/forms/hooks/useFormField";

export default function FormPhoneInput({ label, rules, ...props }) {
	const { t } = useTranslation();

	const { field } = useFormField({
		rules: {
			validate: (value) => (value ? isValidPhoneNumber(value) : true),
			...rules,
		},
		validateErrorMessage: t("invalidPhone", "Invalid phone number"),
		...props,
	});

	const { error } = field;

	return (
		<div className={`field ${error ? "error" : ""}`}>
			<label>{label}</label>

			<PhoneInput {...field} />

			{error?.content && (
				<div className="ui pointing above prompt label" role="alert" aria-atomic="true">
					{error.content}
				</div>
			)}
		</div>
	);
}
