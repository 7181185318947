export default {
	name: "Kungliga biblioteket ebook",
	format: "wav",
	connection: "ftp",
	useSSH: "ssh-kungliga-biblioteket-ebook-private-key",
	audio: false,
	meta: {
		format: "fgs",
		folder: "ISBN",
	},
	cover: {
		folder: "ISBN",
		rename: "ISBN",
	},
	ebook: {
		folder: "ISBN",
	},
	disableArticles: ["cd", "mp3cd"],
	email: ["delivery"],
};
