import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import ProductionPriceForm from "../../../../productions/components/ProductionPriceForm/ProductionPriceForm";

export default function AddExpenseAction({ partyId, production }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState();

	return (
		<>
			<Button onClick={() => setOpen(true)}>{t("addCost")}</Button>

			{open && (
				<ProductionPriceForm
					type="expense"
					production={production}
					firstPartyId={partyId}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
}
