import getExportOptions from "astrid-config/src/exportOptions";
import groupBy from "astrid-helpers/src/groupBy";

import { runBatch } from "../../../../helpers";

import { getArticleType } from "./articles";

export function getArticleMasterData(production, article) {
	return production?.master?.[article];
}

export function getArticleMasterSettings(production, article) {
	const data = getArticleMasterData(production, article);

	return data?.settings;
}

export function getArticleMasterDate(production, article, date = "date") {
	const settings = getArticleMasterSettings(production, article);

	return settings?.[date]?.toDate?.();
}

export function getArticleMasterValue(production, article, value) {
	const data = getArticleMasterData(production, article);

	return data?.[value];
}

export function getArticleMasterExports(production, article) {
	return Object.entries(getArticleMasterValue(production, article, "exports") || {}).map(([id, exportData]) => ({
		id,
		...exportData,
	}));
}

export function getExportSettings(articleDistributionSettings, article) {
	const articleType = getArticleType(article);
	const exportOptions = getExportOptions(articleType);

	const part = articleDistributionSettings?.part || exportOptions.part.default;
	const format = articleDistributionSettings?.format || exportOptions.format.default;
	const bitrate = articleDistributionSettings?.bitrate || exportOptions.bitrate.default;
	const iso = articleType === "mp3cd";
	const ddp = articleType === "cd";

	return {
		part,
		format,
		bitrate,
		iso,
		ddp,
	};
}

export function isExportSettingsEqual(a, b) {
	return (
		a.part === b.part &&
		a.format === b.format &&
		a.iso === b.iso &&
		a.ddp === b.ddp &&
		((a.format === "wav" && a.bitrate === b.bitrate) || a.format !== "wav")
	);
}

export function getArticleMasterExportData(articleExports, exportSettings) {
	const first = articleExports.find(
		(exportData) =>
			exportData.format === exportSettings.format &&
			exportData.part === exportSettings.part &&
			((exportData.iso && exportSettings.iso) || (!exportData.iso && !exportSettings.iso)) &&
			((exportData.ddp && exportSettings.ddp) || (!exportData.ddp && !exportSettings.ddp)) &&
			(exportSettings.format === "wav" || exportData.bitrate === exportSettings.bitrate),
	);

	return (
		first ||
		articleExports.find(
			(exportData) =>
				exportData.format === exportSettings.format &&
				((exportData.iso && exportSettings.iso) || (!exportData.iso && !exportSettings.iso)) &&
				((exportData.ddp && exportSettings.ddp) || (!exportData.ddp && !exportSettings.ddp)) &&
				(exportSettings.format === "wav" || exportData.bitrate === exportSettings.bitrate),
		)
	);
}

export function getArticleMasterExportDate(exportData, date = "created") {
	return exportData?.[date]?.toDate?.();
}

export function getArticleMasterExportDistributorData(exportData, distributor) {
	return exportData?.distributor?.[distributor];
}

export function getArticleMasterExportDistributorDate(exportData, distributor, date = "sent") {
	const data = getArticleMasterExportDistributorData(exportData, distributor);

	return data?.[date]?.toDate?.();
}

export function getArticleMasterExportDistributorStatus(exportData, distributor) {
	const data = getArticleMasterExportDistributorData(exportData, distributor);

	return data?.status;
}

export function isExportExpired(production, article, exportData) {
	const masterDate = getArticleMasterDate(production, article);
	const masterExportDate = getArticleMasterExportDate(exportData);

	return !exportData?.status || masterExportDate < masterDate;
}

export function shouldSendExport(exportData, distributor) {
	const exportStatus = getArticleMasterExportDistributorStatus(exportData, distributor);
	const exportSentDate = getArticleMasterExportDistributorDate(exportData, distributor);
	const exportCreatedDate = getArticleMasterExportDate(exportData, distributor);

	return exportStatus !== "done" || !exportSentDate || exportSentDate < exportCreatedDate;
}

export function createExportId(article, exportSettings) {
	const timestamp = +new Date();

	return timestamp + exportSettings.format + article + exportSettings.part + (exportSettings.bitrate || "");
}

export async function createExports(firebase, { production, exports }) {
	const db = firebase.firestore();

	const groupedExports = Object.entries(groupBy(exports, "exportId"));

	await runBatch(db, async (batch) => {
		for (const [exportId, exports] of groupedExports) {
			const { article, exportSettings } = exports[0];

			// no bitrate for wav
			if (exportSettings.format === "wav") {
				delete exportSettings.bitrate;
			}

			batch.update(production.ref, {
				[`master.${article}.exports.${exportId}`]: {
					...exportSettings,
					user: null,
					status: "queued",
					created: firebase.firestore.FieldValue.serverTimestamp(),
					distributor: {},
				},
				updated: firebase.firestore.FieldValue.serverTimestamp(),
			});

			batch.set(db.collection("tasks").doc("compute").collection("jobs").doc(), {
				job: "createExport",
				status: "queued",
				created: firebase.firestore.FieldValue.serverTimestamp(),
				diskType: "pd-ssd",
				diskSize: 150,
				machineType: "n1-highmem-4",
				args: {
					article,
					export: exportId,
					production: production.id,
				},
			});
		}
	});
}
