import React from "react";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../../admin/components/AdminPage/AdminPageHeader";
import useDistributionPipelineDropdownMenuOptions from "../../../../distribution/hooks/useDistributionPipelineDropdownMenuOptions";

export default function SuperAdminDistributionPipelineHeader({ channel, pipeline }) {
	// const menuOptions = usePipelineMenuOptions({ pipeline });
	const dropdownMenuOptions = useDistributionPipelineDropdownMenuOptions({ channel });

	return (
		<AdminPageHeader dropdownMenuOptions={dropdownMenuOptions(pipeline)}>
			<Header style={{ margin: 0 }}>{pipeline.name}</Header>
		</AdminPageHeader>
	);
}
