const biblioTaskTypes = {
	NEW_TITLE: "biblioNewTitleTask",
	NEW_SCRIPT: "biblioNewScriptTask",
	UPDATE_TITLE: "biblioUpdateTitleTask",
	NEW_PRODUCTION: "biblioNewProductionTask",
	NEW_DISTRIBUTION: "biblioNewDistributionTask",
	UPDATE_DISTRIBUTION: "biblioUpdateDistributionTask",
};

export default biblioTaskTypes;
