import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import api from "../../../../../api";

export default function useAdminTitleMenuOptions({ title }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [articlesCount, articlesCountLoading] = api.articles.useCount({ title });

	return [
		{
			to: "",
			active: pathname.endsWith(title.id),
			text: t("metaData", "Meta data"),
		},
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles"),
			label: articlesCount,
			loading: articlesCountLoading,
		},
		{
			to: "productions",
			active: pathname.endsWith("/productions"),
			text: t("productions", "Productions"),
		},
		{
			to: "versions",
			active: pathname.endsWith("/versions"),
			text: t("versions", "Versions"),
		},
	];
}
