import React, { useCallback, useState } from "react";

export const ClearStateContext = React.createContext({});

export default function ClearStateProvider({ children }) {
	const [clearKey, setClearKey] = useState(0);

	const triggerClear = useCallback(() => {
		setClearKey((prevKey) => prevKey + 1);
	}, []);

	return <ClearStateContext.Provider value={{ clearKey, triggerClear }}>{children}</ClearStateContext.Provider>;
}
