import React from "react";
import { Label } from "semantic-ui-react";

import AgreementActiveStatus from "./AgreementActiveStatus";

export default function AgreementActiveStatusLabel({ agreement }) {
	return (
		<Label basic>
			<Label.Detail style={{ marginLeft: 0 }}>
				<AgreementActiveStatus agreement={agreement} />
			</Label.Detail>
		</Label>
	);
}
