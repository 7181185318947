import React from "react";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../../admin/components/AdminPage/AdminPageHeader";

import useSuperAdminDistributionChannelMenuOptions from "../hooks/useSuperAdminDistributionChannelMenuOptions";

export default function SuperAdminDistributionChannelHeader({ channel }) {
	const menuOptions = useSuperAdminDistributionChannelMenuOptions({ channel });

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<Header style={{ margin: 0 }}>{channel.name}</Header>
		</AdminPageHeader>
	);
}
