import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { useDownloadURL } from "astrid-firestore/src/hooks/index";

export default function DownloadAgreementDropdownItem({ agreement }) {
	const { t } = useTranslation();
	const [signedAgreementUrl] = useDownloadURL({
		bucket: agreement?.signedAgreementFile?.bucket,
		fullPath: agreement?.signedAgreementFile?.fullPath,
	});

	return (
		<Dropdown.Item
			as="a"
			text={t("downloadAgreement", "Download agreement")}
			target="_blank"
			disabled={!agreement.signedAgreementFile}
			href={signedAgreementUrl}
		/>
	);
}
