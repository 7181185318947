import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import CollectionDataTable from "../../../../ui/components/CollectionDataTable/CollectionDataTable";
import useUsersQuery from "../../../../users/hooks/useUsersQuery";

import useAdminPublisherUsersColumns from "../hooks/useAdminPublisherUsersColumns";

export default function AdminPublisherUsers({ publisher }) {
	const { t } = useTranslation();

	const usersQuery = useUsersQuery({ organization: publisher });
	const usersColumns = useAdminPublisherUsersColumns(publisher);

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("publisherUsers", "Publisher users")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<CollectionDataTable
					query={usersQuery}
					columns={usersColumns}
					sortBy={{
						id: "name",
						desc: false,
					}}
				/>
			</Card.Content>
		</Card>
	);
}
