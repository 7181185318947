import countries from "astrid-helpers/src/countries";

const countryOptions = Object.entries(countries)
	.map(([id, { name, native }]) => ({
		key: id,
		value: id,
		text: `${name} (${native})`,
	}))
	.sort((a, b) => a.text.localeCompare(b.text, "sv"));

export default function useCountryOptions() {
	return countryOptions;
}
