import {
	applyActivity,
	applyAmount,
	applyAmountProduced,
	applyEstimation,
	applyExchange,
	applyOpenAmount,
	applyTotal,
	applyValue,
	mapCostFromPrice,
	splitEstimationByType,
} from "./costMappers";
import { reduceSimilarCosts } from "./costsHelper";

export function getOpenExpenses({ costs = [], exchangeRate, openCosts, production, users }) {
	return openCosts
		.filter((cost) => cost.expense)
		.map(applyActivity(users))
		.map(applyAmountProduced(production))
		.map(applyAmount())
		.map(applyValue())
		.map(applyOpenAmount(costs))
		.map(applyTotal())
		.map(applyExchange(exchangeRate))
		.map(applyEstimation(production, exchangeRate))
		.map(splitEstimationByType("edit"))
		.reduce(reduceSimilarCosts, []);
}

export function getOpenRevenues({ costs = [], exchangeRate, openCosts, production, expenses }) {
	const totalExpenses = expenses.reduce((total, cost) => total + cost.exchange?.value, 0);
	const totalEstimatedExpenses = expenses.reduce((total, cost) => total + cost.estimation?.exchanged, 0);

	return openCosts
		.filter((cost) => !cost.expense)
		.map(applyAmountProduced(production))
		.map(applyAmount())
		.map(applyValue(exchangeRate, totalExpenses))
		.map(applyOpenAmount(costs))
		.map(applyTotal())
		.map(applyExchange(exchangeRate))
		.map(applyEstimation(production, exchangeRate, totalEstimatedExpenses))
		.reduce(reduceSimilarCosts, []);
}

export function getOpenCosts({ costs, exchangeRate, prices, production, users }) {
	const openCosts = prices
		.filter(({ type, unit, session, amount }) => type !== "record" || unit === "fixed" || session || amount)
		.map(mapCostFromPrice);

	const expenses = getOpenExpenses({ openCosts, costs, exchangeRate, production, users });
	const revenues = getOpenRevenues({ openCosts, costs, exchangeRate, production, expenses });

	return [...expenses, ...revenues];
}
