import todoPriorities from "../../constants/todoPriorities";
import createTodoRef from "../../utils/createTodoRef";

export default function createTodoData(
	firebase,
	{
		type = null,
		organizationIds = [],
		userIds = [],
		users = [],
		subjectRef = null,
		content = null,
		notification = false,
		languages = [],
		url = null,
		priority = todoPriorities.LOW,
	},
) {
	const ref = createTodoRef(firebase.firestore(), subjectRef.id, type);

	return {
		id: ref.id,
		ref,
		type,
		organizationIds,
		userIds,
		users,
		subjectRef,
		content,
		languages,
		notification,
		url,
		priority,
		createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		resolved: {
			status: false,
			timestamp: null,
			user: null,
		},
	};
}
