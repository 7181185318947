import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import InstantSearchSelect from "../../../../ui/components/InstantSearch/InstantSearchSelect";

export default function CalendarFiltersManagerSelect({ organizationId }) {
	const { t } = useTranslation();

	return (
		<Form.Field name="managers" shouldUnregister={false}>
			<InstantSearchSelect
				multiple
				clearable
				indexName="users"
				label={t("productionManager", "Production coordinator")}
				getOption={({ objectID, name }) => {
					return {
						key: objectID,
						value: objectID,
						text: name,
					};
				}}
				configure={{
					hitsPerPage: 1000,
				}}
				filters={`permissions.producer.${organizationId}:producerStaff`}
			/>
		</Form.Field>
	);
}
