import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ArticleBundle } from "astrid-firestore/src/api/articles/types/ArticleBundle";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import ArticlesInstantSearchSelect from "../ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";

export default function CreateArticleBundleForm({ articles = [], organization, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const publisher = organization || articles[0].publisher;

	const { form, onSubmit, error } = useSimpleForm({
		schema: ArticleBundle,
		defaultValues: {
			name: "",
			articleIds: articles.map((article) => article.id),
			publisher,
		},
		onSubmit: async (data) => {
			return api.articleBundles.create(data);
		},
		onSuccess: ({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/bundles/${id}`),
	});

	return (
		<ModalPrompt
			size="tiny"
			header={t("createArticleBundle", "Create article bundle")}
			onSave={onSubmit}
			onClose={onClose}
		>
			<Form form={form} error={error}>
				<Form.Input name="name" label={t("name", "Name")} />

				<Form.Field name="articleIds" label={t("articles", "Articles")}>
					<ArticlesInstantSearchSelect multiple publisher={publisher} />
				</Form.Field>
			</Form>
		</ModalPrompt>
	);
}
