import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "semantic-ui-react";

export default function DiscussionForm({ onComment }) {
	const { t } = useTranslation();

	const [text, setText] = useState("");
	const [loading, setLoading] = useState(false);

	const onClickComment = async () => {
		setLoading(true);
		setText("");
		await onComment({ text });
		setLoading(false);
	};

	return (
		<Form reply>
			<Form.TextArea style={{ height: 70 }} onChange={(e) => setText(e.target.value)} value={text} />
			<Button
				content={t("discussionComment", "Comment")}
				primary
				loading={loading}
				disabled={loading || !text}
				onClick={onClickComment}
			/>
		</Form>
	);
}
