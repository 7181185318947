import firebase from "firebase/app";

import auth from "astrid-firebase/src/auth";
import db from "astrid-firebase/src/db";
import createStore from "astrid-helpers/src/createStore";

import { setUpCrisp } from "../../crisp/crisp";
import Sentry from "../../sentry/sentry";

import { getActAsUser } from "./actAsUser";
import { getUser, onUser } from "./user";

export const [getProfile, setProfile, useProfile, onProfile, resetProfile] = createStore();

onProfile((profile, prevProfile) => {
	const user = getUser();
	const actAsUser = getActAsUser();

	if (!actAsUser && user && profile && profile?.id !== prevProfile?.id) {
		if (!profile.email) {
			auth.signOut();
		} else {
			const data = {
				logins: {
					last: firebase.firestore.FieldValue.serverTimestamp(),
					prev: (profile.logins && profile.logins.last) || firebase.firestore.FieldValue.serverTimestamp(),
				},
			};

			// store provider image if there's no image set yet
			if (!profile.img && user.photoURL) {
				data.img = user.photoURL;
			}

			// refresh token for new custom claims to take effect?
			if (profile.permissionsRefresh) {
				auth.currentUser.getIdToken(true);
				data.permissionsRefresh = firebase.firestore.FieldValue.delete();
			}

			db.collection("users").doc(user.uid).update(data);

			// configure Sentry scope
			Sentry.configureScope((scope) => {
				scope.setUser({
					id: user.uid,
					email: profile.email,
					username: profile.firstName + " " + profile.lastName,
				});
			});

			setUpCrisp(profile);
			// if (context.props.location.pathname === "/login/email") context.props.history.push("/");
		}
	}
});

onUser((user) => {
	if (user && !user?.isAnonymous) {
		const unsubscribe = db
			.collection("users")
			.doc(user.uid)
			.onSnapshot((doc) =>
				setProfile({
					id: doc.id,
					ref: doc.ref,
					name: `${doc.get("firstName")} ${doc.get("lastName")}`,
					...doc.data(),
				}),
			);

		return () => {
			unsubscribe();
			resetProfile();
		};
	}
});
