import React from "react";
import { useTranslation } from "react-i18next";

import { Pipeline } from "astrid-firestore/src/api/pipelines/types/Pipeline";

import api from "../../../../api";

import AdminForm from "../../../admin/components/AdminForm/AdminForm";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import PipelineSteps from "./components/PipelineSteps";

export default function PipelineForm({ header, channel, pipeline }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Pipeline,
		defaultValues: {
			name: pipeline.name || "",
			steps: pipeline.steps || null,
		},
		onSubmit: (data) => {
			return api.distributionChannels.updatePipeline({ channel, pipeline, data });
		},
	});

	return (
		<AdminForm header={header} {...form}>
			<Form.Input name="name" label={t("name", "Name")} />

			<PipelineSteps name="steps" label={t("steps", "Steps")} />
		</AdminForm>
	);
}
