import get from "lodash/get";
import { useMemo } from "react";

const defaultAccessor = (original, id) => get(original, id);

export default function useTableRows({ data, columns }) {
	return useMemo(
		() =>
			data?.map((original) => ({
				id: original.id,
				data,
				original,
				columns: columns.map(({ id, accessor = defaultAccessor, ...props }) => ({
					id: id + original.id,
					value: accessor(original, id),
					...props,
				})),
			})),
		[columns, data],
	);
}
