import React from "react";
import { useTranslation } from "react-i18next";

import defaultStudioSettings from "astrid-firestore/src/api/studios/defaultStudioSettings";
import { Studio } from "astrid-firestore/src/api/studios/types/Studio";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function StudioSettingsForm({ studio }) {
	const { t } = useTranslation();

	const { form, onSubmit } = useSimpleForm({
		schema: Studio,
		values: {
			ref: studio.ref,
			settings: studio.settings || defaultStudioSettings,
		},
		onSubmit: (data) => {
			return api.studios.update(data);
		},
	});

	return (
		<AdminForm header={t("studioSettings", "Studio settings")} form={form} onSubmit={onSubmit}>
			<Form.Group widths={2}>
				<Form.Select
					name="settings.input"
					options={studio.devices?.inputs || []}
					label={t("incomingSound", "Incoming sound")}
				/>

				<Form.Select
					name="settings.output"
					options={studio.devices?.outputs || []}
					label={t("outgoingSound", "Outgoing sound")}
				/>
			</Form.Group>

			<Form.Group widths={3}>
				<Form.Select
					name="settings.channel"
					label={t("channel", "Channel")}
					options={[
						{
							value: "left",
							text: t("leftChannel", "Left channel"),
						},
						{
							value: "right",
							text: t("rightChannel", "Right channel"),
						},
					]}
				/>
				<Form.Select
					name="settings.releaseChannel"
					label={t("releaseChannel", "Release channel")}
					options={[
						{
							value: "disabled",
							text: t("disableAutoUpdates", "Disable auto-updates"),
						},
						{
							value: "latest",
							text: t("latest", "Latest"),
						},
						{
							value: "beta",
							text: t("beta", "Beta"),
						},
						{
							value: "alpha",
							text: t("alpha", "Alpha"),
						},
					]}
				/>
			</Form.Group>
		</AdminForm>
	);
}
