export function isPart(article) {
	return article.substr(0, 4) === "part";
}

export function getPart(article) {
	return article.split("_")[1];
}

export function getArticleType(article) {
	return isPart(article) ? "part" : article;
}

export function getArticleData(production, article) {
	return article === "total"
		? production // root
		: isPart(article)
		? production.deliveryParts?.[getPart(article)] // part
		: production.deliveryEbook; // ebook
}

export function getArticleName(article) {
	return article === "total"
		? "totala produktionen"
		: isPart(article)
		? `del nr ${+getPart(article) + 1}`
		: "e-boken";
}

export function getArticleTranslation(production, article) {
	return article === "total"
		? production.title
		: isPart(article)
		? `${production.title} [part ${+getPart(article) + 1}]`
		: `${production.title} [eBook]`;
}

export function getArticleValue(production, article, value) {
	const articleData = getArticleData(production, article);

	return articleData?.[value];
}

export function getArticleISBN(production, article) {
	return getArticleValue(production, article, "isbn");
}

export function getArticleImg(production, article) {
	return getArticleValue(production, article, "img");
}

export function getArticleReleaseData(production, article) {
	return getArticleValue(production, article, "release");
}

export function getArticleReleaseDistributorData(production, article, distributor) {
	const data = getArticleReleaseData(production, article);

	return data?.[distributor];
}

export function getArticleReleaseDistributorValue(production, article, distributor, value) {
	const data = getArticleReleaseDistributorData(production, article, distributor);

	return data?.[value];
}

export function getArticleReleaseDate(production, article, distributor, date = "date") {
	const value = getArticleReleaseDistributorValue(production, article, distributor, date);

	return value?.toDate?.();
}

export function getArticleScheduledDate(production, article, distributor) {
	return getArticleReleaseDate(production, article, distributor, "scheduledDate");
}

export function getArticleAnnouncementDate(production, article, distributor) {
	return getArticleReleaseDate(production, article, distributor, "announcementDate");
}

export function getArticleMetaUpdatedDate(production, article, distributor) {
	return getArticleReleaseDate(production, article, distributor, "metaUpdated");
}

export function getArticleMetaSentDate(production, article, distributor) {
	return getArticleReleaseDate(production, article, distributor, "metaSent");
}

export function getMetaUpdatedArticle(production, article) {
	const articleData = getArticleData(production, article);

	return (article === "total" ? production.metaUpdatedTotalArticle : articleData?.metaUpdated)?.toDate?.();
}

export function getLastMetaDate(production, article, distributor) {
	const metaUpdated = getArticleMetaUpdatedDate(production, article, distributor);
	const metaUpdatedArticle = getMetaUpdatedArticle(production, article);
	const metaUpdatedProduction = production?.metaUpdated?.toDate?.();

	return Math.max(metaUpdated || 0, metaUpdatedArticle || 0, metaUpdatedProduction || 0);
}

export function isMetaOutdated(production, article, distributor) {
	const metaSent = getArticleMetaSentDate(production, article, distributor);
	const metaLatest = getLastMetaDate(production, article, distributor);

	return !metaSent || metaSent < metaLatest;
}

export function getActiveArticles(production, articles) {
	const activeArticles = [];

	if (typeof production.isbn === "string") {
		activeArticles.push("total");
	}

	if (production.deliveryEbook) {
		activeArticles.push("ebook");
	}

	if (production.deliveryParts) {
		production.deliveryParts.forEach((part, index) => {
			activeArticles.push(`part_${index}`);
		});
	}

	if (production.deliveryCD) {
		activeArticles.push("cd");
	}

	if (production.deliveryMP3CD) {
		activeArticles.push("mp3cd");
	}

	return activeArticles.filter((article) => !articles || articles.includes(article));
}
