import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

export default function useProductionAds(productionId) {
	const [data] = useCollectionData(
		useMemo(() => db.collection("productions").doc(productionId).collection("ads"), [productionId]),
		{ idField: "id" },
	);

	return data || [];
}
