import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminArticleHeader from "./components/AdminArticleHeader";

export default function AdminArticleView(props) {
	return (
		<AdminPageView {...props}>
			<AdminArticleHeader />
		</AdminPageView>
	);
}
