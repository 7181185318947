export default {
	name: "Bonnier Audio MP3",
	format: "mp3",
	connection: false,
	alertAdmin: true,
	iso: true,
	audio: {
		zip: true,
		rename: "SLUG.SUFFIX",
	},
	disableArticles: ["cd", "ebook"],
	allowMissingISBN: true,
};
