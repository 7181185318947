import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, language, name, ref, string } from "../../../types/_types";

export const Translator = FirestoreDocument.extend({
	name: name(3),
	nameLowerCase: string(3),
	languages: array(language()),
	publisherIds: array(string(3)),
}).merge(FirestoreLifecycle);

export const TranslatorRef = ref(Translator, {
	name: true,
});
