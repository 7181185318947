import { useEffect } from "react";
import { Card } from "semantic-ui-react";

import Form from "../../../forms/components/Form/Form";

import AgreementsFormActions from "./AgreementsFormActions";
import AgreementAppendixesForm from "./forms/AgreementAppendixesForm";
import AgreementNameForm from "./forms/AgreementNameForm";
import AgreementPartiesForm from "./forms/AgreementPartiesForm";
import AgreementPricesForm from "./forms/AgreementPricesForm";
import AgreementSettingsForm from "./forms/AgreementSettingsForm";
import useAgreementsForm from "./hooks/useAgreementsForm";

export default function AgreementsForm({ template, organization, afterSubmit }) {
	const form = useAgreementsForm({ template, organization });

	const { watch, setValue } = form;

	const [pricesApproved, lifeApproved, signed] = watch(["pricesApproved", "lifeApproved", "signed"]);

	useEffect(() => {
		setValue("approved", signed || (lifeApproved && pricesApproved));
	}, [lifeApproved, pricesApproved, setValue, signed]);

	return (
		<Form.FormProvider form={form} afterSubmit={afterSubmit}>
			<Card.Group>
				<AgreementNameForm />
				<AgreementSettingsForm />
				{!template && !signed && <AgreementAppendixesForm />}
				<AgreementPricesForm />
				<AgreementPartiesForm />
				<AgreementsFormActions />
			</Card.Group>
		</Form.FormProvider>
	);
}
