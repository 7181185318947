import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import useHasRight from "../../../authorization/hooks/useHasRight";

import ProductionOfferForm from "./ProductionOfferForm";

export default function ProductionOfferFormButton({ children, ...props }) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const hasRight = useHasRight();

	if (!hasRight("production.accept")) {
		return false;
	}

	return (
		<>
			<Button color="green" onClick={() => setOpen(true)}>
				{children || t("acceptAndPlan")}
			</Button>

			{open && <ProductionOfferForm {...props} onClose={() => setOpen(false)} />}
		</>
	);
}
