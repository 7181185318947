import { AGREEMENT_STATE } from "astrid-functions/src/functions/scrive/agreement/constants";

import {
	getAgreementRef,
	getOrganizationRef,
	getPartyRef,
	getProductionProducerRef,
	getProductionPublisherRef,
	getProductionRef,
	getUserRef,
} from "../../utils/referenceMappers";

import createApprovalData from "../approval/createApprovalData";

import agreementStatuses from "./constants/agreementStatuses";
import createAgreementTemplate from "./createAgreementTemplate";
import createTerminationData from "./createTerminationData";

export default function createAgreementData(firebase, data) {
	const {
		approved = null,
		template = null,
		producer = null,
		createdBy = null,
		publisher = null,
		production = null,
		activeFrom = new Date(),
		firstParty = null,
		secondParty = null,
		firstSignee = null,
		firstPartyVendorId = null,
		secondPartyVendorId = null,
		signedAgreementFile = null,
	} = data;

	const { life, ...templateData } = createAgreementTemplate(firebase, template ? { ...template, ...data } : data);

	const db = firebase.firestore();
	const ref = data.ref || db.collection("agreements").doc();

	activeFrom.setHours(0, 0, 0, 0);

	const expires = new Date(activeFrom);

	const today = new Date();

	expires.setDate(activeFrom.getDate() + parseInt(life));

	return {
		...templateData,
		id: ref.id,
		ref,
		life,
		state: signedAgreementFile ? AGREEMENT_STATE.SIGNED : AGREEMENT_STATE.CREATED,
		// check if agreement is singed and also if the activeFrom date has passed
		status: signedAgreementFile
			? activeFrom <= today
				? agreementStatuses.ACTIVE
				: agreementStatuses.SIGNED
			: agreementStatuses.PENDING_APPROVAL,
		expires: firebase.firestore.Timestamp.fromDate(expires),
		activeFrom: firebase.firestore.Timestamp.fromDate(activeFrom),
		signedAgreementFile,
		initiatedSigning: false,
		template: getAgreementRef(template),
		producer: production?.producer ? getProductionProducerRef(db, production) : getOrganizationRef(producer),
		publisher: production?.publisher ? getProductionPublisherRef(db, production) : getOrganizationRef(publisher),
		createdBy: getUserRef(createdBy),
		production: getProductionRef(production),
		firstParty: getPartyRef(firstParty, firstPartyVendorId),
		secondParty: getPartyRef(secondParty, secondPartyVendorId),
		firstSignee: getUserRef(firstSignee),
		secondSignee: getUserRef(secondParty.signee || secondParty),
		termination: createTerminationData(),
		approval: createApprovalData(approved || null, createdBy),
	};
}
