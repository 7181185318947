import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import createAgreementData from "astrid-firestore/src/api/agreements/createAgreementData";

import PreviewAgreementPdfButton from "../../../../components/PreviewAgreementPdf/PreviewAgreementPdfButton";
import ContentCard from "../../../ui/components/ContentCard/ContentCard";

import Form from "../../../forms/components/Form/Form";

import { isAppendixesValid } from "../../utils/agreementValidators";

import submitAgreements from "./utils/submitAgreements";

export default function AgreementsFormActions() {
	const { t } = useTranslation();

	const { watch, formState, getValues, handleSubmit, afterSubmit } = Form.useFormContext();
	const { isSubmitting } = formState;

	const isValid = Form.usePartialFormValidation(["appendixes"], isAppendixesValid);

	const [approved, signedAgreementFile] = watch(["approved", "signedAgreementFile"]);

	const onSubmit = async (formData) => {
		await submitAgreements(formData);

		afterSubmit?.();
	};

	const previewRequest = () => {
		const { parties, ...values } = getValues();
		const secondParty = Object.values(parties)[0];

		return createAgreementData(firebase, { ...values, secondParty });
	};

	return (
		<ContentCard disabled={!isValid && !signedAgreementFile}>
			<Button
				primary
				loading={isSubmitting}
				disabled={(!isValid && !signedAgreementFile) || isSubmitting}
				onClick={handleSubmit(onSubmit)}
			>
				{signedAgreementFile
					? t("createAgreement", "Create agreement")
					: approved
					? t("sendForSigning", "Send for signing")
					: t("sendForApproval", "Send for approval")}
			</Button>{" "}
			<PreviewAgreementPdfButton request={!signedAgreementFile && previewRequest} />
		</ContentCard>
	);
}
