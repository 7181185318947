export default {
	name: "Polaris Förlag",
	format: "wav",
	connection: "ftp",
	cover: false,
	audio: {
		rename: "CLEANTITLE_SEQ_TOTAL.SUFFIX",
	},
	disableArticles: ["cd", "mp3cd"],
	email: ["delivery"],
};
