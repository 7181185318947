import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import Vendor from "../../../vendors/components/Vendor";

import OrderCosts from "./components/OrderCosts";

export default function OrderDetails({ order }) {
	const { t } = useTranslation();

	const billedVendor = order.expense ? order.firstParty?.vendor : order.secondParty?.vendor;
	const senderVendor = order.expense ? order.secondParty?.vendor : order.firstParty?.vendor;

	return (
		<Grid celled>
			<Grid.Row columns={2}>
				{billedVendor && (
					<Grid.Column>
						<strong>{t("recipient", "Recipient")}</strong>

						<Vendor vendor={billedVendor} />
					</Grid.Column>
				)}
				{senderVendor && (
					<Grid.Column>
						<strong>{t("sender", "Sender")}</strong>

						<Vendor vendor={senderVendor} />
					</Grid.Column>
				)}
			</Grid.Row>
			<Grid.Row>
				<Grid.Column>
					<OrderCosts order={order} />
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}
