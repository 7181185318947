import arrayChunk from "astrid-helpers/src/arrayChunk";

import getCollectionData from "./getCollectionData";

export default async function getCollectionDataById(firebase, ref, ids = []) {
	const chunks = await Promise.all(
		arrayChunk(ids, 10).map((chunk) =>
			getCollectionData(ref.where(firebase.firestore.FieldPath.documentId(), "in", chunk)),
		),
	);

	return chunks.flat();
}
