import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";

import useForm from "../../../../forms/hooks/useForm";

export default function useAgreementsForm({ template, organization }) {
	return useForm({
		defaultValues: {
			name: "",
			type: template?.type || agreementTypes.FRAMEWORK,
			part: template?.part,
			life: template?.life || 365,
			prices: Object.values(template?.prices || {}),
			parties: {},
			daysToSign: 14,
			activeFrom: new Date(),
			firstSigneeId: null,
			firstPartyVendorId: null,
			canManageAgreements: template?.canManageAgreements || false,
			appendixes: template?.appendixes || [],
			template,
			producer: organization,
			firstParty: organization,
		},
	});
}
