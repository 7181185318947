const producerRoles = {
	ADMIN: "producerAdmin",
	MANAGER: "producerStaff",
	RECORDER: "producerRecorder",
	EDITOR: "producerEditor",
	PROOFER: "producerProofer",
	SUBCONTRACTOR: "producerSubcontractor",
	READER_MANAGER: "producerReaderManager",
	LIMITED: "producerLimited",
};

export default producerRoles;
