import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function usePartiesColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "party",
				Header: t("party", "Party"),
			},
			{
				id: "signee",
				Header: t("signee", "Signee"),
			},
			{
				id: "state",
				Header: t("state", "State"),
			},
			{
				id: "timestamp",
				Header: t("timestamp", "Timestamp"),
				accessor: (data) => {
					return data?.time?.toDate()?.toLocaleString();
				},
			},
		],
		[t],
	);
}
