import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import Form from "../../../../forms/components/Form/Form";

import useRefinement from "../hooks/useRefinement";

export default function LanguageSkillsSelect() {
	const { t } = useTranslation();

	const {
		items,
		value = [],
		refine,
	} = useRefinement({
		attribute: "languageSkillCodes",
	});

	const languageOptions = useMemo(
		() =>
			items.map(({ value }) => ({
				key: value,
				value,
				text: languageName(value),
			})),
		[items],
	);

	return (
		<Form.Select
			name="languages"
			label={t("languageSkills", "Language skills")}
			value={value}
			options={languageOptions}
			multiple
			search
			deburr
			fluid
			onChange={(e, { value }) => refine(value)}
		/>
	);
}
