import db from "astrid-firebase/src/db";
import taskStatuses from "astrid-firestore/src/api/tasks/constants/taskStatuses";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

export default function useTasksByTaskType({
	taskTypes = [],
	status = [taskStatuses.COMPLETED, taskStatuses.CANCELLED, taskStatuses.IGNORED],
}) {
	const [tasks, loading, error] = useCollectionData(
		db.collection("tasks/queue/tasks").where("status", "in", status).orderBy("created", "desc").limit(100),
	);

	const filteredTasks = tasks?.filter((task) => taskTypes.includes(task.task));

	return [filteredTasks, loading, error];
}
