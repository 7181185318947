import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";
import DistributionsInstantSearchDataTable from "../../../distribution/components/DistributionsInstantSearchDataTable/DistributionsInstantSearchDataTable";

export default function AdminDistributionsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("distributions", "Distributions")}>{searchInput}</AdminHeader>

			<DistributionsInstantSearchDataTable organization={organization} searchQuery={searchQuery} />
		</>
	);
}
