import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

import CancelOfferDropdownItem from "../items/CancelOfferDropdownItem";
import DeleteOfferDropdownItem from "../items/DeleteOfferDropdownItem";

export default function OpenOfferDropdownMenu({ offer }) {
	return (
		<DropdownMenu>
			{({ setLoading }) => (
				<>
					<CancelOfferDropdownItem offer={offer} setLoading={setLoading} />
					<DeleteOfferDropdownItem offer={offer} setLoading={setLoading} />
				</>
			)}
		</DropdownMenu>
	);
}
