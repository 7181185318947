import React from "react";
import { Icon } from "semantic-ui-react";

const ScheduledObstacles = ({ obstacles }) => {
	return (
		(obstacles?.length &&
			obstacles.map((problem, i) => (
				<div key={i}>
					<Icon name="warning circle" color="red" />
					{problem}
				</div>
			))) || (
			<div>
				<Icon name="check circle" color="green" />
				Allt redo
			</div>
		)
	);
};

export default ScheduledObstacles;
