import { z } from "zod";

import { UserRef } from "../api/users/types/User";

import { boolean, nullable, timestamp } from "./_types";

const FirestoreLifecycle = z.object({
	deleted: boolean(),
	deletedAt: nullable(timestamp()),
	deletedBy: nullable(UserRef),
	created: nullable(timestamp()),
	createdBy: nullable(UserRef),
});

export default FirestoreLifecycle;
