import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import CreateStudioForm from "../CreateStudioForm/CreateStudioForm";

export default function CreateStudioAction({ gen, organization }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)}>{t("addStudio", "Add studio")}</Button>

			{open && <CreateStudioForm gen={gen} organization={organization} onClose={() => setOpen(false)} />}
		</>
	);
}
