import React from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineStepActionArgs from "./PipelineStepActionArgs";
import PipelineStepActionSelect from "./PipelineStepActionSelect";
import PipelineStepForEachCheckbox from "./PipelineStepForEachCheckbox";
import PipelineStepNeedsSelect from "./PipelineStepNeedsSelect";
import PipelineSteps from "./PipelineSteps";

export default function PipelineStepContent({ id, name, fields, error }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const [action, needs] = watch([`${name}.action`, `${name}.needs`]);

	return (
		<Accordion.Content active>
			<div style={{ padding: 10, marginBottom: 10 }}>
				<PipelineStepNeedsSelect id={id} name={name} fields={fields} />

				{needs && <PipelineStepForEachCheckbox name={name} />}

				<PipelineStepActionSelect name={name} />

				<PipelineStepActionArgs action={action} name={name} />

				{!action && <PipelineSteps name={`${name}.steps`} label={t("steps", "Steps")} error={error} />}
			</div>
		</Accordion.Content>
	);
}
