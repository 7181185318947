import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import taskStatuses from "astrid-firestore/src/api/tasks/constants/taskStatuses";
import updateDocument from "astrid-firestore/src/helpers/updateDocument";
import useHasRole from "astrid-web/src/features/authorization/hooks/useHasRole";

import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

export default function TaskDropdownMenu({ task, link }) {
	const hasRole = useHasRole();

	const { t } = useTranslation();

	return (
		<DropdownMenu>
			{({ setLoading }) => (
				<>
					{link && <Dropdown.Item as={Link} {...link} />}

					<Dropdown.Item
						text={t("retry", "Retry")}
						disabled={task.status !== taskStatuses.FAILED && !hasRole("owner")}
						onClick={async () => {
							setLoading(true);
							await updateDocument(task.ref, {
								status: taskStatuses.RETRY,
							});
							setLoading(false);
						}}
					/>

					<Dropdown.Item
						text={t("markAsCompleted", "Mark as completed")}
						disabled={task.status !== taskStatuses.FAILED}
						onClick={async () => {
							setLoading(true);
							await updateDocument(task.ref, {
								status: taskStatuses.COMPLETED,
							});
							setLoading(false);
						}}
					/>

					<Dropdown.Item
						text={t("cancel", "Cancel")}
						disabled={task.status !== taskStatuses.FAILED}
						onClick={async () => {
							setLoading(true);
							await updateDocument(task.ref, {
								status: taskStatuses.CANCELLED,
							});
							setLoading(false);
						}}
					/>
				</>
			)}
		</DropdownMenu>
	);
}
