import debounce from "debounce";
import { useMemo, useRef } from "react";

export default function useDebounceCallback(callback, delay = 500) {
	const ref = useRef(callback);

	ref.current = callback;

	return useMemo(() => debounce(ref.current, delay), [delay]);
}
