import { passwordStrength } from "check-password-strength";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Form, Header, Message, Segment } from "semantic-ui-react";

import { confirmPasswordReset, verifyPasswordResetCode } from "../utils/resetPassword";

export default function ResetPassword() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [code, setCode] = useState(null);
	const [email, setEmail] = useState("");

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [disabled, setDisabled] = useState(false);

	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");

	const strength = useMemo(() => passwordStrength(password), [password]);

	const onReset = async () => {
		setError(null);
		setPassword("");
		setPasswordConfirmation("");

		if (password !== passwordConfirmation) {
			setError(t("passwordMismatch", "Passwords do not match"));
			return;
		}

		if (strength.id < 2) {
			setError(t("passwordTooWeak", "The password is too weak"));
			return;
		}

		setLoading(true);

		try {
			await confirmPasswordReset(code, password);
			navigate(`/?email=${email}`);
		} catch (error) {
			setError(error);
		}

		setLoading(false);
	};

	useEffect(() => {
		if (code) {
			verifyPasswordResetCode(code)
				.then((email) => {
					setEmail(email);
					setLoading(false);
				})
				.catch((error) => {
					setError(error.message);
					setLoading(false);
					setDisabled(true);
				});
		}
	}, [code]);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);

		setCode(params.get("oobCode"));
	}, []);

	const strengthTranslations = {
		0: t("passwordStrength.0", "Very weak"),
		1: t("passwordStrength.1", "Weak"),
		2: t("passwordStrength.2", "Fair"),
		3: t("passwordStrength.3", "Good"),
		4: t("passwordStrength.4", "Strong"),
	};

	const strengthColors = {
		0: "red",
		1: "orange",
		2: "yellow",
		3: "olive",
		4: "green",
	};

	return (
		<Segment
			padded={window.innerWidth > 600 ? "very" : null}
			loading={loading}
			style={{ textAlign: "left", overflow: "hidden" }}
		>
			<Header>
				{t("createNewPassword", "Create a new password")}
				<Header.Subheader>
					{t(
						"createNewPasswordRules",
						"Password must be at least 8 characters, contain an uppercase character, number and symbol.",
					)}{" "}
					<em>{t("createNewPasswordExample", "Example: Astrid123!")}</em>
				</Header.Subheader>
			</Header>

			<Form onSubmit={onReset}>
				<input type="hidden" name="email" value={email} />

				<Form.Input
					name="password"
					type="password"
					disabled={disabled}
					placeholder={t("password")}
					value={password}
					onChange={(e, { value }) => setPassword(value)}
					fluid
				/>

				<Form.Input
					name="passwordConfirmation"
					type="password"
					disabled={disabled}
					placeholder={t("passwordConfirmation", "Password confirmation")}
					value={passwordConfirmation}
					onChange={(e, { value }) => setPasswordConfirmation(value)}
					fluid
				/>

				{!password && error && <Message color="red">{error}</Message>}

				{password && (
					<Message color={strengthColors[strength.id]}>
						{t("passwordStrength", "Password strength")}: {strengthTranslations[strength.id]}
					</Message>
				)}

				<Button color="blue" floated="right" disabled={disabled}>
					{t("save")}
				</Button>
			</Form>

			<Button basic onClick={() => navigate("/login")}>
				{t("loginBtn")}
			</Button>
		</Segment>
	);
}
