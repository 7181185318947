import { useCollectionData } from "astrid-firestore/src/hooks/index";

import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useLanguageOptions from "../../../../users/hooks/useLanguageOptions";

import useNotificationsQuery from "../../../hooks/useNotificationsQuery";

export default function useNotifications({ organization }) {
	const user = useProfile();
	const hasRight = useHasRight();

	const languageOptions = useLanguageOptions({ organization });

	const [notifications, loading, error] = useCollectionData(
		useNotificationsQuery({
			userId: user.id,
			organizationId: hasRight("organization.notifications.view") && organization?.id,
		}),
	);

	const filteredNotifications = notifications?.filter(
		(notification) =>
			!languageOptions?.length ||
			!notification.languages ||
			notification.languages?.some((language) => languageOptions.includes(language)),
	);

	return [filteredNotifications, loading, error];
}
