import setData from "astrid-firestore/src/helpers/setData";

import useForm from "./useForm";
import useFormSubmit from "./useFormSubmit";

export default function useFirestoreForm({ ref, defaultValues }) {
	const form = useForm({ defaultValues });

	const [onSubmit, loading, error] = useFormSubmit(form, async (data) => {
		await setData(ref, data);
	});

	return { form, loading, error, onSubmit };
}
