import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Header, Icon } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";

export default function Release({ release }) {
	const { t } = useTranslation();
	return (
		<Flex justifyContent="center" style={{ marginTop: 25, opacity: !release.url ? 0.3 : 1 }}>
			<Link to={release.url || "#"} style={{ cursor: !release.url ? "default" : "pointer" }}>
				<Header as="h3" style={{ fontSize: 21 }}>
					<Icon name={release.icon} style={{ marginRight: 10, fontSize: 37 }} />

					<Header.Content style={{ flex: "0 1 auto" }}>
						{release.os}

						<Header.Subheader>
							{release.version ? (
								<>
									{release.version} ({release.releaseDate?.toLocaleDateString()})
								</>
							) : (
								t("missingVersion", "Missing version")
							)}
						</Header.Subheader>
					</Header.Content>
				</Header>
			</Link>
		</Flex>
	);
}
