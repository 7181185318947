import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import useStudioOptions from "../../../../studios/hooks/useStudioOptions";

export default function CalendarFiltersStudioSelect({ organizationId }) {
	const { t } = useTranslation();
	const { setValue, watch } = Form.useFormContext();

	const [options, loading] = useStudioOptions({ organizationId });

	const name = "studios";

	const selectedStudios = watch(name);

	const groupOptions = [
		...new Set(
			options
				.filter((option) => !selectedStudios.includes(option.value))
				.reduce(
					(acc, option) => [
						...acc,
						...(option.data.groupTags || []),
						...(option.data.group ? [option.data.group] : []),
					],
					[],
				),
		),
	]
		.sort((a, b) => a.localeCompare(b))
		.map((group) => ({
			key: group,
			value: group,
			group: true,
			text: `${group} (${t("group", "Group")})`,
		}));

	return (
		<Form.Select
			name={name}
			label={t("studio", "Studio")}
			shouldUnregister={false}
			multiple
			clearable
			search
			options={[...groupOptions, ...options]}
			loading={loading}
			onChange={(e, { option, value }) => {
				if (option?.group) {
					const studiosInGroup = options.filter(
						(studio) =>
							!selectedStudios.includes(studio.value) &&
							(studio.data.groupTags?.includes(option.value) || studio.data.group === option.value),
					);

					setValue(name, [...selectedStudios, ...studiosInGroup.map((option) => option.value)]);
				} else {
					setValue(name, value);
				}
			}}
		/>
	);
}
