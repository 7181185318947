import React from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../ui/components/Select/Select";

import useAgreementOptions from "../../hooks/useAgreementOptions";

export default function AgreementSelect({ role, type, firstPartyId, secondPartyId, ...props }) {
	const { t } = useTranslation();

	const [agreementOptions, loading] = useAgreementOptions({
		role,
		type,
		firstPartyId,
		secondPartyId,
	});

	return (
		<Select
			label={t("agreement")}
			options={agreementOptions}
			loading={loading}
			disabled={agreementOptions.length === 0}
			{...props}
		/>
	);
}
