import { useOutletContext } from "react-router-dom";

import SidebarLayout from "../../../ui/layouts/SidebarLayout/SidebarLayout";

import AccountSidebar from "./AccountSidebar";

export default function AccountLayout() {
	const context = useOutletContext();

	return <SidebarLayout context={context} sidebar={<AccountSidebar />} />;
}
