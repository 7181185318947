import round from "astrid-helpers/src/round";

export default function createCost(db, data = {}) {
	const {
		id = undefined,
		activity = 0,
		agreement = null,
		amount = 0,
		amountProduced = 0,
		costs = [],
		deleted = false,
		employee = false,
		estimation = null,
		exchange = null,
		expense = false,
		firstParty = null,
		orderId = null,
		price = null,
		producer = null,
		production = null,
		publisher = null,
		secondParty = null,
		processed = false,
		total = 0,
		value = 0,
	} = data;

	const ref = data.ref || (id ? db.collection("costs").doc(id) : db.collection("costs").doc());

	const totalVat = round(((price?.vat || 0) / 100) * total, 2);
	const totalIncVat = round(total + totalVat, 2);

	return {
		id: ref.id,
		ref,
		activity: +activity,
		agreement,
		amount: +amount,
		amountProduced: +amountProduced,
		amounts: costs.reduce(
			(map, cost) => ({
				...map,
				[cost.price.id]: cost.openAmount,
			}),
			{},
		),
		created: new Date(Date.now()),
		deleted,
		employee,
		estimation,
		exchange,
		expense,
		firstParty,
		orderId,
		price,
		producer,
		production,
		publisher,
		secondParty,
		processed,
		total,
		totalVat,
		totalIncVat,
		value: +value,
	};
}
