import { useCallback, useState } from "react";

import approveDocument from "astrid-firestore/src/api/approval/approveDocument";
import denyDocument from "astrid-firestore/src/api/approval/denyDocument";
import resetDocument from "astrid-firestore/src/api/approval/resetDocument";

import { useProfile } from "../../authentication/state/profile";

export default function useApprovalActions(ref) {
	const user = useProfile();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const action = useCallback(
		async (approvalMethod, data = {}) => {
			setError(null);
			setLoading(true);

			try {
				await approvalMethod(ref, { user, ...data });
			} catch (error) {
				setError(error);
			}

			setLoading(false);
		},
		[ref, user],
	);

	const approve = useCallback((data) => action(approveDocument, data), [action]);
	const deny = useCallback((data) => action(denyDocument, data), [action]);
	const reset = useCallback(() => action(resetDocument), [action]);

	return { approve, deny, reset, error, loading };
}
