export default {
	name: "Nextory",
	format: "wav",
	connection: "ftp",
	meta: {
		folder: "meta",
		format: "onix",
		plaintextSynopsis: true, // no formatting in synopsis/description
		fields: ["availability", "takedown"], // extra metadata fields
		sharedFields: ["tags", "bisac"], // extra shared metadata fields
		// requiredFields: ["pricePayPerLoan", "priceUnitSale"],
	},
	audio: {
		folder: "media/ISBN",
		rename: "ISBN_SEQ_TOTAL.SUFFIX",
	},
	ebook: {
		folder: "media",
	},
	cover: {
		folder: "media",
		rename: "ISBN",
	},
	disableArticles: ["cd", "mp3cd"],
};
