import { Table } from "semantic-ui-react";

import AppendixSelect from "./AppendixSelect";

export default function AddAppendix({ onAdd, ...props }) {
	return (
		<Table.Row>
			<Table.Cell width={6}>
				<AppendixSelect
					{...props}
					value={null}
					onChange={(e, { value, option }) =>
						onAdd({
							appendixId: value,
							name: option?.data?.name || "",
							file: option?.data?.file || null,
							text: "",
						})
					}
				/>
			</Table.Cell>
			<Table.Cell colSpan={2} />
		</Table.Row>
	);
}
