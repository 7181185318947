import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import { durationToSec, secToDuration } from "../../helpers/fnc";

import { estimateTotal } from "./fnc";

class ProgressEdit extends Component {
	render() {
		const { t, label, production, size = "" } = this.props;
		let { proofParts, progress } = production;

		if (typeof progress === "object" && (production.productionType === "subcontract" || !production.proofParts)) {
			// new progress data
			return progressBar({ production, progress, size, label, t });
		} else if (proofParts) {
			// legacy progress info
			return legacyProgressBar({ production, proofParts, size, label, t });
		} else {
			return null;
		}
	}
}

const progressBar = ({ production, progress, size, label, t }) => {
	let prooferToShow = production.proofer?.[0] || (progress.proofed ? Object.keys(progress.proofed)[0] : null);

	// if (production.proofer?.length > 1) {
	// more than one proofer, show self to proofers and least progressed to admins?
	// console.log("implement");
	// }
	//const { t } = this.props;
	const prooferProgress = production.progress?.proofed?.time || progress?.proofed?.[prooferToShow] || 0;
	const editProgress = progress.edited?.time || progress.edited;

	const total = estimateTotal(production) * 1000;

	let estimatedTotal = isFinite(total) ? total : editProgress;
	let recordedTotal = production.progress?.recorded?.time || estimatedTotal;
	let halfDone;
	let percent = Math.round((editProgress / estimatedTotal) * 100);
	let proofProgress = (prooferProgress / editProgress) * 100;
	let proofProgressTotal = (prooferProgress / recordedTotal) * 100;
	let status = percent > 98 && proofProgressTotal >= 98 ? "done" : "";

	return (
		<div className={"ui progress earselect-editprogress " + size}>
			<Popup
				content={
					<div>
						<strong>{t("editedEdit")} </strong>
						{secToDuration(editProgress / 1000, false)}
						<br />
						{prooferProgress && (
							<>
								<strong>{t("proofProgress")}</strong>
								{secToDuration(prooferProgress / 1000, false)}
							</>
						)}
					</div>
				}
				inverted
				size="tiny"
				position="top center"
				style={{ textAlign: "center" }}
				trigger={
					<div
						className={"bar " + (status === "done" ? "proof" + (halfDone ? " half" : "") : "")}
						style={{ flexBasis: percent + "%" }}
					>
						{size !== "tiny" && <Icon name={status === "done" ? "headphones" : "cut"} />}
						{!!proofProgress && <div className="proof-progress" style={{ width: proofProgress + "%" }} />}
					</div>
				}
			/>
			{label && <div className="label">{label}</div>}
		</div>
	);
};

const legacyProgressBar = ({ production, proofParts, size, label, t }) => {
	let total, estimatedTotal, editedDuration;

	if (production.stats && production.stats.cubase) {
		// base stats on logged sessions
		estimatedTotal = estimateTotal(production);
		editedDuration = production.recordedDurationEdited
			? durationToSec(production.recordedDurationEdited)
			: proofParts
			? Object.values(proofParts)
					.filter((part) => part.status !== "deleted")
					.reduce((curr, part) => curr + part.audioDuration, 0)
			: 0;

		total = editedDuration > estimatedTotal * 0.95 ? editedDuration : estimatedTotal;
	} else if (proofParts) {
		// base stats on self recorded parts
		total = production.pages;
	} else {
		return null;
	}

	// retrofit new proofData from Astrid Studio to match old part based proof status
	if (production.prooferData) {
		let prooferToShow = production.proofer[0];

		if (production.proofer.length > 1) {
			// more than one proofer, show self to proofers and least progressed to admins
			console.log("implement");
		}

		// massage data
		const { progress, completed } = production.prooferData[prooferToShow] || {};

		proofParts = {
			legacyFakeKey: {
				audioDuration: durationToSec(production.recordedDurationEdited),
				lastMarker: durationToSec(production.recordedDurationEdited) * (progress || 0),
				status: completed ? "done" : "",
			},
		};
	}

	let pi = 0;
	let lastSelfRecordedPage = 0;

	return (
		<div className={"ui progress earselect-editprogress " + size}>
			{Object.values(proofParts)
				.filter((part) => part.status !== "deleted" && part.audioDuration)
				.sort((a, b) => (a.sort > b.sort ? 1 : -1))
				.map((part) => {
					// based on sessions or self recorded parts
					const percentBase = part.selfRecordedLastPage
						? part.selfRecordedLastPage - lastSelfRecordedPage
						: part.audioDuration;

					// count pages since last part for self recorded parts
					if (part.selfRecordedLastPage) lastSelfRecordedPage = part.selfRecordedLastPage;

					const percent = (percentBase / total) * 100;
					pi++;

					const halfDone =
						part.status === "done" &&
						production.proofer &&
						production.proofer.length > 1 &&
						part.delivered &&
						Object.keys(part.delivered).length < production.proofer.length;

					// proof progress
					let proofProgress = 0;
					if (part.lastMarker && (part.status !== "done" || halfDone)) {
						proofProgress = (part.lastMarker / part.audioDuration) * 100;
						if (proofProgress > 100) proofProgress = 100;
					}

					return (
						<Popup
							content={
								<div>
									<strong>
										{t("partProgress")} #{pi}
									</strong>
									<br />
									{secToDuration(part.audioDuration, false)}
									<br />
									{part.status === "done"
										? t("proofedProgress") +
										  (halfDone
												? ` (${Object.keys(part.delivered).length}/${
														production.proofer.length
												  })`
												: "")
										: t("editedProgress")}
								</div>
							}
							inverted
							size="tiny"
							position="top center"
							style={{ textAlign: "center" }}
							key={pi}
							trigger={
								<div
									className={
										"bar " + (part.status === "done" ? "proof" + (halfDone ? " half" : "") : "")
									}
									style={{ flexBasis: percent + "%" }}
								>
									{size !== "tiny" && <Icon name={part.status === "done" ? "headphones" : "cut"} />}
									{!!proofProgress && (
										<div className="proof-progress" style={{ width: proofProgress + "%" }} />
									)}
								</div>
							}
						/>
					);
				})}
			{label && <div className="label">{label}</div>}
		</div>
	);
};

export default withTranslation()(ProgressEdit);
