import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

export default function RemoveVoiceAction({ voice, production, setLoading }) {
	const { t } = useTranslation();

	return (
		<Dropdown.Item
			text={t("remove", "Remove")}
			disabled={voice.id === "1" || voice.offers.length > 0}
			onClick={async () => {
				setLoading?.(true);
				await updateDocument(production.ref, {
					[`voices.${voice.id}`]: firebase.firestore.FieldValue.delete(),
				});
				setLoading?.(false);
			}}
		/>
	);
}
