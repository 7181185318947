import firebase from "firebase/app";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, Modal } from "semantic-ui-react";

import cancelSession from "astrid-firestore/src/api/sessions/cancelSession";
import notifyCancelledSession from "astrid-firestore/src/api/sessions/notifications/notifyCancelledSession";

import { useProfile } from "../../../../authentication/state/profile";
import Form from "../../../../forms/components/Form/Form";
import useForm from "../../../../forms/hooks/useForm";

export default function CancelSessionForm({ session, onClickBack, onClose }) {
	const { t } = useTranslation();
	const profile = useProfile();

	const [error, setError] = useState(null);

	const form = useForm({
		defaultValues: {
			cancelReason: "",
			notifyAffectedUsers: false,
		},
	});

	const { handleSubmit, formState } = form;
	const { isValid, isSubmitting } = formState;

	const submit = handleSubmit(async ({ cancelReason, notifyAffectedUsers }) => {
		setError(null);

		try {
			await cancelSession(firebase, { session, cancelReason, user: profile });

			if (notifyAffectedUsers) {
				const userIds = [...session.reader, session.recorder].filter((id) => id !== profile.id);

				await notifyCancelledSession({ before: session, after: session, userIds, t });
			}

			onClose();
		} catch (error) {
			setError(error);
			console.error(error);
		}
	});

	return (
		<>
			<Modal.Content>
				<Form form={form}>
					<Form.TextArea name="cancelReason" label={t("reasonForCancel", "Reason for cancelling")} />

					<Form.Checkbox
						name="notifyAffectedUsers"
						label={t("notifyAffectedUsers", "Notify affected users")}
						rules={{ required: false }}
					/>
				</Form>
				{error && <Message negative>{t("errorMessageHeader", "Something went wrong")}</Message>}
			</Modal.Content>
			<Modal.Actions>
				<Button disabled={isSubmitting} onClick={onClickBack}>
					{t("back", "Back")}
				</Button>

				<Button color="red" loading={isSubmitting} disabled={isSubmitting || !isValid} onClick={submit}>
					{t("confirm", "Confirm")}
				</Button>
			</Modal.Actions>
		</>
	);
}
