import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useApproveAgreementsColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				collapsing: true,
				accessor: ({ created }) => created?.toDate?.().toLocaleString(),
			},
			{
				id: "producer.name",
				Header: t("producer", "Producer"),
				collapsing: true,
			},
			{
				id: "name",
				Header: t("name"),
				collapsing: true,
			},
			{
				id: "secondParty.name",
				Header: t("secondParty", "Second party"),
				collapsing: true,
			},
			{
				id: "expires",
				Header: t("expires", "Expires"),
				collapsing: false,
				accessor: ({ expires }) => expires?.toDate?.().toLocaleString(),
			},
		],
		[t],
	);
}
