import React from "react";

import Select from "../../../ui/components/Select/Select";

import useTeamRoleOptions from "../../hooks/useTeamRoleOptions";

export default function TeamRoleSelect(props) {
	const options = useTeamRoleOptions();

	return <Select options={options} {...props} />;
}
