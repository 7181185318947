import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, Header, Menu } from "semantic-ui-react";

import SelectColumns from "../../ui/components/SelectColumns/SelectColumns";

export default function MyProductionsAccordion({ selectedColumns, selectColumn }) {
	const { t } = useTranslation();
	const [openAccordions, setOpenAccordions] = useState(["columns"]);

	return (
		<Accordion as={Menu} vertical fluid>
			<Menu.Item>
				<Accordion.Title
					index="columns"
					active={openAccordions.includes("columns")}
					content={
						<div>
							<Header as="h4">{t("columns")}</Header>
						</div>
					}
					onClick={(e, { index }) => {
						if (openAccordions.includes(index)) {
							setOpenAccordions(openAccordions.filter((activeIndex) => activeIndex !== index));
						} else {
							setOpenAccordions(openAccordions.concat(index));
						}
					}}
				/>
				<Accordion.Content
					active={openAccordions.includes("columns")}
					content={<SelectColumns selectedColumns={selectedColumns} selectColumn={selectColumn} />}
				/>
			</Menu.Item>
		</Accordion>
	);
}
