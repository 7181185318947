export default function Bar({ index, row, rowStyle, isCompleted, colors }) {
	if (!row.progress) {
		return false;
	}
	return (
		<div
			className={`ui small progress ${!isCompleted && index !== 0 && "active"}`}
			style={{ ...rowStyle, zIndex: index, background: "none", margin: 0 }}
		>
			<div
				className="bar"
				style={{
					width: `${row.progress > 100 ? 100 : row.progress}%`,
					backgroundColor: colors[row.color],
					outline: index === 0 && isCompleted ? "1.5px solid green" : "",
				}}
			/>
		</div>
	);
}
