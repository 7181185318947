import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAgreementParts from "../../../../agreements/hooks/useAgreementParts";
import useAgreementTypes from "../../../../agreements/hooks/useAgreementTypes";

export default function useApproveAgreementTemplatesColumns() {
	const { t } = useTranslation();

	const agreementTypes = useAgreementTypes();
	const agreementParts = useAgreementParts();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				collapsing: true,
				accessor: ({ created }) => created.toDate?.().toLocaleString(),
			},
			{
				id: "producer.name",
				Header: t("producer", "Producer"),
				collapsing: true,
			},
			{
				id: "name",
				Header: t("name"),
				collapsing: true,
			},
			{
				id: "type",
				Header: t("type", "Type"),
				collapsing: true,
				accessor: ({ type }) => agreementTypes[type],
			},
			{
				id: "part",
				Header: t("part", "Part"),
				collapsing: false,
				accessor: ({ part }) => agreementParts[part],
			},
		],
		[agreementParts, agreementTypes, t],
	);
}
