import get from "lodash/get";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Header, Icon, Popup, Table, Transition } from "semantic-ui-react";

import { base, db, firebase } from "astrid-firebase";
import arrayChunk from "astrid-helpers/src/arrayChunk";

import { withStore } from "../../helpers/context";
import { toDate } from "../../helpers/fnc";
import withUserProfile from "../../hocs/withUserProfile";

class HomeAlerts extends Component {
	state = { transition: true, prods: [] };

	componentDidMount() {
		const { profile } = this.props;
		const { alertProductions } = profile;

		const alertQueries = arrayChunk(Object.keys(alertProductions || {}), 10).map((chunk) =>
			db
				.collection("productions")
				.where(firebase.firestore.FieldPath.documentId(), "in", chunk)
				.where("deleted", "==", false)
				.get(),
		);

		const draftQueries = Object.keys(get(profile, "permissions.publisher") || {}).map((pubId) => {
			let q = db
				.collection("productions")
				.where("publisher", "==", pubId)
				.where("status", "==", "draft")
				.where("deleted", "==", false);

			if (get(profile, "languageAccess.length")) {
				q = q.where("language", "in", profile.languageAccess);
			}

			return q.get();
		});

		// MEMO: move to generic function? maybe make realtime? custom hook?
		Promise.all([...(alertQueries || []), ...(draftQueries || [])]).then((snaps) => {
			const prods = [];
			snaps.forEach((snap) => {
				snap.docs.forEach((doc) => {
					prods.push({ id: doc.id, ...doc.data() });
				});
			});

			this.setState({ prods });
			setTimeout(() => {
				this.setState({ transition: false });
			}, 400);
		});
	}

	render() {
		const { transition, prods } = this.state;
		const { t, profile, uid } = this.props;
		const { alertProductions } = profile;

		prods.forEach((prod) => {
			// call the status "booked" until first session is logged
			if (
				prod.status === "production" &&
				!prod.stats &&
				!prod.proofParts &&
				prod.productionType !== "subcontract"
			)
				prod.status = "booked";

			// call the status "mastring" when polish is done
			if (prod.status === "production" && prod.polishDone && prod.productionType !== "subcontract")
				prod.status = "mastering";
		});

		// check for old drafts (3 days past creation or 7 days past reminder)
		const oldDrafts = prods.filter(
			(prod) =>
				prod.status === "draft" &&
				(prod.readerStatus ||
					prod.readerSuggestion ||
					prod.deliveryDate ||
					moment().diff(moment(toDate(prod.draftReminder || prod.created)), "days") >=
						(prod.draftReminder ? 7 : 3)),
		);

		const passedAlerts = alertProductions
			? Object.keys(alertProductions).filter((alert) => toDate(alertProductions[alert].time) < new Date())
			: [];

		return !!(passedAlerts.length || oldDrafts.length) ? (
			<>
				<Header as="h4">
					<Transition animation="tada" duration={1400} visible={transition}>
						<Icon name="alarm" color="purple" />
					</Transition>
					<Header.Content>{t("reminders")}</Header.Content>
				</Header>

				<Table compact basic="very" sortable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t("titleHomeAlert")}</Table.HeaderCell>
							<Table.HeaderCell>{t("commentHomeAlert")}</Table.HeaderCell>
							<Table.HeaderCell>{t("reminderHomeAlert")}</Table.HeaderCell>
							<Table.HeaderCell>{t("deliveryDateHomeAlert")}</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{oldDrafts.map((prod) => (
							<Table.Row key={prod.id}>
								<Table.Cell collapsing selectable>
									<Link to={"/production/" + prod.id}>
										<Popup
											inverted
											size="mini"
											content={t("postponeReminder")}
											trigger={
												<Icon
													name="file outline"
													style={{ cursor: "pointer" }}
													onClick={(e) => {
														e.preventDefault();
														base.updateDoc("productions/" + prod.id, {
															draftReminder: new Date(),
														});
													}}
												/>
											}
										/>
										{prod.title}
									</Link>
								</Table.Cell>
								<Table.Cell>
									{(prod.readerStatus || prod.readerSuggestion || prod.deliveryDate) && (
										<strong>
											<Icon name="warning sign" color="orange" />
											{t("sendProdOffer")}{" "}
										</strong>
									)}
									{moment().diff(moment(toDate(prod.created)), "days") > 2 && (
										<em>
											{t("createdDraft")} {moment().diff(moment(toDate(prod.created)), "days")}{" "}
											{t("daysAgo")} {"!"}
										</em>
									)}
								</Table.Cell>
								<Table.Cell collapsing textAlign="right">
									{moment(toDate(prod.created)).format("YYYY-MM-DD HH:mm")}
								</Table.Cell>

								<Table.Cell collapsing textAlign="right">
									{prod.deliveryDate && moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
								</Table.Cell>
							</Table.Row>
						))}
						{passedAlerts
							.map((id) => prods.find((prod) => prod.id === id) || {})
							.filter((prod) => prod.id)
							.sort((a, b) => (a.deliveryDate > b.deliveryDate ? 1 : -1))
							.map((prod) => (
								<Table.Row key={prod.id}>
									<Table.Cell collapsing selectable>
										<Link to={"/production/" + prod.id}>
											<Popup
												inverted
												size="mini"
												content={t("resetReminder")}
												trigger={
													<Icon
														name="alarm mute"
														style={{ cursor: "pointer" }}
														onClick={(e) => {
															e.preventDefault();

															let alertProductions = profile.alertProductions;
															delete alertProductions[prod.id];

															base.updateDoc("users/" + uid, {
																alertProductions,
															});
														}}
													/>
												}
											/>
											{prod.title}
										</Link>
									</Table.Cell>
									<Table.Cell>
										<em>{alertProductions[prod.id].comment}</em>
									</Table.Cell>
									<Table.Cell collapsing textAlign="right">
										{moment(alertProductions[prod.id].time.toDate()).format("YYYY-MM-DD HH:mm")}
									</Table.Cell>

									<Table.Cell collapsing textAlign="right">
										{prod.deliveryDate && moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
									</Table.Cell>
								</Table.Row>
							))}
					</Table.Body>
				</Table>
			</>
		) : (
			""
		);
	}
}

export default withTranslation()(withStore(withUserProfile(HomeAlerts)));
