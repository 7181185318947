import capitalize from "lodash/capitalize";
import moment from "moment";
import "moment/dist/locale/en-gb";
import "moment/dist/locale/sv";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Popup } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

import { getProfile } from "../features/authentication/state/profile";
import i18n from "../i18n";

const changeLanguage = (languageCode) => {
	return (e) => {
		e.preventDefault();
		i18n.changeLanguage(languageCode);
		document.body.click();
	};
};

const LanguageSelector = () => {
	const [languages, setLanguages] = useState();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		// get languages from locize.com
		i18n.services.backendConnector.backend.getLanguages((error, langs) => {
			const isLive = window.location.hostname === "astrid.fm";
			const langArray = Object.entries(langs);

			// all languages on dev, stage and beta. only languages translated more that 90% on live.
			const availableLangs = isLive
				? langArray.filter(([code, lang]) => lang?.translated?.live > 0.9)
				: langArray;

			setLanguages(availableLangs);
		});
	}, [i18n]);

	useEffect(() => {
		moment.locale(i18n.language === "en" ? "en-gb" : i18n.language);

		const profile = getProfile();

		if (profile && profile.languageCode !== i18n.language) {
			updateDocument(profile.ref, { languageCode: i18n.language });
		}
	}, [i18n.language]);

	return (
		<Popup wide trigger={<Button icon="world" content="Language" size="mini" />} on="click">
			<Grid divided columns="equal">
				{languages?.map(([code, lang]) => (
					<Grid.Column key={code}>
						<Popup
							trigger={
								<Button
									primary
									size="mini"
									onClick={changeLanguage(code)}
									content={capitalize(lang.nativeName)}
								/>
							}
							content={t("translateContent") + lang.nativeName}
							position="top center"
							size="mini"
							inverted
						/>
					</Grid.Column>
				))}
			</Grid>
		</Popup>
	);
};

export default LanguageSelector;
