import { useMemo } from "react";

import { db } from "astrid-firebase";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

export default function useMyOffersQuery({ userId, orgId, type }) {
	return useMemo(() => {
		if (userId) {
			let query = db
				.collection("offers")
				.where("deleted", "==", false)
				.where("secondParty.id", "==", userId)
				.where("status", "in", [
					offerStatuses.OFFERED,
					offerStatuses.ACCEPTED,
					offerStatuses.INTERESTED,
					offerStatuses.DECLINED,
					offerStatuses.CANCELLED,
					offerStatuses.SIGNING,
				])
				.orderBy("createdAt", "desc")
				.limit(50);
			if (type === organizationTypes.NARRATOR) {
				return query.where("role", "==", teamRoles.READER);
			} else {
				return query.where("producer.id", "==", orgId);
			}
		}
		return false;
	}, [userId, type, orgId]);
}
