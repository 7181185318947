import { Segment } from "semantic-ui-react";

import useTableRows from "../../hooks/useTableRows";

import FlexTableHeader from "./components/FlexTableHeader";
import FlexTableRow from "./components/FlexTableRow";

const style = {
	margin: 0,
	border: "1px solid rgba(34,36,38,.15)",
	borderRadius: ".28571429rem",
	boxShadow: "none",
};

const defaultGetRowProps = (row) => ({});

export default function FlexTable({
	data,
	gap = 15,
	header,
	columns,
	footer,
	children,
	selectable,
	getRowDisabled,
	getRowKey = (row) => row.id,
	getRowProps = defaultGetRowProps,
	...props
}) {
	const rows = useTableRows({ data, columns });

	return (
		<Segment.Group style={style} {...props}>
			{header && <FlexTableHeader columns={columns} gap={gap} />}

			{rows?.map((row) => (
				<FlexTableRow
					key={getRowKey(row)}
					disabled={!!getRowDisabled?.(row.original)}
					row={row}
					gap={gap}
					selectable={selectable}
					{...getRowProps(row)}
				/>
			))}

			{children}

			{footer && <Segment secondary>{footer}</Segment>}
		</Segment.Group>
	);
}
