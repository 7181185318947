import nameCase from "astrid-helpers/src/nameCase";

import createFirestoreCollection from "../../firestore/createFirestoreCollection";

import { Translator } from "./types/Translator";

const translators = createFirestoreCollection("translators", Translator, {
	preProcess: async ({ api, firebase }, { data }) => {
		if (data.name) {
			data.name = nameCase(data.name);
			data.nameLowerCase = data.name.toLowerCase();

			// Check if a translator with the same name already exists
			const [existingTranslator] = await api.translators.getAll((ref) =>
				ref
					.where(firebase.firestore.FieldPath.documentId(), "!=", data.ref.id)
					.where("nameLowerCase", "==", data.nameLowerCase)
					.limit(1),
			);

			if (existingTranslator) {
				data.id = existingTranslator.id;
				data.ref = existingTranslator.ref;

				if (data.publisherIds) {
					data.publisherIds = [
						...existingTranslator.publisherIds,
						...data.publisherIds.filter((id) => !existingTranslator.publisherIds.includes(id)),
					];
				}
			}
		}

		return data;
	},
});

export default translators;
