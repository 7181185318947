export default {
	name: "Ellibs",
	format: "mp3",
	connection: "ftp",
	meta: {
		format: "excel",
		folder: "DATE",
		fields: ["availability"],
		sharedFields: ["tags", "bisac"],
		requiredFields: ["priceUnitSale"],
		currency: ["EUR"], // supported (or preselected) currencies
	},
	audio: {
		zip: true,
		rename: "ISBN_SEQ_TOTAL.SUFFIX",
		folder: "DATE",
	},
	cover: { folder: "DATE" },
	disableArticles: ["cd", "mp3cd"],
};
