import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useDistributionsTableColumns from "../../hooks/useDistributionsTableColumns";

export default function DistributionsInstantSearchDataTable({ article, organization, ...props }) {
	const navigate = useNavigate();

	const columns = useDistributionsTableColumns();

	const filters = [organization && `publisher.id:${organization.id}`, article && `article.id:${article.id}`]
		.filter(Boolean)
		.join(" AND ");

	return (
		<InstantSearchDataTable
			indexName="distributions"
			columns={columns}
			filters={filters}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/distributions/${id}`)}
			{...props}
		/>
	);
}
