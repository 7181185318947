import { useTranslation } from "react-i18next";

import api from "../../../../../api";
import useFileUpload from "../../../../ui/hooks/useFileUpload";

export default function useAdminTitleDropdownMenuOptions({ title }) {
	const { t } = useTranslation();

	const [{ uploadFile, input, error, loading }] = useFileUpload({
		bucketName: "earselect-uploads",
		getDestination: () => `covers/${title.id}`,
		onSuccess: (cover) => {
			return api.titles.update({ ref: title.ref, cover });
		},
	});

	const options = [
		{
			text: t("uploadCover", "Upload cover"),
			onClick: uploadFile,
		},
		{
			text: t("delete", "Delete"),
			onClick: () => {
				return api.titles.softDelete(title);
			},
		},
	];

	return { options, input, loading, error };
}
