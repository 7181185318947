import runBatch from "../../helpers/runBatch";

import createReaderInquiryTodoData from "../todos/data/offers/createReaderInquiryTodoData";

import offerStatuses from "./constants/offerStatuses";

export default async function declineInquiry(firebase, { offer, declineReason, declineReasonText }) {
	return runBatch(firebase.firestore(), (batch) => {
		const todo = createReaderInquiryTodoData(firebase, { offer, status: offerStatuses.DECLINED });

		batch.update(offer.ref, {
			status: offerStatuses.DECLINED,
			declineReason,
			declineReasonText,
		});

		batch.set(todo.ref, todo);
	});
}
