import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

export default function NarratorOrganizationContext() {
	const { orgId } = useParams();

	const organization = useMemo(
		() => ({
			id: orgId,
			type: organizationTypes.NARRATOR,
		}),
		[orgId],
	);

	return <Outlet context={{ organization }} />;
}
