import api from "../../../../api";
import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import FirestoreDocumentContext from "../../../firebase/components/FirestoreDocumentContext";

import AdminArticleView from "../../views/AdminArticleView/AdminArticleView";

export default function AdminArticle() {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<FirestoreDocumentContext param="article" api={api.articles}>
			<AdminArticleView />
		</FirestoreDocumentContext>
	);
}
