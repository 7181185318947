import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import useRemoveTeamMemberCallback from "../../../hooks/useRemoveTeamMemberCallback";

export default function RemoveMemberDropdownItem({ member, production, setLoading, setError }) {
	const { t } = useTranslation();

	const removeTeamMemberCallback = useRemoveTeamMemberCallback();

	return (
		<>
			<Dropdown.Item
				text={t("remove", "Remove")}
				onClick={async () => {
					setLoading?.(true);
					setError(null);
					try {
						await removeTeamMemberCallback({ member, production });
					} catch (error) {
						console.log(error);
						setError(error);
					}
					setLoading?.(false);
				}}
			/>
		</>
	);
}
