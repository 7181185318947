import { useMemo } from "react";

import { languageOptions } from "astrid-helpers/src/languages";

export default function useOrganizationLanguageOptions(organization) {
	return useMemo(
		() => languageOptions.filter(({ value }) => organization.languageOptions?.includes(value)),
		[organization.languageOptions],
	);
}
