import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useImprintsTableColumns from "../../hooks/useImprintsTableColumns";

export default function ImprintsCollectionDataTable({ getQuery = (query) => query, ...props }) {
	const navigate = useNavigate();

	const columns = useImprintsTableColumns();

	return (
		<CollectionDataTable
			filterable={false}
			query={getQuery(db.collection("imprints").where("deleted", "==", false))}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/imprints/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			searchableKeys={["name"]}
			{...props}
		/>
	);
}
