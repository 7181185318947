import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Icon, Segment } from "semantic-ui-react";

import Popup from "astrid-web/src/features/ui/components/Popup/Popup";

import Flex from "../../../../../components/Flex/Flex";

export default function HomeLayoutWidget({ icon, color, header, toolbar, children, collapsable = true }) {
	const { t } = useTranslation();

	const [collapsed, setCollapsed] = useState(false);

	return (
		<Grid.Row>
			<Grid.Column>
				<Segment padded>
					{header && (
						<Flex style={{ marginBottom: !collapsed && "1.4em" }}>
							<Header as="h4" style={{ margin: 0 }}>
								{icon && <Icon name={icon} color={color} />}

								<Header.Content>{header}</Header.Content>
							</Header>

							<Flex style={{ flex: 0, gap: 15 }}>
								{toolbar}

								{collapsable && (
									<div>
										<Popup
											content={collapsed ? t("open", "Open") : t("close", "Close")}
											trigger={
												<Icon
													link
													name={collapsed ? "chevron right" : "chevron down"}
													onClick={() => setCollapsed((collapsed) => !collapsed)}
												/>
											}
										/>
									</div>
								)}
							</Flex>
						</Flex>
					)}

					{!collapsed && children}
				</Segment>
			</Grid.Column>
		</Grid.Row>
	);
}
