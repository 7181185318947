import React, { Component } from "react";
import { firebase, base } from "astrid-firebase";

import { Segment, Table, Button } from "semantic-ui-react";
// import cloneDeep from "lodash/cloneDeep";

class TaskRunner extends Component {
	state = {
		tasks: {},
	};

	UNSAFE_componentWillMount() {
		base.bindCollection("tasks", {
			context: this,
			state: "tasks",
			withIds: true,
			then() {},
			onFailure(err) {
				console.log("task err", err);
			},
		});

		// var pi = 0;
		// var ci = 0;
		// base.get("productions", { withIds: true })
		// 	.then(prod => {
		// 		prod.forEach(prod => {
		// 			pi++;
		// 			base.get("productions/" + prod.id + "/comments", { withIds: true })
		// 				.then(com => {
		// 					com.forEach(com => {
		// 						ci++;
		// 						console.log("com", com.id, pi, ci);

		// 						if (!com.recipient.prodAdmin && com.recipient.prodStaff) console.log("com", com);
		// 					});
		// 				})
		// 				.catch(e => {
		// 					console.log(e);
		// 				});
		// 		});
		// 	})
		// 	.catch(e => {
		// 		console.log(e);
		// 	});
		// console.log(pi, ci);
	}

	render() {
		return (
			<Segment loading={this.state.loading} style={{ overflow: "hidden" }}>
				<h2>Uppgiftsköraren</h2>
				{/*

				BATCHA ÖVER ORD FRÅN EN PRODUKTION TILL EN ANNAN

				<Button
					content="Fixa ord"
					onClick={e => {
						console.log("ok");
						base.get("words", {
							context: this,
							withIds: true,
							// query: ref => ref.where("productions.cKcDsTWNDZKMltbzbQAw.index", "==", true),
							query: ref => ref.where("productions.dwiCRi1Fbac1ShHeERch.index", "==", true),
						}).then(words => {
							words.forEach(word => {
								const newData = cloneDeep(word);

								newData.productions = {
									qfiuGgSl44S1Jt0eMuKN: {
										author: ["ooUtvBYZzWHJR4basiei"],
										context: "",
										index: true,
										productionLanguage: "sv",
										wordLanguage: "en",
									},
								};

								if (word.contributions) {
									Object.keys(word.contributions).forEach(cont => {
										newData.contributions[cont] = {
											...word.contributions[cont],
											production: "qfiuGgSl44S1Jt0eMuKN",
										};
									});
								}

								base.updateDoc("words/" + word.id, newData);
							});
						});
					}}
				/> */}
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Uppgift</Table.HeaderCell>
							<Table.HeaderCell>Kördes senast</Table.HeaderCell>
							<Table.HeaderCell>Av</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{Object.keys(this.state.tasks)
							.filter((i) => this.state.tasks[i].task)
							.map((i) => {
								const { id, task, starter, time } = this.state.tasks[i];
								const since = time && time.toDate && (new Date() - time.toDate()) / 1000;
								return (
									<Table.Row key={i}>
										<Table.Cell>
											<Button
												// disabled={since < 60}
												color="teal"
												icon="undo"
												onClick={() => {
													base.updateDoc("tasks/" + id, {
														time: firebase.firestore.FieldValue.serverTimestamp(),
														starter: this.props.profile.email,
													});
												}}
											/>
											{task}
										</Table.Cell>
										<Table.Cell>
											{time && time.toDate().toLocaleString()}
											{since < 60 && <small>({since} s sedan)</small>}
										</Table.Cell>
										<Table.Cell>{starter}</Table.Cell>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</Segment>
		);
	}
}

export default TaskRunner;
