import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import HomeLayout from "../../layouts/HomeLayout/HomeLayout";

import useAlertProductions from "./hooks/useAlertProductions";
import useAlertsColumns from "./hooks/useAlertsColumns";

export default function AlertsWidget() {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const data = useAlertProductions();
	const columns = useAlertsColumns();

	return (
		<HomeLayout.Widget icon="alarm" color="purple" header={t("reminders")}>
			<DataTable
				data={data}
				columns={columns}
				onClick={({ id }) => navigate(`/production/${id}`)}
				noDataAvailableMessage={t("youHaveNoReminders", "You have no reminders")}
			/>
		</HomeLayout.Widget>
	);
}
