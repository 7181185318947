const storytoolMp3 = {
	name: "Storytool API (MP3)",
	audio: {
		zip: true,
		rename: "ISBN.SUFFIX",
	},
	connection: false,
	allowMissingISBN: true,
	disableArticles: ["cd", "mp3cd"],
};

export default storytoolMp3;
