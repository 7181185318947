import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

// import { Store } from "astrid-web/src/helpers/context";
import { firebase } from "astrid-firebase";

const ExportButton = ({ selected, selectAll, unselectAll }) => {
	const { t } = useTranslation();

	const nofArticles = Object.values(selected).reduce((prev, curr) => Object.keys(curr).length + prev, 0);

	const submit = ({ distributor }) => {
		// get id token
		firebase
			.auth()
			.currentUser.getIdToken()
			.then((token) => {
				const requestBody = {
					token: token,
					format: "excel",
					distributor,
					productions: JSON.stringify(selected), // Assuming articles is an array or object
				};

				// Define the URL of your Firebase function
				const functionUrl = window.ES.stage
					? "https://us-central1-stage-earselect.cloudfunctions.net/downloadMetadata"
					: "https://us-central1-earselect.cloudfunctions.net/downloadMetadata";

				// Make a fetch call to the Firebase function
				fetch(functionUrl, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(requestBody),
				})
					.then((response) => {
						if (response.ok) {
							return response.blob();
						} else {
							throw new Error("Failed to fetch excel metadata");
						}
					})
					.then((blob) => {
						return URL.createObjectURL(blob);
					})
					.then((url) => {
						window.open(url, "_blank");
					});
			})
			.catch((error) => {
				console.log("Export error:", error);
			});
	};

	return (
		<>
			<Dropdown text={t("export")} icon="file alternate" floating labeled pointing button className="icon">
				<Dropdown.Menu>
					<Dropdown.Header content={nofArticles + t("choosenArticles")} />
					<Dropdown.Item icon="square outline plus" text={t("markAll")} onClick={selectAll} />
					<Dropdown.Item icon="square outline minus" text={t("unMarkAll")} onClick={unselectAll} />
					<Dropdown.Divider />
					<Dropdown.Header content={t("fetchMetadata")} />
					<Dropdown.Item
						icon="file excel"
						text={t("excelStorytel")}
						onClick={() => submit({ distributor: "storytel" })}
						disabled={!nofArticles}
					/>
					<Dropdown.Item
						icon="file excel"
						text={t("excelAudibleDE")}
						onClick={() => submit({ distributor: "audibleDE" })}
						disabled={!nofArticles}
					/>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

export default ExportButton;
