import getCollectionData from "../../../helpers/getCollectionData";

import offerStatuses from "../constants/offerStatuses";
import offerTypes from "../constants/offerTypes";

export default async function getOffersToCancel(db, offer) {
	// If the offer doesn't have a voice id, we don't need to cancel anything
	if (!offer?.voiceId) {
		return [];
	}

	// Get all inquiry offers for the same production that are not deleted
	const offers = await getCollectionData(
		db
			.collection("offers")
			.where("deleted", "==", false)
			.where("type", "==", offerTypes.INQUIRY)
			.where("status", "==", offerStatuses.OFFERED)
			.where("voiceId", "==", offer.voiceId)
			.where("production.id", "==", offer.production.id),
	);

	// Filter all offers that are offered or interested
	return offers.filter(
		(o) => o.id !== offer.id && [offerStatuses.OFFERED, offerStatuses.INTERESTED].includes(o.status),
	);
}
