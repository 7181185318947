import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useArticleVersionsTableColumns from "../../hooks/useArticleVersionsTableColumns";

export default function ArticleVersionsCollectionDataTable({ article }) {
	const navigate = useNavigate();
	const columns = useArticleVersionsTableColumns({ article });

	return (
		<CollectionDataTable
			query={article.ref.collection("versions")}
			columns={columns}
			onClick={({ id }) => navigate(`${id}/after`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
		/>
	);
}
