import React from "react";
import { useTranslation } from "react-i18next";

import { priceTypeFromRole } from "astrid-firestore/src/api/prices/utils/priceMappers";

import Select from "../../../ui/components/Select/Select";

import useAgreementTemplateOptions from "../../hooks/useAgreementTemplateOptions";
import rolePartMap from "../../utils/rolePartMap";

export default function AgreementTemplateSelect({ type, role, firstPartyId, ...props }) {
	const { t } = useTranslation();

	const agreementTemplateOptions = useAgreementTemplateOptions({
		type,
		part: rolePartMap[role],
		priceType: priceTypeFromRole[role],
		firstPartyId,
	});

	return <Select search fluid label={t("template", "Template")} options={agreementTemplateOptions} {...props} />;
}
