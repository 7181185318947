import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Icon, Table } from "semantic-ui-react";

import Form from "../../../../forms/components/Form/Form";

import AppendixSelect from "./AppendixSelect";

function AppendixContent({ name, appendix, producerId }) {
	const { t } = useTranslation();

	switch (appendix) {
		case undefined:
			return null;
		case "file":
			return (
				<Form.UploadButton
					name={`${name}.file`}
					bucketName="earselect-secret"
					path={`appendixes/${producerId}`}
				/>
			);
		case "text":
			return <Form.TextArea name={`${name}.text`} placeholder={t("freeText", "Free text")} />;
		default:
			return (
				<Button as="a" href={appendix?.file?.url} target="_blank">
					{t("preview", "Preview")}
				</Button>
			);
	}
}

export default function Appendix({ name, index, options, disabled, onChange, onRemove, producerId }) {
	const { t } = useTranslation();
	const { watch } = useFormContext();

	const { appendixId, appendix } = watch(name, {});

	return (
		<Table.Row>
			<Table.Cell width={6} verticalAlign="top">
				<Form.Field
					name={`${name}.appendixId`}
					onChange={(e, { value, option }) =>
						onChange({
							appendixId: value,
							name: option?.data?.name || "",
							file: option?.data?.file || null,
							text: "",
						})
					}
				>
					<AppendixSelect number={index + 1} options={options} />
				</Form.Field>
			</Table.Cell>

			<Table.Cell verticalAlign="top">
				{appendixId && (
					<>
						<Form.Input name={`${name}.name`} placeholder={t("title", "Title")} />

						<AppendixContent name={name} appendix={appendix || appendixId} producerId={producerId} />
					</>
				)}
			</Table.Cell>

			{!disabled ? (
				<Table.Cell width={1} selectable textAlign="center" style={{ cursor: "pointer" }} onClick={onRemove}>
					<Icon name="trash" />
				</Table.Cell>
			) : (
				<Table.Cell width={1} />
			)}
		</Table.Row>
	);
}
