import denyDocument from "../approval/denyDocument";

import offerStatuses from "./constants/offerStatuses";

export default async function denyOffer({ offer, user }) {
	return denyDocument(offer.ref, {
		user,
		status: offerStatuses.APPROVAL_DENIED,
	});
}
