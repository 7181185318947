import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

import { durationToSec } from "../../helpers/fnc";

class ProgressRecording extends Component {
	render() {
		const { t, label, production, size = "" } = this.props;

		// logged pages or part pages?
		const pages =
			(production.stats && production.stats.pages && production.stats.pages.total) ||
			production.stats.selfRecordedPages ||
			(production.subcontractor && production.progress?.recorded?.time && production.pages) ||
			0;

		let percent = production.status === "done" ? 100 : Math.round((pages / production.pages) * 100);
		if (percent > 100) percent = 100;

		let editPercent;
		if (production.recordedDuration && production.recordedDurationEdited) {
			editPercent = Math.round(
				(durationToSec(production.recordedDurationEdited) / durationToSec(production.recordedDuration)) * 100,
			);
		}

		let done = percent > 99 && !editPercent;

		// override for external/backlist
		if (production.productionType && production.master && production.productionType !== "subcontract") {
			percent = 100;
			editPercent = 100;
			done = true;
		}

		return (
			<Popup
				content={
					<div>
						<strong>
							{percent}% {t("recordedProgress")}
						</strong>
						{!!production.pages && (
							<div>
								({pages} / {production.pages} {t("pagesProgress")})
							</div>
						)}
						{editPercent && (
							<div>
								{t("ofWhich")} {editPercent}% {t("editedProgress")}
							</div>
						)}
					</div>
				}
				inverted
				size="tiny"
				position="top center"
				style={{ textAlign: "center" }}
				trigger={
					<div
						className={
							"ui teal " +
							size +
							" " +
							(done || percent >= 100 ? "success" : "active") +
							" progress earselect-recordingprogress"
						}
					>
						<div className="bar" style={{ width: percent + "%" }}>
							{size !== "small" && size !== "tiny" && <div className="progress">{percent}%</div>}
							{!!editPercent && !done && (
								<div className="edit-progress" style={{ width: editPercent + "%" }} />
							)}
						</div>
						{size !== "tiny" && label && <div className="label">{label}</div>}
					</div>
				}
			/>
		);
	}
}

export default withTranslation()(ProgressRecording);
