import dot from "dot-object";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import PipelineStep from "./PipelineStep";

export default function PipelineSteps({ name, label }) {
	const { t } = useTranslation();
	const [activeIndex, setActiveIndex] = useState(null);

	const { formState, getValues } = Form.useFormContext();
	const { fields, update, append, remove, swap } = Form.useFieldArray({ name });

	const errors = dot.pick(name, formState.errors);

	const indexes = name.split(".").filter((i) => i !== "steps");

	const id = [...indexes.map((index) => Number(index) + 1), fields.length + 1].join("-");

	const onClick = (index) => {
		setActiveIndex((activeIndex) => (activeIndex === index ? null : index));
	};

	const onReorder = (from, to) => {
		if (to >= 0 && to < fields.length) {
			swap(from, to);
		}
	};

	const onAppend = () => {
		append({
			action: null,
			args: null,
			name: `step-${id}`,
			steps: null,
			disabled: false,
		});
	};

	const onRemove = (index) => {
		remove(index);
	};

	const onDisabled = (index) => {
		const data = getValues(`${name}.${index}`);
		update(index, { ...data, disabled: !data.disabled });
	};

	const style = {
		border: `2px solid ${activeIndex !== null ? "#2185d0" : "grey"}`,
		boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
		marginTop: 0,
	};

	return (
		<div className="field">
			{label && <label>{label}</label>}

			<Accordion styled fluid style={style}>
				{fields.map((field, index) => (
					<PipelineStep
						key={field.id}
						id={field.id}
						index={index}
						fields={fields}
						error={errors?.[index]}
						name={`${name}.${index}`}
						active={index === activeIndex}
						onClick={onClick}
						onRemove={onRemove}
						onReorder={onReorder}
						onDisabled={onDisabled}
					/>
				))}

				<Accordion.Footer icon="plus" onClick={onAppend}>
					{t("addStep", "Add step")}
				</Accordion.Footer>
			</Accordion>
		</div>
	);
}
