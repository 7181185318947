const languages = {
	af: { name: "Afrikaans", nativeName: "Afrikaans", iso6392: "afr" },
	sq: { name: "Albanian", nativeName: "Shqip", sv: "Albanska", iso6392: "alb" },
	ar: { name: "Arabic", nativeName: "العربية", sv: "Arabiska", iso6392: "ara" },
	hy: { name: "Armenian", nativeName: "Հայերեն", iso6392: "arm" },
	az: { name: "Azerbaijani", nativeName: "azərbaycan dili", iso6392: "aze" },
	eu: { name: "Basque", nativeName: "euskara, euskera", iso6392: "baq" },
	be: { name: "Belarusian", nativeName: "Беларуская", iso6392: "bel" },
	bn: { name: "Bengali", nativeName: "বাংলা", iso6392: "ben" },
	bs: { name: "Bosnian", nativeName: "bosanski jezik", iso6392: "bos" },
	bg: { name: "Bulgarian", nativeName: "български език", iso6392: "bul" },
	my: { name: "Burmese", nativeName: "ဗမာစာ", iso6392: "bur" },
	ca: { name: "Catalan; Valencian", nativeName: "Català", iso6392: "cat" },
	ce: { name: "Chechen", nativeName: "нохчийн мотт", iso6392: "che" },
	zh: { name: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語", iso6392: "chi" },
	co: { name: "Corsican", nativeName: "Corsu", iso6392: "cos" },
	hr: { name: "Croatian", nativeName: "Hrvatski", iso6392: "hrv" },
	cs: { name: "Czech", nativeName: "česky", iso6392: "cze" },
	da: { name: "Danish", nativeName: "Dansk", sv: "Danska", iso6392: "dan" },
	nl: { name: "Dutch", nativeName: "Nederlands, Vlaams", sv: "Holländska", iso6392: "dut" },
	en: { name: "English", nativeName: "English", sv: "Engelska", iso6392: "eng" },
	et: { name: "Estonian", nativeName: "eesti", iso6392: "est" },
	fo: { name: "Faroese", nativeName: "føroyskt", iso6392: "fao" },
	fj: { name: "Fijian", nativeName: "vosa Vakaviti", iso6392: "fij" },
	fi: { name: "Finnish", nativeName: "suomi", sv: "Finska", iso6392: "fin" },
	fr: { name: "French", nativeName: "français", sv: "Franska", iso6392: "fre" },
	ff: { name: "Fula; Fulah; Pulaar; Pular", nativeName: "Fulfulde, Pulaar, Pular", iso6392: "ful" },
	gl: { name: "Galician", nativeName: "Galego", iso6392: "glg" },
	ka: { name: "Georgian", nativeName: "ქართული", iso6392: "geo" },
	de: { name: "German", nativeName: "Deutsch", sv: "Tyska", iso6392: "ger" },
	el: { name: "Greek, Modern", nativeName: "Ελληνικά", iso6392: "gre" },
	ht: { name: "Haitian; Haitian Creole", nativeName: "Kreyòl ayisyen", iso6392: "hat" },
	he: { name: "Hebrew (modern)", nativeName: "עברית", iso6392: "heb" },
	hi: { name: "Hindi", nativeName: "हिन्दी, हिंदी", iso6392: "hin" },
	hu: { name: "Hungarian", nativeName: "Magyar", iso6392: "hun" },
	id: { name: "Indonesian", nativeName: "Bahasa Indonesia", iso6392: "ind" },
	ga: { name: "Irish", nativeName: "Gaeilge", iso6392: "gle" },
	is: { name: "Icelandic", nativeName: "Íslenska", sv: "Isländska", iso6392: "ice" },
	it: { name: "Italian", nativeName: "Italiano", sv: "Italienska", iso6392: "ita" },
	ja: { name: "Japanese", nativeName: "日本語 (にほんご／にっぽんご)", sv: "Japanska", iso6392: "jpn" },
	jv: { name: "Javanese", nativeName: "basa Jawa", iso6392: "jav" },
	kl: { name: "Kalaallisut, Greenlandic", nativeName: "kalaallisut", iso6392: "kal" },
	ks: { name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎", iso6392: "kas" },
	kk: { name: "Kazakh", nativeName: "Қазақ тілі", iso6392: "kaz" },
	ki: { name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ", iso6392: "kik" },
	ky: { name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили", iso6392: "kir" },
	kv: { name: "Komi", nativeName: "коми кыв", iso6392: "kom" },
	kg: { name: "Kongo", nativeName: "KiKongo", iso6392: "kon" },
	ko: { name: "Korean", nativeName: "한국어 (韓國語), 조선말 (朝鮮語)", iso6392: "kor" },
	ku: { name: "Kurdish", nativeName: "Kurdî, كوردی‎", iso6392: "kur" },
	la: { name: "Latin", nativeName: "Lingua latina", iso6392: "lat" },
	lb: { name: "Luxembourgish, Letzeburgesch", nativeName: "Lëtzebuergesch", iso6392: "ltz" },
	li: { name: "Limburgish", nativeName: "Limburgs", iso6392: "lim" },
	lo: { name: "Lao", nativeName: "ພາສາລາວ", iso6392: "lao" },
	lt: { name: "Lithuanian", nativeName: "lietuvių kalba", iso6392: "lit" },
	lv: { name: "Latvian", nativeName: "latviešu valoda", iso6392: "lav" },
	mk: { name: "Macedonian", nativeName: "македонски јазик", iso6392: "mac" },
	ms: { name: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎", iso6392: "may" },
	mn: { name: "Mongolian", nativeName: "монгол", iso6392: "mon" },
	ne: { name: "Nepali", nativeName: "नेपाली", iso6392: "nep" },
	nb: { name: "Norwegian Bokmål", nativeName: "Norsk bokmål", sv: "Norskt bokmål", iso6392: "nob" },
	nn: { name: "Norwegian Nynorsk", nativeName: "Norsk nynorsk", sv: "Nynorsk", iso6392: "nno" },
	no: { name: "Norwegian", nativeName: "Norsk", sv: "Norska", iso6392: "nor" },
	fa: { name: "Persian", nativeName: "فارسی", sv: "Persiska", iso6392: "per" },
	pl: { name: "Polish", nativeName: "Polski", sv: "Polska", iso6392: "pol" },
	pt: { name: "Portuguese", nativeName: "Português", iso6392: "por" },
	rm: { name: "Romansh", nativeName: "Rumantsch grischun", iso6392: "roh" },
	rn: { name: "Kirundi", nativeName: "kiRundi", iso6392: "run" },
	ro: { name: "Romanian, Moldavian, Moldovan", nativeName: "română", iso6392: "rum" },
	ru: { name: "Russian", nativeName: "русский язык", sv: "Ryska", iso6392: "rus" },
	sa: { name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्", iso6392: "san" },
	sc: { name: "Sardinian", nativeName: "sardu", iso6392: "srd" },
	sd: { name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎", iso6392: "snd" },
	se: { name: "Northern Sami", nativeName: "Davvisámegiella", sv: "Samiska", iso6392: "sme" },
	sm: { name: "Samoan", nativeName: "gagana faa Samoa", iso6392: "smo" },
	sr: { name: "Serbian", nativeName: "српски језик", iso6392: "srp" },
	gd: { name: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig", iso6392: "gla" },
	si: { name: "Sinhala, Sinhalese", nativeName: "සිංහල", iso6392: "sin" },
	sk: { name: "Slovak", nativeName: "slovenčina", iso6392: "slo" },
	sl: { name: "Slovene", nativeName: "slovenščina", iso6392: "slv" },
	so: { name: "Somali", nativeName: "Soomaaliga, af Soomaali", iso6392: "som" },
	es: { name: "Spanish; Castilian", nativeName: "español, castellano", sv: "Spanska", iso6392: "spa" },
	su: { name: "Sundanese", nativeName: "Basa Sunda", iso6392: "sun" },
	sw: { name: "Swahili", nativeName: "Kiswahili", iso6392: "swa" },
	sv: { name: "Swedish", nativeName: "Svenska", sv: "Svenska", iso6392: "swe" },
	ta: { name: "Tamil", nativeName: "தமிழ்", iso6392: "tam" },
	th: { name: "Thai", nativeName: "ไทย", iso6392: "tha" },
	ti: { name: "Tigrinya", nativeName: "ትግርኛ", iso6392: "tir" },
	bo: { name: "Tibetan Standard, Tibetan, Central", nativeName: "བོད་ཡིག", iso6392: "tib" },
	tk: { name: "Turkmen", nativeName: "Türkmen, Түркмен", iso6392: "tuk" },
	tl: { name: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔", iso6392: "tgl" },
	tr: { name: "Turkish", nativeName: "Türkçe", iso6392: "tur" },
	ty: { name: "Tahitian", nativeName: "Reo Tahiti", iso6392: "tah" },
	uk: { name: "Ukrainian", nativeName: "українська", iso6392: "ukr" },
	ur: { name: "Urdu", nativeName: "اردو", iso6392: "urd" },
	uz: { name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎", iso6392: "uzb" },
	vi: { name: "Vietnamese", nativeName: "Tiếng Việt", iso6392: "vie" },
	cy: { name: "Welsh", nativeName: "Cymraeg", iso6392: "wel" },
	yi: { name: "Yiddish", nativeName: "ייִדיש", iso6392: "yid" },
	XX: {
		name: "No language, yibberish, nonsense, fantasy...",
		nativeName: "No language, yibberish, nonsense, fantasy...",
		sv: "Inget språk, nonsens, fantasy...",
	},
	XZ: {
		name: "Other Language",
		nativeName: "Other Language",
		sv: "Annat/Okänt språk",
	},
	as: { name: "Assamese", nativeName: "অসমীয়া", iso6392: "asm" },
	or: { name: "Oriya", nativeName: "ଓଡ଼ିଆ Odia", iso6392: "ori" },
	te: { name: "Telugu", nativeName: "తెలుగు Telugu", iso6392: "tel" },
	gu: { name: "Gujarati", nativeName: "ગુજરાતી Gujarātī", iso6392: "guj" },
	ml: { name: "Malayalam", nativeName: "മലയാളം Malayāļã", iso6392: "mal" },
	mr: { name: "Marathi", nativeName: "मराठी Marāţhī", iso6392: "mar" },
	kn: { name: "Kannada", nativeName: "ಕನ್ನಡ", iso6392: "kan" },
};

export default languages;
