import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Header, Icon } from "semantic-ui-react";

import Flex from "astrid-components/lib/components/Layout/Flex";

import useVoiceTranslations from "../hooks/useVoiceTranslations";

export default function ReaderInformation({ user }) {
	const { t } = useTranslation();
	const voiceTranslations = useVoiceTranslations();

	const samples = Object.entries(user?.readerData?.samples || {});

	return (
		<>
			{user.readerData.presentation && (
				<>
					<Divider />
					<Header.Subheader>{user.readerData.presentation}</Header.Subheader>
				</>
			)}

			<Divider />

			<Flex style={{ flexDirection: "column", marginTop: 5 }} gap={5} alignItems="flex-start">
				{user.readerData.currency && (
					<Header.Subheader>
						{<Icon name="currency" />}
						{user.readerData.currency || "SEK"} - {user.readerData.cost}/{t("hour", "hour")}
					</Header.Subheader>
				)}

				{user.readerData.voice && (
					<Header.Subheader>
						{<Icon name="microphone" />}
						{voiceTranslations[user.readerData.voice]}
					</Header.Subheader>
				)}

				{user.readerData.genre && (
					<Header.Subheader>
						{<Icon name="book" />}
						{user.readerData.userData?.genre.map((genre) => t(`${genre}`, `${genre}`)).join(", ")}
					</Header.Subheader>
				)}
			</Flex>

			{samples.length > 0 && (
				<>
					<Divider />

					{samples.map(([id, { file }]) => {
						return (
							<Flex key={id} gap={15} style={{ marginTop: 10 }}>
								<audio
									style={{
										width: "100%",
										height: 39,
									}}
									src={file}
									controls
									onPlay={(e) => {
										const allAudio = document.querySelectorAll("audio");

										for (const audio of allAudio) {
											if (audio !== e.target) audio.pause();
										}
									}}
								/>
							</Flex>
						);
					})}
				</>
			)}
		</>
	);
}
