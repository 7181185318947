import api from "../../../../api";
import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import FirestoreDocumentContext from "../../../firebase/components/FirestoreDocumentContext";

import AdminTitleView from "../../views/AdminTitleView/AdminTitleView";

export default function AdminTitle() {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<FirestoreDocumentContext param="title" api={api.titles}>
			<AdminTitleView />
		</FirestoreDocumentContext>
	);
}
