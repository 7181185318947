import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

export default function CreateAgreementTemplateAction() {
	const { t } = useTranslation();

	return (
		<Button as={Link} to="../templates/create">
			{t("createAgreementTemplate", "Create agreement template")}
		</Button>
	);
}
