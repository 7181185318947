import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import { filterDuplicateKeys } from "../../../../ui/utils/filters";

export default function OfferProductionSelect({ offers }) {
	const { t } = useTranslation();

	const productionOptions = offers
		.map((offer) => ({
			key: offer.production.id,
			value: offer.production.id,
			text: offer.production.title,
		}))
		.filter(filterDuplicateKeys);

	return (
		<Form.Select
			search
			name="productions"
			multiple
			options={productionOptions}
			label={t("production", "Production")}
		/>
	);
}
