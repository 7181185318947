import updateDocument from "../../helpers/updateDocument";

import agreementStatuses from "./constants/agreementStatuses";
import createTerminationData from "./createTerminationData";

export default async function terminateAgreement({ agreement, user }) {
	return updateDocument(agreement.ref, {
		termination: createTerminationData(true, user),
		status: agreementStatuses.TERMINATED,
	});
}
