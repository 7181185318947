import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import api from "../../../../api";
import Popup from "../../../ui/components/Popup/Popup";
import useLoadingFunction from "../../../ui/hooks/useLoadingFunction";

export default function ArtifactDefaultArticleArtifactIcon({ article, data, disabled = false }) {
	const { t } = useTranslation();

	const isDefaultArtifact = article?.artifact?.id === data.id;

	const [onClick, loading, error] = useLoadingFunction((e) => {
		e.stopPropagation();

		return api.articles.update({ ref: article.ref, artifact: data });
	});

	return (
		<Popup
			content={
				error
					? t("errorMessageHeader", "Something went wrong")
					: isDefaultArtifact
					? t("defaultArtifact", "Default artifact")
					: t("disabledArtifact", "Disabled artifact")
			}
			trigger={
				<Icon
					color={error ? "red" : isDefaultArtifact ? "green" : undefined}
					name={
						error
							? "warning sign"
							: loading
							? "spinner"
							: isDefaultArtifact
							? "check circle outline"
							: "times circle outline"
					}
					loading={loading}
					link={!disabled && !loading}
					onClick={!disabled && onClick}
				/>
			}
		/>
	);
}
