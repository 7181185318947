import React, { Component } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import { firebase, base } from "astrid-firebase";
import RecordButton from "../RecordButton";

class ProductionWordContribute extends Component {
	state = {
		comment: "",
		example: "",
		saved: false,
		sampleUpload: null,
	};

	storageRef = firebase
		.storage()
		.refFromURL(window.ES.stage ? "gs://stage-earselect-public" : "gs://earselect-public");

	startUpload = (e) => {
		const word = this.props.word;

		const sampleId = +new Date();
		if (this.state.example) {
			// audio file
			const file = this.state.example;
			const filePath = "wordSamples/" + word.id + "/sample-" + sampleId + ".mp3";

			// start upload
			const uploadTask = this.storageRef.child(filePath).put(file);

			// upload status events
			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED,
				(snapshot) => {
					// progress
					const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
					this.setState({ sampleUpload: progress + "%" });
				},
				(error) => {
					// error
					console.log(error);
				},
				() => {
					// success, store in db
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						this.saveContribution(sampleId, downloadURL, filePath);
					});
				},
			);
		} else {
			// no sample, save right away
			this.saveContribution(sampleId);
		}
	};

	deleteWord = (word) => {
		word.productions[this.props.productionId].index = false;

		base.updateDoc("words/" + word.id, {
			productions: word.productions,
		});
	};

	saveContribution = (sampleId, url, path) => {
		const word = this.props.word;
		let contributions = word.contributions || {};

		const data = {
			email: this.props.email,
			time: firebase.firestore.FieldValue.serverTimestamp(),
			production: this.props.productionId,
		};
		if (url) data.file = url;
		if (path) data.filePath = path;
		if (this.props.uid) data.user = this.props.uid;
		if (this.state.comment) data.comment = this.state.comment;

		contributions[sampleId] = data;

		base.updateDoc("words/" + word.id, {
			contributions,
		})
			.then(() => {
				this.setState({ saved: true });

				if (this.props.onSave) this.props.onSave();
			})
			.catch((error) => {
				console.error("Word update", error);
			});
	};

	render() {
		const { word, productionId, validUser, t } = this.props;

		return !this.state.saved ? (
			<Form style={{ clear: "both"}}>
				{word.productions[productionId].context && (
					<p style={{ whiteSpace: "pre" }}>
						<strong>{t("wordContext")}</strong> <br />
						{word.productions[productionId].context}
					</p>
				)}
				<strong>{t("recordNewSoundSample")} </strong>
				<RecordButton
					onChange={(file) => {
						this.setState({ example: file });
					}}
				/>

				<div
					style={{
						marginTop: "1em"
					}}
				>
					<Form.TextArea
						label={t("commentOrInfoAboutWord")}
						value={this.state.comment}
						onChange={(e, data) => {
							this.setState({ comment: data.value });
						}}
						maxLength='120'
					/>
					{this.props.ids.includes(word.id) && 
					<Button 
						style={{position: 'absolute', bottom: 0, right: 0}} 
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (window.confirm(t("wantToDeleteFromWordList")))
								this.deleteWord(word);
						}} icon>
					<Icon name='trash alternate'/>
					</Button>}
					<Form.Button
						content={this.state.sampleUpload ? this.state.sampleUpload : t("save")}
						primary
						disabled={
							!!this.state.sampleUpload || !validUser || !this.state.example
						}
						onClick={this.startUpload}
					/>
				</div>
			</Form>
		) : (
			<div style={{ clear: "both" }}>
				<Form.Button content={t("Saved")} color="green" disabled />
			</div>
		);
	}
}

export default ProductionWordContribute;
