import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { Button, Dropdown, Form, Header, Icon, Label, Modal, Popup, Segment, Table } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { base, db, firebase, hasRight } from "astrid-firebase";
import arrayChunk from "astrid-helpers/src/arrayChunk";
import DocTitle from "astrid-web/src/components/DocTitle";

import BillReceiver from "../components/invoicing/BillReceiver";
import ProductionBilling, { getBillingStatus } from "../components/production/ProductionBilling";
import DateTime from "../features/ui/components/DateInput/DateTime";
import { withStore } from "../helpers/context";
import { durationToSec, toDate } from "../helpers/fnc";

const generatePDF = firebase.functions().httpsCallable("generatePDF");

class Billing extends Component {
	defaultState = {
		producer: Object.keys(this.props.store.state.profile.permissions.producer)[0], // memo should support choosing producer
		publisher: "",
		createdBy: [],
		productionType: [],
		credit: null,
		billDate: new Date(),
		startDate: null,
		endDate: null,
		language: [],
		hiddenProductions: [],
		hideBilled: true,
		hidePayed: false,
		temporarySettings: {},
		extraRows: [],
		showingSentBill: false,
		extractedISBN: null,
		isbnHits: null,
	};

	state = {
		...cloneDeep(this.defaultState),
	};

	resetState = () => {
		this.setState({ ...cloneDeep(this.defaultState) });
	};

	UNSAFE_componentWillMount() {
		this.props.store.getOrganizations();
		this.props.store.getUsers();

		// get billing settings
		base.bindDoc("organizations/" + this.state.producer + "/secrets/billing", {
			context: this,
			state: "billingSettings",
			then() {
				if (!this.state.billingSettings.publisherSettings) {
					// add doc if it doesn't exist
					const billingRef = db.collection(`organizations/${this.state.producer}/secrets`).doc("billing");
					billingRef.set({ publisherSettings: {} }, { merge: true });
				}

				// store so that we can reset after loading sent bill
				this.defaultState.billingSettings = cloneDeep(this.state.billingSettings);
			},
			onFailure(err) {
				console.log(err);
			},
		});

		// authors
		base.bindCollection("people", {
			context: this,
			state: "people",
			withIds: true,
			query: (ref) => ref.where("type", "==", "author"),
			then() {},
			onFailure(err) {
				console.log("people err", err);
			},
		});

		base.bindCollection("organizations/" + this.state.producer + "/secrets/billing/bills", {
			context: this,
			state: "lastBill",
			withIds: true,
			query: (ref) => ref.orderBy("billed", "desc").limit(1),
			then(data) {
				console.log(data);
			},
			onFailure(err) {
				console.log("Bills get err", err);
			},
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const priceIncReader = get(
			this.state,
			"billingSettings.publisherSettings." + this.state.publisher + ".priceIncReader",
		);

		if (priceIncReader) {
			const oldProds = this.getRelevantProductions(prevState);
			const newProds = this.getRelevantProductions(this.state);

			if (JSON.stringify(newProds) !== JSON.stringify(oldProds)) {
				this.setPriceIncReader();
			}
		}
	}

	setPriceIncReader = () => {
		const priceIncReader = get(
			this.state,
			"billingSettings.publisherSettings." + this.state.publisher + ".priceIncReader",
		);
		const prods = this.getRelevantProductions(this.state);

		const newSettings = cloneDeep(this.state.temporarySettings);

		prods
			.filter((prod) => prod.readerContractedByProducer)
			.forEach((prod) => {
				newSettings["price_" + prod.id] = priceIncReader;
				newSettings["note_" + prod.id] = "(inkl. uppläsararvode)";
			});

		this.setState({
			temporarySettings: newSettings,
		});
	};

	handleSettings = (e, data) => {
		this.setState({ [data.name]: data.value });

		if (data.name === "publisher") {
			// get productions
			this.setState({ extraRows: [], temporarySettings: {} });

			// ditch old binding
			if (this.prodBind) {
				base.removeBinding(this.prodBind);
			}
			if (this.billBind) {
				base.removeBinding(this.billBind);
			}

			if (data.value !== "other") {
				this.prodBind = base.bindCollection("productions", {
					context: this,
					state: "productions",
					withIds: true,
					query: (ref) =>
						data.bill
							? // get this way to include productions that have been moved to another publisher when showing a sent bill
							  ref.where(
									"billingStatus.producer.bills",
									"array-contains",
									data.bill.credit || data.bill.id,
							  )
							: // get this way to list all productions belonging to a publisher
							  ref.where("publisher", "==", data.value),
					then() {
						this.setPriceIncReader();
					},
					onFailure(err) {
						console.log("Prod get err", err);
					},
				});
			} else {
				this.setState({ productions: null });
			}

			this.billBind = base.bindCollection("organizations/" + this.state.producer + "/secrets/billing/bills", {
				context: this,
				state: "bills",
				withIds: true,
				query: (ref) => ref.where("publisher", "==", data.value),
				then() {},
				onFailure(err) {
					console.log("Bills get err", err);
				},
			});
		}
	};

	handleProducerSettings = (e, data) => {
		// Semanctic UI components gives data object, otherwise get it from the html element.
		// For Contenteditable, get the name from a data attribute
		const name = (data && data.name) || e.target.name || e.currentTarget.dataset.name;
		const value = (data && data.value) || e.target.value;

		base.updateDoc("organizations/" + this.state.producer + "/secrets/billing", {
			[name]: value,
		});
	};

	handleTemporarySettings = (e, data) => {
		// Semanctic UI components gives data object, otherwise get it from the html element.
		// For Contenteditable, get the name from a data attribute
		const name = (data && data.name) || e.target.name || e.currentTarget.dataset.name;
		const value = (data && data.value) || e.target.value;

		this.setState({
			temporarySettings: {
				...this.state.temporarySettings,
				[name]: value,
			},
			editedTemporarySettings: true,
		});
	};

	setBillReceiver = (billReceiver) => {
		this.handleTemporarySettings(null, {
			name: "billReceiver",
			value: billReceiver,
		});
	};

	saveEditedSettings = () => {
		this.setState({
			generatingBill: true,
		});

		// generate PDF
		const html = document.querySelector("div.current-bill")?.innerHTML;
		generatePDF({ html, billId: this.state.showingSentBill, fileName: this.state.temporarySettings.billNo }).then(
			(result) => {
				base.updateDoc(
					"organizations/" + this.state.producer + "/secrets/billing/bills/" + this.state.showingSentBill,
					{
						temporarySettings: { ...this.state.temporarySettings, pdf: result.data.url },
					},
				).catch((err) => {
					console.log("save pdf error", err);
				});

				this.setState({
					editedTemporarySettings: false,
					generatingBill: false,
				});
			},
		);
	};

	handlePublisherSettings = (e, data) => {
		base.updateDoc("organizations/" + this.state.producer + "/secrets/billing", {
			["publisherSettings." + this.state.publisher + "." + data.name]: data.value,
		});
	};

	getMonthOptions = () => {
		let year = 2018;
		let month = 0;
		let options = [];

		// loop from beginning of Astrid until now
		while (moment(year + "-" + (month + 1) + "-01", "YYYY-M-DD") <= moment()) {
			options.push({
				key: year + "-" + month,
				value: year + "-" + month,
				text: year + " " + moment().month(month).format("MMMM"),
			});

			// add a month
			month++;

			// new year after december
			if (month === 12) {
				year++;
				month = 0;
			}
		}

		// show current month up top
		options.reverse();
		return options;
	};

	getPublisherSettings = (publisher, billingSettings) => {
		return {
			priceBase: 32,
			termsDays: 30,
			readerDiscounts: [],
			readerDiscountSettings: {},
			...((publisher &&
				billingSettings &&
				billingSettings.publisherSettings &&
				billingSettings.publisherSettings[publisher]) ||
				{}),
		};
	};

	getDefaultBillSender = () => {
		const { producer, language } = this.state;
		const { store } = this.props;
		const { organizations } = store.state;

		return language && language[0]
			? organizations[producer]?.publisherBilling?.[language[0]]
			: organizations[producer].billingAddress;
	};

	setAllAsBilled = () => {
		this.setState({ generatingBill: true });

		const {
			producer,
			publisher,
			billDate,
			startDate,
			endDate,
			extraRows,
			hiddenProductions,
			hideBilled,
			hidePayed,
			language,
		} = this.state;

		const timestamp = firebase.firestore.FieldValue.serverTimestamp();

		// duplicate current settings
		const billingSettings = cloneDeep(this.state.billingSettings);
		const temporarySettings = cloneDeep(this.state.temporarySettings);
		const publisherSettings = this.getPublisherSettings(publisher, billingSettings);

		// remove irrelevant publisher settings
		if (publisherSettings)
			billingSettings.publisherSettings = {
				[this.state.publisher]: publisherSettings,
			};
		else delete billingSettings.publisherSettings;

		// get relevant productions
		let { productions: relevantProductions } = this.calculateSums(
			this.getRelevantProductions(),
			extraRows,
			temporarySettings,
			publisherSettings,
		);

		// store durations and billing status permanently
		if (relevantProductions)
			relevantProductions.forEach((p) => {
				if (!temporarySettings["duration_" + p.id] && p.deliveryDuration)
					temporarySettings["duration_" + p.id] = p.deliveryDuration;

				temporarySettings["billingStatus_" + p.id] = getBillingStatus(p).articles;
			});

		// store addresses
		if (!temporarySettings.billSender) temporarySettings.billSender = this.getDefaultBillSender();

		// store bill settings
		const billData = {
			billed: timestamp,
			publisher,
			billDate,
			billingSettings,
			startDate,
			endDate,
			extraRows,
			hiddenProductions,
			hideBilled,
			hidePayed,
			language,
			temporarySettings,
		};

		base.addToCollection("organizations/" + producer + "/secrets/billing/bills", billData).then((ref) => {
			console.log("Created bill:", ref.id);

			// add billing ID to selected productions
			if (relevantProductions) {
				const map = [];
				relevantProductions.forEach((prod) => {
					const articles = prod.billingArticles;
					const billData = {};

					// store bill ID
					billData["billingStatus.producer.bills"] = firebase.firestore.FieldValue.arrayUnion(ref.id);

					// store relecant billing status flags
					if (articles.production && !articles.production.billed)
						billData["billingStatus.producer.billed"] = timestamp;
					if (articles.cloud && !articles.cloud.billed && publisherSettings.cloudStorage)
						billData["billingStatus.producer.billedCloud"] = timestamp;
					if (articles.ebook && !articles.ebook.billed && publisherSettings.ebookStorage)
						billData["billingStatus.producer.billedEbook"] = timestamp;

					map.push([prod.id, billData]);
				});

				// chunk up batch because timestamp and arrayUnion counts as one write
				const collection = db.collection("productions");
				Promise.all(
					arrayChunk(map, 160).map((chunk) => {
						const batch = db.batch();
						console.log("new batch");

						for (const [id, data] of chunk) {
							console.log(id, data);
							batch.update(collection.doc(id), data);
						}

						return batch.commit();
					}),
				).then(() => {
					console.log("Production bill batch is done");
				});
			}

			// generate PDF, show bill, open email modal
			const html = document.querySelector("div.current-bill")?.innerHTML;
			generatePDF({ html, billId: ref.id, fileName: temporarySettings.billNo }).then((result) => {
				ref.update({ "temporarySettings.pdf": result.data.url }).then(() => {
					temporarySettings.pdf = result.data.url;
					this.showBill({ ...billData, id: ref.id });
					this.setState({ generatingBill: false, modal: "publisherEmail" });
				});
			});
		});
	};

	setAsPayed = (prods) => {
		console.log("prods", prods);
		const timestamp = firebase.firestore.FieldValue.serverTimestamp();
		const map = [];

		prods.forEach((prod) => {
			const preBillArticles = prod.billingArticles;
			const currentArticles = getBillingStatus(prod).articles;

			const newData = {};

			["production", "cloud", "ebook"].forEach((article) => {
				if (
					preBillArticles[article] &&
					!preBillArticles[article].billed &&
					currentArticles[article].billed &&
					!currentArticles[article].payed
				) {
					let flag = "payed";
					if (article === "ebook") flag += "Ebook";
					if (article === "cloud") flag += "Cloud";

					newData["billingStatus.producer." + flag] = timestamp;
				}
			});

			map.push([prod.id, newData]);
		});

		const collection = db.collection("productions");
		Promise.all(
			arrayChunk(map, 120).map((chunk) => {
				const batch = db.batch();
				console.log("new payed batch");

				for (const [id, data] of chunk) {
					console.log(id, data);
					batch.update(collection.doc(id), data);
				}

				return batch.commit();
			}),
		).then(() => {
			console.log("Payed bill batch is done");
		});
	};

	getRelevantProductions = (state) => {
		const {
			productions,
			startDate,
			createdBy,
			credit,
			productionType,
			endDate,
			language,
			hiddenProductions,
			hideBilled,
			hidePayed,
			showingSentBill,
			temporarySettings,
		} = state || this.state;

		return (
			productions &&
			productions
				.filter((prod) => {
					// this needs to be here to load OLD bills
					const legacyCreatedByImport = (prod.batch && prod.batch.imported) || prod.createdBy === "import";
					const { total, billed, payed } = !showingSentBill ? getBillingStatus(prod) : {};

					return showingSentBill
						? get(prod, "billingStatus.producer.bills", []).includes(credit || showingSentBill)
						: prod.status === "done" &&
								// !prod.finance?.earnings && // ignore productions with new finance setup
								(!createdBy.length ||
									(createdBy.includes("astrid") && !legacyCreatedByImport) ||
									(createdBy.includes("import") && legacyCreatedByImport)) &&
								(!productionType.length ||
									(productionType.includes("internal") && !prod.productionType) ||
									(productionType.includes("external") && prod.productionType === "external") ||
									(productionType.includes("backlist") && prod.productionType === "backlist")) &&
								(!startDate ||
									toDate(prod.deliveryDateActual || prod.deliveryDate || prod.created) >=
										startDate) &&
								(!endDate ||
									toDate(prod.deliveryDateActual || prod.deliveryDate || prod.created) <= endDate) &&
								!hiddenProductions.includes(prod.id) &&
								(!hideBilled || (total && total > billed)) &&
								(!hidePayed || (total && total > payed)) &&
								(!language.length || language.includes(prod.language));
				})
				.map((prod) => ({
					...prod,
					billingArticles: !showingSentBill
						? // live status
						  getBillingStatus(prod).articles
						: // load permanent data from bill
						  temporarySettings["billingStatus_" + prod.id] ||
						  // trick old bills into looking at current status... but all articles as unbilled, to be included in calculation
						  Object.entries(getBillingStatus(prod).articles).reduce((prev, [key, obj]) => {
								prev[key] = { ...obj, billed: false };
								return prev;
						  }, {}),
				}))
		);
	};

	calculateSums = (prods, extraRows, temporarySettings, publisherSettings) => {
		let total = 0;
		let totalProductionCost = 0;
		let totalDuration = 0;

		if (!temporarySettings) temporarySettings = {};
		const productions =
			prods &&
			!temporarySettings.linkedProductions &&
			prods
				.map((prod) => {
					const articles = prod.billingArticles || {};

					let discount =
						temporarySettings["percent_" + prod.id] !== undefined ||
						temporarySettings["minus_" + prod.id] !== undefined
							? temporarySettings["percent_" + prod.id]
								? { unit: "percent", amount: temporarySettings["percent_" + prod.id] }
								: { unit: "minus", amount: temporarySettings["minus_" + prod.id] }
							: prod.reader &&
							  prod.reader.reduce(
									(prev, curr) =>
										publisherSettings.readerDiscounts &&
										publisherSettings.readerDiscounts.includes(curr) &&
										publisherSettings.readerDiscountSettings[curr] > prev.amount
											? {
													unit:
														(publisherSettings.readerDiscountUnit &&
															publisherSettings.readerDiscountUnit[curr]) ||
														"percent",
													amount: publisherSettings.readerDiscountSettings[curr],
											  }
											: prev,
									{ unit: "percent", amount: 0 },
							  );

					// fallback non-discount discount
					if (!discount)
						discount = {
							unit: "percent",
							amount: 0,
						};

					let duration = temporarySettings["duration_" + prod.id] || prod.deliveryDuration || "";
					let minutePrice = articles.production
						? temporarySettings["price_" + prod.id] || publisherSettings.priceBase
						: 0; // external and backlist are "free"

					let sum =
						duration && minutePrice && articles.production && !articles.production.billed
							? Math.round(
									Math.ceil(durationToSec(duration) / 60) *
										(minutePrice - (discount.unit === "minus" ? discount.amount : 0)) *
										(1 - (discount.unit === "percent" ? discount.amount : 0) / 100),
							  )
							: 0;

					totalProductionCost += sum;

					if (publisherSettings.cloudStorage && articles.cloud && !articles.cloud.billed) {
						// charge audio cloud storage
						sum = sum + Number(publisherSettings.cloudStorage);

						// double charge ebook storage?
						if (
							publisherSettings.cloudChargeAllArticles &&
							publisherSettings.ebookStorage &&
							articles.ebook &&
							!articles.ebook.billed
						) {
							sum = sum + Number(publisherSettings.ebookStorage);
						}
					} else if (publisherSettings.ebookStorage && articles.ebook && !articles.ebook.billed) {
						// only ebook
						sum = sum + Number(publisherSettings.ebookStorage);
					}

					total = total + sum;

					if (duration) totalDuration += Math.ceil(durationToSec(duration) / 60);

					return {
						...prod,
						sum,
						discount: discount.amount,
						discountUnit: discount.unit,
					};
				})
				// hide 0 sum rows
				.filter((p) => p.sum);

		// add extra rows to total
		if (extraRows)
			extraRows.forEach((row) => {
				total = total + parseInt(row.sum || 0, 10);
			});

		return {
			total,
			totalProductionCost,
			totalDuration,
			productions,
		};
	};

	showBill = (bill) => {
		const settings = bill;

		settings.startDate = toDate(settings.startDate);
		settings.endDate = toDate(settings.endDate);
		settings.billDate = toDate(settings.billDate);

		const state = {
			...this.state,
			...settings,
			credit: bill.credit,
			showingSentBill: bill.id,
			editedTemporarySettings: false,
		};

		if (bill?.temporarySettings?.linkedProductions) {
			state.searchingISBN = true;

			db.collection("productions")
				.where("billingStatus.producer.bills", "array-contains", bill.id)
				.get()
				.then((docs) => {
					const ids = {};

					docs.forEach((doc) => {
						ids[doc.id] = doc.data();
					});
					this.setState({
						searchingISBN: false,
						isbnHits: ids,
					});
				});
		}

		this.setState(state);
	};

	getPDFbills = () => {
		const { startDate, endDate } = this.state;

		if (startDate || endDate)
			base.get("organizations/" + this.state.producer + "/secrets/billing/bills", {
				withIds: true,
				query: (ref) => {
					if (startDate) ref = ref.where("billed", ">=", toDate(startDate));
					if (endDate) ref = ref.where("billed", "<=", toDate(endDate));

					return ref;
				},
			}).then((data) => {
				const pdfBills = data.filter((bill) => bill?.temporarySettings?.pdf);

				this.setState({
					pdfBills,
				});
			});
	};

	addRow = () => {
		this.setState({ extraRows: [...this.state.extraRows, {}] });
	};

	print = () => {
		window.document.title = "Faktura " + get(this, "state.temporarySettings.billNo");
		window.print();
	};

	createCredit = () => {
		const bill = this.state.bills.find((bill) => bill.id === this.state.showingSentBill);
		const newBill = cloneDeep(bill);

		// switch some data
		delete newBill.id;
		newBill.credit = bill.id;
		newBill.temporarySettings.billNo = bill.temporarySettings.billNo + "-kredit";
		newBill.temporarySettings.creditBillNo = bill.temporarySettings.billNo;
		newBill.billDate = firebase.firestore.FieldValue.serverTimestamp();
		newBill.billed = newBill.billDate;

		if (!newBill.startDate) delete newBill.startDate;
		if (!newBill.endDate) delete newBill.endDate;

		base.addToCollection("organizations/" + this.state.producer + "/secrets/billing/bills", newBill).then((ref) => {
			console.log("Created bill:", ref.id);

			// update old bill and productions
			base.updateDoc("organizations/" + this.state.producer + "/secrets/billing/bills/" + bill.id, {
				creditedBy: ref.id,
			});

			this.showBill({ ...newBill, id: ref.id });
			this.saveEditedSettings();
		});
	};

	extractISBN = (e) => {
		if (!e.target.value) return;
		const extractedISBN = e.target.value.match(/\b(\d\s*?){13}\b/gm);

		this.setState({ extractedISBN });
	};

	searchISBN = () => {
		const isbn = this.state.extractedISBN;
		this.setState({ searchingISBN: true });

		const getAudio = arrayChunk(isbn, 10).map((chunk) =>
			db.collection("productions").where("isbn", "in", chunk).get(),
		);

		const getEbooks = arrayChunk(isbn, 10).map((chunk) =>
			db.collection("productions").where("deliveryEbook.isbn", "in", chunk).get(),
		);

		Promise.all([...getAudio, ...getEbooks])
			.then((arr) => {
				console.log("Production bill batch is done", arr);

				const ids = {};

				arr.forEach((b) => {
					b.docs.forEach((doc) => {
						const prod = doc.data();

						if (isbn.includes(prod.isbn) || isbn.includes(prod.deliveryEbook?.isbn)) ids[doc.id] = prod;
					});
				});

				// search delivery parts separately, for some reason the query fails silently if added to the Promise.all batch
				db.collection("productions")
					.where("deliveryParts", "!=", "")
					.get()
					.then((docs) => {
						docs.forEach((doc) => {
							const prod = doc.data();

							if (prod.deliveryParts?.find((p) => isbn.includes(p.isbn))) ids[doc.id] = prod;
						});

						this.setState({ searchingISBN: false, isbnHits: ids, extractISBN: "" });
					});
			})
			.catch((err) => {
				console.log("searchISBN -> err", err);
			});
	};

	linkISBNtoBill = () => {
		const { isbnHits, showingSentBill, temporarySettings } = this.state;

		const map = [];
		Object.keys(isbnHits).forEach((prod) => {
			const billData = {
				billingStatus: {
					producer: {
						bills: firebase.firestore.FieldValue.arrayUnion(showingSentBill),
						billed: firebase.firestore.FieldValue.serverTimestamp(),
					},
				},
			};

			map.push([prod, billData]);
		});

		const collection = db.collection("productions");
		Promise.all(
			arrayChunk(map, 160).map((chunk) => {
				const batch = db.batch();
				console.log("new batch");

				for (const [id, data] of chunk) {
					batch.set(collection.doc(id), data, { merge: true });
				}

				return batch.commit();
			}),
		).then(() => {
			this.setState({
				temporarySettings: { ...temporarySettings, linkedProductions: true },
			});

			base.updateDoc("organizations/" + this.state.producer + "/secrets/billing/bills/" + showingSentBill, {
				"temporarySettings.linkedProductions": true,
			})
				.then(() => {
					console.log("great");
				})
				.catch((err) => console.log);

			console.log("Production bill batch is done");
		});
	};

	setLinkedAsPayed = () => {
		const { isbnHits, showingSentBill, temporarySettings } = this.state;
		const map = [];
		Object.keys(isbnHits).forEach((prod) => {
			const billData = {
				billingStatus: {
					producer: {
						payed: firebase.firestore.FieldValue.serverTimestamp(),
					},
				},
			};

			map.push([prod, billData]);
		});

		const collection = db.collection("productions");
		Promise.all(
			arrayChunk(map, 160).map((chunk) => {
				const batch = db.batch();

				for (const [id, data] of chunk) {
					batch.set(collection.doc(id), data, { merge: true });
				}

				return batch.commit();
			}),
		).then(() => {
			this.setState({
				temporarySettings: { ...temporarySettings, linkedProductionsPayed: true },
			});
			base.updateDoc("organizations/" + this.state.producer + "/secrets/billing/bills/" + showingSentBill, {
				"temporarySettings.linkedProductionsPayed": true,
			})
				.then(() => {
					console.log("great");
				})
				.catch((err) => console.log);

			console.log("Production bill batch is done");
		});
	};

	render() {
		const {
			bills,
			producer,
			productions,
			publisher,
			credit,
			people,
			productionType,
			billDate,
			startDate,
			endDate,
			language,
			billingSettings,
			hiddenProductions,
			hideBilled,
			hidePayed,
			temporarySettings,
			editedTemporarySettings,
			extraRows,
			showingSentBill,
			lastBill,
			extractedISBN,
			searchingISBN,
			isbnHits,
			generatingBill,
			modal,
		} = this.state;
		const { store } = this.props;
		const { profile, organizations, users } = store.state;

		// get publisher billing settings
		const publisherSettings = this.getPublisherSettings(publisher, billingSettings);

		// get relevant productions and calculate sum and discount
		let { total, /*totalDuration, totalProductionCost,*/ productions: relevantProductions } = this.calculateSums(
			this.getRelevantProductions(),
			extraRows,
			temporarySettings,
			publisherSettings,
		);

		// did any production receive a discount?
		const hasDiscount = relevantProductions && relevantProductions.find((prod) => prod.discount);

		let billedAt =
			productions &&
			groupBy(
				productions.filter((prod) => prod.status === "done" && get(prod, "billingStatus.producer.billed")),
				(prod) => moment(toDate(prod.billingStatus.producer.billed)).format("YYYY-MM-DD HH:mm"),
			);

		if (publisher === "other" && bills) {
			if (!billedAt) billedAt = {};
			bills.forEach((bill) => {
				billedAt[moment(toDate(bill.billed)).format("YYYY-MM-DD HH:mm")] = [];
			});
		}

		const lastBillNo = lastBill && get(lastBill, "[0].temporarySettings.billNo");

		const missingHits =
			isbnHits &&
			extractedISBN &&
			Object.keys(isbnHits).length < extractedISBN.length &&
			extractedISBN.filter(
				(isbn) =>
					!Object.values(isbnHits).find(
						(prod) =>
							prod.isbn === isbn ||
							prod?.deliveryEbook?.isbn === isbn ||
							prod.deliveryParts?.find((p) => isbn === p.isbn),
					),
			);

		return (
			organizations &&
			hasRight(store, "producerBilling.createPublisherInvoice") && (
				<div>
					<DocTitle title="Fakturering" />

					{showingSentBill ? (
						<Segment className="no-print">
							<b>Visar skickad faktura</b>
							<Form as="div">
								<Form.Group>
									<Form.Button
										content="Återgå till fakturaverktyget"
										onClick={() => {
											this.setState({ showingSentBill: false });
											this.resetState();
										}}
									/>

									{temporarySettings.pdf ? (
										<Button.Group color="blue">
											<Button
												className="no-print"
												content="Ladda ner PDF"
												href={temporarySettings.pdf}
											/>
											<Button
												className="no-print"
												style={{ borderLeft: "1px solid #93bcdc" }}
												content="Skicka PDF"
												onClick={() => {
													this.setState({ modal: "publisherEmail" });
												}}
											/>
										</Button.Group>
									) : (
										<Form.Button
											primary
											content="Skriv ut/spara som pdf"
											icon="print"
											onClick={this.print}
										/>
									)}

									{!credit && (
										<Form.Button
											color="orange"
											content="Skapa kreditfaktura"
											icon="retweet"
											onClick={this.createCredit}
										/>
									)}
									{editedTemporarySettings && (
										<Form.Button
											color="green"
											content="Spara redigeringar"
											icon="save"
											onClick={this.saveEditedSettings}
											loading={generatingBill}
										/>
									)}

									<Form.Button
										color="teal"
										content="Markera som betald"
										icon="check"
										onClick={() => {
											if (window.confirm("Är du säkert?")) {
												this.setAsPayed(relevantProductions);
											}
										}}
									/>
								</Form.Group>
							</Form>
						</Segment>
					) : (
						<>
							<Segment className="billing-settings">
								<Form as="div">
									<Form.Group>
										<Form.Select
											label="Produktionsbolag"
											value={producer}
											options={Object.keys(profile.permissions.producer).map((prod) => ({
												key: prod,
												value: prod,
												text: organizations && organizations[prod] && organizations[prod].name,
											}))}
											name="producer"
											onChange={this.handleSettings}
										/>
										<Form.Select
											label="Förlag"
											value={publisher}
											search
											options={[
												{ key: "other", value: "other", text: "Ej förlag / övrigt" },
												...organizations[producer].publishers
													.filter((publisher) => organizations[publisher])
													.map((publisher) => {
														const { id, name } = store.state.organizations[publisher];
														return {
															key: id,
															value: id,
															text: name,
														};
													})
													.sort((a, b) =>
														a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1,
													),
											]}
											name="publisher"
											onChange={this.handleSettings}
										/>
										{publisher !== "other" && (
											<>
												<Form.Select
													multiple
													label="Typ av produktion"
													value={productionType || []}
													options={[
														{ key: "internal", text: "Intern" },
														{ key: "backlist", text: "Backlist / e-bok" },
														{ key: "external", text: "Extern" },
													].map(({ key, text }) => ({
														key,
														text,
														value: key,
													}))}
													name="productionType"
													onChange={this.handleSettings}
												/>
												<div className="field">
													<label>
														Startdatum (
														<Dropdown
															inline
															scrolling
															text="el. välj månad"
															options={this.getMonthOptions()}
															onChange={(e, data) => {
																if (data.value) {
																	const [year, month] = data.value.split("-");
																	this.setState({
																		startDate: moment()
																			.year(year)
																			.month(month)
																			.startOf("month")
																			.toDate(),
																		endDate: moment()
																			.year(year)
																			.month(month)
																			.endOf("month")
																			.toDate(),
																	});
																}
															}}
														/>
														)
													</label>
													<DateTime
														value={startDate}
														timeFormat={false}
														onChange={(dt) => {
															if (typeof dt === "object") {
																this.setState({ startDate: dt });
															} else if (!dt) {
																this.setState({ startDate: null });
															}
														}}
														onBlur={(dt) => {
															const writtenDate = moment(dt).toDate();
															this.setState({ startDate: dt ? writtenDate : null });
														}}
													/>
												</div>
												<div className="field">
													<label>Slutdatum</label>
													<DateTime
														value={endDate}
														timeFormat={false}
														onChange={(dt) => {
															if (typeof dt === "object") {
																this.setState({ endDate: dt });
															}
														}}
														onBlur={(dt) => {
															const writtenDate = moment(dt).toDate();
															this.setState({ endDate: dt ? writtenDate : null });
														}}
													/>
												</div>
												<Form.Select
													search
													deburr
													fluid
													label={"Språk"}
													multiple
													name="language"
													onChange={this.handleSettings}
													value={language}
													options={[
														{
															key: "fake",
															text: "(Filtrera ej)",
															value: null,
														},
														...Object.keys(languages).map((langCode) => {
															const lang = languages[langCode];

															return {
																key: langCode,
																value: langCode,
																text:
																	(lang.sv || lang.name) +
																	" (" +
																	lang.nativeName +
																	")",
															};
														}),
													]}
												/>
											</>
										)}
									</Form.Group>
									<Form.Group>
										<Form.Button
											color="green"
											content="Markera alla som fakturerade och skapa PDF"
											icon="check"
											onClick={this.setAllAsBilled}
											loading={generatingBill}
										/>

										<Form.Button
											primary
											content="Skicka skapade PDF-fakturor till redovisning"
											onClick={() => {
												this.setState({ modal: "accountingEmail" });
												this.getPDFbills();
											}}
										/>
									</Form.Group>
								</Form>
							</Segment>
						</>
					)}

					{publisher ? (
						<>
							{!showingSentBill && publisher !== "other" && (
								<Segment className="no-print">
									<Header
										as="h4"
										icon="clipboard list"
										content={"Prislista för " + organizations[publisher].name}
									/>
									<Form as="div" className="flex-stack">
										<div style={{ width: "48%" }}>
											<Form.Group widths="equal">
												<Form.Input
													label="Betalningsvillkor"
													type="number"
													value={publisherSettings.termsDays}
													name="termsDays"
													onChange={this.handlePublisherSettings}
													labelPosition="right"
												>
													<input />
													<Label>dagar</Label>
												</Form.Input>
												<div className="field"></div>
											</Form.Group>
											<Form.Group widths="equal">
												<Form.Input
													label="Grundpris"
													type="number"
													name="priceBase"
													value={publisherSettings.priceBase}
													onChange={this.handlePublisherSettings}
													labelPosition="right"
												>
													<input />
													<Label>SEK</Label>
												</Form.Input>
												<Form.Input
													label="Inkl. uppläsararvode"
													type="number"
													name="priceIncReader"
													value={publisherSettings.priceIncReader || ""}
													onChange={this.handlePublisherSettings}
													labelPosition="right"
												>
													<input />
													<Label>SEK</Label>
												</Form.Input>
											</Form.Group>
											<Form.Group widths="equal">
												<Form.Input
													label="Molnlagring"
													type="number"
													name="cloudStorage"
													value={publisherSettings.cloudStorage || ""}
													onChange={this.handlePublisherSettings}
													labelPosition="right"
												>
													<input />
													<Label>SEK</Label>
												</Form.Input>
												<Form.Input
													label="Molnlagring, endast e-bok"
													type="number"
													name="ebookStorage"
													value={publisherSettings.ebookStorage || ""}
													onChange={this.handlePublisherSettings}
													labelPosition="right"
												>
													<input />
													<Label>SEK</Label>
												</Form.Input>
											</Form.Group>
											{publisherSettings.cloudStorage && publisherSettings.ebookStorage && (
												<Form.Group>
													<Form.Checkbox
														label={
															"Debitera endast " +
															publisherSettings.cloudStorage +
															" SEK om både ljudbok och e-bok finns"
														}
														checked={!publisherSettings.cloudChargeAllArticles}
														onChange={(e, data) => {
															this.handlePublisherSettings(e, {
																name: "cloudChargeAllArticles",
																value: !data.checked,
															});
														}}
													/>
												</Form.Group>
											)}

											{publisherSettings.priceType &&
												Object.keys(publisherSettings.priceType).map((price, i) => (
													<Form.Input
														key={i}
														label={
															<span>
																{price}{" "}
																<Icon
																	name="trash alternate"
																	style={{ cursor: "pointer" }}
																	onClick={(e) => {
																		this.handlePublisherSettings(e, {
																			name: "priceType." + price,
																			value: firebase.firestore.FieldValue.delete(),
																		});
																	}}
																/>
															</span>
														}
														type="number"
														name="priceBase"
														defaultValue={publisherSettings.priceType[price]}
														onChange={(e, data) => {
															this.handlePublisherSettings(e, {
																name: "priceType." + price,
																value: data.value,
															});
														}}
														labelPosition="right"
													>
														<input />
														<Label>SEK</Label>
													</Form.Input>
												))}
											<Form.Group widths="equal">
												<Form.Input
													label="Lägg till nytt pris"
													placeholder="Ex: Endast korr, backlist o.s.v"
													labelPosition="right"
													id="newPriceName"
												>
													<input />
													<Form.Button
														primary
														icon="plus"
														content="Lägg till"
														onClick={(e) => {
															const name = document.querySelector("#newPriceName").value;

															if (name) {
																const data = {
																	name: "priceType." + name,
																	value: publisherSettings.priceBase || 0,
																};
																this.handlePublisherSettings(e, data);
															}
														}}
													/>
												</Form.Input>
											</Form.Group>
										</div>
										<div className="billing-readers">
											<Form.Select
												multiple
												search
												label="Uppläsarrabatt"
												name="readerDiscounts"
												value={publisherSettings.readerDiscounts}
												options={Object.values(users || {})
													.filter((user) => user.permissions && user.permissions.reader)
													.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
													.map((user) => ({
														text: user.firstName + " " + user.lastName,
														key: user.id,
														value: user.id,
													}))}
												onChange={this.handlePublisherSettings}
											/>
											<br />
											{publisherSettings.readerDiscounts.map((reader) => (
												<Form.Input
													key={reader}
													labelPosition="right"
													type="number"
													value={publisherSettings.readerDiscountSettings[reader] || 0}
													onChange={(e, data) => {
														data.name = "readerDiscountSettings." + reader;
														this.handlePublisherSettings(e, data);
													}}
												>
													<Label>
														{users[reader].firstName + " " + users[reader].lastName}
													</Label>
													<input />
													<Dropdown
														basic
														className="label"
														options={[
															{ key: "percent", value: "percent", text: "%" },
															{ key: "minus", value: "minus", text: "SEK" },
														]}
														value={
															(publisherSettings.readerDiscountUnit &&
																publisherSettings.readerDiscountUnit[reader]) ||
															"percent"
														}
														onChange={(e, data) => {
															data.name = "readerDiscountUnit." + reader;
															this.handlePublisherSettings(e, data);
														}}
													/>
												</Form.Input>
											))}
										</div>
									</Form>
								</Segment>
							)}
							<div className={"current-bill" + (showingSentBill ? " sent-bill" : "")}>
								<style>
									{`
						.billing-readers {
							width: 50%;
						}
						.billing-readers .field {
							display: inline-block;
							margin-right: 1em!important;
						}
						.billing-readers .field input {
							max-width: 70px;
						}

						.current-bill {
							background: white;
							padding: 2em;
						}
						.current-bill [contenteditable] {
							display: inline-block;
							min-width: 15px;
							background: #fffed9;
							white-space: pre-wrap;
						}
						.current-bill [contenteditable].permanent {
							background: #d9ffde;
						}
						.bill-date {
							margin-top: 0;
							text-align: right;
						}
						.billdate-picker {
							display: inline-block;
						}
						.billdate-picker .rdtPicker {
							right: 0;
							font-size: 14px;
						}
						.bill-title, .bill-date {
							width: 30%;
						}

						.current-bill .ui.table thead th {
							background: none !important;
						}
						.current-bill .ui.table tr {
							vertical-align: middle;
						}
						.current-bill .ui.table a {
							color: inherit;
						}
						.bill-top {
							margin-bottom: 1em;
						}
						.bill-header {
							clear: both;
							margin-bottom: 3em;
							padding-bottom: 3em;
							border-bottom: 1px solid;
						}
						.bill-production-discount:not(:empty):after {
							content: '%';
						}
						.bill-production-discount.minus:not(:empty):after {
							content: ' SEK/min';
						}
						.bill-sum {
							border: none;
						}
						.bill-sum td {
							border: none !important;
						}
						.bill-footer {
							margin-top: 3em;
							padding-top: 3em;
							border-top: 1px solid;
							text-align: center
						}
						.current-bill .icon {
							cursor: pointer;
						}

						.sent-bill .no-print {
							display: none;
						}
						.sent-bill [contenteditable] {
							background: transparent !important;
						}

						@media print {
							html, body {
								background: white !important;
								margin: 0;
								padding: 0;
							}
							.site-header, .site-footer, .billing-settings, .no-print, .stage-server:before {
								display: none !important;
							}
							.current-bill {
								padding: 0;
								font-size: 0.7rem;
							}
							.current-bill [contenteditable] {
								background: none;
							}

						}
						@page{
							margin: 1cm 2cm;
						}
					`}
								</style>

								{!showingSentBill && lastBillNo && (
									<small className="no-print">(Senast skickade: {lastBillNo})</small>
								)}

								<div className="flex-stack bill-top">
									<h1 className="bill-title">
										{credit ? "Kreditfaktura" : "Faktura"}{" "}
										<ContentEditable
											// disabled={!!showingSentBill && !credit}
											data-name="billNo"
											onChange={this.handleTemporarySettings}
											html={temporarySettings.billNo || "#00"}
										/>
										{credit && (
											<span style={{ fontSize: "0.6em", display: "block" }}>
												Krediterar faktura {temporarySettings.creditBillNo}
											</span>
										)}
									</h1>

									{organizations[producer].logo && (
										<img
											src={organizations[producer].logo}
											style={{ alignSelf: "baseline", width: 120 }}
											alt=""
										/>
									)}
									<div className="bill-date">
										<h3 style={{ display: "inline-block" }}>
											<DateTime
												value={billDate}
												timeFormat={false}
												closeOnSelect={true}
												className="billdate-picker no-print"
												onChange={(dt) => {
													if (typeof dt === "object") {
														this.setState({
															billDate: new Date(dt.format("YYYY-MM-DD")),
														});
													}
												}}
												renderInput={(props, openCalendar, closeCalendar) => (
													<span style={{ display: "inline-block" }}>
														<Icon
															name="pencil"
															size="small"
															onClick={openCalendar}
															className="no-print"
														/>
													</span>
												)}
											/>
											{moment(billDate).format("LL")}{" "}
										</h3>
										<div>
											<span style={{ display: "inline-block" }}>
												<Popup
													on="click"
													hoverable
													content={
														<Form as="div">
															<Form.Input
																label="Betalningsvillkor"
																type="number"
																value={publisherSettings.termsDays}
																name="termsDays"
																onChange={this.handlePublisherSettings}
																labelPosition="right"
															>
																<input />
																<Label>dagar</Label>
															</Form.Input>
														</Form>
													}
													trigger={<Icon name="pencil" size="small" className="no-print" />}
												/>
											</span>
											Betalningsvillkor: {publisherSettings.termsDays} dagar{" "}
										</div>
										<div>
											Förfallodatum:{" "}
											{moment(billDate).add(publisherSettings.termsDays, "days").format("LL")}
										</div>
									</div>
								</div>
								<div className="flex-stack bill-header">
									<div>
										<h3>Mottagare:</h3>
										<BillReceiver
											publisher={publisher}
											setBillReceiver={this.setBillReceiver}
											billReceiver={temporarySettings.billReceiver}
											onChange={this.handleTemporarySettings}
										/>
									</div>
									<div>
										<h3>Avsändare:</h3>
										<ContentEditable
											className="bill-sender"
											data-name="billSender"
											onChange={this.handleTemporarySettings}
											html={
												temporarySettings.billSender ||
												this.getDefaultBillSender() ||
												organizations[producer].name + "<br><br>Redigerbart fält"
											}
										/>
									</div>
								</div>

								{publisher !== "other" && (
									<div className="no-print flex-stack">
										<Form style={{ whiteSpace: "nowrap" }}>
											<Form.Group widths="equal">
												<Form.Checkbox
													inline
													label="Dölj fakturerade produktioner"
													checked={!!hideBilled}
													onChange={(e, data) => {
														this.setState({
															hideBilled: !hideBilled,
														});
													}}
												/>
												<Form.Checkbox
													inline
													label="Dölj betalda produktioner"
													checked={!!hidePayed}
													onChange={(e, data) => {
														this.setState({
															hidePayed: !hidePayed,
														});
													}}
												/>
											</Form.Group>
										</Form>
										{!!hiddenProductions.length && (
											<Form.Button
												size="small"
												floated="right"
												content="Återställ dolda produktioner"
												onClick={(e) => {
													this.setState({
														hiddenProductions: [],
													});
												}}
											/>
										)}
									</div>
								)}

								<Table basic="very">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>
												{publisher !== "other" ? "Produktion" : "Specifikation"}
											</Table.HeaderCell>
											<Table.HeaderCell>
												{publisher !== "other" ? "Författare" : ""}
											</Table.HeaderCell>
											<Table.HeaderCell>
												{publisher !== "other" ? "Speltid" : "Antal"}
											</Table.HeaderCell>
											<Table.HeaderCell>
												{publisher !== "other" ? "Minutpris" : "Pris"}
											</Table.HeaderCell>
											<Table.HeaderCell>
												<span className={hasDiscount ? "" : "no-print"}>Rabatt</span>
											</Table.HeaderCell>
											{(publisherSettings.cloudStorage || publisherSettings.ebookStorage) &&
												publisher !== "other" && (
													<Table.HeaderCell>Molnlagring</Table.HeaderCell>
												)}
											<Table.HeaderCell>Summa</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{relevantProductions &&
											relevantProductions
												.sort((a, b) => (a.title > b.title ? 1 : -1))
												.map((prod) => (
													<Table.Row key={prod.id}>
														<Table.Cell>
															<a
																href={"/production/" + prod.id}
																target="_blank"
																rel="noopener noreferrer"
															>
																{prod.title}
															</a>
															<br />
															<small>
																{(prod.isbn || prod.deliveryParts) && (
																	<>
																		<Icon name="headphones" />
																		{prod.isbn}
																		{prod.isbn && prod.deliveryParts && ", "}
																		{prod?.deliveryParts
																			?.filter((p) => p.isbn)
																			.map((p) => p.isbn)
																			.join(", ")}
																		&nbsp;
																	</>
																)}
																{prod?.deliveryEbook?.isbn && (
																	<>
																		<Icon name="book" />
																		{prod?.deliveryEbook?.isbn}
																	</>
																)}
															</small>
														</Table.Cell>
														<Table.Cell>
															{prod.author &&
																prod.author.length &&
																prod.author.reduce((prev, curr, i) => {
																	const appendix =
																		i === prod.author.length - 1
																			? ""
																			: i === prod.author.length - 2
																			? " och "
																			: ", ";

																	const author = people.find(
																		(author) => author.id === curr,
																	);

																	const name = author
																		? author.firstName + " " + author.lastName
																		: "";

																	return prev + name + appendix;
																}, "")}
														</Table.Cell>
														<Table.Cell>
															<ContentEditable
																disabled={!!showingSentBill}
																html={
																	temporarySettings["duration_" + prod.id] ||
																	prod.deliveryDuration ||
																	""
																}
																onChange={(e) => {
																	this.setState({
																		temporarySettings: {
																			...temporarySettings,
																			["duration_" + prod.id]: e.target.value,
																		},
																	});
																}}
															/>
														</Table.Cell>
														<Table.Cell>
															{!prod.productionType &&
															get(prod, "billingArticles.production") &&
															!get(prod, "billingArticles.production.billed")
																? (temporarySettings["duration_" + prod.id] ||
																		prod.deliveryDuration) && (
																		<>
																			<ContentEditable
																				disabled={!!showingSentBill}
																				html={
																					(temporarySettings[
																						"price_" + prod.id
																					] ||
																						publisherSettings.priceBase ||
																						"") + "" // neccesary to avoid ContentEditable error message about number as html attr
																				}
																				onChange={(e) => {
																					this.setState({
																						temporarySettings: {
																							...temporarySettings,
																							["price_" + prod.id]:
																								e.target.value,
																						},
																					});
																				}}
																			/>{" "}
																			SEK{" "}
																			<ContentEditable
																				disabled={!!showingSentBill}
																				html={
																					temporarySettings[
																						"note_" + prod.id
																					] || ""
																				}
																				onChange={(e) => {
																					this.setState({
																						temporarySettings: {
																							...temporarySettings,
																							["note_" + prod.id]:
																								e.target.value,
																						},
																					});
																				}}
																			/>
																			{publisherSettings.priceType && (
																				<span className="no-print">
																					<Dropdown>
																						<Dropdown.Menu>
																							<Dropdown.Item
																								text={"Grundpris"}
																								description={
																									publisherSettings.priceBase +
																									" SEK"
																								}
																								onClick={(e) => {
																									this.setState({
																										temporarySettings:
																											{
																												...temporarySettings,
																												["price_" +
																												prod.id]:
																													publisherSettings.priceBase,
																												["note_" +
																												prod.id]:
																													"",
																											},
																									});
																								}}
																							/>
																							{publisherSettings.priceIncReader && (
																								<Dropdown.Item
																									text={
																										"Inkl. uppläsararvode"
																									}
																									description={
																										publisherSettings.priceIncReader +
																										" SEK"
																									}
																									onClick={(e) => {
																										this.setState({
																											temporarySettings:
																												{
																													...temporarySettings,
																													["price_" +
																													prod.id]:
																														publisherSettings.priceIncReader,
																													["note_" +
																													prod.id]:
																														"(inkl. uppläsararvode)",
																												},
																										});
																									}}
																								/>
																							)}
																							{Object.keys(
																								publisherSettings.priceType,
																							).map((price, i) => (
																								<Dropdown.Item
																									key={price}
																									text={price}
																									description={
																										publisherSettings
																											.priceType[
																											price
																										] + " SEK"
																									}
																									onClick={(e) => {
																										this.setState({
																											temporarySettings:
																												{
																													...temporarySettings,
																													["price_" +
																													prod.id]:
																														publisherSettings
																															.priceType[
																															price
																														],
																													["note_" +
																													prod.id]:
																														"(" +
																														price +
																														")",
																												},
																										});
																									}}
																								/>
																							))}
																						</Dropdown.Menu>
																					</Dropdown>
																				</span>
																			)}
																		</>
																  )
																: prod.productionType === "external"
																? "Extern produktion"
																: "Backlist/e-bok"}
														</Table.Cell>
														<Table.Cell singleLine>
															{!prod.productionType &&
																get(prod, "billingArticles.production") &&
																!get(prod, "billingArticles.production.billed") && (
																	<ContentEditable
																		disabled={!!showingSentBill}
																		className={
																			"bill-production-discount " +
																			prod.discountUnit
																		}
																		html={
																			prod.discount ||
																			temporarySettings[
																				(prod.discountUnit || "percent") +
																					"_" +
																					prod.id
																			] ||
																			""
																		}
																		onChange={(e) => {
																			this.setState({
																				temporarySettings: {
																					...temporarySettings,
																					[(prod.discountUnit || "percent") +
																					"_" +
																					prod.id]: e.target.value,
																				},
																			});
																		}}
																	/>
																)}
														</Table.Cell>
														{(publisherSettings.cloudStorage ||
															publisherSettings.ebookStorage) && (
															<Table.Cell singleLine>
																{publisherSettings.cloudStorage &&
																prod.billingArticles.cloud &&
																!prod.billingArticles.cloud.billed
																	? publisherSettings.cloudStorage + " SEK"
																	: publisherSettings.ebookStorage &&
																	  prod.billingArticles.ebook &&
																	  !prod.billingArticles.ebook.billed
																	? publisherSettings.ebookStorage + " SEK"
																	: null}

																{publisherSettings.cloudChargeAllArticles &&
																	prod.billingArticles.cloud &&
																	!prod.billingArticles.cloud.billed &&
																	publisherSettings.ebookStorage &&
																	prod.billingArticles.ebook &&
																	!prod.billingArticles.ebook.billed &&
																	" + " + publisherSettings.ebookStorage + " SEK"}
															</Table.Cell>
														)}
														<Table.Cell singleLine>
															{credit && "-"}
															{prod.sum} SEK
															<span className="no-print" style={{ float: "right" }}>
																<Popup
																	size="tiny"
																	inverted
																	content="Dölj från faktura"
																	trigger={
																		<Icon
																			name="delete"
																			onClick={(e) => {
																				this.setState({
																					hiddenProductions: [
																						...hiddenProductions,
																						prod.id,
																					],
																				});
																			}}
																		/>
																	}
																/>

																<ProductionBilling production={prod} size="large" />
															</span>
														</Table.Cell>
													</Table.Row>
												))}

										{extraRows.map((row, ri) => (
											<Table.Row key={ri}>
												<Table.Cell>
													{" "}
													<ContentEditable
														disabled={!!showingSentBill}
														html={row.production || ""}
														onChange={(e) => {
															extraRows[ri].production = e.target.value;
															this.setState({
																extraRows,
															});
														}}
													/>
												</Table.Cell>
												<Table.Cell>
													{" "}
													<ContentEditable
														disabled={!!showingSentBill}
														html={row.author || ""}
														onChange={(e) => {
															extraRows[ri].author = e.target.value;
															this.setState({
																extraRows,
															});
														}}
													/>
												</Table.Cell>
												<Table.Cell>
													<ContentEditable
														disabled={!!showingSentBill}
														html={row.time || ""}
														onChange={(e) => {
															extraRows[ri].time = e.target.value;
															this.setState({
																extraRows,
															});
														}}
													/>
												</Table.Cell>
												<Table.Cell>
													{" "}
													<ContentEditable
														html={row.price || ""}
														disabled={!!showingSentBill}
														onChange={(e) => {
															extraRows[ri].price = e.target.value;
															this.setState({
																extraRows,
															});
														}}
													/>
												</Table.Cell>
												<Table.Cell singleLine>
													{" "}
													<ContentEditable
														disabled={!!showingSentBill}
														html={row.discount || ""}
														onChange={(e) => {
															extraRows[ri].discount = e.target.value;
															this.setState({
																extraRows,
															});
														}}
													/>
												</Table.Cell>
												{publisherSettings.cloudStorage && <Table.Cell singleLine></Table.Cell>}
												<Table.Cell singleLine>
													{credit && "-"}
													<ContentEditable
														disabled={!!showingSentBill}
														html={row.sum || ""}
														onChange={(e) => {
															extraRows[ri].sum = e.target.value;
															this.setState({
																extraRows,
															});
														}}
													/>{" "}
													SEK
													<span className="no-print" style={{ float: "right" }}>
														<Popup
															size="tiny"
															inverted
															content="Ta bort från faktura"
															trigger={
																<Icon
																	name="delete"
																	onClick={(e) => {
																		extraRows.splice(ri, 1);
																		this.setState({
																			extraRows,
																		});
																	}}
																/>
															}
														/>
													</span>
												</Table.Cell>
											</Table.Row>
										))}
										<Table.Row>
											<Table.Cell
												colSpan={
													4 +
													(publisherSettings.cloudStorage || publisherSettings.ebookStorage
														? 1
														: 0)
												}
												rowSpan={3}
												valign="bottom"
												style={{ position: "relative", paddingTop: 15 }}
											>
												<span
													className="no-print"
													style={{ position: "absolute", left: 0, top: 10 }}
												>
													<Popup
														inverted
														size="tiny"
														content="Lägg till fakturarad"
														trigger={
															<Icon
																name="plus"
																onClick={(e) => {
																	this.addRow();
																}}
															/>
														}
													/>
												</span>
												<div className="no-print">
													<em>Fritext (hälsning, info...)</em>
												</div>
												<ContentEditable
													disabled={!!showingSentBill}
													className="bill-sender"
													data-name="billFree"
													onChange={this.handleTemporarySettings}
													html={temporarySettings.billFree || ""}
												/>
											</Table.Cell>
											<Table.Cell style={{ textAlign: "right" }} singleLine>
												TOTALT:
											</Table.Cell>
											<Table.Cell singleLine>
												{credit && "-"}
												{total} SEK
												{/* <br />
												{temporarySettings.billNo || ""}
												{"\t"}
												{totalDuration}
												{"\t"}
												{totalProductionCost} */}
											</Table.Cell>
										</Table.Row>
										<Table.Row className="bill-sum">
											<Table.Cell style={{ textAlign: "right" }} singleLine>
												Moms{" "}
												<ContentEditable
													disabled={!!showingSentBill}
													data-name="vat"
													onChange={this.handleTemporarySettings}
													html={temporarySettings.vat || "6"}
												/>
												%:
											</Table.Cell>
											<Table.Cell singleLine>
												{credit && "-"}
												{(total * (Math.abs(temporarySettings.vat || 6) / 100)).toFixed(2)} SEK
											</Table.Cell>
										</Table.Row>
										<Table.Row className="bill-sum">
											<Table.Cell style={{ textAlign: "right" }} singleLine>
												<b>{credit ? "ER TILLGODO" : "ATT BETALA"}:</b>
											</Table.Cell>
											<Table.Cell singleLine>
												{credit && "-"}
												{(total * (1 + Math.abs(temporarySettings.vat || 6) / 100)).toFixed(
													2,
												)}{" "}
												SEK
											</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
								<div className="bill-footer">
									<ContentEditable
										className="permanent"
										data-name="billingFooter"
										html={
											this.state.billingFooter ||
											(billingSettings && billingSettings.billingFooter) ||
											"Betalningsvillkor, bankgiro etc...<br><br>Redigerbar sidfot"
										}
										onChange={this.handleProducerSettings}
									/>
								</div>
							</div>
						</>
					) : (
						<p>Välj förlag för att skapa ny faktura eller visa skickade fakturor.</p>
					)}

					{!showingSentBill && publisher && billedAt && !!Object.keys(billedAt).length && (
						<Segment className="no-print">
							<p>
								<b>Skickade fakturor:</b>
							</p>

							{bills &&
								bills
									.sort((a, b) => (toDate(a.billed) > toDate(b.billed) ? 1 : -1))
									.map((bill) => (
										<BillingListButton
											showBill={this.showBill}
											setAsPayed={this.setAsPayed}
											key={bill.id}
											productions={productions}
											bill={bill}
										/>
									))}
						</Segment>
					)}

					{showingSentBill && publisher === "other" && (
						<Segment className="no-print">
							<b>Länkade ljudboksproduktioner</b>

							{!temporarySettings.linkedProductions && (
								<div>
									<Form.TextArea
										placeholder="Fyll i text med ISBN för att söka"
										onChange={this.extractISBN}
										rows={6}
										style={{ minWidth: 400 }}
									/>
									{extractedISBN && (
										<p>
											<b>Hittade ISBN:</b> {extractedISBN.join(", ")}
										</p>
									)}
									{extractedISBN && (
										<p>
											<Button
												content={`Sök ${extractedISBN.length} produktioner`}
												loading={searchingISBN}
												onClick={this.searchISBN}
												primary
											/>
										</p>
									)}
								</div>
							)}
							{isbnHits &&
								extractedISBN &&
								Object.entries(isbnHits).map(([id, p]) => (
									<div key={id}>
										<b>{p.title}</b>{" "}
										{p.isbn &&
											"Ljudbok: " +
												p.isbn +
												" Arvode: " +
												(p.readerContractedByProducer ? "prod.bolag" : "förlag")}
										{p.deliveryParts &&
											"Ljudbok (delad): " +
												p.deliveryParts.map((p) => p.isbn).join(", ") +
												" Arvode: " +
												(p.readerContractedByProducer ? "prod.bolag" : "förlag")}
										{extractedISBN.includes(p.isbn) && p?.billingStatus?.producer?.billed && (
											<b style={{ color: "red" }}> Redan fakturerad! &nbsp;</b>
										)}
										&nbsp; {p?.deliveryEbook?.isbn && "E-bok: " + p.deliveryEbook.isbn}
									</div>
								))}

							{!!missingHits?.length && (
								<p>
									<b style={{ color: "red" }}>SAKNADE ISBN: </b>
									{missingHits.join(", ")}
								</p>
							)}

							{isbnHits &&
								(!temporarySettings.linkedProductions ? (
									<p>
										<br />
										<Button
											content={
												"Markera " +
												Object.keys(isbnHits).length +
												" st som fakturerade för ljudboksproduktion"
											}
											onClick={this.linkISBNtoBill}
											color="green"
										/>
									</p>
								) : (
									<p>
										<br />
										<Button
											content={
												temporarySettings.linkedProductionsPayed
													? "Länkade produktioner är betalda!"
													: "Markera länkade produktioner som betalda"
											}
											onClick={this.setLinkedAsPayed}
											color="green"
											disabled={temporarySettings.linkedProductionsPayed}
											loading={searchingISBN}
										/>
									</p>
								))}
						</Segment>
					)}
					<Segment className="no-print">
						<Form as="div">
							<Form.Input
								inline
								label="Sök faktura"
								value={this.state.billSearch || ""}
								onChange={(e, data) => {
									this.setState({ billSearch: data.value });
								}}
							/>
							<BillSearchResult
								organizations={organizations}
								producer={this.state.producer}
								setAsPayed={this.setAsPayed}
								search={this.state.billSearch}
								calculateSums={this.calculateSums}
								getPublisherSettings={this.getPublisherSettings}
								showingSentBill={showingSentBill}
								loadBill={(bill) => {
									this.handleSettings(null, { name: "publisher", value: bill.publisher, bill });
									this.showBill(bill);
								}}
							/>
						</Form>
					</Segment>
					{modal && (
						<Modal open>
							{modal === "publisherEmail" && (
								<>
									<Modal.Content>
										<Form>
											<Form.Input
												label="Mottagare (separera flera mottagare med komma)"
												required
												defaultValue={organizations?.[publisher]?.billingEmail}
												id="recipient"
											/>
											<Form.Input
												label="Ämnesrad"
												defaultValue="Ny faktura från Earselect AB"
												required
												id="subject"
											/>
											<Form.TextArea
												label="Meddelande"
												defaultValue={"Fakturan är bifogad\n\nmvh Astrid"}
												required
												id="message"
											/>
										</Form>
									</Modal.Content>
									<Modal.Actions>
										<Button
											primary
											floated="left"
											content="Ladda ner PDF"
											href={temporarySettings.pdf}
										/>

										<Button
											content="Avbryt"
											onClick={() => {
												this.setState({ modal: false });
											}}
										/>

										<Button
											primary
											content="Skicka epost"
											onClick={() => {
												const recipients = document
													.querySelector("#recipient")
													?.value.split(",")
													.filter((email) => email)
													.map((email) => email.trim());

												if (recipients.length) {
													this.setState({ sendingEmail: true });
													console.log("render -> recipients", recipients);
													db.collection("notifications")
														.add({
															subject:
																document.querySelector("#subject")?.value || "Faktura",
															message:
																document.querySelector("#message")?.value ||
																"mvh Astrid",
															attachments: [
																{
																	path: `gs://${
																		window.ES.stage ? "stage-" : ""
																	}earselect-secret/billingPDF/${showingSentBill}.pdf`,
																	name: "Faktura.pdf",
																},
															],
															time: new Date(),
															to: { email: recipients },
															sendOnStage: true,
														})
														.then(() => {
															this.setState({ modal: false, sendingEmail: false });
														});
												} else {
													window.alert("Ange mottagare!");
												}
											}}
											loading={this.state.sendingEmail}
										/>
									</Modal.Actions>
								</>
							)}

							{modal === "accountingEmail" && (
								<>
									<Modal.Content>
										<Form>
											<Form.Group widths="equal">
												<div className="field">
													<label>
														Fr.o.m &nbsp;
														<Dropdown
															inline
															scrolling
															text="(el. välj månad)"
															options={this.getMonthOptions()}
															onChange={(e, data) => {
																if (data.value) {
																	const [year, month] = data.value.split("-");
																	this.setState(
																		{
																			startDate: moment()
																				.year(year)
																				.month(month)
																				.startOf("month")
																				.toDate(),
																			endDate: moment()
																				.year(year)
																				.month(month)
																				.endOf("month")
																				.toDate(),
																		},
																		this.getPDFbills,
																	);
																}
															}}
														/>
													</label>
													<DateTime
														value={startDate}
														timeFormat={false}
														onChange={(dt) => {
															if (typeof dt === "object") {
																this.setState({ startDate: dt });
															} else if (!dt) {
																this.setState({ startDate: null });
															}
														}}
														onBlur={(dt) => {
															const writtenDate = moment(dt).toDate();
															this.setState(
																{ startDate: dt ? writtenDate : null },
																this.getPDFbills,
															);
														}}
													/>
												</div>
												<div className="field">
													<label>T.o.m</label>
													<DateTime
														value={endDate}
														timeFormat={false}
														onChange={(dt) => {
															if (typeof dt === "object") {
																this.setState({ endDate: dt });
															} else if (!dt) {
																this.setState({ endDate: null });
															}
														}}
														onBlur={(dt) => {
															const writtenDate = moment(dt).toDate();
															this.setState(
																{ endDate: dt ? writtenDate : null },
																this.getPDFbills,
															);
														}}
													/>
												</div>
											</Form.Group>
											<p>
												<b>Valda fakturor: </b>
												<br />
												{!(startDate || endDate) ? (
													<small>Ange datum.</small>
												) : this.state?.pdfBills?.length ? (
													this.state?.pdfBills?.map((bill) => {
														return (
															<a key={bill.id} href={bill.temporarySettings.pdf}>
																<Label
																	color="blue"
																	as="span"
																	content={
																		bill.temporarySettings.billNo ||
																		toDate(bill.billed)
																			.toISOString()
																			.substr(0, 16)
																			.replace("T", " ")
																	}
																	size="small"
																/>{" "}
															</a>
														);
													})
												) : (
													<small>Inga PDF-fakturor från vald period</small>
												)}
											</p>
											<Form.Input
												label="Mottagare (separera flera mottagare med komma)"
												required
												defaultValue={organizations?.[producer]?.accountingEmail}
												id="recipient"
											/>
											<Form.Input
												label="Ämnesrad"
												defaultValue="Skickade fakturor från Earselect AB"
												required
												id="subject"
											/>
											<Form.TextArea
												label="Meddelande"
												defaultValue={"Fakturorna är bifogade\n\nmvh Astrid"}
												required
												id="message"
											/>
										</Form>
									</Modal.Content>
									<Modal.Actions>
										<Button
											content="Avbryt"
											onClick={() => {
												this.setState({ modal: false });
											}}
										/>
										<Button
											primary
											content="Skicka epost"
											onClick={() => {
												this.setState({ sendingEmail: true });

												const recipients = document
													.querySelector("#recipient")
													?.value.split(",")
													.map((email) => email.trim());

												if (recipients.length)
													db.collection("notifications")
														.add({
															subject:
																document.querySelector("#subject")?.value || "Faktura",
															message:
																document.querySelector("#message")?.value ||
																"mvh Astrid",
															attachments: this.state?.pdfBills?.map((bill) => ({
																path: `gs://${
																	window.ES.stage ? "stage-" : ""
																}earselect-secret/billingPDF/${bill.id}.pdf`,
																name:
																	(bill.temporarySettings.billNo ||
																		toDate(bill.billed)
																			.toISOString()
																			.substr(0, 16)
																			.replace("T", " ")) + ".pdf",
															})),

															time: new Date(),
															to: { email: recipients },
															sendOnStage: true,
														})
														.then(() => {
															this.setState({ modal: false, sendingEmail: false });
														});
											}}
											loading={this.state.sendingEmail}
											disabled={!this.state?.pdfBills?.length}
										/>
									</Modal.Actions>
								</>
							)}
						</Modal>
					)}
				</div>
			)
		);
	}
}

const BillingListButton = ({ productions, bill, showBill, setAsPayed }) => {
	let billedProds = (
		(productions &&
			productions.filter((prod) => (get(prod, "billingStatus.producer.bills") || []).includes(bill.id))) ||
		[]
	).map((prod) => ({
		...prod,
		billingArticles:
			// load permanent data from bill
			bill.temporarySettings["billingStatus_" + prod.id] ||
			// trick old bills into looking at current status... but all articles as unbilled, to be included in calculation
			Object.entries(getBillingStatus(prod).articles).reduce((prev, [key, obj]) => {
				prev[key] = { ...obj, billed: false };
				return prev;
			}, {}),
	}));

	return (
		<Button.Group key={bill.id} size="small" style={{ margin: "0 5px 5px 0" }}>
			<Popup
				size="tiny"
				inverted
				content={
					billedProds.length ? (
						<div>
							<p>
								<b>Fakturerade produktioner:</b>
							</p>
							{billedProds.map((prod) => (
								<div key={prod.id}>
									<PayStatus prod={prod} /> {prod.title}
								</div>
							))}
							<p>
								<br />
								<b>Summa:</b> {bill.total} SEK
							</p>
							<p>
								<b>Klicka för att visa fakturan</b>
							</p>
						</div>
					) : (
						<div>{bill.temporarySettings.billReceiver}</div>
					)
				}
				trigger={
					<Button
						basic
						color="teal"
						content={
							get(bill, "temporarySettings.billNo") ||
							moment(toDate(bill.billed)).format("YYYY-MM-DD HH:mm")
						}
						onClick={(e) => {
							showBill(bill);
						}}
					/>
				}
			/>
			{!bill.credit && (
				<Popup
					size="tiny"
					inverted
					content={<div>Markera produktioner som betalda</div>}
					trigger={
						<Button
							icon="check"
							color="teal"
							onClick={(e) => {
								if (window.confirm("Är du säker på att du vill markera fakturan som betald?"))
									setAsPayed(billedProds);
							}}
						/>
					}
				/>
			)}
		</Button.Group>
	);
};

const PayStatus = ({ prod }) => {
	const { billed, payed } = getBillingStatus(prod);
	return <Icon name="money" color={billed && billed === payed ? "green" : "red"} />;
};

const BillSearchResult = ({
	organizations,
	search,
	producer,
	loadBill,
	setAsPayed,
	showingSentBill,
	getPublisherSettings,
	calculateSums,
}) => {
	const [bills, setBills] = useState();
	useEffect(() => {
		base.get("organizations/" + producer + "/secrets/billing/bills", { withIds: true }).then(setBills);
	}, [producer]);

	const [prods, setProds] = useState();
	useEffect(() => {
		const date = new Date();
		const sixMonthsAgo = date.setMonth(date.getMonth() - 6);
		base.get("productions", {
			query: (ref) => ref.where("producer", "==", producer).where("deliveryDate", ">", new Date(sixMonthsAgo)),
			withIds: true,
		}).then(setProds);
	}, [producer]);

	const unpayedProductions =
		bills &&
		prods &&
		!search &&
		prods.filter((p) => {
			const { billed, payed } = getBillingStatus(p);

			return billed && billed > payed;
		});

	const unpayedBills =
		unpayedProductions &&
		unpayedProductions
			.reduce((prev, curr) => {
				if (curr.billingStatus.producer.bills) {
					curr.billingStatus.producer.bills.forEach((billId) => {
						const bill = bills.find((bill) => bill.id === billId);

						// add relevant bills
						const { billed, payed, billedCloud, payedCloud, billedEbook, payedEbook } =
							curr.billingStatus.producer;

						let oldestPayed = billed && payed ? toDate(billed) : new Date("2000-01-01");
						if (billedCloud && payedCloud && toDate(billedCloud) > oldestPayed)
							oldestPayed = toDate(billedCloud);
						if (billedEbook && payedEbook && toDate(billedEbook) > oldestPayed)
							oldestPayed = toDate(billedEbook);

						if (bill && toDate(bill.billed) > oldestPayed && !prev.includes(billId)) {
							prev.push(billId);
						}
					});
				}

				return prev;
			}, [])
			.map((id) => {
				const bill = bills.find((bill) => bill.id === id);
				const publisherSettings = getPublisherSettings(bill.publisher, bill.billingSettings);

				const { total } = calculateSums(
					prods
						.filter((prod) => get(prod, "billingStatus.producer.bills", []).includes(bill.id))
						.map((prod) => {
							const preBillArticles =
								bill.temporarySettings["billingStatus_" + prod.id] ||
								Object.entries(getBillingStatus(prod).articles).reduce((prev, [key, obj]) => {
									prev[key] = { ...obj, billed: false };
									return prev;
								}, {});
							const currentArticles = getBillingStatus(prod).articles;

							const billingArticles = {};
							["production", "cloud", "ebook"].forEach((article) => {
								billingArticles[article] =
									preBillArticles && preBillArticles[article] && !preBillArticles[article].billed
										? { billed: currentArticles[article] && currentArticles[article].payed }
										: false;
							});

							return {
								...prod,
								billingArticles,
							};
						}),
					bill.extraRows,
					bill.temporarySettings,
					publisherSettings,
				);

				return { ...bill, id, total };
			})
			.filter((b) => b.total && !b.creditedBy && !b.credit);

	return (
		<div>
			{bills &&
				search &&
				Object.entries(bills)
					.filter(([id, bill]) => (get(bill, "temporarySettings.billNo") || "").includes(search))
					.map(([id, bill]) => (
						<Button
							size="small"
							key={id}
							style={{ margin: "5px 5px 0 0" }}
							onClick={(e) => {
								loadBill(bill);
							}}
						>
							{organizations[bill.publisher] ? organizations[bill.publisher].name : "Övrig"}{" "}
							{get(bill, "temporarySettings.billNo")}
						</Button>
					))}

			{!showingSentBill && !search && (
				<div>
					<b>Obetalda fakturor</b>
					<br />
					{bills && !search && prods ? (
						<>
							{unpayedBills
								.sort((a, b) => {
									const pubA = getPublisherSettings(a.publisher, a.billingSettings);
									const pubB = getPublisherSettings(b.publisher, b.billingSettings);

									const dateA = moment(toDate(a.billDate)).add(pubA.termsDays, "days").toDate();
									const dateB = moment(toDate(b.billDate)).add(pubB.termsDays, "days").toDate();

									return dateA > dateB ? 1 : -1;
								})
								.map((bill) => {
									return bill ? (
										<BillingListButton
											setAsPayed={setAsPayed}
											showBill={loadBill}
											key={bill.id}
											productions={prods}
											bill={bill}
										/>
									) : null;
								})}
							<br />
							<b>Obetald summa (exkl. moms): </b>
							{unpayedBills.reduce((prev, bill) => prev + bill.total, 0)} SEK
						</>
					) : (
						<em>Laddar...</em>
					)}
				</div>
			)}
		</div>
	);
};

export default withStore(Billing);
