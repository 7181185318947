import { useMemo } from "react";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import useTeamMembersByRole from "../../team/hooks/useTeamMembersByRole";

export default function useReaderOptions({ production }) {
	const readerOptions = useTeamMembersByRole({ team: production?.team, role: teamRoles.READER });

	return useMemo(() => {
		return readerOptions?.map((reader) => ({
			key: reader.user.id,
			value: reader.user.id,
			text: reader.user.name,
		}));
	}, [readerOptions]);
}
