import { estimateTotalStudioHours } from "../../productions/utils/productionEstimation";
import { getEstimatedDurationInMinutes } from "../../productions/utils/productionHelper";

import { getCostValue } from "./costsHelper";

function getEstimatedSessionActivity({ cost }) {
	return ((cost.session?.end?.toDate() || 0) - (cost.session?.start?.toDate() || 0)) / 1000 / 60;
}

function getEstimatedSessionAmountProduced({ cost, production }) {
	const estimatedSessionMinutes = getEstimatedSessionActivity({ cost });
	const estimatedDurationInMinutes = getEstimatedDurationInMinutes(production);
	const estimatedStudioMinutes = estimateTotalStudioHours(production) * 60;

	return (estimatedDurationInMinutes / estimatedStudioMinutes) * estimatedSessionMinutes;
}

function getEstimatedActivity({ cost }) {
	switch (cost.price?.type) {
		case "record":
			return getEstimatedSessionActivity({ cost });
		default:
			return 0;
	}
}

function getEstimatedAmountProduced({ cost, production }) {
	switch (cost.price?.type) {
		case "record":
			return getEstimatedSessionAmountProduced({ cost, production });
		default:
			return getEstimatedDurationInMinutes(production);
	}
}

function getEstimatedAmount({ cost, production }) {
	if (cost.price?.amount) {
		return cost.price.amount;
	}

	switch (cost.price?.unit) {
		case "activity":
			return getEstimatedActivity({ cost, production });
		case "time":
			return getEstimatedAmountProduced({ cost, production });
		case "hour":
			return getEstimatedAmountProduced({ cost, production }) / 60 || 0;
		default:
			return 1;
	}
}

export function getCostEstimation({ cost, production, exchangeRate, expenses }) {
	if (!cost.price?.currency) {
		return {
			value: 0,
			exchanged: 0,
		};
	}

	const rate = exchangeRate.rates[cost.price.currency];

	const price = getCostValue({ cost, exchangeRate, expenses });
	const amount = getEstimatedAmount({ cost, production });

	const value = amount * price;
	const exchanged = value / rate;

	return {
		value,
		exchanged,
	};
}
