import React from "react";
import { useTranslation } from "react-i18next";

import { Translator } from "astrid-firestore/src/api/translators/types/Translator";
import { languageOptions } from "astrid-helpers/src/languages";

import api from "../../../../../api";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

export default function AdminTranslatorSettingsForm({ translator }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Translator,
		values: {
			ref: translator.ref,
			name: translator.name || "",
			languages: translator.languages || [],
		},
		onSubmit: (data) => {
			return api.translators.update(data, { version: true });
		},
	});

	return (
		<AdminForm header={t("settings", "Settings")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("name", "Name")} />
				<Form.Select
					name="languages"
					label={t("languages", "Languages")}
					multiple
					search
					options={languageOptions}
				/>
			</Form.Group>
		</AdminForm>
	);
}
