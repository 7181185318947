import get from "lodash/get";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Label, Loader } from "semantic-ui-react";

import { db } from "astrid-firebase";
import { updateDocument } from "astrid-firestore/src/helpers";

import { sendToDistributor } from "../fnc";

export default function SendEbook({ distName, profile, uid, productionId, production, distributor, logEvent }) {
	const { t } = useTranslation();
	const ebookUploaded = production?.deliveryEbook?.path;
	const ebookSent = get(production, "deliveryEbook.release." + distributor + ".metaSent");
	const isSendingEbook = ebookSent === "queued";

	const send = () => {
		const articles = {
			[productionId]: { ebook: "metadata" },
		};
		sendToDistributor(distributor, articles, profile, uid);
		const newData = {
			["deliveryEbook.release." + distributor + ".metaSent"]: "queued",
		};
		updateDocument(db.collection("productions").doc(productionId), newData).then(() => logEvent(newData));
	};

	return (
		<div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
			<Button
				size="tiny"
				color={ebookSent?.toDate ? "teal" : "blue"}
				disabled={isSendingEbook || !ebookUploaded || !production.deliveryEbook?.isbn}
				content={
					isSendingEbook || ebookSent?.toDate
						? `${distName}: ${t("sendAgain", "send again")}`
						: `${distName}: ${t("send", "send")}`
				}
				onClick={send}
			/>
			{isSendingEbook && (
				<Button
					size="tiny"
					icon="trash"
					color="red"
					onClick={() => {
						const newData = {
							["deliveryEbook.release." + distributor + ".metaSent"]: "reset",
						};
						updateDocument(db.collection("productions").doc(productionId), newData).then(() =>
							logEvent(newData),
						);
					}}
				/>
			)}
			{isSendingEbook && <Loader inline active size="tiny" />}
			{ebookSent?.toDate && (
				<Label basic pointing="left">
					{`${ebookSent?.toDate?.()?.toLocaleString()}`}
				</Label>
			)}
		</div>
	);
}
