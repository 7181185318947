import flattenIntervals from "astrid-helpers/src/flattenIntervals";
import timeToMs from "astrid-helpers/src/timeToMs";

const gracePeriod = 15000;

function compareOverlappingSessions(session, sessions) {
	if (session.status === "cancelled") {
		return false;
	}

	const intervals =
		session.pageInterval?.map((obj) => {
			return [obj.in, obj.out];
		}) || [];

	for (const comparedSession of sessions) {
		if (comparedSession.id === session.id) {
			return false;
		}

		// 15 sec grace period for overlapping in timeline
		const cubaseIn = timeToMs(session.cubaseIn) + gracePeriod;
		const cubaseOut = timeToMs(comparedSession.cubaseOut);

		if (cubaseIn < cubaseOut) {
			return true;
		}

		const comparedIntervals =
			comparedSession.pageInterval?.map((obj) => {
				return [obj.in, obj.out];
			}) || [];

		const combinedIntervals = [...intervals, ...comparedIntervals];

		const flattenedIntervals = flattenIntervals(combinedIntervals, -1);

		if (combinedIntervals.length !== flattenedIntervals.length) {
			return true;
		}
	}
}

export default compareOverlappingSessions;
