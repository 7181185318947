import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminAppendixesView from "../views/SuperAdminAppendixesView/SuperAdminAppendixesView";

export default function SuperAdminAppendixes() {
	const hasRight = useHasRight();

	if (!hasRight("agreements.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminAppendixesView />;
}
