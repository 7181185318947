import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import { useLoadingValues } from "astrid-firestore/src/hooks";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import CustomPriceForm from "../../../offers/components/OfferForm/components/CustomPriceForm";
import { userIsEmployee } from "../../../users/utils/userAccessors";

import TeamMemberAgreementField from "./components/TeamMemberAgreementField";
import TeamMemberFirstPartyField from "./components/TeamMemberFirstPartyField";
import TeamMemberFirstPartyVendorField from "./components/TeamMemberFirstPartyVendorField";
import TeamMemberPriceField from "./components/TeamMemberPriceField";
import TeamMemberRoleField from "./components/TeamMemberRoleField";
import TeamMemberUserField from "./components/TeamMemberUserField";
import useMemberForm from "./hooks/useMemberForm";
import submitMember from "./utils/submitMember";

export default function TeamMemberForm({ offer, production, member, onClose }) {
	const { t } = useTranslation();

	const form = useMemberForm({ offer, production, member });

	const { watch, setValue, formState, handleSubmit } = form;

	const [role, userId, firstPartyId, priceId] = watch(["role", "userId", "firstPartyId", "priceId"]);

	const [[user, firstParty]] = useLoadingValues(
		useDocumentDataOnce(userId && db.collection("users").doc(userId)),
		useDocumentDataOnce(firstPartyId && db.collection("organizations").doc(firstPartyId)),
	);

	useEffect(() => {
		setValue("user", user);
		setValue("employee", role === teamRoles.READER ? false : userIsEmployee(production.producer)(user));
	}, [production.producer, setValue, user, role]);

	const addHeader = t("addMember", "Add member");
	const offerHeader = offer && `${t("addMember", "Add member")}: ${offer.secondParty.name}`;
	const editHeader = member && `${t("editMember", "Edit member")}: ${member.user.name}`;

	return (
		<ModalPrompt
			header={offerHeader || editHeader || addHeader}
			onClose={onClose}
			disabled={!formState.isValid}
			onSave={handleSubmit(submitMember)}
		>
			<Form form={form}>
				{!offer && !member && (
					<Form.Group widths="2">
						<TeamMemberRoleField />
						<TeamMemberUserField />
					</Form.Group>
				)}

				<TeamMemberFirstPartyField />
				<TeamMemberFirstPartyVendorField firstParty={firstParty} />
				<TeamMemberAgreementField />
				<TeamMemberPriceField />

				{priceId === "custom" && (
					<CustomPriceForm firstParty={firstParty} production={production} defaultPrice={offer?.price} />
				)}
			</Form>
		</ModalPrompt>
	);
}
