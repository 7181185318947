import get from "lodash/get";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { Component } from "react";
import ContentEditable from "react-contenteditable";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Loader, Modal, Tab } from "semantic-ui-react";

import { base } from "astrid-firebase";

class ProductionProofScript extends Component {
	state = {
		polishMarkers: null,
		// if script has been edited set second tab, if not or if reader set first tab
		activeIndex: this.props.production.proofScript && !this.props.isReader ? 1 : 0,
		script: null,
		totalDuration: null,
		proofScriptDone: this.props.production.proofScriptDone,
	};

	UNSAFE_componentWillMount() {
		const { proofParts, deliveryCD } = this.props.production;

		if (deliveryCD) {
			const totalDuration = Object.values(proofParts).reduce((curr, part) => curr + part.audioDuration, 0);
			this.setState({ totalDuration });
		}

		base.get("productions/" + this.props.productionId + "/markers", {
			context: this,
		}).then((markers) => {
			let sortedMarkers = sortBy(
				markers
					.filter(
						(marker) =>
							marker.scriptText &&
							marker.type !== "right" &&
							proofParts[marker.part] &&
							proofParts[marker.part].status === "done" &&
							!(marker.type === "script" && marker.changeText) && // only include script error if not corrected at recording
							!(marker.type === "noise" && !marker.scriptText), // only include noise if script text
					)
					.map((marker) => ({ ...marker, sort: proofParts[marker.part].sort })),
				["sort", "time", "part"],
			);

			this.setState({ polishMarkers: sortedMarkers });
		});
	}

	updateScript = (e) => {
		this.setState({ script: e.target.value });
	};

	saveScript = (e) => {
		if (this.state.script) {
			this.props.handleChange(e, { name: "proofScript", value: this.state.script });
			this.setState({ script: null });
		}
	};

	close = (e) => {
		this.saveScript();
		this.props.context.setState({ proofScriptModal: false });
	};

	generateBlurb = (txt) => {
		const regex = /<span.*?data-tag="([^"]*)"[^>]*>(?:.*?<\/span>)?/gi;
		const { t, production, publisher, producer, userMap } = this.props;

		return (
			"<p>" +
			txt.replace(regex, (match, tag) => {
				let str = "";

				if (tag === "title") str = production.title;
				else if (tag === "reader")
					str = production.reader
						? production.reader
								.reduce((arr, id) => {
									return [
										...arr,
										userMap[id]
											? userMap[id].firstName + " " + userMap[id].lastName
											: t("deletedNarrator"),
									];
								}, [])
								.join(" och ")
						: t("missing");
				else if (tag === "author")
					str = production.authorOptions
						? production.authorOptions
								.reduce((arr, author) => {
									return [...arr, author ? author.text : t("deletedAuthor")];
								}, [])
								.join(" och ")
						: t("missing");
				else if (tag === "translator")
					str = production.translatorOptions
						? production.translatorOptions
								.reduce((arr, translator) => {
									return [...arr, translator ? translator.text : t("deletedTranslator")];
								}, [])
								.join(" och ")
						: t("missing");
				else if (tag === "publisher") str = publisher.name;
				else if (tag === "producer") str = producer.name;
				else if (tag === "year")
					str =
						production.deliveryDate && production.deliveryDate.toDate
							? moment(production.deliveryDate.toDate()).format("YYYY")
							: "";

				return str;
			}) +
			"</p>"
		);
	};

	generateScript = () => {
		const { production, publisher } = this.props;

		let script = "";

		let intro = get(production, "blurbs.intro") || get(publisher, "blurbs.intro");
		let outro = get(production, "blurbs.outro") || get(publisher, "blurbs.outro");

		if (intro) {
			script += this.generateBlurb(intro);
		}

		if (this.state.polishMarkers) {
			this.state.polishMarkers.forEach((marker) => {
				script += `<p><strong> Sida ${marker.page}:</strong> ${marker.scriptText}</p>`;
			});
		}

		if (outro) {
			script += this.generateBlurb(outro);
		}

		if (production.deliveryParts) {
			Object.values(production.deliveryParts).forEach((part) => {
				script += "<p>" + part.title + "</p>";
			});
		}

		if (this.state.totalDuration && production.deliveryCD) {
			// split recorded time with 72 for 74 min discs (default) and 77 for 80 min discs
			const discDuration = (production.deliveryCD.duration === 80 ? 77 : 72) * 60;
			const discAmount = Math.ceil(this.state.totalDuration / discDuration);

			let disc = 0;
			while (disc < discAmount) {
				disc++;
				script += "<p>" + production.title + ", CD " + disc + "</p>";
			}
		}

		return script;
	};

	render() {
		const { t, production, isReader } = this.props;
		const { activeIndex, script, proofScriptDone } = this.state;

		const generated = this.generateScript();

		return (
			<Modal open={true} closeOnDimmerClick={false} onClose={this.close}>
				<Modal.Header>
					{t("fineScriptHeader")} – {production.title}
				</Modal.Header>
				<Modal.Content scrolling>
					<Modal.Description>
						<Tab
							onTabChange={(e, { activeIndex }) => this.setState({ activeIndex })}
							activeIndex={activeIndex}
							panes={[
								{
									menuItem: t("generatedFromProof"),
									render: () => (
										<Tab.Pane>
											{generated ? (
												<div dangerouslySetInnerHTML={{ __html: generated }} />
											) : (
												<Loader />
											)}
										</Tab.Pane>
									),
								},
								{
									menuItem:
										production.proofScript || isReader
											? t("editedFineScript")
											: t("createEditableFineScript"),
									render: () => (
										<Tab.Pane>
											<ContentEditable
												onChange={this.updateScript}
												disabled={isReader}
												onBlur={this.saveScript}
												html={script || production.proofScript || generated || ""}
											/>
										</Tab.Pane>
									),
								},
								// reader only gets the second tab
							].filter((pane, i) => !isReader || i)}
						/>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					{!!activeIndex && !isReader && (
						<>
							<Button
								primary
								icon="bold"
								style={{ float: "left" }}
								onMouseDown={(e) => {
									e.preventDefault(); // Avoids loosing focus from the editable area
									document.execCommand("bold"); // Send the command to the browser
								}}
							/>
							<Button
								primary
								icon="italic"
								style={{ float: "left" }}
								onMouseDown={(e) => {
									e.preventDefault();
									document.execCommand("italic");
								}}
							/>
							<Checkbox
								label={t("visibleForReader")}
								checked={proofScriptDone}
								onChange={(e) => {
									this.setState({ proofScriptDone: !proofScriptDone });
									this.props.handleChange(null, {
										name: "proofScriptDone",
										value: !proofScriptDone,
									});
								}}
							/>
						</>
					)}
					<Button
						color="green"
						icon="checkmark"
						labelPosition="left"
						onClick={this.close}
						content={t("ready")}
					/>
				</Modal.Actions>
			</Modal>
		);
	}
}

export default withTranslation()(ProductionProofScript);
