import { useTranslation } from "react-i18next";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import todoTypes from "astrid-firestore/src/constants/todoTypes";

export default function useNotificationHeaderTranslations(todo) {
	const { t } = useTranslation();

	const offerStatusTranslations = {
		[offerTypes.WORK_ORDER]: {
			[offerStatuses.ACCEPTED]: t("workOrderAccepted", "Work order was accepted"),
			[offerStatuses.DECLINED]: t("workOrderDeclined", "Work order was declined"),
			[offerStatuses.EXPIRED]: t("workOrderExpired", "Work order has expired"),
		},
		[offerTypes.INQUIRY]: {
			[offerStatuses.INTERESTED]: t("inquiryAccepted", "Inquiry was accepted"),
			[offerStatuses.DECLINED]: t("inquiryDeclined", "Inquiry was declined"),
			[offerStatuses.EXPIRED]: t("inquiryExpired", "Inquiry has expired"),
		},
		[offerTypes.OPEN]: {
			[offerStatuses.ACCEPTED]: t("openPositionAccepted", "Open position was accepted"),
			[offerStatuses.EXPIRED]: t("openPositionExpired", "Open position has expired"),
		},
	};

	const offerTypeCommentTranslations = {
		[offerTypes.WORK_ORDER]: t("newCommentOnWorkOrder", "New comment on work order"),

		[offerTypes.INQUIRY]: t("newCommentOnInquiry", "New comment on inquiry"),
	};

	return {
		[todoTypes.OFFER_STATUS_CHANGED]:
			offerStatusTranslations[todo?.content?.offer?.type]?.[todo?.content?.offer?.status],
		[todoTypes.READER_INQUIRY_STATUS_CHANGED]:
			offerStatusTranslations[offerTypes.INQUIRY]?.[todo?.content?.offer?.status],
		[todoTypes.SUBCONTRACTOR_UPLOADED_FILES]: t("subcontractorUploadedFiles", "Subcontractor has uploaded files"),
		[todoTypes.NEW_COMMENT_ON_OFFER]: offerTypeCommentTranslations[todo?.content?.offer?.type],
	};
}
