import { useTranslation } from "react-i18next";

export default function useDistributionChannelDropdownMenuOptions(data) {
	const { t } = useTranslation();

	return [
		{
			as: "a",
			text: t("view", "View"),
			href: `/admin/distributions/channels/${data.id}`,
		},
	];
}
