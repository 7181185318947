import { Button } from "semantic-ui-react";

import CreateStudioAction from "../../../../studios/components/StudiosActions/CreateStudioAction";

export default function AdminStudiosActions({ gen, organization }) {
	return (
		<Button.Group basic secondary>
			<CreateStudioAction gen={gen} organization={organization} />
		</Button.Group>
	);
}
