import React, { useMemo } from "react";

import TeamMemberCreatedLabel from "../../../../team/components/TeamMemberCreated/TeamMemberCreatedLabel";
import TeamMemberDropdownMenu from "../../../../team/components/TeamMemberDropdownMenu/TeamMemberDropdownMenu";
import TeamMemberHeader from "../../../../team/components/TeamMemberHeader/TeamMemberHeader";
import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function useProductionTeamColumns(production) {
	return useMemo(
		() => [
			{
				id: "user.img",
				Cell: ({ value }) => <ImageAvatar src={value} />,
			},
			{
				id: "user.name",
				flex: 1,
				Cell: ({ row }) => <TeamMemberHeader member={row.original} />,
			},
			{
				id: "created",
				Cell: ({ row }) => <TeamMemberCreatedLabel member={row.original} />,
			},
			{
				id: "menu",
				Cell: ({ row }) => <TeamMemberDropdownMenu member={row.original} production={production} />,
			},
		],
		[production],
	);
}
