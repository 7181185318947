export default function getAgreementStatus(agreement) {
	const now = new Date();

	if (agreement.activeFrom?.toDate?.() > now) {
		return "inactive";
	}

	if (agreement.expires?.toDate?.() <= now) {
		return "expired";
	}

	return "active";
}
