import React from "react";
import { Header, Icon, List, ListItem, Segment } from "semantic-ui-react";

import { useDownloadURL } from "astrid-firestore/src/hooks";

import Flex from "../../../../../components/Flex/Flex";

function formatFileSize(bytes) {
	const sizeInMB = bytes / 1024 / 1024;
	const sizeInGB = bytes / 1024 / 1024 / 1024;

	// If the size is larger than or equal to 1 GB
	if (sizeInGB >= 1) {
		return `${sizeInGB.toFixed(2)} GB`; // Rounded to 2 decimal places
	} else {
		return `${sizeInMB.toFixed(2)} MB`; // Rounded to 2 decimal places
	}
}

function ArchivedFile({ file }) {
	const [url] = useDownloadURL({ bucket: file.bucket, fullPath: file.fullPath });

	return (
		<>
			<a href={url} download={file.name} rel="noopener noreferrer">
				{file.name}
			</a>
			{!file.name.endsWith("json") && ` (${formatFileSize(file.size)})`}
		</>
	);
}

function ListFiles({ files, header, icon }) {
	return files?.length ? (
		<div>
			<Header as="h4">
				<Icon name={icon} />
				{header}
			</Header>
			<List>
				{files.map((file) => {
					return (
						<ListItem key={file.name}>
							<ArchivedFile file={file} />
						</ListItem>
					);
				})}
			</List>
		</div>
	) : null;
}

export default function DownloadFilesSegment({ hit }) {
	return (
		<Segment style={{ width: "33%" }}>
			<Flex
				style={{
					gap: 30,
					justifyContent: "flex-start",
				}}
			>
				<div>
					<Header as="h4">
						<Icon name="code" />
						metadata
					</Header>
					{hit.metaDataFile && <ArchivedFile file={hit.metaDataFile} />}
				</div>
				<ListFiles files={hit.zipFiles} header="zip" icon="zip" />
				<ListFiles files={hit.mp3Files} header="mp3" icon="sound" />
				<ListFiles files={hit.wavFiles} header="wav" icon="sound" />
				<ListFiles files={hit.epubFiles} header="epub" icon="book" />
				<ListFiles files={hit.pdfFiles} header="pdf" icon="file pdf outline" />
			</Flex>
		</Segment>
	);
}
