import runTransaction from "../../helpers/runTransaction";
import { getUserRef } from "../../utils/referenceMappers";

import offerStatuses from "./constants/offerStatuses";

export default async function deleteOffer(firebase, { offer, user }) {
	const { voiceId } = offer;

	return runTransaction(firebase.firestore(), [offer.ref], async (transaction, [offer]) => {
		// Cancel the signing if it's in progress
		if (offer.agreement?.id) {
			await firebase.functions().httpsCallable("scrive-cancelSigning")({
				agreementId: offer.agreement.id,
			});
		}

		// Delete the offer if it's a draft, otherwise mark it as deleted
		if (offer.status === offerStatuses.DRAFT) {
			transaction.delete(offer.ref);
		} else {
			transaction.update(offer.ref, {
				deleted: true,
				deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
				deletedBy: getUserRef(user),
			});
		}

		// Remove the reader from the voice
		if (voiceId && offer.secondParty?.id) {
			transaction.update(offer.production.ref, {
				[`voices.${voiceId}.readerIds`]: firebase.firestore.FieldValue.arrayRemove(offer.secondParty.id),
			});
		}
	});
}
