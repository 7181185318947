import runBatch from "../../helpers/runBatch";

import createOrderData from "./createOrderData";

export default function createOrders(firebase, { orders, user }) {
	const db = firebase.firestore();

	return runBatch(db, (batch) => {
		for (const costs of orders) {
			const order = createOrderData(firebase, {
				costs,
				createdBy: user,
			});

			batch.set(order.ref, order);

			for (const cost of costs) {
				batch.update(cost.ref, { orderId: order.id });
			}
		}
	});
}
