import React from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Button, Card, List } from "semantic-ui-react";

import { firebase } from "astrid-firebase";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import Form from "../../forms/components/Form/Form";
import useForm from "../../forms/hooks/useForm";

import useInvoiceNumber from "../hooks/useInvoiceNumber";

const createSalesOrderInvoice = firebase.functions().httpsCallable("invoices-createSalesOrderInvoice");

function getInitialDueDate() {
	const today = new Date();
	today.setDate(today.getDate() + 30);
	return today;
}

export default function CreateSalesOrderInvoice({ order }) {
	const { t } = useTranslation();
	const { organization } = useOutletContext();
	const [invoiceNumber, loadingInvoiceNumber, errorInvoiceNumber] = useInvoiceNumber({ orgId: organization.id });

	const form = useForm({
		defaultValues: {
			invoiceNumber,
			dueDate: getInitialDueDate(),
			optionalReference: null,
			vatReverseCharge: false
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = form;

	const submit = handleSubmit(async (formData) => {
		try {
			await createSalesOrderInvoice({
				organizationId: organization.id,
				orderId: order.id,
				dueDate: formData.dueDate.getTime(),
				optionalReference: formData.optionalReference,
				invoiceNumber,
				vatReverseCharge: formData.vatReverseCharge
			});
		} catch (error) {
			console.error(error);
		}
	});

	if (order.expense) {
		return null;
	}

	return (
		<Form form={form}>
			<LoadingContext data={invoiceNumber} loading={loadingInvoiceNumber} error={errorInvoiceNumber}>
				<Card style={{ marginBottom: 20 }}>
					<Card.Content>
						<Card.Description>
							<List divided relaxed>
								<List.Item>
									<List.Icon name="hashtag" />
									<List.Content>
										<List.Header>{invoiceNumber}</List.Header>
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="time" />
									<List.Content>
										<Form.DateInput
											inputProps={{ disabled: isSubmitting }}
											name="dueDate"
											label={t("dueDate", "Due date")}
										/>
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Icon name="address book outline" />
									<List.Content>
										<Form.TextArea
											rules={{ required: false }}
											name="optionalReference"
											label={t("reference", "Reference")}
											rows={1}
										/>
									</List.Content>
								</List.Item>
								<List.Item>
									<List.Content>
										<Form.Checkbox
											rules={{ required: false }}
											name="vatReverseCharge"
											label={t("vatReverseCharge", "VAT reverse charge")}
										/>
									</List.Content>
								</List.Item>
							</List>
						</Card.Description>
					</Card.Content>
					<Card.Content extra>
						<Button color="teal" disabled={isSubmitting} loading={isSubmitting} onClick={submit}>
							{t("createInvoice", "Create invoice")}
						</Button>
					</Card.Content>
				</Card>
			</LoadingContext>
		</Form>
	);
}
