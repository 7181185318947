import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import Table from "../../../../ui/components/Table/Table";

import useAdminPublisherPricesColumns from "../hooks/useAdminPublisherPricesColumns";

import PublisherPricesActions from "./PublisherPricesActions";

export default function AdminPublisherPrices({ publisher, organization }) {
	const { t } = useTranslation();

	const data = useMemo(
		() => Object.values(publisher.prices?.[organization.id] || {}),
		[organization.id, publisher.prices],
	);

	const columns = useAdminPublisherPricesColumns({ publisher, organization });

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("defaultPricing", "Default pricing")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Table data={data} columns={columns} footer={false} />
			</Card.Content>
			<Card.Content extra>
				<PublisherPricesActions publisher={publisher} organization={organization} />
			</Card.Content>
		</Card>
	);
}
