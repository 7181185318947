import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";

import ArticleCoverImage from "../../../../articles/components/ArticleCoverImage/ArticleCoverImage";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminArticleDropdownMenuOptions from "../hooks/useAdminArticleDropdownMenuOptions";
import useAdminArticleMenuOptions from "../hooks/useAdminArticleMenuOptions";

export default function AdminArticleHeader({ article }) {
	const { t } = useTranslation();
	const menuOptions = useAdminArticleMenuOptions({ article });

	const { input, loading, error, options: dropdownMenuOptions } = useAdminArticleDropdownMenuOptions({ article });

	return (
		<AdminPageHeader
			error={error}
			loading={loading}
			menuOptions={menuOptions}
			dropdownMenuOptions={dropdownMenuOptions}
		>
			{input}

			<ArticleCoverImage thumb rounded article={article} style={{ width: 80, height: 80 }} />

			<Header style={{ margin: 0 }}>
				{article.title && (
					<Header.Subheader style={{ marginBottom: 2 }}>
						{`${t("title", "Title")}: ${article.title.name}`}
					</Header.Subheader>
				)}

				{article.name}
			</Header>
		</AdminPageHeader>
	);
}
