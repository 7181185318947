import { enumArray, object, string } from "../../../types/_types";

import defaultStudioSettings from "../defaultStudioSettings";

export const StudioSettings = object({
	channel: enumArray(["left", "right"]),
	input: string(),
	output: string(),
	releaseChannel: enumArray(["disabled", "latest", "beta", "alpha"]),
}).default(defaultStudioSettings);
