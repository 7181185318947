import React from "react";

import PublisherSettings from "../components/publisher/PublisherSettings";
import { withStore } from "../helpers/context";

const Publisher = (props) => {
	const pid = props.match.params.orgId;

	return <PublisherSettings key={pid} {...props} />;
};

export default withStore(Publisher);
