import { firebase } from "astrid-firebase";
import createProductionPriceData from "astrid-firestore/src/api/productions/prices/createProductionPriceData";

export default function getExpense({ firstParty, production, memberId, approved, ...data }) {
	const { user, employee, agreement } = production.team[memberId];

	return createProductionPriceData(firebase, {
		...data,
		expense: true,
		approved: approved || employee || agreement?.firstParty?.id === production.publisher,
		firstParty,
		secondParty: user,
		employee,
		agreement,
		production,
	});
}
