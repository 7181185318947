import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";

import { formatDate } from "../../../../../helpers/date";
import Form from "../../../../forms/components/Form/Form";

export default function AdminArticleDatesFormInputs({ name = "", labels }) {
	const { t } = useTranslation();

	const { watch } = Form.useFormContext();

	const { announcementDate, releaseDate, takedownDate } = watch("distribution");

	return (
		<>
			<Table.Cell style={{ width: "28%" }}>
				<Form.DateInput
					name={`${name}.announcementDate`}
					label={labels && t("announcementDate", "Announcement date")}
					placeholder={announcementDate}
					disableBeforeToday
					formatDate={formatDate}
					rules={{ valueAsDate: false }}
				/>
			</Table.Cell>

			<Table.Cell style={{ width: "28%" }}>
				<Form.DateInput
					name={`${name}.releaseDate`}
					label={labels && t("releaseDate", "Release date")}
					placeholder={releaseDate}
					disableBeforeToday
					formatDate={formatDate}
					rules={{ valueAsDate: false }}
				/>
			</Table.Cell>

			<Table.Cell style={{ width: "28%" }}>
				<Form.DateInput
					name={`${name}.takedownDate`}
					label={labels && t("takedownDate", "Takedown date")}
					placeholder={takedownDate}
					disableBeforeToday
					formatDate={formatDate}
					rules={{ valueAsDate: false }}
				/>
			</Table.Cell>
		</>
	);
}
