import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import { invoiceTypes } from "astrid-firestore/src/api/invoices/invoiceTypes";

import DeleteOrderModal from "./DeleteOrderModal";

function showInactivate({ invoices, order }) {
	return !order?.inactivated && invoices?.length && invoices?.[0].type === invoiceTypes.CREDIT;
}

function DeleteOrInactivateButton({ invoices, order, onClick }) {
	const { t } = useTranslation();

	const showDelete = !invoices?.length;
	const showInactive = showInactivate({ order, invoices });

	if (showDelete) {
		return <Button onClick={onClick}>{t("delete", "Delete")}</Button>;
	}
	if (showInactive) {
		return <Button onClick={onClick}>{t("inactivate", "Inactivate")}</Button>;
	}
	return null;
}

export default function DeleteOrderAction(props) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<DeleteOrInactivateButton order={props.order} invoices={props.invoices} onClick={() => setOpen(true)} />

			{open && (
				<DeleteOrderModal
					{...props}
					shouldInactive={showInactivate({ invoices: props.invoices })}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
}
