import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import CreateDistributionChannelForm from "./CreateDistributionChannelForm";

export default function CreateDistributionChannelButton() {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button basic secondary onClick={() => setOpen(true)}>
				{t("createDistributionChannel", "Create distribution channel")}
			</Button>

			{open && <CreateDistributionChannelForm onClose={() => setOpen(false)} />}
		</>
	);
}
