import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

export default function AddVoiceAction({ production }) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const numberOfVoices = Object.keys(production.voices || {}).length || 1;

	return (
		<Button
			loading={loading}
			content={t("addVoice", "Add voice")}
			onClick={async () => {
				setLoading(true);
				await updateDocument(production.ref, {
					"voices.1": production.voices?.["1"],
					[`voices.${numberOfVoices + 1}`]: {},
				});
				setLoading(false);
			}}
		/>
	);
}
