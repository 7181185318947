import React from "react";

import Form from "../../../../forms/components/Form/Form";

import offerDefaultValues from "../../../constants/offerDefaultValues";

const today = new Date();

const enableFrom = new Date(
	today.getFullYear(),
	today.getMonth(),
	today.getDate() + offerDefaultValues.EXPIRES_IN_MIN_DAYS,
);

export default function ExpiresAtInput(props) {
	return <Form.DateInput enableFrom={enableFrom} {...props} />;
}
