import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminTranslatorHeader from "./components/AdminTranslatorHeader";

export default function AdminTranslatorView(props) {
	return (
		<AdminPageView {...props}>
			<AdminTranslatorHeader />
		</AdminPageView>
	);
}
