import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

export default function ReaderSexAndBirthday({ reader }) {
	const { t } = useTranslation();

	return (
		<Label basic>
			{t(reader.sex)}
			{reader.birthday && (
				<Label.Detail style={{ marginLeft: 5 }}>
					{moment().diff(reader.birthday, "years")} {t("years")}
				</Label.Detail>
			)}
		</Label>
	);
}
