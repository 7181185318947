import todoTypes from "../../constants/todoTypes";
import { getDocumentData } from "../../helpers/index";
import runTransaction from "../../helpers/runTransaction";

import createTodoResolvedData from "../todos/data/todos/createTodoResolvedData";
import createTodoRef from "../todos/utils/createTodoRef";

export default function cancelSession(firebase, { session, cancelReason, user }) {
	const db = firebase.firestore();
	const sessionRef = db.collection("sessions").doc(session.id);

	return runTransaction(db, [sessionRef], async (transaction) => {
		if (session.productionPriceRef) {
			transaction.delete(session.productionPriceRef);
		}
		transaction.update(sessionRef, { status: "cancelled", cancelReason });

		const sessionMissingRecorderTodoRef = createTodoRef(db, session.id, todoTypes.SESSION_MISSING_RECORDER);
		const sessionMissingRecorder = await getDocumentData(sessionMissingRecorderTodoRef);
		if (sessionMissingRecorder) {
			transaction.update(sessionMissingRecorderTodoRef, {
				resolved: createTodoResolvedData(firebase, { user }),
			});
		}
	});
}
