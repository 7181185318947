import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import agreementParts from "astrid-firestore/src/api/agreements/constants/agreementParts";

export default function useAgreementParts() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[agreementParts.USER]: t("agreementPartUser", "Consultant agreement"),
			[agreementParts.NARRATOR]: t("agreementPartNarrator", "Narrator agreement"),
			[agreementParts.PUBLISHER]: t("agreementPartPublisher", "Publisher agreement"),
			[agreementParts.SUBCONTRACTOR]: t("agreementPartSubcontractor", "Subcontractor agreement"),
		}),
		[t],
	);
}
