import { useTranslation } from "react-i18next";

import UploadButton from "../../../../components/UploadButton";
import useFileUpload from "../../../ui/hooks/useFileUpload";

import useFormField from "../../hooks/useFormField";

export default function FormUploadButton({ path, bucketName, text, ...props }) {
	const { t } = useTranslation();
	const { field } = useFormField(props);
	const { onChange } = field;

	const [{ loading: uploading }, onUploadFile] = useFileUpload({
		bucketName,
		getDestination: (file) => `${path}/${Date.now()}.${file.name.split(".").pop()}`,
		onSuccess: (fileData) => onChange(null, { value: fileData }),
	});

	return (
		<UploadButton
			loading={uploading}
			onUpload={onUploadFile}
			text={text || t("selectFile", "Select file")}
			style={{ whiteSpace: "nowrap" }}
		/>
	);
}
