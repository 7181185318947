import { Icon } from "semantic-ui-react";

export default function trashbins(onClick) {
	return (hooks) => {
		hooks.visibleColumns.push((columns) => [
			{
				id: "selection",
				groupByBoundary: true,
				Cell: ({ row }) => <Icon disabled={row.original.disabled} onClick={() => onClick(row)} />,
			},
			...columns,
		]);
	};
}
