import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useAgreementsPriceOptions from "../../../../agreements/hooks/useAgreementsPriceOptions";
import Form from "../../../../forms/components/Form/Form";
import Select from "../../../../ui/components/Select/Select";

import PriceForm from "./PriceForm";

export default function ProducerRevenueForm({ disableAgreementPrices = false }) {
	const { t } = useTranslation();
	const { watch, setValue } = Form.useFormContext();
	const [revenue, production] = watch(["revenue", "production"]);

	const publisherPriceOptions = useAgreementsPriceOptions({
		part: "publisher",
		firstPartyId: production?.producer,
		secondPartyId: production?.publisher,
	});

	const priceOptions = useMemo(
		() => [{ key: "custom", value: "custom", text: t("customPrice", "Custom price") }, ...publisherPriceOptions],
		[publisherPriceOptions, t],
	);

	return (
		<>
			{!disableAgreementPrices && (
				<Form.Field name="revenue.publisherPriceId" defaultValue="custom">
					<Select
						onChangeOption={(option) => setValue("revenue.publisherPrice", option?.data)}
						label={t("price", "Price")}
						options={priceOptions}
					/>
				</Form.Field>
			)}

			{!revenue?.publisherPrice?.price && <PriceForm part="publisher" prefix="revenue" showVAT />}
		</>
	);
}
