import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import api from "../../../../api";
import Popup from "../../../ui/components/Popup/Popup";
import useLoadingFunction from "../../../ui/hooks/useLoadingFunction";

export default function ArticleToggleSyncTitleDataIcon({ data, disabled = false }) {
	const { t } = useTranslation();

	const [onToggle, loading, error] = useLoadingFunction(async (e) => {
		e.stopPropagation();

		return api.articles.updateTitleData({ ...data, sync: !data.sync });
	});

	return (
		<Popup
			content={
				error
					? t("errorMessageHeader", "Something went wrong")
					: data.sync
					? t("syncTitleData", "Syncing title data")
					: t("useArticleData", "Using article data")
			}
			trigger={
				<Icon
					color={error && "red"}
					name={error ? "warning sign" : loading ? "spinner" : data.sync ? "lock" : "lock open"}
					loading={loading}
					disabled={disabled || loading || !data.title?.ref}
					link={!disabled && !loading}
					onClick={!disabled && onToggle}
				/>
			}
		/>
	);
}
