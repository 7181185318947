import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import CreateArtifactForm from "./CreateArtifactForm";

export default function CreateArtifactButton({ defaultName, article, organization, ...props }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button basic secondary onClick={() => setOpen(true)} {...props}>
				{t("createArtifact", "Create artifact")}
			</Button>

			{open && (
				<CreateArtifactForm
					onClose={() => setOpen(false)}
					article={article}
					organization={organization}
					defaultName={defaultName}
				/>
			)}
		</>
	);
}
