import { useMemo } from "react";

import { useProfile } from "../../../../authentication/state/profile";

export default function useAlertProductions() {
	const { id: profileId, alertProductions } = useProfile();

	return useMemo(() => {
		return Object.entries(alertProductions || {})
			.filter(([id, alert]) => alert?.time?.toDate() < new Date())
			.map(([id, alert]) => {
				return {
					...alert,
					id,
					profileId,
				};
			});
	}, [alertProductions, profileId]);
}
