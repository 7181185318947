import React, { useContext, useState } from "react";
import { Button, Modal } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import cancelSession from "astrid-firestore/src/api/sessions/cancelSession";

import Form from "../../../forms/components/Form/Form";
import useForm from "../../../forms/hooks/useForm";

import notifyCancelSession from "../../helpers/notifyCancelSession";

import SessionContext from "../SessionContext";

export default function CancelSessionModal() {
	const [loading, setLoading] = useState(false);
	const { t, session, production, profile, onClose } = useContext(SessionContext);

	const form = useForm({
		mode: "onSubmit",
		defaultValues: {
			cancelReason: "",
			notify: true,
		},
	});

	const {
		handleSubmit,
		formState: { errors, isValid },
	} = form;

	const submit = handleSubmit(async (formData) => {
		setLoading(true);
		try {
			await cancelSession(firebase, { session, cancelReason: formData.cancelReason, user: profile });
			if (formData.notify) {
				await notifyCancelSession({
					cancelReason: formData.cancelReason,
					session,
					production,
					profile,
					t,
				});
			}
			onClose();
		} catch (error) {
			setLoading(false);
		}
	});

	return (
		<>
			<Modal.Header>{t("sureWantToCancel")}</Modal.Header>
			<Modal.Content>
				<Form form={form}>
					<Form.TextArea
						name="cancelReason"
						label={t("reasonForCancel")}
						error={!!errors?.["cancelReason"]}
					/>
					<Form.Checkbox name="notify" label={t("affectedWillBeNotified")} rules={{ required: false }} />
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button color="red" loading={loading} disabled={loading || !isValid} onClick={submit}>
					{t("confirm", "Confirm")}
				</Button>
			</Modal.Actions>
		</>
	);
}
