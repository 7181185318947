import { Accordion } from "semantic-ui-react";

import OrderDetails from "../../OrderDetails/OrderDetails";

import OrderTitle from "./OrderTitle";

export default function Order({ active, order, ...props }) {
	return (
		<>
			<OrderTitle active={active} order={order} {...props} />

			{active && (
				<Accordion.Content active>
					<OrderDetails order={order} />
				</Accordion.Content>
			)}
		</>
	);
}
