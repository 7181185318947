import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { getDocumentData } from "astrid-firestore/src/helpers";

import AlertModal from "../../../ui/components/AlertModal/AlertModal";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import useSoftDelete from "../../../ui/hooks/useSoftDelete";

import ViewAgreementDropdownItem from "../../../agreements/components/AgreementDropdownMenu/items/ViewAgreementDropdownItem";

import EditCostDropdownItem from "./items/EditCostDropdownItem";
import FinalizeCostDropdownItem from "./items/FinalizeCostDropdownItem";

export default function OpenCostDropdownMenu({ openCost, production, isExpanded, expandedProps }) {
	const { t } = useTranslation();

	const softDelete = useSoftDelete();

	const { costs, price } = openCost;

	const [openModal, setOpenModal] = useState(false);

	return (
		<>
			<DropdownMenu>
				{({ setLoading }) => (
					<>
						<ViewAgreementDropdownItem agreement={openCost.agreement} />
						<FinalizeCostDropdownItem openCost={openCost} setLoading={setLoading} />
						<Dropdown.Item {...expandedProps}>
							{isExpanded ? t("hideSubCosts", "Hide sub costs") : t("showSubCosts", "Show sub costs")}
						</Dropdown.Item>
						<EditCostDropdownItem
							openCost={openCost}
							production={production}
							disabled={openCost.price?.type !== "custom"}
						/>
						<Dropdown.Item
							disabled={costs?.length > 1 || !price?.ref || price?.disabled}
							text={t("remove", "Remove")}
							onClick={async () => {
								setLoading(true);

								try {
									if (openCost.session?.ref) {
										const session = await getDocumentData(openCost.session.ref);

										if (session?.status === "booked") {
											setOpenModal(true);

											setLoading(false);

											return;
										}
									}

									await softDelete(openCost.price.ref);
								} catch (error) {
									window.alert(t("softDeleteFailed", "Failed to delete resource!"));
									console.error(error);
								}

								setLoading(false);
							}}
						/>
					</>
				)}
			</DropdownMenu>

			{openModal && (
				<AlertModal
					onClose={() => setOpenModal(false)}
					text={t("cantDeleteUnfinishedSessionCost", "You can't delete costs that has unfinished sessions")}
				/>
			)}
		</>
	);
}
