import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { MetaData } from "../../../types/MetaData";
import {
	array,
	boolean,
	date,
	enumValues,
	intMinMax,
	isbn,
	nullable,
	object,
	record,
	string,
} from "../../../types/_types";

import { ArtifactRef } from "../../artifacts/types/ArtifactRef";
import { OrganizationRef } from "../../organizations/types/Organization";
import { TitleRef } from "../../titles/types/Title";

import articleTypes from "../constants/articleTypes";

import { ArticleBundleRef } from "./ArticleBundleRef";

export const articleType = enumValues(articleTypes);

export const articleDistribution = object({
	announcementDate: nullable(date()),
	releaseDate: nullable(date()),
	takedownDate: nullable(date()),
})
	.refine(
		({ announcementDate, releaseDate }) => {
			return !announcementDate || !releaseDate || announcementDate <= releaseDate;
		},
		{
			message: "Must be after announcement date",
			path: ["releaseDate"],
		},
	)
	.refine(
		({ releaseDate, takedownDate }) => {
			return !releaseDate || !takedownDate || releaseDate <= takedownDate;
		},
		{
			message: "Must be after release date",
			path: ["takedownDate"],
		},
	)
	.default({
		announcementDate: null,
		releaseDate: null,
		takedownDate: null,
	});

export const articleChannels = record(
	string(3),
	object({
		distribution: articleDistribution,
	}),
);

export const Article = FirestoreDocument.extend({
	bundle: nullable(ArticleBundleRef),
	artifact: nullable(ArtifactRef),
	isbn: nullable(isbn()),
	narrator: nullable(string()),
	narrators: array(string()),
	pages: nullable(intMinMax()),
	duration: nullable(string()),
	producer: nullable(OrganizationRef),
	publisher: OrganizationRef,
	sync: boolean(),
	title: nullable(TitleRef),
	type: articleType,
	distribution: nullable(articleDistribution),
	channels: nullable(articleChannels),
})
	.merge(FirestoreLifecycle)
	.merge(MetaData);
