/*
languages
languageSkill
countries
currencies
prodStatus
defaultHours
colors
*/

export const languageSkill = [
	"Viss kunskap om uttal",
	"Grundläggande förståelse",
	"Goda kunskaper, kan föra samtal",
	"Flytande",
	"Modersmål",
];

export const currencies = {
	SEK: { short: "SEK", long: "Svensk krona", preset: ["sv"] },
	NOK: { short: "NOK", long: "Norsk krone", preset: ["no", "nb", "nn"] },
	DKK: { short: "DKK", long: "Dansk krone", preset: ["dk"] },
	ISK: { short: "ISK", long: "Isländsk króna", preset: ["is"] },
	EUR: { short: "Euro", long: "Euro", preset: ["fi", "nl"] },
	GBP: { short: "Pound", long: "Pound sterling" },
	USD: { short: "USD", long: "US Dollars" },
	RUB: { short: "RUB", long: "Rysk rubel" },
	BRL: { short: "BRL", long: "Brasiliansk real" },
	THB: { short: "THB", long: "Thai baht", preset: ["th"] },
};

export const prodStatus = {
	draft: { text: "Utkast", icon: "file outline", color: "grey" },
	offer: { text: "Förfrågan", icon: "question", color: "yellow" },
	planning: { text: "Planeras", icon: "tasks", color: "orange" },
	accepted: { text: "Accepterat", icon: "thumbs up", color: "orange" },
	booked: { text: "Bokad", icon: "calendar alternate", color: "teal" }, // fake, actually production
	production: { text: "Produceras", icon: "microphone", color: "teal" },
	mastering: { text: "Mastras", icon: "law", color: "teal" }, // fake, actually Production
	done: { text: "Slutförd", icon: "truck", color: "green" },
};

// MEMO: make custom per producer?
export const defaultHours = {
	day1: { in: "08:00", out: "22:00" },
	day2: { in: "08:00", out: "22:00" },
	day3: { in: "08:00", out: "22:00" },
	day4: { in: "08:00", out: "22:00" },
	day5: { in: "08:00", out: "22:00" },
	day6: { in: "08:00", out: "22:00" },
	day7: { in: "08:00", out: "22:00" },
};

export const colors = [
	"#f25a5a",
	"#5af2f2",
	"#a6f25a",
	"#a65af2",
	"#f2cc5a",
	"#5a80f2",
	"#5af280",
	"#f25acc",
	"#f2805a",
	"#5accf2",
	"#80f25a",
	"#cc5af2",
	"#f2f25a",
	"#5af2a6",
	"#5a5af2",
	"#f25aa6",
	"#f2a65a",
	"#5aa6f2",
	"#5af25a",
	"#f25af2",
	"#ccf25a",
	"#805af2",
	"#5af2cc",
	"#f25a80",
];
