import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import TeamMemberCreated from "./TeamMemberCreated";

export default function TeamMemberCreatedLabel({ member }) {
	const { t } = useTranslation();

	return (
		<Label basic>
			{t("added", "Added")}

			<Label.Detail style={{ marginLeft: 5 }}>
				<TeamMemberCreated member={member} />
			</Label.Detail>
		</Label>
	);
}
