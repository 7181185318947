import firebase from "firebase/app";
import "firebase/storage";

import arrayChunk from "astrid-helpers/src/arrayChunk";
import { isLive } from "astrid-helpers/src/env";

import "./app";
import db from "./db";

const storage = firebase.storage();

export function upload(bucket, path, data, meta = {}) {
	const ref = storage.refFromURL(`gs://${isLive ? "" : "stage-"}${bucket}`);

	return ref.child(path).put(data, meta);
}

export async function getFile(files) {
	// put single ID in array
	const multiple = Array.isArray(files);
	if (!multiple) files = [files];

	const snaps = await Promise.all(
		arrayChunk(files, 10).map((chunk) =>
			db.collection("files").where(firebase.firestore.FieldPath.documentId(), "in", chunk).get(),
		),
	);

	const result = {};
	snaps.forEach((snap) => {
		snap.docs.forEach((doc) => {
			result[doc.id] = doc.data();
		});
	});

	// return map or single object
	return multiple ? result : result[files[0]];
}

export async function addFile({
	updateId,
	type: uploadType,
	bucket,
	path,
	file,
	name,
	userId,
	history: existingHistory,
	historyEntry,
	onProgress,
	onError,
}) {
	// optionally override file name
	const fileName = name || file.name;

	// get suffix from original file name
	const suffix = file.name.includes(".") ? "." + file.name.split(".").pop() : "";

	// start upload
	const storageRef = firebase.storage().refFromURL("gs://" + bucket);
	const uploadTask = storageRef
		.child(path)
		.put(file, { contentDisposition: `inline; filename="${fileName}${suffix}"` });

	return new Promise((resolve) => {
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				if (onProgress) onProgress(progress + "%");
			},
			(error) => {
				console.error(error);
				if (onError) onError(error);
			},
			async () => {
				// success, store in db
				const { size, type } = file;
				const url = await uploadTask.snapshot.ref.getDownloadURL();

				// store file data
				const data = {
					type: uploadType,
					file: {
						modified: firebase.firestore.FieldValue.serverTimestamp(),
						bucket,
						path,
						url,
						size,
						name: fileName,
						type,
					},
				};

				// history
				if (historyEntry !== false) {
					const history = existingHistory || [];
					const historyData = {
						// default history
						action: "created",
						user: userId,
						time: new Date(), // serverTimestamp() doesn't work in arrays, trust the client...

						// override with supplied history
						...historyEntry,
					};
					history.push(historyData);

					data.history = firebase.firestore.FieldValue.arrayUnion(...history);
				}

				// store
				const docRef = updateId ? db.collection("files").doc(updateId) : db.collection("files").doc();
				await docRef.set(data, { merge: true });
				const id = docRef.id;

				// resolve with file id
				resolve(id);
			},
		);
	});
}

export default storage;
