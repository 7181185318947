import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import AdminForm from "../../../components/AdminForm/AdminForm";

function CompressionField({ label, name, min, max, step, watch }) {
	const value = watch(name);

	return (
		<>
			<Form.Input
				name={name}
				label={
					<label>
						{label}{" "}
						<Label size="small" style={{ marginLeft: 5 }}>
							{value}
						</Label>
					</label>
				}
				type="range"
				min={min}
				max={max}
				step={step}
				style={{ width: "50%" }}
			/>
		</>
	);
}

export default function StudioCompression({ organization }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		defaultValues: {
			pregain: organization.studioSettings?.compression?.pregain || 1,
			threshold: organization.studioSettings?.compression?.threshold || -24,
			ratio: organization.studioSettings?.compression?.ratio || 20,
			attack: organization.studioSettings?.compression?.attack || 0.003,
			release: organization.studioSettings?.compression?.release || 0.25,
			knee: organization.studioSettings?.compression?.knee || 30,
			gain: organization.studioSettings?.compression?.gain || 1,
		},
		onSubmit: (compression) => {
			return updateDocument(organization.ref, {
				"studioSettings.compression": compression,
			});
		},
	});

	const fields = [
		{
			name: "pregain",
			label: t("pregain", "Pre gain"),
			min: 0,
			max: 4,
			step: 0.1,
		},
		{
			name: "threshold",
			label: t("threshold", "Threshold"),
			min: -100,
			max: 0,
			step: 1,
		},
		{
			name: "ratio",
			label: t("ratio", "Ratio"),
			min: 1,
			max: 20,
			step: 0.1,
		},
		{
			name: "attack",
			label: t("attack", "Attack"),
			min: 0,
			max: 1,
			step: 0.001,
		},
		{
			name: "release",
			label: t("release", "Release"),
			min: 0,
			max: 1,
			step: 0.001,
		},
		{
			name: "knee",
			label: t("knee", "Knee"),
			min: 0,
			max: 40,
			step: 0.1,
		},
		{
			name: "gain",
			label: t("gain", "Post gain"),
			min: 0,
			max: 4,
			step: 0.1,
		},
	];

	return (
		<AdminForm header={t("studioCompression", "Studio compression")} {...form}>
			{fields.map((field) => (
				<CompressionField key={field.name} watch={form.watch} {...field} />
			))}
		</AdminForm>
	);
}
