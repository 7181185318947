import { useMemo } from "react";

import { db } from "astrid-firebase";
import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

export default function useProductionRequestsQuery({ orgId }) {
	return useMemo(() => {
		if (orgId) {
			return db
				.collection("productions")
				.where("deleted", "==", false)
				.where("producer", "==", orgId)
				.where("status", "in", [productionStatuses.OFFER, productionStatuses.PLANNING]);
		}
		return false;
	}, [orgId]);
}
