import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import isDenied from "astrid-firestore/src/api/approval/isDenied";

import useApprovalActions from "../../../approval/hooks/useApprovalActions";
import useHasRight from "../../../authorization/hooks/useHasRight";

export default function DropdownItemDeny({ data, right, setLoading }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const { deny } = useApprovalActions(data.ref);

	if (right && !hasRight(right)) {
		return null;
	}

	return (
		<Dropdown.Item
			text={t("deny", "Deny")}
			disabled={isDenied(data)}
			loading
			onClick={async () => {
				setLoading?.(true);

				try {
					await deny();
				} catch (error) {
					console.error(error);
				}

				setLoading?.(false);
			}}
		/>
	);
}
