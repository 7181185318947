import anchorme from "anchorme";
import moment from "moment";
import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, Icon, Message, Popup, Statistic } from "semantic-ui-react";

import { durationToSec, toDate } from "astrid-web/src/helpers/fnc";
import { prodStatus } from "astrid-web/src/helpers/lists";

import getStudioHours from "../../../features/productions/utils/getStudioHours";

import ProgressEdit from "../ProgressEdit";
import ProgressRecording from "../ProgressRecording";

const ProductionStatus = ({
	isProducer,
	isPublisher,
	isReader,
	user,
	producer,
	publisher,
	production,
	handleChange,
}) => {
	const isManager = (isProducer && isProducer?.includes("producerAdmin")) || production?.manager?.includes(user.uid);
	const isSubcontractorProduction = production?.productionType === "subcontract";
	const clickClass = isManager ? " pointer" : "";
	const { t } = useTranslation();

	const { remainingStudioHours } = getStudioHours(production);

	const setStatus = (status) => {
		if (window.confirm(t("changeProdStatus"))) {
			handleChange(null, { name: "status", value: status });
		}
	};

	const estimatedDuration = production.estimatedDuration?.slice(0, -3);

	return (
		<>
			{production.status === "draft" && (
				<Message icon floating warning>
					<Icon name="warning sign" color="orange" />
					<Message.Content>
						<b>{t("draft")} </b>
						{t("notShownYet")}
					</Message.Content>
				</Message>
			)}

			{production.status === "done" && (
				<Message icon floating style={{ background: "white" }}>
					<Icon name="paper plane outline" color="green" />
					<Message.Content>
						<div className="flex-stack" style={{ alignItems: "center" }}>
							<div style={{ minWidth: "50%" }}>
								<Message.Header>{t("prodDelivered")}</Message.Header>
								{production.deliveryDateActual &&
									// producers can always see exact date. publishers can see starting from april 9th 2020.
									(isProducer || toDate(production.deliveryDateActual) > new Date("2020-04-09")) && (
										<div>
											{t("wasFinalized")}{" "}
											{moment(toDate(production.deliveryDateActual)).format("YYYY-MM-DD HH:mm")}
										</div>
									)}
								{production.deliveryDuration && t("duration") + ": " + production.deliveryDuration}
								{production.deliveryMessage &&
									(!production.deliveryDuration ||
										durationToSec(production.deliveryDuration) !==
											durationToSec(production.deliveryMessage)) && (
										<div
											className="line-broken"
											dangerouslySetInnerHTML={{
												__html: anchorme(production.deliveryMessage, {
													truncate: [26, 15],
													attributes: [
														function (urlObj) {
															if (urlObj.protocol !== "mailto:")
																return { name: "target", value: "blank" };
														},
													],
												}),
											}}
										/>
									)}
							</div>
						</div>
					</Message.Content>
				</Message>
			)}

			<section className={"production-status"}>
				<div
					className={(production.status === "offer" ? "current" : "") + clickClass}
					disabled={production.status === "draft"}
					onClick={
						isManager
							? () => {
									setStatus("offer");
							  }
							: null
					}
				>
					<Popup
						size="small"
						inverted
						content={
							`${publisher && publisher.name} ` + t("hasSentInquiryTo") + ` ${producer && producer.name}`
						}
						trigger={
							<h4>
								<Icon name={prodStatus.offer.icon} />
								{/* {prodStatus.offer.text} */}
								{t("offer")}
							</h4>
						}
					/>
				</div>

				<div
					className={(production.status === "planning" ? "current" : "") + clickClass}
					disabled={!production.status || ["draft", "offer"].includes(production.status)}
					onClick={
						isManager
							? () => {
									setStatus("planning");
							  }
							: null
					}
				>
					<Popup
						size="small"
						inverted
						content={`${producer && producer.name} ` + t("willPlanProdAndRead")}
						trigger={
							<h4>
								<Icon name={prodStatus.planning.icon} />
								{/* {prodStatus.planning.text} */}
								{t("planning")}
							</h4>
						}
					/>
				</div>

				<div
					className={(production.status === "accepted" ? "current" : "") + clickClass}
					disabled={!production.status || ["draft", "offer", "planning"].includes(production.status)}
					onClick={
						isManager
							? () => {
									setStatus("accepted");
							  }
							: null
					}
				>
					<Popup
						size="small"
						inverted
						content={t(production.subcontractor ? "subcontractorHasAcceptedProd" : "readerHasAcceptedProd")}
						trigger={
							<h4>
								<Icon name={prodStatus.accepted.icon} />
								{/* {prodStatus.accepted.text} */}
								{t("accepted")}
							</h4>
						}
					/>
				</div>

				{production.productionType !== "subcontract" && (
					<div
						className={
							production.status === "production" &&
							!production.stats &&
							!production.proofParts &&
							!production.progress
								? "current"
								: ""
						}
						disabled={
							!production.status || ["draft", "offer", "planning", "accepted"].includes(production.status)
						}
						onClick={
							isManager
								? () => {
										setStatus("production");
								  }
								: null
						}
					>
						<Popup
							size="small"
							inverted
							content={t("recTimesBooked")}
							trigger={
								<h4>
									<Icon name={prodStatus.booked.icon} />
									{t("booked")}
								</h4>
							}
						/>
					</div>
				)}
				<div
					className={
						(production.status === "production" &&
						(production.productionType === "subcontract" ||
							(!!(production.stats || production.proofParts || production.progress) &&
								!production.polishDone))
							? "current"
							: "") + clickClass
					}
					disabled={
						!production.status ||
						["offer", "accepted", "planning"].includes(production.status) ||
						!(
							production.stats ||
							production.proofParts ||
							production.progress ||
							production.productionType === "subcontract"
						)
					}
					onClick={
						isManager
							? () => {
									setStatus("production");
							  }
							: null
					}
				>
					<Popup
						size="small"
						inverted
						content={t("recEditAndProofing")}
						trigger={
							<h4>
								<Icon name={prodStatus.production.icon} />
								{/* {prodStatus.production.text} */}
								{t("producing")}
							</h4>
						}
					/>
				</div>
				{production.productionType !== "subcontract" && (
					<div
						className={
							production.status === "production" &&
							!!(production.stats || production.proofParts) &&
							production.polishDone
								? "current"
								: ""
						}
						disabled={
							!production.status ||
							["offer", "accepted", "planning"].includes(production.status) ||
							!(production.stats || production.proofParts) ||
							(!production.polishDone && production.status === "production")
						}
					>
						<Popup
							size="small"
							inverted
							content={t("masteringInProgress")}
							trigger={
								<h4>
									<Icon name={prodStatus.mastering.icon} />
									{/* {prodStatus.mastering.text} */}
									{t("mastering")}
								</h4>
							}
						/>
					</div>
				)}

				<div
					className={(production.status === "done" ? "current" : "") + clickClass}
					disabled={production.status !== "done"}
					onClick={
						isManager
							? () => {
									setStatus("done");
							  }
							: null
					}
				>
					<Popup
						size="small"
						inverted
						content={t("productionFinished")}
						trigger={
							<h4>
								<Icon name={prodStatus.done.icon} />
								{/* {prodStatus.done.text} */}
								{t("completed")}
							</h4>
						}
					/>

					{/* {production.scheduled && (
							<p>
								<Icon name="clock" /> Leveransen är schemalagd.
							</p>
						)} */}
				</div>
			</section>

			<div style={{ textAlign: "center", minHeight: "3em" }} className="production-statistics clear">
				{isPublisher ? (
					<div style={{ marginBottom: "1em" }}>
						{production.stats && ["production", "done"].includes(production.status) && (
							<>
								<ProgressRecording label={t("recPages")} production={production} />

								{production.stats.cubase && (
									<Statistic horizontal size="mini" color="orange">
										<Statistic.Value>
											<Icon name="play" />
											&nbsp;
											{estimatedDuration}
										</Statistic.Value>
										<Statistic.Label>{t("calcDuration")}</Statistic.Label>
									</Statistic>
								)}
							</>
						)}

						{(isManager || isPublisher) && (
							<ProductionPrio production={production} handleChange={handleChange} />
						)}
					</div>
				) : (
					<>
						{(production.stats || production.progress) && (
							<>
								{!(isReader && production.status === "done") && production.stats && (
									<ProgressRecording production={production} />
								)}
								{!isReader &&
									(!production.productionType || production.productionType === "subcontract") && (
										<ProgressEdit production={production} />
									)}
								{production.stats?.tempo && production.status !== "done" && (
									<>
										<Statistic horizontal size="mini" color="orange">
											<Statistic.Value>
												<Icon name="play" />
												&nbsp;
												{estimatedDuration}
											</Statistic.Value>
											<Statistic.Label>{t("calcDuration")}</Statistic.Label>
										</Statistic>
										<Statistic horizontal size="mini" color="orange">
											<Statistic.Value>
												<Icon name="clock" />
												&nbsp;
												{remainingStudioHours}
											</Statistic.Value>
											<Statistic.Label>{t("studioTimeLeft")}</Statistic.Label>
										</Statistic>
									</>
								)}
								{!isReader && production.stats && (
									<>
										{production.stats.tempo && (
											<Statistic horizontal size="mini" color="teal">
												<Statistic.Value>
													<Icon name="file text" />
													&nbsp;
													{Math.round(production.stats.tempo.average * 100) / 100}
												</Statistic.Value>
												<Statistic.Label>{t("recTempo")}</Statistic.Label>
											</Statistic>
										)}
										{production.stats.ratio && (
											<Statistic horizontal size="mini" color="teal">
												<Statistic.Value>
													<Icon name="microphone" />
													&nbsp;
													{Math.round(production.stats.ratio.average)} %
												</Statistic.Value>
												<Statistic.Label>{t("recRatio")}</Statistic.Label>
											</Statistic>
										)}
										{production.stats.loss && (
											<Statistic horizontal size="mini" color="teal">
												<Statistic.Value>
													<Icon name="trash alternate" />
													&nbsp;
													{Math.round(production.stats.loss.average)} %
												</Statistic.Value>
												<Statistic.Label>{t("recLoss")}</Statistic.Label>
											</Statistic>
										)}
									</>
								)}
							</>
						)}
						{/* {isManager && <ProductionDelegation production={production} producer={producer} />} */}
						{isSubcontractorProduction && !isPublisher && (
							<div style={{ float: "right" }}>
								{production.subcontractorStatus === "declined" ? (
									<>
										<Icon name="exclamation" color="red" />
										<span style={{ opacity: "80%" }}>{t("subcontractorProductionDeclined")}</span>
									</>
								) : (
									<>
										<Icon name="wrench" color="green" />
										<span style={{ opacity: "80%" }}>
											{t("subcontractorProduction")}
											{production?.proofer?.includes("NONE")
												? ", " + t("subcontractorProductionNoProofer")
												: ""}
										</span>
									</>
								)}
							</div>
						)}
						{!isSubcontractorProduction && (
							<ProductionPrio production={production} handleChange={handleChange} />
						)}
					</>
				)}
			</div>
		</>
	);
};

export default withTranslation()(ProductionStatus);

const ProductionPrio = ({ production, handleChange }) => {
	const { t } = useTranslation();

	return (
		<Button.Group
			size="mini"
			onClick={(e) => handleChange(null, { name: "priority", value: e.target.value })}
			className="production-prio"
		>
			<Button
				color="yellow"
				value="low"
				content={t("low")}
				style={production.priority !== "low" ? { opacity: 0.2 } : null}
			/>
			<Button color="orange" content={t("average")} style={production.priority ? { opacity: 0.2 } : null} />
			<Button
				color="red"
				value="high"
				content={t("high")}
				style={production.priority !== "high" ? { opacity: 0.2 } : null}
			/>
		</Button.Group>
	);
};
