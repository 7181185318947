import createPrice from "astrid-firestore/src/api/prices/createPrice";
import runBatch from "astrid-firestore/src/helpers/runBatch";

export default function createPrices(firebase, { organization, prices, currency, languages }) {
	const db = firebase.firestore();

	return runBatch(db, (batch) => {
		for (const priceData of prices) {
			const price = createPrice(db, {
				...priceData,
				currency,
				languages,
				producer: organization,
			});

			batch.set(price.ref, price);
		}
	});
}
