import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";

import recorderIsPartOfTeam from "./recorderIsPartOfTeam";
import sessionIsAnyRecorderInTeam from "./sessionsIsAnyRecorderInTeam";

export default function sessionIsAvailableForRecorder({ production, session, sessionRoles, user }) {
	if (sessionRoles.isRecorder) {
		if (session.recorder === sessionOfferTypes.OPEN_POSITION_RECORDER || session.recorder === user.id) {
			return true;
		} else if (
			sessionIsAnyRecorderInTeam(session.recorder) &&
			recorderIsPartOfTeam({
				production,
				user,
			})
		) {
			return true;
		}
		return false;
	}
	return false;
}
