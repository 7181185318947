import { useMemo } from "react";

import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import useStudiosQuery from "./useStudiosQuery";

export default function useStudioGroupTagOptions(organizationId, groupTags = []) {
	const [studios = []] = useCollectionDataOnce(useStudiosQuery({ organizationId }));

	return useMemo(
		() =>
			[
				...new Set(
					studios.reduce(
						(prev, curr) => [...prev, ...(curr.groupTags || []), ...(curr.group ? [curr.group] : [])],
						groupTags,
					),
				),
			].map((group) => ({
				key: group,
				value: group,
				text: group,
			})),
		[groupTags, studios],
	);
}
