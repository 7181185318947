import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Header, Segment } from "semantic-ui-react";

import { getDeliveryObstacles, getDistOptions } from "astrid-web/src/components/production/fnc";
import ScheduledObstacles from "astrid-web/src/components/scheduled/ScheduledObstacles";
import ScheduledPlan from "astrid-web/src/components/scheduled/ScheduledPlan";

const ProductionScheduled = ({ production, publisher, distributors }) => {
	const { t } = useTranslation();
	const distOptions = getDistOptions({ production, publisher });
	const obstacles = getDeliveryObstacles({ production, distOptions });

	return (
		<Segment>
			<Header as="h4" icon="truck" content="Schemalagd leverans" />
			<b>{t("deliverySchedule")} :</b>
			{!publisher ? (
				<em>{t("loading")}</em>
			) : (
				<ScheduledPlan production={production} distOptions={distOptions} distributorSettings={distributors} />
			)}

			{!!obstacles?.length && (
				<>
					<Divider />
					<p>
						<b>{t("isMissingFromPubl")} :</b>
					</p>
					<ScheduledObstacles obstacles={obstacles} />
				</>
			)}
		</Segment>
	);
};

export default ProductionScheduled;
