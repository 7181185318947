export default {
	name: "Fabel",
	format: "wav",
	connection: "ftp",
	meta: false,
	audio: {
		rename: "SEQ.SUFFIX",
	},
	cover: true,
	email: ["delivery"],
	disableArticles: ["cd", "mp3cd"],
};
