import useForm from "./useForm";
import useFormSubmit from "./useFormSubmit";

export default function useSimpleForm({
	onSubmit: _onSubmit,
	onSuccess,
	onError,
	onSubmitError,
	clearOnSuccess,
	...props
}) {
	const form = useForm(props);

	const [onSubmit, loading, error] = useFormSubmit(form, _onSubmit, {
		onSuccess,
		onError,
		onSubmitError,
		clearOnSuccess,
	});

	return { form, ...form, loading, error, onSubmit };
}
