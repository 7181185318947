import { useCallback } from "react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

import { useProfile } from "../../../authentication/state/profile";
import useOrganizationsQuery from "../../../organizations/hooks/useOrganizationsQuery";
import useSelectOrganization from "../../../organizations/hooks/useSelectOrganization";
import useUserOrganizations from "../../../organizations/hooks/useUserOrganizations";

import CreateProductionForm from "../../components/CreateProductionForm/CreateProductionForm";

export default function CreateProductionProducerView({ organization }) {
	const profile = useProfile();
	const selectOrganization = useSelectOrganization();

	// change global organization when producer is changed in form
	const setProducerId = useCallback(
		(id) => {
			if (id !== organization.id) {
				selectOrganization({ id, type: organizationTypes.PRODUCER });
			}
		},
		[organization.id, selectOrganization],
	);

	// get publishers for producer
	const [publishers] = useCollectionDataOnce(
		useOrganizationsQuery({
			producer: organization.id,
			type: organizationTypes.PUBLISHER,
		}),
	);

	const producerOptions = useUserOrganizations(profile)
		.filter(({ type }) => type === organizationTypes.PRODUCER)
		.map(({ id, name }) => ({ key: id, value: id, text: name }));

	const publisherOptions = publishers?.map(({ id, name }) => ({ key: id, value: id, text: name }));

	return (
		<CreateProductionForm
			profile={profile}
			setProducerId={setProducerId}
			producerOptions={producerOptions}
			publisherOptions={publisherOptions}
			defaultProducerId={organization.id}
			defaultPublisherId={publisherOptions?.length === 1 ? publisherOptions[0].value : null}
			enableProductionCategory={organization.enableProductionCategory}
		/>
	);
}
