export default {
	name: "Storytel files.com",
	// format: "wav",
	connection: "ftp",
	audio: true,
	cover: true,
	meta: { format: "excel", fields: ["availability", "takedown"], sharedFields: ["tags", "thema"] },
	// send emails to distributor after metadata updates?
	email: ["tags"],
	emailLanguages: ["sv"], // only swedish productions currently
	scrape: true,
	disableArticles: ["cd", "mp3cd"],
};
