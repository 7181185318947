import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { priceTypeFromRole } from "astrid-firestore/src/api/prices/utils/priceMappers";

import Form from "../../../../forms/components/Form/Form";
import CurrencySelect from "../../../../prices/components/CurrencySelect/CurrencySelect";
import PriceUnitSelect from "../../../../prices/components/PriceUnitSelect/PriceUnitSelect";
import usePriceAutoApproval from "../../../../prices/hooks/usePriceAutoApproval";

export default function CustomPriceForm({ firstParty, production, defaultPrice, autoApprove = false }) {
	const { t } = useTranslation();
	const { watch, setValue } = Form.useFormContext();

	const [role, customPrice] = watch(["role", "customPrice"]);

	const [approved = false] = usePriceAutoApproval({ price: customPrice, production, autoApprove });

	useEffect(() => {
		setValue("customPrice.approved", !autoApprove || approved);
	}, [approved, autoApprove, setValue]);

	return (
		<Form.Group widths="equal">
			<Form.Field name="customPrice.type" defaultValue={priceTypeFromRole[role]} hidden />

			<Form.Field
				name="customPrice.name"
				defaultValue={defaultPrice?.name || ""}
				rules={{ required: false }}
				hidden
			/>

			<Form.NumberInput
				name="customPrice.price"
				label={t("cost", "Cost")}
				defaultValue={defaultPrice?.price || null}
				error={
					autoApprove &&
					!!customPrice.price &&
					!customPrice.approved && {
						content: t("priceNeedsApproval", "Needs to be approved"),
						pointing: "above",
					}
				}
			/>

			<Form.Field
				name="customPrice.currency"
				defaultValue={defaultPrice?.currency || firstParty?.currencyDefault || null}
			>
				<CurrencySelect label={t("currency", "Currency")} limitedOptions={firstParty?.currencyOptions} />
			</Form.Field>

			<Form.Field name="customPrice.unit" defaultValue={defaultPrice?.unit || null}>
				<PriceUnitSelect label={t("unit", "Unit")} />
			</Form.Field>
		</Form.Group>
	);
}
