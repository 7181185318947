import { Popup } from "semantic-ui-react";

import useOfferStatusPopupContent from "./hooks/useOfferStatusPopupContent";

export default function OfferStatusPopup({ offer, children }) {
	const popupContent = useOfferStatusPopupContent({ offer });

	if (!popupContent) {
		return children;
	}

	return <Popup trigger={children} content={popupContent} size="mini" position="top center" />;
}
