import React from "react";
import { Icon, Popup } from "semantic-ui-react";

const SettingsPopup = ({ children, inline }) => {
	return (
		<Popup
			trigger={
				<Icon
					color="grey"
					name="setting"
					size="large"
					className={"settings-popup-icon " + (inline ? "inline" : "")}
				/>
			}
			on="click"
			content={children}
		/>
	);
};

export default SettingsPopup;
