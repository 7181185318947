import { useTranslation } from "react-i18next";

import todoTypes from "astrid-firestore/src/constants/todoTypes";

import useArticleTranslations from "../../../hooks/useArticleTranslations";

export default function TodoContent({ todo }) {
	const { t } = useTranslation();
	const { type, content } = todo;
	const articleTranslations = useArticleTranslations();

	switch (type) {
		case todoTypes.SCRIPT_MISSING:
			return (
				<i>
					{t("scriptDateTodo", "Script date")}:{" "}
					{content.production?.scriptDate?.toDate?.()?.toLocaleDateString()}
				</i>
			);

		case todoTypes.READER_INQUIRY_DRAFT:
			return (
				<i>
					{t("createdADraftFor", "{{publisher}} created a draft for {{reader}}", {
						publisher: content.publisher.name,
						reader: content.reader.name,
					})}
				</i>
			);

		case todoTypes.LAST_COMMENT_PUBLISHER:
			return (
				<i>
					{t("publisherHasCommentedOnProduction", "{{publisher}} has commented on {{production}}", {
						publisher: content.publisher.name,
						production: content.production.title,
					})}
				</i>
			);

		case todoTypes.SESSION_MISSING_RECORDER:
			return (
				<i>
					{t("sessionStarts", "Session starts")}: {content.session?.start?.toDate?.()?.toLocaleString()}
				</i>
			);

		case todoTypes.MISSING_PROOFER:
		case todoTypes.MISSING_EDITOR:
			return (
				<i>
					{t("deliveryDate", "Delivery date")}:{" "}
					{content.production?.deliveryDate?.toDate?.()?.toLocaleDateString()}
				</i>
			);

		case todoTypes.FILES_UPLOADED:
			return (
				<i>
					{Object.entries(content.files || {})
						.map(
							([article, { length, external }]) =>
								t("filesWereUploadedTo", "{{amount}} files were uploaded to {{article}}", {
									amount: length,
									article,
								}) + (external ? ` (${t("external", "External")})` : ""),
						)
						.join(", ")}
				</i>
			);
		case todoTypes.DELIVERY_DATE_MISSING:
			return (
				<i>
					{t(
						"deliveryDateMissingForArticles",
						"Delivery date is missing for the following articles: {{article}}",
						{
							article: content.production.articlesMissingDeliveryDate
								.map((article) => articleTranslations[article])
								.join(", "),
						},
					)}
				</i>
			);
		default:
			return null;
	}
}
