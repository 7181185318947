import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useAgreementAppendixesQuery({ type, part, deleted = false, producerId } = {}) {
	return useMemo(() => {
		let query = createQuery(db.collection("agreementAppendixes"), {
			type,
			part,
			deleted,
		});

		if (producerId) {
			query = query.where("producerIds", "array-contains", producerId);
		}

		return query;
	}, [type, part, deleted, producerId]);
}
