import { useCallback, useMemo } from "react";

export default function useIsAgreementPriceApproved(template) {
	const templatePrices = useMemo(() => Object.values(template?.prices || {}), [template]);

	return useCallback(
		({ type, price, unit, currency }) =>
			!template ||
			!!templatePrices.find(
				(b) =>
					type === b.type &&
					unit === b.unit &&
					currency === b.currency &&
					(template?.part === "publisher" ? price >= b.price : price <= b.price),
			),
		[template, templatePrices],
	);
}
