import intersection from "lodash/intersection";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import isAssignedToRecorder from "../../features/calendar/helpers/isAssignedToRecorder";
import { secToDuration, toDate } from "../../helpers/fnc";

class BookingStats extends Component {
	render() {
		const { t, sessions, filter, productionMap } = this.props;

		let activeSessions = sessions;
		if (filter) {
			activeSessions = activeSessions.filter((session, i) => {
				return !(
					(filter.studio && filter.studio.includes(session.studio)) ||
					(filter.status && filter.status.includes(session.status)) ||
					(filter.production && !filter.production.includes(session.production)) ||
					(filter.reader &&
						!intersection(
							filter.reader,
							productionMap[session.production]
								? productionMap[session.production].reader
								: session.reader,
						).length) ||
					(filter.manager &&
						!intersection(filter.manager, productionMap[session.production]?.manager).length) ||
					(filter.recorder &&
						!(
							filter.recorder.includes(session.recorder) ||
							(filter.recorder.includes("none") && !isAssignedToRecorder(session))
						))
				);
			});
		}

		const stats = activeSessions.reduce(
			(prev, curr) => {
				let plannedDuration = moment(toDate(curr.end)).unix() - moment(toDate(curr.start)).unix();
				if (plannedDuration < 0) return prev;
				else
					return {
						booked: curr.status !== "cancelled" ? prev.booked + plannedDuration : prev.booked,
						done: curr.status === "done" ? prev.done + plannedDuration : prev.done,
						cancel: curr.status === "cancelled" ? prev.cancel + plannedDuration : prev.cancel,
						noRec:
							!isAssignedToRecorder(curr) && curr.status !== "cancelled"
								? prev.noRec + plannedDuration
								: prev.noRec,
					};
			},
			{ booked: 0, done: 0, cancel: 0, noRec: 0 },
		);

		return (
			<>
				<Label>
					{t("totalStudioTime")} <Label.Detail>{secToDuration(stats.booked)}</Label.Detail>
				</Label>
				<Label>
					{t("accomplishedStudioTime")} <Label.Detail>{secToDuration(stats.done)}</Label.Detail>
				</Label>
				<Label>
					{t("cancelledStudioTime")} <Label.Detail>{secToDuration(stats.cancel)}</Label.Detail>
				</Label>
				<Label>
					{t("studioTimeWithoutTechn")} <Label.Detail>{secToDuration(stats.noRec)}</Label.Detail>
				</Label>
			</>
		);
	}
}

export default withTranslation()(BookingStats);
