import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";
import { getAgreementRef, getPriceRef } from "astrid-firestore/src/utils/referenceMappers";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Select from "../../ui/components/Select/Select";

import Form from "../../forms/components/Form/Form";

import sessionIsAnyRecorderInTeam from "../helpers/sessionsIsAnyRecorderInTeam";
import useSessionOptions from "../hooks/useSessionOptions";

import SessionContext from "./SessionContext";

export default function SessionBaseForm({ disable = false }) {
	const { t, production } = useContext(SessionContext);
	const {
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();
	const [selectedRecorder, selectedPriceId] = watch(["recorder", "priceId"]);

	const [sessionOptions, loadingSessionOptions, errorSessionOptions] = useSessionOptions({
		production,
		recorderId: selectedRecorder,
	});

	const { readerOptions, recorderOptions, recorderPriceOptions, studioOptions } = sessionOptions;

	useEffect(() => {
		if (sessionIsAnyRecorderInTeam(selectedRecorder)) {
			setValue("priceId", null);
			setValue("priceRef", null);
			setValue("agreementRef", null);
			setValue("recorder", sessionOfferTypes.ANY_RECORDER_IN_TEAM); // set value when recorder is null.
		} else if (recorderPriceOptions?.length === 1) {
			setValue("priceId", recorderPriceOptions[0].value);
			setValue("priceRef", getPriceRef(recorderPriceOptions[0].data.price));
			setValue("agreementRef", getAgreementRef(recorderPriceOptions[0].data.agreement));
		}
	}, [selectedRecorder, recorderPriceOptions, selectedPriceId, setValue]);

	return (
		<LoadingContext data={sessionOptions} loading={loadingSessionOptions} error={errorSessionOptions}>
			<Form.Group widths="equal">
				<Form.Field name="reader">
					<Select
						multiple
						disabled={disable}
						label={t("reader")}
						options={readerOptions}
						error={!!errors?.["reader"]}
					/>
				</Form.Field>
				<Form.Field name="studio">
					<Select search label={t("bookStudio")} options={studioOptions} error={!!errors?.["studio"]} />
				</Form.Field>
			</Form.Group>
			<Form.Group widths="equal">
				<Form.Field name="production">
					<Select
						label={t("Production")}
						options={[{ key: production?.id, value: production?.id, text: production?.title }]}
						disabled
					/>
				</Form.Field>
				<Form.Field name="recorder">
					<Select
						disabled={disable}
						error={!!errors?.["recorder"]}
						label={t("recorder")}
						options={recorderOptions}
						onChangeOption={(option) => {
							if (option?.data) {
								setValue("recorderName", option.data);
							}
						}}
					/>
				</Form.Field>
				<Form.Field name="priceId" rules={{ required: !sessionIsAnyRecorderInTeam(selectedRecorder) }}>
					<Select
						disabled={
							disable || selectedRecorder === sessionOfferTypes.ANY_RECORDER_IN_TEAM || !selectedRecorder
						}
						error={!!errors?.["priceId"]}
						label={t("price")}
						options={recorderPriceOptions}
						onChangeOption={(option) => {
							if (!option) {
								// means no match and the price should be removed.
								setValue("priceId", null);
								setValue("priceRef", null);
								setValue("agreementRef", null);
							} else {
								setValue("priceRef", getPriceRef(option.data.price));
								setValue("agreementRef", getAgreementRef(option.data.agreement));
							}
						}}
					/>
				</Form.Field>
			</Form.Group>
			<Form.Checkbox name="polish" label={t("polishSession")} rules={{ required: false }} />
		</LoadingContext>
	);
}
