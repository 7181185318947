import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateDistributionPipelineForm from "./CreateDistributionPipelineForm";

export default function CreateDistributionPipelineButton({ channel }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("createPipeline", "Create pipeline")}>
			{({ setOpen }) => <CreateDistributionPipelineForm channel={channel} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
