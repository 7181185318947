import { useTranslation } from "react-i18next";

import Select from "../../../../ui/components/Select/Select";

export default function AppendixSelect({ number, ...props }) {
	const { t } = useTranslation();

	return (
		<Select
			placeholder={t("appendixNumber", "Appendix {{number}}", { number })}
			additionalOptions={[
				{
					key: "file",
					value: "file",
					text: `[${t("fileFromComputer", "File from computer")}]`,
				},
				{
					key: "text",
					value: "text",
					text: `[${t("freeText", "Free text")}]`,
				},
			]}
			fluid
			search
			noResultsMessage={t("noAppendixesFound", "No appendixes found")}
			{...props}
		/>
	);
}
