import { Card, Header } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import StudioDropdownMenu from "../../../../studios/components/StudioDropdownMenu/StudioDropdownMenu";

export default function StudioHeader({ studio }) {
	return (
		<Card fluid>
			<Card.Content>
				<Flex style={{ gap: 15, justifyContent: "flex-start" }}>
					<Header style={{ margin: 0 }}>
						{studio.name}
						<Header.Subheader>{studio.id}</Header.Subheader>
					</Header>
					<StudioDropdownMenu studio={studio} />
				</Flex>
			</Card.Content>
		</Card>
	);
}
