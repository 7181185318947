import { useMemo } from "react";
import { useExpanded, useFilters, usePagination, useRowSelect, useSortBy } from "react-table";

import checkboxes from "../plugins/checkboxes";
import trashbins from "../plugins/trashbins";

export default function usePlugins({
	sortable = false,
	removable = false,
	filterable = false,
	selectable = false,
	pagination = false,
	expandable = false,
	onRemoveRow,
}) {
	return useMemo(
		() =>
			[
				filterable && useFilters,
				sortable && useSortBy,
				pagination && usePagination,
				selectable && useRowSelect,
				expandable && useExpanded,
				selectable && checkboxes(selectable),
				removable && trashbins(onRemoveRow),
			].filter((p) => !!p),
		[expandable, filterable, onRemoveRow, pagination, removable, selectable, sortable],
	);
}
