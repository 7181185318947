import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import ProductionPriceForm from "../../../../productions/components/ProductionPriceForm/ProductionPriceForm";

export default function AddRevenueAction({ production }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState();

	return (
		<>
			<Button onClick={() => setOpen(true)}>{t("addEarnings")}</Button>

			{open && (
				<ProductionPriceForm
					type="revenue"
					production={production}
					firstPartyId={production.producer}
					onClose={() => setOpen(false)}
				/>
			)}
		</>
	);
}
