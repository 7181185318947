import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminDistributionChannelsView from "../views/SuperAdminDistributionChannelsView/SuperAdminDistributionChannelsView";

export default function SuperAdminDistributionChannels(props) {
	const hasRight = useHasRight();

	if (!hasRight("distribution.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminDistributionChannelsView {...props} />;
}
