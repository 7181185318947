import { useEffect } from "react";

export default function useInitialFormData(key, { name, value, onChange }) {
	useEffect(() => {
		if (key && value) {
			localStorage.setItem(`initialFormData.${key}`, value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useEffect(() => {
		if (!value) {
			onChange?.(null, { name, value: localStorage.getItem(`initialFormData.${key}`) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
