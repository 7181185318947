import { firebase } from "astrid-firebase";
import finalizeOpenCosts from "astrid-firestore/src/api/costs/finalizeOpenCosts";

import useLoadingFunction from "../../ui/hooks/useLoadingFunction";

import useFinalizableCosts from "./useFinalizableCosts";

export default function useFinalizeCosts(openCosts) {
	const finalizableCosts = useFinalizableCosts(openCosts);

	const [finalizeCosts, loading, error] = useLoadingFunction(() => finalizeOpenCosts(firebase, finalizableCosts));

	return { finalizeCosts, finalizableCosts, loading, error };
}
