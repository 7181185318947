import { useNavigate, useParams } from "react-router-dom";

export default function withMatch(Component) {
	const Wrapper = (props) => {
		const params = useParams();
		const navigate = useNavigate();

		return (
			<Component
				match={{ params }}
				history={{
					push: navigate,
					replace: (to) => navigate(to, { replace: true }),
				}}
				{...props}
			/>
		);
	};

	Wrapper.displayName = "withMatch";

	return Wrapper;
}
