import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";

import ArticleCoverImage from "../../../../articles/components/ArticleCoverImage/ArticleCoverImage";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminArticleVersionMenuOptions from "../hooks/useAdminArticleVersionMenuOptions";

export default function AdminArticleVersionHeader({ version }) {
	const { t } = useTranslation();
	const menuOptions = useAdminArticleVersionMenuOptions();

	const article = version.after;

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<ArticleCoverImage thumb rounded article={article} style={{ width: 80, height: 80 }} />

			<Header style={{ margin: 0 }}>
				{article.title && (
					<Header.Subheader style={{ marginBottom: 2 }}>
						{`${t("title", "Title")}: ${article.title.name}`}
					</Header.Subheader>
				)}

				{article.name}
			</Header>
		</AdminPageHeader>
	);
}
