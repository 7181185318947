import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useVoiceTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[1]: t("veryDark", "Very deep"),
			[2]: t("dark", "Deep"),
			[3]: t("neutral", "Neutral"),
			[4]: t("light", "Bright"),
			[5]: t("veryLight", "Very bright"),
		}),
		[t],
	);
}
