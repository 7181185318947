import React, { useMemo } from "react";
import { Icon } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import { useCollectionDataOnce, useLoadingValues } from "astrid-firestore/src/hooks";

export default function useStudioOptions({ organizationId }) {
	const [[studios, studiosGen2], loading, error] = useLoadingValues(
		useCollectionDataOnce(
			organizationId &&
				db.collection("organizations").doc(organizationId).collection("studios").where("active", "==", true),
		),
		useCollectionDataOnce(
			organizationId &&
				db.collection("studios").where("producerId", "==", organizationId).where("active", "==", true),
		),
	);

	const activeStudioOptions = useMemo(
		() =>
			studios
				?.concat(studiosGen2 || [])
				.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
				.map((studio) => {
					const text = `${studio.name}${studio.gen ? ` (gen ${studio.gen})` : ""}`;

					return {
						id: studio.id,
						key: studio.id,
						value: studio.id,
						data: studio,
						text,
						content: (
							<span>
								<Icon name="cube" style={{ color: studio.color || "black" }} />
								{text}
							</span>
						),
					};
				}) || [],
		[studios, studiosGen2],
	);

	return [activeStudioOptions, loading, error];
}
