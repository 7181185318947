import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

export default function OrderLabel({ order }) {
	const { t } = useTranslation();

	return (
		<Label color={order.processed && "green"}>
			{order.processed ? t("paid", "Paid") : t("pending", "Pending")}
		</Label>
	);
}
