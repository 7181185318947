import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminStudioView from "../views/AdminStudioView/AdminStudioView";

export default function AdminStudio() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("studios.view")) {
		return <Unauthorized />;
	}

	return <AdminStudioView id={id} organizationId={organization.id} />;
}
