import React from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../ui/components/Select/Select";

import usePriceOptions from "../../hooks/usePriceOptions";

export default function PriceSelect({ user, role, language, agreement, producerId, ...props }) {
	const { t } = useTranslation();

	const [options, loading] = usePriceOptions({
		user,
		role,
		language,
		agreement,
		producerId,
	});

	return <Select label={t("price")} options={options} disabled={options.length === 0} loading={loading} {...props} />;
}
