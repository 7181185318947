import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import PricesActions from "../../../prices/components/PricesActions/PricesActions";
import usePricesQuery from "../../../prices/hooks/usePricesQuery";

import AdminHeader from "../../components/AdminHeader/AdminHeader";

import usePricesColumns from "./hooks/usePricesColumns";

export default function AdminPricesView({ header, producerId, ...props }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = usePricesQuery({ producerId });
	const columns = usePricesColumns();

	return (
		<>
			<AdminHeader>
				{t("globalPrices", "Global prices")} <PricesActions />
			</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(`../prices/${id}`)}
				sortBy={{
					id: "created",
					desc: true,
				}}
				{...props}
			/>
		</>
	);
}
