import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import AppendixForm from "../../../../agreements/components/AppendixForm/AppendixForm";

export default function CreateAppendixAction() {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)}>{t("createAppendix", "Create appendix")}</Button>

			{open && <AppendixForm onClose={() => setOpen(false)} />}
		</>
	);
}
