import React from "react";

import biblioTaskTypes from "astrid-firestore/src/api/biblio/tasks/constants/biblioTaskTypes";
import taskStatuses from "astrid-firestore/src/api/tasks/constants/taskStatuses";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";
import BiblioTasksView from "../../biblio/views/BiblioTasksView/BiblioTasksView";
import useTasksByTaskType from "../../tasks/hooks/useTasksByTaskType";

export default function SuperAdminBiblioUncompletedTasks() {
	const hasRight = useHasRight();

	const [tasks, loading, error] = useTasksByTaskType({
		taskTypes: Object.values(biblioTaskTypes),
		status: [taskStatuses.CREATED, taskStatuses.INITIALIZED, taskStatuses.FAILED, taskStatuses.RETRY],
	});

	if (!hasRight("biblio.admin")) {
		return <Unauthorized />;
	}

	return (
		<LoadingContext data={tasks} loading={loading} error={error}>
			<BiblioTasksView tasks={tasks} />
		</LoadingContext>
	);
}
