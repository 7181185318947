import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import { invoiceTypes } from "astrid-firestore/src/api/invoices/invoiceTypes";

import CreateSalesOrderInvoice from "../../../../invoices/components/CreateSalesOrderInvoice";
import DataTable from "../../../../ui/components/DataTable/DataTable";

import useInvoicesColumns from "../hooks/useInvoicesColumns";

function shouldShowCreateInvoice({ order, invoices }) {
	const latestInvoice = invoices?.[0];

	return !order.processed && !order.inactivated && (!invoices?.length || latestInvoice?.type === invoiceTypes.CREDIT);
}

export default function OrderInvoices({ order, invoices }) {
	const { t } = useTranslation();
	const columns = useInvoicesColumns();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header as="h3" content={t("invoiceHeader", "Invoices")} />

				{shouldShowCreateInvoice({ order, invoices }) && (
					<CreateSalesOrderInvoice order={order} invoices={invoices} />
				)}

				<DataTable columns={columns} data={invoices} />
			</Card.Content>
		</Card>
	);
}
