import useForm from "../../../../forms/hooks/useForm";

export default function useAgreementTemplateForm({ organization }) {
	return useForm({
		mode: "onChange",
		defaultValues: {
			name: "",
			type: null,
			part: null,
			life: 365,
			prices: [],
			daysToSign: 14,
			appendixes: [],
			producer: organization,
			firstParty: organization,
		},
	});
}
