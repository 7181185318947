import { useTranslation } from "react-i18next";

import { languageOptions } from "astrid-helpers/src/languages";

import api from "../../../../../api";
import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import MetadataEditor from "./MetadataEditor";

export default function SuperAdminDistributionChannelForm({ channel }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: api.distributionChannels.Schema,
		defaultValues: {
			ref: channel.ref,
			name: channel.name || "",
			acceptedLanguages: channel.acceptedLanguages || [],
		},
		onSubmit: (data) => {
			return api.distributionChannels.update(data);
		},
	});

	return (
		<AdminForm header={t("settings", "Settings")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("name", "Name")} />
				<Form.Select
					multiple
					search
					name="acceptedLanguages"
					label={t("acceptedLanguages", "Accepted languages")}
					options={languageOptions}
				/>
			</Form.Group>
			<MetadataEditor channel={channel} />
		</AdminForm>
	);
}
