import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import uniqid from "uniqid";

import { Pipeline } from "astrid-firestore/src/api/pipelines/types/Pipeline";

import api from "../../../../api";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateDistributionPipelineForm({ channel, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const id = uniqid();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Pipeline,
		defaultValues: {
			name: "",
		},
		onSubmit: (data) => {
			return api.distributionChannels.createPipeline({ channel, id, data });
		},
		onSuccess: () => {
			navigate(id);
		},
	});

	return (
		<ModalPrompt size="tiny" header={t("createPipeline", "Create pipeline")} onSave={onSubmit} onClose={onClose}>
			<Form form={form}>
				<Form.Input name="name" label={t("name", "Name")} />
			</Form>

			{error && <ErrorMessage error={error} />}
		</ModalPrompt>
	);
}
