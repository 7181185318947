import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function useAgreementsQuery({
	type,
	part,
	statuses,
	deleted = false,
	templateId,
	producerId,
	productionId,
	firstPartyId,
	firstPartyVendorId,
	secondPartyId,
	secondPartyVendorId,
	secondSigneeId,
	secondSigneeState,
} = {}) {
	return useMemo(() => {
		let query = createQuery(db.collection("agreements"), {
			type,
			part,
			deleted,
			"template.id": templateId,
			"producer.id": producerId,
			"production.id": productionId,
			"firstParty.id": firstPartyId,
			"firstParty.vendor.id": firstPartyVendorId,
			"secondParty.id": secondPartyId,
			"secondSignee.id": secondSigneeId,
			"secondSignee.signState": secondSigneeState,
			"secondParty.vendor.id": secondPartyVendorId,
		});

		if (statuses) {
			query = query.where("status", "in", statuses);
		}

		return query;
	}, [
		type,
		part,
		deleted,
		templateId,
		producerId,
		productionId,
		firstPartyId,
		firstPartyVendorId,
		secondPartyId,
		secondSigneeId,
		secondSigneeState,
		secondPartyVendorId,
		statuses,
	]);
}
