const countries = {
	AF: "Afghanistan",
	AL: "Albanien",
	DZ: "Algeriet",
	AS: "Amerikanska Samoa",
	AD: "Andorra",
	AO: "Angola",
	AI: "Anguilla",
	AG: "Antigua och Barbuda",
	AR: "Argentina",
	AM: "Armenien",
	AW: "Aruba",
	AU: "Australien",
	AZ: "Azerbajdzjan",
	BS: "Bahamas",
	BH: "Bahrain",
	BD: "Bangladesh",
	BB: "Barbados",
	BE: "Belgien",
	BZ: "Belize",
	BJ: "Benin",
	BM: "Bermuda",
	BT: "Bhutan",
	BO: "Bolivia",
	BA: "Bosnien",
	BW: "Botswana",
	BR: "Brasilien",
	VG: "Brittiska Jungfruöarna",
	BN: "Brunei",
	BG: "Bulgarien",
	BF: "Burkina Faso",
	BI: "Burundi",
	CV: "Cap Verde",
	KY: "Caymanöarna",
	CF: "Centralafrikanska republiken",
	CL: "Chile",
	CO: "Colombia",
	CK: "Cooköarna",
	CR: "Costa rica",
	CY: "Cypern, norr",
	DK: "Danmark",
	CD: "Demokratiska republiken Kongo",
	DJ: "Djibouti",
	DM: "Dominica",
	DO: "Dominikanska republiken",
	EC: "Ecuador",
	EG: "Egypten",
	GQ: "Ekvatorialguinea",
	SV: "El Salvador",
	CI: "Elfenbenskusten",
	ER: "Eritrea",
	EE: "Estland",
	ET: "Etiopien",
	FK: "Norfolk Island",
	FJ: "Fiji",
	PH: "Filippinerna",
	FI: "Finland",
	CG: "Folkrepubliken Kongo",
	FR: "Frankrike",
	FO: "Färöarna",
	AE: "Förenade arabemiraten",
	GA: "Gabon",
	GM: "Gambia",
	GE: "Georgien",
	GH: "Ghana",
	GI: "Gibraltar",
	GR: "Grekland",
	GD: "Grenada",
	GL: "Grönland",
	GU: "Guam",
	GT: "Guatemala",
	GN: "Guinea",
	GW: "Guinea-Bissau",
	GY: "Guyana",
	HT: "Haiti",
	HN: "Honduras",
	HK: "Hong Kong",
	IN: "Indien",
	ID: "Indonesien",
	IQ: "Irak",
	IR: "Iran",
	IE: "Irland",
	IS: "Island",
	IL: "Israel",
	IT: "Italien",
	JM: "Jamaica",
	JP: "Japan",
	YE: "Jemen",
	JE: "Jersey",
	JO: "Jordanien",
	CX: "Julön",
	KH: "Kambodja",
	CM: "Kamerun",
	CA: "Kanada",
	KZ: "Kazakstan",
	KE: "Kenya",
	CN: "Kina",
	KG: "Kirgizistan",
	KI: "Kiribati",
	KM: "Komorerna",
	HR: "Kroatien",
	CU: "Kuba",
	KW: "Kuwait",
	LA: "Laos",
	LS: "Lesotho",
	LV: "Lettland",
	LB: "Libanon",
	LR: "Liberia",
	LY: "Libyen",
	LI: "Liechtenstein",
	LT: "Litauen",
	LU: "Luxemburg",
	MO: "Macau",
	MG: "Madagaskar",
	MW: "Malawi",
	MY: "Malaysia",
	MV: "Maldiverna",
	ML: "Mali",
	MT: "Malta",
	MA: "Marocko",
	MH: "Marshallöarna",
	MQ: "Martinique",
	MR: "Mauretanien",
	MU: "Mauritius",
	MX: "Mexico",
	FM: "Mikronesien",
	MZ: "Moçambique",
	MD: "Moldavien",
	MC: "Monaco",
	MN: "Mongoliet",
	MS: "Montserrat",
	MM: "Myanmar",
	NA: "Namibia",
	NR: "Nauru",
	NL: "Nederländerna",
	NP: "Nepal",
	NI: "Nicaragua",
	NE: "Niger",
	NG: "Nigeria",
	NU: "Niue",
	KP: "Nordkorea",
	MK: "Nordmakedonien",
	NO: "Norge",
	NZ: "Nya Zeeland",
	OM: "Oman",
	PK: "Pakistan",
	PW: "Palau",
	PA: "Panama",
	PG: "Papua Nya Guinea",
	PY: "Paraguay",
	PE: "Peru",
	PN: "Pitcairn Islands",
	PL: "Polen",
	PT: "Portugal",
	PR: "Puerto Rico",
	QA: "Qatar",
	RO: "Rumänien",
	RW: "Rwanda",
	RU: "Ryssland",
	KN: "Saint Kitts och Nevis",
	LC: "Saint Lucia",
	VC: "Saint Vincent och Grenadinerna",
	SB: "Salomonöarna",
	WS: "Samoa",
	SM: "San Marino",
	ST: "São Tomé och Príncipe",
	SA: "Saudiarabien",
	CH: "Schweiz",
	SN: "Senegal",
	RS: "Serbien",
	SC: "Seychellerna",
	SL: "Sierra Leone",
	SG: "Singapore",
	SK: "Slovakien",
	SI: "Slovenien",
	SO: "Somalia",
	ES: "Spanien",
	LK: "Sri Lanka",
	GB: "Storbritannien",
	SD: "Sudan",
	SR: "Surinam",
	SE: "Sverige",
	SZ: "Swaziland",
	ZA: "Sydafrika",
	KR: "Sydkorea",
	SY: "Syrien",
	TJ: "Tadzjikistan",
	TW: "Taiwan",
	TZ: "Tanzania",
	TD: "Tchad",
	TH: "Thailand",
	CZ: "Tjeckien",
	TG: "Togo",
	TO: "Tonga",
	TT: "Trinidad och Tobago",
	TN: "Tunisien",
	TR: "Turkiet",
	TM: "Turkmenistan",
	TC: "Turks- och Caicosöarna",
	tv: "Tuvalu",
	DE: "Tyskland",
	UG: "Uganda",
	UA: "Ukraina",
	HU: "Ungern",
	UY: "Uruguay",
	VI: "US Virgin Islands",
	US: "USA",
	UZ: "Uzbekistan",
	VU: "Vanuatu",
	VA: "Vatikanstaten",
	VE: "Venezuela",
	VN: "Vietnam",
	BY: "Vitryssland",
	ZM: "Zambia",
	ZW: "Zimbabwe",
	AT: "Österrike",
};

export default countries;
