import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import TitleVersionsCollectionDataTable from "../../../titles/components/TitleVersionsCollectionDataTable/TitleVersionsCollectionDataTable";

export default function AdminTitleVersionsView({ title }) {
	const { t } = useTranslation();

	return (
		<>
			<Card fluid>
				<Card.Content>
					<Card.Header>{t("versions", "Versions")}</Card.Header>
				</Card.Content>
				<Card.Content>
					<TitleVersionsCollectionDataTable title={title} />
				</Card.Content>
			</Card>
		</>
	);
}
