import { db } from "astrid-firebase";
import getNextInvoiceNumber from "astrid-firestore/src/api/invoices/getNextInvoiceNumber";
import { useDocumentData } from "astrid-firestore/src/hooks";

export default function useInvoiceNumber({ orgId }) {
	const [lastInvoiceNumber, loading, error] = useDocumentData(
		db.collection("organizations").doc(orgId).collection("settings").doc("invoiceNumber"),
	);

	const invoiceNumber =
		!loading &&
		!error &&
		getNextInvoiceNumber({
			prevYear: lastInvoiceNumber?.year,
			prevNumber: lastInvoiceNumber?.number,
			currentYear: new Date().getFullYear(),
		});

	return [invoiceNumber, loading, error];
}
