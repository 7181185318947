import { useCallback, useEffect, useRef, useState } from "react";

import useCollectionOnce from "./useCollectionOnce";

export default function useLoadMore(ref, { limit = 10 } = {}) {
	const queryRef = useRef(ref);

	const [data, setData] = useState();
	const [last, setLast] = useState();
	const [query, setQuery] = useState(queryRef.current);

	const [snapshot, loading, error] = useCollectionOnce(query.limit(limit));

	const loadMore = useCallback(() => {
		setQuery(queryRef.current.startAfter(last));
	}, [last]);

	useEffect(() => {
		if (!queryRef.current.isEqual(ref)) {
			queryRef.current = ref;
			setData(undefined);
			setLast(undefined);
			setQuery(queryRef.current);
		}
	}, [limit, ref, snapshot]);

	useEffect(() => {
		if (snapshot?.query.isEqual(query.limit(limit))) {
			const { size } = snapshot;

			setLast((last) => {
				const next = size === limit && snapshot.docs[size - 1];

				if (last !== next) {
					setData((data) => [
						...(data || []),
						...snapshot.docs.map((doc) => ({
							...doc.data(),
							id: doc.id,
							ref: doc.ref,
						})),
					]);
				}

				return next;
			});
		}
	}, [limit, query, snapshot]);

	if (error) {
		console.error(error);
	}

	return [data, loading, error, last && loadMore];
}
