import { useLayoutEffect, useRef } from "react";
import { Form, Ref } from "semantic-ui-react";

export default function AdaptiveTextArea({ value, style, ...props }) {
	const ref = useRef();

	// Make the textarea size responsive to content
	useLayoutEffect(() => {
		const textArea = ref.current.querySelector("textarea");

		textArea.style.height = "0px";
		textArea.style.height = `${textArea.scrollHeight + 2}px`;
	}, [value]);

	return (
		<Ref innerRef={ref}>
			<Form.TextArea {...props} value={value} style={{ resize: "none", ...style }} />
		</Ref>
	);
}
