import {
	getAgreementRef,
	getPartyRef,
	getPriceRef,
	getProductionProducerRef,
	getProductionRef,
	getSessionRef,
} from "../../../utils/referenceMappers";

import createApprovalData from "../../approval/createApprovalData";

export default function createProductionPriceData(firebase, data = {}) {
	const {
		identifier = undefined,
		vat = 0,
		type = null,
		name = "",
		unit = "time",
		price = null,
		amount = 0,
		maxUses = false,
		expense = false,
		deleted = false,
		currency = null,
		approved = null,
		employee = false,
		disabled = false,
		agreement = null,
		production = null,
		firstParty = null,
		secondParty = null,
		referencePrice = null,
		producer = null,
		session = null,
		firstPartyVendorId = null,
	} = data;

	const db = firebase.firestore();
	const collection = production.ref
		? production.ref.collection("productionPrices")
		: db.collection("productions").doc(production.id).collection("productionPrices");

	const ref = identifier ? collection.doc(identifier) : collection.doc();

	return {
		id: ref.id,
		ref,
		vat: +vat,
		type,
		name,
		unit,
		price: price ? +price : null,
		amount: +amount || 0,
		maxUses: +maxUses || false,
		expense,
		created: new Date(Date.now()),
		deleted,
		currency,
		approval: createApprovalData(approved || null),
		employee,
		disabled,
		languages: [production.language],
		production: getProductionRef(production),
		producer: producer || getProductionProducerRef(db, production),
		session: getSessionRef(session),
		agreement: getAgreementRef(agreement),
		firstParty: getPartyRef(agreement?.firstParty || firstParty || producer, firstPartyVendorId),
		secondParty: getPartyRef(agreement?.secondParty || secondParty),
		referencePrice: getPriceRef(referencePrice),
	};
}
