import React from "react";

import FormInput from "../FormInput/FormInput";

export default function FormNumberInput(props) {
	return (
		<FormInput
			type="number"
			rules={{
				min: 0,
				required: true,
				valueAsNumber: true,
			}}
			{...props}
		/>
	);
}
