import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import useFinalizeCosts from "../../../hooks/useFinalizeCosts";

export default function FinalizeCostDropdownItem({ openCost, disabled, setLoading }) {
	const { t } = useTranslation();

	const { finalizeCosts, finalizableCosts } = useFinalizeCosts([openCost]);

	return (
		<Dropdown.Item
			text={t("finalizeCost", "Finalize cost")}
			disabled={disabled || finalizableCosts.length === 0}
			onClick={async () => {
				setLoading?.(true);
				await finalizeCosts();
				setLoading?.(false);
			}}
		/>
	);
}
