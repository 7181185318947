import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

import agreementParts from "astrid-firestore/src/api/agreements/constants/agreementParts";

import BlurInput from "../../../../../components/BlurInput";
import useCostTypeOptions from "../../../../costs/hooks/useCostTypeOptions";
import Form from "../../../../forms/components/Form/Form";
import CurrencySelect from "../../../../prices/components/CurrencySelect/CurrencySelect";
import VatSelect from "../../../../prices/components/VatSelect/VatSelect";
import usePriceUnitOptions from "../../../../prices/hooks/usePriceUnitOptions";

import useIsAgreementPriceApproved from "../../../hooks/useIsAgreementPriceApproved";

import useMaxUsesOptions from "./useMaxUsesOptions";

export default function useAgreementPricesFormColumns({ part, template, onRemove, maxPrices = false }) {
	const { t } = useTranslation();

	const unitOptions = usePriceUnitOptions(part);
	const maxUsesOptions = useMaxUsesOptions();
	const costTypeOptions = useCostTypeOptions(part);
	const isPriceApproved = useIsAgreementPriceApproved(template);

	const defaultType = costTypeOptions.length === 1 ? costTypeOptions[0].value : null;

	return useMemo(
		() => [
			{
				id: "type",
				Header: t("type", "Type"),
				Cell: ({ row }) => (
					<Form.Select
						name={`prices.${row.index}.type`}
						fluid
						search
						options={costTypeOptions}
						placeholder={t("chooseType", "Select type")}
						defaultValue={defaultType}
					/>
				),
			},
			{
				id: "name",
				Header: t("name", "Name"),
				Cell: ({ row }) => (
					<Form.Field name={`prices.${row.index}.name`}>
						<BlurInput placeholder={t("namePrice", "Name price variant")} />
					</Form.Field>
				),
			},
			{
				id: "price",
				Header: maxPrices
					? part === "publisher"
						? t("minPrice", "Min. price")
						: t("maxPrice", "Max. price")
					: t("price", "Price"),
				Cell: ({ row }) => {
					const { index } = row;
					const { watch } = Form.useFormContext();

					const approved = watch(`prices.${index}.approved`);

					return (
						<Form.NumberInput
							name={`prices.${index}.price`}
							error={
								!approved && {
									content: t("priceAboveThresholdMessage", "Needs to be approved before signing"),
									pointing: "above",
								}
							}
						/>
					);
				},
			},
			{
				id: "unit",
				Header: t("unit", "Unit"),
				Cell: ({ row }) => <Form.Select name={`prices.${row.index}.unit`} fluid upward options={unitOptions} />,
			},
			{
				id: "currency",
				Header: t("currency", "Currency"),
				Cell: ({ row }) => {
					const { watch } = Form.useFormContext();
					const firstParty = watch("firstParty");

					return (
						<Form.Field name={`prices.${row.index}.currency`}>
							<CurrencySelect
								limitedOptions={firstParty.currencyOptions}
								upward
								placeholder={t("chooseCurrency")}
							/>
						</Form.Field>
					);
				},
			},
			part === agreementParts.PUBLISHER && {
				id: "vat",
				Header: t("VAT", "VAT"),
				Cell: ({ row }) => (
					<Form.Field name={`prices.${row.index}.vat`}>
						<VatSelect upward />
					</Form.Field>
				),
			},
			{
				id: "maxUses",
				Header: t("maxUses", "Max uses"),
				Cell: ({ row }) => (
					<Form.Select
						name={`prices.${row.index}.maxUses`}
						fluid
						upward
						options={maxUsesOptions}
						rules={{ required: false }}
					/>
				),
			},
			{
				id: "approved",
				Cell: ({ row }) => {
					const { index } = row;
					const { watch, setValue } = Form.useFormContext();

					const [type, price, unit, currency] = watch([
						`prices.${index}.type`,
						`prices.${index}.price`,
						`prices.${index}.unit`,
						`prices.${index}.currency`,
					]);

					useEffect(() => {
						setValue(`prices.${index}.approved`, isPriceApproved({ type, price, unit, currency }));
					}, [currency, price, index, setValue, type, unit]);

					return null;
				},
			},
			{
				id: "remove",
				Cell: ({ row }) => (
					<Icon name="trash alternate outline" style={{ cursor: "pointer" }} onClick={() => onRemove(row)} />
				),
			},
		],
		[t, maxPrices, part, costTypeOptions, defaultType, unitOptions, maxUsesOptions, isPriceApproved, onRemove],
	);
}
