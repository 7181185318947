import { useEffect } from "react";
import useAsyncFn from "react-use/lib/useAsyncFn";

import { db, firebase } from "astrid-firebase";
import getCollectionDataById from "astrid-firestore/src/helpers/getCollectionDataById";

import { useProfile } from "../../authentication/state/profile";

export default function useStarred() {
	const profile = useProfile();

	const [{ value, loading, error }, fetchStarredProductions] = useAsyncFn(async () => {
		return await getCollectionDataById(firebase, db.collection("productions"), profile.starredProductions);
	}, [profile]);

	useEffect(() => {
		if (profile?.starredProductions) {
			fetchStarredProductions();
		}
	}, [profile, fetchStarredProductions]);

	return [value, loading, error];
}
