import { useTranslation } from "react-i18next";

import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

import useAgreementPartOptions from "../../../hooks/useAgreementPartOptions";
import useAgreementTypeOptions from "../../../hooks/useAgreementTypeOptions";

export default function AgreementTemplateSettingsForm() {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();

	const isValid = Form.usePartialFormValidation(["name"]);

	const [type, part] = watch(["type", "part"]);

	const agreementTypeOptions = useAgreementTypeOptions();
	const agreementPartOptions = useAgreementPartOptions();

	return (
		<ContentCard header={t("settings")} disabled={!isValid}>
			<Form.Form>
				<Form.Group widths="equal">
					<Form.Select
						name="type"
						options={agreementTypeOptions}
						label={t("agreementType", "Agreement type")}
					/>

					<Form.Select name="part" options={agreementPartOptions} label={t("agreementPart", "Part")} />

					{type === agreementTypes.FRAMEWORK && (
						<Form.Input name="life" type="number" label={t("agreementTime")} />
					)}

					<Form.Input
						name="daysToSign"
						type="number"
						max={30}
						label={t("daysToSign")}
						rules={{
							min: { value: 1, message: t("daysToSignValidation") },
							max: { value: 30, message: t("daysToSignValidation") },
						}}
					/>
				</Form.Group>

				{type === agreementTypes.FRAMEWORK && part === "publisher" && (
					<Form.Checkbox
						name="canManageAgreements"
						label={t("canManageAgreements", "Can manage user agreements for this publisher")}
						rules={{ required: false }}
					/>
				)}
			</Form.Form>
		</ContentCard>
	);
}
