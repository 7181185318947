import { useMemo } from "react";

import { teamRoles } from "astrid-firestore/src/constants/teamRoles";

import useTeamRoleTranslations from "./useTeamRoleTranslations";

export default function useTeamRoleOptions() {
	const roles = useTeamRoleTranslations();

	return useMemo(
		() =>
			Object.entries(roles)
				.filter(([key]) => key !== teamRoles.READER)
				.map(([key, text]) => ({
					key,
					text,
					value: key,
				})),
		[roles],
	);
}
