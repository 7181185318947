import React, { useContext, useState } from "react";
import { Accordion, Header, Icon } from "semantic-ui-react";

import getSessionPriceAndAgreement from "astrid-firestore/src/api/sessions/getSessionPriceAndAgreement";

import mapOffer from "../../../../myproductions/utils/mapOffer";
import OfferInformation from "../../../../offers/views/OfferView/components/OfferInformation";

import recorderIsPartOfTeam from "../../../helpers/recorderIsPartOfTeam";

import SessionContext from "../../SessionContext";

export default function ProductionInformation() {
	const { t, profile, session, production, sessionRoles } = useContext(SessionContext);
	const [active, setActive] = useState(false);

	const hidePrice =
		!session.priceRef ||
		(sessionRoles.isReader && !sessionRoles.isAdminOrProducerStaff && !sessionRoles.isRecorder) ||
		(sessionRoles.isRecorder &&
			!recorderIsPartOfTeam({
				production,
				user: profile,
			}));
	const priceAndAgreement =
		!hidePrice && production && getSessionPriceAndAgreement({ session, production, user: profile });
	const { price } = priceAndAgreement || {};

	const mappedOffer = mapOffer({
		offer: { price, production },
		user: profile,
	});

	if (!mappedOffer) {
		return null;
	}

	return (
		<>
			<Accordion.Title active={active} onClick={() => setActive(!active)}>
				<Header size="small">
					<Icon name="dropdown" />
					{t("productionInformation", "Production information")}
				</Header>
			</Accordion.Title>
			<Accordion.Content active={active}>
				<OfferInformation offer={mappedOffer.offer} hidePrice={hidePrice} production={production} />
			</Accordion.Content>
		</>
	);
}
