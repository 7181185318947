import memoize from "lodash/memoize";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Header, Icon, Table } from "semantic-ui-react";

import { db, firebase } from "astrid-firebase";
import { toDate } from "astrid-web/src/helpers/fnc";

const getReaders = memoize(
	(ids, setReaders) =>
		db
			.collection("users")
			.where(firebase.firestore.FieldPath.documentId(), "in", ids)
			.get()
			.then((docs) => {
				const readers = {};
				docs.forEach((org) => {
					readers[org.id] = org.data();
				});
				setReaders(readers);
			}),

	(...args) => JSON.stringify(args),
);

const HomeReaderOffers = ({ productions }) => {
	const [readers, setReaders] = useState({});
	const { t } = useTranslation();

	const offers = productions.filter(
		(prod) =>
			!prod.stats &&
			prod.status !== "draft" &&
			prod.readerStatus &&
			Object.entries(prod.readerStatus).find(
				([id, status]) => ["offer", "denied"].includes(status.status) && prod.reader?.includes(id),
			),
	);

	const readerIds = offers.reduce((prev, curr) => {
		Object.keys(curr.readerStatus).forEach((reader) => {
			if (!prev.includes(reader)) prev.push(reader);
		});
		return prev;
	}, []);

	useEffect(() => {
		if (readerIds?.length) {
			getReaders(readerIds, setReaders);
		}
	}, [readerIds]);

	return offers?.length ? (
		<>
			<Header as="h4" icon="user plus" content={t("readerOffers")} />
			<Table compact basic="very" sortable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>{t("titleReadOffer")}</Table.HeaderCell>
						<Table.HeaderCell>{t("readerReadOffer")}</Table.HeaderCell>
						<Table.HeaderCell>{t("delDateReadOffer")}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{offers
						.sort((a, b) => (a.deliveryDate > b.deliveryDate ? 1 : -1))
						.map((prod) => {
							return (
								<Table.Row key={prod.id}>
									<Table.Cell selectable>
										<Link to={"/production/" + prod.id}>{prod.title}</Link>
									</Table.Cell>
									<Table.Cell>
										<table className="table-invisible">
											<tbody>
												{Object.entries(prod.readerStatus)
													.filter(([id, status]) => readers[id] && prod.reader?.includes(id))
													.map(([id, status]) => (
														<tr key={id}>
															<td>
																{readers[id].firstName} {readers[id].lastName}
															</td>
															<td>
																<Icon
																	name={
																		status.status === "offer"
																			? toDate(status.date) > new Date()
																				? "clock"
																				: "warning sign"
																			: status.status === "denied"
																			? "delete"
																			: "check"
																	}
																	color={
																		status.status === "offer"
																			? toDate(status.date) > new Date()
																				? "orange"
																				: "red"
																			: status.status === "denied"
																			? "red"
																			: "green"
																	}
																/>
																{
																	<small>
																		{status.status === "offer" && status.date
																			? `${
																					toDate(status.date) > new Date()
																						? t("expires")
																						: t("didExpire")
																			  } ${moment(toDate(status.date)).format(
																					"lll",
																			  )}`
																			: status.status === "denied"
																			? t("hasDeclined")
																			: t("hasAccepted")}
																	</small>
																}
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</Table.Cell>
									<Table.Cell collapsing textAlign="right">
										{prod.deliveryDate && moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
									</Table.Cell>
								</Table.Row>
							);
						})}
				</Table.Body>
			</Table>
		</>
	) : null;
};

export default HomeReaderOffers;
