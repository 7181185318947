import { Icon, Label } from "semantic-ui-react";

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import { useOrganizationId } from "../../../../organizations/state/organizationId";

import tabHasNotification from "../utils/tabHasNotification";

import useProductionSecondaryMenuOptions from "./useProductionSecondaryMenuOptions";

export default function useProductionMenuOptions({ production, offers, alerts, permissions, isDesktop }) {
	const { t } = useTranslation();

	const user = useProfile();
	const orgId = useOrganizationId();
	const location = useLocation();
	const hasRight = useHasRight();

	const { pathname } = location;

	const options = useMemo(() => {
		const alertArray = Object.values(alerts);

		const isAdmin = hasRight("productions.admin");
		const isManager = hasRight("productions.manage");
		const isSubcontractor = hasRight("subcontractor", false);

		const infoAlerts = isManager ? alertArray?.filter((m) => m.tab === "info").length : 0;
		const articlesAlerts = isManager ? alertArray?.filter((m) => m.tab === "articles").length : 0;
		const audiobookAlerts = isManager ? alertArray?.filter((m) => m.tab === "audiobook").length : 0;

		const readerOffersNotifications = tabHasNotification("readers", { offers });
		const teamOffersNotifications = tabHasNotification("team", { offers });

		return [
			{
				to: `/production/${production.id}`,
				active: pathname.endsWith(production.id),
				content: (
					<>
						<Icon name="info circle" />

						{isDesktop ? t("overview") : ""}

						{!!infoAlerts && (
							<Label color="red" floating circular size="tiny">
								{infoAlerts}
							</Label>
						)}
					</>
				),
			},
			{
				to: "articles",
				active: pathname.includes("articles"),
				hidden: !isManager || (!isAdmin && isSubcontractor),
				content: (
					<>
						<Icon name="truck" />

						{isDesktop ? t("articles") : ""}

						{!!articlesAlerts && (
							<Label color="red" floating circular size="tiny">
								{articlesAlerts}
							</Label>
						)}
					</>
				),
			},
			{
				to: "audiobook",
				active: pathname.includes("audiobook"),
				hidden:
					!(typeof production.isbn === "string" || production.deliveryParts) ||
					// production.productionType || // Assigns string value that resolves false in check
					(typeof production.productionType === "undefined" && production.status === "draft") ||
					production.productionType === "external",
				content: (
					<>
						<Icon name="microphone" />

						{isDesktop ? t("audiobookProd") : ""}

						{!!audiobookAlerts && (
							<Label color="red" floating circular size="tiny">
								{audiobookAlerts}
							</Label>
						)}
					</>
				),
			},
			{
				to: "readers",
				active: pathname.includes("readers"),
				hidden:
					!isManager ||
					(!isAdmin && isSubcontractor) ||
					production.productionType === "external" ||
					!hasRight("readerRegistry.view"),
				content: (
					<>
						<Icon name="user circle" />

						{isDesktop ? t("reader", "Reader") : ""}

						{readerOffersNotifications > 0 && (
							<Label color="blue" floating circular size="tiny">
								{readerOffersNotifications}
							</Label>
						)}
					</>
				),
			},
			{
				to: "team",
				active: pathname.includes("team"),
				hidden: !hasRight("productions.team") || production.status === "offer",
				content: (
					<>
						<Icon name="group" />

						{isDesktop ? t("teamAndOffers", "Team & Offers") : ""}

						{teamOffersNotifications > 0 && (
							<Label color="blue" floating circular size="tiny">
								{teamOffersNotifications}
							</Label>
						)}
					</>
				),
			},
			{
				to: "finance",
				active: pathname.includes("finance"),
				hidden: !hasRight("finance.editProductionPrices") || production.status === "offer",
				content: (
					<>
						<Icon name="money bill alternate outline" />

						{isDesktop ? t("finance", "Finance") : ""}
					</>
				),
			},
		];
	}, [
		alerts,
		hasRight,
		offers,
		production.id,
		production.isbn,
		production.deliveryParts,
		production.productionType,
		production.status,
		pathname,
		isDesktop,
		t,
	]);

	const secondaryOptions = useProductionSecondaryMenuOptions({ production, isDesktop });

	return useMemo(
		() => (isDesktop ? options : [...options, ...secondaryOptions]),
		[isDesktop, options, secondaryOptions],
	);
}
