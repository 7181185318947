// MEMO: make custom per publisher? or per distributor? or at least merge to a better structure?

export default {
	sv: {
		kids: "Barn",
		biographies: "Biografier",
		crime: "Deckare",
		finance: "Ekonomi & business",
		erotica: "Erotiskt",
		fact: "Fakta",
		fantasy: "Fantasy & scifi",
		history: "Historia",
		classics: "Klassiker",
		poetry: "Lyrik",
		shortstories: "Noveller",
		selfhelp: "Personlig utveckling",
		nonfiction: "Romaner",
		language: "Språk",
		thriller: "Spänningsromaner",
		teen: "Tonår & nästan vuxen",
		harlequin: "Harlequin",
		feature: "Reportage",
		romance: "Romance",
		religion: "Religion och spiritualitet",
	},
	en: {
		kids: "Children",
		biographies: "Biographies",
		crime: "Crime",
		finance: "Economy & Business",
		erotica: "Erotica",
		fact: "Non-fiction",
		fantasy: "Fantasy & scifi",
		history: "History",
		classics: "Classics",
		poetry: "Lyric Poetry",
		shortstories: "Short stories",
		selfhelp: "Personal development",
		nonfiction: "Fiction",
		language: "Language",
		thriller: "Thrillers",
		teen: "Teens & Young adult",
		harlequin: "Harlequin",
		feature: "Feature",
		romance: "Romance",
		religion: "Religion & Spirituality",
	},

	/* MAPPING TO THEMA */
	thema: {
		kids: "YFB",
		biographies: "DNB",
		crime: "FF",
		finance: "KJ",
		erotica: "FP",
		fact: "JB",
		fantasy: "FM",
		history: "NH",
		classics: "FBC",
		poetry: "DC",
		shortstories: "FYB",
		selfhelp: "VSP",
		nonfiction: "FB",
		language: "CB",
		thriller: "FH",
		teen: "5LF",
		harlequin: "FXD",
		feature: "DNP",
		romance: "FXD",
		religion: "QR",
	},

	/* EXTERNAL COUNTRIES WANT ENGLISH!!! */

	// pl: {
	// 	kids: "Dla dzieci",
	// 	biographies: "Biografia",
	// 	crime: "Kryminał",
	// 	finance: "Bines i ekonomia",
	// 	erotica: "Erotyk",
	// 	fact: "Literatura Faktu",
	// 	fantasy: "Fantasy i Sci-Fi",
	// 	history: "Historia",
	// 	classics: "Klasyka",
	// 	poetry: "Poezja",
	// 	shortstories: "Opowiadania",
	// 	selfhelp: "Rozwój osobisty",
	// 	nonfiction: "Powieść obyczajowa",
	// 	language: "Nauka języków",
	// 	thriller: "Thriller",
	// 	teen: "Dla młodziezy",
	// 	harlequin: "Harlequin",
	// 	feature: "Feature",
	// 	religion: "Religia i duchowność",
	// 	romance: "Romans",
	// },
	// nl: {
	// 	kids: "Kinderen",
	// 	biographies: "Biografieën",
	// 	crime: "Misdaad",
	// 	finance: "Economie & zakelijk",
	// 	erotica: "Erotische fictie",
	// 	fact: "Non fictie",
	// 	fantasy: "Fantasy & SciFi",
	// 	history: "Geschiedenis",
	// 	classics: "Klassiek",
	// 	poetry: "Drama & Poëzie",
	// 	shortstories: "Korte verhalen",
	// 	selfhelp: "Persoonlijke ontwikkeling",
	// 	nonfiction: "Romans",
	// 	language: "Taal",
	// 	thriller: "Spanning",
	// 	teen: "Young adult",
	// 	harlequin: "Harlequin",
	// 	feature: "Feature",
	// 	religion: "Spiritualiteit",
	// 	romance: "Romance",
	// },
	// fi: {
	// 	kids: "Lapset",
	// 	biographies: "Elämäkerrat",
	// 	crime: "Dekkarit",
	// 	finance: "Talous & liike-elämä",
	// 	erotica: "Erotiikka",
	// 	fact: "Tietokirjallisuus",
	// 	fantasy: "Fantasia & scifi",
	// 	history: "Historia",
	// 	classics: "Klassikot",
	// 	poetry: "Runous & draama",
	// 	shortstories: "Novellit & esseet",
	// 	selfhelp: "Hyvinvointi & elämäntaito",
	// 	nonfiction: "Romaanit",
	// 	language: "Kielet",
	// 	thriller: "Jännitys",
	// 	teen: "Nuoret",
	// 	harlequin: "Harlequin",
	// 	feature: "Feature",
	// 	religion: "Henkinen kasvu",
	// 	romance: "Romantiikka",
	// },
};
