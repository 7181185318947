import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

import { base } from "astrid-firebase";
import DocTitle from "astrid-web/src/components/DocTitle";

import PublisherBlurbs from "./PublisherBlurbs";
import PublisherCurrencies from "./PublisherCurrencies";
import PublisherDistribution from "./PublisherDistribution";
import PublisherProductionMeta from "./PublisherProductionMeta";

class PublisherSettings extends Component {
	state = {};

	UNSAFE_componentWillMount() {
		// what publisher?
		const pid = this.props.match.params.orgId;
		this.setState({
			pid,
		});

		// get publisher
		base.bindDoc("organizations/" + pid, {
			context: this,
			state: "publisher",
			then() {},
			onFailure(err) {
				console.log("pub sett mount", err);
			},
		});
	}

	render() {
		const { pid, publisher } = this.state;

		return this.state.publisher ? (
			<>
				<DocTitle title={publisher.name} />
				<h1>{publisher.name}</h1>
				<Segment padded>
					<PublisherDistribution
						singleLanguage={
							(!(publisher.languageOptions || []).length && publisher.languageDefault) || false
						}
						pid={pid}
						publisher={publisher}
						distributionOptions={publisher.distributionOptions}
						saveOptions={(distributionOptions) => {
							const pid = this.props.match.params.orgId;
							base.updateDoc("organizations/" + pid, { distributionOptions });
						}}
					/>
				</Segment>
				<PublisherCurrencies pid={pid} publisher={publisher} />
				<Segment padded>
					<PublisherBlurbs
						template
						pid={pid}
						publisher={publisher}
						singleLanguage={
							(!(publisher.languageOptions || []).length && publisher.languageDefault) || false
						}
					/>
				</Segment>
				<PublisherProductionMeta pid={pid} publisher={publisher} />
			</>
		) : (
			<h1>Loading...</h1>
		);
	}
}

export default PublisherSettings;
