import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import DropdownMenu from "astrid-web/src/features/ui/components/DropdownMenu/DropdownMenu";

export default function MyProductionsDropdownMenu({ production }) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			{() => (
				<Dropdown.Item as={Link} to={`/production/${production?.id}`}>
					{t("toProduction", "To production")}
				</Dropdown.Item>
			)}
		</DropdownMenu>
	);
}
