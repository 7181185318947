import { useTranslation } from "react-i18next";

import { useProfile } from "../../../../authentication/state/profile";
import useHasOrganizationRole from "../../../../authorization/hooks/useHasOrganizationRole";
import Form from "../../../../forms/components/Form/Form";

export default function CalendarFiltersHideUnavailableCheckbox({ organization, form }) {
	const { t } = useTranslation();
	const user = useProfile();
	const hasOrganizationRole = useHasOrganizationRole({ organization, user });

	if (!hasOrganizationRole("producerRecorder") || !form.isNoEngineerBookedActive) {
		return null;
	}

	return (
		<Form.Checkbox
			name="hideUnavailable"
			label={t("hideUnavailableSessions", "Hide unavailable sessions")}
			shouldUnregister={false}
		/>
	);
}
