import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import SuperAdminProductionPriceView from "../views/SuperAdminProductionPriceView/SuperAdminProductionPriceView";

export default function SuperAdminProductionPrice() {
	const { id, productionId } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("costs.admin")) {
		return <Unauthorized />;
	}

	return <SuperAdminProductionPriceView productionId={productionId} id={id} />;
}
