import { useState } from "react";
import { Dropdown } from "semantic-ui-react";

import OfferForm from "../../OfferForm/OfferForm";

export default function PrepareOfferDropdownItem({ type, offer, production, text, ...props }) {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Dropdown.Item text={text} onClick={() => setOpen(true)} {...props} />

			{open && <OfferForm type={type} offer={offer} production={production} onClose={() => setOpen(false)} />}
		</>
	);
}
