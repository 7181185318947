import { Label } from "semantic-ui-react";

import taskStatusColors from "../../utils/taskStatusColors";

import TaskStatus from "./TaskStatus";

export default function TaskStatusLabel({ task, ...props }) {
	return (
		<Label color={taskStatusColors[task.status]} {...props}>
			<TaskStatus task={task} />
		</Label>
	);
}
