import { useTranslation } from "react-i18next";

export default function useGenderOptions() {
	const { t } = useTranslation();

	return [
		{ key: "male", value: "male", text: t("male", "Male") },
		{ key: "female", value: "female", text: t("female", "Female") },
		{ key: "other", value: "other", text: t("other", "Other") },
	];
}
