import msToTime from "./msToTime";

export function formatCostToTime({ cost }) {
	const unit = cost?.price?.unit;
	switch (unit) {
		default:
			return "-";
		case "time":
		case "activity":
			return msToTime(cost.amount * 60000, false, true);
		case "hour":
			return msToTime(cost.amount * 3600000, false, true);
	}
}
