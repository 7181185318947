import { Navigate, Outlet } from "react-router-dom";

import Messages from "../../../../components/Messages/Messages";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";
import { useUser } from "../../../authentication/state/user";

import MainMenu from "../../components/MainMenu/MainMenu";

export default function SiteLayout() {
	const user = useUser();
	const profile = useProfile();

	if (user === null) {
		return <Navigate to="/login" />;
	}

	return (
		<LoadingContext data={user} loading={user === undefined || !profile}>
			<header className="site-header">
				<MainMenu />
			</header>

			<Messages />

			<Outlet />
		</LoadingContext>
	);
}
