import { useNavigate } from "react-router-dom";

import InstantSearchDataTable from "../../../ui/components/InstantSearch/InstantSearchDataTable";

import useArticleBundlesTableColumns from "../../hooks/useArticleBundlesTableColumns";

export default function ArticlesInstantSearchDataTable({ organization, ...props }) {
	const navigate = useNavigate();

	const columns = useArticleBundlesTableColumns();

	return (
		<InstantSearchDataTable
			indexName="articleBundles"
			columns={columns}
			configure={{
				filters: `publisher.id:${organization.id}`,
			}}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/bundles/${id}`)}
			{...props}
		/>
	);
}
