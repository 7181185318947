import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import ProducerAdminLayout from "../layouts/ProducerAdminLayout/ProducerAdminLayout";

export default function ProducerAdmin(props) {
	const hasRight = useHasRight();

	if (!hasRight("admin")) {
		return <Unauthorized />;
	}

	return <ProducerAdminLayout {...props} />;
}
