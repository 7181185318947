import React from "react";

import { useProfile } from "../../authentication/state/profile";

import VendorSettings from "../components/VendorSettings/VendorSettings";

export default function Vendors() {
	const profile = useProfile();
	return <VendorSettings uid={profile.id} type="user" currentVendors={profile.vendors} />;
}
