import pipelineStatuses from "astrid-firestore/src/api/pipelines/constants/pipelineStatuses";

const pipelineStatusColors = {
	[pipelineStatuses.DISABLED]: "black",
	[pipelineStatuses.PENDING]: "blue",
	[pipelineStatuses.RUNNING]: "yellow",
	[pipelineStatuses.COMPLETED]: "green",
	[pipelineStatuses.FAILED]: "red",
};

export default pipelineStatusColors;
