import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "semantic-ui-react";

import todoPriorityColors from "../../../../todos/constants/todoPriorityColors";
import useTodoPriorityTranslations from "../../../../todos/hooks/useTodoPriorityTranslations";
import Popup from "../../../../ui/components/Popup/Popup";

export default function TodoPriorityDropdown({ priority, todos, onClick }) {
	const { t } = useTranslation();

	const priorityTranslations = useTodoPriorityTranslations();

	const options = [...new Set(todos.map((todo) => todo.priority))].map((priority) => ({
		key: priority,
		value: priority,
		text: priorityTranslations[priority],
	}));

	return (
		<Dropdown
			icon={false}
			floating
			trigger={
				<Popup
					content={priority ? priorityTranslations[priority] : t("filterByPriority", "Filter by priority")}
					trigger={<Icon color={priority && todoPriorityColors[priority]} link name="exclamation circle" />}
				/>
			}
		>
			<Dropdown.Menu>
				<Dropdown.Item text={t("all", "All")} onClick={() => onClick(null)} />
				{options.map((option) => (
					<Dropdown.Item
						key={option.key}
						text={option.text}
						onClick={() => onClick(option.value)}
						active={priority === option.value}
						style={{
							color: priority === option.value && "#2185d0",
							fontWeight: priority === option.value && "normal",
						}}
					/>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}
