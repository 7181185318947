import React from "react";

import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import Flex from "../../../../../components/Flex/Flex";
import OfferDiscussionIcon from "../../../../offers/components/OfferDiscussion/OfferDiscussionIcon";
import OfferDropdownMenu from "../../../../offers/components/OfferDropdownMenu/OfferDropdownMenu";
import OfferHeader from "../../../../offers/components/OfferHeader/OfferHeader";
import OfferLabel from "../../../../offers/components/OfferLabel/OfferLabel";
import OfferStatusLabel from "../../../../offers/components/OfferStatus/OfferStatusLabel";
import OfferStatusPopup from "../../../../offers/components/OfferStatus/OfferStatusPopup";
import useProductionTeamMember from "../../../../productions/hooks/useProductionTeamMember";
import Accordion from "../../../../ui/components/Accordion/Accordion";
import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function VoiceOffer({ offer, production, ...props }) {
	const member = useProductionTeamMember({
		production,
		role: offer.role,
		userId: offer.secondParty.id,
	});

	return (
		<Accordion.Title {...props}>
			<Flex style={{ gap: 12, justifyContent: "flex-start", whiteSpace: "nowrap" }}>
				<ImageAvatar src={offer.secondParty.img} />
				<OfferHeader offer={offer} member={member} />
			</Flex>

			<Flex style={{ gap: 12, justifyContent: "flex-end", whiteSpace: "nowrap" }}>
				<OfferStatusPopup offer={offer}>
					<OfferStatusLabel offer={offer} />
				</OfferStatusPopup>

				<OfferLabel offer={offer} />

				<div>
					{offer.status !== offerStatuses.INITIAL && <OfferDiscussionIcon offer={offer} />}{" "}
					<OfferDropdownMenu offer={offer} production={production} />
				</div>
			</Flex>
		</Accordion.Title>
	);
}
