import { Card } from "semantic-ui-react";

import Form from "../../../forms/components/Form/Form";

import AgreementTemplateFormActions from "./AgreementTemplateFormActions";
import AgreementTemplateAppendixesForm from "./forms/AgreementTemplateAppendixesForm";
import AgreementTemplateNameForm from "./forms/AgreementTemplateNameForm";
import AgreementTemplatePricesForm from "./forms/AgreementTemplatePricesForm";
import AgreementTemplateSettingsForm from "./forms/AgreementTemplateSettingsForm";
import useAgreementTemplateForm from "./hooks/useAgreementTemplateForm";

export default function AgreementTemplateForm({ organization, afterSubmit }) {
	const form = useAgreementTemplateForm({ organization });

	return (
		<Form.FormProvider form={form} afterSubmit={afterSubmit}>
			<Card.Group>
				<AgreementTemplateNameForm />
				<AgreementTemplateSettingsForm />
				<AgreementTemplateAppendixesForm />
				<AgreementTemplatePricesForm />
				<AgreementTemplateFormActions />
			</Card.Group>
		</Form.FormProvider>
	);
}
