import keyBy from "lodash/keyBy";
import React from "react";
import { flushSync } from "react-dom";

import { base, db, firebase } from "astrid-firebase";
import permissions from "astrid-permissions/permissions";
import { prioRoles } from "astrid-permissions/prio";

import { getProfile } from "../features/authentication/state/profile";

const Store = React.createContext();

class StoreProvider extends React.Component {
	state = {
		currentTimeOffset: 0, // sync with server on request

		live: null, // live or stage server?
		user: null, // firebase auth user
		profile: null, // earselect profile

		organizations: null, // all organizations,

		users: null, // all users,
		userQueries: {}, // user query results,

		productions: null, // all productions,
		productionQueries: {}, // production query results,
		producerId: null, // currently selected producer organization id
	};

	gotAll = {
		organizations: false,
		productions: false,
		users: false,
	};
	rebaseBindings = [];

	componentDidMount() {
		console.log("Setup context");

		// sync time from server
		const getServerTime = firebase.functions().httpsCallable("serverTime");
		getServerTime().then((result) => {
			this.setState({ currentTimeOffset: result.data.date - +new Date() });
		});
	}

	getUserName(userId, users) {
		const user = (users || this.state.users)[userId];
		const name = user ? user.firstName + " " + user.lastName : undefined;
		return name;
	}

	getServerTime = () => {
		return +new Date() + this.state.currentTimeOffset;
	};

	getOrganizations = () =>
		new Promise((resolve, reject) => {
			if (!this.gotAll.organizations) {
				this.gotAll.organizations = true;

				console.log("📂 Orgs query");
				const bind = base.listenToCollection("organizations", {
					context: this,
					state: "organizations",
					withIds: true,
					then(data) {
						// if you're just a publisher, you can't see other publisher orgs
						const perms = this.state.profile.permissions;
						if (!perms.producerIndex && perms.publisherIndex) {
							data.organizations = data.organizations.filter(
								(org) => org.type === "producer" || perms.publisher[org.id],
							);
						}

						const keyed = keyBy(data.organizations, "id");

						this.setState({ organizations: keyed });
						resolve(this.state.organizations);
					},
					onFailure(err) {
						console.log("Context organizations err", err);

						// reset (for instance on logout)
						this.setState({ organizations: null });
						this.gotAll.organizations = false;
						reject(err);
					},
				});

				this.rebaseBindings.push(bind);
			} else {
				resolve(this.state.organizations);
			}
		});

	getOrganization = (id) =>
		new Promise((resolve, reject) => {
			const org =
				this.state.organizations && Object.values(this.state.organizations).find((org) => org.id === id);
			if (org) {
				// return it
				resolve(org);
			} else if (!this.gotAll.organizations) {
				// get all and run this function again
				this.getOrganizations().then(() => resolve(this.getOrganization(id)));
			} else {
				// no such organization
				reject(false);
			}
		});

	getProductions = ({ filterStatus, filterPublisher, adminAlerts } = {}) =>
		new Promise((resolve, reject) => {
			// check permissions, build queries
			const profile = this.state.profile || getProfile();
			const perms = profile.permissions,
				languageAccess = profile.languageAccess,
				uid = this.state.user.uid,
				queries = [];

			if (!perms) reject("No production permissions?");

			// producer and publisher roles
			if (perms.producer)
				Object.keys(perms.producer).forEach((key) => {
					queries.push(["producer", "==", key]);
				});
			if (perms.publisher)
				Object.keys(perms.publisher).forEach((key) => {
					queries.push(["publisher", "==", key]);
				});

			// booked reader
			if (perms.reader) queries.push(["reader", "array-contains", this.state.user.uid]);

			let adminFlags = [
				"lastCommentPublisher",
				"adminAttention",
				"adminDownloadExport",
				"bookedSessionMissingRecorder",
			];
			if (adminAlerts) {
				console.log("ADMIN");
				adminFlags.forEach((flag) => {
					const op = flag === "adminDownloadExport" ? "array-contains-any" : ">";
					const val =
						flag === "adminDownloadExport"
							? [
									"normal artikel: bonnierWav",
									"normal artikel: bonnierMp3",
									"normal artikel: publit",
									"normal artikel: storytool",
									"delartikel: bonnierWav",
									"delartikel: bonnierMp3",
									"delartikel: publit",
									"delartikel: storytool",
							  ]
							: new Date("2000-01-01");
					queries.push([flag, op, val]);
				});
			}

			queries.forEach((query) => {
				console.log("📂 Prod query", query);

				// current publisher id
				let producerId = query[0] === "producer" ? query[2] : false;

				if (this.state.user) {
					let dbCollection = db
						.collection("productions")
						.where("deleted", "==", false)
						.where(...query);

					// can only query one "IN" per query, filter on clients instead
					if (filterStatus && !adminFlags.includes(query[0])) {
						dbCollection = dbCollection.where("status", "in", filterStatus);
					} else if (filterPublisher) {
						dbCollection = dbCollection.where("publisher", "in", filterPublisher);
					} else if (languageAccess && languageAccess.length && !filterStatus && !filterStatus) {
						dbCollection = dbCollection.where("language", "in", languageAccess);
					}

					dbCollection
						.get()
						.then((querySnapshot) => {
							const docs = [];

							querySnapshot.forEach(function (doc) {
								const data = doc.data();
								data.id = doc.id;
								data.ref = doc.ref;
								docs.push(data);
							});

							return docs;
						})
						.then((data) => {
							const prodPerms = producerId && perms.producer && perms.producer[producerId];
							const isSubcontractor = prodPerms?.includes?.("producerSubcontractor");
							const isAdmin = prodPerms?.includes?.("producerAdmin");

							// temp fix for combined language and status filters
							if (languageAccess && languageAccess.length && filterStatus) {
								data = data.filter((prod) => languageAccess.includes(prod.language));
							}

							// filter for current producer only
							if (this.state.producerId) {
								data = data.filter((prod) => prod.producer === this.state.producerId);
							}

							if (isSubcontractor && !isAdmin) {
								data = data.filter((prod) => prod.subcontractor && prod.subcontractor === uid);
							}

							if (perms.reader && !prodPerms) {
								// filter readers declined and draft and stuff
								console.log("Filter reader prods");
								data = data.filter(
									(prod) =>
										!["draft", "offer"].includes(prod.status) &&
										prod.readerStatus &&
										prod.readerStatus[this.state.user.uid] &&
										!(prod.readerStatus[this.state.user.uid].status === "denied"),
								);
							}

							// filter access for limited producer user
							if (prodPerms && prodPerms.includes("producerLimited")) {
								console.log("Filter limited user prods");

								data = data.filter(
									(prod) =>
										(prod.manager && prod.manager.includes(uid)) ||
										(prod.proofer && prod.proofer.includes(uid)) ||
										(prod.reader && prod.reader.includes(uid)) ||
										(prod.bookedRecorders && prod.bookedRecorders.includes(uid)) ||
										(prod.editor && prod.editor.includes(uid)),
								);
							}

							// filter access for proofers, recorders and editors
							const canOnlyListAcceptedProductions =
								prodPerms &&
								!prodPerms.find(
									(role) =>
										role !== "any" &&
										permissions[role] &&
										permissions[role].includes("productions.listUnacceptedProductions"),
								);

							if (canOnlyListAcceptedProductions && !isSubcontractor) {
								data = data.filter(
									(prod) =>
										(prod.proofer && prod.proofer.includes(uid)) ||
										(prod.editor && prod.editor.includes(uid)) ||
										(["accepted", "production"].includes(prod.status) &&
											(prod.productionDate || prod.status === "production") &&
											((prodPerms.includes("producerProofer") &&
												!(prod.proofer && prod.proofer.length)) ||
												(prodPerms.includes("producerEditor") &&
													!(prod.editor && prod.editor.length)))),
								);
							}

							// filter prioritized productions if none of the users roles are prioritized
							if (
								prodPerms &&
								!prodPerms.find(
									(role) => role !== "any" && role !== "producerLimited" && !prioRoles.includes(role),
								)
							) {
								data = data.filter(
									(prod) =>
										(prodPerms.includes("producerProofer") &&
											(!prod.prooferPrio || (prod.proofer && prod.proofer.includes(uid)))) ||
										(prodPerms.includes("producerEditor") &&
											(!prod.editorPrio || (prod.editor && prod.editor.includes(uid)))) ||
										(prodPerms.includes("producerRecorder") &&
											(!prod.recorderPrio ||
												prod.bookedSessionMissingRecorder ||
												prod.bookedSessionMissingRecorderPrio ||
												(prod.bookedRecorders && prod.bookedRecorders.includes(uid)))),
								);
							}

							// store with key as id
							const keyed = keyBy(data, "id");

							const productionQueries = {
								...this.state.productionQueries,
								[query[0] + "_" + query[2]]: keyed, // "unique" query key
							};

							// save to state
							flushSync(() => {
								this.setState({
									productionQueries,
									productions:
										productionQueries &&
										Object.values(productionQueries).reduce(
											(prev, curr) => ({ ...prev, ...curr }),
											{},
										),
								});
							});

							// resolve promise (first one only... TODO: should do a Promise.all here)
							resolve(this.state.productions);
						})
						.catch((err) => {
							console.log("Context productions err", err, query);

							// reset (for instance on logout)
							this.setState({ gotAllProductions: false, productions: null });
							reject(err);
						});
				}
			});
		});

	getUsers = async () => {
		const users = {};
		const { permissions } = this.state.profile;

		const snapshot = (await db.collection("users").get()).docs.map((doc) => ({ id: doc.id, ...doc.data() }));

		const producerPublishers = this.state.organizations?.[this.state.producerId]?.publishers;

		for (const user of snapshot) {
			if (permissions.producer && this.state.producerId) {
				for (const producerId of Object.keys(permissions.producer)) {
					if (producerId === this.state.producerId && user?.permissions?.producer?.[producerId]) {
						users[user.id] = user;
					}

					if (producerId === this.state.producerId && user?.permissions?.reader?.includes?.("allProducers")) {
						users[user.id] = user;
					}
				}
			}

			if (producerPublishers) {
				producerPublishers.forEach((pub) => {
					if (user?.permissions?.publisher?.[pub]?.includes?.("any")) {
						users[user.id] = user;
					}
				});
			}

			// publisher users
			if (permissions.publisher) {
				// get all coworkers
				for (const publisherId of Object.keys(permissions.publisher)) {
					if (user?.permissions?.publisher?.[publisherId]?.includes?.("any")) {
						users[user.id] = user;
					}

					// get all publisher exclusive or general readers
					if (user?.permissions?.reader?.includes?.("allPublishers")) {
						users[user.id] = user;
					}
				}

				if (user?.permissions?.producerIndex === true) {
					users[user.id] = user;
				}
			}

			if (permissions.reader && !permissions.producer && !permissions.publisher) {
				users[user.id] = user;
			}
		}

		// reader (get all for now... TODO)

		this.setState({
			users,
		});
	};

	setProducerId = (orgId) => {
		if (this.state.producerId !== orgId) {
			this.gotAll = {
				organizations: false,
				productions: false,
				users: false,
			};

			this.setState({
				producerId: orgId,
				producerType: null,
				producerPublishers: null,
				users: null,
				userQueries: null,
				organizations: null,
				productions: null,
				productionQueries: null,
			});
		}
	};

	unbindAll = () => {
		this.setState({
			currentTimeOffset: 0, // sync with server on request

			live: null, // live or stage server?
			user: null, // firebase auth user
			profile: null, // earselect profile

			organizations: null, // all organizations,

			users: null, // all users,
			userQueries: {}, // user query results,

			productions: null, // all productions,
			productionQueries: {}, // production query results,
		});
		this.rebaseBindings.forEach((binding) => {
			base.removeBinding(binding);
		});
	};

	setStoreState = (data) => {
		return this.setState(data);
	};

	render() {
		const context = {
			state: this.state,
			setState: this.setStoreState,
			// self: this,
			getServerTime: this.getServerTime,
			getOrganizations: this.getOrganizations,
			getOrganization: this.getOrganization,
			getProductions: this.getProductions,
			getUsers: this.getUsers,
			setProducerId: this.setProducerId,
			unbindAll: this.unbindAll,
			getUserName: this.getUserName,
		};

		return <Store.Provider value={context}>{this.props.children}</Store.Provider>;
	}
}

// HOC, returns a component wrapped in the Store context
const withStore = (Component) => (props) =>
	<Store.Consumer>{(context) => <Component {...props} store={context} />}</Store.Consumer>;

const withStoreWithoutUsers = (Component) => (props) =>
	(
		<Store.Consumer>
			{(context) =>
				React.useMemo(() => <Component {...props} store={{ state: context.state }} />, [context.state])
			}
		</Store.Consumer>
	);

export { Store, StoreProvider, withStore, withStoreWithoutUsers };
