import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../authorization/hooks/useHasRight";

import OrganizationAdminSidebar from "../../components/OrganizationAdminSidebar";

export default function PublisherAdminSidebar() {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const location = useLocation();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const { pathname } = location;

	const options = [
		{
			path: "users/list",
			name: t("users", "Users"),
			active: pathname.includes("admin/users"),
			hidden: !hasRight("users.view"),
		},
		{
			path: "authors",
			name: t("authors", "Authors"),
			active: pathname.includes("admin/authors"),
			hidden: !hasRight("authors.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "articles",
			name: t("articles", "Articles"),
			hidden: !hasRight("articles.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
			active: pathname.includes("admin/articles"),
			options: [
				{
					path: "articles",
					active: pathname.includes("admin/articles") && !pathname.includes("bundles"),
					name: t("allArticles", "All articles"),
				},
				{
					path: "articles/bundles",
					name: t("bundles", "Bundles"),
				},
			],
		},
		{
			path: "titles",
			name: t("titles", "Titles"),
			active: pathname.includes("admin/titles"),
			hidden: !hasRight("titles.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "translators",
			name: t("translators", "Translators"),
			active: pathname.includes("admin/translators"),
			hidden: !hasRight("translators.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "series",
			name: t("serie", "Series"),
			active: pathname.includes("admin/series"),
			hidden: !hasRight("series.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "imprints",
			name: t("imprints", "Imprints"),
			active: pathname.includes("admin/imprints"),
			hidden: !hasRight("imprints.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "artifacts/list",
			name: t("artifacts", "Artifacts"),
			active: pathname.includes("admin/artifacts"),
			hidden: !hasRight("artifacts.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "distributions",
			name: t("distributions", "Distributions"),
			active: pathname.includes("admin/distributions"),
			hidden: !hasRight("distributions.view") || !hasFlatteningFeature, // TODO: Remove feature flag check
		},
		{
			path: "settings/language",
			name: t("settings", "Settings"),
			hidden: !hasRight("settings.view"),
			options: [
				{
					path: "settings/language",
					name: t("language", "Language"),
				},
				{
					path: "settings/finance",
					name: t("finance", "Finance"),
				},
				{
					path: "settings/vendors",
					name: t("vendors", "Vendors"),
				},
			],
		},
	];

	return <OrganizationAdminSidebar options={options} />;
}
