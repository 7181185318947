import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";

import { useProfile } from "../../../authentication/state/profile";
import { useOrganizationType } from "../../../organizations/state/organizationType";

export default function AccountSidebar() {
	const { t } = useTranslation();
	const organizationType = useOrganizationType();

	const profile = useProfile();

	const options = useMemo(
		() => [
			{
				path: "vendor-settings",
				name: t("vendorSettings", "Billing information"),
			},
			{
				path: "revenues",
				name: t("revenues", "Revenues"),
				hidden: organizationType === "narrator",
			},
			{
				path: "orders",
				name: t("POs"),
				hidden: organizationType === "narrator",
			},
			{
				path: "agreements",
				name: t("agreements", "Agreements"),
			},
			{
				path: "settings",
				name: t("settings", "Settings"),
			},
		],
		[organizationType, t],
	);

	return (
		<>
			<Message>
				<Message.Header>
					{profile.firstName} {profile.lastName}
				</Message.Header>
			</Message>
			<VerticalMenu options={options} />
		</>
	);
}
