import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Divider, Form, Header, Icon } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import UploadButton from "astrid-web/src/components/UploadButton";

import DateTime from "../../../features/ui/components/DateInput/DateTime";

class ProductionMeta extends Component {
	state = {
		uploads: {},
		count: 0,
	};
	update = (e, data) => {
		const meta = this.props.production.meta ? { ...this.props.production.meta } : {};

		if (data.type && data.type === "checkbox") {
			e = null;
			data.value = data.checked;
		}
		meta[data.name] = data.value;

		this.props.handleChange(e, { name: "meta", value: meta });
	};

	uploadFile = (e) => {
		const uploads = { ...this.state.uploads };
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-secret" : "gs://earselect-secret");

		// file from input
		const file = e.target.files[0];
		const name = e.target.name;

		if (!file) return;

		const suffix = file.name.split(".").pop();

		// start upload
		const uploadTask = storageRef
			.child("meta/" + this.props.production.id + "/" + this.props.production.title + "-" + name + "." + suffix)
			.put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				uploads[name] = progress + "%";
				this.setState({ uploads });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.update(null, { name: name, value: downloadURL });
					uploads[name] = null;
					this.setState({ uploads });
				});
			},
		);
	};

	render() {
		const { t, production, publisher } = this.props;

		const metaData = production.meta || {};
		let meta = publisher?.productionMeta;

		// filter out visible meta for producer
		if (this.props.permissions?.isProducer) {
			const newMeta = {};
			for (const key in meta) {
				const el = meta[key];
				if (el.prodVisible) newMeta[key] = meta[key];
			}
			meta = newMeta;
		}

		// stop if no meta
		if (!meta || !Object.keys(meta).length) return null;

		// sort based on sorting number
		const sortedArray = Object.keys(meta).sort((a, b) => (meta[a].sort > meta[b].sort ? 1 : -1));

		return (
			<>
				<Divider />
				<Header as="h4" icon="checkmark box" content={t("extraPublisherField")} />
				<Form>
					{sortedArray.map((key) => (
						<Form.Group key={key}>
							{meta[key].type === "text" && !meta[key].textRows && (
								<Form.Input
									width={16}
									label={meta[key].name}
									value={metaData[key] || ""}
									name={key}
									onChange={this.update}
									placeholder={meta[key].name}
								/>
							)}
							{meta[key].type === "text" && meta[key].textRows && (
								<Form.TextArea
									width={16}
									label={meta[key].name}
									value={metaData[key] || ""}
									name={key}
									onChange={this.update}
									placeholder={meta[key].name}
								/>
							)}
							{meta[key].type === "checkbox" && (
								<>
									<Icon
										size="large"
										name={meta[key].icon || "checkmark"}
										color={meta[key].color || "green"}
										disabled={!metaData[key]}
									/>
									<div style={{ display: "inline-block" }}>
										<Form.Checkbox
											label={meta[key].name}
											checked={!!metaData[key]}
											name={key}
											onChange={this.update}
										/>
									</div>
								</>
							)}
							{meta[key].type === "select" && (
								<Form.Select
									label={meta[key].name}
									options={
										meta[key].selectOptions
											? meta[key].selectOptions.map((option) => ({
													text: option,
													value: option,
											  }))
											: []
									}
									value={metaData[key]}
									name={key}
									onChange={this.update}
								/>
							)}

							{meta[key].type === "date" && (
								<div className="field">
									<label>{meta[key].name}</label>
									<DateTime
										value={metaData[key] ? moment(metaData[key]).format("YYYY-MM-DD") : ""}
										timeFormat={false}
										onChange={(dt) => {
											if (typeof dt === "object") {
												// valid date, save it
												this.update(null, {
													name: key,
													type: "date",
													value: dt.format("YYYY-MM-DD"),
												});
											}
										}}
										onBlur={(dt) => {
											const writtenDate = moment(dt).format("YYYY-MM-DD");
											// see if typed date is valid and not the same as currently is in the db
											if (
												writtenDate !== "Invalid date" &&
												writtenDate !== moment(metaData[key]).format("YYYY-MM-DD")
											) {
												// valid date, save it
												this.update(null, {
													name: key,
													type: "date",
													value: dt.format("YYYY-MM-DD"),
												});
											} else {
												console.log("Invalid date", dt);
											}
										}}
									/>
								</div>
							)}

							{meta[key].type === "file" && (
								<>
									<UploadButton
										label={meta[key].name}
										text={t("upload")}
										name={key}
										onUpload={this.uploadFile}
									/>

									{this.state.uploads[key] ||
										(metaData[key] && (
											<a
												href={metaData[key]}
												target="_blank"
												rel="noopener noreferrer"
												style={{ verticalAlign: "middle", marginTop: "2.4em" }}
											>
												<Icon name="file" />
												{t("uploadedFile")}
											</a>
										))}
								</>
							)}
						</Form.Group>
					))}
				</Form>
			</>
		);
	}
}

export default withTranslation()(ProductionMeta);
