export default function useEngineerResources({ engineers }) {
	const resources = engineers
		.filter((engineer) => Object.values(engineer.vendors || {})[0]?.city)
		.map((engineer) => ({
			id: engineer.id,
			title: engineer.firstName,
			group: Object.values(engineer.vendors)[0].city,
			engineer,
		}));

	return { resources, resourceColors: {} };
}
