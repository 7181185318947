import React, { useEffect, useRef } from "react";
import { Icon } from "semantic-ui-react";

const StickyAudio = ({ file, start, length, onClose }) => {
	const audio = useRef();

	useEffect(() => {
		const audioEl = audio.current;

		return () => {
			// force unload to kill zombie audio players in Safari
			audioEl.removeAttribute("src");
			audioEl.load();
		};
	}, []);

	const onCanPlay = () => {
		// go to start position then play
		if (audio.current.paused) {
			audio.current.currentTime = start / 1000;
			audio.current.play();
		}
	};

	const onTimeUpdate = () => {
		if (!audio.current.paused && audio.current.currentTime > (start + length) / 1000) {
			audio.current.pause();
			onClose();
		}
	};

	return (
		<div className="sticky-audio">
			<a
				href="/"
				onClick={(e) => {
					e.preventDefault();
					onClose();
				}}
			>
				<Icon name="remove" color="black" size="large" />
			</a>
			<audio
				ref={audio}
				src={file}
				controls
				preload="auto"
				autoPlay={!start}
				style={{ verticalAlign: "middle", width: "90%", maxWidth: 800 }}
				onEnded={onClose}
				onCanPlay={!!start ? onCanPlay : null}
				onTimeUpdate={!!length ? onTimeUpdate : null}
			/>
		</div>
	);
};

export default StickyAudio;
