const config = {
	live: {
		apiKey: "AIzaSyC2BCKD0yWEoYS3z4OsYL5kIgjecuPy4eg",
		authDomain: "earselect.firebaseapp.com",
		databaseURL: "https://earselect.firebaseio.com",
		projectId: "earselect",
		storageBucket: "earselect.appspot.com",
		messagingSenderId: "79168562501",
	},
	stage: {
		apiKey: "AIzaSyAjktpxRWEIrW7gnWlEMCCnBaCHqFPkyOI",
		authDomain: "stage-earselect.firebaseapp.com",
		databaseURL: "https://stage-earselect.firebaseio.com",
		projectId: "stage-earselect",
		storageBucket: "stage-earselect.appspot.com",
		messagingSenderId: "188898605835",
	},
};

export default config;
