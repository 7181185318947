export default function analyzeSilences(
	lastSparse,
	lastDisc,
	discDuration,
	lastOutroSilence,
	silences,
	partStart,
	nextPartStartsWithSilence,
	file,
	info,
	overrides,
	sentenceSilence,
	chapterSilence,
	duration,
) {
	let lastSentence = null;
	let outroSilence = 0;
	const notSparseEnough = [];

	const analyzedSilences = silences.map((silence, index) => {
		const override = overrides[file] || {};

		// ignore first silence of the first part
		const isFirstSilence = silence.start === 0 && !lastSparse;

		// is it the last silence of the part? ignore if next part starts with silence (or if there is no next part), keep value and add to next part
		if (silence.end > info.duration - 0.1 && nextPartStartsWithSilence) outroSilence = silence.duration;

		// is it a chapter size silence?
		const isChapter =
			((!isFirstSilence &&
					((silence.duration > chapterSilence && !outroSilence) ||
						// add last parts outro silence to this parts intro silence?
						(!index &&
							silence.start === 0 &&
							lastOutroSilence &&
							silence.duration + lastOutroSilence > chapterSilence))) ||
				(override.chapter && override.chapter.includes(index))) &&
			// not overridden?
			!(override.off && override.off.includes(index)) &&
			!(override.sentence && override.sentence.includes(index));

		// is it a sentence size silence?
		const isSentence =
			!isFirstSilence &&
			!isChapter &&
			silence.duration > sentenceSilence &&
			// not overridden?
			!(override.off && override.off.includes(index));

		// is it far enough from last split to be active?
		const isSparse =
			isSentence &&
			!outroSilence &&
			(silence.start + partStart > duration + lastSparse ||
				// or overridden?
				(override.sentence && override.sentence.includes(index)));

		// store sparse position for use in next part
		if (isSparse || isChapter) {
			lastSparse = partStart + silence.end;
		}

		// store sparse sentence index, check if it's too close to chapter
		if (isSparse) lastSentence = index;
		else if (isChapter && lastSentence && silence.start - silences[lastSentence].end < duration / 2)
			notSparseEnough.push(lastSentence);

		// is it a disc intro or split?
		const isDiscIntro = override.intro && override.intro.includes(index);
		const isDiscSplit = discDuration && override.disc && override.disc.includes(index);

		if (isDiscSplit) lastDisc = partStart + silence.end;

		const split = isDiscIntro
			? "intro"
			: isDiscSplit
				? "disc"
				: isChapter
					? "chapter"
					: isSentence && isSparse
						? "sentence"
						: "";

		// MEMO: spreading breaks webpack build for unknown reason..!
		// return { ...silence, split, index };
		return Object.assign({ split, index }, silence);
	});

	// remove sentence splits that are too close
	notSparseEnough.forEach((val) => {
		analyzedSilences[val].split = "";
	});

	return { analyzedSilences, lastSparse, lastDisc, outroSilence };
};
