import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, language, nullable, record, ref, string } from "../../../types/_types";

import { Pipeline } from "../../pipelines/types/Pipeline";

export const DistributionChannel = FirestoreDocument.extend({
	name: string(3),
	acceptedLanguages: array(language()),
	pipelines: record(string(), Pipeline.partial()).default({}),
	onixTemplate: nullable(string()),
}).merge(FirestoreLifecycle);

export const DistributionChannelRef = ref(DistributionChannel, {
	name: true,
});
