import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import useOfferTypeTranslations from "../../../../offers/hooks/useOfferTypeTranslations";
import { filterDuplicateKeys } from "../../../../ui/utils/filters";

export default function OfferTypeSelect({ offers }) {
	const { t } = useTranslation();
	const offerTypeTranslations = useOfferTypeTranslations();

	const offerTypeOptions = offers
		.map((offer) => ({
			key: offer.type,
			value: offer.type,
			text: offerTypeTranslations[offer.type],
		}))
		.filter(filterDuplicateKeys);

	return <Form.Select search name="offerTypes" multiple options={offerTypeOptions} label={t("offerTypes", "Type")} />;
}
