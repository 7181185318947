import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useAdminArticleBundleMenuOptions({ bundle }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	return [
		{
			to: "",
			active: pathname.endsWith(bundle.id),
			text: t("articles", "Articles"),
			label: bundle.articleIds.length,
		},
		{
			to: "distribution",
			active: pathname.endsWith("/distribution"),
			text: t("distribution", "Distribution"),
		},
	];
}
