import isAnyRecorderInTeamOrOpenPosition from "astrid-web/src/features/calendar/helpers/isAnyRecorderInTeamOrOpenPosition";

import { getCollectionData, getDocumentData, runBatch } from "../../helpers";

import createProductionPriceData from "../productions/prices/createProductionPriceData";

import createSessionData from "./data/createSessionData";
import createSessionProductionData from "./data/createSessionProductionData";

export default async function createOrUpdateSession(
	firebase,
	{ session, price, agreement, productionId, user, studio, group, reader, recorder, start, end, polish, sessions },
) {
	const db = firebase.firestore();

	const production = await getDocumentData(db.collection("productions").doc(productionId));

	if (!production) {
		throw new Error("Production not found");
	}

	const isAnyRecorderOrOpenPosition = isAnyRecorderInTeamOrOpenPosition({ recorder });

	const [producer, readerUsers, recorderUser] = await Promise.all([
		getDocumentData(db.collection("organizations").doc(production.producer)),
		reader.length > 0
			? getCollectionData(db.collection("users").where(firebase.firestore.FieldPath.documentId(), "in", reader))
			: [],
		!isAnyRecorderOrOpenPosition && getDocumentData(db.collection("users").doc(recorder)),
	]);

	if (!producer) {
		throw new Error("Producer not found");
	}

	if (readerUsers.length !== reader.length) {
		throw new Error("Readers not found");
	}

	if (!recorderUser && !isAnyRecorderOrOpenPosition) {
		throw new Error("Recorder not found");
	}

	const sessionData = createSessionData(firebase, {
		session,
		price,
		agreement,
		production,
		user,
		studio,
		group,
		reader,
		readerUsers,
		recorder,
		recorderUser,
		start,
		end,
		polish,
		sessions,
	});

	const productionData = createSessionProductionData(firebase, { production, sessionData });

	await runBatch(db, (batch) => {
		if (session?.productionPriceRef) {
			batch.delete(session.productionPriceRef);
		}

		if (price) {
			const productionPrice = createProductionPriceData(firebase, {
				...price,
				expense: true,
				approved: true,
				producer,
				agreement,
				production,
				referencePrice: price,
				session: sessionData,
			});

			batch.set(productionPrice.ref, productionPrice);

			batch.set(sessionData.ref, {
				...sessionData,
				productionPriceRef: productionPrice.ref,
			});
		} else {
			batch.set(sessionData.ref, {
				...sessionData,
				productionPriceRef: null,
			});
		}

		if (Object.keys(productionData).length) {
			batch.update(production.ref, productionData);
		}
	});

	return sessionData;
}
