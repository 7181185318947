import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

import Flex from "../../../../components/Flex/Flex";

export default function UserOption({ option, employee, missingVendor }) {
	const { t } = useTranslation();

	const style = { position: "absolute", right: 15 };

	return (
		<Flex>
			<div>{option.text}</div>

			{employee && (
				<Label size="tiny" style={style}>
					{t("employee", "Employee")}
				</Label>
			)}

			{!employee && missingVendor && (
				<Label size="tiny" style={style}>
					{t("missingVendor", "Missing vendor")}
				</Label>
			)}
		</Flex>
	);
}
