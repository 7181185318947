import { Label } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";

export default function TodoAssignedTo({ todo }) {
	const { users, userNames } = todo;

	if (!users?.length && !userNames?.length) {
		return null;
	}

	const assignedUsers = users?.length > 0 ? users : userNames?.map((name) => ({ name }));

	return (
		<Flex justifyContent="flex-start" style={{ gap: 3, marginTop: 11 }}>
			{assignedUsers.map(({ img, name }, index) => (
				<Label key={index} basic image size="small" style={{ padding: "3px 6px 3px 5px", marginLeft: 0 }}>
					<Flex>
						<img alt={name} src={img || "/img/avatar.png"} />
						<p>{name}</p>
					</Flex>
				</Label>
			))}
		</Flex>
	);
}
