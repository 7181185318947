import { useMemo } from "react";

import money from "astrid-helpers/src/money";

import { totalAccessor } from "../utils/costAccessors";

export default function useTotalOpenCost({ costs, rate, currency }) {
	return useMemo(
		() =>
			money(
				costs
					.filter((cost) => cost.exchange)
					.reduce((total, cost) => total + totalAccessor(cost) / cost.exchange.rate, 0) * rate,
				currency,
			),
		[currency, costs, rate],
	);
}
