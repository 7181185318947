import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";

import useAgreementPartOptions from "../../../hooks/useAgreementPartOptions";

export default function AgreementPartSelect(props) {
	const { t } = useTranslation();

	const agreementPartOptions = useAgreementPartOptions();

	return <Form.Select name="part" options={agreementPartOptions} label={t("agreementPart", "Part")} {...props} />;
}
