import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export default function useProductionRequestsColumns() {
	const { t } = useTranslation();
	return useMemo(() => {
		return [
			{
				id: "title",
				Header: t("title"),
				filter: "text",
				Cell: ({ value, row }) => {
					return <Link to={`/production/${row.id}`}>{value}</Link>;
				},
			},
			{
				id: "publisherName",
				Header: t("publisher"),
			},
			{
				id: "scriptDate",
				Header: t("script"),
				Cell: ({ value: scriptDate }) => {
					return (
						<p style={{ display: "flex", alignItems: "baseline" }}>
							<span style={{ whiteSpace: "nowrap" }}>{scriptDate?.date}</span>
							{scriptDate?.date && scriptDate?.isUploaded && (
								<Icon style={{ marginLeft: "5px" }} name="check" color="green" />
							)}
						</p>
					);
				},
			},
		];
	}, [t]);
}
