import { useState } from "react";
import { useInstantSearch } from "react-instantsearch";
import { Accordion, Header, Menu } from "semantic-ui-react";

import AccordionContent from "./AccordinContent";

export default function RefinementAccordion({ refinements, selectedColumns, selectColumn }) {
	const { indexUiState } = useInstantSearch();
	const [openAccordions, setOpenAccordions] = useState([]);

	function handleClick(e, titleProps) {
		const { index } = titleProps;
		if (openAccordions.includes(index)) {
			setOpenAccordions(openAccordions.filter((activeIndex) => activeIndex !== index));
		} else {
			setOpenAccordions(openAccordions.concat(index));
		}
	}

	return (
		<>
			<Accordion as={Menu} vertical fluid>
				{refinements.map((refinement) => {
					const isAccordionEdited =
						!!indexUiState?.refinementList?.[refinement.attribute] ||
						!!indexUiState?.range?.[refinement.attribute];
					return (
						<Menu.Item key={refinement.index}>
							<Accordion.Title
								active={openAccordions.includes(refinement.index)}
								content={
									<div>
										<Header as="h4" color={isAccordionEdited ? "green" : "black"}>
											{`${refinement.header} ${isAccordionEdited ? "*" : ""}`}
										</Header>
									</div>
								}
								index={refinement.index}
								onClick={handleClick}
							/>
							<Accordion.Content
								active={openAccordions.includes(refinement.index)}
								content={
									<AccordionContent
										refinement={refinement}
										selectedColumns={selectedColumns}
										selectColumn={selectColumn}
									/>
								}
							/>
						</Menu.Item>
					);
				})}
			</Accordion>
		</>
	);
}
