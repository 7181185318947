import { useEffect, useState } from "react";
import { Search } from "semantic-ui-react";

import InstantSearchData from "./InstantSearchData";

function SearchQuery({ data, getOption, onResultSelect, ...props }) {
	const options = data.map(getOption);

	return <Search results={options} onResultSelect={onResultSelect} {...props} />;
}

export default function InstantSearchQuery({ configure, value, getOption, onResultSelect, ...props }) {
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		setSearchQuery("");
	}, [value]);

	return (
		<InstantSearchData
			value={value}
			searchQuery={searchQuery}
			configure={{
				hitsPerPage: 10,
				...configure,
			}}
			{...props}
		>
			<SearchQuery
				getOption={getOption}
				onSearchChange={(e, { value }) => {
					setSearchQuery(value);
				}}
				onResultSelect={onResultSelect}
			/>
		</InstantSearchData>
	);
}
