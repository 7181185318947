import firebase from "firebase/app";

import db from "astrid-firebase/src/db";
import { priceTypeFromRole } from "astrid-firestore/src/api/prices/utils/priceMappers";
import createProductionPriceData from "astrid-firestore/src/api/productions/prices/createProductionPriceData";
import addTeamMember from "astrid-firestore/src/api/productions/team/addTeamMember";
import createTeamMemberData from "astrid-firestore/src/api/productions/team/createTeamMemberData";
import { getDocumentData } from "astrid-firestore/src/helpers";

export default async function submitMember({
	role,
	offer,
	price,
	userId,
	priceId,
	production,
	customPrice,
	agreementId,
	firstPartyId,
	firstPartyVendorId,
	member,
}) {
	const [user, firstParty, agreement] = await Promise.all([
		getDocumentData(db.collection("users").doc(userId)),
		getDocumentData(db.collection("organizations").doc(firstPartyId)),
		agreementId && getDocumentData(db.collection("agreements").doc(agreementId)),
	]);

	if (!user) throw new Error(`User ${userId} not found`);
	if (!firstParty) throw new Error(`Organization ${firstPartyId} not found`);

	const referencePrice = priceId === "custom" ? customPrice : price;

	const productionPrice = createProductionPriceData(firebase, {
		type: priceTypeFromRole[role],
		...referencePrice,
		expense: true,
		approved: true,
		agreement,
		production,
		firstParty,
		firstPartyVendorId,
		secondParty: user,
		referencePrice,
	});

	const memberData = createTeamMemberData(firebase, { role, user, agreement, production, firstParty, member });

	return await addTeamMember(firebase, { member: memberData, offer, production, productionPrice });
}
