import React from "react";
import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import ArticleVersionActiveIcon from "../components/ArticleIcons/ArticleVersionActiveIcon";

import useArticleVersionsDropdownMenuOptions from "./useArticleVersionsDropdownMenuOptions";

export default function useArticleVersionsTableColumns({ article }) {
	const { t } = useTranslation();

	return [
		{
			id: "active",
			collapsing: true,
			disableSortBy: true,
			Cell: ({ row }) => {
				return <ArticleVersionActiveIcon article={article} version={row.original} />;
			},
		},
		timestampColumn({ id: "created", Header: t("date", "Date"), collapsing: false }),
		{
			id: "createdBy.name",
			Header: t("createdBy", "Created by"),
			disableSortBy: true,
		},
		dropdownMenuColumn(useArticleVersionsDropdownMenuOptions({ article })),
	];
}
