import { memo } from "react";
import { useTranslation } from "react-i18next";
import useNetworkState from "react-use/lib/useNetworkState";
import { Message } from "semantic-ui-react";

function OfflineMessage() {
	const { online } = useNetworkState();
	const { t } = useTranslation();

	if (online) return false;

	return (
		<Message
			icon="frown"
			error
			style={{ fontWeight: "bold", textAlign: "center", zIndex: "10" }}
			content={t("areOfflineMess")}
		/>
	);
}

export default memo(OfflineMessage);
