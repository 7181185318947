import DataTable from "../../../ui/components/DataTable/DataTable";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useArtifactFilesTableColumns from "../../../artifacts/hooks/useArtifactFilesTableColumns";

import ArtifactFilesDataTableFooter from "./components/ArtifactFilesDataTableFooter";

export default function ArtifactFilesDataTable({ artifact }) {
	const columns = useArtifactFilesTableColumns();

	return (
		<LoadingContext data={artifact?.files || []}>
			<DataTable
				selectable
				data={artifact?.files}
				columns={columns}
				footer={<ArtifactFilesDataTableFooter artifact={artifact} />}
			/>
		</LoadingContext>
	);
}
