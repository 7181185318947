import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "semantic-ui-react";

import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";
import isApproved from "astrid-firestore/src/api/approval/isApproved";

import useAgreementColumns from "../../../../agreements/hooks/useAgreementColumns";
import useAgreementsQuery from "../../../../agreements/hooks/useAgreementsQuery";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import CollectionDataTable from "../../../../ui/components/CollectionDataTable/CollectionDataTable";

export default function TemplateAgreements({ template }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const navigate = useNavigate();

	const query = useAgreementsQuery({ templateId: template.id });
	const columns = useAgreementColumns();

	const disabled = !isApproved(template) || template.deleted || template.type !== agreementTypes.FRAMEWORK;

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("agreements", "Agreements")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<CollectionDataTable
					query={query}
					columns={columns}
					onClick={({ id }) => navigate(`../${id}`)}
					sortBy={{
						id: "expires",
						desc: false,
					}}
				/>
			</Card.Content>
			<Card.Content extra>
				{hasRight("agreements.send") && (
					<Button color="blue" disabled={disabled} onClick={() => navigate(`../prepare/${template.id}`)}>
						{t("prepareAgreements", "Prepare agreements")}
					</Button>
				)}
			</Card.Content>
		</Card>
	);
}
