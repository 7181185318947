import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Form from "../../../../forms/components/Form/Form";
import useVendorOptions from "../../../../vendors/hooks/useVendorOptions";

export default function FirstPartyVendorSelect({ firstParty }) {
	const { t } = useTranslation();
	const { setValue } = useFormContext();

	const vendorOptions = useVendorOptions(firstParty?.vendors);

	useEffect(() => {
		if (vendorOptions?.[0]) {
			setValue("firstPartyVendorId", vendorOptions?.[0]?.value);
		}
	}, [setValue, vendorOptions]);

	return (
		<Form.Select
			name="firstPartyVendorId"
			search
			options={vendorOptions}
			label={t("legalEntity", "Legal entity")}
			noResultsMessage={<Link to={`/producer/${firstParty?.id}/admin/settings`}>{t("addInvoicingDetails")}</Link>}
		/>
	);
}
