import useForm from "../../../../forms/hooks/useForm";

import offerDefaultValues from "../../../constants/offerDefaultValues";

const today = new Date();

export default function useOpenOfferForm() {
	return useForm({
		defaultValues: {
			expiresAt: new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() + offerDefaultValues.EXPIRES_IN_DAYS,
			),
			customPrice: {},
		},
	});
}
