import React from "react";
import { Header } from "semantic-ui-react";

import ArticleCoverImage from "../../../../articles/components/ArticleCoverImage/ArticleCoverImage";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminTitleDropdownMenuOptions from "../hooks/useAdminTitleDropdownMenuOptions";
import useAdminTitleMenuOptions from "../hooks/useAdminTitleMenuOptions";

export default function AdminTitleHeader({ title }) {
	const menuOptions = useAdminTitleMenuOptions({ title });

	const { input, loading, error, options: dropdownMenuOptions } = useAdminTitleDropdownMenuOptions({ title });

	return (
		<AdminPageHeader
			error={error}
			loading={loading}
			menuOptions={menuOptions}
			dropdownMenuOptions={dropdownMenuOptions}
		>
			{input}

			<ArticleCoverImage thumb rounded article={title} style={{ width: 80, height: 80 }} />

			<Header style={{ margin: 0 }}>{title.name}</Header>
		</AdminPageHeader>
	);
}
