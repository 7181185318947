import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import TeamPrice from "../../../../team/components/TeamPrice/TeamPrice";

import OfferDropdownMenu from "../components/OfferDropdownMenu";

export default function useApproveOffersColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "expiresAt",
				Header: t("expires", "Expires"),
				collapsing: true,
				accessor: ({ expiresAt }) => expiresAt?.toDate().toLocaleDateString(),
			},
			{
				id: "secondPartyName",
				Header: t("user", "User"),
				collapsing: true,
				accessor: ({ secondParty }) => secondParty?.name || t("openPosition", "Open position"),
			},
			{
				id: "price",
				Header: t("price", "Price"),
				collapsing: true,
				Cell: ({ row }) => <TeamPrice {...row.original} />,
			},
			{
				id: "actions",
				textAlign: "right",
				collapsing: false,
				Cell: ({ row }) => <OfferDropdownMenu offer={row.original} />,
			},
		],
		[t],
	);
}
