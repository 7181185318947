import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DataTable from "../../../ui/components/DataTable/DataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useApproveAgreementsColumns from "./hooks/useApproveAgreementsColumns";

export default function SuperAdminApproveAgreementsView({ agreements, loading, error }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const columns = useApproveAgreementsColumns();

	return (
		<>
			<AdminHeader>
				{t("needsApproval", "Needs approval")} - {t("agreements", "Agreements")}
			</AdminHeader>

			<DataTable
				data={agreements}
				error={error}
				loading={loading}
				columns={columns}
				onClick={({ id, firstParty }) =>
					navigate(`/${firstParty.type}/${firstParty.id}/admin/agreements/${id}`)
				}
			/>
		</>
	);
}
