import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "semantic-ui-react";

import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import useAgreementStatusTranslations from "../../../../agreements/hooks/useAgreementStatusTranslations";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import Table from "../../../../ui/components/Table/Table";

import usePartiesColumns from "../hooks/usePartiesColumns";

const failedStates = [
	"MISSING_AGREEMENT_DETAILS",
	"FAILED_TO_CREATE_PDF",
	"FAILED_TO_CREATE_DOCUMENT",
	"FAILED_TO_BE_READY_FOR_SIGNING",
	"FAILED_TO_START_SIGNING",
	"FAILED_TO_PROLONG",
	"SIGNING_EXPIRED",
];

export default function AgreementSigning({ agreement }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();

	const partiesColumns = usePartiesColumns();
	const agreementStatuses = useAgreementStatusTranslations();

	const onClickRetry = useCallback(() => updateDocument(agreement.ref, { initiatedSigning: true }), [agreement?.ref]);

	const parties = [
		{
			party: agreement.firstParty?.name,
			signee: agreement.firstSignee?.name,
			state: agreement.firstSignee?.signState,
			time: agreement.firstSignee?.signTime,
		},
		{
			party: agreement.secondParty?.name,
			signee: agreement.secondSignee?.name,
			state: agreement.secondSignee?.signState,
			time: agreement.secondSignee?.signTime,
		},
	];

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("signing", "Signing")}</Card.Header>
				<Card.Description>{agreementStatuses[agreement.status]}</Card.Description>
			</Card.Content>
			<Card.Content>
				<Table data={parties} columns={partiesColumns} footer={false} />
			</Card.Content>
			{hasRight("agreements.send") && !agreement.deleted && (
				<Card.Content extra>
					<Button
						color="teal"
						disabled={
							agreement.initiatedSigning || (agreement.state && !failedStates.includes(agreement.state))
						}
						onClick={onClickRetry}
					>
						{t("retrySigning", "Retry signing")}
					</Button>
				</Card.Content>
			)}
		</Card>
	);
}
