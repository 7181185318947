import { useMemo } from "react";

import useOrganizationRoles from "./useOrganizationRoles";

export default function useOrganizationRoleOptions(type) {
	const roles = useOrganizationRoles(type);

	return useMemo(
		() =>
			Object.entries(roles).map(([value, text]) => ({
				key: value,
				value,
				text,
			})),
		[roles],
	);
}
