import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import ContentCard from "../../../../ui/components/ContentCard/ContentCard";

import { isAppendixesValid } from "../../../utils/agreementValidators";

import SecondaryParties from "../components/SecondaryParties";

export default function AgreementPartiesForm() {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();
	const isValid = Form.usePartialFormValidation(["appendixes"], isAppendixesValid);

	const [part, firstParty, signed] = watch(["part", "firstParty", "signed"]);

	return (
		<ContentCard header={t("sendTo", "Send to")} disabled={!isValid && !signed}>
			<Form.Form>
				<SecondaryParties part={part} firstParty={firstParty} multiple={!signed} />
			</Form.Form>
		</ContentCard>
	);
}
