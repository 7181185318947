import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import { priceAccessor } from "../../../../prices/utils/priceAccessors";

import ProductionPriceDropdownMenu from "../components/ProductionPriceDropdownMenu";

export default function useProductionPricesTableColumns() {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				collapsing: true,
				accessor: ({ created }) => created?.toDate?.().toLocaleString(),
			},
			{
				id: "producer.name",
				Header: t("producer", "Producer"),
				collapsing: true,
			},
			{
				id: "production.title",
				Header: t("production", "Production"),
				collapsing: true,
			},
			{
				id: "type",
				Header: t("type", "Type"),
				collapsing: true,
				accessor: ({ type }) => costTypes[type],
			},
			{
				id: "price",
				Header: t("price", "Price"),
				collapsing: false,
				accessor: priceAccessor,
			},
			{
				id: "menu",
				textAlign: "right",
				collapsing: true,
				Cell: ({ row }) => <ProductionPriceDropdownMenu price={row.original} />,
			},
		],
		[costTypes, t],
	);
}
