import Table from "../../../ui/components/Table/Table";

import useTotalEstimatedCost from "../../hooks/useTotalEstimatedCost";
import useTotalOpenCost from "../../hooks/useTotalOpenCost";

import useOpenCostsTableColumns from "./hooks/useOpenCostsTableColumns";

const getSubRows = (row) => (row.costs?.length > 1 ? row.costs : []);

export default function OpenCostsTable({ partyId, production, openCosts, exchangeRate, ...props }) {
	const currency = production?.productionPrice?.currency || openCosts?.[0]?.price?.currency;
	const rate = exchangeRate.rates[currency];

	const estimatedTotal = useTotalEstimatedCost({ costs: openCosts, rate, currency });
	const openTotal = useTotalOpenCost({ costs: openCosts, rate, currency });

	const columns = useOpenCostsTableColumns({ partyId, production });

	return (
		<Table expandable data={openCosts} columns={columns} getSubRows={getSubRows} {...props}>
			{openCosts.length > 0 && (
				<Table.Row textAlign={"right"} style={{ fontWeight: "bold" }}>
					<Table.Cell></Table.Cell>
					<Table.Cell></Table.Cell>
					<Table.Cell></Table.Cell>
					<Table.Cell>{estimatedTotal}</Table.Cell>
					<Table.Cell>{openTotal}</Table.Cell>
					<Table.Cell></Table.Cell>
				</Table.Row>
			)}
		</Table>
	);
}
