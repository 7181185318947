import { useTranslation } from "react-i18next";

import api from "../../../api";

import { useProfile } from "../../authentication/state/profile";

export default function useArtifactDropdownMenuOptions({ article }) {
	const { t } = useTranslation();

	const user = useProfile();

	return (data) => [
		{
			as: "a",
			text: t("download", "Download"),
			href: data?.url,
			download: true,
		},
		{
			text: t("delete", "Delete"),
			disabled: article?.artifact?.id === data.id,
			action: async () => {
				await api.artifacts.softDelete(data.ref, user);
			},
		},
	];
}
