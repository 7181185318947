import FirestoreDocument from "../../../types/FirestoreDocument";
import { array, enumValues, file, ref, string } from "../../../types/_types";

import agreementParts from "../constants/agreementParts";
import agreementTypes from "../constants/agreementTypes";

export const Appendix = FirestoreDocument.extend({
	agreementParts: array(enumValues(agreementParts)),
	agreementTypes: array(enumValues(agreementTypes)),
	file: file(),
	name: string(3),
	producerIds: array(string()),
});

export const AppendixRef = ref(Appendix, {
	file: true,
	name: true,
});
