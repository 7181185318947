import React from "react";

import AdminPageView from "../../../admin/components/AdminPage/AdminPageView";

import SuperAdminDistributionPipelineHeader from "./components/SuperAdminDistributionPipelineHeader";

export default function SuperAdminDistributionPipelineView(props) {
	return (
		<AdminPageView {...props}>
			<SuperAdminDistributionPipelineHeader />
		</AdminPageView>
	);
}
