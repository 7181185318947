import { useTranslation } from "react-i18next";

export default function useVoiceOptions() {
	const { t } = useTranslation();

	return [
		{ key: "veryDeep", value: "1", text: t("veryDark", "Very deep") },
		{ key: "deep", value: "2", text: t("deep", "Deep") },
		{ key: "neutral", value: "3", text: t("neutral", "Neutral") },
		{ key: "bright", value: "4", text: t("bright", "Bright") },
		{ key: "veryBright", value: "5", text: t("veryLight", "Very bright") },
	];
}
