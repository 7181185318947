import { useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import { useDocumentData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import ProfileView from "../views/ProfileView";

export default function ProfilePage() {
	const { userId } = useParams();

	const [user, loading, error] = useDocumentData(db.collection("users").doc(userId));

	return (
		<LoadingContext data={user} loading={loading} error={error}>
			<ProfileView user={user} />
		</LoadingContext>
	);
}
