import { useTranslation } from "react-i18next";
import { useRefinementList } from "react-instantsearch";
import { Button, Checkbox, Form, Label } from "semantic-ui-react";

import useRefinementTranslations from "../../hooks/translations/useRefinementTranslations";

import RefinementSearchBox from "../RefinementSearchBox";

export default function RefinementCheckboxes({
	attribute,
	sortItems,
	searchable = false,
	scrollable = false,
	showMore = false,
	limit = Infinity,
	showMoreLimit = Infinity,
}) {
	const { t } = useTranslation(["language"]);
	const translations = useRefinementTranslations();

	const settings = scrollable
		? { attribute }
		: {
				attribute,
				limit,
				showMore,
				showMoreLimit,
		  };

	const { items, refine, searchForItems, canToggleShowMore, toggleShowMore, isShowingMore } =
		useRefinementList(settings);

	const sortedItems = sortItems ? sortItems(items) : items;

	return (
		<Form>
			{searchable && <RefinementSearchBox searchForItems={searchForItems} />}
			<div style={scrollable ? { maxHeight: "12rem", overflowY: "auto" } : {}}>
				<Form.Group grouped>
					{sortedItems.map((item) => {
						const label =
							attribute === "language"
								? t(`language:${item.label}`)
								: translations[item.label] || item.label;
						return (
							<Form.Field inline key={item.label}>
								<Checkbox
									label={label}
									checked={item.isRefined}
									onClick={(event) => {
										event.preventDefault();
										refine(item.value);
									}}
								/>
								<Label circular color="grey" size="small">
									{item.count}
								</Label>
							</Form.Field>
						);
					})}
				</Form.Group>
			</div>
			{!scrollable && canToggleShowMore && (
				<Button size="mini" color="blue" onClick={() => toggleShowMore()}>
					{!isShowingMore ? t("showMore", "Show more ...") : t("showLess", "Show less")}
				</Button>
			)}
		</Form>
	);
}
