function isBooked(production) {
	return (
		production.status === "production" &&
		!production.stats &&
		!production.proofParts &&
		!production.progress &&
		production.productionType !== "subcontract"
	);
}

function isMastering(production) {
	return production.status === "production" && production.polishDone && production.productionType !== "subcontract";
}

export default function mapProductionStatus(production) {
	const booked = isBooked(production) && "booked";
	const mastering = isMastering(production) && "mastering";
	return booked || mastering || production.status;
}
