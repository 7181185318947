import round from "astrid-helpers/src/round";

import { estimateTotalStudioHours } from "../../../components/production/fnc";

export default function getStudioHours(production) {
	const totalStudioHours = round(estimateTotalStudioHours(production), 1);
	const completedStudioHours = round((production.liveStats?.completedSessionMinutes || 0) / 60, 1);
	const bookedStudioHours = round((production.bookedMinutes || 0) / 60, 1);
	const remainingStudioHours = round(totalStudioHours - (bookedStudioHours + completedStudioHours), 1);

	return { totalStudioHours, completedStudioHours, bookedStudioHours, remainingStudioHours };
}
