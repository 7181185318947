import { array, boolean, lazy, nullable, object, preprocess, string } from "../../../types/_types";

export const PipelineStep = preprocess(
	({ action, args, steps, ...data }) => {
		return {
			action,
			args: action ? args : null,
			steps: !action ? steps : null,
			...data,
		};
	},
	object({
		action: nullable(string()),
		args: nullable(object().passthrough()),
		disabled: boolean(),
		forEach: boolean(),
		name: string(3),
		needs: nullable(string()),
		steps: lazy(() => nullable(array(PipelineStep))),
	}),
).refine(({ action, steps }) => {
	return !(!action && (!steps || steps.length === 0));
}, "Must have either an action or steps.");
