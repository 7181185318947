const rolePartMap = {
	editor: "user",
	proofer: "user",
	manager: "user",
	reader: "narrator",
	recorder: "user",
	subcontractor: "subcontractor",
};

export default rolePartMap;
