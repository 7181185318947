import React from "react";

import ProducerSettings from "../components/ProducerSettings";

const Settings = (props) => {
	let producerId = props.match.params.producerId;
	console.log(producerId);

	// get from slack oauth redirect url
	if (!producerId) {
		const qs = new URLSearchParams(window.location.search);
		const slackstate = qs.get("state");

		if (slackstate.substr(0, 10) === "slack-auth") {
			producerId = slackstate.substr(10);
		}
	}

	return (
		<div>
			{props.profile &&
				props.profile.permissions &&
				props.profile.permissions.producer &&
				props.profile.permissions.producer[producerId] && (
					<ProducerSettings profile={props.profile} producerId={producerId} query={window.location.search} />
				)}
		</div>
	);
};

export default Settings;
