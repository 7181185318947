import cc from "currency-codes";

import countries from "./countries";

export const codes = cc.codes();
export const currencyCodes = codes;

export const getCountryCurrencyCodes = (country) => (country ? countries[country]?.currency?.split(",") : []);

export default currencyCodes;
