import debounce from "lodash/debounce";
import deburr from "lodash/deburr";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { Button, Form, Header, Input, Loader, Modal, Segment, Table } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { base } from "astrid-firebase";
import DocTitle from "astrid-web/src/components/DocTitle";
import MasterSoundSetting from "astrid-web/src/components/MasterSoundSetting";

import { withStore } from "../helpers/context";

import Pagination from "./Pagination";

class ProducerSettings extends Component {
	state = {
		producer: {
			slack: "",
			dropbox: "",
			studios: [],
			modal: null,
			currentStudio: null,
		},

		people: [],
		searchPerson: "",
		productions: [],
		peoplePage: 0,
		publishers: [],
	};

	colors = [
		"#f25a5a",
		"#5af2f2",
		"#a6f25a",
		"#a65af2",
		"#f2cc5a",
		"#5a80f2",
		"#5af280",
		"#f25acc",
		"#f2805a",
		"#5accf2",
		"#80f25a",
		"#cc5af2",
		"#f2f25a",
		"#5af2a6",
		"#5a5af2",
		"#f25aa6",
		"#f2a65a",
		"#5aa6f2",
		"#5af25a",
		"#f25af2",
		"#ccf25a",
		"#805af2",
		"#5af2cc",
		"#f25a80",
	];

	componentDidMount() {
		base.bindDoc("organizations/" + this.props.producerId, {
			context: this,
			state: "producer",
			then() {
				// check slack auth return
				const grant = this.props.query ? this.props.query.substr(1).split("&") : false;
				if (!this.state.producer.slack && grant && grant[1].substr(0, 16) === "state=slack-auth") {
					// set producer slack status
					base.updateDoc("organizations/" + this.props.producerId, {
						slack: "granted",
					});

					// store slack grant (will trigger cloud function to get token)
					base.addToCollection("slacks", { grant: grant[0].substr(5) }, this.props.producerId);
				}
			},
			onFailure(err) {
				console.log("prod sett mount", err);
			},
		});

		base.bindCollection("people", {
			context: this,
			state: "people",
			withIds: true,
			then() {},
			onFailure(err) {
				console.log("people err", err);
			},
		});
	}

	updateFirestore = (ref, data) => {
		// update database
		base.updateDoc(ref, {
			...data,
		});
	};

	debouncedUpdate = debounce((ref, data) => {
		this.updateFirestore(ref, data);
	}, 0);

	addStudio = (e) => {
		this.setState({ savingStudio: true });

		const name = e.target.querySelector("[name=name]");
		const address = e.target.querySelector("[name=address]");
		const studios = this.state.producer.studios ? { ...this.state.producer.studios } : {};

		// use timestamp as key
		studios[+new Date()] = {
			name: name.value,
			address: address.value,
			active: true,
			color: this.colors[this.getRandomInt(0, this.colors.length)],
		};

		base.updateDoc("organizations/" + this.props.producerId, {
			studios,
		}).then(() => {
			name.value = "";
			address.value = "";
			this.setState({ savingStudio: false });
		});
	};

	getRandomInt(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
	}

	editStudio = (id) => {
		this.setState({
			modal: "editStudio",
			currentStudio: id,
		});
	};

	editPerson = (id) => {
		this.setState({
			modal: "editPerson",
			currentPerson: id,
		});

		if (!this.state.productions.length) {
			base.bindCollection("productions", {
				context: this,
				state: "productions",
				withIds: true,
				query: (ref) => ref.where("producer", "==", this.props.producerId).orderBy("title"),
				then() {},
				onFailure(err) {
					console.log("productions err", err);
				},
			});
		}

		if (!this.state.publishers.length) {
			base.bindCollection("organizations", {
				context: this,
				state: "publishers",
				withIds: true,
				query: (ref) => ref.where("type", "==", "publisher").orderBy("name"),
				then() {},
				onFailure(err) {
					console.log("publishers err", err);
				},
			});
		}
	};

	updateStudio = (e, data) => {
		const studios = this.state.producer.studios ? { ...this.state.producer.studios } : {};

		if (!data) data = {};

		if (data && data.type === "checkbox") {
			data.value = data.checked;

			// don't know why
			this.setState({ preventModalClose: true });
		} else if (data.name === "groupTags" && typeof data.value === "string") {
			// add item sends a string... bug in Semantic maybe? just ignore and the onChange function will catch it
			return;
		} else if (e.target.type === "color") {
			data.name = "color";
			data.value = e.target.value;
		}

		studios[this.state.currentStudio][data.name] = data.value;

		const producer = { ...this.state.producer };
		producer.studios = studios;

		this.setState({
			producer,
		});

		this.debouncedUpdate("organizations/" + this.props.producerId, {
			studios,
		});
	};

	updatePerson = (e, data) => {
		this.debouncedUpdate("people/" + this.state.currentPerson, {
			[data.name]: data.value,
		});
	};

	deletePerson = (e, data) => {
		const { t } = this.props;
		if (window.confirm(t("sureAboutDeletePerson"))) {
			this.modalClose();
			base.removeDoc("people/" + this.state.currentPerson)
				.then(() => {})
				.catch((error) => {
					console.log("Person delete error", error);
				});
		}
	};

	searchPerson = (e, data) => {
		this.setState({ searchPerson: data.value, peoplePage: 0 });
	};

	modalClose = () => {
		if (this.state.preventModalClose) {
			this.setState({ preventModalClose: false });
		} else {
			this.setState({ modal: null, currentStudio: null, currentPerson: null });
		}
	};

	getStudioGroupTags = (addEmptyOption) => {
		// gather all groups
		let tags = Object.values(this.state.producer.studios).reduce(
			(prev, curr) => [...prev, ...(curr.groupTags || []), ...(curr.group ? [curr.group] : [])],
			addEmptyOption ? [""] : [],
		);

		// make'm unique
		tags = [...new Set(tags)];

		return tags;
	};

	render() {
		const { people, producer, peoplePage, currentPerson, searchPerson } = this.state;
		const { t, store, producerId } = this.props;

		// sort and search
		let sortedPeople = sortBy(people, ["firstName", "lastName"]);
		if (searchPerson) {
			sortedPeople = sortedPeople.filter(
				(person) =>
					deburr(person.firstName.toLowerCase()).includes(deburr(searchPerson.toLowerCase())) ||
					deburr(person.lastName.toLowerCase()).includes(deburr(searchPerson.toLowerCase())),
			);
		}

		// page people
		const perPeoplePage = 20;
		const pagedPeople = sortedPeople.slice(peoplePage * perPeoplePage, (peoplePage + 1) * perPeoplePage);

		// edit person
		let person = {};
		if (currentPerson) {
			person = people.find((person) => person.id === currentPerson);
		}

		return (
			<>
				<DocTitle title={producer.name} />

				<h1>{producer.name}</h1>
				<Segment>
					<Header as="h4" icon="microphone" content={t("recAndEdit")} />

					<MasterSoundSetting
						location="organizations"
						locationId={producerId}
						locationName={producer.name}
						userId={get(store, "state.user.uid")}
						value={{ list: producer?.reaperTemplates?.list }}
					/>
				</Segment>

				<Segment>
					<Header as="h4" icon="pencil" content={t("authorAndTranslator")} />
					<Input
						size="small"
						icon="search"
						placeholder={t("searchAuthor")}
						value={this.state.searchPerson}
						onChange={this.searchPerson}
					/>

					<Pagination
						page={peoplePage}
						items={people.length}
						per={perPeoplePage}
						setPage={(peoplePage) => this.setState({ peoplePage })}
						style={{ marginLeft: "1rem", float: "right" }}
					/>

					<Table basic="very" compact sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{t("authorName")}</Table.HeaderCell>
								<Table.HeaderCell>{t("authorRole")}</Table.HeaderCell>
								<Table.HeaderCell>{t("authorEmail")}</Table.HeaderCell>
								<Table.HeaderCell>{t("authorPhone")}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{pagedPeople.map((person) => (
								<Table.Row key={person.id}>
									<Table.Cell selectable style={{ fontWeight: "bold" }}>
										<a
											href="/people/edit"
											onClick={(e) => {
												e.preventDefault();
												this.editPerson(person.id);
											}}
										>
											{person.firstName} {person.lastName}
										</a>
									</Table.Cell>
									<Table.Cell>{person.type === "author" ? t("author") : t("translator")}</Table.Cell>
									<Table.Cell>
										{person.email && <a href={"mailto:" + person.email}>{person.email}</a>}
									</Table.Cell>
									<Table.Cell>
										{person.phone && <a href={"tel:" + person.phone}>{person.phone}</a>}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Segment>

				{/* DROPBOX AND SLACK NO LONGER USED + Slack SDK is outdated
				<Segment>
					<Header as="h4" icon="slack" content="Anslut till Slack" />

					{// Not connected }
					{!producer.slack && (
						<a href={slackUrl}>
							<img
								alt="Add to Slack"
								height="40"
								width="139"
								src="https://platform.slack-edge.com/img/add_to_slack.png"
								srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
							/>
						</a>
					)}

					{// Waiting for grant to become token (cloud funtion) }
					{producer.slack === "granted" && (
						<div>
							<em>Verifierar Slack </em>
							<Loader active inline size="tiny" />
						</div>
					)}

					{// Ansluten }
					{producer.slack === "fetching" && (
						<div>
							<Label color="yellow">
								<Icon name="hourglass half" />
								Hämtar medlemmar...
							</Label>
						</div>
					)}

					{producer.slack === "verified" && (
						<div>
							<Label color="green">
								<Icon name="check" />
								Ansluten!
							</Label>
						</div>
					)}
				</Segment>

				<Segment>
					<Header as="h4" icon="dropbox" content="Anslut till Dropbox" />

					<a
						href={
							"https://www.dropbox.com/oauth2/authorize?client_id=c12hb62i99ebiyn&response_type=code&redirect_uri=https://" +
							(store.state.live ? "" : "stage.") +
							"astrid.fm" +
							"/settings/dropbox&state=" +
							producerId
						}
					>
						<Label color="blue">
							<Icon name={producer.dropbox ? "check" : "external"} />
							{producer.dropbox ? "Ansluten!" : "Klicka för att ansluta"}
							{producer.dropbox && (
								<Icon
									name="delete"
									onClick={e => {
										e.preventDefault();
										if (
											window.confirm(
												"Är du säker på att du vill koppla från Dropbox och sluta skapa projektmappar automatiskt?",
											)
										) {
											base.updateDoc("organizations/" + producerId, {
												dropbox: false,
												dropboxRoot: false,
											});
										}
									}}
								/>
							)}
						</Label>
					</a>
					{producer.dropbox &&
						(!producer.dropboxRoot ? (
							<div>
								<br />
								<b>Välj källmapp för automatiska projektmappar</b>
								<br />
								{dropboxRootFolders ? (
									dropboxRootFolders
										.filter(namespace => namespace.namespace_type[".tag"] === "shared_folder")
										.map(namespace => (
											<Label
												basic
												icon="folder"
												as="a"
												key={namespace.namespace_id}
												onClick={e => {
													// set dropbox root folder
													this.updateFirestore("organizations/" + producerId, {
														dropboxRoot: namespace,
													});
												}}
												content={namespace.name}
											/>
										))
								) : (
									<p>Laddar...</p>
								)}
								<br />
								<em>
									Obs! Dropbox-administratören (den som anslöt Dropbox till Astrid) måste ha
									skrivrättigheter i mappen.
								</em>

								<br />
								<br />
							</div>
						) : (
							<div>
								<br />
								<p>
									<b>Källmapp för automatiska projektmappar:</b>
									<Label as="span" basic icon="folder" content={producer.dropboxRoot.name} />
									<br />
									<small>
										<em>
											Obs! Dropbox-administratören (den som anslöt Dropbox till Astrid) måste ha
											skrivrättigheter i mappen.
										</em>
									</small>
								</p>
								<small>
									<p>
										Första gången per produktion som ett inspelningspass bokas skapas automatiskt en
										mapp i{" "}
										<Label as="span" size="tiny">
											{producer.dropboxRoot.name}
											/Pågående/#Studio(grupp)/Titel
										</Label>
										.
									</p>
									<p>
										Om mallen{" "}
										<Label as="span" size="tiny">
											{producer.dropboxRoot.name}
											/Mallar/STUDIO.cpr
										</Label>
										,
										<Label as="span" size="tiny">
											{producer.dropboxRoot.name}
											/Mallar/GRUPP.cpr
										</Label>
										eller
										<Label as="span" size="tiny">
											{producer.dropboxRoot.name}
											/Mallar/STANDARD.cpr
										</Label>
										finns så kopieras den in i nya projektmappen och döps till{" "}
										<Label as="span" size="tiny">
											Titel_INSPELNING.cpr
										</Label>
										.
									</p>
									<p>Filnamn och sökvägar i Dropbox är ej skiftlägeskänsliga.</p>
								</small>
								<br />
								<Form.Button
									size="mini"
									color="orange"
									loading={this.state.creatingProjectFolder}
									content={
										this.state.createdProjectFolder
											? "Klart! Verifiera resultatet i Dropbox."
											: "Test: Skapa mapp för produktionen 'En låtsasproduktion' i studiogruppen 'Norra gruppen'"
									}
									onClick={this.dropboxSampleFolder}
								/>
							</div>
						))}
				</Segment> */}

				{this.state.modal && (
					<Modal size="small" open={true} onClose={this.modalClose} closeOnDocumentClick>
						{this.state.modal === "editPerson" && (
							<>
								<Modal.Header>
									{t("editPerson")} {person.type === "author" ? t("author") : t("translator")}
								</Modal.Header>
								<Modal.Content>
									<Form>
										<Form.Group widths="equal">
											<Form.Input
												label={t("firstName")}
												name="firstName"
												defaultValue={person.firstName}
												onChange={this.updatePerson}
											/>
											<Form.Input
												label={t("lastName")}
												name="lastName"
												defaultValue={person.lastName}
												onChange={this.updatePerson}
											/>
										</Form.Group>

										<Form.Group widths="equal">
											<Form.Input
												placeholder={t("email")}
												type="email"
												name="email"
												value={person.email || ""}
												onChange={this.updatePerson}
											/>
											<div className={"field"}>
												<PhoneInput
													placeholder={t("phone")}
													value={person.phone || ""}
													defaultCountry="SE"
													onChange={(value) => {
														this.updatePerson(null, { name: "phone", value: value || "" });
													}}
												/>
											</div>
										</Form.Group>

										<Form.Group widths="equal">
											<Form.Select
												search
												deburr
												multiple
												label={t("visibleFor")}
												name="visible"
												value={person.visible || []}
												onChange={this.updatePerson}
												options={this.state.publishers.map((pub) => ({
													key: pub.id,
													text: pub.name,
													value: pub.id,
												}))}
											/>
											{person.type === "author" && (
												<Form.Select
													search
													deburr
													value={person.language || ""}
													label={t("originalLanguage")}
													options={Object.keys(languages).map((code) => ({
														key: code,
														value: code,
														text:
															t("language:" + code) +
															//(languages[code].sv || languages[code].name) +
															" (" +
															languages[code].nativeName +
															")",
													}))}
													name="language"
													onChange={this.updatePerson}
												/>
											)}
										</Form.Group>

										{this.state.productions ? (
											<div>
												<strong>{t("associatedProds")}</strong>
												{this.state.productions
													.filter(
														(prod) =>
															(prod.author && prod.author.includes(person.id)) ||
															(prod.translator && prod.translator.includes(person.id)),
													)
													.map((prod) => (
														<div key={prod.id}>
															<a href={"/production/" + prod.id}>{prod.title}</a>
														</div>
													))}
											</div>
										) : (
											<Loader active inline />
										)}
									</Form>
								</Modal.Content>

								<Modal.Actions>
									<Button
										icon="delete"
										labelPosition="right"
										content={t("delete")}
										color="red"
										floated="left"
										disabled={
											!!(
												!this.state.productions.length ||
												this.state.productions.find(
													(prod) =>
														(prod.author && prod.author.includes(person.id)) ||
														(prod.translator && prod.translator.includes(person.id)),
												)
											)
										}
										onClick={this.deletePerson}
									/>
									<Button
										positive
										icon="checkmark"
										labelPosition="right"
										content={t("ok")}
										onClick={this.modalClose}
									/>
								</Modal.Actions>
							</>
						)}
					</Modal>
				)}
			</>
		);
	}
}

export default withTranslation(["common", "language"])(withStore(ProducerSettings));
