import { Icon, Pagination as SemanticPagination } from "semantic-ui-react";

export default function Pagination(props) {
	return (
		<SemanticPagination
			pointing
			secondary
			borderless
			ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
			firstItem={{ content: <Icon name="angle double left" />, icon: true }}
			lastItem={{ content: <Icon name="angle double right" />, icon: true }}
			prevItem={{ content: <Icon name="angle left" />, icon: true }}
			nextItem={{ content: <Icon name="angle right" />, icon: true }}
			pageItem={{ icon: true }}
			{...props}
		/>
	);
}
