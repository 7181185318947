import { updateDocument } from "astrid-firestore/src/helpers";
import { getAgreementRef, getPriceRef } from "astrid-firestore/src/utils/referenceMappers";

export default async function createPublisherPrice({ type, price, publisher, organization }) {
	const key = `prices.${organization.id}.${type}`;

	return await updateDocument(publisher.ref, {
		[`${key}.price`]: getPriceRef(price),
		[`${key}.agreement`]: getAgreementRef(price.agreement),
	});
}
