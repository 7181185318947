import runBatch from "../../helpers/runBatch";

import createCost from "./createCost";
import summarizeOpenCosts from "./summarizeOpenCosts";

export default async function finalizeOpenCosts(firebase, openCosts) {
	const db = firebase.firestore();

	await runBatch(db, async (batch) => {
		for (const { openAmount, ...costData } of openCosts) {
			const cost = createCost(db, {
				...costData,
				amount: openAmount,
			});

			batch.set(cost.ref, cost);
		}

		const productionIds = [...new Set(openCosts.map((cost) => cost.production.id))];

		await Promise.all(
			productionIds.map(async (productionId) => {
				const productionRef = db.collection("productions").doc(productionId);
				const openCostsSummary = await summarizeOpenCosts(firebase, productionRef);
				// trigger scheduled job to generate statistics.
				const statisticsGenerated = false;

				batch.update(productionRef, { openCostsSummary, statisticsGenerated });
			}),
		);
	});
}
