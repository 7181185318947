import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { useProfile } from "../../authentication/state/profile";
import { useOrganizationType } from "../../organizations/state/organizationType";

export default function useLanguageOptions({ organization }) {
	const user = useProfile();
	const organizationType = useOrganizationType();

	return organizationType !== organizationTypes.NARRATOR
		? user?.[`${organizationType}s`]?.[organization.id]?.languageOptions
		: Object.keys(user?.languages || {});
}
