import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Header, Icon, Table } from "semantic-ui-react";

const HomeSubcontractorOffers = ({ productions, uid }) => {
	const { t } = useTranslation();

	const offers = productions.filter(
		(prod) =>
			prod.status !== "draft" &&
			prod.status !== "offer" &&
			prod.productionType === "subcontract" &&
			prod.subcontractorStatus === "assigned" &&
			prod.subcontractor === uid,
	);

	if (!offers?.length) return false;

	return (
		<>
			<Header as="h4" icon="suitcase" content={t("subcontractorOffers")} />
			<Table compact basic="very" sortable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>{t("title")}</Table.HeaderCell>
						<Table.HeaderCell>{t("deliveryDate")}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{offers
						.sort((a, b) => (a.deliveryDate > b.deliveryDate ? 1 : -1))
						.map((prod) => {
							return (
								<Table.Row key={prod.id}>
									<Table.Cell selectable>
										<Link to={"/production/" + prod.id}>
											<Icon name={"share"} />
											{prod.title}
										</Link>
									</Table.Cell>
									<Table.Cell collapsing textAlign="right">
										{prod.deliveryDate && moment(prod.deliveryDate.toDate()).format("YYYY-MM-DD")}
									</Table.Cell>
								</Table.Row>
							);
						})}
				</Table.Body>
			</Table>
		</>
	);
};

export default HomeSubcontractorOffers;
