import { useTranslation } from "react-i18next";
import { Card, Label } from "semantic-ui-react";

import { languageName } from "astrid-helpers/src/languages";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import usePriceUnits from "../../../../prices/hooks/usePriceUnits";
import { formatPrice } from "../../../../prices/utils/priceFormatters";

export default function PriceInfo({ price }) {
	const { t } = useTranslation();

	const units = usePriceUnits();
	const costTypes = useCostTypes();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{price.name}</Card.Header>
				<Card.Description>
					{costTypes[price.type]}{" "}
					{price.employee && <Label pointing="left">{t("employee", "Employee")}</Label>}
				</Card.Description>
			</Card.Content>
			<Card.Content>
				{t("language", "Language")}
				<Card.Meta>{price.languages.map(languageName).join(", ")}</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("unit", "Unit")}
				<Card.Meta>{units[price.unit]}</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("price", "Price")}
				<Card.Meta>{formatPrice(price)}</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("currency", "Currency")}
				<Card.Meta>{price.currency}</Card.Meta>
			</Card.Content>
			<Card.Content>
				{t("VAT", "VAT")}
				<Card.Meta>{price.vat}%</Card.Meta>
			</Card.Content>
		</Card>
	);
}
