import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import useTeamRoleTranslations from "../../../../team/hooks/useTeamRoleTranslations";
import Select from "../../../../ui/components/Select/Select";

import useProductionTeam from "../../../hooks/useProductionTeam";

export default function ProductionPriceMemberSelect({ firstParty, ...props }) {
	const { t } = useTranslation();
	const { watch } = Form.useFormContext();

	const production = watch("production");

	const team = useProductionTeam(production);
	const teamRoles = useTeamRoleTranslations();

	const memberOptions = useMemo(
		() =>
			team
				.filter(
					(member) =>
						(member.employee && firstParty.type === "producer") ||
						member.firstParty?.id === firstParty.id ||
						member.agreement?.firstParty?.id === firstParty.id,
				)
				.map((member) => ({
					key: member.id,
					value: member.id,
					text: `${member.user.name} (${teamRoles[member.role]})`,
				})),
		[firstParty.id, firstParty.type, team, teamRoles],
	);

	return <Select {...props} search deburr label={t("user", "User")} options={memberOptions} />;
}
