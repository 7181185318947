import db from "astrid-firebase/src/db";
import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";

export default function useCurrentExchangeRate() {
	const [data, loading, error] = useCollectionDataOnce(
		db.collection("exchangeRates").orderBy("timestamp", "desc").limit(1),
	);

	return [data?.[0], loading, error];
}
