import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";

import DownloadAgreementDropdownItem from "./items/DownloadAgreementDropdownItem";
import ViewAgreementDropdownItem from "./items/ViewAgreementDropdownItem";

export default function AgreementDropdownMenu({ agreement }) {
	return (
		<DropdownMenu>
			{() => (
				<>
					<ViewAgreementDropdownItem agreement={agreement} />
					<DownloadAgreementDropdownItem agreement={agreement} />
				</>
			)}
		</DropdownMenu>
	);
}
