import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import agreementParts from "astrid-firestore/src/api/agreements/constants/agreementParts";

export default function usePriceUnitOptions(part = "user") {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				key: "fixed",
				value: "fixed",
				text: t("priceFixed"),
			},
			{
				key: "time",
				value: "time",
				text: t("priceTime", "Per minute"),
			},
			{
				key: "hour",
				value: "hour",
				text: t("priceHour", "Per produced hour"),
			},
			...(part === agreementParts.USER
				? [
						{
							key: "activity",
							value: "activity",
							text: t("priceActivity", "Per worked minute"),
						},
				  ]
				: []),
			...(part === agreementParts.PUBLISHER
				? [
						{
							key: "percentage",
							value: "percentage",
							text: t("pricePercentage", "Percentage of costs"),
						},
				  ]
				: []),
		],
		[part, t],
	);
}
