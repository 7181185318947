import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateArticleForm from "./CreateArticleForm";

export default function CreateArticleButton({ organization }) {
	const { t } = useTranslation();

	return (
		<OpenButton basic secondary text={t("createArticle", "Create article")}>
			{({ setOpen }) => <CreateArticleForm organization={organization} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
