import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";
import Form from "astrid-web/src/features/forms/components/Form/Form";

import useHasRight from "../../../../authorization/hooks/useHasRight";
import useOrganizationLanguageOptions from "../../../../organizations/hooks/useOrganizationLanguageOptions";
import useOrganizationRoleOptions from "../../../../organizations/hooks/useOrganizationRoleOptions";
import useEmployeeOptions from "../../../../users/hooks/useEmployeeOptions";

export default function AdminUserSettings({ user, organization }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const { type } = organization;
	const typePlural = `${type}s`;

	const organizationSettings = user[typePlural]?.[organization.id];

	const roleOptions = useOrganizationRoleOptions(type);
	const employeeOptions = useEmployeeOptions();
	const languageOptions = useOrganizationLanguageOptions(organization);

	const form = Form.useForm({
		defaultValues: {
			languageOptions: organizationSettings?.languageOptions || [],
			employee: organizationSettings?.employee || false,
			permissions: user.permissions?.[type]?.[organization.id] || ["any"],
		},
	});

	const { handleSubmit, formState, reset } = form;

	const { isSubmitting, isDirty, isValid } = formState;

	const onSubmit = async ({ languageOptions, employee, permissions }) => {
		try {
			await updateDocument(user.ref, {
				[`${typePlural}.${organization.id}.languageOptions`]: languageOptions,
				[`${typePlural}.${organization.id}.employee`]: employee,
				[`permissions.${type}.${organization.id}`]: permissions,
			});

			reset({ languageOptions, employee, permissions });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("settings", "Settings")}</Card.Header>
			</Card.Content>
			<Card.Content extra>
				<Form form={form}>
					<Form.Group widths="equal">
						{type === "producer" && (
							<Form.Select
								name="employee"
								options={employeeOptions}
								label={t("employee", "Employee")}
								rules={{ required: false }}
							/>
						)}
						<Form.Select
							multiple
							name="permissions"
							options={roleOptions.filter(
								// Only superadmins can assign producer admins
								({ value }) => hasRight("superadmin") || value !== "producerAdmin",
							)}
							label={t("roles", "Roles")}
						/>
						<Form.Select
							search
							multiple
							name="languageOptions"
							options={languageOptions}
							label={t("languageOptions")}
						/>
					</Form.Group>
				</Form>
			</Card.Content>
			<Card.Content extra>
				<Button primary loading={isSubmitting} disabled={!isDirty || !isValid} onClick={handleSubmit(onSubmit)}>
					{t("save", "Save")}
				</Button>
			</Card.Content>
		</Card>
	);
}
