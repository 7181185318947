export default {
	name: "Kungliga biblioteket audio",
	format: "wav",
	connection: "ftp",
	useSSH: "ssh-kungliga-biblioteket-audio-private-key",
	meta: {
		folder: "ISBN",
		format: "fgs",
	},
	cover: {
		folder: "ISBN",
		rename: "ISBN",
	},
	audio: {
		folder: "ISBN",
		rename: "ISBN_SEQ.SUFFIX",
		disableSendWithoutMeta: true,
	},
	ebook: false,
	disableArticles: ["cd", "mp3cd"],
	email: ["delivery"],
};
