import actionNames from "./actionNames";

const actionArgs = {
	[actionNames.RENAME_FILE]: [
		{
			id: "FOLDER_PATTERN",
			type: "string",
		},
		{
			id: "FILE_PATTERN",
			type: "string",
		},
	],
	[actionNames.SAVE_TO_BUCKET]: [
		{
			id: "BUCKET",
			type: "string",
		},
		{
			id: "PATH",
			type: "string",
		},
	],
	[actionNames.SEND_FILES_TO_FTP]: [
		{
			id: "HOST",
			type: "string",
		},
		{
			id: "PORT",
			type: "string",
		},
		{
			id: "USER",
			type: "string",
		},
		{
			id: "PASSWORD",
			type: "string",
		},
		{
			id: "PARALLEL_UPLOAD",
			type: "number",
		},
	],
};

export default actionArgs;
