import { useTranslation } from "react-i18next";
import { Button, Card, Icon } from "semantic-ui-react";

export default function AgreementAppendixes({ appendixes }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("appendixes", "Appendixes")}</Card.Header>
			</Card.Content>

			{appendixes?.length === 0 && (
				<Card.Content extra>
					<Card.Description>{t("noAppendixes", "No appendixes")}</Card.Description>
				</Card.Content>
			)}

			{appendixes?.map(({ text, file }, index) => (
				<Card.Content extra key={index}>
					{file && (
						<Button icon as="a" target="_blank" href={file.url} labelPosition="left">
							<Icon name="file" />
							{file.name}
						</Button>
					)}

					{text && <Card.Description>{text}</Card.Description>}
				</Card.Content>
			))}
		</Card>
	);
}
