import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input } from "semantic-ui-react";

import { db } from "astrid-firebase";
import { useCollectionData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";
import TasksAccordion from "../../../tasks/components/TasksAccordion/TasksAccordion";

import RetryAllButton from "./components/RetryAllButton";

export default function AdminProductionDeliveriesView({ tasks, loading, error }) {
	const { t } = useTranslation();

	const [productionId, setProductionId] = useState("");

	const [searchTasks, loadingSearchTasks, errorSearchTasks] = useCollectionData(
		productionId && db.collection("tasks/queue/tasks").where("production.id", "==", productionId),
	);

	return (
		<>
			<AdminHeader>
				{t("productions", "Productions")} - {t("uncompletedDeliveries", "Uncompleted deliveries")}
				<Button.Group basic secondary>
					<RetryAllButton tasks={searchTasks || tasks} />
				</Button.Group>
			</AdminHeader>

			<Input
				value={productionId}
				placeholder={t("productionId", "Production ID")}
				onChange={(e, { value }) => setProductionId(value)}
			/>

			<br />
			<br />

			<LoadingContext
				data={searchTasks || tasks}
				loading={loading || loadingSearchTasks}
				error={error || errorSearchTasks}
			>
				<TasksAccordion tasks={searchTasks || tasks} />
			</LoadingContext>
		</>
	);
}
