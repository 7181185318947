import firebase from "firebase/app";
import moment from "moment";

const browseProductionStatistics = firebase.functions().httpsCallable("algolia-browseProductionStatistics");

export default async function fetchStatistics({ producerId, dateType, fromDate, toDate }) {
	const formattedFromDate = moment(fromDate).startOf("day").unix();
	const formattedToDate = moment(toDate).endOf("day").unix();
	const filter = `production.producer:${producerId} AND ${dateType}_timestamp:${formattedFromDate} TO ${formattedToDate}`;
	const statistics = await browseProductionStatistics({ filter });
	return statistics.data;
}
