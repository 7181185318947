import { Button } from "semantic-ui-react";

import CreateOrdersButton from "../../../../orders/components/CreateOrdersButton/CreateOrdersButton";

export default function AccountCostsActions({ costs, disabled }) {
	return (
		<Button.Group size="tiny" style={{ marginLeft: 15 }}>
			<CreateOrdersButton primary expense disabled={disabled} costs={costs} redirectTo={"../orders"} />
		</Button.Group>
	);
}
