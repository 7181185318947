import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

import ArticlesTableFooter from "../ArticlesTableFooter/ArticlesTableFooter";

export default function ArticlesCollectionDataTable({ enableSync, getQuery = (query) => query, ...props }) {
	const navigate = useNavigate();

	const columns = useArticlesTableColumns({ enableSync });

	return (
		<CollectionDataTable
			selectable={({ bundle }) => !bundle}
			query={getQuery(db.collection("articles").where("deleted", "==", false))}
			columns={columns}
			onClick={({ id, publisher }) => navigate(`/publisher/${publisher.id}/admin/articles/${id}`)}
			sortBy={{
				id: "created",
				desc: true,
			}}
			footer={<ArticlesTableFooter />}
			{...props}
		/>
	);
}
