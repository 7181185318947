import { useMemo } from "react";

import useCollectionDataOnce from "astrid-firestore/src/hooks/useCollectionDataOnce";
import { mapAgreementPrices } from "astrid-firestore/src/api/agreements/utils/agreementMappers";

import useApprovedQuery from "../../approval/hooks/useApprovedQuery";

import { filterPriceAvailability, filterPriceType, filterPriceUseability } from "../utils/priceFilters";
import { mapPriceOption } from "../utils/priceMappers";

import usePricesQuery from "./usePricesQuery";

const emptyArray = [];

export default function usePriceOptions({ role, user, producerId, language, agreement }) {
	const agreementPrices = useMemo(() => mapAgreementPrices(agreement), [agreement]);

	const pricesQuery = useApprovedQuery(
		usePricesQuery({
			employee: !!user?.producers?.[producerId]?.employee,
			language,
			producerId,
		}),
	);

	const [prices = emptyArray, loading, error] = useCollectionDataOnce(agreementPrices.length === 0 && pricesQuery);

	const options = useMemo(
		() =>
			(agreementPrices.length > 0 ? agreementPrices : prices)
				.filter(filterPriceType(role))
				.filter(filterPriceUseability())
				.filter(filterPriceAvailability(user?.id))
				.map(mapPriceOption),
		[agreementPrices, prices, role, user?.id],
	);

	return [options, loading, error];
}
