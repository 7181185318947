import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";
import ModalPrompt from "../../../../ui/components/ModalPrompt/ModalPrompt";

export default function CommentDropdownMenu({ setEditComment, editComment, comment }) {
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);

	const onDelete = () => {
		return updateDocument(comment.ref, { deleted: true });
	};

	return (
		<>
			<DropdownMenu disabled={editComment} style={{ float: "right" }}>
				{() => (
					<>
						<Dropdown.Item text={t("edit", "Edit")} onClick={() => setEditComment(true)} />
						<Dropdown.Item text={t("delete", "Delete")} onClick={() => setOpenModal(true)} />
					</>
				)}
			</DropdownMenu>
			{openModal && (
				<ModalPrompt
					onClose={() => setOpenModal(false)}
					onSave={onDelete}
					confirmText={t("yes", "Yes")}
					size="mini"
				>
					{t("deleteComment", "Are you sure you want to delete this comment?")}
				</ModalPrompt>
			)}
		</>
	);
}
