import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Radio, TextArea } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import declineInquiry from "astrid-firestore/src/api/offers/declineInquiry";

import offerDeclineReasons from "../../../constants/offerDeclineReasons";
import useOfferDeclineReasonTranslations from "../../../hooks/useOfferDeclineReasonTranslations";

export default function OfferDeclineReasonForm({ offer, onClose }) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [declineReason, setDeclineReason] = useState("");
	const [declineReasonText, setDeclineReasonText] = useState("");

	const offerDeclineReasonTranslations = useOfferDeclineReasonTranslations();

	const onClickDecline = async () => {
		setLoading(true);
		await declineInquiry(firebase, { offer, declineReason, declineReasonText });
		onClose();
		setLoading(false);
	};

	return (
		<Modal open onClose={onClose}>
			<Modal.Content>
				<Modal.Description style={{ maxWidth: "100%" }}>
					<Form>
						{Object.values(offerDeclineReasons).map((reason) => {
							return (
								<Form.Field key={reason.value}>
									<Radio
										label={offerDeclineReasonTranslations[reason]}
										onClick={() => setDeclineReason(reason)}
										checked={declineReason === reason}
									/>
								</Form.Field>
							);
						})}

						{declineReason === "other" && (
							<TextArea
								value={declineReasonText}
								onChange={(e) => setDeclineReasonText(e.target.value)}
							/>
						)}
					</Form>
				</Modal.Description>
			</Modal.Content>

			<Modal.Actions>
				<Button onClick={onClose}>{t("cancel", "Cancel")}</Button>

				<Button
					disabled={!declineReason || loading}
					loading={loading}
					content={t("decline")}
					onClick={onClickDecline}
					negative
				/>
			</Modal.Actions>
		</Modal>
	);
}
