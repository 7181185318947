import { useTranslation } from "react-i18next";

import pipelineStatuses from "astrid-firestore/src/api/pipelines/constants/pipelineStatuses";

export default function usePipelineStatusTranslations() {
	const { t } = useTranslation();

	return {
		[pipelineStatuses.DISABLED]: t("disabled", "Disabled"),
		[pipelineStatuses.PENDING]: t("pending", "Pending"),
		[pipelineStatuses.RUNNING]: t("running", "Running"),
		[pipelineStatuses.COMPLETED]: t("completed", "Completed"),
		[pipelineStatuses.FAILED]: t("failed", "Failed"),
	};
}
