import React, { useMemo } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import { updateDocument } from "astrid-firestore/src/helpers";

import Accordion from "../../../ui/components/Accordion/Accordion";

import Voice from "./components/Voice";
import VoicesActions from "./components/VoicesActions";

export default function Voices({ production, voices }) {
	const onDragEnd = async ({ source, destination, draggableId }) => {
		if (source.droppableId === destination.droppableId && source.index === destination.index) {
			return;
		}

		const sourceVoice = voices[source.droppableId];
		const destinationVoice = voices[destination.droppableId];

		const sourceVoiceReaderIds = [...sourceVoice.readerIds];
		const destinationVoiceReaderIds =
			sourceVoice === destinationVoice ? sourceVoiceReaderIds : [...destinationVoice.readerIds];

		sourceVoiceReaderIds.splice(source.index, 1);
		destinationVoiceReaderIds.splice(destination.index, 0, draggableId);

		await updateDocument(production.ref, {
			[`voices.${source.droppableId}.readerIds`]: sourceVoiceReaderIds,
			[`voices.${destination.droppableId}.readerIds`]: destinationVoiceReaderIds,
		});
	};

	const allReaderIds = useMemo(
		() => Object.values(voices).reduce((readerIds, voice) => [...readerIds, ...voice.readerIds], []),
		[voices],
	);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Accordion>
				{Object.values(voices).map((voice) => (
					<Voice key={voice.id} voice={voice} allReaderIds={allReaderIds} production={production} />
				))}
				<Accordion.Footer>
					<VoicesActions production={production} />
				</Accordion.Footer>
			</Accordion>
		</DragDropContext>
	);
}
