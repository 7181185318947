import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../authorization/hooks/useHasRight";

import AdminTitlesView from "../views/AdminTitlesView/AdminTitlesView";

export default function AdminTitles() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature || !hasRight("titles.view")) {
		return <Unauthorized />;
	}

	return <AdminTitlesView organization={organization} />;
}
