import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react";

import Flex from "astrid-components/lib/components/Layout/Flex";
import deleteDocument from "astrid-firestore/src/helpers/deleteDocument";
import { getNameWithLastNameInitial } from "astrid-helpers/src/name";

import { useProfile } from "../../../../authentication/state/profile";

import isAnyRecorderInTeamOrOpenPosition from "../../../helpers/isAnyRecorderInTeamOrOpenPosition";

import PopupContent from "./PopupContent";

export default function EventContent({ event, timeText, isTimelineView, modifiedSessions, setModifiedSessions }) {
	const { t } = useTranslation();
	const user = useProfile();

	const [loadingRemoveSession, setLoadingRemoveSession] = useState(false);

	if (event.display === "background") {
		return null;
	}

	const { session } = event.extendedProps;

	const readers = Object.values(session.readerData || {});

	function truncateString(str, maxLength) {
		return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
	}

	const recorderName = isAnyRecorderInTeamOrOpenPosition(session)
		? t("upForGrabs", "Open position")
		: session.recorderData?.name
		? getNameWithLastNameInitial(session.recorderData?.name)
		: "";

	const readerNames = readers?.map(({ name }) => getNameWithLastNameInitial(name)).join(", ");

	const showReader = !session.reader.includes(user.id);
	const showRecorder = session.recorder !== user.id;

	const removeSession = async (e) => {
		setLoadingRemoveSession(true);

		e.stopPropagation();

		try {
			await deleteDocument(session.ref);

			setModifiedSessions((modifiedSessions) => {
				const newSessionsToNotify = { ...modifiedSessions };
				delete newSessionsToNotify[session.id];
				return newSessionsToNotify;
			});
		} catch (error) {
			console.log(error);
		}

		setLoadingRemoveSession(false);
	};

	const showRemoveIcon = modifiedSessions && Object.keys(modifiedSessions).includes(session.id);

	return (
		<Popup
			content={<PopupContent timeText={timeText} readers={readers} session={session} />}
			basic
			size="mini"
			trigger={
				isTimelineView ? (
					<Flex alignItems="center">
						<div
							style={{
								paddingLeft: 3,
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							<strong style={{ maxWidth: 10 }}>{truncateString(session.productionData?.title, 6)}</strong>

							{showRecorder && (
								<>
									<Icon name="headphones" style={{ marginLeft: 2 }} />
									{recorderName}
								</>
							)}

							{showReader && (
								<>
									<Icon name="microphone" style={{ marginLeft: 2 }} />
									{readerNames}
								</>
							)}
						</div>
						{showRemoveIcon && (
							<div>
								<Icon
									loading={loadingRemoveSession}
									name={loadingRemoveSession ? "spinner" : "trash alternate"}
									onClick={(e) => removeSession(e)}
								/>
							</div>
						)}
					</Flex>
				) : (
					<div
						style={{
							paddingLeft: 3,
							height: "100%",
							overflow: "hidden",
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
						}}
					>
						<strong style={{ maxWidth: 10 }}>{session.productionData?.title}</strong>

						<div>
							<Icon name="cube" style={{ marginLeft: 2 }} />
							{session.studioData?.name}
						</div>

						<div>
							<Icon name="headphones" style={{ marginLeft: 2 }} />
							{recorderName}
						</div>

						{showReader && (
							<div>
								<Icon name="microphone" style={{ marginLeft: 2 }} />
								{readerNames}
							</div>
						)}
					</div>
				)
			}
		/>
	);
}
