import equal from "fast-deep-equal";

import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { ref } from "../../../types/_types";

import { Article } from "./Article";

export const ArticleVersionBase = FirestoreDocument.extend({
	after: Article,
	before: Article,
}).merge(FirestoreLifecycle);

export const ArticleVersion = ArticleVersionBase.partial().transform((data) => {
	if (data.after && data.before) {
		data.data = Object.entries(data.after)
			.filter(([key, value]) => !equal(value, data.before[key]))
			.reduce((data, [key, value]) => {
				return {
					...data,
					[key]: value,
				};
			}, {});
	}

	return data;
});

export const ArticleVersionRef = ref(ArticleVersionBase, {
	created: true,
	createdBy: true,
});
