import { useState } from "react";
import { useTranslation } from "react-i18next";

import { sendPasswordResetEmail } from "../utils/resetPassword";

export default function useResetPassword(user) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

	const resetPassword = async () => {
		setLoading(true);

		const email = user.email;

		try {
			await sendPasswordResetEmail(email);

			setMessage(t("youGotInstByMail", "You have been sent an email with instructions to set your new password"));
		} catch (error) {
			console.log(error);
			setError(error);
		}

		setLoading(false);
	};

	return {
		loading,
		message,
		error,
		resetPassword,
	};
}
