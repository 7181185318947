import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Header, Icon, Input, Label } from "semantic-ui-react";

import useDebounce from "astrid-hooks/src/useDebounce";

import FlexColumn from "../../../../../components/Flex/FlexColumn";
import useRefinement from "../../../../readers/components/ReaderSearchForm/hooks/useRefinement";

export default function RefinementCheckboxes({ attribute, header = "", customLabel, searchable = true }) {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState("");
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	const { items, simpleRefine, searchForItems, canToggleShowMore, toggleShowMore, isShowingMore } = useRefinement({
		attribute: attribute,
		limit: 5,
		showMore: true,
		showMoreLimit: 50,
	});

	useEffect(() => {
		searchForItems(debouncedSearchTerm);
	}, [debouncedSearchTerm, searchForItems]);

	return (
		<FlexColumn>
			<Header size="tiny">{header}</Header>
			{searchable && (
				<Input
					value={searchTerm}
					iconPosition="left"
					placeholder={t("search")}
					onChange={(e, { value }) => setSearchTerm(value)}
					style={{ marginBottom: 10 }}
				>
					<Icon name="search" />
					<input />
				</Input>
			)}
			{items.map((item) => {
				return (
					<div style={{ marginBottom: 5 }} key={item.label}>
						<Checkbox
							label={customLabel ? customLabel(item.label) : item.label}
							checked={item.isRefined}
							onClick={() => {
								simpleRefine(item.value);
							}}
						/>
						<Label style={{ marginLeft: 5 }} circular color="grey" size="small">
							{item.count}
						</Label>
					</div>
				);
			})}
			{canToggleShowMore && (
				<Button basic compact style={{ marginTop: 10, width: "150px" }} onClick={() => toggleShowMore()}>
					{!isShowingMore ? t("showMore", "Show more ...") : t("showLess", "Show less")}
				</Button>
			)}
		</FlexColumn>
	);
}
