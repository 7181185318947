import React from "react";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminImprintMenuOptions from "../hooks/useAdminImprintMenuOptions";

export default function AdminImprintHeader({ imprint }) {
	const menuOptions = useAdminImprintMenuOptions({ imprint });

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<Header style={{ margin: 0 }}>{imprint.name}</Header>
		</AdminPageHeader>
	);
}
