import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "semantic-ui-react";

import AddUserLanguageModal from "../../../../account/views/AccountSettingsView/components/AddUserLanguageModal";
import UserLanguagesTable from "../../../../users/components/UserLanguagesTable/UserLanguagesTable";

export default function UserLanguageSkills({ user }) {
	const { t } = useTranslation();

	const [openModal, setOpenModal] = useState(false);

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("languageSkills", "Language skills")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<UserLanguagesTable user={user} />
			</Card.Content>
			<Card.Content>
				<Button primary onClick={() => setOpenModal(true)}>
					{t("add", "Add")}
				</Button>

				{openModal && <AddUserLanguageModal user={user} onClose={() => setOpenModal(false)} />}
			</Card.Content>
		</Card>
	);
}
