import firebase from "firebase/app";
import { useCallback } from "react";

import { updateDocument } from "astrid-firestore/src/helpers";
import { getUserRef } from "astrid-firestore/src/utils/referenceMappers";

import { useProfile } from "../../authentication/state/profile";

export default function useSoftDelete() {
	const user = useProfile();

	return useCallback(
		(ref) =>
			updateDocument(ref, {
				deleted: true,
				deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
				deletedBy: getUserRef(user),
			}),
		[user],
	);
}
