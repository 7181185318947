import React from "react";
import { firebase } from "astrid-firebase";
import { Form, Icon } from "semantic-ui-react";
import get from "lodash/get";
import { Link } from "react-router-dom";

const DistributorPublisher = ({ id, publishers, connection, meta, audio, formValues, handleChange }) => (
	<div className="dist-publisher">
		<b>{publishers[id]}</b>{" "}
		<Icon
			name="trash alternate"
			style={{ cursor: "pointer", float: "right" }}
			onClick={(e) => {
				const current = formValues.publishers || {};
				current[id] = firebase.firestore.FieldValue.delete();
				handleChange(e, { name: "publishers", value: current });
			}}
		/>
		{connection && (
			<div className="field">
				<label>Användaruppgifter</label>
				Gemensamma{" "}
				<Form.Checkbox
					toggle
					className="dist-toggle"
					checked={!!get(formValues, "publishers[" + id + "].credentials")}
					onChange={(e, data) => {
						const current = formValues.publishers || {};
						current[id].credentials = data.checked ? {} : false;
						handleChange(e, { name: "publishers", value: current });
					}}
				/>{" "}
				Egna{" "}
			</div>
		)}
		{get(formValues, "publishers[" + id + "].credentials") && (
			<>
				<Form.Input
					label="Användarnamn"
					value={get(formValues, "publishers[" + id + "].credentials.user") || ""}
					onChange={(e, data) => {
						const current = formValues.publishers || {};
						current[id].credentials.user = data.value;
						handleChange(e, { name: "publishers", value: current });
					}}
				/>
				<Form.Input
					label="Lösenord"
					value={get(formValues, "publishers[" + id + "].credentials.pass") || ""}
					type="password"
					onChange={(e, data) => {
						const current = formValues.publishers || {};
						current[id].credentials.pass = data.value;
						handleChange(e, { name: "publishers", value: current });
					}}
				/>
			</>
		)}
		{meta && audio && (
			<Form.Checkbox
				label="Inaktivera metadata"
				checked={!!get(formValues, "publishers[" + id + "].excludeMeta")}
				onChange={(e, data) => {
					const current = formValues.publishers || {};
					current[id].excludeMeta = data.checked;
					handleChange(e, { name: "publishers", value: current });
				}}
			/>
		)}
		<small style={{ display: "block", fontWeight: "bold" }}>
			<Link to={"/publisher/" + id}>
				<Icon name="edit" /> Redigera distributionsförval
			</Link>
		</small>
	</div>
);

export default DistributorPublisher;
