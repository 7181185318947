import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import AdminOrdersView from "../views/AdminOrdersView/AdminOrdersView";

export default function AdminPurchaseOrders() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("orders.view")) {
		return <Unauthorized />;
	}

	return <AdminOrdersView header={t("POs", "Purchase orders")} expense={true} firstPartyId={organization.id} />;
}
