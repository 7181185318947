import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";

export default function SelectFilter({ column: { filterValue, setFilter, preFilteredRows, id, Header, compact } }) {
	const { t } = useTranslation();

	const options = useMemo(() => {
		const options = new Set();

		preFilteredRows.forEach((row) => options.add(row.values[id]));

		return [
			{ key: id, text: t("all", "All"), value: "" },
			...[...options.values()].map((key) => ({
				key,
				text: key,
				value: key,
			})),
		];
	}, [t, id, preFilteredRows]);

	const onChange = useCallback((e, { value }) => setFilter(value || undefined), [setFilter]);

	return (
		<Dropdown
			basic
			fluid
			clearable
			floating
			icon={null}
			compact={compact}
			options={options}
			value={filterValue || ""}
			placeholder={Header}
			onChange={onChange}
			trigger={
				<Flex style={{ gap: 15, padding: "1em" }}>
					<div>{filterValue || Header}</div>
					<div>
						<Icon name="bars" style={{ color: "#ddd" }} />
					</div>
				</Flex>
			}
		/>
	);
}
