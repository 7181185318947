import { useOutletContext, useParams } from "react-router-dom";

import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";

import AdminArticleVersionView from "../../views/AdminArticleView/AdminArticleVersionView";

export default function AdminArticleVersion() {
	const params = useParams();
	const context = useOutletContext();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const [version, loading, error] = useDocumentDataOnce(
		context.article.ref.collection("versions").doc(params.version),
	);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<LoadingContext data={version} loading={loading} error={error}>
			<AdminArticleVersionView version={version} {...context} />
		</LoadingContext>
	);
}
