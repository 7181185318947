import appendixes from "./agreements/appendixes";
import articleBundles from "./articles/articleBundles";
import articles from "./articles/articles";
import artifacts from "./artifacts/artifacts";
import authors from "./authors/authors";
import distributionChannels from "./distribution/distributionChannels";
import distributions from "./distribution/distributions";
import imprints from "./imprints/imprints";
import organizations from "./organizations/organizations";
import series from "./series/series";
import studios from "./studios/studios";
import titleBundles from "./titles/titleBundles";
import titles from "./titles/titles";
import translators from "./translators/translators";

export default function createAPI({ firebase, plugins, getUser = () => null, debug = false }) {
	const api = {};

	const context = { firebase, plugins, getUser, debug, api };

	api.firebase = firebase;

	api.appendixes = appendixes(context);
	api.articleBundles = articleBundles(context);
	api.articles = articles(context);
	api.artifacts = artifacts(context);
	api.authors = authors(context);
	api.distributionChannels = distributionChannels(context);
	api.distributions = distributions(context);
	api.imprints = imprints(context);
	api.organizations = organizations(context);
	api.series = series(context);
	api.studios = studios(context);
	api.titleBundles = titleBundles(context);
	api.titles = titles(context);
	api.translators = translators(context);

	return api;
}
