import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";

import AlertModal from "../AlertModal/AlertModal";

export default function DropdownMenu({
	icon = "ellipsis vertical",
	direction = "left",
	loading: initialLoading = false,
	children,
	text,
	options,
	size,
	iconStyle,
	...props
}) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const isLoading = loading || initialLoading;

	return (
		<>
			<Dropdown
				floating
				icon={text ? icon : false}
				direction={direction}
				trigger={
					!text && (
						<Icon
							style={iconStyle}
							size={size}
							loading={isLoading}
							name={isLoading ? "spinner" : icon}
							onClick={(e) => e.preventDefault()}
						/>
					)
				}
				text={text}
				{...props}
			>
				<Dropdown.Menu>
					{children && children({ setLoading, setError })}

					{options
						?.filter((option) => !option.hidden)
						.map(({ text, action, linkTo, ...props }) => (
							<Dropdown.Item
								as={linkTo ? Link : "div"}
								to={linkTo}
								key={text}
								onClick={
									action &&
									(async () => {
										setLoading(true);
										setError(null);

										try {
											await action();
										} catch (error) {
											console.error(error);
											setError(error);
										}

										setLoading(false);
									})
								}
								{...props}
							>
								{text}
							</Dropdown.Item>
						))}
				</Dropdown.Menu>
			</Dropdown>

			{error && <AlertModal text={error.message || error} onClose={() => setError(null)} />}
		</>
	);
}
