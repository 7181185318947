export default {
	name: "NFG",
	format: "wav",
	connection: "ftp",
	cover: false,
	audio: {
		rename: "ISBN_CLEANTITLE_SEQ.SUFFIX",
		moveIntoFolderAndZip: {
			zipName: "ISBN_WAV",
			folderName: "ISBN",
		},
	},
	ebook: {
		enableSendWithoutMetaData: true,
		zip: true,
		zipName: "ISBN_EPUB",
	},
	disableArticles: ["cd", "mp3cd"],
	email: ["delivery"],
};
