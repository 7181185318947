import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import AppendixForm from "../../../../agreements/components/AppendixForm/AppendixForm";

export default function EditAppendixAction(props) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<>
			<Dropdown.Item text={t("edit", "Edit")} onClick={() => setOpen(true)} />

			{open && <AppendixForm {...props} onClose={() => setOpen(false)} />}
		</>
	);
}
