import set from "lodash/set";
import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Divider, Form } from "semantic-ui-react";

import { db, firebase } from "astrid-firebase";

const ProductionAuthorContact = ({ author, translator, value = {}, onChange }) => {
	const people = [...(author || []), ...(translator || [])];
	const [loading, setLoading] = useState();
	const [peopleData, setPeopleData] = useState();

	// hack to avoid rerunning useEffect due to shallow comparison
	const peopleString = JSON.stringify(people);

	const setState = (path, data) => {
		const newState = { ...value };
		set(newState, path, data);
		onChange(newState);
	};

	useEffect(() => {
		if (peopleString && JSON.parse(peopleString).length) {
			setLoading(true);

			db.collection("people")
				.where(firebase.firestore.FieldPath.documentId(), "in", JSON.parse(peopleString))
				.get()
				.then((docs) => {
					const data = {};
					const state = { ...value };
					docs.forEach((doc) => {
						const info = doc.data();
						data[doc.id] = info;

						// set ignore flag if person already has contact info
						if (!state[doc.id]) state[doc.id] = { ignore: !!(info.email || info.phone) };
					});

					setLoading(false);
					setPeopleData(data);
					onChange(state);
				});
		}
	}, [peopleString]); // eslint-disable-line

	return people.length && !loading && peopleData ? (
		<Form>
			<p>
				<b>Ange kontaktuppgifter till upphovspersoner:</b>
			</p>
			{people.map((id) => {
				const person = peopleData?.[id];
				const state = value?.[id] || {};
				if (!person) return null;

				const { firstName, lastName, email, phone, type } = person;
				const hasContact = email || phone;

				return (
					!hasContact && (
						<div key={id}>
							<div className={state.ignore ? "fake-disabled" : ""}>
								{firstName + " " + lastName} ({type === "author" ? "Författare" : "Översättare"})
								<Form.Group widths={"equal"}>
									<Form.Input
										placeholder="E-post"
										type="email"
										value={state.email || ""}
										error={state.emailInvalid}
										onChange={(e, data) => {
											setState(id + ".email", data.value);
											setState(id + ".emailInvalid", false);
										}}
										onBlur={(e) => {
											if (state.email && !e.target.checkValidity())
												setState(id + ".emailInvalid", true);
										}}
									/>
									<div className={"field" + (state.phoneInvalid ? " error" : "")}>
										<PhoneInput
											placeholder="Telefon"
											value={state.phone || ""}
											defaultCountry="SE"
											onChange={(val) => {
												setState(id + ".phone", val);
												setState(id + ".phoneInvalid", false);
											}}
											onBlur={() => {
												if (state.phone && !isValidPhoneNumber(state.phone))
													setState(id + ".phoneInvalid", true);
											}}
										/>
									</div>
								</Form.Group>
							</div>
							<Form.Checkbox
								label="Kontaktuppgifter saknas/ej relevanta"
								size="tiny"
								checked={!!state.ignore}
								onChange={(e, data) => {
									setState(id + ".ignore", data.checked);
								}}
								style={{
									fontSize: ".85em",
								}}
							/>
							<Divider />
						</div>
					)
				);
			})}
		</Form>
	) : null;
};

export default ProductionAuthorContact;
