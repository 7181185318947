import React from "react";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";

import AdminPageHeader from "../../../components/AdminPage/AdminPageHeader";

import useAdminArticleBundleDropdownMenuOptions from "../hooks/useAdminArticleBundleDropdownMenuOptions";
import useAdminArticleBundleMenuOptions from "../hooks/useAdminArticleBundleMenuOptions";

export default function AdminArticleBundleHeader({ bundle }) {
	const { t } = useTranslation();
	const menuOptions = useAdminArticleBundleMenuOptions({ bundle });

	const dropdownMenuOptions = useAdminArticleBundleDropdownMenuOptions({ bundle });

	return (
		<AdminPageHeader menuOptions={menuOptions} dropdownMenuOptions={dropdownMenuOptions}>
			<Header style={{ margin: 0 }}>
				{bundle.name}

				<Header.Subheader>
					{t("amountArticles", "{{amount}} articles", { amount: bundle.articles.length })}
				</Header.Subheader>
			</Header>
		</AdminPageHeader>
	);
}
