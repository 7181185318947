import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

import isDenied from "astrid-firestore/src/api/approval/isDenied";
import denyOffer from "astrid-firestore/src/api/offers/denyOffer";

import { getProfile } from "../../../../authentication/state/profile";

export default function DenyOfferDropdownItem({ offer, setLoading, ...props }) {
	const { t } = useTranslation();

	return (
		<Dropdown.Item
			text={t("deny", "Deny")}
			disabled={isDenied(offer)}
			onClick={async () => {
				const user = getProfile();

				setLoading?.(true);

				await denyOffer({ offer, user });

				setLoading?.(false);
			}}
			{...props}
		/>
	);
}
