import AddVoiceAction from "./AddVoiceAction";
import SetVoiceFromTeamReaders from "./SetVoiceFromTeamReaders";

export default function VoicesActions({ production }) {
	return (
		<div>
			<AddVoiceAction production={production} />
			<SetVoiceFromTeamReaders production={production} />
		</div>
	);
}
