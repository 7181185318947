import Select from "../../../ui/components/Select/Select";

import useTeamUserOptions from "../../hooks/useTeamUserOptions";

export default function TeamUserSelect({ role, production, ...props }) {
	const [options, loading, error] = useTeamUserOptions({
		role,
		production,
	});

	return <Select options={options} loading={loading} error={error?.message || error} search deburr {...props} />;
}
