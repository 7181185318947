import React from "react";
import { Form } from "semantic-ui-react";

import useFormField from "../../hooks/useFormField";

export default function FormCheckbox(props) {
	const { field } = useFormField({ valueField: "checked", ...props });

	const { value, ...restField } = field;

	return <Form.Checkbox {...restField} checked={!!value} ref={undefined} />;
}
