export function sortOpenCosts(a, b) {
	if (a.price.type === "manage" && b.price.type !== "manage") {
		return -1;
	}

	if (a.price.type !== "manage" && b.price.type === "manage") {
		return 1;
	}

	return a.secondParty?.name.localeCompare(b.secondParty?.name);
}
