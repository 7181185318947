import { getDeliveryMinutes, getProgressAmount } from "../../productions/utils/productionHelper";

export function getActivity(cost, users) {
	if (cost.price?.amount) {
		return cost.price.unit === "activity" ? cost.price.amount : 0;
	}

	const user = users.find(({ id }) => id === cost.user?.id);

	return ((cost.session ? cost.session.stats?.sessionDuration : user?.activity?.seconds) || 0) / 60;
}

export function getAmountProduced({ cost, production }) {
	if (cost.price?.amount) {
		switch (cost.price.unit) {
			case "time":
				return cost.price.amount;
			case "hour":
				return cost.price.amount * 60;
			default:
				return 0;
		}
	}

	switch (cost.price?.type) {
		case "edit":
			return getProgressAmount(production, "edited", cost.secondParty?.id);
		case "proof":
			return getProgressAmount(production, "proofed", cost.secondParty?.id);
		case "record":
			return (cost.session?.stats?.cubaseDuration || 0) / 60;
		default:
			return getDeliveryMinutes(production);
	}
}

export function getCostAmount(cost) {
	switch (cost.price?.unit) {
		case "activity":
			return cost.activity || 0;
		case "time":
			return cost.amountProduced || 0;
		case "hour":
			return cost.amountProduced / 60 || 0;
		default:
			return 1;
	}
}

export function getCostValue({ cost, exchangeRate, expenses = 0 }) {
	switch (cost.price?.unit) {
		case "percentage":
			return (cost.price.price / 100) * expenses * exchangeRate.rates[cost.price.currency] || 0;
		default:
			return cost.price?.price || 0;
	}
}

export function getCostExchange({ cost, exchangeRate }) {
	if (!cost.price?.currency) {
		return null;
	}

	return {
		rate: exchangeRate.rates[cost.price.currency],
		value: cost.total / exchangeRate.rates[cost.price.currency],
		currency: exchangeRate.base,
		exchangeRateId: exchangeRate.id,
	};
}

export function reduceTotalExchangedCost(costs) {
	return costs.reduce((total, cost) => total + cost.exchange?.value, 0);
}

export function reduceSimilarCosts(costs, cost) {
	// Group costs by agreement and reference price
	const similarCost = costs.find(
		(c) =>
			cost.agreement &&
			cost.referencePrice &&
			c.agreement?.id === cost.agreement.id &&
			c.referencePrice?.id === cost.referencePrice.id,
	);

	// If there is a similar cost, add the new cost to the existing one
	if (similarCost) {
		costs[costs.indexOf(similarCost)] = {
			...similarCost,
			activity: similarCost.activity + cost.activity,
			amountProduced: similarCost.amountProduced + cost.amountProduced,
			amount: similarCost.amount + cost.amount,
			openAmount: similarCost.openAmount + cost.openAmount,
			exchange: {
				...similarCost.exchange,
				value: similarCost.exchange.value + cost.exchange.value,
			},
			estimation: {
				...similarCost.estimation,
				value: similarCost.estimation.value + cost.estimation.value,
				exchanged: similarCost.estimation.exchanged + cost.estimation.exchanged,
			},
			costs: [...similarCost.costs, cost],
			total: similarCost.total + cost.total,
		};
	} else {
		costs.push({ ...cost, costs: [cost] });
	}

	return costs;
}
