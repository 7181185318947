import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import { firebase } from "astrid-firebase";
import acceptInquiry from "astrid-firestore/src/api/offers/acceptInquiry";
import offerStatuses from "astrid-firestore/src/api/offers/constants/offerStatuses";

import Flex from "../../../../../components/Flex/Flex";

export default function InquiryOfferActions({ offer, onClickDecline }) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const onClickAccept = async () => {
		setLoading(true);
		await acceptInquiry(firebase, { offer });
		setLoading(false);
	};

	return (
		<>
			{offer.status === offerStatuses.OFFERED && (
				<Flex style={{ gap: 10 }}>
					<Button
						loading={loading}
						disabled={loading}
						content={t("decline")}
						onClick={onClickDecline}
						negative
						fluid
					/>

					<Button
						loading={loading}
						disabled={loading}
						content={t("acceptInquiry")}
						onClick={onClickAccept}
						color="yellow"
						fluid
					/>
				</Flex>
			)}
		</>
	);
}
