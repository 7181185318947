import { useMemo } from "react";

import { sortTeam } from "../utils/teamUtils";

export default function useProductionTeam(production) {
	return useMemo(
		() =>
			Object.values(production?.team || {})
				.filter((member) => member.user)
				.sort(sortTeam),
		[production?.team],
	);
}
