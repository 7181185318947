import { useTranslation } from "react-i18next";

import api from "../../../../api";
import ModalPrompt from "../../../ui/components/ModalPrompt/ModalPrompt";

import ArticlesInstantSearchSelect from "../../../articles/components/ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function AddArticlesToTitleForm({ title, onClose }) {
	const { t } = useTranslation();

	const { form, onSubmit, error } = useSimpleForm({
		defaultValues: {
			sync: false,
			articleIds: [],
		},
		onSubmit: async ({ articleIds, sync }) => {
			const articles = await api.articles.getAllByIds(articleIds);

			return api.titles.addArticles({ title, articles, sync });
		},
		onSuccess: onClose,
	});

	return (
		<ModalPrompt
			size="tiny"
			header={t("addArticles", "Add articles")}
			onSave={onSubmit}
			onClose={onClose}
			error={error}
		>
			<Form form={form}>
				<Form.Field name="articleIds">
					<ArticlesInstantSearchSelect multiple publisher={title.publisher} />
				</Form.Field>

				<Form.Checkbox
					name="sync"
					label={t("syncNewTitleData", "Sync new title data")}
					rules={{ required: false }}
				/>
			</Form>
		</ModalPrompt>
	);
}
