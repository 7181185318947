import { useTranslation } from "react-i18next";
import { Divider, Header } from "semantic-ui-react";

import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";

import Flex from "../../../../../components/Flex/Flex";
import ImageAvatar from "../../../../ui/components/ImageAvatar/ImageAvatar";

export default function PopupContent({ timeText, readers, session }) {
	const { t } = useTranslation();

	const recorderTypes = {
		[sessionOfferTypes.OPEN_POSITION_RECORDER]: t("openPosition", "Open position"),
		[sessionOfferTypes.ANY_RECORDER_IN_TEAM]: t("anyRecorderInTeam", "Any recorder in team"),
	};

	return (
		<Flex style={{ display: "inline-flex", flexDirection: "column", alignItems: "stretch" }}>
			<HeaderComponent
				avatar={false}
				image={session.productionData.imgThumb || "/img/image.png"}
				name={session.productionData.title}
				workTitle={timeText}
				alignTop
			/>

			<Divider style={{ margin: "5px 0" }} />
			{readers.map((reader) => (
				<HeaderComponent
					key={reader.id}
					image={reader.img}
					name={reader.name}
					workTitle={t("reader", "Narrator")}
				/>
			))}

			{session.recorder && (
				<HeaderComponent
					image={session.recorderData.img}
					name={recorderTypes[session.recorder] || session.recorderData.name || session.recorder}
					workTitle={t("recorder", "Recording engineer")}
				/>
			)}

			{session.managers?.map((manager) => (
				<HeaderComponent
					key={manager.id}
					image={manager.img}
					name={manager.name}
					workTitle={t("productionManager", "Production coordinator")}
				/>
			))}
		</Flex>
	);
}

function HeaderComponent({ image, name, workTitle, alignTop, avatar = true }) {
	return (
		<Header style={{ margin: 2, display: "flex", alignItems: alignTop ? "flex-start" : "center" }} as="h5" image>
			<ImageAvatar
				avatar={avatar}
				src={image}
				style={{ width: 27, height: 27, flexShrink: 0, marginTop: alignTop ? 3 : 0 }}
				rounded={!avatar}
			/>
			<Header.Content style={{ fontSize: 13 }}>
				{name}
				<Header.Subheader style={{ fontSize: 11 }}>{workTitle}</Header.Subheader>
			</Header.Content>
		</Header>
	);
}
