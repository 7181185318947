import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Label } from "semantic-ui-react";

import previewPdf from "./previewPdf";

export default function PreviewAgreementPdfButton({ request }) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	return (
		<>
			<Button
				disabled={!request}
				loading={loading}
				onClick={async () => {
					setLoading(true);

					const data = request();

					try {
						const url = await previewPdf({
							...data,
							expires: data.expires?.toDate().toLocaleDateString("sv"),
							activeFrom: data.activeFrom?.toDate().toLocaleDateString("sv"),
						});

						window.open(url, "_blank");
					} catch (error) {
						console.error("Failed to preview agreement pdf", error);
					}

					setLoading(false);
				}}
			>
				{t("preview")}
			</Button>

			{loading && (
				<Label basic pointing="left">
					{t("previewPdfLoading")}
				</Label>
			)}
		</>
	);
}
