import LoadingContext from "../../../../ui/components/LoadingContext/LoadingContext";

import useChannelReleases from "../../../hooks/useChannelReleases";

import Release from "./Release";

export default function ChannelReleases({ app, channel }) {
	const [releases, loading, error] = useChannelReleases(app, channel);

	return (
		<LoadingContext data={releases} loading={loading} error={error}>
			<div style={{ marginTop: 10 }}>
				{releases.map((release) => (
					<Release key={release.os} release={release} />
				))}
			</div>
		</LoadingContext>
	);
}
