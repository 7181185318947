import React from "react";
import { Form } from "semantic-ui-react";

import useFormField from "../../hooks/useFormField";

export default function FormTextArea(props) {
	const { field } = useFormField(props);

	return <Form.TextArea {...field} ref={undefined} />;
}
