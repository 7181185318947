import { Card } from "semantic-ui-react";

import StudioCompression from "./components/StudioCompression";

export default function OrganizationSettingsStudioView({ organization }) {
	return (
		<Card.Group>
			<StudioCompression organization={organization} />
		</Card.Group>
	);
}
