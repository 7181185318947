import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import usePriceOptions from "../../prices/hooks/usePriceOptions";

export default function useTeamPriceOptions({ role, user, agreement, language, producerId }) {
	const { t } = useTranslation();

	const [priceOptions, loading, error] = usePriceOptions({
		user,
		role,
		language,
		agreement,
		producerId,
	});

	const defaultPriceId = user?.prices?.[producerId]?.[`${language}-${role}`]?.price?.id;

	const options = useMemo(
		() =>
			priceOptions.map((option) => ({
				...option,
				text: option.text + (option.data.id === defaultPriceId ? ` [${t("default")}]` : ""),
				defaultOption: option.data.id === defaultPriceId,
			})),
		[defaultPriceId, priceOptions, t],
	);

	return [options, loading, error];
}
