import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Label } from "semantic-ui-react";

import { updateDocument } from "astrid-firestore/src/helpers";

import Accordion from "../../../../ui/components/Accordion/Accordion";

import VoiceActions from "./VoiceActions";

export default function VoiceName({ voice, production }) {
	const { t } = useTranslation();

	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState(voice.name);

	const placeholder = t("voiceNumber", "Voice {{number}}", { number: voice.id });

	const onSave = async () => {
		if (name !== voice.name) {
			setLoading(true);
			await updateDocument(production.ref, {
				[`voices.${voice.id}.name`]: name,
			});
			setLoading(false);
		}
		setEdit(false);
	};

	return (
		<Accordion.Title style={{ background: "#f9fafb" }} onClick={() => setEdit(true)}>
			<div>
				{edit ? (
					<Input
						value={name}
						loading={loading}
						onChange={(e, { value }) => setName(value)}
						autoFocus
						onBlur={onSave}
						placeholder={placeholder}
						onKeyDown={({ key }) => key === "Enter" && onSave()}
					/>
				) : (
					name || placeholder
				)}

				{voice.assigned && (
					<Label color="green" style={{ marginLeft: 10 }}>
						{t("assigned", "Assigned")}
					</Label>
				)}
			</div>

			<VoiceActions voice={voice} production={production} />
		</Accordion.Title>
	);
}
