import { useTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";

import { mapAgreementPrices } from "astrid-firestore/src/api/agreements/utils/agreementMappers";

import useAgreementPricesColumns from "../../../../agreements/hooks/useAgreementPricesColumns";
import Table from "../../../../ui/components/Table/Table";

export default function TemplatePrices({ template }) {
	const { t } = useTranslation();

	const pricesColumns = useAgreementPricesColumns();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("prices", "Prices")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Table data={mapAgreementPrices(template)} columns={pricesColumns} footer={false} />
			</Card.Content>
		</Card>
	);
}
