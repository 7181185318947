import { useMemo } from "react";

import { db } from "astrid-firebase";
import offerTypes from "astrid-firestore/src/api/offers/constants/offerTypes";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

export default function useOpenPositionsQuery({ type, orgId, user }) {
	return useMemo(() => {
		if (type === organizationTypes.PRODUCER && user && orgId) {
			const defaultPricingRoles = user?.prices?.[orgId];
			const langRoles = Object.keys(defaultPricingRoles || {});
			if (langRoles?.length) {
				return db
					.collection("offers")
					.where("deleted", "==", false)
					.where("producer.id", "==", orgId)
					.where("type", "==", offerTypes.OPEN)
					.where("languageRole", "in", langRoles)
					.orderBy("createdAt", "desc")
					.limit(50);
			}
		}
		return false;
	}, [type, orgId, user]);
}
