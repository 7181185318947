import AdminPageView from "../../../admin/components/AdminPage/AdminPageView";

import SuperAdminDistributionChannelHeader from "./components/SuperAdminDistributionChannelHeader";

export default function SuperAdminDistributionChannelView(props) {
	return (
		<AdminPageView {...props}>
			<SuperAdminDistributionChannelHeader />
		</AdminPageView>
	);
}
