import { Header } from "semantic-ui-react";

import useWindowSize from "react-use/lib/useWindowSize";

import Flex from "../../../../components/Flex/Flex";

import DateHeaderContent from "./components/DateHeaderContent";
import HeaderToolbarNavigation from "./components/HeaderToolbarNavigation";
import HeaderToolbarNavigationDropdown from "./components/HeaderToolbarNavigationDropdown";
import HeaderToolbarTools from "./components/HeaderToolbarTools";

export default function HeaderToolbar({
	disableTools,
	modifiedSessions,
	onOpenNotifyModal,
	onStopBooking,
	onClickBook,
	isBooking,
	onClickFilter,
	viewType,
	startDate,
	endDate,
	isEditing,
	onClickEdit,
	navigation,
	filterForm,
	resourceType,
}) {
	const { width } = useWindowSize();

	const desktop = width > 1200;
	const tablet = width > 900;

	return (
		<Flex style={{ alignItems: "center", marginBottom: 14 }}>
			{!disableTools && (
				<HeaderToolbarTools
					modifiedSessions={modifiedSessions}
					onOpenNotifyModal={onOpenNotifyModal}
					onStopBooking={onStopBooking}
					onClickBook={onClickBook}
					isBooking={isBooking}
					onClickFilter={onClickFilter}
					isEditing={isEditing}
					onClickEdit={onClickEdit}
					filterForm={filterForm}
					resourceType={resourceType}
					desktop={desktop}
					tablet={tablet}
				/>
			)}

			<Header size={viewType.includes("Week") ? "medium" : "large"} style={{ margin: 0 }}>
				<DateHeaderContent endDate={endDate} startDate={startDate} viewType={viewType} />
			</Header>

			{desktop && (
				<HeaderToolbarNavigation navigation={navigation} viewType={viewType} disableTools={disableTools} />
			)}

			{!desktop && (
				<HeaderToolbarNavigationDropdown
					navigation={navigation}
					viewType={viewType}
					disableTools={disableTools}
				/>
			)}
		</Flex>
	);
}
