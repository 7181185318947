export default {
	name: "Audible UK",
	format: "mp3",
	connection: "ftp",
	audio: {
		folder: "ISBN", // defaults to ISBN
		zip: true,
	},
	meta: {
		format: "excel",
		grouped: true, // put each titles/articles in same excel sheet
		folder: "metadata",
		rename: "delivery_note_DATE_TIME",
	},
	cover: {
		folder: "ISBN",
		rename: "ISBN",
		square: true, // crop to square
		min: 2400, // scale up to min 2400
	},
	email: ["delivery"],
	disableArticles: ["cd", "mp3cd", "ebook"], // does not accept ebooks
};
