import React from "react";

import Select from "../../ui/components/Select/Select";

import useVendorOptions from "../hooks/useVendorOptions";

export default function VendorSelect({ vendors, ...props }) {
	const vendorOptions = useVendorOptions(vendors);

	return <Select search options={vendorOptions} {...props} />;
}
