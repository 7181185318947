import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Button, Header, Modal } from "semantic-ui-react";

import PublisherDistribution from "astrid-web/src/components/publisher/PublisherDistribution";

const ProductionDistribution = ({ publisher, production, disabled, handleChange, distributors, distOptions }) => {
	const [distOptionModal, setDistOptionModal] = useState(false);
	const [tempDistOptions, setTempDistOptions] = useState(false);

	const { t } = useTranslation();

	const activeArticles = [];
	if (typeof production.isbn === "string") activeArticles.push("total");
	if (production.deliveryEbook) activeArticles.push("ebook");
	if (production.deliveryParts) activeArticles.push("part");
	if (production.deliveryCD) activeArticles.push("cd");
	if (production.deliveryMP3CD) activeArticles.push("mp3cd");

	return (
		<>
			{publisher?.distributionOptions &&
				!!activeArticles?.length &&
				Object.values(publisher.distributionOptions).find(
					(opt) => opt.languages && opt.languages.includes(production.language),
				) && (
					<Button
						primary
						floated="right"
						labelPosition="left"
						icon="sitemap"
						content={t("editDistribution")}
						disabled={disabled}
						onClick={() => {
							setDistOptionModal(true);
						}}
					/>
				)}
			{distOptionModal && (
				<Modal
					size="fullscreen"
					open={true}
					closeOnDimmerClick={false}
					onClose={() => {
						setDistOptionModal(false);
					}}
					style={{ top: "2%" }}
				>
					<Modal.Header>
						<Header as="h4" icon="sitemap" content={`${production.title} – ` + t("distribution")} />
					</Modal.Header>
					<Modal.Content scrolling>
						<PublisherDistribution
							productionPage
							singleLanguage={production.language || "sv"}
							publisher={publisher}
							distributors={Object.entries(distributors).reduce((prev, curr) => {
								const [id, info] = curr;
								prev.push({ id, info });
								return prev;
							}, [])}
							distributionOptions={{
								override: {
									languages: [production.language],
									...distOptions,
								},
							}}
							activeArticles={activeArticles}
							onChange={(distributionOptions) => {
								setTempDistOptions(distributionOptions);
							}}
							saveOptions={(distributionOptions) => {
								if (tempDistOptions) {
									handleChange(null, { name: "distributionOptions", value: tempDistOptions });
									setTempDistOptions(false);
								}
							}}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button
							floated="left"
							onClick={() => {
								setDistOptionModal(false);
							}}
						>
							{t("undo")}
						</Button>
						<Button
							color="green"
							onClick={() => {
								if (tempDistOptions) {
									handleChange(null, { name: "distributionOptions", value: tempDistOptions });
									setTempDistOptions(false);
								}
								setDistOptionModal(false);
							}}
						>
							{t("saveAndClose")}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default withTranslation()(ProductionDistribution);
