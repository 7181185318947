import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import AddUserForm from "./AddUserForm";

export default function AddUserButton({ organization, reader }) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button basic secondary onClick={() => setOpen(true)}>
				{reader ? t("addReader", "Add narrator") : t("addUser", "Add user")}
			</Button>

			{open && <AddUserForm organization={organization} onClose={() => setOpen(false)} reader={reader} />}
		</>
	);
}
