import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";
import { languageOptions } from "astrid-helpers/src/languages";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import useProducerTypeOptions from "../../../../organizations/hooks/useProducerTypeOptions";

export default function ProducerSettings({ producer }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		defaultValues: {
			// name: producer.name || "",
			producerType: producer.producerType || "standard",
			languageOptions: producer.languageOptions || [],
		},
		onSubmit: (data) => {
			return updateDocument(producer.ref, data);
		},
	});

	const producerTypeOptions = useProducerTypeOptions();

	return (
		<AdminForm header={t("settings", "Settings")} {...form}>
			<Form.Group>
				{/*<Form.Input name="name" label={t("name", "Name")} disabled />*/}
				<Form.Select
					name="producerType"
					label={t("producerType", "Producer type")}
					options={producerTypeOptions}
				/>
				<Form.Select
					name="languageOptions"
					label={t("languageOptions")}
					options={languageOptions}
					multiple
					search
				/>
			</Form.Group>
		</AdminForm>
	);
}
