import AdminTitleDataForm from "./components/AdminTitleDataForm";
import AdminTitleMetaDataForm from "./components/AdminTitleMetaDataForm";

export default function AdminTitleSettingsView({ title }) {
	return (
		<>
			<AdminTitleDataForm title={title} />
			<AdminTitleMetaDataForm title={title} />
		</>
	);
}
