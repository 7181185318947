import { useMemo } from "react";

import mapDocument from "../utils/mapDocument";

import useDocument from "./useDocument";

export default function useDocumentData(query) {
	const [snapshot, loading, error] = useDocument(query);

	const value = useMemo(() => snapshot?.exists && mapDocument(snapshot), [snapshot]);

	return [value, loading, error];
}
