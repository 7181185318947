import { Label } from "semantic-ui-react";

import pipelineStatuses from "astrid-firestore/src/api/pipelines/constants/pipelineStatuses";

import pipelineStatusColors from "../../utils/pipelineStatusColors";

import PipelineStatus from "./PipelineStatus";

export default function PipelineStatusLabel({ step, progress, ...props }) {
	const status = step.disabled ? pipelineStatuses.DISABLED : progress?.status || pipelineStatuses.PENDING;

	return (
		<Label color={pipelineStatusColors[status]} {...props}>
			<PipelineStatus status={status} />
		</Label>
	);
}
