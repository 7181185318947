import { useTranslation } from "react-i18next";

import formatByLocale from "../../../utils/formatByLocale";

export default function useFullCalendarProps(setOpenFilterMenu) {
	const { i18n } = useTranslation();

	const customButton = {
		filterButton: {
			text: "filters",
			click: function () {
				setOpenFilterMenu((openFilterMenu) => !openFilterMenu);
			},
		},
	};

	const slotTimeFormat = {
		hour: i18n.language === "sv" ? "2-digit" : "numeric",
		minute: "2-digit",
		omitZeroMinute: i18n.language !== "sv",
		meridiem: "short",
	};

	const views = {
		resourceTimelineDay: {
			slotLabelFormat: ["dddd", slotTimeFormat],
			slotDuration: "00:15:00",
			scrollTime: "07:00",
		},
		resourceTimelineWeek: {
			slotLabelFormat: [i18n.language === "sv" ? "ddd D/M" : "ddd M/D", slotTimeFormat],
			slotDuration: "00:30:00",
			scrollTime: "00:00",
			titleFormat: formatByLocale({
				sv: ({ start, end }) => start.format("DD MMMM") + " - " + end.format("DD MMMM (V. WW)"),
				en: ({ start, end }) => start.format("DD MMMM") + " - " + end.format("DD MMMM ([Week] WW)"),
			}),
		},
		resourceTimelineMonth: {
			eventMaxStack: 0,
			slotLabelFormat: [
				"MMMM",
				{
					day: "2-digit",
				},
			],
		},
	};

	return {
		customButton,
		slotTimeFormat,
		views,
	};
}
