import AdminPageView from "../../components/AdminPage/AdminPageView";

import AdminDistributionHeader from "./components/AdminDistributionHeader";

export default function AdminDistributionView(props) {
	return (
		<AdminPageView {...props}>
			<AdminDistributionHeader />
		</AdminPageView>
	);
}
