import moment from "moment";
import { Header } from "semantic-ui-react";

import useHasRight from "astrid-web/src/features/authorization/hooks/useHasRight";
import TodoDropdownMenu from "astrid-web/src/features/todos/components/Todos/components/TodoDropdownMenu";

import Flex from "../../../../../components/Flex/Flex";

import useTodoHeaderTranslations from "../../../hooks/useTodoHeaderTranslations";

import TodoIcon from "./TodoIcon";

export default function TodoHeader({ todo }) {
	const hasRight = useHasRight();

	const { header, type, createdAt } = todo;

	const todoHeaders = useTodoHeaderTranslations();

	return (
		<Flex>
			<Header style={{ margin: 0, fontSize: 16 }}>
				{header ? header : todoHeaders[type]}

				{createdAt && (
					<span style={{ color: "grey", fontSize: 13, marginLeft: 5, fontWeight: 400 }}>
						{moment(createdAt?.toDate()).fromNow()}
					</span>
				)}
			</Header>

			<Flex style={{ flex: 0, gap: 5 }}>
				<TodoIcon todo={todo} />

				{hasRight("todos.delete") && !todo.resolved.status && <TodoDropdownMenu todo={todo} />}
			</Flex>
		</Flex>
	);
}
