import equal from "fast-deep-equal";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { Button, Card } from "semantic-ui-react";

import distributorConfig from "astrid-config/src/distributors";
import updateDocument from "astrid-firestore/src/helpers/updateDocument";

import Table from "../../../../ui/components/Table/Table";

import useDistributorsColumns from "../hooks/useDistributorsColumns";

function Footer({ producer, state }) {
	const { t } = useTranslation();

	const distributors = useMemo(() => Object.keys(state.selectedRowIds), [state.selectedRowIds]);

	const disabled = useMemo(() => equal(distributors, producer.distributors), [producer.distributors, distributors]);

	const [{ loading }, onClickSave] = useAsyncFn(() => updateDocument(producer.ref, { distributors }), [distributors]);

	return (
		<Button.Group>
			<Button primary loading={loading} disabled={disabled} onClick={onClickSave}>
				{t("save")}
			</Button>
		</Button.Group>
	);
}

export default function ProducerDistributors({ producer }) {
	const { t } = useTranslation();

	const distributors = useMemo(
		() =>
			Object.keys(distributorConfig).map((id) => ({
				id,
				name: distributorConfig[id].name,
			})),
		[],
	);

	const distributorsColumns = useDistributorsColumns();

	const selectedRowIds = useMemo(
		() => producer.distributors?.reduce((map, id) => ({ ...map, [id]: true }), {}) || {},
		[producer.distributors],
	);

	if (distributors.length === 0) {
		return false;
	}

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("distributorAccess", "Distributor access")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Table
					sortable
					selectable
					filterable
					pagination
					data={distributors}
					columns={distributorsColumns}
					initialState={{ pageSize: 5, selectedRowIds }}
					footer={<Footer producer={producer} />}
					sortBy={{
						id: "name",
						desc: false,
					}}
				/>
			</Card.Content>
		</Card>
	);
}
