import debounce from "lodash/debounce";
import React, { useRef, useState } from "react";

import clamp from "astrid-helpers/src/clamp";
import map from "astrid-helpers/src/map";

import styles from "./styles.module.css";

export default function Timeline({
	size,
	theme,
	clips,
	length,
	offset,
	children,
	onChangeSize,
	onChangeOffset,
	...rest
}) {
	const ref = useRef();
	const [state, setState] = useState("idle");
	const [mouseOffset, setMouseOffset] = useState();

	const left = ref.current && ref.current.getBoundingClientRect().left;
	const width = ref.current && ref.current.offsetWidth;

	const onMouseDown = () => setState("moving");

	const onChangeOffsetDebounced = debounce(onChangeOffset, 10);
	const onChangeSizeDebounced = debounce(onChangeSize, 10);

	const onMouseMove = ({ pageX }) => {
		const x = map(pageX - left, 0, width, 0, length);
		const newOffset = clamp(x - size / 2, 0, length - size);

		setMouseOffset(newOffset);

		if (state === "moving") {
			onChangeOffsetDebounced(newOffset);
		}

		if (state === "trim start") {
			const trim = clamp(x, 0, offset + size - 1000 * 60);

			onChangeOffsetDebounced(trim);
			onChangeSizeDebounced(size + offset - trim);
		}

		if (state === "trim end") {
			const trim = clamp(x, offset + 1000 * 60, length);

			onChangeSizeDebounced(trim - offset);
		}
	};

	const onMouseUp = () => {
		if (state === "moving") {
			onChangeOffset(mouseOffset);
		}

		setState("idle");
	};

	const onMouseLeave = () => {
		setState("idle");
		setMouseOffset();
	};

	const onTrimStart = (event) => {
		event.stopPropagation();
		setState("trim start");
	};

	const onTrimEnd = (event) => {
		event.stopPropagation();
		setState("trim end");
	};

	return (
		<div
			ref={ref}
			className={`${styles.root} ${theme && styles[theme]}`}
			onMouseUp={onMouseUp}
			onMouseDown={onMouseDown}
			onMouseMove={onMouseMove}
			onMouseLeave={onMouseLeave}
			{...rest}
		>
			<div
				className={styles.offset}
				style={{
					left: `${(offset / length) * 100}%`,
					width: `${(size / length) * 100}%`,
				}}
			>
				<div className={styles.trim} onMouseDown={onTrimStart} />
				<div className={styles.trim} onMouseDown={onTrimEnd} />
			</div>
			{clips.map((clip) => (
				<div
					key={clip.id}
					className={styles.clip}
					style={{
						left: `${(clip.position / length) * 100}%`,
						width: `${((clip.end - clip.start) / length) * 100}%`,
					}}
				/>
			))}
			{children}
		</div>
	);
}
