import { Button, Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import notifySessions from "astrid-firestore/src/api/sessions/notifications/notifySessions";

import Form from "../../../forms/components/Form/Form";
import useForm from "../../../forms/hooks/useForm";

export default function NotificationModal({ modifiedSessions, onClose, onStopBooking }) {
	const { t } = useTranslation();

	const sessions = Object.values(modifiedSessions);

	const usersToNotify = sessions
		.map(({ before, after }) => {
			if (before) {
				return [
					before.recorderData,
					after.recorderData,
					...Object.values(before.readerData),
					...Object.values(after.readerData),
				];
			}

			return [after.recorderData, ...Object.values(after.readerData)];
		})
		.flat()
		.filter((user, i, arr) => arr.findIndex((u) => u.id === user.id) === i);

	const form = useForm({
		defaultValues: {
			userIds: {
				...usersToNotify.reduce(
					(map, user) => ({
						...map,
						[user.id]: true,
					}),
					{},
				),
			},
		},
	});

	const { handleSubmit, formState, watch } = form;

	const { userIds } = watch();

	const userIdsToNotify = Object.entries(userIds)
		.filter(([, shouldNotify]) => shouldNotify)
		.map(([id]) => id);

	const onSubmit = async () => {
		if (userIdsToNotify.length > 0) {
			await notifySessions({ modifiedSessions, userIdsToNotify, t });
		}

		onClose();
		onStopBooking();
	};

	return (
		<Modal size="small" open closeOnDimmerClick onClose={onClose}>
			<Modal.Header>{t("notifyCoworker", "Notify resources")}</Modal.Header>

			<Modal.Content>
				<p>
					{usersToNotify.length === 1
						? t(
								"notifyOneCoworkerMessage",
								"{{staffAffected}} user is affected by the updates made in the calendar. Do you want to notify them?",
								{ staffAffected: usersToNotify.length },
						  )
						: t(
								"notifyCoworkersMessage",
								"These {{staffAffected}} users are affected by the updates made in the calendar. Choose who to notify.",
								{ staffAffected: usersToNotify.length },
						  )}
				</p>
				<Form form={form}>
					{usersToNotify?.map(({ id, name }) => (
						<Form.Checkbox key={id} label={name} name={`userIds[${id}]`} rules={{ required: false }} />
					))}
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					disabled={formState.isSubmitting}
					loading={formState.isSubmitting}
					onClick={handleSubmit(onSubmit)}
					primary={userIdsToNotify.length > 0}
				>
					{userIdsToNotify.length > 0 ? t("notify", "Notify") : t("skip", "Skip")}
				</Button>
			</Modal.Actions>
		</Modal>
	);
}
