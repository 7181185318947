import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import PendingAgreementsView from "../views/PendingAgreementsView/PendingAgreementsView";

export default function AdminPendingAgreements() {
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("agreements.view")) {
		return <Unauthorized />;
	}

	return <PendingAgreementsView firstPartyId={organization.id} />;
}
