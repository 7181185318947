import { useMemo } from "react";

import db from "astrid-firebase/src/db";
import createQuery from "astrid-firestore/src/helpers/createQuery";

export default function usePricesQuery({ type, deleted = false, language, employee, producerId } = {}) {
	return useMemo(() => {
		let query = createQuery(db.collection("prices"), {
			type,
			deleted,
			employee,
			"producer.id": producerId,
		});

		if (language) {
			query = query.where("languages", "array-contains", language);
		}

		return query;
	}, [type, deleted, employee, producerId, language]);
}
