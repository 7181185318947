import { useRef, useState } from "react";

export default function useFileInput({ multiple = false }) {
	const ref = useRef();

	const [files, setFiles] = useState([]);

	const input = (
		<input
			style={{ display: "none" }}
			ref={ref}
			type="file"
			multiple={multiple}
			onChange={(e) => {
				setFiles([...e.target.files]);
			}}
		/>
	);

	const onClick = () => {
		ref.current.click();
	};

	return { files, input, onClick };
}
