import { Icon, Input, Label } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentRefinements, useSearchBox } from "react-instantsearch";

import Flex from "../../../../../components/Flex/Flex";
import Form from "../../../../forms/components/Form/Form";

import ClearQueryButton from "./ClearQueryButton";
import ReaderFilterButton from "./ReaderFilterButton";

export default function ReaderSearchBar() {
	const { t } = useTranslation();
	const { items } = useCurrentRefinements();
	const { query, refine, clear } = useSearchBox();

	const hasRefinements = items.length > 0;

	return (
		<div className="ui field">
			<Flex style={{ justifyContent: "stretch", alignItems: "center", gap: 15 }}>
				<Input
					fluid
					value={query}
					icon
					iconPosition="left"
					size="large"
					labelPosition="right"
					placeholder={t("searchCreateNew")}
					onChange={(e, { value }) => refine(value)}
					style={{ flex: 1 }}
					action
				>
					<Icon name="search" />

					<input />

					<ClearQueryButton disabled={!query} onClick={clear} />

					<Form.Field name="filtersOpen" rules={{ required: false }}>
						<ReaderFilterButton active={hasRefinements} />
					</Form.Field>
				</Input>

				{hasRefinements && (
					<Label floating circular size="small" color="blue">
						{items.reduce((total, item) => total + item.refinements.length, 0)}
					</Label>
				)}
			</Flex>
		</div>
	);
}
