import debounce from "lodash/debounce";
import deburr from "lodash/deburr";
import get from "lodash/get";
import intersection from "lodash/intersection";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Form, Header, Icon, Label, Modal, Popup, Progress, Table } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { base, db, firebase, getProductionPermissions, notifyUsers } from "astrid-firebase";
import copyTextToClipboard from "astrid-helpers/src/copyTextToClipboard";
import loginToken from "astrid-helpers/src/loginToken";
import CopyInput from "astrid-web/src/components/CopyInput";
import Pagination from "astrid-web/src/components/Pagination";
import RecordButton from "astrid-web/src/components/RecordButton";
import SettingsPopup from "astrid-web/src/components/SettingsPopup";
import StickyAudio from "astrid-web/src/components/StickyAudio";
import UploadButton from "astrid-web/src/components/UploadButton";
import FetchScript from "astrid-web/src/components/production/parts/FetchScript";
import { MetaPopup } from "astrid-web/src/components/production/parts/MetaGuide";
import { withStore } from "astrid-web/src/helpers/context";
import { toDate } from "astrid-web/src/helpers/fnc";

import CopyWordsToOtherProductionModal from "../../../features/productions/components/ProductionWords/CopyWordsToOtherProductionModal";
import DateTime from "../../../features/ui/components/DateInput/DateTime";

import FlexRow from "../../Flex/FlexRow";

const analyzeLanguages = ["sv", "no", "fi", "en", "de", "es", "fr", "bg", "it", "nl", "da"];
const analyzeScript = firebase.functions().httpsCallable("scripts-analyze");

class ProductionWords extends Component {
	state = {
		loading: true,
		words: [],
		wordsIgnore: {},
		newWord: "",
		newLanguage: "",
		newContext: "",
		wordSearch: "",
		wordPage: 0,
		addNew: true,

		modal: false,
		selectedWord: null,
		inviteEmail: "",
	};

	storageRef = firebase
		.storage()
		.refFromURL(window.ES.stage ? "gs://stage-earselect-public" : "gs://earselect-public");

	UNSAFE_componentWillMount() {
		// chosen words
		base.bindCollection("words", {
			context: this,
			state: "words",
			withIds: true,
			query: (ref) => ref.where("productions." + this.props.productionId + ".index", "==", true),
			then() {
				this.setState({ loading: false });
				this.setReaderAndTranslatorNames(this.props.production);
			},
			onFailure(err) {
				console.log("Words get err", err);
			},
		});

		// ignored words
		// TODO: only load when script has been analyzed
		base.bindDoc("wordsIgnore/" + this.props.production.language, {
			context: this,
			state: "wordsIgnore",
			then() {},
			onFailure(err) {
				console.error("ignore words", err);
			},
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			get(nextProps, "production.authorOptions.length") !== get(this.props, "production.authorOptions.length") ||
			get(nextProps, "production.translatorOptions.length") !==
				get(this.props, "production.translatorOptions.length")
		) {
			this.setReaderAndTranslatorNames(nextProps.production);
		}
	}

	setReaderAndTranslatorNames = (production) => {
		// add missing people (author and translator)
		const people = [...(production.authorOptions || []), ...(production.translatorOptions || [])];

		people.forEach((person) => {
			if (!this.state.words.find((word) => word.word === person.text)) {
				this.addWord({ name: person.text, language: person.language });
			}
		});
	};

	copyAnonymousLink = () => {
		let studioDomain = window.ES.stage ? "stage." : this.props.production.studioVersion === "beta" ? "beta." : "";

		const url = `https://${studioDomain}studio.astrid.fm?token=anonymous&production=${this.props.productionId}`;
		copyTextToClipboard(url);
		window.alert(this.props.t("linkCopied"));
	};

	uploadScript = (e, data) => {
		const storageRef = firebase
			.storage()
			.refFromURL(window.ES.stage ? "gs://stage-earselect-secret" : "gs://earselect-secret");

		// file from input
		const file = e.target.files[0];
		const { t } = this.props;

		if (!file) return;

		const suffix = file.name.split(".").pop();

		if (suffix.toLowerCase() !== "pdf") {
			this.setState({ uploadScript: t("notAPdf") });
			return;
		}

		// start upload
		const uploadTask = storageRef
			.child("scripts/" + this.props.productionId + "/" + this.props.production.title + "." + suffix)
			.put(file);

		// upload status events
		uploadTask.on(
			firebase.storage.TaskEvent.STATE_CHANGED,
			(snapshot) => {
				// progress
				const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				this.setState({ uploadScript: progress + "%" });
			},
			(error) => {
				// error
				console.log(error);
			},
			() => {
				// success, store in db
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					this.props.handleChange(null, [
						{ name: "script", value: downloadURL },
						{ name: "scriptUploadDate", value: firebase.firestore.FieldValue.serverTimestamp() },
					]);

					this.queueAnalysis();

					this.setState({ uploadScript: null });

					// notify team?
					const production = this.props.production;

					if (production.status !== "draft") {
						const team = [
							...new Set([
								...(production.bookedRecorders || []),
								...(production.manager || []),
								...((production.reader &&
									production.reader.length &&
									production.reader.filter(
										(rid) => get(production, "readerStatus." + rid + ".status") === "accepted",
									)) ||
									[]),
								...(production.proofer || []),
								...(production.editor || []),
							]),
						];

						const productionLink = `https://${window.ES.stage ? "stage." : ""}astrid.fm${
							window.location.pathname
						}`;
						const message = t("uploadScriptMessage", { link: productionLink });

						notifyUsers(
							team,
							this.props.store.state.users,
							this.props.production.title + t("scriptUploaded"),
							message,
						);
					}
				});
			},
		);
	};

	addWord = (person) => {
		const productionPermissions = getProductionPermissions(this.props.store, this.props.production);
		const { isProducer, isReader, isPublisher } = productionPermissions;

		const wordData = {
			index: true, // for firestore indexability
			author: this.props.production.author || [],
			productionLanguage: this.props.production.language || person.language,
			wordLanguage: this.state.newLanguage,
			context: this.state.newContext,
			source: isProducer ? "producer" : isReader ? "reader" : isPublisher ? "publisher" : null,
		};

		// if the "word" is an author or translators name, override word data
		const newWord = person.name || this.state.wordSearch;
		if (person.name) {
			wordData.person = true;
			wordData.wordLanguage = person.language || this.props.production.language;
			wordData.author = [];
		}

		if (wordData.wordLanguage)
			base.get("words", {
				context: this,
				withIds: true,
				query: (ref) => ref.where("word", "==", newWord),
			})
				.then((data) => {
					// already exists
					const word = data[0];

					word.productions[this.props.productionId] = wordData;

					base.updateDoc("words/" + word.id, {
						productions: word.productions,
					})
						.then(() => {
							this.setState({ newContext: "", addWord: false });
						})
						.catch((error) => {
							console.error("Word update", error);
						});
				})
				.catch((err) => {
					// create new
					base.addToCollection("words", {
						word: newWord,
						productions: {
							[this.props.productionId]: wordData,
						},
					})
						.then(() => {
							this.setState({ newContext: "", addWord: false });
						})
						.catch((error) => {
							console.log("Word add error", error);
						});
				});
	};

	addGlobalIgnore = (word) => {
		const words = this.state.wordsIgnore.words ? [...this.state.wordsIgnore.words] : [];
		words.push(word.toLocaleLowerCase());

		db.doc("wordsIgnore/" + this.props.production.language)
			.set({
				words,
			})
			.then(() => {})
			.catch((error) => {
				console.error("Ignore words update", error);
			});
	};

	// store algoritmically chosen contribution
	unchosenContribution = {};

	setChoice = (word, id) => {
		const choice = word.choice || {};
		if (choice[this.props.productionId] === id) {
			delete choice[this.props.productionId];
		} else {
			choice[this.props.productionId] = id;
		}

		base.updateDoc("words/" + word.id, {
			choice,
		});
	};

	deleteWord = (word) => {
		word.productions[this.props.productionId].index = false;

		base.updateDoc("words/" + word.id, {
			productions: word.productions,
		});
	};

	deleteContribution = (cont) => {
		const { t } = this.props;
		if (!window.confirm(t("sureAboutDeleteProductionWord"))) return;

		const word = this.state.words.find((word) => word.id === this.state.selectedWord);
		const sample = word.contributions[cont].filePath;

		const promiseDelete = new Promise((resolve, reject) => {
			if (sample && sample.includes("wordSamples")) {
				this.storageRef
					.child(sample)
					.delete()
					.then(function () {
						delete word.contributions[cont];
						resolve(word.contributions);
					})
					.catch(function (err) {
						reject(err);
					});
			} else {
				delete word.contributions[cont];
				resolve(word.contributions);
			}
		});

		promiseDelete
			.then((newContributions) => {
				base.updateDoc("words/" + word.id, {
					contributions: newContributions,
				})
					.then(() => {})
					.catch((error) => {
						console.error("Word delete", error);
					});
			})
			.catch((err) => {
				console.error("Sample delete", err.message);
			});
	};

	queueAnalysis = async () => {
		if (analyzeLanguages.includes(this.props.production.language)) {
			this.setState({ analyzingScript: true });
			await analyzeScript({ productionId: this.props.productionId });
			this.setState({ analyzingScript: false });
		}
	};

	modalClose = () => {
		if (this.state.preventModalClose) {
			this.setState({ preventModalClose: false });
		} else {
			this.setState({ modal: null, selectedWord: null, editActualWord: null });
		}
	};

	onChangePages = debounce((e, data) => {
		console.log("hej");
		this.props.handleChange(null, data);
	}, 500);

	render() {
		const { t, production, productionId, store, uid, handleChange, isReader, isProducer } = this.props;
		const {
			words,
			newLanguage,
			newContext,
			wordSearch,
			includeSamples,
			addNew,
			playingFile,
			wordPage,
			loadingStudio,
			showAllTopWords,
			analyzingScript,
		} = this.state;

		const productionPeople = [...(production.authorOptions || []), ...(production.translatorOptions || [])];
		const modalWord = this.state.selectedWord && words.find((word) => word.id === this.state.selectedWord);
		const activeWords = words.filter(
			(word) =>
				(!word.productions[productionId].person ||
					productionPeople.find((person) => word.word === person.text)) &&
				(!wordSearch || deburr(word.word.toLocaleLowerCase()).includes(deburr(wordSearch.toLocaleLowerCase()))),
		);
		const activeWordsWithSample = activeWords.length
			? activeWords.filter(
					(w) => (w.choice && w.contributions?.[w.choice]) || Object.keys(w.contributions || {}).length,
			  ).length
			: 0;

		const sortedTopWords =
			production.scriptAnalysis?.top &&
			Object.keys(production.scriptAnalysis.top)
				.filter(
					(word) =>
						(!production.scriptAnalysis.ignore || !production.scriptAnalysis.ignore.includes(word)) &&
						(!this.state.wordsIgnore.words ||
							!this.state.wordsIgnore.words.includes(word.toLocaleLowerCase())) &&
						!this.state.words.find((words) => words.word === word),
				)
				.sort((a, b) => {
					// sort by occurances
					if (production.scriptAnalysis.top[a] < production.scriptAnalysis.top[b]) return 1;
					if (production.scriptAnalysis.top[a] > production.scriptAnalysis.top[b]) return -1;

					// sort alphabetically
					if (a > b) return 1;
					if (a < b) return -1;

					// fallback
					return 0;
				});

		const productionPermissions = getProductionPermissions(store, production);
		const { isPublisher, rights: productionRights } = productionPermissions;

		// hide script due to NDA?
		const hideScript =
			production.scriptNDA &&
			!isPublisher &&
			!(
				productionRights.includes("productionScript.upload") ||
				production?.reader?.includes(uid) ||
				production?.editor?.includes(uid) ||
				production?.bookedRecorders?.includes(uid) ||
				production?.proofer?.includes(uid)
			);

		let producerPermissions = get(this.props, "profile.permissions.producer." + production.producer) || [];
		const isAdmin = producerPermissions.includes("producerAdmin");
		const isProducerStaff = producerPermissions.includes("producerStaff");
		const isPublisherAdmin = isPublisher?.includes?.("publisherAdmin");
		const isPublisherStaff = isPublisher?.includes?.("publisherStaff");
		const isSubcontractor =
			producerPermissions.includes("producerSubcontractor") && production?.subcontractor === uid;

		const perPage = 15;

		return (
			<>
				<div className="flex-stack half production-script" style={{ margin: "30px 0 0" }}>
					<div>
						{productionRights.includes("createProduction") &&
							(production.script || production.scriptDeleted) && (
								<SettingsPopup>
									{!isPublisher && productionRights.includes("createProduction") && (
										<Popup
											inverted
											position="bottom left"
											size="tiny"
											content={t("onlyChoosenWorkGroup")}
											trigger={
												<Form.Checkbox
													label={t("limitedAccess")}
													className="production-staff-prio"
													style={{ display: "inline-block", marginBottom: "1em" }}
													checked={!!production.scriptNDA}
													onChange={(e, data) => {
														handleChange(null, {
															name: "scriptNDA",
															value: data.checked,
														});
													}}
												/>
											}
										/>
									)}

									{production.script &&
										productionRights.includes("productionScript.upload") &&
										(production.status === "accepted" ||
											production.status === "offer" ||
											isAdmin ||
											isProducerStaff) && (
											<>
												<Button
													color="red"
													icon="delete"
													content={t("deleteScript")}
													size="small"
													onClick={(e) => {
														if (window.confirm(t("sureAboutDeleteScript"))) {
															handleChange(null, [
																{ name: "script", value: null },
																{ name: "scriptAnalysis", value: null },
																{ name: "scriptDeleted", value: production.script },
															]);
															// close modal by clicking outside…
															document.body.click();
														}
													}}
												/>
											</>
										)}

									{!production.script &&
										production.scriptDeleted &&
										productionRights.includes("productionScript.upload") &&
										!isPublisher && (
											<Button
												color="green"
												icon="recycle"
												content={t("recoverScript")}
												size="small"
												onClick={(e) => {
													handleChange(null, [
														{ name: "script", value: production.scriptDeleted },
														{ name: "scriptAnalysis", value: "queued" },
													]);
												}}
											/>
										)}
								</SettingsPopup>
							)}
						<MetaPopup id="script" text={t("haveToUploadScript")}>
							<Header as="h4" icon="file alternate outline" content={t("script")} />
						</MetaPopup>

						<div className={"field" + (!(production.scriptDate || production.script) ? " error" : "")}>
							{hideScript ? (
								<em>{t("limitedAccessToScript")}</em>
							) : (
								<>
									{productionRights.includes("productionScript.upload") && !production.script && (
										<div style={{ display: "flex" }}>
											{production.language ? (
												<>
													<UploadButton text={t("uploadNow")} onUpload={this.uploadScript} />

													<DateTime
														value={
															production.scriptDate && production.scriptDate.toDate
																? moment(production.scriptDate.toDate()).format(
																		"YYYY-MM-DD",
																  )
																: ""
														}
														inputProps={{
															disabled: !productionRights.includes("createProduction"),
														}}
														isValidDate={(current) => current.isAfter(new Date())}
														timeFormat={false}
														onChange={(dt) => {
															if (typeof dt === "object") {
																// valid date, save it
																handleChange(null, {
																	name: "scriptDate",
																	type: "date",
																	value: dt.format("YYYY-MM-DD"),
																});
															}
														}}
														onBlur={(dt) => {
															const writtenDate = moment(dt).format("YYYY-MM-DD");
															// see if typed date is valid and not the same as currently is in the db
															if (
																writtenDate !== "Invalid date" &&
																writtenDate !==
																	moment(toDate(production.scriptDate)).format(
																		"YYYY-MM-DD",
																	)
															) {
																// valid date, save it
																handleChange(null, {
																	name: "scriptDate",
																	type: "date",
																	value: writtenDate,
																});
															} else {
																console.log("Invalid date", dt);
															}
														}}
														renderInput={(props, openCalendar, closeCalendar) => (
															<Button
																content={
																	production.scriptDate
																		? moment(toDate(production.scriptDate)).format(
																				"YYYY-MM-DD",
																		  )
																		: t("uploadLater")
																}
																basic
																icon="calendar alternate"
																labelPosition="right"
																onClick={openCalendar}
															/>
														)}
													/>
												</>
											) : (
												<div
													className="field"
													style={{ display: "inline-block", marginRight: 10 }}
												>
													<em>{t("specifyProdLanguage")}</em>
												</div>
											)}
										</div>
									)}

									{this.state.uploadScript && <em>{this.state.uploadScript}</em>}
									{production.script && (
										<Popup
											inverted
											size="mini"
											content={
												t("scriptUploaded") +
												" " +
												moment(toDate(production.scriptUploadDate)).format("YYYY-MM-DD HH:mm")
											}
											trigger={
												<FetchScript
													productionId={this.props.productionId}
													production={production}
												/>
											}
										/>
									)}

									{(production?.render?.version || production?.renderedVersion) >= "1.2.0" &&
										isReader && (
											<Button
												primary
												loading={loadingStudio}
												icon="paint brush"
												content={t("fineScript")}
												style={{ borderLeft: "1px solid #7ea7c7" }}
												onClick={(e) => {
													this.setState({ loadingStudio: true });

													let studioDomain = window.ES.stage ? "stage." : "";

													loginToken().then((token) => {
														const url = `https://${studioDomain}studio.astrid.fm?token=${token}&production=${productionId}&role=reader`;

														const open = window.open(url);

														// ios can't open "popups"
														if (open == null || typeof open == "undefined")
															window.location = url;

														this.setState({
															loadingStudio: false,
														});
													});
												}}
											/>
										)}

									{!production.script &&
										!productionRights.includes("productionScript.upload") &&
										(production.scriptDate ? (
											production.scriptDate.toDate ? (
												t("expects") +
												moment(production.scriptDate.toDate()).format("YYYY-MM-DD")
											) : (
												""
											)
										) : (
											<em>{t("dateNotSpecified")}</em>
										))}
								</>
							)}
						</div>
						<MetaPopup id={"pages"} text={t("specifyNrOfPages")}>
							<Form.Input
								label={t("effectivePages")}
								key={"pages"}
								name={"pages"}
								type={"number"}
								width={6}
								error={
									!production.pages ||
									(production.scriptAnalysis?.pages &&
										Math.abs(production.pages - production.scriptAnalysis.pages) >= 15)
								}
								defaultValue={production.pages || ""}
								onChange={this.onChangePages}
								disabled={isReader}
							/>
						</MetaPopup>

						{production.scriptAnalysis &&
							production.scriptAnalysis !== "queued" &&
							production.scriptAnalysis !== "started" && (
								<div>
									{production.scriptAnalysis.fail ? (
										<em>
											{t("scriptAnalysisFail")} {production.scriptAnalysis.fail}
										</em>
									) : (
										<div>
											<p style={{ fontSize: ".92857143em" }}>
												<strong>{t("pages")}:</strong> {production.scriptAnalysis.pages}{" "}
												<strong>{t("words")}</strong> {production.scriptAnalysis.wordsTotal} (
												{production.scriptAnalysis.wordsUnique} {t("unique")})
											</p>
										</div>
									)}
									<div style={{ lineHeight: "2.2", marginBottom: "1em", marginTop: ".5em" }}>
										{sortedTopWords?.slice(0, showAllTopWords ? 10000 : 30).map((word) => (
											<Label
												key={word}
												as="a"
												onClick={(e) => {
													this.setState({ wordSearch: word, addWord: true });
												}}
											>
												{word}
												&nbsp;
												<small style={{ color: "#2185d0" }}>
													{production.scriptAnalysis.top[word]}
												</small>
												{isProducer && (
													<Icon
														name="delete"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();

															const ignore = production.scriptAnalysis.ignore || [];
															ignore.push(word);
															production.scriptAnalysis.ignore = ignore;

															base.updateDoc("productions/" + productionId, {
																scriptAnalysis: production.scriptAnalysis,
															})
																.then(() => {})
																.catch((error) => {
																	console.error("Word ignore", error);
																});
														}}
													/>
												)}
											</Label>
										))}

										{/* ignored words */}

										{this.props.isProducer &&
											production.scriptAnalysis.top &&
											production.scriptAnalysis.ignore &&
											Object.keys(production.scriptAnalysis.top)
												.filter(
													(word) =>
														production.scriptAnalysis.ignore.includes(word) &&
														!this.state.wordsIgnore?.words?.includes(
															word.toLocaleLowerCase(),
														),
												)
												.sort((a, b) =>
													production.scriptAnalysis.top[a] < production.scriptAnalysis.top[b]
														? 1
														: -1,
												)
												.map((word) => (
													<Label
														style={{ background: "#f3f3f3" }}
														key={word}
														as="a"
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();

															const ignore = production.scriptAnalysis.ignore;

															const i = ignore.indexOf(word);
															ignore.splice(i, 1);

															production.scriptAnalysis.ignore = ignore;

															base.updateDoc("productions/" + productionId, {
																scriptAnalysis: production.scriptAnalysis,
															})
																.then(() => {})
																.catch((error) => {
																	console.error("Word ignore", error);
																});
														}}
													>
														<Icon name="plus" color="grey" />
														{word}
														<Popup
															size="mini"
															inverted
															content={t("ignoreWordGlobally")}
															trigger={
																<Icon
																	name="delete"
																	color="red"
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		this.addGlobalIgnore(word);
																	}}
																/>
															}
														/>
													</Label>
												))}

										{sortedTopWords?.length > 30 && !showAllTopWords && (
											<Label
												onClick={() => {
													this.setState({ showAllTopWords: true });
												}}
												className="pointer"
												color="teal"
											>
												{t("showWord", { nof: sortedTopWords?.length - 30 })}
											</Label>
										)}
									</div>
								</div>
							)}
						{(isAdmin || isProducerStaff || isSubcontractor) && (
							<>
								<Button
									primary
									content={!analyzingScript ? t("analyzeScript") : t("scriptAnalysisRuns")}
									disabled={
										!(production.script && analyzeLanguages.includes(production.language)) ||
										analyzingScript
									}
									onClick={this.queueAnalysis}
								/>
								<Button primary content={t("scriptAnonymousLink")} onClick={this.copyAnonymousLink} />
							</>
						)}

						{(production.scriptAnalysis === "queued" || production.scriptAnalysis === "started") && (
							<p>
								<em>{t("scriptAnalysisRuns")}</em>
							</p>
						)}
					</div>

					<div className="production-word-list">
						<Header as="h4" icon="assistive listening systems" content={t("prodWordList")} />
						<div
							style={{
								float: "right",
								width: "35%",
								marginTop: "-.4em",
							}}
						>
							<Form.Input
								size="mini"
								fluid
								placeholder={t("searchAdd")}
								id="wordSearch"
								disabled={!!this.state.addWord}
								value={wordSearch}
								onChange={(e, data) => {
									this.setState({ wordSearch: data.value, wordPage: 0 });
								}}
								action={{
									icon: "add",
									disabled: !wordSearch,
									color: wordSearch ? "blue" : "grey",
									size: "mini",
									onClick: () => {
										this.setState({ addWord: true });
									},
								}}
							/>
						</div>

						{this.state.addWord && (
							<Form size="small" as="div" className="clear">
								<h5>
									{t("addTheWord")} "{wordSearch}"
								</h5>
								<Form.Select
									search
									deburr
									label={t("languageOfWord")}
									value={newLanguage}
									error={!!wordSearch && !newLanguage}
									options={Object.keys(languages).map((code) => ({
										key: code,
										value: code,
										text:
											(languages[code].sv || languages[code].name) +
											" (" +
											languages[code].nativeName +
											")",
									}))}
									onChange={(e, data) => {
										this.setState({ newLanguage: data.value });
									}}
								/>
								<Form.TextArea
									value={newContext}
									label={t("contextOrComment")}
									placeholder={t("exampleOnUse")}
									onChange={(e, data) => {
										this.setState({ newContext: data.value });
									}}
								/>
								<Button
									content={t("cancel")}
									floated="left"
									onClick={() => {
										this.setState({ addWord: false, wordSearch: "" });
									}}
								/>
								<Button
									floated="right"
									icon="plus"
									content={t("addTheWord")}
									color="teal"
									onClick={this.addWord}
									disabled={!wordSearch || !newLanguage}
								/>
							</Form>
						)}

						<Table sortable compact basic="very" selectable style={{ clear: "both" }}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t("word")}</Table.HeaderCell>
									<Table.HeaderCell collapsing>{t("language")}</Table.HeaderCell>
									<Table.HeaderCell collapsing>{t("pronunciationExample")}</Table.HeaderCell>
									<Table.HeaderCell collapsing />
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{activeWords

									.sort((a, b) => (a.word > b.word ? 1 : -1))
									.slice(wordPage * perPage, wordPage * perPage + perPage)
									.map((word) => {
										const wordContext = word.productions[productionId].context;
										const wordSource = word.productions[productionId].source;

										const sourceIcon = {
											publisher: "book",
											reader: "microphone",
											producer: "headphones",
											external: "linkify",
										};

										const sourceContent = {
											publisher: t("addedByPublisher", "Added by publisher"),
											reader: t("addedByNarrator", "Added by narrator"),
											producer: t("addedByProducer", "Added by producer"),
											external: t("addedExternally", "Added externally"),
										};

										return (
											<Table.Row
												key={word.id}
												style={{ cursor: "pointer" }}
												onClick={(e) => {
													e.preventDefault();
													this.setState({
														modal: "word",
														selectedWord: word.id,
														playingFile: null,
													});
												}}
											>
												<Table.Cell>
													{word.word}{" "}
													{wordSource && (
														<Popup
															trigger={
																<Icon name={sourceIcon[wordSource]} color="grey" />
															}
															content={sourceContent[wordSource]}
															style={{ whiteSpace: "pre" }}
														/>
													)}
													{wordContext && (
														<Popup
															trigger={<Icon name="file text" color="grey" />}
															content={wordContext}
															style={{ whiteSpace: "pre" }}
														/>
													)}
													{get(production, "scriptAnalysis.top." + word.word) && (
														<Label
															size="tiny"
															content={get(production, "scriptAnalysis.top." + word.word)}
															style={{ float: "right", color: "#2185d0" }}
														/>
													)}
												</Table.Cell>
												<Table.Cell>
													{t("language:" + word.productions[productionId]?.wordLanguage) ||
														languages?.[word.productions[productionId]?.wordLanguage]?.sv ||
														languages?.[word.productions[productionId]?.wordLanguage]?.name}
												</Table.Cell>
												<Table.Cell style={{ whiteSpace: "nowrap" }}>
													{Object.keys(word.contributions || {}).length ? (
														Object.keys(word.contributions).find(
															(id) => word.contributions[id].file,
														) &&
														(() => {
															let selectedContribution;

															if (
																word.choice &&
																word.choice[productionId] &&
																word.contributions[word.choice[productionId]]
															) {
																// actively chosen sample
																selectedContribution =
																	word.contributions[word.choice[productionId]];
															}

															return selectedContribution ? (
																<>
																	{playingFile?.file !==
																	selectedContribution?.file ? (
																		<Button
																			basic
																			color={"teal"}
																			icon="play"
																			size="mini"
																			disabled={!!this.state.recordingWord}
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();

																				this.setState({
																					playingFile: selectedContribution,
																				});
																			}}
																		/>
																	) : (
																		<Button
																			basic
																			color="teal"
																			icon="square"
																			size="mini"
																			onClick={(e) => {
																				e.preventDefault();
																				e.stopPropagation();
																				this.setState({ playingFile: null });
																			}}
																		/>
																	)}
																	<Label basic className="sample-available">
																		<Icon name="check" />
																		{Object.keys(word.contributions).length}
																	</Label>
																</>
															) : (
																<Label basic className="sample-choose">
																	<Icon
																		name="question circle outline"
																		color="orange"
																	/>
																	{t("chooseSample")} (
																	{Object.keys(word.contributions).length})
																</Label>
															);
														})()
													) : (
														<Label basic className="sample-missing">
															<Icon
																name="close"
																color="red"
																style={{ margin: "0 .75em 0 0" }}
															/>{" "}
															{t("sampleMissing")}
														</Label>
													)}{" "}
													<RecordButton
														miniRec
														onRec={(e) => {
															e.stopPropagation();
															this.setState({ recordingWord: word.id });
														}}
														onStop={(e) => {
															e.stopPropagation();
															this.setState({ recordingWord: "uploading" });
														}}
														onChange={(file) => {
															const sampleId = +new Date();
															// audio file
															const filePath =
																"wordSamples/" +
																word.id +
																"/sample-" +
																sampleId +
																".mp3";

															// start upload
															const uploadTask = this.storageRef
																.child(filePath)
																.put(file);

															// upload status events
															uploadTask.on(
																firebase.storage.TaskEvent.STATE_CHANGED,
																(snapshot) => {
																	// progress
																	const progress = Math.round(
																		(snapshot.bytesTransferred /
																			snapshot.totalBytes) *
																			100,
																	);
																	console.log(progress + "% uploaded");
																},
																(error) => {
																	// error
																	console.log(error);
																},
																() => {
																	// success, store in db
																	uploadTask.snapshot.ref
																		.getDownloadURL()
																		.then((downloadURL) => {
																			const url = downloadURL;
																			const path = filePath;

																			const contributions =
																				word.contributions || {};

																			const data = {
																				email: this.props.profile.email,
																				time: firebase.firestore.FieldValue.serverTimestamp(),
																				production: productionId,
																			};
																			if (url) data.file = url;
																			if (path) data.filePath = path;
																			if (this.props.uid)
																				data.user = this.props.uid;

																			contributions[sampleId] = data;

																			const choice = word.choice || {};
																			choice[productionId] = "" + sampleId; // needs to be string, for some reason...

																			base.updateDoc("words/" + word.id, {
																				contributions,
																				choice,
																			})
																				.then(() => {
																					this.setState({
																						recordingWord: null,
																					});
																				})
																				.catch((error) => {
																					console.error("Word update", error);
																				});
																		});
																},
															);
														}}
														disabled={!!this.state.recordingWord || !!playingFile}
													/>
												</Table.Cell>
												<Table.Cell>
													{(isProducer || isPublisherAdmin || isPublisherStaff) && (
														<a
															href="/"
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																if (window.confirm(t("wantToDeleteFromWordList")))
																	this.deleteWord(word);
															}}
														>
															<Icon name="trash alternate" color="black" />
														</a>
													)}
												</Table.Cell>
											</Table.Row>
										);
									})}
							</Table.Body>
						</Table>

						<FlexRow style={{ justifyContent: "flex-end" }}>
							{!!activeWords?.length && (
								<Popup
									trigger={
										<Progress
											percent={(activeWordsWithSample / activeWords.length) * 100}
											size="small"
											color="olive"
											autoSuccess
											style={{ width: "38%" }}
										/>
									}
									inverted
									size="mini"
									content={t("activeWordPronunciation", {
										wordSample: activeWordsWithSample,
										wordLength: activeWords.length,
									})}
									//Innan interpolation:
									//content={activeWordsWithSample + " av " + activeWords.length + " ord har uttalsexempel"}
								/>
							)}
						</FlexRow>
						<FlexRow style={{ marginBottom: 10, gap: 10 }}>
							<Button
								content={t("invite")}
								icon="envelope"
								color="teal"
								size="mini"
								onClick={(e) => {
									this.setState({ modal: "invite" });
								}}
							/>
							{(isAdmin || isProducerStaff) && (
								<Button
									size="mini"
									content={t("copyPronunciationList", "Copy to other production")}
									icon="copy"
									onClick={() => this.setState({ modal: "copyToOtherProduction" })}
								/>
							)}
						</FlexRow>

						<Pagination
							page={wordPage}
							items={activeWords?.length}
							per={perPage}
							setPage={(page) => {
								this.setState({ wordPage: page });
							}}
							style={{ float: "right", marginLeft: 10 }}
						/>
					</div>
				</div>

				{this.state.modal && (
					<Modal
						size={this.state.modal === "word" ? null : "small"}
						dimmer={true}
						open={true}
						onClose={this.modalClose}
						closeOnDocumentClick
					>
						{this.state.modal === "word" && modalWord && (
							<>
								<Modal.Header>
									{!this.state.editActualWord && modalWord.word}{" "}
									{(isAdmin || isPublisherAdmin || isPublisherStaff) &&
										(!this.state.editActualWord ? (
											<Popup
												size="tiny"
												inverted
												position="bottom left"
												trigger={
													<Icon
														name="pencil"
														size="small"
														onClick={(e) => {
															this.setState({ editActualWord: modalWord.word });
														}}
													/>
												}
												content={t("importantWordPronMessage")}
											/>
										) : (
											<Form
												style={{ display: "inline-block" }}
												onSubmit={(e) => {
													const newData = {
														word: this.state.editActualWord,
													};

													// was the language changed too?
													if (this.state.editActualLanguage)
														newData["productions." + productionId + ".wordLanguage"] =
															this.state.editActualLanguage;

													base.updateDoc("words/" + modalWord.id, newData).then(() => {
														this.setState({
															editActualWord: null,
															editActualLanguage: null,
														});
													});
												}}
											>
												<Form.Group inline style={{ margin: 0 }}>
													<Form.Input
														value={this.state.editActualWord}
														onChange={(e, data) => {
															this.setState({ editActualWord: data.value });
														}}
														name="word-update"
														placeholder={t("newSpelling")}
													/>
													<Form.Dropdown
														selection
														search
														deburr
														name={"languageOriginal"}
														value={
															this.state.editActualLanguage ||
															modalWord.productions[productionId].wordLanguage ||
															""
														}
														options={Object.keys(languages).map((code) => ({
															key: code,
															value: code,
															text:
																(languages[code].sv || languages[code].name) +
																" (" +
																languages[code].nativeName +
																")",
														}))}
														onChange={(e, data) => {
															this.setState({ editActualLanguage: data.value });
														}}
													/>
													<Form.Button primary content={t("update")} />
												</Form.Group>
											</Form>
										))}
									<small style={{ fontWeight: "400", fontSize: "14px" }}>
										&nbsp; (
										{languages?.[modalWord.productions[productionId]?.wordLanguage]?.sv ||
											languages?.[modalWord.productions[productionId]?.wordLanguage]?.name}
										)
									</small>
									<span style={{ float: "right" }}>
										<Button
											as="a"
											href={`https://${
												production.language && production.language.toUpperCase() !== "XX"
													? `${production.language}.`
													: ""
											}forvo.com/search/${modalWord.word}/${
												modalWord.productions[productionId].wordLanguage
											}`}
											target="_blank"
											rel="noopener noreferrer"
											content={t("searchAtForvo")}
											icon="external"
											color="teal"
										/>
									</span>
									<Form>
										<Form.TextArea
											label={t("commentContext")}
											value={
												typeof this.state.modalWordContext === "string"
													? this.state.modalWordContext
													: modalWord?.productions?.[productionId]?.context
											}
											onChange={(e, { value }) => {
												this.setState({ modalWordContext: value });
											}}
										/>
										{typeof this.state.modalWordContext === "string" && (
											<Button
												primary
												content={t("saveComment")}
												onClick={() => {
													base.updateDoc("words/" + modalWord.id, {
														["productions." + productionId + ".context"]:
															this.state.modalWordContext,
													}).then(() => {
														this.setState({
															modalWordContext: null,
														});
													});
												}}
											/>
										)}
									</Form>
								</Modal.Header>
								<Modal.Content>
									{Object.keys(modalWord.contributions || {}).length !== 0 && (
										<Table>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell>{t("pronunciation")}</Table.HeaderCell>
													<Table.HeaderCell>{t("productionLanguage")}</Table.HeaderCell>
													<Table.HeaderCell>{t("pronLanguage")}</Table.HeaderCell>
													{isAdmin && <Table.HeaderCell>{t("user")}</Table.HeaderCell>}
													<Table.HeaderCell />
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{Object.keys(modalWord.contributions)
													.sort()
													.map((cont, i) => (
														<React.Fragment key={i}>
															<Table.Row>
																{modalWord.contributions[cont].file ? (
																	<Table.Cell
																		style={{
																			display: "flex",
																			alignItems: "center",
																		}}
																	>
																		<Icon
																			name="check circle outline"
																			style={{ cursor: "pointer" }}
																			size="large"
																			color={
																				modalWord.choice &&
																				modalWord.choice[productionId] === cont
																					? "green"
																					: "grey"
																			}
																			onClick={(e) => {
																				this.setChoice(modalWord, cont);
																			}}
																		/>
																		<audio
																			controls
																			src={modalWord.contributions[cont].file}
																		/>
																	</Table.Cell>
																) : (
																	// added an empty table cell in cases where there are no recording to fix table design
																	<Table.Cell></Table.Cell>
																)}
																{(() => {
																	const prod =
																		modalWord.productions[
																			modalWord.contributions[cont].production
																		];

																	const thisProd =
																		modalWord.productions[productionId];
																	return (
																		<>
																			<Table.Cell
																				style={{
																					fontWeight:
																						prod &&
																						thisProd &&
																						thisProd.productionLanguage ===
																							prod.productionLanguage
																							? "bold"
																							: "normal",
																				}}
																			>
																				{prod &&
																					thisProd &&
																					!!intersection(
																						thisProd.author,
																						prod.author,
																					).length && (
																						<Popup
																							content={t(
																								"fromSameAuthor",
																							)}
																							trigger={
																								<Icon name="keyboard" />
																							}
																						/>
																					)}
																				{prod &&
																					(languages[prod.productionLanguage]
																						.sv ||
																						languages[
																							prod.productionLanguage
																						].name)}
																			</Table.Cell>
																			<Table.Cell
																				style={{
																					fontWeight:
																						prod &&
																						thisProd &&
																						thisProd.wordLanguage ===
																							prod.wordLanguage
																							? "bold"
																							: "normal",
																				}}
																			>
																				{prod &&
																					(languages[prod.wordLanguage].sv ||
																						languages[prod.wordLanguage]
																							.name)}
																			</Table.Cell>
																		</>
																	);
																})()}
																{isAdmin && (
																	<Table.Cell>
																		<Popup
																			content={
																				modalWord.contributions[cont].email
																			}
																			trigger={
																				modalWord.contributions[cont].user ? (
																					<Link
																						to={
																							"/profile/" +
																							modalWord.contributions[
																								cont
																							].user
																						}
																					>
																						<Icon
																							name="user"
																							color="blue"
																						/>
																					</Link>
																				) : (
																					<a
																						href={
																							"mailto:" +
																							modalWord.contributions[
																								cont
																							].email
																						}
																					>
																						<Icon name="at" color="grey" />
																					</a>
																				)
																			}
																		/>
																	</Table.Cell>
																)}
																<Table.Cell>
																	{isAdmin ||
																	modalWord.contributions[cont].user ===
																		this.props.uid ? (
																		<Icon
																			name="trash alternate"
																			style={{ cursor: "pointer" }}
																			onClick={(e) => {
																				this.deleteContribution(cont);
																			}}
																		/>
																	) : (
																		<Icon
																			name="trash alternate"
																			color="grey"
																			style={{ cursor: "not-allowed" }}
																		/>
																	)}
																</Table.Cell>
															</Table.Row>
															{modalWord.contributions[cont].comment && (
																<Table.Row>
																	<Table.Cell
																		colspan="5"
																		style={{
																			borderTop: 0,
																			padding: "0 0 10px 10px",
																			fontWeight: "bold",
																		}}
																	>
																		{modalWord.contributions[cont].comment}
																	</Table.Cell>
																</Table.Row>
															)}
														</React.Fragment>
													))}
											</Table.Body>
										</Table>
									)}
								</Modal.Content>

								<Modal.Actions>
									<Button onClick={this.modalClose}>{t("close")}</Button>
								</Modal.Actions>
							</>
						)}

						{this.state.modal === "invite" && (
							<>
								<Modal.Header>{t("invite")}</Modal.Header>
								<Modal.Content>
									<Form>
										<p>{t("inviteHelper")}</p>
										<Form.Checkbox
											label={t("inclExistingSample")}
											checked={includeSamples}
											onClick={(e, data) => {
												this.setState({ includeSamples: !includeSamples });
											}}
										/>
										<Form.Checkbox
											label={t("admitAddingWords")}
											checked={addNew}
											disabled={!isAdmin && !isProducerStaff}
											onClick={(e, data) => {
												this.setState({ addNew: !addNew });
											}}
										/>
										<Form.Input
											type="email"
											label={t("emailAddress")}
											value={this.state.inviteEmail}
											onChange={(e, data) => {
												this.setState({ inviteEmail: data.value });
											}}
										/>
										<p>{t("clickBtnToSendEmail")}</p>
										<p>{t("copyAndSendAltWay")}</p>
										<CopyInput
											content={
												window.location.protocol +
												"//" +
												window.location.host +
												"/words/" +
												productionId +
												"/" +
												this.state.inviteEmail +
												"?" +
												(includeSamples ? "&s" : "") +
												(addNew ? "&a=" + production.language : "")
											}
										/>
									</Form>
								</Modal.Content>

								<Modal.Actions>
									<Button onClick={this.modalClose}>{t("close")}</Button>
									<Button
										positive
										icon="send"
										labelPosition="right"
										content={t("send")}
										disabled={!this.state.inviteEmail}
										onClick={() => {
											console.log("skicka mail");
											const url =
												window.location.protocol +
												"//" +
												window.location.host +
												"/words/" +
												productionId +
												"/" +
												this.state.inviteEmail +
												"?" +
												(includeSamples ? "&s" : "") +
												(addNew ? "&a=" + production.language : "");

											window.location =
												"mailto:" +
												this.state.inviteEmail +
												"?subject=" +
												t("pronHelp") +
												"&body=" +
												encodeURIComponent(t("clickLinkToHelpUs")) +
												url;
										}}
									/>
								</Modal.Actions>
							</>
						)}
						{this.state.modal === "copyToOtherProduction" && (
							<CopyWordsToOtherProductionModal
								fromProduction={{ id: productionId, ...production }}
								words={words}
								onClose={this.modalClose}
							/>
						)}
					</Modal>
				)}

				{playingFile && (
					<StickyAudio
						file={playingFile.file}
						start={playingFile.start}
						length={playingFile.length}
						onClose={() => {
							this.setState({ playingFile: null });
						}}
					/>
				)}
			</>
		);
	}
}

export default withTranslation(["common", "language"])(withStore(ProductionWords));
