import runBatch from "../../helpers/runBatch";

import agreementStatuses from "../agreements/constants/agreementStatuses";
import createCommentData from "../discussion/createCommentData";
import createReaderInquiryDraftTodoData from "../todos/data/offers/createReaderInquiryDraftTodoData";

import offerStatuses from "./constants/offerStatuses";
import offerTypes from "./constants/offerTypes";
import createOfferData from "./createOfferData";
import getCommentSender from "./utils/getCommentSender";
import getOffersToCancel from "./utils/getOffersToCancel";

export default async function createOffer(firebase, { agreement, ...data }) {
	const db = firebase.firestore();

	// Create the offer data
	const offer = createOfferData(firebase, { agreement, ...data });

	// Get all offers to cancel
	const offersToCancel = await getOffersToCancel(db, offer);

	await runBatch(db, (batch) => {
		// Create agreement if it doesn't exist
		if (agreement?.status === agreementStatuses.PENDING_APPROVAL) {
			batch.set(agreement.ref, agreement);
		}

		// Cancel other inquiries if this is a work order
		if (offer.type === offerTypes.WORK_ORDER && offersToCancel.length > 0) {
			for (const cancelOffer of offersToCancel) {
				batch.update(cancelOffer.ref, {
					status: offerStatuses.CANCELLED,
				});
			}
		}

		if (offer.optionalMessage && offer.status !== offerStatuses.DRAFT) {
			const comment = createCommentData(firebase, {
				text: offer.optionalMessage,
				user: getCommentSender(offer),
				channels: [`offers/${offer.id}`],
			});

			batch.set(comment.ref, comment);
		}

		if (offer.status === offerStatuses.DRAFT) {
			const todo = createReaderInquiryDraftTodoData(firebase, { offer, status: offerStatuses.DRAFT });

			batch.set(todo.ref, todo);
		}

		// Create the offer
		batch.set(offer.ref, offer);
	});

	return offer;
}
