import { Button } from "semantic-ui-react";

import useHasRight from "../../../authorization/hooks/useHasRight";

import AddPricesAction from "./AddPricesAction";

export default function PricesActions() {
	const hasRight = useHasRight();

	return (
		<Button.Group basic secondary>
			{hasRight("prices.create") && <AddPricesAction />}
		</Button.Group>
	);
}
