import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchBox } from "react-instantsearch";
import { Input } from "semantic-ui-react";

import useDebounce from "astrid-hooks/src/useDebounce";

import { ClearStateContext } from "../contexts/ClearStateProvider";

export default function CustomSearchBox({ value, customCallbackSearch }) {
	const { t } = useTranslation();
	const { clearKey } = useContext(ClearStateContext);
	const { refine } = useSearchBox();
	const [searchTerm, setSearchTerm] = useState(value || "");
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	const mounted = useRef(false);

	useEffect(() => {
		setSearchTerm(value || "");
	}, [value]);

	useEffect(() => {
		setSearchTerm("");
	}, [clearKey]);

	const searchFn = useCallback(
		(value) => {
			if (customCallbackSearch) {
				customCallbackSearch(value);
			} else {
				refine(value);
			}
		},
		[refine, customCallbackSearch],
	);

	useEffect(() => {
		// prevent clear of url on initial render
		if (mounted.current) {
			searchFn(debouncedSearchTerm);
		} else {
			mounted.current = true;
		}
	}, [debouncedSearchTerm, searchFn]);

	return (
		<Input
			fluid
			icon="search"
			placeholder={`${t("search", "Search") + "..."}`}
			value={searchTerm}
			onChange={(e, { value }) => {
				setSearchTerm(value);
			}}
		/>
	);
}
