import api from "../../../../api";

import FirestoreDocumentContext from "../../../firebase/components/FirestoreDocumentContext";

import SuperAdminDistributionChannelView from "../../views/SuperAdminDistributionChannelView/SuperAdminDistributionChannelView";

export default function SuperAdminDistributionChannel() {
	return (
		<FirestoreDocumentContext param="channel" api={api.distributionChannels}>
			<SuperAdminDistributionChannelView />
		</FirestoreDocumentContext>
	);
}
