import React from "react";

import DocTitle from "astrid-web/src/components/DocTitle";

import TaskRunner from "../components/superadmin/TaskRunner";

const Superadmin = (props) => {
	return (
		<>
			<DocTitle title="Superadmin" />

			<TaskRunner profile={props.profile} />
		</>
	);
};

export default Superadmin;
