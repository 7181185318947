import React from "react";
import { Droppable } from "react-beautiful-dnd";

export default function DroppableSlot({ children, ...props }) {
	return (
		<Droppable {...props}>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.droppableProps}>
					{children}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
}
