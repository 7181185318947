import firebase from "firebase/app";

import updateSession from "astrid-firestore/src/api/sessions/updateSession";

export default function useOnChangeEvent({ setIsLoading, resourceType, setModifiedSessions }) {
	return async ({ event }) => {
		setIsLoading(true);

		const resources = event.getResources();

		const {
			id,
			extendedProps: { studio, noStudio },
		} = resources[0];

		const {
			start,
			end,
			extendedProps: { session },
		} = event;

		try {
			const data = {
				start,
				end,
			};

			if (resourceType === "studios") {
				data.studio = noStudio ? null : studio;
				data.studioGroup = noStudio ? id : null;
			}

			const after = await updateSession(firebase, { session, ...data });

			setModifiedSessions((sessions) => ({
				...sessions,
				[session.id]: {
					// Ensure that the before session always is the original session independent of changes
					before: sessions?.[session.id]?.before || session,
					// Latest changes to the session
					after,
				},
			}));
		} catch (error) {
			window.alert(error.message);
		}

		setIsLoading(false);
	};
}
