import { useCallback } from "react";
import { useFieldArray } from "react-hook-form";

import Table from "../../../../ui/components/Table/Table";

import usePricesColumns from "../hooks/usePricesColumns";

import PricesFormTableActions from "./PricesFormTableActions";

export default function PricesFormTable() {
	const { fields, append, remove } = useFieldArray({
		name: "prices",
	});

	const onAdd = useCallback((lastPrice) => append({ ...lastPrice, name: "" }), [append]);
	const onRemove = useCallback(({ index }) => remove(index), [remove]);

	const pricesColumns = usePricesColumns({ onRemove });

	return <Table data={fields} columns={pricesColumns} footer={<PricesFormTableActions onAdd={onAdd} />} />;
}
