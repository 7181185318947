import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

import { formatPrice } from "../../../../prices/utils/priceFormatters";
import useTeamRoleTranslations from "../../../../team/hooks/useTeamRoleTranslations";

import AdminUserPriceDropdownMenu from "../components/AdminUserPriceDropdownMenu";

export default function useAdminUserPricesColumns({ user, organization }) {
	const { t } = useTranslation();

	const roles = useTeamRoleTranslations();

	return useMemo(
		() => [
			{
				id: "agreement.secondParty.vendor.name",
				Header: t("vendor", "Vendor"),
				Cell: ({ value }) => value || t("employee", "Employee"),
			},
			{
				id: "role",
				Header: t("role", "Role"),
				accessor: ({ role }) => roles[role],
			},
			{
				id: "price",
				Header: t("price", "Price"),
				accessor: ({ price }) => price && `${price.name} (${formatPrice(price)})`,
			},
			{
				id: "language",
				Header: t("language", "Language"),
				accessor: ({ language }) => languageName(language),
			},
			{
				id: "agreement.name",
				Header: t("agreement", "Agreement"),
				Cell: ({ value }) => value || t("employee", "Employee"),
			},
			{
				id: "remove",
				collapsing: true,
				Cell: ({ row }) => (
					<AdminUserPriceDropdownMenu user={user} organization={organization} price={row.original} />
				),
			},
		],
		[organization, roles, t, user],
	);
}
