import React from "react";
import { Radio } from "semantic-ui-react";

import useFormField from "../../hooks/useFormField";

export default function FormRadio({ value, ...props }) {
	const { field } = useFormField(props);

	return <Radio {...field} value={value} checked={value === field.value} />;
}
