import { Outlet, useOutletContext, useParams } from "react-router-dom";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import SuperAdminDistributionPipelineView from "../../views/SuperAdminDistributionPipelineView/SuperAdminDistributionPipelineView";

export default function SuperAdminDistributionPipeline() {
	const { id } = useParams();

	const { channel } = useOutletContext();

	const pipeline = channel.pipelines[id];

	console.log(pipeline);

	return (
		<LoadingContext data={pipeline}>
			{pipeline && (
				<SuperAdminDistributionPipelineView
					channel={channel}
					pipeline={pipeline}
					outlet={<Outlet context={{ channel, pipeline }} />}
				/>
			)}
		</LoadingContext>
	);
}
