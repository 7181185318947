import compact from "lodash/compact";
import get from "lodash/get";
import intersection from "lodash/intersection";
import merge from "lodash/merge";
import union from "lodash/union";
import moment from "moment/min/moment-with-locales";

import { Button, Form, Icon, Label, Loader, Modal, Popup } from "semantic-ui-react";

import React, { Component } from "react";
// calendar (TODO: consider lazy loading / code splitting)
import BigCalendar from "react-big-calendar";
import { withTranslation } from "react-i18next";

import { base, firebase, notifyUsers } from "astrid-firebase";
import db from "astrid-firebase/src/db";
import addEvent from "astrid-firebase/src/utils";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import { getTeamMembersByRole } from "astrid-firestore/src/api/productions/utils/team";
import isScriptReadyAtSessionStart from "astrid-firestore/src/api/sessions/utils/isScriptReadyAtSessionStart";
import sessionOfferTypes from "astrid-firestore/src/constants/sessionOfferTypes";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import { setData } from "astrid-firestore/src/helpers";
import { prioTimeLimits } from "astrid-permissions/prio";
// components
import DocTitle from "astrid-web/src/components/DocTitle";
import i18n from "astrid-web/src/i18n";

import Right from "../components/Right";
import AvailabilityModal from "../components/calendar/AvailabilityModal";
import BookingStats from "../components/calendar/BookingStats";
import NotificationButton from "../components/calendar/NotificationButton";
import NotificationModal from "../components/calendar/NotificationModal";
import StudioAppIcon from "../components/calendar/StudioAppIcon";
import BookSession from "../features/calendar/components/BookSession";
import OpenSessionModal from "../features/calendar/components/OpenSession/OpenSessionModal";
import isAnyRecorderInTeamOrOpenPosition from "../features/calendar/helpers/isAnyRecorderInTeamOrOpenPosition";
import isAssignedToRecorder from "../features/calendar/helpers/isAssignedToRecorder";
import sessionIsAvailableForRecorder from "../features/calendar/helpers/sessionIsAvailableForRecorder";
import hasLanguageOptions from "../features/language/hasLanguageOptions";
import { withStore } from "../helpers/context";
import { toDate } from "../helpers/fnc";
import { colors, defaultHours } from "../helpers/lists";

const localizer = BigCalendar.momentLocalizer(moment);

const allViews = ["month", "week", "day"];

class Calendar extends Component {
	state = {
		loadingSessions: true,
		loadingProductions: true,
		loadingUsers: true,

		// data
		sessions: [],
		productions: [],
		users: [],
		studioStatus: {},

		// calendar state
		currentDate: new Date(),
		view: window.innerWidth < 600 ? "day" : "week",
		filter: { status: ["cancelled"] },

		// booking
		bookProduction: "",
		bookStudio: "",
		bookReader: "",
		bookPrio: true,
		bookPolishSession: null,
		bookRecorder: null,
		bookRecorderName: null,
		priceId: null,
		priceRef: null,
		agreementRef: null,
		createProductionPriceCallback: async () => ({}),

		// viewing/editing
		selectedSession: null,

		// availability
		editingAvailability: null,

		// affected peeps
		affectedStaff: [],
		notifications: {},

		// modal
		modal: null,
		preventModalClose: false,
	};

	// to prevent session selection from triggering while dragging
	isDragging = false;

	UNSAFE_componentWillMount() {
		// use en-gb to start week on monday for all countries (en-us is default and starts on sunday)
		moment.locale(i18n.language === "en" ? "en-gb" : i18n.language || "sv");

		// book specific production?
		if (document.location.search.substr(0, 11) === "?production") {
			this.setState({
				bookProduction: document.location.search.substr(12),
				modal: "book",
			});
		}

		this.props.store.getProductions({ filterStatus: ["planning", "accepted", "production"] }).then((org) => {
			const newState = {
				loadingProductions: false,
			};

			if (this.state.bookProduction) {
				const prod = this.props.store.state.productions?.[this.state.bookProduction];
				// booking from prod page, add readers (check to avoid "ghost readers" (actual names accidentally stored))
				let bookReaders = (prod?.reader || []).filter((reader) => !reader.includes(" ") && reader.length > 20);

				if (bookReaders.length) newState.bookReader = bookReaders;
				if (prod?.recorderPrio) newState.bookPrio = true;
			}
			this.setState(newState);

			// get producer and studio info
			const producer =
				this.props.store.state.producerId ||
				// hacky way to pick producer ID from first production for reader users, since their permissions don't contain producer ID
				(this.props.store.state.productions && Object.values(this.props.store.state.productions)[0]?.producer);

			base.bindDoc("organizations/" + producer, {
				context: this,
				state: "producer",
				then() {
					// get studio status
					base.get("organizations/" + producer + "/studios", { withIds: true })
						.then((data) => {
							const studioStatus = data.reduce((prev, curr) => {
								prev[curr.id] = curr;
								return prev;
							}, {});

							this.setState({ studioStatus });
						})
						.catch((e) => {
							console.log(e);
						});
				},
				onFailure(err) {
					console.log("producer err", err);
				},
			});

			// get all the data
			this.getSessions();
		});

		// TODO: set per prod or use store.getUsers?
		base.bindCollection("users", {
			context: this,
			state: "users",
			withIds: true,
			then() {
				this.setState({
					loadingUsers: false,
				});
			},
			onFailure(err) {
				console.log("Users get err", err);
				this.setState({
					loadingUsers: false,
				});
			},
		});

		// set filter from localstorage?
		const ls = window.localStorage.getItem("cal");
		if (ls) {
			const json = JSON.parse(ls);
			const filter = json.filter;
			const columnMode = json.columnMode;
			this.setState({ filter, columnMode }, this.setDefaultFilter);
		} else {
			this.setDefaultFilter();
		}
	}

	arrayToObject = (array) =>
		array.reduce((obj, item) => {
			obj[item.id] = item;
			return obj;
		}, {});

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (nextState.users) this.userMap = this.arrayToObject(this.state.users);

		// get new sessions?
		if (this.state.view !== nextState.view || this.state.currentDate !== nextState.currentDate) {
			this.getSessions(nextState.view, nextState.currentDate);
		}
	}

	componentDidUpdate() {
		// remove RBC-added title
		const eventEls = document.querySelectorAll(".rbc-event");
		for (const el of eventEls) {
			el.removeAttribute("title");
		}
	}

	setDefaultFilter = () => {
		const prodPermissions = get(this.props.profile, "permissions.producer")
			? get(this.props.profile, "permissions.producer." + this.props.store.state.producerId) ||
			  Object.values(get(this.props.profile, "permissions.producer"))[0]
			: [];

		if (
			prodPermissions.includes("producerRecorder") &&
			!prodPermissions.includes("producerStaff") &&
			!prodPermissions.includes("producerAdmin")
		) {
			// hide other recorders sessions
			this.setFilter(null, { name: "owner", value: "other", checked: true });
		}
	};

	getSessions = (view, currentDate) => {
		// ditch old binding
		if (this.sessionBind) {
			this.setState({
				loadingSessions: true,
			});
			base.removeBinding(this.sessionBind);
		}

		// figure out date span
		if (!currentDate) {
			// get from current state (first load)
			view = this.state.view;
			currentDate = this.state.currentDate;
		}
		const start = moment(currentDate).startOf(view).toDate();
		const end = moment(currentDate).endOf(view).toDate();

		this.sessionBind = base.bindCollection("sessions", {
			context: this,
			state: "sessions",
			withIds: true,
			query: (ref) => {
				ref = ref.where("start", ">=", start).where("start", "<=", end).orderBy("start");

				if (this.props.store.state.producerId) {
					ref = ref.where("producer", "==", this.props.store.state.producerId);
				}

				return ref;
			},
			then() {
				this.setState({
					loadingSessions: false,
				});
			},
			onFailure(err) {
				console.log("Session get err", err);
			},
		});

		// get swedish holidays for Earselect only
		if (this.props.store?.state?.producerId === "HfvG4FQfXWpWv6dzqM5E") {
			this.getHolidays(start, end);
		}
	};

	getHolidays = (start, end) => {
		const holidays = [];
		const startYear = moment(start).format("YYYY");
		const startMonth = moment(start).format("MM");
		const endYear = moment(end).format("YYYY");
		const endMonth = moment(end).format("MM");

		const months = [[startYear, startMonth]];

		// add second month if necessary
		if (startMonth !== endMonth) months.push([endYear, endMonth]);

		// get holidays from shady free api https://sholiday.faboul.se/
		Promise.all(months.map(([year, month]) => fetch(`https://sholiday.faboul.se/dagar/v2.1/${year}/${month}`)))
			.then((responses) => Promise.all(responses.map((response) => response.json())))
			.then((data) => {
				data.forEach((month) => {
					month.dagar
						.filter((day) => day.helgdag)
						.forEach((day) => {
							holidays.push({
								start: new Date(day.datum),
								end: new Date(day.datum),
								title: day.helgdag,
								allDay: true,
								holiday: true,
							});
						});
				});

				this.setState({ holidays });
			})
			.catch(function (error) {
				console.log("Swedish holiday error", error);
			});
	};

	createSession = (select) => {
		const { t, store } = this.props;
		const productionId = this.state?.bookProduction;
		const producerId = this.props.store.state?.producerId;
		const productionData = productionId && this.props.store.state.productions[productionId];
		const recorder = this.state.bookRecorder;
		const reader = this.state.bookReader;
		const start = select.start;
		const end = select.end;
		const prodTitle =
			productionData && productionData.title ? t("title") + ": " + productionData.title : t("prodNotSpecified");

		if (end - start < 1000 * 60 * 20) {
			return;
		} else if (end - start < 1000 * 60 * 25) {
			alert(t("recSessionTooShort"));
		} else if (start < new Date()) {
			alert(t("cantCreatePassedSession"));
		} else {
			// confirm if script date is after session
			if (
				!isScriptReadyAtSessionStart({ production: productionData, start }) &&
				!window.confirm(
					t("scriptExpected") +
						" " +
						moment(productionData.scriptDate?.toDate()).format("YYYY-MM-DD") +
						"," +
						t("bookAnyway"),
				)
			) {
				return;
			}

			// go for it
			this.setState({ creating: true });
			let studio = this.state.bookStudio;

			const overlappingStudio = this.state.sessions.filter(
				(session) =>
					session.status === "booked" &&
					toDate(session.start) < end &&
					toDate(session.end) > start &&
					session.studio === studio,
			);

			if (overlappingStudio.length > 0) {
				return window.alert(t("StudioAlreadyInUse"));
			}

			// get studio details
			const studioDetails = this.state.producer.studios?.[studio];

			const ref = db.collection("sessions").doc();

			const managers = getTeamMembersByRole({ production: productionData, role: teamRoles.MANAGER });

			const data = {
				id: ref.id,
				ref,
				status: "booked",
				created: firebase.firestore.FieldValue.serverTimestamp(),
				managerIds: managers.map((manager) => manager.id),
				managers,
				recorder,
				recorderData: {
					name: this.state.bookRecorderName,
				},
				priceId: this.state.priceId,
				priceRef: this.state.priceRef,
				agreementRef: this.state.agreementRef,
				studio,
				start,
				end,
				production: productionId,
				producer: producerId,
				reader,
			};

			if (this.state.bookPrio) data.recorderPrio = true;
			if (this.state.bookPolishSession) data.polish = true;

			this.state
				.createProductionPriceCallback(data)
				.then((productionPriceRef) => {
					// all set, store it
					setData(ref, {
						...data,
						productionPriceRef: productionPriceRef || null,
					}).then(() => {
						if (productionId) {
							// store event in production for blaming
							addEvent({
								productionId,
								user: this.props.user.uid,
								email: this.props.profile.email,
								data: {
									createSession: {
										...data,
										recorder: store.getUserName(data.recorder, this.userMap) || null,
										reader: data.reader.map((id) => store.getUserName(id, this.userMap)),
									},
								},
							});
						}

						// update some production data
						if (productionData) {
							let prodUpdate = {};

							// set production status
							if (["planning", "accepted"].includes(productionData.status)) {
								prodUpdate.status = "production";
							}

							// add bookedPolish on production
							if (data.polish) {
								prodUpdate.bookedPolish = true;
							}

							if (recorder && !productionData?.bookedRecorders?.includes(recorder)) {
								prodUpdate.bookedRecorders = [...(productionData?.bookedRecorders || []), recorder];
							}

							if (Object.keys(prodUpdate).length) {
								base.updateDoc("productions/" + productionId, prodUpdate)
									.then((data) => {})
									.catch((err) => console.log);
							}
						}

						// people to notify
						// prettier-ignore
						const affectedStaff = this.addAffectedStaff(
							false,
							reader,
							[recorder].filter(
							(recorder) =>
								![sessionOfferTypes.OPEN_POSITION_RECORDER, sessionOfferTypes.ANY_RECORDER_IN_TEAM].includes(recorder),
						)
						);

						// store notification for sending to affected staff later
						this.addNotification(ref.id, "new", [...reader, recorder]);

						// reset state and store affected staff
						this.setState({
							studioError: false,
							dateError: false,
							creating: false,
							affectedStaff,
						});

						// notify all recorders about available session
						if (!recorder) {
							const host = window.ES.stage ? "https://stage.astrid.fm" : "https://astrid.fm";

							const readerString = reader
								.reduce(
									(arr, id) => [
										...arr,
										this.userMap[id]
											? this.userMap[id].firstName + " " + this.userMap[id].lastName
											: "[Raderad uppläsare!]",
									],
									[],
								)
								.join(" & ");

							const messageDetails = `${prodTitle}
								${t("time") + ":"} ${moment(start).format("YYYY-MM-DD LT")} - ${moment(end).format("LT")}
								${t("studio") + ":"} ${studioDetails.name} (${studioDetails.address})
								${t("narrator") + ":"} ${readerString}`;

							let notifyRecorder, subject, message;

							const url = productionData && `${host}/production/${productionId}?editSession=${ref.id}`;

							const producerId = this.props.store.state.producerId;

							notifyRecorder = this.state.users
								.filter(
									(user) =>
										user.permissions?.producer?.[producerId] &&
										// skip admins for now... https://trello.com/c/OlZ1qPgX/1093-notiser
										!user.permissions.producer[producerId].includes("producerAdmin") &&
										!user.permissions.producer[producerId].includes("producerStaff") &&
										user.permissions.producer[producerId].includes("producerRecorder") &&
										(!data.recorderPrio ||
											user.permissions.producer[producerId].includes("producerRecorderPrio") ||
											moment(toDate(data.start)).diff(moment(), "hours") <
												prioTimeLimits.producerRecorder) &&
										!user.permissions.producer[producerId].includes("producerLimited") &&
										hasLanguageOptions({
											user,
											orgId: producerId,
											orgType: organizationTypes.PRODUCER,
											languageToMatch: productionData.language,
										}) &&
										user.id !== this.props.user.uid,
								)
								.map((user) => user.id);
							subject = t("recSessionNoTechn") + ` - ${prodTitle}`;
							message = t("recSessionMessage", { sessionMessage: messageDetails, webUrl: url });
							notifyUsers(notifyRecorder, this.userMap, subject, message);
						}
					});
				})
				.catch((error) => {
					console.log("Session add error", error);
				});
		}
	};

	addAffectedStaff = (update, ...staff) => {
		const affectedStaff = compact(union(this.state.affectedStaff, ...staff));

		if (update) {
			this.setState({ affectedStaff });
		} else {
			return affectedStaff;
		}
	};

	addNotification = (session, type, staff) => {
		const notifications = { ...this.state.notifications };
		notifications[session] = {
			type: notifications[session] ? notifications[session].type : type,
			staff,
		};

		this.setState({ notifications });
	};

	sessionDetails = (session) => {
		const { t, profile } = this.props;
		const recorder = this.userMap[session.recorder];
		const production =
			(this.props.store.state.productions && this.props.store.state.productions[session.production]) || {};
		const reader = session.reader || production.reader || [];
		const studio = get(this, "state.producer.studios." + session.studio) || session.studioData;

		if (!this.state.producer) return;

		const isRecorder = profile?.permissions?.producer?.[session.producer]?.includes?.("producerRecorder");
		const isReaderForSession = reader.includes(this.props.user.uid);

		const notAvailableForRecorder =
			isRecorder &&
			!isReaderForSession &&
			!sessionIsAvailableForRecorder({
				production,
				session,
				sessionRoles: { isRecorder },
				user: { id: this.props.user.uid },
			});

		return (
			<div>
				<strong>
					{recorder ? (
						recorder.firstName + " " + recorder.lastName
					) : (
						<span style={{ whiteSpace: "nowrap" }}>
							<Icon fitted name={notAvailableForRecorder ? "dont" : "warning sign"} />{" "}
							{notAvailableForRecorder
								? t("sessionNotAvailable", "Not available for you")
								: t("technBookedSoon")}
						</span>
					)}
				</strong>
				{session.recorder === this.props.user.uid && <Icon name="star" />}
				<br />
				{production.title || t("isNoProduction")}
				<br />
				<strong>
					{reader &&
						reader
							.reduce((arr, id) => {
								return [
									...arr,
									this.userMap[id]
										? this.userMap[id].firstName + " " + this.userMap[id].lastName
										: t("deletedNarratorBrackets"),
								];
							}, [])
							.join(" & ")}
				</strong>
				<br />
				<StudioAppIcon studioStatus={this.state.studioStatus} studio={session.studio} />
				{studio && " " + studio.name}
				<br />
				{session.polish && <Icon name="paint brush" />}
				{session.status === "done" && <Icon name="check" />}
				{session.status === "cancelled" && <Icon name="cancel" />}
			</div>
		);
	};

	getStudioGroupTags = () => {
		// gather all groups
		let tags = Object.values(get(this.state, "producer.studios") || {}).reduce(
			(prev, curr) => [...prev, ...(curr.groupTags || [])],
			[],
		);

		// make'm unique
		tags = [...new Set(tags)];

		return tags;
	};

	getGroupStudios = (group) => {
		// convert string to array for historic reasons
		if (typeof group === "string") group = [group];

		// merge all studio groups
		const studios = this.state.producer.studios;
		const groupStudios = group.reduce((prev, curr) => {
			const all = isNaN(curr)
				? Object.keys(studios).filter((key) => studios[key].groupTags && studios[key].groupTags.includes(curr))
				: [curr];

			return [...prev, ...all];
		}, []);

		// return unique
		return [...new Set(groupStudios)];
	};

	isStudioInGroup = (studio, group) =>
		// look for id or tag overlap
		group.includes(studio) || intersection(this.state.producer.studios[studio]?.groupTags || [], group).length;

	setAvailability = (select) => {
		const { t } = this.props;
		const start = select.start;
		const end = select.end;
		const { uid, spanType } = this.state.editingAvailability;

		if (end - start < 1000 * 60 * 20) {
			// ignore clicks
			return;
		} else if (end - start < 1000 * 60 * 25) {
			// don't allow short breaks
			alert(t("bookAtleastHalfHour"));
		} else if (start < new Date()) {
			// don't allow short breaks
			alert(t("timeHasPassed"));
		} else if (
			spanType === "timeOff" &&
			this.state.sessions.find(
				(session) =>
					session.status === "booked" &&
					session.recorder &&
					session.recorder.includes(uid) &&
					toDate(session.start) < end &&
					toDate(session.end) > start,
			)
		) {
			// check that the recorder isn't already booked
			alert(t("anotherRecIsBooked"));
		} else {
			// all good, create session
			const user = this.userMap[uid];

			const availability = { ...(user.availability || {}) };

			// create array if needed
			if (!availability[spanType]) availability[spanType] = [];

			// add span
			const data = {
				in: start,
				out: end,
			};

			// set home studio
			if (spanType === "bookableTime") data.studio = get(user, "availability.homeStudio") || "";

			availability[spanType].push(data);

			// update db
			base.updateDoc("/users/" + uid, {
				"availability.updated": firebase.firestore.FieldValue.serverTimestamp(),
				["availability." + spanType]: availability[spanType],
			}).then(() => {
				// console.log("done");
			});
		}
	};

	modalClose = () => {
		if (this.state.preventModalClose) {
			this.setState({ preventModalClose: false });
		} else {
			this.setState({ modal: null, selectedSession: null });
		}
	};

	resetBooking = () => {
		this.setState({
			bookProduction: null,
			bookReader: null,
			bookRecorder:
				this.props.profile.permissions.producer &&
				Object.values(this.props.profile.permissions.producer[this.props.store.state.producerId]).includes(
					"producerRecorder",
				)
					? this.props.user.uid
					: null,
			bookStudio: null,
		});
	};

	setFilter = (e, data) => {
		let filter = null;
		let filterColumMode = this.state.columnMode;

		if (data) {
			// clone or start anew
			filter = this.state.filter ? { ...this.state.filter } : {};
			const type = data.name;

			// create empty type array if needed
			if (!filter[type]) filter[type] = [];

			if (data.checked) {
				// add toggled value to filter
				filter[type].push(data.value);
			} else if (type === "group") {
				// set studios based on group
				if (filter.group === data.value) {
					filter.studio = [];
					filterColumMode = false;
					delete filter.group;
				} else {
					filter.group = data.value;
					filterColumMode = true;
					filter.studio = Object.keys(this.state.producer.studios).filter(
						(studio) =>
							!this.state.producer.studios[studio].groupTags ||
							!this.state.producer.studios[studio].groupTags.includes(data.value),
					);
				}
			} else if (Array.isArray(data.value)) {
				// add dropdown value to filter
				if (data.value.length) {
					filter[type] = data.value;
				} else {
					filter[type] = null;
				}
			} else {
				// remove from filter
				const index = filter[type].indexOf(data.value);
				if (index >= 0) filter[type].splice(index, 1);

				if (!filter[type].length) filter[type] = null;
			}

			// no values?
			// reset all if all empty
			if (!compact(Object.values(filter)).length) filter = false;
		} else {
			filter = { status: ["cancelled"] };
		}

		this.setState({ filter, columnMode: filterColumMode });
		window.localStorage.setItem("cal", JSON.stringify({ filter, columnMode: filterColumMode }));
	};

	readerStudio = (readers) => {
		let readerWithStudio =
			readers && readers.find((reader) => get(this.userMap, reader + ".availability.homeStudio"));
		let studio = get(this.userMap[readerWithStudio], "availability.homeStudio");

		if (typeof studio === "string") studio = [studio];

		// return array or false
		return studio && studio.length ? [readerWithStudio, studio] : false;
	};

	render() {
		let {
			users,
			sessions,
			producer,
			selectedSession,
			filter,
			modal,
			view,
			currentDate,
			editingAvailability,
			bookProduction,
			bookReader,
			bookRecorder,
			bookStudio,
			columnMode,
			holidays,
		} = this.state;
		const { t, profile, user, store } = this.props;
		const productions = Object.values(store.state.productions || {});
		const productionMap = store.state.productions || {};

		// is booking active?
		const bookingActive = view !== "month" && bookReader && bookStudio && !editingAvailability;
		const bookingMissingScript =
			bookProduction && productionMap[bookProduction] && !productionMap[bookProduction].script;
		const bookingMissingScriptDate =
			bookingMissingScript &&
			productionMap[bookProduction].scriptDate &&
			toDate(productionMap[bookProduction].scriptDate);

		// skip availability editing in month view
		if (view === "month") editingAvailability = null;

		// permissions
		const prodPermissions = get(profile, "permissions.producer")
			? get(profile, "permissions.producer." + this.props.store.state.producerId) ||
			  Object.values(get(profile, "permissions.producer"))[0]
			: [];

		// hide other productions from readers (if they are not recordrs)
		let activeSessions = sessions;

		if (profile.permissions.reader && !prodPermissions.includes("producerRecorder")) {
			activeSessions = activeSessions.filter((session) => {
				const prod = session.production && productionMap[session.production];
				return (
					// reader explicitly booked on session
					(session.reader && session.reader.includes(user.uid)) ||
					// no reader explicitly booked, but booked in production
					(!session.reader && prod && prod.reader && prod.reader.includes(user.uid))
				);
			});
		}

		// hide prio productions from nonprio users
		if (!prodPermissions.includes("producerAdmin") && !prodPermissions.includes("producerRecorderPrio")) {
			activeSessions = activeSessions.filter(
				(session) =>
					// the booked reader can see anyway
					(session.reader && session.reader.includes(user.uid)) ||
					// nonprio recorder can see within time limit
					!(
						!session.recorder &&
						session.recorderPrio &&
						moment(toDate(session.start)).diff(moment(), "hours") > prioTimeLimits.producerRecorder
					),
			);
		}

		// hide other productions from limited users
		if (prodPermissions.includes("producerLimited")) {
			activeSessions = activeSessions.filter(
				(session) =>
					// limited recorder explicitly booked on session
					session.recorder && session.recorder.includes(user.uid),
			);
		}

		// filter active?
		if (filter) {
			activeSessions = activeSessions.filter((session, i) => {
				const teamRecorderOrOpenPosition = isAnyRecorderInTeamOrOpenPosition(session);
				const isNotYetAssigned = !isAssignedToRecorder(session);
				return !(
					(filter.studio && filter.studio.includes(session.studio)) ||
					(filter.owner && filter.owner.includes("self") && session.recorder === user.uid) ||
					(filter.owner &&
						filter.owner.includes("other") &&
						session.recorder &&
						session.recorder !== user.uid &&
						!teamRecorderOrOpenPosition) ||
					(filter.owner && filter.owner.includes("noTechnicianBooked") && isNotYetAssigned) ||
					(filter.status && filter.status.includes(session.status)) ||
					(filter.production && !filter.production.includes(session.production)) ||
					(filter.reader &&
						!intersection(
							filter.reader,
							productionMap[session.production]
								? productionMap[session.production].reader
								: session.reader,
						).length) ||
					(filter.manager &&
						!intersection(filter.manager, productionMap[session.production]?.manager).length) ||
					(filter.recorder &&
						!(
							filter.recorder.includes(session.recorder) ||
							(filter.recorder.includes("none") && isNotYetAssigned)
						))
				);
			});
		}

		// massage events for calendar display
		let calendarEvents = activeSessions.map((session) => {
			// is the event overlapping?
			let overlappingStudio, overlappingRecorder, overlappingReader, isPolish;
			const overlapping = activeSessions.find((compare) => {
				if (
					// don't compare with self
					compare.id !== session.id &&
					// skip cancelled sessions
					compare.status !== "cancelled" &&
					session.status !== "cancelled" &&
					// is the time overlapping?
					toDate(compare.start) < toDate(session.end) &&
					toDate(compare.end) > toDate(session.start)
				) {
					overlappingStudio = session.studio && compare.studio === session.studio;
					overlappingRecorder = isAssignedToRecorder(session) && compare.recorder === session.recorder;
					overlappingReader =
						session.reader &&
						session.reader.length &&
						compare.reader &&
						!!intersection(compare.reader, session.reader).length;

					// skip overlapping polish sessions
					if (overlappingStudio && overlappingReader && (compare.polish || session.polish)) {
						isPolish = true;
					}
				}
				return !isPolish && (overlappingReader || overlappingRecorder || overlappingStudio);
			});

			return {
				id: session.id,
				title:
					view !== "month" ? (
						this.sessionDetails(session)
					) : (
						<Popup
							trigger={
								<span>
									{(productionMap[session.production] && productionMap[session.production].title) ||
										session.reader
											.reduce((arr, id) => {
												return [
													...arr,
													this.userMap[id]
														? this.userMap[id].firstName + " " + this.userMap[id].lastName
														: t("deletedNarratorBrackets"),
												];
											}, [])
											.join(" & ")}
								</span>
							}
							content={
								<div>
									{session.start &&
										session.start.toDate &&
										moment(session.start.toDate()).format("LT")}{" "}
									– {session.end && session.end.toDate && moment(session.end.toDate()).format("LT")}
									{this.sessionDetails(session)}
								</div>
							}
						/>
					),
				status: session.status,
				start: session.start && session.start.toDate && session.start.toDate(),
				end: session.end && session.end.toDate && session.end.toDate(),
				studio: session.studio,
				recorderless: !session.recorder,
				studioData: session.studioData,
				personal:
					(session.recorder && session.recorder?.includes?.(user.uid)) ||
					(session.reader && session.reader.includes(user.uid)) ||
					(productionMap[session.production] &&
						productionMap[session.production].reader &&
						productionMap[session.production].reader.includes(user.uid)),
				overlapping: overlapping,
			};
		});

		const calendarStart = moment(currentDate).startOf(view).toDate();
		const calendarEnd = moment(currentDate).endOf(view).toDate();

		if (bookingActive && !modal) {
			// create booking overlay

			// start creating overlays at current time or beginning of calendar
			let currentStart = new Date() > calendarStart ? new Date() : calendarStart;

			let timeoff = [];
			if (bookRecorder) {
				const recorder = this.userMap[bookRecorder];
				timeoff = [...(get(recorder, "availability.timeOff") || [])];
				const standardTime = merge({}, defaultHours, get(recorder, "availability.standardTime"));

				// loop standard time and convert into time off spans
				let tempDay = calendarStart;
				while (tempDay < calendarEnd) {
					const isoDay = moment(tempDay).format("E");
					const dayIn = standardTime["day" + isoDay].in;
					const dayOut = standardTime["day" + isoDay].out;

					// morning (or day) off
					timeoff.push({
						// start at midnight
						in: firebase.firestore.Timestamp.fromDate(tempDay),
						// go out at work day's start or end of day if day off
						out: firebase.firestore.Timestamp.fromDate(
							dayIn
								? moment(tempDay).hour(dayIn.substr(0, 2)).minute(dayIn.substr(-2, 2)).toDate()
								: moment(tempDay).endOf("day").toDate(),
						),
					});

					if (dayIn && dayOut) {
						// night off
						timeoff.push({
							in: firebase.firestore.Timestamp.fromDate(
								moment(tempDay).hour(dayOut.substr(0, 2)).minute(dayOut.substr(-2, 2)).toDate(),
							),
							out: firebase.firestore.Timestamp.fromDate(moment(tempDay).endOf("day").toDate()),
						});
					}

					// go to next day
					tempDay = moment(tempDay).add(1, "day").toDate();
				}
			}

			let readersTime;
			if (bookReader && bookReader.length) {
				// merge readers bookable time
				const bookableTime = bookReader.reduce((prev, curr) => {
					const recorder = this.userMap[curr];
					const bookableTime = get(recorder, "availability.bookableTime") || [];
					return [...prev, ...bookableTime];
				}, []);

				// only create timeoffs if reader actually has bookable time
				if (bookableTime.length) {
					// filter relevant times
					const relevantTime = bookableTime
						.filter(
							(time) =>
								(bookStudio === "homeStudio" || time.studio === bookStudio) &&
								toDate(time.in) < calendarEnd &&
								toDate(time.out) > calendarStart,
						)
						.sort((a, b) => (toDate(a.in) > toDate(b.in) ? 1 : -1));

					let tempTime = calendarStart;
					if (relevantTime.length) {
						// set variable to use in collision check later
						readersTime = relevantTime;

						// loop bookable time and convert into time off spans
						relevantTime.forEach((bookable) => {
							if (toDate(bookable.out) > tempTime) {
								// invert time (but not too much, caused by overlapping availability)
								if (tempTime < toDate(bookable.in))
									timeoff.push({
										in: firebase.firestore.Timestamp.fromDate(tempTime),
										out: bookable.in,
									});

								tempTime = toDate(bookable.out);
							}
						});

						// close out rest
						if (tempTime < calendarEnd)
							timeoff.push({
								in: firebase.firestore.Timestamp.fromDate(tempTime),
								out: firebase.firestore.Timestamp.fromDate(calendarEnd),
							});
					} else {
						// no bookable time!
						timeoff.push({
							in: firebase.firestore.Timestamp.fromDate(tempTime),
							out: firebase.firestore.Timestamp.fromDate(calendarEnd),
						});
					}
				}
			}

			// sort timeoff chronologically
			timeoff = timeoff && timeoff.sort((a, b) => (toDate(a.in) > toDate(b.in) ? 1 : -1));

			const overlays = [];

			// flag to skip group collisions when there are other studios available in the group
			let skipGroup = [];

			while (currentStart < calendarEnd) {
				// check recorder and reader time off
				let availabilityCollision;

				if (timeoff && timeoff.length) {
					availabilityCollision = timeoff.find(
						// TODO: figure out why eslint warns about creating functions...
						// eslint-disable-next-line
						(span) =>
							// starts after current
							(toDate(span.in) > currentStart &&
								toDate(span.in) < moment(currentStart).endOf("day").toDate()) ||
							// or already started but ends after current
							(toDate(span.in) <= currentStart && toDate(span.out) > currentStart),
					);

					if (availabilityCollision) {
						availabilityCollision = {
							start: availabilityCollision.in,
							end: availabilityCollision.out,
						};
					}
				}

				// find next session collision
				let bookStudioGroup = false;

				const sessionCollision = sessions.find(
					// TODO: figure out why eslint warns about creating functions...
					// eslint-disable-next-line
					(collision) => {
						const startsAfter =
							toDate(collision.start) >= currentStart &&
							toDate(collision.start) < moment(currentStart).endOf("day").toDate();
						const startsBefore =
							toDate(collision.start) < currentStart && toDate(collision.end) > currentStart;

						// does the reader have a preffered studio at current collision?
						if (!startsBefore && readersTime && readersTime.length && bookStudio === "homeStudio") {
							const time = readersTime.find(
								(time) =>
									toDate(time.in) <= toDate(collision.start) &&
									toDate(time.out) > toDate(collision.start),
							);
							if (time && time.studio) {
								bookStudioGroup = time.studio;
							} else {
								console.log("ATTENTION: ", bookStudioGroup);
								// when does this happen??? should be this.readerStudio(bookReader)[1] instead if .studio
								bookStudioGroup = this.readerStudio(bookReader).studio;
							}

							// convert to array for historic reasons
							if (typeof bookStudioGroup === "string") bookStudioGroup = [bookStudioGroup];
						}

						if (bookStudio === "homeStudio" && !bookStudioGroup && this.readerStudio(bookReader)) {
							// use readers "default" home studio
							bookStudioGroup = this.readerStudio(bookReader)[1];
						} else if (bookStudio && isNaN(bookStudio) && bookStudio !== "homeStudio") {
							// group specifically selected for booking
							bookStudioGroup = [bookStudio];
						}

						return (
							// skip cancelled or done sessions
							collision.status === "booked" &&
							// must be after current, and same day
							(startsAfter || startsBefore) &&
							// now check for same studio, reader or recorder
							((bookStudioGroup
								? !skipGroup.includes(collision.id) &&
								  !startsBefore &&
								  this.isStudioInGroup(collision.studio, bookStudioGroup)
								: collision.studio === bookStudio) ||
								(bookRecorder && collision.recorder === bookRecorder) ||
								(bookReader && intersection(collision.reader, bookReader).length))
						);
					},
				);

				let collision;
				if (availabilityCollision && sessionCollision) {
					// both, pick the one that comes sooner
					collision =
						toDate(availabilityCollision.start) < toDate(sessionCollision.start)
							? availabilityCollision
							: sessionCollision;
				} else {
					// pick either, if any
					collision = availabilityCollision || sessionCollision;
				}

				let isGroupCollision =
					collision &&
					collision.id &&
					bookStudioGroup &&
					sessionCollision &&
					this.isStudioInGroup(sessionCollision.studio, bookStudioGroup);

				if (collision && toDate(collision.start) <= currentStart && toDate(collision.end) > currentStart) {
					// already passed the start of the collision
					// just move the currentStart ahead
					currentStart = toDate(collision.end);
					continue;
				}

				// check if studio group is full
				if (isGroupCollision) {
					const groupStudios = this.getGroupStudios(bookStudioGroup);

					// find overlapping sessions in same group
					// eslint-disable-next-line
					const overlapping = sessions.filter((groupSession) => {
						return (
							groupSession.status === "booked" &&
							toDate(groupSession.start) >= toDate(collision.start) &&
							toDate(groupSession.start) < toDate(collision.end) &&
							this.isStudioInGroup(groupSession.studio, bookStudioGroup)
						);
					});

					if (!skipGroup.includes(collision.id) && overlapping.length < groupStudios.length) {
						// more studios available, just keep going
						skipGroup.push(collision.id); //but don't check same collision again
						continue;
					} else if (overlapping.length) {
						// group is full, find overlap to mark as unavailable
						const startLast = [...overlapping].sort((a, b) =>
							toDate(a.start) < toDate(b.start) ? 1 : -1,
						)[0];
						const endFirst = [...overlapping].sort((a, b) => (toDate(a.end) > toDate(b.end) ? 1 : -1))[0];

						// // only create collision if it has a duration
						// if (toDate(startLast.start) < toDate(endFirst.end))
						collision = {
							...collision,
							start: startLast.start,
							end: endFirst.end,
							apa: 666,
						};
					}
				}

				// reset skip flag (doesn't seem necessary since changing to array?)
				skipGroup = [];

				// stop overlay at next collision or end of day
				let currentEnd;
				if (collision) {
					currentEnd = toDate(collision.start);
				} else {
					currentEnd = moment(currentStart).endOf("day").toDate();
				}

				// create the overlay
				if (currentEnd > currentStart) {
					const newOverlay = {
						overlay: "bookable-overlay",
						start: currentStart,
						end: currentEnd,
						title: "Ledig tid",
					};
					overlays.push(newOverlay);
				}

				// next overlay start
				currentStart = collision
					? // current collisions end
					  toDate(collision.end)
					: // or beginning of tomorrow
					  moment(currentStart).add(1, "day").startOf("day").toDate();
			}

			// merge with actual sessions
			calendarEvents = [...calendarEvents, ...overlays];
		} else if (editingAvailability) {
			// create availability overlays
			const availabilityUser = this.userMap[editingAvailability.uid];

			const spans = get(availabilityUser, "availability[" + editingAvailability.spanType + "]") || [];

			const overlays = spans
				.filter((span) => toDate(span.in) < calendarEnd && toDate(span.out) > calendarStart)
				.map((span) => ({
					overlay: editingAvailability.spanType === "timeOff" ? "timeoff-overlay" : "reader-bookable-overlay",
					start: toDate(span.in),
					end: toDate(span.out),
					title:
						editingAvailability.spanType === "timeOff"
							? t("notAvailableForBooking")
							: t("availableToBook") +
							  (span.studio
									? " i " +
									  (typeof span.studio === "string" ? [span.studio] : span.studio)
											.map((studio) =>
												isNaN(studio) ? studio : get(producer, "studios[" + studio + "].name"),
											)
											.join(", ")
									: ""),
				}));

			// merge with actual sessions
			calendarEvents = [...calendarEvents, ...overlays];
		}

		// get selected session data
		const selectedSessionData = selectedSession && sessions.find((haystack) => selectedSession === haystack.id);

		const filterIsActive = !(
			filter &&
			Object.keys(filter).length === 1 &&
			filter.status &&
			filter.status.length === 1 &&
			filter.status[0] === "cancelled"
		);

		const studiosInViewIndexes =
			columnMode &&
			view !== "month" &&
			producer?.studios &&
			calendarEvents
				.reduce((prev, curr) => {
					const studioId = curr.studio;

					// set studioID to index for column styling
					if (studioId && !prev.includes(studioId)) {
						prev.push(studioId);
					}

					return prev;
				}, [])
				.sort((a, b) =>
					producer.studios[a]?.name.toLowerCase() > producer.studios[b]?.name.toLowerCase() ? 1 : -1,
				);

		// add holidays?
		if (holidays?.length) {
			calendarEvents = [...calendarEvents, ...holidays];
		}

		return (
			<div
				onClick={(e) => {
					// deselect current session on calendar background click
					if (e.target.classList.contains("rbc-time-slot")) this.setState({ selectedSession: null });
				}}
				className={columnMode ? "columnmode" : ""}
			>
				<DocTitle title={t("calendar")} />

				<div>
					<Right can="additionalFeaturesCalendar">
						<Loader
							active={this.state.loadingSessions || this.state.loadingProductions}
							inline
							size="small"
						/>
						<Label
							color={filterIsActive ? "teal" : null}
							as="a"
							size="large"
							onClick={() => {
								this.setState({
									modal: "filter",
								});
							}}
							style={{
								marginBottom: "1em",
							}}
						>
							<Icon name="sliders" />
							{t("filter")}
							{filterIsActive && (
								<Icon
									name="delete"
									onClick={(e) => {
										e.stopPropagation();
										this.setFilter();
									}}
								/>
							)}
						</Label>
						{view !== "month" && (
							<Label
								color={columnMode ? "teal" : null}
								as="a"
								size="large"
								onClick={() => {
									this.setState({
										columnMode: !columnMode,
									});
									window.localStorage.setItem(
										"cal",
										JSON.stringify({ filter, columnMode: !columnMode }),
									);
								}}
								style={{
									marginBottom: "1em",
								}}
							>
								<Icon name="columns" />
								{t("columnMode")}
							</Label>
						)}

						<NotificationButton
							affectedStaff={this.state.affectedStaff}
							onClick={() => {
								this.setState({
									modal: "notify",
								});
							}}
						/>
					</Right>
					{view !== "month" && (
						<>
							<Label
								color={this.state.editingAvailability ? "orange" : null}
								as="a"
								size="large"
								onClick={() => {
									this.setState({
										modal: "availability",
									});
								}}
								style={{
									marginBottom: "1em",
								}}
							>
								<Icon name="calendar times" />
								{t("availability")}
								{editingAvailability &&
									editingAvailability.uid !== user.uid &&
									": " +
										this.userMap[editingAvailability.uid].firstName +
										" " +
										this.userMap[editingAvailability.uid].lastName}
								{editingAvailability && (
									<Icon
										name="delete"
										onClick={(e) => {
											e.stopPropagation();
											this.setState({ editingAvailability: null });
										}}
									/>
								)}
							</Label>

							<Right can="bookSession">
								<div
									style={{
										float: "right",
										marginBottom: "1em",
									}}
								>
									{bookingMissingScript && (
										<span>
											<Icon name="file alternate outline" />
											{t("script")}{" "}
											{bookingMissingScriptDate
												? moment(bookingMissingScriptDate).format("YYYY-MM-DD")
												: t("isMissing")?.toLowerCase()}{" "}
											&nbsp;
										</span>
									)}

									{/*
									<Label
										color={bookingActive ? "pink" : "blue"}
										as="a"
										size="large"
										onClick={() => {
											this.setState({ modal: "book" });
										}}
									>
										<Icon name="calendar plus" />
										{t("book")}
										{bookingActive && (
											<Icon
												name="delete"
												onClick={(e) => {
													e.stopPropagation();
													this.resetBooking();
												}}
											/>
										)}
									</Label>
*/}
									<BookSession
										bookingActive={bookingActive}
										setState={(data) => this.setState(data)}
										state={this.state}
									/>
								</div>
							</Right>
						</>
					)}
				</div>

				<BigCalendar
					popup
					selectable={!!(bookingActive || editingAvailability)}
					culture={i18n.language === "en" ? "en-gb" : i18n.language || "sv"}
					localizer={localizer}
					events={calendarEvents}
					defaultView={view}
					views={allViews}
					messages={{
						previous: t("previous"),
						next: t("later"),
						today: t("today"),
						month: t("month"),
						week: t("week"),
						day: t("day"),
						date: t("date"),
						allDay: t("allDay"),
						time: t("time"),
						event: t("event"),
					}}
					step={15}
					timeslots={4}
					showMultiDayTimes={true}
					scrollToTime={new Date("2018-01-01T07:30")}
					defaultDate={this.state.currentDate}
					formats={{
						dayFormat: "YYYY-MM-DD",
						dayHeaderFormat: "dddd D MMMM",
						dayRangeHeaderFormat: ({ start, end }) => {
							const startMonth = moment(start).format("MMMM");
							const endMonth = moment(end).format("MMMM");

							let disp =
								moment(start).format("D") +
								(startMonth !== endMonth ? " " + startMonth : "") +
								" – " +
								moment(end).format("D") +
								" " +
								endMonth;

							if (this.state.view === "week" && i18n)
								disp += " (" + t("weekAbbr") + moment(start).format("w") + ")";

							return disp;
						},
					}}
					eventPropGetter={(event) => {
						const classes = [];
						const style = {};

						if (event.overlay) {
							// not an actual session
							classes.push("overlay-session");
							classes.push(event.overlay);
						} else {
							// actual session
							if (studiosInViewIndexes) {
								classes.push("studio-index-" + studiosInViewIndexes.indexOf(event.studio));
							}

							// selected?
							if (selectedSession && event.id === selectedSession) classes.push("selected-session");

							// me?
							if (event.personal) classes.push("personal-session");

							// recorder booked?
							if (event.recorderless) classes.push("recorderless-session");

							// recorder booked?
							if (event.overlapping) classes.push("overlapping-session");

							// holiday
							if (event.holiday) classes.push("holiday");

							// figure out some colors
							const studioIndex =
								this.state?.producer?.studios &&
								Object.keys(this.state.producer.studios).indexOf(event.studio);

							style.backgroundColor =
								this.state.producer?.studios?.[event.studio]?.color ||
								colors[studioIndex] ||
								event.studioData?.color;
						}

						return { className: classes.join(" "), style };
					}}
					onSelecting={(a, b, c) => {
						this.isDragging = true;
					}}
					onSelectEvent={(session) => {
						if (this.isDragging) {
							// not an actual click
							this.isDragging = false;
						} else if (session.status === "booked" || session.status === "done") {
							this.setState({
								// prevent closing if already open. just change session.
								preventModalClose: !!this.state.modal,
								selectedSession: session.id,
								modal: "edit",
							});
						} else if (session.status === "cancelled") {
							window.alert("Avbokat pass");
						}
					}}
					onSelectSlot={bookingActive ? this.createSession : this.setAvailability}
					onView={(view) => {
						this.setState({ view, columnMode: view === "day" });
					}}
					onNavigate={(newDate) => {
						this.setState({ currentDate: newDate });
					}}
				/>

				{studiosInViewIndexes && (
					<style>
						{studiosInViewIndexes.map((studio, i) => {
							const part = 100 / studiosInViewIndexes.length;
							const left = i * part;
							const width = part;

							return (
								".studio-index-" +
								i +
								"{left: " +
								left +
								"%!important; width: " +
								width +
								"%!important}"
							);
						})}
					</style>
				)}

				<Right can="additionalFeaturesCalendar">
					<BookingStats sessions={sessions} filter={filter} productionMap={productionMap} />
				</Right>

				{this.state.modal && (
					<Modal size="small" open={true} onClose={this.modalClose} closeOnDocumentClick>
						{/*{this.state.modal === "book" && (
							<>
								<Modal.Header>{t("bookRecSession")}</Modal.Header>
								<Modal.Content>
									<Form loading={loadingData}>
										<Form.Group widths="equal">
											<Form.Select
												fluid
												search
												deburr
												multiple
												loading={this.state.loadingUsers}
												error={this.state.readerError}
												label={t("readerStudio")}
												name="reader"
												onChange={(e, data) => {
													const newData = { bookReader: data.value };

													// check if reader has homeStudio
													if (this.readerStudio(data.value)) {
														newData.bookStudio = "homeStudio";
													}
													console.log(newData);

													this.setState(newData);
												}}
												value={bookReader || []}
												options={users
													.filter(
														(user) =>
															user.permissions &&
															user.permissions.reader &&
															(!bookProduction || // all readers, or readers book on selected production
																(productionMap &&
																	productionMap[bookProduction] &&
																	productionMap[bookProduction].reader &&
																	productionMap[bookProduction].reader.includes(
																		user.id,
																	))),
													)
													.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
													.map((user, i) => {
														return {
															key: user.id,
															text: user.firstName + " " + user.lastName,
															value: user.id,
														};
													})}
											/>
											<Form.Select
												fluid
												search
												deburr
												label={t("bookStudio")}
												name="studio"
												error={this.state.studioError}
												onChange={(e, data) => {
													this.setState({
														bookStudio: data.value,
													});
												}}
												value={bookStudio}
												options={[
													...(bookReader && this.readerStudio(bookReader)
														? [
																{
																	key: "homeStudio",
																	value: "homeStudio",
																	text: t("readerRequest"),
																},
														  ]
														: []),
													...this.getStudioGroupTags().map((group) => ({
														key: group,
														value: group,
														text: (
															<span>
																<Icon name="cubes" /> {group}
															</span>
														),
													})),
													...(producer && producer.studios
														? Object.keys(producer.studios)
																.filter((key) => producer.studios[key].active)
																.sort((a, b) =>
																	producer.studios[a].name.toLowerCase() >
																	producer.studios[b].name.toLowerCase()
																		? 1
																		: -1,
																)
																.map((key, i) => {
																	const studio = producer.studios[key];
																	const color =
																		studio.color ||
																		colors[
																			Object.keys(producer.studios).indexOf(key)
																		];

																	return {
																		key: i,
																		value: key,
																		text: (
																			<span>
																				<Icon name="cube" style={{ color }} />{" "}
																				{studio.name}
																			</span>
																		),
																	};
																})
														: []),
												]}
											/>
										</Form.Group>
										<Form.Group widths="equal">
											<Form.Select
												search
												deburr
												fluid
												label={t("production")}
												name="production"
												disabled={
													// non admin recorders can't book
													(prodPermissions.includes("producerRecorder") ||
														(profile.permissions.reader &&
															profile.readerData &&
															profile.readerData.selfRecorder)) &&
													!prodPermissions.includes("producerAdmin") &&
													!prodPermissions.includes("producerStaff")
												}
												onChange={(e, data) => {
													const newState = {
														bookProduction: data.value,
														bookReader: data.value
															? (productionMap[data.value].reader || []).filter(
																	(reader) =>
																		// avoid "ghost" readers
																		!reader.includes(" ") && reader.length > 20,
															  )
															: bookReader,
													};

													// check if reader has homeStudio
													if (this.readerStudio(newState.bookReader)) {
														newState.bookStudio = "homeStudio";
													}

													newState.bookPrio =
														productionMap[data.value] &&
														productionMap[data.value].recorderPrio;

													this.setState(newState);
												}}
												value={bookProduction}
												options={[
													{
														key: 0,
														text: t("noProdBooked"),
														value: null,
													},
													...productions
														.filter(
															(production) =>
																!["offer", "done", "draft"].includes(
																	production.status,
																) &&
																(!bookReader || // all productions, or only productions with selected reader(s)
																	bookReader.length ===
																		intersection(bookReader, production.reader)
																			.length),
														)
														.sort((a, b) =>
															toDate(a.productionDate) > toDate(b.productionDate)
																? 1
																: -1,
														)
														.map((production, i) => {
															const bookedHours = bookedStudioHours(production);
															const estHours = estimateStudioHours(production);

															return {
																key: i + 1,
																text:
																	production.title +
																	(estHours
																		? ` (${
																				bookedHours ? bookedHours + "/" : ""
																		  }${estHours}h)`
																		: ""),
																value: production.id,
															};
														}),
												]}
											/>
											<Form.Select
												fluid
												search
												deburr
												loading={this.state.loadingUsers}
												label={t("technician")}
												name="recorder"
												onChange={(e, data) => {
													this.setState({ bookRecorder: data.value });
												}}
												disabled={
													// non admin recorders can't book
													(prodPermissions.includes("producerRecorder") ||
														(profile.permissions.reader &&
															profile.readerData &&
															profile.readerData.selfRecorder)) &&
													!prodPermissions.includes("producerAdmin") &&
													!prodPermissions.includes("producerStaff")
												}
												value={bookRecorder}
												options={[
													{
														key: 0,
														text: t("noTechnician"),
														value: null,
													},
													...users
														.filter((user) => {
															const prod = productionMap[bookProduction];

															const defaultPrice =
																user?.prices?.[prod?.producer]?.[
																	`${prod.language}-recorder`
																];

															return (
																(!prod || defaultPrice) &&
																((user.permissions &&
																	user.permissions.producer &&
																	get(
																		user,
																		"permissions.producer." +
																			this.props.store.state.producerId,
																		[],
																	).includes("producerRecorder") &&
																	(!bookPrio ||
																		get(
																			user,
																			"permissions.producer." +
																				this.props.store.state.producerId,
																			[],
																		).includes("producerRecorderPrio")) &&
																	(!prod ||
																		!prod.language ||
																		!user.languages ||
																		!Object.keys(user.languages).length ||
																		user.languages[prod.language])) ||
																	(user.permissions &&
																		user.permissions.reader &&
																		user.readerData &&
																		user.readerData.selfRecorder &&
																		prod &&
																		prod.reader &&
																		prod.reader.includes(user.id)))
															);
														})
														.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
														.map((user, i) => {
															return {
																key: user.id,
																text: user.firstName + " " + user.lastName,
																value: user.id,
															};
														}),
												]}
											/>
										</Form.Group>
										<Form.Group inline>
											<Form.Checkbox
												label={t("polishSession")}
												name="polishSession"
												checked={bookPolishSession}
												onChange={(e, data) => {
													this.setState({ bookPolishSession: data.checked });
												}}
											/>

											<div style={{ clear: "both" }} />
										</Form.Group>
									</Form>
								</Modal.Content>
								<Modal.Actions>
									<Button
										onClick={() => {
											this.resetBooking();
											this.modalClose();
										}}
									>
										{t("resetCalendarBtn")}
									</Button>
									<Button
										positive
										icon="checkmark"
										labelPosition="right"
										content={t("startBooking")}
										onClick={() => {
											const reader = bookReader;
											const studio = bookStudio;

											let error = false;

											if (!reader) {
												this.setState({ readerError: true });
												error = true;
											} else {
												this.setState({ readerError: false });
											}
											if (!studio) {
												this.setState({ studioError: true });
												error = true;
											} else {
												this.setState({ studioError: false });
											}

											if (!error) this.modalClose();
										}}
									/>
								</Modal.Actions>
							</>
						)}*/}

						{this.state.modal === "edit" && selectedSessionData && (
							<OpenSessionModal
								session={selectedSessionData}
								onClose={() => this.modalClose()}
								notificationCallbacks={{
									addAffectedStaff: this.addAffectedStaff,
									addNotification: this.addNotification,
								}}
							/>
							/*	<SessionEdit
									session={selectedSessionData}
									studios={producer.studios}
									userMap={this.userMap}
									onCancel={(e) => {
										this.setState({ preventModalClose: false, modal: "cancel" });
									}}
									onDone={this.modalClose}
									addAffectedStaff={this.addAffectedStaff}
									addNotification={this.addNotification}
									profile={profile}
									producer={producer}
									user={user}
									store={store}
								/>*/
						)}

						{this.state.modal === "filter" && (
							<>
								<Modal.Header>{t("filterCalendar")}</Modal.Header>
								<Modal.Content className="calendar-filter">
									<p>
										{[
											{ value: "self", text: t("yourRecs") },
											{ value: "other", text: t("othersRecs") },
											{
												value: "noTechnicianBooked",
												text: t("noTechnicianBooked", "No engineer booked"),
											},
										].map((check) => {
											const checked = !!(
												!this.state.filter ||
												!this.state.filter.owner ||
												(this.state.filter.owner &&
													!this.state.filter.owner.includes(check.value))
											);

											return (
												<Label
													key={check.value}
													as="a"
													name="owner"
													value={check.value}
													checked={checked}
													className={checked ? "" : "calendar-tag-filtered"}
													onClick={this.setFilter}
												>
													<Icon name="user" /> {check.text}
												</Label>
											);
										})}
									</p>
									<p>
										{[
											{
												id: "booked",
												name: t("booked"),
												icon: "time",
											},
											{
												id: "done",
												name: t("completed"),
												icon: "check",
											},
											{
												id: "cancelled",
												name: t("cancelled"),
												icon: "cancel",
											},
										].map((status) => {
											const checked = !!(
												!this.state.filter ||
												!this.state.filter.status ||
												(this.state.filter.status &&
													!this.state.filter.status.includes(status.id))
											);

											return (
												<Label
													key={status.id}
													as="a"
													name="status"
													value={status.id}
													checked={checked}
													className={checked ? "" : "calendar-tag-filtered"}
													onClick={this.setFilter}
												>
													<Icon name={status.icon} /> {status.name}
												</Label>
											);
										})}
									</p>

									{producer &&
										producer.studios &&
										Object.values(producer.studios).find((studio) => studio.groupTags) && (
											<p>
												<b
													style={{
														display: "block",
														fontSize: ".92857143em",
														marginBottom: ".2rem",
													}}
												>
													{t("studioGroup")}
												</b>
												{this.getStudioGroupTags().map((group) => (
													<Label
														as="a"
														name="group"
														key={group}
														value={group}
														className={
															this.state.filter?.group !== group
																? "calendar-tag-filtered"
																: ""
														}
														onClick={(e, data) => {
															this.setFilter(e, data);
														}}
													>
														<Icon name="cubes" /> {group}
													</Label>
												))}
											</p>
										)}
									<p>
										<span
											style={{ display: "block", fontSize: ".92857143em", marginBottom: ".2rem" }}
										>
											<b>{t("bookStudio")}</b>
											&nbsp;
											<small>{t("doubleClickToChoose")}</small>
										</span>

										{producer &&
											producer.studios &&
											Object.keys(producer.studios)
												.sort((a, b) => {
													return producer.studios[a].name.toLowerCase() >
														producer.studios[b].name.toLowerCase()
														? 1
														: -1;
												})
												.filter((id) => producer.studios[id].active)
												.map((id) => {
													const studio = producer.studios[id];

													const color =
														producer &&
														(producer.studios[id].color ||
															colors[Object.keys(producer.studios).indexOf(id)]);

													const checked = !!(
														!this.state.filter ||
														!this.state.filter.studio ||
														(this.state.filter.studio &&
															!this.state.filter.studio.includes(id))
													);

													return (
														<Label
															key={id}
															as="a"
															style={{
																background: color,
																userSelect: "none",
															}}
															name="studio"
															value={id}
															checked={checked}
															className={checked ? "" : "calendar-tag-filtered"}
															onClick={this.setFilter}
															onDoubleClick={(e, data) => {
																const otherStudios = Object.keys(
																	producer.studios,
																).filter(
																	(fid) => producer.studios[id].active && fid !== id,
																);

																this.setFilter(null, {
																	name: "studio",
																	value: otherStudios,
																});
															}}
														>
															<Icon name="cube" /> {studio.name}
														</Label>
													);
												})}
									</p>

									<Form>
										<Form.Group widths="equal">
											<Form.Select
												search
												deburr
												fluid
												label={t("production")}
												name="production"
												multiple
												onChange={this.setFilter}
												value={this.state.filter.production || []}
												options={productions.map((production, i) => {
													return {
														key: i,
														text: production.title,
														value: production.id,
													};
												})}
											/>
											<Form.Select
												fluid
												search
												deburr
												multiple
												loading={this.state.loadingUsers}
												label={t("readerStudio")}
												name="reader"
												onChange={this.setFilter}
												value={this.state.filter.reader || []}
												options={users
													.filter((user) => user.permissions && user.permissions.reader)
													.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
													.map((user, i) => {
														return {
															key: user.id,
															text: user.firstName + " " + user.lastName,
															value: user.id,
														};
													})}
											/>
											<Form.Select
												fluid
												search
												deburr
												multiple
												loading={this.state.loadingUsers}
												label={t("technician")}
												name="recorder"
												onChange={this.setFilter}
												value={this.state.filter.recorder || []}
												options={[
													{ key: "none", value: "none", text: "Tekniker saknas" },
													...users
														.filter(
															(user) =>
																user.permissions?.producer &&
																get(
																	user,
																	"permissions.producer." +
																		this.props.store.state.producerId,
																	[],
																).includes("producerRecorder"),
														)
														.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
														.map((user, i) => {
															return {
																key: user.id,
																text: user.firstName + " " + user.lastName,
																value: user.id,
															};
														}),
												]}
											/>
											<Form.Select
												fluid
												search
												deburr
												multiple
												loading={this.state.loadingUsers}
												label={t("prodManager")}
												name="manager"
												onChange={this.setFilter}
												value={this.state.filter.manager || []}
												options={[
													{ key: "none", value: "none", text: t("prodManagerIsMissing") },
													...users
														.filter(
															(user) =>
																user.permissions?.producer &&
																(get(
																	user,
																	"permissions.producer." +
																		this.props.store.state.producerId,
																	[],
																).includes("producerAdmin") ||
																	get(
																		user,
																		"permissions.producer." +
																			this.props.store.state.producerId,
																		[],
																	).includes("producerStaff")),
														)
														.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
														.map((user, i) => {
															return {
																key: user.id,
																text: user.firstName + " " + user.lastName,
																value: user.id,
															};
														}),
												]}
											/>
										</Form.Group>
									</Form>
								</Modal.Content>

								<Modal.Actions>
									<Button
										onClick={() => {
											this.setFilter();
										}}
									>
										{t("reset")}
									</Button>
									<Button
										positive
										icon="checkmark"
										labelPosition="right"
										content={t("ready")}
										onClick={this.modalClose}
									/>
								</Modal.Actions>
							</>
						)}

						{this.state.producer && this.state.modal === "notify" && (
							<NotificationModal
								affectedStaff={this.state.affectedStaff}
								notifications={this.state.notifications}
								userMap={this.userMap}
								modalClose={this.modalClose}
								sessions={this.state.sessions}
								productionMap={productionMap}
								studios={this.state.producer.studios}
								resetNotifications={() => {
									this.setState({ affectedStaff: [], notifications: {} });
								}}
							/>
						)}

						{this.state.modal === "availability" && (
							<AvailabilityModal
								store={store}
								modalClose={this.modalClose}
								isEditing={get(editingAvailability, "uid")}
								producer={producer}
								editAvailability={(uid, spanType) => {
									this.setState({
										editingAvailability: uid
											? {
													uid,
													spanType,
											  }
											: null,
									});
								}}
							/>
						)}
					</Modal>
				)}
			</div>
		);
	}
}

export default withTranslation()(withStore(Calendar));
