import api from "../../../../api";
import FlexTable from "../../../ui/components/FlexTable/FlexTable";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useArticleDistributionChannelsTableColumns from "../../hooks/useArticleDistributionChannelsTableColumns";

export default function ArticleDistributionChannelsCollectionFlexTable({ article, organization, ...props }) {
	const [data, loading, error] = api.distributionChannels.useAllByIds(
		organization.distributionChannelIds || ["JUGGP9qBpHy9JuoSBdmL", "6a0xFok7w2Ti3N7oED2X"],
	);

	const columns = useArticleDistributionChannelsTableColumns(article);

	return (
		<LoadingContext data={data} loading={loading} error={error}>
			<FlexTable
				data={data}
				columns={columns}
				getRowDisabled={({ acceptedLanguages }) => {
					return acceptedLanguages?.length > 0 && !acceptedLanguages.includes(article.language);
				}}
				{...props}
			/>
		</LoadingContext>
	);
}
