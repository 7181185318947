import { useEffect, useRef } from "react";

export default function useResetFields(form, fieldMap) {
	const fieldMapRef = useRef(fieldMap);

	fieldMapRef.current = fieldMap;

	const { watch, resetField } = form;

	useEffect(() => {
		if (fieldMapRef.current) {
			const { unsubscribe } = watch((data, { name, type }) => {
				if (type === "change") {
					for (const [field, fields] of Object.entries(fieldMapRef.current)) {
						if (fields.includes(name)) {
							resetField(field);
						}
					}
				}
			});

			return unsubscribe;
		}
	}, [resetField, watch]);
}
