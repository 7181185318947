import deburr from "lodash/deburr";
import get from "lodash/get";
import intersection from "lodash/intersection";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, Form, Icon, Segment } from "semantic-ui-react";

import languages from "astrid-config/src/languages";
import { base, db, firebase } from "astrid-firebase";
import getCollectionData from "astrid-firestore/src/helpers/getCollectionData";
import DocTitle from "astrid-web/src/components/DocTitle";
import SettingsPopup from "astrid-web/src/components/SettingsPopup";
import StickyAudio from "astrid-web/src/components/StickyAudio";

import { withStore } from "../../helpers/context";
import { localStorage } from "../../helpers/fnc";

import Pagination from "../Pagination";

import ReaderCard from "./ReaderCard";

class ListReaders extends Component {
	state = {
		filter: localStorage("readers", "filter") || {},
		sorting: localStorage("readers", "sorting") || "name",
		page: 0,
		pins: localStorage("readers", "pins") || [],
		publisherSettings: {},
		nonSearchable: false,
		production: {},
	};

	UNSAFE_componentWillMount() {
		// get discounts from producer
		base.get("organizations/" + this.props.store.state.producerId + "/secrets/billing").then((data) => {
			if (data.publisherSettings) {
				Object.keys(data.publisherSettings).forEach((pub) => {
					if (get(this, "props.store.state.profile.permissions.publisher." + pub)) {
						this.setState({
							publisherSettings: { ...this.state.publisherSettings, [pub]: data.publisherSettings[pub] },
						});
					}
				});
			}
		});

		getCollectionData(db.collection("exchangeRates").orderBy("timestamp", "desc").limit(1)).then(
			(exchangeRates) => {
				this.setState({ exchangeRate: exchangeRates[0] });
			},
		);

		this.props.store.getUsers();

		// only show pinned when opened from reader card
		if (this.props.onlyPinned) this.setState({ onlyPinned: true });

		this.setDefaultLanguageFilter();
	}

	getNativeLanguages(languages) {
		return Object.entries(languages || {}).reduce((result, [lang, { skill }]) => {
			const parsedCode = typeof code === "string" ? parseInt(skill) : skill;
			return parsedCode === 5 ? result.concat(lang) : result;
		}, []);
	}

	setDefaultLanguageFilter = () => {
		const profile = this.props.store.state.profile;
		const defaultLanguages = this.getNativeLanguages(profile.languages);

		this.setState((state) => ({
			filter: {
				...state.filter,
				language: [...new Set([...(state.filter?.language || []), ...defaultLanguages])],
			},
		}));
	};

	setFilter = (e, data) => {
		const filter = { ...this.state.filter };

		if (data.as === "button") {
			switch (data.name) {
				case "male":
					filter.female = false;
					filter.other = false;
					break;
				case "female":
					filter.male = false;
					filter.other = false;
					break;
				case "other":
					filter.male = false;
					filter.female = false;
					break;
				default:
					break;
			}
			filter[data.name] = !filter[data.name];
		} else {
			filter[data.name] = data.value;
		}

		this.setState({ filter, page: 0 });
		localStorage("readers", "filter", filter);
	};

	readerFeeToEuro = (readers) => {
		const { exchangeRate } = this.state;

		return readers.map((reader) => {
			const currency = reader.readerData?.currency || "SEK";
			const cost = reader.readerData?.cost || 0;

			return {
				...reader,
				readerData: {
					...reader.readerData,
					costEuro: cost / exchangeRate?.rates[currency],
				},
			};
		});
	};

	filterReaders = (readers) => {
		const filter = this.state.filter;

		const filtered = this.readerFeeToEuro(readers).filter((reader) => {
			const data = reader.readerData;
			const languages = reader.languages || data?.language || {};

			let langSpoken = [];
			let langSkills = [];

			if (filter.language?.length && data && languages) {
				langSpoken = filter.language.filter((code) => +languages[code]?.skill === 5 || +languages[code] === 5);
			}

			if (filter.otherLanguages?.length && data && languages) {
				langSkills = filter.otherLanguages.filter(
					(code) => +languages[code]?.skill < 5 || +languages[code] < 5,
				);
			}

			return (
				this.state.pins.includes(reader.id) ||
				((!filter.name ||
					deburr((reader.firstName + " " + reader.lastName).toLowerCase()).includes(
						deburr(filter.name.toLowerCase()),
					)) &&
					data &&
					(!filter.female || data.sex === "female") &&
					(!filter.male || data.sex === "male") &&
					(!filter.other || data.sex === "other") &&
					(!filter.referrer || reader.referrer === filter.referrer) &&
					(!filter.agefrom ||
						(data.birthday &&
							data.birthday.toDate &&
							data.birthday.toDate() <= moment().subtract(filter.agefrom, "years").toDate())) &&
					(!filter.ageto ||
						(data.birthday &&
							data.birthday.toDate &&
							data.birthday.toDate() >=
								moment()
									.subtract(Math.abs(filter.ageto) + 1, "years")
									.toDate())) &&
					(!filter?.cost?.length ||
						filter.cost.find(
							(cost) =>
								data.costEuro &&
								((data.costEuro >= cost && data.costEuro < cost + 50) ||
									(cost === 350 && data.costEuro >= 350)),
						)) &&
					(!filter.voice ||
						!filter.voice.length ||
						(data.voice && filter.voice.includes(Math.abs(data.voice)))) &&
					(!filter.language || !filter.language.length || langSpoken.length === filter.language.length) &&
					(!filter.otherLanguages ||
						!filter.otherLanguages.length ||
						langSkills.length === filter.otherLanguages.length))
			);
		});

		return filtered;
	};

	getRecommendations = () => {
		this.setState({
			loadingProduction: true,
			sorting: "magic",
			filter: {},
		});
		this.setState({
			filter: {
				language: this.props.production.language ? [this.props.production.language] : null,
			},
		});

		if (this.props.production.author) {
			// get other readers that has read the other and realted authors
			const authorReaders = firebase.functions().httpsCallable("authorReaders");
			const relatedReaders = firebase.functions().httpsCallable("relatedReaders");

			authorReaders({ author: this.props.production.author }).then((result) => {
				this.setState({ authorReaders: result.data });

				relatedReaders({
					author: this.props.production.author,
					genre: this.props.production.genre,
					reader: result.data,
				}).then((result2) => {
					this.setState({ relatedReaders: result2.data, loadingProduction: false });
				});
			});
		}
	};

	render() {
		const {
			page,
			filter,
			sorting,
			publisherSettings,
			nonSearchable,
			onlyPinned,
			authorReaders,
			relatedReaders,
			loadingProduction,
		} = this.state;
		const { t, store, production, productionId, onClose, onChange, readerIds } = this.props;
		const { profile, users, producerId } = store.state;
		const pins =
			(productionId
				? [...(production.readerSuggestion || []), ...(production.reader || [])]
				: this.state.pins
			).filter((pin) => !readerIds || readerIds.includes(pin)) || [];

		const allReaders = Object.values(users || []).filter(
			(user) =>
				user.permissions.reader &&
				(nonSearchable || (user.readerData && user.readerData.searchable)) &&
				(!onlyPinned || !pins.length || pins.includes(user.id)),
		);
		let readers = allReaders;

		if (Object.keys(filter).length) {
			readers = this.filterReaders(readers);
		}

		if (pins.length || production) {
			// add pin status and sample update for sorting
			readers = readers.map((reader) => {
				let magic = 0;
				const extra = {
					...reader,
					sampleUpdate: reader.readerData.samples
						? // negative id for sorting reasons
						  -Object.keys(reader.readerData.samples).reduce((prev, curr) => (prev > curr ? prev : curr), 0)
						: 0,
					created: -(reader.created ? reader.created.seconds : 0), // invert date for sorting reasons
					magicHits: [],
				};

				// figure out some production based points and info
				if (production && authorReaders) {
					if (
						production.language &&
						(get(reader, "readerData.language." + production.language) > 4 ||
							get(reader, "languages." + production.language + ".skill") > 4)
					) {
						// extra.magicHits.push("Modersmål");
						magic -= 5;
					}

					if (production.genre && get(reader, "readerData.userData.genre")) {
						const overlap = intersection(production.genre, get(reader, "readerData.userData.genre"));
						if (overlap.length) {
							extra.magicHits.push(t("genre"));
							magic -= overlap.length * 5;
						}
					}

					if (authorReaders && authorReaders.includes(reader.id)) {
						extra.magicHits.push(t("author"));
						magic -= 20;
					}

					if (relatedReaders && relatedReaders.includes(reader.id)) {
						extra.magicHits.push(t("related"));
						magic -= 10;
					}

					if (production.languageExtra && (get(reader, "readerData.language") || get(reader, "languages"))) {
						const overlap = intersection(
							production.languageExtra,
							Object.keys(get(reader, "languages") || get(reader, "readerData.language") || {}),
						);
						if (overlap.length) {
							extra.magicHits.push(t("language"));
							magic -= overlap.length * 3;
						}
					}

					extra.magic = magic;
				}

				return extra;
			});
		}

		let sortKey = sorting;
		if (sorting === "lastSample") sortKey = "sampleUpdate";
		else if (sorting === "name") sortKey = "firstName";
		else if (sorting === "magic") sortKey = "magic";

		readers = sortBy(readers, ["readerData.curated", sortKey, "lastName"]);

		// paging
		const perPage = 12;
		let pagedReaders = readers.slice(page * perPage, (page + 1) * perPage);

		// get reader discounts
		const discounts = {};
		if (Object.values(publisherSettings).length) {
			pagedReaders.forEach((reader) => {
				const settings = Object.values(publisherSettings).find(
					(pub) =>
						pub.readerDiscounts &&
						pub.readerDiscounts.includes(reader.id) &&
						pub.readerDiscountSettings &&
						pub.readerDiscountSettings[reader.id],
				);

				if (settings) {
					// store discount
					discounts[reader.id] = {
						amount: settings.readerDiscountSettings[reader.id],
						unit: (settings.readerDiscountUnit && settings.readerDiscountUnit[reader.id]) || "percent",
					};
				}
			});
		}

		const filterFeeOptions = [
			{ text: t("1KTo1,5K"), value: 100 },
			{ text: t("1,5KTo2K"), value: 150 },
			{ text: t("2KTo2,5K"), value: 200 },
			{ text: t("2,5KTo3K"), value: 250 },
			{ text: t("3KTo3,5K"), value: 300 },
			{ text: t("3,5KPlus"), value: 350 },
		];

		return (
			<>
				<DocTitle title={(production?.title ? production.title + " – " : "") + t("narrator")} />
				{production && (
					<div style={{ float: "left" }}>
						{!pins?.length ? (
							<em>
								{t("markReaderAsSuggestion")} <Icon name="check circle outline" color="grey" />{" "}
								{t("nextToReaderName")}
							</em>
						) : (
							<Button
								primary
								//content="Lägg till produktionen"
								content={t("addToProd")}
								icon={"plus circle"}
								labelPosition="left"
								onClick={onClose}
							/>
						)}

						{production && !!pins?.length && (
							<Button
								color="pink"
								content={onlyPinned ? t("showAll") : t("showChoosen")}
								icon={"circle " + (onlyPinned ? "outline" : "check")}
								labelPosition="left"
								onClick={() => {
									this.setState({ onlyPinned: !onlyPinned });
								}}
							/>
						)}
					</div>
				)}

				<div className="reader-modal-actions">
					{profile &&
						profile.permissions &&
						profile.permissions.producer &&
						profile.permissions.producer[producerId] &&
						(Object.values(profile.permissions.producer[producerId]).includes("producerReaderManager") ||
							Object.values(profile.permissions.producer[producerId]).includes("producerAdmin")) && (
							<SettingsPopup inline>
								<Form>
									<Form.Select
										label={t("invitedBy")}
										options={[
											{ key: "FAKE", value: "", text: t("noOne") },
											...allReaders
												.reduce((prev, curr) => {
													if (curr.referrer && !prev.includes(curr.referrer))
														prev.push(curr.referrer);
													return prev;
												}, [])
												.map((code) => ({
													key: code,
													value: code,
													text: users[code]
														? users[code].firstName + " " + users[code].lastName
														: code,
												})),
										]}
										name="referrer"
										value={filter.referrer || ""}
										onChange={this.setFilter}
									/>
									<Form.Checkbox
										checked={nonSearchable}
										label={t("showNoSearchableToo")}
										onChange={() => {
											this.setState({ nonSearchable: !nonSearchable });
										}}
									/>
								</Form>
							</SettingsPopup>
						)}

					{production?.genre && production?.author && (
						<Button
							icon="magic"
							labelPosition="left"
							content={t("matchWithProd")}
							color="orange"
							onClick={this.getRecommendations}
							loading={loadingProduction}
						/>
					)}

					<Link to="/readerSignup">
						<Button icon="send" labelPosition="left" color="teal" content={t("inviteNewReader")} />
					</Link>
					<Link to={"/readerPreview/" + pins.join(",")} style={!pins.length ? { pointerEvents: "none" } : {}}>
						<Button
							icon="share"
							labelPosition="left"
							color="violet"
							disabled={!pins.length}
							content={t("shareMarkedReaderViaEmail")}
						/>
					</Link>
				</div>

				<Segment className="clear">
					<Form as="div">
						<Form.Group>
							<Form.Input
								label={t("name")}
								name="name"
								value={filter.name || ""}
								onChange={this.setFilter}
								width={4}
								autoFocus={true}
							/>
							<div className="field">
								<label>{t("sex")}</label>
								<Button.Group>
									<Button
										labelPosition="left"
										icon="woman"
										content={t("woman")}
										color="teal"
										name="female"
										value={true}
										onClick={this.setFilter}
										basic={!filter.female}
										style={{ fontWeight: "normal" }}
									/>
									<Button
										labelPosition="right"
										icon="man"
										content={t("man")}
										color="teal"
										name="male"
										value={true}
										onClick={this.setFilter}
										basic={!filter.male}
										style={{ fontWeight: "normal" }}
									/>
									<Button
										labelPosition="right"
										icon="transgender"
										content={t("other")}
										color="teal"
										name="other"
										value={true}
										onClick={this.setFilter}
										basic={!filter.other}
										style={{ fontWeight: "normal" }}
									/>
								</Button.Group>
							</div>
							<Form.Input
								label={t("minAge")}
								name="agefrom"
								type="number"
								min="0"
								value={filter.agefrom || ""}
								onChange={this.setFilter}
								width={1}
							/>
							<Form.Input
								label={t("maxAge")}
								name="ageto"
								type="number"
								min="0"
								value={filter.ageto || ""}
								onChange={this.setFilter}
								width={1}
							/>
							<Form.Select
								multiple
								selection
								options={filterFeeOptions}
								label={t("fee")}
								name="cost"
								value={(filter.cost || []).filter((value) =>
									filterFeeOptions.find((option) => option.value === value),
								)}
								onChange={this.setFilter}
								width={2}
								fluid
							/>
							<Form.Select
								multiple
								selection
								options={[
									{ key: 1, text: t("veryDark"), value: 1 },
									{ key: 2, text: t("dark"), value: 2 },
									{ key: 3, text: t("neutral"), value: 3 },
									{ key: 4, text: t("light"), value: 4 },
									{ key: 5, text: t("veryLight"), value: 5 },
								]}
								label={t("voice")}
								name="voice"
								value={filter.voice || []}
								onChange={this.setFilter}
								width={3}
								fluid
							/>
						</Form.Group>
						<Form.Group style={{ marginBottom: 5 }}>
							<Form.Select
								multiple
								selection
								search
								deburr
								label={t("languageSkills", "Language skills")}
								options={Object.keys(languages).map((code) => ({
									key: code,
									value: code,
									text: t("language:" + code) + " (" + languages[code].nativeName + ")",
								}))}
								name="language"
								value={filter.language || []}
								onChange={this.setFilter}
								width={4}
								fluid
							/>
							<Form.Select
								multiple
								selection
								search
								deburr
								label={t("otherLanguageSkills")}
								options={Object.keys(languages).map((code) => ({
									key: code,
									value: code,
									text: t("language:" + code) + " (" + languages[code].nativeName + ")",
								}))}
								name="otherLanguages"
								value={filter.otherLanguages || []}
								onChange={this.setFilter}
								width={4}
								fluid
							/>
							<Form.Select
								selection
								label={t("sorting")}
								options={[
									{ key: "name", value: "name", text: t("name") },
									{ key: "lastSample", value: "lastSample", text: t("lastSample") },
									{ key: "created", value: "created", text: t("registrated") },
								]}
								value={sorting}
								onChange={(e, data) => {
									this.setState({ sorting: data.value });
									localStorage("readers", "sorting", data.value);
								}}
								width={2}
								fluid
							/>
							<div style={{ alignSelf: "flex-end", marginLeft: "auto", paddingRight: 10 }}>
								<Pagination
									page={this.state.page}
									items={readers.length}
									per={perPage}
									setPage={(page) => {
										this.setState({ page });

										// scroll modal or window to top
										const el = document.querySelector(".reader-modal")?.parentElement || window;
										el.scrollTo({
											top: 0,
											left: 0,
											behavior: "smooth",
										});
									}}
								/>
							</div>
						</Form.Group>
					</Form>
				</Segment>

				<Card.Group
					className={"reader-list" + (loadingProduction ? " loading" : "")}
					style={{ clear: "both", marginBottom: ".3em" }}
				>
					{pagedReaders.map((reader) => (
						<ReaderCard
							key={reader.id}
							parent={this}
							store={store}
							reader={reader}
							pins={pins}
							pinned={pins.includes(reader.id)}
							discounts={discounts}
							onChange={onChange}
							productionId={productionId}
							alreadyAdded={production?.readerStatus?.[reader.id]?.status === "accepted"}
							status={production?.readerStatus?.[reader.id]?.status || ""}
						/>
					))}
				</Card.Group>

				<Pagination
					page={this.state.page}
					items={readers.length}
					per={perPage}
					setPage={(page) => {
						this.setState({ page });

						// scroll modal or window to top
						const el = document.querySelector(".reader-modal")?.parentElement || window;
						el.scrollTo({
							top: 0,
							left: 0,
							behavior: "smooth",
						});
					}}
					style={{ marginLeft: "1rem", float: "right" }}
				/>

				{this.state.playingFile && (
					<StickyAudio
						file={this.state.playingFile}
						onClose={() => {
							this.setState({ playingFile: null });
						}}
					/>
				)}
			</>
		);
	}
}

export default withTranslation(["common", "language"])(withStore(ListReaders));
