import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Icon, Input, Label, Loader, Modal } from "semantic-ui-react";

import { getBisac } from "../../helpers/fnc";

export const CodeList = ({ codes, parent, level, search, selected, toggleCode, bisac }) => {
	const [open, setOpen] = useState([]);
	const { t } = useTranslation();

	const hits = codes.filter((code) =>
		parent || search.length >= 2
			? (search &&
					(code.CodeValue.toLowerCase() === search.toLowerCase() ||
						(search.length >= 3 && code.CodeDescription.toLowerCase().includes(search.toLowerCase())))) ||
			  (parent && code.CodeParent === parent)
			: !code.CodeParent,
	);

	return hits.length ? (
		hits.map((code) => (
			<div key={code.CodeValue} style={level ? { paddingLeft: level * 10 } : null} className="clear">
				{!search && (!parent || codes.find((c) => c.CodeParent === code.CodeValue)) ? (
					<Icon
						name={"caret " + (open.includes(code.CodeValue) ? "down" : "right")}
						onClick={() => {
							const newOpen = [...open];
							if (newOpen.includes(code.CodeValue)) {
								newOpen.splice(newOpen.indexOf(code.CodeValue), 1);
							} else {
								newOpen.push(code.CodeValue);
							}
							setOpen(newOpen);
						}}
					/>
				) : (
					<Icon style={{ visibility: "hidden" }} />
				)}
				<Form.Checkbox
					size="small"
					checked={selected.includes(code.CodeValue)}
					indeterminate={
						!selected.includes(code.CodeValue) && !!selected.find((c) => c.startsWith(code.CodeValue))
					}
					onChange={(e, data) => {
						toggleCode(code.CodeValue);
					}}
					label={{
						children: (
							<>
								<Label size="small" color={bisac && getBisac([code.CodeValue]).length ? "green" : null}>
									{code.CodeValue}
								</Label>{" "}
								{code.CodeDescription}
							</>
						),
					}}
				/>

				{open.includes(code.CodeValue) && (
					<CodeList
						codes={codes}
						parent={code.CodeValue}
						level={(level || 0) + 1}
						selected={selected}
						toggleCode={toggleCode}
						bisac={bisac}
					/>
				)}
			</div>
		))
	) : (
		<small>
			<b>{t("noResults", "No results")}! </b>
			{search.length < 3 && t("minimumThreeCharacters", "Minimum of three letters for valid search")}
		</small>
	);
};

const ThemaField = ({ production, productionRights, handleChange, bisac }) => {
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [codes, setCodes] = useState(false);
	const [search, setSearch] = useState("");

	const selected = production.genreEditeur || [];

	const toggleCode = (code) => {
		const newCodes = [...selected];
		if (newCodes.includes(code)) {
			newCodes.splice(newCodes.indexOf(code), 1);
		} else {
			newCodes.push(code);
		}

		handleChange(null, { name: "genreEditeur", value: newCodes });
	};

	return (
		<>
			<Form.Select
				label={"Klassifikation: Thema" + (bisac ? " + BISAC" : "")}
				search
				multiple
				name={"genreEditeur"}
				value={production.genreEditeur || []}
				disabled={loading || !productionRights.includes("createProduction")}
				className={!productionRights.includes("createProduction") ? "locked-field" : null}
				loading={loading}
				noResultsMessage={null}
				onOpen={() => {
					setOpen(true);
					if (!codes && !loading) {
						setLoading(true);
						import("astrid-config/src/genres/thema_sv.json").then((data) => {
							setLoading(false);

							setCodes(data.default.CodeList.ThemaCodes.Code);
						});
					}
				}}
				options={(production.genreEditeur || []).map((code) => ({
					key: code,
					value: code,
					text: code,
				}))}
				onChange={(e, data) => {
					handleChange(null, data);
				}}
				renderLabel={({ value }) => value}
			/>

			{open && (
				<Modal
					open
					centered={false}
					onClose={() => {
						setOpen(false);
					}}
					closeOnDocumentClick
				>
					<Modal.Content scrolling className="thema-modal">
						{loading ? (
							<Loader inline active />
						) : (
							<>
								{bisac && (
									<div className="thema-bisac-info">
										<p>
											<em>
												<Icon name="info circle" color="green" />
												Vissa distributörer använder klassifikationen BISAC, koderna översätts
												automatiskt till från de Thema-koder du väljer. Översättningen täcker
												inte 100% av listan, se till att minst en Bisac-kod blir vald.
											</em>
										</p>
										<p>
											<b>Valda BISAC-koder:</b>{" "}
											{getBisac(production.genreEditeur).join(", ") ||
												"Inga koder valda, välj Thema-koder från nivå 2 och nedåt (T.ex. FB)"}
										</p>
									</div>
								)}
								<div style={{ float: "right", minWidth: "30%" }}>
									<Input
										fluid
										size="small"
										placeholder="Sök på kod eller beskrivning..."
										value={search}
										onChange={(e, data) => {
											setSearch(data.value);
										}}
									/>
								</div>
								{codes && (
									<CodeList
										codes={codes}
										search={search}
										selected={selected}
										toggleCode={toggleCode}
										bisac={bisac}
									/>
								)}
							</>
						)}
					</Modal.Content>
					<Modal.Actions>
						<a target="_blank" rel="noopener noreferrer" href="https://ns.editeur.org/thema/sv/">
							<Button
								as="span"
								primary
								icon="external"
								labelPosition="right"
								floated="left"
								content="Mer info om Thema"
							/>
						</a>

						<Button
							positive
							icon="checkmark"
							labelPosition="right"
							content="Klar"
							onClick={() => {
								setOpen(false);
							}}
						/>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default ThemaField;
