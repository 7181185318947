import { useTranslation } from "react-i18next";

import Form from "../../../../forms/components/Form/Form";
import useOfferStatusTranslations from "../../../../offers/hooks/useOfferStatusTranslations";
import { filterDuplicateKeys } from "../../../../ui/utils/filters";

export default function OfferStatusSelect({ offers }) {
	const { t } = useTranslation();
	const offerStatusTranslations = useOfferStatusTranslations();

	const offerStatusOptions = offers
		.map((offer) => ({
			key: offer.status,
			value: offer.status,
			text: offerStatusTranslations[offer.status],
		}))
		.filter(filterDuplicateKeys);

	return (
		<Form.Select
			search
			name="offerStatuses"
			multiple
			options={offerStatusOptions}
			label={t("offerStatus", "Status")}
		/>
	);
}
